import React, { useState, useEffect } from 'react';
// API calls
import { getAllPromotions, /* getAllSponsorLogo, */ getSprPromotionCategory, getSprPromotionType } from '../Models/SponsorModel';
// Components
import Header from '../NewHome/headerColaborador';
import Menu from "../Menu";
import Footer from '../footerApp';
/* import AdCarousel from './AdCarousel'; */
import AdCards from './AdCards';
import AdOptions from './AdOptions';
// Tools
import { getJwt } from "../../../lib/auth";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const Advertising = () => {
    const [promotionList, setPromotionList] = useState([]);
    /* const [logoList, setLogoList] = useState([]); */
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [discount, setDiscount] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [showMessage, setShowMessage] = useState(false);
    useEffect(() => {
        getSprPromotionCategory().then(response => {
            setCategories(response);
        });
        getSprPromotionType().then(response => {
            setTypes(response);
        });
        getAllPromotions(0).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setPromotions(response.resource);
                        setBrands(response.listBrands);
                        let index = 0; // Flag
                        let organizer = []; // Array to oraganice objects by 4 elements each cicle
                        let comparative_1 = []; // Comparative array
                        let comparative_2 = []; // Comparative array
                        let structure = []; // Array with objects structure
                        if (response.resource.length > 4) {
                            for (let x in response.resource) {
                                if (index === 4) {
                                    // Fill comparative array with current cicle objects
                                    comparative_1.push(response.resource[x]);
                                    for (let y in organizer) {
                                        comparative_2.push(organizer[y]);
                                    }
                                    // Set current objects in the structure
                                    structure.push({ adios: '', organizer });
                                    // Clean organizer array
                                    organizer = [];
                                    // Set current object to organizer array
                                    organizer.push(response.resource[x]);
                                    // Set flag
                                    index = 1;
                                } else {
                                    // Fill comparative array with current cicle objects
                                    comparative_1.push(response.resource[x]);
                                    // Set current object to organizer array
                                    organizer.push(response.resource[x]);
                                    // Set flag
                                    index = index + 1;
                                }
                            }
                            // Clean organizer array
                            organizer = [];
                            // Array comparation
                            let comparativeResult = comparative_1.filter(elemento => comparative_2.indexOf(elemento) === -1);
                            for (let x in comparativeResult) {
                                // Set current object to organizer array
                                organizer.push(comparativeResult[x]);
                            }
                            structure.push({ adios: '', organizer });
                        } else {
                            for (let x in response.resource) {
                                // Fill comparative array with current cicle objects
                                comparative_1.push(response.resource[x]);
                                // Set current object to organizer array
                                organizer.push(response.resource[x]);
                            }
                            structure.push({ adios: '', organizer });
                        }
                        setPromotionList(structure);
                        break;
                    case 1002:

                        break;
                    default:
                        toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                        break;
                }
            } else {
                toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
            }
        });
        /* getAllSponsorLogo(getJwt('enterprise')).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let index = 0; // Flag
                        let organizer = []; // Array to oraganice objects by 4 elements each cicle
                        let comparative_1 = []; // Comparative array
                        let comparative_2 = []; // Comparative array
                        let structure = []; // Array with objects structure
                        if (response.sponsor.length > 6) {
                            for (let x in response.sponsor) {
                                if (index === 6) {
                                    // Fill comparative array with current cicle objects
                                    comparative_1.push(response.sponsor[x]);
                                    for (let y in organizer) {
                                        comparative_2.push(organizer[y]);
                                    }
                                    // Set current objects in the structure
                                    structure.push({ adios: '', organizer });
                                    // Clean organizer array
                                    organizer = [];
                                    // Set current object to organizer array
                                    organizer.push(response.sponsor[x]);
                                    // Set flag
                                    index = 1;
                                } else {
                                    // Fill comparative array with current cicle objects
                                    comparative_1.push(response.sponsor[x]);
                                    // Set current object to organizer array
                                    organizer.push(response.sponsor[x]);
                                    // Set flag
                                    index = index + 1;
                                }
                            }
                            // Clean organizer array
                            organizer = [];
                            // Array comparation
                            let comparativeResult = comparative_1.filter(elemento => comparative_2.indexOf(elemento) === -1);
                            for (let x in comparativeResult) {
                                // Set current object to organizer array
                                organizer.push(comparativeResult[x]);
                            }
                            structure.push({ adios: '', organizer });
                        } else {
                            for (let x in response.sponsor) {
                                // Fill comparative array with current cicle objects
                                comparative_1.push(response.sponsor[x]);
                                // Set current object to organizer array
                                organizer.push(response.sponsor[x]);
                            }
                            structure.push({ adios: '', organizer });
                        }
                        setLogoList(structure);
                        break;
                    case 1002:

                        break;
                    default:
                        toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                        break;
                }
            } else {
                toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
            }
        }); */
    }, []);
    const showPromotions = (arr) => {
        let index = 0; // Flag
        let organizer = []; // Array to oraganice objects by 4 elements each cicle
        let comparative_1 = []; // Comparative array
        let comparative_2 = []; // Comparative array
        let structure = []; // Array with objects structure
        if (arr.length > 4) {
            for (let x in arr) {
                if (index === 4) {
                    // Fill comparative array with current cicle objects
                    comparative_1.push(arr[x]);
                    for (let y in organizer) {
                        comparative_2.push(organizer[y]);
                    }
                    // Set current objects in the structure
                    structure.push({ adios: '', organizer });
                    // Clean organizer array
                    organizer = [];
                    // Set current object to organizer array
                    organizer.push(arr[x]);
                    // Set flag
                    index = 1;
                } else {
                    // Fill comparative array with current cicle objects
                    comparative_1.push(arr[x]);
                    // Set current object to organizer array
                    organizer.push(arr[x]);
                    // Set flag
                    index = index + 1;
                }
            }
            // Clean organizer array
            organizer = [];
            // Array comparation
            let comparativeResult = comparative_1.filter(elemento => comparative_2.indexOf(elemento) === -1);
            for (let x in comparativeResult) {
                // Set current object to organizer array
                organizer.push(comparativeResult[x]);
            }
            structure.push({ adios: '', organizer });
        } else {
            for (let x in arr) {
                // Fill comparative array with current cicle objects
                comparative_1.push(arr[x]);
                // Set current object to organizer array
                organizer.push(arr[x]);
            }
            structure.push({ adios: '', organizer });
        }
        setPageIndex(0);
        setPromotionList(structure);
    }
    const handleCategories = e => {
        let arr = [];
        if (e.target.value === '' && brand === '' && discount === '') {
            arr = promotions;
        }
        if (e.target.value === '' && brand === '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value === '' && brand !== '' && discount === '') {
            for (let x in promotions) {
                if (promotions[x].idBrand === parseInt(brand)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value === '' && brand !== '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount) && promotions[x].idBrand === parseInt(brand)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand !== '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount) && promotions[x].idPromotionCategory === parseInt(e.target.value) && promotions[x].idBrand === parseInt(brand)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand === '' && discount === '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand !== '' && discount === '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(e.target.value) && promotions[x].idBrand === parseInt(brand)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand === '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount) && promotions[x].idPromotionCategory === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        setCategory(e.target.value);
        if (arr.length === 0) {
            setShowMessage(true);
        } else {
            showPromotions(arr);
            setShowMessage(false);
        }
    }
    const handleBrands = e => {
        let arr = [];
        if (e.target.value === '' && category === '' && discount === '') {
            arr = promotions;
        }
        if (e.target.value === '' && category === '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value === '' && category !== '' && discount === '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(category)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value === '' && category !== '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount) && promotions[x].idPromotionCategory === parseInt(category)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && category !== '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(discount) && promotions[x].idPromotionCategory === parseInt(category) && promotions[x].idBrand === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && category === '' && discount === '') {
            for (let x in promotions) {
                if (promotions[x].idBrand === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && category !== '' && discount === '') {
            for (let x in promotions) {
                if (promotions[x].idBrand === parseInt(e.target.value) && promotions[x].idPromotionCategory === parseInt(category)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && category === '' && discount !== '') {
            for (let x in promotions) {
                if (promotions[x].idBrand === parseInt(e.target.value) && promotions[x].idPromotionType === parseInt(discount)) {
                    arr.push(promotions[x]);
                }
            }
        }
        setBrand(e.target.value);
        if (arr.length === 0) {
            setShowMessage(true);
        } else {
            showPromotions(arr);
            setShowMessage(false);
        }
    }
    const handleDiscounts = e => {
        let arr = [];
        if (e.target.value === '' && brand === '' && category === '') {
            arr = promotions;
        }
        if (e.target.value === '' && brand === '' && category !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(category)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value === '' && brand !== '' && category === '') {
            for (let x in promotions) {
                if (promotions[x].idBrand === parseInt(brand)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value === '' && brand !== '' && category !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(category) && promotions[x].idBrand === parseInt(brand)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand === '' && category === '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionType === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand !== '' && category !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(category) && promotions[x].idBrand === parseInt(brand) && promotions[x].idPromotionType === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand !== '' && category === '') {
            for (let x in promotions) {
                if (promotions[x].idBrand === parseInt(brand) && promotions[x].idPromotionType === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        if (e.target.value !== '' && brand === '' && category !== '') {
            for (let x in promotions) {
                if (promotions[x].idPromotionCategory === parseInt(category) && promotions[x].idPromotionType === parseInt(e.target.value)) {
                    arr.push(promotions[x]);
                }
            }
        }
        setDiscount(e.target.value);
        if (arr.length === 0) {
            setShowMessage(true);
        } else {
            showPromotions(arr);
            setShowMessage(false);
        }
    }
    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay">
                <Header />
                <Menu />
                <div className="content-wrapper bootomFooter">
                    <div className="content-header">
                        <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                            <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                <div className="row breadcrumbs-top d-inline-block">
                                    <div className="breadcrumb-wrapper col-12">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item font-medium-3"><a href={getJwt('rol') === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")}>Inicio</a></li>
                                            <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Promociones</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        {/* <AdCarousel
                            logoList={logoList}
                        /> */}
                        <div className="col-11 mx-auto mb-3 text-center">
                            <h1 className="text-base-guay">Promociones</h1>
                        </div>
                        <AdOptions
                            categories={categories}
                            types={types}
                            brands={brands}
                            brand={brand}
                            category={category}
                            discount={discount}
                            handleBrands={handleBrands}
                            handleCategories={handleCategories}
                            handleDiscounts={handleDiscounts}
                        />
                        <AdCards
                            promotionList={promotionList}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            showMessage={showMessage}
                        />
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Advertising;
