import React, {
    useState,
    useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
// TODO: API calls
import {
    getUploadedCollaborators,
    setFinishUpload,
} from '../../../Models/Collaborators_model'
import { getEnterpriseArea } from '../../../Models/Catalog_model'
import {
    getBranches,
    getCommunicactionConfig,
} from '../../../Models/Enterprise_model'
// TODO: Components
import InfoModal from './InfoModal'
import ModalLoaderGen from '../../../ModalLoaderGen'
// TODO: Generic func
import { convertDateToYYYYMMDD } from '../../../../helpers/helpers'
import { getJwt } from '../../../../../lib/auth'
// TODO: Tools
import Datepicker, { registerLocale } from 'react-datepicker'
import {
    getDay,
    /* addDays, */
} from 'date-fns'
import es from 'date-fns/locale/es'
import * as toastr from 'toastr'
import $ from 'jquery'

registerLocale('es', es)

const WelcomeEmailCtrl = ({
    actualizarSteps,
    setCompletedSteps,
    setTitleSection,
}) => {
    const history = useHistory()
    const [selectedDate, setSelectedDate] = useState('')
    const [datePickerValue, setDatePickerValue] = useState('')
    const [message, setMessage] = useState('')
    const [unassignedAreas, setUnassignedAreas] = useState([])
    const [unassignedBranches, setUnassignedBranches] = useState([])
    const [collaboratorsTotal, setCollaboratorsTotal] = useState([])
    const [branchesTotal, setBranchesTotal] = useState([])
    const [areasTotal, setAreasTotal] = useState([])

    useEffect(() => {
        setUnassignedAreas(JSON.parse(atob(localStorage.getItem('ar'))))
        setUnassignedBranches(JSON.parse(atob(localStorage.getItem('br'))))
        getUploadedCollaborators(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    const collaboratorsArr = response.listCollab.filter(collabs =>
                        collabs.active === 1 || // Activo
                        collabs.active === 96 || // Colaborador registrado (vivo) sin telefono
                        collabs.active === 97 || // Colaborador registrado (vivo) sin correo
                        collabs.active === 98 || // Colaborador registrado (vivo) sin telefono
                        collabs.active === 99 // Colaborador registrado (vivo) sin correo
                    );
                    setCollaboratorsTotal(collaboratorsArr.length);
                }
            }
        })
        getBranches(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setBranchesTotal(response.branches.length)
                }
            }
        })
        getEnterpriseArea(getJwt("enterprise")).then((response) => {
            if (response) {
                setAreasTotal(response.areas.length)
            }
        })
        getCommunicactionConfig(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.configCommunication.length > 0) {
                    setMessage(response.configCommunication[0].presentationMsg)
                }
            }
        })
    }, [])

    setTitleSection('Configuración Correo')

    const isWeekday = date => {
        const day = getDay(date)
        return day !== 0 && day !== 6
    }

    const handleOnChange = date => {
        setDatePickerValue(date)
        setSelectedDate(date)
    }

    const finishUploadProcess = (selection) => {
        if (selection === 1) {
            const obj = {
                enterpriseId: getJwt('enterprise'),
            }
            $('#modalLoaderGen').show();
            setFinishUpload(obj).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        toastr.success('Se enviaron los correos correctamente.', '¡Éxito!')
                        $('#modalLoaderGen').hide();
                        history.push('/inicio-administrador')
                    } else {
                        toastr.success('Falló para el envio de correo. Intenta nuevamente. Si el problema perciste comunicate con el equipo de soporte.', '¡Ooops!')
                        $('#modalLoaderGen').hide();
                    }
                } else {
                    toastr.success('Falló para el envio de correo. Intenta nuevamente. Si el problema perciste comunicate con el equipo de soporte.', '¡Ooops!')
                    $('#modalLoaderGen').hide();
                }
            })
        }
        if (selection === 2) {
            const obj = {
                enterpriseId: getJwt('enterprise'),
                flagGreetingDate: 1,
                idUser: getJwt('jtw'),
                greetingDate: convertDateToYYYYMMDD(datePickerValue),
            }
            $('#modalLoaderGen').show();
            setFinishUpload(obj).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        toastr.success('Se programaron los correos correctamente.', '¡Éxito!')
                        $('#modalLoaderGen').hide();
                        history.push('/inicio-administrador')
                    } else {
                        toastr.error('Falló para el envio de correo. Intenta nuevamente. Si el problema perciste comunicate con el equipo de soporte.', '¡Ooops!')
                        $('#modalLoaderGen').hide();
                    }
                } else {
                    toastr.error('Falló para el envio de correo. Intenta nuevamente. Si el problema perciste comunicate con el equipo de soporte.', '¡Ooops!')
                    $('#modalLoaderGen').hide();
                }
            })
        }
    }

    /* const handleMinDate = () => {
        const today = new Date()
        if (today.getHours() > 14) {
            return addDays(today, 1)
        } else {
            return today
        }
    } */

    return (
        <div className='col-12'>
            {datePickerValue !== '' && (
                <InfoModal
                    collaboratorsTotal={collaboratorsTotal}
                    branchesTotal={branchesTotal}
                    areasTotal={areasTotal}
                    datePickerValue={datePickerValue}
                    finishUploadProcess={finishUploadProcess}
                    convertDateToYYYYMMDD={convertDateToYYYYMMDD}
                />
            )}
            <ModalLoaderGen />
            <div className='card'>
                <div className='card-body'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-5 col-lg-5 mb-1'>
                                        <div className='card border-left-green h-100'>
                                            <div className='card-body py-1'>
                                                <div className='row'>
                                                    <div className='col-12 mb-2'>
                                                        <div className='row'>
                                                            <div className='col-2 d-flex justify-content-center'>
                                                                <img
                                                                    className='s-icon'
                                                                    src='./../img/welcomeEmail/icono_aprobado.png'
                                                                    alt='icon'
                                                                />
                                                            </div>
                                                            <div className='col-10'>
                                                                <span className='f-subtitle-sm'>Se guardaron correctamente los registros:</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col-2'></div>
                                                            <div className='col-10 pl-5 f-normal'>
                                                                <p className='font-weight-bold m-0'>{collaboratorsTotal} colaboradores</p>
                                                                <p className='font-weight-bold m-0'>{branchesTotal} centros de trabajo</p>
                                                                <p className='font-weight-bold m-0'>{areasTotal} áreas</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {unassignedAreas.length > 0 && (
                                        <div className='col-sm-12 col-md-5 col-lg-5 mb-1 mx-auto'>
                                            <div className='card border-left-yellow h-100'>
                                                <div className='card-body py-1'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-2 d-flex justify-content-center'>
                                                                    <img
                                                                        className='s-icon'
                                                                        src='./../img/welcomeEmail/icono_warning.png'
                                                                        alt='icon'
                                                                    />
                                                                </div>
                                                                <div className='col-10'>
                                                                    <p className='f-subtitle-sm'>Se detectaron las siguientes áreas sin ser asignadas a colaboradores:</p>
                                                                    <ul className='f-normal'>
                                                                        {unassignedAreas.map(element => (
                                                                            <li
                                                                                key={`area-${element.areaEntId}`}
                                                                            >{element.description}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {unassignedBranches.length > 0 && (
                                        <div className='col-sm-12 col-md-5 col-lg-5 mb-1'>
                                            <div className='card border-left-yellow h-100'>
                                                <div className='card-body py-1'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-2 d-flex justify-content-center'>
                                                                    <img
                                                                        className='s-icon'
                                                                        src='./../img/welcomeEmail/icono_warning.png'
                                                                        alt='icon'
                                                                    />
                                                                </div>
                                                                <div className='col-10'>
                                                                    <p className='f-subtitle-sm'>Se detectaron los siguientes centros de trabajo sin ser asignados a colaboradores:</p>
                                                                    <ul className='f-normal'>
                                                                        {unassignedBranches.map(element => (
                                                                            <li
                                                                                key={`branch-${element.branchEntId}`}
                                                                            >{element.description}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <h1 className='f-subtitle'>Tus colaboradores están apunto de iniciar su viaje, envía el correo de bienvenida</h1>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-8'>
                                                <div className='col-12 border rounded border-secondary bg-secundary-2 py-1'>
                                                    <div className='col-12 f-normal'>
                                                        <p>¡Hola colaborador!</p>
                                                        <p>¿Conoces {''}
                                                            <img
                                                                className='img-logo-nav'
                                                                src='./../img/guay-logo.png'
                                                                alt='logo'
                                                                style={{ height: '1.1rem' }}
                                                            /> {''}
                                                            ? Somos una plataforma que ayuda a las organizaciones y sus colaboradores a transformar el entorno laboral: más saludable, mejor habilitado, crecimiento continuo y mejores competencias.
                                                        </p>
                                                        <p>¡Lo hemos creado pensando en ti!</p>
                                                        <p>Con guay ahora obtendrás diversos beneficios que puedes aprovechar dentro y fuera de tu entorno laboral para mejorar tu calidad de vida.</p>
                                                        <p>Algunos de los beneficios que encontrarás:</p>
                                                        <ul>
                                                            <li>Información y herramientas para mejorar la salud y bienestar en el trabajo</li>
                                                            <li>Evaluaciones y diagnósticos</li>
                                                        </ul>
                                                        <p>Disfruta de la vida utilizando este extraordinario medio que ponemos a tu alcance para tu beneficio.</p>
                                                        <p>Todo esto y más lo encuentras en {''}
                                                            <img
                                                                className='img-logo-nav'
                                                                src='./../img/guay-logo.png'
                                                                alt='logo'
                                                                style={{ height: '1.1rem' }}
                                                            />, {''}
                                                            ingresa a nuestra plataforma, descúbrela y sorpréndete.
                                                        </p>
                                                        <p>{message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 d-flex flex-wrap align-content-center'>
                                                <div className='col-12 mb-1 text-center'>
                                                    <h5 className='text-blue-sec font-weight-bold m-0 f-subtitle-sm'>Programar tu fecha de envío:</h5>
                                                </div>
                                                <div className='col-12 mb-1 d-flex justify-content-center datepicker-full'>
                                                    <Datepicker
                                                        dateFormat="d MMMM, yyyy"
                                                        className='form-control text-center bg-secundary-2 border-secondary f-normal'
                                                        selected={selectedDate}
                                                        onChange={date => handleOnChange(date)}
                                                        filterDate={isWeekday}
                                                        minDate={new Date()}
                                                        placeholderText='Selecciona'
                                                        onKeyDown={(e) => { e.preventDefault() }}
                                                        autoComplete='off'
                                                        id='dateCommunication'
                                                        locale='es'
                                                    />
                                                    <img
                                                        className='s-icon-sm'
                                                        style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            right: '20px',
                                                        }}
                                                        src='./../img/welcomeEmail/icono_calendario.png'
                                                        alt='icon'
                                                    />
                                                </div>
                                                <div className='col-12 mb-1 text-center'>
                                                    <span className='text-blue-sec font-weight-bold f-normal'>
                                                        {
                                                            datePickerValue !== '' && (
                                                                convertDateToYYYYMMDD(new Date()) === convertDateToYYYYMMDD(datePickerValue)
                                                                    ? ('Se envia al presionar Terminar')
                                                                    : ('Se envía a las 8:00 hrs')
                                                            )
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <button
                                    className='btn btn-sm bg-secundary-1 text-white f-normal'
                                    onClick={() => {
                                        actualizarSteps(3)
                                        setCompletedSteps(3)
                                    }}
                                >Regresar</button>
                                <button
                                    className='btn btn-sm bg-primary-1 text-white f-normal float-right'
                                    onClick={() => {
                                        if (datePickerValue === '') {
                                            toastr.warning('Debes seleccionar una fecha', '¡Ooops!')
                                            return
                                        }
                                        $('#infoModal').show()
                                    }}
                                >Terminar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeEmailCtrl