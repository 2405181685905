import React from 'react';
import FileViewer from "react-file-viewer";
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const DocumentViewer = ({ file, type }) => {

    const onError = e => {
        toastr.info("El tipo de documento no se puede visualizar. Te sugerimos que lo descargues.", "¡Ooops!");
        console.log(e, "error in file-viewer");
    };

    return (
        <div
            className="modal"
            id="modalDocumentViewer"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => $('#modalDocumentViewer').hide()}>&times;</button>
                    </div>
                    {/*<!-- Modal body -->*/}
                    <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                        <div className="col-lg-12 viewDocument">
                            <FileViewer fileType={type} filePath={file} onError={onError} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentViewer
