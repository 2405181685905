import React, { Fragment } from 'react';
// API calls
import { getAddressByCP } from "../Models/Catalog_model";
// Tools
import $ from "jquery";
import PropTypes from 'prop-types';

const GenDataAddress = ({ dataAddress, setDataAddress, countries }) => {
    const {
        stateResidence,
        townResidence,
        neibgResidence,
        cpResidence,
        streetResidence,
        numExtResidence,
        numIntResidence,
        countryResidence
    } = dataAddress;
    const handleZip = e => {
        let value = e.target.value;
        switch (e.target.value.length) {
            case 5:
                $("#cpResidence").addClass("border-success");
                $("#cpResidence").removeClass("border-danger-guay");
                getAddressByCP(e.target.value).then((respAddress) => {
                    if (respAddress.length > 0) {
                        $("#stateResidence, #townResidence, #neibgResidence").html("");
                        $("#stateResidence").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                        $("#townResidence").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                        $("#townResidence").addClass("border-success");
                        $("#townResidence").removeClass("border-danger-guay");
                        $("#stateResidence").addClass("border-success");
                        $("#stateResidence").removeClass("border-danger-guay");
                        $("#neibgResidence").addClass("border-success");
                        $("#neibgResidence").removeClass("border-danger-guay");
                        $.each(respAddress, function (i, item) {
                            $("#neibgResidence").append('<option key="n' + respAddress[i].idNeighborhood + '" value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                            $("#neibgResidence").attr("disabled", false);
                        });
                        setDataAddress({
                            ...dataAddress,
                            cpResidence: respAddress[0].zip,
                            stateResidence: respAddress[0].idState,
                            townResidence: respAddress[0].idTown,
                            neibgResidence: respAddress[0].idNeighborhood,
                            countryResidence: '484', //Mexico
                        });
                    } else {
                        const select = "Seleccione";
                        const optionCero = 0;
                        setDataAddress({
                            ...dataAddress,
                            cpResidence: value,
                            stateResidence: 0,
                            townResidence: 0,
                            neibgResidence: 0,
                        });
                        $("#stateResidence").removeClass("border-success");
                        $("#townResidence").removeClass("border-success");
                        $("#neibgResidence").removeClass("border-success");
                        $("#cpResidence").removeClass("border-success");
                        $("#stateResidence, #townResidence, #neibgResidence").html("");
                        $("#stateResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                        $("#townResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                        $("#neibgResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                    }
                });
                break;
            case 4:
                $("#cpResidence").addClass("border-success");
                $("#cpResidence").removeClass("border-danger-guay");
                getAddressByCP(e.target.value).then((respAddress) => {
                    if (respAddress.length > 0) {
                        $("#stateResidence, #townResidence, #neibgResidence").html("");
                        $("#stateResidence").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                        $("#townResidence").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                        $("#townResidence").addClass("border-success");
                        $("#townResidence").removeClass("border-danger-guay");
                        $("#stateResidence").addClass("border-success");
                        $("#stateResidence").removeClass("border-danger-guay");
                        $("#neibgResidence").addClass("border-success");
                        $("#neibgResidence").removeClass("border-danger-guay");
                        $.each(respAddress, function (i, item) {
                            $("#neibgResidence").append('<option key="n' + respAddress[i].idNeighborhood + '" value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                            $("#neibgResidence").attr("disabled", false);
                        });
                        setDataAddress({
                            ...dataAddress,
                            cpResidence: respAddress[0].zip,
                            stateResidence: respAddress[0].idState,
                            townResidence: respAddress[0].idTown,
                            neibgResidence: respAddress[0].idNeighborhood,
                            countryResidence: '484', //Mexico
                        });
                    } else {
                        const select = "Seleccione";
                        const optionCero = 0;
                        setDataAddress({
                            ...dataAddress,
                            cpResidence: value,
                            stateResidence: 0,
                            townResidence: 0,
                            neibgResidence: 0,
                        });
                        $("#stateResidence").removeClass("border-success");
                        $("#townResidence").removeClass("border-success");
                        $("#neibgResidence").removeClass("border-success");
                        $("#cpResidence").removeClass("border-success");
                        $("#stateResidence, #townResidence, #neibgResidence").html("");
                        $("#stateResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                        $("#townResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                        $("#neibgResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                    }
                });
                break;
            default:
                if (e.target.value.length < 4) {
                    const select = "Seleccione";
                    const optionCero = 0;
                    setDataAddress({
                        ...dataAddress,
                        cpResidence: value,
                        stateResidence: 0,
                        townResidence: 0,
                        neibgResidence: 0,
                    });
                    $("#stateResidence").removeClass("border-success");
                    $("#townResidence").removeClass("border-success");
                    $("#neibgResidence").removeClass("border-success");
                    $("#cpResidence").removeClass("border-success");
                    $("#stateResidence, #townResidence, #neibgResidence").html("");
                    $("#stateResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                    $("#townResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                    $("#neibgResidence").append('<option value="' + optionCero + '">' + select + '</option>');
                }
                break;
        }
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 mb-1">
                    <h3>Dirección</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Código Postal:</label>
                        <input
                            type="number"
                            className="form-control form-control-sm"
                            placeholder="Código postal"
                            id="cpResidence"
                            name="cpResidence"
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                            onChange={handleZip}
                            value={cpResidence}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>País:</label>
                        <select
                            className="form-control form-control-sm"
                            id="countryResidence"
                            name="countryResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            value={countryResidence}
                        >
                            <option value="">Seleccionar</option>
                            {
                                countries.map((data, index) => (
                                    <option
                                        key={"contry" + index + "--" + data.id}
                                        value={data.id}
                                    >{data.description}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Estado:</label>
                        <select
                            className="form-control form-control-sm"
                            id="stateResidence"
                            name="stateResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            disabled
                            value={stateResidence}
                        >
                            <option value="">Seleccionar</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Ciudad:</label>
                        <select
                            className="form-control form-control-sm"
                            id="townResidence"
                            name="townResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            disabled
                            value={townResidence}
                        >
                            <option value="">Seleccionar</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Colonia:</label>
                        <select
                            className="form-control form-control-sm"
                            id="neibgResidence"
                            name="neibgResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            value={neibgResidence}
                        >
                            <option value="">Seleccionar</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Calle:</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Calle"
                            maxLength="150"
                            id="streetResidence"
                            name="streetResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            value={streetResidence}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Núm. exterior:</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="100"
                            maxLength="10"
                            id="numExtResidence"
                            name="numExtResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            value={numExtResidence}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Núm. Interior:</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="100 B"
                            maxLength="10"
                            id="numIntResidence"
                            name="numIntResidence"
                            onChange={e => {
                                setDataAddress({
                                    ...dataAddress,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            value={numIntResidence}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

GenDataAddress.propTypes = {
    dataAddress: PropTypes.object.isRequired,
    setDataAddress: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired
}

export default GenDataAddress;
