import React, { useState } from 'react';
// API calls
import { setClickBrandingByUser } from '../Models/SponsorModel';
// Tools
import Carousel from 'react-bootstrap/Carousel';
import { getJwt } from '../../../lib/auth';

const BannerLogo = ({ logoList }) => {
    console.log(logoList)
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const iconNext = <span className="bi bi-chevron-right" style={{ color: '#6b6f82' }}></span>;
    const prevIcon = <span className="bi bi-chevron-left" style={{ color: '#6b6f82' }}></span>;
    return (
        <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            nextIcon={iconNext}
            prevIcon={prevIcon}
        >
            {
                logoList.length === 0 ? (
                    <div className="carousel-item active">
                        <div
                            className="col-11 mx-auto d-flex justify-content-center flex-wrap align-content-center"
                            style={{
                                height: '50px',
                            }}
                        >
                            <span>No hay logos disponibles</span>
                        </div>
                    </div>
                ) : (
                    logoList.map((data, index) => (
                        <Carousel.Item
                            interval={10000}
                            key={'inner-logo-' + index}
                        >
                            <div className="col-11 mx-auto">
                                <div className="row">
                                    {
                                        data.organizer.map((img, index) => (
                                            <div
                                                key={'logo-' + index}
                                                className="col-sx-12 col-sm-12 col-md-2 col-lg-2 mx-auto d-flex justify-content-center py-1"
                                            >
                                                {
                                                    img.resourceUrl === '' ? (
                                                        <div
                                                            className="d-flex flex-wrap align-content-center"
                                                            style={{ height: '35px' }}
                                                        >
                                                            <span
                                                                onClick={() => {
                                                                    if (img.customUrl1 !== '') {
                                                                        let formData = new FormData();
                                                                        let data = {
                                                                            idBrandPresence: img.idBrandPresence,
                                                                            idUser: getJwt('jtw'),
                                                                            idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                                                                        }
                                                                        formData.append("request", JSON.stringify(data));
                                                                        setClickBrandingByUser(formData);
                                                                        let protocol = img.customUrl1.split(':');
                                                                        if (protocol[0] === 'https' || protocol[0] === 'http') {
                                                                            window.open(img.customUrl1);
                                                                        } else {
                                                                            window.open("//" + img.customUrl1);
                                                                        }
                                                                    }
                                                                }}
                                                            >{img.title}</span>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={img.resourceUrl}
                                                            className="pointer"
                                                            alt="promo"
                                                            onClick={() => {
                                                                let formData = new FormData();
                                                                let data = {
                                                                    idBrandPresence: img.idBrandPresence,
                                                                    idUser: getJwt('jtw'),
                                                                    idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                                                                }
                                                                formData.append("request", JSON.stringify(data));
                                                                setClickBrandingByUser(formData);
                                                                let protocol = img.customUrl1.split(':');
                                                                if (protocol[0] === 'https' || protocol[0] === 'http') {
                                                                    window.open(img.customUrl1);
                                                                } else {
                                                                    window.open("//" + img.customUrl1);
                                                                }
                                                            }}
                                                            style={{
                                                                height: '35px'
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Carousel.Item>
                    ))
                )
            }
        </Carousel>
    );
}

export default BannerLogo;
