import React, { useState, useEffect } from 'react'
//import { IconGuaycitaHelper } from './components/guaycitaHelper/IconGuaycitaHelper'
import { FloatDivHelper } from './components/guaycitaHelper/FloatDivHelper'
import { connect } from 'react-redux'
import $ from 'jquery'

const HelperApp = ({ general }) => {
    const { idProduct, idCategory, idSubCategory } = general;
    const [showHelpImg, setShowHelpImg] = useState(true)
    const [showBtn, setShowBtn] = useState(false)
    const [countHelp, setCountHelp] = useState(0)
    const [hideFlag, setHideFlag] = useState(true)

    /* useEffect(() => {
        if (sessionStorage.getItem('countHelp') === undefined) {
            sessionStorage.setItem('countHelp', 0)
        } else {
            setShowHelpImg(false)
            setShowBtn(true)
        }
    }, []) */

    useEffect(() => {
        if (sessionStorage.getItem('countHelp') !== '15') {
            if (countHelp < 15) {
                setTimeout(() => {

                    let counter = countHelp + 1
                    setCountHelp(counter)
                }, 1000)
            } else {
                setShowHelpImg(false)
                setShowBtn(true)
                sessionStorage.setItem('countHelp', 15)
            }
        } else {
            if (hideFlag) {
                setShowHelpImg(false)
                setShowBtn(true)
            } else {
                setHideFlag(false)
            }
        }
    }, [countHelp, hideFlag])

    const handleClickHelp = () => {
        if (showHelpImg) {
            $('#containerHelp').removeClass('animate__backOutRight hidden');
            $('#containerHelp').addClass('animate__backInRight');
            //setDisplayHelp(!displayHelp);
            setShowHelpImg(false)
            setHideFlag(false)
            sessionStorage.setItem('countHelp', 15)
        }
        else {
            $('#containerHelp').removeClass('animate__backInRight');
            $('#containerHelp').addClass('animate__backOutRight');
            //setDisplayHelp(!displayHelp);
            setShowHelpImg(true)
        }
    }

    return (
        <div className='col-12'>
            <div className='div-helper d-flex flex-wrap align-content-center'>
                {showHelpImg && (
                    <img
                        src="./../img/guaycita.gif"
                        alt="guaycita"
                        style={{
                            marginRight: '25px',
                            width: '80px',
                            height: '80px',
                        }}
                        className="pointer fade-style"
                        onClick={handleClickHelp}
                    />
                )}
                <FloatDivHelper
                    setShowBtn={setShowBtn}
                    idProduct={idProduct}
                    idCategory={idCategory}
                    idSubCategory={idSubCategory}
                />
                {showBtn && (
                    <div
                        className='bg-cian-giki d-flex flex-wrap align-content-center div-span fade-style'
                        onClick={() => {
                            setShowHelpImg(!showHelpImg)
                            setShowBtn(false)
                            sessionStorage.setItem('countHelp', 0)
                            setCountHelp(1)
                            console.log('contador')
                        }}
                    >
                        <i
                            className={`bi ${showHelpImg ? 'bi-question-lg' : 'bi-question-lg'} text-white font-medium-5 pointer`}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}
export default connect(mapStateToProps)(HelperApp)