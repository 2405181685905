import React, { useState, useEffect } from 'react'

const PlanEsencial = () => {
    const [urlImg, setUrlImg] = useState('')
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            // platform = "Mobile";
            setUrlImg("./../img/beneficios/BANNER_plan_oftalmo_esencial_mobile.png")
        } else {
            setUrlImg("./../img/beneficios/BANNER_plan_oftalmo_esencial.png")
        }
      
    }, [])
    return (
        <div className='container-fluid'>

            <div className='row bg-cian-giki py-1 py-md-0'>
                <img className="img-responsive" src={urlImg} alt="img-banner" />
                {/* <div className='col-12'>
                    <div className='row'>
                        <div className='col-4 col-md-3 d-flex justify-content-center align-items-center'>
                            <div>
                                <img
                                    src='./../img/beneficios/Icono_beneficios_Oftalmologia.png'
                                    className='img-fluid my-0 my-md-2'
                                    alt='oftalmologia'
                                />
                            </div>
                        </div>
                        <div className='col-8 col-md-9 d-flex align-items-center'>
                            <div className='w-100'>
                                <div className='row'>
                                    <div className='col-12 px-1 px-md-4'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <h1 className='text-white fs-1-125'>Plan Esencial</h1>
                                            <img
                                                src='./../img/beneficios/icono_plan_esecnial_oftalmo.png'
                                                className='img-fluid ml-1 my-1'
                                                alt='oftalmologia'
                                            />
                                        </div>
                                        <hr className='hr-white m-0 p-0 mb-md-1 pb-md-1' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 px-1 px-md-4'>
                                        <h1 className='text-white fs-1'>Consultas ilimitadas de urgencia</h1>
                                        <h2 className='text-white text-justify fs-1'>(accidente o enfermedad) para valoración visual que recomiende el Oftalmólogo.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className='row my-3'>

                <div className='col-12 d-flex align-items-center mb-1'>
                    <h1 className='fs-1'>Incluyen los siguientes estudios:</h1>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-0'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>1.</b> Test de agudeza visual</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-1 order-sm-2 order-md-3'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>2.</b> Medición de graduación</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-2 order-sm-4 order-md-6'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>3.</b> Valoración de movimientos oculares</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-3 order-sm-6 order-md-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>4.</b> Biomicroscopia y fondo de ojo</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-4 order-sm-8 order-md-4'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>5.</b> Toma de presión intraocular</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-5 order-sm-1 order-md-7'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>6.</b> Gonioscopía</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-6 order-sm-3 order-md-2'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>7.</b> Discriminación de colores</h2>
                </div>

                <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center mb-1 order-7 order-sm-5 order-md-5'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'><b>8.</b> Refracción pre y post cicloplejia</h2>
                </div>

            </div>

            <div className='row border-bottom'>
                <div className='col-10 col-md-4 bg-yellow-giki mx-auto mx-md-0'>
                    <h1 className='text-center fs-1-25'>Cobertura</h1>
                </div>
            </div>

            <div className='row my-2'>
                <div className='col-6 col-md-4 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-green-giki text-center fs-1-563'>31</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Ciudades principales del país</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-green-giki text-center fs-1-563'>71</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Profesionales afiliados que respaldan nuestro servicio</h2>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PlanEsencial;