import React from 'react'

const DeliciasTC = () => {
    return (
        <div className='container-fluid bg-transparent-2'>
            <div className='col-sm-12 col-md-10 col-lg-6 mx-auto'>
                <div className='card mt-3'>
                    <div className='card-body'>
                        <p className='font-weight-bold'>Delicias a la medida</p>

                        <p className='text-justify'>
                            Los presentes Términos y Condiciones aplicarán para hacer válido el beneficio contenido en el “cupón” anexo a este correo, aunado a esto, el usuario deberá escribir o comunicarse al número: 55 3947 7368 para coordinar el tema del curso, disponibilidad de horarios y cupo.
                        </p>

                        <p className='text-justify'>
                            Es importante que el Beneficiario mencione al proveedor que cuenta con un cupón de descuento emitido por SD Sigma.
                        </p>

                        <p className='text-justify'>
                            Este beneficio no será acumulable con otras promociones, ni descuentos expresamente no autorizados por el proveedor de cocina seleccionado.
                        </p>

                        <p className='text-justify'>
                            El beneficio tendrá vigencia de treinta (30) días naturales desde el momento en que el Usuario redima o active el código otorgado por Sigma, acorde con el convenio vigente celebrado entre la empresa del colaborador y Sigma.
                        </p>

                        <p className='text-justify'>
                            La promoción es intransferible e improrrogable y no podrá ser cambiada, canjeable o reembolsable por dinero en efectivo, ni por alguna modalidad de redención, ni en especie, ni por algún otro servicio.
                        </p>

                        <p className='text-justify'>
                            Los términos y condiciones relacionados a los cursos se pueden consultar en la página <a className='text-decoration-none text-black font-weight-bold' href='https://www.deliciasalamedida.com/' target='_blank' rel="noreferrer">www.deliciasalamedida.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliciasTC