import React from 'react'

const JobInfoForm = ({
  tcProfileHiring,
  tcProfileAntiquity,
  tcProfileStaffType,
  tcProfileWorkExperience,
  tcProfileTypeOfDay,
  tcProfileCompensation,
  setShowForm,
  jobInfoObj,
  setJobInfoObj,
  handleOnSubmitForm,
}) => {
  const {
    idHiring,
    idAntiquity,
    idStaffType,
    idWorkExperience,
    idTypeDay,
    shiftRotation,
    idCompensation,
  } = jobInfoObj
  const handleOnChange = e => {
    setJobInfoObj({
      ...jobInfoObj,
      [e.target.name]: e.target.value,
    })
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    //console.log(jobInfoObj)
    handleOnSubmitForm(3, jobInfoObj)
  }
  return (
    <div className='col-12'>
      <div className='row'>
        <div className='col-12'>
          <p className='font-weight-bold font-medium-5'>Información Laboral</p>
        </div>
      </div>
      <form onSubmit={handleOnSubmit}>
        <div className='row'>
          {/* <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idHiring'
                className='m-0'
              >¿Cuál es tu tipo de contratación?</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idHiring'
                name='idHiring'
                value={idHiring}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileHiring.map(hiring => (
                    <option
                      key={`hiring-${hiring.idHiring}`}
                      value={hiring.idHiring}
                    >{hiring.description}</option>
                  ))
                }
              </select>
            </div>
          </div> */}
          <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idTypeDay'
                className='m-0'
              >¿Qué tipo de jornada laboral tienes?</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idTypeDay'
                name='idTypeDay'
                value={idTypeDay}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileTypeOfDay.map(day => (
                    <option
                      key={`day-${day.idTypeDay}`}
                      value={day.idTypeDay}
                    >{day.description}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idAntiquity'
                className='m-0'
              >¿Cuánto tiempo llevas en tu puesto actual?</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idAntiquity'
                name='idAntiquity'
                value={idAntiquity}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileAntiquity.map(antiquity => (
                    <option
                      key={`antiquity-${antiquity.idAntiquity}`}
                      value={antiquity.idAntiquity}
                    >{antiquity.description}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto d-flex flex-wrap align-content-center'>
            <div className='row'>
              <div className='col-12'>
                <label
                  htmlFor='shiftRotation'
                  className='m-0'
                >¿Realizas rotación de turno?</label>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col'>
                    <div className='form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='shiftRotation'
                          value='1'
                          onChange={handleOnChange}
                          checked={shiftRotation === '1' ? true : shiftRotation === 1 ? true : false}
                        />Si
                      </label>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='shiftRotation'
                          value='2'
                          onChange={handleOnChange}
                          checked={shiftRotation === '2' ? true : shiftRotation === 2 ? true : false}
                        />No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idWorkExperience'
                className='m-0'
              >Experiencia laboral</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idWorkExperience'
                name='idWorkExperience'
                value={idWorkExperience}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileWorkExperience.map(exp => (
                    <option
                      key={`exp-${exp.idWorkExperience}`}
                      value={exp.idWorkExperience}
                    >{exp.description}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          {/* <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idStaffType'
                className='m-0'
              >¿Qué tipo de personal eres?</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idStaffType'
                name='idStaffType'
                value={idStaffType}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileStaffType.map(staff => (
                    <option
                      key={`staff-${staff.idStaffType}`}
                      value={staff.idStaffType}
                    >{staff.description}</option>
                  ))
                }
              </select>
            </div>
          </div> */}
          {/* <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idCompensation'
                className='m-0'
              >¿Qué esquema de compensación económica tienes?</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idCompensation'
                name='idCompensation'
                value={idCompensation}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileCompensation.map(compensation => (
                    <option
                      key={`compensation-${compensation.idCompensation}`}
                      value={compensation.idCompensation}
                    >{compensation.description}</option>
                  ))
                }
              </select>
            </div>
          </div> */}
        </div>
        {/* <div className='row'>
          <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
            <div className='form-group'>
              <label
                htmlFor='idWorkExperience'
                className='m-0'
              >Experiencia laboral</label>
              <select
                className='form-control form-control-sm input-style-2 font-weight-bold'
                id='idWorkExperience'
                name='idWorkExperience'
                value={idWorkExperience}
                onChange={handleOnChange}
              >
                <option value='0'>Seleccionar</option>
                {
                  tcProfileWorkExperience.map(exp => (
                    <option
                      key={`exp-${exp.idWorkExperience}`}
                      value={exp.idWorkExperience}
                    >{exp.description}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
          </div>
        </div> */}
        <div className='row'>
          <div className='col'>
            <button
              type='button'
              className='btn btn-sm btn-save-blue'
              onClick={() => setShowForm(0)}
            >Regresar</button>
          </div>
          <div className='col'>
            <button
              type='submit'
              className='btn btn-sm btn-pink float-right'
            >Guardar</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default JobInfoForm