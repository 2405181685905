import React, { useState, useEffect } from 'react'
// TODO: API call
import {
    getProfileCatalogs,
} from '../../Models/Catalog_model'
import {
    getProfileInformation,
    setProfileInformation,
    uploadImgProfileUser,
} from '../../Models/ProfileModel'
// TODO: Generic func
import { getJwt } from '../../../../lib/auth'
import { convertDateToYYYYMMDD } from '../../../helpers/helpers'
// TODO: Tools
import { useHistory } from 'react-router-dom'
import * as toastr from 'toastr'
import $ from 'jquery'
import Datepicker, { registerLocale } from 'react-datepicker'
import { subYears } from 'date-fns'
import es from 'date-fns/locale/es'
registerLocale('es', es)

const GenInfoModal = () => {
    const history = useHistory()
    const [imgProfile, setImgProfile] = useState('')
    const [tcProfileGender, settcProfileGender] = useState([])
    const [genInfoObj, setGenInfoObj] = useState({
        idUser: getJwt('jtw'),
        imgProfile: '',
        name: '',
        lastName: '',
        birthdate: '',
        idGender: '0',
        postalCode: '',
        email: '',
    })
    const {
        name,
        lastName,
        birthdate,
        idGender,
        postalCode,
        email,
    } = genInfoObj
    useEffect(() => {
        getProfileInformation(getJwt('jtw')).then(response => {
            if (response && response.code === 1) {
                setImgProfile(response.profileInformation[0].basicInformation.imgProfile === undefined ? '' : response.profileInformation[0].basicInformation.imgProfile)
                setGenInfoObj({
                    idUser: getJwt('jtw'),
                    name: response.profileInformation[0].basicInformation.name === undefined ? '' : response.profileInformation[0].basicInformation.name,
                    lastName: response.profileInformation[0].basicInformation.lastName === undefined ? '' : response.profileInformation[0].basicInformation.lastName,
                    idGender: response.profileInformation[0].basicInformation.idGender === undefined ? '0' : response.profileInformation[0].basicInformation.idGender,
                    postalCode: response.profileInformation[0].basicInformation.postalCode === undefined ? '' : response.profileInformation[0].basicInformation.postalCode === 0 ? '' : response.profileInformation[0].basicInformation.postalCode,
                    birthdate: response.profileInformation[0].basicInformation.birthdate === undefined ? '' : response.profileInformation[0].basicInformation.birthdate === '' ? '' : new Date(response.profileInformation[0].basicInformation.birthdate),
                    email: response.profileInformation[0].basicInformation.email === undefined ? '' : response.profileInformation[0].basicInformation.email,
                })
            } else {
                toastr.error(`No se pudo actualizar la información. Verifica tu conexión de internet. En caso de persistir el problema contacta con el equipo de soporte. Error: ${response.message}`, '¡Ooops!')
            }
        })
        getProfileCatalogs().then(response => {
            if (response && response.code === 1) {
                settcProfileGender(response.tcProfileGender)
            }
        })
    }, [])
    const handleOnSubmitForm = (idForm, dataObj) => {
        if (postalCode.length > 0 && postalCode.length < 5) {
            toastr.warning('El código postal debe tener 5 digitos.', '¡Ooops!')
            return
        }
        let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
        if (!regex.test(email)) {
            toastr.warning('El formato del Correo de Cuenta no es correcto.', '¡Ooops!')
            return
        }
        let data = {}
        if (idForm === 1) {
            data = {
                infoToSave: idForm,
                basicInformation: {
                    idUser: parseInt(getJwt('jtw')),
                    name: dataObj.name,
                    lastName: dataObj.lastName,
                    birthdate: dataObj.birthdate === '' ? '' : convertDateToYYYYMMDD(dataObj.birthdate),
                    idGender: parseInt(dataObj.idGender),
                    postalCode: dataObj.postalCode === '' ? 0 : parseInt(dataObj.postalCode),
                    email: dataObj.email,
                },
            }
        }
        //console.log(data)
        setProfileInformation(data).then(response => {
            if (response && response.code === 1) {
                $('#profileModal').hide()
                history.push('/perfil')
                sessionStorage.setItem('showProfileMessage', '1')
            } else {
                $('#profileModal').hide()
                toastr.error(`No se pudo actualizar la información. Verifica tu conexión de internet. En caso de persistir el problema contacta con el equipo de soporte. Error: ${response.message}`, '¡Ooops!')
            }
        })
    }
    const handleOnChange = e => {
        setGenInfoObj({
            ...genInfoObj,
            [e.target.name]: e.target.value,
        })
    }
    const handleOnSubmit = e => {
        e.preventDefault()
        //console.log(genInfoObj)
        handleOnSubmitForm(1, genInfoObj)
    }
    //* Upload profile image
    const renderFilePers = () => {
        $('#fileModal').click()
    }
    const onChangeHandler = e => {
        let fileTypes = [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/tiff',
        ]
        let correct = false
        let reader = new FileReader()
        let file = e.target.files[0]
        for (let x in fileTypes) {
            if (fileTypes[x] === e.target.files[0].type) {
                correct = true
            }
        }
        if (correct === true) {
            reader.onload = function (e) {
                $('#imgProfileModal').attr('src', e.target.result)
            }
            reader.readAsDataURL(e.target.files[0])
            let data = new FormData()
            data.append('idUser', getJwt('jtw'))
            data.append('file', file)
            uploadImgProfileUser(data).then(response => {
                if (response && response.code === 1) {
                    $('#file').attr('value', '')
                } else {
                    $('#file').attr('value', '')
                    toastr.error(`No se pudo subir el archivo. Verifica tu conexión de internet. En caso de persistir el problema contacta con el equipo de soporte. Error: ${response.message}`, '¡Ooops!')
                }
            })
        } else {
            $('#file').attr('value', '')
            toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!')
        }
    }
    return (
        <div
            className='modal'
            id='profileModal'
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop='false'
        >
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='btn-close-1 d-flex justify-content-center flex-wrap align-content-center'>
                        <button
                            type='button'
                            className='close'
                            onClick={() => $('#profileModal').hide()}
                        >&times;</button>
                    </div>
                    <div className='modal-body container-style-1 border-0'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='text-center font-weight-bold font-medium-5'>Dejanos conocerte mejor!</p>
                                    <p className='text-center font-weight-bold font-medium-3'>Confirma y completa tu información</p>
                                </div>
                            </div>
                            <form onSubmit={handleOnSubmit}>
                                <div className='row'>
                                    <div className='col-12 d-flex flex-wrap align-content-center'>
                                        <div className='col-12 mb-2 d-flex justify-content-center img-wrapper-2'>
                                            <img
                                                className='img-rounded-1 rounded-circle border-green-guay border-1 pointer'
                                                src={imgProfile === '' ? './../img/imagesProfile/iconos_guay-36.png' : imgProfile}
                                                id='imgProfileModal'
                                                alt='profile'
                                                onClick={renderFilePers}
                                            />
                                            <img
                                                className='pointer'
                                                src='./../img/imagesProfile/perfil_organizacion_iconos-03.png'
                                                style={{
                                                    width: '40px',
                                                    height: '25px',
                                                    position: 'absolute',
                                                    right: '35%',
                                                    bottom: '0',
                                                }}
                                                alt='camara'
                                                onClick={renderFilePers}
                                            />
                                            <input
                                                type='file'
                                                id='fileModal'
                                                className='hidden'
                                                onChange={onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='form-group'>
                                            <label
                                                htmlFor='name'
                                                className='m-0'
                                            >Nombre</label>
                                            <input
                                                type='text'
                                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                                id='name'
                                                name='name'
                                                value={name}
                                                onChange={handleOnChange}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label
                                                htmlFor='lastName'
                                                className='m-0'
                                            >Apellidos</label>
                                            <input
                                                type='text'
                                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                                id='lastName'
                                                name='lastName'
                                                value={lastName}
                                                onChange={handleOnChange}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label
                                                htmlFor='birthdate'
                                                className='m-0'
                                            >Fecha de Nacimiento</label>
                                            <div className='col-12 p-0 datepicker-w-100'>
                                                <Datepicker
                                                    dateFormat='dd-MM-yyyy'
                                                    className='form-control form-control-sm input-style-2 font-weight-bold'
                                                    selected={birthdate}
                                                    onChange={date => {
                                                        setGenInfoObj({
                                                            ...genInfoObj,
                                                            birthdate: date,
                                                        })
                                                    }}
                                                    minDate={subYears(new Date(), 100)}
                                                    maxDate={subYears(new Date(), 16)}
                                                    locale='es'
                                                    placeholderText='dd-mm-aaaa (28-01-2000)'
                                                    showYearDropdown
                                                    showMonthDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label
                                                htmlFor='idGender'
                                                className='m-0'
                                            >Género</label>
                                            <select
                                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                                id='idGender'
                                                name='idGender'
                                                value={idGender}
                                                onChange={handleOnChange}
                                            >
                                                <option value='0'>Seleccionar</option>
                                                {
                                                    tcProfileGender.map(gender => (
                                                        <option
                                                            key={`gender-${gender.idGender}`}
                                                            value={gender.idGender}>{gender.description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label
                                                htmlFor='postalCode'
                                                className='m-0'
                                            >Código Postal de Residencia</label>
                                            <input
                                                type='number'
                                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                                id='postalCode'
                                                name='postalCode'
                                                value={postalCode}
                                                onChange={handleOnChange}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label
                                                htmlFor='email'
                                                className='m-0'
                                            >Correo de Cuenta</label>
                                            <input
                                                type='email'
                                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                                id='email'
                                                name='email'
                                                value={email}
                                                onChange={handleOnChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col d-flex justify-content-center'>
                                        <button
                                            type='submit'
                                            className='btn btn-sm btn-pink float-right'
                                        >Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenInfoModal
