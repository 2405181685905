import React, { useState } from 'react'
// TODO: API calls
import { updNewPasswordUser } from '../../profile/Model'
// TODO: Generic func
import { getJwt } from '../../../../lib/auth'
// TODO: Tools
import $ from 'jquery'
import * as toastr from 'toastr'

const SecurityForm = ({ setShowForm }) => {
    const [password, updatePassword] = useState({
        actualPass: '',
        newPassA: '',
        newPassB: ''
    })
    const { actualPass, newPassA, newPassB } = password
    const [validation1, setValidation1] = useState(false)
    const [validation2, setValidation2] = useState(false)
    const [validation3, setValidation3] = useState(false)
    const [validation4, setValidation4] = useState(false)
    const [validation5, setValidation5] = useState(false)
    const [validation6, setValidation6] = useState(false)
    const [correctPassword, setCorrectPassword] = useState(false)
    const handleOnChange = (e) => {
        let value = e.target.value
        $('#newPassA').removeClass('border-danger-guay')
        if (e.target.name === 'newPassA') {
            let counter = 0
            let regexSpecial = /[!@#$%^&*._-]/
            let regexCapital = /[A-Z]/
            let regexNumber = /[0-9]/
            let regexLength = /().{6}/
            let regexWhitespace = /\s/
            let regexLower = /[a-z]/
            if (regexSpecial.test(value)) {
                setValidation1(true)
                counter++
            } else {
                setValidation1(false)
            }
            if (regexCapital.test(value)) {
                setValidation2(true)
                counter++
            } else {
                setValidation2(false)
            }
            if (regexNumber.test(value)) {
                setValidation3(true)
                counter++
            } else {
                setValidation3(false)
            }
            if (regexLength.test(value)) {
                setValidation4(true)
                counter++
            } else {
                setValidation4(false)
            }
            if (regexWhitespace.test(value)) {
                setValidation5(true)
            } else {
                setValidation5(false)
                counter++
            }
            if (regexLower.test(value)) {
                setValidation6(true)
                counter++
            } else {
                setValidation6(false)
            }
            if (counter === 6) {
                setCorrectPassword(true)
            } else {
                setCorrectPassword(false)
            }
        }
        updatePassword({
            ...password,
            [e.target.name]: value
        })
    }

    const validationPassword = (key) => {
        let validated = 0
        let validated_2 = 0
        if (key === 1) {
            if (actualPass === '') {
                $('#actualPass').addClass('border-danger-guay')
                toastr.error('Falta llenar el campo: Constraseña actual', '¡Ooops!')
            } else {
                validated = validated + 1
            }
            if (newPassA === '') {
                $('#newPassA').addClass('border-danger-guay')
                toastr.error('Falta llenar el campo: Nueva contraseña', '¡Ooops!')
            } else {
                validated = validated + 1
            }
            if (newPassB === '') {
                $('#newPassB').addClass('border-danger-guay')
                toastr.error('Falta llenar el campo: Confirmar contraseña', '¡Ooops!')
            } else {
                validated = validated + 1
            }
            if (validated === 3) {
                return true
            }
        }
        if (key === 2) {
            if (newPassA !== newPassB) {
                $('#newPassA').addClass('border-danger-guay')
                $('#newPassB').addClass('border-danger-guay')
                toastr.error('Las contraseñas no coinciden.', '¡Ooops!')
            } else {
                validated_2 = validated_2 + 1
            }
            if (validated_2 === 1) {
                return true
            }
        }
    }

    const getRandomArbitrary = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min
    }

    const handleOnSubmit = e => {
        e.preventDefault()
        let validation_1 = validationPassword(1)
        let validation_2 = validationPassword(2)
        if (validation_1 === true) {
            if (validation_2 === true) {
                if (correctPassword) {
                    const saltValueNewA = 'Z3VheUE='
                    const saltValueNewB = 'Z3VheUI='
                    const saltValueOld = 'Z3VheQ=='

                    const saltfinishOld = 'bWUgc2llbnRvIGd1YXk='
                    const saltfinishA = 'bWUgc2llbnRvIGd1YXkgQQ=='
                    const saltfinishB = 'bWUgc2llbnRvIGd1YXkgQg=='

                    const randomNumOld = getRandomArbitrary(10, 100)
                    const randomNumA = getRandomArbitrary(10, 100)
                    const randomNumB = getRandomArbitrary(10, 100)

                    let password_1 = saltValueOld + randomNumOld + btoa(actualPass) + saltfinishOld
                    let password_2 = saltValueNewA + randomNumA + btoa(newPassA) + saltfinishA
                    let password_3 = saltValueNewB + randomNumB + btoa(newPassB) + saltfinishB

                    let data = {
                        actualPass: password_1,
                        newPassA: password_2,
                        newPassB: password_3,
                        userId: getJwt('jtw')
                    }
                    let formData = new FormData()
                    formData.append('request', JSON.stringify(data))
                    updNewPasswordUser(formData).then((response) => {
                        if (response.code === 1) {
                            toastr.success('Se actualizó la contraseña correctamente.', '¡Bien hecho!')
                            $('#actualPass, #newPassA, #newPassB').val('')
                        } else {
                            switch (response.code) {
                                case 1002:
                                    toastr.error('La contraseña actual no coincide.', '¡Ooops!')
                                    break
                                case 1003:
                                    $('#newPassA').addClass('border-danger-guay')
                                    $('#newPassB').addClass('border-danger-guay')
                                    toastr.error('Las contraseñas no coinciden.', '¡Ooops!')
                                    break
                                default:
                                    toastr.error('No se actualizó la contraseña correctamente.', '¡Ooops!')
                                    break
                            }
                        }
                    })
                } else {
                    toastr.warning('La contraseña no tiene el formato correcto.', '¡Ooops!')
                }
            }
        }
    }
    const seePassword = e => {
        let inputSelected = $('#actualPass')
        if (e.target.id === 'icon_eye_actPass') {
            if (inputSelected.attr('type') === 'password') {
                inputSelected.attr('type', 'text')
                $('#icon_eye_actPass').attr('class', 'ft-eye-off')
            } else {
                inputSelected.attr('type', 'password')
                $('#icon_eye_actPass').attr('class', 'ft-eye')
            }
        }
        if (e.target.id === 'icon_eye_A') {
            inputSelected = $('#newPassA')
            if (inputSelected.attr('type') === 'password') {
                inputSelected.attr('type', 'text')
                $('#icon_eye_A').attr('class', 'ft-eye-off')
            } else {
                inputSelected.attr('type', 'password')
                $('#icon_eye_A').attr('class', 'ft-eye')
            }
        }
        if (e.target.id === 'icon_eye_B') {
            inputSelected = $('#newPassB')
            if (inputSelected.attr('type') === 'password') {
                inputSelected.attr('type', 'text')
                $('#icon_eye_B').attr('class', 'ft-eye-off')
            } else {
                inputSelected.attr('type', 'password')
                $('#icon_eye_B').attr('class', 'ft-eye')
            }
        }
    }
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='col-12'>
                    <p className='font-weight-bold font-medium-5'>Seguridad</p>
                </div>
            </div>
            <div className='col-sx-12 col-sm-12 col-md-12 col-lg-8 col-xxl-6 mx-auto'>
                <form
                    onSubmit={handleOnSubmit}
                    autoComplete='off'
                >
                    <div className='form-group'>
                        <label htmlFor='actualPass'>Contraseña Actual:</label>
                        <div className='input-group'>
                            <input
                                type='password'
                                className='form-control border-0'
                                id='actualPass'
                                name='actualPass'
                                maxLength='100'
                                onChange={handleOnChange}
                                autoComplete='off'
                                style={{ backgroundColor: '#f3f2f8' }}
                            />
                            <div className='input-group-prepend'>
                                <span
                                    className='input-group-text icon-eye-form bg-light-gray-giki border-0'
                                    onClick={seePassword}
                                >
                                    <i
                                        className='ft-eye'
                                        id='icon_eye_actPass'
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='newPassA'>Nueva Contraseña:</label>
                        <div className='col-12'>
                            <p>Requisitos:</p>
                            <div className='row'>
                                <div className='col'>
                                    <ul>
                                        <li><span className={validation4 ? ('text-green font-weight-bold') : ('text-base')}> 6 o más dígitos</span></li>
                                        <li><span className={validation2 ? ('text-green font-weight-bold') : ('text-base')}>1 Mayúscula</span></li>
                                        <li><span className={validation6 ? ('text-green font-weight-bold') : ('text-base')}>1 Minúscula</span></li>
                                    </ul>
                                </div>
                                <div className='col'>
                                    <ul>
                                        <li><span className={validation3 ? ('text-green font-weight-bold') : ('text-base')}>1 Número</span></li>
                                        <li><span className={validation1 ? ('text-green font-weight-bold') : ('text-base')}>1 Símbolo (!@#$%^&*_-.)</span></li>
                                        <li><span className={validation5 === false ? ('text-green font-weight-bold') : ('text-base')}>Sin espacios</span></li>
                                    </ul>
                                </div>
                            </div>
                            <p className='text-base'>Ejemplo: Hol@123</p>
                        </div>
                        <div className='input-group'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text bg-light-gray-giki border-0 text-base'><i className='la la-lock' /></span>
                            </div>
                            <input
                                type='password'
                                className='form-control border-0'
                                name='newPassA'
                                id='newPassA'
                                maxLength='100'
                                onChange={handleOnChange}
                                autoComplete='off'
                                style={{ backgroundColor: '#f3f2f8' }}
                            />
                            <div className='input-group-prepend'>
                                <span
                                    className='input-group-text icon-eye-form bg-light-gray-giki border-0'
                                    onClick={seePassword}
                                >
                                    <i
                                        className='ft-eye'
                                        id='icon_eye_A'
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='newPassB'>Confirma tu Contraseña:</label>
                        <div className='input-group'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text bg-light-gray-giki border-0 text-base'><i className='la la-lock' /></span>
                            </div>
                            <input
                                type='password'
                                className='form-control border-0'
                                name='newPassB'
                                id='newPassB'
                                maxLength='100'
                                onChange={handleOnChange}
                                autoComplete='off'
                                style={{ backgroundColor: '#f3f2f8' }}
                            />
                            <div className='input-group-prepend'>
                                <span
                                    className='input-group-text icon-eye-form bg-light-gray-giki border-0'
                                    onClick={seePassword}
                                >
                                    <i
                                        className='ft-eye'
                                        id='icon_eye_B'
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button
                                type='button'
                                className='btn btn-sm btn-save-blue'
                                onClick={() => setShowForm(0)}
                            >Regresar</button>
                        </div>
                        <div className='col'>
                            <button
                                type='submit'
                                className='btn btn-sm btn-pink float-right'
                            >Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SecurityForm
