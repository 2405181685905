import React from 'react'

const SeccionOlvidar = () => {
  return (
    <>
        <div className='row'>
            <div className='col-12  col-md-4 col-lg-4 d-flex align-items-center justify-content-center mt-2 mt-md-2 bg-blue-navy'>
                <h4 className='font-weight-bold text-white text-center fs-1-25 py-1 m-0'>¡No olvides!</h4>
            </div>
        </div>
        <div className='row justify-content-center flex-wrap align-content-center my-2 pb-3'>
            <div className='mb-1 col-5 col-md-2 col-lg-2 '>
                <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                    <img className="img-responsive w-50 pb-1" src="./../img/beneficios/Icono_atencion.png" alt="img-atencion" />
                    <p className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Atención telefónica</p>
                </div>
            </div>
            <div className='mb-1 col-5 col-md-2 col-lg-2'>
                <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                    <img className="img-responsive w-50 py-1" src="./../img/beneficios/Icono_reembolso.png" alt="img-cobertura-mobile" />
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Reembolso en ciudades donde no hay cobertura</h4>
                </div>
            </div>
            <div className='mb-3 col-5 col-md-2 col-lg-2'>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Tratamientos garantizados</h4>
                </div>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Sin deducibles</h4>
                </div>
            </div>
            <div className='mb-3 col-5 col-md-2 col-lg-2'>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Preexistencias cubiertas*</h4>
                </div>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Sin periodos de espera</h4>
                </div>
            </div>
            <div className='mb-1 col-5 col-md-2 col-lg-2'>
                <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                    <img className="img-responsive w-50 py-1" src="./../img/beneficios/Icono_edad.png" alt="img-cobertura-mobile" />
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Sin límites de edad</h4>
                </div>
            </div>
            <div className='mb-1 col-5 col-md-2 col-lg-2'>
                <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                    <img className="img-responsive w-50 py-1" src="./../img/beneficios/Icono_precio.png" alt="img-precio-mobile"/>
                    <h4 className='fs-875 text-blue-sec font-weight-normal text-center m-0'>Precio preferencial en tratamientos no cubiertos</h4>
                </div>
            </div>
            
        </div>
    </>
  )
}

export default SeccionOlvidar
