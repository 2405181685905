import React, { useState, useEffect, Fragment } from 'react';
import Header from './headerColaborador';
import Footer from '../footerApp';
import { redirectIfNotAuthenticated, getJwt, setJwt } from "../../../lib/auth";
import $ from "jquery";
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getTutorials, setViewedTutorial, getuserData } from "./Model";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
//import { updProfilebyUser } from '../profile/Model';
/* import ProfileModal from '../profile/ProfileModal'; */
import GenInfoModal from './profile/GenInfoModal';
import MainpageCarousel from './MainpageCarousel';
import { getProductsFree, getProductsUser } from '../Models/Users_model';
import { useHistory } from "react-router-dom";
import { getAllResourcesEnterprise } from '../Models/GuaykipediaModel';
import Menu from '../Menu';
import { getAllPromotions } from '../Models/SponsorModel';
import HelperApp from '../HelperApp';

const InicioUsuarioGenerico = () => {

    const [data, updateData] = useState([]);
    const [generalData, updateGeneralData] = useState([]);
    /* const [setData, updateSetData] = useState({
        idProfile: 0,
        idUser: getJwt("jtw"),
        name: "",
        lastName: "",
        birthday: "",
        stateId: 0,
        townId: 0,
        neighborhoodId: 0,
        zipCode: "",
        phone: "",
        mainMail: "",
        altMail: ""
    }); */
    //const { name, lastName, birthday, stateId, townId, zipCode, phone, mainMail, altMail } = setData;
    //const [files, updateFiles] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [sliderInner, setSliderInner] = useState([]);
    const [sliderPage, setSliderPage] = useState(0);
    const history = useHistory();
    const [enterpriseData, updateEnterpriseData] = useState([]);
    const [showBtns, setShowBtns] = useState(false);
    const arrCirclesImg = [
        {
            imgCircle:"./../img/inicioColaborador/circulo_verde_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_rosa_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_azul_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_amarillo_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_rosa_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_azul_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_amarillo_arriba.png",
            imgStyle:{position:'absolute', right:'0px', top:'0px',width:'50px'}
        },
        {
            imgCircle:"./../img/inicioColaborador/circulo_verde_abajo.png",
            imgStyle:{position:'absolute', right:'0px', bottom:'0px',width:'50px'}
        }
    ];
    useEffect(() => {
        try {
            getuserData(getJwt("jtw")).then((response) => {
                if (response) {
                    if (response.success === true) {
                        updateGeneralData(response);
                        updateData(response);
                        /* updateSetData({
                            ...setData,
                            mainMail: response.correo,
                            name: response.name,
                            lastName: response.lastName
                        }); */
                        updateEnterpriseData(response.enterprise);
                        let products = [];
                        let webinar = {
                            idProduct: 0,
                            idCategory: 4,
                            idSubcategory: 4,
                            productType: 3,
                            imgCategory: "./../img/Elemento_43.png",
                            nameCategory: "Webinars",
                            descCategory: "Conoce sobre diversos temas de bienestar, salud física y emocional.",
                        };
                        products.push(webinar);
                        getProductsFree(0, response.token).then((response) => {
                            for (let x in response.listProducts) {
                                if (getJwt("enterprise") === undefined || getJwt("enterprise") === 'undefined') {
                                    let id = `${response.listProducts[x].idProduct}-${response.listProducts[x].idCategory}-${response.listProducts[x].idSubcategory}`;
                                    switch (id) {
                                        case '1-1-0':
                                            products.push({ ...response.listProducts[x], productType: 1 });
                                            break;
                                        case '1-4-0':

                                            break;
                                        default:
                                            products.push({ ...response.listProducts[x], productType: 1 });
                                            break;
                                    }
                                } else {
                                    products.push({ ...response.listProducts[x], productType: 1 });
                                }
                            }
                            getProductsUser(getJwt("jtw")).then((response) => {
                                for (let x in response.listProducts) {
                                    if (response.listProducts[x].acquired !== 0) {
                                        products.push({ ...response.listProducts[x], productType: 2 });
                                    }
                                }
                                let array = [];
                                let array_2 = [];
                                let arrayAux = [];
                                let arrayCompare = [];
                                if (getJwt('enterprise') === '84' ||
                                    getJwt('enterprise') === '85' ||
                                    getJwt('enterprise') === '86'
                                ) {
                                    getAllPromotions(0).then(response => {
                                        if (response) {
                                            if (response.code === 1) {
                                                let data = {
                                                    idProduct: 0,
                                                    idCategory: 5,
                                                    idSubcategory: 5,
                                                    productType: 3,
                                                    imgCategory: "./../img/inicioColaborador/iconos_49.png",
                                                    nameCategory: "Promociones",
                                                    descCategory: "Promociones para ti.",
                                                };
                                                products.push(data);
                                                for (let x in products) {
                                                    if (array.length < 3) {
                                                        if (array_2.length > 0) {
                                                            array.push(array_2[0], products[x]);
                                                            array_2 = [];
                                                        } else {
                                                            array.push(products[x]);
                                                            array_2 = [];
                                                        }
                                                    } else {
                                                        arrayAux.push(array);
                                                        array_2.push(products[x]);
                                                        array = [];
                                                    }
                                                }
                                                for (let y in arrayAux) {
                                                    for (let x in arrayAux[y]) {
                                                        arrayCompare.push(arrayAux[y][x]);
                                                    }
                                                }
                                                arrayAux.push(products.filter(elemento => arrayCompare.indexOf(elemento) === -1));
                                                // setSliderData(arrayAux);
                                                // setSliderInner(arrayAux[0]);
                                                let arrayAux2 = []
                                                let counter = 0
                                                for (let x in arrayAux ) {
                                                    if(counter === (arrCirclesImg.length)){
                                                        arrayAux2.push({...arrayAux[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                                        counter = 1
                                                    }else{
                                                        arrayAux2.push({...arrayAux[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                                        counter++
                                                    }
                                                }
                                                setSliderData(arrayAux2);
                                                setSliderInner(arrayAux2[0]);
                                                if (arrayAux.length > 1) {
                                                    setShowBtns(true);
                                                }
                                            } else {
                                                for (let x in products) {
                                                    if (array.length < 3) {
                                                        if (array_2.length > 0) {
                                                            array.push(array_2[0], products[x]);
                                                            array_2 = [];
                                                        } else {
                                                            array.push(products[x]);
                                                            array_2 = [];
                                                        }
                                                    } else {
                                                        arrayAux.push(array);
                                                        array_2.push(products[x]);
                                                        array = [];
                                                    }
                                                }
                                                for (let y in arrayAux) {
                                                    for (let x in arrayAux[y]) {
                                                        arrayCompare.push(arrayAux[y][x]);
                                                    }
                                                }
                                                arrayAux.push(products.filter(elemento => arrayCompare.indexOf(elemento) === -1));
                                                // setSliderData(arrayAux);
                                                // setSliderInner(arrayAux[0]);
                                                let arrayAux2 = []
                                                let counter = 0
                                                for (let x in arrayAux ) {
                                                    if(counter === (arrCirclesImg.length)){
                                                        arrayAux2.push({...arrayAux[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                                        counter = 1
                                                    }else{
                                                        arrayAux2.push({...arrayAux[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                                        counter++
                                                    }
                                                }
                                                setSliderData(arrayAux2);
                                                setSliderInner(arrayAux2[0]);
                                                if (arrayAux.length > 1) {
                                                    setShowBtns(true);
                                                }
                                            }
                                        }
                                    });
                                } else {
                                    let arrProducts = []
                                    let counter = 0
                                    for (let x in products ) {
                                        if(counter === (arrCirclesImg.length)){
                                            arrProducts.push({...products[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                                            counter = 1
                                        }else{
                                            arrProducts.push({...products[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                                            counter++
                                        }
                                    }
                                    for (let x in arrProducts) {
                                        if (array.length < 3) {
                                            if (array_2.length > 0) {
                                                array.push(array_2[0], arrProducts[x]);
                                                array_2 = [];
                                            } else {
                                                array.push(arrProducts[x]);
                                                array_2 = [];
                                            }
                                        } else {
                                            arrayAux.push(array);
                                            array_2.push(arrProducts[x]);
                                            array = [];
                                        }
                                    }
                                    for (let y in arrayAux) {
                                        for (let x in arrayAux[y]) {
                                            arrayCompare.push(arrayAux[y][x]);
                                        }
                                    }
                                    arrayAux.push(arrProducts.filter(elemento => arrayCompare.indexOf(elemento) === -1));
                                    setSliderData(arrayAux);
                                    setSliderInner(arrayAux[0]);
                                    if (arrayAux.length > 1) {
                                        setShowBtns(true);
                                    }
                                }
                            });
                        });
                    }
                }
            });
            getTutorials(getJwt("jtw"), 2).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        $('#tourModal').show();
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, []);

    const sliderFunction = (indicator) => {
        let page;
        let arrayNum = sliderData.length - 1;
        if (indicator === 1) {
            page = sliderPage + 1;
            if (page <= arrayNum) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        } else {
            page = sliderPage - 1;
            if (page >= 0) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        }
    }

    const steps = [
        {
            selector: '[data-tut="tourColaborador-step-1"]',
            content: () => (
                <div>
                    <p>Da click en nuestro logo para regresar a la Pantalla de Inicio</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        /* {
            selector: '[data-tut="tourColaborador-step-2"]',
            content: () => (
                <div>
                    <p>Conoce nuestros productos entrando en la tienda guay</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }, */
        {
            selector: '[data-tut="tourColaborador-step-4"]',
            content: () => (
                <div>
                    <p>Administra tu perfil y cuentas</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        /* {
            selector: '[data-tut="tourColaborador-step-5"]',
            content: () => (
                <div>
                    <p>Aquí encontrarás tus alertas y comunicación</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }, */
        {
            selector: '[data-tut="tourColaborador-step-6"]',
            content: () => (
                <div>
                    <p>¡Conoce más! Entérete de temas relevantes e información general</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-7"]',
            content: () => (
                <div>
                    <p>Encuentra tus servicios y beneficios guay</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        },
        {
            selector: '[data-tut="tourColaborador-step-8"]',
            content: () => (
                <div>
                    <p>Cuenta con nosotros para resolver tus dudas e inquietudes</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center',
                padding: '40px'
            }
        }
    ];

    const [isTourOpen, setIsTourOpen] = useState(false);

    const startIntro = () => {
        try {
            $("#tourModal").hide();
            setIsTourOpen(true);
        } catch (error) {
            console.log(error);
        }
    }

    const noIntro = () => {
        try {
            $("#tourModal").hide();
            $("#modal-1").show();
            enableBodyScroll(true);
            setViewedTutorial(getJwt("jtw"), 2);
        } catch (error) {
            console.log(error);
        }
    }

    const endIntro = () => {
        try {
            $("#modal-1").show();
            enableBodyScroll(true);
            setViewedTutorial(getJwt("jtw"), 2);
        } catch (error) {
            console.log(error);
        }
    }

    /* const renderFilePers = () => {
        $("#file").click();
    } */

    /* const onChangeHandler = (e) => {
        try {
            let fileTypes = [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff"
            ]
            let correct = false;
            let reader = new FileReader();
            let file = e.target.files[0];
            for (let x in fileTypes) {
                if (fileTypes[x] === e.target.files[0].type) {
                    correct = true;
                }
            }
            if (correct === true) {
                reader.onload = function (e) {
                    $('#img-person').attr('src', e.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                updateFiles({
                    ...files,
                    file
                });
            } else {
                toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!');
            }
        } catch (error) {
            console.log(error);
        }
    } */

    /* const validationData = () => {
        let validated = 0;
        if (name === "") {
            $("#name").addClass("border-danger-guay");
            toastr.error('Falta llenar el campo: Nombre', '¡Ooops!');
        } else {
            validated = validated + 1;
        }
        if (lastName === "") {
            $("#lastName").addClass("border-danger-guay");
            toastr.error('Falta llenar el campo: Apellidos', '¡Ooops!');
        } else {
            validated = validated + 1;
        }
        if (mainMail === "") {
            $("#mainMail").addClass("border-danger-guay");
            toastr.error('Falta llenar el campo: Correo de contacto', '¡Ooops!');
        } else {
            validated = validated + 1;
        }
        if (validated === 3) {
            return true;
        }
    } */

    /* const setProfileData = (e) => {
        e.preventDefault();
        try {
            let validated = validationData();
            if (validated === true) {
                let data = {
                    ...setData,
                    name: name === "" ? "" : name,
                    lastName: lastName === "" ? "" : lastName,
                    birthday: birthday === "" ? "" : setData.birthday.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1'),
                    stateId: zipCode === "" || zipCode < 5 ? 0 : stateId,
                    townId: zipCode === "" || zipCode < 5 ? 0 : townId,
                    neighborhoodId: zipCode === "" || zipCode < 5 ? 0 : parseInt(setData.neighborhoodId),
                    zipCode: zipCode === "" || zipCode < 5 ? 0 : parseInt(setData.zipCode),
                    phone: phone === "" ? "" : phone,
                    mainMail: mainMail === "" ? "" : mainMail,
                    altMail: altMail === "" ? "" : altMail
                }
                let formData = new FormData();
                formData.append("file", files.file);
                formData.append("request", JSON.stringify(data));
                updProfilebyUser(formData).then((response) => {
                    if (response.code === 1) {
                        $('#modal-2').hide();
                        toastr.success('Se registró la información correctamente.', '¡Bien hecho!');
                        let userData = {
                            id: generalData.token,
                            enterprise: getJwt("enterprise"),
                            rol: getJwt("rol"),
                            help: generalData.help,
                            tkc: generalData.tk,
                            email: generalData.correo,
                            session: generalData.session,
                            enterpriseName: name,
                            enterpriseInitials: getJwt("enterpriseInitials"),
                            userName: generalData.name
                        }
                        setJwt(userData);
                        window.location.reload();
                    } else {
                        $('#modal-2').hide();
                        toastr.error('No se registró la información correctamente.', '¡Ooops!');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    } */

    const handleServicesActions = (productType, idProduct, idCategory, idSubcategory) => {
        let id = "";
        if (productType === 1) {
            id = "F-" + idProduct + "-" + idCategory + "-" + idSubcategory + "";
        }
        if (productType === 2) {
            id = "P-" + idProduct + "-" + idCategory + "-" + idSubcategory + "";
        }
        if (productType === 3) {
            id = "E-" + idProduct + "-" + idCategory + "-" + idSubcategory + "";
        }
        console.log(id)
        switch (id) {
            case "F-1-1-0":
                getAllResourcesEnterprise(getJwt("enterprise")).then((response) => {
                    if (response) {
                        if (response.code === 1) {
                            let adquired = 0;
                            for (let x in response.listProducts) {
                                for (let y in response.listProducts[x].listCategorys) {
                                    if (response.listProducts[x].listCategorys[y].listSubCategorys !== undefined) {
                                        for (let z in response.listProducts[x].listCategorys[y].listSubCategorys) {
                                            if (response.listProducts[x].listCategorys[y].listSubCategorys[z].adquired) {
                                                adquired = adquired + 1;
                                            }
                                        }
                                    }
                                    if (response.listProducts[x].listCategorys[y].adquired !== undefined) {
                                        adquired = adquired + 1;
                                    }
                                }
                            }
                            if (adquired === 0) {
                                toastr.info('No tienes productos adquiridos.', '¡Hola ' + data.name + '!');
                            } else {
                                if (data.name === getJwt("enterpriseName")) {
                                    let userData = {
                                        id: generalData.token,
                                        enterprise: enterpriseData[0].enterpriseId,
                                        rol: enterpriseData[0].rolEnterpriseId,
                                        help: generalData.help,
                                        tkc: generalData.tk,
                                        email: generalData.correo,
                                        session: generalData.session,
                                        enterpriseName: enterpriseData[0].name,
                                        enterpriseInitials: enterpriseData[0].initials,
                                        userName: generalData.name
                                    }
                                    setJwt(userData);
                                    history.push("/guaykipedia");
                                } else {
                                    history.push("/guaykipedia");
                                }
                            }
                        } else {
                            toastr.info('No tienes productos adquiridos.', '¡Hola ' + data.name + '!');
                        }
                    }
                });
                break;
            case "F-2-2-0":
                toastr.info('El Perfil de personalidad está por liberarse... ¡espéralo muy pronto!', '¡Hola ' + data.name + '!');
                break;
            case "F-1-4-0":
                history.push("/beneficios");
                break;
            case "E-0-4-4":
                history.push("/webinar");
                break;
            case "E-0-5-5":
                history.push("/promociones");
                break;
            default:
                break;
        }
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content new-guay-plataform bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <Tour
                        steps={steps}
                        isOpen={isTourOpen}
                        onRequestClose={() => setIsTourOpen(false)}
                        onAfterOpen={() => disableBodyScroll(true)}
                        onBeforeClose={() => endIntro()}
                    />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            <div className="container-fluid font-quicksand">
                                <div className="row">
                                    <HelperApp />
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                        <MainpageCarousel handleServicesActions={handleServicesActions}/>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2" data-tut="tourColaborador-step-7">
                                            <div className="row px-1">
                                                {
                                                    sliderInner.map((data,index) =>
                                                        <div className="col-sm-12 col-md-6 col-lg-3 col-xxl-3 mb-1" id={data.idProduct + "-" + data.idCategory} key={index}>
                                                            <div className="card h-100 div-shadow-style-1 pointer" onClick={() => handleServicesActions(data.productType, data.idProduct, data.idCategory, data.idSubcategory)}>
                                                                <div className="card-body py-0 d-flex flex-wrap align-content-center">
                                                                    <img
                                                                        src={data.imgCircle}
                                                                        alt={data.nameCategory}
                                                                        style={data.imgCircleStyle}
                                                                        // style={(index % 2) == 0 ? {position:'absolute', right:'0px', top:'0px',width:'50px'} : {position:'absolute', right:'0px', bottom:'0px',width:'50px'}}
                                                                        />
                                                                    <div className="row h-100">
                                                                        <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center justify-content-center p-0">
                                                                            <img className="img-card-responsive" src={data.imgCategory} alt="news" />
                                                                        </div>
                                                                        <div className="col-sx-12 col-sm-12 col-md-8 col-lg-8 pt-1 d-flex flex-wrap align-content-center text-justify">
                                                                            <p className='fs-1-563 font-weight-bold text-blue-sec '>{data.nameCategory}</p>
                                                                            <p className='fs-875 text-blue-sec '>{data.descCategory}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            {
                                                showBtns === true ? (
                                                    <Fragment>
                                                        <button
                                                            className="carousel-control-prev carousel-a-style-2 border-0 pointer"
                                                            data-slide="prev"
                                                            onClick={() => sliderFunction(0)}>
                                                            <span className="carousel-control-prev-icon carousel-span-style-3"></span>
                                                        </button>
                                                        <button
                                                            className="carousel-control-next carousel-a-style-2 border-0 pointer"
                                                            data-slide="next"
                                                            onClick={() => sliderFunction(1)}>
                                                            <span
                                                                className="carousel-control-next-icon carousel-span-style-4"></span>
                                                        </button>
                                                    </Fragment>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                            {/*MODAL*/}
                            {/*<!-- Tutorial modal -->*/}
                            <div className="modal" id="tourModal" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content" style={{ border: 'none', background: 'transparent' }}>

                                        {/*<!-- Modal body -->*/}
                                        <div className="modal-body">
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-5 mx-auto">
                                                <div className="card img-fluid" style={{ background: 'transparent', boxShadow: 'none' }}>
                                                    <img className="card-img-top" src="./../img/imagesAdminPanel/iconos_guay-57.png" alt="guay-digital" />
                                                    <div className="card-img-overlay">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <p className="font-medium-1 text-center text-white ">¡Bienvenido a guay!</p>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <p className="font-medium-1 text-center text-white ">Acompañanos a conocer nuestra plataforma</p>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 d-flex justify-content-around">
                                                            <span className="float-left btn-text-1" onClick={noIntro} >Omitir este paso</span>
                                                            <span className="float-right btn-text-2 font-weight-bold" onClick={startIntro} >Siguiente</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<!-- Complete information modal -->*/}
                            <div className="modal" id="modal-1" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                        {/*<!-- Modal body -->*/}
                                        <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sx-3 col-sm-3 col-md-3 col-lg-3">
                                                        <img className="img-responsive" src="./../img/imagesProfile/iconos_guay-54.png" alt="guay-digital" />
                                                    </div>
                                                    <div className="col-sx-7 col-sm-7 col-md-7 col-lg-7 d-flex justify-content-center flex-wrap align-content-center">
                                                        <p className="text-blue text-center font-medium-5">Para brindarte una mejor experiencia completa la información de tu perfil de usuario</p>
                                                        <button className="btn btn-blue-2 btn-sm mb-1 pl-5 pr-5 btn-align-center" style={{ bottom: '0' }} id="close-1" onClick={() => { $('#modal-1').hide(); /* $('#modal-2').show(); */ $('#profileModal').show() }}>Continuar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*MODAL*/}
                            {/*<!-- Profile edit modal -->*/}
                            <GenInfoModal />
                            {/* <ProfileModal
                                setProfileData={setProfileData}
                                renderFilePers={renderFilePers}
                                onChangeHandler={onChangeHandler}
                                updateSetData={updateSetData}
                                setData={setData}
                                imgProfile=""
                            /> */}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default InicioUsuarioGenerico;