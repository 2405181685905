import React from 'react';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// API calls
import { valAssistanceConsent } from '../../Models/AssistanceModel';

const StatisticalFilterNoConcent = ({
    idCollab,
    idEnterprise,
    idGroup,
    setNoConsent,
    setShowSurvey,
    isPregnant,
    idPeriod
}) => {
    /**
         * 
         * @param {event} e 
         */
    const handleNoConsent = e => {
        e.preventDefault();
        let name = e.target.name;
        if (name === "no") {
            valAssistanceConsent(idCollab, idEnterprise, idGroup, idPeriod, 0, isPregnant).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            $('#wellcomeMessage').hide();
                            $('#noConcent').hide();
                            setNoConsent(true);
                            setShowSurvey(false);
                            break;
                        default:
                            toastr.error("Ocurrió un problema. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                            break;
                    }
                } else {
                    toastr.error("Ocurrió un problema. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                }
            });
        } else {
            valAssistanceConsent(idCollab, idEnterprise, idGroup, idPeriod, 1, isPregnant).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            $('#wellcomeMessage').hide();
                            $('#noConcent').hide();
                            break;
                        default:
                            toastr.error("Ocurrió un problema. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                            break;
                    }
                } else {
                    toastr.error("Ocurrió un problema. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                }
            });
        }
    }
    return (
        <div
            className="modal"
            id="noConcent"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered">
                <div
                    className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body modal-div-bodered container-style-1 pb-2">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <h1 className="font-weight-bold">Introducción Encuesta</h1>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center">
                            <span>¿Estás seguro de cancelar tu participación en el Programa de Asistencia guay?</span>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            <button
                                className="btn btn-sm btn-blue-3 font-medium-1"
                                name="no"
                                onClick={handleNoConsent}>No deseo participar</button>
                            <button
                                className="btn btn-sm btn-blue-3 float-right font-medium-1"
                                name="yes"
                                onClick={handleNoConsent}>Si deseo participar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticalFilterNoConcent;
