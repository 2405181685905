import React, { useState } from 'react';
import { getDay, addDays } from 'date-fns';
import Datepicker from 'react-datepicker';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { convertDateToYYYYMMDD, convertStringToDate } from '../../../helpers/helpers';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { setCalendarByLaunch } from '../../Models/Launch_model';

const TableCommunicationLaunchDate = ({
    tableCommData,
    serviceSelect,
    updParams,
    enterpriseId,
    idQuizEnterprise
}) => {

    const today = new Date();
    const [centros, setcentros] = useState(tableCommData.listBranch);

    const obj = serviceSelect.split(",");
    const idcat = obj[0];
    const idsub = obj[1];
    const idprod = obj[2];

    const setDate = (idBranch, idGroup, date) => {
        setCalendarByLaunch(tableCommData.idEnterprise, idGroup, idprod, idcat, idsub, tableCommData.type_launch, convertDateToYYYYMMDD(date), idQuizEnterprise).then((response) => {
            switch (response.code) {
                case 1:
                    updParams({
                        idEnterprise: tableCommData.idEnterprise,
                        idCategorie: idcat,
                        idGroup: idGroup,
                        status: 2
                    });
                    break;

                default:
                    toastr.error('Ocurrió un error inesperado, verifique su conexión a internet y vuelva a intentarlo.');
                    break;
            }
        });
        const arras = centros.map(centro => {
            if (centro.idBranch === idBranch) {
                return { ...centro, comunicationLaunch: convertDateToYYYYMMDD(date) }
            } else {
                return { ...centro }
            }
        })
        setcentros(arras);
    }

    const dateFormat = (cell, _row, _data1, _index) => {
        if (cell === '0' || cell === '1969-12-31') { //launchDate en '0' no se ha programado fecha de lanzamiento
            return (
                <Popup trigger={<i className="ft-alert-triangle icon-size-small text-yellow pointer align-self-center mx-1" />} position="bottom">
                    <div className="p-1 text-center">
                        La fecha de lanzamiento no fue programada, diríjase al modulo de "Lanzamientos" y programe una fecha para este centro de trabajo.
                    </div>
                </Popup>
            )
        } else {
            const stringDate = cell.split('-');
            return (
                `${stringDate[2]}-${stringDate[1]}-${stringDate[0]}`
            )
        }
    }

    const datepickerFormat = (_cell, row, _data1, index) => {
        if (centros[index].comunicationLaunch === undefined) { //undefined communicationLaunch no se ha programado fecha de comunicacion
            return (
                <Datepicker
                    dateFormat="dd-MM-yyyy"
                    onChange={date => setDate(row.idBranch, row.idGroup, date)}
                    filterDate={isWeekday}
                    excludeDates={today.getDay() === 4 ? ([addDays(new Date(), 1), addDays(new Date(), 4)]) : (today.getDay() === 5 ? ([addDays(new Date(), 3), addDays(new Date(), 4)]) : ([addDays(new Date(), 1), addDays(new Date(), 2)]))}
                    placeholderText="Selecciona una fecha"
                    disabled={canEditDate(centros[index].edit, centros[index].idGroup)}
                    onKeyDown={(e) => handleKeydown(e)}
                    className="form-control py-0 height-sm-1"
                />
            )
        } else {
            return (
                <Datepicker
                    dateFormat="dd-MM-yyyy"
                    selected={convertStringToDate(centros[index].comunicationLaunch)}
                    onChange={date => setDate(row.idBranch, row.idGroup, date)}
                    filterDate={isWeekday}
                    excludeDates={today.getDay() === 4 ? ([addDays(new Date(), 1), addDays(new Date(), 4)]) : (today.getDay() === 5 ? ([addDays(new Date(), 3), addDays(new Date(), 4)]) : ([addDays(new Date(), 1), addDays(new Date(), 2)]))}
                    placeholderText="Selecciona una fecha"
                    disabled={canEditDate(centros[index].edit, centros[index].idGroup)}
                    onKeyDown={(e) => handleKeydown(e)}
                    className="form-control py-0 height-sm-1"
                />
            )
        }
    }

    /* table validation functions and functionality options */

    const canEditDate = (edit, idGroup) => {
        if (edit === 1) { //edit 1 = puede editar
            if (idGroup === 0) { //idGroup 0 = no puede editar
                return true;
            }
            return false;
        } else { //edit 0 = no puede editar
            return true;
        }
    }

    const isWeekday = date => {
        const currentDate = new Date();
        const selectedDate = new Date(date);
        const day = getDay(date);
        return day !== 0 && day !== 6 && selectedDate > currentDate;
    };

    const handleKeydown = (e) => {
        e.preventDefault();
    }

    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        noDataText: 'No se encontraron registros.',
        onRowClick: function (row) {
            updParams({
                idEnterprise: enterpriseId,
                idCategorie: idcat,
                idGroup: row.idGroup,
                status: 2
            });
        },
    }

    return (
        <div className="row">
            <div className="col-12 table-responsive">
                <p className="font-medium-4 text-center text-gray mb-4">Configura la comunicación para cada centro de trabajo</p>
                <BootstrapTable
                    data={centros}
                    striped
                    hover
                    condensed
                    pagination={true}
                    tableStyle={{ border: 'none', padding: '0px', fontSize: '1.2rem' }}
                    trClassName='tr-data-table table-checkbox'
                    options={options}
                >
                    <TableHeaderColumn isKey dataField="idBranch" hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="description" dataAlign="left" width="25%" tdStyle={{ fontSize: '1.2rem' }}>Centro de trabajo</TableHeaderColumn>
                    <TableHeaderColumn dataField="launchDate" dataAlign="center" width="20%" tdStyle={{ fontSize: '1.2rem' }} dataFormat={dateFormat}>Fecha de Lanzamiento</TableHeaderColumn>
                    <TableHeaderColumn dataField="comunicationLaunch" dataAlign="center" width="20%" tdStyle={{ fontSize: '1.2rem' }} dataFormat={datepickerFormat}>Fecha de Comunicación</TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    )
}

export default TableCommunicationLaunchDate