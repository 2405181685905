/*
 File Name: AssistanceModel.js
 Description: Manage assistance endpoint API Requests
 guay dev's team
*/
import axios from "axios";
/**
 * 
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessStatistics = async (collaboratorId, idEnterprise, idGroup) => {
    try {
        let result = await axios({
            url: global.base_url + "assistance/validationAccessStatistics?collaboratorId=" + collaboratorId + "&idEnterprise=" + idEnterprise + "&idGroup=" + idGroup + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {string} answers 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseStatistics = async (answers, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        answers: answers,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "assistance/saveResponseStatistics",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idPeriod 
 * @param {int} consent 
 * @param {int} pregnant 
 * @returns 
 */
export const valAssistanceConsent = async (collaboratorId, idEnterprise, idGroup, idPeriod, consent, pregnant) => {
    let request = {
        collaboratorId: collaboratorId,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idPeriod: idPeriod,
        consent: consent,
        pregnant: pregnant
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "assistance/valAssistanceConsent",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const updFinishSurveyStatistics = async (collaboratorId, idEnterprise, idGroup) => {
    try {
        let result = await axios({
            url: global.base_url + "assistance/updFinishSurveyStatistics?collaboratorId=" + collaboratorId + "&idEnterprise=" + idEnterprise + "&idGroup=" + idGroup + "",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para enviar datos de usuario que requieren informacion / soporte
 * @param {*} contact 
 * @param {*} email 
 * @param {*} phone 
 * @param {*} message 
 * @returns 
 */
export const setSupportUser = async (data) => {
    /* const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json"); */
    try {
        let result = await axios({
            url: global.base_url + "support/setSupportUser",
            method: 'POST',
            /* headers: myHeaders, */
            data,
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};