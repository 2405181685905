import React, { useState, useEffect } from "react";
import $ from "jquery";
import Swal from "sweetalert2";
import { postRequest } from "../../../lib/api/api";
// import { setJwt } from "../../../lib/auth";
import Recaptcha from "react-google-invisible-recaptcha";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
/*
    Activate users from shopify
*/
const ActivateUserShop = props => {
  const [validation1, setValidation1] = useState(false);
  const [validation2, setValidation2] = useState(false);
  const [validation3, setValidation3] = useState(false);
  const [validation4, setValidation4] = useState(false);
  const [validation5, setValidation5] = useState(false);
  const [validation6, setValidation6] = useState(false);
  const [generalValidation, setGeneralValidation] = useState(0);
  const [userData, upUserData] = useState([]);

  const [objValidate, updValidate] = useState({
    key: "",
    ckey: "",
    user: "",
    id: "",
  });
  // eslint-disable-next-line
  /* const [correctProcess, setCorrectProcess] = useState(false); */

  const getValue = (e) => {
    try {
      let value = e.target.value;
      $(".border-danger").removeClass("border-danger");
      /* let regexPass = /^[A-Za-z]\w{6,14}$/; */
      /* let regexPass = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/
      if (!regexPass.test(value)) {
        $("#" + e.target.name).removeClass("border-success");
        $("#" + e.target.name).addClass("border-danger");
        setCorrectProcess(false);
        return;
      } else {
        $("#" + e.target.name).removeClass("border-danger");
        $("#" + e.target.name).addClass("border-success");
        setCorrectProcess(true);
      } */
      if(e.target.name === 'ckey'){
        let counter = 0;
          let regexSpecial = /[!@#$%^&*._-]/
          let regexCapital = /[A-Z]/
          let regexNumber = /[0-9]/
          let regexLength = /().{6}/
          let regexWhitespace = /\s/
          let regexLower = /[a-z]/
          if (regexSpecial.test(value)) {
            setValidation1(true);
            counter++;
          } else {
            setValidation1(false);
          }
          if (regexCapital.test(value)) {
            setValidation2(true);
            counter++;
          } else {
            setValidation2(false);
          }
          if (regexNumber.test(value)) {
            setValidation3(true);
            counter++;
          } else {
            setValidation3(false);
          }
          if (regexLength.test(value)) {
            setValidation4(true);
            counter++;
          } else {
            setValidation4(false);
          }
          if (regexWhitespace.test(value)) {
            setValidation5(true);
          } else {
            setValidation5(false);
            counter++;
          }
          if (regexLower.test(value)) {
            setValidation6(true);
            counter++;
          } else {
            setValidation6(false);
          }
          if (counter === 6) {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            setGeneralValidation(1);
          } else {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            setGeneralValidation(0);
          }
      }
      updValidate({
        ...objValidate,
        [e.target.name]: value,
      });
      /* if (e.target.name === "key") {
        if (objValidate.ckey !== objValidate.key) {
          $(".alert").addClass("hidden");
        } else {

          $(".alert").removeClass("hidden");
        }
      } */
    } catch (error) {
      console.log(error);
    }
  }
  const setPass = (e) => {
    e.preventDefault();
    try {
      console.log(objValidate.ckey)
      if (objValidate.ckey.length === 0) {
        toastr.error('Debes de ingresar una contraseña.', '¡Ooops!');
        return;
      }
      if (objValidate.key.length === 0) {
        toastr.error('Debes de ingresar la confirmación de la contraseña.', '¡Ooops!');
        return;
      }
      /* let regexPass = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/ */
      /* if (!regexPass.test(objValidate.ckey)) { */
      if (generalValidation === 0) {
        $("#ckey").addClass("border-danger");
        Swal.fire({
          title: '<p class="text-white mb-2">!Atención!</p>',
          text: "¡Contraseña no válida! Recuerda utilizar más de 8 caracteres que incluya letras, números y símbolos.",
        }).then(() => {});
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        return;
      }
      /* if (!regexPass.test(objValidate.key)) {
        Swal.fire({
          title: '<p class="text-white mb-2">!Atención!</p>',
          text: "¡Contraseña no válida! Recuerda utilizar más de 8 caracteres que incluya letras, números y símbolos.",
        }).then(() => {});
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        $("#key").addClass("border-danger");
        return;
      } */
      $('#btn-send').text("").prop('disabled', true)
        .addClass("btn-guay-processing")
        .append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite infinite"></i> <span class="pl-1">Activando...</span>');
      
      if (objValidate.ckey === objValidate.key) {
        postRequest("/users/setPasswordUser", objValidate).then((result) => {
          if (!result.code) {
            // eslint-disable-next-line
            switch (result.code) {
              case 1010:
                toastr.warning("Ocurrió un problema al activar tu cuenta. Intenta de nuevo.");
                break;
              case 1011:
                toastr.warning("Ocurrió un problema al registrar la contraseña. Intenta de nuevo.");
                break;
            }
            $('#btn-send').text("").prop('disabled', false)
              .removeClass("btn-guay-processing")
              .append('Guardar y Salir');
          } else {
            $('#btn-send').text("").prop('disabled', false)
              .removeClass("btn-guay-processing")
              .append('Activado');
            /* setJwt(objValidate); */
            window.location = "/";
          }
        });
      } else {
        toastr.info("Las contraseñas no coinciden.");
        $('#btn-send').text("").prop('disabled', false)
          .removeClass("btn-guay-processing")
          .append('Guardar y Salir');
      }
    } catch (error) {
      $('#btn-send').text("").prop('disabled', false)
        .removeClass("btn-guay-processing")
        .append('Guardar y Salir');
      console.log(error);
    }
  }

  useEffect(() => {
    const getObject = props.match.params;
    let obj = getObject.db.split("=");
    postRequest("/users/data?user=" + atob(obj[1])).then((result) => {
      objValidate.user = atob(obj[1]);
      objValidate.id = atob(obj[1]);
      upUserData(result);
      if (result.status > 0) {
        Swal.fire({
          title: '<p class="text-white mb-2">!Espera!</p>',
          text: "El registro de la cuenta ya se ha completado, verifica la información.",
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          dangerMode: true
        }).then(() => {
          const hostname = ((window || {}).location || {}).hostname;
          window.location.href = "https://" + hostname + "";
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
      }
    });
    // eslint-disable-next-line
  }, []);

  $(".toggle-password").click(function () {

    $(this).toggleClass("ft-eye-off");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  const showPassword = (e) => {
    let id = e.target.id;
    switch (id) {
      case "showPassword_1":
        if ($("#ckey").attr("type") === "password") {
          $("#ckey").attr("type", "text");
          $("#" + id).removeClass("ft-eye");
          $("#" + id).addClass("ft-eye-off");
        } else {
          $("#ckey").attr("type", "password");
          $("#" + id).removeClass("ft-eye-off");
          $("#" + id).addClass("ft-eye");
        }
        break;
      case "showPassword_2":
        if ($("#key").attr("type") === "password") {
          $("#key").attr("type", "text");
          $("#" + id).removeClass("ft-eye");
          $("#" + id).addClass("ft-eye-off");
        } else {
          $("#key").attr("type", "password");
          $("#" + id).removeClass("ft-eye-off");
          $("#" + id).addClass("ft-eye");
        }
        break;
      default:
        $("#" + id).removeClass("ft-eye");
        break;
    }
  }

  return (
    <div className="app-content content bg-login-3">
      <div className="content-overlay">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="col-sx-10 col-sm-10 col-md-6 col-lg-3 mx-auto">
                  <div className="card mt-5">
                    <div className="card-body" style={{ padding: '0px' }}>
                      <div className="col-lg-12 d-flex justify-content-center header-black">
                        <p className="font-medium-5 text-center font-secondary align-middle pt-1">
                          Completa tu registro
                          <img
                            src="../../img/guay-logo.png"
                            alt="guay-digital"
                            style={{ height: '1.5rem' }}
                            className="ml-1"
                          />
                        </p>
                      </div>
                      <div className="col-lg-11 mx-auto mt-2">
                        <p className="text-base text-center font-secondary">Para iniciar tu experiencia en nuestra Plataforma ingresa una contraseña</p>
                      </div>
                      <div className="col-lg-11 mx-auto d-flex justify-content-center mt-2">
                        <span className="font-secondary">{userData.correo}</span>
                      </div>
                      <div className="col-lg-11 mx-auto mt-2">
                        <input
                          className="form-control mt-2 input-style-1"
                          type="password"
                          id="ckey"
                          name="ckey"
                          maxLength="20"
                          minLength="6"
                          onChange={getValue}
                          placeholder="Contraseña"
                        ></input>
                        <span id="showPassword_1" className="ft-eye field-icon pointer" onClick={showPassword}></span>
                        <input
                          className="form-control mt-2 input-style-1"
                          type="password"
                          id="key"
                          name="key"
                          maxLength="20"
                          minLength="6"
                          placeholder="Confirmar Contraseña"
                          onChange={getValue}
                        ></input>
                        <span id="showPassword_2" className="ft-eye field-icon pointer" onClick={showPassword}></span>
                        <div className="col-lg-11 mx-auto">
                          {/* <p className="font-small-2 text-center font-secondary text-base">Utiliza más de 8 carácteres, combinando letras, números y simbolos</p> */}
                          <p className="font-small-2 text-base text-center" style={{ fontFamily: "quicksand" }}>
                            <span className="font-weight-bold">Requisitos:</span> Utiliza más de
                            <span className={validation4 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}> 6 carácteres, </span>
                            <span className={validation2 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 mayúscula, </span>
                            <span className={validation6 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 minúscula, </span>
                            <span className={validation3 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 número, </span>
                            <span className={validation1 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 símbolo (!@#$%^&*_-.), </span>
                            <span className={validation5 === false ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>sin espacios</span>
                          </p>
                        </div>
                      </div>
                      <Recaptcha
                        sitekey="6Lf2KjIbAAAAAK-K2Cwtz7UkGf_ENb5B0dmrHFlx"
                        onResolved={() => console.log("Human detected.")}
                      />
                      <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mx-auto d-flex justify-content-center mt-2">
                        <button id="btn-send" className="btn btn-green btn-sm mx-auto font-secondary d-flex align-items-center" onClick={setPass}>Guardar y Salir</button>
                      </div>
                      <div className="col-lg-12 mt-2">
                        <div className="col-lg-10 mx-auto">
                          <p className="font-small-1 text-center font-secondary">Al crear una cuenta, aceptas las <span className="text-blue">Condiciones de Uso</span> y el <span className="text-blue">Aviso de Privacidad</span> de guay</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="col-sx-10 col-sm-10 col-md-6 col-lg-3 mx-auto">
                  <img src="../../img/loguin_fb_google_1_4.png" className="img-responsive" alt="guay-digital" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivateUserShop;