import React, { useState, useEffect } from 'react';
// API calls
import { /* setInteractionBenefits, */ getBenefitsByEnterpriseAndCollab } from '../Models/BenefitsModel';
// Components
import Header from '../NewHome/headerColaborador';
import Menu from "../Menu";
import BenefitCard from './BenefitCard';
import BenefitHML from './BenefitHML';
import HelperApp from '../HelperApp';
import Footer from '../footerApp';
// Tools
import {
    redirectIfNotAuthenticated,
    getJwt,
} from "../../../lib/auth";
import { connect } from 'react-redux';
import UtilizarBeneficio from '../Beneficios/UtilizarBeneficio';
import AgendarCitaForm from '../Beneficios/AgendarCitaForm';
import { getAppointmentsByIdCollabAndIdEnterprise,  } from '../Models/Catalog_sigma_model';
import { getCatalogSigmaTypeReason } from '../Models/Catalog_model';
import ModalLoaderGen from '../ModalLoaderGen';
import { handleScroll } from '../../helpers/helpers';

const Benefits = ({
    general,
}) => {
    const [guayBenefits, setguayBenefits] = useState([]);
    const [contactData, setContactData] = useState({
        description: '',
        email: '',
        whatsapp: '',
        phone: '',
        website: '',
    });
    const {
        description,
        email,
        whatsapp,
        phone,
        website,
    } = contactData;
    const [icons, setIcons] = useState({});
    const [showHTML, setShowHTML] = useState(false);
    const [url, setURL] = useState('');
    const [benefitName, setBenefitName] = useState('');
    const [idCollab, setIdCollab] = useState('')
    const [showUseBenefitHtml, setShowUseBenefitHtml] = useState(false)
    const [showScheduleAppoiment, setShowScheduleAppoiment] = useState(false)
    const [idBenefit, setIdBenefit] = useState('')
    const [idProduct, setIdProduct] = useState(0)
    const [appointmentsList, setAppointmentsList] = useState([])
    const [catalogTypeReason, setCatalogTypeReason] = useState([])
    const [refreshUseBenefitHtml, setRefreshUseBenefitHtml] = useState(false)
    useEffect(() => {
        setIdCollab(general.enterpriseInScope.idCollab)
    }, [general.enterpriseInScope.idCollab])
    
    useEffect(() => {
        getBenefitsByEnterpriseAndCollab(getJwt("enterprise"), general.enterpriseInScope.idCollab).then(response => {
            if (response.validation) {
                if (response.data.code !== 1001) {
                    setguayBenefits(response.data.benefits.filter(benefit => benefit.active === 1));
                    setIcons(response.data.iconsContact);
                }
            }
        })
    }, [general.enterpriseInScope.idCollab]);

    useEffect(() => {
        if((showUseBenefitHtml) || (showUseBenefitHtml && refreshUseBenefitHtml)){
            getAppointmentsByIdCollabAndIdEnterprise(general.enterpriseInScope.idCollab,parseInt(getJwt("enterprise"))).then(response => {
                if (response.validation) {
                    // la respuesta trae de todas las citas 
                    // filtrar por idBenefit
                    const appointmentBenefitList = response.data.appointmentList.filter(data => data.idBenefit === idBenefit)
                    // console.log('appointmentBenefitList: ',appointmentBenefitList);
                    setAppointmentsList(appointmentBenefitList)
                    setRefreshUseBenefitHtml(false)
                }
            })
        }
    }, [showUseBenefitHtml,idBenefit,general.enterpriseInScope.idCollab,refreshUseBenefitHtml])

    useEffect(() => {
        getCatalogSigmaTypeReason().then(response => {
            if (response.validation) {
                setCatalogTypeReason(response.data.sigmaCatalogTypeReason.length > 0 ? response.data.sigmaCatalogTypeReason : [])
              }
        })
    }, [])
    
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <ModalLoaderGen/>
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href={getJwt('rol') === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")}>Inicio</a></li>
                                                <li
                                                    className={`breadcrumb-item font-medium-3 text-blue-sec ${benefitName === '' ? 'font-weight-bold' : 'pointer'}`}
                                                    onClick={() => {
                                                        if (benefitName !== '') {
                                                            setURL('')
                                                            setBenefitName('')
                                                            setShowHTML(false)
                                                            setIdProduct(0)
                                                            setIdBenefit(0)
                                                            setShowUseBenefitHtml(false)
                                                            setShowScheduleAppoiment(false)
                                                            handleScroll('section-top')
                                                        }
                                                    }}
                                                >Beneficios</li>
                                                {benefitName !== '' && (
                                                    <li className={`breadcrumb-item font-medium-3 text-blue-sec ${!showUseBenefitHtml && !showScheduleAppoiment && 'font-weight-bold'} `}>{benefitName}</li>
                                                )}
                                                {showUseBenefitHtml &&
                                                    <li className={`breadcrumb-item font-medium-3 text-blue-sec ${showUseBenefitHtml && 'font-weight-bold' } `}>Utilizar el servicio</li>
                                                }
                                                {showScheduleAppoiment && (
                                                    <>
                                                        <li className={`breadcrumb-item font-medium-3 text-blue-sec ${showUseBenefitHtml && 'font-weight-bold' } `}>Utilizar el servicio</li>
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Agendar Cita</li>
                                                    </>
                                                   
                                                )}
                                                
                                                
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body" id='section-top'>
                            <HelperApp />
                            {!showHTML ? (
                                <>
                                    <div className='col-12 mx-auto mt-1 mb-3 text-center'>
                                        <h1 className='text-base-guay'>Beneficios</h1>
                                    </div>
                                    <BenefitCard
                                        guayBenefits={guayBenefits}
                                        setContactData={setContactData}
                                        setShowHTML={setShowHTML}
                                        setURL={setURL}
                                        setBenefitName={setBenefitName}
                                        setShowUseBenefitHtml={setShowUseBenefitHtml}
                                        setIdBenefit={setIdBenefit}
                                        setIdProduct={setIdProduct}
                                    />
                                </>
                               
                            ) : (
                                showUseBenefitHtml ? (
                                    <UtilizarBeneficio
                                        idBenefit={idBenefit} 
                                        idCollab={idCollab}
                                        setIdBenefit={setIdBenefit}
                                        setIdProduct={setIdProduct}
                                        setURL={setURL}
                                        setBenefitName={setBenefitName}
                                        setShowHTML={setShowHTML}
                                        setShowScheduleAppoiment={setShowScheduleAppoiment}
                                        setShowUseBenefitHtml={setShowUseBenefitHtml}
                                        appointmentsList={appointmentsList}
                                        catalogTypeReason={catalogTypeReason}
                                        setRefreshUseBenefitHtml={setRefreshUseBenefitHtml}
                                    />
                                ) : (
                                    showScheduleAppoiment ? (
                                        <AgendarCitaForm
                                            idBenefit={idBenefit} 
                                            idProduct={idProduct}
                                            idCollab={idCollab}
                                            setShowScheduleAppoiment={setShowScheduleAppoiment}
                                            setShowUseBenefitHtml={setShowUseBenefitHtml}
                                        />
                                    ) : (
                                    <>
                                        <div className='col-12 mx-auto mt-1 mb-3 text-center'>
                                            <h1 className='text-base-guay'>Beneficios</h1>
                                        </div>
                                        <BenefitHML
                                            url={url}
                                            setURL={setURL}
                                            setShowHTML={setShowHTML}
                                            setBenefitName={setBenefitName}
                                        />
                                    </>
                                    )
                                )
                            )}
                            <div
                                className="modal"
                                id="contactModal"
                                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                                data-backdrop="false"
                            >
                                <div className="modal-dialog modal-sm modal-dialog-centered">
                                    <div
                                        className="modal-content"
                                        style={{ borderRadius: '30px' }}
                                    >
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: 'silver',
                                                width: '25px',
                                                height: '25px',
                                                borderRadius: '50px',
                                                top: '-25px',
                                                right: '-25px',
                                            }}
                                        >&times;</button>
                                        <div className="modal-body">
                                            <h1 className='text-center font-weight-bold'>Contáctanos</h1>
                                            <p className='font-medium-1'>{description}</p>
                                            {email !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.mail} style={{ width: '25px', height: '20px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(`mailto:${email}`)}>{email}</span></p>}
                                            {phone !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.phone} style={{ width: '25px', height: '25px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(`callto:${phone}`)}>{phone}</span></p>}
                                            {whatsapp !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.whatsapp} style={{ width: '25px', height: '25px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`)}>{whatsapp}</span></p>}
                                            {website !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.site} style={{ width: '25px', height: '25px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(website)}>{website}</span></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps)(Benefits)
