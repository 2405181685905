import axios from "axios"
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idPerzonalizedProfile 
 * @returns 
 */
export const getEnterpriseProfileFunctionalities = async (idEnterprise, idPerzonalizedProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `role/getEnterpriseProfileFunctionalities?idEnterprise=${idEnterprise}&idPerzonalizedProfile=${idPerzonalizedProfile}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        });
        if (result.data) {
            return result.data
        }
    } catch (error) {
        console.log(error)
    }
};
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const saveProfile = async (data) => {
    try {
        var raw = JSON.stringify(data)
        let result = await axios({
            method: 'POST',
            url: global.base_url + "role/saveProfile",
            headers: {
                'Content-Type': 'application/json',
                Authorization: global.tokenAuth,
            },
            data: raw,
        })
        if (result.data) {
            return result.data
        }
    } catch (error) {
        console.log(error)
    }
}
