import React from 'react';

const AdOptions = ({
    categories,
    types,
    brands,
    brand,
    category,
    discount,
    handleBrands,
    handleCategories,
    handleDiscounts,
}) => {

    return (
        <div className="col-11 mx-auto mb-5">
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-group">
                        <h5 htmlFor="options">Marcas</h5>
                        <select
                            className="form-control form-control-sm"
                            id="options"
                            onChange={handleBrands}
                            value={brand}
                            style={{fontFamily: '"Quicksand", Georgia, "Times New Roman", Times, serif'}}
                        >
                            <option value="">Seleccione</option>
                            {
                                brands.map(data => (
                                    <option
                                        key={'category-' + data.id}
                                        value={data.id}
                                    >{data.description}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-group">
                        <h5 htmlFor="options">Categorías</h5>
                        <select
                            className="form-control form-control-sm"
                            id="options"
                            onChange={handleCategories}
                            value={category}
                            style={{fontFamily: '"Quicksand", Georgia, "Times New Roman", Times, serif'}}
                        >
                            <option value="">Seleccione</option>
                            {
                                categories.map(data => (
                                    <option
                                        key={'category-' + data.idPromotionCategory}
                                        value={data.idPromotionCategory}
                                    >{data.description}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-group">
                        <h5 htmlFor="options">Descuentos</h5>
                        <select
                            className="form-control form-control-sm"
                            id="options"
                            onChange={handleDiscounts}
                            value={discount}
                            style={{fontFamily: '"Quicksand", Georgia, "Times New Roman", Times, serif'}}
                        >
                            <option value="">Seleccione</option>
                            {
                                types.map(data => (
                                    <option
                                        key={'category-' + data.idPromotionType}
                                        value={data.idPromotionType}
                                    >{data.description}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdOptions
