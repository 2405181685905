import React, { useState } from 'react';
// Components
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Swal from "sweetalert2";
import $ from "jquery";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getDay, addDays, subDays } from 'date-fns';
import LoaderApp from '../LoaderApp';
import DocumentViewer from './DocumentViewer';
// API calls
import { dwlDocumentMgmt, uploadDocumentalFiles, getProductsDocuments, getDocumentsByProduct, setLaunchOfDocumentManagement, setFlagSend } from '../Models/DocumentalModel';
import { getJwt } from "../../../lib/auth";

const DocumentManagement = ({
    setDiffusionVia,
    listDocNecProducts,
    setListDocNecProducts,
    services,
    setService,
    listInfoDocuments,
    setListInfoDocuments,
    idProductSelected,
    idCategorySelected,
    step_4,
}) => {
    // Variables
    const [dataDocument, setDataDocument] = useState([{
        idEnterprise: 0,
        idProduct: 0,
        idCategory: 0,
        idTypeDocument: 0,
        flagSend: 0,
        idDocumentMng: 0
    }]);
    const [loading_2, setLoading_2] = useState(false);
    const [fileData, setFileData] = useState([{
        file: "",
        type: ""
    }]);
    const { file, type } = fileData;
    /**
     * 
     * @param {Event} e 
     */
    const getService = e => {
        e.preventDefault();
        try {
            let service = e.target.value;
            setService(service);
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Render the correct image according with the status
     * @param {int} status 
     * @returns 
     */
    const documentStatus = status => {
        if (status === 0) {
            return (
                <img
                    src="./../img/DocumentManagement/iconos_configuracion-28.png"
                    style={{ height: '1.5rem' }}
                    alt="img-guay" />
            )
        }
        if (status === 1) {
            return (
                <img
                    src="./../img/DocumentManagement/iconos_configuracion-29.png"
                    style={{ height: '1.5rem' }}
                    alt="img-guay" />
            )
        }
        if (status === 2) {
            return (
                <img
                    src="./../img/DocumentManagement/iconos_configuracion-31.png"
                    style={{ height: '1.5rem' }}
                    alt="img-guay" />
            )
        }
        if (status === 3 || status === 4) {
            return (
                <img
                    src="./../img/DocumentManagement/iconos_configuracion-30.png"
                    style={{ height: '1.5rem' }}
                    alt="img-guay" />
            )
        }
    }
    /**
     * Set the correct document to view
     * @param {int} idEnterprise 
     * @param {int} idDocumentMng 
     * @param {int} flagSend 
     */
    const previewDocument = (idEnterprise, idDocumentMng, flagSend) => {
        dwlDocumentMgmt(idEnterprise, idDocumentMng, flagSend).then((response) => {
            switch (response.code) {
                case 1:
                    if (response.flagFiles === 0) {
                        let file = response.urlFile;
                        let fileTypes = [
                            ".png",
                            ".jpeg",
                            ".docx"
                        ];
                        let exist = false;
                        for (let x in fileTypes) {
                            let isType = file.indexOf(fileTypes[x]);
                            if (isType !== -1) {
                                exist = true
                            }
                        }
                        if (exist === true) {
                            for (let x in fileTypes) {
                                let isType = file.indexOf(fileTypes[x]);
                                if (isType !== -1) {
                                    let type = fileTypes[x].slice(1);
                                    setFileData({
                                        ...fileData,
                                        file: file,
                                        type: type
                                    });
                                    $('#modalDocumentViewer').show();
                                }
                            }
                        } else {
                            toastr.info("El tipo de documento no se puede visualizar. Te sugerimos que lo descargues.", "¡Ooops!");
                        }
                    } else {
                        Swal.fire({
                            title: '<p class="text-white mb-2">Archivo con muchos documentos</p>',
                            text: "No se puede previsualizar el archivo ya que contiene archivos multiples. Debe de descargar el archivo comprimido con todos los docuemntos.",
                            showCloseButton: true,
                            showCancelButton: true,
                            focusCancel: true,
                            cancelButtonText: 'Cancelar',
                            showConfirmButton: true,
                            confirmButtonText: "Si, descargar",
                            reverseButtons: true,
                            dangerMode: true
                        }).then((willDelete) => {
                            if (willDelete.isConfirmed) {
                                window.open(response.urlFile);
                            }
                        });
                        $(".swal2-title").addClass("bg-header-swal");
                        $(".swal2-actions").addClass("w-100 justify-content-around");
                        $(".swal2-confirm").removeClass("swal2-styled");
                        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                        $(".swal2-cancel").removeClass("swal2-styled");
                        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    }
                    break;
                case 404:
                    toastr.info("No se encontraron documentos", "¡Ooops!");
                    break;
                case 1001:
                    toastr.error("La empresa no existe o está inactiva.", "¡Ooops!");
                    break;
                case 1002:
                    toastr.error("No existe documento o no está activo.", "¡Ooops!");
                    break;
                case 1003:
                    toastr.info("No existe documento cargados.", "¡Ooops!");
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "¡Ooops!");
                    break;
            }
        });
    }
    /**
     * 
     * @param {int} idEnterprise 
     * @param {int} idDocumentMng 
     * @param {int} flagSend 
     */
    const downloadDocument = (idEnterprise, idDocumentMng, flagSend) => {
        dwlDocumentMgmt(idEnterprise, idDocumentMng, flagSend).then((response) => {
            switch (response.code) {
                case 1:
                    window.open(response.urlFile);
                    break;
                case 404:
                    toastr.info("No se encontraron documentos", "¡Ooops!");
                    break;
                case 1001:
                    toastr.error("La empresa no existe o está inactiva.", "¡Ooops!");
                    break;
                case 1002:
                    toastr.error("No existe documento o no está activo.", "¡Ooops!");
                    break;
                case 1003:
                    toastr.info("No existe documento cargados.", "¡Ooops!");
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "¡Ooops!");
                    break;
            }
        });
    }
    /**
     * Trigger the file input
     * @param {int} idEnterprise 
     * @param {int} idProduct 
     * @param {int} idCategory 
     * @param {int} idTypeDocument 
     * @param {int} flagSend 
     * @param {int} idDocumentMng 
     */
    const renderFilePers = (idEnterprise, idProduct, idCategory, idTypeDocument, flagSend, idDocumentMng) => {
        $("#file").click();
        setDataDocument({
            ...dataDocument,
            idEnterprise: idEnterprise,
            idProduct: idProduct,
            idCategory: idCategory,
            idTypeDocument: idTypeDocument,
            flagSend: flagSend,
            idDocumentMng: idDocumentMng
        });
    }
    /**
     * Upload the selected document
     * @param {Event} e 
     */
    const onChangeHandler = e => {
        try {
            let fileTypes = [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff",
                "application/pdf"
            ];
            let correct = false;
            let file = e.target.files[0];
            let posicion = e.target.files[0].name.indexOf('.docx');
            for (let x in fileTypes) {
                if (fileTypes[x] === file.type || posicion !== -1) {
                    correct = true;
                }
            }
            if (correct === true) {
                uploadDocumentalFiles(dataDocument.idEnterprise, dataDocument.idProduct, dataDocument.idCategory, dataDocument.idTypeDocument, 2, false, dataDocument.idDocumentMng, getJwt('jtw'), e.target.files).then((response) => {
                    switch (response.code) {
                        case 1:
                            reloadData();
                            toastr.success('Se subió el archivo correctamente.', '¡Éxito!');
                            break;
                        case 404:
                            toastr.info("No se encontraron documentos", "¡Ooops!");
                            break;
                        case 1001:
                            toastr.error("La empresa no existe o está inactiva.", "¡Ooops!");
                            break;
                        case 1002:
                            toastr.error("No existe documento o no está activo.", "¡Ooops!");
                            break;
                        case 1003:
                            toastr.info("No existe documento cargados.", "¡Ooops!");
                            break;
                        default:
                            toastr.error("Recargue la pagina, por favor", "¡Ooops!");
                            break;
                    }
                });
            } else {
                toastr.error('El tipo de archivo no es correcto. Los tipos de archivo permitidos son: .png, .tiff, .jpg, .jpeg, .pdf y .docx.', '¡Ooops!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Trigger the file input
     * @param {int} idEnterprise 
     * @param {int} idProduct 
     * @param {int} idCategory 
     * @param {int} idTypeDocument 
     * @param {int} flagSend 
     * @param {int} idDocumentMng 
     */
    const renderFileEvidence = (idEnterprise, idProduct, idCategory, idTypeDocument, flagSend, idDocumentMng) => {
        $("#fileEvidence").click();
        setDataDocument({
            ...dataDocument,
            idEnterprise: idEnterprise,
            idProduct: idProduct,
            idCategory: idCategory,
            idTypeDocument: idTypeDocument,
            flagSend: flagSend,
            idDocumentMng: idDocumentMng
        });
    }
    /**
     * Upload the selected document
     * @param {Event} e 
     */
    const uploadEvidence = e => {
        try {
            if (e.target.files.length <= 3) {
                let fileTypes = [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/tiff",
                    "application/pdf"
                ];
                let allFiles = e.target.files;
                let file = e.target.files[0];
                let file_2 = e.target.files[1];
                let file_3 = e.target.files[2];
                let files = [];
                let fileValidation = [
                    false,
                    false,
                    false
                ];
                let areFilesCorrect = true;
                let totalFiles = 0;
                files.push(file, file_2, file_3);
                for (let x in files) {
                    if (files[x] !== undefined) {
                        let posicion = files[x].name.indexOf('.docx');
                        if (posicion === -1) {
                            for (let y in fileTypes) {
                                if (fileTypes[y] === files[x].type) {
                                    fileValidation[x] = true;
                                }
                            }
                        } else {
                            fileValidation[x] = true;
                        }
                    } else {
                        totalFiles = totalFiles + 1;
                        fileValidation[x] = true;
                    }
                }
                if (totalFiles === 3) {
                    areFilesCorrect = false;
                } else {
                    for (let x in fileValidation) {
                        if (fileValidation[x] === false) {
                            areFilesCorrect = false;
                        }
                    }
                }
                if (areFilesCorrect === true) {
                    uploadDocumentalFiles(dataDocument.idEnterprise, dataDocument.idProduct, dataDocument.idCategory, dataDocument.idTypeDocument, 2, true, dataDocument.idDocumentMng, getJwt('jtw'), allFiles).then((response) => {
                        switch (response.code) {
                            case 1:
                                reloadData();
                                toastr.success('Se subió el archivo correctamente.', '¡Éxito!');
                                break;
                            case 404:
                                toastr.info("No se encontraron documentos", "¡Ooops!");
                                break;
                            case 1001:
                                toastr.error("La empresa no existe o está inactiva.", "¡Ooops!");
                                break;
                            case 1002:
                                toastr.error("No existe documento o no está activo.", "¡Ooops!");
                                break;
                            case 1003:
                                toastr.info("No existe documento cargados.", "¡Ooops!");
                                break;
                            default:
                                toastr.error("Recargue la pagina, por favor", "¡Ooops!");
                                break;
                        }
                    });
                } else {
                    if (totalFiles !== 3) {
                        toastr.error('Algún archivo no es correcto. Los tipos de archivos permitidos son: .png, .tiff, .jpg, .jpeg, .pdf y .docx.', '¡Ooops!');
                    }
                }
            } else {
                toastr.error('Solo puedes subir un máximo de 3 archivos.', '¡Ooops!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Reload the documents
     */
    const reloadData = () => {
        setLoading_2(true);
        getProductsDocuments(getJwt('enterprise')).then((response) => {
            switch (response.code) {
                case 1:
                    setListDocNecProducts(response.listDocNecProducts);
                    getDocumentsByProduct(getJwt('enterprise'), idProductSelected, idCategorySelected).then((response) => {
                        switch (response.code) {
                            case 1:
                                for (let x in response.listInfoDocuments) {
                                    let completeDate = response.listInfoDocuments[x].lastUpdate;
                                    let date = completeDate.split(" ");
                                    let shortDate = date[0];
                                    response.listInfoDocuments[x].lastUpdate = shortDate;
                                }
                                setListInfoDocuments(response.listInfoDocuments);
                                setLoading_2(false);
                                break;
                            case 404:
                                toastr.info("No se encontraron documentos", "¡Ooops!");
                                setLoading_2(false);
                                break;
                            case 1001:
                                toastr.error("La empresa no existe o está inactiva.", "Error");
                                setLoading_2(false);
                                break;
                            case 1002:
                                toastr.info("No requiere documentos necesarios.", "¡Ooops!");
                                setLoading_2(false);
                                break;
                            default:
                                toastr.error("Recargue la pagina, por favor", "Error");
                                setLoading_2(false);
                                break;
                        }
                    });
                    break;
                case 404:
                    toastr.info("No se encontraron documentos", "¡Ooops!");
                    setLoading_2(false);
                    break;
                case 1001:
                    toastr.error("La empresa no existe o está inactiva.", "Error");
                    setLoading_2(false);
                    break;
                case 1002:
                    toastr.info("No requiere documentos necesarios.", "¡Ooops!");
                    setLoading_2(false);
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "Error");
                    setLoading_2(false);
                    break;
            }
        });
    }

    //convert string date to date and return null if invalid date
    const convertDate = (date) => {
        if (date !== "") {
            const dateParts = date.split("-");
            return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        } else {
            return null;
        }
    }

    //disabled weekend days and past dates
    const isWeekday = (date) => {
        /* const currentDate = new Date();
        const selectedDate = new Date(date); */
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    //function to convert type date to string format required to send to db
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    //check if date can be edited
    const disableDateSelection = (status, diffusionDate) => {
        //checar si hay fecha
        if (diffusionDate !== "" && diffusionDate !== undefined) {
            const currentDate = new Date();
            const diffusionDate2 = convertDate(diffusionDate);
            //si el status === 2 y la fecha de difusion es la misma que la fecha actual desactivar la edicion de fecha
            if (status === 2 && diffusionDate2 <= currentDate) {
                return true;
            } else {
                //cualquier otro status resulta en desactivar la fecha
                if (status === 0 || status === 3 /* || status === 4 */) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if (status === 0 || status === 3 /* || status === 4 */) {
                return true;
            } else {
                return false;
            }
        }
    }
    /**
     * Set the diffussion date by document
     * @param {string} date 
     * @param {int} idTypeDocument 
     * @param {int} idDocumentMng 
     */
    const onChangeDate = (date, idTypeDocument, idDocumentMng) => {
        let listDocumentaryManagement = [{
            idDocumentMng: idDocumentMng,
            idTypeDocument: idTypeDocument,
            diffusionDate: date
        }];
        setLoading_2(true);
        setLaunchOfDocumentManagement(getJwt('enterprise'), getJwt('jtw'), listDocumentaryManagement).then((response) => {
            switch (response.code) {
                case 1:
                    reloadData();
                    toastr.success("¡Se actualizó la fecha correctamente!");
                    break;
                case 1002:
                    toastr.warning("Un documento no se encuentra actualizado, recargue la página y vuelva a intentarlo", "Actualizado");
                    setLoading_2(false);
                    break;
                default:
                    toastr.error("Ocurrió un error inesperado, recargue la página y vuelva a intentarlo.");
                    setLoading_2(false);
                    break;
            }
        });
    }

    //check the month
    const NumToSp = {
        "01": "Enero",
        "02": "Febrero",
        "03": "Marzo",
        "04": "Abril",
        "05": "Mayo",
        "06": "Junio",
        "07": "Julio",
        "08": "Agosto",
        "09": "Septiembre",
        "10": "Octubre",
        "11": "Noviembre",
        "12": "Diciembre"
    }

    //convert string formated date to human formated date
    function getDate(input) {
        const date = input.split("-");
        const year = date[0];
        const month = NumToSp[date[1]];
        const day = date[2];
        return `${day} de ${month} de ${year}`;
    }

    const changeVia = () => {
        let statusZero = 0;
        let statusOne = 0;
        for (let x in listInfoDocuments) {
            if (listInfoDocuments[x].status === 0 && (listInfoDocuments[x].rank === 1 || listInfoDocuments[x].rank === 2)) {
                statusZero = statusZero + 1;
            }
            if (listInfoDocuments[x].status === 1 && (listInfoDocuments[x].rank === 1 || listInfoDocuments[x].rank === 2)) {
                statusOne = statusOne + 1;
            }
        }
        if (statusZero === 2) {
            setDiffusionVia(0);
        } else {
            if (statusOne > 0) {
                let listDocumentaryManagement = [];
                for (let x in listInfoDocuments) {
                    listDocumentaryManagement.push({ idDocumentMng: listInfoDocuments[x].idDocumentMng });
                }
                setFlagSend(getJwt('enterprise'), getJwt('jtw'), 1, listDocumentaryManagement).then((response) => {
                    switch (response.code) {
                        case 1:
                            setDiffusionVia(0);
                            break;
                        case 1001:
                            toastr.error("La empresa no existe o está inactiva.", "¡Ooops!");
                            break;
                        case 1002:
                            toastr.error("No existe documento o no está activo.", "¡Ooops!");
                            break;
                        default:
                            toastr.error("Recargue la pagina, por favor", "¡Ooops!");
                            break;
                    }
                });
            } else {
                toastr.error("El estado es Completo o En difusión, por lo tanto, no se puede hacer el cambio.", "¡Ooops!");
            }
        }
    }

    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay rounded-top mb-5 py-2">
            <div className="row">
                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto form-inline d-flex justify-content-center">
                    <label className="text-base mr-1">Servicio: </label>
                    <select
                        className="form-control form-control-sm w-25"
                        name="service"
                        onChange={getService}
                        value={services}>
                        {listDocNecProducts.map((option) => (
                            option.necesary === 1
                                ?
                                (
                                    <option
                                        key={option.idCategory + option.idProduct}
                                        value={option.idCategory + option.idProduct}
                                    >
                                        {option.title}
                                    </option>
                                )
                                :
                                (null)
                        ))}
                    </select>
                </div>
                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-2">
                    <p className="text-base">Documentos que requieren difusión:</p>
                    <p className="text-base-guay">La difusión de tus documentos se realiza fuera de la plataforma guay, deberás subir también la evidencia de esta difusión para poder cumplir con lo norma. {!step_4 && <span className="text-blue pointer" onClick={() => changeVia()}>Cambiar <i className='ft ft-repeat align-middle' /></span>}</p>
                </div>
                {
                    loading_2 === true
                        ?
                        (
                            <LoaderApp />
                        )
                        :
                        (
                            listInfoDocuments.map((data) =>
                                <div className={listInfoDocuments[listInfoDocuments.length - 1].idTypeDocument === data.idTypeDocument ? ("col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-1 mt-2") : ("col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto border-bottom font-medium-1 mt-2")} key={data.rank}>
                                    <div className="row mb-2">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <a href={data.urlTemplate}><img className="mr-1" src="./../img/icons/iconos_configuracion-27.png" style={{ height: '1rem' }} alt="img-guay" /></a>
                                            <span className="mr-1">{data.title}</span>
                                            {documentStatus(data.status)}
                                        </div>
                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="row">
                                                <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                                                    <div className="row">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                            <img
                                                                className="w-100"
                                                                src={data.rank === 1 ? ("./../img/icons/iconos_configuracion-04.png") : (data.rank === 2 ? ("./../img/icons/iconos_configuracion-02.png") : ('./../img/DocumentManagement/iconos_comisiones.png'))}
                                                                alt="img-guay" />
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-1 text-center">
                                                            <span className="text-base-guay font-small-1">Ultima actualización {data.lastUpdate === "" ? ("---") : (getDate(data.lastUpdate))}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                                                    <div className="row">
                                                        {/* <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                            {
                                                                data.existEnt === 1
                                                                    ?
                                                                    (
                                                                        <span
                                                                            className="text-base fake-select pointer"
                                                                            onClick={() => previewDocument(data.idEnterprise, data.idDocumentMng, 0)}>
                                                                            <i className='ft ft-eye text-blue font-weight-bold align-middle' /> Descargar Documento
                                                                        </span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span
                                                                            className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                            <i className='ft ft-eye text-blue font-weight-bold align-middle' /> Descargar Documento
                                                                        </span>
                                                                    )
                                                            }
                                                        </div> */}
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                            {
                                                                data.existEnt === 1
                                                                    ?
                                                                    (
                                                                        <span
                                                                            className="text-base fake-select pointer"
                                                                            onClick={() => downloadDocument(data.idEnterprise, data.idDocumentMng, 0)}>
                                                                            <i className='ft ft-download text-blue font-weight-bold align-middle' /> Descargar
                                                                        </span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                            <i className='ft ft-download text-blue font-weight-bold align-middle' /> Descargar
                                                                        </span>
                                                                    )
                                                            }
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                            {
                                                                !step_4
                                                                    ?
                                                                    (
                                                                        <span className="text-base fake-select pointer" onClick={() => renderFilePers(data.idEnterprise, data.idProduct, data.idCategory, data.idTypeDocument, 2, data.idDocumentMng)}>
                                                                            <input
                                                                                type="file"
                                                                                id="file"
                                                                                className="hidden"
                                                                                onChange={onChangeHandler} />
                                                                            <i className='ft ft-refresh-ccw text-blue font-weight-bold align-middle' /> Actualiza Documento
                                                                        </span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                            <i className='ft ft-download text-blue font-weight-bold align-middle' /> Actualiza Documento
                                                                        </span>
                                                                    )
                                                            }
                                                        </div>
                                                        {
                                                            data.existEnt === 1 && !step_4
                                                                ?
                                                                (
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                                        <Datepicker
                                                                            dateFormat="dd-MM-yyyy"
                                                                            className="hidden"
                                                                            selected={convertDate(data.diffusionDate)}
                                                                            onChange={date => onChangeDate(convert(date), data.idTypeDocument, data.idDocumentMng)}
                                                                            filterDate={isWeekday}
                                                                            excludeDates={[addDays(new Date(), 1)]}
                                                                            minDate={subDays(new Date(), 730)}
                                                                            placeholderText="Selecciona una fecha"
                                                                            disabled={disableDateSelection(data.status, data.diffusionDate)}
                                                                            strictParsing
                                                                            id={"id-" + data.rank}
                                                                        />
                                                                        {
                                                                            data.diffusionDate === ""
                                                                                ?
                                                                                (
                                                                                    <span
                                                                                        className="text-base fake-select pointer"
                                                                                        onClick={() => $("#id-" + data.rank).click()}>
                                                                                        <i className='ft ft-calendar text-blue font-weight-bold align-middle' /> En difusión
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <span
                                                                                        className="text-base fake-select pointer"
                                                                                        onClick={() => $("#id-" + data.rank).attr('disabled') === 'disabled' ? (toastr.info("Se está generando tu evidencia de difusión. Por favor espera para volver a difundir.", "¡Ooops!")) : ($("#id-" + data.rank).click())}>
                                                                                        <i className='ft ft-calendar text-blue font-weight-bold align-middle' /> En difusión: {data.diffusionDate}
                                                                                    </span>
                                                                                )
                                                                        }
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    step_4 ? (
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                                            <span className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                                <i className='ft ft-calendar text-blue font-weight-bold align-middle' /> {data.diffusionDate === "" ? ('En difusión') : (`En difusión: ${data.diffusionDate}`)}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                                            <span className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                                <i className='ft ft-calendar text-blue font-weight-bold align-middle' /> En difusión
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="row">
                                                <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                                                    <div className="row">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                            <img
                                                                className="w-100"
                                                                src="./../img/icons/iconos_configuracion-03.png"
                                                                alt="img-guay" />
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-1 text-center">
                                                            <span className="text-base-guay font-small-1">Ultima actualización {data.lastUpdate === "" ? ("---") : (getDate(data.lastUpdate))}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                                                    <div className="row">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                            {
                                                                data.status === 4
                                                                    ?
                                                                    (
                                                                        <span
                                                                            className="text-base fake-select pointer"
                                                                            onClick={() => previewDocument(data.idEnterprise, data.idDocumentMng, 1)}
                                                                        >
                                                                            <i className='ft ft-download text-blue font-weight-bold align-middle' /> Descargar evidencia de difusión
                                                                        </span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                            <i className='ft ft-download text-blue font-weight-bold align-middle' /> Descargar evidencia de difusión
                                                                        </span>
                                                                    )
                                                            }
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                            {
                                                                data.status > 1 && !step_4
                                                                    ?
                                                                    (
                                                                        <span
                                                                            className="text-base fake-select pointer"
                                                                            onClick={() => renderFileEvidence(data.idEnterprise, data.idProduct, data.idCategory, data.idTypeDocument, 2, data.idDocumentMng)}>
                                                                            <input
                                                                                type="file"
                                                                                id="fileEvidence"
                                                                                className="hidden"
                                                                                onChange={uploadEvidence}
                                                                                multiple />
                                                                            <i className='ft ft-refresh-ccw text-blue font-weight-bold align-middle' /> Actualiza Evidencia
                                                                        </span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span className="text-base pointer" style={{ opacity: '0.5' }}>
                                                                            <i className='ft ft-refresh-ccw text-blue font-weight-bold align-middle' /> Actualiza Evidencia
                                                                        </span>
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                }
                {/* <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 d-flex justify-content-end p-0">
                    <a href="/gestion-documental" onClick={() => { sessionStorage.setItem("step", 2); }}><span className="text-blue fake-select pointer">Ir a comunicados</span></a>
                </div> */}
            </div >
            <DocumentViewer
                file={file}
                type={type} />

        </div >
    )
}

export default DocumentManagement;
