/*
 File Name: Catalog_model.js
 Description: Manage Generic Catalog API Requests
 guay dev's team
*/
import axios from "axios";
// TODO: Tools
import * as toastr from 'toastr'

/*
  Get Estados Sigma
*/
export const getEstadosApiSigma = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}apiSigma/getEstadosApiSigma`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 1000:
                    toastr.info('Hubo un problema en generar el token de sigma.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                case 1001:
                    toastr.info('Hubo un problema al conectar con el sistema de sigma.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
};

/*
  Get Estados Sigma
*/
export const getCiudadesApiSigma = async (estado) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}apiSigma/getCiudadesApiSigma?estado=${estado}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 1000:
                    toastr.info('Hubo un problema en generar el token de sigma.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                case 1001:
                    toastr.info('Hubo un problema al conectar con el sistema de sigma.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
};

/*
  Get Estados Sigma
*/
export const getSectoresApiSigma = async (estado,ciudad) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}apiSigma/getSectoresApiSigma?estado=${estado}&ciudad=${ciudad}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (parseInt(result.data.code)) {
                case 1:
                    obj.data = result.data
                    break;
                case 2:
                    toastr.info('Tienes una cita activa. Por lo tanto no puedes generar una nueva', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                break;
                case 200:
                    obj.data = result.data
                    break;
                case 1000:
                    toastr.info('Hubo un problema en generar el token de sigma.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                case 1001:
                    toastr.info('Hubo un problema al conectar con el sistema de sigma.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
};

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const createAppoimentBenefitSigma = async (data) => {
    try {
        let formData = new FormData()
        formData.append('idCollab', data.idCollab)
        formData.append('idEnterprise', data.idEnterprise)
        formData.append('idUser', data.idUser)
        formData.append('idBenefit', data.idBenefit)
        formData.append('cProduct', data.cProduct)
        formData.append('idTypeReason', data.idTypeReason)
        formData.append('fCita', data.fCita)
        formData.append('idHour', data.idHour)
        formData.append('description', data.description)
        formData.append('cEstado', data.cEstado)
        formData.append('cCiudad', data.cCiudad)
        formData.append('cSector', data.cSector)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}apiSigma/createAppointmentBenefitSigma`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        let obj = {
            data: {},
            validation: true,
        }
        // console.log('result app: ', result);
        if(result){
            if(parseInt(result.data.status) === 200){
                obj.data = result.data
            }else if(result.data.code){
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data
                        break;
                    case 2:
                        toastr.info(`${result.data.message && result.data.message !== '' ? result.data.message: 'Actualmente hay una cita agendada'}.`, '¡Ooops!')
                        obj.validation = false
                        obj.data = result.data
                        break;
                    case 200:
                        obj.data = result.data
                        break;
                    case 1005:
                        toastr.info(`No se encontro el colaborador.`, '¡Ooops!')
                        obj.validation = false
                        obj.data = result.data
                        break;
                    case 1006:
                        toastr.info(`No se encontro el código de aseguradora.`, '¡Ooops!')
                        obj.validation = false
                        obj.data = result.data
                        break;
                    case 1007:
                        toastr.info(`No se encontro el cliente.`, '¡Ooops!')
                        obj.validation = false
                        obj.data = result.data
                    break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                        obj.validation = false
                        break;
                }
            }else{
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
                obj.validation = false
            }
        }else{
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!')
            obj.validation = false
        }

        return obj
    } catch (error) {
        console.log(error)
    }
}

/*
  Get Appiments Sigma by idCollab && idEnterprise
*/
export const getAppointmentsByIdCollabAndIdEnterprise = async (idCollab,idEnterprise) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}apiSigma/getAppointmentsByIdCollabAndIdEnterprise?idCollab=${idCollab}&idEnterprise=${idEnterprise}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }

        if (result.data) {
            switch (parseInt(result.data.code)) {
                case 1:
                    obj.data = result.data
                    break;
                case 2:
                    toastr.info('No ha generado ninguna cita', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                break;
                case 200:
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
};

/*
  Get appointment Sigma by idAppointment
*/
export const getAppointmentByIdAgenda = async (idAppointment) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}apiSigma/getAppointmentByIdAgenda?idAppointment=${idAppointment}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result) {
            if(result.status === 200){
                obj.data = result.data
            }else{
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.status}`, '¡Ooops!')
                obj.validation = false
            }
            // switch (parseInt(result.data.code)) {
            //     case 1:
            //         obj.data = result.data
            //         break;
            //     case 2:
            //         toastr.info('No ha generado ninguna cita', '¡Ooops!')
            //         obj.validation = false
            //         obj.data = result.data
            //     break;
            //     case 200:
            //         obj.data = result.data
            //         break;
            //     default:
            //         toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
            //         obj.validation = false
            //         break;
            // }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.status}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
};

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const cancelAppointmentByIdAgenda = async (idAppointment ) => {
    try {
        let formData = new FormData()
        formData.append('idAppointment ', idAppointment)

        let result = await axios({
            method: 'POST',
            url: `${global.base_url}apiSigma/cancelAppointmentByIdAgenda`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result) {
            if(parseInt(result.status) === 200){
                obj.data = result.data
            }else if(parseInt(result.status) === -2){
                toastr.info(`No se puede cancelar una cita que ya fue cancelada u anulada`, '¡Ooops!')
                obj.validation = false
            }else{
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.status}`, '¡Ooops!')
                obj.validation = false
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}






