import React, { useState,useEffect } from 'react'
import { getJwt } from "../../../lib/auth";
import { sendCardsByEnterprise } from '../Models/Launch_model';
import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table'
import { MultiSelect } from 'react-multi-select-component'
import Swal from "sweetalert2";
import $ from 'jquery';
import * as toastr from 'toastr';
import ModalFichas from './ModalFichas';
const TablaReenvioFichas = ({cardListTable,selectOptions,disableActions}) => {
  const [selected, setSelected] = useState(selectOptions)
  const [flagAll, setFlagAll] = useState(0)
  const [flagLeader, setFlagLeader] = useState(0)
  const [branches, setBranches] = useState('0')
  const [branchesText, setBranchesText] = useState('Todos los Centros de Trabajo.')
  const [urlCard, setUrlCard] = useState()

  const {cards} = cardListTable;

  const selectMultipleLables= {
    "allItemsAreSelected": "Todos los Centros de Trabajo.",
    "clearSearch": "Limpiar Busqueda",
    "clearSelected": "Limpiar seleccionados",
    "noOptions": "No hay centros de trabajo registrados",
    "search": "Buscar",
    "selectAll": "Todos los Centros de Trabajo",
    "selectAllFiltered": "Todos los Centros de Trabajo (Filtrado)",
    "selectSomeItems": "Seleccione...",
    "create": "Crear",
  }

  useEffect(() => {
    let isEqual = JSON.stringify(selectOptions) === JSON.stringify(selected);
    let selectedBranches = [];
    let selectedBranchesText = [];

    if(isEqual){
        selectedBranches = [0];
        selectedBranchesText = ['Todos los Centros de Trabajo.']
    }else{
      selected.forEach(element => {
          if (!selectedBranches.includes(element.value)) {
            selectedBranches.push(element.value)
            selectedBranchesText.push(element.label)
          }
        });
    }
    setBranches(selectedBranches.toString());
    setBranchesText(selectedBranchesText.toString())
  }, [selected,selectOptions])
  
  const setIconEmail = (cell, row, enumObject, rowIndex) => {
    let data = row;
    return (
        <div>
          <i
            onClick={() => confirmSendEmail(data)}
            className='bi bi-envelope icon-medium-size text-blue pointer'
            data-toggle="tooltip"
            data-placement="top"
            title={`Enviar Ficha`}
          />
        </div>
    )
  }

  const setIconShow = (cell, row, enumObject, rowIndex) => {
    return (
        <div>
          <i
            onClick={() => previewCard(cell)}
            className='bi bi-eye icon-medium-size text-blue pointer'
            data-toggle="tooltip"
            data-placement="top"
            title={`Ver Ficha`}
          />
        </div>
    )
  }

  const previewCard = (url) => {
    setUrlCard(url)
    $("#CardModal").show();
  }

  const handleSwitchLeaderChange = ({target}) => {
    if (target.checked === true) {
      setFlagLeader(1)
      setFlagAll(0)
    }else{
      setFlagLeader(0)
    }
  }

  const handleSwitchAllChange = ({target}) => {
    if (target.checked === true) {
      setFlagAll(1)
      setFlagLeader(0)
    }else{
      setFlagAll(0)
    }
  }


  const confirmSendEmail = (data) =>{
    let emailTo = flagAll === 1 ? 'Todos' : 'Líderes'

    if(flagAll === 0 && flagLeader === 0){
      toastr.info('Debe seleccionar si es para todos o solo para líderes');
    }else if(branches === ''){
      toastr.info('Debe seleccionar por lo menos un centro de trabajo');
    }else{
      Swal.fire({
        title: `<p class="text-white mb-2">Enviar ficha</p>`,
        html: `
        <div>
          <p class="text-black mb-2"><b>¿Estás seguro de querer enviar la ficha?</b></p>
          <table>
            <tbody>
              <tr>
                <td align="left"><b>Nombre de la ficha: </b></td>
                <td align="left">${data.description}</td>
              </tr>
              <tr>
                <td align="left"><b>Se enviara a: </b></td>
                <td align="left">${emailTo}</td>
              </tr>
              <tr>
                <td align="left"><b>Centros de Trabajo: </b></td>
                <td align="left">${branchesText}</td>
              </tr>
            </tbody>
          </table>
        </div>
        `,
        showCloseButton: true,
        showCancelButton: true,
        focusCancel: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: "Enviar",
        reverseButtons: true,
        dangerMode: true
        }).then((willSend) => {
            if (willSend.isConfirmed) {
              sendCardsByEnterprise(getJwt('jtw'),getJwt('enterprise'), data.idFicha,flagAll,flagLeader,branches ).then(response => {
                switch (response?.code) {
                    case 1:
                        toastr.success(`Se ha enviado correctamente la ficha: ${data.description}`)
                        break

                    case 99:
                        toastr.error('Ocurrió un error, verifique su conexión a internet.')
                        break

                    case 1001:
                        toastr.error('La organización no se encuentra o no está activa.')
                        break

                    case 1002:
                      toastr.error('El usuario no se encuentra o no está activo.')
                      break

                    default:
                        toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                        break
                }
              })
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    } 
  }


  const rowStyleFormat = () => {
    return { backgroundColor: '#fff' }
  }

  const tdStyleFormat = () => {
      return { padding: '0px', whiteSpace: 'normal', wordWrap: 'break-word', height: '10px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1', verticalAlign: 'middle' }
  }

  const thStyleFormat = () => {
      return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '0px', paddingRight: '0px' }
  }

  const createCustomSearchField = () => {
    return (
      <SearchField
        className='form-control-sm my-custom-class'
        placeholder='Buscar...' />
    );
  }

  const options = {
    sizePerPage: 10,
    hideSizePerPage: true,
    noDataText: 'No se encontraron registros.',
    searchField: createCustomSearchField,
  } 

  return (
    <div className='col-12 bg-white rounded p-2 mb-5'> 
      <form>
        {
          !disableActions ?
          (
            <div className='row mb-2'>
              <div className='col'>
                <div className="row align-items-center">
                  <div className="col-3 d-flex flex-wrap align-content-center">
                      <b className="col-form-label text-base">Todos</b>
                  </div>
                  <div className="col-3 d-flex flex-wrap align-content-center">
                      <div className="form-check form-switch">
                          <input className="form-check-input bg-green-check" type="checkbox" role="switch"
                              name='chckAll'
                              id='chckAll'
                              onChange={handleSwitchAllChange}
                              checked={flagAll === 1 ? true : false}
                          />
                          <label className="fw-bold form-check-label"></label>
                      </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className="row align-items-center">
                  <div className="col-3 d-flex flex-wrap align-content-center">
                      <b className="col-form-label text-base">Líderes</b>
                  </div>
                  <div className="col-3 d-flex flex-wrap align-content-center">
                      <div className="form-check form-switch">
                          <input className="form-check-input bg-green-check" type="checkbox" role="switch"
                              name='chckLeader'
                              id='chckLeader'
                              onChange={handleSwitchLeaderChange}
                              checked={flagLeader === 1 ? true : false}
                          />
                          <label className="fw-bold form-check-label"></label>
                      </div>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                  <div className="row align-items-center">
                      <div className="col-4">
                          <b className="col-form-label text-base">Centros de Trabajo</b>
                      </div>
                      <div className="col-8">
                        <MultiSelect
                          className="multi-select"
                          options={selectOptions}
                          hasSelectAll={true}
                          overrideStrings={selectMultipleLables}
                          value={selected}
                          onChange={e => {
                            setSelected(e)
                          }}
                        />
                      </div>
                  </div>
              </div>
            </div>

          ) : (null)
        }
        
        <div className='row mb-2'>
          <div className="col-12">
            <BootstrapTable
                data={cards}
                condensed
                pagination={true}
                tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                trClassName='tr-data-table'
                search={true}
                searchPlaceholder='Buscar...'
                options={options}
                trStyle={rowStyleFormat}
            >
                <TableHeaderColumn dataField="email" dataAlign="center" width='10%' tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} dataFormat={setIconShow} searchable={ false }>Ver</TableHeaderColumn>
                <TableHeaderColumn isKey dataField="description" dataAlign="center" width="40%" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} dataSort>Nombre</TableHeaderColumn>
                {
                  !disableActions ?
                  (
                    <TableHeaderColumn dataField="idFicha" dataAlign="center" width='10%' tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} dataFormat={setIconEmail} searchable={ false }>Reenviar</TableHeaderColumn>
                  ): (null)
                }
            </BootstrapTable>
          </div>
        </div>  
      </form>
      <ModalFichas
          urlCard={urlCard} setUrlCard={setUrlCard}
      />
    </div>
    
  )
}


export default TablaReenvioFichas
