import redirect from "./redirect";
import { setCookie, getCookie } from "./session";
import { authenticate } from "../services/authApi";
import swal from "sweetalert";
import { validateCredentials } from "./validation";
import axios from "axios";


export const signIn = async (email, password) => {
  const error = validateCredentials(email, password);
  if (error) {
    return error;
  }

  const res = await authenticate(email, password);
  if (!res.jwt) {
    return res;
  }

  setCookie("jwt", res.jwt);
  redirect("/user");
  return null;
};

export const getJwt = (ctx) => {
  return getCookie(ctx);
};

export const setJwt = crx => {
  setCookie("jtw", crx.id);
  setCookie("rol", crx.rol);
  setCookie("enterprise", crx.enterprise);
  setCookie("branch", crx.branch);
  setCookie("help", crx.help);
  setCookie("help2", crx.help2);
  setCookie("tkc", crx.tkc);
  setCookie("email", crx.email);
  setCookie("session", crx.session);
  setCookie("enterpriseName", crx.enterpriseName);
  setCookie("enterpriseInitials", crx.enterpriseInitials);
  setCookie("business", crx.business);
  setCookie("userName", crx.userName);
}


export const isAuthenticated = ctx => !!getJwt(ctx);

export const redirectIfAuthenticated = ctx => {
  if (isAuthenticated(ctx)) {
    redirect("/user", ctx);
    return true;
  }
  return false;
};

export const redirectIfNotAuthenticated2 = () => {
  const origin = window.location.origin;
  if (!isAuthenticated("jtw")) {
    window.location = origin;
    return false;
  }
  return true;
};


export const redirectIfNotAuthenticated = () => {
  if (!isAuthenticated("jtw")) {
    window.location = "acceso";
    return false;
  }
  return true;
};

export const validateScreen = (screen) => {
  try {
    if (screen > 1024) {
      swal("¡Espera!", "Para ingresar al módulo de ECO-RS es necesario que ingreses desde un dispositivo movíl o tablet", "info").then((value) => {
        window.location = "../acceso";
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export const setSession = async (data) => {

  try {
    let formData = new FormData();
    formData.append("user", JSON.stringify(data));
    let result = await axios({
      url: global.base_url + "users/logOut",
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data;',
        Authorization: global.tokenAuth
      }
    });
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
  }
};