import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getJwt } from '../../../../lib/auth';
import { getActiveProductsByEnterprise, /* getInfoLaunch4Product, */ getCalendarCom, /* getHasTypeLaunch, */ DisabledCalendarCom } from '../../../app/Models/Enterprise_model';
import { setCalendarByLaunch, getLaunchsByService } from '../../../app/Models/Launch_model';
import Header from '../../NewHome/headerAdministrador';
import Menu from "../../Menu";
import Footer from '../../footerApp';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getDay, addDays } from 'date-fns';
import HorizontalTimeline from "react-horizontal-timeline";
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Swal from "sweetalert2";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ModalResume from '../../services/ModalResume';
import TableCommunicationLaunchDate from './TableCommunicationLaunchDate';
import { resetLaunch } from '../../../../redux/launchDuck';
import { connect } from 'react-redux';
import { changeProductCategorySubCategory } from '../../../../redux/generalDuck';
import { validateAccess, getSubsection, getSubsectionIn } from '../../../helpers/validations'
import HelperApp from '../../HelperApp';

/* let count = 0; */
const Calendar = ({ launchStore, access, resetLaunch, changeProductCategorySubCategory }) => {
  const enterpriseId = getJwt("enterprise");
  const [launch, setLaunch] = useState([]);
  const [activeProducts, setactiveProducts] = useState([])
  /* const [Item, updateFichas] = useState(0); */
  const [categorie, upCate] = useState(0);
  const [disableCheck, setDisableCheck] = useState(true);
  // eslint-disable-next-line
  const [tipoLanzamiento, settipoLanzamiento] = useState(null); //tipo de lanzamiento independiente / conjunto
  const [launchData, upLaunchData] = useState({});
  /* const [DatesWeeks, updweeks] = useState(["2021-01-01", "2021-01-02", "2021-01-03"]); */
  const [paramsCal, updParams] = useState({
    idEnterprise: 0,
    idCategorie: 0,
    idGroup: 0,
    status: 2
  });
  const [serviceSelect, setServiceSelect] = useState("");
  const [exerciseSelect, setExerciseSelect] = useState("");
  const [nextFlow, setNextFlow] = useState(false);
  const history = useHistory();
  const [launchCommunicationDate, setLaunchCommunicationDate] = useState("");
  const [editLaunchCommunicationDate, setEditLaunchCommunicationDate] = useState(true);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [cards, setCards] = useState([]);
  const [info, setInfo] = useState([]);
  const [totalWeeks, setTotalWeeks] = useState(0);

  const [tableCommData, setTableCommData] = useState(null);
  const [disableActions, setDisableActions] = useState(false)

  const [idQuizEnterprise, setIdQuizEnterprise] = useState(0)

  useEffect(() => {
    try {
      if (parseInt(getJwt('rol')) > 5) {
        //console.log(access)
        //console.log(validateAccess(access.list, 2))
        const section = validateAccess(access.list, 2)
        //console.log(getSubsection(section[0].subSection, 14))
        const subSection = getSubsection(section[0].subSection, 14)
        //console.log(getSubsectionIn(subSection[0].subSectionIn, 33))
        const comunicacion = getSubsectionIn(subSection[0].subSectionIn, 33)

        if (section[0].id_action === 0) {
          history.push("/inicio-colaborador")
        } else {
          if (comunicacion[0].id_action === 0) {
            toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
            history.push("/administracion-servicios")
          } else {
            if (comunicacion[0].id_action === 2) {
              setDisableActions(true)
            }
          }
        }
      }
      getActiveProductsByEnterprise(enterpriseId).then((response) => {
        switch (response?.code) {
          case 1:
            sessionStorage.getItem("inLaunch") === "1" ? (setNextFlow(true)) : (setNextFlow(false));
            if (sessionStorage.getItem("inLaunch") === "1") {
              let completeProduct = sessionStorage.getItem("idCategory") + "," + sessionStorage.getItem("idSubcategory") + "," + sessionStorage.getItem("idProduct");
              const activeProducts = response.listProducts.filter((product) => {
                if (product.acquired === 1) {
                  return product;
                } else {
                  return null;
                }
              });
              setIdQuizEnterprise(sessionStorage.getItem("idQuizEnterprise"))
              settipoLanzamiento(sessionStorage.getItem("idTypeLaunch"));
              setactiveProducts(activeProducts);
              setServiceSelect(completeProduct);
              setExerciseSelect(`${sessionStorage.getItem("idGroup")}`);
              setEditLaunchCommunicationDate(false);
              if (launchStore.launch !== null) {
                let obj = completeProduct.split(",");
                let idcat = obj[0];
                let idsub = obj[1];
                let idprod = obj[2];
                getLaunchsByService(enterpriseId, idprod, idcat, idsub).then((response) => {
                  switch (response.code) {
                    case 1:
                      const launchGroup = launchStore.launchCT.find(centro => centro.idGroup !== 0); //encuentra el primer centro de trabajo del lanzamiento activo cuyo grupo sea diferente a 0
                      const launchActive = response.listLauch.find(launch => launch.idGroup === launchGroup.idGroup); //encuentra el lanzamiento que se esta configurando para asignarles sus fechas de comunicado
                      if (launchActive) {
                        let arrayCentros = launchStore.launchCT.map((centro) => {
                          let obj = launchActive.listBranch.find(group => group.idBranch === centro.idBranch);
                          if (obj) {
                            return {
                              ...centro,
                              comunicationLaunch: obj.comunicationLaunch
                            }
                          } else {
                            return {
                              ...centro
                            }
                          }
                        });
                        setTableCommData({ idEnterprise: enterpriseId, type_launch: sessionStorage.getItem("idTypeLaunch"), listBranch: arrayCentros });
                      }
                      setLaunch(response.listLauch);
                      break;

                    default:
                      toastr.error('Ocurrió un error inesperado, verifique su conexión a internet y vuelva a intentarlo.');
                      break;
                  }
                });
              }
            } else {
              const activeProducts = response.listProducts.filter((product) => {
                if (product.acquired === 1) {
                  return product;
                } else {
                  return null;
                }
              });
              setactiveProducts(activeProducts);
            }
            break;
          case 200:
            // eslint-disable-next-line
            const activeProducts2 = response.listProducts.filter((product) => {
              if (product.acquired === 1) {
                return product;
              }
            });
            setactiveProducts(activeProducts2)
            break;
          default:
            toastr.error("Ocurrió un error recargue la página por favor");
            break;
        }
      })
    } catch (error) {
      console.error(error);
    }

  }, [enterpriseId, launchStore, access.list, history])

  useEffect(() => {

    return () => {
      resetLaunch();
    }
  }, [resetLaunch])


  useEffect(() => {
    let formData = new FormData();
    formData.append("request", JSON.stringify(paramsCal));
    getCalendarCom(formData).then((response) => {
      if (response.code === 1) {
        if (response.data.length === 0) {
          setDisableCheck(true);
        } else {
          setDisableCheck(false);
          let arrNum = [];
          let arr = [];
          let arrAux = [];
          let joinInfoCards = [];
          let getInfoCards = [[], [], [], [], [],];
          setTotalWeeks(response.weeks);
          for (let index = 1; index <= response.weeks; index++) {
            arrNum.push({ index });
          }
          for (let x in arrNum) {
            for (let y in response.data) {
              if (response.data[y].week === arrNum[x].index) {
                let index = response.data[y].day - 2;
                getInfoCards[index] = [...getInfoCards[index], response.data[y]];
                arrAux.push(response.data[y]);
              }
            }
            arr.push(arrAux);
            arrAux = [];
            joinInfoCards.push(getInfoCards);
            getInfoCards = [[], [], [], [], [],];
          }
          setCards(joinInfoCards);
          setInfo(joinInfoCards[0]);
          /* updateFichas(response.data); */
          upLaunchData(response);
          let arrayWeeks = ["2021-01-1"];
          for (var i = 2; i <= response.weeks; i++) {
            arrayWeeks.push('2021-01-' + i)
          }
          /* updweeks(arrayWeeks); */
          setLaunchCommunicationDate(new Date(convert2(response.comunicationDate)));
          if (response.editDateCom === 1) { //se puede editar la fecha de comunicacion
            setEditLaunchCommunicationDate(false);
          }
          $("#is-active-div").removeClass("hide-com");
          setBtnSaveDisabled(false);
        }
        $("#disabled-com").removeClass("hidden");
      }
    });

    return () => {
    }

  }, [paramsCal])


  const onActiveMSG = (e) => {
    try {

      $("#msg-DC").attr("hidden", false);
    } catch (error) {
      console.error(error)
    }
  };
  const offActiveMSG = (e) => {
    try {
      $("#msg-DC").attr("hidden", true);
    } catch (error) {
      console.error(error)
    }
  };
  const isActiveCOmunication = (e) => {
    try {
      let formData = new FormData();
      let data = {};
      if (disableCheck) {
        $("#is-active-div").removeClass("is-active-comunication");
        updParams({
          ...paramsCal,
          status: 1
        });
        data = {
          ...paramsCal,
          status: 1
        }
      } else {
        $("#is-active-div").addClass("is-active-comunication");
        updParams({
          ...paramsCal,
          status: 2
        });
        data = {
          ...paramsCal,
          status: 2
        }
      }
      formData.append("request", JSON.stringify(data));
      DisabledCalendarCom(formData).then((response) => {
        if (response.suceess) {
          toastr.success("Operación realizada correctamente", "¡Exito!");
          setDisableCheck(false);
          formData.append("request", JSON.stringify(paramsCal));
          getCalendarCom(formData).then((response) => {
            if (response.code === 1) {
              if (response.data.length === 0) {
                setDisableCheck(true);
                setEditLaunchCommunicationDate(true);
              } else {
                let arrNum = [];
                let arr = [];
                let arrAux = [];
                let joinInfoCards = [];
                let getInfoCards = [[], [], [], [], [],];
                setTotalWeeks(response.weeks);
                for (let index = 1; index <= response.weeks; index++) {
                  arrNum.push({ index });
                }
                for (let x in arrNum) {
                  for (let y in response.data) {
                    if (response.data[y].week === arrNum[x].index) {
                      let index = response.data[y].day - 2;
                      getInfoCards[index] = [...getInfoCards[index], response.data[y]];
                      arrAux.push(response.data[y]);
                    }
                  }
                  arr.push(arrAux);
                  arrAux = [];
                  joinInfoCards.push(getInfoCards);
                  getInfoCards = [[], [], [], [], [],];
                }
                setCards(joinInfoCards);
                setInfo(joinInfoCards[0]);
                /* updateFichas(response.data); */
                upLaunchData(response);
                let arrayWeeks = ["2021-01-1"];
                for (var i = 2; i <= response.weeks; i++) {
                  arrayWeeks.push('2021-01-' + i)
                }
                /* updweeks(arrayWeeks); */
                setLaunchCommunicationDate(new Date(convert2(response.comunicationDate)));
                if (response.editDateCom === 1) { //se puede editar la fecha de comunicacion
                  setEditLaunchCommunicationDate(false);
                }
                $("#is-active-div").removeClass("hide-com");
              }
              $("#disabled-com").removeClass("hidden");
            }
          });
        } else {
          toastr.error('Hubo un problema. Intente nuevamente.', '¡Ooops!');
          setDisableCheck(true);
        }
      });

    } catch (error) {
      console.error(error);
    }
  };

  //Maneja el cambio de ejercicio
  const getSelectExe = (e) => {
    try {
      if (sessionStorage.getItem("inLaunch") === "1") {
        Swal.fire({
          title: '<p className="text-white mb-2">¡Aún no has terminado!</p>',
          text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
          showCloseButton: true,
          showCancelButton: true,
          focusCancel: true,
          cancelButtonText: 'Cancelar',
          showConfirmButton: true,
          confirmButtonText: "Sí, salir",
          reverseButtons: true,
          dangerMode: true
        }).then((willDelete) => {
          if (willDelete.isConfirmed) {
            sessionStorage.removeItem("inLaunch");
            sessionStorage.removeItem("isEdition");
            sessionStorage.removeItem("idProduct");
            sessionStorage.removeItem("idCategory");
            sessionStorage.removeItem("idSubcategory");
            sessionStorage.removeItem("nameCategory");
            sessionStorage.removeItem("nameSubcategory");
            sessionStorage.removeItem("idTypeLaunch");
            sessionStorage.removeItem("descriptionLaunch");
            sessionStorage.removeItem("flagLaunch");
            sessionStorage.removeItem("launchDate");
            sessionStorage.removeItem("idGroup");
            sessionStorage.removeItem("numCollabSelected");
            history.push("./administracion-servicios");
            let formData = new FormData();
            let params = {
              idEnterprise: enterpriseId,
              idCategorie: categorie,
              idGroup: e.target.value,
              status: 2
            }
            updParams(params);
            formData.append("request", JSON.stringify(params));
            setExerciseSelect(e.target.value);
            getCalendarCom(formData).then((response) => {
              if (response.code === 1) {
                let arrNum = [];
                let arr = [];
                let arrAux = [];
                let joinInfoCards = [];
                let getInfoCards = [[], [], [], [], [],];
                setTotalWeeks(response.weeks);
                for (let index = 1; index <= response.weeks; index++) {
                  arrNum.push({ index });
                }
                for (let x in arrNum) {
                  for (let y in response.data) {
                    if (response.data[y].week === arrNum[x].index) {
                      let index = response.data[y].day - 2;
                      getInfoCards[index] = [...getInfoCards[index], response.data[y]];
                      arrAux.push(response.data[y]);
                    }
                  }
                  arr.push(arrAux);
                  arrAux = [];
                  joinInfoCards.push(getInfoCards);
                  getInfoCards = [[], [], [], [], [],];
                }
                setCards(joinInfoCards);
                setInfo(joinInfoCards[0]);
                /* updateFichas(response.data); */
                upLaunchData(response);
                let arrayWeeks = ["2021-01-1"];
                for (var i = 2; i <= response.weeks; i++) {
                  arrayWeeks.push('2021-01-' + i)
                }
                /* updweeks(arrayWeeks); */
                setLaunchCommunicationDate(new Date(convert2(response.comunicationDate)));
                if (response.editDateCom === 1) { //se puede editar la fecha de comunicacion
                  setEditLaunchCommunicationDate(false);
                }
              }
              // $("#is-active-div").removeClass("hide-com");
              // $("#disabled-com").removeClass("hidden");
            });
          }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
      } else {
        //data for modal
        let idGroup = parseInt(e.target.value, 10);
        let exLaunch = launch.find(element => element.idGroup === idGroup);
        sessionStorage.setItem("idTypeLaunch", exLaunch.type_launch || exLaunch.typeLaunch); //independiente | conjunto
        sessionStorage.setItem("descriptionLaunch", exLaunch.description); //nombre ejercicio
        sessionStorage.setItem("flagLaunch", exLaunch.flagLaunch); // empresa | centros de trabajo
        sessionStorage.setItem("launchDate", exLaunch.launchDate); // fecha lanzamiento
        settipoLanzamiento(exLaunch.type_launch || exLaunch.typeLaunch);
        if (exLaunch.listBranch.length > 0) {
          setTableCommData(exLaunch);
        }
        //data for modal
        let formData = new FormData();
        let params = {
          idEnterprise: enterpriseId,
          idCategorie: categorie,
          idGroup: e.target.value,
          status: 2
        }
        updParams(params);
        formData.append("request", JSON.stringify(params));
        setExerciseSelect(e.target.value);
        getCalendarCom(formData).then((response) => {
          if (response.code === 1) {
            if (response.data.length === 0) {
              setDisableCheck(true);
            } else {
              setDisableCheck(false);
              let arrNum = [];
              let arr = [];
              let arrAux = [];
              let joinInfoCards = [];
              let getInfoCards = [[], [], [], [], [],];
              setTotalWeeks(response.weeks);
              for (let index = 1; index <= response.weeks; index++) {
                arrNum.push({ index });
              }
              for (let x in arrNum) {
                for (let y in response.data) {
                  if (response.data[y].week === arrNum[x].index) {
                    let index = response.data[y].day - 2;
                    getInfoCards[index] = [...getInfoCards[index], response.data[y]];
                    arrAux.push(response.data[y]);
                  }
                }
                arr.push(arrAux);
                arrAux = [];
                joinInfoCards.push(getInfoCards);
                getInfoCards = [[], [], [], [], [],];
              }
              setCards(joinInfoCards);
              setInfo(joinInfoCards[0]);
              /* updateFichas(response.data); */
              upLaunchData(response);
              let arrayWeeks = ["2021-01-1"];
              for (var i = 2; i <= response.weeks; i++) {
                arrayWeeks.push('2021-01-' + i)
              }
              /* updweeks(arrayWeeks); */
              setLaunchCommunicationDate(new Date(convert2(response.comunicationDate)));
              if (response.editDateCom === 1) { //se puede editar la fecha de comunicacion
                setEditLaunchCommunicationDate(false);
              }
              $("#is-active-div").removeClass("hide-com");
            }
            $("#disabled-com").removeClass("hidden");
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* const handleWeeks = (index, prev) => {
    try {
      $("#week-" + prev).addClass("hidden");
      $("#week-" + index).removeClass("hidden");
    } catch (error) {
      console.error(error);
    }
  } */
  //cambio de servicios
  const handleSelectProductChange = (value) => {
    try {
      if (value !== "0") {
        const obj = value.split(",");
        const idcat = parseInt(obj[0]);
        const idsub = parseInt(obj[1]);
        const idprod = parseInt(obj[2]);
        changeProductCategorySubCategory(idprod, idcat, idsub);
      }
      if (sessionStorage.getItem("inLaunch") === "1") {
        Swal.fire({
          title: '<p className="text-white mb-2">¡Aún no has terminado!</p>',
          text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
          showCloseButton: true,
          showCancelButton: true,
          focusCancel: true,
          cancelButtonText: 'Cancelar',
          showConfirmButton: true,
          confirmButtonText: "Sí, salir",
          reverseButtons: true,
          dangerMode: true
        }).then((willDelete) => {
          if (willDelete.isConfirmed) {
            sessionStorage.removeItem("inLaunch");
            sessionStorage.removeItem("isEdition");
            sessionStorage.removeItem("idProduct");
            sessionStorage.removeItem("idCategory");
            sessionStorage.removeItem("idSubcategory");
            sessionStorage.removeItem("nameCategory");
            sessionStorage.removeItem("nameSubcategory");
            sessionStorage.removeItem("idTypeLaunch");
            sessionStorage.removeItem("descriptionLaunch");
            sessionStorage.removeItem("flagLaunch");
            sessionStorage.removeItem("launchDate");
            sessionStorage.removeItem("idGroup");
            sessionStorage.removeItem("numCollabSelected");
            sessionStorage.removeItem("idQuizEnterprise");
            history.push("./administracion-servicios");
            let obj = value.split(",");
            let idcat = obj[0];
            let idsub = obj[1];
            let prod = obj[2];
            setServiceSelect(value);
            upCate(idcat);
            switch (parseInt(idcat)) {
              case 1:
                getLaunches(enterpriseId, prod, idsub, idcat, 1);
                break;
              case 2:
                getLaunches(enterpriseId, prod, idsub, idcat, 2);
                break;
              case 6:
                getLaunches(enterpriseId, prod, idsub, idcat, 6);
                break;
              case 7:
                getLaunches(enterpriseId, prod, idsub, idcat, 7);
                break;
              case 4:
                getLaunches(enterpriseId, prod, idcat, idsub, 4);
                break;
              default:
                toastr.info("otro producto");
                break;
            }
          }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
      } else {
        let obj = value.split(",");
        let idcat = obj[0];
        let idsub = obj[1];
        let prod = obj[2];
        //data for modal
        let idCategory = parseInt(obj[0], 10);
        let idSubcategory = parseInt(obj[1], 10);
        let idProduct = parseInt(obj[2], 10);
        let service = activeProducts.find(element => element.idProduct === idProduct && element.idCategory === idCategory && element.idSubcategory === idSubcategory);
        sessionStorage.setItem("idProduct", service.idProduct); //id producto
        sessionStorage.setItem("idCategory", service.idCategory); //id categoria
        sessionStorage.setItem("idSubcategory", service.idSubcategory); //id subcategoria
        sessionStorage.setItem("nameCategory", service.nameCategory); //nombre categoria
        sessionStorage.setItem("nameSubcategory", service.nameSubcategory); //nombre subcategoria
        //data for modal
        setServiceSelect(value);
        upCate(idcat);
        switch (parseInt(idcat)) {
          case 1:
            getLaunches(enterpriseId, prod, idcat, idsub, 1);
            break;
          case 2:
            getLaunches(enterpriseId, prod, idcat, idsub, 2);
            break;
          case 6:
            getLaunches(enterpriseId, prod, idcat, idsub, 6);
            break;
          case 7:
            getLaunches(enterpriseId, prod, idcat, idsub, 7);
            break;
          case 4:
            getLaunches(enterpriseId, prod, idcat, idsub, 4);
            break;
          default:
            toastr.info("otro producto");
            setLaunch(0);
            break;
        }
        $("#is-active-div").addClass("hide-com");
        $("#disabled-com").addClass("hidden");
        setExerciseSelect("");
        setEditLaunchCommunicationDate(true);
        setLaunchCommunicationDate("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  /* function getLaunches(idEnterprise, idProduct, idCategory, idSubCategory, flagNormative) {
    let arrayLaunches = [];
    let promise1;
    let promise2;
    switch (flagNormative) {
  
      case 1: //lanzamientos NOM
        getHasTypeLaunch(idEnterprise, idProduct, idCategory, idSubCategory).then((response) => {//nos regresa si tiene lanzamientos conjuntos o independientes
          settipoLanzamiento(response.code);
          switch (response?.code) {
            case 1: //lanzamiento independiente
              promise1 = new Promise((resolve, reject) => {
                getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 1, 1).then((response) => {//checamos si tiene lanzamientos normativos
                  if (response?.code === 1) {
                    return resolve(response.infoLastLaunch);
                  } else if (response?.code === 2) {
                    return resolve(response.infoLastLaunchProdBranch);
                  } else {
                    return resolve(undefined);
                  }
                });
              });
              promise2 = new Promise((resolve, reject) => {
                getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 1, 2).then((response) => {//checamos si tiene lanzamientos complementarios
                  if (response?.code === 1) { //tiene lanzamientos complementarios
                    return resolve(response.infoLastLaunch);
                  } else {
                    return resolve(undefined);
                  }
                });
              });
              arrayLaunches = [];
              Promise.all([promise1, promise2]).then(values => {
                if (values[0] !== undefined) {
                  values[0].map(val => arrayLaunches.push(val));
                }
                if (values[1] !== undefined) {
                  values[1].map(val => arrayLaunches.push(val));
                }
                setLaunch(arrayLaunches);
              }).catch(reason => {
                setLaunch(0);
                console.error(reason);
              });
              break;
  
            case 2: //lanzamiento conjunto
              promise1 = new Promise((resolve, reject) => {
                getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 2, 1).then((response) => {//checamos si tiene lanzamientos normativos
                  if (response?.code === 1) {
                    return resolve(response.infoLastLaunch);
                  } else if (response?.code === 2) {
                    return resolve(response.infoLastLaunchProdBranch);
                  } else {
                    return resolve(undefined);
                  }
                });
              });
              promise2 = new Promise((resolve, reject) => {
                getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 2, 2).then((response) => {//checamos si tiene lanzamientos complementarios
                  if (response?.code === 1) { //tiene lanzamientos complementarios
                    return resolve(response.infoLastLaunch);
                  } else {
                    return resolve(undefined);
                  }
                });
              });
              arrayLaunches = [];
              Promise.all([promise1, promise2]).then(values => {
                if (values[0] !== undefined) {
                  values[0].map(val => arrayLaunches.push(val));
                }
                if (values[1] !== undefined) {
                  values[1].map(val => arrayLaunches.push(val));
                }
                setLaunch(arrayLaunches);
              }).catch(reason => {
                setLaunch(0);
                console.error(reason);
              });
              break;
  
            default:
              toastr("OCurrió un problema al cargar la información");
              break;
          }
        });
        break;
  
      case 2: //clima
        getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 1, flagNormative).then((response) => {//checamos si tiene lanzamientos
          if (response?.code === 1) { //tiene lanzamientos
            settipoLanzamiento(1);
            setLaunch(response.infoLastLaunch);
          } else {
            setLaunch([]);
            toastr.error("No se tienen lanzamientos");
          }
        });
        break;
  
      case 6: //factor humano
        getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 1, flagNormative).then((response) => {//checamos si tiene lanzamientos
          if (response?.code === 1) { //tiene lanzamientos
            settipoLanzamiento(1);
            setLaunch(response.infoLastLaunch);
          } else {
            setLaunch([]);
            toastr.error("No se tienen lanzamientos");
          }
        });
        break;
  
      case 7: //regreso seguro
        getInfoLaunch4Product(idEnterprise, idProduct, idCategory, idSubCategory, 1, flagNormative).then((response) => {//checamos si tiene lanzamientos
          if (response?.code === 1) { //tiene lanzamientos
            settipoLanzamiento(1);
            setLaunch(response.infoLastLaunch);
          } else {
            setLaunch([]);
            toastr.error("No se tienen lanzamientos");
          }
        });
        break;
  
      default:
        break;
    }
  } */

  function getLaunches(idEnterprise, idProduct, idCategory, idSubCategory, flagNormative) {
    getLaunchsByService(idEnterprise, idProduct, idCategory, idSubCategory).then(response => {
      switch (response?.code) {
        case 1:
          setLaunch(response.listLauch);
          break;

        default:
          setLaunch(0);
          break;
      }
    });
  }

  function convert2(str) {
    var parts = str.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  const isWeekday = date => {
    const currentDate = new Date();
    const selectedDate = new Date(date);
    const day = getDay(date);
    return day !== 0 && day !== 6 && selectedDate > currentDate;
  };

  function handleLaunchCommunicationDateChange(date) {
    if (date !== "") {
      setLaunchCommunicationDate(date);
      let idProduct = "";
      let idCategory = "";
      let idSubcategory = "";
      let idGroup = sessionStorage.getItem("idGroup");
      let idTypeLaunch = sessionStorage.getItem("idTypeLaunch");
      if (serviceSelect !== "") {
        let obj = serviceSelect.split(",");
        idCategory = obj[0];
        idSubcategory = obj[1];
        idProduct = obj[2];
        idGroup = exerciseSelect;
        idTypeLaunch = tipoLanzamiento;
      }
      setCalendarByLaunch(enterpriseId, idGroup, idProduct, idCategory, idSubcategory, idTypeLaunch, JSON.stringify(date), idQuizEnterprise).then((response) => {
        switch (response?.code) {
          case 1:
            sessionStorage.setItem("communicationDate", date);
            const formData = new FormData();
            const params = {
              idEnterprise: enterpriseId,
              idCategorie: idCategory,
              idGroup: idGroup,
              status: 2
            }
            // setNextFlow(false);
            formData.append("request", JSON.stringify(params));
            getCalendarCom(formData).then((response) => {
              switch (response?.code) {
                case 1:
                  let arrNum = [];
                  let arr = [];
                  let arrAux = [];
                  let joinInfoCards = [];
                  let getInfoCards = [[], [], [], [], [],];
                  setTotalWeeks(response.weeks);
                  for (let index = 1; index <= response.weeks; index++) {
                    arrNum.push({ index });
                  }
                  for (let x in arrNum) {
                    for (let y in response.data) {
                      if (response.data[y].week === arrNum[x].index) {
                        let index = response.data[y].day - 2;
                        getInfoCards[index] = [...getInfoCards[index], response.data[y]];
                        arrAux.push(response.data[y]);
                      }
                    }
                    arr.push(arrAux);
                    arrAux = [];
                    joinInfoCards.push(getInfoCards);
                    getInfoCards = [[], [], [], [], [],];
                  }
                  setCards(joinInfoCards);
                  setInfo(joinInfoCards[0]);
                  /* updateFichas(response.data); */
                  upLaunchData(response);
                  let arrayWeeks = ["2021-01-1"];
                  for (var i = 2; i <= response.weeks; i++) {
                    arrayWeeks.push('2021-01-' + i)
                  }
                  /* updweeks(arrayWeeks); */
                  // setNextFlow(false);
                  if (response.editDateCom === 1) { //se puede editar la fecha de comunicacion
                    setEditLaunchCommunicationDate(false);
                  }
                  setBtnSaveDisabled(false);
                  switch (parseInt(idCategory)) {
                    case 1:
                      getLaunches(enterpriseId, idProduct, idCategory, idSubcategory, 1);
                      break;
                    case 2:
                      getLaunches(enterpriseId, idProduct, idCategory, idSubcategory, 2);
                      break;
                    case 6:
                      getLaunches(enterpriseId, idProduct, idCategory, idSubcategory, 6);
                      break;
                    case 7:
                      getLaunches(enterpriseId, idProduct, idCategory, idSubcategory, 7);
                      break;
                    case 4:
                      getLaunches(enterpriseId, idProduct, idCategory, idSubcategory, 4);
                      break;
                    default:
                      toastr.info("otro producto");
                      break;
                  }
                  $("#is-active-div").removeClass("hide-com");
                  $("#disabled-com").removeClass("hidden");
                  break;

                default:
                  console.error(response)
                  break;
              }
            });
            break;

          default:
            console.error(response)
            break;
        }
      })
    }
  }

  const handleClickSave = function () {
    resetLaunch();
    setShowModal(true);
    $("#modalResume").show();

  }

  const handleAdmin = () => {
    if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
      Swal.fire({
        title: '<p className="text-white mb-2">¡Aún no has terminado!</p>',
        text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
        showCloseButton: true,
        showCancelButton: true,
        focusCancel: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: "Sí, salir",
        reverseButtons: true,
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          sessionStorage.removeItem("inLaunch");
          sessionStorage.removeItem("isEdition");
          sessionStorage.removeItem("idProduct");
          sessionStorage.removeItem("idCategory");
          sessionStorage.removeItem("idSubcategory");
          sessionStorage.removeItem("nameCategory");
          sessionStorage.removeItem("nameSubcategory");
          sessionStorage.removeItem("idTypeLaunch");
          sessionStorage.removeItem("descriptionLaunch");
          sessionStorage.removeItem("flagLaunch");
          sessionStorage.removeItem("launchDate");
          sessionStorage.removeItem("idGroup");
          sessionStorage.removeItem("numCollabSelected");
          sessionStorage.removeItem("idQuizEnterprise");
          history.push("/inicio-administrador");
        }
      });
      $(".swal2-title").addClass("bg-header-swal");
      $(".swal2-actions").addClass("w-100 justify-content-around");
      $(".swal2-confirm").removeClass("swal2-styled");
      $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
      $(".swal2-cancel").removeClass("swal2-styled");
      $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    } else {
      history.push("/inicio-administrador");
    }
  }

  const handleLanz = () => {
    history.push("/administracion-servicios");
  }

  // const handleTokenForwarding = () => {
  //   history.push("/reenvio-fichas");
  // }

  // Prevent browser back button
  const { location: { pathname } } = history;
  useEffect(() => {
    if (pathname !== 'comunicacion') {
      const unblock = history.block((location, action) => {
        let inLaunch = sessionStorage.getItem('inLaunch');
        if (inLaunch === '1') {
          if (window.confirm("Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?")) {
            sessionStorage.removeItem("inLaunch");
            sessionStorage.removeItem("isEdition");
            sessionStorage.removeItem("idProduct");
            sessionStorage.removeItem("idCategory");
            sessionStorage.removeItem("idSubcategory");
            sessionStorage.removeItem("nameCategory");
            sessionStorage.removeItem("nameSubcategory");
            sessionStorage.removeItem("idTypeLaunch");
            sessionStorage.removeItem("descriptionLaunch");
            sessionStorage.removeItem("flagLaunch");
            sessionStorage.removeItem("launchDate");
            sessionStorage.removeItem("idGroup");
            sessionStorage.removeItem("numCollabSelected");
            sessionStorage.removeItem("idQuizEnterprise");
          } else {
            return false;
          }
        }
        return true;
      });
      return () => {
        unblock();
      }
    }
    // eslint-disable-next-line
  }, [pathname]);

  const VALUES = [
    "2018-03-01",
    "2018-03-02",
    "2018-03-03",
    "2018-03-04",
    "2018-03-05",
    "2018-03-06",
    "2018-03-07",
    "2018-03-08",
    "2018-03-09",
    "2018-03-10",
    "2018-03-11",
    "2018-03-12",
    "2018-03-13",
    "2018-03-14",
    "2018-03-15",
    "2018-03-16",
    "2018-03-17",
    "2018-03-18",
  ];
  const [listC, setlistC] = useState({
    curIdx: 0
  });
  const { curIdx } = listC;

  return (

    <div className="app-content bootomFooter">
      <div className="content-overlay"></div>
      <Header />
      <Menu />
      <div className="content-wrapper bootomFooter">
        <div className="content-header">
          <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
            <div className="content-header-right breadcrumb-new mt-1 d-flex justify-content-end">
              <div className="row breadcrumbs-top d-inline-block">
                <div className="breadcrumb-wrapper col-12">
                  <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item font-medium-3 pointer">
                    {nextFlow === true ? (<span onClick={() => goToByLink("/inicio-administrador")}>Administración</span>) : (<a href="/inicio-administrador">Administración</a>)}
                  </li> */}
                    <li
                      className="breadcrumb-item font-medium-3 pointer"
                      onClick={() => handleAdmin()}
                    >Administración</li>
                    <li className="breadcrumb-item active font-medium-3">Configura / Comunicados
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <HelperApp />
          <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mt-2">
            <div className="row">
              <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2">
              </div>
              <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10">
                <h2><strong>Configura tus servicios</strong></h2>
              </div>
              <div className="col-sx-12 col-sm-12 col-md-12 col-lg-2 p-0 mt-2">
                <div className="card">
                  <ul className="list-group enterprise-list">
                    <li className="list-group-item  pointer" data-step="1">
                      {/* {
                        nextFlow === true
                          ?
                          (
                            <span
                              className="ml-1 font-medium-2"
                              onClick={() => {goToByLink("/administracion-servicios"); sessionStorage.setItem("step", 2);}}>Lanzamientos</span>
                          )
                          :
                          (
                            <a href="/administracion-servicios">
                              <span className="ml-1 font-medium-2">Lanzamientos</span>
                            </a>
                          )
                      } */}
                      {
                        nextFlow === true
                          ?
                          (
                            <span
                              className="breadcrumb-item ml-1 font-medium-3 pointer"
                              onClick={() => {
                                handleLanz();
                                sessionStorage.setItem("step", 2);
                              }}
                            >Lanzamientos</span>
                          )
                          :
                          (
                            <span
                              className="breadcrumb-item ml-1 font-medium-3 pointer"
                              onClick={() => handleLanz()}
                            >Lanzamientos</span>
                          )
                      }
                    </li>
                    <li className="list-group-item active pointer" data-step="2" >
                      <span className="ml-1 font-medium-2">Comunicados</span>
                    </li>
                    {/* <li className="list-group-item pointer" data-step="3" >
                      <span
                        className="breadcrumb-item ml-1 font-medium-3 pointer"
                        onClick={() => {
                          handleTokenForwarding();
                        }}
                      >Reenvío de Fichas</span>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-sx-10 col-sm-10 col-md-12 col-lg-10 mt-2">
                <div className="card" style={{ minHeight: "600px" }}>
                  <div className="card-header" style={{ paddingBottom: '0px' }}>
                    {showModal && <ModalResume setNextFlow={setNextFlow} setShowModal={setShowModal} />}
                    <div className='row'>
                      <div className='col-sm-12 col-md-12 col-lg-5'>
                        <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <span className="input-group-text font-medium-2 text-base bg-transparent border-0">Servicio:</span>
                          </div>
                          <select
                            className="form-control"
                            onChange={(e) => handleSelectProductChange(e.target.value)}
                            value={serviceSelect}>
                            {nextFlow === true ? (null) : (<option value="0">Seleccione...</option>)}
                            {
                              activeProducts.map((product, idx) => {
                                return (
                                  <option
                                    key={idx}
                                    value={`${product.idCategory},${product.idSubcategory},${product.idProduct}`}
                                  >
                                    {`${product.nameCategory} ${product.idSubcategory === 1 ? "Fase 1 -" : product.idSubcategory === 2 ? "Fase 2 -" : ""} ${product.nameSubcategory}`}
                                  </option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-5'>
                        <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <span className="input-group-text font-medium-2 text-base bg-transparent border-0">Nombre del ejercicio:</span>
                          </div>
                          <select className="form-control"
                            key="exerciseName"
                            name="exerciseName"
                            id="exerciseName"
                            onChange={getSelectExe}
                            value={exerciseSelect}
                          >
                            {nextFlow === true ? (<option>{sessionStorage.getItem("descriptionLaunch")}</option>) : (<option value="0">Seleccione...</option>)}
                            {
                              launch.length > 0 ?
                                launch.map((data, idx) => {
                                  if (data.idGroup !== 0) {
                                    return (
                                      <option
                                        key={idx}
                                        value={`${data.idGroup}`}
                                      >
                                        {data.description}
                                      </option>
                                    )
                                  } else {
                                    return null
                                  }
                                })
                                : ""
                            }
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-2'>
                        {
                          !disableActions && (
                            paramsCal.idEnterprise !== 0 ? (
                              <div className="row d-flex justify-content-around hidden" id="disabled-com">
                                <p className="globo i abajo-derecha" hidden id="msg-DC">
                                  Al dar clic en esta opción no se enviará información adicional a tus colaboradores
                                </p>
                                <p className="col-md-8 text-gray font-small-2 d-flex flex-wrap align-content-center" id="disabled-com">Deshabilitar comunicación</p>
                                <div
                                  className="div-info-user col-md-4"
                                  onMouseEnter={onActiveMSG}
                                  onMouseLeave={offActiveMSG}
                                >
                                  <label className="switchBtn">
                                    <input
                                      type="checkbox"
                                      id="isActive"
                                      name="isActive"
                                      onChange={isActiveCOmunication}
                                      checked={disableCheck}
                                    />
                                    <div className="slideBTNSwitch round"></div>
                                  </label>
                                </div>
                              </div>
                            ) : (null)
                          )
                        }
                      </div>
                      {tableCommData === null ?
                        <div className="col-12 mt-3">
                          <div className="row">
                            <div className="col-12">
                              <div id="divLaunchCommunication" className="d-flex align-items-center">
                                <Popup trigger={<i className="ft-help-circle icon-size-small icon-guay rounded-circle pointer align-self-center mx-1" />} position="bottom">
                                  <div className="p-1 text-center">
                                    Las fichas que recibirán tus colaboradores ayudaran a sensibilizaros respecto a diversos temas relacionados con el servicio, recuerda que esta comunicación es independiente a el envió de las encuestas y sus resultados
                                  </div>
                                </Popup>
                                <span className="font-medium-2 text-base mr-1">Fecha de inicio de comunicación</span>
                                <Datepicker
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  selected={launchCommunicationDate}
                                  onChange={date => handleLaunchCommunicationDateChange(date)}
                                  filterDate={isWeekday}
                                  excludeDates={new Date().getDay() === 4 ? ([addDays(new Date(), 1), addDays(new Date(), 4)]) : (new Date().getDay() === 5 ? ([addDays(new Date(), 3), addDays(new Date(), 4)]) : ([addDays(new Date(), 1), addDays(new Date(), 2)]))}
                                  placeholderText="Selecciona una fecha"
                                  disabled={disableActions ? disableActions : editLaunchCommunicationDate}
                                  onKeyDown={(e) => { e.preventDefault() }}
                                  autoComplete="off"
                                  id="dateCommunication"
                                />
                                <img src="./../img/icons/iconos_configuracion-07.png" className="mx-1" alt="img-guay" style={{ height: '35px', width: '35px' }} onClick={() => document.getElementById("dateCommunication").click()} />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="col-12 mt-3">
                          <TableCommunicationLaunchDate
                            tableCommData={tableCommData}
                            serviceSelect={serviceSelect}
                            updParams={updParams}
                            enterpriseId={enterpriseId}
                            idQuizEnterprise={idQuizEnterprise}
                          />
                        </div>
                      }
                    </div>
                    <div className="row">
                      {/* <div className="col-md-12 col-lg-5">
                        <div className="col-md-12  d-flex align-items-center">
                          <p className="font-medium-2 text-center p-0 my-0 mx-1 text-base">Servicio:</p>
                          <select className="form-control" onChange={(e) => handleSelectProductChange(e.target.value)}
                            value={serviceSelect}>
                            {nextFlow === true ? (null) : (<option value="0">Seleccione...</option>)}
                            {
                              activeProducts.map((product, idx) => {
                                return (
                                  <option
                                    key={idx}
                                    value={`${product.idCategory},${product.idSubcategory},${product.idProduct}`}


                                  >
                                    {`${product.nameCategory} ${product.idSubcategory === 1 ? "Fase 1 -" : product.idSubcategory === 2 ? "Fase 2 -" : ""} ${product.nameSubcategory}`}
                                  </option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div> */}

                      {/* <div className="col-md-12 col-lg-5">
                        <div className="col-md-12  d-flex align-items-center d-flex justify-content-end">
                          <p className="font-medium-2 text-center p-0 my-0 mx-1 text-base ">Nombre del ejercicio:</p>
                          <select className="form-control col-md-6"
                            key="exerciseName"
                            name="exerciseName"
                            id="exerciseName"
                            onChange={getSelectExe}
                            value={exerciseSelect}
                          >
                            {nextFlow === true ? (<option>{sessionStorage.getItem("descriptionLaunch")}</option>) : (<option value="0">Seleccione...</option>)}
                            {

                              launch.length > 0 ?

                                launch.map((data, idx) => {
                                  if (data.idGroup !== 0) {
                                    return (
                                      <option
                                        key={idx}
                                        value={`${data.idGroup}`}
                                      >
                                        {data.description}
                                      </option>
                                    )
                                  } else {
                                    return null
                                  }
                                })
                                : ""
                            }
                          </select>
                        </div>
                      </div> */}
                      {/* {tableCommData === null ?
                        <div className="col-12 mt-3">
                          <div className="row">
                            <div className="col-12">
                              <div id="divLaunchCommunication" className="d-flex align-items-center">
                                <Popup trigger={<i className="ft-help-circle icon-size-small icon-guay rounded-circle pointer align-self-center mx-1" />} position="bottom">
                                  <div className="p-1 text-center">
                                    Las fichas que recibirán tus colaboradores ayudaran a sensibilizaros respecto a diversos temas relacionados con el servicio, recuerda que esta comunicación es independiente a el envió de las encuestas y sus resultados
                                  </div>
                                </Popup>
                                <span className="font-medium-2 text-base mr-1">Fecha de inicio de comunicación</span>
                                <Datepicker
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  selected={launchCommunicationDate}
                                  onChange={date => handleLaunchCommunicationDateChange(date)}
                                  filterDate={isWeekday}
                                  excludeDates={new Date().getDay() === 4 ? ([addDays(new Date(), 1), addDays(new Date(), 4)]) : (new Date().getDay() === 5 ? ([addDays(new Date(), 3), addDays(new Date(), 4)]) : ([addDays(new Date(), 1), addDays(new Date(), 2)]))}
                                  placeholderText="Selecciona una fecha"
                                  disabled={editLaunchCommunicationDate}
                                  onKeyDown={(e) => { e.preventDefault() }}
                                  autoComplete="off"
                                  id="dateCommunication"
                                />
                                <img src="./../img/icons/iconos_configuracion-07.png" className="mx-1" alt="img-guay" style={{ height: '35px', width: '35px' }} onClick={() => document.getElementById("dateCommunication").click()} />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="col-12 mt-3">
                          <TableCommunicationLaunchDate tableCommData={tableCommData} serviceSelect={serviceSelect} updParams={updParams} enterpriseId={enterpriseId} />
                        </div>
                      } */}
                    </div>


                    <div className="heading-elements col-md-2 ">
                      <p className="globo i abajo-derecha" hidden id="msg-DC">
                        Al dar clic en esta opción no se enviará información adicional a tus colaboradores
                      </p>
                      <ul className="list-inline mb-0 d-flex justify-content-end">

                        {/* <div className="row d-flex justify-content-around hidden" id="disabled-com">
                          {
                            paramsCal.idEnterprise !== 0 ? (
                              <Fragment>
                                <p className=" col-md-8 text-gray font-small-2">Deshabilitar comunicación</p>
                                <div
                                  className="div-info-user col-md-4"
                                  onMouseEnter={onActiveMSG}
                                  onMouseLeave={offActiveMSG}
                                >
                                  <label className="switchBtn">
                                    <input
                                      type="checkbox"
                                      id="isActive"
                                      name="isActive"
                                      onChange={isActiveCOmunication}
                                      checked={disableCheck}
                                    />
                                    <div className="slideBTNSwitch round"></div>
                                  </label>
                                </div>
                              </Fragment>
                            ) : (null)
                          }
                        </div> */}
                      </ul>
                    </div>
                  </div>
                  <div className="card-content collapse show  mt-3  hide-com" id="is-active-div">
                    <h4 className="text-center text-danger">Duración {launchData ? launchData.weeks : ""} semanas</h4>
                    <h4 className="text-center text-base">Fecha de Lanzamiento: {launchData ? launchData.launchDate : ""}</h4>
                    <div className="col-12">
                      <div
                        style={{
                          width: "75%",
                          height: "100px",
                          margin: "0 auto"
                        }}
                      >
                        <HorizontalTimeline
                          styles={{ outline: "silver", foreground: "#19295C" }}
                          index={curIdx}
                          indexClick={index => {
                            let h = totalWeeks - 1;
                            if (index > h) {
                            } else {
                              let arr = cards[index];
                              setInfo(arr);
                              setlistC({ curIdx: index, prevIdx: curIdx });
                            }
                          }}
                          values={VALUES}
                          getLabel={function (date, index) {
                            let obj = date.split("-");
                            let h = totalWeeks - 1;
                            if (index > h) {
                              return "";
                            } else {
                              return "Semana " + obj[2];
                            }
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {
                            info.map((data, index) => (
                              <div className="col-sm-12 col-md-12 col-lg-2 mx-auto">
                                <div className="card box-shadow-0 border-secondary-guay">
                                  <div className="guay-card-header bg-secondary-guay">
                                    <h4 className="guay-card-title text-white">{index === 0 ? "Lunes" : index === 1 ? "Martes" : index === 2 ? "Miercoles" : index === 3 ? "Jueves" : "Viernes"}</h4>
                                  </div>
                                  {
                                    data.length > 0 ? (
                                      <div className="col">
                                        {
                                          data.map((data_2, index) => (
                                            <div className={"card-guay-" + data_2.idGlosario} key={data_2.idficha}>
                                              <div className="card-content">
                                                <p className="card-text-guay" key={data_2.idFicha}>{data_2.ficha}</p>
                                                <p className="card-text-guay font-small-5">Fecha: {data_2.date}</p>
                                              </div>
                                            </div>
                                          ))
                                        }
                                      </div>
                                    ) : (
                                      null
                                    )
                                  }
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                    <div className="card-content collapse show ">
                      <div className="card-body px-5 mx-2 mb-0">
                        <div className="card-text">
                          <h4>Glosario</h4>
                        </div>
                        <div className="row p-1 mb-0">
                          <div className="row col-md-6">
                            <p className="card-guay-LE pr-1" style={{ maxWidth: "15px", maxHeight: "15px", paddingTop: "-10px" }}></p>
                            <p style={{ paddingTop: "8px", paddingLeft: "10px" }}>Programa de comunicación y capacitación (todos)
                            </p>
                          </div>
                          {/* <div className="row col-md-6">
                            <p className="card-guay-CC pr-1" style={{ maxWidth: "15px", maxHeight: "15px", paddingTop: "-10px" }}></p>
                            <p style={{ paddingTop: "8px", paddingLeft: "10px" }}>Comunicación y capacitación (Lideres)</p>
                          </div> */}
                          <div className="row col-md-6">
                            <p className="card-guay-PCC pr-1" style={{ maxWidth: "15px", maxHeight: "15px", paddingTop: "-10px" }}></p>
                            <p style={{ paddingTop: "8px", paddingLeft: "10px" }}>Elementos generales (encuestas, recordatorios)</p>
                          </div>
                          <div className="row col-md-6">
                            <p className="card-guay-Info pr-1" style={{ maxWidth: "15px", maxHeight: "15px", paddingTop: "-10px" }}></p>
                            <p style={{ paddingTop: "8px", paddingLeft: "10px" }}>Programa de comunicación y capacitación (líderes)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row col-12 d-flex justify-content-end mt-0">
                        <button
                          className="btn btn-save-blue btn-sm font-medium-1 px-3 mb-2 mr-3"
                          onClick={handleClickSave}
                          disabled={disableActions ? disableActions : btnSaveDisabled}
                        >Guardar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div >
    </div >
  )

}

const mapStateToProps = ({ launch, access }) => {
  return {
    launchStore: launch,
    access
  }
}

export default connect(mapStateToProps, { resetLaunch, changeProductCategorySubCategory })(Calendar);