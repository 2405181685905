import React, { useState, useEffect } from 'react';
import { getJwt } from '../../../lib/auth';
import { getInfoLaunch4Product } from '../Models/Enterprise_model';
import { getCollabByLaunch, getCollabWithOutLaunch, saveComLaunch, setCancelLaunch } from '../Models/Launch_model';
import DescParagraphComponent from './DescParagraphComponent';
import FechaLanzamiento from './FechaLanzamiento';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import $ from 'jquery';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

const LanzamientoComplementario = ({
    setloading,
    productData,
    tipoLanzamiento,
    idGroup,
    setidGroup,
    idAdminLaunch,
    setidAdminLaunch,
    setShowTable,
    setGeneralPreview,
    setOnlyViewFlag,
    disableActions,
    customSurveys,
}) => {

    //get data from cookies
    const enterpriseId = getJwt("enterprise");
    const userId = getJwt("jtw");

    //get data from session
    let collabs = "0";
    let numCollabs2 = 0;
    let descriptionLaunch2 = "";
    let selectLaunch2 = undefined;
    let allowEdit2 = true;
    let checkCollabs2 = "";
    if (sessionStorage.getItem("collabs") !== null) {
        collabs = sessionStorage.getItem("collabs");
        numCollabs2 = sessionStorage.getItem("collabs").split(",").length;
    }
    if (sessionStorage.getItem("descriptionLaunch") !== null) {
        descriptionLaunch2 = sessionStorage.getItem("descriptionLaunch");
    }
    if (sessionStorage.getItem("selectValue") !== null) {
        selectLaunch2 = sessionStorage.getItem("selectValue");
        allowEdit2 = false;
        checkCollabs2 = "nuevo";
    }

    //getters & setters
    const [launchesComp, setLaunchesComp] = useState([]);
    const [selectLaunch, setSelectLaunch] = useState(selectLaunch2);
    const [descriptionLaunch, setDescriptionLaunch] = useState(descriptionLaunch2);
    const [checkbox, setcheckbox] = useState(false);
    const [launchDate, setlaunchDate] = useState(null);
    const [idReminder, setidReminder] = useState(undefined);
    const [editBtnState, setEditBtnState] = useState(false);
    const [allowEdit, setallowEdit] = useState(allowEdit2);
    const [dateEdit, setDateEdit] = useState(true);
    const [numCollabs, setnumCollabs] = useState(numCollabs2);
    const [checkCollabs, setcheckCollabs] = useState(checkCollabs2);
    const [optionVal, setoptionVal] = useState(true);
    // eslint-disable-next-line
    const [updateComponent, setupdateComponent] = useState(false);
    const history = useHistory();
    const [launchData, setLaunchData] = useState({
        idGroup: null,
        eliminate: null,
    });
    const [listIdQuizEnterprise, setListIdQuizEnterprise] = useState([]);
    const [surveyChecked, setSurveyChecked] = useState(0);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        /* sessionStorage.removeItem("descriptionLaunch");
        sessionStorage.removeItem("selectValue"); */
        // sessionStorage.removeItem('settipoLanzamiento');
        setSurveyChecked(0)
        getInfoLaunch4Product(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory, 1, tipoLanzamiento).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        //tiene lanzamientos complementarios
                        setLaunchesComp(response.infoLastLaunch);
                        setListIdQuizEnterprise(response.listIdQuizEnterprise)
                        if (sessionStorage.getItem("selectValue") !== null) {
                            /* sessionStorage.setItem("isEdition", 1); */
                            setSelectLaunch(sessionStorage.getItem("selectValue"));
                            sessionStorage.setItem("selectValue", sessionStorage.getItem("selectValue"));
                            const idLaunchAdmin = parseInt(sessionStorage.getItem("selectValue"), 10);
                            // eslint-disable-next-line
                            response.infoLastLaunch.map((launch) => {
                                if (launch.idAdminLaunch === idLaunchAdmin) {
                                    setDescriptionLaunch(launch.description);
                                    findCollabs(sessionStorage.getItem("selectValue"), launch.idGroup, launch.idAdminLaunch);
                                    setcheckCollabs("lanzamiento");
                                    setidGroup(launch.idGroup);
                                    setidAdminLaunch(launch.idAdminLaunch);
                                    setallowEdit(true);
                                    setcheckbox(true);
                                    setDateEdit(true);
                                    setlaunchDate(new Date(convert2(launch.launchDate)));
                                    setidReminder(launch.idReminderLaunch);
                                    // setdisplayDate(true);
                                    if (launch.edit === 1) {
                                        setEditBtnState(true);
                                        // setallowEdit(true);
                                    }
                                    // checkbox.current.checked = true;
                                    sessionStorage.setItem("eliminate", launch.eliminate);
                                    setLaunchData({
                                        idGroup: launch.idGroup,
                                        eliminate: launch.eliminate,
                                    });
                                }
                            });
                        }
                        getCollabWithOutLaunch(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory).then((response) => {
                            if (response) {
                                if (response.code === 1) {
                                    setoptionVal(false);
                                } else {
                                    setoptionVal(true);
                                }
                            }
                        });
                        break;
                    case 4:
                        setoptionVal(false);
                        break;
                    case 1002:
                        toastr.info("Ya hay un lanzamiento programado que contempla a todos los colaboradores");
                        sessionStorage.removeItem("descriptionLaunch");
                        sessionStorage.removeItem("selectValue");
                        setoptionVal(true);
                        break;
                    case 1003:
                        toastr.info("Ya hay un lanzamiento programado que contempla a todos los colaboradores");
                        sessionStorage.removeItem("descriptionLaunch");
                        sessionStorage.removeItem("selectValue");
                        setoptionVal(true);
                        break;
                    case 1004:
                        toastr.info("Ya hay un lanzamiento programado que contempla a todos los colaboradores");
                        sessionStorage.removeItem("descriptionLaunch");
                        sessionStorage.removeItem("selectValue");
                        setoptionVal(true);
                        break;
                    default:
                        console.log("response", response)
                        break;
                }
            }
        });
        return () => {
        }
        // eslint-disable-next-line
    }, [enterpriseId, productData.idCategory, productData.idProduct, productData.idSubcategory, tipoLanzamiento])

    //Function to find the numbers of collabs selected
    const findCollabs = (collabState, idGroup, idAdminLaunch) => {
        switch (collabState) {
            case "":
                setnumCollabs(0);
                break;
            case "nuevo":
                getCollabWithOutLaunch(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory).then((response) => {
                    if (response) {
                        if (response.code === 1) {
                            setnumCollabs(response.listCollab.length);
                        }
                    }
                });
                break;
            default:
                getCollabByLaunch(enterpriseId, idGroup, idAdminLaunch).then((response) => {
                    if (response) {
                        if (response.code === 1) {
                            setnumCollabs(response.listCollab.length);
                        }
                    }
                });
                break;
        }
    }

    //function to convert type date to string format required to send to db
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    //function to convert string format to type date required to datepicker component
    function convert2(str) {
        var parts = str.match(/(\d+)/g);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }

    const handleSelectLaunchChange = (selectValue) => {
        switch (selectValue) {
            case "":
                setSelectLaunch("");
                setDescriptionLaunch("");
                findCollabs(selectValue);
                setcheckCollabs("");
                setallowEdit(true);
                setcheckbox(false);
                setDateEdit(true);
                setlaunchDate(null);
                setidReminder(undefined);
                setEditBtnState(false);
                setidGroup(0);
                setidAdminLaunch(0);
                sessionStorage.removeItem("inLaunch");
                sessionStorage.removeItem("isEdition");
                sessionStorage.removeItem("selectValue");
                sessionStorage.removeItem("collabs");
                setLaunchData({
                    idGroup: null,
                    eliminate: null,
                });
                break;
            case "nuevo":
                sessionStorage.setItem("isEdition", 0);
                setSelectLaunch(selectValue);
                sessionStorage.setItem("selectValue", selectValue);
                if (sessionStorage.getItem("collabs") === null) {
                    findCollabs(selectValue, 0, 0);
                    setDescriptionLaunch(descriptionLaunch2);
                } else {
                    setDescriptionLaunch("");
                    setnumCollabs(numCollabs2);
                }
                setcheckCollabs("nuevo");
                setcheckbox(false);
                setDateEdit(true);
                setlaunchDate(null);
                setidReminder(undefined);
                setallowEdit(false);
                setEditBtnState(false);
                setidGroup(0);
                setidAdminLaunch(0);
                setLaunchData({
                    idGroup: null,
                    eliminate: null,
                });
                break;
            default:
                sessionStorage.setItem("isEdition", 1);
                setSelectLaunch(selectValue);
                sessionStorage.setItem("selectValue", selectValue);
                const idLaunchAdmin = parseInt(selectValue, 10);
                if (listIdQuizEnterprise.length > 0) {
                    for (let x in listIdQuizEnterprise) {
                        if(listIdQuizEnterprise[0] !== null){
                            if (idLaunchAdmin === listIdQuizEnterprise[x].idAdminLaunch) {
                                setSelected(listIdQuizEnterprise[0].idQuizEnterprise)
                                setSurveyChecked(1)
                            }
                        }
                    }
                }
                // eslint-disable-next-line
                launchesComp.map((launch) => {
                    if (launch.idAdminLaunch === idLaunchAdmin) {
                        setDescriptionLaunch(launch.description);
                        findCollabs(selectValue, launch.idGroup, launch.idAdminLaunch);
                        setcheckCollabs("lanzamiento");
                        setidGroup(launch.idGroup);
                        setidAdminLaunch(launch.idAdminLaunch);
                        setallowEdit(true);
                        setcheckbox(true);
                        setDateEdit(true);
                        setlaunchDate(new Date(convert2(launch.launchDate)));
                        setidReminder(launch.idReminderLaunch);
                        // setdisplayDate(true);
                        if (launch.edit === 1) {
                            setEditBtnState(true);
                            // setallowEdit(true);
                        } else {
                            setEditBtnState(false);
                        }
                        // checkbox.current.checked = true;
                        sessionStorage.setItem("eliminate", launch.eliminate);
                        setLaunchData({
                            idGroup: launch.idGroup,
                            eliminate: launch.eliminate,
                        });
                    }
                });
                break;
        }
    }

    const handleInputLaunchNameChange = (inputValue) => {
        setDescriptionLaunch(inputValue);
        sessionStorage.setItem('descriptionLaunch', inputValue);
    }

    const handleCheckboxChange = (checkboxValue) => {
        setcheckbox(checkboxValue);
        setDateEdit(!checkboxValue);
    }

    const handleClickCancelLaunch = function () {
        Swal.fire({
            title: '<p class="text-white mb-2">¡Atención</p>',
            text: "¿Estás seguro de eliminar el lanzamiento? ",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Si, eliminar",
            reverseButtons: true,
            dangerMode: true
        }).then((response) => {
            if (response.isConfirmed) {
                setCancelLaunch(enterpriseId, launchData.idGroup).then(response => {
                    switch (response?.code) {
                        case 1:
                            toastr.success("Se ha eliminado el lanzamiento correctamente", "Éxito");
                            sessionStorage.setItem('settipoLanzamiento', '2');
                            window.location.reload();

                            break;
                        case 1001:
                            toastr.info("Su organización se encuentra desactivada", "Error");
                            break;
                        case 1002:
                            toastr.error("No se ha podido eliminar el lanzamiento", "Error");
                            break;

                        default:
                            toastr.error("Ocurrió un error inesperado, verifique su conexión a internet.", "Error");
                            break;
                    }
                });
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }

    const handleClickEdit = () => {
        setEditBtnState(false);
        setallowEdit(false);
        setDateEdit(false);
        sessionStorage.setItem('isEdition', 1);
    }

    const handleClickSave = () => {
        if (descriptionLaunch !== "" || launchDate !== null) {
            setloading(true);
            sessionStorage.removeItem("selectValue");
            sessionStorage.removeItem("descriptionLaunch");
            sessionStorage.removeItem("collabs");
            const dateStr = JSON.stringify(convert(launchDate));
            const userStr = JSON.stringify(userId);
            const descStr = JSON.stringify(descriptionLaunch);
            const flagLaunch = 2;
            let flagAllCollabs = null;
            if (collabs === "0") {
                flagAllCollabs = true;
            } else {
                flagAllCollabs = false;
            }
            let idReminderLaunch = 0;
            if (idReminder !== undefined) {
                idReminderLaunch = idReminder;
            }
            const idQuizEnterprise = surveyChecked === 1 ? selected : surveyChecked
            saveComLaunch(
                enterpriseId,
                productData.idProduct,
                productData.idCategory,
                productData.idSubcategory,
                idReminderLaunch,
                collabs,
                descStr,
                dateStr,
                userStr,
                flagLaunch,
                productData.dateOfExpiry,
                flagAllCollabs,
                idAdminLaunch,
                idQuizEnterprise,
            ).then((response) => {
                if (response) {
                    setloading(false);
                    switch (response.code) {
                        case 1:
                            sessionStorage.setItem("idProduct", productData.idProduct);
                            sessionStorage.setItem("idCategory", productData.idCategory);
                            sessionStorage.setItem("idSubcategory", productData.idSubcategory);
                            sessionStorage.setItem("nameCategory", productData.nameCategory);
                            sessionStorage.setItem("nameSubcategory", productData.nameSubcategory);
                            sessionStorage.setItem("idTypeLaunch", 1); //independiente complementario
                            sessionStorage.setItem("descriptionLaunch", descriptionLaunch); //nombre ejercicio
                            sessionStorage.setItem("numCollabSelected", numCollabs); //numero de colaboradroes
                            sessionStorage.setItem("flagLaunch", 2); // centros de trabajo
                            sessionStorage.setItem("launchDate", launchDate); // fecha lanzamiento
                            sessionStorage.setItem("idQuizEnterprise", idQuizEnterprise); // encuesta personalizada
                            if (sessionStorage.getItem("isEdition") !== "1") {
                                sessionStorage.setItem("inLaunch", 1);
                                sessionStorage.removeItem("isEdition");
                                sessionStorage.setItem("idGroup", response.idGroup);
                                history.push("/comunicacion");
                            }
                            setupdateComponent(true);
                            toastr.success("Ahora revisa la comunicación de las fichas de sensibilización", "Cambios guardados exitosamente");
                            break;
                        case 2:
                            sessionStorage.setItem("idProduct", productData.idProduct);
                            sessionStorage.setItem("idCategory", productData.idCategory);
                            sessionStorage.setItem("idSubcategory", productData.idSubcategory);
                            sessionStorage.setItem("nameCategory", productData.nameCategory);
                            sessionStorage.setItem("nameSubcategory", productData.nameSubcategory);
                            sessionStorage.setItem("idTypeLaunch", 1); //independiente complementario
                            sessionStorage.setItem("descriptionLaunch", descriptionLaunch); //nombre ejercicio
                            sessionStorage.setItem("numCollabSelected", numCollabs); //numero de colaboradroes
                            sessionStorage.setItem("flagLaunch", flagLaunch); // centros de trabajo
                            sessionStorage.setItem("launchDate", launchDate); // fecha lanzamiento
                            sessionStorage.setItem("idQuizEnterprise", idQuizEnterprise); // encuesta personalizada
                            if (sessionStorage.getItem("isEdition") !== "1") {
                                sessionStorage.setItem("inLaunch", 1);
                                sessionStorage.removeItem("isEdition");
                                sessionStorage.setItem("idGroup", response.idGroup);
                                history.push("/comunicacion");
                            }
                            setupdateComponent(true);
                            toastr.success("Ahora revisa la comunicación de las fichas de sensibilización", "Cambios guardados exitosamente");
                            break;
                        case 1001:
                            toastr.error("Error no se encontró la organización asociada", "Error");
                            break;
                        case 1002:
                            toastr.error("No hay centros de trabajo activos", "Error");
                            break;
                        case 1003:
                            toastr.error("La organización ya cuenta con un ejercicio en progreso", "Error");
                            break;
                        case 1004:
                            toastr.error("La organización ya cuenta con un ejercicio en progreso por centros de trabajo", "Error");
                            break;
                        case 1005:
                            toastr.error("La organización ya cuenta con un ejercicio activo para toda la empresa.", "Error");
                            break;
                        default:
                            toastr.error("Error desconocido", "Error");
                            break;
                    }
                }
            });
        } else {
            toastr.warning("Debes de llenar toda la información", "Importante");
        }
    }

    const GreenRadio = withStyles({
        root: {
            color: disableActions ? ('#d8d8d9') : ('#15253c'),
            '&$checked': {
                color: disableActions ? ('#d8d8d9') : ('#9cb537'),
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-11">
                <DescParagraphComponent
                    enterpriseId={enterpriseId}
                    disableActions={disableActions}
                />
            </div>
            <div className='col-11 mb-2'>
                <div className="row">
                    <div className="col-12 d-flex align-items-center font-medium-2 text-base">
                        <span>¿Deseas agregar una encuesta personalizada?</span>
                        <label className="align-items-center ml-2 my-0">
                            Si
                        </label>
                        <GreenRadio
                            checked={surveyChecked === 1 ? true : false}
                            onChange={() => {
                                if (customSurveys.length > 0) {
                                    setSurveyChecked(1)
                                } else {
                                    toastr.info("No tienes encuestas personalizadas. Primero crea una en la sección Encuesta.", "¡Ooops!")
                                }
                            }}
                            value="a"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            size="small"
                            disabled={disableActions ? disableActions : optionVal ? allowEdit : optionVal}
                        />
                        <label className="align-items-end ml-2 my-0">
                            No
                        </label>
                        <GreenRadio
                            checked={surveyChecked === 0 ? true : false}
                            onChange={() => setSurveyChecked(0)}
                            value="a"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            size="small"
                            disabled={disableActions ? disableActions : optionVal ? allowEdit : optionVal}
                        />
                    </div>
                </div>
            </div>
            {
                surveyChecked === 1 && (
                    <div className="col-11 mb-2">
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6 d-flex align-items-center'>
                                <span className="font-medium-2 text-base mr-1">Nombre de la encuesta</span>
                                <select
                                    className="form-control col-6 mr-1"
                                    value={selected}
                                    onChange={e => {
                                        setSelected(e.target.value)
                                    }}
                                    disabled={disableActions ? disableActions : optionVal ? allowEdit : optionVal}
                                >
                                    <option value={0}>- Selecciona -</option>
                                    {
                                        customSurveys.map((survey) => (
                                            <option
                                                key={survey.value}
                                                value={survey.value}
                                            >{survey.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="col-11 mb-2">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <p className="font-medium-2 text-base mr-1">Nombre del ejercicio</p>
                        <select className="form-control col-3" onChange={(e) => handleSelectLaunchChange(e.target.value)} value={selectLaunch}>
                            <option value="">- Selecciona -</option>
                            <option
                                value="nuevo"
                                disabled={disableActions ? disableActions : optionVal}
                            >Nuevo</option>
                            {
                                launchesComp.map((launches, idx) => {
                                    return (
                                        <option key={idx} value={launches.idAdminLaunch}>{launches.description}</option>
                                    );
                                })
                            }
                        </select>
                        <Popup trigger={<i className="ft-info icon-size icon-guay rounded-circle mx-1" />} position="top right">
                            <div className="p-1 text-center">
                                Para programar un nuevo ejercicio selecciona la opción <span className="font-weight-bold">“Nuevo”</span>, luego registra su nombre. Para consultar ejercicios programados selecciona el que quieras consultar.
                            </div>
                        </Popup>
                        {
                            selectLaunch === "nuevo" ?
                                <input
                                    className="form-control col-4"
                                    maxLength={100}
                                    placeholder="Ej. Lanzamiento complementario 2021"
                                    onChange={(e) => handleInputLaunchNameChange(e.target.value)}
                                    value={descriptionLaunch}
                                    disabled={disableActions}
                                />
                                :
                                null
                        }
                    </div>
                </div>
            </div>
            <div className="col-11 mb-2">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <p className="font-medium-2 text-base">
                            Para el lanzamiento a programar se mandará a <span className="font-weight-bold text-dark">{numCollabs} colaboradores</span>
                            {checkCollabs === "nuevo" ? <ins className="text-blue ml-1 pointer" onClick={() => setShowTable(true)}>Modificar</ins>
                                : checkCollabs === "lanzamiento" ? <ins className="text-blue ml-1 pointer" onClick={() => {
                                    if (launchData.eliminate === 1) {
                                        setGeneralPreview(true);
                                    } else {
                                        setGeneralPreview(true);
                                        setOnlyViewFlag(true);
                                    }
                                }}>Visualizar</ins>
                                    : null
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-11 mb-2">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <p className="font-medium-2 text-base mr-1">¿Es correcto?</p>
                        <label className="switch">
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e.target.checked)}
                                checked={checkbox}
                                disabled={disableActions ? disableActions : allowEdit}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-11 mb-2">
                <FechaLanzamiento
                    launchDate={launchDate}
                    setlaunchDate={setlaunchDate}
                    idReminder={idReminder}
                    setidReminder={setidReminder}
                    allowEdit={dateEdit}
                    productData={productData}
                    disableActions={disableActions}
                />
            </div>
            <div className="col-12">
                    <div className="col-12 d-flex justify-content-end">
                        {launchData.eliminate === 1 &&
                            <button
                                className="btn btn-sm btn-blue-alert font-medium-2 px-5 mx-1"
                                onClick={handleClickCancelLaunch}
                                disabled={disableActions}
                            >Eliminar</button>}
                        {editBtnState ?
                            <button
                                className="btn btn-guay btn-sm font-medium-2 px-5 mx-4"
                                onClick={handleClickEdit}
                                disabled={disableActions}
                            >Editar</button> : null}
                        <button
                            className="btn btn-save-blue btn-sm font-medium-2 px-5"
                            onClick={handleClickSave}
                            disabled={disableActions ? disableActions : allowEdit}
                        >Continuar</button>
                    </div>
            </div>
        </div>
    )
}

export default LanzamientoComplementario
