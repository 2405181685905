import React from 'react'

const PromotionsCardInfo = ({
    cardData
}) => {
    return (
        <div
            className="modal"
            id="cardModal"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div
                    className="modal-content card"
                    style={{ borderRadius: '20px' }}
                >
                    <div className="modal-body card-body">
                        <div className='col-12'>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col'>
                                        <img
                                            style={{
                                                width: '75px',
                                                height: '75px',
                                            }}
                                            src="./../img/promotions/logo_sigma.png"
                                            alt='icons'
                                        />
                                    </div>
                                    <div className='col text-center'>
                                        <span className='font-weight-bold font-large-1'>Tarjeta Digital</span>
                                    </div>
                                    <div className='col d-flex justify-content-end'>
                                        <img
                                            className='pointer'
                                            style={{
                                                width: '25px',
                                                height: '25px',
                                            }}
                                            src="./../img/promotions/icono_cerrar_activo.png"
                                            alt='icons'
                                            data-dismiss="modal"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='col-3 mx-auto card-info-guay'>
                                    <div className='row'>
                                        <div className='col-12 card-info-guay-in py-1'>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className='col-2 d-flex justify-content-center flex-wrap align-content-center'>
                                                        <img
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                            }}
                                                            src="./../img/promotions/icono_cuadro_arriba.png"
                                                            alt='icons'
                                                        />
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='m-0 text-secondary font-small-2'>Nombre del beneficiario</p>
                                                        <p className='m-0 font-weight-bold'>{cardData.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <span className='font-weight-bold'>Póliza: <span className='font-weight-normal'>{cardData.policy}</span></span>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <span className='font-weight-bold'>Núm identificación: <span className='font-weight-normal'>{cardData.idCURP}</span></span>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <span className='font-weight-bold'>Vigencia: <span className='font-weight-normal'>{cardData.validity}</span></span>
                                            </div>
                                            <div className='col-12 d-flex justify-content-end'>
                                                <img
                                                    className='w-50'
                                                    src="./../img/promotions/icono_rayas_abajo.png"
                                                    alt='icons'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <p className='text-center m-0'>Los beneficios se proporcionarán con base a los servicios contratados.</p>
                            </div>
                            <div className='col-12 mb-2'>
                                <p className='text-center m-0 font-weight-bold'>Conoce los beneficios llamando a:</p>
                            </div>
                            <div
                                className='col-3 mx-auto mb-2 pointer'
                                onClick={() => window.open(`callto:5589505489`)}
                            >
                                <div className='card box-shadow-guay-1'>
                                    <div className='card-body'>
                                        <div className='col-12 mb-1'>
                                            <p className='text-center font-weight-bold m-0'>5589505489</p>
                                        </div>
                                        <div className='col-12 mb-1 d-flex justify-content-center'>
                                            <img
                                                className='img-fluid'
                                                src="./../img/promotions/icono_telefono_activo.png" alt='icons' />
                                        </div>
                                        <div className='col-12'>
                                            <p className='text-center font-weight-bold m-0'>24 horas, 365 días al año</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <p className='text-center font-weight-bold'>Otros beneficios y descuentos:</p>
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='row d-flex justify-content-center'>
                                    <div className='col-2'>
                                        <div className='card h-100 px-2 box-shadow-guay-1'>
                                            <div className='card-body p-0 d-flex justify-content-center flex-wrap align-content-center'>
                                                <span className='font-weight-bold text-center'>Farmacias presentando receta digital</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className='card h-100 px-2 box-shadow-guay-1'>
                                            <div className='card-body p-0 d-flex justify-content-center flex-wrap align-content-center'>
                                                <span className='font-weight-bold text-center'>Laboratorios médicos</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className='card h-100 px-2 box-shadow-guay-1'>
                                            <div className='card-body p-0 d-flex justify-content-center flex-wrap align-content-center'>
                                                <span className='font-weight-bold text-center'>Ópticas</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className='card h-100 px-2 box-shadow-guay-1'>
                                            <div className='card-body p-0 d-flex justify-content-center flex-wrap align-content-center'>
                                                <span className='font-weight-bold text-center'>Gimnasios</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionsCardInfo