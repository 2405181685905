/*
 File Name: Enterprise_model.js
 Description: Manage Enterprises API Requests
 guay dev's team
*/

import axios from "axios";

export const saveNomLaunch = async (
    enterpriseId,
    productId,
    categoryId,
    subcategoryId,
    flagNormative,
    nameLaunch,
    typeLaunchId,
    reminderLaunchId,
    launchDate,
    userId,
    flagLaunch,
    listBranchLaunch,
    idQuizEnterprise,
) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('request', `{
        "enterpriseId":${enterpriseId},
        "idProduct": ${productId},
        "idCategory": ${categoryId},
        "idSubcategory": ${subcategoryId},
        "flgNormative": ${flagNormative},
        "nameLaunch": ${nameLaunch},
        "idTypeLaunch": ${typeLaunchId},
        "idReminderLaunch": ${reminderLaunchId},
        "launchDate": ${launchDate},
        "userId": ${userId},
        "flagLaunch": ${flagLaunch},
        "idQuizEnterprise": ${idQuizEnterprise},
    }`);
    data.append('listBranchLaunch', JSON.stringify(listBranchLaunch));
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/setNomEntLaunch`,
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Get a list of available collaborators to assign a product launch
 * @param {int} idEnterprise 
 * @param {int} idProduct 
 * @param {int} idCategory 
 * @param {int} idSubcategory 
 * @returns {Object}
 */
export const getCollabWithOutLaunch = async (idEnterprise, idProduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/launch/getCollabWithOutLaunch?idEnterprise=" + idEnterprise + "&idProduct=" + idProduct + "&idCategory=" + idCategory + "&idSubcategory=" + idSubcategory + "",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get a list of assigned collaborators to a launch
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idAdminLaunch 
 * @returns {Object}
 */
export const getCollabByLaunch = async (idEnterprise, idGroup, idAdminLaunch) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/launch/getCollabByLaunch?idEnterprise=" + idEnterprise + "&idGroup=" + idGroup + "&idAdminLaunch=" + idAdminLaunch + "",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const saveComLaunch = async (
    enterpriseId,
    productId,
    categoryId,
    subcategoryId,
    reminderLaunchId,
    collabs,
    nameLaunch,
    launchDate,
    userId,
    flagLaunch,
    validDate,
    flagAllCollabs,
    idAdminLaunch,
    idQuizEnterprise,
) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('request', `{
        "enterpriseId": ${enterpriseId},
        "idProduct": ${productId},
        "idCategory": ${categoryId},
        "idSubcategory": ${subcategoryId},
        "idReminderLaunch": ${reminderLaunchId},
        "listIdCollabs": ${JSON.stringify(collabs)},
        "nameLaunch": ${nameLaunch},
        "launchDate": ${launchDate},
        "userId": ${userId},
        "flagLaunch": ${flagLaunch},
        "validity":${JSON.stringify(validDate)},
        "flagAllCollabs":${flagAllCollabs},
        "idAdminLaunch":${idAdminLaunch},
        "idQuizEnterprise":${idQuizEnterprise},
    }`);
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/setComplementaryEntLaunch`,
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Returns an array of pulses based on launch date
 * @param {string} launchDate 
 * @param {string} validity 
 * @returns Object periodLaunchList : []
 */
export const getadminPeriodList = async (launchDate, validity) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/getadminPeriodList?launchDate=${launchDate}&validity=${validity}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Returns an array of pulses based on idGroup
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @returns Object listPeriods : []
 */
export const getPeriodsByLaunch = async (idEnterprise, idGroup) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/getPeriodsByLaunch?idEnterprise=${idEnterprise}&idGroup=${idGroup}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Asigna fecha de lanzamiento de comunicados
 * @param {*} enterpriseId 
 * @param {*} idGroup 
 * @param {*} idProduct 
 * @param {*} idCategory 
 * @param {*} idSubcategory 
 * @param {*} idTypeLaunch 
 * @param {*} launchComunication 
 * @returns 
 */
export const setCalendarByLaunch = async (
    enterpriseId,
    idGroup,
    idProduct,
    idCategory,
    idSubcategory,
    idTypeLaunch,
    launchComunication,
    idQuizEnterprise,
) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append("request", `{
        enterpriseId: ${enterpriseId}, 
        idGroup: ${idGroup}, 
        idProduct: ${idProduct}, 
        idCategory: ${idCategory}, 
        idSubcategory: ${idSubcategory}, 
        idTypeLaunch: ${idTypeLaunch}, 
        launchComunication: ${launchComunication},
        idQuizEnterprise: ${idQuizEnterprise},
    }`);
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/setCalendarByLaunch`,
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const getLaunchsByService = async (idEnterprise, idProduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/getLaunchsByService?idEnterprise=${idEnterprise}&idProduct=${idProduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const setCancelLaunch = async (idEnterprise, idGroup) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{ idEnterprise: ${idEnterprise}, idGroup: ${idGroup} }`);
    try {
        let result = await axios({
            url: global.base_url + 'enterprise/launch/setCancelLaunch',
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idGroup 
 * @returns 
 */
export const setUpdateCollabsToLaunch = async (idEnterprise, idGroup, collabsList) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{ idEnterprise: ${idEnterprise}, idGroup: ${idGroup}, collabsList: '${collabsList}' }`);
    try {
        let result = await axios({
            url: global.base_url + 'enterprise/launch/setUpdateCollabsToLaunch',
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const getCardsByProduct = async () => {
    try {
        let result = await axios({
            url: global.base_url + `cards/getCardsByProduct`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const sendCardsByEnterprise = async (idUser, idEnterprise,idCard,flagAll,flagLeader,branches) => {
    let formData = new FormData();
    let data = {
        idUser,
        idEnterprise,
        idCard,
        flagAll,
        flagLeader,
        branches,
    }
    formData.append("request", JSON.stringify(data));
    try {
        let result = await axios({
            url: global.base_url + 'cards/sendCardsByEnterprise',
            method: 'POST',
            data:formData,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const sendCardsByEnterpriseLaunch = async (idUser, idEnterprise,idCard,idGroup,flagAll,branch) => {
    let formData = new FormData();
    let data = {
        idUser,
        idEnterprise,
        idCard,
        idGroup,
        flagAll,
        branch,
    }
    formData.append("request", JSON.stringify(data));
    try {
        let result = await axios({
            url: global.base_url + 'cards/sendCardsByEnterpriseLaunch',
            method: 'POST',
            data:formData,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const sendRemeinderByLaunch = async (idUser, idEnterprise,idGroup,idProduct,idCategory,idSubcategory) => {
    let formData = new FormData();
    let data = {
        idUser,
        idEnterprise,
        idGroup,
        idProduct,
        idCategory,
        idSubcategory,
    }
    formData.append("request", JSON.stringify(data));
    try {
        let result = await axios({
            url: global.base_url + 'enterprise/launch/sendRemeinderByLaunch',
            method: 'POST',
            data:formData,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} enterpriseId 
 * @param {*} idGroup 
 * @returns 
 */
export const getCollabWithOutAssingSpecificLaunch = async (enterpriseId, idGroup, idRol, idUser) => {
    try {
        let result = await axios({
            url: `${global.base_url}enterprise/launch/getCollabWithOutAssingSpecificLaunch?idEnterprise=${enterpriseId}&idGroup=${idGroup}&idRol=${idRol}&idUser=${idUser}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setCollabsToSpecificLaunch = async (arrayCollabs, idGroup) => {
    try {
        let formData = new FormData();
        formData.append("arrayCollabs", JSON.stringify(arrayCollabs));
        formData.append("idGroup", idGroup);
        let result = await axios({
            method: 'POST',
            url: global.base_url + "enterprise/launch/setCollabsToSpecificLaunch",
            data: formData,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
