/*
 File Name: Survey.js
 Description: Manage Surveys API Requests
 guay dev's team
*/
import axios from "axios";

/**
 *  Validation access to survey EE
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessEL = async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `survey/validationAccessEL?collaboratorId=${collaboratorId}&idEnterprise=${idEnterprise}&surveyId=${surveyId}&idGroup=${idGroup}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Save answers for el entorno laboral
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @returns 
 */
export const saveResponseEL = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('request', `{"idAnswer": ${idAnswer},"idQuestion": ${idQuestion},"idEnterprise": ${idEnterprise},"idGroup": ${idGroup},"idCollab": ${idCollab}, 
    "userDate": ${userDate},
    "coordinates": ${coordinates},
    "browser": ${browser},
    "os": ${os},
    "userIP": "",
    "providerIP":"" }`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "survey/saveResponseEL",
            method: 'POST',
            data
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Terminar encuesta entorno laboral
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @param {*} surveyId 
 * @param {*} idGroup 
 * @param {*} sections 
 * @returns 
 */
export const updFinishSurveyEL = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append("collaboratorId", collaboratorId);
    data.append("idEnterprise", idEnterprise);
    data.append("surveyId", surveyId);
    data.append("idGroup", idGroup);
    data.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "survey/updFinishSurveyEL",
            method: 'POST',
            data
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Method for access demographic climate survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessDemogClimate = async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `survey/validationAccessDemogClimate?collaboratorId=${collaboratorId}&idEnterprise=${idEnterprise}&surveyId=${surveyId}&idGroup=${idGroup}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener encuesta clinicos
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @param {*} idGroup 
 * @param {*} idPeriod 
 * @returns JSON Object
 */
export const validationAccessClinic = async (collaboratorId, idEnterprise, idGroup, idPeriod) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `assistance/validationAccessClinic?collaboratorId=${collaboratorId}&idEnterprise=${idEnterprise}&idGroup=${idGroup}&idPeriod=${idPeriod}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Guardar respuesta clinicos
 * @param {*} idAnswer 
 * @param {*} idSubanswer 
 * @param {*} idQuestion 
 * @param {*} idEnterprise 
 * @param {*} idGroup 
 * @param {*} idCollab 
 * @returns 
 */
export const saveResponseClinic = async (idAnswer, idSubanswer, idQuestion, idEnterprise, idGroup, idPeriod, idCollab, userDate, coordinates, browser, os) => {
    var data = new FormData();
    data.append("request", `{idAnswer: ${idAnswer}, idSubanswer: "${idSubanswer}", idQuestion: ${idQuestion}, idEnterprise: ${idEnterprise}, idPeriod: ${idPeriod}, idGroup: ${idGroup}, idCollab: ${idCollab}, userDate: ${userDate}, coordinates: ${coordinates}, browser: ${browser}, os: ${os}, userIP:"", providerIP:""}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "assistance/saveResponseClinic",
            method: 'POST',
            data,
            redirect: 'follow'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Guardar respuestas abiertas
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @param {*} idGroup 
 * @param {*} idPeriod 
 * @param {*} arrayOpenQuestions 
 * @returns 
 */
export const saveResponseOpenClinic = async (collaboratorId, idEnterprise, idGroup, idPeriod, arrayOpenQuestions) => {
    var data = new FormData();
    data.append("idEnterprise", idEnterprise);
    data.append("idGroup", idGroup);
    data.append("idCollab", collaboratorId);
    data.append("responsesOpen", arrayOpenQuestions);
    data.append("idPeriod", idPeriod);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "assistance/saveResponseOpenClinic",
            method: 'POST',
            data,
            redirect: 'follow'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Terminar encuesta clinicos
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @param {*} idGroup 
 * @param {*} idPeriod 
 * @returns 
 */
export const updFinishSurveyClinic = async (collaboratorId, idEnterprise, idGroup, idPeriod) => {
    var data = new FormData();
    data.append("collaboratorId", collaboratorId);
    data.append("idEnterprise", idEnterprise);
    data.append("idGroup", idGroup);
    data.append("idPeriod", idPeriod);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "assistance/updFinishSurveyClinic",
            method: 'POST',
            data,
            redirect: 'follow'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Method for access demographic climate survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessDemogCulture= async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `survey/validationAccessDemogCulture?collaboratorId=${collaboratorId}&idEnterprise=${idEnterprise}&idGroup=${idGroup}&surveyId=${surveyId}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};


