import { React } from 'react';
import { colorsArray } from '../../../helpers/helpers';
import ReactApexChart from "react-apexcharts";
const ColaboradoresBar = ({ 
    Graphics2,   
     }) => {

        const SeriesBar = Graphics2.serie?Graphics2.serie:[]; 
        const OptionBar = {
        chart: {
            type: 'bar',
            height: 650,
            stacked: true,            
            // stackType: '100%'
        },
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
        title: {
            text:"Comparación de usuarios registrados",
            align:'center',
            style: {
                fontSize:  '20px',
                fontWeight:  'bold',
                fontFamily:  undefined,
                color:  '#263238'
              },
          },
        xaxis: {
            categories: Graphics2.categories?Graphics2.categories:[],
            tickPlacement: 'between',
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: false,
              trim: true,
              
            }   
          },
          legend: {
            position: 'top',
            offsetX: 0,
            offsetY: 0
          },
          colors: colorsArray,
          "fill": {
            colors: colorsArray
        
        },
        dataLabels: {
            style: {
              colors: ['#fff'],
              
            },
            formatter: function (val, opt) {
              return val+"%"
            },
          
          },
          yaxis: {
            max:100,
            labels: {
                show: true,
                formatter: function (val) {
                  return val + "%";
                }
              }
          },
          tooltip: {
            theme: 'dark',
            }
  
        
           
            
    };
    return (
      <div className='row'>       
      
        <div id="barra"  className="col-xl-12 col-lg-12 col-12 mt-2">
                                        
        <ReactApexChart
            type="bar"
            options={OptionBar}
            series={SeriesBar}
            height={450}
            colors={colorsArray} 
        />

        </div>
        
        
      </div>
    )
}



export default ColaboradoresBar;
