import React from 'react';
import LoaderApp from './LoaderApp';

const ModalLoader = () => {
    return (
        <div
            className="modal"
            id="modalLoader"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row d-flex align-items-center justify-content-center">
                                <h1>¡Subiendo archivos!</h1>
                            </div>
                        </div>
                        <LoaderApp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalLoader;
