import React, { useState, useEffect } from 'react';
import Header from '../NewHome/headerAdministrador';
import Footer from '../footerApp';
import Menu from "./../Menu";
import $, { isEmptyObject } from "jquery";
import Swal from 'sweetalert2';
import { remaining_character, validateYear, validateYearVal, validateCollabVal, validateCollab } from "../../../lib/utils/generalFuntions";
import { getJwt } from "../../../lib/auth";
import { getAddressById, getuserData } from "../NewHome/Model";
import { validateFile } from "../../../lib/utils/validateFile";
import { updateLogoEnte, updateEnterprise, getEnterpriseById } from "./Model";
import { getCountries, getSectors, getSubSectors, getAddressByCP } from "../Models/Catalog_model";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import RegisterCommunication from './RegisterCommunication';
import RegisterCommunication2 from './RegisterCommunication2';
import RegisterComplainChannel from './RegisterComplainChannel';
import { setCommunicationConfig, setComplaintConfig, sendConfigTest, getComplaintConfig, getCommunicactionConfig } from '../Models/Enterprise_model';
import ModalLoader from '../ModalLoader';
import GenDataAddress from './GenDataAddress';
import { connect } from 'react-redux';
import { changeEnterpriseAction } from '../../../redux/generalDuck';
import ModalLoaderGen from '../ModalLoaderGen';
import { validateAccess, getSubsection } from '../../helpers/validations';
import { useHistory } from "react-router-dom";
import HelperApp from '../HelperApp';

const DatosGenerales = (props) => {

    const [stepNum, setStep] = useState(1);
    const [enterprise, upEnterprise] = useState({
        user: getJwt("jtw"),
        //enterpriseId: sessionStorage.getItem('enterpriseId'),
        complete: 0,
        // Step 1
        comercial: "",
        short: "",
        email: "",
        sector: "",
        subSector: "",
        country: "",
        anio: "",
        colaboradores: "",
        logo: "",
        // Step 2
        mision: "",
        vision: "",
        objetivos: "",
        valores: "",
        // Step 3
        fiscalRegimenId: "",
        rfc: "",
        countryFiscal: "",
        cp: 0,
        neibg: 0,
        numExt: "",
        razon: "",
        state: 0,
        town: 0,
        street: "",
        numInt: "",
        nameNeighborhood: "",
        nameTown: "",
        nameState: ""
    });

    const [subSectors, upSubSectors] = useState([]);
    const [sectors, upSectors] = useState([]);
    const [countries, upCountries] = useState([]);
    const [files, upFiles] = useState({});
    /* const [stepIn, setStepIn] = useState(""); */

    const {
        // Step 1
        comercial, short, email, sector, subSector,
        // Step 2
        country, anio, colaboradores,
        // Step 3
        fiscalRegimenId, rfc, countryFiscal, cp, neibg, numExt, razon, street, numInt, nameTown, nameNeighborhood, town, state, nameState } = enterprise;
    const [company] = useState({
        enterpriseId: ""
    });

    const [subSectionOne, setSubSectionOne] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showFiles, setShowFiles] = useState([]);
    const [showFiles_2, setShowFiles_2] = useState([]);
    const [sectionOne, setSectionOne] = useState(true);
    const [communicationData, setCommunicationData] = useState({
        idEnterprise: 0,
        idUser: getJwt("jtw"),
        designFlg: 1,
        logoFlg: 2,
        presentationMsg: ""
    });
    const [channelData, setChannelData] = useState({
        idEnterprise: 0,
        idUser: getJwt("jtw"),
        complaintFlg: 1,
        presentationMsg: "",
        url: ""
    });
    const [dataAddress, setDataAddress] = useState({
        stateResidence: 0,
        townResidence: 0,
        neibgResidence: 0,
        cpResidence: 0,
        streetResidence: "",
        numExtResidence: "",
        numIntResidence: "",
        countryResidence: ""
    });

    const history = useHistory();

    const [justRead, setJustRead] = useState(false)

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && props.access.list.length > 0) {
            //console.log(props.access)
            //console.log(validateAccess(props.access.list, 4))
            const section = validateAccess(props.access.list, 4)
            //console.log(getSubsection(section[0].subSection, 26))
            const subsection = getSubsection(section[0].subSection, 26)
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            if (subsection[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            if (subsection[0].id_action === 2) {
                setJustRead(true)
            }
        }
        if (getJwt('rol') === '3') {
            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
            history.push("/inicio-colaborador")
        }
    }, [props.access, history])

    useEffect(() => {
        const getObject = props.match.params;
        let object = getObject.db.split("=");
        var id = object[1];
        company.enterpriseId = id;
        getCommunicactionConfig(id).then((response) => {
            if (response) {
                if (response.code === 1) {
                    if (response.configCommunication.length > 0) {
                        setCommunicationData({
                            ...communicationData,
                            idEnterprise: id,
                            idUser: getJwt("jtw"),
                            designFlg: response.configCommunication[0].design,
                            logoFlg: response.configCommunication[0].logo,
                            presentationMsg: response.configCommunication[0].presentationMsg
                        });
                    } else {
                        setCommunicationData({
                            ...communicationData,
                            idEnterprise: id
                        });
                    }
                    let initialStep = sessionStorage.getItem("step");
                    if (initialStep === "3") {
                        setSubSectionOne(3);
                        sessionStorage.removeItem("step");
                    }
                }
            }
        });
        getComplaintConfig(id).then((response) => {
            if (response) {
                if (response.code === 1) {
                    if (response.configComplaint.length > 0) {
                        setChannelData({
                            ...channelData,
                            idEnterprise: id,
                            idUser: getJwt("jtw"),
                            complaintFlg: response.configComplaint[0].channel,
                            presentationMsg: response.configComplaint[0].presentationMsg,
                            url: response.configComplaint[0].url
                        });
                    } else {
                        setChannelData({
                            ...channelData,
                            idEnterprise: id
                        });
                    }
                    if (response.configImgComplaint.length > 0) {
                        setShowFiles(response.configImgComplaint);
                    }
                    if (response.listUploadedDoc.length > 0) {
                        setShowFiles_2(response.listUploadedDoc);
                    }
                }
            }
        });
        getSectors().then((respSectors) => {
            upSectors([
                ...sectors,
                ...respSectors
            ]);
        });
        getCountries().then((respCountries) => {
            upCountries([
                ...countries,
                ...respCountries
            ]);
        });
        getEnterpriseById(id).then((dataEnterprise) => {
            if (dataEnterprise.sector > 0) {
                getSubSectors(dataEnterprise.sector).then((respSubSectors) => {
                    upSubSectors([
                        // ...subSectors,
                        ...respSubSectors
                    ]);
                });
            }
            if (dataEnterprise.listInfoZip.length > 0) {
                getAddressByCP(dataEnterprise.listInfoZip[0].zip).then((respAddress) => {
                    if (respAddress.length > 0) {
                        $("#stateResidence, #townResidence, #neibgResidence").html("");
                        $("#stateResidence").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                        $("#townResidence").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                        $("#townResidence").addClass("border-success");
                        $("#townResidence").removeClass("border-danger-guay");
                        $("#stateResidence").addClass("border-success");
                        $("#stateResidence").removeClass("border-danger-guay");
                        $("#neibgResidence").addClass("border-success");
                        $("#neibgResidence").removeClass("border-danger-guay");
                        $.each(respAddress, function (i, item) {
                            $("#neibgResidence").append('<option key="n' + respAddress[i].idNeighborhood + '" value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                            $("#neibgResidence").attr("disabled", false);
                        });
                        setDataAddress({
                            stateResidence: dataEnterprise.listInfoZip[0].idState === null || dataEnterprise.listInfoZip[0].idState === "null" ? ("") : (dataEnterprise.listInfoZip[0].idState),
                            townResidence: dataEnterprise.listInfoZip[0].idTown === null || dataEnterprise.listInfoZip[0].idTown === "null" ? ("") : (dataEnterprise.listInfoZip[0].idTown),
                            neibgResidence: dataEnterprise.listInfoZip[0].idNeighborhood === null || dataEnterprise.listInfoZip[0].idNeighborhood === "null" ? ("") : (dataEnterprise.listInfoZip[0].idNeighborhood),
                            cpResidence: dataEnterprise.listInfoZip[0].zip === null || dataEnterprise.listInfoZip[0].zip === "null" ? ("") : (dataEnterprise.listInfoZip[0].zip),
                            streetResidence: dataEnterprise.listInfoZip[0].street === null || dataEnterprise.listInfoZip[0].street === "null" ? ("") : (dataEnterprise.listInfoZip[0].street),
                            numExtResidence: dataEnterprise.listInfoZip[0].numExt === null || dataEnterprise.listInfoZip[0].numExt === "null" ? ("") : (dataEnterprise.listInfoZip[0].numExt),
                            numIntResidence: dataEnterprise.listInfoZip[0].numInt === null || dataEnterprise.listInfoZip[0].numInt === "null" ? ("") : (dataEnterprise.listInfoZip[0].numInt),
                            countryResidence: dataEnterprise.listInfoZip[0].idCountry === null || dataEnterprise.listInfoZip[0].idCountry === "null" ? ("") : (dataEnterprise.listInfoZip[0].idCountry),
                        });
                    } else {

                    }
                });
            } else {
                setDataAddress({
                    stateResidence: 0,
                    townResidence: 0,
                    neibgResidence: 0,
                    cpResidence: 0,
                    streetResidence: "",
                    numExtResidence: "",
                    numIntResidence: "",
                    countryResidence: dataEnterprise.country
                });
            }
            getAddressById(id).then((responseAddress) => {
                if (!responseAddress) {
                    upEnterprise({
                        ...enterprise,
                        enterpriseId: "" + dataEnterprise.enterpriseId + "",
                        complete: dataEnterprise.status,
                        // Step 1
                        comercial: dataEnterprise.comercial === null || dataEnterprise.comercial === "null" ? ("") : (dataEnterprise.comercial),
                        short: dataEnterprise.short === null || dataEnterprise.short === "null" ? ("") : (dataEnterprise.short),
                        email: dataEnterprise.email === null || dataEnterprise.email === "null" ? "" : "" + dataEnterprise.email + "",
                        sector: dataEnterprise.sector === null || dataEnterprise.sector === "null" ? "" : "" + dataEnterprise.sector + "",
                        subSector: "" + dataEnterprise.subSector + "",
                        country: "" + dataEnterprise.country + "",
                        anio: "" + dataEnterprise.anio + "",
                        colaboradores: "" + dataEnterprise.collaborators + "",
                        logo: "" + dataEnterprise.logo + "",
                        // Step 2
                        mision: dataEnterprise.mision === null || dataEnterprise.mision === "null" ? ("") : (dataEnterprise.mision),
                        vision: dataEnterprise.vision === null || dataEnterprise.vision === "null" ? ("") : (dataEnterprise.vision),
                        objetivos: dataEnterprise.objetivos === null || dataEnterprise.objetivos === "null" ? ("") : (dataEnterprise.objetivos),
                        valores: dataEnterprise.valores === null || dataEnterprise.valores === "null" ? ("") : (dataEnterprise.valores),
                        // Step 3
                        fiscalRegimenId: "" + dataEnterprise.fiscalRegimenId + "",
                        rfc: dataEnterprise.rfc === null ? "" : "" + dataEnterprise.rfc + "",
                        razon: dataEnterprise.legal === null ? "" : "" + dataEnterprise.legal + "",
                        // Step 3 with getAddressById
                        countryFiscal: "484",
                        cp: 0,
                        neibg: 0,
                        numExt: "",
                        state: 0,
                        town: 0,
                        street: "",
                        numInt: ""

                    });
                } else {
                    if (responseAddress.length === 0) {
                        upEnterprise({
                            ...enterprise,
                            enterpriseId: "" + dataEnterprise.enterpriseId + "",
                            complete: dataEnterprise.status,
                            // Step 1
                            comercial: dataEnterprise.comercial,
                            short: dataEnterprise.short,
                            email: dataEnterprise.email === null ? "" : "" + dataEnterprise.email + "",
                            sector: "" + dataEnterprise.sector + "",
                            subSector: "" + dataEnterprise.subSector + "",
                            country: "" + dataEnterprise.country + "",
                            anio: "" + dataEnterprise.anio + "",
                            colaboradores: "" + dataEnterprise.collaborators + "",
                            logo: "" + dataEnterprise.logo + "",
                            // Step 2
                            mision: dataEnterprise.mision === null || dataEnterprise.mision === "null" ? ("") : (dataEnterprise.mision),
                            vision: dataEnterprise.vision === null || dataEnterprise.vision === "null" ? ("") : (dataEnterprise.vision),
                            objetivos: dataEnterprise.objetivos === null || dataEnterprise.objetivos === "null" ? ("") : (dataEnterprise.objetivos),
                            valores: dataEnterprise.valores === null || dataEnterprise.valores === "null" ? ("") : (dataEnterprise.valores),
                            // Step 3
                            fiscalRegimenId: "" + dataEnterprise.fiscalRegimenId + "",
                            rfc: dataEnterprise.rfc === null ? "" : "" + dataEnterprise.rfc + "",
                            razon: dataEnterprise.legal === null ? "" : "" + dataEnterprise.legal + "",
                            // Step 3 with getAddressById
                            countryFiscal: "484",
                            cp: 0,
                            neibg: 0,
                            numExt: "",
                            state: 0,
                            town: 0,
                            street: "",
                            numInt: ""
                        });
                    } else {
                        upEnterprise({
                            ...enterprise,
                            enterpriseId: "" + dataEnterprise.enterpriseId + "",
                            complete: dataEnterprise.status,
                            // Step 1
                            comercial: dataEnterprise.comercial,
                            short: dataEnterprise.short,
                            email: dataEnterprise.email === null ? "" : "" + dataEnterprise.email + "",
                            sector: "" + dataEnterprise.sector + "",
                            subSector: "" + dataEnterprise.subSector + "",
                            country: "" + dataEnterprise.country + "",
                            anio: "" + dataEnterprise.anio + "",
                            colaboradores: "" + dataEnterprise.collaborators + "",
                            // Step 2
                            mision: dataEnterprise.mision === null || dataEnterprise.mision === "null" ? ("") : (dataEnterprise.mision),
                            vision: dataEnterprise.vision === null || dataEnterprise.vision === "null" ? ("") : (dataEnterprise.vision),
                            objetivos: dataEnterprise.objetivos === null || dataEnterprise.objetivos === "null" ? ("") : (dataEnterprise.objetivos),
                            valores: dataEnterprise.valores === null || dataEnterprise.valores === "null" ? ("") : (dataEnterprise.valores),
                            // Step 3
                            fiscalRegimenId: "" + dataEnterprise.fiscalRegimenId + "",
                            rfc: dataEnterprise.rfc === null ? "" : "" + dataEnterprise.rfc + "",
                            razon: dataEnterprise.legal === null ? "" : "" + dataEnterprise.legal + "",
                            // Step 3 with getAddressById
                            countryFiscal: "484",
                            cp: responseAddress[0].zip,
                            neibg: responseAddress[0].idNeighborhood,
                            numExt: "" + responseAddress[0].numExt + "",
                            state: responseAddress[0].idState,
                            town: responseAddress[0].idTown,
                            street: "" + responseAddress[0].street + "",
                            numInt: responseAddress[0].numInt === null ? "" : "" + responseAddress[0].numInt + "",
                            logo: "" + dataEnterprise.logo + "",
                            nameTown: "" + responseAddress[0].nameTown + "",
                            nameNeighborhood: "" + responseAddress[0].nameNeighborhood + "",
                            nameState: "" + responseAddress[0].nameState + "",
                        });
                    }
                }
            });
        });
        // eslint-disable-next-line
    }, [stepNum]);

    const [enterpriseGroup, setenterpriseGroup] = useState({});

    useEffect(() => {
        getuserData(getJwt('jtw')).then(response => {
            if (response) {
                const enterpriseInt = parseInt(enterprise.enterpriseId);
                const enterpriseGroupId = response.enterprise.find(enterprise => enterprise.enterpriseId === enterpriseInt)?.enterpriseGroupId;
                setenterpriseGroup(response.enterpriseGroup.find(enterpriseGroup => enterpriseGroup.enterpriseGroupId === enterpriseGroupId));
            }
        })
    }, [enterprise.enterpriseId]);


    const getValue = (e) => {
        let value = e.target.value.replace(/[ ]{2,}/, ' ');

        if (e.target.getAttribute("add-count") === "1") {
            let inpID = e.target.id;
            let maxLength = e.target.getAttribute("data-max-length");
            let eCount = e.target.getAttribute("element-name-character-count");
            remaining_character(value.length, inpID, maxLength, eCount);
        }

        switch (e.target.name) {
            case "sector":
                if (value !== "0") {
                    $("#" + e.target.name).addClass("border-success");
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    getSubSectors(value).then((respSubSectors) => {
                        upSubSectors([
                            // ...subSectors,
                            ...respSubSectors
                        ]);
                        upEnterprise({
                            ...enterprise,
                            sector: value,
                            complete: 1
                        });
                    });
                }
                break;
            case "cp":
                if (value.length + 0 >= 5) {
                    $("#cp").addClass("border-success");
                    $("#cp").removeClass("border-danger-guay");
                    getAddressByCP(value).then((respAddress) => {
                        if (respAddress.length > 0) {
                            $("#state, #town, #neibg").html("");
                            $("#state").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                            $("#town").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                            $("#town").addClass("border-success");
                            $("#town").removeClass("border-danger-guay");
                            $("#state").addClass("border-success");
                            $("#state").removeClass("border-danger-guay");
                            $("#neibg").addClass("border-success");
                            $("#neibg").removeClass("border-danger-guay");
                            $.each(respAddress, function (i, item) {
                                $("#neibg").append('<option value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                $("#neibg").attr("disabled", false);
                            });
                            upEnterprise({
                                ...enterprise,
                                cp: value,
                                state: respAddress[0].idState,
                                town: respAddress[0].idTown,
                                neibg: respAddress[0].idNeighborhood,
                                street: $("#street").val(),
                                complete: 3
                            });
                        } else {
                            Swal.fire({
                                title: '<p class="text-white mb-2">¡Oops!</p>',
                                text: "El código postal registrado no existe. Intenta nuevamente",
                                showCloseButton: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                dangerMode: true
                            });
                            $(".swal2-title").addClass("bg-header-swal");
                            $(".swal2-actions").addClass("w-100 justify-content-around");
                            $(".swal2-confirm").removeClass("swal2-styled");
                            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                            $(".swal2-cancel").removeClass("swal2-styled");
                            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                        }
                    });
                } else {
                    const select = "--Seleccione--";
                    const optionCero = 0;
                    upEnterprise({
                        ...enterprise,
                        cp: value,
                        state: 0,
                        town: 0,
                        neibg: 0,
                        complete: 3
                    });
                    $("#state, #town, #neibg").html("");
                    $("#state").append('<option value="' + optionCero + '">' + select + '</option>');
                    $("#town").append('<option value="' + optionCero + '">' + select + '</option>');
                    $("#neibg").append('<option value="' + optionCero + '">' + select + '</option>');
                }
                break;

            case "anio":
                validateYear(e.target.name);
                upEnterprise({
                    ...enterprise,
                    [e.target.name]: value

                });
                break;
            case "colaboradores":
                validateCollab(e.target.name);
                upEnterprise({
                    ...enterprise,
                    [e.target.name]: value

                });
                break;
            case "email":
                let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                if (regex.test(value)) {
                    $("#email").removeClass("border-danger-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value
                    });
                } else {
                    $("#email").addClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value
                    });
                }
                break;
            case "rfc":
                if(value === "0" || e.target.value.length === 0){
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: ""
                    });
                }else if (e.target.value.length < 12 || e.target.value.length > 13){
                    $("#" + e.target.name).addClass("border-danger-guay");
                    $("#" + e.target.name).removeClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value
                    });
                }else{
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value
                    });
                }
               
                break;
            default:
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).removeClass("border-success");
                    $("#" + e.target.name).addClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: "",
                    });
                } else {
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#fiscalRegimenLabel").removeClass("text-base-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value,
                    });
                }
                break;
        }
    }

    const renderFilePers = () => {
        $("#file").click();
    }

    const onChangeHandler = (e) => {
        try {
            let file = e.target.files[0];
            validateFile(file).then((res) => {
                if (res.success === 1) {
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        $('#img-person').attr('src', e.target.result);
                    };
                    reader.readAsDataURL(file);
                    let formData = new FormData();
                    formData.append("file", file)
                    formData.append("enterprise", JSON.stringify(company));
                    updateLogoEnte(formData).then((res) => {
                        if (res.success) {
                            toastr.success("Se actualizó correctamente el logo de la organización.", "¡Exito!");
                            getuserData(getJwt("jtw")).then(response => {
                                const enterpriseUpdate = response.enterprise.find(enterprise => enterprise.enterpriseId === props.general.enterpriseInScope.enterpriseId);
                                if (enterpriseUpdate !== undefined) {
                                    props.changeEnterpriseAction(enterpriseUpdate);
                                } else {
                                    props.changeEnterpriseAction({ update: true });
                                }
                            });
                        } else {
                            toastr.error("Ocurrió un problema con la actualización. Intente nuevamente.", "¡Error!");
                        }
                    });
                } else {
                    Swal.fire({
                        title: '<p class="text-white mb-2">¡Oops!</p>',
                        text: "" + res.msg + "",
                        showCloseButton: true,
                        showCancelButton: false,
                        focusCancel: false,
                        cancelButtonText: 'Cancelar',
                        showConfirmButton: true,
                        confirmButtonText: "Ok",
                        dangerMode: true
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
            });


        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = () => {
        try {

            let answered = false;
            let form_1 = 0;
            let form_3 = 0;

            switch (stepNum) {
                case 1:
                    if (!validateYear("anio")) {
                        $("#anio").addClass("border-danger-guay");
                        toastr.error('El campo Año de fundación debe ser un año válido.', '¡Espera!');
                        return false
                    }
                    if (!validateCollab("colaboradores")) {
                        $("#anio").addClass("border-danger-guay");
                        toastr.error('El número de colaboradores no es válido.', '¡Espera!');
                        return false
                    }
                    if (enterprise.comercial.length === 0) {
                        $("#comercial").addClass("border-danger-guay");
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.short.length === 0 || enterprise.short === "null") {
                        $("#short").addClass("border-danger-guay");
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.email.length === 0) {
                        $("#email").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Correo organizacional', '¡Espera!');
                    } else {
                        let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                        if (regex.test(enterprise.email)) {
                            $("#email").removeClass("border-danger-guay");
                            form_1 = form_1 + 1;
                        } else {
                            $("#email").addClass("border-danger-guay");
                            toastr.error('Formato de Correo organizacional incorrecto.', '¡Espera!');
                        }
                    }
                    if (enterprise.sector.length === 0 || enterprise.sector === "0") {
                        $("#sector").addClass("border-danger-guay");
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.subSector.length === 0 || enterprise.subSector === "0") {
                        $("#subSector").addClass("border-danger-guay");
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.rfc.length > 0 && (enterprise.rfc.length > 13 || enterprise.rfc.length < 12)) {
                        $("#rfc").addClass("border-danger-guay");
                        toastr.error('El campo: RFC tiene que tener como mínimo 12 caracteres y máximo 13.', '¡Espera!');
                        return false
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (form_1 === 6) {
                        return answered = true;
                    }
                    break;
                /*case 2:
                    let anio = enterprise.anio.toString();
                    if (enterprise.country === "0" || enterprise.country.length === 0) {
                        $("#country").addClass("border-danger-guay");
                    } else {
                        form_2 = form_2 + 1;
                    }
                    if (anio.length === 0) {
                        $("#anio").addClass("border-danger-guay");
                    } else {
                        if (anio.length === 4) {
                            form_2 = form_2 + 1;
                        } else {
                            $("#anio").addClass("border-danger-guay");
                        }
                    }
                    if (enterprise.colaboradores.length === 0) {
                        $("#colaboradores").addClass("border-danger-guay");
                    } else {
                        if (enterprise.colaboradores > 0) {
                            form_2 = form_2 + 1;
                        } else {
                            $("#colaboradores").addClass("border-danger-guay");
                        }
                    }
                    if (form_2 === 3) {
                        return answered = true;
                    }
                    break;*/
                case 3:
                case "3":

                    if (enterprise.fiscalRegimenId.length === 0) {
                        $("#fiscalRegimenLabel").addClass("text-base-guay");
                    } else {
                        form_3 = form_3 + 1;
                    }
                    if (enterprise.rfc.length === 0) {
                        $("#rfc").addClass("border-danger-guay");
                        return
                    } else {
                        if (enterprise.rfc.length <= 13) {
                            form_3 = form_3 + 1;
                        } else {
                            $("#rfc").addClass("border-danger-guay");
                        }
                    }
                    if (enterprise.countryFiscal.length === 0) {
                        $("#countryFiscal").addClass("border-danger-guay");
                    } else {
                        form_3 = form_3 + 1;
                    }
                    if (enterprise.cp === 0 || enterprise.cp === "") {
                        $("#cp").addClass("border-danger-guay");
                    } else {
                        form_3 = form_3 + 1;
                    }
                    if (enterprise.neibg === null) {
                        $("#neibg").addClass("border-danger-guay");
                    } else {
                        form_3 = form_3 + 1;
                    }
                    if (enterprise.street.length === 0) {
                        $("#street").addClass("border-danger-guay");
                    } else {
                        form_3 = form_3 + 1;
                    }
                    if (enterprise.numExt.length === 0) {
                        $("#numExt").addClass("border-danger-guay");
                    } else {
                        form_3 = form_3 + 1;
                    }
                    if (form_3 === 7) {
                        return answered = true;
                    }
                    break;
                default:
                    if (answered === false) {
                        return answered = true;
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const saveRegister = () => {
        try {
            switch (stepNum) {
                case 1:
                case "1":
                    // if (enterprise.comercial.length === 0 ||
                    //     enterprise.short.length === 0 ||
                    //     enterprise.sector.length === 0 ||
                    //     enterprise.subSector.length === 0) {
                    //     toastr.error("Todos los campos son obligatorios", "¡Espera");
                    //     return false;
                    // }

                    if(enterprise.comercial.length === 0){
                        toastr.error("El campo Nombre Comercial es obligatorio", "¡Espera");
                        return false;
                    }
                    if(enterprise.short.length === 0){
                        toastr.error("El campo Nombre Corto es obligatorio", "¡Espera");
                        return false;
                    }
                    if(enterprise.sector.length === 0){
                        toastr.error("El campo Sector o Industria es obligatorio", "¡Espera");
                        return false;
                    }
                    if(enterprise.subSector.length === 0){
                        toastr.error("El campo Subsector es obligatorio", "¡Espera");
                        return false;
                    }

                    if (!validateYear("anio")) {
                        $("#anio").addClass("border-danger-guay");
                        toastr.error('El campo Año de fundación debe ser un año válido.', '¡Espera!');
                        return false
                    }
                    if (!validateCollab("colaboradores")) {
                        $("#colaboradores").addClass("border-danger-guay");
                        toastr.error('El número de colaboradores no es válido.', '¡Espera!');
                        return false
                    }


                    break;
                case 2:
                case "2":
                    if (!validateYearVal(enterprise.anio)) {
                        toastr.error('El campo Año de fundación debe ser un año válido. Datos Generales', '¡Espera!');
                        return false
                    }
                    if (!validateCollabVal(enterprise.colaboradores)) {
                        toastr.error('El valor para el número de colaboradores no es válido. Datos Generales', '¡Espera!');
                        return false
                    }


                    break;
                case 3:
                case "3":
                    if (!validateYearVal(enterprise.anio)) {
                        toastr.error('El campo Año de fundación debe ser un año valido. Datos Generales', '¡Espera!');
                        return false
                    }
                    if (!validateCollabVal(enterprise.colaboradores)) {
                        toastr.error('El valor para el número de colaboradores no es válido. Datos Generales', '¡Espera!');
                        return false
                    }
                    if (!validateForm()) {
                        return false;
                    }
                    let count = 0;

                    if (enterprise.rfc.length === 0) {
                        validateMessage("#rfc", 'Falta llenar el campo: RFC');
                        count++;
                    }
                    if (enterprise.fiscalRegimenId.length === 0) {
                        validateMessage("#fiscalRegimenLabel", 'Falta llenar el campo: Régimen fiscal');
                        count++;
                    }
                    if (enterprise.countryFiscal.length === 0) {
                        validateMessage("#countryFiscal", 'Falta llenar el campo: País');
                        count++;
                    }
                    if (enterprise.cp.length === 0) {
                        validateMessage("#cp", 'Falta llenar el campo: Código Postal');
                        count++;
                    }
                    if (enterprise.street.length === 0) {
                        validateMessage("#street", 'Falta llenar el campo: Calle');
                        count++;
                    }
                    if (enterprise.numExt.length === 0) {
                        validateMessage("#numExt", 'Falta llenar el campo: Núm. exterior');
                        count++;
                    }

                    if (count > 0) {
                        return;
                    }
                    break;

                default:
                    break;
            }
            // if(validateForm()){
            let formData = new FormData();
            const { mision, vision, objetivos, valores } = enterprise;
            let enterpriseClean = {
                ...enterprise,
                ...dataAddress,
                countryResidence: dataAddress.countryResidence.toString()
            };
            if (mision === "null") {
                enterpriseClean = { ...enterpriseClean, mision: "" };
            }
            if (vision === "null") {
                enterpriseClean = { ...enterpriseClean, vision: "" };
            }
            if (objetivos === "null") {
                enterpriseClean = { ...enterpriseClean, objetivos: "" };
            }
            if (valores === "null") {
                enterpriseClean = { ...enterpriseClean, valores: "" };
            }
            formData.append("enterprise", JSON.stringify(enterpriseClean));
            updateEnterprise(formData).then((res) => {
                if (res.success === 1) {
                    toastr.success("Se actualizó la información correctamente", "¡Éxito!");
                } else {
                    toastr.error("Ocurrió un problema al actualizar la información", "¡Espera");
                }
            });
            // }

        } catch (error) {
            console.log(error);
        }
    }

    const validateMessage = (element, msg) => {
        setTimeout(function () {
            $(element).addClass("border-danger-guay");
            toastr.error(msg, '¡Espera!');
        }, 500);
        return;
    }

    const updateStep = (event) => {
        try {
            let $currentTarget = $(event.currentTarget);
            let step = $currentTarget.attr("data-step");
            setStep(step);
            if (step === "1") {
                setSectionOne(true);
            } else {
                setSectionOne(false);
            }
            $(".list-group-item").removeClass("active");
            $('.list-group-item[data-step="' + step + '"]').addClass("active");
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeSubSection = e => {
        e.preventDefault();
        let id = e.target.id;
        if (id === "nextBtn") {
            let next = subSectionOne + 1;
            if (subSectionOne === 0) {
                setNewEnterpriseSubSection(next);
            }
            if (subSectionOne === 1) {
                setSubSectionOne(next);
            }
            if (subSectionOne === 2) {
                setNewCommunication(next);
            }
            if (subSectionOne === 3) {
                setNewComplaintChannel();
            }
        } else {
            let back = subSectionOne - 1;
            if (back === 0) {
                $('#modalLoaderGen').show()
                getEnterpriseById(company.enterpriseId).then((dataEnterprise) => {
                    if (dataEnterprise.listInfoZip.length > 0) {
                        getAddressByCP(dataEnterprise.listInfoZip[0].zip).then((respAddress) => {
                            if (respAddress.length > 0) {
                                $("#stateResidence, #townResidence, #neibgResidence").html("");
                                $("#stateResidence").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                                $("#townResidence").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                                $("#townResidence").addClass("border-success");
                                $("#townResidence").removeClass("border-danger-guay");
                                $("#stateResidence").addClass("border-success");
                                $("#stateResidence").removeClass("border-danger-guay");
                                $("#neibgResidence").addClass("border-success");
                                $("#neibgResidence").removeClass("border-danger-guay");
                                $.each(respAddress, function (i, item) {
                                    $("#neibgResidence").append('<option key="n' + respAddress[i].idNeighborhood + '" value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                    $("#neibgResidence").attr("disabled", false);
                                });
                                setDataAddress({
                                    stateResidence: respAddress[0].idState,
                                    townResidence: respAddress[0].idTown,
                                    neibgResidence: respAddress[0].idNeighborhood,
                                    cpResidence: respAddress[0].zip,
                                    streetResidence: dataEnterprise.listInfoZip[0].street === 'null' ? '' : dataEnterprise.listInfoZip[0].street,
                                    numExtResidence: dataEnterprise.listInfoZip[0].numExt === 'null' ? '' : dataEnterprise.listInfoZip[0].numExt,
                                    numIntResidence: dataEnterprise.listInfoZip[0].numInt === 'null' ? '' : dataEnterprise.listInfoZip[0].numInt,
                                    countryResidence: dataEnterprise.listInfoZip[0].numInt === 'null' ? '' : dataEnterprise.listInfoZip[0].idCountry,
                                });
                            } else {

                            }
                            $('#modalLoaderGen').hide()
                        });
                    } else {
                        setDataAddress({
                            stateResidence: 0,
                            townResidence: 0,
                            neibgResidence: 0,
                            cpResidence: 0,
                            streetResidence: "",
                            numExtResidence: "",
                            numIntResidence: "",
                            countryResidence: dataEnterprise.country
                        });
                        $('#modalLoaderGen').hide()
                    }
                });
            }
            setSubSectionOne(back);
        }
    }

    const setNewEnterpriseSubSection = (section) => {
        try {
            let validation = validateForm();
            if (validation === true) {
                let formData = new FormData();
                let file = [];
                if ((dataAddress.cpResidence !== 0 && dataAddress.stateResidence === 0) || (dataAddress.cpResidence.length > 0 && dataAddress.stateResidence === 0)) {
                } else {
                    let countryResidence = dataAddress.countryResidence.toString()
                    let data = {
                        ...enterprise,
                        ...dataAddress,
                        countryResidence: countryResidence
                    }
                    formData.append("file", file);
                    formData.append("enterprise", JSON.stringify(data));
                    updateEnterprise(formData).then((res) => {
                        if (res.success === 1) {
                            setSubSectionOne(section);
                        } else {
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setNewCommunication = (section) => {
        try {
            const {
                idEnterprise,
                idUser,
                designFlg,
                logoFlg,
                presentationMsg
            } = communicationData;
            setCommunicationConfig(idEnterprise, idUser, designFlg, logoFlg, presentationMsg).then((response) => {
                if (response.code === 1) {
                    setSubSectionOne(section);
                } else {
                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const setNewComplaintChannel = (section) => {
        try {
            const {
                idEnterprise,
                idUser,
                complaintFlg,
                presentationMsg,
                url
            } = channelData;
            $('#modalLoaderGen').show()
            sessionStorage.setItem('CPV', '1') // Flag to validate complete information in order to access to products
            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, selectedFiles).then((response) => {
                if (response.code === 1) {
                    toastr.success('Se guardó correctamente los cambios.', '¡Éxito!');
                    $('#modalLoaderGen').hide()
                } else {
                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                    $('#modalLoaderGen').hide()
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const testMessage = () => {
        const {
            idEnterprise,
            idUser,
            designFlg,
            logoFlg,
            presentationMsg
        } = communicationData;
        setCommunicationConfig(idEnterprise, idUser, designFlg, logoFlg, presentationMsg).then((response) => {
            if (response.code === 1) {
                sendConfigTest(idEnterprise).then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                toastr.success('Se envió correctamente la prueba.', '¡Éxito!');
                                break;
                            case 200:
                                toastr.success('Se envió correctamente la prueba.', '¡Éxito!');
                                break;
                            case 1003:
                                toastr.warning('Excediste las pruebas. Intenta mañana.', '¡Ooops!');
                                break;
                            default:
                                toastr.error('Hubo un problema. No se envió la prueba.', '¡Ooops!');
                                break;
                        }
                    }
                });
            } else {
                toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
            }
        });
    }

    const uploadFiles = (arrayFiles, type) => {
        try {
            const {
                idEnterprise,
                idUser,
                complaintFlg,
                presentationMsg,
                url
            } = channelData;
            $("#modalLoader").show();
            if (type === 1) {
                setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                    if (response.code === 1) {
                        getComplaintConfig(idEnterprise).then((response) => {
                            setShowFiles_2(response.listUploadedDoc);
                            $("#modalLoader").hide();
                        });
                    } else {
                        toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        $("#modalLoader").hide();
                    }
                });
            } else {
                if (arrayFiles.length === 5) {
                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[2]).then((response) => {
                                        if (response.code === 1) {
                                            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[3]).then((response) => {
                                                if (response.code === 1) {
                                                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[4]).then((response) => {
                                                        if (response.code === 1) {
                                                            getComplaintConfig(idEnterprise).then((response) => {
                                                                setShowFiles(response.configImgComplaint);
                                                                setSelectedFiles([]);
                                                            });
                                                            $("#modalLoader").hide();
                                                        } else {
                                                            getComplaintConfig(idEnterprise).then((response) => {
                                                                setShowFiles(response.configImgComplaint);
                                                                setSelectedFiles([]);
                                                            });
                                                            $("#modalLoader").hide();
                                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                                        }
                                                    });
                                                } else {
                                                    getComplaintConfig(idEnterprise).then((response) => {
                                                        setShowFiles(response.configImgComplaint);
                                                        setSelectedFiles([]);
                                                    });
                                                    $("#modalLoader").hide();
                                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                                }
                                            });
                                        } else {
                                            getComplaintConfig(idEnterprise).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                        }
                                    });
                                } else {
                                    getComplaintConfig(idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(idEnterprise).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 4) {
                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[2]).then((response) => {
                                        if (response.code === 1) {
                                            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[3]).then((response) => {
                                                if (response.code === 1) {
                                                    getComplaintConfig(idEnterprise).then((response) => {
                                                        setShowFiles(response.configImgComplaint);
                                                        setSelectedFiles([]);
                                                    });
                                                    $("#modalLoader").hide();
                                                } else {
                                                    getComplaintConfig(idEnterprise).then((response) => {
                                                        setShowFiles(response.configImgComplaint);
                                                        setSelectedFiles([]);
                                                    });
                                                    $("#modalLoader").hide();
                                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                                }
                                            });
                                        } else {
                                            getComplaintConfig(idEnterprise).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                        }
                                    });
                                } else {
                                    getComplaintConfig(idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(idEnterprise).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 3) {
                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[2]).then((response) => {
                                        if (response.code === 1) {
                                            getComplaintConfig(idEnterprise).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                        } else {
                                            getComplaintConfig(idEnterprise).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                        }
                                    });
                                } else {
                                    getComplaintConfig(idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(idEnterprise).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 2) {
                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    getComplaintConfig(idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                } else {
                                    getComplaintConfig(idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(idEnterprise).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 1) {
                    setComplaintConfig(idEnterprise, idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            getComplaintConfig(idEnterprise).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                        } else {
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* const hashString = (string) => {
        try {
            var enc = window.btoa(string);
            return enc;
        } catch (error) {
            console.log(error);
        }
    } */

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay"></div>
            <Header />
            <Menu />
            <div className="content-wrapper bootomFooter">
                <div className="content-header row">
                    <div className="col-md-6"></div>
                    <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                        <div className="row breadcrumbs-top d-inline-block">

                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                                    </li>
                                    {
                                        isEmptyObject(enterpriseGroup) ?
                                            <li className="breadcrumb-item font-medium-3">
                                                <a href="/inicio-empresas">Organizaciones</a>
                                            </li>
                                            :
                                            <>
                                                <li className="breadcrumb-item font-medium-3">
                                                    <a href="/inicio-empresas">Organizaciones</a>
                                                </li>
                                                <li className="breadcrumb-item font-medium-3">
                                                    <a
                                                        href={`/grupo-empresas/${hashString('guay.digital')}=${enterpriseGroup.enterpriseGroupId}=${hashString(enterpriseGroup.comercialName)}`}>{enterpriseGroup.comercialName}</a>
                                                </li>
                                            </>
                                    }
                                    <li className="breadcrumb-item active font-medium-3 active">{enterprise.comercial}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <HelperApp />
                    <div className="row">
                        {/* <div className="col-sx-12 col-sm-12 col-md-1 col-lg-2 mb-40"></div> */}
                        <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mb-40">
                            <div className="row mb-1 ">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                    <h3 className="font-weight-bold">Perfil de la organización / {enterprise.comercial}</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sx-2 col-sm-2 col-md-12 col-lg-2">
                                    <div className="card">
                                        <ul className="list-group enterprise-list">
                                            <li className="list-group-item active pointer" data-step="1" onClick={updateStep}>
                                                {/* <span className="badge badge-secondary badge-pill float-left mt-1p font-medium-2">1</span> */}
                                                <span className="ml-1 font-medium-1">Datos Generales</span>
                                            </li>
                                            <li className="list-group-item pointer" data-step="2" onClick={updateStep}>
                                                {/* <span className="badge badge-secondary badge-pill float-left mt-1p font-medium-2">2</span> */}
                                                <span className="ml-1 font-medium-1">Datos Especificos</span>
                                            </li>
                                            {/* <li className="list-group-item pointer" data-step="3" onClick={updateStep}> */}
                                                {/* <span className="badge badge-secondary badge-pill float-left mt-1p font-medium-2">3</span>  (estaba comentado)*/} 
                                                {/* <span className="ml-1 font-medium-1">Datos Facturación</span> */}
                                            {/* </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-12 col-lg-10">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                    <form>
                                                        {
                                                            stepNum === 1 || stepNum === "1" ?
                                                                subSectionOne === 0
                                                                    ?
                                                                    (
                                                                        <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">
                                                                            <div className="row">
                                                                                <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2 pr-0">
                                                                                    <img
                                                                                        src="./../img/altaEmpresas/perfil_organizacion_iconos-05.png"
                                                                                        alt="header-section"
                                                                                        style={{ width: '75px', height: '75px' }} />
                                                                                </div>
                                                                                <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 pl-0 d-flex flex-wrap align-content-center">
                                                                                    <p className="font-weight-bold">Generales</p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                justRead ? (
                                                                                    <div className="row">
                                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                                                            {enterprise.logo !== "" && enterprise.logo !== "null" ?
                                                                                                <img
                                                                                                    src={enterprise.logo}
                                                                                                    id="img-person"
                                                                                                    alt={enterprise.comercial}
                                                                                                    className="pointer img-responsive-group mt-1"
                                                                                                />
                                                                                                :
                                                                                                <img
                                                                                                    src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                    id="img-person"
                                                                                                    alt="empresa"
                                                                                                    className="pointer img-responsive-group mt-1 pull-up"
                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="row">
                                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                                                            <input type="file" id="file" className="hidden" onChange={onChangeHandler} />
                                                                                            {enterprise.logo !== "" && enterprise.logo !== "null" ?
                                                                                                <img src={enterprise.logo} id="img-person"
                                                                                                    alt={enterprise.comercial}
                                                                                                    className="pointer img-responsive-group mt-1 " onClick={renderFilePers} />
                                                                                                :
                                                                                                <img src="./../img/altaEmpresas/iconos_shopy_img-31.png" id="img-person" alt="empresa"
                                                                                                    className="pointer img-responsive-group mt-1 pull-up" onClick={renderFilePers} />
                                                                                            }
                                                                                            <br />
                                                                                            <button type="button"
                                                                                                className="btn btn-outline-secondary btn-min-width mr-1 mb-1 mt-1 font-small-3 "
                                                                                                onClick={renderFilePers}
                                                                                            >Actualizar Logo</button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group mt-1">
                                                                                        <label>Nombre Comercial:</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="comercial"
                                                                                            name="comercial"
                                                                                            maxLength="100"
                                                                                            minLength="2"
                                                                                            placeholder="Nombre Comercial"
                                                                                            onChange={getValue}
                                                                                            value={comercial}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group ">
                                                                                        <label>Nombre Corto:</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="short"
                                                                                            name="short"
                                                                                            maxLength="60"
                                                                                            minLength="2"
                                                                                            placeholder="Nombre Corto"
                                                                                            onChange={getValue}
                                                                                            value={short}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label className="d-flex flex-wrap align-content-center">Correo institucional:
                                                                                            <Popup trigger={<i className="ft-info icon-guay rounded-circle mx-1" style={{ fontSize: '1.5rem' }} />} position="right">
                                                                                                <div className="p-1 text-center">
                                                                                                    Este correo será el punto de contacto que tenga guay con la empresa, también ahí llegará los mensajes que se ingresen al Canal de denuncia en caso de utilizar el que guay te ofrece.
                                                                                                </div>
                                                                                            </Popup>
                                                                                        </label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="email"
                                                                                            id="email"
                                                                                            name="email"
                                                                                            maxLength="100"
                                                                                            onChange={getValue}
                                                                                            value={email}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Sector o Industria:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="sector"
                                                                                            id="sector"
                                                                                            onChange={getValue}
                                                                                            value={sector}
                                                                                            disabled={justRead}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {sectors.map((optionSector, index) =>
                                                                                                <option
                                                                                                    key={"optionSector-" + index + "--" + optionSector.sector_id}
                                                                                                    value={optionSector.sector_id}
                                                                                                >
                                                                                                    {optionSector.description}
                                                                                                </option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group mb-0">
                                                                                        <label className="small">Subsector:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="subSector"
                                                                                            id="subSector"
                                                                                            onChange={getValue}
                                                                                            value={subSector}
                                                                                            disabled={justRead}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {subSectors.map((optionSubSector, index) => (
                                                                                                <option
                                                                                                    key={"optionSubSector-" + index + "--" + optionSubSector.sub_sector_id}
                                                                                                    value={optionSubSector.sub_sector_id}
                                                                                                >
                                                                                                    {optionSubSector.description}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group ">
                                                                                        <label>País de Origen:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="country"
                                                                                            id="country"
                                                                                            onChange={getValue}
                                                                                            value={country}
                                                                                            key={country}
                                                                                            disabled={justRead}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {countries.map((optionCountries, index) => (
                                                                                                <option
                                                                                                    key={"key-" + index + "--" + optionCountries.id}
                                                                                                    value={optionCountries.id}
                                                                                                >
                                                                                                    {optionCountries.description}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Año de fundación: </label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="number"
                                                                                            id="anio"
                                                                                            name="anio"
                                                                                            maxLength="9999"
                                                                                            minLength="-9999"
                                                                                            placeholder="Año de Fundación"
                                                                                            onChange={getValue}
                                                                                            value={anio}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Número de Colaboradores: </label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="number"
                                                                                            id="colaboradores"
                                                                                            name="colaboradores"
                                                                                            minLength="1"
                                                                                            placeholder="Núm. Colaboradores"
                                                                                            onChange={getValue}
                                                                                            value={colaboradores}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>RFC</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="rfc"
                                                                                            name="rfc"
                                                                                            maxLength="13"
                                                                                            minLength="12"
                                                                                            placeholder="RFC"
                                                                                            onChange={getValue}
                                                                                            value={rfc}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <GenDataAddress
                                                                                dataAddress={dataAddress}
                                                                                setDataAddress={setDataAddress}
                                                                                countries={countries}
                                                                            />
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        subSectionOne === 1
                                                                            ?
                                                                            (
                                                                                <RegisterCommunication
                                                                                    communicationData={communicationData}
                                                                                    setCommunicationData={setCommunicationData}
                                                                                    enterprise={enterprise}
                                                                                    files={files}
                                                                                    upFiles={upFiles}
                                                                                />
                                                                            )
                                                                            :
                                                                            (
                                                                                subSectionOne === 2
                                                                                    ?
                                                                                    (
                                                                                        <RegisterCommunication2
                                                                                            communicationData={communicationData}
                                                                                            setCommunicationData={setCommunicationData}
                                                                                            testMessage={testMessage} />
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        subSectionOne === 3
                                                                                            ?
                                                                                            (
                                                                                                <RegisterComplainChannel
                                                                                                    channelData={channelData}
                                                                                                    setChannelData={setChannelData}
                                                                                                    selectedFiles={selectedFiles}
                                                                                                    setSelectedFiles={setSelectedFiles}
                                                                                                    uploadFiles={uploadFiles}
                                                                                                    showFiles={showFiles}
                                                                                                    setShowFiles={setShowFiles}
                                                                                                    short={short}
                                                                                                    showFiles_2={showFiles_2}
                                                                                                    setShowFiles_2={setShowFiles_2}
                                                                                                    idEnterprise={channelData.idEnterprise}
                                                                                                />
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                null
                                                                                            )
                                                                                    )
                                                                            )
                                                                    )
                                                                : stepNum === 2 || stepNum === "2" ?
                                                                    <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11 mx-auto">

                                                                        <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11 mx-auto">
                                                                            <div className="form-group mt-1">
                                                                                <label>Misión: </label>
                                                                                <textarea id="mision"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="mision"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    value={enterprise.mision !== "null" ? enterprise.mision : ""}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-mision"
                                                                                    disabled={justRead}
                                                                                >
                                                                                </textarea>
                                                                                <p id="character-count-mision" className="small" align="right">{
                                                                                    enterprise.mision.length > 0
                                                                                        && enterprise.mision !== null
                                                                                        && enterprise.mision !== "null"
                                                                                        ? enterprise.mision.length : "0"}/500</p>
                                                                            </div>
                                                                            <div className="form-group mt-1">
                                                                                <label>Visión: </label>
                                                                                <textarea id="vision"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="vision"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    value={enterprise.vision !== "null" ? enterprise.vision : ""}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-vision"
                                                                                    disabled={justRead}
                                                                                ></textarea>
                                                                                <p id="character-count-vision" className="small" align="right">{
                                                                                    enterprise.vision.length > 0
                                                                                        && enterprise.vision !== null
                                                                                        && enterprise.vision !== "null"
                                                                                        ? enterprise.vision.length : "0"}/500</p>
                                                                            </div>
                                                                            <div className="form-group mt-1">
                                                                                <label>Objetivos: </label>
                                                                                <textarea id="objetivos"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="objetivos"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    value={enterprise.objetivos !== "null" ? enterprise.objetivos : ""}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-objetivos"
                                                                                    disabled={justRead}
                                                                                ></textarea>
                                                                                <p id="character-count-objetivos" className="small" align="right">{
                                                                                    enterprise.objetivos.length
                                                                                        && enterprise.objetivos !== null
                                                                                        && enterprise.objetivos !== "null"
                                                                                        ? enterprise.objetivos.length : "0"}/500</p>
                                                                            </div>
                                                                            <div className="form-group mt-1">
                                                                                <label>Valores: </label>
                                                                                <textarea id="valores"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="valores"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    value={enterprise.valores !== "null" ? enterprise.valores : ""}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-valores"
                                                                                    disabled={justRead}
                                                                                ></textarea>
                                                                                <p id="character-count-valores" className="small" align="right">{
                                                                                    enterprise.valores.length > 0
                                                                                        && enterprise.valores !== null
                                                                                        && enterprise.valores !== "null"
                                                                                        ? enterprise.valores.length : "0"}/500</p>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    : stepNum === 3 || stepNum === "3" ?
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                                            <div className="row">
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            Régimen fiscal:
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            {fiscalRegimenId === "1" ?
                                                                                                <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId1" value="1" style={{ height: '10px' }} onChange={getValue} checked={"checked"} disabled={justRead} />
                                                                                                :
                                                                                                <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId1" value="1" style={{ height: '10px' }} onChange={getValue} disabled={justRead} />
                                                                                            }
                                                                                            Persona Fisíca
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            {fiscalRegimenId === "2" ?
                                                                                                <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId2" value="2" style={{ height: '10px' }} onChange={getValue} checked={"checked"} disabled={justRead} />
                                                                                                :
                                                                                                <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId2" value="2" style={{ height: '10px' }} onChange={getValue} disabled={justRead} />
                                                                                            }
                                                                                            Persona Moral
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            {fiscalRegimenId === "3" ?
                                                                                                <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId3" value="3" style={{ height: '10px' }} onChange={getValue} checked={"checked"} disabled={justRead} />
                                                                                                :
                                                                                                <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId3" value="3" style={{ height: '10px' }} onChange={getValue} disabled={justRead} />
                                                                                            }
                                                                                            RIF
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                                    {/* <div className="form-group mt-1 ">
                                                                                        <label>RFC</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="rfc"
                                                                                            name="rfc"
                                                                                            maxLength="13"
                                                                                            minLength="13"
                                                                                            placeholder="RFC"
                                                                                            onChange={getValue}
                                                                                            value={rfc}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div> */}
                                                                                    <div className="form-group">
                                                                                        <label>País:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="countryFiscal"
                                                                                            id="countryFiscal"
                                                                                            onChange={getValue}
                                                                                            value={countryFiscal}
                                                                                            disabled={justRead}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {countries.map((optionCountries, index) => (
                                                                                                <option
                                                                                                    key={`${optionCountries.id}-${index}`}
                                                                                                    value={optionCountries.id}
                                                                                                >
                                                                                                    {optionCountries.description}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Código Postal: </label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="number"
                                                                                            id="cp"
                                                                                            name="cp"
                                                                                            placeholder="Código Postal"
                                                                                            onChange={getValue}
                                                                                            disabled={justRead}
                                                                                            value={cp}
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Colonia:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="neibg"
                                                                                            id="neibg"
                                                                                            disabled
                                                                                            onChange={getValue}
                                                                                            value={neibg}
                                                                                        >
                                                                                            <option value={neibg}>{nameNeighborhood ? nameNeighborhood : "--- Seleccione ---"}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group mb-1">
                                                                                        <label>Núm. exterior:</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="numExt"
                                                                                            name="numExt"
                                                                                            maxLength="50"
                                                                                            minLength="2"
                                                                                            placeholder="Número Exterior"
                                                                                            onChange={getValue}
                                                                                            value={numExt}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                                    <div className="form-group mt-1">
                                                                                        <label>Razón Social (opcional)</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="razon"
                                                                                            name="razon"
                                                                                            maxLength="100"
                                                                                            minLength="3"
                                                                                            placeholder="Razón Social"
                                                                                            onChange={getValue}
                                                                                            value={razon}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Estado:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="state"
                                                                                            id="state"
                                                                                            disabled>
                                                                                            <option value={state}>{nameState ? nameState : "--- Seleccione ---"}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label >Municipio/Alcaldía:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="town"
                                                                                            id="town"
                                                                                            disabled>
                                                                                            <option value={town}>{nameTown ? nameTown : "--- Seleccione ---"}</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Calle:</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="street"
                                                                                            name="street"
                                                                                            maxLength="150"
                                                                                            minLength="2"
                                                                                            placeholder="Calle"
                                                                                            onChange={getValue}
                                                                                            value={street}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div className="form-group mb-1">
                                                                                        <label>Núm. Interior (opcional):</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="numInt"
                                                                                            name="numInt"
                                                                                            maxLength="50"
                                                                                            minLength="2"
                                                                                            placeholder="Número Interior"
                                                                                            onChange={getValue}
                                                                                            value={numInt}
                                                                                            disabled={justRead}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                        }
                                                    </form>
                                                    {
                                                        !justRead && (
                                                            sectionOne === true
                                                                ?
                                                                (
                                                                    subSectionOne > 0
                                                                        ?
                                                                        (
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                                                                                <button
                                                                                    className="btn btn-blue float-left font-medium-1 btn-sm"
                                                                                    id="backBtn"
                                                                                    name="backBtn"
                                                                                    onClick={handleChangeSubSection}>Atrás</button>
                                                                                <button
                                                                                    className="btn btn-blue float-right font-medium-1 btn-sm"
                                                                                    id="nextBtn"
                                                                                    name="nextBtn"
                                                                                    onClick={handleChangeSubSection}>{subSectionOne === 3 ? 'Guardar cambios' : 'Siguiente'}</button>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                                                                                <button
                                                                                    className="btn btn-blue float-right font-medium-1 btn-sm"
                                                                                    id="nextBtn"
                                                                                    name="nextBtn"
                                                                                    onClick={handleChangeSubSection}>Siguiente</button>
                                                                            </div>
                                                                        )
                                                                )
                                                                :
                                                                (
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                                                                        <button
                                                                            className="btn btn-blue float-right font-medium-1 btn-sm"
                                                                            onClick={() => saveRegister()}>Guardar Cambios</button>
                                                                        {/* <button className="btn btn-green float-right font-medium-1" id="saveBtn" onClick={stepNum === 3 ? () => setNewEnterprise() : () => handleOnClickNext()}>{stepNum === 3 ? 'Terminar' : 'Continuar'}</button> */}
                                                                    </div>
                                                                )
                                                        )
                                                    }
                                                    {/* <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mx-auto d-flex justify-content-center mt-2">
                                                        <button className="btn btn-green font-medium-1" onClick={() => saveRegister()}>Guardar Cambios</button>
                                                        <button className="btn btn-green btn-sm w-25 float-right" onClick={stepNum === 3 ? () => setNewEnterprise() : () => handleOnClickNext()}>{stepNum === 3 ? 'Terminar' : 'Continuar'}</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ModalLoaderGen />
                        <ModalLoader />
                        <Footer />
                    </div>
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = ({ general, access }) => {
    return {
        general,
        access,
    }
}

export default connect(mapStateToProps, { changeEnterpriseAction })(DatosGenerales);