import React, { useState, useEffect } from 'react';
import { getActiveProductsByEnterprise } from '../Models/Enterprise_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import $ from "jquery";
import Carousel from 'react-bootstrap/Carousel';
import Header from '../NewHome/headerAdministrador';
import Footer from '../footerApp';
import Menu from "../Menu";
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import { useHistory } from "react-router-dom";
import { getUploadedCollaborators } from '../Models/Collaborators_model';
import { getAdByEnterpriseAll } from '../Models/Monitor_model';
import { getuserData } from "../NewHome/Model";
import LoaderApp from '../LoaderApp';
import { changeProductCategorySubCategory } from '../../../redux/generalDuck';
import { connect } from 'react-redux';
import { validateAccess, validateSectionAccess, getSubsection } from '../../helpers/validations';
import HelperApp from '../HelperApp';

const Products = ({ access, changeProductCategorySubCategory }) => {

    /* const [listProducts, setListProducts] = useState([]); */
    const [sliderInner, setSliderInner] = useState([]);
    const [sliderPage, setSliderPage] = useState(0);
    const [sliderData, setSliderData] = useState([]);
    const [index, setIndex] = useState(0);
    const history = useHistory();
    const [listCollaborators, setListCollaborators] = useState([]);
    const [URLKiosk, setURLKiosk] = useState("");
    const [loading, setLoading] = useState(true);
    const [currentEnterprise, updateCurrentEnterprise] = useState([]);


    const [cardAccess, setCardAccess] = useState({
        configuracion: 0,
        ejecucion: 0,
        resultados: 0,
        acciones: 0,
        cumplimiento: 0,
    })
    const {
        configuracion,
        ejecucion,
        resultados,
        acciones,
        cumplimiento,
    } = cardAccess

    const [justReadSteps, setJustReadSteps] = useState({
        step_1: 0,
        step_2: 0,
        step_3: 0,
        step_4: 0,
    })
    const {
        step_1,
        step_2,
        step_3,
        step_4,
    } = justReadSteps

    useEffect(() => {
        // * Validation access in administration menu
        if (parseInt(getJwt('rol')) > 5 && access.access.list.length > 0) {
            //console.log(access.access.list)
            //console.log(validateAccess(access.access.list, 3))
            const section = validateAccess(access.access.list, 3)
            const section_2 = validateAccess(access.access.list, 4)
            //console.log(getSubsection(section[0].subSection, 20))
            const roles = getSubsection(section[0].subSection, 20)
            //console.log(getSubsection(section[0].subSection, 21))
            const colaboradores = getSubsection(section[0].subSection, 21)
            //console.log(getSubsection(section[0].subSection, 41))
            const encuestas = getSubsection(section[0].subSection, 41)
            setJustReadSteps({
                step_1: section_2[0].id_action,
                step_2: roles[0].id_action,
                step_3: colaboradores[0].id_action,
                step_4: encuestas[0].id_action,
            })
        }
    }, [access.access.list])

    useEffect(() => {
        try {
            //console.log(access.access.list)
            let showSection = validateSectionAccess(access.access.list, 2)
            if (showSection) {
                getuserData(getJwt("jtw")).then((result) => {
                    for (let x in result.enterprise) {
                        if (result.enterprise[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                            if (result.enterprise[x].incomplete === 0) {
                                if (result.enterprise[x].rolEnterpriseId === 4) {
                                    toastr.info("No tienes permiso para acceder en esta sección por esta empresa.");
                                    history.push('/inicio-colaborador')
                                }
                            } else {
                                if (result.enterprise[x].rolEnterpriseId !== 4) {
                                    // let message = "Faltan datos de la empresa " + result.enterprise[x].name + " por llenar. Ve a Administrar > Organizaciones > Configuración > Perfil de organización y llena los datos faltantes."
                                    let message = "Faltan datos de la empresa " + result.enterprise[x].name + " por llenar. Da click en este mensaje."
                                    toastr.warning(message, "¡Ooops!",{onclick: function() { 
                                        window.location = "/perfil-empresa/" + hashString("guaydigital") + result.enterprise[x].enterpriseId  + "=" + hashString(result.enterprise[x].name );
                                     }});
                                }else{
                                    toastr.info("No tienes permiso para acceder en esta sección por esta empresa.");
                                }
                                history.push('/inicio-colaborador')
                            }
                        }
                    }
                    for (let x in result.enterprise) {
                        if (result.enterprise[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                            updateCurrentEnterprise(result.enterprise[x]);
                            if (result.enterprise[x].incomplete === 0) {
                                getActiveProductsByEnterprise(getJwt("enterprise")).then((response) => {
                                    switch (response?.code) {
                                        case 1:
                                            let product = response.listProducts;
                                            let array = [];
                                            let array_2 = [];
                                            let arrayAux = [];
                                            let arrayCompare = [];
                                            for (let x in product) {
                                                if (array.length < 3) {
                                                    if (array_2.length > 0) {
                                                        array.push(array_2[0], product[x]);
                                                        array_2 = [];
                                                    } else {
                                                        array.push(product[x]);
                                                        array_2 = [];
                                                    }
                                                } else {
                                                    arrayAux.push(array);
                                                    array_2.push(product[x]);
                                                    array = [];
                                                }
                                            }
                                            for (let y in arrayAux) {
                                                for (let x in arrayAux[y]) {
                                                    arrayCompare.push(arrayAux[y][x]);
                                                }
                                            }
                                            arrayAux.push(product.filter(elemento => arrayCompare.indexOf(elemento) === -1));
                                            setSliderData(arrayAux);
                                            /* setListProducts(response.listProducts); */
                                            setSliderInner(arrayAux[0]);
                                            break;
                                        case 1001:
                                            toastr.error("La empresa no se encuentra activa");
                                            window.location = "/inicio-colaborador";
                                            break;
                                        default:
                                            toastr.error("Problemas al cargar los productos. Intente nuevamente.");
                                            break;
                                    }
                                });
                                getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                                    if (response) {
                                        switch (response.code) {
                                            case 1:
                                                setListCollaborators(response.listCollab);
                                                break;
                                            case 1001:
                                                setListCollaborators([]);
                                                break;
                                            default:
                                                toastr.error("Problemas al cargar la página actual. Intente nuevamente.");
                                                break;
                                        }
                                    } else {
                                        toastr.error("Problemas al cargar la página actual. Intente nuevamente.");
                                    }
                                });
                                setLoading(false);
                            } else {
                                if (getJwt('rol') === "3" || getJwt('rol') === "6") {
                                    sessionStorage.setItem('showMessage', 1);
                                    window.location = "/inicio-colaborador";
                                } else {
                                    sessionStorage.setItem('showMessage', 1);
                                    window.location = "/inicio-colaborador";
                                }
                            }
                        }
                    }
                });
                const cardAccessArr = validateAccess(access.access.list, 2)
                let obj = {
                    configuracion: 0,
                    ejecucion: 0,
                    resultados: 0,
                    acciones: 0,
                    cumplimiento: 0,
                }
                for (let x in cardAccessArr[0].subSection) {
                    if (cardAccessArr[0].subSection[x].idPlatformFunctionality === 14 && cardAccessArr[0].subSection[x].id_action !== 0) {
                        obj = {
                            ...obj,
                            configuracion: cardAccessArr[0].subSection[x].id_action,
                        }
                    }
                    if (cardAccessArr[0].subSection[x].idPlatformFunctionality === 15 && cardAccessArr[0].subSection[x].id_action !== 0) {
                        obj = {
                            ...obj,
                            ejecucion: cardAccessArr[0].subSection[x].id_action,
                        }
                    }
                    if (cardAccessArr[0].subSection[x].idPlatformFunctionality === 16 && cardAccessArr[0].subSection[x].id_action !== 0) {
                        obj = {
                            ...obj,
                            resultados: cardAccessArr[0].subSection[x].id_action,
                        }
                    }
                    if (cardAccessArr[0].subSection[x].idPlatformFunctionality === 17 && cardAccessArr[0].subSection[x].id_action !== 0) {
                        obj = {
                            ...obj,
                            acciones: cardAccessArr[0].subSection[x].id_action,
                        }
                    }
                    if (cardAccessArr[0].subSection[x].idPlatformFunctionality === 18 && cardAccessArr[0].subSection[x].id_action !== 0) {
                        obj = {
                            ...obj,
                            cumplimiento: cardAccessArr[0].subSection[x].id_action,
                        }
                    }
                }
                setCardAccess(obj)
            } else {
                if (access.access.list.length > 0) {
                    toastr.info('No tienes acceso a esta sección.', '¡Ooops!')
                    history.push('/inicio-colaborador');
                }
            }
        } catch (error) {
            console.log(error);
            toastr.error("Problemas al cargar la página actual. Intente nuevamente.");
        }
    }, [access.access.list, history]);

    useEffect(() => {
        getAdByEnterpriseAll(getJwt("enterprise"), getJwt('jtw'), getJwt('rol')).then((response) => {
            if (response) {
                if (response.code === 1) {
                    if (response.data.length > 0) {
                        let hostname = ((window || {}).location || {}).hostname;
                        let kiosk = hostname + "/centralSurvey/" + btoa(getJwt("enterprise"));
                        setURLKiosk(kiosk);
                    }
                }
            }
        });
    }, []);

    /* const hashString = (string) => {
        try {
            //let r = Math.random().toString(36).substring(7);
            var enc = window.btoa(string);
            return enc;
        } catch (error) {
            console.log(error);
        }
    } */

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }

    const sliderFunction = (indicator) => {
        let page;
        let arrayNum = sliderData.length - 1;
        if (indicator === 1) {
            page = sliderPage + 1;
            if (page <= arrayNum) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        } else {
            page = sliderPage - 1;
            if (page >= 0) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        }
        if (indicator === 1) {
            page = sliderPage + 1;
            if (page <= arrayNum) {
                let page_2 = page - 1;
                for (let x in sliderData[page]) {
                    /* if (getJwt('rol') === '6') {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('header-style-1');
                    } else {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('header-style-2');
                    } */
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-0').addClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').addClass('header-style-1');

                    /* if (getJwt('rol') === '6') {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('header-style-2');
                    } else {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('header-style-1');
                    } */
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-0').removeClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').removeClass('header-style-2');

                    /* if (getJwt('rol') === '6') {
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-1');
                    } else {
                        if (parseInt(getJwt('rol')) > 6) {
                            if (configuracion === 0) {
                                $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-2');
                            } else {
                                $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-1');
                            }
                        } else {
                            $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-2');
                        }
                    } */
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-0').addClass('header-style-2');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-1');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').addClass('header-style-1');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').addClass('header-style-1');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').addClass('header-style-1');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').addClass('header-style-1');

                    /* if (getJwt('rol') === '6') {
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('header-style-2');
                    } else {
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('header-style-1');
                    } */
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-0').removeClass('header-style-1');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('header-style-2');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').removeClass('header-style-2');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').removeClass('header-style-2');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').removeClass('header-style-2');
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').removeClass('header-style-2');

                    /* if (getJwt('rol') === '6') {
                        $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('show');
                    } else {
                        $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('show');
                    } */
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-0').addClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').removeClass('show');

                    /* if (getJwt('rol') === '6') {
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('show');
                    } else {
                        if (parseInt(getJwt('rol')) > 6) {
                            if (configuracion === 0) {
                                $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('show');
                            } else {
                                $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('show');
                            }
                        } else {
                            $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('show');
                        }
                    } */
                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-0').addClass('show');
                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('show');
                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').removeClass('show');
                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').removeClass('show');
                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').removeClass('show');
                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').removeClass('show');

                    /* $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').attr({ 'aria-expanded': 'true' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').attr({ 'aria-expanded': 'false' });

                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').attr({ 'aria-expanded': 'true' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').attr({ 'aria-expanded': 'false' }); */
                }
            }
        } else {
            page = sliderPage - 1;
            if (page >= 0) {
                let page_2 = page + 1;
                let flag = 0;
                for (let x in sliderData[page]) {
                    /* if (getJwt('rol') === '6') {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('header-style-1');
                    } else {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('header-style-2');
                    } */
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-0').addClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').addClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').addClass('header-style-1');

                    /* if (getJwt('rol') === '6') {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('header-style-2');
                    } else {
                        $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('header-style-1');
                    } */
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-0').removeClass('header-style-1');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').removeClass('header-style-2');
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').removeClass('header-style-2');
                    if (sliderData[page_2].length > flag) {
                        flag = flag = 1;
                        /* if (getJwt('rol') === '6') {
                            $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-1');
                        } else {
                            $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-2');
                        } */
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-0').addClass('header-style-2');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('header-style-1');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').addClass('header-style-1');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').addClass('header-style-1');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').addClass('header-style-1');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').addClass('header-style-1');

                        /* if (getJwt('rol') === '6') {
                            $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('header-style-2');
                        } else {
                            $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('header-style-1');
                        } */
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-0').removeClass('header-style-1');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('header-style-2');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').removeClass('header-style-2');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').removeClass('header-style-2');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').removeClass('header-style-2');
                        $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').removeClass('header-style-2');

                        /* if (getJwt('rol') === '6') {
                            $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('show');
                        } else {
                            if (parseInt(getJwt('rol')) > 6) {
                                if (configuracion === 0) {
                                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('show');
                                } else {
                                    $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('show');
                                }
                            } else {
                                $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').addClass('show');
                            }
                        } */
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-0').addClass('show');
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').removeClass('show');
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').removeClass('show');
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').removeClass('show');
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').removeClass('show');
                        $('#' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').removeClass('show');
                    }

                    /* if (getJwt('rol') === '6') {
                        $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('show');
                    } else {
                        $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').addClass('show');
                    } */
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-0').addClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').removeClass('show');
                    $('#' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').removeClass('show');


                    /* $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-1').attr({ 'aria-expanded': 'true' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-2').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-3').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-4').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page][x].idCategory + '-' + sliderData[page][x].idSubcategory + '-5').attr({ 'aria-expanded': 'false' });

                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-1').attr({ 'aria-expanded': 'true' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-2').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-3').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-4').attr({ 'aria-expanded': 'false' });
                    $('#heading-' + sliderData[page_2][x].idCategory + '-' + sliderData[page_2][x].idSubcategory + '-5').attr({ 'aria-expanded': 'false' }); */
                }
            }
        }
    }

    const changeBackgorund = (id) => {
        let word = $('#' + id).attr('class');
        let posicion = word.indexOf('header-style-1');
        var lastChar = id.substr(id.length - 1);
        let cadenaCorregida = id.substring(0, id.length - 1);
        switch (lastChar) {
            case "0":
                if (posicion !== -1) {
                    $('#' + id).removeClass('header-style-1');
                    $('#' + id).addClass('header-style-2');

                    $('#' + cadenaCorregida + "1").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "1").addClass('header-style-1');
                    $('#' + cadenaCorregida + "2").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "2").addClass('header-style-1');
                    $('#' + cadenaCorregida + "3").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "3").addClass('header-style-1');
                    $('#' + cadenaCorregida + "4").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "4").addClass('header-style-1');
                    $('#' + cadenaCorregida + "5").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "5").addClass('header-style-1');
                } else {
                    $('#' + id).removeClass('header-style-2');
                    $('#' + id).addClass('header-style-1');
                }
                break;
            case "1":
                if (posicion !== -1) {
                    $('#' + id).removeClass('header-style-1');
                    $('#' + id).addClass('header-style-2');

                    $('#' + cadenaCorregida + "0").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "0").addClass('header-style-1');
                    $('#' + cadenaCorregida + "2").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "2").addClass('header-style-1');
                    $('#' + cadenaCorregida + "3").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "3").addClass('header-style-1');
                    $('#' + cadenaCorregida + "4").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "4").addClass('header-style-1');
                    $('#' + cadenaCorregida + "5").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "5").addClass('header-style-1');
                } else {
                    $('#' + id).removeClass('header-style-2');
                    $('#' + id).addClass('header-style-1');
                }
                break;
            case "2":
                if (posicion !== -1) {
                    $('#' + id).removeClass('header-style-1');
                    $('#' + id).addClass('header-style-2');
                    $('#' + cadenaCorregida + "0").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "0").addClass('header-style-1');
                    $('#' + cadenaCorregida + "1").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "1").addClass('header-style-1');
                    $('#' + cadenaCorregida + "3").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "3").addClass('header-style-1');
                    $('#' + cadenaCorregida + "4").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "4").addClass('header-style-1');
                    $('#' + cadenaCorregida + "5").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "5").addClass('header-style-1');
                } else {
                    $('#' + id).removeClass('header-style-2');
                    $('#' + id).addClass('header-style-1');
                }
                break;
            case "3":
                if (posicion !== -1) {
                    $('#' + id).removeClass('header-style-1');
                    $('#' + id).addClass('header-style-2');
                    $('#' + cadenaCorregida + "0").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "0").addClass('header-style-1');
                    $('#' + cadenaCorregida + "2").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "2").addClass('header-style-1');
                    $('#' + cadenaCorregida + "1").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "1").addClass('header-style-1');
                    $('#' + cadenaCorregida + "4").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "4").addClass('header-style-1');
                    $('#' + cadenaCorregida + "5").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "5").addClass('header-style-1');
                } else {
                    $('#' + id).removeClass('header-style-2');
                    $('#' + id).addClass('header-style-1');
                }
                break;
            case "4":
                if (posicion !== -1) {
                    $('#' + id).removeClass('header-style-1');
                    $('#' + id).addClass('header-style-2');
                    $('#' + cadenaCorregida + "0").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "0").addClass('header-style-1');
                    $('#' + cadenaCorregida + "2").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "2").addClass('header-style-1');
                    $('#' + cadenaCorregida + "3").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "3").addClass('header-style-1');
                    $('#' + cadenaCorregida + "1").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "1").addClass('header-style-1');
                    $('#' + cadenaCorregida + "5").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "5").addClass('header-style-1');
                } else {
                    $('#' + id).removeClass('header-style-2');
                    $('#' + id).addClass('header-style-1');
                }
                break;
            case "5":
                if (posicion !== -1) {
                    $('#' + id).removeClass('header-style-1');
                    $('#' + id).addClass('header-style-2');
                    $('#' + cadenaCorregida + "0").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "0").addClass('header-style-1');
                    $('#' + cadenaCorregida + "2").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "2").addClass('header-style-1');
                    $('#' + cadenaCorregida + "3").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "3").addClass('header-style-1');
                    $('#' + cadenaCorregida + "4").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "4").addClass('header-style-1');
                    $('#' + cadenaCorregida + "1").removeClass('header-style-2');
                    $('#' + cadenaCorregida + "1").addClass('header-style-1');
                } else {
                    $('#' + id).removeClass('header-style-2');
                    $('#' + id).addClass('header-style-1');
                }
                break;
            default:
                break;
        }
    }

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const animationIn = () => {
        $('img.img-selection').show(500);
        $('h1.h1-selection').slideDown(800);
        $('p.p-selection').slideDown(1000);
        $('button.btn-selection').slideDown(1200);
    }

    const animationOut = () => {
        $('img.img-selection').hide(1000);
        $('h1.h1-selection').hide(1000);
        $('p.p-selection').hide(1000);
        $('button.btn-selection').hide(1000);
    }

    const setProductObject = (data) => {
        let validate = 0;
        for (let x in listCollaborators) {
            if (listCollaborators[x].active === 1) {
                validate = 1;
            }
        }
        if (validate === 1) {
            const saltValue_1 = "R3VheVByb2R1Y3RvczAw"; // GuayProductos00
            const saltValue_2 = "MDBHdWF5UHJvZHVjdG9z"; // 00GuayProductos
            const randomNum_1 = Math.floor(Math.random() * (9 - 0)) + 0;
            const randomNum_2 = Math.floor(Math.random() * (9 - 0)) + 0;
            const randomNum_3 = Math.floor(Math.random() * (9 - 0)) + 0;
            const randomNum_4 = Math.floor(Math.random() * (9 - 0)) + 0;
            const product = JSON.stringify(data);
            const ciphertext = randomNum_1 + saltValue_1 + randomNum_2 + btoa(product) + randomNum_3 + saltValue_2 + randomNum_4;
            sessionStorage.setItem('hulk', ciphertext);
            changeProductCategorySubCategory(data.idProduct, data.idCategory, data.idSubcategory);
            history.push('/administracion-servicios');
        } else {
            $('#message_1').show();
        }
    }

    const copyText = () => {
        let aux = document.createElement("input");
        aux.setAttribute("value", URLKiosk);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        toastr.success("Se copió correctamente el enlace en el portapapeles.");
    }

    function cardTextTitle(data) {
        if (data.idCategory === 1) {
            return `${data.nameSubcategory}`;
        } else {
            return `${data.nameCategory}`;
        }
    }

    function cardTextSubTitle(data) {
        if (data.nameSubcategory === "") {
            return `(${data.nameProduct})`;
        } else {
            return `(${data.nameCategory} - ${data.nameProduct})`;
        }
    }

    // const saludLaboral = ["Sabias_que_saludlaboral-01.jpg", "Sabias_que_saludlaboral-02.jpg", "Sabias_que_saludlaboral-03.jpg", "Sabias_que_saludlaboral-04.jpg", "Sabias_que_saludlaboral-05.jpg", "Sabias_que_saludlaboral-06.jpg"];

    // const factorHumano = ["Sabias_que_factorhumano-07.jpg", "Sabias_que_factorhumano-08.jpg", "Sabias_que_factorhumano-09.jpg", "Sabias_que_factorhumano-10.jpg", "Sabias_que_factorhumano-11.jpg", "Sabias_que_factorhumano-12.jpg", "Sabias_que_factorhumano-13.jpg"];

    // const climaLaboral = ["Sabias_que_clima-14.jpg", "Sabias_que_clima-15.jpg", "Sabias_que_clima-16.jpg", "Sabias_que_clima-17.jpg", "Sabias_que_clima-18.jpg", "Sabias_que_clima-19.jpg", "Sabias_que_clima-20.jpg"];

    const carruselImg = [
        {name:"sabias-que_2024_administradores4.png", url:""},
        {name:"sabias-que_2024_administradores5.png", url:""},
        {name:"sabias-que_2024_administradores8.png", url:""},
        {name:"sabias-que_2024_administradores2.png", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/Admins/Cargas%20cuantitativas.pdf"},
        {name:"sabias-que_2024_administradores6.png", url:""},
        {name:"sabias-que_2024_administradores3.png", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/Admins/Escasa%20claridad%20de%20funciones.pdf"},
        {name:"sabias-que_2024_administradores7.png", url:""},
        {name:"sabias-que_2024_administradores9.png", url:""},
        {name:"sabias-que_2024_administradores10.png", url:""},
        {name:"sabias-que_2024_administradores1.png", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/Admins/Frente%20a%20casos%20de%20violencia%20laboral%2C%20hostigamiento%20y%20acoso%20sexual_2024%20(2).pdf"},
    ];

    const handleClick = (url) => {
        if(url !== "") {
            window.open(url, '_blank');
        }
    };

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content new-guay-plataform bootomFooter" >
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            {
                                loading === true ? (
                                    <div className="row">
                                        <div className="col-12 my-1 text-center">
                                            <h1>Validando datos de la empresa</h1>
                                        </div>
                                        <LoaderApp />
                                    </div>
                                ) : (
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-3">
                                        <div className="row">
                                            <HelperApp />
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-3">
                                                <div className="row">
                                                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-12 mb-1">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 p-0 bg-white-1 d-flex justify-content-center flex-wrap align-content-center" >
                                                            <Carousel className="carousle-control-style w-100" activeIndex={index} onSelect={handleSelect} onSlide={animationOut} onSlid={animationIn}>
                                                                {/* {
                                                                    saludLaboral.map((element) => (
                                                                        <Carousel.Item interval={10000}>
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2 p-0 d-flex justify-content-center">
                                                                                <img
                                                                                    src={`./../img/inicioAdministrador/Salud_Laboral/${element}`}
                                                                                    alt={element}
                                                                                    id={index}
                                                                                    style={{ height: '50vh' }}
                                                                                />
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    ))
                                                                }
                                                                {
                                                                    factorHumano.map((element) => (
                                                                        <Carousel.Item interval={10000}>
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2 p-0 d-flex justify-content-center">
                                                                                <img
                                                                                    src={`./../img/inicioAdministrador/Factor_Humano/${element}`}
                                                                                    alt={element}
                                                                                    id={index}
                                                                                    style={{ height: '50vh' }}
                                                                                />
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    ))
                                                                }
                                                                {
                                                                    climaLaboral.map((element) => (
                                                                        <Carousel.Item interval={10000}>
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2 p-0 d-flex justify-content-center">
                                                                                <img
                                                                                    src={`./../img/inicioAdministrador/Clima_Laboral/${element}`}
                                                                                    alt={element}
                                                                                    id={index}
                                                                                    style={{ height: '50vh' }}
                                                                                />
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    ))
                                                                } */}
                                                                {
                                                                    carruselImg.map((element) => (
                                                                        <Carousel.Item interval={10000}>
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2 p-0 d-flex justify-content-center">
                                                                                <img
                                                                                    src={`https://storage.googleapis.com/guay-api-production/Operacion/Home/SabiasQue/Admins/${element.name}`}
                                                                                    alt={element.name}
                                                                                    className={element.url !== "" ? 'img-fluid pointer':'img-fluid'}
                                                                                    id={index}
                                                                                    onClick={() => handleClick(element.url)}
                                                                                    // style={{ height: '30vh' }}
                                                                                />
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    ))
                                                                }
                                                            </Carousel>
                                                        </div>
                                                    </div>
                                                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-12 menu-option-admin">
                                                        <div className="card">
                                                            <div className="card-header bg-blue-guay p-1">
                                                                <span>Administración</span>
                                                            </div>
                                                            {
                                                                getJwt('rol') === "3" ? (
                                                                    <div className="card-body p-1">
                                                                        <span className="d-block text-base pointer mt-1">Organización / Grupo</span>
                                                                        <a className="link-blue-1" href="administracion-usuarios"><span className="d-block link-blue-1 pointer mt-1">Usuarios y Roles</span></a>
                                                                        <a className="link-blue-1" href="administracion-colaboradores" onClick={() => sessionStorage.getItem('fromOrg') === 'undefined' ? null : sessionStorage.removeItem('fromOrg')}><span className="d-block link-blue-1 pointer mt-1">Colaboradores</span></a>
                                                                        {/* <span className="d-block text-base pointer mt-1">Canal de Denuncia</span> */}
                                                                        {URLKiosk === ""
                                                                            ?
                                                                            (
                                                                                <span className="d-block text-base pointer mt-1">Quiosco</span>
                                                                            )
                                                                            :
                                                                            (
                                                                                <span className="d-block link-blue-1 pointer mt-1" onClick={() => $('#kioskMessage').show()}>Quiosco</span>
                                                                            )
                                                                        }
                                                                        <a className="link-blue-1" href="cumplimiento"><span className="d-block link-blue-1 pointer mt-1">Cumplimiento</span></a>
                                                                        <span className="d-block text-base pointer mt-1">Encuestas Personalizadas</span>
                                                                    </div>
                                                                ) : (
                                                                    getJwt('rol') === "6" ? (
                                                                        <div className="card-body p-1">
                                                                            <span className="d-block text-base pointer mt-1">Organización / Grupo</span>
                                                                            <span className="d-block text-base pointer mt-1">Usuarios y Roles</span>
                                                                            <span className="d-block text-base pointer mt-1">Colaboradores</span>
                                                                            <span className="d-block text-base pointer mt-1">Quiosco</span>
                                                                            <a className="link-blue-1" href="cumplimiento" onClick={() => sessionStorage.setItem("idp-idc", "1-1-1")}><span className="d-block link-blue-1 pointer mt-1">Cumplimiento</span></a>
                                                                            <span className="d-block text-base pointer mt-1">Encuestas Personalizadas</span>
                                                                        </div>
                                                                    ) : (
                                                                        parseInt(getJwt('rol')) > 6 ? (
                                                                            <div className="card-body p-1">
                                                                                {
                                                                                    step_1 === 0 ? (
                                                                                        <span className="d-block text-base pointer mt-1">Organización / Grupo</span>
                                                                                    ) : (
                                                                                        <a className="link-blue-1" href={"/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name)}><span className="d-block link-blue-1 pointer mt-1">Organización / Grupo</span></a>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    step_2 === 0 ? (
                                                                                        <span className="d-block text-base pointer mt-1">Usuarios y Roles</span>
                                                                                    ) : (
                                                                                        <a className="link-blue-1" href="administracion-usuarios"><span className="d-block link-blue-1 pointer mt-1">Usuarios y Roles</span></a>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    step_3 === 0 ? (
                                                                                        <span className="d-block text-base pointer mt-1">Colaboradores</span>
                                                                                    ) : (
                                                                                        <a className="link-blue-1" href="administracion-colaboradores" onClick={() => sessionStorage.getItem('fromOrg') === 'undefined' ? null : sessionStorage.removeItem('fromOrg')}><span className="d-block link-blue-1 pointer mt-1">Colaboradores</span></a>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    URLKiosk === "" ? (
                                                                                        <span className="d-block text-base pointer mt-1">Quiosco</span>
                                                                                    ) : (
                                                                                        <span className="d-block link-blue-1 pointer mt-1" onClick={() => $('#kioskMessage').show()}>Quiosco</span>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    cumplimiento === 0 ? (
                                                                                        <span className="d-block text-base pointer mt-1">Cumplimiento</span>
                                                                                    ) : (
                                                                                        <a className="link-blue-1" href="cumplimiento" onClick={() => sessionStorage.setItem("idp-idc", "1-1-1")}><span className="d-block link-blue-1 pointer mt-1">Cumplimiento</span></a>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    step_4 === 0 ? (
                                                                                        <span className="d-block text-base pointer mt-1">Encuestas Personalizadas</span>
                                                                                    ) : (
                                                                                        <a className="link-blue-1" href="encuestas"><span className="d-block link-blue-1 pointer mt-1">Encuestas Personalizadas</span></a>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <div className="card-body p-1">
                                                                                <a className="link-blue-1" href={"/perfil-empresa/" + hashString("guaydigital") + currentEnterprise.enterpriseId + "=" + hashString(currentEnterprise.name)}><span className="d-block link-blue-1 pointer mt-1">Organización / Grupo</span></a>
                                                                                <a className="link-blue-1" href="administracion-usuarios"><span className="d-block link-blue-1 pointer mt-1">Usuarios y Roles</span></a>
                                                                                <a className="link-blue-1" href="administracion-colaboradores" onClick={() => sessionStorage.getItem('fromOrg') === 'undefined' ? null : sessionStorage.removeItem('fromOrg')}><span className="d-block link-blue-1 pointer mt-1">Colaboradores</span></a>
                                                                                {/* <span className="d-block text-base pointer mt-1">Canal de Denuncia</span> */}
                                                                                {URLKiosk === ""
                                                                                    ?
                                                                                    (
                                                                                        <span className="d-block text-base pointer mt-1">Quiosco</span>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <span className="d-block link-blue-1 pointer mt-1" onClick={() => $('#kioskMessage').show()}>Quiosco</span>
                                                                                    )
                                                                                }
                                                                                <a className="link-blue-1" href="cumplimiento" onClick={() => sessionStorage.setItem("idp-idc", "1-1-1")}><span className="d-block link-blue-1 pointer mt-1">Cumplimiento</span></a>
                                                                                <a className="link-blue-1" href="encuestas"><span className="d-block link-blue-1 pointer mt-1">Encuestas Personalizadas</span></a>
                                                                            </div>
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-9">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                    <ul className="nav nav-taps">
                                                        <li className="nav-item w-100">
                                                            <a className="nav-link nav-link-3 active text-center rounded" data-toggle="tab" href="#products">Diagnóstico</a>
                                                        </li>
                                                        {/* <li className="nav-item nav-link-disable pointer w-50 text-center rounded-right">
                            Servicios continuos
                        </li> */}
                                                    </ul>
                                                    <div className="tab-content bg-white-1 p-1">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 tab-pane active" id="products">
                                                            <div className="row">
                                                                <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto" style={{ zIndex: '5' }}>
                                                                    <div className="row mt-2 mb-2">
                                                                        {sliderInner.map((data, index) => {
                                                                            return (
                                                                                <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 mb-2" key={index}>
                                                                                    {data.acquired === 1 || data.acquired === 2
                                                                                        ?
                                                                                        (
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                                <div className="d-flex justify-content-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                                                    <span className="font-weight-bold font-medium-1">{cardTextTitle(data)}</span>
                                                                                                </div>
                                                                                                <div className="d-flex justify-content-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                                                    <span className="text-base-guay font-small-3">{cardTextSubTitle(data)}</span>
                                                                                                </div>
                                                                                                <div className="d-flex justify-content-center mb-1">
                                                                                                    <span className="text-base font-small-3">{data.dateOfExpiry ? "Vigencia a " + data.dateOfExpiry : <span className="transparent-color">00/00/00</span>}</span>
                                                                                                    <Popup trigger={<i className="ft-help-circle icon-size-xs icon-guay rounded-circle pointer align-self-center ml-1" />} position="top">
                                                                                                        <div className="p-1 text-center">
                                                                                                            Fecha en la que termina la vigencia de tu servicio, recuerda renovar antes
                                                                                                        </div>
                                                                                                    </Popup>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12" style={{ opacity: '0.5' }}>
                                                                                                <div className="d-flex justify-content-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                                                    <span className="font-weight-bold font-medium-1">{cardTextTitle(data)}</span>
                                                                                                </div>
                                                                                                <div className="d-flex justify-content-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                                                    <span className="text-base-guay font-small-3">{cardTextSubTitle(data)}</span>
                                                                                                </div>
                                                                                                <div className="d-flex justify-content-center mb-1">
                                                                                                    <span className="text-base font-small-3">{data.dateOfExpiry ? data.dateOfExpiry : <span className="transparent-color">00/00/00</span>}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {data.acquired === 1 || data.acquired === 2
                                                                                        ?
                                                                                        (
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 p-0 card-style-1">
                                                                                                <div id={'product-' + data.idCategory + '-' + data.idSubcategory} role="tablist" >
                                                                                                    <div id={'product-' + data.idCategory + '-' + data.idSubcategory} className="card accordion collapse-icon accordion-icon-rotate" style={{ margin: 'none', boxShadow: 'none' }}>
                                                                                                        <a
                                                                                                            id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-0'}
                                                                                                            className="card-header text-base-guay header-style-2 p-1 font-small-3"
                                                                                                            data-toggle="collapse"
                                                                                                            href={'#' + data.idCategory + '-' + data.idSubcategory + '-0'}
                                                                                                            aria-expanded="true"
                                                                                                            aria-controls={data.idCategory + '-' + data.idSubcategory + '-0'}
                                                                                                            onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-0')}>
                                                                                                            <div className="card-title lead collapsed font-small-3 font-small-3">Recursos guay</div>
                                                                                                        </a>
                                                                                                        <div
                                                                                                            id={data.idCategory + '-' + data.idSubcategory + '-0'}
                                                                                                            role="tabpanel"
                                                                                                            data-parent={'#product-' + data.idCategory + '-' + data.idSubcategory}
                                                                                                            aria-labelledby={'heading-' + data.idCategory + '-' + data.idSubcategory + '-0'}
                                                                                                            className='collapse show'
                                                                                                        >
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1 font-small-2 text-justify">
                                                                                                                    <div
                                                                                                                        className="row link-blue-2 pointer d-flex justify-content-center"
                                                                                                                        onClick={() => {
                                                                                                                            changeProductCategorySubCategory(data.idProduct, data.idCategory, data.idSubcategory)
                                                                                                                            history.push('/recursosDescargables')
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className="col-12 text-center pb-1">
                                                                                                                            <div className="col-6 mx-auto">
                                                                                                                                <img
                                                                                                                                    src="./../img/InicioAdministrador_2/icon-folder.png"
                                                                                                                                    alt="img-guay"
                                                                                                                                    className="w-75"
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <span className="font-medium-1"><ins>Recursos guay</ins></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <p className="text-base">
                                                                                                                        Aquí encontrarás posters, fichas, videos e infografías con las que cuenta el servicio para apoyarte
                                                                                                                        en la sensibilización de tus colaboradores.
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            /* getJwt('rol') === '6' ? (
                                                                                                                <span className="card-header text-base p-1 font-small-3">
                                                                                                                    <div className="card-title lead collapsed font-small-3 font-small-3">Configuración</div>
                                                                                                                </span>
                                                                                                            ) : ( */
                                                                                                                parseInt(getJwt('rol')) > 5 ? (
                                                                                                                    configuracion > 0 ? (
                                                                                                                        <a
                                                                                                                            id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                                            className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                            data-toggle="collapse"
                                                                                                                            href={'#' + data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                                            aria-expanded="false"
                                                                                                                            aria-controls={data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                                            onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-1')}>
                                                                                                                            <div className="card-title lead collapsed font-small-3 font-small-3">Configuración</div>
                                                                                                                        </a>
                                                                                                                    ) : (
                                                                                                                        <span className="card-header text-base p-1 font-small-3">
                                                                                                                            <div className="card-title lead collapsed font-small-3 font-small-3">Configuración</div>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                ) : (
                                                                                                                    <a
                                                                                                                        id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                                        className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                        data-toggle="collapse"
                                                                                                                        href={'#' + data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                                        aria-expanded="false"
                                                                                                                        aria-controls={data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                                        onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-1')}>
                                                                                                                        <div className="card-title lead collapsed font-small-3 font-small-3">Configuración</div>
                                                                                                                    </a>
                                                                                                                )
                                                                                                            /* ) */
                                                                                                        }
                                                                                                        <div
                                                                                                            id={data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                            role="tabpanel"
                                                                                                            data-parent={'#product-' + data.idCategory + '-' + data.idSubcategory}
                                                                                                            aria-labelledby={'heading-' + data.idCategory + '-' + data.idSubcategory + '-1'}
                                                                                                            /* className={
                                                                                                                getJwt('rol') === '6' ? ("collapse")
                                                                                                                    : 
                                                                                                                    parseInt(getJwt('rol')) > 5 ?
                                                                                                                        configuracion > 0 ?
                                                                                                                            ("collapse show") :
                                                                                                                            ("collapse")
                                                                                                                        : ("collapse show")
                                                                                                            } */
                                                                                                            className="collapse"
                                                                                                        >
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1 font-small-2 text-justify">
                                                                                                                    <div
                                                                                                                        className="row link-blue-2 pointer d-flex justify-content-center"
                                                                                                                        onClick={() => setProductObject(data)}>
                                                                                                                        <div className="col-12 text-center pb-1">
                                                                                                                            <div className="col-6 mx-auto">
                                                                                                                                <img src="./../img/InicioAdministrador_2/iconos_registro_documental-24.png" alt="img-guay" className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                            </div>
                                                                                                                            <span className="font-medium-1"><ins>Configuración</ins></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <p className="text-base">
                                                                                                                        Una vez que diste de alta a tus colaboradores estas listo para calendarizar los cuestionarios e
                                                                                                                        iniciar esta aventura, con ello comienza la difusión de la comunicación correspondiente a tu
                                                                                                                        servicio.
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            parseInt(getJwt('rol')) > 6 ? (
                                                                                                                ejecucion > 0 ? (
                                                                                                                    <a
                                                                                                                        id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-2'}
                                                                                                                        className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                        data-toggle="collapse"
                                                                                                                        href={'#' + data.idCategory + '-' + data.idSubcategory + '-2'}
                                                                                                                        aria-expanded="false"
                                                                                                                        aria-controls={data.idCategory + '-' + data.idSubcategory + '-2'}
                                                                                                                        onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-2')}>
                                                                                                                        <div className="card-title lead collapsed font-small-3">Ejecución</div>
                                                                                                                    </a>
                                                                                                                ) : (
                                                                                                                    <span className="card-header text-base p-1 font-small-3">
                                                                                                                        <div className="card-title lead collapsed font-small-3 font-small-3">Ejecución</div>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <a
                                                                                                                    id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-2'}
                                                                                                                    className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                    data-toggle="collapse"
                                                                                                                    href={'#' + data.idCategory + '-' + data.idSubcategory + '-2'}
                                                                                                                    aria-expanded="false"
                                                                                                                    aria-controls={data.idCategory + '-' + data.idSubcategory + '-2'}
                                                                                                                    onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-2')}>
                                                                                                                    <div className="card-title lead collapsed font-small-3">Ejecución</div>
                                                                                                                </a>
                                                                                                            )
                                                                                                        }
                                                                                                        <div id={data.idCategory + '-' + data.idSubcategory + '-2'} role="tabpanel" data-parent={'#product-' + data.idCategory + '-' + data.idSubcategory} aria-labelledby={'heading-' + data.idCategory + '-' + data.idSubcategory + '-2'} className="collapse">
                                                                                                            <div className="card-content">

                                                                                                                {data.idProduct === 1 && data.idCategory === 1
                                                                                                                    ?
                                                                                                                    (
                                                                                                                        <div className="card-body p-1 font-small-2 text-base text-justify">
                                                                                                                            <a href={"/gestion-documental"} onClick={() => sessionStorage.setItem("idp-idc", `${data.idProduct}-${data.idCategory}`)}>
                                                                                                                                <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                                    <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6 mx-auto text-center pb-1">
                                                                                                                                        <img src="./../img/InicioAdministrador_2/iconos_registro_documental-25.png" alt="img-guay" className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                        <span className="font-medium-1"><ins>Ejecución</ins></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </a>
                                                                                                                            <p className="text-base">
                                                                                                                                En esta sección darás seguimiento a la participación y resultados de las encuestas. También
                                                                                                                                encontrarás un lugar para concentrar la documentación necesaria para el servicio.
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                    :
                                                                                                                    (
                                                                                                                        <div className="card-body p-1 font-small-2 text-base text-justify">
                                                                                                                            <a href={"/gestion-documental"} onClick={() => sessionStorage.setItem("idp-idc", `${data.idProduct}-${data.idCategory}`)}>
                                                                                                                                <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                                    <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6 mx-auto text-center pb-1">
                                                                                                                                        <img src="./../img/InicioAdministrador_2/iconos_registro_documental-25.png" alt="img-guay" className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                        <span className="font-medium-1"><ins>Ejecución</ins></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </a>
                                                                                                                            <p className="text-base">
                                                                                                                                En esta sección darás seguimiento a la participación y resultados de las encuestas. También
                                                                                                                                encontrarás un lugar para concentrar la documentación necesaria para el servicio.
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            parseInt(getJwt('rol')) > 6 ? (
                                                                                                                resultados > 0 ? (
                                                                                                                    <a
                                                                                                                        id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-3'}
                                                                                                                        className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                        data-toggle="collapse"
                                                                                                                        href={'#' + data.idCategory + '-' + data.idSubcategory + '-3'}
                                                                                                                        aria-expanded="false"
                                                                                                                        aria-controls={data.idCategory + '-' + data.idSubcategory + '-3'}
                                                                                                                        onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-3')}>
                                                                                                                        <div className="card-title lead collapsed font-small-3">Resultados</div>
                                                                                                                    </a>
                                                                                                                ) : (
                                                                                                                    <span className="card-header text-base p-1 font-small-3">
                                                                                                                        <div className="card-title lead collapsed font-small-3 font-small-3">Resultados</div>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <a
                                                                                                                    id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-3'}
                                                                                                                    className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                    data-toggle="collapse"
                                                                                                                    href={'#' + data.idCategory + '-' + data.idSubcategory + '-3'}
                                                                                                                    aria-expanded="false"
                                                                                                                    aria-controls={data.idCategory + '-' + data.idSubcategory + '-3'}
                                                                                                                    onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-3')}>
                                                                                                                    <div className="card-title lead collapsed font-small-3">Resultados</div>
                                                                                                                </a>
                                                                                                            )
                                                                                                        }
                                                                                                        <div id={data.idCategory + '-' + data.idSubcategory + '-3'} role="tabpanel" data-parent={'#product-' + data.idCategory + '-' + data.idSubcategory} aria-labelledby={'heading-' + data.idCategory + '-' + data.idSubcategory + '-3'} className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1 font-small-2 text-base text-justify">
                                                                                                                    <a href={"/resultados"} onClick={() => { changeProductCategorySubCategory(data.idProduct, data.idCategory, data.idSubcategory); sessionStorage.setItem("idp-idc", `${data.idProduct}-${data.idCategory}-${data.idSubcategory}`); sessionStorage.setItem("idc", data.idCategory); }}>
                                                                                                                        <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                            <div className="col-12 text-center pb-1">
                                                                                                                                <div className="col-6 mx-auto">
                                                                                                                                    <img src="./../img/InicioAdministrador_2/iconos_registro_documental-26.png" alt="img-guay" className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                </div>
                                                                                                                                <span className="font-medium-1"><ins>Resultados</ins></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <p className="text-base">
                                                                                                                        Cuando concluya cada ejercicio dirígete a esta sección para conocer tus resultados
                                                                                                                        organizacionales y los analiticos obtenidos.
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            parseInt(getJwt('rol')) > 6 ? (
                                                                                                                acciones > 0 ? (
                                                                                                                    <a
                                                                                                                        id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-4'}
                                                                                                                        className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                        data-toggle="collapse"
                                                                                                                        href={'#' + data.idCategory + '-' + data.idSubcategory + '-4'}
                                                                                                                        aria-expanded="false"
                                                                                                                        aria-controls={data.idCategory + '-' + data.idSubcategory + '-4'}
                                                                                                                        onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-4')}>
                                                                                                                        <div className="card-title lead collapsed font-small-3">Acciones</div>
                                                                                                                    </a>
                                                                                                                ) : (
                                                                                                                    <span className="card-header text-base p-1 font-small-3">
                                                                                                                        <div className="card-title lead collapsed font-small-3 font-small-3">Acciones</div>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <a
                                                                                                                    id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-4'}
                                                                                                                    className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                    data-toggle="collapse"
                                                                                                                    href={'#' + data.idCategory + '-' + data.idSubcategory + '-4'}
                                                                                                                    aria-expanded="false"
                                                                                                                    aria-controls={data.idCategory + '-' + data.idSubcategory + '-4'}
                                                                                                                    onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-4')}>
                                                                                                                    <div className="card-title lead collapsed font-small-3">Acciones</div>
                                                                                                                </a>
                                                                                                            )
                                                                                                        }
                                                                                                        <div id={data.idCategory + '-' + data.idSubcategory + '-4'} role="tabpanel" data-parent={'#product-' + data.idCategory + '-' + data.idSubcategory} aria-labelledby={'heading-' + data.idCategory + '-' + data.idSubcategory + '-4'} className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                {
                                                                                                                    data.idCategory === 1 || data.idCategory === 6 || data.idCategory === 2 || data.idCategory === 4
                                                                                                                        ?
                                                                                                                        (
                                                                                                                            <a
                                                                                                                                href={"/acciones"}
                                                                                                                                onClick={() => {
                                                                                                                                    changeProductCategorySubCategory(data.idProduct, data.idCategory, data.idSubcategory);
                                                                                                                                    sessionStorage.setItem("idc", data.idCategory)
                                                                                                                                    sessionStorage.setItem("idsc", data.idSubcategory)
                                                                                                                                }}>
                                                                                                                                <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                                    <div className="col-12 text-center pb-1">
                                                                                                                                        <div className="col-6 mx-auto">
                                                                                                                                            <img
                                                                                                                                                src="./../img/InicioAdministrador_2/iconos_registro_documental-27.png"
                                                                                                                                                alt="img-guay"
                                                                                                                                                className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                        </div>
                                                                                                                                        <span className="font-medium-1"><ins>Acciones</ins></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </a>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        (
                                                                                                                            <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                                <div className="col-12 text-center pb-1">
                                                                                                                                    <div className="col-6 mx-auto">
                                                                                                                                        <img
                                                                                                                                            src="./../img/InicioAdministrador_2/iconos_registro_documental-27.png"
                                                                                                                                            alt="img-guay"
                                                                                                                                            className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                    </div>
                                                                                                                                    <span className="font-medium-1" onClick={() => {
                                                                                                                                        toastr.info("Estamos trabajando para traerte las mejores herramientas.");
                                                                                                                                    }
                                                                                                                                    }><ins>Acciones</ins></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                }
                                                                                                                <div className="card-body p-1 font-small-2 text-base text-justify">
                                                                                                                    ¿Y ahora qué? La información obtenida es muy valiosa, con ella determinaremos cómo
                                                                                                                    mejorar mediante diversos planes de acción y protocolos, que te recomendamos para que los
                                                                                                                    adaptes a tu organización.
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            parseInt(getJwt('rol')) > 6 ? (
                                                                                                                cumplimiento > 0 ? (
                                                                                                                    <a
                                                                                                                        id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-5'}
                                                                                                                        className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                        data-toggle="collapse"
                                                                                                                        href={'#' + data.idCategory + '-' + data.idSubcategory + '-5'}
                                                                                                                        aria-expanded="false"
                                                                                                                        aria-controls={data.idCategory + '-' + data.idSubcategory + '-5'}
                                                                                                                        onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-5')}>
                                                                                                                        <div className="card-title lead collapsed font-small-3">Cumplimiento</div>
                                                                                                                    </a>
                                                                                                                ) : (
                                                                                                                    <span className="card-header text-base p-1 font-small-3">
                                                                                                                        <div className="card-title lead collapsed font-small-3 font-small-3">Cumplimiento</div>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <a
                                                                                                                    id={'heading-' + data.idCategory + '-' + data.idSubcategory + '-5'}
                                                                                                                    className="card-header text-base-guay header-style-1 p-1 font-small-3"
                                                                                                                    data-toggle="collapse"
                                                                                                                    href={'#' + data.idCategory + '-' + data.idSubcategory + '-5'}
                                                                                                                    aria-expanded="false"
                                                                                                                    aria-controls={data.idCategory + '-' + data.idSubcategory + '-5'}
                                                                                                                    onClick={() => changeBackgorund('heading-' + data.idCategory + '-' + data.idSubcategory + '-5')}>
                                                                                                                    <div className="card-title lead collapsed font-small-3">Cumplimiento</div>
                                                                                                                </a>
                                                                                                            )
                                                                                                        }
                                                                                                        <div id={data.idCategory + '-' + data.idSubcategory + '-5'} role="tabpanel" data-parent={'#product-' + data.idCategory + '-' + data.idSubcategory} aria-labelledby={'heading-' + data.idCategory + '-' + data.idSubcategory + '-5'} className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                {
                                                                                                                    data.idCategory === 6 || data.idCategory === 2 ? (
                                                                                                                        <span onClick={() => {
                                                                                                                            toastr.info("Estamos trabajando para traerte las mejores herramientas.");
                                                                                                                        }}>
                                                                                                                            <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                                <div className="col-12 text-center pb-1">
                                                                                                                                    <div className="col-6 mx-auto">
                                                                                                                                        <img src="./../img/InicioAdministrador_2/iconos_registro_documental-28.png" alt="img-guay" className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                    </div>
                                                                                                                                    <span className="font-medium-1"><ins>Cumplimiento</ins></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    ) : (

                                                                                                                        <a href={"/cumplimiento"} onClick={() => sessionStorage.setItem("idp-idc", `${data.idProduct}-${data.idCategory}-${data.idSubcategory}`)}>
                                                                                                                            <div className="row link-blue-2 pointer d-flex justify-content-center">
                                                                                                                                <div className="col-12 text-center pb-1">
                                                                                                                                    <div className="col-6 mx-auto">
                                                                                                                                        <img src="./../img/InicioAdministrador_2/iconos_registro_documental-28.png" alt="img-guay" className="w-100" /* style={{ width: '50px' }} */ />
                                                                                                                                    </div>
                                                                                                                                    <span className="font-medium-1"><ins>Cumplimiento</ins></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </a>
                                                                                                                    )
                                                                                                                }
                                                                                                                <div className="card-body p-1 font-small-2 text-base text-justify">
                                                                                                                    Finalizó esta aventura revisa tu evidencia y para los ejercicios de carácter normativo hallarás
                                                                                                                    el panel de cumplimiento.
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 p-0 card-style-1">
                                                                                                <div id={'product-' + data.idCategory + '-' + data.idSubcategory} role="tablist" aria-multiselectable="true" style={{ opacity: '0.5' }}>
                                                                                                    <div className="card accordion collapse-icon accordion-icon-rotate" style={{ margin: 'none', boxShadow: 'none' }}>
                                                                                                        <span
                                                                                                            className="card-header header-style-3 p-1">
                                                                                                            <div className="card-title lead collapsed font-small-3">Recursos guay</div>
                                                                                                        </span>
                                                                                                        <span
                                                                                                            className="card-header header-style-3 p-1">
                                                                                                            <div className="card-title lead collapsed font-small-3">Configuración</div>
                                                                                                        </span>
                                                                                                        <div role="tabpanel" className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <span
                                                                                                            className="card-header header-style-3 p-1">
                                                                                                            <div className="card-title lead collapsed font-small-3">Ejecución</div>
                                                                                                        </span>
                                                                                                        <div role="tabpanel" className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <span
                                                                                                            className="card-header header-style-3 p-1">
                                                                                                            <div className="card-title lead collapsed font-small-3">Resultados</div>
                                                                                                        </span>
                                                                                                        <div role="tabpanel" className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <span
                                                                                                            className="card-header header-style-3 p-1">
                                                                                                            <div className="card-title lead collapsed font-small-3">Acciones</div>
                                                                                                        </span>
                                                                                                        <div role="tabpanel" className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <span
                                                                                                            className="card-header header-style-3 p-1">
                                                                                                            <div className="card-title lead collapsed font-small-3">Cumplimiento</div>
                                                                                                        </span>
                                                                                                        <div role="tabpanel" className="collapse">
                                                                                                            <div className="card-content">
                                                                                                                <div className="card-body p-1"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="btn-middle-1 d-flex justify-content-center flex-wrap align-content-center">
                                                                                                    <a href="tienda"><button className="btn btn-blue-3 btn-sm" >Adquiérelo</button></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="btn-middle-1 d-flex justify-content-between flex-wrap align-content-center" style={{ zIndex: '1' }}>
                                                                    <div
                                                                        className="py-5 pr-1 pointer"
                                                                        onClick={() => sliderFunction(0)}>
                                                                        <i className='ft ft-chevron-left icon-medium-size font-weight-bold text-blue-sec ' />
                                                                    </div>
                                                                    <div
                                                                        className="py-5 pl-1 pointer"
                                                                        onClick={() => sliderFunction(1)}>
                                                                        <i className='ft ft-chevron-right icon-medium-size font-weight-bold text-blue-sec' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="container tab-pane" id="services">
                            <h1>Servicios continuos</h1>
                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<!-- The Modal -->*/}
                                        <div
                                            className="modal"
                                            id="message_1"
                                            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                                            data-backdrop="false">
                                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                                <div
                                                    className="modal-content"
                                                    style={{
                                                        borderRadius: '1.5rem',
                                                        border: '1px solid gray',
                                                        overflow: 'auto'
                                                    }}>
                                                    {/*<!-- Modal body -->*/}
                                                    <div className="modal-body scrollbar-style">
                                                        <div className="col-lg-12 mb-1">
                                                            <div className="row">
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                                                </div>
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center">
                                                                    <span className="font-medium-2"></span>
                                                                    <p>
                                                                        No cuentas con colaboradores activos. Puedes ir a la pantalla Administración de colaboradores dando click <a className="link-blue-2" href="administracion-colaboradores" onClick={() => sessionStorage.getItem('fromOrg') === 'undefined' ? null : sessionStorage.removeItem('fromOrg')}>aquí</a>.
                                                                    </p>
                                                                </div>
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                                                    <button
                                                                        className="btn btn-pink btn-sm pointer"
                                                                        onClick={() => $('#message_1').hide()} >Aceptar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- The Modal -->*/}
                                        <div
                                            className="modal"
                                            id="kioskMessage"
                                            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                                            data-backdrop="false">
                                            <div className="modal-dialog modal-dialog-centered modal-sm">
                                                <div
                                                    className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto"
                                                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            id="close" onClick={() => $('#kioskMessage').hide()}>&times;</button>
                                                    </div>
                                                    {/*<!-- Modal body -->*/}
                                                    <div className="modal-body modal-div-style-1 container-style-1">
                                                        <div className="row">
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                                <p>¿Qué deseas hacer?</p>
                                                            </div>
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                <button
                                                                    className="btn btn-blue-3 btn-sm float-left"
                                                                    onClick={() => copyText()}>Copiar enlace</button>
                                                                <button
                                                                    className="btn btn-blue-3 btn-sm float-right"
                                                                    onClick={() => window.open("centralSurvey/" + btoa(getJwt("enterprise")))}>Ir a quiosco</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <Footer />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (access) => {
    return {
        access
    }
}

export default connect(mapStateToProps, { changeProductCategorySubCategory })(Products);