import React, {
    useState,
    useEffect,
} from 'react'
// TODO: API calls
import { getResources } from '../Models/Catalog_model'
// TODO: Components
import Header from '../NewHome/headerAdministrador'
import Menu from '../Menu'
import Footer from '../footerApp'
import Table from './Table'
import VideosLayout from './VideosLayout'
// TODO: Generic Func
import {
    redirectIfNotAuthenticated,
    getJwt,
} from '../../../lib/auth'
import { connect } from 'react-redux'
import {
    validateAccess,
    getSubsection,
} from '../../helpers/validations'
import { useHistory } from 'react-router-dom'
import * as toastr from 'toastr'
import ModalViewPdf from '../enterprises/ModalViewPdf'
import ImagesViewer from '../ImagesViewer'

const DownSrcController = ({
    general,
    access,
}) => {
    const history = useHistory()
    const [showTable, setShowTable] = useState(0)
    const [serviceName, setServiceName] = useState('')
    const [listFichas, setListFichas] = useState([])
    const [listPosters, setListPosters] = useState([])
    const [listWebinars, setListWebinars] = useState([])
    const [listInfografias, setListInfografias] = useState([])
    const [urlShowPdf, setUrlShowPdf] = useState("")
    const [showImage, setShowImage] = useState("");

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
            const section = validateAccess(access.list, 2)
            const subsection = getSubsection(section[0].subSection, 43)
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push('/inicio-colaborador')
            } else {
                if (subsection[0].id_action === 0) {
                    toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                    history.push('/inicio-administrador')
                }
            }
        }
    }, [access.list, history])
    useEffect(() => {
        if (general.idProduct === 1) {
            let getServiceName = `${general.idProduct}-${general.idCategory}-${general.idSubCategory}` === '1-1-1' ? (
                'NOM - Estado Emocional'
            ) : (
                `${general.idProduct}-${general.idCategory}-${general.idSubCategory}` === '1-1-2' ? (
                    'NOM - Entorno Laboral'
                ) : (
                    'Clima Laboral'
                )
            )
            setServiceName(getServiceName)
            getResources(general.idProduct, general.idCategory, general.idSubCategory).then(response => {
                if (response.code === 1) {
                    const getFichas = response.taResourcesAdminList.filter(data => data.resourceType === 1 && data.active === 1)
                    let newFichas = []
                    getFichas.forEach((data, index) => {
                        newFichas.push({ ...data, orderNumber: index + 1 })
                    })
                    setListFichas(newFichas)
                    const getPosters = response.taResourcesAdminList.filter(data => data.resourceType === 2 && data.active === 1)
                    let newPosters = []
                    getPosters.forEach((data, index) => {
                        newPosters.push({ ...data, orderNumber: index + 1 })
                    })
                    setListPosters(newPosters)
                    const getWebinars = response.taResourcesAdminList.filter(data => data.resourceType === 3 && data.active === 1)
                    let newWebinars = []
                    getWebinars.forEach((data, index) => {
                        newWebinars.push({ ...data, orderNumber: index + 1 })
                    })
                    setListWebinars(newWebinars)
                    const getInfografias = response.taResourcesAdminList.filter(data => data.resourceType === 4 && data.active === 1)
                    let newInfografias = []
                    getInfografias.forEach((data, index) => {
                        newInfografias.push({ ...data, orderNumber: index + 1 })
                    })
                    setListInfografias(newInfografias)
                }
            })
        }
    }, [general])
    if (redirectIfNotAuthenticated()) {
        return (
            <div className='app-content bootomFooter'>
                <div className='content-overlay'>
                    <ModalViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf}/>
                    <ImagesViewer showImage={showImage} />
                    <Header />
                    <Menu />
                    <div className='content-wrapper bootomFooter'>
                        <div className='content-header'>
                            <div className='col-11 mx-auto'>
                                <div className='content-header-right breadcrumb-new mt-2 d-flex justify-content-end'>
                                    <div className='row breadcrumbs-top d-inline-block'>
                                        <div className='breadcrumb-wrapper col-12'>
                                            <ol className='breadcrumb'>
                                                <li className='breadcrumb-item font-medium-3'><a href='/inicio-administrador'>Administración</a></li>
                                                <li className='breadcrumb-item font-medium-3 text-blue-sec'>Recursos guay</li>
                                                <li className='breadcrumb-item font-medium-3 text-blue-sec font-weight-bold'>
                                                    {
                                                        showTable === 0 ? 'Fichas ' :
                                                            showTable === 1 ? 'Posters' :
                                                                showTable === 2 ? 'Videos' :
                                                                    showTable === 3 && 'Infografías'
                                                    }
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-body'>
                            <div className='col-11 mx-auto mt-1'>
                                <h3 className='font-weight-bold'>Recursos {serviceName}</h3>
                            </div>
                            <div className='col-11 mx-auto mt-1'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='col-12 mb-3'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <button
                                                        className={`btn ${showTable === 0 ? 'btn-blue-3' : 'btn btn-grey'} btn-block font-weight-bold`}
                                                        onClick={() => setShowTable(0)}
                                                    >Fichas</button>
                                                </div>
                                                <div className='col'>
                                                    <button
                                                        className={`btn ${showTable === 1 ? 'btn-blue-3' : 'btn btn-grey'} btn-block font-weight-bold`}
                                                        onClick={() => setShowTable(1)}
                                                    >Posters</button>
                                                </div>
                                                <div className='col'>
                                                    <button
                                                        className={`btn ${showTable === 3 ? 'btn-blue-3' : 'btn btn-grey'} btn-block font-weight-bold`}
                                                        onClick={() => setShowTable(3)}
                                                    >Infografías</button>
                                                </div>
                                                <div className='col'>
                                                    <button
                                                        className={`btn ${showTable === 2 ? 'btn-blue-3' : 'btn btn-grey'} btn-block font-weight-bold`}
                                                        onClick={() => setShowTable(2)}
                                                    >Videos</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <h3 className='font-weight-bold'>{
                                                showTable === 0 ? 'Fichas enviadas por correo' :
                                                    showTable === 1 ? 'Posters generados' :
                                                        showTable === 2 ? 'Videos' :
                                                            showTable === 3 && 'Infografías'
                                            }</h3>
                                        </div>
                                        {showTable === 2 ? (
                                            <VideosLayout
                                                listWebinars={listWebinars}
                                            />
                                        ) : (
                                            <Table
                                                listFichas={listFichas}
                                                listPosters={listPosters}
                                                listInfografias={listInfografias}
                                                showTable={showTable}
                                                setUrlShowPdf={setUrlShowPdf}
                                                setShowImage={setShowImage}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div >
                </div >
            </div >
        )
    }
}

const mapStateToProps = ({
    general,
    access,
}) => {
    return {
        general,
        access,
    }
}

export default connect(mapStateToProps)(DownSrcController)
