import React from "react";
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from "jquery";
// Documentation
import PropTypes from 'prop-types';

const ActMonModalguay = ({
    modalguayGeneralData,
    modalCollaboratorsList }) => {
    const {
        descStatus,
        nameCollab,
        percentage,
        idStatus,
    } = modalguayGeneralData;
    /**
     * Table props - Start
     */
    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    };
    /**
     * 
     * @returns
     */
    const handleShowTable = () => {
        let array = [];
        for (let index = 0; index < modalCollaboratorsList.length; index++) {
            const element = modalCollaboratorsList[index];
            if (index === 0) {
                array.push(element);
            } else {
                if (element.idPhase === modalCollaboratorsList[index - 1].idPhase) {
                    let auxArray = [];
                    auxArray.push(element);
                    auxArray[0].descPhase = "";
                    array.push(auxArray[0]);
                    for (let x in array) {
                        if (array[x].rank === modalCollaboratorsList[index - 1].rank) {
                            array[x].nuevoValor = 1;
                        }
                    }
                } else {
                    array.push(element);
                }
            }
        }
        return (array)
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const statusIcon = (cell, row, index) => {
        if (cell === 1) {
            return (
                <span>OK</span>
            )
        }
        if (cell === 2) {
            return (
                <span className="text-red">Fuera de proceso</span>
            )
        }
        if (cell === 3) {
            return (
                <span className="text-green">Finalizado</span>
            )
        }
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const handleDateStepFormat = (cell, row, index) => {
        let completDate = cell.split("-");
        let month = "";
        switch (completDate[1]) {
            case "01":
                month = "Enero"
                break;
            case "02":
                month = "Febrero"
                break;
            case "03":
                month = "Marzo"
                break;
            case "04":
                month = "Abril"
                break;
            case "05":
                month = "Mayo"
                break;
            case "06":
                month = "Junio"
                break;
            case "07":
                month = "Julio"
                break;
            case "08":
                month = "Agosto"
                break;
            case "09":
                month = "Septiembre"
                break;
            case "10":
                month = "Octubre"
                break;
            case "11":
                month = "Noviembre"
                break;
            default:
                month = "Diciembre"
                break;
        }
        return (
            <span>{month + " " + completDate[2] + ", " + completDate[0]}</span>
        )
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const handlePhaseFormat = (cell, row, index) => {
        if (row.descPhase === "") {
            return (
                <span></span>
            )
        } else {
            return (
                <span>{cell}</span>
            )
        }
    }
    const trClassFormat = (row, rowIndex) => {
        if (row.nuevoValor === 1) {
            return (
                "border-none-1"
            )
        }
    }
    /**
     * Table props - End
     * 
     * Progress bar props - Start
     * 
     * @param {*} percentage 
     * @returns 
     */
    const barOptions = (percentage, idStatus) => {
        let width = 0;
        let backgroundColor = "#efeff6";
        switch (idStatus) {
            case 1:
                //blue
                width = `${percentage}%`;
                backgroundColor = "#2188b4";
                break;
            case 2:
                //red
                width = `${percentage}%`;
                backgroundColor = "#E74C3C";
                break;
            case 3:
                //green
                width = `${percentage}%`;
                backgroundColor = "#9cb537";
                break;
            default:
                width = 0;
                backgroundColor = "#efeff6";
                break;
        }
        return {
            width,
            backgroundColor
        }
    };
    /**
     * Progress bar props - Start
     */
    const handleTextColor = (idStatus) => {
        let color = "#efeff6";
        switch (idStatus) {
            case 1:
                //blue
                color = "#2188b4";
                break;
            case 2:
                //red
                color = "#E74C3C";
                break;
            case 3:
                //green
                color = "#9cb537";
                break;
            default:
                color = "#efeff6";
                break;
        }
        return {
            color
        }
    };
    /**
     * Progress bar props - End
     */
    return (
        <div
            className="modal"
            id="modalActMonModalguay"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => $('#modalActMonModalguay').hide()}>&times;</button>
                    </div>
                    <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <h1 className="text-base-guay">Tracking - guay</h1>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-start font-medium-1">
                                    <span>Colaborador: <span className="text-base-guay font-weight-bold">{nameCollab}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-start font-medium-1">
                                    <span>Estatus: <span className="text-base-guay font-weight-bold">{descStatus}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-5 col-lg-5">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-start font-medium-1">
                                            <span className="text-base">Avance:</span>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 px-0">
                                            <div
                                                className="progress"
                                                style={{ marginTop: '4px' }}
                                            >
                                                <div
                                                    className="progress-bar"
                                                    style={barOptions(percentage, idStatus)} />
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-start font-medium-1">
                                            <span
                                                className="font-weight-bold"
                                                style={handleTextColor(idStatus)} >{percentage}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <BootstrapTable
                                data={handleShowTable()}
                                hover
                                pagination={true}
                                trClassName={trClassFormat}
                                /* tableStyle={{ padding: '0px', fontSize: '.8rem', color: '#6b6f82' }} */
                                options={options}
                                headerStyle={{ border: 'red 0px solid' }}
                                bodyStyle={{ border: 'green 0px solid' }}
                                tableStyle={{ border: '#9cb0d1 2.5px solid', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField='rank'
                                    hidden ></TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='descPhase'
                                    dataFormat={handlePhaseFormat}
                                    /* width="20%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Fase</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='descStage'
                                    /* width="25%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Etapa</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='descActivity'
                                    /* width="25%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Actividad</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='dateStep'
                                    dataFormat={handleDateStepFormat}
                                    /* width="20%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Fecha de actualización</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='idStatus'
                                    dataFormat={statusIcon}
                                    /* width="10%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Estatus</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ActMonModalguay.propTypes = {
    modalguayGeneralData: PropTypes.object.isRequired,
    modalCollaboratorsList: PropTypes.array.isRequired
}

export default ActMonModalguay;
