import React from 'react';

const PregnantMessage = () => {
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <div className="row font-medium-5 text-base-guay font-weight-bold">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                    <i
                        className="ft ft-check-circle"
                        style={{ fontSize: '150px', color: 'grey' }} />
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center text-center mt-5">
                    <span>Muchas gracias por tu participación, en breve te llegará un correo con algunas recomendaciones.</span>
                </div>
            </div>
        </div>
    )
}

export default PregnantMessage;
