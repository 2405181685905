import axios from "axios";
// TODO: Tools
import * as toastr from 'toastr'
/**
 * Catalog of benefits
 * @returns 
 */
export const getBenefits = async () => {
    try {
        let result = await axios({
            url: global.base_url + "benefits/getBenefits",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth,
            },
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Save click when user clicked in benefit
 * @param {object} request 
 * @returns 
 */
export const setInteractionBenefits = async (request) => {
    try {
        let result = await axios({
            url: global.base_url + "benefits/setInteractionBenefits",
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth,
            },
            data: request,
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {string} idEnterprise 
 * @returns 
 */
export const getBenefitsByEnterpriseAndCollab = async (idEnterprise, idCollab) => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}benefits/getBenefitsByEnterpriseAndCollab?idEnterprise=${idEnterprise}&idCollab=${idCollab}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    //toastr.info('No hay beneficios activos.', '¡Ooops!')
                    //obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {string} idEnterprise 
 * @returns 
 */
export const getPromotionsByEnterpriseAndCollab = async (idEnterprise, idCollab) => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}benefits/getPromotionsByEnterpriseAndCollab?idEnterprise=${idEnterprise}&idCollab=${idCollab}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    //toastr.info('No hay promociones activas.', '¡Ooops!')
                    //obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}


/**
 * Relation benefits with Enterprise
 * @returns 
 */
export const checkEnterpriseBenefit = async (idEnterprise) => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}benefits/checkEnterpriseBenefit?idEnterprise=${idEnterprise}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    toastr.info('La empresa no se encuentra activa.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                case 1002:
                    toastr.info('El Beneficio no se encuentra activo.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                case 1003:
                    toastr.info('La empresa no tiene colaboradores activos.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getCredentialSigma = async (idEnterprise, idUser, idCollaborator) => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}benefits/getCredentialSigma?idEnterprise=${idEnterprise}&idUser=${idUser}&idCollaborator=${idCollaborator}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    //toastr.info('No hay promociones activas.', '¡Ooops!')
                    //obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
