import React, { useState } from 'react'

const PromotionsFilters = ({
    showCard,
    promotions,
    filterData,
    categories,
    types,
    setFilterData,
    setCategories,
    setTypes,
}) => {
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTypes, setSelectedTypes] = useState([])
    const [selectedOrder, setSelectedOrder] = useState(0)
    const handleClick = (id, type) => {
        //console.log('id, type', id, type)
        // Function to know if the id was already selected
        const alreadySelected = (element, arr) => {
            //console.log('element, arr', element, arr)
            const flag = arr.indexOf(element)
            return flag
        }
        // Get array
        let arr = type === 1 ? selectedCategories : type === 2 ? selectedTypes : selectedOrder
        let newArr = []
        let alreadyFlag = false
        // Validate if the id was already selected
        //console.log('alreadySelected', alreadySelected(id, arr))
        if (alreadySelected(id, arr) !== -1) {
            // Create a new array without current id
            for (let x in arr) {
                if (arr[x] !== id) {
                    newArr.push(arr[x])
                }
            }
            alreadyFlag = true
        } else {
            arr.push(id)
        }
        //console.log('arr', arr)
        //console.log('newArr', newArr)
        // Fucntion to check the input
        const checkedOption = (element, arr, keyName) => {
            //console.log('element, arr, keyName', element, arr, keyName)
            let newArr = []
            for (let x in arr) {
                if (arr[x][keyName] === element) {
                    newArr.push({ ...arr[x], checked: !arr[x].checked })
                } else {
                    newArr.push(arr[x])
                }
            }
            return newArr
        }
        // Set array
        if (type === 1) {
            const newcategories = alreadyFlag ? newArr : arr
            if (newcategories.length === 0 && selectedTypes.length === 0) {
                setFilterData(promotions)
                //console.log('checkedOption', checkedOption(id, categories, 'idPromotionCategory'))
                setCategories(checkedOption(id, categories, 'idPromotionCategory'))
                setSelectedCategories(newcategories)
                return
            }
            if (newcategories.length === 0 && selectedTypes.length > 0) {
                let getNewTypes = []
                for (let x in selectedTypes) {
                    getNewTypes.push(promotions.filter(element => element.idPromotionType === selectedTypes[x]))
                }
                //console.log('getNewTypes', getNewTypes)
                let joinNewTypes = []
                for (let x in getNewTypes) {
                    for (let y in getNewTypes[x]) {
                        joinNewTypes.push(getNewTypes[x][y])
                    }
                }
                //console.log('joinNewTypes', joinNewTypes)
                setFilterData(joinNewTypes)
                setCategories(checkedOption(id, categories, 'idPromotionCategory'))
                setSelectedCategories(newcategories)
                return
            }
            let getNewCategories = []
            for (let x in newcategories) {
                getNewCategories.push(promotions.filter(element => element.idPromotionCategory === newcategories[x]))
            }
            //console.log('getNewCategories', getNewCategories)
            let joinNewCategories = []
            for (let x in getNewCategories) {
                for (let y in getNewCategories[x]) {
                    joinNewCategories.push(getNewCategories[x][y])
                }
            }
            //console.log('joinNewCategories', joinNewCategories)
            let getNewTypes = []
            for (let x in selectedTypes) {
                getNewTypes.push(joinNewCategories.filter(element => element.idPromotionType === selectedTypes[x]))
            }
            //console.log('getNewTypes', getNewTypes)
            let joinNewTypes = []
            for (let x in getNewTypes) {
                for (let y in getNewTypes[x]) {
                    joinNewTypes.push(getNewTypes[x][y])
                }
            }
            //console.log('joinNewTypes', joinNewTypes)
            let newFilterData = []
            if (joinNewTypes.length === 0) {
                newFilterData = joinNewTypes.concat(joinNewCategories)
            }
            //console.log('newFilterData', newFilterData)
            setFilterData(joinNewTypes.length === 0 ? newFilterData : joinNewTypes)
            //console.log('checkedOption', checkedOption(id, categories, 'idPromotionCategory'))
            setCategories(checkedOption(id, categories, 'idPromotionCategory'))
            setSelectedCategories(newcategories)
        }
        if (type === 2) {
            const newTypes = alreadyFlag ? newArr : arr
            if (selectedCategories.length === 0 && newTypes.length === 0) {
                setFilterData(promotions)
                //console.log('checkedOption', checkedOption(id, types, 'idPromotionType'))
                setTypes(checkedOption(id, types, 'idPromotionType'))
                setSelectedTypes(newTypes)
                return
            }
            if (selectedCategories.length > 0 && newTypes.length === 0) {
                let getNewCategories = []
                for (let x in selectedCategories) {
                    getNewCategories.push(promotions.filter(element => element.idPromotionCategory === selectedCategories[x]))
                }
                //console.log('getNewCategories', getNewCategories)
                let joinNewCategories = []
                for (let x in getNewCategories) {
                    for (let y in getNewCategories[x]) {
                        joinNewCategories.push(getNewCategories[x][y])
                    }
                }
                //console.log('joinNewCategories', joinNewCategories)
                setFilterData(joinNewCategories)
                //console.log('checkedOption', checkedOption(id, types, 'idPromotionType'))
                setTypes(checkedOption(id, types, 'idPromotionType'))
                setSelectedTypes(newTypes)
                return
            }
            let getNewCategories = []
            for (let x in selectedCategories) {
                getNewCategories.push(promotions.filter(element => element.idPromotionCategory === selectedCategories[x]))
            }
            //console.log('getNewCategories', getNewCategories)
            let joinNewCategories = []
            for (let x in getNewCategories) {
                for (let y in getNewCategories[x]) {
                    joinNewCategories.push(getNewCategories[x][y])
                }
            }
            //console.log('joinNewCategories', joinNewCategories)
            let getNewTypes = []
            for (let x in newTypes) {
                if (joinNewCategories.length === 0) {
                    getNewTypes.push(promotions.filter(element => element.idPromotionType === newTypes[x]))
                } else {
                    getNewTypes.push(joinNewCategories.filter(element => element.idPromotionType === newTypes[x]))
                }
            }
            //console.log('getNewTypes', getNewTypes)
            let joinNewTypes = []
            for (let x in getNewTypes) {
                for (let y in getNewTypes[x]) {
                    joinNewTypes.push(getNewTypes[x][y])
                }
            }
            //console.log('joinNewTypes', joinNewTypes)
            let newFilterData = []
            if (joinNewTypes.length === 0) {
                newFilterData = joinNewTypes.concat(joinNewCategories)
            }
            //console.log('newFilterData', newFilterData)
            setFilterData(joinNewTypes.length === 0 ? newFilterData : joinNewTypes)
            //console.log('checkedOption', checkedOption(id, types, 'idPromotionType'))
            setTypes(checkedOption(id, types, 'idPromotionType'))
            setSelectedTypes(newTypes)
        }
    }
    const sortByName = id => {
        //console.log('filterData', filterData)
        let newArr = []
        for (let x in filterData) {
            newArr.push(filterData[x])
        }
        if (id === 1) {
            const sortByAZ = newArr.sort(function (a, b) {
                if (a.nameEnterprise > b.nameEnterprise) {
                    return 1;
                }
                if (a.nameEnterprise < b.nameEnterprise) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            })
            //console.log('sortByAZ', sortByAZ)
            setFilterData(sortByAZ)
            setSelectedOrder(id)
        } else {
            const sortByZA = newArr.sort(function (a, b) {
                if (b.nameEnterprise > a.nameEnterprise) {
                    return 1;
                }
                if (b.nameEnterprise < a.nameEnterprise) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            })
            //console.log('sortByZA', sortByZA)
            setFilterData(sortByZA)
            setSelectedOrder(id)
        }
    }
    return (
        <div className='col-2'>
            <div className='card rounded-lg border-top-green-1'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <img
                                //className='img-fluid pointer'
                                style={{ width: '25px', height: '25px' }}
                                src="./../img/promotions/icono_filtros.png"
                                alt='icons'
                                data-toggle="modal"
                                data-target="#cardModal"
                            />
                            <span className='ml-1 font-medium-2 font-weight-bold text-base-light-gray'>Filtros</span>
                        </div>
                        <div className='col-12'>
                            <div
                                className="dropdown"
                                id="myDropdown"
                            >
                                <button
                                    type="button"
                                    className="btn bg-white dropdown-toggle btn-block text-base-light-gray text"
                                    data-toggle="dropdown"
                                    style={{ textAlign: 'left' }}
                                >
                                    <span style={{ paddingLeft: '25px' }}>Categorías</span>
                                </button>
                                <div className="dropdown-menu w-100 rounded-lg">
                                    {categories.map(categorie => (
                                        <div
                                            key={`categorie-${categorie.idPromotionCategory}`}
                                            className={`w-100 p-1 pointer span-hover-blue-light ${categorie.checked && 'span-blue-light'}`}
                                            onClick={() => handleClick(categorie.idPromotionCategory, 1)}
                                        >
                                            <span>{categorie.description}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div
                                className="dropdown"
                                id="myDropdown"
                            >
                                <button
                                    type="button"
                                    className="btn bg-white dropdown-toggle btn-block text-base-light-gray"
                                    data-toggle="dropdown"
                                    style={{ textAlign: 'left' }}
                                >
                                    <span style={{ paddingLeft: '25px' }}>Descuentos</span>
                                </button>
                                <div className="dropdown-menu w-100 with-box-shadow-3">
                                    {types.map(type => (
                                        <div
                                            key={`categorie-${type.idPromotionType}`}
                                            className={`w-100 p-1 pointer span-hover-blue-light ${type.checked && 'span-blue-light'}`}
                                            onClick={() => handleClick(type.idPromotionType, 2)}

                                        >
                                            <span>{type.description}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div
                                className="dropdown"
                                id="myDropdown"
                            >
                                <button
                                    type="button"
                                    className="btn bg-white  dropdown-toggle btn-block text-base-light-gray"
                                    data-toggle="dropdown"
                                    style={{ textAlign: 'left' }}
                                >
                                    <span style={{ paddingLeft: '25px' }}>Ordenar</span>
                                </button>
                                <div className="dropdown-menu w-100 with-box-shadow-3">
                                    <span
                                        className="dropdown-item pointer"
                                        onClick={() => sortByName(1)}
                                    >A - Z</span>
                                    <span
                                        className="dropdown-item pointer"
                                        onClick={() => sortByName(2)}
                                    >Z - A</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col d-flex justify-content-center flex-wrap align-content-center text-center'>
                                    {(selectedCategories.length > 0 || selectedTypes.length > 0) && (
                                        <span
                                            className='text-info text-underline pointer align-bottom'
                                            //style={{ paddingLeft: '35px' }}
                                            onClick={() => {
                                                let newCat = []
                                                for (let x in categories) {
                                                    newCat.push({ ...categories[x], checked: false })
                                                }
                                                let newTy = []
                                                for (let x in types) {
                                                    newTy.push({ ...types[x], checked: false })
                                                }
                                                setCategories(newCat)
                                                setTypes(newTy)
                                                setFilterData(promotions)
                                                setSelectedCategories([])
                                                setSelectedTypes([])
                                            }}
                                        >Quitar filtros</span>
                                    )}
                                </div>
                                {/* {showCard && (
                            <div className='col'>

                                <div className='col-12 d-flex justify-content-center'>
                                    <img
                                        className='img-fluid pointer'
                                        src="./../img/promotions/icono_ tarjeta_digital.png"
                                        alt='icons'
                                        data-toggle="modal"
                                        data-target="#cardModal"
                                    />
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                    <span
                                        className='pointer font-weight-bold text-center'
                                        data-toggle="modal"
                                        data-target="#cardModal"
                                    >Tarjeta digital</span>
                                </div>
                            </div>
                        )} */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionsFilters