import React, { useState, useEffect } from 'react';
import Header from '../NewHome/headerAdministrador';
import Footer from '../footerApp';
import Menu from "../Menu";
import $ from "jquery";
import Swal from 'sweetalert2';
import { getJwt } from "../../../lib/auth";
import { setEnterpriseGroup } from "./Model";
import { getAddressByCP } from "../Models/Catalog_model";
import { remaining_character } from "../../../lib/utils/generalFuntions";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { validateFile } from "../../../lib/utils/validateFile";
import { getuserData } from "../NewHome/Model";
import { useHistory } from "react-router-dom";
import HelperApp from '../HelperApp';

const RegGroup = () => {

    const [stepNum, setStep] = useState(1);
    const [enterprise, upEnterprise] = useState({
        userId: getJwt("jtw"),
        comercialName: "",
        legalName: "",
        shortName: "",
        description: "",
        logo: "",
        rfc: "",
        state: 0,
        town: 0,
        neibg: 0,
        zipCode: 0,
        street: "",
        ext_num: "",
        int_num: "",
        complete: 0,
        mision: "",
        vision: "",
        objetivos: "",
        valores: ""
    });
    const [enterprises, upEnterprises] = useState([]);
    const [files, upFiles] = useState([]);
    const history = useHistory();
    useEffect(() => {
        if (getJwt('rol') === '3') {
            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
            history.push("/inicio-colaborador")
        }
    }, [history])
    useEffect(() => {
        getuserData(getJwt("jtw")).then((result) => {
            upEnterprises(result.enterprise);
        });
    }, []);

    const getValue = (e) => {
        $("#" + e.target.name).removeClass("border-success");
        let value = e.target.value;
        if (e.target.getAttribute("add-count") === "1") {
            let inpID = e.target.id;
            let maxLength = e.target.getAttribute("data-max-length");
            let eCount = e.target.getAttribute("element-name-character-count");
            remaining_character(value.length, inpID, maxLength, eCount);
        }
        switch (e.target.name) {

            case "zipCode":
                if (value.length + 0 >= 5) {
                    $("#zipCode").addClass("border-success");
                    $("#zipCode").removeClass("border-danger-guay");
                    getAddressByCP(value).then((respAddress) => {
                        if (respAddress.length > 0) {
                            $("#state, #town, #neibg").html("")
                            $("#state").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                            $("#town").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                            $("#town").addClass("border-success");
                            $("#town").removeClass("border-danger-guay");
                            $("#state").addClass("border-success");
                            $("#state").removeClass("border-danger-guay");
                            $("#neibg").addClass("border-success");
                            $("#neibg").removeClass("border-danger-guay");
                            $.each(respAddress, function (i, item) {
                                $("#neibg").append('<option value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                $("#neibg").attr("disabled", false);
                            });
                            upEnterprise({
                                ...enterprise,
                                zipCode: value,
                                state: respAddress[0].idState,
                                town: respAddress[0].idTown,
                                neibg: respAddress[0].idNeighborhood,
                                street: $("#street").val(),
                                complete: 3
                            });
                        } else {
                            Swal.fire({
                                title: '<p class="text-white mb-2">¡Oops!</p>',
                                text: "El código postal registrado no existe. Intenta nuevamente",
                                showCloseButton: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                dangerMode: true
                            });
                            $(".swal2-title").addClass("bg-header-swal");
                            $(".swal2-actions").addClass("w-100 justify-content-around");
                            $(".swal2-confirm").removeClass("swal2-styled");
                            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                            $(".swal2-cancel").removeClass("swal2-styled");
                            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                        }
                    });
                }
                break;
            case "rfc":
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).addClass("border-success");
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: "",
                        complete: setComplete(),
                    });
                } else if (e.target.value.length < 12 || e.target.value.length > 13){
                    $("#" + e.target.name).addClass("border-danger-guay");
                    $("#" + e.target.name).removeClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: e.target.value,
                        complete: setComplete(),
                    });
                }else{
                    $("#" + e.target.name).addClass("border-success");
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: e.target.value,
                        complete: setComplete(),
                    });
                }
                break;
            default:
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).removeClass("border-success");
                    $("#" + e.target.name).addClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: "",
                        complete: setComplete(),
                    });
                } else {
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#fiscalRegimenLabel").removeClass("text-base-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: e.target.value,
                        complete: setComplete(),
                    });
                }
                break;
        }
    }

    const renderFilePers = () => {
        $("#file").click();
    }

    const setComplete = () => {
        if (stepNum === 1) {
            return 1;
        }

        if (stepNum === 2) {
            return 2;
        }
        if (stepNum === 3) {
            return 3;

        }
    }

    const onChangeHandler = (e) => {
        try {
            let file = e.target.files[0];
            validateFile(file).then((res) => {
                if (res.success === 1) {

                    let reader = new FileReader();
                    reader.onload = function (e) {
                        $('#img-person').attr('src', e.target.result);
                    };

                    reader.readAsDataURL(file);
                    upFiles({
                        ...files,
                        file
                    });
                } else {
                    Swal.fire({
                        title: '<p class="text-white mb-2">¡Oops!</p>',
                        text: "" + res.msg + "",
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        dangerMode: true
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = () => {
        try {

            let answered = false;
            let form_1 = 0;
            // let form_2 = 0;
            // let form_3 = 0;

            switch (stepNum) {
                case 1:
                    if (enterprise.comercialName.length === 0) {
                        $("#comercialName").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Nombre Comercial', '¡Espera!');

                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.shortName.length === 0) {
                        $("#shortName").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Nombre Corto', '¡Espera!');

                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.legalName.length === 0) {
                        $("#legalName").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Nombre legal', '¡Espera!');

                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.description.length === 0) {
                        $("#description").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Descripción', '¡Espera!');

                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.rfc.length > 0 && (enterprise.rfc.length > 13 || enterprise.rfc.length < 12)) {
                        $("#rfc").addClass("border-danger-guay");
                        toastr.error('El campo: RFC tiene que tener como mínimo 12 caracteres y máximo 13.', '¡Espera!');

                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (form_1 === 5) {
                        return answered = true;
                    }
                    break;
                /* case 2:
                     if (enterprise.vision.length === 0) {
                         $("#vision").addClass("border-danger-guay");
                         toastr.error('Falta ingresar la visión del grupo', '¡Espera!');
                     } else {
                         form_2 = form_2 + 1;
                     }
                     if (enterprise.mision.length === 0) {
                         $("#mision").addClass("border-danger-guay");
                         toastr.error('Falta ingresar la misión del grupo', '¡Espera!');
                     } else {
                         form_2 = form_2 + 1;
                     }
                     if (enterprise.valores.length === 0) {
                         $("#valores").addClass("border-danger-guay");
                         toastr.error('Falta ingresar los valores del grupo', '¡Espera!');
                     } else {
                         form_2 = form_2 + 1;
                     }
                     if (enterprise.valores.length === 0) {
                         $("#objetivos").addClass("border-danger-guay");
                         toastr.error('Falta ingresar los objetivos del grupo', '¡Espera!');
                     } else {
                         form_2 = form_2 + 1;
                     }
                     if (form_2 === 4) {
                         return answered = true;
                     }
                     break;*/
                /* case 3:
 
                     console.log(enterprise.rfc.length);
                     if (enterprise.rfc.length === 0 || enterprise.rfc.length === undefined) {
                         $("#rfc").addClass("border-danger-guay");
                         toastr.error('Falta ingresar el código postal', '¡Espera!');
 
                     } else {
                         form_3 = form_3 + 1;
                     }
                     if (enterprise.zipCode.length === 0 || enterprise.zipCode.length === undefined) {
                         $("#zipCode").addClass("border-danger-guay");
                         toastr.error('Falta ingresar el código postal', '¡Espera!');
                     } else {
                         form_3 = form_3 + 1;
                     }
                     if (enterprise.street.length === 0) {
                         $("#street").addClass("border-danger-guay");
                         toastr.error('Falta ingresar la calle', '¡Espera!');
                     } else {
                         form_3 = form_3 + 1;
                     }
                     if (enterprise.ext_num.length === 0) {
                         $("#ext_num").addClass("border-danger-guay");
                         toastr.error('Falta ingresar el número exterior', '¡Espera!');
                     } else {
                         form_3 = form_3 + 1;
                     }
                     if (form_3 === 4) {
                         return answered = true;
                     }
                     break;*/
                default:
                    if (answered === false) {
                        return answered = true;
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleOnClickNext = () => {
        try {
            let validation = validateForm();
            if (validation === true) {
                let nextStep = stepNum + 1;
                setStep(nextStep);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const saveRegister = () => {
    //     try {
    //         switch (stepNum) {
    //             case 1:
    //                 if (enterprise.comercialName.length === 0 ||
    //                     enterprise.shortName.length === 0 ||
    //                     enterprise.legalName.length === 0 ||
    //                     enterprise.description.length === 0) {
    //                     toastr.error("Todos los campos son obligatorios", "¡Espera!");
    //                     return false;
    //                 }
    //                 break;
    //            /* case 2:
    //                 if (enterprise.mision.length === 0 ||
    //                     enterprise.vision.length === 0 ||
    //                     enterprise.valores.length === 0 ||
    //                     enterprise.objetivos.length === 0) {
    //                     toastr.error("Todos los campos son obligatorios", "¡Espera!");
    //                     return false;
    //                 }
    //                 break;*/
    //             case 3:
    //                 if (enterprise.zipCode.length === 0 ||
    //                     enterprise.street.length === 0 ||
    //                     enterprise.numExt.length === 0) {
    //                     toastr.error("Todos los campos son obligatorios", "¡Espera!");
    //                     return false;
    //                 }
    //                 break;
    //              default:
    //                  break   
    //         }

    //         enterprise.complete = setComplete();

    //         let formData = new FormData();
    //         formData.append("file", files.file);
    //         formData.append("group", JSON.stringify(enterprise));
    //         setEnterpriseGroup(formData).then((res) => {
    //             if (res.success === 1) {
    //                 toastr.success("Se registró el grupo organizacional correctamente", "¡Exito!");
    //                 window.location = "/inicio-empresas";
    //             } else {
    //                 toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
    //             }
    //         })
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const setNewGroupEnterprise = () => {
        try {
            let validation = validateForm();
            if (validation === true) {
                enterprise.complete = setComplete();
                $("#saveBtn").addClass("disabled").text("Procesando");
                let formData = new FormData();
                formData.append("file", files.file);
                formData.append("group", JSON.stringify(enterprise));
                setEnterpriseGroup(formData).then((res) => {
                    if (res.success === 1) {
                        toastr.success("Se registró la organización correctamente", "¡Exito!");
                        window.location = "/inicio-empresas";

                    } else {
                        toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        $("#saveBtn").removeClass("disabled").text("Terminar");
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay">
                <Header />
                <Menu />
                <div className="content-wrapper bootomFooter">
                    <div className="content-header row">
                        <div className="col-md-6"></div>
                        <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        {enterprises.length > 0
                                            ?
                                            (
                                                <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                                                </li>
                                            )
                                            :
                                            (
                                                <li className="breadcrumb-item font-medium-3 pointer fake-link-guay" onClick={() => toastr.warning("Primero debes seleccionar una empresa de la lista.", "¡Ooops!")}>Administración</li>
                                            )
                                        }
                                        <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Organizaciones</a>
                                        </li>
                                        <li className="breadcrumb-item active font-medium-3">Alta Grupo Organizacional
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        <HelperApp />
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-10">
                                <h2 ><strong>Alta Grupo Organizacional</strong></h2>
                            </div>

                        </div>

                        <div className="row mt-2">
                            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-1 mb-40"></div>
                            <div className="col-sx-12 col-sm-6 col-md-12 col-lg-3 ml-4 mb-40">

                                <div className="card">
                                    <div className="">
                                        <div className="media-list list-group">
                                            <a href="/inicio-empresas/registra-grupo" className="list-group-item list-group-item-action media">
                                                <div className="media-left">
                                                    {
                                                        stepNum === 1 ?
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-07.png" alt="img-guay" />
                                                            :
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-10.png" alt="img-guay" />
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="list-group-item-heading mt-4p">Datos Generales</h5>
                                                </div>
                                            </a>
                                            <a href="/inicio-empresas/registra-grupo" className="list-group-item list-group-item-action media">
                                                <div className="media-left">
                                                    {
                                                        stepNum === 2 ?
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-08.png" alt="img-guay" />
                                                            :
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-11.png" alt="img-guay" />
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="list-group-item-heading mt-4p">Datos Específicos</h5>
                                                </div>
                                            </a>
                                            {/* <a href="/inicio-empresas/registra-grupo" className="list-group-item list-group-item-action media">
                                                <div className="media-left">
                                                    {
                                                        stepNum === 3 ?
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-09.png" alt="img-guay" />
                                                            :
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-12.png" alt="img-guay" />
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="list-group-item-heading">Datos de Facturación</h5>
                                                </div>
                                            </a> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <form autoComplete="off">
                                                {
                                                    stepNum === 1 ?
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <h4 className="form-section"><i className="ft-user"></i> Ingrese la siguiente información del Grupo</h4>
                                                            <div className="col-sx-12 col-sm-12 col-md-9 col-lg-9">
                                                                <div className="form-group mt-1">
                                                                    <label >Nombre Comercial:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="comercialName"
                                                                        name="comercialName"
                                                                        maxLength="100"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group ">
                                                                    <label >Nombre Corto:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="shortName"
                                                                        name="shortName"
                                                                        maxLength="100"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group ">
                                                                    <label >Nombre Legal:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="legalName"
                                                                        name="legalName"
                                                                        maxLength="100"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Descripción:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="description"
                                                                        name="description"
                                                                        maxLength="200"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>RFC</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="rfc"
                                                                        name="rfc"
                                                                        maxLength="13"
                                                                        minLength="12"
                                                                        onChange={getValue}
                                                                        placeholder="RFC (Opcional)"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 p-0">
                                                                <div className="form-group">
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                        <label>Logotiopo (opcional): </label>
                                                                    </div>
                                                                    <input type="file" id="file" className="hidden" onChange={onChangeHandler} />
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                        <img src="./../img/altaEmpresas/iconos_shopy_img-31.png" id="img-person" alt="persona" className="w-50 pointer pull-up" onClick={renderFilePers} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : stepNum === 2 ?
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h4 className="form-section"><i class="ft-settings"></i> Ingrese la siguiente información</h4>
                                                                <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">
                                                                    <div className="form-group mt-1">
                                                                        <label>Misión: </label>
                                                                        <textarea id="mision"
                                                                            rows="3"
                                                                            className="form-control"
                                                                            name="mision"
                                                                            minLength="4"
                                                                            onChange={getValue}
                                                                            add-count="1"
                                                                            data-max-length="500"
                                                                            element-name-character-count="character-count-mision"
                                                                        ></textarea>
                                                                        <p id="character-count-mision" className="small" align="right">0/500</p>
                                                                    </div>
                                                                    <div className="form-group mt-1">
                                                                        <label>Visión: </label>
                                                                        <textarea id="vision"
                                                                            rows="3"
                                                                            className="form-control"
                                                                            name="vision"
                                                                            minLength="4"
                                                                            onChange={getValue}
                                                                            add-count="1"
                                                                            data-max-length="500"
                                                                            element-name-character-count="character-count-vision"
                                                                        ></textarea>
                                                                        <p id="character-count-vision" className="small" align="right">0/500</p>
                                                                    </div>
                                                                    <div className="form-group mt-1">
                                                                        <label>Objetivos: </label>
                                                                        <textarea id="objetivos"
                                                                            rows="3"
                                                                            className="form-control"
                                                                            name="objetivos"
                                                                            minLength="4"
                                                                            onChange={getValue}
                                                                            add-count="1"
                                                                            data-max-length="500"
                                                                            element-name-character-count="character-count-objetivos"
                                                                        ></textarea>
                                                                        <p id="character-count-objetivos" className="small" align="right">0/500</p>
                                                                    </div>
                                                                    <div className="form-group mt-1">
                                                                        <label>Valores: </label>
                                                                        <textarea id="valores"
                                                                            rows="3"
                                                                            className="form-control"
                                                                            name="valores"
                                                                            minLength="4"
                                                                            onChange={getValue}
                                                                            add-count="1"
                                                                            data-max-length="500"
                                                                            element-name-character-count="character-count-valores"
                                                                        ></textarea>
                                                                        <p id="character-count-valores" className="small" align="right">0/500</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : stepNum === 3 ?
                                                                <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">
                                                                    <h4 className="form-section"><i class="ft-settings"></i> Ingrese la siguiente información</h4>
                                                                    <div className="row">
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                            {/* <div className="form-group ">
                                                                                <label>RFC</label>
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="text"
                                                                                    id="rfc"
                                                                                    name="rfc"
                                                                                    maxLength="13"
                                                                                    minLength="13"
                                                                                    onChange={getValue}
                                                                                    placeholder="RFC (Opcional)"
                                                                                ></input>
                                                                            </div> */}
                                                                            <div className="form-group">
                                                                                <label>Estado:</label>
                                                                                <select
                                                                                    className="form-control form-control-sm"
                                                                                    name="state"
                                                                                    id="state"
                                                                                    disabled>
                                                                                    <option value="0">--Seleccione--</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <label>Colonia:</label>
                                                                                <select
                                                                                    className="form-control form-control-sm"
                                                                                    name="neibg"
                                                                                    id="neibg"
                                                                                    disabled
                                                                                    onChange={getValue}
                                                                                >
                                                                                    <option value="0">--Seleccione--</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="form-group mb-1">
                                                                                <label>Núm. exterior:</label>
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="text"
                                                                                    id="ext_num"
                                                                                    name="ext_num"
                                                                                    maxLength="50"
                                                                                    minLength="2"
                                                                                    placeholder="Número Exterior"
                                                                                    onChange={getValue}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                            <div className="form-group ">
                                                                                <label>Código Postal: </label>
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="number"
                                                                                    id="zipCode"
                                                                                    name="zipCode"
                                                                                    minLength="4"
                                                                                    placeholder="Código Postal"
                                                                                    onChange={getValue}
                                                                                ></input>
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <label>Municipio/Alcaldía:</label>
                                                                                <select
                                                                                    className="form-control form-control-sm"
                                                                                    name="town"
                                                                                    id="town"
                                                                                    disabled>
                                                                                    <option value="0"> ---Selecione---</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label>Calle:</label>
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="text"
                                                                                    id="street"
                                                                                    name="street"
                                                                                    maxLength="150"
                                                                                    minLength="2"
                                                                                    placeholder="Calle"
                                                                                    onChange={getValue}
                                                                                ></input>
                                                                            </div>
                                                                            <div className="form-group mb-1">
                                                                                <label>Núm. Interior (opcional):</label>
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="text"
                                                                                    id="int_num"
                                                                                    name="int_num"
                                                                                    maxLength="50"
                                                                                    minLength="2"
                                                                                    placeholder="Número Interior"
                                                                                    onChange={getValue}
                                                                                ></input>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                : ""
                                                }
                                            </form>
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mx-auto mt-3">
                                                {
                                                    // stepNum < 3 ?
                                                    stepNum < 2 ?
                                                        <button className="btn btn-blue float-left font-medium-1" onClick={() => setNewGroupEnterprise()}>Guardar y salir</button>
                                                        : ""
                                                }
                                                {/* <button className="btn btn-green float-right font-medium-1" id="saveBtn" onClick={stepNum === 3 ? () => setNewGroupEnterprise() : () => handleOnClickNext()}>{stepNum === 3 ? 'Terminar' : 'Continuar'}</button> */}
                                                <button className="btn btn-green float-right font-medium-1" id="saveBtn" onClick={stepNum === 2 ? () => setNewGroupEnterprise() : () => handleOnClickNext()}>{stepNum === 2 ? 'Terminar' : 'Continuar'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegGroup;