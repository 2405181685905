import React from 'react';

const SurveyComplete = ({language='es'}) => {
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <div className="row font-medium-5 text-base-guay font-weight-bold">
                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center mx-auto">
                    <img src={language === 'es' ? "./../img/DocumentManagement/iconos_configuracion-30.png" : "./../img/DocumentManagement/Ingles_Complete.png"} className="img-responsive" alt="img-guay" />
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center text-center mt-5">
                    <span>{language === 'es' ? '¡Gracias por tu participación!' : 'Thank you for your participation!'}</span>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center text-center mt-1">
                    <span>{language === 'es' ? 'Te invitamos a conocer más sobre estos temas en nuestra guakipedia.' : 'We invite you to learn more about these topics in our Guakipedia.'}</span>
                </div>
            </div>
        </div>
    )
}

export default SurveyComplete;
