import React, { useEffect } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { handleScrollStart } from '../../../helpers/helpers';

export const CompleteGuides = ({ completeGuides, setProtocolState, previewFilePDF }) => {

    useEffect(() => {
        handleScrollStart("div-Acciones");
    }, [])
    

    const urlFormatter = (cell, row) => {
        return <a onClick={() => previewFilePDF(cell)} rel="noreferrer"><i className='ft-download text-base-guay' /></a>;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <i className="btn btn-guay ft-arrow-left mr-5 font-medium-2 py-0" onClick={() => setProtocolState(0)} />
                    <br />
                    <h1 className="font-medium-4">Guías de Acciónes</h1>
                    <hr />
                </div>
            </div>
            <div className="col-12 mt-1">
                <BootstrapTable data={completeGuides}  scrollTop={'top'} search={true} searchPlaceholder={"Documento..."}>
                    <TableHeaderColumn dataField='idCompleteGuide' isKey hidden>idCompleteGuide</TableHeaderColumn>
                    <TableHeaderColumn dataField='url' dataFormat={urlFormatter} width="7%"><i className='ft-download' /></TableHeaderColumn>
                    <TableHeaderColumn dataField='title'>Documento</TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    );
}
