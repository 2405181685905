import React, { useState, useEffect } from 'react';
import { getJwt } from '../../../lib/auth';
import { getadminPeriodList, getPeriodsByLaunch } from '../Models/Launch_model';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const TablePulses = ({ launchDate, validity, idGroup }) => {

    //get data from cookies
    const idEnterprise = getJwt("enterprise");

    // let finalDate = "dd-mm-yyyy";
    const [pulses, setPulses] = useState([]);
    const [finalDate, setFinalDate] = useState("dd-mm-yyyy");

    useEffect(() => {
        if (idGroup === 0) {
            getadminPeriodList(launchDate, validity).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setPulses(response.periodLaunchList);
                            setFinalDate(response.periodLaunchList[response.periodLaunchList.length - 1].initialDate);
                            // finalDate = response.periodLaunchList[response.periodLaunchList.length - 1].finalDate;
                            break;
                        default:
                            toastr.error("Ocurrió un error, vuelva a intentarlo.", "Error");
                            break;
                    }
                }
            });
        } else {
            getPeriodsByLaunch(idEnterprise, idGroup).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setPulses(response.listPeriods);
                            setFinalDate(response.listPeriods[response.listPeriods.length - 1].initialDate);
                            // finalDate = response.listPeriods[response.listPeriods.length - 1].finalDate;
                            break;
                        default:
                            toastr.error("Ocurrió un error, vuelva a intentarlo.", "Error");
                            break;
                    }
                }
            })
        }
        return () => {
        }
    }, [launchDate, idEnterprise, validity, idGroup])

    const NumToSp = {
        "01": "Enero",
        "02": "Febrero",
        "03": "Marzo",
        "04": "Abril",
        "05": "Mayo",
        "06": "Junio",
        "07": "Julio",
        "08": "Agosto",
        "09": "Septiembre",
        "10": "Octubre",
        "11": "Noviembre",
        "12": "Diciembre"
    }

    function getDate(input) {
        const date = input.split("-");
        const day = date[2];
        const month = NumToSp[date[1]];
        const year = date[0];
        return `${day} de ${month} de ${year}`;
    }

    // //function to convert type date to string format required to send to db
    // function convert(str) {
    //     var date = new Date(str),
    //         mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //         day = ("0" + date.getDate()).slice(-2);
    //     return [date.getFullYear(), mnth, day].join("-");
    // }

    const statusFormat = (cell, row, data1, index) => {
        let status = "Pendiente";
        switch (cell) {
            case 1:
                status = "Programado";
                break;
            case 2:
                status = "Iniciado";
                break;
            case 3:
                status = "Terminado";
                break;
            case 4:
                status = "Pausado";
                break;
            default:
                status = "Por definir";
                break;
        }
        return (
            <div>
                {status}
            </div>
        )
    }

    const options = {
        noDataText: 'No se encontraron registros.',
    }

    return (
        <div className="row">
            <div className="col-12">
                <p className="font-medium-2 text-center text-red font-weight-bold mb-4">El último pulso a enviarse será el {getDate(finalDate)} debido a que el servicio concluye el {getDate(validity)}</p>
            </div>
            <div className="col-8 mx-auto table-responsive">
                <p className="font-medium-2 text-center text-base mb-4">Los cuestionarios se mandarán en el siguiente orden, recuerda que puedes pausarlos o cancelarlos en cualquier momento:</p>
            </div>
            <div className="col-8 mx-auto table-scroll-1">
                <BootstrapTable
                    data={pulses}
                    striped
                    hover
                    condensed
                    tableStyle={{ border: 'none', padding: '0px', fontSize: '1.2rem' }}
                    trClassName='tr-data-table table-checkbox'
                    options={options}
                >
                    <TableHeaderColumn isKey dataField="finalDate" hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="description" dataAlign="left" width="33%" tdStyle={{ fontSize: '1.2rem' }}>Pulso</TableHeaderColumn>
                    <TableHeaderColumn dataField="initialDate" dataAlign="center" width="33%" tdStyle={{ fontSize: '1.2rem' }}>Lanzamiento</TableHeaderColumn>
                    <TableHeaderColumn dataField="status" dataAlign="center" width="33%" tdStyle={{ fontSize: '1.2rem' }} dataFormat={statusFormat}>Estatus</TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    )
}

export default TablePulses
