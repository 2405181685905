import React, { useState, useEffect } from 'react';
import { getActiveProductsByEnterprise, getHasTypeLaunch, getInfoLaunch4Product } from '../Models/Enterprise_model';
import { getLaunches } from '../Models/Catalog_model';
import { saveNomLaunch, setCancelLaunch } from '../Models/Launch_model';
import DescParagraphComponent from './DescParagraphComponent';
import FechaLanzamiento from './FechaLanzamiento';
import FechaLanzamiento2 from './FechaLanzamiento2';
import TablaLanzamientos from './TablaLanzamientos';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import $ from 'jquery';
import { connect } from 'react-redux';
import { modifyLaunch } from '../../../redux/launchDuck';

const LanzamientoNormativo = ({
    setloading,
    enterpriseId,
    userId,
    productData,
    tipoLanzamiento,
    modifyLaunch,
    disableActions,
    customSurveys,
}) => {

    const [lastLaunchDate, setlastLaunchDate] = useState(null);
    const [lastExecutedLaunchDate, setLastExecutedLaunchDate] = useState('');
    const [catalogLaunches, setcatalogLaunches] = useState([]);
    const [idTypeLaunch, setidTypeLaunch] = useState(0); //Tipo de lanzamiento: independiente / conjunto
    const [descriptionLaunch, setdescriptionLaunch] = useState("");
    const [flagLaunch, setflagLaunch] = useState(0);
    const [launchDate, setlaunchDate] = useState(null);
    const [idReminder, setidReminder] = useState(undefined);
    const [centrosTrabajo, setCentrosTrabajo] = useState([]);
    const [allowEdit, setallowEdit] = useState(true);
    const [cantEdit, setcantEdit] = useState(false);
    const [conjLaunchOpt, setConjLaunchOpt] = useState(false);
    const [byCT, setByCT] = useState(false);
    const [byCTOT, setByCTOT] = useState(true);
    // eslint-disable-next-line
    const [isUpdate, setIsUpdate] = useState(false);
    const history = useHistory();
    const [hasJoinLaunches, setHasJoinLaunches] = useState(undefined);
    const [hasOtherLaunches, setHasOtherLaunches] = useState(false);
    const [disableOption, setDisableOption] = useState(true);
    const [launchData, setLaunchData] = useState({
        idGroup: null,
        eliminate: null,
    });

    const [surveyChecked, setSurveyChecked] = useState(0);
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        sessionStorage.removeItem("descriptionLaunch");
        sessionStorage.removeItem("numCollabSelected");
        setSurveyChecked(0)
        getHasTypeLaunch(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory).then((response) => {
            if (response) {
                if (response.code === 1 || response.code === 2) {
                    getInfoLaunch4Product(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory, response.code, tipoLanzamiento).then((response) => {
                        if (response) {
                            switch (response.code) {
                                case 1:
                                    //tiene lanzamientos NOM x toda la empresa
                                    setlastLaunchDate(response.infoLastLaunch[0].launchDate);
                                    setLastExecutedLaunchDate(response.lastExecutionDate);
                                    setidTypeLaunch(response.infoLastLaunch[0].typeLaunch);
                                    setdescriptionLaunch(response.infoLastLaunch[0].description);
                                    setflagLaunch(response.infoLastLaunch[0].flagLaunch);
                                    setlaunchDate(new Date(convert2(response.infoLastLaunch[0].launchDate)));
                                    setidReminder(response.infoLastLaunch[0].idReminderLaunch);

                                    if (response.listIdQuizEnterprise !== undefined) {
                                        if (response.listIdQuizEnterprise.length > 0) {
                                            if(response.listIdQuizEnterprise[0] !== null){
                                                setSelected(response.listIdQuizEnterprise[0].idQuizEnterprise)
                                                setSurveyChecked(1)
                                            }
                                        }
                                    }
                                    if (response.infoLastLaunch[0].edit === 1) {
                                        setallowEdit(true);
                                        setIsUpdate(true);
                                        setDisableOption(true);
                                    } else {
                                        setcantEdit(true);
                                    }
                                    setByCT(false);
                                    setByCTOT(false);
                                    setLaunchData({
                                        idGroup: response.infoLastLaunch[0].idGroup,
                                        eliminate: response.infoLastLaunch[0].eliminate,
                                    });
                                    break;
                                case 2:
                                    //tiene lanzamientos NOM x centros de trabajo
                                    setlastLaunchDate(response.infoLastLaunchProdBranch[0].launchDate);
                                    setLastExecutedLaunchDate(response.lastExecutionDate);
                                    setidTypeLaunch(response.infoLastLaunchProdBranch[0].typeLaunch);
                                    setdescriptionLaunch(response.infoLastLaunchProdBranch[0].description);
                                    setflagLaunch(response.infoLastLaunchProdBranch[0].flagLaunch);
                                    if (response.listIdQuizEnterprise !== undefined) {
                                        if(response.listIdQuizEnterprise[0] !== null){
                                            if (response.listIdQuizEnterprise.length > 0) {
                                                setSelected(response.listIdQuizEnterprise[0].idQuizEnterprise)
                                                setSurveyChecked(1)
                                            }
                                        }
                                    }
                                    setLaunchData({
                                        idGroup: response.infoLastLaunchProdBranch[0].idGroup,
                                        eliminate: null,
                                    });
                                    if (response.infoLastLaunchProdBranch[0].edit === 1) {
                                        setallowEdit(true);
                                        setIsUpdate(true);
                                        setDisableOption(true);
                                    } else {
                                        setcantEdit(true);
                                    }
                                    break;
                                case 3:
                                    getInfoLaunch4Product(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory, 1, 2).then((response) => {
                                        if (response) {
                                            if (response.code === 1) {
                                                switch (response.infoLastLaunch.length) {
                                                    case 0:
                                                        // no tiene lanzamientos programados / no tiene lanzamientos complementarios
                                                        setlastLaunchDate(null);
                                                        setLastExecutedLaunchDate(response.lastExecutionDate);
                                                        setidTypeLaunch(0);
                                                        setdescriptionLaunch("");
                                                        setflagLaunch(0);
                                                        setlaunchDate(null);
                                                        setidReminder(undefined);
                                                        setallowEdit(false);
                                                        setDisableOption(false);
                                                        setByCT(true);
                                                        setByCTOT(true);
                                                        setLaunchData({
                                                            idGroup: null,
                                                            eliminate: 0,
                                                        });
                                                        break;
                                                    default:
                                                        // no tiene lanzamientos programados / tiene un lanzamiento complementario
                                                        setlastLaunchDate(null);
                                                        setLastExecutedLaunchDate(response.lastExecutionDate);
                                                        setidTypeLaunch(0);
                                                        setdescriptionLaunch("");
                                                        setflagLaunch(1);
                                                        setByCT(true);
                                                        setByCTOT(false);
                                                        setlaunchDate(null);
                                                        setidReminder(undefined);
                                                        setallowEdit(false);
                                                        setDisableOption(false);
                                                        setLaunchData({
                                                            idGroup: null,
                                                            eliminate: 0,
                                                        });
                                                        break;
                                                }
                                            } else {
                                                if (response.code === 4) {
                                                    setlastLaunchDate(null);
                                                    setLastExecutedLaunchDate(response.lastExecutionDate);
                                                    setidTypeLaunch(0);
                                                    setdescriptionLaunch("");
                                                    setflagLaunch(0);
                                                    setlaunchDate(null);
                                                    setidReminder(undefined);
                                                    setallowEdit(false);
                                                    setDisableOption(false);
                                                    setcantEdit(true);
                                                    setByCT(true);
                                                    setByCTOT(true);
                                                    setLaunchData({
                                                        idGroup: null,
                                                        eliminate: 0,
                                                    });
                                                }
                                            }
                                        }
                                    })
                                    break;
                                default:
                                    toastr.info("No puedes programar un lanzamiento.", '¡Ooops!');
                                    break;
                            }
                        }
                    });
                }
            }
        });
        getLaunches().then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setcatalogLaunches(response.listTypeLaunch);
                        break;
                    default:
                        toastr.error("Ocurrió un error al obtener el catálogo de lanzamientos");
                        break;
                }
            }
        });
        getActiveProductsByEnterprise(enterpriseId).then((response) => {
            switch (response?.code) {
                case 1:
                    // eslint-disable-next-line
                    const activeProducts = response.listProducts.filter((product) => {
                        if (product.acquired === 1 && (product.idSubcategory >= 1)) {
                            return product;
                        }
                    });
                    if (activeProducts.length === 2) {
                        setConjLaunchOpt(true);
                    }
                    break;
                case 200:
                    // eslint-disable-next-line
                    const activeProducts2 = response.listProducts.filter((product) => {
                        if (product.acquired === 1) {
                            return product;
                        }
                    });
                    if (activeProducts2.length === 2) {
                        setConjLaunchOpt(true);
                    }
                    break;
                default:
                    toastr.error("Ocurrió un error recargue la página por favor");
                    break;
            }
        })

        //validar el tipo de lanzamiento conjunto / independiente
        getHasTypeLaunch(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory).then((response) => {
            switch (response?.code) {
                case 1: // no tiene lanzamientos en conjunto
                    setHasJoinLaunches(false);
                    getInfoLaunch4Product(enterpriseId, productData.idProduct, productData.idCategory, 1, 1, 1).then((response) => {//checamos si tiene lanzamientos de la fase 1
                        switch (response?.code) {
                            case 1://tiene un lanzamiento por toda la empresa
                                setHasOtherLaunches(true);
                                break;
                            case 2://tiene un lanzamiento por centros de trabajo
                                setHasOtherLaunches(true);
                                break;

                            default:
                                break;
                        }
                    });
                    getInfoLaunch4Product(enterpriseId, productData.idProduct, productData.idCategory, 2, 1, 1).then((response) => {//checamos si tiene lanzamientos de la fase 2
                        switch (response?.code) {
                            case 1://tiene un lanzamiento por toda la empresa
                                setHasOtherLaunches(true);
                                break;
                            case 2://tiene un lanzamiento por centros de trabajo
                                setHasOtherLaunches(true);
                                break;

                            default:
                                break;
                        }
                    });
                    break;
                case 2: // si tiene lanzamientos en conjunto
                    setHasJoinLaunches(true);
                    break;
                default:
                    setHasJoinLaunches(undefined);
                    break;
            }
        });

        return () => {
            setallowEdit(true);
            setcantEdit(false);
            setdescriptionLaunch("");
        }
    }, [productData, enterpriseId, tipoLanzamiento])

    function orderDate(str) {
        const date = str.split("-");
        return `${date[2]}-${date[1]}-${date[0]}`;
    }

    function convert2(str) {
        var parts = str.match(/(\d+)/g);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleSelectLauchChange = (e) => {
        if (hasJoinLaunches === true) { // si tiene lanzamientos conjunto, no puede seleccionar lanzamientos independientes
            toastr.info("Ya cuenta con un lanzamiento conjunto", "No puede cambiar el tipo lanzamiento");
        } else if (hasJoinLaunches === false) { //no tiene lanzamientos en conjunto
            if (hasOtherLaunches === true) { //cuenta con otros lanzamientos
                if (e.target.value === "2") {
                    toastr.info("Por el momento solo puede configurar lanzamientos independientes");
                } else if (e.target.value === "1") {
                    setidTypeLaunch(parseInt(e.target.value));
                } else {
                    setidTypeLaunch(parseInt(e.target.value));
                }
            } else {
                setidTypeLaunch(parseInt(e.target.value));
            }
        } else { // error al obtener respuesta
            toastr.error("No se puede cambiar el tipo de lanzamiento, recargue la pagina y vuelva intentarlo");
        }
    }

    const handleInputLaunchNameChange = (inputValue) => {
        setdescriptionLaunch(inputValue);
    }

    const handleClickNo = () => {
        setflagLaunch(0);
    }
    const handleClickYes = () => {
        setflagLaunch(1);
    }

    const handleClickCancelLaunch = function () {
        Swal.fire({
            title: '<p class="text-white mb-2">¡Atención</p>',
            text: "¿Estás seguro de eliminar el lanzamiento? ",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Si, eliminar",
            reverseButtons: true,
            dangerMode: true
        }).then((response) => {
            if (response.isConfirmed) {
                setCancelLaunch(enterpriseId, launchData.idGroup).then(response => {
                    switch (response?.code) {
                        case 1:
                            toastr.success("Se ha eliminado el lanzamiento correctamente", "Éxito");
                            window.location.reload();
                            break;
                        case 1001:
                            toastr.info("Su organización se encuentra desactivada", "Error");
                            break;
                        case 1002:
                            toastr.error("No se ha podido eliminar el lanzamiento", "Error");
                            break;

                        default:
                            toastr.error("Ocurrió un error inesperado, verifique su conexión a internet.", "Error");
                            break;
                    }
                });
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }

    const handleClickEdit = () => {
        sessionStorage.setItem('isEdition', 1);
        setallowEdit(false);
        setDisableOption(true);
    }

    const handleClickSave = () => {
        if (descriptionLaunch !== "" && idTypeLaunch !== 0) {
            if (flagLaunch === 0 && launchDate !== null) { //lanzamiento por empresa
                setloading(true);
                const dateString = JSON.stringify(convert(launchDate));
                const userIdString = JSON.stringify(userId);
                const descString = JSON.stringify(descriptionLaunch);
                for (let index = 0; index < centrosTrabajo.length; index++) {
                    centrosTrabajo[index].launchDate = convert(centrosTrabajo[index].launchDate);
                }
                let idRemLaunch = 0;
                if (idReminder !== undefined) {
                    idRemLaunch = idReminder;
                }
                const idQuizEnterprise = surveyChecked === 1 ? selected : surveyChecked
                saveNomLaunch(
                    enterpriseId,
                    productData.idProduct,
                    productData.idCategory,
                    productData.idSubcategory,
                    tipoLanzamiento,
                    descString,
                    idTypeLaunch,
                    idRemLaunch,
                    dateString,
                    userIdString,
                    flagLaunch,
                    centrosTrabajo,
                    idQuizEnterprise,
                ).then((response) => {
                    if (response) {
                        setloading(false);
                        switch (response.code) {
                            case 1:
                                toastr.success("Ahora revisa la comunicación de las fichas de sensibilización", "Cambios guardados exitosamente");
                                sessionStorage.setItem("idProduct", productData.idProduct);
                                sessionStorage.setItem("idCategory", productData.idCategory);
                                sessionStorage.setItem("idSubcategory", productData.idSubcategory);
                                sessionStorage.setItem("nameCategory", productData.nameCategory);
                                sessionStorage.setItem("nameSubcategory", productData.nameSubcategory);
                                sessionStorage.setItem("idTypeLaunch", idTypeLaunch); //independiente | conjunto
                                sessionStorage.setItem("descriptionLaunch", descriptionLaunch); //nombre ejercicio
                                sessionStorage.setItem("flagLaunch", flagLaunch); // empresa | centros de trabajo
                                sessionStorage.setItem("launchDate", launchDate); // fecha lanzamiento
                                sessionStorage.setItem("idQuizEnterprise", idQuizEnterprise); // encuesta personalizada
                                if (sessionStorage.getItem("isEdition") !== "1") {
                                    sessionStorage.setItem("inLaunch", 1);
                                    sessionStorage.removeItem("isEdition");
                                    sessionStorage.setItem("idGroup", response.idGroup);
                                    history.push("/comunicacion");
                                }
                                break;
                            case 2:
                                toastr.success("Ahora revisa la comunicación de las fichas de sensibilización", "Cambios guardados exitosamente");
                                sessionStorage.setItem("idProduct", productData.idProduct);
                                sessionStorage.setItem("idCategory", productData.idCategory);
                                sessionStorage.setItem("idSubcategory", productData.idSubcategory);
                                sessionStorage.setItem("nameCategory", productData.nameCategory);
                                sessionStorage.setItem("nameSubcategory", productData.nameSubcategory);
                                sessionStorage.setItem("idTypeLaunch", idTypeLaunch); //independiente | conjunto
                                sessionStorage.setItem("descriptionLaunch", descriptionLaunch); //nombre ejercicio
                                sessionStorage.setItem("flagLaunch", flagLaunch); // empresa | centros de trabajo
                                sessionStorage.setItem("launchDate", launchDate); // fecha lanzamiento
                                sessionStorage.setItem("idQuizEnterprise", idQuizEnterprise); // encuesta personalizada
                                if (centrosTrabajo.find(centro => centro.idGroup === 0)) {
                                    sessionStorage.setItem("inLaunch", 1);
                                    sessionStorage.removeItem("isEdition");
                                    sessionStorage.setItem("idGroup", response.listGroups[0].idGroup);
                                    let arrayCentros = centrosTrabajo.map((centro) => {
                                        let obj = response.listGroups.find(group => group.idBranch === centro.idBranch);
                                        if (obj) {
                                            return {
                                                ...centro,
                                                idGroup: obj.idGroup
                                            }
                                        } else {
                                            return {
                                                ...centro
                                            }
                                        }
                                    });
                                    modifyLaunch(tipoLanzamiento, idTypeLaunch, descriptionLaunch, flagLaunch, launchDate, arrayCentros);
                                    history.push("/comunicacion");
                                }
                                break;
                            case 1001:
                                toastr.error("Error no se encontró la organización asociada", "Error");
                                break;
                            case 1002:
                                toastr.error("No hay centros de trabajo activos", "Error");
                                break;
                            case 1003:
                                toastr.error("La organización ya cuenta con un ejercicio en progreso", "Error");
                                break;
                            case 1004:
                                toastr.error("La organización ya cuenta con un ejercicio en progreso por centros de trabajo", "Error");
                                break;
                            case 1005:
                                toastr.error("La organización ya cuenta con un ejercicio activo para toda la empresa.", "Error");
                                break;
                            default:
                                toastr.error("Ocurrió un error desconocido, verifique su conexión a internet.", "Error");
                                break;
                        }
                    }
                });
            } else if (flagLaunch === 1 && centrosTrabajo.length !== 0) { //lanzamiento por centro de trabajo
                setloading(true);
                const dateString = JSON.stringify(convert(launchDate));
                const userIdString = JSON.stringify(userId);
                const descString = JSON.stringify(descriptionLaunch);
                for (let index = 0; index < centrosTrabajo.length; index++) {
                    centrosTrabajo[index].launchDate = convert(centrosTrabajo[index].launchDate);
                }
                let idRemLaunch = 0;
                if (idReminder !== undefined) {
                    idRemLaunch = idReminder;
                }
                const idQuizEnterprise = surveyChecked === 1 ? selected : surveyChecked
                saveNomLaunch(
                    enterpriseId,
                    productData.idProduct,
                    productData.idCategory,
                    productData.idSubcategory,
                    tipoLanzamiento,
                    descString,
                    idTypeLaunch,
                    idRemLaunch,
                    dateString,
                    userIdString,
                    flagLaunch,
                    centrosTrabajo,
                    idQuizEnterprise,
                ).then((response) => {
                    if (response) {
                        setloading(false);
                        switch (response.code) {
                            case 1:
                                toastr.success("Ahora revisa la comunicación de las fichas de sensibilización", "Cambios guardados exitosamente");
                                sessionStorage.setItem("idProduct", productData.idProduct);
                                sessionStorage.setItem("idCategory", productData.idCategory);
                                sessionStorage.setItem("idSubcategory", productData.idSubcategory);
                                sessionStorage.setItem("nameCategory", productData.nameCategory);
                                sessionStorage.setItem("nameSubcategory", productData.nameSubcategory);
                                sessionStorage.setItem("idTypeLaunch", idTypeLaunch); //independiente | conjunto
                                sessionStorage.setItem("descriptionLaunch", descriptionLaunch); //nombre ejercicio
                                sessionStorage.setItem("flagLaunch", flagLaunch); // empresa | centros de trabajo
                                sessionStorage.setItem("launchDate", launchDate); // fecha lanzamiento
                                sessionStorage.setItem("idQuizEnterprise", idQuizEnterprise); // encuesta personalizada
                                if (sessionStorage.getItem("isEdition") !== "1") {
                                    sessionStorage.setItem("inLaunch", 1);
                                    sessionStorage.removeItem("isEdition");
                                    sessionStorage.setItem("idGroup", response.idGroup);
                                    history.push("/comunicacion");
                                }
                                break;
                            case 2:
                                toastr.success("Ahora revisa la comunicación de las fichas de sensibilización", "Cambios guardados exitosamente");
                                sessionStorage.setItem("idProduct", productData.idProduct);
                                sessionStorage.setItem("idCategory", productData.idCategory);
                                sessionStorage.setItem("idSubcategory", productData.idSubcategory);
                                sessionStorage.setItem("nameCategory", productData.nameCategory);
                                sessionStorage.setItem("nameSubcategory", productData.nameSubcategory);
                                sessionStorage.setItem("idTypeLaunch", idTypeLaunch); //independiente | conjunto
                                sessionStorage.setItem("descriptionLaunch", descriptionLaunch); //nombre ejercicio
                                sessionStorage.setItem("flagLaunch", flagLaunch); // empresa | centros de trabajo
                                sessionStorage.setItem("idQuizEnterprise", idQuizEnterprise); // encuesta personalizada
                                if (centrosTrabajo.find(centro => centro.idGroup === 0)) {
                                    sessionStorage.setItem("inLaunch", 1);
                                    sessionStorage.removeItem("isEdition");
                                    sessionStorage.setItem("idGroup", response.listGroups[0].idGroup);
                                    let arrayCentros = centrosTrabajo.map((centro) => {
                                        let obj = response.listGroups.find(group => group.idBranch === centro.idBranch);
                                        if (obj) {
                                            return {
                                                ...centro,
                                                idGroup: obj.idGroup
                                            }
                                        } else {
                                            return {
                                                ...centro
                                            }
                                        }
                                    });
                                    modifyLaunch(tipoLanzamiento, idTypeLaunch, descriptionLaunch, flagLaunch, launchDate, arrayCentros);
                                    history.push("/comunicacion");
                                }
                                break;
                            case 1001:
                                toastr.error("Error no se encontró la organización asociada", "Error");
                                break;
                            case 1002:
                                toastr.error("No hay centros de trabajo activos", "Error");
                                break;
                            case 1003:
                                toastr.error("La organización ya cuenta con un ejercicio en progreso", "Error");
                                break;
                            case 1004:
                                toastr.error("La organización ya cuenta con un ejercicio en progreso por centros de trabajo", "Error");
                                break;
                            case 1005:
                                toastr.error("La organización ya cuenta con un ejercicio activo para toda la empresa.", "Error");
                                break;
                            default:
                                toastr.error("Ocurrió un error desconocido, verifique su conexión a internet.", "Error");
                                break;
                        }
                    }
                });
            } else {
                toastr.warning("Debes de llenar toda la información", "Importante");
            }
        } else {
            toastr.warning("Debes de llenar toda la información", "Importante");
        }
    }

    const GreenRadio = withStyles({
        root: {
            color: disableOption ? ('#d8d8d9') : ('#15253c'),
            '&$checked': {
                color: disableOption ? ('#d8d8d9') : ('#9cb537'),
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const GreenRadio2 = withStyles({
        root: {
            color: disableOption ? (allowEdit ? '#d8d8d9' : '#15253c') : ('#15253c'),
            '&$checked': {
                color: disableOption ? (allowEdit ? '#d8d8d9' : '#9cb537') : ('#9cb537'),
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-11 d-flex justify-content-between mb-1">
                <span className="text-base-guay font-medium-2 text-right">Último lanzamiento ejecutado: {lastExecutedLaunchDate === '' ? "- --- --" : orderDate(lastExecutedLaunchDate)}</span>
                <span className="text-base-guay font-medium-2 m-0 text-right">Último lanzamiento configurado: {lastLaunchDate ? orderDate(lastLaunchDate) : "- --- --"}</span>
            </div>
            <div className="col-12 col-sm-11">
                <DescParagraphComponent
                    enterpriseId={enterpriseId}
                    disableActions={disableActions}
                />
            </div>
            <div className='col-11 mb-2'>
                <div className="row">
                    <div className="col-12 d-flex align-items-center font-medium-2 text-base">
                        <span>¿Deseas agregar una encuesta personalizada?</span>
                        <label className="align-items-center ml-2 my-0">
                            Si
                        </label>
                        <GreenRadio2
                            checked={surveyChecked === 1 ? true : false}
                            onChange={() => {
                                if (customSurveys.length > 0) {
                                    setSurveyChecked(1)
                                } else {
                                    toastr.info("No tienes encuestas personalizadas. Primero crea una en la sección Encuesta.", "¡Ooops!")
                                }
                            }}
                            value="a"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            size="small"
                            disabled={disableActions ? disableActions : allowEdit}
                        />
                        <label className="align-items-end ml-2 my-0">
                            No
                        </label>
                        <GreenRadio2
                            checked={surveyChecked === 0 ? true : false}
                            onChange={() => setSurveyChecked(0)}
                            value="a"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            size="small"
                            disabled={disableActions ? disableActions : allowEdit}
                        />
                    </div>
                </div>
            </div>
            {
                surveyChecked === 1 && (
                    <div className="col-11 mb-2">
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6 d-flex align-items-center'>
                                <span className="font-medium-2 text-base mr-1">Nombre de la encuesta</span>
                                <select
                                    className="form-control col-6 mr-1"
                                    value={selected}
                                    onChange={e => {
                                        setSelected(e.target.value)
                                    }}
                                    disabled={disableActions ? disableActions : allowEdit}
                                >
                                    <option value={0}>- Selecciona -</option>
                                    {
                                        customSurveys.map((survey) => (
                                            <option
                                                key={survey.value}
                                                value={survey.value}
                                            >{survey.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="col-11 mb-2">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <p className="font-medium-2 text-base mr-1">Tipo de Lanzamiento</p>
                        <select
                            className="form-control col-4 mr-1"
                            value={idTypeLaunch}
                            onChange={handleSelectLauchChange}
                            disabled={disableActions ? disableActions : disableOption}
                        >
                            <option value={0}>- Selecciona -</option>
                            {
                                // eslint-disable-next-line
                                catalogLaunches.map((launch) => {
                                    if (conjLaunchOpt) {
                                        return (
                                            <option
                                                key={launch.idTypeLaunch}
                                                value={launch.idTypeLaunch}
                                            >{launch.description}</option>
                                        )
                                    } else {
                                        if (launch.idTypeLaunch !== 2) {
                                            return (
                                                <option
                                                    key={launch.idTypeLaunch}
                                                    value={launch.idTypeLaunch}
                                                >{launch.description}</option>
                                            )
                                        }
                                    }
                                })
                            }
                        </select>
                        <Popup trigger={<i className="ft-help-circle icon-size icon-guay rounded-circle" />} position="top right">
                            {
                                idTypeLaunch === 1 ? <div className="p-1 text-center">
                                    Se lanzará la encuesta de <span className="font-weight-bold">{productData.nameSubcategory}</span> que corresponde únicamente a la <span className="font-weight-bold">{productData.idSubcategory === 1 ? "Fase 1" : productData.idSubcategory === 2 ? "Fase 2" : "Fase"}</span> del <span className="font-weight-bold">{productData.nameCategory}</span>, para cumplir con la norma deberás programar el siguiente lanzamiento en otro momento
                                </div> : idTypeLaunch === 2 ? <div className="p-1 text-center">
                                    Se lanzarán simultáneamente los cuestionarios de la <span className="font-weight-bold">Fase 1</span> y la <span className="font-weight-bold">Fase 2</span> de la <span className="font-weight-bold">Norma – 035</span>, es decir <span className="font-weight-bold">Estado Emocional</span> y <span className="font-weight-bold">Entorno Laboral</span>, lo que se configure en esta sección será igual en las dos fases.
                                </div> : <div className="p-1 text-center">Debes seleccionar un tipo de lanzamiento</div>
                            }
                        </Popup>
                    </div>
                </div>
            </div>
            <div className="col-11 mb-2">
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <p className="font-medium-2 text-base mr-1">Nombre del Ejercicio</p>
                        <input
                            className="form-control col-4 mr-1"
                            maxLength={100}
                            onChange={(e) => handleInputLaunchNameChange(e.target.value)}
                            value={descriptionLaunch}
                            disabled={disableActions ? disableActions : allowEdit}
                            placeholder="Ej. NOM 035 2021"
                        />
                        <Popup trigger={<i className="ft-help-circle icon-size icon-guay rounded-circle" />} position="top right">
                            <div className="p-1 text-center">
                                Nombra tu ejercicio para identificarlo posteriormente
                            </div>
                        </Popup>
                    </div>
                </div>
            </div>
            <div className="col-11 mb-2">
                <div className="row">
                    <div className="col-12 d-flex align-items-center font-medium-2 text-base">
                        <span>¿Quieres lanzar por centro de trabajo?</span>
                        {byCT === true ?
                            <>
                                <label className="align-items-center ml-2 my-0">
                                    Si
                                </label>
                                <GreenRadio
                                    checked={flagLaunch === 1 ? true : false}
                                    onChange={handleClickYes}
                                    value="a"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'C' }}
                                    size="small"
                                    disabled={disableActions ? disableActions : false}
                                />
                            </>
                            :
                            <>
                                <label className="align-items-center ml-2 my-0">
                                    Si
                                </label>
                                <GreenRadio
                                    checked={flagLaunch === 1 ? true : false}
                                    onChange={handleClickYes}
                                    value="a"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'C' }}
                                    size="small"
                                    disabled={disableActions ? disableActions : true}
                                />
                            </>
                        }
                        {
                            byCTOT === false ?
                                <>
                                    <label className="align-items-end ml-2 my-0">
                                        No
                                    </label>
                                    <GreenRadio
                                        checked={flagLaunch === 0 ? true : false}
                                        onChange={handleClickNo}
                                        value="a"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'C' }}
                                        size="small"
                                        disabled={disableActions ? disableActions : true}
                                    />
                                </>
                                :
                                <>
                                    <label className="align-items-end ml-2 my-0">
                                        No
                                    </label>
                                    <GreenRadio
                                        checked={flagLaunch === 0 ? true : false}
                                        onChange={handleClickNo}
                                        value="a"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'C' }}
                                        size="small"
                                        disabled={disableActions ? disableActions : allowEdit}
                                    />
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className="col-11 mb-2">
                {flagLaunch === 1 ?
                    allowEdit === true ?
                        <FechaLanzamiento2
                            enterpriseId={enterpriseId}
                            productData={productData}
                            centrosTrabajo={centrosTrabajo}
                            setCentrosTrabajo={setCentrosTrabajo}
                            flgLaunch={idTypeLaunch}
                        />
                        :
                        <TablaLanzamientos
                            enterpriseId={enterpriseId}
                            productData={productData}
                            centrosTrabajo={centrosTrabajo}
                            setCentrosTrabajo={setCentrosTrabajo}
                            flgLaunch={idTypeLaunch}
                            disableActions={disableActions}
                        />
                    :
                    <FechaLanzamiento
                        launchDate={launchDate}
                        setlaunchDate={setlaunchDate}
                        idReminder={idReminder}
                        setidReminder={setidReminder}
                        allowEdit={allowEdit}
                        productData={productData}
                        disableActions={disableActions}
                    />
                }
            </div>
            <div className="col-12">
                <div className="col-12 d-flex justify-content-end">
                    {launchData.eliminate === 1 &&
                        <button
                            className="btn btn-sm btn-blue-alert font-medium-2 px-5 mx-1"
                            onClick={handleClickCancelLaunch}
                            disabled={disableActions}
                        >Eliminar</button>}
                    {allowEdit === true ?
                        <button
                            className="btn btn-guay btn-sm font-medium-2 px-5 mx-1"
                            onClick={handleClickEdit}
                            disabled={disableActions ? disableActions : cantEdit}
                        >Editar</button> : null}
                    <button
                        className="btn btn-save-blue btn-sm font-medium-2 px-5"
                        onClick={(handleClickSave)}
                        disabled={disableActions ? disableActions : allowEdit}
                    >Continuar</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ launch }) => {
    return {
        launch
    }
}

export default connect(mapStateToProps, { modifyLaunch })(LanzamientoNormativo)
