import { getJwt } from "../../lib/auth"

const functionsArr = [
    {
        son: 0,
        idEnterprise: 0,
        description: "Comunidad",
        rank: 1,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 1
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Administración de servicios",
        rank: 2,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 2
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Administración de organización",
        rank: 3,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 3
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Administración de organizaciones",
        rank: 4,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 4
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Circulo personal",
        rank: 5,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 5
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Webinars",
        rank: 6,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 6
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "guaykipedia",
        rank: 7,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 7
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Beneficios",
        rank: 8,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 8
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Promociones",
        rank: 9,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 9
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Perfil personalidad",
        rank: 10,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 10
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Asistencia",
        rank: 11,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 11
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Evaluaciones",
        rank: 12,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 12
    },
    {
        son: 1,
        idEnterprise: 0,
        description: "Tienda",
        rank: 13,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 13
    },
    {
        son: 2,
        idEnterprise: 0,
        description: "Recursos Descargables",
        rank: 43,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 43
    },
    {
        son: 2,
        idEnterprise: 0,
        description: "Configuración",
        rank: 14,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 14
    },
    {
        son: 2,
        idEnterprise: 0,
        description: "Ejecución",
        rank: 15,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 15
    },
    {
        son: 2,
        idEnterprise: 0,
        description: "Resultados",
        rank: 16,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 16
    },
    {
        son: 2,
        idEnterprise: 0,
        description: "Acciones",
        rank: 17,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 17
    },
    {
        son: 2,
        idEnterprise: 0,
        description: "Cumplimiento",
        rank: 18,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 18
    },
    {
        son: 3,
        idEnterprise: 0,
        description: "Organización y grupo",
        rank: 19,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 19
    },
    {
        son: 3,
        idEnterprise: 0,
        description: "Usuarios y roles",
        rank: 20,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 20
    },
    {
        son: 3,
        idEnterprise: 0,
        description: "Colaboradores",
        rank: 21,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 21
    },
    {
        son: 3,
        idEnterprise: 0,
        description: "Quiosco",
        rank: 22,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 22
    },
    {
        son: 3,
        idEnterprise: 0,
        description: "Cumplimiento",
        rank: 23,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 23
    },
    {
        son: 4,
        idEnterprise: 0,
        description: "Grupo y perfil",
        rank: 24,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 24
    },
    {
        son: 4,
        idEnterprise: 0,
        description: "Grupo y organización",
        rank: 25,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 25
    },
    {
        son: 4,
        idEnterprise: 0,
        description: "Organización y perfil",
        rank: 26,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 26
    },
    {
        son: 4,
        idEnterprise: 0,
        description: "Organización y usuario",
        rank: 27,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 27
    },
    {
        son: 4,
        idEnterprise: 0,
        description: "Organización y colaboradores",
        rank: 28,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 28
    },
    {
        son: 5,
        idEnterprise: 0,
        description: "Administrar",
        rank: 29,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 29
    },
    {
        son: 5,
        idEnterprise: 0,
        description: "Perfil personal",
        rank: 30,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 30
    },
    {
        son: 13,
        idEnterprise: 0,
        description: "Ver tienda",
        rank: 31,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 31
    },
    {
        son: 14,
        idEnterprise: 0,
        description: "Lanzamientos",
        rank: 32,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 32
    },
    {
        son: 14,
        idEnterprise: 0,
        description: "Comunicación",
        rank: 33,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 33
    },
    {
        son: 15,
        idEnterprise: 0,
        description: "Usuarios registrados",
        rank: 34,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 34
    },
    {
        son: 15,
        idEnterprise: 0,
        description: "Avance y participación",
        rank: 35,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 35
    },
    {
        son: 15,
        idEnterprise: 0,
        description: "Correos",
        rank: 36,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 36
    },
    {
        son: 15,
        idEnterprise: 0,
        description: "Gestión documental",
        rank: 37,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 37
    },
    {
        son: 17,
        idEnterprise: 0,
        description: "Siguientes pasos",
        rank: 38,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 38
    },
    {
        son: 17,
        idEnterprise: 0,
        description: "Monitor de acciones",
        rank: 39,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 39
    },
    {
        son: 17,
        idEnterprise: 0,
        description: "Protocolos de acción",
        rank: 40,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 40
    },
    {
        son: 3,
        idEnterprise: 0,
        description: "Encuestas",
        rank: 41,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 41
    },
]

const generalStructure = [
    {
        son: 0,
        idEnterprise: 0,
        description: "Comunidad",
        rank: 1,
        id_action: 4,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 1,
        subSection: [
            {
                son: 1,
                idEnterprise: 0,
                description: "Webinars",
                rank: 6,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 6,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "guaykipedia",
                rank: 7,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 7,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "Beneficios",
                rank: 8,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 8,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "Promociones",
                rank: 9,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 9,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "Perfil personalidad",
                rank: 10,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 10,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "Asistencia",
                rank: 11,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 11,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "Evaluaciones",
                rank: 12,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 12,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 1,
                idEnterprise: 0,
                description: "Tienda",
                rank: 13,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 13,
                subSectionIn: [
                    {
                        son: 13,
                        idEnterprise: 0,
                        description: "Ver tienda",
                        rank: 31,
                        id_action: 4,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 31,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    }
                ],
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            }
        ],
        checks: [
            {
                checked: false,
                checkName: "Lectura",
                checkId: 2
            },
            {
                checked: false,
                checkName: "Escritura / Lectura",
                checkId: 1
            }
        ]
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Administración de servicios",
        rank: 2,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 2,
        subSection: [
            {
                son: 2,
                idEnterprise: 0,
                description: "Recursos Descargables",
                rank: 43,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 43,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 2,
                idEnterprise: 0,
                description: "Configuración",
                rank: 14,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 14,
                subSectionIn: [
                    {
                        son: 14,
                        idEnterprise: 0,
                        description: "Lanzamientos",
                        rank: 32,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 32,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                    {
                        son: 14,
                        idEnterprise: 0,
                        description: "Comunicación",
                        rank: 33,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 33,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                ],
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 2,
                idEnterprise: 0,
                description: "Ejecución",
                rank: 15,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 15,
                subSectionIn: [
                    {
                        son: 15,
                        idEnterprise: 0,
                        description: "Usuarios registrados",
                        rank: 34,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 34,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                    {
                        son: 15,
                        idEnterprise: 0,
                        description: "Avance y participación",
                        rank: 35,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 35,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                    {
                        son: 15,
                        idEnterprise: 0,
                        description: "Correos",
                        rank: 36,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 36,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                    {
                        son: 15,
                        idEnterprise: 0,
                        description: "Gestión documental",
                        rank: 37,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 37,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    }
                ],
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 2,
                idEnterprise: 0,
                description: "Resultados",
                rank: 16,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 16,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 2,
                idEnterprise: 0,
                description: "Acciones",
                rank: 17,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 17,
                subSectionIn: [
                    {
                        son: 17,
                        idEnterprise: 0,
                        description: "Siguientes pasos",
                        rank: 38,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 38,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                    {
                        son: 17,
                        idEnterprise: 0,
                        description: "Monitor de acciones",
                        rank: 39,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 39,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    },
                    {
                        son: 17,
                        idEnterprise: 0,
                        description: "Protocolos de acción",
                        rank: 40,
                        id_action: 0,
                        idPerzonalizedProfile: 0,
                        idPlatformFunctionality: 40,
                        checks: [
                            {
                                checked: false,
                                checkName: "Lectura",
                                checkId: 2
                            },
                            {
                                checked: false,
                                checkName: "Escritura / Lectura",
                                checkId: 1
                            }
                        ]
                    }
                ],
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 2,
                idEnterprise: 0,
                description: "Cumplimiento",
                rank: 18,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 18,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            }
        ],
        checks: [
            {
                checked: false,
                checkName: "Lectura",
                checkId: 2
            },
            {
                checked: false,
                checkName: "Escritura / Lectura",
                checkId: 1
            }
        ]
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Administración de organización",
        rank: 3,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 3,
        subSection: [
            {
                son: 3,
                idEnterprise: 0,
                description: "Organización y grupo",
                rank: 19,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 19,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 3,
                idEnterprise: 0,
                description: "Usuarios y roles",
                rank: 20,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 20,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 3,
                idEnterprise: 0,
                description: "Colaboradores",
                rank: 21,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 21,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 3,
                idEnterprise: 0,
                description: "Quiosco",
                rank: 22,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 22,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 3,
                idEnterprise: 0,
                description: "Cumplimiento",
                rank: 23,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 23,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 3,
                idEnterprise: 0,
                description: "Encuestas",
                rank: 41,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 41,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            }
        ],
        checks: [
            {
                checked: false,
                checkName: "Lectura",
                checkId: 2
            },
            {
                checked: false,
                checkName: "Escritura / Lectura",
                checkId: 1
            }
        ]
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Administración de organizaciones",
        rank: 4,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 4,
        subSection: [
            {
                son: 4,
                idEnterprise: 0,
                description: "Grupo y perfil",
                rank: 24,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 24,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 4,
                idEnterprise: 0,
                description: "Grupo y organización",
                rank: 25,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 25,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 4,
                idEnterprise: 0,
                description: "Organización y perfil",
                rank: 26,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 26,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 4,
                idEnterprise: 0,
                description: "Organización y usuario",
                rank: 27,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 27,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 4,
                idEnterprise: 0,
                description: "Organización y colaboradores",
                rank: 28,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 28,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            }
        ],
        checks: [
            {
                checked: false,
                checkName: "Lectura",
                checkId: 2
            },
            {
                checked: false,
                checkName: "Escritura / Lectura",
                checkId: 1
            }
        ]
    },
    {
        son: 0,
        idEnterprise: 0,
        description: "Circulo personal",
        rank: 5,
        id_action: 0,
        idPerzonalizedProfile: 0,
        idPlatformFunctionality: 5,
        subSection: [
            {
                son: 5,
                idEnterprise: 0,
                description: "Administrar",
                rank: 29,
                id_action: 0,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 29,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            },
            {
                son: 5,
                idEnterprise: 0,
                description: "Perfil personal",
                rank: 30,
                id_action: 4,
                idPerzonalizedProfile: 0,
                idPlatformFunctionality: 30,
                checks: [
                    {
                        checked: false,
                        checkName: "Lectura",
                        checkId: 2
                    },
                    {
                        checked: false,
                        checkName: "Escritura / Lectura",
                        checkId: 1
                    }
                ]
            }
        ],
        checks: [
            {
                checked: false,
                checkName: "Lectura",
                checkId: 2
            },
            {
                checked: false,
                checkName: "Escritura / Lectura",
                checkId: 1
            }
        ]
    }
]

export const validateAccess = function (arr, section) {
    const structureData = getArr => {

        const getArrIds = getArr.map(data => data.idPlatformFunctionality)
        // Get functionsArr items wihtout getArr items
        const getFuncArrWGetArr = functionsArr.filter(data => getArrIds.indexOf(data.idPlatformFunctionality) === -1)
        //console.log(getFuncArrWGetArr)
        // Concat getFuncArrWGetArr with getArr
        const getConcatGetFuncArrWGetArr = getFuncArrWGetArr.concat(getArr)
        //console.log(getConcatGetFuncArrWGetArr)
        // getConcatGetFuncArrWGetArr ordered by descendent
        const getOrderDesc = getConcatGetFuncArrWGetArr.sort(function (a, b) {
            if (a.idPlatformFunctionality > b.idPlatformFunctionality) {
                return 1;
            }
            if (a.idPlatformFunctionality < b.idPlatformFunctionality) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        //console.log(getOrderDesc)

        const getFathers = getOrderDesc.filter(card => card.son === 0)
        //console.log(getFathers)
        const getChildren = getOrderDesc.filter(card => card.son !== 0)
        //console.log(getChildren)
        const getChildrenIds = getChildren.map(card => card.idPlatformFunctionality)
        //console.log(getChildrenIds)
        let getGrandchildren = []
        for (let a in getChildrenIds) {
            for (let b in getChildren) {
                if (getChildrenIds[a] === getChildren[b].son) {
                    getGrandchildren.push(getChildren[b])
                }
            }
        }
        //console.log(getGrandchildren)
        const getChildrenFatherIds = getGrandchildren.map(grandchildren => grandchildren.son)
        //console.log(getChildrenFatherIds)
        const singleIds = new Set(getChildrenFatherIds);
        const getChildrenFatherSingleIds = [...singleIds];
        //console.log(getChildrenFatherSingleIds)
        let getChildrenFathers = []
        for (let a in getChildrenFatherSingleIds) {
            for (let b in getChildren) {
                if (getChildrenFatherSingleIds[a] === getChildren[b].idPlatformFunctionality) {
                    getChildrenFathers.push(getChildren[b])
                }
            }
        }
        //console.log(getChildrenFathers)

        //console.log(getFathers)
        //console.log(getChildren)
        //console.log(getChildrenFathers)
        //console.log(getGrandchildren)

        // Get childrenFathers with sons
        let groupChildrenWGrandchildren = []
        for (let a in getChildrenFathers) {
            let arr = []
            for (let b in getGrandchildren) {
                if (getChildrenFathers[a].idPlatformFunctionality === getGrandchildren[b].son) {
                    if (getGrandchildren[b].id_action === 0 || getGrandchildren[b].id_action === 3) {
                        arr.push({
                            ...getGrandchildren[b],
                            checks: [
                                {
                                    checked: false,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: false,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    if (getGrandchildren[b].id_action === 1) {
                        arr.push({
                            ...getGrandchildren[b],
                            checks: [
                                {
                                    checked: false,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: true,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    if (getGrandchildren[b].id_action === 2) {
                        arr.push({
                            ...getGrandchildren[b],
                            checks: [
                                {
                                    checked: true,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: false,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    if (getGrandchildren[b].id_action === 4) {
                        arr.push({
                            ...getGrandchildren[b],
                            checks: [
                                {
                                    checked: true,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: true,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                }
            }
            groupChildrenWGrandchildren.push({ ...getChildrenFathers[a], subSectionIn: arr })
        }
        //console.log(groupChildrenWGrandchildren)

        // Get fathers with sons
        let groupFathersWChildrens = []
        for (let a in getFathers) {
            let arr = []
            for (let b in getChildren) {
                if (getFathers[a].idPlatformFunctionality === getChildren[b].son) {
                    if (getChildren[b].id_action === 0 || getChildren[b].id_action === 3) {
                        arr.push({
                            ...getChildren[b],
                            checks: [
                                {
                                    checked: false,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: false,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    if (getChildren[b].id_action === 1) {
                        arr.push({
                            ...getChildren[b],
                            checks: [
                                {
                                    checked: false,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: true,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    if (getChildren[b].id_action === 2) {
                        arr.push({
                            ...getChildren[b],
                            checks: [
                                {
                                    checked: true,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: false,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    if (getChildren[b].id_action === 4) {
                        arr.push({
                            ...getChildren[b],
                            checks: [
                                {
                                    checked: true,
                                    checkName: 'Lectura',
                                    checkId: 2,
                                },
                                {
                                    checked: true,
                                    checkName: 'Escritura / Lectura',
                                    checkId: 1,
                                },
                            ],
                        })
                    }
                    /* arr.push({
                        ...getChildren[b],
                        checks: [
                            {
                                checked: false,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: false,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    }) */
                }
            }
            groupFathersWChildrens.push({ ...getFathers[a], subSection: arr })
        }
        //console.log(groupFathersWChildrens)

        //console.log(groupChildrenWGrandchildren)
        //console.log(groupFathersWChildrens)
        // Join groupChildrenWGrandchildren with groupFathersWChildrens
        let modifiedStructure = []
        for (let b in groupFathersWChildrens) {
            let arr = []
            let itemId = 0
            for (let a in groupChildrenWGrandchildren) {
                if (groupChildrenWGrandchildren[a].son === groupFathersWChildrens[b].idPlatformFunctionality) {
                    //console.log(groupFathersWChildrens[b].subSection)
                    //console.log(groupChildrenWGrandchildren[a])
                    //console.log(groupFathersWChildrens[b].subSection.length)
                    //console.log(itemId)
                    if (itemId < groupFathersWChildrens[b].subSection.length) {
                        for (let c in groupFathersWChildrens[b].subSection) {
                            if (groupChildrenWGrandchildren[a].idPlatformFunctionality === groupFathersWChildrens[b].subSection[c].idPlatformFunctionality) {
                                //console.log(groupChildrenWGrandchildren[a])
                                //console.log(groupChildrenWGrandchildren[a].id_action)
                                if (groupChildrenWGrandchildren[a].id_action === 0 || groupChildrenWGrandchildren[a].id_action === 3) {
                                    arr.push({
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: false,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: false,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    })
                                }
                                if (groupChildrenWGrandchildren[a].id_action === 1) {
                                    arr.push({
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: false,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: true,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    })
                                }
                                if (groupChildrenWGrandchildren[a].id_action === 2) {
                                    arr.push({
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: true,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: false,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    })
                                }
                                if (groupChildrenWGrandchildren[a].id_action === 4) {
                                    arr.push({
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: true,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: true,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    })
                                }
                                /* arr.push({
                                    ...groupChildrenWGrandchildren[a],
                                    checks: [
                                        {
                                            checked: false,
                                            checkName: 'Lectura',
                                            checkId: 2,
                                        },
                                        {
                                            checked: false,
                                            checkName: 'Escritura / Lectura',
                                            checkId: 1,
                                        },
                                    ],
                                }) */
                            } else {
                                arr.push(groupFathersWChildrens[b].subSection[c])
                            }
                            itemId = itemId + 1
                        }
                    } else {
                        for (let d in arr) {
                            if (groupChildrenWGrandchildren[a].idPlatformFunctionality === arr[d].idPlatformFunctionality) {
                                if (groupChildrenWGrandchildren[a].id_action === 0 || groupChildrenWGrandchildren[a].id_action === 3) {
                                    arr[d] = {
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: false,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: false,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    }
                                }
                                if (groupChildrenWGrandchildren[a].id_action === 1) {
                                    arr[d] = {
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: false,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: true,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    }
                                }
                                if (groupChildrenWGrandchildren[a].id_action === 2) {
                                    arr[d] = {
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: true,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: false,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    }
                                }
                                if (groupChildrenWGrandchildren[a].id_action === 4) {
                                    arr[d] = {
                                        ...groupChildrenWGrandchildren[a],
                                        checks: [
                                            {
                                                checked: true,
                                                checkName: 'Lectura',
                                                checkId: 2,
                                            },
                                            {
                                                checked: true,
                                                checkName: 'Escritura / Lectura',
                                                checkId: 1,
                                            },
                                        ],
                                    }
                                }
                                /* arr[d] = {
                                    ...groupChildrenWGrandchildren[a],
                                    checks: [
                                        {
                                            checked: false,
                                            checkName: 'Lectura',
                                            checkId: 2,
                                        },
                                        {
                                            checked: false,
                                            checkName: 'Escritura / Lectura',
                                            checkId: 1,
                                        },
                                    ],
                                } */
                            }
                        }
                        itemId = itemId + 1
                    }
                    //console.log(arr)
                }
            }
            if (arr.length > 0) {
                itemId = 0
                if (groupFathersWChildrens[b].id_action === 0 || groupFathersWChildrens[b].id_action === 3) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        subSection: arr,
                        checks: [
                            {
                                checked: false,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: false,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                if (groupFathersWChildrens[b].id_action === 1) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        subSection: arr,
                        checks: [
                            {
                                checked: false,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: true,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                if (groupFathersWChildrens[b].id_action === 2) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        subSection: arr,
                        checks: [
                            {
                                checked: true,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: false,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                if (groupFathersWChildrens[b].id_action === 4) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        subSection: arr,
                        checks: [
                            {
                                checked: true,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: true,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                /* modifiedStructure.push({
                    ...groupFathersWChildrens[b],
                    subSection: arr,
                    checks: [
                        {
                            checked: false,
                            checkName: 'Lectura',
                            checkId: 2,
                        },
                        {
                            checked: false,
                            checkName: 'Escritura / Lectura',
                            checkId: 1,
                        },
                    ],
                }) */
            } else {
                itemId = 0
                if (groupFathersWChildrens[b].id_action === 0 || groupFathersWChildrens[b].id_action === 3) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        checks: [
                            {
                                checked: false,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: false,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                if (groupFathersWChildrens[b].id_action === 1) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        checks: [
                            {
                                checked: false,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: true,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                if (groupFathersWChildrens[b].id_action === 2) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        checks: [
                            {
                                checked: true,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: false,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                if (groupFathersWChildrens[b].id_action === 4) {
                    modifiedStructure.push({
                        ...groupFathersWChildrens[b],
                        checks: [
                            {
                                checked: true,
                                checkName: 'Lectura',
                                checkId: 2,
                            },
                            {
                                checked: true,
                                checkName: 'Escritura / Lectura',
                                checkId: 1,
                            },
                        ],
                    })
                }
                /* modifiedStructure.push({
                    ...groupFathersWChildrens[b],
                    checks: [
                        {
                            checked: false,
                            checkName: 'Lectura',
                            checkId: 2,
                        },
                        {
                            checked: false,
                            checkName: 'Escritura / Lectura',
                            checkId: 1,
                        },
                    ],
                }) */
            }
        }
        //console.log(modifiedStructure)
        const getModifiedStructureIds = modifiedStructure.map(data => data.idPlatformFunctionality)
        // Get generalStrcuture without modifiedStructure
        const getGeneralStrucWModifStruct = generalStructure.filter(data => getModifiedStructureIds.indexOf(data.idPlatformFunctionality) === -1)
        //console.log(getGeneralStrucWModifStruct)
        // Concat getGeneralStrucWModifStruct with modifiedStructure
        const getConcatGenStructWModifStruct = getGeneralStrucWModifStruct.concat(modifiedStructure)
        //console.log(getConcatGenStructWModifStruct)
        // getConcatGenStructWModifStruct ordered by descendent
        const getOrderConcatStructures = getConcatGenStructWModifStruct.sort(function (a, b) {
            if (a.idPlatformFunctionality > b.idPlatformFunctionality) {
                return 1;
            }
            if (a.idPlatformFunctionality < b.idPlatformFunctionality) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        //console.log(getOrderConcatStructures)
        //setGeneralStructure(getOrderConcatStructures)
        //setSection(2)
        let sectionData = []
        for (let a in getOrderConcatStructures) {
            if (getOrderConcatStructures[a].idPlatformFunctionality === section) {
                sectionData.push(getOrderConcatStructures[a])
            }
        }
        return sectionData
    }
    let newData = structureData(arr)
    return newData
}

export const validateSectionAccess = function (arr, section) {
    if (parseInt(getJwt('rol')) > 6) {
        let showSection = false
        if (section === 2 || section === 3) {
            for (let x in arr) {
                if (arr[x].idPlatformFunctionality === 2 || arr[x].idPlatformFunctionality === 3) {
                    showSection = true
                }
            }
        } else {
            for (let x in arr) {
                if (arr[x].idPlatformFunctionality === section) {
                    showSection = true
                }
            }
        }
        return showSection
    } else {
        return true
    }
}

export const getSubsection = function (arr, subsection) {
    let newArr = []
    for (let x in arr) {
        if (arr[x].idPlatformFunctionality === subsection) {
            newArr.push(arr[x])
        }
    }
    return newArr
}

export const getSubsectionIn = function (arr, subsectionIn) {
    let newArr = []
    for (let x in arr) {
        if (arr[x].idPlatformFunctionality === subsectionIn) {
            newArr.push(arr[x])
        }
    }
    return newArr
}