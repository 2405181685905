import React from 'react'

const BenefitCard = ({
    guayBenefits,
    setContactData,
    setShowHTML,
    setURL,
    setBenefitName,
    setShowUseBenefitHtml,
    setIdBenefit,
    setIdProduct,
    handleScroll,
}) => {

    return (
        <div className='col-12 col-md-11 col-lg-11 col-xxl-10 mx-auto mb-1'>
            <div className='row'>
                {
                    guayBenefits.map(data => (
                        <div
                            className='col-12 col-md-6 col-lg-4 col-xxl-4 mb-3'
                            key={data.idBenefit}
                        >
                            <div className='card m-0 h-100'>
                                <div className='card-body px-0 pb-0'>
                                    <div className='row h-100'>
                                        <div className='col-12 col-md-7 col-xl-6 d-flex flex-wrap align-content-center pr-0'>
                                            <div className='col-12 pb-1 d-flex justify-content-center flex-wrap align-content-center h-25'>
                                                {
                                                    data.urlLogo === '' || data.urlLogo === undefined ? (
                                                        <h2 className='mb-1'>{data.nameEnterprise}</h2>
                                                    ) : (
                                                        <img
                                                            src={data.urlLogo}
                                                            alt='benefit'
                                                            style={{
                                                                height: '50px',
                                                                width: 'auto',
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                            <div className='col-12 h-50 d-flex justify-content-center flex-wrap align-content-center'>
                                                <p className='text-justify pt-1'>{data.description}</p>
                                            </div>
                                            <div className='col-12 h-25 d-flex justify-content-center flex-wrap align-content-center'>
                                                <div className="dropdown d-flex justify-content-center w-75">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm bg-primary-1 w-100 text-white d-flex justify-content-center flex-wrap align-content-center"
                                                        data-toggle="dropdown"
                                                    >
                                                        <span className='font-medium-1 pr-1'>Ver más</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                        </svg>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        {
                                                            data.urlEnterprise !== '' && (
                                                                <span
                                                                    className="dropdown-item pointer"
                                                                    onClick={() => {
                                                                        /* let request = {
                                                                            idBenefit: data.idBenefit,
                                                                            idUser: getJwt('jtw'),
                                                                        };
                                                                        let formData = new FormData();
                                                                        formData.append("request", JSON.stringify(request));
                                                                        setInteractionBenefits(formData);
                                                                        window.open(data.urlEnterprise); */
                                                                        if (data.urlEnterprise.indexOf('somosguay.com') > 0 || data.urlEnterprise.indexOf('guay.digital') > 0) {
                                                                            setShowHTML(true)
                                                                            setURL(data.urlEnterprise)
                                                                            setBenefitName(data.nameEnterprise)
                                                                            handleScroll('section-top')
                                                                        } else {
                                                                            window.open(data.urlEnterprise)
                                                                        }
                                                                    }}
                                                                >Ver beneficio</span>
                                                            )
                                                        }
                                                        {
                                                            data.urlService !== '' && (
                                                                <span
                                                                    className="dropdown-item pointer"
                                                                    onClick={() => {
                                                                        /* let request = {
                                                                            idBenefit: data.idBenefit,
                                                                            idUser: getJwt('jtw'),
                                                                        };
                                                                        let formData = new FormData();
                                                                        formData.append("request", JSON.stringify(request));
                                                                        setInteractionBenefits(formData);
                                                                        window.open(data.urlEnterprise); */
                                                                        if (data.urlService.indexOf('somosguay.com') > 0 || data.urlService.indexOf('guay.digital') > 0) {
                                                                            setIdBenefit(data.idBenefit)
                                                                            if(data.urlService.indexOf('form-dental') > 0 ){
                                                                                setShowUseBenefitHtml(true)
                                                                                setShowHTML(true)
                                                                                setIdProduct(1)
                                                                                setBenefitName(data.nameEnterprise)
                                                                            }else if(data.urlService.indexOf('form-oftalmologico') > 0 ){
                                                                                setShowUseBenefitHtml(true)
                                                                                setShowHTML(true)
                                                                                setIdProduct(2)
                                                                                setBenefitName(data.nameEnterprise)
                                                                            }else{
                                                                                setShowHTML(true)
                                                                                setURL(data.urlService)
                                                                                setBenefitName(data.nameEnterprise)
                                                                            }
                                                                            
                                                                        } else {
                                                                            window.open(data.urlService)
                                                                        }
                                                                    }}
                                                                >Utilizar el servicio</span>
                                                            )
                                                        }
                                                        {
                                                            (data.email !== '' || data.whatsapp !== '' || data.phone !== '' || data.urlContact !== '') && (
                                                                <span
                                                                    className="dropdown-item pointer"
                                                                    data-toggle="modal"
                                                                    data-target="#contactModal"
                                                                    onClick={() => {
                                                                        setContactData({
                                                                            //description: data.contact ? data.contact : '',
                                                                            email: data.email ? data.email : '',
                                                                            whatsapp: data.whatsapp ? data.whatsapp : '',
                                                                            phone: data.phone ? data.phone : '',
                                                                            website: data.urlContact ? data.urlContact : '',
                                                                        });
                                                                    }}
                                                                >Contacto</span>
                                                            )
                                                        }
                                                        {
                                                            data.digitalManual !== undefined && (
                                                                <span
                                                                    className="dropdown-item pointer"
                                                                    onClick={() => window.open(data.digitalManual)}
                                                                >Manual</span>
                                                            )
                                                        }
                                                        {
                                                            data.digitalDescription !== undefined && (
                                                                <span
                                                                    className="dropdown-item pointer"
                                                                    onClick={() => window.open(data.digitalDescription)}
                                                                >Desc. Digital</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-5 col-xl-6 d-flex justify-content-center flex-wrap align-content-end'>
                                            {
                                                data.urlResource === "" || data.urlResource === undefined ? (
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                        alt='benefit'
                                                    />
                                                ) : (
                                                    <img
                                                        style={{ width: '90%' }}
                                                        src={data.urlResource}
                                                        alt='benefit'
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
    
}

export default BenefitCard