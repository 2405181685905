import React from 'react'

const SurveyComplete = () => {
  return (
    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-smoke d-flex justify-content-center flex-wrap align-content-center" style={{height:'100vh'}}>
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center flex-wrap align-content-center mx-auto">
            <img src="../img/filtroClinico/PantallaCompletado.png" className="img-fluid" alt="img-completo" />
        </div>
    </div>
  )
}

export default SurveyComplete
