import React,
{
    useState,
    useEffect,
} from 'react'
// API calls
import { getCollabWithOutAssingSpecificLaunch } from '../Models/Launch_model'
// TODO: Components
import CollabsTable from './CollabsTable'
// TODO: Generic func
import {
    getJwt,
} from '../../../lib/auth'
// TODO: Tools
import * as toastr from 'toastr'
import $ from 'jquery'

const AssingCollabsLauncCtrl = ({
    step_2,
    currentIdGroup,
    branchesCat,
    categoriesJobCat,
    areasCat,
    refreshData,
    setCurrentIdGroup,
}) => {

    // useStates arr
    const [collaborators, setCollaborators] = useState([])
    const [isChangeFlag, setIsChangedFlag] = useState(false)

    useEffect(() => {
        if (currentIdGroup > 0) {
            getCollabWithOutAssingSpecificLaunch(getJwt('enterprise'), currentIdGroup, getJwt('rol'), getJwt('jtw')).then(response => {
                if (response.code === 1 || response.code === 200) {
                    let collabArr = []
                    response.listCollab.forEach(collab => {
                        let obj = {}
                        branchesCat.forEach(e => { if (e.branchEntId === collab.branchEntId) { obj = { ...collab, branchEntId: e.description, } } })
                        areasCat.forEach(e => { if (e.areaId === collab.areaEntId) { obj = { ...obj, areaEntId: e.description, } } })
                        categoriesJobCat.forEach(e => { if (e.categoryEntId === collab.categoryEntId) { obj = { ...obj, categoryEntId: e.description, } } })
                        collabArr.push(obj)
                    })
                    //console.log(collabArr)
                    setCollaborators(collabArr)
                }else if(response.code === 1001){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('La empresa no está activa o no existe.', '¡Ooops!')
                }else if(response.code === 1002){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('No hay colaboradores para asignar.', '¡Ooops!')
                }else if(response.code === 1003){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('No se encontro la evaluacón.', '¡Ooops!')
                }else if(response.code === 1004){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('El status de la evaluación es incorrecto.', '¡Ooops!')
                }else {
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.error('"Ocurrió un problema de conexión", "Revisa tu conexión a internet"', '¡Error!')
                }
            })
            setIsChangedFlag(false)
        } else {
            $('#modalAssingCollabs').hide()
            setCurrentIdGroup(0)
            setIsChangedFlag(false)
        }
    }, [currentIdGroup, branchesCat, areasCat, categoriesJobCat])

    const handleShowTable = groupId => {
        if (groupId !== '0') {
            getCollabWithOutAssingSpecificLaunch(getJwt('enterprise'), groupId, getJwt('rol'), getJwt('jtw')).then(response => {
                if (response.code === 1 || response.code === 200) {
                    let collabArr = []
                    response.listCollab.forEach(collab => {
                        let obj = {}
                        branchesCat.forEach(e => { if (e.branchEntId === collab.branchEntId) { obj = { ...collab, branchEntId: e.description, } } })
                        areasCat.forEach(e => { if (e.areaId === collab.areaEntId) { obj = { ...obj, areaEntId: e.description, } } })
                        categoriesJobCat.forEach(e => { if (e.categoryEntId === collab.categoryEntId) { obj = { ...obj, categoryEntId: e.description, } } })
                        collabArr.push(obj)
                    })
                    //console.log(collabArr)
                    setCollaborators(collabArr)
                    setIsChangedFlag(true)
                } else if(response.code === 1001){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('La empresa no está activa o no existe.', '¡Ooops!')
                }else if(response.code === 1002){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('No hay colaboradores para asignar.', '¡Ooops!')
                }else if(response.code === 1003){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('No se encontró el ejercicio.', '¡Ooops!')
                }else if(response.code === 1004){
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.info('El status del ejercicio es incorrecto.', '¡Ooops!')
                }else {
                    $('#modalAssingCollabs').hide()
                    setCurrentIdGroup(0)
                    toastr.error('"Ocurrió un problema de conexión", "Revisa tu conexión a internet"', '¡Error!')
                }
            })
        }
    }

    return (
        <div
            className="modal"
            id="modalAssingCollabs"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                $('#modalAssingCollabs').hide()
                                setCurrentIdGroup(0)
                                if (isChangeFlag) {
                                    refreshData()
                                    setIsChangedFlag(false)
                                }
                            }}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='col-12 mb-1'>
                                                <h4 className='text-center'>Asignar colaboradores a lanzamiento en curso</h4>
                                            </div>
                                            <div className='col-12 py-3'>
                                                {collaborators.length > 0 && (
                                                    <div className='row mt-3'>
                                                        <CollabsTable
                                                            collaborators={collaborators}
                                                            launchsSelected={currentIdGroup}
                                                            handleShowTable={handleShowTable}
                                                            disableActions={step_2}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssingCollabsLauncCtrl
