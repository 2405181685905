import React, { useState, useEffect } from 'react'
// TODO: API call
import {
    getAddressByCP,
    getProfileCatalogs,
} from '../../Models/Catalog_model'
import {
    getProfileInformation,
    setProfileInformation,
} from '../../Models/ProfileModel'
// TODO: Components
import Header from '../headerColaborador'
import Menu from '../../Menu'
import HelperApp from '../../HelperApp'
import ProfilePanel from './ProfilePanel'
import GenInfoForm from './GenInfoForm'
import PersonalInfoForm from './PersonalInfoForm'
import JobInfoForm from './JobInfoForm'
import SecurityForm from './SecurityForm'
import Message from './Message'
import Footer from '../../footerApp'
// TODO: Generic func
import { getJwt } from '../../../../lib/auth'
import { convertDateToYYYYMMDD } from '../../../helpers/helpers'
// TODO: Tools
import * as toastr from 'toastr'

const ProfileController = () => {

    const [showForm, setShowForm] = useState(0)
    const [showMessage, setShowMessage] = useState(true)

    const [tcProfileAntiquity, settcProfileAntiquity] = useState([])
    const [tcProfileCompensation, settcProfileCompensation] = useState([])
    const [tcProfileConveyance, settcProfileConveyance] = useState([])
    const [tcProfileDependents, settcProfileDependents] = useState([])
    const [tcProfileGender, settcProfileGender] = useState([])
    const [tcProfileHiring, settcProfileHiring] = useState([])
    const [tcProfileMaritalStatus, settcProfileMaritalStatus] = useState([])
    const [tcProfileScholarship, settcProfileScholarship] = useState([])
    const [tcProfileSleepTime, settcProfileSleepTime] = useState([])
    const [tcProfileStaffType, settcProfileStaffType] = useState([])
    const [tcProfileTransferTime, settcProfileTransferTime] = useState([])
    const [tcProfileTypeOfDay, settcProfileTypeOfDay] = useState([])
    const [tcProfileWorkExperience, settcProfileWorkExperience] = useState([])
    const [tcProfileWorkSchemes, settcProfileWorkSchemes] = useState([])

    const [percentageForms, setPercentageForms] = useState({
        genInfo: 15,
        personalInfo: 25,
        jobInfo: 50,
    })
    const [generalInfo, setGeneralInfo] = useState({
        fullName: '',
        img: '',
        stateName: '',
    })

    const [genInfoObj, setGenInfoObj] = useState({
        idUser: getJwt('jtw'),
        imgProfile: '',
        name: '',
        lastName: '',
        birthdate: '',
        idGender: '0',
        postalCode: '',
        email: '',
    })
    const [personalInfoObj, setPersonalInfoObj] = useState({
        idMaritalStatus: '0',
        idScholarship: '0',
        scholarshipComplete: '0',
        idTransferTime: '0',
        idConveyance: '0',
        idDependents: '0',
        idSleepTime: '0',
        idWorkScheme: '0',
    })
    const [jobInfoObj, setJobInfoObj] = useState({
        idHiring: '0',
        idAntiquity: '0',
        idStaffType: '0',
        idWorkExperience: '0',
        idTypeDay: '0',
        shiftRotation: '0',
        idCompensation: '0',
    })

    useEffect(() => {
        if (sessionStorage.getItem('showProfileMessage') === '1') {
            setShowMessage(true)
            sessionStorage.removeItem('showProfileMessage')
        }
        getProfileInformation(getJwt('jtw')).then(response => {
            if (response && response.code === 1) {
                setPercentageForms({
                    genInfo: response.profileBasicInfoPercentage,
                    personalInfo: response.profilePersonalInfoPercentage,
                    jobInfo: response.profileJobInfoPercentage,
                })
                setGenInfoObj({
                    idUser: getJwt('jtw'),
                    name: response.profileInformation[0].basicInformation.name === undefined ? '' : response.profileInformation[0].basicInformation.name,
                    lastName: response.profileInformation[0].basicInformation.lastName === undefined ? '' : response.profileInformation[0].basicInformation.lastName,
                    idGender: response.profileInformation[0].basicInformation.idGender === undefined ? '0' : response.profileInformation[0].basicInformation.idGender,
                    postalCode: response.profileInformation[0].basicInformation.postalCode === undefined ? '' : response.profileInformation[0].basicInformation.postalCode === 0 ? '' : response.profileInformation[0].basicInformation.postalCode,
                    birthdate: response.profileInformation[0].basicInformation.birthdate === undefined ? '' : response.profileInformation[0].basicInformation.birthdate === '' ? '' : new Date(response.profileInformation[0].basicInformation.birthdate),
                    email: response.profileInformation[0].basicInformation.email === undefined ? '' : response.profileInformation[0].basicInformation.email,
                })
                setPersonalInfoObj({
                    idMaritalStatus: response.profileInformation[0].personalInformation.idMaritalStatus === undefined ? '0' : response.profileInformation[0].personalInformation.idMaritalStatus,
                    idScholarship: response.profileInformation[0].personalInformation.idScholarship === undefined ? '0' : response.profileInformation[0].personalInformation.idScholarship,
                    scholarshipComplete: response.profileInformation[0].personalInformation.scholarshipComplete === undefined ? '0' : response.profileInformation[0].personalInformation.scholarshipComplete,
                    idTransferTime: response.profileInformation[0].personalInformation.idTransferTime === undefined ? '0' : response.profileInformation[0].personalInformation.idTransferTime,
                    idConveyance: response.profileInformation[0].personalInformation.idConveyance === undefined ? '0' : response.profileInformation[0].personalInformation.idConveyance,
                    idDependents: response.profileInformation[0].personalInformation.idDependents === undefined ? '0' : response.profileInformation[0].personalInformation.idDependents,
                    idSleepTime: response.profileInformation[0].personalInformation.idSleepTime === undefined ? '0' : response.profileInformation[0].personalInformation.idSleepTime,
                    idWorkScheme: response.profileInformation[0].personalInformation.idWorkScheme === undefined ? '0' : response.profileInformation[0].personalInformation.idWorkScheme,
                })
                setJobInfoObj({
                    idHiring: response.profileInformation[0].jobInformation.idHiring === undefined ? '0' : response.profileInformation[0].jobInformation.idHiring,
                    idAntiquity: response.profileInformation[0].jobInformation.idAntiquity === undefined ? '0' : response.profileInformation[0].jobInformation.idAntiquity,
                    idStaffType: response.profileInformation[0].jobInformation.idStaffType === undefined ? '0' : response.profileInformation[0].jobInformation.idStaffType,
                    idWorkExperience: response.profileInformation[0].jobInformation.idWorkExperience === undefined ? '0' : response.profileInformation[0].jobInformation.idWorkExperience,
                    idTypeDay: response.profileInformation[0].jobInformation.idTypeDay === undefined ? '0' : response.profileInformation[0].jobInformation.idTypeDay,
                    shiftRotation: response.profileInformation[0].jobInformation.shiftRotation === undefined ? '0' : response.profileInformation[0].jobInformation.shiftRotation,
                    idCompensation: response.profileInformation[0].jobInformation.idCompensation === undefined ? '0' : response.profileInformation[0].jobInformation.idCompensation,
                })

                if (response.profileInformation[0].basicInformation.postalCode !== 0) {
                    getAddressByCP(response.profileInformation[0].basicInformation.postalCode).then(responseAddress => {
                        setGeneralInfo({
                            fullName: `${response.profileInformation[0].basicInformation.name} ${response.profileInformation[0].basicInformation.lastName}`,
                            img: response.profileInformation[0].basicInformation.imgProfile === undefined ? '' : response.profileInformation[0].basicInformation.imgProfile,
                            stateName: responseAddress[0].nameState.toLowerCase(),
                        })
                    })
                } else {
                    setGeneralInfo({
                        fullName: `${response.profileInformation[0].basicInformation.name} ${response.profileInformation[0].basicInformation.lastName}`,
                        img: response.profileInformation[0].basicInformation.imgProfile === undefined ? '' : response.profileInformation[0].basicInformation.imgProfile,
                        stateName: '',
                    })
                }
            } else {
                toastr.error(`No se pudo actualizar la información. Verifica tu conexión de internet. En caso de persistir el problema contacta con el equipo de soporte. Error: ${response.message}`, '¡Ooops!')
            }
        })
        getProfileCatalogs().then(response => {
            if (response && response.code === 1) {
                settcProfileAntiquity(response.tcProfileAntiquity)
                settcProfileCompensation(response.tcProfileCompensation)
                settcProfileConveyance(response.tcProfileConveyance)
                settcProfileDependents(response.tcProfileDependents)
                settcProfileGender(response.tcProfileGender)
                settcProfileHiring(response.tcProfileHiring)
                settcProfileMaritalStatus(response.tcProfileMaritalStatus)
                settcProfileScholarship(response.tcProfileScholarship)
                settcProfileSleepTime(response.tcProfileSleepTime)
                settcProfileStaffType(response.tcProfileStaffType)
                settcProfileTransferTime(response.tcProfileTransferTime)
                settcProfileTypeOfDay(response.tcProfileTypeOfDay)
                settcProfileWorkExperience(response.tcProfileWorkExperience)
                settcProfileWorkSchemes(response.tcProfileWorkSchemes)
            }
        })
    }, [])
    const handleOnSubmitForm = (idForm, dataObj) => {
        let data = {}
        if (idForm === 1) {
            if (dataObj.postalCode.length < 5) {
                toastr.warning('El código postal debe tener 5 digitos.', '¡Ooops!')
                return
            }
            let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
            if (!regex.test(dataObj.email)) {
                toastr.warning('El formato del Correo de Cuenta no es correcto.', '¡Ooops!')
                return
            }
            data = {
                infoToSave: idForm,
                basicInformation: {
                    idUser: parseInt(getJwt('jtw')),
                    name: dataObj.name,
                    lastName: dataObj.lastName,
                    birthdate: dataObj.birthdate === '' ? '' : convertDateToYYYYMMDD(dataObj.birthdate),
                    idGender: parseInt(dataObj.idGender),
                    postalCode: parseInt(dataObj.postalCode),
                    email: dataObj.email,
                },
            }
        }
        if (idForm === 2) {
            data = {
                infoToSave: idForm,
                basicInformation: {
                    idUser: parseInt(getJwt('jtw')),
                },
                personalInformation: {
                    idMaritalStatus: parseInt(dataObj.idMaritalStatus),
                    idScholarship: parseInt(dataObj.idScholarship),
                    scholarshipComplete: parseInt(dataObj.scholarshipComplete),
                    idTransferTime: parseInt(dataObj.idTransferTime),
                    idConveyance: parseInt(dataObj.idConveyance),
                    idDependents: parseInt(dataObj.idDependents),
                    idSleepTime: parseInt(dataObj.idSleepTime),
                    idWorkScheme: parseInt(dataObj.idWorkScheme),
                }
            }
        }
        if (idForm === 3) {
            data = {
                infoToSave: idForm,
                basicInformation: {
                    idUser: parseInt(getJwt('jtw')),
                },
                jobInformation: {
                    idHiring: parseInt(dataObj.idHiring),
                    idAntiquity: parseInt(dataObj.idAntiquity),
                    idStaffType: parseInt(dataObj.idStaffType),
                    idWorkExperience: parseInt(dataObj.idWorkExperience),
                    idTypeDay: parseInt(dataObj.idTypeDay),
                    shiftRotation: parseInt(dataObj.shiftRotation),
                    idCompensation: parseInt(dataObj.idCompensation),
                }
            }
        }
        //console.log(data)
        setProfileInformation(data).then(response => {
            if (response && response.code === 1) {
                getProfileInformation(getJwt('jtw')).then(response => {
                    if (response && response.code === 1) {
                        setPercentageForms({
                            genInfo: response.profileBasicInfoPercentage,
                            personalInfo: response.profilePersonalInfoPercentage,
                            jobInfo: response.profileJobInfoPercentage,
                        })
                        setGenInfoObj({
                            idUser: getJwt('jtw'),
                            name: response.profileInformation[0].basicInformation.name === undefined ? '' : response.profileInformation[0].basicInformation.name,
                            lastName: response.profileInformation[0].basicInformation.lastName === undefined ? '' : response.profileInformation[0].basicInformation.lastName,
                            idGender: response.profileInformation[0].basicInformation.idGender === undefined ? '0' : response.profileInformation[0].basicInformation.idGender,
                            postalCode: response.profileInformation[0].basicInformation.postalCode === undefined ? '' : response.profileInformation[0].basicInformation.postalCode === 0 ? '' : response.profileInformation[0].basicInformation.postalCode,
                            birthdate: response.profileInformation[0].basicInformation.birthdate === undefined ? '' : response.profileInformation[0].basicInformation.birthdate === '' ? '' : new Date(response.profileInformation[0].basicInformation.birthdate),
                            email: response.profileInformation[0].basicInformation.email === undefined ? '' : response.profileInformation[0].basicInformation.email,
                        })
                        setPersonalInfoObj({
                            idMaritalStatus: response.profileInformation[0].personalInformation.idMaritalStatus === undefined ? '0' : response.profileInformation[0].personalInformation.idMaritalStatus,
                            idScholarship: response.profileInformation[0].personalInformation.idScholarship === undefined ? '0' : response.profileInformation[0].personalInformation.idScholarship,
                            scholarshipComplete: response.profileInformation[0].personalInformation.scholarshipComplete === undefined ? '0' : response.profileInformation[0].personalInformation.scholarshipComplete,
                            idTransferTime: response.profileInformation[0].personalInformation.idTransferTime === undefined ? '0' : response.profileInformation[0].personalInformation.idTransferTime,
                            idConveyance: response.profileInformation[0].personalInformation.idConveyance === undefined ? '0' : response.profileInformation[0].personalInformation.idConveyance,
                            idDependents: response.profileInformation[0].personalInformation.idDependents === undefined ? '0' : response.profileInformation[0].personalInformation.idDependents,
                            idSleepTime: response.profileInformation[0].personalInformation.idSleepTime === undefined ? '0' : response.profileInformation[0].personalInformation.idSleepTime,
                            idWorkScheme: response.profileInformation[0].personalInformation.idWorkScheme === undefined ? '0' : response.profileInformation[0].personalInformation.idWorkScheme,
                        })
                        setJobInfoObj({
                            idHiring: response.profileInformation[0].jobInformation.idHiring === undefined ? '0' : response.profileInformation[0].jobInformation.idHiring,
                            idAntiquity: response.profileInformation[0].jobInformation.idAntiquity === undefined ? '0' : response.profileInformation[0].jobInformation.idAntiquity,
                            idStaffType: response.profileInformation[0].jobInformation.idStaffType === undefined ? '0' : response.profileInformation[0].jobInformation.idStaffType,
                            idWorkExperience: response.profileInformation[0].jobInformation.idWorkExperience === undefined ? '0' : response.profileInformation[0].jobInformation.idWorkExperience,
                            idTypeDay: response.profileInformation[0].jobInformation.idTypeDay === undefined ? '0' : response.profileInformation[0].jobInformation.idTypeDay,
                            shiftRotation: response.profileInformation[0].jobInformation.shiftRotation === undefined ? '0' : response.profileInformation[0].jobInformation.shiftRotation,
                            idCompensation: response.profileInformation[0].jobInformation.idCompensation === undefined ? '0' : response.profileInformation[0].jobInformation.idCompensation,
                        })

                        if (response.profileInformation[0].basicInformation.postalCode !== 0) {
                            getAddressByCP(response.profileInformation[0].basicInformation.postalCode).then(responseAddress => {
                                setGeneralInfo({
                                    fullName: `${response.profileInformation[0].basicInformation.name} ${response.profileInformation[0].basicInformation.lastName}`,
                                    img: response.profileInformation[0].basicInformation.imgProfile,
                                    stateName: responseAddress[0].nameState.toLowerCase(),
                                })
                            })
                        } else {
                            setGeneralInfo({
                                fullName: `${response.profileInformation[0].basicInformation.name} ${response.profileInformation[0].basicInformation.lastName}`,
                                img: response.profileInformation[0].basicInformation.imgProfile,
                                stateName: '',
                            })
                        }

                        setShowForm(0)
                    }
                })
            } else {
                setShowForm(0)
                toastr.error(`No se pudo actualizar la información. Verifica tu conexión de internet. En caso de persistir el problema contacta con el equipo de soporte. Error: ${response.message}`, '¡Ooops!')
            }
        })
    }

    return (
        <div className='app-content new-guay-plataform bootomFooter'>
            <div className='content-overlay'>
                <Header />
                <Menu />
                <div className='content-wrapper bootomFooter'>
                    <div className='content-header'>
                        <div className='col-11 mx-auto'>
                            <div className='content-header-right breadcrumb-new mt-2 d-flex justify-content-end'>
                                <div className='row breadcrumbs-top d-inline-block'>
                                    <div className='breadcrumb-wrapper col-12'>
                                        <ol className='breadcrumb'>
                                            <li className='breadcrumb-item font-medium-3'><a href='/inicio-colaborador'>Home</a></li>
                                            <li className={showForm === 0 ? ('breadcrumb-item font-medium-3 text-blue-sec font-weight-bold') : ('breadcrumb-item font-medium-3 text-blue-sec')}>Mi perfil</li>
                                            {showForm === 1 && (
                                                <li className='breadcrumb-item font-medium-3 text-blue-sec font-weight-bold'>Información Básica</li>
                                            )}
                                            {showForm === 2 && (
                                                <li className='breadcrumb-item font-medium-3 text-blue-sec font-weight-bold'>Información Personal</li>
                                            )}
                                            {showForm === 3 && (
                                                <li className='breadcrumb-item font-medium-3 text-blue-sec font-weight-bold'>Información Laboral</li>
                                            )}
                                            {showForm === 4 && (
                                                <li className='breadcrumb-item font-medium-3 text-blue-sec font-weight-bold'>Seguridad</li>
                                            )}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-body'>
                        <HelperApp />
                        <div className='col-sx-11 col-sm-11 col-md-10 col-lg-10 col-xxl-8 mx-auto'>
                            <div
                                className='card mt-3'
                                style={{ minHeight: '70vh' }}
                            >
                                <div className='card-body d-flex flex-wrap align-content-center'>
                                    {showForm === 0 && (
                                        <ProfilePanel
                                            percentageForms={percentageForms}
                                            setShowForm={setShowForm}
                                            generalInfo={generalInfo}
                                        />
                                    )}
                                    {showForm === 1 && (
                                        <GenInfoForm
                                            tcProfileGender={tcProfileGender}
                                            setShowForm={setShowForm}
                                            genInfoObj={genInfoObj}
                                            setGenInfoObj={setGenInfoObj}
                                            handleOnSubmitForm={handleOnSubmitForm}
                                            generalInfo={generalInfo}
                                        />
                                    )}
                                    {showForm === 2 && (
                                        <PersonalInfoForm
                                            tcProfileMaritalStatus={tcProfileMaritalStatus}
                                            tcProfileScholarship={tcProfileScholarship}
                                            tcProfileTransferTime={tcProfileTransferTime}
                                            tcProfileConveyance={tcProfileConveyance}
                                            tcProfileDependents={tcProfileDependents}
                                            tcProfileSleepTime={tcProfileSleepTime}
                                            tcProfileWorkSchemes={tcProfileWorkSchemes}
                                            setShowForm={setShowForm}
                                            personalInfoObj={personalInfoObj}
                                            setPersonalInfoObj={setPersonalInfoObj}
                                            handleOnSubmitForm={handleOnSubmitForm}
                                        />
                                    )}
                                    {showForm === 3 && (
                                        <JobInfoForm
                                            tcProfileHiring={tcProfileHiring}
                                            tcProfileAntiquity={tcProfileAntiquity}
                                            tcProfileStaffType={tcProfileStaffType}
                                            tcProfileWorkExperience={tcProfileWorkExperience}
                                            tcProfileTypeOfDay={tcProfileTypeOfDay}
                                            tcProfileCompensation={tcProfileCompensation}
                                            setShowForm={setShowForm}
                                            jobInfoObj={jobInfoObj}
                                            setJobInfoObj={setJobInfoObj}
                                            handleOnSubmitForm={handleOnSubmitForm}
                                        />
                                    )}
                                    {showForm === 4 && (
                                        <SecurityForm
                                            setShowForm={setShowForm}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {showMessage && (
                            <Message
                                setShowMessage={setShowMessage}
                            />
                        )}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default ProfileController
