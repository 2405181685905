import React from 'react'

const CultureStructureSection = ({
    section,
    handleChange,
}) => {
    const handleColorSection = sectionId =>{
        let color = ''
        switch (sectionId) {
            case 1:
                color = 'bg-green-feel'
                break;
            case 2:
                color = 'bg-yellow-grow'
                break;
            case 3:
                color = 'bg-header-swal'
                break;
            case 4:
                color = 'bg-pink-mine'
                break;
            default:
                break;
        }
        return color
    }
  return (
    <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto mt-1">
        {
            section.map((sectionData) => (
                <div className="row" key={sectionData.idsection}>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-3">
                        <span className="text-base font-weight-bold">{sectionData.description}</span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 mt-1">
                        {
                            sectionData.questions.map((question) => (
                                <div className="card shadow-card-1" id={question.idquestion} key={question.idquestion}>
                                    <div className={`card-header py-1 ${handleColorSection(sectionData.idsection)}`}>
                                        <span>{question.description}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row">
                                                {
                                                    question.answers.map((answer) => (
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 padding-small-2" key={question.idquestion + "-" + answer.idanswer}>
                                                            {answer.check === 1
                                                                ?
                                                                (
                                                                    <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={(e) => handleChange(e, sectionData.idsection, question.idquestion, answer.idanswer)} ><i className="ft ft-check-circle text-base-guay align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                                )
                                                                :
                                                                (
                                                                    <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={(e) => handleChange(e, sectionData.idsection, question.idquestion, answer.idanswer)} ><i className="ft ft-circle text-base align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))
        }
    </div>
  )
}

export default CultureStructureSection