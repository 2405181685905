import React, { useState, useEffect } from "react";
import $ from "jquery";
import Header from '../../NewHome/headerAdministrador';
import Menu from "../../Menu";
import { getJwt } from "../../../../lib/auth";
import { updateLogoGroupEnte, getDatagroupById, updateEnterpriseGroup } from "../Model";
import { getAddressByCP } from "../../Models/Catalog_model";
import { validateFile } from "../../../../lib/utils/validateFile";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { remaining_character } from "../../../../lib/utils/generalFuntions";
import { connect } from 'react-redux';
import { validateAccess, getSubsection } from "../../../helpers/validations";
import { useHistory } from "react-router-dom";

const Profile = props => {
    //const [enterpriseGroup, upEnterprisesGroup] = useState([]);
    const [stepNum, setStep] = useState(1);
    // eslint-disable-next-line
    const [group, upGroup] = useState({
        groupId: ""
    });
    const [enterpriseGroup, upEnterprisesGroup] = useState({
        userId: getJwt("jtw"),
        comercialName: "",
        legalName: "",
        shortName: "",
        description: "",
        logo: "",
        state: 0,
        town: 0,
        neibg: 0,
        zipCode: 0,
        street: "",
        ext_num: "",
        int_num: "",
        mision: "",
        vision: "",
        objetivos: "",
        valores: "",
        status: 0,
        active: 0,
        options: 0,
        groupId: 0,
        rfc: "",
        neigName: "",
        stateName: "",
        towName: ""
    });

    const history = useHistory();

    const [justRead, setJustRead] = useState(false)

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && props.access.list.length > 0) {
            //console.log(props.access)
            //console.log(validateAccess(props.access.list, 4))
            const section = validateAccess(props.access.list, 4)
            //console.log(getSubsection(section[0].subSection, 24))
            const subsection = getSubsection(section[0].subSection, 24)
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            if (subsection[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            if (subsection[0].id_action === 2) {
                setJustRead(true)
            }
        }
        if (getJwt('rol') === '3') {
            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
            history.push("/inicio-colaborador")
        }
    }, [props.access, history])

    useEffect(() => {
        const getObject = props.match.params;
        let object = getObject.db.split("=");
        var id = object[1];
        getDatagroupById(id).then((result) => {
            upEnterprisesGroup({
                ...enterpriseGroup,
                comercialName: "" + result.comercialName + "",
                legalName: "" + result.legalName + "",
                shortName: "" + result.shortName + "",
                description: "" + result.description + "",
                logo: "" + result.logo + "",
                state: result.stateId,
                town: result.townId,
                neibg: result.neighborhoodId,
                zipCode: result.zipCode === 0 ? "" : result.zipCode,
                street: "" + result.street == null ? "" : result.street + "",
                ext_num: "" + result.ext_num == null ? "" : result.ext_num + "",
                int_num: "" + result.int_num == null ? "" : result.int_num + "",
                mision: result.mision === null ? "" : "" + result.mision + "",
                vision: result.vision === null ? "" : "" + result.vision + "",
                objetivos: result.objetivos === null ? "" : "" + result.objetivos + "",
                valores: result.valores === null ? "" : "" + result.valores + "",
                groupId: result.groupId,
                rfc: result.rfc === null ? "" : "" + result.rfc + "",
            });
            if (result.zipCode > 0) {
                getAddressByCP(result.zipCode).then((respAddress) => {
                    $.each(respAddress, function (i, item) {
                        if (respAddress[i].idNeighborhood === result.neighborhoodId) {

                            enterpriseGroup.neigName = respAddress[i].nameNeighborhood;
                            enterpriseGroup.stateName = respAddress[i].nameState;
                            enterpriseGroup.towName = respAddress[i].nameTown;

                            upEnterprisesGroup({
                                ...enterpriseGroup,
                                comercialName: "" + result.comercialName + "",
                                legalName: "" + result.legalName + "",
                                shortName: "" + result.shortName + "",
                                description: "" + result.description + "",
                                logo: "" + result.logo + "",
                                state: result.stateId,
                                town: result.townId,
                                neibg: result.neighborhoodId,
                                zipCode: result.zipCode === 0 ? "" : result.zipCode,
                                street: "" + result.street == null ? "" : result.street + "",
                                ext_num: "" + result.ext_num == null ? "" : result.ext_num + "",
                                int_num: "" + result.int_num == null ? "" : result.int_num + "",
                                mision: result.mision === null ? "" : "" + result.mision + "",
                                vision: result.vision === null ? "" : "" + result.vision + "",
                                objetivos: result.objetivos === null ? "" : "" + result.objetivos + "",
                                valores: result.valores === null ? "" : "" + result.valores + "",
                                groupId: result.groupId,
                                rfc: result.rfc === null ? "" : "" + result.rfc + "",
                                neigName: respAddress[i].nameNeighborhood,
                                stateName: respAddress[i].nameState,
                                towName: respAddress[i].nameTown,

                            });


                        }
                    });
                });
                $("#zipCode").val(enterpriseGroup.zipCode);
            }

        });

        // eslint-disable-next-line
    }, []);
    const onChangeHandlerGroup = (e) => {
        try {
            let file = e.target.files[0];
            validateFile(file).then((res) => {
                if (res.success === 1) {
                    let reader = new FileReader();
                    group.groupId = enterpriseGroup.groupId;
                    reader.onload = function (e) {
                        $('#img-person').attr('src', e.target.result);
                    };
                    reader.readAsDataURL(file);
                    let formData = new FormData();
                    formData.append("file", file)
                    formData.append("group", JSON.stringify(group));
                    updateLogoGroupEnte(formData).then((res) => {
                        if (res.success) {
                            toastr.success("Se actualizó correctamente el logo del Grupo Organizacional.", "¡Exito!");

                        } else {
                            toastr.error("Ocurrió un problema con la actualización. Intente nuevamente.", "¡Error!");
                        }
                    });
                } else {
                    toastr.error("" + res.msg + "");
                }
            });

        } catch (error) {
            console.log(error);
        }
    }
    const renderFilePers = () => {
        $("#file").click();
    }
    const getValue = (e) => {
        let value = e.target.value;

        if (e.target.getAttribute("add-count") === 1) {
            let inpID = e.target.id;
            let maxLength = e.target.getAttribute("data-max-length");
            let eCount = e.target.getAttribute("element-name-character-count");
            remaining_character(value.length, inpID, maxLength, eCount);
        }
        switch (e.target.name) {
            case "zipCode":
                if (value.length + 0 >= 5) {
                    $("#zipCode").addClass("border-success");
                    $("#zipCode").removeClass("border-danger-guay");
                    getAddressByCP(value).then((respAddress) => {
                        if (respAddress.length > 0) {
                            $("#state, #town, #neibg").html("");
                            $("#state").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                            $("#town").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                            $("#town").addClass("border-success");
                            $("#town").removeClass("border-danger-guay");
                            $("#state").addClass("border-success");
                            $("#state").removeClass("border-danger-guay");
                            $("#neibg").addClass("border-success");
                            $("#neibg").removeClass("border-danger-guay");
                            $.each(respAddress, function (i, item) {
                                $("#neibg").append('<option value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                $("#neibg").attr("disabled", false);
                            });
                            upEnterprisesGroup({
                                ...enterpriseGroup,
                                zipCode: value,
                                state: respAddress[0].idState,
                                town: respAddress[0].idTown,
                                neibg: respAddress[0].idNeighborhood,
                                street: $("#street").val(),
                                complete: 3
                            });
                        } else {
                            toastr.warning("El código postal registrado no existe. Intenta nuevamente");
                        }
                    });
                } else {
                    const select = "--Seleccione--";
                    const optionCero = 0;
                    upEnterprisesGroup({
                        ...enterpriseGroup,
                        zipCode: value,
                        state: 0,
                        town: 0,
                        neibg: 0,
                        complete: 3
                    });
                    $("#state, #town, #neibg").html("");
                    $("#state").append('<option value="' + optionCero + '">' + select + '</option>');
                    $("#town").append('<option value="' + optionCero + '">' + select + '</option>');
                    $("#neibg").append('<option value="' + optionCero + '">' + select + '</option>');
                }
                break;
            case "rfc":
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprisesGroup({
                        ...enterpriseGroup,
                        [e.target.name]: "",
                    });
                }else if (e.target.value.length < 12 || e.target.value.length > 13){
                    $("#" + e.target.name).addClass("border-danger-guay");
                    $("#" + e.target.name).removeClass("border-success");
                    upEnterprisesGroup({
                        ...enterpriseGroup,
                        [e.target.name]: e.target.value,
                    });
                }else{
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprisesGroup({
                        ...enterpriseGroup,
                        [e.target.name]: e.target.value,
                    });
                }
                break;
            default:
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).removeClass("border-success");
                    $("#" + e.target.name).addClass("border-danger-guay");
                    upEnterprisesGroup({
                        ...enterpriseGroup,
                        [e.target.name]: "",
                    });
                } else {
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#fiscalRegimenLabel").removeClass("text-base-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprisesGroup({
                        ...enterpriseGroup,
                        [e.target.name]: e.target.value,
                    });
                }
                break;
        }
    }

    const saveRegister = () => {
        try {

            // eslint-disable-next-line
            console.log(stepNum);
            switch (stepNum) {
                case 1:
                case "1":
                    // if (enterpriseGroup.comercialName.length === 0 ||
                    //     enterpriseGroup.shortName.length === 0 ||
                    //     enterpriseGroup.legalName.length === 0 ||
                    //     enterpriseGroup.description.length === 0) {
                    //     toastr.error("Todos los campos son obligatorios", "¡Espera");
                    //     return false;
                    // }
                    if(enterpriseGroup.comercialName.length === 0){
                        toastr.error("El campo Nombre Comercial es obligatorio", "¡Espera");
                        return false;
                    }
                    if(enterpriseGroup.shortName.length === 0){
                        toastr.error("El campo Nombre Corto es obligatorio", "¡Espera");
                        return false;
                    }
                    if(enterpriseGroup.legalName.length === 0){
                        toastr.error("El campo Nombre Legal es obligatorio", "¡Espera");
                        return false;
                    }
                    if(enterpriseGroup.description.length === 0){
                        toastr.error("El campo Descripción es obligatorio", "¡Espera");
                        return false;
                    }
                    if (enterpriseGroup.rfc.length > 0 && (enterpriseGroup.rfc.length > 13 || enterpriseGroup.rfc.length < 12)) {
                        validateMessage("#rfc", 'El campo: RFC tiene que tener como mínimo 12 caracteres y máximo 13.');
                        return false;
                    }

                    break;
                case 2:
                case "2":
                    // if (enterpriseGroup.mision.length === 0 ||
                    //     enterpriseGroup.vision.length === 0 ||
                    //     enterpriseGroup.valores.length === 0 ||
                    //     enterpriseGroup.objetivos.length === 0) {
                    //     toastr.error("Todos los campos son obligatorios", "¡Espera");
                    //     return false;
                    // }
                    // if (enterpriseGroup.valores.length === 0) {
                    //     enterprise["valores"] = "a";
                    // }

                    break;
                case 3:
                case "3":
                    let count = 0;

                    if (enterpriseGroup.rfc.length === 0) {
                        validateMessage("#rfc", 'Falta llenar el campo: RFC');
                        count++;
                    }

                    console.log(enterpriseGroup.zipCode);
                    if (enterpriseGroup.zipCode.length === 0) {

                        validateMessage("#zipCode", 'Falta llenar el campo: Código Postal');
                        count++;
                    }
                    if (enterpriseGroup.street.length === 0) {
                        validateMessage("#street", 'Falta llenar el campo: Calle');
                        count++;
                    }
                    if (enterpriseGroup.ext_num.length === 0) {
                        validateMessage("#ext_num", 'Falta llenar el campo: Núm. exterior');
                        count++;
                    }

                    if (count > 0) {
                        return;
                    }
                    break;
                default:
                    break;
            }
            enterpriseGroup.userId = "" + getJwt("jtw") + "";
            let data = {
                ...enterpriseGroup,
                zipCode: enterpriseGroup.zipCode === "" ? (0) : (enterpriseGroup.zipCode)
            }
            let formData = new FormData();
            formData.append("group", JSON.stringify(data));
            updateEnterpriseGroup(formData).then((res) => {
                if (res.success === 1) {
                    toastr.success("Se actualizó la información correctamente", "¡Éxito!");
                } else {
                    toastr.error("Ocurrió un problema al actualizar la información", "¡Espera");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    const validateMessage = (element, msg) => {
        setTimeout(function () {
            $(element).addClass("border-danger-guay");
            toastr.error(msg, '¡Espera!');
        }, 500);
        return;
    }

    const updateStep = (event) => {
        try {
            let $currentTarget = $(event.currentTarget);
            let step = $currentTarget.attr("data-step");
            setStep(step);
            console.log(step);
            $(".list-group-item").removeClass("active");
            $('.list-group-item[data-step="' + step + '"]').addClass("active");
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay"></div>
            <Header />
            <Menu />
            <div className="content-wrapper bootomFooter">
                <div className="content-header row">
                    <div className="col-md-6"></div>
                    <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                        <div className="row breadcrumbs-top d-inline-block">

                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                                    </li>
                                    <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Grupo Organizacional</a>
                                    </li>
                                    <li className="breadcrumb-item active font-medium-3 active">{enterpriseGroup.comercialName}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-sx-12 col-sm-12 col-md-1 col-lg-2 mb-40"></div>
                        <div className="col-sx-12 col-sm-12 col-md-10 col-lg-9 mb-40">
                            <div className="row mb-1 ">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                    <h3 className="font-weight-bold">Grupo Organizacional / {enterpriseGroup.comercialName}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <ul className="list-group enterprise-list">
                                            <li className="list-group-item active pointer" data-step="1" onClick={updateStep}>
                                                {/* <span className="badge badge-secondary badge-pill float-left mt-1p font-medium-2">1</span> */}
                                                <span className="ml-1 font-medium-2">Datos Generales</span>
                                            </li>
                                            <li className="list-group-item pointer" data-step="2" onClick={updateStep}>
                                                {/* <span className="badge badge-secondary badge-pill float-left mt-1p font-medium-2">2</span> */}
                                                <span className="ml-1 font-medium-2">Datos Especificos</span>
                                            </li>
                                            {/* <li className="list-group-item pointer" data-step="3" onClick={updateStep}> */}
                                                {/* <span className="badge badge-secondary badge-pill float-left mt-1p font-medium-2">3</span> estaba comentado */}
                                                {/* <span className="ml-1 font-medium-2">Datos Facturación</span> */}
                                            {/* </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="card">
                                        <form>
                                            {
                                                stepNum === 1 || stepNum === "1" ?
                                                    <div className="row">
                                                        {
                                                            justRead ? (
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                                    {enterpriseGroup.logo ?
                                                                        <img
                                                                            src={enterpriseGroup.logo}
                                                                            className="img-responsive-group mt-1 pointer"
                                                                            alt=""
                                                                            id="img-person"
                                                                        ></img>
                                                                        :
                                                                        <img
                                                                            src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                            id="img-person"
                                                                            alt="empresa"
                                                                            className="pointer img-responsive-group mt-1 pull-up"
                                                                        />
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                                    <input type="file" id="file" className="hidden" onChange={onChangeHandlerGroup} />
                                                                    {enterpriseGroup.logo ?
                                                                        <img src={enterpriseGroup.logo} className="img-responsive-group mt-1 pointer" alt=""
                                                                            onClick={renderFilePers} id="img-person"
                                                                        ></img>
                                                                        :
                                                                        <img src="./../img/altaEmpresas/iconos_shopy_img-31.png" id="img-person" alt="empresa"
                                                                            className="pointer img-responsive-group mt-1 pull-up" onClick={renderFilePers} />
                                                                    }
                                                                    <br />
                                                                    <button type="button"
                                                                        className="btn btn-outline-secondary btn-min-width mr-1 mb-1 mt-1 font-small-3" onClick={renderFilePers}>Actualizar Logo</button>
                                                                </div>
                                                            )
                                                        }
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div className="col-sx-12 col-sm-12 col-md-9 col-lg-9 ml-4">
                                                                <div className="form-group mt-1">
                                                                    <label >Nombre Comercial:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="comercialName"
                                                                        name="comercialName"
                                                                        maxLength="100"
                                                                        minLength="2"
                                                                        onChange={getValue}
                                                                        value={enterpriseGroup.comercialName}
                                                                        disabled={justRead}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group ">
                                                                    <label >Nombre Corto:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="shortName"
                                                                        name="shortName"
                                                                        maxLength="60"
                                                                        minLength="2"
                                                                        onChange={getValue}
                                                                        value={enterpriseGroup.shortName}
                                                                        disabled={justRead}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group ">
                                                                    <label >Nombre Legal:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="legalName"
                                                                        name="legalName"
                                                                        maxLength="200"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                        value={enterpriseGroup.legalName}
                                                                        disabled={justRead}
                                                                    ></input>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Descripción:</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="description"
                                                                        name="description"
                                                                        maxLength="200"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                        value={enterpriseGroup.description}
                                                                        disabled={justRead}
                                                                    ></input>
                                                                </div>
                                                                <div lassName="form-group">
                                                                    <label>RFC</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        id="rfc"
                                                                        name="rfc"
                                                                        maxLength="13"
                                                                        minLength="12"
                                                                        placeholder="RFC"
                                                                        onChange={getValue}
                                                                        value={enterpriseGroup.rfc}
                                                                        disabled={justRead}
                                                                    ></input>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // eslint-disable-next-line
                                                    : stepNum === 2 || stepNum == "2" ?
                                                        <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">

                                                            <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">
                                                                <div className="form-group mt-1">
                                                                    <label>Misión: </label>
                                                                    <textarea id="mision"
                                                                        rows="3"
                                                                        className="form-control"
                                                                        name="mision"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                        value={enterpriseGroup.mision !== "null" ? enterpriseGroup.mision : ""}
                                                                        add-count="1"
                                                                        data-max-length="500"
                                                                        element-name-character-count="character-count-mision"
                                                                        disabled={justRead}
                                                                    >
                                                                    </textarea>
                                                                    <p id="character-count-mision" className="small" align="right">{
                                                                        // eslint-disable-next-line
                                                                        enterpriseGroup.mision.length
                                                                            // eslint-disable-next-line
                                                                            && enterpriseGroup.mision != null
                                                                            // eslint-disable-next-line
                                                                            && enterpriseGroup.mision != "null"
                                                                            // eslint-disable-next-line
                                                                            > 0 ? enterpriseGroup.mision.length : "0"}/500</p>
                                                                </div>
                                                                <div className="form-group mt-1">
                                                                    <label>Visión: </label>
                                                                    <textarea id="vision"
                                                                        rows="3"
                                                                        className="form-control"
                                                                        name="vision"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                        // eslint-disable-next-line
                                                                        value={enterpriseGroup.vision != "null" ? enterpriseGroup.vision : ""}
                                                                        add-count="1"
                                                                        data-max-length="500"
                                                                        element-name-character-count="character-count-vision"
                                                                        disabled={justRead}
                                                                    ></textarea>
                                                                    <p id="character-count-vision" className="small" align="right">{
                                                                        enterpriseGroup.vision.length
                                                                            && enterpriseGroup.vision != null
                                                                            // eslint-disable-next-line
                                                                            && enterpriseGroup.vision != "null"
                                                                            // eslint-disable-next-line
                                                                            > 0 ? enterpriseGroup.vision.length : "0"}/500</p>
                                                                </div>
                                                                <div className="form-group mt-1">
                                                                    <label>Objetivos: </label>
                                                                    <textarea id="objetivos"
                                                                        rows="3"
                                                                        className="form-control"
                                                                        name="objetivos"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                        // eslint-disable-next-line
                                                                        value={enterpriseGroup.objetivos != "null" ? enterpriseGroup.objetivos : ""}
                                                                        add-count="1"
                                                                        data-max-length="500"
                                                                        element-name-character-count="character-count-objetivos"
                                                                        disabled={justRead}
                                                                    ></textarea>
                                                                    <p id="character-count-objetivos" className="small" align="right">{
                                                                        enterpriseGroup.objetivos.length
                                                                            // eslint-disable-next-line
                                                                            && enterpriseGroup.objetivos != null
                                                                            // eslint-disable-next-line
                                                                            && enterpriseGroup.objetivos != "null"
                                                                            // eslint-disable-next-line
                                                                            > 0 ? enterpriseGroup.objetivos.length : "0"}/500</p>
                                                                </div>
                                                                <div className="form-group mt-1">
                                                                    <label>Valores: </label>
                                                                    <textarea id="valores"
                                                                        rows="3"
                                                                        className="form-control"
                                                                        name="valores"
                                                                        minLength="4"
                                                                        onChange={getValue}
                                                                        // eslint-disable-next-line
                                                                        value={enterpriseGroup.valores != "null" ? enterpriseGroup.valores : ""}
                                                                        add-count="1"
                                                                        data-max-length="500"
                                                                        element-name-character-count="character-count-valores"
                                                                        disabled={justRead}
                                                                    ></textarea>
                                                                    <p id="character-count-valores" className="small" align="right">{
                                                                        enterpriseGroup.valores.length
                                                                            && enterpriseGroup.valores != null
                                                                            // eslint-disable-next-line
                                                                            && enterpriseGroup.valores != "null"
                                                                            // eslint-disable-next-line
                                                                            > 0 ? enterpriseGroup.valores.length : "0"}/500</p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        // eslint-disable-next-line
                                                        : stepNum === 3 || stepNum == "3" ?
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                                <div className="row">
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                        {/* <div className="form-group mt-1 ">
                                                                            <label>RFC</label>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="text"
                                                                                id="rfc"
                                                                                name="rfc"
                                                                                maxLength="13"
                                                                                minLength="13"
                                                                                placeholder="RFC"
                                                                                onChange={getValue}
                                                                                value={enterpriseGroup.rfc}
                                                                                disabled={justRead}
                                                                            ></input>
                                                                        </div> */}
                                                                        <div className="form-group">
                                                                            <label>Código Postal: </label>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="text"
                                                                                id="zipCode"
                                                                                name="zipCode"
                                                                                placeholder="Código Postal"
                                                                                onChange={getValue}
                                                                                value={enterpriseGroup.zipCode}
                                                                                disabled={justRead}
                                                                            ></input>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Colonia:</label>
                                                                            <select
                                                                                className="form-control form-control-sm"
                                                                                name="neibg"
                                                                                id="neibg"
                                                                                disabled
                                                                                onChange={getValue}
                                                                            >
                                                                                <option value={enterpriseGroup.neibg}>{enterpriseGroup.neigName}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group mb-1">
                                                                            <label>Núm. exterior:</label>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="text"
                                                                                id="ext_num"
                                                                                name="ext_num"
                                                                                maxLength="50"
                                                                                minLength="2"
                                                                                placeholder="Número Exterior"
                                                                                onChange={getValue}
                                                                                value={enterpriseGroup.ext_num}
                                                                                disabled={justRead}
                                                                            ></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mt-1">

                                                                        <div className="form-group">
                                                                            <label>Estado:</label>
                                                                            <select
                                                                                className="form-control form-control-sm"
                                                                                name="state"
                                                                                id="state"
                                                                                disabled>
                                                                                <option value={enterpriseGroup.stateName}>{enterpriseGroup.stateName}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label >Municipio/Alcaldía:</label>
                                                                            <select
                                                                                className="form-control form-control-sm"
                                                                                name="town"
                                                                                id="town"
                                                                                disabled>
                                                                                <option value={enterpriseGroup.town}>{enterpriseGroup.towName}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Calle:</label>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="text"
                                                                                id="street"
                                                                                name="street"
                                                                                maxLength="150"
                                                                                minLength="2"
                                                                                placeholder="Calle"
                                                                                onChange={getValue}
                                                                                value={enterpriseGroup.street}
                                                                                disabled={justRead}
                                                                            ></input>
                                                                        </div>
                                                                        <div className="form-group mb-1">
                                                                            <label>Núm. Interior (opcional):</label>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="text"
                                                                                id="int_num"
                                                                                name="int_num"
                                                                                maxLength="50"
                                                                                minLength="2"
                                                                                placeholder="Número Interior"
                                                                                onChange={getValue}
                                                                                value={enterpriseGroup.int_num}
                                                                                disabled={justRead}
                                                                            ></input>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                            }
                                        </form>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mx-auto d-flex justify-content-center mt-2 mb-3">
                                            {
                                                !justRead && <button className="btn btn-green font-medium-1" onClick={() => saveRegister()}>Guardar Cambios</button>
                                            }
                                            {/*<button className="btn btn-green btn-sm w-25 float-right" onClick={stepNum === 3 ? () => setNewEnterprise() : () => handleOnClickNext()}>{stepNum === 3 ? 'Terminar' : 'Continuar'}</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ access }) => {
    return {
        access,
    }
}

export default connect(mapStateToProps)(Profile);