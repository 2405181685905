import React from 'react';
import { Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Welcome from "./components/web/Welcome";
import Register from "./components/web/register/Register";
import ValidataSms from "./components/web/register/Validate-sms";
import ActivateUserShop from "./components/web/register/Activate-user-shopify";
import Panel from "./components/app/Panel";
import RegisterUsers from "./components/app/enterprises/Register_users";
import ForgottenPassword from './components/web/register/Forgotten-password';
import AssignmentUser from './components/app/enterprises/Assignment_user';
import ErrorPage from "./components/app/ErrorPage";
import ResetPassword from './components/web/register/ResetPassword';
import ControlSurvey from './components/app/htk/ControlSurvey';
import Redirect from './components/app/Redirect';
import MainPage from './components/app/NewHome/MainPage';
import EmpresasPanel from './components/app/enterprises/EmpresasPanel';
import RegisterEnterprise from './components/app/enterprises/RegisterEnterprise';
import DatosGenerales from './components/app/enterprises/datosGenerales';
import AdminPanel from './components/app/NewHome/adminPanel';
/* import AdministracionUsuarios from './components/app/enterprises/adminUsuarios'; */
import InicioColaborador from './components/app/NewHome/inicioColaborador';
import InicioAdministrador from './components/app/enterprises/InicioAdministrador_2';
import InicioUsuarioGenerico from './components/app/NewHome/inicioUsuarioGenerico';
import RegGroup from './components/app/enterprises/Register_group';
import Home from './components/app/Shop/Home';
/* import MyProfile from './components/app/NewHome/myProfile'; */
import ActivateEmail from './components/app/NewHome/activateEmail';
import AdminCollab from './components/app/enterprises/collab/Admin_collab';
import AdminServices from './components/app/services/AdminServices';
import ErrorLoadingPage from './components/app/ErrorLoadingPage';
import Enterprises from './components/app/enterprises/groups/Enterprises';
import Profile from './components/app/enterprises/groups/Profile';
import RegisterEnterpriseGroup from './components/app/enterprises/groups/RegisterEnterprise';
import Activate_account from './components/web/register/Activate_account';
import UsersGroupEnterprise from './components/app/enterprises/groups/Users';
import Calendar from './components/app/enterprises/comunication/Calendar';
import DocumentManagementController from './components/app/documentManagement/DocumentManagementController';
import ATSController from './components/app/surveys/ATSController';
import EntornoLaboral from './components/app/surveys/EntornoLaboral';
import FiltroClinico from './components/app/surveys/FiltroClinico';
import DemographicController from './components/app/surveys/Demographic/DemographicController';
import DemographicClimate from './components/app/surveys/Demographic/DemographicClimate';
import GuaykipediaController from './components/app/guaykipedia/GuaykipediaController';
import ClimateController from './components/app/surveys/Climate/ClimateController';
import AppShop from './components/app/Shop/AppShop';
import AppBenefits from './components/app/Shop/AppBenefits';
import SupportPage from './components/app/SupportPage';
import KioskController from './components/app/kiosk/KioskController';
import Apple_callback from './components/auth/Apple_callback';
import StatisticalFilterController from './components/app/surveys/StatisticalFilter/StatisticalFilterController';
import ResultController from './components/app/ResultComponents/ResultController';
import ActionController from './components/app/actions/ActionController';
import ActMonCollabForm from './components/app/actions/actionsMonitoring/ActMonCollabForm';
import AnaliticsController from './components/app/ResultComponents/AnaliticsController';
import Application from './components/app/Shop/Commercial/Application';
import Micro from './components/app/administration/micro/Micro';
import { Docs } from './components/app/administration/micro/Docs';
import { IframeOut } from './components/app/administration/micro/IframeOut';
import RegisterFreemium from './components/app/registerFreemium/RegisterFreemium';
/* import Advertising from './components/app/advertising/Advertising'; */
import PromotionsIndex from './components/app/promotions/PromotionsIndex';
import Webinar from './components/app/webinar/Webinar';
import FreeLanding from './components/app/FreeLanding';
import Benefits from './components/app/benefits/Benefits';
import MonitoreoMail from './components/app/MailGun/MonitoreoMail';
import StatusController from './components/app/Shop/Commercial/StatusStructure/StatusController';
import Monitoreo from './components/app/MailGun/monitoreo/Monitoreo';
import AdminUsrController from './components/app/enterprises/adminUsr/AdminUsrController';
import SurveyController from './components/app/SurveyController';
import ProfileController from './components/app/NewHome/profile/ProfileController';
import ReenvioFichas from './components/app/services/ReenvioFichas';
import DownSrcController from './components/app/downloadResources/DownSrcController';
import AssingCollabsLauncCtrl from './components/app/assingCollabsLaunch/AssingCollabsLauncCtrl';
import AgendarCitaForm from './components/app/Beneficios/AgendarCitaForm';
import CultureController from './components/app/surveys/Culture/CultureController';
import DemographicCulture from './components/app/surveys/Demographic/DemographicCulture';
import ClinicalFilter from './components/app/surveys/ClinicalFilterHTK/ClinicalFilter';
import HealthTracking from './components/app/surveys/ClinicalFilterHTK/HealthTracking';
import Habits from './components/app/surveys/ClinicalFilterHTK/Habits';
import Diagnosis from './components/app/surveys/ClinicalFilterHTK/Diagnosis';


export const AdminRouters = () => {
    return (
        <Switch>
            <Route path="/" component={Welcome} exact />
            <Route path="/acceso" component={Login} exact />
            <Route path="/acceso/recuperar-contrasena" component={ForgottenPassword} exact />
            <Route path="/activa-usuario/:db" component={Activate_account} exact />
            <Route path="/registro" component={Register} exact />
            <Route path="/validar-cuenta-sms/:db" component={ValidataSms} exact />
            <Route path="/activa-cuenta-web/:db" component={ActivateUserShop} exact />
            <Route path="/activa-email-web/:db" component={ActivateEmail} exact />
            <Route path="/inicio" component={Panel} exact />
            <Route path="/inicio-usuario" component={InicioUsuarioGenerico} exact />
            <Route path="/inicio-tienda" component={MainPage} exact />
            <Route path="/inicio-empresas" component={EmpresasPanel} exact />
            <Route path="/inicio-empresas/registro-empresa" component={RegisterEnterprise} exact />
            <Route path="/perfil-empresa/:db" component={DatosGenerales} exact />
            <Route path="/inicio-empresas/administracion-empresas" component={AdminPanel} exact />
            <Route path="/administracion-usuarios" component={AdminUsrController} exact />
            <Route path="/inicio-empresas/registra-grupo" component={RegGroup} exact />
            <Route path="/inicio-colaborador" component={InicioColaborador} exact />
            <Route path="/inicio-administrador" component={InicioAdministrador} exact />
            {/* <Route path="/perfil" component={MyProfile} exact /> */}
            <Route path="/perfil" component={ProfileController} exact />
            <Route path="/empresas/registro-usuarios" component={RegisterUsers} exact />
            <Route path="/empresas/asignacion-usuarios" component={AssignmentUser} exact />
            <Route path="/acceso/restablecer-contrasena/:db" component={ResetPassword} exact />
            <Route path="/htk/:db" component={ControlSurvey} exact />
            <Route path="/redirect/:db" component={Redirect} exact />
            <Route path="/tienda" component={Home} exact />
            <Route path="/administracion-colaboradores" component={AdminCollab} exact />
            <Route path="/administracion-servicios" component={AdminServices} exact />
            <Route path="/gestion-documental" component={DocumentManagementController} exact />
            <Route path="/grupo-empresarial/:db" component={Profile} exact />
            <Route path="/grupo-empresas/:db" component={Enterprises} exact />
            <Route path="/registra-organizacion/:db" component={RegisterEnterpriseGroup} exact />
            <Route path="/usuarios-grupo-org/:db" component={UsersGroupEnterprise} exact />
            <Route path="/estadoemocional/:db" component={ATSController} exact />
            <Route path="/demograficoClima/:db" component={DemographicClimate} exact />
            <Route path="/demograficoCultura/:db" component={DemographicCulture} exact />
            <Route path="/culturaorganizacional/:db" component={CultureController} exact />
            <Route path="/demografico/:db" component={DemographicController} exact />
            <Route path="/entornolaboral/:identity" component={EntornoLaboral} exact />
            <Route path="/filtroClinico/:identity" component={FiltroClinico} exact />
            <Route path="/filtro-Clinico/:identity" component={ClinicalFilter} exact />
            <Route path="/health-tracking/:identity" component={HealthTracking} exact />
            <Route path="/habits/:identity" component={Habits} exact />
            <Route path="/diagnosis/:identity" component={Diagnosis} exact />
            <Route path="/comunicacion" component={Calendar} exact />
            <Route path="/guaykipedia" component={GuaykipediaController} exact />
            <Route path="/app-shop" component={AppShop} exact />
            <Route path="/app-benefits" component={AppBenefits} exact />
            <Route path="/support" component={SupportPage} exact />
            <Route path="/climalaboral/:db" component={ClimateController} exact />
            <Route path="/centralSurvey/:db" component={KioskController} exact />
            <Route path="/filtroEstadistico/:db" component={StatisticalFilterController} exact />
            <Route path="/error-loading-page" component={ErrorLoadingPage} exact />
            <Route path="/apple/callback" component={Apple_callback} exact />
            <Route path="/resultados" component={AnaliticsController} exact />
            <Route path="/cumplimiento" component={ResultController} exact />
            <Route path="/acciones" component={ActionController} exact />
            <Route path="/colaborador-tracking" component={ActMonCollabForm} exact />
            <Route path="/solicitud-comercial" component={Application} exact />
            <Route path="/webinar" component={Webinar} exact />
            <Route path="/microstrategy-dossier" component={Micro} exact />
            <Route path="/microstrategy-prompts" component={Docs} exact />
            <Route path="/microstrategy-docs" component={IframeOut} exact />
            <Route path="/registro-freemium" component={RegisterFreemium} exact />
            {/* <Route path="/promociones" component={Advertising} exact /> */}
            <Route path="/promociones" component={PromotionsIndex} exact />
            <Route path="/free-landing" component={FreeLanding} exact />
            <Route path="/beneficios" component={Benefits} exact />
            <Route path="/monitor-mail" component={MonitoreoMail} exact/>
            <Route path="/estatus-solicitud" component={StatusController} exact />
            <Route path="/monitoreo" component={Monitoreo} exact/>
            <Route path="/encuestas" component={SurveyController} exact/>
            <Route path="/reenvio-fichas" component={ReenvioFichas} exact />
            <Route path="/recursosDescargables" component={DownSrcController} exact/>
            <Route path="/asignar-colaboradores-lanzamiento" component={AssingCollabsLauncCtrl} exact/>
            <Route path="/beneficios/agendar-cita" component={AgendarCitaForm} />
            {/* <Route path="/beneficios/agendar-cita/:db" component={AgendarCitaForm} /> */}

            <Route component={ErrorPage} exact />
        </Switch>
    )
}