import axios from "axios";

/**
 * Regresa el listado de analiticos
 * @param {*} enterpriseId 
 * @returns object
 */
export const getOutcomeByEnterprise = async (enterpriseId, userId) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `outcome/getOutcomeByEnterprise?idEnterprise=${enterpriseId}&idUser=${userId}`,
            method: 'GET'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};