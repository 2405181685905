/*
 File Name: TrackingModel.js
 Description: Manage Surveys API Requests
 guay dev's team
*/
import axios from "axios";
/**
 * 
 * @param {int} idEnterprise 
 * @returns 
 */
export const getTrackingByGuay = async (idEnterprise) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "tracking/getTrackingByGuay?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idCollab 
 * @param {*} idPeriod 
 * @param {*} idGroup 
 * @returns 
 */
export const getSpecificTrackingByGuay = async (idEnterprise, idCollab, idPeriod, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "tracking/getSpecificTrackingByGuay?idEnterprise=" + idEnterprise + "&idCollab=" + idCollab + "&idPeriod=" + idPeriod + "&idGroup=" + idGroup + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para traer los colaboradores a canalizar y catalogos
 * @param {int || string} enterpriseId 
 * @param {int || string} idUser 
 * @param {int || string} idRol 
 * @returns JSON
 */
export const getCollabsToCanalization = async (enterpriseId, idUser, idRol) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: `${global.base_url}tracking/getCollabsToCanalization?idEnterprise=${enterpriseId}&idUser=${idUser}&idRol=${idRol}`,
            method: 'GET'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para canalizar colaboradores
 * @param {int} idEnterprise 
 * @param {array} listCollabs 
 * @param {int} idTypeCanalization 
 * @returns JSON response success || error
 */
export const setCollabsToCanalization = async (idEnterprise, listCollabs, idTypeCanalization, institution) => {
    const formdata = new FormData();
    formdata.append("idEnterprise", idEnterprise);
    if (institution !== undefined) {
        formdata.append("institution", institution);
    }
    formdata.append("listCollabs", listCollabs);
    formdata.append("idTypeCanalization", idTypeCanalization);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + 'tracking/setCollabsToCanalization',
            method: 'POST',
            data: formdata,
            redirect: 'follow'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Metodo para cancelar una canalizacion
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @returns 
 */
export const setCancelExerciseToCanalization = async (idEnterprise, idGroup) => {
    const data = new FormData();
    const request = {
        idGroup,
        idEnterprise
    };
    data.append('request', JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + 'tracking/setCancelExerciseToCanalization',
            method: 'POST',
            data,
            redirect: 'follow'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * 
 * @param {*} idEnterprise 
 * @returns 
 */
export const getTrackingOfBranchByOther = async (idEnterprise) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "tracking/getTrackingOfBranchByOther?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Metodo para cancelar una canalizacion
 * @param {int} idEnterprise 
 * @param {array} listCollabs 
 * @returns JSON response sucess || error
 */
export const setCancelCollabsToCanalization = async (idEnterprise, listCollabs) => {
    const formdata = new FormData();
    formdata.append("idEnterprise", idEnterprise);
    formdata.append("listCollabs", listCollabs);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + 'tracking/setCancelCollabsToCanalization',
            method: 'POST',
            data: formdata,
            redirect: 'follow'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idBranch 
 * @returns 
 */
export const getTrackingOfBranchMonitor = async (idEnterprise, idBranch) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "tracking/getTrackingOfBranchMonitor?idEnterprise=" + idEnterprise + "&idBranch=" + idBranch + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Metodo para obtener el resumen de la canalizacion
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @returns JSON Response {}
 */
export const getResumeCanalization = async (idEnterprise, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `tracking/getResumeCanalization?idEnterprise=${idEnterprise}&idGroup=${idGroup}`,
            method: 'GET',
            redirect: 'follow'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} closeDate 
 * @param {*} startDate 
 * @param {*} idAttention 
 * @param {*} diagnostic 
 * @param {*} commet 
 * @param {*} sessions 
 * @param {*} idPeriod 
 * @param {*} idCollab 
 * @param {*} idGroup 
 * @param {*} idEnterprise 
 * @returns 
 */
export const setTrackingOfCollabByOther = async (closeDate, startDate, idAttention, commet, sessions, address, curDate, idPeriod, idCollab, idGroup, idEnterprise) => {
    try {
        let formData = new FormData();
        let data = {
            closeDate: closeDate,
            startDate: startDate,
            idAttention: idAttention,
            commet: commet,
            sessions: sessions,
            address: address,
            curDate: curDate,
            idPeriod: idPeriod,
            idCollab: idCollab,
            idGroup: idGroup,
            idEnterprise: idEnterprise
        };
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "tracking/setTrackingOfCollabByOther",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Metodo para finalizar la canalizacion
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @returns JSON response success || error
 */
export const setFinishCanalization = async (idEnterprise, idGroup) => {
    const formdata = new FormData();
    const request = {
        idGroup,
        idEnterprise
    };
    formdata.append('request', JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + 'tracking/setFinishCanalization',
            method: 'POST',
            data: formdata,
            redirect: 'follow'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const setResetTrackingOfCollab = async (data) => {
    try {
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "tracking/setResetTrackingOfCollab",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * 
 * @param {*} idEnterprise 
 * @returns 
 */
export const getChanneledCollabsReportPDF = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + 'tracking/getChanneledCollabsReportPDF?idEnterprise=' + idEnterprise,
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: global.tokenAuth
            },
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idGroup 
 * @param {*} idCategory 
 * @param {*} idUser
 * @param {*} idRol
 * @returns 
 */
export const getGeneralTracking = async (idEnterprise, idGroup, idCategory, idUser, idRol) => {
    try {
        let result = await axios({
            url: `${global.base_url}tracking/getGeneralTracking?idEnterprise=${idEnterprise}&idGroup=${idGroup}&idCategory=${idCategory}&idUser=${idUser}&idRol=${idRol}`,
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idCollab 
 * @param {*} idPeriod 
 * @param {*} idGroup 
 * @returns 
 */
export const getSpecificTrackingByOther = async (idEnterprise, idCollab, idPeriod, idGroup) => {
    try {
        let result = await axios({
            url: global.base_url + 'tracking/getSpecificTrackingByOther?idEnterprise=' + idEnterprise + "&idCollab=" + idCollab + "&idPeriod=" + idPeriod + "&idGroup=" + idGroup + "",
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Create a pdf filtering by one or all parameters
 * @param {*} idEnterprise 
 * @param {*} idProduct 
 * @param {*} idGroup 
 * @returns 
 */
export const getChanneledCollabsReportPDFFiltered = async (idEnterprise, idProduct, idGroup, idUser, idRol) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: `${global.base_url}tracking/getChanneledCollabsReportPDF?idEnterprise=${idEnterprise}&idProduct=${idProduct}&idGroup=${idGroup}&idUser=${idUser}&idRol=${idRol}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
