import React from 'react';

const FreeLanding = () => {
    return (
        <div className="app-content content">
            <div className="content-overlay">
                <div className="content-wrapper">
                    <div className="content-header"></div>
                    <div className="content-body pt-5">
                        <div className='col-11 mx-auto mb-5'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-9 col-lg-9 d-flex flex-wrap align-content-center'>
                                    <div className='row'>
                                        <div className='col-12 mb-1'>
                                            <h2>Nuestro <span className='font-weight-bold'>plan gratuito</span> tienen como objetivo ayudar a las <span className='font-weight-bold'>PYMES</span> mexicanas para el cumplimiento de la <span className='font-weight-bold'>NOM-035-STPS</span>, con nuestra plataforma podrás:</h2>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-06.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-07.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-08.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-09.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-10.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-3 col-lg-3'>
                                    <img
                                        className='img-responsive'
                                        src="./../img/freeLanding/pantallas_dic_guay-15.png"
                                        alt='logo'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-2 col-lg-2'></div>
                                <div className='col-sm-12 col-md-2 col-lg-2'>
                                    <img
                                        src="./../img/freeLanding/pantallas_dic_guay-14.png"
                                        alt='logo'
                                        style={{
                                            position: 'absolute',
                                            height: '90%',
                                            zIndex: '1',
                                        }}
                                    />
                                </div>
                                <div className='col-sm-12 col-md-8 col-lg-8'>
                                    <div className='row'>
                                        <div className='col-10 mb-1'>
                                            <h3 className='text-base'>Nosotros te damos las herramientas necesarias para su aplicación y cuando concluyas tus evaluaciones obtendrás los siguientes beneficios:</h3>
                                        </div>
                                        <div className='col-10 mb-5'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-17.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-18.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <img
                                                        className='img-responsive'
                                                        src="./../img/freeLanding/pantallas_dic_guay-22.png"
                                                        alt='logo'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 py-5 mt-5'>
                                            <h3 className='font-weight-bold'>Todo esto y más lo encuentras en <img src='./../img/guay-logo.png' alt='logo' style={{ height: '1.51rem' }} /> registraté, descúbre y sorpréndete</h3>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='bg-blue-navy py-2 mt-5'
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        bottom: '0',
                                    }}
                                >
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center flex-wrap align-content-center'>
                                            <a
                                                className='text-decoration-none'
                                                target="_self"
                                                rel="noreferrer"
                                                href="/"
                                            ><span
                                                className='text-white pointer'
                                                style={{
                                                    textDecoration: 'underline'
                                                }}
                                            >Regresar</span></a>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'></div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 text-center'>
                                            <h3 className='text-white'>Todo esto y más lo encuentras en <img src='./../img/guay-logo.png' alt='logo' style={{ height: '1.51rem' }} /></h3>
                                            <h3 className='font-weight-bold text-white'>¡Registraté, descúbre y sorpréndete!</h3>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center flex-wrap align-content-center'>
                                            <a
                                                target="_self"
                                                rel="noreferrer"
                                                href="registro-freemium"
                                                className='w-75'
                                            ><button className='btn btn-sm btn-pink w-75'>Registro</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12 bg-blue-navy py-3'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center flex-wrap align-content-center'>
                                    <a
                                        className='text-decoration-none'
                                        target="_self"
                                        rel="noreferrer"
                                        href="/"
                                    ><span
                                        className='text-white pointer'
                                        style={{
                                            textDecoration: 'underline'
                                        }}
                                    >Regresar</span></a>
                                </div>
                                <div className='col-sm-12 col-md-8 col-lg-8 text-center'>
                                    <h3 className='text-white'>Todo esto y más lo encuentras en <img src='./../img/guay-logo.png' alt='logo' style={{ height: '1.51rem' }} /></h3>
                                    <h3 className='font-weight-bold text-white'>¡Registraté, descúbre y sorpréndete!</h3>
                                </div>
                                <div className='col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center flex-wrap align-content-center'>
                                    <a
                                        target="_self"
                                        rel="noreferrer"
                                        href="registro-freemium"
                                        className='w-75'
                                    ><button className='btn btn-sm btn-pink w-75'>Registro</button></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreeLanding
