import { React } from 'react';
import { colorsArray } from '../../../helpers/helpers';
import ReactApexChart from "react-apexcharts";

const Areas = ({
    Graphics4,
    DataGraphic,
    ListCargos,
     }) => {
        
    
    /**
     * Graphic Bar
     */
    const imagenes=['/img/MonitoreoMailGun/area1.png','/img/MonitoreoMailGun/area2.png','/img/MonitoreoMailGun/area3.png',
                    '/img/MonitoreoMailGun/area4.png','/img/MonitoreoMailGun/area5.png','/img/MonitoreoMailGun/area6.png',
                    '/img/MonitoreoMailGun/area7.png','/img/MonitoreoMailGun/area8.png'];
   
    const serievalue=Graphics4.serie?Graphics4.serie:[""]; 
    const rango=serievalue.length;    
     const seriePie=[{
       data:serievalue
      }];
     const optionsPie={
        chart: {
            type: 'bar',
          },
        
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                show:false,
                position: 'bottom'
              },
            }
          },
          colors: colorsArray,
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#15253c']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
              enabled: false,
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: Graphics4.labels?Graphics4.labels:[""],
            axisBorder: {
                show: false,
               
            },
            axisTicks: {
                show: false,
                
            },
            labels:{
                show:false,
            }
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          title: {
              text: 'Usuarios por área',
              align: 'center',
              floating: true
          },
          
          tooltip: {
            theme: 'dark',
            x: {
              show: true
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            },
            
          },
          legend: {
            show: false,
        }, grid: {

            show: false,
         },
        };
   
         
      const listItems = ListCargos.map((data,index) =>
        <div key={index} className="media text-muted pt-3 col-xl-6 col-lg-6 col-12 ">
                
            <div className="avatar" style={{width: '20%'}}>
                <img className={`avatar-img rounded-circle ${data.total === 0 ? "img-disabled" : " "}`} src={imagenes[index]} alt=""/>
            </div>
            <div className="media-body ml-1 mt-1">
                <p className={"h6 mb-0 text-truncate" + data.total === 0 ? " img-disabled" : ""}>
                    { data.total > 0 ? ((data.total*100)/DataGraphic.totalCollab).toFixed(1) : 0} % {data.cargo} ({data.total})</p>
            </div>
        </div>
     );
     
     const changeheightChart= ()=>{
        let height;
          if(rango<=10){
            height=300;
          }else if(rango>=11){
            height=rango*25;
          }
        return(
          height
        )
     }
   
  
    
    return (
      <div className='row mb-3'>        
        <div id="grafica" className="col-xl-6 col-lg-6 col-12 pb-2 graphic-container">    
            <ReactApexChart
                type="bar"
                options={optionsPie}
                series={seriePie}
                height={changeheightChart()}
            />
        </div>
        <div id="lista" className="col-xl-6 col-lg-6 col-12">        
            <div className='row'>
                {listItems}
            </div>
            
           
        </div>

       
      </div>
    )
}



export default Areas;
