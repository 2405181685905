import React, { Fragment, useState, useEffect } from "react";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import $ from "jquery";
import { redirectIfNotAuthenticated, getJwt } from "../../lib/auth";
import Datauser from "./users/Datauser";
import { postRequest } from "../../lib/api/api";
const Panel = () => {
  const [userData, upUserData] = useState([]);
  useEffect(() => {
    $("body").removeClass("blank-page");
    $("body").addClass("menu-expanded fixed-navbar");
    postRequest("/users/data?user=" + getJwt("jtw")).then((result) => {
      console.log(result)
      upUserData(result);
    });
  }, []);
  if (redirectIfNotAuthenticated()) {
    return (
      <Fragment>
        <Header name={userData.name} img={userData.img} />
        <Menu />
        <Datauser />
        <Footer />
      </Fragment>
    );
  }
};

export default Panel;
