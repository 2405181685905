import React, { Fragment, useEffect, useRef } from 'react';
// Tools
import $ from 'jquery';

const Chat = ({
    currentChatUsr,
    setCurrentChatUsr,
    chat,
    setChat,
    chatBot,
    chatUser,
    setShowBtn,
    setLastData
}) => {
    const openManual = (section) => {
        const host = window.location.host;
        let url = '';
        switch (host) {
            case "localhost:3000":
                url = 'http://127.0.0.1:5500/html/manual/index.html?';
                break;
            case "frontend.development.guay.digital":
                url = 'https://help.development.guay.digital/html/manual/index.html?';
                break;
            case "frontend.staging.guay.digital":
                url = 'https://help.staging.guay.digital/html/manual/index.html?';
                break;
            case "somosguay.com":
                url = 'https://help.somosguay.com/html/manual/index.html?';
                break;
            default:
                url = 'http://127.0.0.1:5500/html/manual/index.html?';
                break;
        }
        window.open(`${url}${section}`);
    }

    const container = useRef();

    useEffect(() => {
        container.current.scrollTo({
            top: container.current.scrollHeight,
            behavior: "smooth"
        });
    }, [chat])

    return (
        <div className='col-12 p-1 div-help' ref={container}>
            {
                chat.map((data, index) => (
                    <div className={data.idStructure === 1 ? ('row d-flex justify-content-start') : ('row d-flex justify-content-end')}>
                        <div className={data.idStructure === 1 ? ('col-8 rounded-chat bg-gray-chat-assistant text-gray-chat-assistant mb-1 p-1 ml-1 animation-chat-1') : ('col-8 rounded-chat bg-gray-chat-user text-white mb-1 p-1 mr-1 animation-chat-2')}>
                            {data.idContent === 1 ? (
                                data.text.map((text, index2) => (
                                    <span>{text}</span>
                                ))
                            ) : (null)}
                            {data.idContent === 1 ? (
                                data.links.map((link, indexLink) => (
                                    <p>{link.text} <span className='pointer blink font-weight-bold' onClick={() => openManual(link.manualLink)}>{link.description}</span>.</p>
                                ))
                            ) : (null)}
                            {data.idContent === 2 ? (
                                data.text.map((text, index2) => {
                                    setLastData(data);
                                    return (
                                        <button
                                            className='btn btn-sm bg-gray-chat-user text-white font-weight-bold p-0 mx-1'
                                            onClick={() => {
                                                if (text.option === 0) {
                                                    $('#containerHelp').removeClass('animate__backInRight');
                                                    $('#containerHelp').addClass('animate__backOutRight');
                                                    setShowBtn(true)
                                                } else {
                                                    let newArr = [];
                                                    let arrAdded = [];
                                                    for (let x in chat) {
                                                        newArr.push(chat[x]);
                                                    }
                                                    for (let x in chatBot) {
                                                        if (chatBot[x].answer === text.option) {
                                                            newArr.push(chatBot[x]);
                                                            arrAdded.push(chatBot[x]);
                                                        }
                                                    }
                                                    if (arrAdded[0].idUser !== 0) {
                                                        for (let x in chatUser) {
                                                            if (chatUser[x].idUser === arrAdded[0].idUser) {
                                                                newArr.push(chatUser[x]);
                                                            }
                                                        }
                                                    }
                                                    if (arrAdded[0].lastAns === 1 && data.lastUser === 1) {
                                                        newArr.push(data);
                                                    } else {
                                                        setCurrentChatUsr(currentChatUsr + 1);
                                                    }
                                                    setChat(newArr);
                                                }
                                            }}
                                            disabled={currentChatUsr > data.idUser ? true : false}
                                        >{text.description}</button>
                                    )
                                })
                            ) : (null)}
                            {data.idContent === 3 ? (
                                <div
                                    id={`accordion${index}`}
                                    className='text-black'
                                >
                                    {
                                        data.text.map((text, index3) => (
                                            <div className="card m-0 rounded-0">
                                                <div className="card-header p-1">
                                                    <a
                                                        className="card-link font-weight-bold"
                                                        data-toggle="collapse"
                                                        href={`#collapse${index3}`}
                                                    >
                                                        {text.title}
                                                    </a>
                                                </div>
                                                <div
                                                    id={`collapse${index3}`}
                                                    className="collapse"
                                                    data-parent={`#accordion${index}`}
                                                >
                                                    <div className="card-body p-1">
                                                        <p>{text.parragrap}</p>
                                                        <p className='text-center'>
                                                            <span
                                                                className='pointer text-manual-guay blink'
                                                                onClick={() => openManual(text.manualLink)}
                                                            >Saber más</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : (null)}
                            {data.idContent === 4 ? (
                                <Fragment>
                                    <p className='text-gray-chat-assistant text-center'>{data.lists[0].title}</p>
                                    {
                                        data.text.map((text, index2) => (
                                            <p>{text}</p>
                                        ))
                                    }
                                    <ul>
                                        {
                                            data.lists[0].subtitles.map((sub, index2) => (
                                                <li>
                                                    <span
                                                        className='pointer text-manual-guay blink'
                                                        onClick={() => openManual(sub.manualLink)}
                                                    >{sub.text}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Fragment>
                            ) : (null)}
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default Chat;