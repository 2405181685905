import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const ErrorPage = () => {

    return (

        <Fragment>
            <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="col-lg-4 col-md-8 col-10 p-0">
                    <div className="card-header bg-transparent border-0">
                        <h2 className="error-code text-center mb-2" style={{ fontSize: "100px" }}>404</h2>
                        <h3 className="text-uppercase text-center">Página no encontrada</h3>
                    </div>
                    <div className="card-content">
                        <div class="input-group">
                            <input type="text" className="form-control border-grey border-lighten-1" placeholder="Buscar..." aria-describedby="button-addon2" />
                            <div class="input-group-append">
                                <span className="input-group-append" id="button-addon2">
                                    <button className="btn btn-secondary border-grey border-lighten-1" type="button">
                                        <i className="ft-search"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-12">
                                <NavLink className="btn btn-primary btn-block" to="/about">
                                    Volver a la página principal <i className="ft-home float-right" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-transparent">
                        <div className="row">
                            <p className="text-muted text-center col-12 pb-1">
                                ©
                                <span className="year"> 2020</span>
                                <span className="pointer" style={{color:"#d81865"}}> Guay digital </span>
                                todos los derechos reservados
                            </p>
                            <div className="col-12 text-center" >
                                <i className="ft-facebook pointer" style={{color:"#d81865"}} />
                                <i className="ft-twitter ml-1 pointer" style={{color:"#d81865"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );

};

export default ErrorPage;
