import React from 'react';
import { setEndOfLaunch } from '../Models/Monitor_model';
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const ModalAlerts = ({ enterpriseId, advance, setUpdate }) => {

    function handleClicYesCloseLaunch() {
        $('#closeExePSSCaseAsk').hide();
        $('#closeEvalCaseAsk').hide();
        $('#closeExeCaseAsk').hide();
        setEndOfLaunch(enterpriseId, advance.idAdminLaunch, advance.idGroup).then((response) => {
            switch (response?.code) {
                case 1:
                    setUpdate(1);
                    toastr.success("¡Tu evaluación del ejercicio seleccionado fue cerrado con éxito!", "¡Éxito!");
                    break;
                case 99:
                    toastr.error("¡Ocurrió un error al cerrar tu evaluación del ejercicio!", "¡Error!");
                    break;
                case 1001:
                    toastr.error("¡Ocurrió un error con la empresa al cerrar la evaluación del ejercicio!", "¡Error!");
                    break;
                case 1002:
                    toastr.error("¡Los datos provistos no son los correctos revisa nuevamente!", "¡Error!");
                    break;
                case 1003:
                    toastr.warning("¡Tu evaluación del ejercicio no se puede cerrar debido a que la participación no es del 100%!", "¡Ojo!");
                    break;
                    case 1004:
                    toastr.info("¡Tu evaluación del ejercicio no se puede cerrar debido a que se encuentra pausado!", "¡Ojo!");
                    break;
                default:
                    toastr.error("¡Ocurrió un inesperado!", "¡Error!");
                    break;
            }
        });
    }

    return (
        <>

            <div
                className="modal"
                id="closeExePSSCaseAsk"
                style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div
                        className="modal-content"
                        style={{ backgroundColor: 'transparent', borderRadius: '2rem', overflow: "auto", border: "none" }}>
                        <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                            <button
                                type="button"
                                className="close"
                                onClick={() => $('#closeExePSSCaseAsk').hide()}>&times;</button>
                        </div>
                        <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <img width="75px" src="./img/icons/iconos_registro_documental-12.png" alt="success" />
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-8">
                                    <h1 className="font-medium-2 text-base text-center">¿Estás seguro que deseas dar por concluido el servicio configurado para Retorno Seguro en su totalidad?</h1>
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-10">
                                    <p className="font-medium-2 text-base text-center">De seleccionar "Sí, concluir", ya no se podrá reactivar este ejercicio configurado, si es que aún tienes ciclos de evaluaciones programados pendientes, y a su vez estarás indicando que ya no generaras actividades de seguimiento y monitoreo que fundamenten y/o den cumplimiento en su totalidad a tú Protocolo de Seguridad Sanitaria. Por lo tanto, si llegases a requerir retomar o reiniciar este servicio Normativo en complitud, deberás configurar un nuevo lanzamiento.</p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-between">
                                    <button className="btn btn-small btn-cancel-style2" onClick={() => $("#closeExePSSCaseAsk").hide()}>No, regresar</button>
                                    <button className="btn btn-small btn-save-style2">Si, concluir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div
                className="modal"
                id="closeExeCaseAsk"
                style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div
                        className="modal-content"
                        style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                            <button
                                type="button"
                                className="close"
                                onClick={() => $('#closeExeCaseAsk').hide()}>&times;</button>
                        </div>
                        <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <img width="75px" src="./img/icons/iconos_registro_documental-12.png" alt="success" />
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-8">
                                    <h1 className="font-medium-2 text-base text-center">¿Estás seguro que deseas dar por concluido el ejercicio? </h1>
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-10">
                                    <p className="font-medium-2 text-base text-center">De afirmar la solicitud, ya no se podrá reactivar este ejercicio configurado, si es que aún tiene ciclos de evaluaciones programados pendientes. Por lo tanto, si llegase a requerir retomar o reiniciar este servicio de cuestionarios, deberás configurar un nuevo lanzamiento.</p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-between">
                                    <button className="btn btn-small btn-cancel-style2" onClick={() => $("#closeExeCaseAsk").hide()}>No, regresar</button>
                                    <button className="btn btn-small btn-save-style2" onClick={handleClicYesCloseLaunch}>Si, concluir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div
                className="modal"
                id="closeEvalCaseAsk"
                style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div
                        className="modal-content"
                        style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                            <button
                                type="button"
                                className="close"
                                onClick={() => $('#closeEvalCaseAsk').hide()}>&times;</button>
                        </div>
                        <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <img width="75px" src="./img/icons/iconos_registro_documental-12.png" alt="success" />
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-10">
                                    <p className="font-medium-2 text-base text-center">¿Estás seguro que deseas dar por concluido el periodo de evaluación y así dar inicio al proceso de generación de resultados?</p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-between">
                                    <button className="btn btn-small btn-cancel-style2" onClick={() => $("#closeEvalCaseAsk").hide()}>Atrás</button>
                                    <button className="btn btn-small btn-save-style2" onClick={handleClicYesCloseLaunch}>Si, estoy seguro</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div
                className="modal"
                id="closeEvalCaseError"
                style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div
                        className="modal-content"
                        style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                            <button
                                type="button"
                                className="close"
                                onClick={() => $('#closeEvalCaseError').hide()}>&times;</button>
                        </div>
                        <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <img width="75px" src="./img/icons/iconos_registro_documental-11.png" alt="success" />
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-10">
                                    <p className="font-medium-2 text-base text-center">Este ejercicio requiere contar con el 100% de colaboración de los participantes para poder accionar el cierre del ejercicio y evaluación </p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-center">
                                    <button className="btn btn-small btn-cancel-style2" onClick={() => $("#closeEvalCaseError").hide()}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className="modal"
                id="closeEvalCaseError2"
                style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div
                        className="modal-content"
                        style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                            <button
                                type="button"
                                className="close"
                                onClick={() => $('#closeEvalCaseError').hide()}>&times;</button>
                        </div>
                        <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <img width="75px" src="./img/icons/iconos_registro_documental-11.png" alt="success" />
                                </div>
                            </div>
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-10">
                                    <p className="font-medium-2 text-base text-center">Este ejercicio requiere contar con el 80% de colaboración de los participantes para poder accionar el cierre del ejercicio y evaluación </p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-center">
                                    <button className="btn btn-small btn-cancel-style2" onClick={() => $("#closeEvalCaseError2").hide()}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ModalAlerts
