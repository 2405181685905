import React, { useState, useEffect } from 'react';
import LoaderApp from '../../LoaderApp';
import { getBranches, deleteBranches, editBranches, addBranches, updateBranches, homologateBranches } from '../../Models/Enterprise_model';
import { getJwt } from "../../../../lib/auth";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import BootstrapTable2 from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import $ from "jquery";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import Geocode from "react-geocode";

const RegisterBranches = ({ google, setTitleSection, actualizarSteps, setCompletedSteps }) => {

    const [loading, setLoading] = useState(true);
    const [listBranches, setListBranches] = useState([]);
    const [listBranchesAux, setListBranchesAux] = useState([]);
    const [description, setDescription] = useState('');
    const [branchEntId, setBranchEntId] = useState(0);
    const [enterpriseId, setEnterpriseId] = useState(0);
    const [mapPosition, setMapPosition] = useState({
        lat: 19.427709388114348,
        lng: -99.13243757939662
    });
    const [markerPosition, setMarkerPosition] = useState({
        lat: 19.427709388114348,
        lng: -99.13243757939662
    });
    const [fullAddress, setFullAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [objectBranch, setObjectBranch] = useState({
        enterpriseId: enterpriseId,
        branchEntId: branchEntId,
        geolocation: '',
        description: description
    });
    const { SearchBar } = Search;
    const [expanded, setExpanded] = useState([]);
    const [indexSelected, setIndexSelected] = useState(-1);
    const [selected, setSelected] = useState([]);
    const [branchHomologar, setBranchHomologar] = useState('');
    const [branchEntIdHom, setBranchEntIdHom] = useState([]);
    const [listBraAddr, setListBraAddr] = useState([]); // List of branches with the same address
    const [selected_2, setSelected_2] = useState([]);
    const [selected_3, setSelected_3] = useState([]);
    const [branchName, setBranchName] = useState("");
    Geocode.setApiKey("AIzaSyBgC8VAUgZHdOxXhfCXHD9UmfuUAe8e2ek");

    useEffect(() => {
        try {
            getBranches(getJwt("enterprise")).then((response) => {
                if (response.code === 1) {
                    setListBranches(response.branches);
                    setListBranchesAux(response.branches);
                }
            });
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => {
        setTitleSection("Administración de Centros de Trabajo");
    })

    const onChange = (selected, selection) => {
        setSelected(selected);
    }

    const onChange_2 = (selected, selection) => {
        setSelected_2(selected);
    }

    const onChange_3 = (selected, selection) => {
        setSelected_3(selected);
    }

    const setBranchesListBox = () => {
        let array = listBranches;
        let array_2 = [];

        for (let x in array) {
            array_2.push({ value: array[x].branchEntId, label: array[x].description })
        }

        return array_2;
    }

    const handleClickDelete = (object) => {
        Swal.fire({
            title: '<p class="text-white mb-2">¿Estás seguro?</p>',
            text: "Una vez eliminado no se podrá recuperar…",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "De acuerdo",
            reverseButtons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                deleteBranches(object.enterpriseId, object.branchEntId).then((response) => {
                    switch (response.code) {
                        case 1:
                            setLoading(true);
                            toastr.success('Centro de trabajo eliminado correctamente', 'Ok');
                            getBranches(object.enterpriseId).then((response) => {
                                if (response.code === 1) {
                                    setListBranches(response.branches);
                                    setLoading(false);
                                }
                            });
                            break;
                        case 99:
                            toastr.error('Ocurrió un error al eliminar el centro de trabajo', 'Error');
                            break;
                        case 1000:
                            toastr.error('No se pudo encontrar la empresa o el centro de trabajo, recarga la página', 'Error');
                            break;
                        case 1001:
                            toastr.info('No se pueden eliminar centros de trabajo con colaboradores activos', 'Ojo');
                            break;
                        case 1002:
                            toastr.info('Este centro de trabajo ya no está registrado :O , recargue la página', 'Ojo');
                            break;
                        default:
                            toastr.error('Ocurrió un error al eliminar el centro de trabajo', 'Error');
                            break;
                    }
                });
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    };

    const handleClickEdit = (object) => {
        swal({
            title: "Edita el Centro de trabajo",
            text: `El nombre del centro de trabajo: "${object.description}" cambiara por:`,
            content: "input",
            buttons: ["Cancelar", "Confirmar"]
        }).then((ok) => {
            if (ok) {
                if (ok.trim() !== '') {
                    let objBranch = { "enterpriseId": object.enterpriseId, "branchEntId": object.branchEntId, "description": ok }
                    objBranch = JSON.stringify(objBranch);
                    setLoading(true);
                    editBranches(objBranch, false).then((response) => {
                        switch (response.code) {
                            case 1:
                                getBranches(getJwt("enterprise")).then((response) => {
                                    if (response.code === 1) {
                                        setListBranches(response.branches);
                                        setLoading(false);
                                    }
                                });
                                toastr.success("Centro de trabajo actualizado correctamente", "Exito");
                                break;
                            case 1001:
                                setLoading(false);
                                setBranchHomologar(response.branchRequest.description);
                                /* setListBranches(listBranchesAux); */
                                setBranchesListBox_2(response.branchRequest, response.branches);
                                $('#homologar-3').show();
                                break;
                            default:
                                setLoading(false);
                                toastr.error("Ocurrio un error al actualizar el centro de trabajo", "Error");
                                break;
                        }
                    });
                } else {
                    toastr.info("El campo se encuentra vacío", "Ojo");
                }
            }
        });
    }

    const handleClickAdd = () => {
        if (branchName !== "") {
            const data = { "enterpriseId": getJwt("enterprise"), "description": branchName };
            addBranches(data, false).then((response) => {
                switch (response.code) {
                    case 1:
                        getBranches(getJwt("enterprise")).then((response) => {
                            if (response.code === 1) {
                                setListBranches(response.branches);
                                setListBranchesAux(response.branches);
                            }
                        });
                        setExpanded([]);
                        setBranchName("");
                        $("#addBranch").hide();
                        toastr.success("Centro de trabajo agregado correctamente", "Exito");
                        break;
                    case 1002:
                        $('#forceAddBranch').show();
                        $("#addBranch").hide();
                        break;
                    default:
                        setBranchName("");
                        $("#addBranch").hide();
                        toastr.error("No se pudo guardar el centro de trabajo, recarga la pagina y vuelve a intentarlo.", "Error");
                        break;
                }
            });
        } else {
            toastr.info("El campo se encuentra vacío", "Ojo");
        }
    }
    const forceAddBranch = () => {
        const data = { "enterpriseId": getJwt("enterprise"), "description": branchName };
        addBranches(data, true).then((response) => {
            switch (response.code) {
                case 1:
                    getBranches(getJwt("enterprise")).then((response) => {
                        if (response.code === 1) {
                            setListBranches(response.branches);
                            setListBranchesAux(response.branches);
                        }
                    });
                    setExpanded([]);
                    setBranchName("");
                    toastr.success("Centro de trabajo agregado correctamente", "Exito");
                    $("#forceAddBranch").hide();
                    break;
                default:
                    setBranchName("");
                    $("#forceAddBranch").hide();
                    toastr.error("No se pudo guardar el centro de trabajo, recarga la pagina y vuelve a intentarlo.", "Error");
                    break;
            }
        });
    }
    /**
     * Using google api search geocode
     * @returns 
     */
    const geocodeAddress = () => {
        if ($('#address').val() === "") {
            return toastr.error('Escribe la dirección para buscar en el mapa.', '¡Ooops!');
        }
        const completeAdress = $('#address').val();
        const google = window.google = window.google ? window.google : {}
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: completeAdress }, (results, status) => {
            if (status === "OK") {
                $("#address").val(results[0].formatted_address);
                setFullAddress(results[0].formatted_address);
                let zip = '';
                for (let x in results[0].address_components) {
                    if (results[0].address_components[x].types[0] === 'postal_code') {
                        zip = results[0].address_components[x].long_name;
                    }
                }
                setZipCode(zip);
                setObjectBranch({
                    ...objectBranch,
                    enterpriseId: enterpriseId,
                    branchEntId: branchEntId,
                    geolocation: '' + results[0].geometry.location.lat() + ', ' + results[0].geometry.location.lng() + '',
                    description: description,
                });
                setMapPosition({
                    ...mapPosition,
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                });
                setMarkerPosition({
                    ...markerPosition,
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                });
            } else {
                console.log("Geocode was not successful for the following reason: " + status)
                toastr.error('No se ecuentra la dirección escrita. Verifique que sea una dirección valida.', '¡Ooops!');
            }
        });
    }
    /**
     * Assign an address to a branch
     * @returns 
     */
    const addLocation = () => {
        if (branchEntId === 0) {
            return toastr.error('Debes seleccionar un Centro de trabajo.', '¡Ooops!');
        }
        if (fullAddress === "") {
            return toastr.error('Debes buscar la ubicación de la empresa en el mapa.', '¡Ooops!');
        }
        let dataAddress = {
            full_address: fullAddress,
            zipCode: zipCode === '' ? 0 : zipCode,
        }
        let forceSave = false;
        setListBranches([]);
        if (objectBranch.branchEntId !== 0) {
            updateBranches(objectBranch, dataAddress, forceSave).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        getBranches(getJwt("enterprise")).then((response) => {
                            if (response.code === 1) {
                                setListBranches(response.branches);
                                setListBranchesAux(response.branches)
                                toastr.success('Información actualizada correctamente.', '¡Bien hecho!');
                                $("#address").val('');
                                setZipCode('');
                                setObjectBranch({
                                    enterpriseId: 0,
                                    branchEntId: 0,
                                    geolocation: '',
                                    description: ''
                                });
                            } else {
                                setListBranches(listBranchesAux);
                                toastr.error('Información no se actualizó correctamente.', '¡Ooops!');
                            }
                        });
                    } else {
                        switch (response.code) {
                            case 1001:
                                setBranchHomologar(response.branchRequest.description);
                                setListBranches(listBranchesAux);
                                setBranchesListBox_2(response.branchRequest, response.branches);
                                $('#homologar-2').show();
                                break;
                            default:
                                setListBranches(listBranchesAux);
                                toastr.error('Información no se actualizó correctamente.', '¡Ooops!');
                                break;
                        }
                    }
                } else {
                    toastr.error('Hay un problema con la cominicación con el servidor. Intente más tarde.', '¡Ooops!');
                }
            });
        } else {
            setListBranches(listBranchesAux);
            toastr.warning('Debes realizar un cambio en la información antes de guardar');
        }
    }

    const handleOnDragEnd = e => {
        let newLat = e.latLng.lat();
        let newLng = e.latLng.lng();
        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address;
                /* const addressArray = response.results[0].address_components; */
                $("#address").val(address);
                setDescription(address);
                setFullAddress(address);
                let zip = '';
                for (let x in response.results[0].address_components) {
                    if (response.results[0].address_components[x].types[0] === 'postal_code') {
                        zip = response.results[0].address_components[x].long_name;
                    }
                }
                setZipCode(zip);
                setObjectBranch({
                    ...objectBranch,
                    enterpriseId: enterpriseId,
                    branchEntId: branchEntId,
                    geolocation: '' + newLat + ', ' + newLng + '',
                    description: description,
                });
                setMapPosition({
                    ...mapPosition,
                    lat: newLat,
                    lng: newLng
                });
                setMarkerPosition({
                    ...markerPosition,
                    lat: newLat,
                    lng: newLng
                });
            }
        )
    }

    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap google={google}
                    defaultZoom={15}
                    defaultCenter={{ lat: mapPosition.lat, lng: mapPosition.lng }}
                >
                    <Marker google={google}
                        draggable={true}
                        onDragEnd={handleOnDragEnd}
                        position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
                    />
                </GoogleMap>
            )
        )
    );
    /**
     * Update administration steps flow
     */
    const setStepBack = () => {
        actualizarSteps(1);
        setCompletedSteps(1);
    }
    /**
     * Set administration steps flow
     */
    const setStep = () => {
        let array = listBranches;
        let allData = true;
        for (let x in array) {
            if (array[x].fullAddress === "") {
                allData = false
            }
        }
        if (allData) {
            actualizarSteps(3);
            setCompletedSteps(3);
        } else {
            toastr.error('Aún quedan centros de trabajo con información incompleta.', '¡Ooops!');
        }
    }
    /**
     * Set content in the table
     * @param {*} cell 
     * @param {*} row 
     * @returns 
     */
    const tdFormatter = (cell, row) => {
        if (row.fullAddress === "") {
            return (
                <div className="col-lg-12 px-0 py-1">
                    <div className="row">
                        <div className="col-lg-4 d-flex justify-content-center">
                            <span>
                                <i className='ft ft-edit-2 icon-medium-size transparent-color pointer mr-1'
                                    onClick={() => handleClickEdit(row)}
                                    data-toggle="modal"
                                    data-target="#dataCollab" />
                            </span>
                            <span>
                                <i className='ft ft-trash-2 icon-medium-size transparent-color pointer'
                                    onClick={() => handleClickDelete(row)} />
                            </span>
                        </div>
                        <div className="col-lg-8 p-0">
                            <span>{cell}</span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-lg-12 px-0 py-1">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 d-flex justify-content-center">
                            <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Editar centro de trabajo">
                                <i className='ft ft-edit-2 icon-medium-size transparent-color pointer mr-1'
                                    onClick={() => handleClickEdit(row)}
                                    data-toggle="modal"
                                    data-target="#dataCollab" />
                            </span>
                            <span>
                                <i className='ft ft-trash-2 icon-medium-size transparent-color pointer'
                                    onClick={() => handleClickDelete(row)}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Eliminar centro de trabajo" />
                            </span>
                        </div>
                        <div className="col-md-4 col-lg-6 p-0">
                            <span>{cell}</span>
                        </div>
                        <div className="col-md-4 col-lg-2 pl-0">
                            <span>
                                <i className='ft ft-check icon-medium-size text-green pointer'
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Datos completos" />
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
    }
    /**
     * Table columns
     */
    const columns = [{
        dataField: 'description',
        headerAttrs: {
            hidden: true
        },
        formatter: tdFormatter,
    }];
    /**
     * Set extended row content
     * @param {*} address 
     * @returns 
     */
    const infoExtendedRow = (address) => {
        if (address === "") {
            return (
                <div>
                    <p>Dirección no registrada</p>
                </div>
            )
        } else {
            return (
                <div>
                    <p>{address}</p>
                </div>
            )
        }
    }
    /**
     * Handle events when a row is expanded
     * @param {*} row 
     * @param {*} isExpand 
     * @param {*} rowIndex 
     * @param {*} e 
     */
    const handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (indexSelected < 0) {
            if (isExpand) {
                setExpanded([row.branchEntId])
            } else {
                setExpanded([])
            }
        } else {
            if (indexSelected === rowIndex) {
                setExpanded([row.branchEntId])
            } else {
                if (isExpand) {
                    setExpanded([row.branchEntId])
                }
            }
        }
    }
    /**
     * Assign a className to selected row
     * @param {*} row 
     * @returns 
     */
    const rowSelectedClass = (row) => {
        if (row.fullAddress === "") {
            return ('row-parent-color-2')
        } else {
            return ('row-parent-color')
        }
    }
    /**
     * Assign a className to selected child row
     * @param {*} row 
     * @returns 
     */
    const expandedClass = (row) => {
        if (row.fullAddress === "") {
            return ('row-child-color-2')
        } else {
            return ('row-child-color')
        }
    }
    /**
     * Extended row props
     */
    const expandRow = {
        onlyOneExpanding: true,
        parentClassName: (isExpanded, row, rowIndex) => rowSelectedClass(row),
        className: (isExpanded, row, rowIndex) => expandedClass(row),
        renderer: row => infoExtendedRow(row.fullAddress),
        expanded: expanded,
        onExpand: handleOnExpand
    };
    /**
     * Row events
     */
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (indexSelected < 0) {
                setIndexSelected(rowIndex)
                $('#address').val(row.fullAddress);
                setDescription(row.description);
                setBranchEntId(row.branchEntId);
                setEnterpriseId(row.enterpriseId);
                if (row.fullAddress !== "") {
                    setFullAddress(row.fullAddress);
                    let fullString = row.geolocation;
                    let splitedString = fullString.split(",");
                    let lng = splitedString[1].trim();
                    setMapPosition({
                        ...mapPosition,
                        lat: parseFloat(splitedString[0]),
                        lng: parseFloat(lng)
                    });
                    setMarkerPosition({
                        ...markerPosition,
                        lat: parseFloat(splitedString[0]),
                        lng: parseFloat(lng)
                    });
                } else {
                    setFullAddress(row.fullAddress);
                }
            } else {
                if (indexSelected !== rowIndex) {
                    setIndexSelected(rowIndex)
                    $('#address').val(row.fullAddress);
                    if (branchEntId !== row.enterpriseId) {
                        setDescription(row.description);
                        setBranchEntId(row.branchEntId);
                        setEnterpriseId(row.enterpriseId);
                        if (row.fullAddress !== "") {
                            setFullAddress(row.fullAddress);
                            let fullString = row.geolocation;
                            let splitedString = fullString.split(",");
                            let lng = splitedString[1].trim();
                            setMapPosition({
                                ...mapPosition,
                                lat: parseFloat(splitedString[0]),
                                lng: parseFloat(lng)
                            });
                            setMarkerPosition({
                                ...markerPosition,
                                lat: parseFloat(splitedString[0]),
                                lng: parseFloat(lng)
                            });
                        } else {
                            setFullAddress(row.fullAddress);
                        }
                    }
                }
            }
        }
    };
    /**
     * Row classes
     * @param {*} row 
     * @param {*} rowIndex 
     * @returns 
     */
    const rowClasses = (row, rowIndex) => {
        let classes = null;
        if (row.fullAddress === "") {
            classes = 'rowActive';
        } else {
            classes = 'completed-row';
        }
        return classes;
    };
    /**
     * Set a loader when data table is empty
     * @returns 
     */
    const loaderLayout = () => {
        return (
            <LoaderApp />
        )
    }

    const closeModal = (modal) => {
        switch (modal) {
            case 1:
                $("#homologar").hide();
                setSelected([]);
                break;
            case 2:
                $("#confirmHomologar").hide();
                $("#homologar").show();
                break;
            case 3:
                $("#confirmHomologar").hide();
                setSelected([]);
                break;
            case 4:
                $("#homologar-2").hide();
                $("#confirmHomologar-2").hide();
                setSelected_2([]);
                break;
            case 5:
                $("#confirmHomologar-2").hide();
                $("#homologar-2").show();
                break;
            case 6:
                $("#homologar-3").hide();
                break;
            case 7:
                $("#confirmHomologar-3").hide();
                $("#homologar-3").show();
                break;
            case 8:
                $("#confirmHomologar-3").hide();
                break;
            default:
                break;
        }
    }

    const nextModal = (modal) => {
        switch (modal) {
            case 1:
                setBranchHomologar($('#branchHomologar').val());
                $("#homologar").hide();
                $("#confirmHomologar").show();
                break;
            case 2:
                $("#homologar-2").hide();
                $("#confirmHomologar-2").show();
                break;
            case 3:
                $("#homologar-3").hide();
                $("#confirmHomologar-3").show();
                break;
            default:
                break;
        }
    }
    /**
     * Function that set list of branches in the listBox component
     * @param {*} currentBranch 
     * @param {*} branches 
     * @param {int} branches.branchEntId - The id of the branch
     * @param {string} branches.description - The name of the branch
     */
    const setBranchesListBox_2 = (currentBranch, branches) => {
        let array = branches;
        let array_2 = [];
        setBranchEntIdHom(currentBranch);
        for (let x in array) {
            array_2.push({ value: array[x].branchEntId, label: array[x].description });
        }
        setListBraAddr(array_2);
    }
    /**
     * Function that force the branch creation
     */
    const forceBranch = () => {
        try {
            let dataAddress = {
                full_address: fullAddress
            }
            let forceSave = true;
            setListBranches([]);
            updateBranches(objectBranch, dataAddress, forceSave).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setExpanded([]);
                        getBranches(getJwt("enterprise")).then((response) => {
                            if (response.code === 1) {
                                setListBranches(response.branches);
                                setListBranchesAux(response.branches);
                                $("#address").val('');
                                closeModal(4);
                                toastr.success('Información actualizada correctamente.', '¡Bien hecho!');
                            } else {
                                setListBranches(listBranchesAux);
                                $("#address").val('');
                                closeModal(4);
                                toastr.error('Información no se actualizó correctamente.', '¡Ooops!');
                            }
                        });
                    } else {
                        setListBranches(listBranchesAux);
                        $("#address").val('');
                        closeModal(4);
                        toastr.error('Información no se actualizó correctamente.', '¡Ooops!');
                    }
                } else {
                    setListBranches(listBranchesAux);
                    $("#address").val('');
                    closeModal(4);
                    toastr.error('Información no se actualizó correctamente.', '¡Ooops!');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Function that homologate when the user click the button 'Homologar'
     */
    const homologarBranches = () => {
        let ids = ""
        if (selected.length !== 0) {
            for (let x in selected) {
                ids = ids + selected[x] + ",";
            }
            setListBranchesAux(listBranches);
            setListBranches([]);
            homologateBranches(getJwt("enterprise"), ids.slice(0, -1), 0, branchHomologar).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setExpanded([]);
                            getBranches(getJwt("enterprise")).then((response) => {
                                if (response) {
                                    if (response.code === 1) {
                                        toastr.success('Los centros de trabajo fueron homologados a ' + branchHomologar, '¡Exito!');
                                        setBranchHomologar('');
                                        $('#branchHomologar').val('');
                                        setListBranches(response.branches);
                                        setListBranchesAux(response.branches);
                                        closeModal(3);
                                    }
                                } else {
                                    toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                                    setListBranches(listBranchesAux);
                                    closeModal(3);
                                }
                            });
                            break;
                        case 1005:
                            setListBranches(listBranchesAux);
                            toastr.warning('Existe otro centro de trabajo con el mismo nombre que quieres homologar. Escribe un nombre diferente.', '¡Ooops!');
                            break;
                        default:
                            toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                            setListBranches(listBranchesAux);
                            closeModal(3);
                            break;
                    }
                } else {
                    toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                    setListBranches(listBranchesAux);
                    closeModal(3);
                }

            });
        } else {
            toastr.warning('No has seleccionado ningún centro de trabajo para homologar.', 'Ojo');
        }
    }
    /**
     * Function that homologate when the user wants to add an address
     */
    const homologarBranches_2 = () => {
        let ids = "";
        if (selected_2.length !== 0) {
            for (let x in selected_2) {
                ids = ids + selected_2[x] + ",";
            }
            setListBranchesAux(listBranches);
            setListBranches([]);
            homologateBranches(getJwt("enterprise"), ids.slice(0, -1), branchEntIdHom.branchEntId, branchHomologar).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setExpanded([]);
                        getBranches(getJwt("enterprise")).then((response) => {
                            if (response) {
                                if (response.code === 1) {
                                    toastr.success('Los centros de trabajo fueron homologados a ' + branchHomologar, '¡Exito!');
                                    setBranchHomologar('');
                                    $('#branchHomologar').val('');
                                    setListBranches(response.branches);
                                    setListBranchesAux(response.branches);
                                    closeModal(4);
                                }
                            } else {
                                closeModal(4);
                                setListBranches(listBranchesAux);
                                toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                            }
                        });
                    } else {
                        closeModal(4);
                        setListBranches(listBranchesAux);
                        toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                    }
                } else {
                    closeModal(4);
                    setListBranches(listBranchesAux);
                    toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                }

            });
        } else {
            setListBranchesAux(listBranches);
            setListBranches([]);
            homologateBranches(getJwt("enterprise"), "" + listBraAddr[0].value + "", branchEntIdHom.branchEntId, branchHomologar).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        getBranches(getJwt("enterprise")).then((response) => {
                            if (response) {
                                if (response.code === 1) {
                                    toastr.success('Los centros de trabajo fueron homologados a ' + branchHomologar, '¡Exito!');
                                    setBranchHomologar('');
                                    $('#branchHomologar').val('');
                                    setListBranches(response.branches);
                                    setListBranchesAux(response.branches);
                                    closeModal(4);
                                }
                            } else {
                                closeModal(4);
                                setListBranches(listBranchesAux);
                                toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                            }
                        });
                    } else {
                        closeModal(4);
                        setListBranches(listBranchesAux);
                        toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                    }
                } else {
                    closeModal(4);
                    setListBranches(listBranchesAux);
                    toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                }

            });
        }
    }
    /**
     * Function that homologate when the user wants to edit the name's branch
     * @param {int} flag - Flag that indicates if it is multiple matches or one match
     */
    const homologarBranches_3 = (flag) => {
        let ids = ""
        if (flag > 1) {
            if (selected_3.length !== 0) {
                for (let x in selected_3) {
                    ids = ids + selected_3[x] + ",";
                }
                setListBranchesAux(listBranches);
                setListBranches([]);
                homologateBranches(getJwt("enterprise"), ids.slice(0, -1), 0, branchHomologar).then((response) => {
                    if (response) {
                        if (response.code === 1) {
                            setExpanded([]);
                            getBranches(getJwt("enterprise")).then((response) => {
                                if (response) {
                                    if (response.code === 1) {
                                        toastr.success('Los centros de trabajo fueron homologados a ' + branchHomologar, '¡Exito!');
                                        setBranchHomologar('');
                                        $('#branchHomologar').val('');
                                        setListBranches(response.branches);
                                        setListBranchesAux(response.branches);
                                        closeModal(8);
                                    }
                                } else {
                                    closeModal(8);
                                    setListBranches(listBranchesAux);
                                    toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                                }
                            });
                        } else {
                            closeModal(8);
                            setListBranches(listBranchesAux);
                            toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                        }
                    } else {
                        closeModal(8);
                        setListBranches(listBranchesAux);
                        toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                    }

                });
            } else {
                toastr.warning('No has seleccionado ningún centro de trabajo para homologar.', 'Ojo');
            }
        } else {
            for (let x in selected) {
                ids = ids + selected[x];
            }
            setListBranchesAux(listBranches);
            setListBranches([]);
            homologateBranches(getJwt("enterprise"), ids, branchEntIdHom.branchEntId, branchHomologar).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setExpanded([]);
                        getBranches(getJwt("enterprise")).then((response) => {
                            if (response) {
                                if (response.code === 1) {
                                    toastr.success('Los centros de trabajo fueron homologados a ' + branchHomologar, '¡Exito!');
                                    setBranchHomologar('');
                                    $('#branchHomologar').val('');
                                    setListBranches(response.branches);
                                    setListBranchesAux(response.branches);
                                    closeModal(6);
                                }
                            } else {
                                closeModal(6);
                                setListBranches(listBranchesAux);
                                toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                            }
                        });
                    } else {
                        closeModal(6);
                        setListBranches(listBranchesAux);
                        toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                    }
                } else {
                    closeModal(6);
                    setListBranches(listBranchesAux);
                    toastr.error('Ocurrió un error al homologar el centro de trabajo.', 'Error');
                }

            });
        }
    }

    if (loading) {
        return (
            <LoaderApp />
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-5 bg-white-guay rounded" >
                <div className="row">
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                        <div className="row">
                            <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="text-base-guay font-weight-bold pl-1 font-medium-2">Ubica tus {listBranches.length} Centros de trabajo</span>
                            </div>
                            <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6">
                                <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Homologar centros de trabajo">
                                    <img
                                        className="pointer float-right"
                                        src="./../img/collabImages/iconos_carga-09.png"
                                        alt="img-guay"
                                        style={{ height: '25px', width: '25px' }}
                                        onClick={() => $('#homologar').show()} />
                                </span>
                                <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Agregar centro de trabajo">
                                    <img
                                        className="pointer float-right mr-1"
                                        src="./../img/collabImages/iconos_carga_colaboradores-07.png"
                                        alt="img-guay"
                                        style={{ height: '25px', width: '25px' }}
                                        onClick={() => $("#addBranch").show()} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                        <span className="text-base pl-1 font-medium-2">Buscar Centro de Trabajo</span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                        <div className="row">
                            <div className="col-sx-4 col-sm-4 col-md-12 col-lg-4 mb-3">
                                <ToolkitProvider
                                    keyField='branchEntId'
                                    data={listBranches}
                                    columns={columns}
                                    search
                                >
                                    {
                                        props => (
                                            <div className="col-lg-12">
                                                <div className="col-lg-12 p-0 complete-searchBar mb-2">
                                                    <SearchBar
                                                        {...props.searchProps}
                                                        style={{ width: '100%', marginTop: '1px' }}
                                                        className="form-control form-control-sm"
                                                        placeholder="Buscar" />
                                                </div>
                                                <BootstrapTable2
                                                    bodyClasses="foo"
                                                    expandRow={expandRow}
                                                    hover
                                                    bordered={false}
                                                    rowEvents={rowEvents}
                                                    rowClasses={rowClasses}
                                                    headerClasses="header-class"
                                                    wrapperClasses="boo"
                                                    noDataIndication={() => loaderLayout()}
                                                    {...props.baseProps}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                            <div className="col-sx-8 col-sm-8 col-md-12 col-lg-8 mb-1">
                                <div className="row">
                                    <div className="col-sx-9 col-sm-9 col-md-9 col-lg-9">
                                        <div className="input-group input-group-sm">
                                            <input type="text" className="form-control form-control-sm" placeholder="Escribe la dirección o ubicalo en el mapa!" id="address" name="address" />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-light text-dark" onClick={() => geocodeAddress()}><i className="ft ft-search pointer" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sx-3 col-sm-3 col-md-3 col-lg-3 d-flex justify-content-end flex-wrap align-content-center">
                                        <button className="btn btn-pink btn-sm" onClick={() => addLocation()}>Agregar ubicación</button>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                        <AsyncMap
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBgC8VAUgZHdOxXhfCXHD9UmfuUAe8e2ek&libraries=geometry,drawing,places"
                                            loadingElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                            containerElement={
                                                <div style={{ height: '300px' }} />
                                            }
                                            mapElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 mb-1">
                        <button className="btn btn-blue-3 btn-sm pointer float-left" onClick={() => setStepBack()} >Regresar</button>
                        <button className="btn btn-pink btn-sm pointer float-right" onClick={() => setStep()} >Continuar</button>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div className="modal" id="homologar" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                    <h2 className="align-middle font-weight-bold">Homologa centros de trabajo</h2>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                    <p>
                                        En ocasiones el mismo centro de trabajo tiene varios nombres, coloca
                                        en el rectángulo del lado derecho los centros de trabajo que correspondan
                                        o uno para unificar el nombre o todos los registros
                                    </p>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <div className="row">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                            <div className="row font-medium-2 font-weight-bold">
                                                <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-center">
                                                    <span>Total de Centros de Trabajo</span>
                                                </div>
                                                <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-center">
                                                    <span>Centros de Trabajo a homologar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <div className="row">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                            <DualListBox
                                                options={setBranchesListBox()}
                                                selected={selected}
                                                onChange={onChange}

                                                icons={{
                                                    moveLeft: <span className="la la-chevron-left" />,
                                                    moveAllLeft: [
                                                        <span key={0} className="la la-chevron-left" />,
                                                        <span key={1} className="la la-chevron-left" />,
                                                    ],
                                                    moveRight: <span className="la la-chevron-right" />,
                                                    moveAllRight: [
                                                        <span key={0} className="la la-chevron-right" />,
                                                        <span key={1} className="la la-chevron-right" />,
                                                    ]
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center">
                                    <form className="form-inline">
                                        <label className="pr-1">Se homologarán a: </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm w-50"
                                            id="branchHomologar"
                                            name="branchHomologar" />
                                    </form>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => closeModal(1)}>Atrás</button>
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => nextModal(1)}>Siguiente</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div className="modal" id="confirmHomologar" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Estás a punto de homologar centros de trabajo</h2>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <span>Al dar click en Homologar automáticamente cambiarán los registros al centro de trabajo: <span className="text-base-guay">{branchHomologar}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <div className="row">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                            <span>Eliminandose los centros de trabajo</span>
                                        </div>
                                        {selected.map((branch) => (
                                            listBranches.map((data) => (
                                                branch === data.branchEntId
                                                    ?
                                                    (
                                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                            <span className="text-base-guay font-weight-bold">{data.description}</span>
                                                        </div>
                                                    )
                                                    :
                                                    (null)
                                            ))
                                        ))}
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2 d-flex justify-content-center">
                                    <span className="font-weight-bold">¿Estás seguro de homologar los centros de trabajo?</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => closeModal(2)}>Atrás</button>
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => homologarBranches()}>Homologar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div className="modal" id="homologar-2" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Existe otro centro de trabajo con la misma dirección</h2>
                                </div>
                                {listBraAddr.length > 1 ? (
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                            <p>
                                                Si lo anterior es correcto da click en <span className="font-weight-bold">Guardar</span>. El dar click en <span className="font-weight-bold">Homologar</span> automáticamente cambiarán los registros de los centros de trabajo seleccionados al centro de trabajo: <span className="text-base-guay">{branchHomologar}</span>
                                            </p>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                            <div className="row">
                                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                    <DualListBox
                                                        options={listBraAddr}
                                                        selected={selected_2}
                                                        onChange={onChange_2}

                                                        icons={{
                                                            moveLeft: <span className="la la-chevron-left" />,
                                                            moveAllLeft: [
                                                                <span key={0} className="la la-chevron-left" />,
                                                                <span key={1} className="la la-chevron-left" />,
                                                            ],
                                                            moveRight: <span className="la la-chevron-right" />,
                                                            moveAllRight: [
                                                                <span key={0} className="la la-chevron-right" />,
                                                                <span key={1} className="la la-chevron-right" />,
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                            <p>
                                                Si lo anterior es correcto da click en <span className="font-weight-bold">Guardar</span>. Al dar click en <span className="font-weight-bold">Homologar</span> automáticamente
                                                cambiarán los registros del centro de trabajo de <span className="text-base-guay">{listBraAddr.length > 0 ? listBraAddr[0].label : null}</span> al centro de trabajo <span className="text-base-guay">{branchEntIdHom.description}</span>
                                            </p>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                            <p>Eliminandose el centro de trabajo <span className="text-base-guay font-weight-bold">{listBraAddr.length > 0 ? listBraAddr[0].label : null}</span></p>
                                        </div>
                                    </div>
                                )}

                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2 d-flex justify-content-center">
                                    <span className="font-weight-bold font-medium-2">¿Qué deseas realizar?</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => closeModal(4)}>Atrás</button>
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => forceBranch()}>Guardar</button>
                                    <button
                                        className="btn btn-pink btn-sm pl-5 pr-5 mr-1 pointer float-right"
                                        onClick={() => nextModal(2)}>Homologar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div className="modal" id="confirmHomologar-2" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Estás a punto de homologar centros de trabajo</h2>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <span>Al dar click en continuar automáticamente cambiarán los registros al centro de trabajo: <span className="text-base-guay">{branchHomologar}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <div className="row">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                            <span>Eliminandose los centros de trabajo</span>
                                        </div>
                                        {listBraAddr.map((branch) => (
                                            listBranches.map((data) => (
                                                branch.value === data.branchEntId ?
                                                    (<div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                        <span className="text-base-guay font-weight-bold">{data.description}</span>
                                                    </div>
                                                    )
                                                    : (null)
                                            ))
                                        ))}
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2 d-flex justify-content-center">
                                    <span className="font-weight-bold">¿Estás seguro de homologar los centros de trabajo?</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => closeModal(5)}>Atrás</button>
                                    <button
                                        className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => homologarBranches_2()}>Homologar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div className="modal" id="homologar-3" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Existe otro centro de trabajo con el mismo nombre</h2>
                                </div>
                                {listBraAddr.length > 1 ? (
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                            <p>
                                                Al dar click en <span className="font-weight-bold">Homologar</span> automáticamente cambiarán los registros de los centros de trabajo seleccionados
                                                al centro de trabajo <span className="text-base-guay">{branchHomologar}</span>
                                            </p>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                            <div className="row">
                                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                    <DualListBox
                                                        options={listBraAddr}
                                                        selected={selected_3}
                                                        onChange={onChange_3}

                                                        icons={{
                                                            moveLeft: <span className="la la-chevron-left" />,
                                                            moveAllLeft: [
                                                                <span key={0} className="la la-chevron-left" />,
                                                                <span key={1} className="la la-chevron-left" />,
                                                            ],
                                                            moveRight: <span className="la la-chevron-right" />,
                                                            moveAllRight: [
                                                                <span key={0} className="la la-chevron-right" />,
                                                                <span key={1} className="la la-chevron-right" />,
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                            <p>
                                                Al dar click en <span className="font-weight-bold">Homologar</span> automáticamente cambiarán los registros del centro de trabajo de
                                                {listBraAddr.length > 0 ? listBraAddr[0].label : null} al centro de trabajo <span className="text-base-guay">{branchHomologar}</span>
                                            </p>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center text-center font-medium-2">
                                            <p>Eliminandose el centro de trabajo <span className="text-base-guay font-weight-bold">{listBraAddr.length > 0 ? listBraAddr[0].label : null}</span></p>
                                        </div>
                                    </div>
                                )}
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2 d-flex justify-content-center">
                                    <span className="font-weight-bold font-medium-2">¿Estás seguro de Homologar los centros de trabajo?</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => closeModal(6)}>Atrás</button>
                                    <button
                                        className="btn btn-pink btn-sm pl-5 pr-5 mr-1 pointer float-right"
                                        onClick={() => listBraAddr.length > 1 ? (nextModal(3)) : (homologarBranches_3(1))}>Homologar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div className="modal" id="confirmHomologar-3" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Estás a punto de homologar centros de trabajo</h2>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <span>Al dar click en continuar automáticamente cambiarán los registros al centro de trabajo: <span className="text-base-guay">{branchHomologar}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <div className="row">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                            <span>Eliminandose los centros de trabajo</span>
                                        </div>
                                        {selected_3.map((branch) => (
                                            listBranches.map((data) => (
                                                branch === data.branchEntId
                                                    ?
                                                    (
                                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                            <span className="text-base-guay font-weight-bold">{data.description}</span>
                                                        </div>
                                                    )
                                                    :
                                                    (null)
                                            ))
                                        ))}
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2 d-flex justify-content-center">
                                    <span className="font-weight-bold">¿Estás seguro de homologar los centros de trabajo?</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => closeModal(7)}>Atrás</button>
                                    <button
                                        className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => homologarBranches_3(2)}>Homologar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal - Force add branch -->*/}
                <div className="modal" id="addBranch" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Agrega centro de trabajo</h2>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <form>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="branchName-1"
                                                id="branchName-1"
                                                className="form-control"
                                                value={branchName}
                                                onChange={(e) => { setBranchName(e.target.value) }} />
                                        </div>
                                    </form>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => { $("#addBranch").hide(); setBranchName(""); $("#branchName-1").val("") }}>Atrás</button>
                                    <button
                                        className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => handleClickAdd()}>Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal - Force add branch -->*/}
                <div className="modal" id="forceAddBranch" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body scrollbar-style">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <img src="./../img/collabImages/iconos_carga_colaboradores-11.png" alt="img-guay" style={{ height: '100px', width: '100px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-2">
                                    <h2 className="align-middle font-weight-bold">Existe un centro de trabajo con el nombre parecido a <span>{branchName}</span></h2>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 text-center font-medium-2">
                                    <span>¿Deseas crearlo de cualquier manera?</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <button
                                        className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                        onClick={() => { $("#forceAddBranch").hide(); $("#addBranch").show() }}>Atrás</button>
                                    <button
                                        className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                                        onClick={() => forceAddBranch()}>Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default RegisterBranches;
