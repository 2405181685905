import React, { useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import $ from "jquery"; 
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { valAssistanceConsent } from '../../Models/ClinicalFilterHTK_model';
import PregnantMessageModal from './PregnantMessageModal';

const Intro = ({
    collaboratorId,
    idEnterprise,
    setIdCollabSurveyControlFCHTK,
    idCollabSurveyControlFCHTK,
    setPregnantMess,
    setNoConsent,
    setShowSurvey,
    setConsent,
    handleClickUp
}) => {
    //const [checked, setChecked] = useState(false);
    const [privacyCheck, setPrivacyCheck] = useState(false);
    const [consentCheck, setConsentCheck] = useState(false);
    const [femaleIcon, setFemaleIcon] = useState('../img/filtroClinico/Icono_mujer_default.png');
    const [maleIcon, setMaleIcon] = useState('../img/filtroClinico/Icono_hombre_default.png');
    const [gender, setGender] = useState(0);
    const [isPregnant, setIsPregnant] = useState(null);
    const [isAvailable, setIsAvailable] = useState(false)

    /**
     * 
     * @param {event} e 
     */
    const startSurvey = e => {
        e.preventDefault();
        if(privacyCheck && consentCheck && isAvailable){
            if(gender === 0){ //No ha seleccionado el perfil
                toastr.warning("Seleccione su perfil.", "¡Ooops!");
            }else if(gender === 2){ //Es mujer
                if(isPregnant === null){ // No ha contestado si esta embarazada
                    toastr.warning("Indique si esta embarazada.", "¡Ooops!");
                }else{
                    console.log('valAssistanceConsent(',collaboratorId,', ',idEnterprise,', ',idCollabSurveyControlFCHTK,', 1,', isPregnant,')');
                    valAssistanceConsent(collaboratorId, idEnterprise, idCollabSurveyControlFCHTK, 1, isPregnant).then((response) => {
                        if (response) {
                            switch (response.code) {
                                case 1:
                                    // setConsent(true);
                                    setIdCollabSurveyControlFCHTK(response.idCollabSurveyControlFCHTK);
                                    // isPregnant === 1 && setPregnantMess(true);
                                    if(isPregnant === 1){
                                        $('#PregnantModal').show();
                                    }else{
                                        setConsent(true); 
                                        handleClickUp(); 
                                    }
                                    setShowSurvey(false);                                    
                                    
                                    break;
                                case 1001:
                                    toastr.error("La empresa o colaborador no se encuentra activa.");
                                    break;
                                default:
                                    toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                                    break;
                            }
                        } else {
                            toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                        }
                    });
                }
            }else{ //Es hombre
                console.log('valAssistanceConsent(',collaboratorId,', ',idEnterprise,', ',idCollabSurveyControlFCHTK,', 1, 0)');
                valAssistanceConsent(collaboratorId, idEnterprise, idCollabSurveyControlFCHTK, 1, 0).then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                setIdCollabSurveyControlFCHTK(response.idCollabSurveyControlFCHTK);
                                setConsent(true);
                                handleClickUp();
                                break;
                            case 1001:
                                toastr.error("La empresa o colaborador no se encuentra activa.");
                                break;
                            default:
                                toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                                break;
                        }
                    } else {
                        toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                    }
                });
            }
        }else{
            // toastr.warning("Debe indicar que leyó y acepto el Aviso de privacidad, los Términos y Condiciones, así como el documento de Consentimiento informado.", "¡Ooops!");
        }
    }

    // Función para manejar el cambio de valor de Aceptacion del Aviso de Privacidad
    /* const handleChangePrivacy = (event) => {
        setChecked(event.target.checked);
        setChecked2(event.target.checked);
        if(checked && checked2){
            setIsAvailable(false)
        }
        //event.target.checked === false && setIsAvailable(false);
    }; */

    const handleContinue = () => {
        if(privacyCheck && consentCheck){
         // element which needs to be scrolled to
         const element = document.getElementById("experiencia");
         // scroll to element
         element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
         setIsAvailable(true);
        }
    }
  return (
    <div className="col-12 bg-white-smoke" >
        <PregnantMessageModal/>
        <div className='row'>
            <div className='col-sm-12 col-md-11 col-lg-11 col-xl-11 mx-auto'>
                <div className='card mt-1 box-shadow-guay-1 text-blue-sec'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6 align-content-center'>
                                <img
                                    src="../img/filtroClinico/1-Bienvenida.png"
                                    className="w-100"
                                    alt='logo'
                                />
                            </div> 
                            <div className='col-sm-12 col-md-6 col-lg-6 align-content-center font-quicksand fs-1-4em'>
                                <p className='text-justify'>Es una prueba basada en ciencia con la que <span className='font-weight-bold'>tendrás una guía</span> para manejar tus emociones y sentirte mejor {''} <br/><br/>
                                    <span className='font-weight-bold'>Sólo para ti,</span> recibirás: {''}<br/>
                                </p>   
                                <ul className='font-weight-bold'>
                                    <li>Diagnóstico preliminar</li>
                                    <li>Actividades recomendadas de autogestión</li>
                                </ul> {''} 
                                <p className='text-justify'>
                                    No reemplaza la ayuda de un profesional
                                </p>                                               
                            </div> 
                            <div className='col-12'>
                                <FormControlLabel value={1}
                                    control={
                                        <Checkbox  icon={<i className="ft ft-circle  align-middle text-white-blue-seagull fs-1-2em" />}
                                            checkedIcon={<i className="ft ft-check-circle text-white-blue-seagull align-middle fs-1-2em" />}
                                            checked={privacyCheck}
                                            onChange={() => setPrivacyCheck(!privacyCheck)}
                                            size="small" />
                                    }
                                    label={<p className='font-quicksand my-1 fs-1-2em'>He leído y acepto el <span className='text-underline' onClick={()=>window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>Aviso de privacidad y Términos y Condiciones</span></p>}
                                />
                            </div>
                              <div className='col-12'>
                                  <FormControlLabel 
                                  value={1}
                                      control={
                                          <Checkbox
                                              icon={<i className="ft ft-circle  align-middle text-white-blue-seagull fs-1-2em" />}
                                              checkedIcon={<i className="ft ft-check-circle text-white-blue-seagull align-middle fs-1-2em" />}
                                              checked={consentCheck}
                                              onChange={() => setConsentCheck(!consentCheck)}
                                              size="small"
                                          />
                                      }
                                      label={<p className='font-quicksand my-1 fs-1-2em'>He leído y acepto el <span className="text-underline pointer" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Consentimiento_informado_102020.pdf")}>Consentimiento Informado</span></p>}
                                  />
                              </div>
                            <div className='col-12 d-flex justify-content-center mb-1'>
                                {/* <button className={`${checked ? 'btn btn-sm border-primary-1 bg-primary-1 fs-1-4em text-white px-3 rounded-pill-1 pointer' : 'btn btn-sm border-0 bg-secundary-3 text-white px-3 fs-1-4em rounded-pill-1'}`} onClick={handleContinue}>Continuar</button> */}
                                  <button
                                      className={`${privacyCheck && consentCheck ?
                                          'btn btn-sm border-primary-1 bg-primary-1 fs-1-4em text-white px-3 rounded-pill-1 pointer' :
                                          'btn btn-sm border-0 bg-secundary-3 text-white px-3 fs-1-4em rounded-pill-1'}`}
                                      onClick={handleContinue}
                                  >Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-11 col-lg-11 col-xl-11 mx-auto' id="experiencia">
                <div className={privacyCheck && consentCheck && isAvailable ? 'card mt-1 box-shadow-guay-1 text-blue-sec' : 'card mt-1 box-shadow-guay-1 text-blue-sec div-dissable-style-2'}>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6 align-content-center'>
                                <img
                                    src="../img/filtroClinico/2-Experiencia.png"
                                    className="w-100"
                                    alt='Experiencia'
                                />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6 text-blue-sec font-quicksand '>
                                <p className='text-justify font-weight-bold fs-1-4em'>Recorreras los siguientes pasos:</p>
                                <ul className=' text-base-guay'>
                                    <li><span className='font-weight-bold fs-1-3em text-blue-sec'>Paso 1</span> {''} <br/>
                                        <p className='text-blue-sec fs-1-2em'>Comenzarás con preguntas de antecedentes referentes a tu bienestar mental. {''}
                                        Tiempo aprox. 3 minutos</p>
                                    </li>
                                    <li><span className='font-weight-bold fs-1-3em text-blue-sec'>Paso 2</span> {''} <br/>
                                        <p className='text-blue-sec fs-1-2em'>Identificarás síntomas a nivel físico y emocional de los últimos 5 días. {''}
                                        Tiempo aprox. 10 a 15 minutos</p>
                                    </li>
                                    <li><span className='font-weight-bold fs-1-3em text-blue-sec'>Tus resultados</span> {''} <br/>
                                        <p className='text-blue-sec fs-1-2em'>Obtendrás tu pre-diagnóstico y las actividades recomendadas para mejorar tu bienestar mental.</p>
                                    </li>
                                </ul>
                            </div> 
                            <div className='col-12 mb-1 d-flex justify-content-center'>
                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 text-center bg-pacific-blue rounded-pill-1 mb-1'>
                                    <span className='text-white font-weight-bold fs-1-4em'>¿Estás listo para comenzar?</span>
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-center'>
                                <span className='text-blue-sec font-weight-bold fs-1em'>Selecciona tu perfil</span>
                            </div>
                            <div className='col-12 d-flex justify-content-center'>
                                <img
                                    src={maleIcon}
                                    className={`${privacyCheck && consentCheck && isAvailable ? 'mr-1 mb-1 pointer': 'mr-1 mb-1'}`}
                                    style={{ height: '100px' }}
                                    alt='logo'
                                    onMouseOver={() => privacyCheck && consentCheck && isAvailable && setMaleIcon('../img/filtroClinico/Icono_hombre_over.png')}
                                    onMouseOut={() => privacyCheck && consentCheck && isAvailable && setMaleIcon(gender === 0 || gender === 2 ? '../img/filtroClinico/Icono_hombre_default.png' : '../img/filtroClinico/Icono_hombre_seleccionado.png')}
                                    onClick={() => {
                                        if(privacyCheck && consentCheck && isAvailable){
                                            setGender(1);
                                            gender === 2 && setFemaleIcon('../img/filtroClinico/Icono_mujer_default.png');
                                            setMaleIcon('../img/filtroClinico/Icono_hombre_seleccionado.png');
                                            setIsPregnant(null) 
                                        }
                                    }}
                                />
                                <img
                                    src={femaleIcon}
                                    className={`${privacyCheck && consentCheck && isAvailable ? 'mr-1 mb-1 pointer': 'mr-1 mb-1'}`}
                                    style={{ height: '100px' }}
                                    alt='logo'
                                    onMouseOver={() => privacyCheck && consentCheck && isAvailable && setFemaleIcon('../img/filtroClinico/Icono_mujer_over.png')}
                                    onMouseOut={() => privacyCheck && consentCheck && isAvailable && setFemaleIcon(gender === 0 || gender === 1 ? '../img/filtroClinico/Icono_mujer_default.png' : '../img/filtroClinico/Icono_mujer_seleccionada.png')}
                                    onClick={() => {
                                        if(privacyCheck && consentCheck && isAvailable){
                                            setGender(2);
                                            gender === 1 && setMaleIcon('../img/filtroClinico/Icono_hombre_default.png');
                                            setFemaleIcon('../img/filtroClinico/Icono_mujer_seleccionada.png');
                                        }
                                    }}
                                />
                            </div>
                            {
                                gender === 2 && (
                                    <>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <p className='font-weight-bold'>¿Estás embarazada?</p>
                                        </div>
                                        <div className='col-12 mx-1 fs-1em d-flex justify-content-center'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={<i className="ft ft-square text-base align-middle" />}
                                                        disabled={privacyCheck && consentCheck && isAvailable ? false : true}
                                                        checkedIcon={<i className="ft ft-check-square text-green align-middle" />}
                                                        checked={isPregnant===1}
                                                        onChange={() => setIsPregnant(1)}
                                                    />
                                                }
                                                label="Si"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={<i className="ft ft-square text-base align-middle" />}
                                                        checkedIcon={<i className="ft ft-check-square text-green align-middle" />}
                                                        disabled={privacyCheck && consentCheck && isAvailable ? false : true}
                                                        checked={isPregnant===0}
                                                        onChange={() => setIsPregnant(0)}
                                                    />
                                                }
                                                label="No"
                                            />
                                        </div>
                                    </>
                                )
                            }
                                
                            <div className='col-12 d-flex justify-content-center py-1'>
                                <button  className={privacyCheck && consentCheck && gender > 0 ? gender === 1 ? "btn btn-sm border-primary-1 bg-primary-1 fs-1-3em text-white px-3 rounded-pill-1 pointer" : isPregnant === null ? "btn btn-sm border-0 bg-secundary-3 text-white px-3 fs-1-3em rounded-pill-1" : "btn btn-sm border-primary-1 bg-primary-1 fs-1-3em text-white px-3 rounded-pill-1 pointer" : "btn btn-sm border-0 bg-secundary-3 text-white px-3 fs-1-3em rounded-pill-1"} onClick={startSurvey} >Comenzar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Intro
