import axios from 'axios'

export const getProfileInformation = async (idUser) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}profile/getProfileInformation?idUser=${idUser}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

export const uploadImgProfileUser = async (data) => {
    try {
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}profile/uploadImgProfileUser`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: data,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

export const setProfileInformation = async (data) => {
    try {
        let dataJSON = JSON.stringify(data)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}profile/setProfileInformation`,
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'application/json',
            },
            data: dataJSON,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
