import React, { Component } from 'react';
import $ from "jquery";
import Swal from "sweetalert2";
import { getDataUsrHTK, setDataUsrHTK } from './Model';
import Drivers from './Drivers';
import Mood from './Mood';
import Health from './Health';
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
//import Loader from 'react-loader-spinner';

export default class ControlSurvey extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            loading: true,
            checked: false,
            request: {
                idEnterprise: 0,
                idExercise: 0,
                idCollab: 0,
                period: 0,
                idGroup: 0
            },
            questions: [],
            options: [],
            sections: [],
            data: {
                enterprise: "",
                name: "",
                idEncuesta: "",
                logo: "../img/logo_guay_color.png",
                status: "",
                avisopriv: "",
                code: "",
                entName: "",
                idCollab: "",
                idExercise: ""
            },
            results: {},
            response: {},
            answers: []
        }
        this.setResponse = this.setResponse.bind(this);
    }

    componentDidMount() {
        try {
            let getObject = this.props.match.params;
            let param = atob(getObject.db)
            let obj = param.split("=");
            this.setState({
                request: {
                    idEnterprise: parseInt(obj[0]),
                    idExercise: parseInt(obj[1]),
                    idCollab: parseInt(obj[3]),
                    period: parseInt(obj[2]),
                    idGroup: parseInt(obj[4])
                }
            }, () => {
                getDataUsrHTK(this.state.request).then((res) => {
                    if (res) {
                        this.setState({
                            data: {
                                enterprise: res.idEnterprise,
                                name: res.name,
                                idEncuesta: res.idEncuesta,
                                logo: res.logo,
                                status: res.status,
                                avisopriv: res.avisopriv,
                                code: res.code,
                                entName: res.comercialName,
                                idCollab: res.idCollab,
                                idExercise: res.idExercise,
                                message: res.swal ? res.swal : ""
                            },
                            sections: res.sections,
                            questions: res.questions,
                            options: res.options,
                            answers: res.responses
                        }, () => {
                            this.loadData();
                        });

                    } else {
                        toastr.error("Ocurrió un error, intentalo nuevamente.");
                    }
                });
            });

            //   this.loadDataSurvey();
        } catch (error) {
            console.log(error);
        }
    }

    loadData() {
        try {
            // [VZO] 29/01/21 Ignore warning -> 'day1' is assigned a value but never used  no-unused-vars
            // eslint-disable-next-line
            let day1 = 0;
            let day2 = 0;
            let day3 = 0;
            let day4 = 0;
            let day5 = 0;
            if (this.state.answers.idDay1) {
                $("#d-dia-1 ,#m-dia-1 ,#h-dia-1").find(".icon-res").addClass("disabled cursor-not-allowed text-disabled");
                $("#d-dia-1 ,#m-dia-1 ,#h-dia-1").find(".icon-res").removeClass("pull-up");
                for (let i = 0; i < 24; i++) {
                    $(".icon-res[data-day='1'][data-res='" + this.state.answers.idDay1[i].idResponse + "'][data-question='" + this.state.answers.idDay1[i].idQuestion + "']").removeClass("text-disabled")
                }
                // eslint-disable-next-line
                day1 = 1;
            }
            if (this.state.answers.idDay2) {
                $("#d-dia-2 ,#m-dia-2 ,#h-dia-2").find(".icon-res").addClass("disabled cursor-not-allowed text-disabled");
                $("#d-dia-2 ,#m-dia-2 ,#h-dia-2").find(".icon-res").removeClass("pull-up");
                for (let i = 0; i < 24; i++) {
                    $(".icon-res[data-day='2'][data-res='" + this.state.answers.idDay2[i].idResponse + "'][data-question='" + this.state.answers.idDay2[i].idQuestion + "']").removeClass("text-disabled")
                }
                day2 = 2;
            }
            if (this.state.answers.idDay3) {
                $("#d-dia-3 ,#m-dia-3 ,#h-dia-3").find(".icon-res").addClass("disabled cursor-not-allowed text-disabled");
                $("#d-dia-3 ,#m-dia-3 ,#h-dia-3").find(".icon-res").removeClass("pull-up");
                for (let i = 0; i < 24; i++) {
                    $(".icon-res[data-day='3'][data-res='" + this.state.answers.idDay3[i].idResponse + "'][data-question='" + this.state.answers.idDay3[i].idQuestion + "']").removeClass("text-disabled")
                }
                day3 = 1;
            }
            if (this.state.answers.idDay4) {
                $("#d-dia-4 ,#m-dia-4 ,#h-dia-4").find(".icon-res").addClass("disabled cursor-not-allowed text-disabled");
                $("#d-dia-4 ,#m-dia-4 ,#h-dia-4").find(".icon-res").removeClass("pull-up");
                for (let i = 0; i < 24; i++) {
                    $(".icon-res[data-day='4'][data-res='" + this.state.answers.idDay4[i].idResponse + "'][data-question='" + this.state.answers.idDay4[i].idQuestion + "']").removeClass("text-disabled")
                }
                day4 = 1;
            }
            if (this.state.answers.idDay5) {
                $("#d-dia-5 ,#m-dia-5 ,#h-dia-5").find(".icon-res").addClass("disabled cursor-not-allowed text-disabled");
                $("#d-dia-5 ,#m-dia-5 ,#h-dia-5").find(".icon-res").removeClass("pull-up");
                for (let i = 0; i < 24; i++) {
                    $(".icon-res[data-day='5'][data-res='" + this.state.answers.idDay5[i].idResponse + "'][data-question='" + this.state.answers.idDay5[i].idQuestion + "']").removeClass("text-disabled")
                }
                day5 = 1;
            }



            $("#drivers, #mood, #health").find(".tab-pane").removeClass("active")
            $("#drivers, #mood, #health").find(".nav-link").removeClass("active")

            if (day2 === 0) {
                $(".nav-link[data-day='2']").addClass("active")
                $(".nav-link[data-day='2']").removeClass("disabled")
                $("#d-dia-1 ,#m-dia-1 ,#h-dia-1").removeClass("active");
                $("#d-dia-2 ,#m-dia-2 ,#h-dia-2").addClass("active");
                $("#send").attr("data-day", 2);
                $("#send").attr("data-next", 3);
            } else if (day3 === 0) {

                $(".nav-link[data-day='3']").addClass("active")
                $(".nav-link[data-day='3']").removeClass("disabled")
                $(".nav-link[data-day='2']").removeClass("disabled")
                $("#d-dia-1 ,#m-dia-1 ,#h-dia-1").removeClass("active");
                $("#d-dia-2 ,#m-dia-2 ,#h-dia-2").removeClass("active");
                $("#d-dia-3 ,#m-dia-3 ,#h-dia-3").addClass("active");
                $("#send").attr("data-day", 3);
                $("#send").attr("data-next", 4);
            } else if (day4 === 0) {
                $(".nav-link[data-day='4']").addClass("active")
                $(".nav-link[data-day='4']").removeClass("disabled")
                $(".nav-link[data-day='2']").removeClass("disabled")
                $(".nav-link[data-day='3']").removeClass("disabled")
                $("#d-dia-1 ,#m-dia-1 ,#h-dia-1").removeClass("active");
                $("#d-dia-2 ,#m-dia-2 ,#h-dia-2").removeClass("active");
                $("#d-dia-3 ,#m-dia-3 ,#h-dia-3").removeClass("active");
                $("#d-dia-4 ,#m-dia-4 ,#h-dia-4").addClass("active");
                $("#send").attr("data-day", 4);
                $("#send").attr("data-next", 5);
            } else if (day5 === 0) {
                $(".nav-link[data-day='5']").addClass("active")
                $(".nav-link[data-day='5']").removeClass("disabled")
                $(".nav-link[data-day='2']").removeClass("disabled")
                $(".nav-link[data-day='3']").removeClass("disabled")
                $(".nav-link[data-day='4']").removeClass("disabled")
                $("#d-dia-1 ,#m-dia-1 ,#h-dia-1").removeClass("active");
                $("#d-dia-2 ,#m-dia-2 ,#h-dia-2").removeClass("active");
                $("#d-dia-3 ,#m-dia-3 ,#h-dia-3").removeClass("active");
                $("#d-dia-4 ,#m-dia-4 ,#h-dia-4").removeClass("active");
                $("#d-dia-5 ,#m-dia-5 ,#h-dia-5").addClass("active");
                $("#send").attr("data-day", 5);
                $("#send").attr("data-next", 0);
            }

        } catch (error) {
            console.log(error);
        }
    }

    setResponse(event) {
        try {
            let $currentTarget = $(event.currentTarget);
            let day = $currentTarget.attr("data-day");
            let next = $currentTarget.attr("data-next");

            this.setState({
                results: {
                    ...this.state.results,
                    idQuestion: $(".result").attr("data-question"),
                    idResponse: $(".result").attr("data-res")
                }
            });

            var array = [];
            var data = [];
            $(".result").each(function (index) {
                array = {
                    "idQuestion": $(this).attr("data-question"),
                    "idResponse": $(this).attr("data-res")
                };
                data.push(array);
            });
            this.setState({
                response: data
            }, () => {
                console.log(this.state.response.length);
                if (this.state.response.length === 24) {
                    this.setState({
                        results: {
                            idEnterprise: this.state.data.enterprise,
                            idExercise: this.state.data.idExercise,
                            idCollab: this.state.data.idCollab,
                            day: day,
                            idGroup: this.state.request.idGroup,
                            period: this.state.request.period
                        },
                    }, () => {
                        let formData = new FormData();
                        formData.append("data", JSON.stringify(this.state.results));
                        formData.append("results", JSON.stringify(this.state.response));
                        setDataUsrHTK(formData).then((res) => {
                            if (res.code) {

                                if (day === "5") {
                                    Swal.fire({
                                        title: '<p class="text-white mb-2">¡Éxito!</p>',
                                        text: "Gracias por completar el registro de la información.",
                                        showCloseButton: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        dangerMode: true
                                    }).then(() => {
                                        window.location.reload();
                                    });
                                    $(".swal2-title").addClass("bg-header-swal");
                                    $(".swal2-actions").addClass("w-100 justify-content-around");
                                    $(".swal2-confirm").removeClass("swal2-styled");
                                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                                    $(".swal2-cancel").removeClass("swal2-styled");
                                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                                } else {
                                    Swal.fire({
                                        title: '<p class="text-white mb-2">¡Éxito!</p>',
                                        text: "Información registrada correctamente",
                                        showCloseButton: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        dangerMode: true
                                    });
                                    $(".swal2-title").addClass("bg-header-swal");
                                    $(".swal2-actions").addClass("w-100 justify-content-around");
                                    $(".swal2-confirm").removeClass("swal2-styled");
                                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                                    $(".swal2-cancel").removeClass("swal2-styled");
                                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                                }
                                this.updateDayActive(day, next);

                            } else {
                                Swal.fire({
                                    title: '<p class="text-white mb-2">¡Oops!</p>',
                                    text: "Ocurrió un error, al registar intenta nuevamente",
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    dangerMode: true
                                });
                                $(".swal2-title").addClass("bg-header-swal");
                                $(".swal2-actions").addClass("w-100 justify-content-around");
                                $(".swal2-confirm").removeClass("swal2-styled");
                                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                                $(".swal2-cancel").removeClass("swal2-styled");
                                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                            }
                        });
                    });
                } else {
                    Swal.fire({
                        title: '<p class="text-white mb-2">Espera</p>',
                        text: "Algunas respuestas del día no se han contestado. Recuerda todas la preguntas deben de ser respondidas",
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        dangerMode: true
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
            });



            //console.log(this.state.results)
        } catch (error) {
            console.log(error);
        }
    }

    updateDayActive(day, nextDay) {
        try {
            $("#d-dia-" + day + ",#m-dia-" + day + " ,#h-dia-" + day + "").find(".icon-res").removeClass("result pull-up cursor-pointer");
            $("#d-dia-" + day + ",#m-dia-" + day + " ,#h-dia-" + day + "").find(".icon-res").addClass("cursor-not-allowed");
            $("#drivers, #mood, #health").find(".tab-pane").removeClass("active")
            $("#drivers, #mood, #health").find(".nav-link").removeClass("active")
            /** */
            $(".nav-link[data-day='" + nextDay + "']").addClass("active")
            $(".nav-link[data-day='" + nextDay + "']").removeClass("disabled")
            $("#d-dia-" + day + " ,#m-dia-" + day + " ,#h-dia-" + day + "").removeClass("active");
            $("#d-dia-" + nextDay + " ,#m-dia-" + nextDay + " ,#h-dia-" + nextDay + "").addClass("active");
            $("#send").attr("data-day", nextDay);
            $("#send").attr("data-next", parseInt(nextDay) + parseInt(1));
        } catch (error) {
            console.log(error);
        }
    }



    render() {   // eslint-disable-next-line
        const { loading } = this.state;
        /*if (loading) { // if your component doesn't have to wait for async data, remove this block 
            return (
                <div className="col-md-12">
                    <div className="row d-flex align-items-center justify-content-center" style={{ marginTop: "100%" }}>
                        <Loader type="Oval" color="#00BFFF" height={80} width={80} />
                    </div>
                </div>
            )
        }*/
        return (
            <React.Fragment>
                <div className="content-header row mt-2">
                    <div className="col-md-2"></div>
                    <div className="content-header-left col-md-2 col-12 mb-1">
                        <img src={this.state.data.logo} className="w-75" alt="htk" />
                    </div>
                    <div className="col-md-4 text-center mt-2">
                        <h1 className="text-base-guay" >Health Tracking</h1>
                        <h2 className="text-blue" >{this.state.data.entName} </h2>
                    </div>
                    <div className="content-header-right col-md-2 col-12 text-right mt-2">

                        <img src="../img/logo_guay_color.png" className="w-75" alt="logo" />
                    </div>
                </div>
                <section className="row mt-2">
                    <div className="col-xs-12 col-md-1"></div>
                    <div className="col-xs-12 col-md-10 d-flex align-items-center justify-content-center">
                        <div className="col-xs-12 col-lg-10 col-md-10  box-shadow-2 p-0">
                            {this.state.data.status <= 0 ? (
                                <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                                    <div className="card-header border-0 ">
                                        <h2 className="text-blue">{this.state.data.name}</h2>
                                        <p>El siguiente instrumento de medición considera un periodo de 5 días consecutivos.
                                            Su finalidad es evaluar su tendencia en comportamientos y síntomas. </p>
                                        <h3 className="text-base-guay">1. {this.state.sections.length > 0 ? this.state.sections[0].description : ""}</h3>
                                        <p>De acuerdo a la escala de calificación y a tu estilo de vida; ¿El día de hoy, qué tanto has hecho o llevado a cabo las siguientes actividades que aportan a tu calidad de vida?</p>
                                        <div className="mt-2">
                                            {this.state.questions.length > 0 ?
                                                <Drivers questions={this.state.questions}
                                                    options={this.state.options}></Drivers>
                                                : ""}
                                        </div>
                                        <br />
                                        <h3 className="text-base-guay mt-2">2. {this.state.sections.length > 0 ? this.state.sections[1].description : ""}</h3>
                                        <p>De acuerdo a la escala de calificación, identifica con qué intensidad has sentido las siguientes emociones o sentimientos durante el día de hoy:</p>
                                        <div className="mt-2">
                                            {this.state.questions.length > 0 ?
                                                <Mood questions={this.state.questions}
                                                    options={this.state.options}></Mood>
                                                : ""}
                                        </div>
                                        <br />
                                        <h3 className="text-base-guay mt-2">3. {this.state.sections.length > 0 ? this.state.sections[2].description : ""}</h3>
                                        <p>De acuerdo a la escala de calificación, ¿Con qué intensidad has vivido el día de hoy, los siguientes síntomas o respuestas físicas?</p>
                                        <div className="mt-2">
                                            {this.state.questions.length > 0 ?
                                                <Health questions={this.state.questions}
                                                    options={this.state.options}></Health>
                                                : ""}
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-12 text-right">
                                                <button type="button" id="send" data-day="1" data-prev="0" data-next="2" class="btn btn-blue btn-min-width mr-1 mb-1" onClick={this.setResponse}>Guardar</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ) :
                                <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                                    <div className="card-header border-0 text-center ">
                                        <h2 className="text-blue">{this.state.data.name}</h2>
                                        <p><i class="las la-user-check font-large-2 text-success"></i>{this.state.data.message}</p>
                                    </div>

                                </div>
                            }

                        </div>
                    </div>
                </section>
            </React.Fragment>
        )

    }
}
