import React, { useEffect, useState } from 'react';
import { getBranchesLaunch } from '../Models/Enterprise_model';
import { getReminders } from '../Models/Catalog_model';
import { getDay, addDays } from 'date-fns';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const TablaLanzamientos = ({ enterpriseId, productData, centrosTrabajo, setCentrosTrabajo, flgLaunch }) => {

    const [centros, setcentros] = useState([]);
    const [centrosBef, setcentrosBef] = useState([]);
    const [optionRec, setoptionRec] = useState([]);
    const today = new Date();
    useEffect(() => {
        getBranchesLaunch(enterpriseId, productData.idProduct, productData.idCategory, productData.idSubcategory, 1, flgLaunch).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setcentrosBef(response.listBranchLaunch);
                    const array = response.listBranchLaunch;
                    for (let index = 0; index < array.length; index++) {
                        if (array[index].launchDate === "0") {
                            array[index].launchDate = null;
                        } else {
                            array[index].launchDate = new Date(convert(array[index].launchDate));
                        }
                    }
                    setcentros(array);
                }
            }
        });
        getReminders().then((response) => {
            if (response) {
                if (response.code === 1) {
                    setoptionRec(response.listReminderLaunch)
                }
            }
        });
        return () => {
        }
    }, [productData, enterpriseId, flgLaunch])

    function convert(str) {
        var parts = str.match(/(\d+)/g);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }

    const isWeekday = date => {
        const currentDate = new Date();
        const selectedDate = new Date(date);
        const day = getDay(date);
        return day !== 0 && day !== 6 && selectedDate > currentDate;
    };

    const setDate = (idBranch, date) => {
        const array = centros.map(centro => {
            if (centro.idBranch === idBranch) {
                return { ...centro, launchDate: date }
            } else {
                return { ...centro }
            }
        });
        setcentros(array);
        setCentrosTrabajo(array);
    }

    const handleKeydown = (e) => {
        e.preventDefault();
    }

    const datepickerFormat = (cell, row, data1, index) => {
        return (
            <Datepicker
                dateFormat="dd-MM-yyyy"
                selected={centros[index].launchDate}
                onChange={date => setDate(row.idBranch, date)}
                filterDate={isWeekday}
                excludeDates={today.getDay() === 4 ? ([addDays(new Date(), 1), addDays(new Date(), 4)]) : (today.getDay() === 5 ? ([addDays(new Date(), 3), addDays(new Date(), 4)]) : ([addDays(new Date(), 1), addDays(new Date(), 2)]))}
                placeholderText="Selecciona una fecha"
                disabled
                onKeyDown={(e) => handleKeydown(e)}
                className="form-control py-0 height-sm-1"
            />
        )
    }

    const setReminder = (idBranch, idReminder) => {
        const array = centros.map(centro => {
            if (centro.idBranch === idBranch) {
                return { ...centro, idReminderLaunch: parseInt(idReminder) }
            } else {
                return { ...centro }
            }
        });
        setcentros(array);
        setCentrosTrabajo(array);
    }

    const selectFormat = (cell, row, data1, index) => {
        return (
            <select className="form-control dropdown-toggle form-control-sm border-0" name="idReminder"
                value={centros[index].idReminderLaunch}
                onChange={(e) => setReminder(row.idBranch, e.target.value)}
                disabled
            >
                <option>Selecciona</option>
                {
                    optionRec.map((option, i) => {
                        return (
                            <option key={option.idReminderLaunch} value={option.idReminderLaunch}>{option.description}</option>
                        )
                    })
                }
            </select >
        )
    }

    const statusFormat = (cell, row, data1, index) => {
        return (
            <div className="bg-warning text-white text-center">
                {centrosBef[index].launchDate === null ? <div className={"background-yellow"}>pendiente</div> : <div className={"background-green"}>programado</div>}
            </div>
        )
    }

    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        noDataText: 'No se encontraron registros.',
    }

    return (
        <div className="row">
            <div className="col-12 table-responsive">
                <p className="font-medium-4 text-center text-gray mb-4">Configura los lanzamientos para cada centro de trabajo</p>
                <BootstrapTable
                    data={centros}
                    striped
                    hover
                    condensed
                    pagination={true}
                    tableStyle={{ border: 'none', padding: '0px', fontSize: '1.2rem' }}
                    trClassName='tr-data-table table-checkbox'
                    options={options}
                >
                    <TableHeaderColumn isKey dataField="idBranch" hidden></TableHeaderColumn>
                    <TableHeaderColumn dataField="description" dataAlign="left" width="25%" tdStyle={{ fontSize: '1.2rem' }}>Centro de trabajo</TableHeaderColumn>
                    <TableHeaderColumn dataField="launchDate" dataAlign="center" width="21%" tdStyle={{ fontSize: '1.2rem' }} dataFormat={datepickerFormat}>Lanzamiento</TableHeaderColumn>
                    <TableHeaderColumn dataField="idReminderLaunch" dataAlign="center" width="33%" tdStyle={{ fontSize: '1.2rem' }} dataFormat={selectFormat}>Recordatorio</TableHeaderColumn>
                    <TableHeaderColumn dataField="launchDate" dataAlign="center" width="20%" tdStyle={{ fontSize: '1.2rem' }} dataFormat={statusFormat}>Estatus</TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    )
}

export default TablaLanzamientos

