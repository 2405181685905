import React, { useState } from 'react'
import { NavLink } from "react-router-dom"
// API calls
import { getuserData } from "../app/NewHome/Model";
// Tools
import axios from "axios"
import $ from "jquery"
import { setJwt } from "../../lib/auth"
import { endpoint } from "../../lib/api/api"
import * as toastr from 'toastr'
import { max_length_inp } from "../../lib/utils/generalFuntions"
import { changeEnterpriseAction } from '../../redux/generalDuck';
import { connect } from 'react-redux';

import "animate.css"

const ModalLogin = ({changeEnterpriseAction}) => {
    const [regisData, updStateReg] = useState({
        name: "",
        email: "",
        phone: "",
        key: "",
        type: "1",
        usePhone: 0,
        isValidate: 0,
        user: 0,
        img: "",
        lastName: "",
        tokenApple: ""
    });
    const getValue = (e) => {
        try {
            let value = e.target.value;
            if (e.target.id === "user") {
                max_length_inp(e.target.id, 100)
            }
            if (value === null || value.length === 0) {
                $("#" + e.target.name).removeClass("border-success");
                $("#" + e.target.name).addClass("border-danger");
            } else {
                $("#" + e.target.name).removeClass("border-danger");
                $("#" + e.target.name).addClass("border-success");
            }

            updStateReg({
                ...regisData,
                [e.target.name]: value,
            });
        } catch (error) {
            console.log(error);
        }
    }
    const validaUser = async (e) => {
        e.preventDefault();
        $('#btn-login').text("").prop('disabled', true).addClass("btn-guay-processing").append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite	infinite"></i> Procesando...');
        $("#form-users :input").prop("disabled", true);
        try {
            let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
            if (!regex.test(regisData.email)) {
                toastr.warning('La sintaxis de tu correo es incorrecta.', '¡Ooops!');
                setTimeout(function () {
                    $('#btn-login').text("").prop('disabled', false).append('Ingresar').removeClass("btn-guay-processing");
                    $("#form-users :input").prop("disabled", false);
                }, 3000);
                return;
            }
            if (regisData.email.length > 0 || regisData.phone.length > 0 || regisData.key.length > 0) {
                const result = await axios.post(endpoint("/users/login"), regisData, {
                    headers: {
                        'Authorization': '' + global.tokenAuth + '',
                        'Content-Type': 'application/json',
                    }
                });
                let response = result.data;
                if (response.success) {
                    getuserData(response.id).then((response) => {
                        sessionStorage.setItem('session', response.session);
                        changeEnterpriseAction(response.enterprise[0]);
                        if (response.enterprise.length > 0) {
                            let userData = {
                                id: response.token,
                                enterprise: response.enterprise[0].enterpriseId,
                                rol: response.rol === 2 ? response.rol : response.enterprise[0].rolEnterpriseId === 2 ? response.enterprise[0].rolEnterpriseId : response.rol,
                                help: response.help,
                                tkc: response.tk,
                                email: response.correo,
                                session: response.session,
                                enterpriseName: response.enterprise[0].name,
                                enterpriseInitials: response.initials,
                                business: response.business,
                                userName: response.name,
                                branch: response.enterprise[0].branchEntId,
                            }
                            setJwt(userData);
                        } else {
                            let userData = {
                                id: response.token,
                                rol: response.rol,
                                help: response.help,
                                tkc: response.tk,
                                email: response.correo,
                                session: response.session,
                                enterpriseName: response.name,
                                enterpriseInitials: response.initials,
                                business: response.business,
                                userName: response.name,
                                branch: 0,
                            }
                            setJwt(userData);
                        }
                        switch (response.rol) {
                            case 1:
                                window.location = "inicio-usuario";
                                break;
                            default:
                                window.location = "inicio-colaborador";
                                break;
                        }
                    });
                } else {
                    setTimeout(function () {
                        $('#btn-login').text("").prop('disabled', false).append('Ingresar').removeClass("btn-guay-processing");
                        $("#form-users :input").prop("disabled", false);
                    }, 3000);
                    switch (response.code) {
                        case 1006:
                        case 1007:
                            setTimeout(function () {
                                toastr.error('Los datos ingresados no son correctos. Vuelva a ingresar la información.', '¡Ooops!');
                            }, 3000);
                            break;
                        case 1008:
                            setTimeout(function () {
                                toastr.warning('La cuenta aún no ha sido activada.', '¡Ooops!');
                            }, 3000);
                            break;
                        case 1009:
                            setTimeout(function () {
                                toastr.warning('Solamente se puede tener una sesión activa. Cierra todas las sesiones e ingresa nuevamente.', '¡Espera!');
                            }, 3000);
                            break;
                        default:
                            setTimeout(function () {
                                toastr.error('Error en la activación. Verifique la información.', '¡Ooops!');
                            }, 3000);
                            break;
                    }
                }
            } else {
                toastr.warning('Debe registrar la información solicitada.', '¡Atención!');
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }
    const seePassword = (e) => {
        e.preventDefault();
        try {
            var input = $("#password");
            if (input.attr("type") === "password") {
                input.attr("type", "text");
                $("#icon_eye_1").attr("class", "ft-eye-off");
            } else {
                input.attr("type", "password");
                $("#icon_eye_1").attr("class", "ft-eye");
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="col-12">
            <div
                className='card'
                style={{ borderRadius: '15px 15px 15px 15px' }}
            >
                <div
                    className="card-header text-center bg-blue-navy py-1"
                    style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                >
                    <span className='text-white font-medium-5 font-weight-bold'>Inicia Sesión</span>
                </div>
                <div className='card-body'>
                    <div className='col-12 py-1'>
                        <form
                            className='row'
                            id="form-users"
                            onSubmit={validaUser}
                        >
                            <div className='col-10 mx-auto'>
                                <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text bg-dark"
                                            style={{ borderBottomLeftRadius: '15px', borderTopLeftRadius: '15px' }}
                                        ><i className="la la-at" /></span>
                                    </div>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Correo"
                                        name="email"
                                        id="user"
                                        style={{ borderBottomRightRadius: '15px', borderTopRightRadius: '15px' }}
                                        onChange={getValue}
                                    />
                                </div>
                                <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text bg-dark"
                                            style={{ borderBottomLeftRadius: '15px', borderTopLeftRadius: '15px' }}
                                        ><i className="la la-lock" /></span>
                                    </div>
                                    <input
                                        type="password"
                                        className="form-control rounded-lg"
                                        placeholder="Contraseña"
                                        name="key"
                                        id="password"
                                        onChange={getValue}
                                    />
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text bg-dark"
                                            style={{ borderBottomRightRadius: '15px', borderTopRightRadius: '15px' }}
                                            onClick={seePassword}
                                        ><i className="ft-eye" id="icon_eye_1" /></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-center mb-2'>
                                <button
                                    type="submit"
                                    className='btn btn-green'
                                    id="btn-login"
                                >Ingresar</button>
                            </div>
                            <div className='col-12 mb-1'>
                                <NavLink to="acceso/recuperar-contrasena"><p className="text-blue text-center">¿Olvidaste tu contraseña?</p></NavLink>
                            </div>
                            <div className='col-12'>
                                <NavLink to="registro"><p className="text-blue text-center">¿No tienes cuenta guay? <span className='font-weight-bold'>Registrate</span></p></NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps, { changeEnterpriseAction })(ModalLogin);
// export default ModalLogin