import React, { useState, useEffect } from 'react';
import { getEnterpriseArea } from '../Models/Catalog_model'
import { getLastUpdateDate, getUploadedCollaborators } from '../Models/Collaborators_model'
import { getBranches, getBranchesLaunch } from '../Models/Enterprise_model'
import $ from "jquery";
import { getJwt } from '../../../lib/auth';

const ModalResume = ({ setNextFlow, setShowModal }) => {

    //get data from cookies
    const enterpriseId = getJwt("enterprise");

    //getters && setters
    const [collaborators, setCollaborators] = useState([]);
    const [listBranches, setListBranches] = useState([]);
    const [listAreas, setListAreas] = useState([]);
    const [date, setdate] = useState("");
    const [ctMess, setCtMess] = useState(false);
    const numCollabs = collaborators.length;
    const numAreas = listAreas.length;
    const numCentros = listBranches.length;
    //data from session   
    let idProduct = sessionStorage.getItem("idProduct");;
    let idCategory = sessionStorage.getItem("idCategory");
    let idSubcategory = sessionStorage.getItem("idSubcategory");
    let nameCategory = sessionStorage.getItem("nameCategory");
    let nameSubcategory = sessionStorage.getItem("nameSubcategory");
    let numCollabSelected = undefined;
    if (sessionStorage.getItem("numCollabSelected")) {
        numCollabSelected = parseInt(sessionStorage.getItem("numCollabSelected"), 10);
    }

    useEffect(() => {

        getUploadedCollaborators(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setCollaborators(response.listCollab);
                }
            }
        });
        getBranches(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setListBranches(response.branches);
                }
            }
        });
        getEnterpriseArea(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setListAreas(response.areas);
                }
            }
        });
        getLastUpdateDate(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setdate(response.lastDateUpload)
                }
            }
        });
        getBranchesLaunch(enterpriseId, idProduct, idCategory, idSubcategory, 1, sessionStorage.getItem('flagLaunch')).then((response) => {
            if (response) {
                if (response.code === 1) {
                    const found = response.listBranchLaunch.find(element => element.launchDate === '0');
                    if (found) {
                        setCtMess(true);
                    }
                }
            }
        });
        return () => {
        }
    }, [enterpriseId, idProduct, idCategory, idSubcategory])

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("-");
    }

    const NumToSp = {
        "01": "Enero",
        "02": "Febrero",
        "03": "Marzo",
        "04": "Abril",
        "05": "Mayo",
        "06": "Junio",
        "07": "Julio",
        "08": "Agosto",
        "09": "Septiembre",
        "10": "Octubre",
        "11": "Noviembre",
        "12": "Diciembre"
    }

    //formatting date
    function getDate(input) {
        if (input.match(/^\d/)) {
            const date = input.split("-");
            const day = date[2];
            const month = NumToSp[date[1]];
            const year = date[0];
            return `${day} de ${month} de ${year}`;
        } else {
            const v = convert(input);
            const date = v.split("-");
            const day = date[0];
            const month = NumToSp[date[1]];
            const year = date[2];
            return `${day} de ${month} de ${year}`;
        }
    }

    //formating date
    function getDate2(input) {
        const date = input.split("-");
        const day = date[0];
        const month = NumToSp[date[1]];
        const year = date[2];
        return `${day} de ${month} de ${year}`;
    }

    const handleClickSaveModal = () => {
        sessionStorage.removeItem("inLaunch");
        sessionStorage.removeItem("isEdition");
        setShowModal(false);
        setNextFlow(false);
        $('#modalResume').hide();
    }

    return (
        <div className="modal" id="modalResume" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
            {/* <div className={unassignedBranches.length > 0 || unassignedAreas.length > 0 ? ("modal-dialog modal-lg modal-dialog-centered") : ("modal-dialog modal-dialog-centered")}> */}
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                    {/*<!-- Modal body -->*/}
                    <div className="modal-body scrollbar-style">
                        <div className="row d-flex justify-content-center">
                            <div className="col-10 d-flex justify-content-center mt-2">
                                <img src="./../img/collabImages/iconos_carga_colaboradores-12.png" alt="img-guay" style={{ height: '50px', width: '50px' }} />
                            </div>
                            <div className="col-10 d-flex justify-content-center mt-2">
                                <span className="text-green font-medium-3">Cambios guardados exitosamente</span>
                            </div>
                            <div className="col-10 d-flex justify-content-center mt-2">
                                <span className="font-medium-3 text-blue-sec">
                                    {`${nameCategory} ${idSubcategory === "1" ? "Fase 1 -" : idSubcategory === "2" ? "Fase 2 -" : ""} ${nameSubcategory}`}
                                </span>
                            </div>
                            <div className="col-10 d-flex justify-content-center mt-3">
                                <p className="font-medium-2 text-base text-center py-0 my-0">Cuentas con un total de {numCollabs} colaboradores, distribuidos en {numCentros} centros de trabajo y {numAreas} &aacute;reas, la última fecha de actualización fue el {getDate2(date)}</p>
                            </div>
                            {
                                sessionStorage.getItem('idTypeLaunch') && idProduct === "1" && idCategory === "1" ?
                                    <div className="col-10 d-flex justify-content-center mt-2">
                                        <span className="font-medium-3 text-blue-sec">
                                            Tipo de lanzamiento:
                                            <span className="font-medium-3 text-base-guay">
                                                {sessionStorage.getItem('flagLaunch') === "2" ? " Lanzamiento Complementario" :
                                                    sessionStorage.getItem('idTypeLaunch') === "1" ? " Lanzamiento Independiente" :
                                                        sessionStorage.getItem('idTypeLaunch') === "2" ? " Lanzamiento Conjunto" :
                                                            null}
                                            </span>
                                        </span>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-10 d-flex justify-content-center mt-2">
                                <span className="font-medium-3 text-blue-sec">
                                    Nombre del ejercicio:
                                    {sessionStorage.getItem('descriptionLaunch')
                                        ?
                                        (
                                            <span className="font-medium-3 text-base-guay">
                                                {` ${sessionStorage.getItem('descriptionLaunch')}`}
                                            </span>
                                        )
                                        :
                                        (
                                            <span className="font-medium-3 text-base-guay">
                                                {` ${sessionStorage.getItem('nomEjercicio')}`}
                                            </span>
                                        )
                                    }
                                </span>
                            </div>
                            {numCollabSelected !== undefined ?
                                <div className="col-10 d-flex justify-content-center mt-2">
                                    <span className="font-medium-3 text-blue-sec">
                                        El lanzamiento se mandará a :
                                        <span className="font-medium-3 text-base-guay">
                                            {` ${numCollabSelected} ${numCollabSelected <= 1 ? "colaborador" : "colaboradores"}`}
                                        </span>
                                    </span>
                                </div>
                                :
                                null
                            }
                            {
                                sessionStorage.getItem('flagLaunch') === "2" || sessionStorage.getItem('isworkingEnv') === "1" || sessionStorage.getItem('isFH') === "1" ? null :
                                    <div className="col-10 d-flex justify-content-center mt-2">
                                        <span className="font-medium-3 text-blue-sec">
                                            ¿Quieres lanzar por centro de trabajo?
                                            <span className="font-medium-3 text-base-guay">
                                                {sessionStorage.getItem('flagLaunch') === "1" ? " Si" :
                                                    sessionStorage.getItem('flagLaunch') === "0" ? " No" :
                                                        null}
                                            </span>
                                        </span>
                                    </div>
                            }
                            {
                                sessionStorage.getItem('flagLaunch') === "0" || sessionStorage.getItem('flagLaunch') === "2" ?
                                    <div className="col-10 d-flex justify-content-center mt-2">
                                        <span className="font-medium-3 text-blue-sec">
                                            Fecha de lanzamiento:
                                            <span className="font-medium-3 text-base-guay">
                                                {` ${getDate(sessionStorage.getItem('launchDate'))}`}
                                            </span>
                                        </span>
                                    </div> : null
                            }
                            {
                                sessionStorage.getItem('flagLaunch') === "1" || sessionStorage.getItem('isworkingEnv') === "1" || sessionStorage.getItem('isFH') === "1" ? null :
                                    <div className="col-10 d-flex justify-content-center mt-2">
                                        <span className="font-medium-3 text-blue-sec">
                                            Inicio de comunicación:
                                            <span className="font-medium-3 text-base-guay">
                                                {` ${getDate(sessionStorage.getItem('communicationDate'))}`}
                                            </span>
                                        </span>
                                    </div>
                            }
                            {
                                sessionStorage.getItem('flagLaunch') === "1" && sessionStorage.getItem('isComplementary') === null ?
                                    (ctMess ? <div className="col-10 d-flex justify-content-center mt-2">
                                        <span className="font-medium-3 font-weight-bold text-center text-red">
                                            ¡Tienes centros de trabajo pendientes de asignar fecha de lanzamiento y/o fecha de comunicación!
                                        </span>
                                    </div> : null) : null
                            }
                            <div className="col-8 d-flex justify-content-center mt-5">
                                <button className="btn btn-pink btn-sm pl-5 pr-5 pointer" onClick={handleClickSaveModal}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalResume
