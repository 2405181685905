import React, { useState, useEffect } from "react";
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Doughnut } from "react-chartjs-2";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";
// API calls
import { getGeneralTracking, getSpecificTrackingByOther, getSpecificTrackingByGuay, getChanneledCollabsReportPDFFiltered } from '../../Models/TrackingModel';
import { getJwt } from "../../../../lib/auth";
import { getActiveProductsByEnterprise } from '../../Models/Enterprise_model';
// Components
import ActMonModalguay from "./ActMonModalguay";
import ActMonModalOther from "./ActMonModalOther";

const ActMonController = ({ step_2 }) => {
    /**
     * Global variables - Start
     */
    // Array variables
    /* const [catStage, setCatStage] = useState([]); */
    const [listExtractCollabs, setListExtractCollabs] = useState([]);
    /* const [listExtractCollabsOther, setListExtractCollabsOther] = useState([]); */
    const [listAllCollChanByPrivInst, setListAllCollChanByPrivInst] = useState([]);
    const [listAllCollChanByPublInst, setListAllCollChanByPublInst] = useState([]);
    const [listAllCollChanByTele, setListAllCollChanByTele] = useState([]);
    const [listGroups, setListGroups] = useState([]);
    /* const [listProcedences, setListProcedences] = useState([]); */
    const [listAllCollab, setListAllCollab] = useState([]);
    const [modalCollaboratorsList, setModalCollaboratorsList] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [listStageCollab, setListStageCollab] = useState([]);
    const [listStageCollab_2, setListStageCollab_2] = useState([]);
    const [listExtractSteps, setListExtractSteps] = useState([]);
    // Object variables
    const [generalNumbers, setGeneralNumbers] = useState({
        closed: 0,
        inProcess: 0,
        numCollabs: 0,
        offProcess: 0,
        wMonitor: 0
    });
    const {
        closed,
        inProcess,
        numCollabs,
        offProcess,
        wMonitor
    } = generalNumbers;
    const [individualNumbers, setIndividualNumbers] = useState({
        closedIN: 0,
        inProcessIN: 0,
        numCollabsIN: 0,
        offProcessIN: 0,
        wMonitorIN: 0
    });
    const {
        closedIN,
        inProcessIN,
        /* numCollabsIN, */
        offProcessIN,
        wMonitorIN
    } = individualNumbers;
    const [doughnutNumbers, setDoughnutNumbers] = useState({
        totalCollChanByguay: 0,
        totalCollChanByPrivInst: 0,
        totalCollChanByPublInst: 0,
        totalCollChanByTele: 0
    });
    const {
        totalCollChanByguay,
        totalCollChanByPrivInst,
        totalCollChanByPublInst,
        totalCollChanByTele
    } = doughnutNumbers;
    const [modalguayGeneralData, setModalguayGeneralData] = useState({
        descStatus: "",
        nameCollab: "",
        percentage: 0,
        idStatus: 0
    });
    const [modalOtherGeneralData, setModalOtherGeneralData] = useState({
        descStatus: "",
        nameCollab: "",
        percentage: 0,
        idStatus: 0
    });
    // Variables
    const [idTable, setIdTable] = useState(0);
    const [serviceSelectValue, setServiceSelectValue] = useState("");
    const [exerciseSelectValue, setExerciseSelectValue] = useState("0");
    /**
     * Global variables - End
     * 
     * useEffect - Start
     */
    useEffect(() => {
        getActiveProductsByEnterprise(getJwt("enterprise")).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let getActiveProducts = [];
                        for (let x in response.listProducts) {
                            if (response.listProducts[x].idCategory === 1 && response.listProducts[x].idSubcategory === 1 && response.listProducts[x].acquired === 1) {
                                getActiveProducts.push(response.listProducts[x]);
                            }
                            if (response.listProducts[x].idCategory === 6 && response.listProducts[x].acquired === 1) {
                                getActiveProducts.push(response.listProducts[x]);
                            }
                        }
                        setListProducts(getActiveProducts);
                        break;
                    default:
                        break;
                }
            }
        });
        getGeneralTracking(getJwt("enterprise"), 0, parseInt(sessionStorage.getItem('idc')), getJwt('jtw'), getJwt('rol')).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let getAllCollab = [];
                        let getAllCollChanByguay = [];
                        let getAllCollChanByPrivInst = [];
                        let getAllCollChanByPublInst = [];
                        let getAllCollChanByTele = [];
                        /* setCatStage(response.catStage); */
                        setListExtractCollabs(response.listExtractCollabs);
                        /* setListExtractCollabsOther(response.listExtractCollabsOther); */
                        setListGroups(response.listGroups);
                        /* setListProcedences(response.listProcedences); */
                        if (response.listExtractCollabs.length > 0) {
                            for (let x in response.listExtractCollabs) {
                                getAllCollab.push(response.listExtractCollabs[x]);
                                getAllCollChanByguay.push(response.listExtractCollabs[x]);
                            }
                        }
                        if (response.listExtractCollabsOther.length > 0) {
                            for (let x in response.listExtractCollabsOther) {
                                getAllCollab.push(response.listExtractCollabsOther[x]);
                                if (response.listExtractCollabsOther[x].idCanalization === 2) {
                                    getAllCollChanByPrivInst.push(response.listExtractCollabsOther[x]);
                                }
                                if (response.listExtractCollabsOther[x].idCanalization === 3) {
                                    getAllCollChanByPublInst.push(response.listExtractCollabsOther[x]);
                                }
                                if (response.listExtractCollabsOther[x].idCanalization === 4) {
                                    getAllCollChanByTele.push(response.listExtractCollabsOther[x]);
                                }
                            }
                        }
                        setListAllCollab(getAllCollab);
                        setListAllCollChanByPrivInst(getAllCollChanByPrivInst);
                        setListAllCollChanByPublInst(getAllCollChanByPublInst);
                        setListAllCollChanByTele(getAllCollChanByTele);
                        setGeneralNumbers({
                            closed: response.closed,
                            inProcess: response.inProcess,
                            numCollabs: response.numCollabs,
                            offProcess: response.offProcess,
                            wMonitor: response.wMonitor
                        });
                        setDoughnutNumbers({
                            totalCollChanByguay: getAllCollChanByguay.length,
                            totalCollChanByPrivInst: getAllCollChanByPrivInst.length,
                            totalCollChanByPublInst: getAllCollChanByPublInst.length,
                            totalCollChanByTele: getAllCollChanByTele.length
                        });
                        setServiceSelectValue(sessionStorage.getItem('idc'));
                        break;
                    default:
                        toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
                        break;
                }
            } else {
                toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
            }
        });
        if (sessionStorage.getItem('menuOption')) {
            sessionStorage.removeItem('menuOption');
        }
        $(".react-bs-container-body").addClass("table-NextSteps");
    }, []);
    /**
     * useEffect - End
     * 
     * @param {*} collaboratorId 
     * @returns 
     */
    const handleLoadData = (collaboratorId, idGroup) => {
        let successLoadData = 1;
        let getIdGroup = idGroup;
        let getIdCategory = parseInt(exerciseSelectValue) === 0 ? (parseInt(sessionStorage.getItem('idc'))) : (parseInt(exerciseSelectValue));
        getGeneralTracking(getJwt("enterprise"), getIdGroup, getIdCategory, getJwt('jtw'), getJwt('rol')).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let getAllCollab = [];
                        let getAllCollChanByguay = [];
                        let getAllCollChanByPrivInst = [];
                        let getAllCollChanByPublInst = [];
                        let getAllCollChanByTele = [];
                        let currentCollaborator = [];
                        /* setCatStage(response.catStage); */
                        setListExtractCollabs(response.listExtractCollabs);
                        /* setListExtractCollabsOther(response.listExtractCollabsOther); */
                        /* setListGroups(response.listGroups); */
                        /* setListProcedences(response.listProcedences); */
                        if (response.listExtractCollabs.length > 0) {
                            for (let x in response.listExtractCollabs) {
                                getAllCollab.push(response.listExtractCollabs[x]);
                                getAllCollChanByguay.push(response.listExtractCollabs[x]);
                            }
                        }
                        if (response.listExtractCollabsOther.length > 0) {
                            for (let x in response.listExtractCollabsOther) {
                                getAllCollab.push(response.listExtractCollabsOther[x]);
                                if (response.listExtractCollabsOther[x].idCanalization === 2) {
                                    getAllCollChanByPrivInst.push(response.listExtractCollabsOther[x]);
                                }
                                if (response.listExtractCollabsOther[x].idCanalization === 3) {
                                    getAllCollChanByPublInst.push(response.listExtractCollabsOther[x]);
                                }
                                if (response.listExtractCollabsOther[x].idCanalization === 4) {
                                    getAllCollChanByTele.push(response.listExtractCollabsOther[x]);
                                }
                            }
                        }
                        setListAllCollab(getAllCollab);
                        setListAllCollChanByPrivInst(getAllCollChanByPrivInst);
                        setListAllCollChanByPublInst(getAllCollChanByPublInst);
                        setListAllCollChanByTele(getAllCollChanByTele);
                        setGeneralNumbers({
                            closed: response.closed,
                            inProcess: response.inProcess,
                            numCollabs: response.numCollabs,
                            offProcess: response.offProcess,
                            wMonitor: response.wMonitor
                        });
                        setDoughnutNumbers({
                            totalCollChanByguay: getAllCollChanByguay.length,
                            totalCollChanByPrivInst: getAllCollChanByPrivInst.length,
                            totalCollChanByPublInst: getAllCollChanByPublInst.length,
                            totalCollChanByTele: getAllCollChanByTele.length
                        });
                        let getAttentionId = 0;
                        let closed = 0;
                        let inProcess = 0;
                        let offProcess = 0;
                        let wMonitor = 0;
                        for (let x in response.listExtractCollabsOther) {
                            if (response.listExtractCollabsOther[x].collaboratorId === collaboratorId) {
                                currentCollaborator.push(response.listExtractCollabsOther[x]);
                                getAttentionId = response.listExtractCollabsOther[x].idCanalization;
                            }
                        }
                        switch (getAttentionId) {
                            case 2:
                                for (let x in getAllCollChanByPrivInst) {
                                    if (getAllCollChanByPrivInst[x].idStatus === 1) {
                                        inProcess = inProcess + 1;
                                    }
                                    if (getAllCollChanByPrivInst[x].idStatus === 2) {
                                        offProcess = offProcess + 1;
                                    }
                                    if (getAllCollChanByPrivInst[x].idStatus === 3) {
                                        closed = closed + 1;
                                    }
                                    if (getAllCollChanByPrivInst[x].idStatus === 4) {
                                        wMonitor = wMonitor + 1;
                                    }
                                }
                                setIndividualNumbers({
                                    ...individualNumbers,
                                    closedIN: closed,
                                    inProcessIN: inProcess,
                                    offProcessIN: offProcess,
                                    wMonitorIN: wMonitor
                                });
                                break;
                            case 3:
                                for (let x in getAllCollChanByPublInst) {
                                    if (getAllCollChanByPublInst[x].idStatus === 1) {
                                        inProcess = inProcess + 1;
                                    }
                                    if (getAllCollChanByPublInst[x].idStatus === 2) {
                                        offProcess = offProcess + 1;
                                    }
                                    if (getAllCollChanByPublInst[x].idStatus === 3) {
                                        closed = closed + 1;
                                    }
                                    if (getAllCollChanByPublInst[x].idStatus === 4) {
                                        wMonitor = wMonitor + 1;
                                    }
                                }
                                setIndividualNumbers({
                                    ...individualNumbers,
                                    closedIN: closed,
                                    inProcessIN: inProcess,
                                    offProcessIN: offProcess,
                                    wMonitorIN: wMonitor
                                });
                                break;
                            case 4:
                                for (let x in getAllCollChanByTele) {
                                    if (getAllCollChanByTele[x].idStatus === 1) {
                                        inProcess = inProcess + 1;
                                    }
                                    if (getAllCollChanByTele[x].idStatus === 2) {
                                        offProcess = offProcess + 1;
                                    }
                                    if (getAllCollChanByTele[x].idStatus === 3) {
                                        closed = closed + 1;
                                    }
                                    if (getAllCollChanByTele[x].idStatus === 4) {
                                        wMonitor = wMonitor + 1;
                                    }
                                }
                                setIndividualNumbers({
                                    ...individualNumbers,
                                    closedIN: closed,
                                    inProcessIN: inProcess,
                                    offProcessIN: offProcess,
                                    wMonitorIN: wMonitor
                                });
                                break;
                            default:
                                for (let x in getAllCollChanByguay) {
                                    if (getAllCollChanByguay[x].idStatus === 1) {
                                        inProcess = inProcess + 1;
                                    }
                                    if (getAllCollChanByguay[x].idStatus === 2) {
                                        offProcess = offProcess + 1;
                                    }
                                    if (getAllCollChanByguay[x].idStatus === 3) {
                                        closed = closed + 1;
                                    }
                                }
                                setIndividualNumbers({
                                    ...individualNumbers,
                                    closedIN: closed,
                                    inProcessIN: inProcess,
                                    offProcessIN: offProcess,
                                });
                                break;
                        }
                        handleViewTrackByguay(currentCollaborator[0]);
                        break;
                    default:
                        successLoadData = 2;
                        break;
                }
            } else {
                successLoadData = 2;
            }
        });
        return (successLoadData)
    }
    /**
     * Table props - Start
     */
    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    };
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const nameCollabFormat = (cell, row, index) => {
        return (
            <span
                className="text-base-blue pointer"
                data-toggle="tooltip"
                title={cell}
                onClick={() => handleViewTrackByguay(row)} >{cell}</span>
        )
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const tooltipFormat = (cell, row, index) => {
        return (
            <span
                data-toggle="tooltip"
                title={cell} >{cell}</span>
        )
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const descCanalizationFormat = (cell, row, index) => {
        let textColor;
        if (row.idCanalization === 1) {
            textColor = "text-base-guay font-weight-bold";
        } else if (row.idCanalization === 2) {
            textColor = "text-yellow font-weight-bold";
        } else if (row.idCanalization === 3) {
            textColor = "text-green font-weight-bold";
        } else {
            textColor = "text-blue font-weight-bold";
        }
        return (
            <span
                className={textColor}
                data-toggle="tooltip"
                title={cell} >{cell}</span>
        );
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const statusIcon = (cell, row, index) => {
        if (cell === 1) {
            return (
                <div
                    className="mx-auto"
                    data-toggle="tooltip"
                    title="En proceso"
                    style={{ width: '15px', height: '15px', background: '#2188b4', borderRadius: '100px' }}
                />
            )
        }
        if (cell === 2) {
            return (
                <div
                    className="mx-auto"
                    data-toggle="tooltip"
                    title="Fuera de proceso"
                    style={{ width: '15px', height: '15px', background: '#343246', borderRadius: '100px' }}
                />
            )
        }
        if (cell === 3) {
            return (
                <div
                    className="mx-auto"
                    data-toggle="tooltip"
                    title="Finalizado"
                    style={{ width: '15px', height: '15px', background: '#9cb537', borderRadius: '100px' }}
                />
            )
        }
        if (cell === 4) {
            return (
                <div
                    className="mx-auto"
                    data-toggle="tooltip"
                    title="Sin monitorear"
                    style={{ width: '15px', height: '15px', background: '#dfa444', borderRadius: '100px' }}
                />
            )
        }
    }
    /**
     * 
     * @param {*} data 
     */
    const handleViewTrackByguay = (data) => {
        if (data.idCanalization === 1) {
            getSpecificTrackingByGuay(getJwt('enterprise'), data.collaboratorId, data.idPeriod, data.idGroup).then((response) => {
                switch (response.code) {
                    case 1:
                    case 200:
                        let arryaLenght = response.listStageCollab.length - 1;
                        setModalCollaboratorsList(response.listStageCollab);
                        for (let x in response.listStageCollab) {
                            if (parseInt(x) === arryaLenght) {
                                if (response.listStageCollab[x].idStatus === 1) {
                                    setModalguayGeneralData({
                                        ...modalguayGeneralData,
                                        descStatus: response.listStageCollab[x].descStatus,
                                        nameCollab: response.listStageCollab[x].nameCollab,
                                        percentage: parseInt(response.listStageCollab[x].percentage),
                                        idStatus: response.listStageCollab[x].idStatus
                                    });
                                }
                                if (response.listStageCollab[x].idStatus === 2) {
                                    setModalguayGeneralData({
                                        ...modalguayGeneralData,
                                        descStatus: response.listStageCollab[x].descStatus,
                                        nameCollab: response.listStageCollab[x].nameCollab,
                                        percentage: 100,
                                        idStatus: response.listStageCollab[x].idStatus
                                    });
                                }
                                if (response.listStageCollab[x].idStatus === 3) {
                                    setModalguayGeneralData({
                                        ...modalguayGeneralData,
                                        descStatus: response.listStageCollab[x].descStatus,
                                        nameCollab: response.listStageCollab[x].nameCollab,
                                        percentage: 100,
                                        idStatus: response.listStageCollab[x].idStatus
                                    });
                                }
                            }
                        }
                        $("#modalActMonModalguay").show();
                        break;
                    case 1001:
                        toastr.info('La empresa no está activa. Active la empresa e intente nuevamente.', '¡Ooops!');
                        break;
                    case 1002:
                        toastr.info('No hay información del colaborador.', '¡Ooops!');
                        break;
                    default:
                        toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
                        break;
                }

            });
        } else {
            getSpecificTrackingByOther(getJwt('enterprise'), data.collaboratorId, data.idPeriod, data.idGroup).then((response) => {
                switch (response.code) {
                    case 1:
                    case 200:
                        let arryaLenght = response.listExtractSteps.length - 1;
                        setListStageCollab(response.listStageCollab[0]);
                        setListStageCollab_2(response.listStageCollab[0]);
                        setListExtractSteps(response.listExtractSteps);
                        for (let x in response.listExtractSteps) {
                            if (parseInt(x) === arryaLenght) {
                                if (response.listExtractSteps[x].idStatus === 1) {
                                    setModalOtherGeneralData({
                                        ...modalOtherGeneralData,
                                        descStatus: response.listExtractSteps[x].descStatus,
                                        nameCollab: response.listStageCollab[0].nameCollab,
                                        percentage: response.percentage,
                                        idStatus: response.listExtractSteps[x].idStatus
                                    });
                                }
                                if (response.listExtractSteps[x].idStatus === 2) {
                                    setModalOtherGeneralData({
                                        ...modalOtherGeneralData,
                                        descStatus: response.listExtractSteps[x].descStatus,
                                        nameCollab: response.listStageCollab[0].nameCollab,
                                        percentage: response.percentage,
                                        idStatus: response.listExtractSteps[x].idStatus
                                    });
                                }
                                if (response.listExtractSteps[x].idStatus === 3) {
                                    setModalOtherGeneralData({
                                        ...modalOtherGeneralData,
                                        descStatus: response.listExtractSteps[x].descStatus,
                                        nameCollab: response.listStageCollab[0].nameCollab,
                                        percentage: 100,
                                        idStatus: response.listExtractSteps[x].idStatus
                                    });
                                }
                                if (response.listExtractSteps[x].idStatus === 4) {
                                    setModalOtherGeneralData({
                                        ...modalOtherGeneralData,
                                        descStatus: response.listExtractSteps[x].descStatus,
                                        nameCollab: response.listStageCollab[0].nameCollab,
                                        percentage: 100,
                                        idStatus: response.listExtractSteps[x].idStatus
                                    });
                                }
                                if (response.listExtractSteps[x].idStatus === 5) {
                                    setModalOtherGeneralData({
                                        ...modalOtherGeneralData,
                                        descStatus: response.listExtractSteps[x].descStatus,
                                        nameCollab: response.listStageCollab[0].nameCollab,
                                        percentage: 100,
                                        idStatus: response.listExtractSteps[x].idStatus
                                    });
                                }
                            }
                        }
                        $("#modalActMonModalOther").show();
                        break;
                    case 1001:
                        toastr.info('La empresa no está activa. Active la empresa e intente nuevamente.', '¡Ooops!');
                        break;
                    case 1002:
                        toastr.info('No hay información del colaborador.', '¡Ooops!');
                        break;
                    default:
                        toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
                        break;
                }
            });
        }
    }
    /**
     * Table props - End
     * 
     * Doughnut props - Start
     */
    const opciones = {
        responsive: true,
        legend: false,
        hoverOffset: 1000
    };
    const doughnutData_1 = {
        labels: [
            "guay",
            "Institución privada",
            "Institución pública",
            "Teleasistencia"
        ],
        datasets: [
            {
                data: [totalCollChanByguay, totalCollChanByPrivInst, totalCollChanByPublInst, totalCollChanByTele],
                backgroundColor: [
                    "#af2f72",
                    "#dfa444",
                    "#9cb537",
                    "#2188b4"
                ],
            },
        ],
    };
    const doughnutData_2 = {
        labels: [
            "guay"
        ],
        datasets: [
            {
                data: [totalCollChanByguay],
                backgroundColor: [
                    "#af2f72"
                ],
            },
        ],
    }
    const doughnutData_3 = {
        labels: [
            "Institución privada"
        ],
        datasets: [
            {
                data: [totalCollChanByPrivInst],
                backgroundColor: [
                    "#dfa444"
                ],
            },
        ],
    }
    const doughnutData_4 = {
        labels: [
            "Institución pública"
        ],
        datasets: [
            {
                data: [totalCollChanByPublInst],
                backgroundColor: [
                    "#9cb537"
                ],
            },
        ],
    }
    const doughnutData_5 = {
        labels: [
            "Teleasistencia"
        ],
        datasets: [
            {
                data: [totalCollChanByTele],
                backgroundColor: [
                    "#2188b4"
                ],
            },
        ],
    }
    /**
     * 
     * @param {*} option 
     */
    const handleShowTable = (option) => {
        try {
            let closed = 0;
            let inProcess = 0;
            /* let numCollabs = 0; */
            let offProcess = 0;
            let wMonitor = 0;
            switch (option) {
                case 0:
                    for (let x in listExtractCollabs) {
                        if (listExtractCollabs[x].idStatus === 1) {
                            inProcess = inProcess + 1;
                        }
                        if (listExtractCollabs[x].idStatus === 2) {
                            offProcess = offProcess + 1;
                        }
                        if (listExtractCollabs[x].idStatus === 3) {
                            closed = closed + 1;
                        }
                    }
                    setIndividualNumbers({
                        ...individualNumbers,
                        closedIN: closed,
                        inProcessIN: inProcess,
                        offProcessIN: offProcess,
                    });
                    setIdTable(1);
                    break;
                case 1:
                    for (let x in listAllCollChanByPrivInst) {
                        if (listAllCollChanByPrivInst[x].idStatus === 1) {
                            inProcess = inProcess + 1;
                        }
                        if (listAllCollChanByPrivInst[x].idStatus === 2) {
                            offProcess = offProcess + 1;
                        }
                        if (listAllCollChanByPrivInst[x].idStatus === 3) {
                            closed = closed + 1;
                        }
                        if (listAllCollChanByPrivInst[x].idStatus === 4) {
                            wMonitor = wMonitor + 1;
                        }
                    }
                    setIndividualNumbers({
                        ...individualNumbers,
                        closedIN: closed,
                        inProcessIN: inProcess,
                        offProcessIN: offProcess,
                        wMonitorIN: wMonitor
                    });
                    setIdTable(2);
                    break;
                case 2:
                    for (let x in listAllCollChanByPublInst) {
                        if (listAllCollChanByPublInst[x].idStatus === 1) {
                            inProcess = inProcess + 1;
                        }
                        if (listAllCollChanByPublInst[x].idStatus === 2) {
                            offProcess = offProcess + 1;
                        }
                        if (listAllCollChanByPublInst[x].idStatus === 3) {
                            closed = closed + 1;
                        }
                        if (listAllCollChanByPublInst[x].idStatus === 4) {
                            wMonitor = wMonitor + 1;
                        }
                    }
                    setIndividualNumbers({
                        ...individualNumbers,
                        closedIN: closed,
                        inProcessIN: inProcess,
                        offProcessIN: offProcess,
                        wMonitorIN: wMonitor
                    });
                    setIdTable(3);
                    break;
                case 3:
                    for (let x in listAllCollChanByTele) {
                        if (listAllCollChanByTele[x].idStatus === 1) {
                            inProcess = inProcess + 1;
                        }
                        if (listAllCollChanByTele[x].idStatus === 2) {
                            offProcess = offProcess + 1;
                        }
                        if (listAllCollChanByTele[x].idStatus === 3) {
                            closed = closed + 1;
                        }
                        if (listAllCollChanByTele[x].idStatus === 4) {
                            wMonitor = wMonitor + 1;
                        }
                    }
                    setIndividualNumbers({
                        ...individualNumbers,
                        closedIN: closed,
                        inProcessIN: inProcess,
                        offProcessIN: offProcess,
                        wMonitorIN: wMonitor
                    });
                    setIdTable(4);
                    break;
                default:
                    setIdTable(0);
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Doughnut props - End
     * 
     * General functions - Start
     * 
     * @param {*} e 
     */
    const handleServiceSelect = e => {
        e.preventDefault();
        let value = parseInt(e.target.value);
        setServiceSelectValue(e.target.value);
        if (exerciseSelectValue === "100") {
            handleGetGeneralTracking(0, value);
        } else {
            handleGetGeneralTracking(parseInt(exerciseSelectValue), value);
        }
        setIdTable(0);
    }
    /**
     * 
     * @param {*} e 
     */
    const handleExerciseSelect = e => {
        e.preventDefault();
        let value = parseInt(e.target.value);
        if (value === 100) {
            handleGetGeneralTracking(0, parseInt(serviceSelectValue));
        } else {
            handleGetGeneralTracking(value, parseInt(serviceSelectValue));
        }
        setExerciseSelectValue(e.target.value);
        setIdTable(0);
    }
    /**
     * 
     * @param {*} idGroup 
     * @param {*} idCategory 
     */
    const handleGetGeneralTracking = (idGroup, idCategory) => {
        getGeneralTracking(getJwt("enterprise"), idGroup, idCategory, getJwt('jtw'), getJwt('rol')).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let getAllCollab = [];
                        let getAllCollChanByguay = [];
                        let getAllCollChanByPrivInst = [];
                        let getAllCollChanByPublInst = [];
                        let getAllCollChanByTele = [];
                        /* setCatStage(response.catStage); */
                        setListExtractCollabs(response.listExtractCollabs);
                        /* setListExtractCollabsOther(response.listExtractCollabsOther); */
                        /* setListGroups(response.listGroups); */
                        /* setListProcedences(response.listProcedences); */
                        if (response.listExtractCollabs.length > 0) {
                            for (let x in response.listExtractCollabs) {
                                getAllCollab.push(response.listExtractCollabs[x]);
                                getAllCollChanByguay.push(response.listExtractCollabs[x]);
                            }
                        }
                        if (response.listExtractCollabsOther.length > 0) {
                            for (let x in response.listExtractCollabsOther) {
                                getAllCollab.push(response.listExtractCollabsOther[x]);
                                if (response.listExtractCollabsOther[x].idCanalization === 2) {
                                    getAllCollChanByPrivInst.push(response.listExtractCollabsOther[x]);
                                }
                                if (response.listExtractCollabsOther[x].idCanalization === 3) {
                                    getAllCollChanByPublInst.push(response.listExtractCollabsOther[x]);
                                }
                                if (response.listExtractCollabsOther[x].idCanalization === 4) {
                                    getAllCollChanByTele.push(response.listExtractCollabsOther[x]);
                                }
                            }
                        }
                        setListAllCollab(getAllCollab);
                        setListAllCollChanByPrivInst(getAllCollChanByPrivInst);
                        setListAllCollChanByPublInst(getAllCollChanByPublInst);
                        setListAllCollChanByTele(getAllCollChanByTele);
                        setGeneralNumbers({
                            closed: response.closed,
                            inProcess: response.inProcess,
                            numCollabs: response.numCollabs,
                            offProcess: response.offProcess,
                            wMonitor: response.wMonitor
                        });
                        setDoughnutNumbers({
                            totalCollChanByguay: getAllCollChanByguay.length,
                            totalCollChanByPrivInst: getAllCollChanByPrivInst.length,
                            totalCollChanByPublInst: getAllCollChanByPublInst.length,
                            totalCollChanByTele: getAllCollChanByTele.length
                        });
                        break;
                    default:
                        toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
                        break;
                }
            } else {
                toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
            }
        });
    }
    /**
     * General functions - End
     */
    return (
        <div className="row">
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row d-flex justify-content-around">
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6" hidden>
                        <div className="row h-100 d-flex flex-wrap align-content-center">
                            <div className="col-lg-2 d-flex flex-wrap align-content-center">
                                <span className="font-weight-bold text-base">Servicio:</span>
                            </div>
                            <select
                                className="form-control form-control-sm col-lg-8"
                                id="serviceSelect"
                                value={serviceSelectValue}
                                onChange={(e) => handleServiceSelect(e)} >
                                {
                                    listProducts.map((productData) => (
                                        <option value={productData.idCategory}>{productData.nameCategory}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 p-0 d-flex flex-wrap align-content-center">
                                <span className="font-weight-bold text-base">Nombre del ejercicio:</span>
                            </div>
                            <select
                                className="form-control form-control-sm col-lg-7"
                                id="exerciseSelect"
                                value={exerciseSelectValue}
                                onChange={(e) => handleExerciseSelect(e)} >
                                <option value="100">Todos</option>
                                {
                                    listGroups.map((groupData) => (
                                        <option value={groupData.idGroup}>{groupData.descLaunch}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-2">
                <div className="row">
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                        <span className="font-weight-bold">Monitor de asistencia</span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                        {
                            listAllCollab.length !== 0 ? (
                                <span
                                    className="font-small-3 pointer float-right text-blue text-underline"
                                    onClick={() => {
                                        getChanneledCollabsReportPDFFiltered(getJwt('enterprise'), serviceSelectValue, exerciseSelectValue === '100' ? (0) : (exerciseSelectValue), getJwt('jtw'), getJwt('rol')).then(response => {
                                            if (response) {
                                                if (response.code === 1) {
                                                    window.open(response.url);
                                                }
                                            }
                                        });
                                    }}
                                >Reporte de colaboradores canalizados</span>
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                <div className="row">
                    {/* <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 mx-auto"> */}
                    <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 mx-auto" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}>
                        {
                            idTable !== 0
                                ?
                                (
                                    <div className="btn-close-2 d-flex justify-content-center flex-wrap align-content-center" style={{ top: '0px' }}>
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={() => setIdTable(0)} >&times;</button>
                                    </div>
                                )
                                :
                                (null)
                        }
                        <div style={{ position: 'absolute' }}>
                            <p className="m-0 font-weight-bold font-small-3">{idTable === 1 ? listExtractCollabs.length : idTable === 2 ? listAllCollChanByPrivInst.length : idTable === 3 ? listAllCollChanByPublInst.length : idTable === 4 ? listAllCollChanByTele.length : numCollabs}</p>
                            <p className="m-0 font-weight-bold font-small-3">{idTable === 1 ? "guay" : idTable === 2 ? "Privada" : idTable === 3 ? "Pública" : idTable === 4 ? "Teleasistencia" : "TOTAL"}</p>
                        </div>
                        {
                            idTable === 0
                                ?
                                (
                                    <Doughnut
                                        data={doughnutData_1}
                                        options={opciones}
                                        getElementAtEvent={
                                            (i, event) => {
                                                if (i.length > 0) {
                                                    handleShowTable(i[0]._index);
                                                } else {
                                                    handleShowTable(5);
                                                }
                                            }
                                        } />
                                )
                                :
                                (
                                    idTable === 1
                                        ?
                                        (

                                            <Doughnut
                                                data={doughnutData_2}
                                                options={opciones}
                                                getElementAtEvent={
                                                    (i, event) => {
                                                        if (i.length > 0) {
                                                            handleShowTable(i[0]._index);
                                                        } else {
                                                            handleShowTable(5);
                                                        }
                                                    }
                                                } />
                                        )
                                        :
                                        (
                                            idTable === 2
                                                ?
                                                (
                                                    <Doughnut
                                                        data={doughnutData_3}
                                                        options={opciones}
                                                        getElementAtEvent={
                                                            (i, event) => {
                                                                if (i.length > 0) {
                                                                    handleShowTable(i[0]._index);
                                                                } else {
                                                                    handleShowTable(5);
                                                                }
                                                            }
                                                        } />
                                                )
                                                :
                                                (
                                                    idTable === 3
                                                        ?
                                                        (
                                                            <Doughnut
                                                                data={doughnutData_4}
                                                                options={opciones}
                                                                getElementAtEvent={
                                                                    (i, event) => {
                                                                        if (i.length > 0) {
                                                                            handleShowTable(i[0]._index);
                                                                        } else {
                                                                            handleShowTable(5);
                                                                        }
                                                                    }
                                                                } />
                                                        )
                                                        :
                                                        (
                                                            <Doughnut
                                                                data={doughnutData_5}
                                                                options={opciones}
                                                                getElementAtEvent={
                                                                    (i, event) => {
                                                                        if (i.length > 0) {
                                                                            handleShowTable(i[0]._index);
                                                                        } else {
                                                                            handleShowTable(5);
                                                                        }
                                                                    }
                                                                } />
                                                        )
                                                )
                                        )
                                )
                        }
                    </div>
                    {/* </div> */}
                    <div className="col-sx-12 col-sm-12 col-md-9 col-lg-9 mx-auto d-flex flex-wrap align-content-center">
                        <div className="col-3 h-100">
                            <div className="row h-100">
                                <div className="col-11 p-0 mx-auto with-box-shadow-3">
                                    <div className="col-12 px-0 pt-1 h-50">
                                        <div className="row h-100">
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <span className="font-weight-bold text-yellow font-large-2 m-0">{idTable === 0 ? wMonitor : wMonitorIN}</span>
                                            </div>
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <img
                                                    className="w-75"
                                                    src="./../img/actions/actionsMonitoring/iconos_acciones-06.png"
                                                    alt="img-monitoring"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 h-50 font-medium-1 d-flex flex-wrap align-content-end">
                                        <span>Sin monitorear</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 h-100">
                            <div className="row h-100">
                                <div className="col-11 p-0 mx-auto with-box-shadow-3">
                                    <div className="col-12 px-0 pt-1 h-50">
                                        <div className="row h-100">
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <h1 className="font-weight-bold text-blue font-large-2 m-0">{idTable === 0 ? inProcess : inProcessIN}</h1>
                                            </div>
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <img
                                                    className="w-75"
                                                    src="./../img/actions/actionsMonitoring/iconos_acciones-03.png"
                                                    alt="img-monitoring"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 h-50 font-medium-1 d-flex flex-wrap align-content-end">
                                        <span>En proceso</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 h-100">
                            <div className="row h-100">
                                <div className="col-11 p-0 mx-auto with-box-shadow-3">
                                    <div className="col-12 px-0 pt-1 h-50">
                                        <div className="row h-100">
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <h1 className="font-weight-bold text-black font-large-2 m-0">{idTable === 0 ? offProcess : offProcessIN}</h1>
                                            </div>
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <img
                                                    className="w-75"
                                                    src="./../img/actions/actionsMonitoring/iconos_acciones-07.png"
                                                    alt="img-monitoring"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 h-50 font-medium-1 d-flex flex-wrap align-content-end">
                                        <span>Fuera de proceso</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 h-100">
                            <div className="row h-100">
                                <div className="col-11 p-0 mx-auto with-box-shadow-3">
                                    <div className="col-12 px-0 pt-1 h-50">
                                        <div className="row h-100">
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <h1 className="font-weight-bold text-green font-large-2 m-0">{idTable === 0 ? closed : closedIN}</h1>
                                            </div>
                                            <div className="col-6 h-100 d-flex justify-content-center flex-wrap align-content-center">
                                                <img
                                                    className="w-75"
                                                    src="./../img/actions/actionsMonitoring/iconos_acciones-04.png"
                                                    alt="img-monitoring"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 h-50 font-medium-1 d-flex flex-wrap align-content-end">
                                        <span>Finalizados</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="row">
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                        <BootstrapTable
                            data={idTable === 0 ? (listAllCollab) : (idTable === 1 ? (listExtractCollabs) : (idTable === 2 ? (listAllCollChanByPrivInst) : (idTable === 3 ? listAllCollChanByPublInst : (listAllCollChanByTele))))}
                            hover
                            pagination={true}
                            /* tableStyle={{ padding: '0px', fontSize: '.8rem', color: '#6b6f82' }} */
                            options={options}
                            headerStyle={{ border: 'red 0px solid' }}
                            bodyStyle={{ border: 'green 0px solid' }}
                            tableStyle={{ border: '#9cb0d1 2.5px solid', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField='collaboratorId'
                                dataAlign="center"
                                hidden ></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='nameCollab'
                                dataSort
                                dataAlign="center"
                                dataFormat={nameCollabFormat}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="250px" */ >Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='descArea'
                                dataSort
                                dataAlign="center"
                                dataFormat={tooltipFormat}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="150px" */ >Área</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='descJob'
                                dataAlign="center"
                                dataFormat={tooltipFormat}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="150px" */ >Cargo</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='descCanalization'
                                dataAlign="center"
                                dataSort
                                dataFormat={descCanalizationFormat}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="200px" */ >Canalizado a</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='descStage'
                                dataAlign="center"
                                dataSort
                                dataFormat={tooltipFormat}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="400px" */ >Etapa</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='descActivity'
                                dataAlign="center"
                                dataSort
                                dataFormat={tooltipFormat}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="400px" */ >Actividad</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='idStatus'
                                dataAlign="center"
                                dataSort
                                dataFormat={statusIcon}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                /* width="150px" */ >Estatus</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
            <ActMonModalguay
                modalguayGeneralData={modalguayGeneralData}
                modalCollaboratorsList={modalCollaboratorsList} />
            <ActMonModalOther
                listStageCollab={listStageCollab}
                setListStageCollab={setListStageCollab}
                listExtractSteps={listExtractSteps}
                modalOtherGeneralData={modalOtherGeneralData}
                handleLoadData={handleLoadData}
                listStageCollab_2={listStageCollab_2}
                step_2={step_2}
            />
        </div>
    )
}

export default ActMonController;
