import React from 'react';
// Tools
import PropTypes from 'prop-types';

const StatusNotes = ({ notes }) => {
    return (
        <div className="col-sx-11 col-sm-11 col-md-3 col-lg-3 mx-auto">
            <div className="card">
                <div className="card-body">
                    <div className="col-12 mb-1">
                        <span className="text-base-guay font-weight-bold">Notas de la Solicitud</span>
                    </div>
                    {
                        notes.map(data => (
                            <div
                                className="col-12 mb-1 text-base"
                                key={data.idOrderLog}
                            >
                                <span className="d-block">{data.descStatus}</span>
                                {
                                    data.comment ? (
                                        <span className="d-block font-small-2">{data.name}: {data.comment}</span>
                                    ) : (
                                        null
                                    )
                                }
                                <span className="d-block font-small-2">{data.register_date}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

StatusNotes.propTypes = {
    notes: PropTypes.array.isRequired
}

export default StatusNotes;
