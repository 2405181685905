import React from 'react'
import $ from "jquery"

const ModalFooterViewPdf = ({urlShowPdf,setUrlShowPdf}) => {
    // console.log( typeof urlShowPdf);
    const closeModalFooterViewPdf = () => {
        $('#closeModalFooterViewPdf').hide()
        setUrlShowPdf("")
    }
  return (
    <div
        className="modal"
        id="closeModalFooterViewPdf"
        style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
        data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content" tyle={{ backgroundColor: 'transparent', borderRadius: '2rem', overflow: "auto", border: "none" }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                        type="button"
                        className="close"
                        onClick={() => closeModalFooterViewPdf()}>&times;</button>
                    </div>
                    <div className="modal-body scrollbar-style-hidden pl-0 pr-0 pt-0">
                        <iframe 
                            src={urlShowPdf}
                            width="100%" 
                            height="780" 
                            title='pdf'
                            />
                    </div>
                </div>
            </div>
    </div>
  )
}
export default ModalFooterViewPdf;
