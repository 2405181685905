import React from 'react'

const PregnantMessageModal = () => {
    return (
        <div
            className="modal"
            id="PregnantModal"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div
                    className="modal-content border-0 box-shadow-guay-1"
                >
                    <div className="modal-body">
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <img
                                        src="../img/filtroClinico/Imagen_embarazo.png"
                                        className="img-fluid"
                                        alt='logo'
                                    />
                                </div>
                                <div className='col-12'>
                                    <p className='text-center fs-1-3em text-blue-sec'>
                                        De acuerdo con lo que nos comentas <span className='text-underline'>te recomendamos</span> que <span className='font-weight-bold'>consultes a un especialista en salud mental</span>, inclusive si te sientes bien como una acción de prevención.
                                    </p>
                                    <p className='text-center fs-1-3em text-blue-sec font-weight-bold'>¡Estamos contigo!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PregnantMessageModal
