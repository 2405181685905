import React from 'react'
import $ from "jquery";
import * as toastr from 'toastr';
import { sendMailNotActive } from './ModelMonitor';


export const SendMailNotActiveModal = ({organizacionId,groupId,branchId}) => {
    const sendMail = () => {
        $('#resendMailNotActiveModal').hide();
        $('#modalLoaderGen').show();
        sendMailNotActive(parseInt(organizacionId),parseInt(groupId),branchId ).then(response => {
            switch (response?.code) {
                case 1:
                    $('#modalLoaderGen').hide();
                    toastr.success("Se envió la invitación correctamente.", "¡Éxito!");
                    break

                // case 99:
                //     $('#modalLoaderGen').hide();
                //     toastr.error('Ocurrió un error, verifique su conexión a internet.')
                //     break

                // case 1001:
                //     $('#modalLoaderGen').hide();
                //     toastr.error('La organización no se encuentra o no está activa.')
                //     break

                // case 1002:
                //     $('#modalLoaderGen').hide();
                //   toastr.error('El usuario no se encuentra o no está activo.')
                //   break

                default:
                    $('#modalLoaderGen').hide();
                    toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                    break
            }
        })

    }
  return (
    <div
    className="modal"
    id="resendMailNotActiveModal"
    style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
    data-backdrop="false">
        <div className="modal-dialog modal-dialog-centered modal-md">
            <div
                className="modal-content"
                style={{ backgroundColor: 'transparent',borderRadius: '2rem',border: "none" }}

                >
                        <button
                        className="close"
                        style={
                            {
                                position: 'absolute',
                                top: '-20px',
                                right: '-20px',
                                opacity: '1',
                                fontSize: '2rem',
                            }
                        }
                        onClick={() => {
                            $("#resendMailNotActiveModal").hide();
                        }}
                    >&times;</button>
                
                <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                    <div className="row mb-1 d-flex justify-content-center">
                        <div className="col-8">
                            <h1 className="font-medium-1 text-center">Reenviar la liga de activación de cuenta</h1>
                        </div>
                    </div>
                    <div className="row mb-1 d-flex justify-content-center">
                        <div className="col">
                            <p className="font-medium-1 text-center">Se reenviará a los colaboradores que no han activado su cuenta.</p>
                        </div>
                    </div>
                    <div className="row mb-1 d-flex justify-content-center">
                        <div className="col" style={{textAlign: 'center'}}>
                            <button className="btn btn-small btn-cancel-style2" onClick={() => $("#resendMailNotActiveModal").hide()}>No, regresar</button>
                        </div>
                        <div className="col" style={{textAlign: 'center'}}>
                            <button className="btn btn-small btn-save-style2" onClick={() => sendMail()}>Enviar</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div> 
  )
}
