import { getActiveEnterprise } from "../app/Models/Enterprise_model";

/**
 * Función que Retorna el +99 cuando el valor es > 100 sino retorna valor 
 * @param {int} value 
 * @returns string
 */
export const setStringNumberFormatter = function (value = 0) {
    let stringValue;
    if (value >= 1000) {
        stringValue = '+999';
    } else if (value === 0) {
        stringValue = '--';
    } else {
        stringValue = `${value}`;
    }
    return stringValue;
}

export const validationOnEnterpriseChange = (enterprise) => {
    return new Promise(resolve => {
        /* if (enterprise.incomplete === 0) { */
            getActiveEnterprise(enterprise.enterpriseId).then((response) => {
                switch (response?.code) {
                    case 1: //empresa activa
                        resolve({ validation: true });
                        break;
                    case 1001: //empresa inactiva o no existente
                        resolve({ validation: false, errorMessage: "No se puede acceder debido a que la empresa no se encuentra activa." });
                        break;
                    default://error no documentado
                        resolve({ validation: false, errorMessage: "Ocurrió un error desconocido, verifique que esta conectado a internet, sino contacte a su administrador." });
                        break;
                }
            });
        /* } else {
            resolve({ validation: false, errorMessage: "Faltan datos de la empresa " + enterprise.name + " por llenar. Ve a Administrar > Organizaciones > Configuración > Perfil de organización y llena los datos faltantes." });
        } */
    });
}

export const returnBrowser = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Desconocido";
      // Detectar navegador
    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
    } else if (userAgent.match(/msie|trident/i)) {
        browserName = "Internet Explorer";
    }
    return browserName;
}

export const returnBrowser2 = () => {
    let userAgentData = navigator.userAgentData;
    if (Object.keys(userAgentData).length > 0) {
        if(navigator.userAgentData.brands.length > 2 ){
            userAgentData = navigator.userAgentData.brands[1];
            return userAgentData.brand;
        }else{
            return "Desconocido";
        }
       
    } else {
        userAgentData = navigator.userAgent;
        if (userAgentData) {
            if (userAgentData.indexOf("Opera") > -1) {
                return "Opera";
            } else if (userAgentData.indexOf("Firefox") > -1) {
                return "Mozilla Firefox";
            } else if (userAgentData.indexOf("MSIE") > -1) {
                return "Microsoft Internet Explorer";
            } else if (userAgentData.indexOf("Safari") > -1) {
                return "Apple Safari";
            }else {
                return "Desconocido";
            }
        }else {
            return "Desconocido";
        }
    }
}

export const returnOS = () => {
    let { platform } = navigator;
    if (platform) {
        if (platform.indexOf("Win32") > -1) {
            return "Windows";
        } else if (platform.indexOf("MacIntel") > -1) {
            return "MacIntel";
        } else {
            return "";
        }
    }
}

/**
 * Promise to obtain geoLocationPosition
 * 
 * example of use: 
 * const v = await getLocation.then(response => {
 *      return response
 * }).catch(error => {
 *      return error
 * })
 * console.log(v);
 * remember to use inside of async function
 * 
 * @returns response success || error
 */
export const getLocation = new Promise((resolve, reject) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve(`${position.coords.latitude}, ${position.coords.longitude}`);
        }, (error) => {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    reject("User denied the request for Geolocation.");
                    break;
                case error.POSITION_UNAVAILABLE:
                    reject("Location information is unavailable.");
                    break;
                case error.TIMEOUT:
                    reject("The request to get user location timed out.");
                    break;
                case error.UNKNOWN_ERROR:
                    reject("An unknown error occurred.")
                    break;
                default:
                    reject("An unknown error occurred.")
                    break;
            }
        });
    } else {
        reject("Geolocation is not supported by this browser.");
    }
})

export const convertStringToDate = function (string) {
    var parts = string.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

export const convertDateToYYYYMMDD = function (date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
}

export const convertDateToYYYYMMDDBirth = function (date, age) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    return [date.getFullYear() - age,
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
}

export const convertDateToWords = function (date) {
    let mm = date.getMonth()
    let dd = date.getDate()

    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']

    return `${dd} de ${months[mm]} de ${date.getFullYear()}`
}

export const colorsArray = ["#9cb537", "#af2f72", "#2188b4", "#dfa443",
    "#7d9645", "#be5b70", "#4e81ad", "#d69356",
    "#3f4c40", "#9a5587", "#6979a4", "#c7a73d",
    "#29514e", "#8d6391", "#7d6f9b", "#15253c"];

export const deleteSteps = () => {
    let pathname = window.location.pathname
    if (sessionStorage.getItem('step') !== null) {
        let value = sessionStorage.getItem('step').split(',')
        let currentPath = value[1]
        if (pathname !== currentPath) {
            sessionStorage.removeItem('step')
        }
    }
}

export const handleScroll = (selector) => {
    // element which needs to be scrolled to
    const element = document.getElementById(selector);
    if (document.getElementById(selector) !== null) {
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }
}

export const handleScrollStart = (selector) => {
    // element which needs to be scrolled to
    const element = document.getElementById(selector);
    if (document.getElementById(selector) !== null) {
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "start" });
    }
}
