import React from 'react'
import { convertDateToYYYYMMDDBirth } from '../../../helpers/helpers'

const DentalForm = ({
    dataCollab,
    getValue,
    genderCat,
    relationBenefitEnterprise,
    getFormateDate,
}) => {
    const {
        curp,
        gender,
        birthdate,
    } = dataCollab
    return (
        <>
            {relationBenefitEnterprise && (
                <>
                    <div class="form-group">
                        <label htmlFor="curp">CURP:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="curp"
                            name="curp"
                            onChange={getValue}
                            value={curp}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='gender'>Género:</label>
                        <select
                            className="form-control"
                            id="gender"
                            name="gender"
                            onChange={getValue}
                            value={gender}
                        >
                            <option value=''>Seleccionar</option>
                            {genderCat.map(data => (
                                <option
                                    key={`dental-${data.idGender}`}
                                    value={data.idGender}
                                >{data.description}</option>
                            ))}
                        </select>
                    </div>
                    <div class="form-group">
                        <label htmlFor="birthdate">Cumpleaños:</label>
                        <input
                            type="date"
                            className="form-control"
                            id="birthdate"
                            name="birthdate"
                            onChange={getValue}
                            value={getFormateDate(birthdate)}
                            min={convertDateToYYYYMMDDBirth(new Date(), 110)}
                            max={convertDateToYYYYMMDDBirth(new Date(), 18)}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export default DentalForm