import axios from "axios";

/*
* Set recovery email
* formData content:
* + JSON with user data
*/
export const recoveryPass = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "users/recoveryPassword",
            method: 'POST',
            data: formData,
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            }
        });
        return result.data ? result.data : 0;
    } catch (error) {
        console.log(error);
    }
};


/*
* Activate account
* formData content:
* + JSON with user data
*/

export const activateAccounts = async (db, gdb) => {
    try {
        let result = await axios({
            url: global.base_url + "users/active-user?db=" + gdb + "&gdb=" + db + "&ko=2",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            }
        });
        return result.data ? result.data : 0;
    } catch (error) {
        console.log(error);
    }
};

/*
* Validate token
* formData content:
* + JSON with user data
*/
export const validateToken = async (db, gc) => {
    try {
        let result = await axios({
            url: global.base_url + "users/validate-token?db=" + db + "&gc=" + gc,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            }
        });
        return result.data ? result.data : 0;
    } catch (error) {
        console.log(error);
    }
};



/*
* update user password
* formData content:
* + JSON with user data
*/
export const updatePasswordFGT = async (formData) => {
    try {
        const user = formData.id_user;
        const key = formData.password_1;
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "users/credentials",
            method: 'POST',
            params: {
                user,
                key
            }
        });
        return result.data ? result.data : 0;
    } catch (error) {
        console.log(error);
    }
};
