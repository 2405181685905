/*
 File Name: ClinicalFilterHTK_model.js
 Description: Manage Surveys API Requests
 guay dev's team
*/
// TODO: Tools
import * as toastr from 'toastr';
import axios from "axios";

/**
 * Metodo para obtener encuesta clinico
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @returns JSON Object
 */
export const validationAccessFCHTK = async (collaboratorId, idEnterprise) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `HealthTrackingV2Controller/validationAccessFCHTK?idCollaborator=${collaboratorId}&idEnterprise=${idEnterprise}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * 
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} idCollabSurveyControlFCHTK 
 * @param {int} consent 
 * @param {int} pregnant 
 * @returns 
 */
export const valAssistanceConsent = async (collaboratorId, idEnterprise, idCollabSurveyControlFCHTK, consent, pregnant) => {
    let request = {
        idCollaborator: collaboratorId,
        idEnterprise: idEnterprise,
        idCollabSurveyControlFCHTK: idCollabSurveyControlFCHTK,
        consent: consent,
        pregnant: pregnant
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "HealthTrackingV2Controller/valAssistanceConsent",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Guardar respuesta clinicos
 * @param {*} idAnswer 
 * @param {*} idSubanswer 
 * @param {*} idQuestion 
 * @param {*} idEnterprise 
 * @param {*} idCollabSurveyControlFCHTK 
 * @param {*} idCollab 
 * @param {*} answerText
 * @returns 
 */
export const saveResponseClinic = async (idAnswer, idSubanswer, idQuestion, idEnterprise, idCollabSurveyControlFCHTK, idCollab, answerText, userDate, coordinates, browser, os) => {
    var data = new FormData();
    data.append("request", `{idAnswer: ${idAnswer}, idSubAnswer: "${idSubanswer}", idQuestion: ${idQuestion}, idEnterprise: ${idEnterprise}, idCollabSurveyControlFCHTK: ${idCollabSurveyControlFCHTK}, idCollab: ${idCollab}, answerText: "${answerText}", userDate: ${userDate}, coordinates: ${coordinates}, browser: ${browser}, os: ${os}, userIP:"", providerIP:""}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "HealthTrackingV2Controller/saveResponseClinic",
            method: 'POST',
            data,
            redirect: 'follow'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Guardar respuestas abiertas
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @param {*} idCollabSurveyControlFCHTK 
 * @param {*} arrayOpenQuestions 
 * @returns 
 */
export const saveResponseOpenClinic = async (collaboratorId, idEnterprise, idCollabSurveyControlFCHTK, arrayOpenQuestions) => {
    var data = new FormData();
    data.append("idEnterprise", idEnterprise);
    data.append("idCollabSurveyControlFCHTK", idCollabSurveyControlFCHTK);
    data.append("idCollab", collaboratorId);
    data.append("responsesOpen", arrayOpenQuestions);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "HealthTrackingV2Controller/saveResponseOpenClinic",
            method: 'POST',
            data,
            redirect: 'follow'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Terminar encuesta clinicos
 * @param {*} collaboratorId 
 * @param {*} idEnterprise 
 * @param {*} idCollabSurveyControlFCHTK 
 * @returns 
 */
export const updFinishSurveyFC = async (collaboratorId, idEnterprise, idCollabSurveyControlFCHTK) => {
    var data = new FormData();
    data.append("idCollaborator", collaboratorId);
    data.append("idEnterprise", idEnterprise);
    data.append("idCollabSurveyControlFCHTK", idCollabSurveyControlFCHTK);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "HealthTrackingV2Controller/updFinishSurveyFC",
            method: 'POST',
            data,
            redirect: 'follow'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
};

export const saveResponseHTK = async (data) => {
    try {
        let formData = new FormData();
        formData.append('request', JSON.stringify(data));
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}HealthTrackingV2Controller/saveResponseHTK`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1000:
                        toastr.info(`Problemas para manejar la petición. Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        toastr.info('La empresa o el colaborador están inactivos. Contacta un administrador de tu empresa y verifica el estado de la empresa o colaborador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1003:
                        toastr.info('La evaluación ya fue contestada. Si no fue por ti, contacta a tu administrador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
}

export const updFinishSurveyHTK = async (objectData) => {
    try {
        let formData = new FormData();
        formData.append("idCollaborator", objectData.idCollaborator);
        formData.append("idEnterprise", objectData.idEnterprise);
        formData.append("idCollabSurveyControlFCHTK", objectData.idCollabSurveyControlFCHTK);
        console.log(formData);
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}HealthTrackingV2Controller/updFinishSurveyHTK`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1000:
                        toastr.info(`Problemas para manejar la petición. Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        toastr.info('La empresa o el colaborador están inactivos. Contacta un administrador de tu empresa y verifica el estado de la empresa o colaborador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
}

export const saveResponseHabits = async (data) => {
    try {
        let formData = new FormData();
        formData.append('request', JSON.stringify(data));
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}HealthTrackingV2Controller/saveResponseHabits`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1000:
                        toastr.info(`Problemas para manejar la petición. Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        toastr.info('La empresa o el colaborador están inactivos. Contacta un administrador de tu empresa y verifica el estado de la empresa o colaborador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1003:
                        toastr.info('La evaluación ya fue contestada. Si no fue por ti, contacta a tu administrador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
}

export const updFinishSurveyHabits = async (objectData) => {
    try {
        let formData = new FormData();
        formData.append("idCollaborator", objectData.idCollaborator);
        formData.append("idEnterprise", objectData.idEnterprise);
        formData.append("idCollabSurveyControlFCHTK", objectData.idCollabSurveyControlFCHTK);
        console.log(formData);
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}HealthTrackingV2Controller/updFinishSurveyHabits`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1000:
                        toastr.info(`Problemas para manejar la petición. Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        toastr.info('La empresa o el colaborador están inactivos. Contacta un administrador de tu empresa y verifica el estado de la empresa o colaborador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1002:
                        toastr.info('Tu evaluación se encuentra inactiva. Contacta un administrador de tu empresa y verifica el estado de la evaluación.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1003:
                        toastr.info('Faltan preguntas por contestar. Por favor verifica tu evaluación.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
}

export const getProtocolFCHTK  = async (objectData) => {
    try {
        let formData = new FormData();
        formData.append("idCollaborator", objectData.idCollaborator);
        formData.append("idEnterprise", objectData.idEnterprise);
        formData.append("idCollabSurveyControlFCHTK", objectData.idCollabSurveyControlFCHTK);
        console.log(formData);
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}HealthTrackingV2Controller/getProtocolFCHTK `,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 2:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        toastr.info('La empresa o el colaborador están inactivos. Contacta un administrador de tu empresa y verifica el estado de la empresa o colaborador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1002:
                        toastr.info('Tu evaluación se encuentra inactiva. Contacta un administrador de tu empresa y verifica el estado de la evaluación.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Hubo un problema en obtener tu diagnóstico: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
}

export const updFinishFCHTK = async (objectData) => {
    try {
        let formData = new FormData();
        formData.append("idCollaborator", objectData.idCollaborator);
        formData.append("idEnterprise", objectData.idEnterprise);
        formData.append("idCollabSurveyControlFCHTK", objectData.idCollabSurveyControlFCHTK);
        formData.append("anonymous", objectData.anonymous);
        console.log(formData);
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}HealthTrackingV2Controller/updFinishFCHTK`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        toastr.info('La empresa o el colaborador están inactivos. Contacta un administrador de tu empresa y verifica el estado de la empresa o colaborador.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1002:
                        toastr.info('Tu evaluación se encuentra inactiva. Contacta un administrador de tu empresa y verifica el estado de la evaluación.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
}
