import React, { Fragment } from 'react';
import Slider from "react-rangeslider";
import { logoutHook } from "../../app/LogOut";
import { useHistory } from "react-router-dom";

const Header = (empresa) => {
    const label = {
        0: '0 de 4'
    }

    const history = useHistory();

    const handleLogout = (event) => {
        event.preventDefault();

        try {
            logoutHook().then(result => {
                console.log(result)
                if (result === true) {
                    history.push("/acceso");
                }
            })
        } catch (e) {
            alert(e.message);
        }
    }

    return (
        <Fragment>
            <nav className="header-navbar navbar-expand-md navbar navbar-with-menu nav-modification mx-auto navbar-without-dd-arrow fixed-top bg-white navbar-brand-center shadow" data-nav="brand-center" id="new-nav-2021">
                <div className="navbar-wrapper">
                    <div className="navbar-header" id="new-nav-2021">
                        <ul className="nav navbar-nav flex-row">
                            <li className="nav-item mobile-menu d-md-none mr-auto">
                                {/* eslint-disable-next-line*/}
                                <a className="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="#">
                                    <i className="ft-menu font-large-1"></i>
                                </a>
                            </li>
                            {/* eslint-disable-next-line*/}
                            <li className="nav-item"><a className="navbar-brand" href="#"><img alt="guay" src="./../img/guay-logo.png" style={{ height: '25px' }} /> </a></li>
                        </ul>
                    </div>
                    <div className="navbar-container content">
                        <div className="collapse navbar-collapse" id="navbar-mobile">
                            <ul className="nav navbar-nav mr-auto float-left">
                                {/* eslint-disable-next-line*/}
                                <li className="nav-item d-none d-md-block"><a className="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="#"><i className="ft-menu"></i></a></li>
                                {empresa.empresa === true ?
                                    <li className="dropdown dropdown-notification nav-item">
                                        <img className="img-icon-2021" src="./../img/imagesHeader/loguin_pregunta.png" data-toggle="dropdown" alt="guay-digital" />
                                        <div className="dropdown-menu quick-start">
                                            <div className="row">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                    <p>Quick start</p>
                                                    <p>Información básica</p>
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 quick-start-slider">
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        value={10}
                                                        labels={label}
                                                    />
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row d-flex align-items-center justify-content-center">
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-check align-middle ft-check"></i></div>
                                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-8">Alta organización</div>
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-plus-2 align-middle ft-plus-circle"></i></div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row d-flex align-items-center justify-content-center">
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-check align-middle ft-check"></i></div>
                                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-8">Carga de Colaboradores</div>
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-plus-2 align-middle ft-plus-circle"></i></div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row d-flex align-items-center justify-content-center">
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-check align-middle ft-check"></i></div>
                                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-8">Configura Centros de Trabajo</div>
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-plus-2 align-middle ft-plus-circle"></i></div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row d-flex align-items-center justify-content-center">
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-check align-middle ft-check"></i></div>
                                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-8 align-middle">Revisa Colaboradores</div>
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-plus-2 align-middle ft-plus-circle"></i></div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                    <div className="row d-flex align-items-center justify-content-center">
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-check align-middle ft-check"></i></div>
                                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-8 align-middle">Revisa áreas</div>
                                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-1"><i className="icon-plus-2 align-middle ft-plus-circle"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    :
                                    null}
                            </ul>
                            <ul className="nav navbar-nav float-right">
                                {/* eslint-disable-next-line*/}
                                <li className="dropdown dropdown-notification nav-item" data-tut="step-1"><a className="nav-link nav-link-label text-green" data-toggle="modal" data-target="#myModal" href="#"><img className="icon-nav-2021" src="./../img/imagesHeader/iconos_guay_inicio_01.png" alt="guay-digital" /></a></li>
                                {/* eslint-disable-next-line*/}
                                <li className="dropdown dropdown-notification nav-item" data-tut="step-2"><a className="nav-link nav-link-label text-green" href="#"><img className="icon-nav-2021" src="./../img/imagesHeader/iconos_guay_inicio-02.png" alt="guay-digital" /></a></li>
                                {/* eslint-disable-next-line*/}
                                {/* <li className="dropdown dropdown-notification nav-item">
                                    eslint-disable-next-line
                                    <a className="nav-link nav-link-label text-yellow" data-toggle="dropdown" href="#"><img className="icon-nav-2021" src="./../img/inicio/iconos_guay_inicio-03.png" alt="guay-digital"/></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <h4 className="dropdown-header m-0 text-center text-bold-600">Organización</h4>
                                        <a className="dropdown-item" href="/configuracion/empresas">
                                            <i className="la la-building text-base-guay"></i>Datos Empresa
                                    </a>
                                        <a className="dropdown-item" href="/configuracion/empresas/colaboradores">
                                            <i className="ft-users text-base-guay"></i> Carga colaboradores
                                    </a>
                                        <a className="dropdown-item" href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf">
                                            <i className="la la-file-text-o text-base-guay"></i> Politica de Privacidad | Condiciones
                                    </a>
                                    </div>
                                </li>*/}
                                {/* eslint-disable-next-line*/}
                                <li className="dropdown dropdown-notification nav-item"><a className="nav-link nav-link-label" onClick={handleLogout} href="#"><img className="icon-nav-2021" src="./../img/imagesHeader/iconos_guay_inicio-04.png" alt="guay-digital" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            {/* Modal */}
            <div className="modal" id="myModal">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content hide-modal-2">


                        {/*<div class="modal-header">
                            <h4 class="modal-title">Modal Heading</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>*/}


                        <div className="modal-body hide-modal-2">
                            <div className="row">
                                <div className="col-sm-12 d-flex align-items-center justify-content-center">

                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                        <div className="globo iii inModal abajo-izquierda text-center">
                                            <p>Te estamos redireccionando a nuestra tienda en linea</p>
                                            <button className="btn float-left mb-2" id="btn-modal-tienda" data-dismiss="modal">Permancer aquí</button>
                                            <button className="btn float-right mb-2" id="btn-modal-tienda">Ir</button>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-sm-12 d-flex align-items-center justify-content-center">
                                    <div className="row">
                                        <div className="col"><img className="dummy-guay" src="./../img/imagesHeader/Elementos_GUAY-06.png" alt="guay-digital" /></div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*<div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>*/}

                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Header;