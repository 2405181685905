import React, { useState } from 'react';
import $ from "jquery";

const IntroSurvey = () => {

    const [btnState, setBtnState] = useState(true);

    return (
        <div className="modal" id="modal-1" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto" style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body modal-div-bodered container-style-1 pb-2">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <h1 className="font-weight-bold">Introducción Encuesta</h1>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 mt-1">
                            <p className="text-justify">Queremos conocer tu situación laboral y cómo te sientes, así que dáte un tiempo para responder las siguientes preguntas: lee cuidadosamente y elige la respuesta que mejor te describa considerando los últimos dos meses, al ser de índole personal no hay respuestas correctas o incorrectas. </p>
                            <p className="text-justify">Este cuestionario da cumplimiento a la NOM-035-STPS-2018 por lo que es de carácter obligatorio y la información obtenida será tratada de manera confidencial. </p>
                            <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener un resultado válido y confiable.</p>
                            <p className="text-justify">Agradecemos tu colaboración.</p>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            <div className="form-group form-check">
                                <label className="form-check-label pointer font-medium-1">
                                    <input type="checkbox" className="form-check-input" value="" onChange={() => setBtnState(!btnState)} /> Al dar click aceptas nuestro <span className="text-blue font-weight-bold" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>aviso de privacidad</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            {
                                !btnState ? <button className="btn btn-small btn-blue-3 float-right font-medium-1" onClick={() => $('#modal-1').hide()} disabled={btnState}>Encuesta</button> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroSurvey
