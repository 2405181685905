import React from 'react';
// Components
import StatisticalFilterMultipleAnswers from './StatisticalFilterMultipleAnswers';
import StatiticalFilterAnswer from './StatiticalFilterAnswer';

const StatisticalFilterStructure = ({ listQuestions, openQuestion, handleSelectAnswer, setOpenQuestion, handleCompleteSurvey }) => {
    return (
        <div className="row">
            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto font-medium-3 mt-1">
                <span className="text-base font-weight-bold">Acontecimiento traumático severo - Profundización</span>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto font-medium-1 mt-1">
                {
                    listQuestions.map((question) => (
                        question.idQuestion <= 6
                            ?
                            (
                                <div
                                    className="card shadow-card-1"
                                    key={question.idQuestion}
                                    id={question.idQuestion} >
                                    <div className="card-header bg-blue-guay-2 py-1">
                                        <span>{question.description}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            {
                                                question.idQuestion === 6
                                                    ?
                                                    (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <div className="form-group m-0">
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="2"
                                                                            maxLength="100"
                                                                            onChange={(e) => { setOpenQuestion(e.target.value); handleCompleteSurvey() }}
                                                                            value={openQuestion}></textarea>
                                                                        <label className="text-base">* Ingrese hasta 100 caracteres en su respuesta.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <StatisticalFilterMultipleAnswers
                                                                idQuestion={question.idQuestion}
                                                                idType={question.idType}
                                                                listAnswers={question.listAnswers}
                                                                handleSelectAnswer={handleSelectAnswer}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <StatisticalFilterMultipleAnswers
                                                            idQuestion={question.idQuestion}
                                                            idType={question.idType}
                                                            listAnswers={question.listAnswers}
                                                            handleSelectAnswer={handleSelectAnswer}
                                                        />
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (null)
                    ))
                }
            </div>
            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto font-medium-3 mt-1">
                <span className="text-base font-weight-bold">Con qué frecuencia has experimentando lo siguiente:</span>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto font-medium-1 mt-1">
                {
                    listQuestions.map((question) => (
                        question.idQuestion > 6
                            ?
                            (
                                <div
                                    className="card shadow-card-1"
                                    key={question.idQuestion}
                                    id={question.idQuestion}>
                                    <div className="card-header bg-blue-guay-2 py-1">
                                        <span>{question.description}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <StatiticalFilterAnswer
                                                idQuestion={question.idQuestion}
                                                idType={question.idType}
                                                listAnswers={question.listAnswers}
                                                handleSelectAnswer={handleSelectAnswer}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (null)
                    ))
                }
            </div>
        </div>
    )
}

export default StatisticalFilterStructure;
