//actions
const SET_LAUNCH = 'SET_LAUNCH';

const initialState = {
    launch: null, // lanzamiento normativo: 1 o complementario
    launchType: null, // lanzamiento independiente: 1 o conjunto: 2
    launchName: null, // nombre del lanzamiento
    flagLaunch: null, // lanzamiento por centros de trabajo: 1 o por toda la empresa: 0
    launchDate: null, // fecha del lanzamiento
    launchCT: null // array de los lanzamientos por centros de trabajo
}

// reducer
const launchReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_LAUNCH:
            return {
                ...state,
                ...action.payload
            }

        default:
            return state;
    }

}

export default launchReducer;

//auxiliar
const saveStorage = function (data) {
    sessionStorage.setItem('launch', btoa(JSON.stringify(data)));
}

const removeStorage = function () {
    sessionStorage.removeItem('launch');
}

// action creators
export const restoreLaunchAction = () => (dispatch) => {
    if (sessionStorage.getItem('launch') !== null) {
        const launch = JSON.parse(atob(sessionStorage.getItem('launch')));
        if (launch && launch.launch !== null) {
            dispatch({
                type: SET_LAUNCH,
                payload: {
                    ...launch
                }
            })
        }
    }
}

export const modifyLaunch = (launch, launchType, launchName, flagLaunch, launchDate, launchCT) => (dispatch, getState) => {
    dispatch({
        type: SET_LAUNCH,
        payload: {
            launch, launchType, launchName, flagLaunch, launchDate, launchCT
        }
    });
    saveStorage(getState().launch);
}

export const resetLaunch = () => (dispatch, getState) => {
    dispatch({
        type: SET_LAUNCH,
        payload: {
            ...initialState
        }
    });
    saveStorage(getState().launch);
}

export const clearLaunch = () => (dispatch, _getState) => {
    dispatch({
        type: SET_LAUNCH,
        payload: {
            ...initialState
        }
    });
    removeStorage();
}
