import React from 'react'

const PersonalInfoForm = ({
    tcProfileMaritalStatus,
    tcProfileScholarship,
    tcProfileTransferTime,
    tcProfileConveyance,
    tcProfileDependents,
    tcProfileSleepTime,
    tcProfileWorkSchemes,
    setShowForm,
    personalInfoObj,
    setPersonalInfoObj,
    handleOnSubmitForm,
}) => {
    const {
        idMaritalStatus,
        idScholarship,
        scholarshipComplete,
        idTransferTime,
        idConveyance,
        idDependents,
        idSleepTime,
        idWorkScheme,
    } = personalInfoObj
    const handleOnChange = e => {
        setPersonalInfoObj({
            ...personalInfoObj,
            [e.target.name]: e.target.value,
        })
    }
    const handleOnSubmit = e => {
        e.preventDefault()
        //console.log(personalInfoObj)
        handleOnSubmitForm(2, personalInfoObj)
    }
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='col-12'>
                    <p className='font-weight-bold font-medium-5'>Información Personal</p>
                </div>
            </div>
            <form onSubmit={handleOnSubmit}>
                <div className='row'>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='idMaritalStatus'
                                className='m-0'
                            >Estado Civil</label>
                            <select
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='idMaritalStatus'
                                name='idMaritalStatus'
                                value={idMaritalStatus}
                                onChange={handleOnChange}
                            >
                                <option value='0'>Seleccionar</option>
                                {
                                    tcProfileMaritalStatus.map(marital => (
                                        <option
                                            key={`marital-${marital.idMaritalStatus}`}
                                            value={marital.idMaritalStatus}
                                        >{marital.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='idConveyance'
                                className='m-0'
                            >¿Qué medio de transporte utilizas para trasladarte al trabajo?</label>
                            <select
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='idConveyance'
                                name='idConveyance'
                                value={idConveyance}
                                onChange={handleOnChange}
                            >
                                <option value='0'>Seleccionar</option>
                                {
                                    tcProfileConveyance.map(conveyance => (
                                        <option
                                            key={`conveyance-${conveyance.idConveyance}`}
                                            value={conveyance.idConveyance}
                                        >{conveyance.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='idScholarship'
                                className='m-0'
                            >Escolaridad</label>
                            <select
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='idScholarship'
                                name='idScholarship'
                                value={idScholarship}
                                onChange={handleOnChange}
                            >
                                <option value='0'>Seleccionar</option>
                                {
                                    tcProfileScholarship.map(scholar => (
                                        <option
                                            key={`scholar-${scholar.idScholarship}`}
                                            value={scholar.idScholarship}
                                        >{scholar.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='idDependents'
                                className='m-0'
                            >¿Quienes son tus dependientes económicos?</label>
                            <select
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='idDependents'
                                name='idDependents'
                                value={idDependents}
                                onChange={handleOnChange}
                            >
                                <option value='0'>Seleccionar</option>
                                {
                                    tcProfileDependents.map(dependents => (
                                        <option
                                            key={`dependents-${dependents.idDependents}`}
                                            value={dependents.idDependents}
                                        >{dependents.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto d-flex flex-wrap align-content-center'>
                        <div className='row'>
                            <div className='col-12'>
                                <label
                                    htmlFor='scholarshipComplete'
                                    className='m-0'
                                >Tu último nivel de estudios es:</label>
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='form-check-inline'>
                                            <label className='form-check-label'>
                                                <input
                                                    type='radio'
                                                    className='form-check-input'
                                                    name='scholarshipComplete'
                                                    value='1'
                                                    onChange={handleOnChange}
                                                    checked={scholarshipComplete === '1' ? true : scholarshipComplete === 1 ? true : false}
                                                />Completo
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-check-inline'>
                                            <label className='form-check-label'>
                                                <input
                                                    type='radio'
                                                    className='form-check-input'
                                                    name='scholarshipComplete'
                                                    value='2'
                                                    onChange={handleOnChange}
                                                    checked={scholarshipComplete === '2' ? true : scholarshipComplete === 2 ? true : false}
                                                />Incompleto
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='idSleepTime'
                                className='m-0'
                            >¿Cuántas horas duermes en promedio?</label>
                            <div className='col-12 my-1' style={{ height: '50px' }}>
                                <div
                                    className='row d-flex justify-content-between'
                                    style={{
                                        backgroundColor: '#6b6f82',
                                        height: '5px',
                                    }}>
                                    {
                                        tcProfileSleepTime.map(sleep => (
                                            <div style={{
                                                backgroundColor: '#6b6f82',
                                                width: '2.5px',
                                                height: '30px'
                                            }}>
                                                <div style={{
                                                    position: 'relative',
                                                    top: '-16px',
                                                    left: '-16px',
                                                    width: '35px',
                                                    height: '35px',
                                                }}>
                                                    {idSleepTime === sleep.idSleepTime && (
                                                        <img
                                                            className='pointer'
                                                            src='./../img/imagesProfile/Elemento_136.png'
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                            }}
                                                            alt='clock'
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className={idSleepTime === sleep.idSleepTime ? ('font-small-2 pointer font-weight-bold') : ('font-small-2 pointer')}
                                                    style={{
                                                        position: 'relative',
                                                        bottom: '0',
                                                        left: 'calc(-3rem + -50%)',
                                                        width: '80px'
                                                    }}
                                                    onClick={() => {
                                                        setPersonalInfoObj({
                                                            ...personalInfoObj,
                                                            idSleepTime: sleep.idSleepTime,
                                                        })
                                                    }}
                                                ><p className='text-center'>{sleep.description}</p></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label htmlFor='idTransferTime'>Tiempo total para trasladarte a tu Centro de trabajo (ida y vuelta)</label>
                            <div className='col-12 my-1' style={{ height: '50px' }}>
                                <div
                                    className='row d-flex justify-content-between'
                                    style={{
                                        backgroundColor: '#6b6f82',
                                        height: '5px',
                                    }}>
                                    {
                                        tcProfileTransferTime.map(transfer => (
                                            <div style={{
                                                backgroundColor: '#6b6f82',
                                                width: '2.5px',
                                                height: '30px'
                                            }}>
                                                <div style={{
                                                    position: 'relative',
                                                    top: '-15px',
                                                    left: '-15px',
                                                    width: '35px',
                                                    height: '35px',
                                                }}>
                                                    {idTransferTime === transfer.idTransferTime && (
                                                        <img
                                                            className='pointer'
                                                            src='./../img/imagesProfile/Elemento_136.png'
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                            }}
                                                            alt='clock'
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className={idTransferTime === transfer.idTransferTime ? ('font-small-2 pointer font-weight-bold') : ('font-small-2 pointer')}
                                                    style={{
                                                        position: 'relative',
                                                        bottom: '0',
                                                        left: 'calc(-3rem + -50%)',
                                                        width: '80px'
                                                    }}
                                                    onClick={() => {
                                                        setPersonalInfoObj({
                                                            ...personalInfoObj,
                                                            idTransferTime: transfer.idTransferTime,
                                                        })
                                                    }}
                                                ><p className='text-center'>{transfer.description}</p></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='idWorkScheme'
                                className='m-0'
                            >¿Qué esquema de trabajo tienes?</label>
                            <select
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='idWorkScheme'
                                name='idWorkScheme'
                                value={idWorkScheme}
                                onChange={handleOnChange}
                            >
                                <option value='0'>Seleccionar</option>
                                {
                                    tcProfileWorkSchemes.map(schemes => (
                                        <option
                                            key={`schemes-${schemes.idWorkScheme}`}
                                            value={schemes.idWorkScheme}
                                        >{schemes.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button
                            type='button'
                            className='btn btn-sm btn-save-blue'
                            onClick={() => setShowForm(0)}
                        >Regresar</button>
                    </div>
                    <div className='col'>
                        <button
                            type='submit'
                            className='btn btn-sm btn-pink float-right'
                        >Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PersonalInfoForm
