/**
 * Properties for API 
 */
try {
    //const protocol = window.location.protocol;
    const host = window.location.host;
    switch (host) {
        case "localhost:3000":
            global.base_url = "https://api.development.guay.digital/v2/";
            //global.base_url = "http://localhost:8080/v2/"; 
            //global.base_url_guay = "https://development.guay.digital/loginExterno";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            global.urlSupport = 'https://help.development.guay.digital/html/manual/index.html';
            global.domain = 'localhost:3000'
            global.surveyFront = 'https://surveyapi.development.guay.digital/'
            global.surveyFrontPage = 'https://frontsurvey.development.guay.digital/'
            break;
        case "frontend.development.guay.digital":
            global.base_url = "https://api.development.guay.digital/v2/";
            global.base_url_guay = "https://development.guay.digital/loginExterno";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            global.urlSupport = 'https://help.development.guay.digital/html/manual/index.html';
            global.domain = '.guay.digital'
            global.surveyFront = 'https://surveyapi.development.guay.digital/'
            global.surveyFrontPage = 'https://frontsurvey.development.guay.digital/'
            break;
        case "frontend.staging.guay.digital":
            global.base_url = "https://api.staging.guay.digital/v2/";
            global.base_url_guay = "https://development.guay.digital/loginExterno";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            global.urlSupport = 'https://help.staging.guay.digital/html/manual/index.html';
            global.domain = '.guay.digital'
            global.surveyFront = 'https://surveyapi.staging.guay.digital/'
            global.surveyFrontPage = 'https://frontsurvey.staging.guay.digital/'
            break;
        case "somosguay.com":
            global.base_url = "https://api.guay.digital/v2/";
            global.base_url_guay = "https://guay.digital/loginExterno";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTM2MzQ5ODcsImlhdCI6MTYzNTk1NDk4N30.JZtCAcEfChOd3M37GS61iPrO-PFqCwsSICcBHscBS20L6Vt8-2fNoilGzenuLS5TfKHgisU7-RBteQFmur8OVQ";
            global.urlSupport = 'https://help.somosguay.com/html/manual/index.html';
            global.domain = '.somosguay.com'
            global.surveyFront = 'https://surveyapi.guay.digital/'
            global.surveyFrontPage = 'https://frontsurvey.somosguay.com/'
            break;
        default:
            global.base_url = "https://api.development.guay.digital/v2/";
            global.base_url_guay = "https://development.guay.digital/loginExterno";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            global.urlSupport = 'https://help.development.guay.digital/html/manual/index.html';
            global.domain = 'localhost:3000'
            global.surveyFront = 'https://surveyapi.development.guay.digital/'
            global.surveyFrontPage = 'https://frontsurvey.development.guay.digital/'
            break;
    }
} catch (error) {
    console.log(error);
}