import React, {
    //useState,
    useRef,
} from 'react'
// TODO: API calls
import {
    saveComplementaryEvidence,
    addEvidenceFile,
    editComplementaryEvidence,
} from '../../Models/ComplaintModel'
// TODO: Components
import Loader from '../../LoaderApp'
// TODO: Tools
import Dropzone from 'react-dropzone'
import * as toastr from 'toastr'
import $ from 'jquery'
import Swal from 'sweetalert2'

const ComEviForm = ({
    resourceObj,
    setResourceObj,
    handleResetTable,
    savedFiles,
    setSavedFiles,
    deleteSpecificEvidence,
    service,
    files,
    setFiles,
    sizeFileTotal,
    setSizeFileTotal,
}) => {
    const {
        idComplementaryEvidence,
        idUser,
        //resourceType,
        title,
        description,
    } = resourceObj

    const inputRef = useRef()

    const tipoArchivos = {
        archivos: {
            filesType: '.jpg, .jpeg, .png, .gif, .pdf, .mp3, .mp4, .rar, .zip',
            maxFiles: '250MB',
        },
        /* audio: {
            filesType: '.mp3',
            maxFiles: '250MB',
        },
        video: {
            filesType: '.mp4',
            maxFiles: '250MB',
        }, */
    }
    const {
        archivos,
        /* audio,
        video, */
    } = tipoArchivos

    const onDrop = (acceptedFiles, fileRejections) => {
        /* if (resourceType === '') {
            toastr.info('Primero debes seleccionar un tipo de evidencia.', '¡Ooops!')
            return
        } */
        if (idComplementaryEvidence === '' && files.length === 10) {
            toastr.info('El máximo de archivos a sido alcanzado. Eliminar algún archivo por subir para subir uno nuevo.', '¡Ooops!')
            return
        }
        if (idComplementaryEvidence === '' && (files.length + fileRejections.length) > 10) {
            toastr.info(`Solo puedes subir ${10 - files.length} más.`, '¡Ooops!')
            return
        }
        if (idComplementaryEvidence !== '' && savedFiles.length === 10) {
            toastr.info('El máximo de archivos a sido alcanzado. Eliminar algún archivo guardado para subir uno nuevo.', '¡Ooops!')
            return
        }
        if (idComplementaryEvidence !== '' && (savedFiles.length + files.length + fileRejections.length) > 10) {
            toastr.info(`Solo puedes subir ${10 - (savedFiles.length + files.length)} más.`, '¡Ooops!')
            return
        }
        let acceptFilesValidated = []
        let sizeFile = 0
        let noFiles = 0
        acceptedFiles.forEach(element => {
            let file = element
            /* if (resourceType === 3) {
                if (file.size < 50000000) {
                    acceptFilesValidated.push(element)
                }
            } else {
                if (file.size < 250000000) {
                    acceptFilesValidated.push(element)
                }
            } */
            //console.log(`file.size: ${file.size}`)
            //console.log(`sizeFile: ${sizeFile}`)
            //console.log(`sizeFileTotal: ${sizeFileTotal}`)
            if ((file.size + sizeFile + sizeFileTotal) < 250000000) {
                sizeFile = sizeFile + file.size
                acceptFilesValidated.push(element)
            } else {
                noFiles = noFiles + 1
            }
        })
        if (noFiles > 0) {
            toastr.info(`Hay ${noFiles} archivos que no se cargaron porque superan los 250MB totales.`, '¡Ooops!')
        }
        /* if (acceptFilesValidated.length < acceptedFiles.length) {
            toastr.info('No todos los archivos se van a subir porque no cumplen con los requerimientos.', '¡Ooops!')
        } */
        if (fileRejections.length === 1) {
            toastr.info('El archivo no cumplen con el tipo de archivo o el peso máximo.', '¡Ooops!')
        }
        if (fileRejections.length > 1) {
            toastr.info('Algunos archivos no cumplen con el tipo de archivo o el peso máximo.', '¡Ooops!')
        }
        /* if (fileRejections.length > acceptFilesValidated.length) {
            toastr.info(`Se exedió el máximo de archivos por subir (Máximo: ${idComplementaryEvidence === '' ? 10 : 10 - savedFiles.length}).`, '¡Ooops!')
        } */
        setSizeFileTotal(sizeFileTotal + sizeFile)
        setFiles([...files, ...acceptFilesValidated])
        //console.log(acceptedFiles)
        //console.log(fileRejections)
    }

    const handleOnChange = e => {
        $('.border-danger').removeClass('border-danger')
        /* if (e.target.name === 'resourceType') {
            if (resourceType === '') {
                setResourceObj({
                    ...resourceObj,
                    [e.target.name]: e.target.value,
                })
            } else {
                const value = e.target.value
                Swal.fire({
                    title: '<p class="text-white mb-2">¿Deseas cambiar el tipo de archivo?</p>',
                    text: 'Si cambias el tipo de archivo se eliminará los archivos por subir.',
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'Cancelar',
                    showConfirmButton: true,
                    confirmButtonText: 'Si, cambiar',
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        setFiles([])
                        inputRef.current.value = ''
                        setResourceObj({
                            ...resourceObj,
                            resourceType: value,
                        })
                    }
                })
                $('.swal2-title').addClass('bg-header-swal')
                $('.swal2-actions').addClass('w-100 justify-content-around')
                $('.swal2-confirm').removeClass('swal2-styled')
                $('.swal2-confirm').addClass('btn btn-sm btn-blue-alert mt-2')
                $('.swal2-cancel').removeClass('swal2-styled')
                $('.swal2-cancel').addClass('btn btn-sm btn-gray-alert mt-2')
            }
        } else {
            setResourceObj({
                ...resourceObj,
                [e.target.name]: e.target.value,
            })
        } */
        setResourceObj({
            ...resourceObj,
            [e.target.name]: e.target.value,
        })
    }

    const handleOnSubmit = e => {
        e.preventDefault()
        //console.log(resourceObj)
        if (idComplementaryEvidence === '') {
            if (title === '') {
                $('#title').addClass('border-danger')
            }
            if (description === '') {
                $('#description').addClass('border-danger')
            }
            /* if (resourceType === '') {
                $('#resourceType').addClass('border-danger')
            } */
            if ([
                //resourceType,
                title,
                description,
            ].includes('')) {
                toastr.warning('Todos los campos son obligatorios.', '¡Ooops!')
                return
            }
            if (files.length === 0) {
                toastr.warning('Debes subir algún archivo.', '¡Ooops!')
                return
            }
            const getService = parseInt(service) === 1 ? '1-1-1' : parseInt(service) === 2 ? '1-1-2' : '1-2-0'
            const getIdService = getService.split('-')
            let newObj = {
                ...resourceObj,
                idComplementaryEvidence: idComplementaryEvidence === '' ? 0 : idComplementaryEvidence,
                //resourceType: parseInt(resourceType),
                idProduct: parseInt(getIdService[0]),
                idCategory: parseInt(getIdService[1]),
                idSubcategory: parseInt(getIdService[2]),
            }
            //console.log(newObj)
            $('#myModal').show()
            saveComplementaryEvidence(newObj, files).then(response => {
                if (response) {
                    if (response.code === 1) {
                        handleResetTable()
                        handleReset()
                        $('#myModal').hide()
                        toastr.success('Se creó correctamente la evidencia.', '¡Éxito!')
                    } else {
                        handleReset()
                        $('#myModal').hide()
                        toastr.error('No se pudo crear la evidencia.', '¡Ooops!')
                    }
                } else {
                    handleReset()
                    $('#myModal').hide()
                    toastr.error('No se pudo crear la evidencia.', '¡Ooops!')
                }
            })
        } else {
            let newObjEvidence = {
                idComplementaryEvidence,
                idUser,
                title,
                description,
            }
            let newObj = {
                idComplementaryEvidence,
                idComplementaryEvidenceResources: 0,
                idUser,
                //resourceType: parseInt(resourceType),
            }
            editComplementaryEvidence(newObjEvidence).then(response => {
                if (response) {
                    if (response.code === 1) {
                        addEvidenceFile(newObj, files).then(response => {
                            if (response) {
                                if (response.code === 1) {
                                    handleResetTable()
                                    handleReset()
                                    toastr.success('Se guardó correctamente la evidencia.', '¡Éxito!')
                                    $('#myModal').hide()
                                } else {
                                    toastr.error('No se pudieron subir todos los archivos.', '¡Ooops!')
                                    handleResetTable()
                                    handleReset()
                                    $('#myModal').hide()
                                }
                            } else {
                                toastr.error('No se pudieron subir todos los archivos.', '¡Ooops!')
                                handleResetTable()
                                handleReset()
                                $('#myModal').hide()
                            }
                        })
                    } else {
                        handleReset()
                        $('#myModal').hide()
                        toastr.error('No se pudo actualizar la evidencia.', '¡Ooops!')
                    }
                } else {
                    handleReset()
                    $('#myModal').hide()
                    toastr.error('No se pudo actualizar la evidencia.', '¡Ooops!')
                }
            })
        }
    }

    const handleReset = () => {
        setSavedFiles([])
        setFiles([])
        setResourceObj({
            ...resourceObj,
            idComplementaryEvidence: '',
            //resourceType: '',
            title: '',
            description: '',
        })
    }

    return (
        <div className='col-12'>
            <form
                className='row'
                onSubmit={handleOnSubmit}
            >
                <div className='col-12 mb-2'>
                    <h4 className='font-weight-bold'>{idComplementaryEvidence === '' ? 'Agregar' : 'Editar'} evidencia complementaria:</h4>
                </div>
                <div className='col-6'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='title'>Titulo:</label>
                            <input
                                type='text'
                                className='form-control'
                                id='title'
                                name='title'
                                value={title}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='description'>Descripción:</label>
                            <textarea
                                className='form-control textarea-unresize'
                                rows='5'
                                id='description'
                                name='description'
                                value={description}
                                onChange={handleOnChange}
                            ></textarea>
                        </div>
                    </div>
                    {/* <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='resourceType'>Tipo de evidencia:</label>
                            <select
                                className='form-control'
                                id='resourceType'
                                name='resourceType'
                                value={resourceType}
                                onChange={handleOnChange}
                                disabled={idComplementaryEvidence !== '' && true}
                            >
                                <option value=''>Seleccionar</option>
                                <option value='1'>Archivo</option>
                                <option value='2'>Audio</option>
                                <option value='3'>Video</option>
                            </select>
                        </div>
                    </div> */}
                </div>
                <div className='col-5 mx-auto d-flex flex-wrap align-content-center'>
                    <Dropzone
                        onDrop={onDrop}
                        multiple={true}
                        /* accept={parseInt(resourceType) === 1 ? archivos.filesType : parseInt(resourceType) === 2 ? audio.filesType : video.filesType} */
                        accept={archivos.filesType}
                        maxFiles={idComplementaryEvidence === '' ? 10 - files.length : 10 - (savedFiles.length + files.length)}
                        ref={inputRef}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: 'dropzone row bg-gray-guay rounded font-small-3 py-1 pointer' })}>
                                <input {...getInputProps()} />
                                <div className='col-10 mx-auto'>
                                    <div className='row'>
                                        <div className='col-12 mb-1 text-center'>
                                            <span className='font-medium-3 font-weight-bold'>Sube la evidencia</span>
                                        </div>
                                        <div className='col-12 mb-1 text-center'>
                                            <p className='m-0'>
                                                <span className='font-weight-bold'>Máximo de archivos: </span>
                                                {idComplementaryEvidence === '' ? `${files.length} de 10` : `${savedFiles.length + files.length} de 10`}.
                                                <br />
                                                <span className='font-weight-bold'>Tipo de archivos: </span>
                                                {archivos.filesType}.
                                                <br />
                                                <span className='font-weight-bold'>Peso: </span>
                                                {`${(sizeFileTotal / 1048576).toFixed(2)}MB de ${archivos.maxFiles} máximo`}.
                                            </p>
                                        </div>
                                        <div className='col-4 mx-auto text-center'>
                                            <i className='ft-monitor icon-big-size text-base-guay' />
                                            <p className='m-0 font-weight-bold'>Arrastrar y soltar aquí</p>
                                        </div>
                                        <div className='col-1 mx-auto text-center d-flex flex-wrap align-content-center'>
                                            <p className='m-0 font-weight-bold'>ó</p>
                                        </div>
                                        <div className='col-4 mx-auto text-center'>
                                            <i className='ft-upload-cloud icon-big-size text-base-guay' />
                                            <p className='m-0 font-weight-bold'>Selecciona el Archivo</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    {/* {resourceType === '' ? (
                        <div
                            className='row bg-gray-guay rounded font-small-3 py-1 pointer'
                            onClick={() => toastr.info('Debes seleccionar primero un tipo de evidencia.', '¡Ooops!')}
                        >
                            <div className='col-12 mb-1 text-center'>
                                <span className='font-medium-3 font-weight-bold text-base'>Sube la evidencia</span>
                            </div>
                            <div className='col-4 mx-auto text-center'>
                                <i className='ft-monitor icon-big-size text-base' />
                                <p className='m-0 font-weight-bold'>Arrastrar y soltar aquí</p>
                            </div>
                            <div className='col-1 mx-auto text-center d-flex flex-wrap align-content-center'>
                                <p className='m-0 font-weight-bold'>ó</p>
                            </div>
                            <div className='col-4 mx-auto text-center'>
                                <i className='ft-upload-cloud icon-big-size text-base' />
                                <p className='m-0 font-weight-bold'>Selecciona el Archivo</p>
                            </div>
                        </div>
                    ) : (
                        <Dropzone
                            onDrop={onDrop}
                            multiple={true}
                            accept={parseInt(resourceType) === 1 ? archivos.filesType : parseInt(resourceType) === 2 ? audio.filesType : video.filesType}
                            maxFiles={idComplementaryEvidence === '' ? 10 : 10 - savedFiles.length}
                            ref={inputRef}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: 'dropzone row bg-gray-guay rounded font-small-3 py-1 pointer' })}>
                                    <input {...getInputProps()} />
                                    <div className='col-10 mx-auto'>
                                        <div className='row'>
                                            <div className='col-12 mb-1 text-center'>
                                                <span className='font-medium-3 font-weight-bold'>Sube la evidencia</span>
                                            </div>
                                            <div className='col-12 mb-1 text-center'>
                                                <p className='m-0'>
                                                    <span className='font-weight-bold'>Máximo de archivos: </span>
                                                    {idComplementaryEvidence === '' ? 10 : 10 - savedFiles.length}.
                                                    <br />
                                                    <span className='font-weight-bold'>Tipo de archivos: </span>
                                                    {parseInt(resourceType) === 1 ? archivos.filesType : parseInt(resourceType) === 2 ? audio.filesType : video.filesType}.
                                                    <br />
                                                    <span className='font-weight-bold'>Peso máximo: </span>
                                                    {parseInt(resourceType) === 1 ? archivos.maxFiles : parseInt(resourceType) === 2 ? audio.maxFiles : video.maxFiles}.
                                                </p>
                                            </div>
                                            <div className='col-4 mx-auto text-center'>
                                                <i className='ft-monitor icon-big-size text-base-guay' />
                                                <p className='m-0 font-weight-bold'>Arrastrar y soltar aquí</p>
                                            </div>
                                            <div className='col-1 mx-auto text-center d-flex flex-wrap align-content-center'>
                                                <p className='m-0 font-weight-bold'>ó</p>
                                            </div>
                                            <div className='col-4 mx-auto text-center'>
                                                <i className='ft-upload-cloud icon-big-size text-base-guay' />
                                                <p className='m-0 font-weight-bold'>Selecciona el Archivo</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    )} */}
                    {files.length > 0 && (
                        <div className='col-12 mt-2 font-small-3'>
                            <div className='row'>
                                <div className='col-12 mb-1'>
                                    <span className='font-weight-bold'>Archivos por subir:</span>
                                </div>
                            </div>
                            {files.map((data, index) => (
                                <div className='row mb-1'>
                                    <div className='col-9 rounded bg-gray-guay py-1 overflow-hidden font-medium-3'>
                                        <i className='bi bi-paperclip text-base-guay font-medium-5' />
                                        <span
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title={data.name}
                                            style={{ textOverflow: 'ellipsis' }}
                                        >{data.name}</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-around flex-wrap align-content-center font-medium-5'>
                                        <i
                                            className='bi bi-trash text-blue'
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title='Eliminar'
                                            onClick={() => {
                                                Swal.fire({
                                                    title: '<p class="text-white mb-2">¿Deseas eliminar el archivo?</p>',
                                                    text: 'Si eliminas este archivo no podrás recuperarlo.',
                                                    showCloseButton: true,
                                                    showCancelButton: true,
                                                    focusCancel: true,
                                                    cancelButtonText: 'Cancelar',
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Si, eliminar',
                                                    reverseButtons: true,
                                                    dangerMode: true
                                                }).then((willDelete) => {
                                                    if (willDelete.isConfirmed) {
                                                        let newFiles = []
                                                        files.forEach((data, i) => {
                                                            if (index !== i) {
                                                                newFiles.push(data)
                                                            }
                                                        })
                                                        setFiles(newFiles)
                                                    }
                                                })
                                                $('.swal2-title').addClass('bg-header-swal')
                                                $('.swal2-actions').addClass('w-100 justify-content-around')
                                                $('.swal2-confirm').removeClass('swal2-styled')
                                                $('.swal2-confirm').addClass('btn btn-sm btn-blue-alert mt-2')
                                                $('.swal2-cancel').removeClass('swal2-styled')
                                                $('.swal2-cancel').addClass('btn btn-sm btn-gray-alert mt-2')
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {savedFiles.length > 0 && (
                        <div className='col-12 mt-2 font-small-3'>
                            <div className='row'>
                                <div className='col-12 mb-1'>
                                    <span className='font-weight-bold'>Archivos guardados:</span>
                                </div>
                            </div>
                            {savedFiles.map(data => (
                                <div className='row mb-1'>
                                    <div className='col-9 rounded bg-gray-guay py-1 overflow-hidden font-medium-3'>
                                        <i className='bi bi-paperclip text-base-guay font-medium-5' />
                                        <span
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title={data.title}
                                            style={{ textOverflow: 'ellipsis' }}
                                        >{data.title}</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-around flex-wrap align-content-center font-medium-5'>
                                        <i
                                            className='bi bi-trash text-blue pointer'
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title='Eliminar'
                                            onClick={() => {
                                                Swal.fire({
                                                    title: '<p class="text-white mb-2">¿Deseas eliminar el archivo?</p>',
                                                    text: 'Si eliminas este archivo no podrás recuperarlo.',
                                                    showCloseButton: true,
                                                    showCancelButton: true,
                                                    focusCancel: true,
                                                    cancelButtonText: 'Cancelar',
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Si, eliminar',
                                                    reverseButtons: true,
                                                    dangerMode: true
                                                }).then((willDelete) => {
                                                    if (willDelete.isConfirmed) {
                                                        const getSaveFiles = savedFiles.filter(dataFilter => dataFilter.idComplementaryEvidenceResources !== data.idComplementaryEvidenceResources)
                                                        let obj = {
                                                            idUser,
                                                            idComplementaryEvidence: idComplementaryEvidence,
                                                            idComplementaryEvidenceResources: data.idComplementaryEvidenceResources,
                                                        }
                                                        deleteSpecificEvidence(obj).then(response => {
                                                            if (response) {
                                                                if (response.code === 1) {
                                                                    setSavedFiles(getSaveFiles)
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                                $('.swal2-title').addClass('bg-header-swal')
                                                $('.swal2-actions').addClass('w-100 justify-content-around')
                                                $('.swal2-confirm').removeClass('swal2-styled')
                                                $('.swal2-confirm').addClass('btn btn-sm btn-blue-alert mt-2')
                                                $('.swal2-cancel').removeClass('swal2-styled')
                                                $('.swal2-cancel').addClass('btn btn-sm btn-gray-alert mt-2')
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='col-12 my-2 d-flex justify-content-end'>
                    {idComplementaryEvidence !== '' && (
                        <button
                            type='button'
                            className='btn btn-sm btn-blue-3 mr-3'
                            onClick={() => {
                                handleReset()
                            }}
                        >Cancelar</button>
                    )}
                    <button
                        type='submit'
                        className='btn btn-sm btn-blue-3'
                    >Guardar</button>
                </div>
            </form>
            <div
                className='modal'
                id='myModal'
                data-backdrop='false'
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div
                        className='modal-content'
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                        <div className='modal-body'>
                            <Loader />
                            <div className='col-12 text-center mt-3'>
                                <h1>Subiendo recurso</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComEviForm