import React, {
    Fragment,
    useEffect,
    useState
} from 'react';
import {
    getProductsguayki,
    getResourcesguayki,
    getSpecificResourceguayki
} from '../Models/Repository_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import parse from "html-react-parser";
import 'animate.css';
import GuaykipediaMenu from './GuaykipediaMenu';

const GuaykipediaCtrlPublic = () => {

    const [guaypediaState, setGuaypediaState] = useState({
        products: [],
        activeProduct: {
            title: "Producto",
            idCategory: 0
        },
        activeSection: {
            title: "Sección",
            idSectionguaykipedia: 0
        },
        listResources: [],
        resourceSpecific: "",
        menu: [],
    });

    useEffect(() => {
        getProductsguayki().then(response => {
            switch (response?.code) {
                case 1:
                    setGuaypediaState(state => ({
                        ...state,
                        products: response.products,
                        activeProduct: {
                            title: response.products[0].title,
                            idCategory: response.products[0].idCategory
                        },
                        activeSection: {
                            title: response.products[0].subCategories[0].title,
                            idSectionguaykipedia: response.products[0].subCategories[0].idSectionguaykipedia
                        },
                        menu: response.products,
                    }));
                    getResourcesguayki(response.products[0].subCategories[0].idSectionguaykipedia).then(response => {
                        switch (response?.code) {
                            case 1:
                                setGuaypediaState(state => ({
                                    ...state,
                                    listResources: response.listResources,
                                }));
                                break;
                            case 99:
                                toastr.error("Ocurrió un error al obtener toda la información de los recursos (abstracts)", "Error 99");
                                break;
                            case 1001:
                                toastr.error("No existen recursos disponibles (abstracts)", "Error 1001");
                                break;
                            default:
                                toastr.error("Ocurrió un error con el servidor.");
                                break;
                        }
                    })
                    break;
                case 99:
                    toastr.error("Ocurrió un error al obtener toda la información de los recursos (menú)", "Error 99");
                    break;
                case 1001:
                    toastr.error("No existen recursos disponibles (menú)", "Error 1001");
                    break;
                default:
                    toastr.error("Ocurrió un error con el servidor.");
                    break;
            }
        });
    }, []);

    const {
        products,
        activeProduct,
        activeSection,
        listResources,
        resourceSpecific,
        menu
    } = guaypediaState;

    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.section && domNode.attribs.custom) {
                return <button className={domNode.attribs.classname} onClick={() => handleClickVerMas(domNode.attribs.section, `idResource-${domNode.attribs.custom}`)} >Conoce más</button>;
            }
        }
    };

    const handleClickGuaykipedia = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const handleClickSection = (idCategory, idSectionguaykipedia) => {
        getResourcesguayki(idSectionguaykipedia).then(response => {
            switch (response?.code) {
                case 1:
                    setGuaypediaState(state => ({
                        ...state,
                        activeProduct: {
                            title: products.find(product => product.idCategory === idCategory).title,
                            idCategory: products.find(product => product.idCategory === idCategory).idCategory
                        },
                        listResources: response.listResources,
                        activeSection: {
                            title: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === idSectionguaykipedia).title,
                            idSectionguaykipedia: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === idSectionguaykipedia).idSectionguaykipedia
                        },
                        resourceSpecific: "",
                    }));
                    handleClickGuaykipedia();
                    break;
                case 99:
                    toastr.error("Error al obtener los recursos (abstracts) disponibles", "Error 99");
                    break;
                case 1001:
                    toastr.error("No existen recursos (abstracts) disponibles", "Error 1001");
                    break;
                default:
                    toastr.error("Ocurrió un error desconocido.");
                    break;
            }
        })
    }

    const handleClickVerMas = (id, idResource, idCategory) => {
        getSpecificResourceguayki(id).then(response => {
            switch (response?.code) {
                case 1:
                    const cambiarImagen = response.resource.replace(/logo_guaykipedia/g, "logo_guaykipedia_3");
                    if (idCategory) {
                        setGuaypediaState(state => ({
                            ...state,
                            resourceSpecific: cambiarImagen,
                            activeSection: {
                                title: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === parseInt(id)).title,
                                idSectionguaykipedia: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === parseInt(id)).idSectionguaykipedia
                            },
                        }));
                    } else {
                        setGuaypediaState(state => ({
                            ...state,
                            resourceSpecific: cambiarImagen,
                        }));
                    }
                    setTimeout(() => {
                        window.scrollTo({
                            top: document.getElementById(idResource).offsetTop,
                            behavior: "smooth"
                        });
                    }, 500);
                    break;
                case 99:
                    toastr.error("Error al obtener los recursos (especifico) disponibles", "Error 99")
                    break;
                case 1001:
                    toastr.error("No existen recursos (especifico) disponibles", "Error 1001")
                    break;
                default:
                    break;
            }
        });
    }
    return (
        <div className="app-content new-guay-plataform">
            <div className="content-overlay">
                <div className="content-wrapper">
                    <div className="content-header"></div>
                    <div className="content-body">
                        <div className='col-12 bg-white py-1'>
                            <div className='col-10 mx-auto'>
                                <GuaykipediaMenu
                                    menu={menu}
                                    activeProduct={activeProduct}
                                    handleClickSection={handleClickSection}
                                    handleClickVerMas={handleClickVerMas}
                                />
                            </div>
                        </div>
                        <div className='col-12  bg-white'>
                            <div className='col-11 mx-auto'>
                                <div className='row'>
                                    {resourceSpecific !== "" ?
                                        parse(resourceSpecific)
                                        :
                                        listResources.map(resource => {
                                            if (resource.firstTemplate !== undefined) {
                                                return <Fragment key={resource.idResourcesguaykipedia}>{parse(resource.firstTemplate, options)}</Fragment>
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <span
                            className="scroll-to-target scroll-to-top pointer"
                            onClick={handleClickGuaykipedia}
                        ><i className="la la-angle-up"></i></span>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default GuaykipediaCtrlPublic;