import React, { useState } from 'react';
import { getJwt } from '../../../../lib/auth';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { setCancelCollabsToCanalization } from '../../Models/TrackingModel';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

/*** 3 publico // 2 privado // 1 guay ***/
export const ModalCanalizedTable = ({ setModalData, modalData, setCollabsLists, step_1 }) => {

    const enterpriseId = getJwt('enterprise');

    const { typeModal, canalizedCollabs, catalogAreas, catalogBranches, catalogCategories } = modalData;

    const [collabsToUncanalize, setCollabsToUncanalize] = useState([]);

    const handleCollabUncanalize = function () {
        let newCollabs = [];
        if (collabsToUncanalize.length !== 0) {
            setCancelCollabsToCanalization(enterpriseId, JSON.stringify(collabsToUncanalize)).then((response) => {
                switch (response?.code) {
                    case 1:
                        switch (typeModal) {
                            case 1: //guay
                                newCollabs = canalizedCollabs.filter(item => collabsToUncanalize.indexOf(item) === -1);
                                setCollabsLists(prevState => {
                                    return {
                                        ...prevState,
                                        collabs: [...prevState.collabs, ...collabsToUncanalize],
                                        collabguayList: [...newCollabs]
                                    }
                                });
                                break;
                            case 2: //privado
                                newCollabs = canalizedCollabs.filter(item => collabsToUncanalize.indexOf(item) === -1);
                                setCollabsLists(prevState => {
                                    return {
                                        ...prevState,
                                        collabs: [...prevState.collabs, ...collabsToUncanalize],
                                        collabPrivateList: [...newCollabs]
                                    }
                                });
                                break;
                            case 3: //publico
                                newCollabs = canalizedCollabs.filter(item => collabsToUncanalize.indexOf(item) === -1);
                                setCollabsLists(prevState => {
                                    return {
                                        ...prevState,
                                        collabs: [...prevState.collabs, ...collabsToUncanalize],
                                        collabPublicList: [...newCollabs]
                                    }
                                });
                                break;
                            case 4: //teleasistencia
                                newCollabs = canalizedCollabs.filter(item => collabsToUncanalize.indexOf(item) === -1);
                                setCollabsLists(prevState => {
                                    return {
                                        ...prevState,
                                        collabs: [...prevState.collabs, ...collabsToUncanalize],
                                        collabMDOList: [...newCollabs]
                                    }
                                });
                                break;

                            default:
                                break;
                        }
                        setModalData(prevState => {
                            return {
                                prevState,
                                typeModal: 0,
                                canalizedCollabs: [],
                                showModal: false
                            }
                        });
                        toastr.success("Se actualizo la lista de colaboradores canalizados");
                        break;
                    case 99:
                        toastr.error("Los colaboradores seleccionados no se pudieron modificar");
                        break;
                    case 404:
                        toastr.error("No se encontraron los colaboradores que se requieren actualizar");
                        break;
                    case 1001:
                        toastr.error("La empresa no existe o se encuentra desactivada");
                        break;
                    default:
                        toastr.error("Error desconocido");
                        // console.error(response);
                        break;
                }
            });
        }
    }


    /**
     * Table styles
     */

    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
    };

    const branchFormater = function (cell) {
        const branch = catalogBranches.find(branch => branch.branchEntId === cell);
        let branchName = 'Sin centro';
        if (branch !== undefined) {
            branchName = branch.description;
        }
        return `${branchName}`;
    }

    const areaFormater = function (cell) {
        const area = catalogAreas.find(area => area.areaEntId === cell);
        let areaName = 'Sin área';
        if (area !== undefined) {
            areaName = area.description;
        }
        return `${areaName}`;
    }

    const categoriesFormater = function (cell) {
        const category = catalogCategories.find(boss => boss.categoryEntId === cell);
        let categoryName = '-';
        if (category !== undefined) {
            categoryName = category.description;
        }
        return `${categoryName}`;
    }

    /**
     * Table functions
     */
    const selectRowProp = {
        mode: 'checkbox',
        hideSelectColumn: false,
        clickToSelect: true,
        onSelect: (row, isSelected) => handleSelectionCollab(row, isSelected),
        onSelectAll: (selectedAll, rows) => handleSelectionAll(selectedAll, rows)
    };

    const handleSelectionCollab = function (row, isSelected) {
        if (isSelected === true) { // se selecciono
            const arrayCollabs = [...collabsToUncanalize, row];
            setCollabsToUncanalize(arrayCollabs);
        } else { // se deselecciono
            const arrayCollabs = collabsToUncanalize.filter(collab => collab.idCollab !== row.idCollab);
            setCollabsToUncanalize(arrayCollabs);
        }
    }

    const handleSelectionAll = function (selectedAll, rows) {
        if (selectedAll === true) {
            setCollabsToUncanalize(rows);
        } else {
            setCollabsToUncanalize([]);
        }
    }

    return (
        <div
            className="modal"
            id="collabModalguay"
            style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'Block' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                >
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                setModalData(prevState => {
                                    return {
                                        ...prevState,
                                        typeModal: 0,
                                        canalizedCollabs: [],
                                        showModal: false
                                    }
                                })
                            }}>
                            &times;
                        </button>
                    </div>

                    <div className="modal-body modal-div-style-1 container-style-1" style={{ backgroundColor: '#f4f4f6' }}>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <h2 className="font-weight-bold text-blue-gray text-center">Colaboradores canalizados a asistencia {typeModal === 3 ? 'Pública' : typeModal === 2 ? 'Privada' : typeModal === 1 ? <img src="/img/guay-logo.png" alt="guay" /> : ''}</h2>
                            </div>
                            <div className="col-12 mb-1">
                                <h3 className="font-weight-bold text-pink-mine text-center">Total: {canalizedCollabs.length}</h3>
                            </div>
                            <div className="col-12 mb-1">
                                <BootstrapTable
                                    selectRow={selectRowProp}
                                    data={canalizedCollabs}
                                    trClassName='tr-data-table table-checkbox-2'
                                    tableHeaderClass='table-checkbox-2'
                                    tableStyle={{ border: '0px', padding: '0px', fontSize: '1rem', color: '#798a94', backgroundColor: 'white' }}
                                    containerStyle={{ border: '#9cb0d1 2.5px solid' }}
                                    options={options}
                                >
                                    <TableHeaderColumn isKey dataField="idCollab" dataAlign="center" hidden>ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField="nameCollab" width="160px" dataAlign="center" tdStyle={{ fontSize: '1rem', padding: '5px' }} dataSort>Colaborador</TableHeaderColumn>
                                    <TableHeaderColumn dataField="areaEntId" width="100px" dataAlign="center" tdStyle={{ fontSize: '1rem', padding: '5px' }} dataFormat={areaFormater} dataSort>Área</TableHeaderColumn>
                                    <TableHeaderColumn dataField="categoryEntId" width="150px" dataAlign="center" tdStyle={{ fontSize: '1rem', padding: '5px' }} dataFormat={categoriesFormater} dataSort>Cargo</TableHeaderColumn>
                                    <TableHeaderColumn dataField="idBranch" width="200px" dataAlign="center" tdStyle={{ fontSize: '1rem', padding: '5px' }} dataFormat={branchFormater} dataSort>Centro de trabajo</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-2">
                                {
                                    !step_1 && (
                                        <button
                                            className="btn btn-sm btn-blue-3 font-medium-3 px-4"
                                            onClick={handleCollabUncanalize}
                                        >
                                            No canalizar
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
