import React, { useState, useEffect } from 'react';
import Header from './headerAdministrador';
import Footer from '../footerApp';
import BackgroundImg from './backgroundImg';
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import { getuserData } from "./Model";
import $ from "jquery";
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const AdminPanel = () => {

    //const [data, setData] = useState([]);
    const [enterprises, updateEnterprises] = useState([]);

    useEffect(() => {
        getuserData(getJwt("jtw")).then((result) => {
            //setData(result);
            updateEnterprises(result.enterprise);
        });
        $('#tourModal').show();
    }, []);

    const steps = [
        {
            selector: '[data-tut="step-1"]',
            content: () => (
                <div>
                    <p style={{ marginTop: '20px', fontSize: '20px' }}>Conoce el menú:</p>
                    <p>Visita nuetra tienda</p>
                </div>
            ),
            style: {
                backgroundColor: '#9cb537',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center'
            }
        },
        {
            selector: '[data-tut="step-2"]',
            content: () => (
                <div>
                    <p style={{ marginTop: '20px', fontSize: '20px' }}>Conoce el menú:</p>
                    <p>Administración de Perfil</p>
                </div>
            ),
            style: {
                backgroundColor: '#af2f72',
                borderRadius: '10px',
                color: '#fff',
                textAlign: 'center'
            }
        }
        // ...
    ];

    const [isTourOpen, setIsTourOpen] = useState(false);

    const startIntro = () => {
        $("#tourModal").hide();
        setIsTourOpen(true);
    }

    const noIntro = () => {
        $("#tourModal").hide();
        enableBodyScroll(true);
    }

    const endIntro = () => {
        enableBodyScroll(true);
    }

    const goAsignacionRoles = () => {
        sessionStorage.setItem('enterpriseId', enterprises[0].enterpriseId);
        sessionStorage.setItem('enterpriseName', enterprises[0].name);
        sessionStorage.setItem('userRolId', enterprises[0].rolEnterpriseId);
        window.location = "/inicio-empresas/administracion-usuarios";
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            <div className="row">
                                <Header empresa={true} />
                                <BackgroundImg />
                                <Tour
                                    steps={steps}
                                    isOpen={isTourOpen}
                                    onRequestClose={() => setIsTourOpen(false)}
                                    onAfterOpen={() => disableBodyScroll(true)}
                                    onBeforeClose={() => endIntro()}
                                />
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-11 mt-110 mx-auto">
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex flex-row-reverse" >
                                            <div className="d-inline-block w-20 ml-5 d-flex flex-wrap align-content-center">
                                                <button type="button" className="btn btn-administracionUsuarios btn-sm" >{enterprises.map(data => data.name)}</button>
                                            </div>
                                            <div className="d-inline-block mr-5 pointer" onClick={goAsignacionRoles} >
                                                <img className="icon-img-60" src="./../img/imagesAdminPanel/iconos_shopy_guay-44.png" alt="img-guay" />
                                                <span className="align-middle ml-1" style={{ borderBottom: '2px solid #af2f72' }}>Agregar usuarios</span>
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-11 mx-auto">
                                            <div className="row">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10" >
                                                    <div id="demo" class="carousel slide" data-ride="carousel">

                                                        <ul class="carousel-indicators">
                                                            <li data-target="#demo" data-slide-to="0" className="active guay carousel-indicators-10"></li>
                                                            <li data-target="#demo" data-slide-to="1" className="guay carousel-indicators-10"></li>
                                                            <li data-target="#demo" data-slide-to="2" className="guay carousel-indicators-10"></li>
                                                        </ul>

                                                        <div class="carousel-inner">
                                                            <div class="carousel-item active">
                                                                <a href="https://guay.digital/resources/template/sabias_que_guay.pdf" target="_blank" rel="noopener noreferrer"><img className="img-responsive" src="./../img/inicio/sabias_shopy.png" alt="Guay-digital" /></a>
                                                            </div>
                                                            <div class="carousel-item">
                                                                <a href="https://guay.digital/resources/template/sabias_que_guay.pdf" target="_blank" rel="noopener noreferrer"><img className="img-responsive" src="./../img/inicio/sabias_shopy.png" alt="Guay-digital" /></a>
                                                            </div>
                                                            <div class="carousel-item">
                                                                <a href="https://guay.digital/resources/template/sabias_que_guay.pdf" target="_blank" rel="noopener noreferrer"><img className="img-responsive" src="./../img/inicio/sabias_shopy.png" alt="Guay-digital" /></a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-sx-6 col-sm-6 col-md-6 col-lg-2">
                                                    <div className="row d-flex justify-content-center align-content-center">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-5">
                                                            <center><i className="ft ft-chevron-up"></i></center>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <img className="img-responsive" src="./../img/imagesAdminPanel/iconos_shopy_guay-37.png" alt="Guay-digital" />
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <img className="img-responsive" src="./../img/imagesAdminPanel/iconos_shopy_guay-38.png" alt="Guay-digital" />
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <center><i className="ft ft-chevron-down"></i></center>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                        {/*MODAL*/}
                        {/*<!-- The Modal -->*/}
                        <div className="modal" id="tourModal" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content" style={{ border: 'none', background: 'transparent' }}>

                                    {/*<!-- Modal body -->*/}
                                    <div className="modal-body" style={{ height: '600px' }}>
                                        <div className="col-sx-12 col-sm-12 col-md-10 col-lg-7 mx-auto">
                                            <img className="img-responsive img-dummy-1" src="./../img/imagesAdminPanel/iconos_shopy_guay-39.png" alt="guay-digital" />
                                            <p className="font-medium-1 text-center div-style-1">¡Bienvenido a guay!</p>
                                            <p className="font-small-3 text-center div-style-2">Para continuar da de alta una organización y configura sus Colaboradores y Centros de trabajo</p>
                                            <div className="font-small-3 text-center div-style-3">
                                                <span class="float-left btn-text-1" onClick={noIntro} >Omitir este paso</span>
                                                <span class="float-right btn-text-2" onClick={startIntro} >Siguiente</span>
                                            </div>
                                            <img className="img-responsive img-dummy-2" src="./../img/element4.png" alt="guay-digital" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminPanel;