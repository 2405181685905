/*
 File Name: Collaborators_model.js
 Description: Manage Collaborators API Requests
 guay dev's team
*/
import axios from "axios";

/**
 * Get the numbers of collaborators
 * @param {number} enterpriseId 
 * @returns {JSON} promise
 */
export const getUploadedCollaborators = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "collaborator/getUploadedCollaborators?enterpriseId=" + enterpriseId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get the numbers of collaborators
 * @param {number} enterpriseId 
 * @returns {JSON} promise
 */
export const getTotalNumberCollaborators = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "collaborator/getNumCollab?enterpriseId=" + enterpriseId,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Set a new collaborator or update collaborator data
 * @param {*} object 
 * @returns {JSON} promise
 */
export const setUpdateCollaborators = async (object) => {
    try {
        let result = await axios({
            url: `${global.base_url}collaborator/setUpdateCollaborator`,
            method: 'POST',
            data: object,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Remove a specific collaborator from a specific enterprise 
 * @param {int} enterpriseId 
 * @param {int} collaboratorId 
 * @returns JSON response
 */
export const deleteCollaborators = async (enterpriseId, collaboratorId) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{enterpriseId: ${enterpriseId}, collaboratorId: ${collaboratorId}}`);
    try {
        let result = await axios({
            url: global.base_url + "collaborator/delCollaborators",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get all enterprises and areas data that are not unassigned to a collaborator from an enterprise
 * @param {int} enterpriseId 
 * @returns 
 */
export const setFinishUpload = async (obj) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', JSON.stringify(obj));
    try {
        let result = await axios({
            url: global.base_url + "collaborator/setFinishUpload",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get last modification date of collabs
 * @param {int} enterpriseId 
 * @returns 
 */
export const getLastUpdateDate = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + `collaborator/getlastCollaboratorsUpload?enterpriseId=${enterpriseId}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Delete collaborators in a concatenated list of collaborators id
 * @param {int} enterpriseId 
 * @param {int} option - Set the collaborators status (0 - delete, 1 - inactive, 2 - active, 3 - undelete)
 * @param {string} collaboratorIds - Concatenated list of collaborators id (e.g. "1,2,3,4")
 * @returns 
 */
export const delListCollaborators = async (enterpriseId, option, collaboratorIds) => {
    try {
        let data = {
            enterpriseId: enterpriseId,
            option: option,
            collaboratorIds: collaboratorIds
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "collaborator/delListCollaborators",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Funcion para subir el archivo
 * @param {file xlsx} file 
 */
 export const subirArchivo = async (file, enterpriseId, flagUpdate, flagAdd) => {
    const [archivo] = file;
    const data = new FormData();
    data.append('request', `{"enterpriseId":"${enterpriseId}", "flagUpdate":${flagUpdate}, "flagAdd":${flagAdd}}}`);
    data.append("file", archivo, archivo.path);
    try {
        let result = await axios({
            url: global.base_url + "collaborator/uploadListCollaborator",
            method: 'POST',
            data,  
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {int} enterpriseId 
 * @param {int} flagUpdate 
 * @returns 
 */
export const updateListCollaborator = async (enterpriseId, flagUpdate) => {
    try {
        let data = {
            enterpriseId: enterpriseId,
            flagUpdate: flagUpdate
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "collaborator/updateListCollaborator",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * 
 * @param {int} enterpriseId 
 * @param {int} flagUpdate 
 * @returns 
 */
 export const cancelUpdateListCollaborator = async (enterpriseId, flagUpdate) => {
    try {
        let data = {
            enterpriseId: enterpriseId,
            flagUpdate: flagUpdate
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "collaborator/cancelUpdateListCollaborator",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} enterpriseId 
 * @param {*} text 
 * @returns 
 */
export const getCollabByText = async (enterpriseId, text) => {
    try {
        let result = await axios({
            url: global.base_url + "collaborator/getCollabByText?enterpriseId=" + enterpriseId + "&text=" + text + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {int} idCollab 
 * @param {int} idEnterprise 
 * @returns 
 */
export const getsurveyLinksKiosco = async (idCollab, idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "collaborator/getsurveyLinksKiosco?idCollab=" + idCollab + "&idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Delete or replace boss from a specific enterprise 
 * @param {object} bossList  
 * @returns JSON response
 */
export const deleteReplaceBoss = async (dataCollabNewLiders) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('bossList ', JSON.stringify(dataCollabNewLiders));
    try {
        let result = await axios({
            url: global.base_url + "collaborator/deleteReplaceBoss",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};