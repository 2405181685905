import React from 'react';
import Iframe from 'react-iframe';

export const Docs = () => {
    return (
        <div className="container mt-5">
            <h1>Docs Prompts</h1>
            <hr />
            <Iframe url="https://analytics.guay.digital/MicroStrategy/servlet/mstrWeb?Server=35.231.31.201&Project=Pruebas+Moi&Port=0&evt=2048001&src=mstrWeb.2048001&documentID=68F9C6E44A5B83F2EB45B89560BB9334&uid=administrator2&pwd=12345678&elementsPromptAnswers=A076B2B94FBD505195E56D8254A999F1;A076B2B94FBD505195E56D8254A999F1:21;A076B2B94FBD505195E56D8254A999F1:22"
                width="100%"
                height="500px"
                id="myId"
                className="w-100"
                display="initial"
                position="relative" overflow="hidden" />
        </div>
    );
}
