import React, { useState } from 'react';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// API calls
import { valAssistanceConsent } from '../../Models/AssistanceModel';

const StatisticalFilterModal = ({
    gender,
    idCollab,
    idEnterprise,
    idGroup,
    setPregnantMess,
    setShowSurvey,
    isPregnant,
    setPregnant,
    idPeriod
}) => {
    // Global variables
    const [showButton, setShowButton] = useState(0);
    /* const [isPregnant, setPregnant] = useState(0); */
    const [consent, setConsent] = useState(0);
    /**
     * 
     * @param {event} e 
     */
    const handleConsent = e => {
        /* e.preventDefault(); */
        let value = e.target.value;
        if (value === "1") {
            setShowButton(true);
            setConsent(1);
        } else {
            $('#noConcent').show();
            setShowButton(false);
            setConsent(0);
        }
    }
    /**
     * 
     * @param {event} e 
     */
    const startSurvey = e => {
        e.preventDefault();
        // validates if the user gives her consent and if she is pregnant
        if (consent === 1 && isPregnant === 1) {
            valAssistanceConsent(idCollab, idEnterprise, idGroup, idPeriod, consent, isPregnant).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            $('#wellcomeMessage').hide();
                            setPregnantMess(true);
                            setShowSurvey(false);
                            break;
                        default:
                            toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                            break;
                    }
                } else {
                    toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                }
            });
        } else {
            valAssistanceConsent(idCollab, idEnterprise, idGroup, idPeriod, consent, isPregnant).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            $('#wellcomeMessage').hide();
                            break;
                        default:
                            toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                            break;
                    }
                } else {
                    toastr.error("No se pudo iniciar el ejercicio. Intente nuevamente o contacte a su administrador.", "¡Ooops!");
                }
            });
        }
    }
    return (
        <div
            className="modal"
            id="wellcomeMessage"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body modal-div-bodered container-style-1 pb-2">
                        <div className="row">
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                <h1 className="font-weight-bold">Introducción Encuesta</h1>
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 mt-1">
                                <p className="text-justify">Agradecemos tu colaboración por tomarte el tiempo para responder los cuestionarios realizados hasta el momento, con base en los resultados hemos detectado una afección en tu salud. Con la finalidad de complementar la información de tu diagnóstico y principalmente, poder brindarte el protocolo más adecuado para ti en los próximos días, daremos inicio con el Programa de Asistencia basado en el método guay. Este proceso se proporciona a través de cuatro fases. El tiempo de ejecución depende de ti, de tu disciplina para responder y llevar a cabo las tareas de autogestión.</p>
                                <p className="text-justify">Los procesos que integran el servicio son:</p>
                                <p className="text-justify"><span className="font-weight-bold">Encuesta de filtro</span> donde profundizamos en tus posibles afecciones para saber si podemos tratarte con los protocolos de intervención primaria o requieres de una canalización con un especialista clínico. (Filtro clínico)</p>
                                <p className="text-justify"><span className="font-weight-bold">Proceso de seguimiento a tu salud</span> el objetivo de esta encuesta es conocer un poco más de tus hábitos diarios sobre tus emociones y comportamientos para brindarte el Protocolo de Intervención más adecuado a tu afección. (Health Tracking)</p>
                                <p className="text-justify"><span className="font-weight-bold">Proceso de autogestión</span> este se basa en las técnicas básicas de intervención así como cognitiva conductual de autoaplicación y gestión primaria. Está dirigido a todo usuario diagnosticado. (Protocolo de Intervención Primaria)</p>
                                <p className="text-justify"><span className="font-weight-bold">Proceso de evaluación de mejora</span>, en este proceso se identifica la evolución del padecimiento y el proceso de mejora. (Health Tracking)</p>
                                <p className="text-blue text-right font-weight-bold pointer" onClick={()=>window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Consentimiento_informado_102020.pdf")}>Consentimiento informado</p>
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                <div className="form-group form-check">
                                    <label className="form-check-label pointer font-medium-1">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value={1}
                                            name="consent"
                                            onChange={handleConsent} /> Sí acepto el <span className="text-blue font-weight-bold" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>Aviso de privacidad y términos y condiciones.</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                <div className="form-group form-check">
                                    <label className="form-check-label pointer font-medium-1">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value={0}
                                            name="consent"
                                            onChange={handleConsent} /> No acepto el Aviso de privacidad y términos y condiciones.
                                    </label>
                                </div>
                            </div>
                            {
                                gender === 2
                                    ?
                                    (
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                            <div className="form-group form-check">
                                                <label className="form-check-label pointer font-medium-1">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="gender"
                                                        onChange={isPregnant === 1 ? (() => setPregnant(0)) : (() => setPregnant(1))} /> ¿Te encuentras en estado de embarazo?
                                                </label>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (null)
                            }
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 py-1">
                                {
                                    showButton === true
                                        ?
                                        (
                                            <button
                                                className="btn btn-sm btn-blue-3 float-right font-medium-1"
                                                onClick={startSurvey}>Encuesta</button>
                                        )
                                        : null
                                }
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mt-1 mx-auto">
                                <img
                                    className="img-responsive"
                                    src="./../img/img-encuesta-filtro-estadistico.png"
                                    alt="guay-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticalFilterModal;
