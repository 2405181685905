import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { getJwt } from "../../../../lib/auth";
import { getUploadedCollaborators, /*cancelUpdateListCollaborator,*/ updateListCollaborator, subirArchivo } from '../../Models/Collaborators_model';
import { getBranches, getBossEnterprise } from '../../Models/Enterprise_model';
import { getEnterpriseArea } from '../../Models/Catalog_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Dropzone from 'react-dropzone';
import LoaderApp from '../../LoaderApp';
import DentalForm from './DentalForm';

const ModalUploadCollab = (
    {
        setLoading,
        setShowHistory,
        dataCollab,
        setNewCollab,
        getValue,
        listJobs,
        listBosses,
        setListBosses,
        listCategories,
        setDescriptions,
        currentNumCollabs,
        setCurrentNumCollabs,
        currentNumAreas,
        setCurrentNumAreas,
        currentNumBranches,
        setCurrentNumBranches,
        numCollabs,
        numAreas,
        numBranches,
        listAreas,
        setListAreas,
        listBranches,
        setListBranches,
        minDate,
        maxDate,
        genderCat,
        relationBenefitEnterprise,
        setRelationBenefitEnterprise,
    }
) => {

    const [newNumCollabs, setNewNumCollabs] = useState(0);
    const [newNumAreas, setNewNumAreas] = useState(0);
    const [newNumBranches, setNewNumBranches] = useState(0);
    const [loading_2, setLoading_2] = useState(false);

    const {
        namesCollab,
        lastName,
        secondLastName,
        email,
        cel,
        categoryEntId,
        jobEntId,
        areaEntId,
        bossEntId,
        branchEntId,
        levelCollabR1,
        levelCollabR2,
        type_ent_colabR1,
        type_ent_colabR2,
        rfc,
        dateOfAdmission,
        customB,
        customC,
        customD,
        customE,
        customF,
        customG,
    } = dataCollab;

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("numCollabs");
            sessionStorage.removeItem("numAreas");
            sessionStorage.removeItem("numBranches");
        }
    }, [])


    /* useEffect(() => {
        try {
            getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setCollaborators(response.listCollab);
                    }
                } else {
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                }
            });
            getBranches(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListBranches(response.branches);
                    }
                } else {
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                }
            });
            getEnterpriseArea(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListAreas(response.areas);
                    }
                } else {
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                }
            });
            getBossEnterprise(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        if (response) {
                            if (response.code === 1) {
                                setListBosses(response.listBoss);
                            }
                        } else {
                            toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                        }
                    }
                } else {
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                }
            });
        } catch (error) {
            toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
            console.log(error);
        }
    }, []); */
    /**
     * 
     */
    const getCurrentNumbers = () => {
        let array_1 = [];
        let array_2 = [];
        let array_3 = [];
        getUploadedCollaborators(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    for (let x in response.listCollab) {
                        if (response.listCollab[x].active !== 99 && response.listCollab[x].active !== 0) {
                            array_1.push(response.listCollab[x]);
                        }
                    }
                    setCurrentNumCollabs(array_1.length);
                }
            } else {
                toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
            }
        });
        getBranches(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setListBranches(response.branches);
                    for (let x in response.branches) {
                        if (response.branches[x].active !== 99 && response.branches[x].active !== 0) {
                            array_2.push(response.branches[x]);
                        }
                    }
                    setCurrentNumBranches(array_2.length);
                }
            } else {
                toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
            }
        });
        getEnterpriseArea(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setListAreas(response.areas);
                    for (let x in response.areas) {
                        if (response.areas[x].active !== 99 && response.areas[x].active !== 0) {
                            array_3.push(response.areas[x]);
                        }
                    }
                    setCurrentNumAreas(array_3.length);
                }
            } else {
                toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
            }
        });
        getBossEnterprise(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    if (response) {
                        if (response.code === 1) {
                            setListBosses(response.listBoss);
                        }
                    } else {
                        toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                    }
                }
            } else {
                toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
            }
        });
    }
    /**
     * 
     */
    const getFinalNumbers = () => {
        let array_1 = [];
        let array_2 = [];
        let array_3 = [];
        getUploadedCollaborators(getJwt("enterprise")).then((response) => {
            switch (response?.code) {
                case 1:
                    for (let x in response.listCollab) {
                        if (response.listCollab[x].active === 99) {
                            array_1.push(response.listCollab[x]);
                        }
                    }
                    setDescriptions(response.listCollab);
                    setNewNumCollabs(array_1.length);
                    break;

                case 1001:
                    window.location.reload();
                    break;

                default:
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                    break;
            }
        });
        getBranches(getJwt("enterprise")).then((response) => {
            switch (response?.code) {
                case 1:
                    setListBranches(response.branches);
                    for (let x in response.branches) {
                        if (response.branches[x].active === 99) {
                            array_2.push(response.branches[x]);
                        }
                    }
                    setNewNumBranches(array_2.length);
                    break;

                case 1001:
                    window.location.reload();
                    break;

                default:
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                    break;
            }
        });
        getEnterpriseArea(getJwt("enterprise")).then((response) => {
            switch (response?.code) {
                case 1:
                    setListAreas(response.areas);
                    for (let x in response.areas) {
                        if (response.areas[x].active === 99) {
                            array_3.push(response.areas[x]);
                        }
                    }
                    setNewNumAreas(array_3.length);
                    break;

                case 1001:
                    window.location.reload();
                    break;

                default:
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                    break;
            }
        });
        getBossEnterprise(getJwt("enterprise")).then((response) => {
            switch (response?.code) {
                case 1:
                    if (response) {
                        if (response.code === 1) {
                            setListBosses(response.listBoss);
                        }
                    } else {
                        toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                    }
                    break;

                case 1001:
                    window.location.reload();
                    break;

                default:
                    toastr.error('Ocurrió un problema al momento de obtener los datos.', '¡Ooops!');
                    break;
            }
        });
    }
    /**
     * 
     */
    const setAddCollaborators = () => {
        sessionStorage.setItem("numCollabs", numCollabs);
        sessionStorage.setItem("numAreas", numAreas);
        sessionStorage.setItem("numBranches", numBranches);
        updateListCollaborator(getJwt("enterprise"), 0).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        $('#uploadFile').hide();
                        getFinalNumbers();
                        $('#confirmUpload').show();
                        toastr.success('Archivo cargado correctamente.', '¡Genial!');
                        break;
                    case 99:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1001:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1002:
                        toastr.warning('Tienes correos duplicados. Por favor verifica tu plantilla.', '¡Ooops!');
                        break;
                    default:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                }
            } else {
                toastr.error('Error inesperado.', '¡Error!');
            }
        });
    }
    /**
     * 
     */
    const setRepalceCollaborators = () => {
        sessionStorage.setItem("numCollabs", numCollabs);
        sessionStorage.setItem("numAreas", numAreas);
        sessionStorage.setItem("numBranches", numBranches);
        updateListCollaborator(getJwt("enterprise"), 1).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        $('#uploadFile').hide();
                        getFinalNumbers();
                        $('#confirmReplace').show();
                        toastr.success('Archivo cargado correctamente.', '¡Genial!');
                        break;
                    case 99:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1001:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1002:
                        toastr.warning('Tienes correos duplicados. Por favor verifica tu plantilla.', '¡Ooops!');
                        break;
                    case 1003:
                        toastr.warning('Tienes teléfonos duplicados. Por favor verifica tu plantilla.', '¡Ooops!');
                        break;
                    default:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                }
            } else {
                toastr.error('Error inesperado.', '¡Error!');
            }
        });
    }
    /**
     * Function that handle if the file is correct
     * @param {File} acceptedFiles 
     * @param {File} fileRejections 
     */
    const onDrop = (acceptedFiles, fileRejections) => {
        setLoading_2(true);
        if (fileRejections.length > 0) {
            setLoading_2(false);
            toastr.error('El archivo que utilizaste no es compatible con la plataforma. Por favor descarga la plantilla.', '¡Ooops!');
        } else {
            subirArchivo(acceptedFiles, getJwt("enterprise"), 1, 0).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setLoading_2(false);
                            $('#uploadFile').show();
                            getCurrentNumbers();
                            toastr.success('Archivo cargado correctamente.', '¡Genial!');
                            break;
                        case 99:
                            setLoading_2(false);
                            toastr.error('Hubo un error inesperado al subir el archivo, descarga la plantilla e intenta nuevamente.', '¡Error!');
                            break;
                        case 404:
                            setLoading_2(false);
                            toastr.error('Hubo un error inesperado al subir el archivo, recarga la página y vuelve a intentar.', '¡Error!');
                            break;
                        case 1001:
                            setLoading_2(false);
                            toastr.warning('Archivo con errores, por favor checa tus datos y vuelve a intentarlo.', '¡Ojo!');
                            break;
                        case 1002:
                            setLoading_2(false);
                            toastr.warning('El archivo que utilizaste es de una versión pasada, ya no es compatible con la plataforma. Por favor descargué nuevamente la plantilla y corroboré la información', 'Ups, al parecer cambio la estructura del archivo');
                            break;
                        case 1006:
                            setLoading_2(false);
                            toastr.info(response.message, '¡Ooops!');
                            break;
                        default:
                            setLoading_2(false);
                            toastr.error('Error inesperado.', '¡Error!');
                            break;
                    }
                } else {
                    setLoading_2(false);
                    toastr.error('Error inesperado.', '¡Error!');
                }
            });
        }
    };
    const onDropAdd = (acceptedFiles, fileRejections) => {
        setLoading_2(true);
        if (fileRejections.length > 0) {
            setLoading_2(false);
            toastr.error('El archivo que utilizaste no es compatible con la plataforma. Por favor descarga la plantilla.', '¡Ooops!');
        } else {
            subirArchivo(acceptedFiles, getJwt("enterprise"), 1, 1).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setLoading_2(false);
                            $('#uploadFile2').show();
                            getCurrentNumbers();
                            toastr.success('Archivo cargado correctamente.', '¡Genial!');
                            break;
                        case 99:
                            setLoading_2(false);
                            toastr.error('Hubo un error inesperado al subir el archivo, descarga la plantilla e intenta nuevamente.', '¡Error!');
                            break;
                        case 404:
                            setLoading_2(false);
                            toastr.error('Hubo un error inesperado al subir el archivo, recarga la página y vuelve a intentar.', '¡Error!');
                            break;
                        case 1001:
                            setLoading_2(false);
                            toastr.warning('Archivo con errores, por favor checa tus datos y vuelve a intentarlo.', '¡Ojo!');
                            break;
                        case 1002:
                            setLoading_2(false);
                            toastr.warning('El archivo que utilizaste es de una versión pasada, ya no es compatible con la plataforma. Por favor descargué nuevamente la plantilla y corroboré la información', 'Ups, al parecer cambio la estructura del archivo');
                            break;
                        case 1006:
                            setLoading_2(false);
                            toastr.info(response.message, '¡Ooops!');
                            break;
                        default:
                            setLoading_2(false);
                            toastr.error('Error inesperado.', '¡Error!');
                            break;
                    }
                } else {
                    setLoading_2(false);
                    toastr.error('Error inesperado.', '¡Error!');
                }
            });
        }
    };

    /**
     * Inicializacion de metodos, parametros y validaciones
     */
    /* const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: '.xlsx',
        maxFiles: 1,
        onDrop
    }); */
    /**
     * 
     */
    const stepBack = () => {
        updateListCollaborator(getJwt("enterprise"), 2).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        getFinalNumbers();
                        $('#uploadFile').hide();
                        break;
                    case 99:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1001:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    default:
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                }
            } else {
                toastr.error('Error inesperado.', '¡Error!');
            }
        });
    }

    /*
    const cancelUpdate = () => {
        sessionStorage.removeItem("numCollabs");
        sessionStorage.removeItem("numAreas");
        sessionStorage.removeItem("numBranches");
        cancelUpdateListCollaborator(getJwt("enterprise"), 0).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        $('#confirmUpload').hide();
                        getFinalNumbers();
                        $('#uploadFile').show();
                        break;
                    case 99:
                        $('#confirmUpload').hide();
                        getFinalNumbers();
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1001:
                        $('#confirmUpload').hide();
                        getFinalNumbers();
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    default:
                        $('#confirmUpload').hide();
                        getFinalNumbers();
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                }
            } else {
                $('#confirmUpload').hide();
                getFinalNumbers();
                toastr.error('Error inesperado.', '¡Error!');
            }
        });
    }
    */

    /*
    const cancelReplace = () => {
        sessionStorage.removeItem("numCollabs");
        sessionStorage.removeItem("numAreas");
        sessionStorage.removeItem("numBranches");
        cancelUpdateListCollaborator(getJwt("enterprise"), 1).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        $('#confirmReplace').hide();
                        $('#uploadFile').show();
                        getFinalNumbers();
                        break;
                    case 99:
                        $('#confirmReplace').hide();
                        getFinalNumbers();
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    case 1001:
                        $('#confirmReplace').hide();
                        getFinalNumbers();
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                    default:
                        $('#confirmReplace').hide();
                        getFinalNumbers();
                        toastr.error('Error inesperado.', '¡Error!');
                        break;
                }
            } else {
                $('#confirmReplace').hide();
                getFinalNumbers();
                toastr.error('Error inesperado.', '¡Error!');
            }
        });
    }
    */
    
    const getFormateDate = (Bdate) => {
        let getObject = Bdate;
        let obj = getObject.split(" ");
        return (obj[0]);
    }

    if (loading_2 === true) {
        return (
            <LoaderApp />
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay rounded-top mb-5">
                <div className="row">
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                        <span className="font-medium-2">Actualiza tus colaboradores</span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap align-content-center font-medium-2 mt-1">
                        <span className="text-base">Colaboradores: <span className="text-base-guay font-weight-bold">{" " + numCollabs}</span></span>
                        <span className="ml-4 text-base">Áreas: <span className="text-base-guay font-weight-bold">{" " + numAreas}</span></span>
                        <span className="ml-4 text-base">Centros de trabajo: <span className="text-base-guay font-weight-bold">{" " + numBranches}</span></span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 text-center font-medium-2 mt-2">
                        <p className="font-medium-2">Descarga la plantilla de colaboradores, llena el archivo adecuadamente y cárgalo aquí.</p>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mt-2"></div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 text-center mb-1">
                        <div className='row'>
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-small-3 mt-1">
                                <p>Recuerda que al elegir:</p>
                                <p><span className="font-weight-bold">Reemplazar:</span> Eliminas los registros actuales y realizas una nueva carga.</p>
                                <p><span className="font-weight-bold">Agregar:</span> Al número de colaboradores actual se le agregarán los registros nuevos.</p>
                            </div>
                            <div className='col-sx-11 col-sm-11 col-md-5 col-lg-5 mx-auto contenedor-alta pointer'>
                                <Dropzone
                                    onDrop={onDrop}
                                    multiple={false}
                                    accept='.xlsx'
                                    maxFiles={1}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone row' })}>
                                            <input {...getInputProps()} />
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                        <p className="font-medium-2"><span className="font-weight-bold">Reemplazar plantilla</span></p>
                                                        <i className="ft-monitor icon-big-size text-base-guay"></i>
                                                        <p className="font-medium-2">Arrastrar y soltar aqu&iacute;</p>
                                                        <p className="text-center">&oacute;</p>
                                                        <i className="ft-upload-cloud icon-big-size text-base-guay"></i>
                                                        <p className="font-medium-2 text-break">Selecciona el Archivo</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                            <div className='col-sx-11 col-sm-11 col-md-5 col-lg-5 mx-auto contenedor-alta pointer'>
                                <Dropzone
                                    onDrop={onDropAdd}
                                    multiple={false}
                                    accept='.xlsx'
                                    maxFiles={1}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone row' })}>
                                            <input {...getInputProps()} />
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                        <p className="font-medium-2"><span className="font-weight-bold">Agregar plantilla</span></p>
                                                        <i className="ft-monitor icon-big-size text-base-guay"></i>
                                                        <p className="font-medium-2">Arrastrar y soltar aqu&iacute;</p>
                                                        <p className="text-center">&oacute;</p>
                                                        <i className="ft-upload-cloud icon-big-size text-base-guay"></i>
                                                        <p className="font-medium-2 text-break">Selecciona el Archivo</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 text-center mb-1">
                        <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto contenedor-alta pointer h-100 d-flex justify-content-center flex-wrap align-content-center">
                            <p className="font-medium-2">Hazlo de forma manual, uno por uno dentro de nuestra plataforma.</p>
                            <button
                                className="btn btn-pink btn-sm"
                                onClick={() => $('#dataCollab').show()}><i className="ft-user-plus mr-1" />Agregar colaborador</button>
                        </div>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 text-center mt-2 mb-2">
                        <a href={relationBenefitEnterprise ? 'https://storage.googleapis.com/guay-api-production/Operacion/Plantilla/Plantilla_Plataforma_guay_2023_B.xlsx': 'https://storage.googleapis.com/guay-api-production/Operacion/Plantilla/Plantilla_Plataforma_guay_2023_A.xlsx'} download><button className="btn btn-pink btn-sm" ><i className="ft-download mr-1" />Descarga plantilla</button></a>
                        {/* <a href='https://storage.googleapis.com/guay-api-production/Operacion/Plantilla/Plantilla_Plataforma_guay_v1.xlsx' download><button className="btn btn-pink btn-sm" ><i className="ft-download mr-1" />Descarga plantilla</button></a> */}
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mt-2"></div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                        <button
                            className="btn btn-blue-3 btn-sm"
                            onClick={() => setShowHistory(0)}>Regresar</button>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div
                    className="modal"
                    id="dataCollab"
                    style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                    data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div
                            className="modal-content"
                            style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body pb-5 scrollbar-style">
                                <div className="col-lg-10 mx-auto">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                <img
                                                    src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png"
                                                    alt="img-guay"
                                                    style={{ height: '50px', width: '50px' }} />
                                                <h2 className="align-middle font-weight-bold">Datos del colaborador</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={setNewCollab} method="post">
                                        <div className="col-12 mt-1">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="text-base">Nombre</label>
                                                        <input
                                                            type="text"
                                                            name="namesCollab"
                                                            className="form-control"
                                                            maxLength="100"
                                                            id="namesCollab"
                                                            onChange={getValue}
                                                            value={namesCollab} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Apellido paterno</label>
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            className="form-control"
                                                            maxLength="100"
                                                            id="lastName"
                                                            onChange={getValue}
                                                            value={lastName} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Apellido materno</label>
                                                        <input
                                                            type="text"
                                                            name="secondLastName"
                                                            className="form-control"
                                                            maxLength="100"
                                                            id="secondLastName"
                                                            onChange={getValue}
                                                            value={secondLastName} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Correo Electrónico</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            maxLength="100"
                                                            id="email"
                                                            onChange={getValue}
                                                            value={email} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Cargo/Puesto</label>
                                                        <select
                                                            className="form-control"
                                                            name="jobEntId"
                                                            id="jobEntId"
                                                            onChange={getValue}
                                                            value={jobEntId}>
                                                            <option value="0">--Seleccione--</option>
                                                            {listJobs.map((optionJobs) => (
                                                                <option
                                                                    key={optionJobs.jobEntId}
                                                                    value={optionJobs.jobEntId}
                                                                >
                                                                    {optionJobs.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Área</label>
                                                        <select
                                                            className="form-control"
                                                            name="areaEntId"
                                                            id="areaEntId"
                                                            onChange={getValue}
                                                            value={areaEntId}>
                                                            <option value="0">--Seleccione--</option>
                                                            {listAreas.map((optionAreas) => (
                                                                <option
                                                                    key={optionAreas.areaId}
                                                                    value={optionAreas.areaId}
                                                                >
                                                                    {optionAreas.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Jefe Directo</label>
                                                        <select
                                                            className="form-control"
                                                            name="bossEntId"
                                                            id="bossEntId"
                                                            onChange={getValue}
                                                            value={bossEntId}>
                                                            <option value="0">--Seleccione--</option>
                                                            {listBosses.map((optionBosses) => (
                                                                <option
                                                                    key={optionBosses.bossEntId}
                                                                    value={optionBosses.bossEntId}
                                                                >
                                                                    {optionBosses.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Cargo Homologado</label>
                                                        <select
                                                            className="form-control"
                                                            name="categoryEntId"
                                                            id="categoryEntId"
                                                            onChange={getValue}
                                                            value={categoryEntId}>
                                                            <option value="0">--Seleccione--</option>
                                                            {listCategories.map((optionCategories) => (
                                                                <option
                                                                    key={optionCategories.categoryEntId}
                                                                    value={optionCategories.categoryEntId}
                                                                >
                                                                    {optionCategories.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Centro de Trabajo</label>
                                                        <select
                                                            className="form-control"
                                                            name="branchEntId"
                                                            id="branchEntId"
                                                            onChange={getValue}
                                                            value={branchEntId}>
                                                            <option value="0">--Seleccione--</option>
                                                            {listBranches.map((optionBranches) => (
                                                                <option
                                                                    key={optionBranches.branchEntId}
                                                                    value={optionBranches.branchEntId}
                                                                >
                                                                    {optionBranches.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="text-base"
                                                            id="type_ent_colab_l">¿Es Lider?</label>
                                                        <div>
                                                            <div className="form-check-inline">
                                                                <label className="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        onChange={getValue}
                                                                        className="form-check-input"
                                                                        name="type_ent_colab"
                                                                        value={1}
                                                                        checked={type_ent_colabR1} />Si
                                                                </label>
                                                            </div>
                                                            <div className="form-check-inline">
                                                                <label className="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        onChange={getValue}
                                                                        className="form-check-input"
                                                                        name="type_ent_colab"
                                                                        value={2}
                                                                        checked={type_ent_colabR2} />No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">RFC</label>
                                                        <input
                                                            type="text"
                                                            name="rfc"
                                                            className="form-control"
                                                            id="rfc"
                                                            onChange={getValue}
                                                            value={rfc} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Teléfono Móvil</label>
                                                        <input
                                                            type="number"
                                                            name="cel"
                                                            className="form-control"
                                                            id="cel"
                                                            onChange={getValue}
                                                            value={cel}
                                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Key Player</label>
                                                        <div>
                                                            <div className="form-check-inline">
                                                                <label className="form-check-label" >
                                                                    <input
                                                                        type="radio"
                                                                        onChange={getValue}
                                                                        className="form-check-input"
                                                                        name="levelCollab"
                                                                        value={1}
                                                                        checked={levelCollabR1} />Si
                                                                </label>
                                                            </div>
                                                            <div className="form-check-inline">
                                                                <label className="form-check-label" >
                                                                    <input
                                                                        type="radio"
                                                                        onChange={getValue}
                                                                        className="form-check-input"
                                                                        name="levelCollab"
                                                                        value={2}
                                                                        checked={levelCollabR2} />No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Fecha de ingreso</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="dd/mm/aaaa"
                                                            name="dateOfAdmission"
                                                            id="dateOfAdmission"
                                                            min={minDate}
                                                            max={maxDate}
                                                            onChange={getValue}
                                                            value={dateOfAdmission} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-base">Telemedicina</label>
                                                        <div>
                                                            <div className="form-check-inline">
                                                                <label className="form-check-label" >
                                                                    <input
                                                                        type="radio"
                                                                        onChange={getValue}
                                                                        className="form-check-input"
                                                                        name="customA"
                                                                        value="1"
                                                                    />Si
                                                                </label>
                                                            </div>
                                                            <div className="form-check-inline">
                                                                <label className="form-check-label" >
                                                                    <input
                                                                        type="radio"
                                                                        onChange={getValue}
                                                                        className="form-check-input"
                                                                        name="customA"
                                                                        value=""
                                                                    />No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="accordionSet">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <a className="card-link" data-toggle="collapse" href="#collapseSet">Abrir campos personalizados</a>
                                                            </div>
                                                            <div className="collapse" id="collapseSet" data-parent="#accordionSet">
                                                                <div className="card-body">
                                                                    {/* <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 1 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customA"
                                                                            name="customA"
                                                                            onChange={getValue}
                                                                            value={customA}></textarea>
                                                                    </div> */}
                                                                    <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 1 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customB"
                                                                            name="customB"
                                                                            onChange={getValue}
                                                                            value={customB}></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 2 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customC"
                                                                            name="customC"
                                                                            onChange={getValue}
                                                                            value={customC}></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 3 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customD"
                                                                            name="customD"
                                                                            onChange={getValue}
                                                                            value={customD}></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 4 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customE"
                                                                            name="customE"
                                                                            onChange={getValue}
                                                                            value={customE}></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 5 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customF"
                                                                            name="customF"
                                                                            onChange={getValue}
                                                                            value={customF}></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="text-base">Campo personalizado 6 <span className="text-base">{"(máx. 100 caracteres)"}</span></label>
                                                                        <textarea
                                                                            className="form-control textarea-unresize"
                                                                            rows="5"
                                                                            maxLength="100"
                                                                            id="customG"
                                                                            name="customG"
                                                                            onChange={getValue}
                                                                            value={customG}></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <DentalForm
                                                        dataCollab={dataCollab}
                                                        getValue={getValue}
                                                        genderCat={genderCat}
                                                        relationBenefitEnterprise={relationBenefitEnterprise}
                                                        getFormateDate={getFormateDate}
                                                    />                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button
                                                className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                                onClick={(e) => { e.preventDefault(); $('#dataCollab').hide() }}>Atrás</button>
                                            <button
                                                type="submit"
                                                className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                                            /* onClick={() => setNewCollab()} */>Guardar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div
                    className="modal"
                    id="uploadFile"
                    style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                    data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div
                            className="modal-content"
                            style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body pb-5 scrollbar-style">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2 mt-1">
                                        <p>Existen <span className="text-base-guay font-weight-bold">{numCollabs}</span> colaboradores distribuidos en <span className="text-base-guay font-weight-bold">{numAreas}</span> áreas y <span className="text-base-guay font-weight-bold">{numBranches}</span> Centros de trabajo</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        <button
                                            className="btn btn-blue-3 btn-sm w-25 pointer float-left"
                                            onClick={() => stepBack()}>Atrás</button>
                                        <button
                                            className="btn btn-pink btn-sm w-25 pointer float-right"
                                            onClick={() => setRepalceCollaborators()}>Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal"
                    id="uploadFile2"
                    style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                    data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div
                            className="modal-content"
                            style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body pb-5 scrollbar-style">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2 mt-1">
                                        <p>Existen <span className="text-base-guay font-weight-bold">{numCollabs}</span> colaboradores distribuidos en <span className="text-base-guay font-weight-bold">{numAreas}</span> áreas y <span className="text-base-guay font-weight-bold">{numBranches}</span> Centros de trabajo.</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        <button
                                            className="btn btn-blue-3 btn-sm w-25 pointer float-left"
                                            onClick={() => stepBack()}>Atrás</button>
                                        <button
                                            className="btn btn-pink btn-sm w-25 pointer float-right"
                                            onClick={() => setAddCollaborators()}>Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div
                    className="modal"
                    id="confirmUpload"
                    style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                    data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div
                            className="modal-content"
                            style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body pb-5 scrollbar-style">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2 mt-1">
                                        <p>Existen <span className="text-base-guay font-weight-bold">
                                            {currentNumCollabs === 0 ? sessionStorage.getItem('numCollabs') : currentNumCollabs}
                                        </span> colaboradores distribuidos en <span className="text-base-guay font-weight-bold">{currentNumAreas === 0 ? sessionStorage.getItem('numAreas') : currentNumAreas}
                                            </span> áreas y <span className="text-base-guay font-weight-bold">
                                                {currentNumBranches === 0 ? sessionStorage.getItem('numBranches') : currentNumBranches}
                                            </span> Centros de trabajo.</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2">
                                        <p>Se agregarán <span className="text-base-guay font-weight-bold">{newNumCollabs}</span> colaboradores distribuidos en {newNumAreas > 0 && newNumBranches > 0 ? (<span><span className="text-base-guay font-weight-bold">{newNumAreas}</span> áreas nuevas y <span className="text-base-guay font-weight-bold">{newNumBranches}</span> Centros de trabajo nuevos.</span>) : (<span>áreas y centros de trabajo ya registrados.</span>)} </p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2">
                                        <p>¿Es correcto?</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex justify-content-center">
                                        {/* <button
                                            className="btn btn-blue-3 btn-sm w-25 pointer"
                                            onClick={() => cancelUpdate()}>Atrás</button> */}
                                        <button
                                            className="btn btn-pink btn-sm w-25 pointer"
                                            onClick={() => {
                                                sessionStorage.removeItem("numCollabs");
                                                sessionStorage.removeItem("numAreas");
                                                sessionStorage.removeItem("numBranches");
                                                setShowHistory(0);
                                            }}>Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- The Modal -->*/}
                <div
                    className="modal"
                    id="confirmReplace"
                    style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                    data-backdrop="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div
                            className="modal-content"
                            style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body pb-5 scrollbar-style">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2 mt-1">
                                        <p>Existen <span className="text-base-guay font-weight-bold">
                                            {currentNumCollabs === 0 ? sessionStorage.getItem('numCollabs') : currentNumCollabs}
                                        </span> colaboradores distribuidos en <span className="text-base-guay font-weight-bold">{currentNumAreas === 0 ? sessionStorage.getItem('numAreas') : currentNumAreas}
                                            </span> áreas y <span className="text-base-guay font-weight-bold">
                                                {currentNumBranches === 0 ? sessionStorage.getItem('numBranches') : currentNumBranches}
                                            </span> Centros de trabajo los cuales serán <span className="text-danger">eliminados</span>.</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2">
                                        <p>Se agregarán <span className="text-base-guay font-weight-bold">{newNumCollabs}</span> colaboradores distribuidos en {newNumAreas > 0 && newNumBranches > 0 ? (<span><span className="text-base-guay font-weight-bold">{newNumAreas}</span> áreas nuevas y <span className="text-base-guay font-weight-bold">{newNumBranches}</span> Centros de trabajo nuevos.</span>) : (<span>áreas y centros de trabajo ya registrados.</span>)} </p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center font-medium-2">
                                        <p>¿Es correcto?</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex justify-content-center">
                                        {/* <button
                                            className="btn btn-blue-3 btn-sm w-25 pointer"
                                            onClick={() => cancelReplace()}>Atrás</button> */}
                                        <button
                                            className="btn btn-pink btn-sm w-25 pointer"
                                            onClick={() => {
                                                sessionStorage.removeItem("numCollabs");
                                                sessionStorage.removeItem("numAreas");
                                                sessionStorage.removeItem("numBranches");
                                                setShowHistory(0);
                                            }}>Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalUploadCollab
