import React from 'react'
import ReactPlayer from 'react-player/lazy'

const VideosLayout = ({ listWebinars }) => {
    return (
        <div className='col-12'>
            <div className='row'>
                {listWebinars.length === 0 ? (
                    <div className='col-12 text-center'>
                        <h1>No hay videos por mostrar</h1>
                    </div>
                ) : (
                    listWebinars.map(data => (
                        <div className="col-sm-12 col-md-4 col-lg-4 mb-3" key={`webinar-${data.idResource}`}>
                            <div className="card m-0 h-100 hover-shadow-1">
                                {
                                    data.url.indexOf('.mp3') !== -1 ? (
                                        <div className="row d-flex justify-content-center flex-wrap align-content-center">
                                            <img
                                            src='../../img/icono-video_recursos.png'
                                            className='w-75 pt-1'
                                            alt='img'
                                        />
                                        </div>
                                    ) : (
                                        <div className="card-img-top d-flex justify-content-center flex-wrap align-content-center">
                                            <ReactPlayer
                                                url={data.url}
                                                width="100%"
                                                height="auto"
                                                controls
                                            />
                                        </div>
                                    )
                                }

                                <div className="card-body d-flex flex-wrap align-content-center p-1">
                                    <h6 className="card-tilte font-weight-bold m-0">{data.title}</h6>
                                </div>
                            </div>
                            <div className='col-12 text-center mt-1'>
                                <span
                                    className='text-blue pointer text-underline'
                                    onClick={() => window.open(data.url)}
                                >
                                    Descargar <i className='bi bi-download' />
                                </span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default VideosLayout