import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";
/**
 * Validate form
 * @param {object} formData 
 * @param {object} fullName 
 * @returns 
 */
export function validateFields(formData/* , fullName */) {
    const {
        zipCode,
        enterpriseName,
        /* applicaDate, */
        enterpriseShortName,
        email,
        idCountries,
        numCollabs,
        phone,
        sectorId,
        subSectorId,
    } = formData;
    /* const {
        name,
        lastName
    } = fullName; */
    /* if (name.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#name').addClass('border-danger-guay');
        return 0;
    }
    if (lastName.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#lastName').addClass('border-danger-guay');
        return 0;
    } */
    if (enterpriseName.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#enterpriseName').addClass('border-danger-guay');
        return 0;
    }
    if (enterpriseShortName.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#enterpriseShortName').addClass('border-danger-guay');
        return 0;
    }
    if (email.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#email').addClass('border-danger-guay');
        return 0;
    }
    if (phone.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#phone').addClass('border-danger-guay');
        return 0;
    } else if (phone.length < 10){
        toastr.error("Telefono tiene que tener por lo menos 10 digitos.", '¡Ooops!');
        $('#phone').addClass('border-danger-guay');
    }
    if (zipCode.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#zipCode').addClass('border-danger-guay');
        return 0;
    }
    if (idCountries.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#idCountries').addClass('border-danger-guay');
        return 0;
    }
    if (sectorId.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#sectorId').addClass('border-danger-guay');
        return 0;
    }
    if (subSectorId.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#subSectorId').addClass('border-danger-guay');
        return 0;
    }
    if (numCollabs.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#numCollabs').addClass('border-danger-guay');
        return 0;
    }
    /* if (applicaDate.trim() === '') {
        toastr.error("Todos los campos son obligatorios.", '¡Ooops!');
        $('#applicaDate').addClass('border-danger-guay');
        return 0;
    } */
    return 1;
}