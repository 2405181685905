import React from 'react'
// TODO: API calls
import { uploadImgProfileUser } from '../../Models/ProfileModel'
// TODO: General func
import { getJwt } from '../../../../lib/auth'
// TODO: Tools
import * as toastr from 'toastr'
import $ from 'jquery'
import Datepicker, { registerLocale } from 'react-datepicker'
import { subYears } from 'date-fns'
import es from 'date-fns/locale/es'
registerLocale('es', es)

const GenInfoForm = ({
    tcProfileGender,
    setShowForm,
    genInfoObj,
    setGenInfoObj,
    handleOnSubmitForm,
    generalInfo,
}) => {
    const {
        name,
        lastName,
        birthdate,
        idGender,
        postalCode,
        email,
    } = genInfoObj
    const {
        img,
    } = generalInfo
    const handleOnChange = e => {
        setGenInfoObj({
            ...genInfoObj,
            [e.target.name]: e.target.value,
        })
    }
    const handleOnSubmit = e => {
        e.preventDefault()
        //console.log(genInfoObj)
        handleOnSubmitForm(1, genInfoObj)
    }
    //* Upload profile image
    const renderFilePers = () => {
        $('#file').click()
    }
    const onChangeHandler = e => {
        let fileTypes = [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/tiff',
        ]
        let correct = false
        let reader = new FileReader()
        let file = e.target.files[0]
        for (let x in fileTypes) {
            if (fileTypes[x] === e.target.files[0].type) {
                correct = true
            }
        }
        if (correct === true) {
            reader.onload = function (e) {
                $('#imgProfile').attr('src', e.target.result)
            }
            reader.readAsDataURL(e.target.files[0])
            let data = new FormData()
            data.append('idUser', getJwt('jtw'))
            data.append('file', file)
            uploadImgProfileUser(data).then(response => {
                if (response && response.code === 1) {
                    $('#file').attr('value', '')
                } else {
                    $('#file').attr('value', '')
                    toastr.error(`No se pudo subir el archivo. Verifica tu conexión de internet. En caso de persistir el problema contacta con el equipo de soporte. Error: ${response.message}`, '¡Ooops!')
                }
            })
        } else {
            $('#file').attr('value', '')
            toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!')
        }
    }
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='col-12'>
                    <p className='font-weight-bold font-medium-5'>Información Básica</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <p className='text-center font-weight-bold font-medium-5'>Dejanos conocerte mejor!</p>
                    <p className='text-center font-weight-bold font-medium-3'>Confirma y completa tu información</p>
                </div>
            </div>
            <form onSubmit={handleOnSubmit}>
                <div className='row'>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex flex-wrap align-content-center'>
                        <div className='col-12 mb-2 d-flex justify-content-center img-wrapper'>
                            <img
                                className='img-rounded-1 rounded-circle border-green-guay border-1 pointer'
                                src={img === '' ? './../img/imagesProfile/iconos_guay-36.png' : img}
                                id='imgProfile'
                                alt='profile'
                                onClick={renderFilePers}
                            />
                            <img
                                className='pointer'
                                src='./../img/imagesProfile/perfil_organizacion_iconos-03.png'
                                style={{
                                    width: '60px',
                                    height: '40px',
                                    position: 'absolute',
                                    right: '25%',
                                    bottom: '0',
                                }}
                                alt='camara'
                                onClick={renderFilePers}
                            />
                            <input
                                type='file'
                                id='file'
                                className='hidden'
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                    <div className='col-sx-12 col-sm-12 col-md-6 col-lg-4 col-xxl-4 mx-auto'>
                        <div className='form-group'>
                            <label
                                htmlFor='name'
                                className='m-0'
                            >Nombre</label>
                            <input
                                type='text'
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='name'
                                name='name'
                                value={name}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className='form-group'>
                            <label
                                htmlFor='lastName'
                                className='m-0'
                            >Apellidos</label>
                            <input
                                type='text'
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='lastName'
                                name='lastName'
                                value={lastName}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className='form-group'>
                            <label
                                htmlFor='birthdate'
                                className='m-0'
                            >Fecha de Nacimiento</label>
                            <div className='col-12 p-0 datepicker-w-100'>
                                <Datepicker
                                    dateFormat='dd-MM-yyyy'
                                    className='form-control form-control-sm input-style-2 font-weight-bold'
                                    selected={birthdate}
                                    onChange={date => {
                                        setGenInfoObj({
                                            ...genInfoObj,
                                            birthdate: date,
                                        })
                                    }}
                                    minDate={subYears(new Date(), 100)}
                                    maxDate={subYears(new Date(), 16)}
                                    locale='es'
                                    placeholderText='dd-mm-aaaa (28-01-2000)'
                                    showYearDropdown
                                    showMonthDropdown
                                />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label
                                htmlFor='idGender'
                                className='m-0'
                            >Género</label>
                            <select
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='idGender'
                                name='idGender'
                                value={idGender}
                                onChange={handleOnChange}
                            >
                                <option value='0'>Seleccionar</option>
                                {
                                    tcProfileGender.map(gender => (
                                        <option
                                            key={`gender-${gender.idGender}`}
                                            value={gender.idGender}>{gender.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <label
                                htmlFor='postalCode'
                                className='m-0'
                            >Código Postal de Residencia</label>
                            <input
                                type='number'
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='postalCode'
                                name='postalCode'
                                value={postalCode}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className='form-group'>
                            <label
                                htmlFor='email'
                                className='m-0'
                            >Correo de Cuenta</label>
                            <input
                                type='email'
                                className='form-control form-control-sm input-style-2 font-weight-bold'
                                id='email'
                                name='email'
                                value={email}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button
                            type='button'
                            className='btn btn-sm btn-save-blue'
                            onClick={() => setShowForm(0)}
                        >Regresar</button>
                    </div>
                    <div className='col'>
                        <button
                            type='submit'
                            className='btn btn-sm btn-pink float-right'
                        >Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default GenInfoForm
