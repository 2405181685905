import React from 'react';

const Nav = () => {
    return (
        <div className="col-12 d-flex justify-content-end mt-2">
            <div className="breadcrumb-wrapper font-medium-3 px-1">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item font-medium-3">
                        <a href="/tienda">Tienda</a>
                    </li>
                    <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold pointer">
                        <span>Solicitud prueba</span>
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default Nav;
