import React, { useState, useEffect } from "react";
import { confirmEmail } from '../profile/Model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Swal from "sweetalert2";
import $ from 'jquery';

const ActivateEmail = (props) => {
    const [urlObject, updateUrlObject] = useState({
        url1: "",
        idProfile: "",
        url2: ""
    })
    useEffect(() => {
        const getObject = props.match.params;
        let object = getObject.db.split("=");
        updateUrlObject({
            ...urlObject,
            url1: object[0],
            idProfile: object[1],
            url2: object[2]
        })
        // eslint-disable-next-line
    }, []);

    const setConfirmEmail = () => {
        try {
            let data = {
                idProfile: urlObject.idProfile
            }
            confirmEmail(data).then((response) => {
                if (response) {
                    if (response.success === true) {
                        Swal.fire({
                            title: '<p class="text-white mb-2">¡Bien hecho!</p>',
                            text: "Se confirmó el correo correctamente. Ahora puedes iniciar sesión.",
                            showCloseButton: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            dangerMode: true
                        }).then(() => {
                            window.location = "/";
                        });
                        $(".swal2-title").addClass("bg-header-swal");
                        $(".swal2-actions").addClass("w-100 justify-content-around");
                        $(".swal2-confirm").removeClass("swal2-styled");
                        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                        $(".swal2-cancel").removeClass("swal2-styled");
                        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    } else {
                        switch (response.code) {
                            case "3013":
                                toastr.error('Este correo ya se registró en otra cuenta. Ingresa otro correo disponible.', '¡Ooops!');
                                break;
                            case "3012":
                                toastr.error('Problema interno. No se confirmó el correo correctamente.', '¡Ooops!');
                                break;
                            case "3011":
                                toastr.error('EL tiempo de confirmación expiró.', '¡Ooops!');
                                break;
                            case "3010":
                                toastr.error('No se encuentra el usuario. No se confirmó el correo correctamente.', '¡Ooops!');
                                break;
                            case "100":
                                console.log("Parameters error");
                                break;
                            default:
                                toastr.error('Problema interno. No se confirmó el correo correctamente.', '¡Ooops!');
                                break;
                        }
                    }
                } else {
                    toastr.error('Problema interno. No se confirmó el correo correctamente.', '¡Ooops!');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="app-content content bg-login-3">
            <div className="content-overlay">
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-sx-10 col-sm-10 col-md-6 col-lg-3 mx-auto">
                                    <div class="card mt-5">
                                        <div class="card-body" style={{ padding: "0px" }}>
                                            <div className="col-lg-12 d-flex justify-content-center header-black">
                                                <p className="font-medium-5 text-center font-secondary align-middle pt-1">
                                                    Confirma tu correo
                                                    <img
                                                        src="../../img/guay-logo.png"
                                                        alt="guay-digital"
                                                        style={{ height: "1.5rem" }}
                                                        className="ml-1"
                                                    />
                                                </p>
                                            </div>
                                            <div className="col-lg-11 mx-auto mt-2">
                                                <p className="text-base text-center font-secondary">
                                                    Para iniciar tu experiencia en nuestra plataforma
                                                    confirma tu correo
                                                </p>
                                            </div>
                                            <div className="col-lg-11 mx-auto d-flex justify-content-center mt-2">
                                                <span className="font-secondary"></span>
                                            </div>
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mx-auto d-flex justify-content-center mt-2">
                                                <button
                                                    className="btn btn-green btn-sm mx-auto font-secondary"
                                                    onClick={setConfirmEmail}
                                                >
                                                    Confirmar
                                                </button>
                                            </div>
                                            <div className="col-lg-12 mt-2">
                                                <div className="col-lg-10 mx-auto">
                                                    <p className="font-small-1 text-center font-secondary">
                                                        Al crear una cuenta, aceptas las{" "}
                                                        <span className="text-blue">
                                                            Condiciones de Uso
                                                        </span>{" "}
                                                        y el{" "}
                                                        <span className="text-blue">
                                                            Aviso de Privacidad
                                                        </span>{" "}
                                                        de guay
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="col-sx-10 col-sm-10 col-md-6 col-lg-3 mx-auto">
                                    <img
                                        src="../../img/loguin_fb_google_1_4.png"
                                        className="img-responsive"
                                        alt="guay-digital"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivateEmail;
