import React, { useState, useEffect } from 'react';
import { getActiveProductsByEnterprise } from '../Models/Enterprise_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { connect } from 'react-redux';
import { changeProductCategorySubCategory } from '../../../redux/generalDuck';

const SelectProductComponent = ({ enterpriseId, productData, setproductData, changeProductCategorySubCategory }) => {

    const [activeProducts, setactiveProducts] = useState([]);

    useEffect(() => {
        getActiveProductsByEnterprise(enterpriseId).then((response) => {
            switch (response?.code) {
                case 1:
                    // eslint-disable-next-line
                    const activeProducts = response.listProducts.filter((product) => {
                        if (product.acquired === 1) {
                            return product;
                        }
                    });
                    setactiveProducts(activeProducts)
                    break;
                case 200:
                    // eslint-disable-next-line
                    const activeProducts2 = response.listProducts.filter((product) => {
                        if (product.acquired === 1) {
                            return product;
                        }
                    });
                    setactiveProducts(activeProducts2)
                    break;
                default:
                    toastr.error("Ocurrió un error recargue la página por favor");
                    break;
            }
        })
        return () => {
        }
    }, [enterpriseId])

    const handleSelectProductChange = (obj) => {
        const data = JSON.parse(obj);
        changeProductCategorySubCategory(data.idProduct, data.idCategory, data.idSubcategory);
        setproductData(data);
    };

    function setOptionText(product) {
        if (product.idSubcategory === 1) {
            return `${product.nameSubcategory} (${product.nameCategory} - Fase 1)`;
        } else if (product.idSubcategory === 2) {
            return `${product.nameSubcategory} (${product.nameCategory} - Fase 2)`;
        } else {
            return `${product.nameCategory}`;
        }
    }

    return (
        <div className="row justify-content-center mb-2">
            <div className="col-md-12  col-lg-6 d-flex align-items-center">
                <p className="font-medium-2 text-center p-0 my-0 mx-1 text-base">Producto:</p>
                <select className="form-control" value={JSON.stringify(productData)} onChange={(e) => handleSelectProductChange(e.target.value)}>
                    {
                        activeProducts.map((product, idx) => {
                            return (
                                <option
                                    key={idx}
                                    value={JSON.stringify(product)}
                                >
                                    {setOptionText(product)}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps, { changeProductCategorySubCategory })(SelectProductComponent);
