import React, { useState, useEffect, useRef } from 'react';
import { getReminders } from '../Models/Catalog_model';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getDay, addDays } from 'date-fns'
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

const FechaLanzamiento = ({ launchDate, setlaunchDate, idReminder, setidReminder, allowEdit, productData, disableActions }) => {
    const [showRec, setshowRec] = useState(false);
    const [optionRec, setoptionRec] = useState([]);
    const itemRec = useRef(null);
    const today = new Date();
    useEffect(() => {
        getReminders().then((response) => {
            if (response) {
                if (response.code === 1) {
                    setoptionRec(response.listReminderLaunch)
                }
            }
        });
        return () => {
        };
    }, []);

    const handleIconClick = () => {
        setshowRec(!showRec);
        if (showRec) {
        }
    };

    const isWeekday = date => {
        const currentDate = new Date();
        const selectedDate = new Date(date);
        const day = getDay(date);
        return day !== 0 && day !== 6 && selectedDate > currentDate;
    };

    const handleSelectReminderChange = (value) => {
        setidReminder(JSON.parse(value))
    }

    // function convert(str) {
    //     var date = new Date(str),
    //         mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //         day = ("0" + date.getDate()).slice(-2);
    //     return [date.getFullYear(), mnth, day].join("-");
    // }

    const handleLaunchDateChange = (date) => {
        const dateParts = productData.dateOfExpiry.split("-");
        const cDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        if (addDays(date, 7) >= cDate) {
            toastr.info("La fecha de vencimiento del producto esta próxima, no es posible programar", "¡Importante!")
        } else {
            // sessionStorage.setItem('launchDate', convert(date));
            setlaunchDate(date);
        }
    }

    const handleKeydown = (e) => {
        e.preventDefault();
    }

    return (
        <div className="row px-1 p-sm-0">
            <div className="col-12 d-sm-flex align-items-center">
                <p className="font-medium-2 text-base mr-1">Fecha de lanzamiento</p>
                <Datepicker
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    selected={launchDate}
                    onChange={date => handleLaunchDateChange(date)}
                    filterDate={isWeekday}
                    excludeDates={today.getDay() === 4 ? ([addDays(new Date(), 1), addDays(new Date(), 4)]) : (today.getDay() === 5 ? ([addDays(new Date(), 3), addDays(new Date(), 4)]) : ([addDays(new Date(), 1), addDays(new Date(), 2)]))}
                    placeholderText="Selecciona una fecha"
                    disabled={disableActions ? disableActions : allowEdit}
                    onKeyDown={(e) => handleKeydown(e)}
                />
                {idReminder !== undefined || showRec ?
                    <select
                        className="ml-1 form-control col-10 col-sm-4 my-1 my-sm-0"
                        onChange={(e) => handleSelectReminderChange(e.target.value)}
                        value={idReminder}
                        disabled={disableActions ? disableActions : allowEdit}
                    >
                        <option>Agregar recordatorio</option>
                        {
                            optionRec.map((option) => {
                                return (
                                    <option key={option.idReminderLaunch} value={option.idReminderLaunch} selected={option.idReminderLaunch === idReminder ? true : false}>{option.description}</option>
                                )
                            })
                        }
                    </select>
                    : null}
                <i ref={itemRec} className="mx-1 ft-bell icon-size my-1 my-sm-0 pointer" onClick={() => handleIconClick()} />
                <Popup trigger={<i className="ft-help-circle icon-size icon-guay rounded-circle pointer" />} position="top">
                    <div className="p-1 text-center">
                        Te enviaremos un correo en la fecha que nos indiques para recordarte cuanto tiempo ha pasado de que inicio el lanzamiento de tus cuestionarios
                    </div>
                </Popup>
            </div>
        </div>
    )
}

export default FechaLanzamiento
