import React, { useState, useEffect } from 'react'
import { getEnterpriseArea } from '../Models/Catalog_model'
import { getLastUpdateDate, getUploadedCollaborators } from '../Models/Collaborators_model'
import { getBranches } from '../Models/Enterprise_model'

const DescParagraphComponent = ({
    enterpriseId,
    disableActions,
}) => {
    const [collaborators, setCollaborators] = useState([]);
    const [listBranches, setListBranches] = useState([]);
    const [listAreas, setListAreas] = useState([]);
    const [date, setdate] = useState("");
    const numCollabs = collaborators.length;
    const numAreas = listAreas.length;
    const numCentros = listBranches.length;
    useEffect(() => {
        getUploadedCollaborators(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setCollaborators(response.listCollab.filter(collab => collab.active === 1));
                }
            }
        });
        getBranches(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setListBranches(response.branches);
                }
            }
        });
        getEnterpriseArea(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setListAreas(response.areas);
                }
            }
        });
        getLastUpdateDate(enterpriseId).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setdate(response.lastDateUpload)
                }
            }
        })
        return () => {
        }
    }, [enterpriseId])

    const NumToSp = {
        "01": "Enero",
        "02": "Febrero",
        "03": "Marzo",
        "04": "Abril",
        "05": "Mayo",
        "06": "Junio",
        "07": "Julio",
        "08": "Agosto",
        "09": "Septiembre",
        "10": "Octubre",
        "11": "Noviembre",
        "12": "Diciembre"
    }

    function getDate(input) {
        const date = input.split("-");
        const day = date[0];
        const month = NumToSp[date[1]];
        const year = date[2];
        return `${day} de ${month} de ${year}`;
    }

    return (
        <div className="row mb-2">
            <div className="col-12">
                <p className="font-medium-2 text-base">Cuentas con un total de <span className="font-weight-bold text-dark">{numCollabs} Colaboradores</span>, distribuidos en <span className="font-weight-bold text-dark">{numCentros} Centros de Trabajo</span> y <span className="font-weight-bold text-dark">{numAreas} &Aacute;reas</span>, la última fecha de actualización fue el {getDate(date)} {!disableActions && (<a className="text-blue ml-1 pointer" href={"./administracion-colaboradores"}><ins>Actualizar</ins></a>)}</p>
            </div>
        </div>
    )
}

export default DescParagraphComponent
