import React from 'react';
import $ from "jquery";

const DocumentModalCompliance = ({setListDocuments,listDocuments,setUrlShowPdf,branch,setIdAchievement,idAchievement}) => {

    const closeModalViewPdf = () => {
        $('#documentModalCompliance').hide()
        setListDocuments([])
        setIdAchievement(null)
    }

    /**
     * 
     * @param {*} url 
     */
    const validateTypeExt = (url) =>{
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        return ext
    }

    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        closeModalViewPdf()
        let ext = validateTypeExt(url)
        if (ext === 'pdf') {
            setUrlShowPdf(url);
            $('#closeModalViewPdf').show()
        }else{
            window.open(url)
        }
    }

     /**
     * 
     * @param {*} url 
     */
     const previewFilePDFResults = (urlEmpresa, urlCT) => {
        //previewFilePDF el general (ejercicio, empresa)  previewFilePDFCT (ejercicio,empresa reemplazar param:idBranch por :176 vendria siendo el id del ct)
        closeModalViewPdf()
        // console.log('branch',branch);

        if(parseInt(branch) === 0){
            // console.log('urlEmpresa: ',urlEmpresa);
            setUrlShowPdf(urlEmpresa);
            $('#closeModalViewPdf').show()
        }else if(parseInt(branch) > 0){
            const urlCtFile = urlCT.replace(":param:idBranch", `:${branch}`);
            // console.log('urlCtFile: ',urlCtFile);
            setUrlShowPdf(urlCtFile);
            $('#closeModalViewPdf').show()
        }

     }

    return (
        <div
            className="modal"
            id="documentModalCompliance"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-12 mb-2'>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => {
                                        closeModalViewPdf();
                                    }}
                                >&times;</button>
                            </div>
                            <div className='col-12 mb-2'>
                                <h2 className='text-base-guay text-center'>Descarga tus evidencias anteriores</h2>
                            </div>
                            {
                                listDocuments.map((data,i) => (
                                    <div className='col-12 mb-2' key={i}>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-6 col-lg-8 text-center'>
                                                <span>{`${data.diffusionDate} - ${data.description}`}</span>
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4 text-center'>
                                                <i
                                                    // className={validateTypeExt(data.urlFile) !== 'pdf'  ? 'text-base-guay ft ft-download pointer': 'text-base-guay bi bi-eye'}
                                                    className='text-base-guay bi bi-eye'
                                                    onClick={() => {
                                                        if(parseInt(idAchievement) === 7){
                                                            console.log('entra a resultados');
                                                            previewFilePDFResults(data.urlFile,data.urlFileCT);
                                                        }else{
                                                            previewFilePDF(data.urlFile);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentModalCompliance