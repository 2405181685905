import React from 'react'
import $ from 'jquery'

const InfoModal = ({ currentName, setCurrentName }) => {
    return (
        <div
            className='modal'
            id='infoModal'
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop='false'
        >
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-body container-style-1 border-0 bg-blue-navy'>
                        <div className='col-12'>
                            <h3 className='text-white text-center'>DISCLAIMER</h3>
                            <h3 className='text-white text-center'>O</h3>
                            <h3 className='text-white text-center'>AVISO IMPORTANTE</h3>
                            <p className='text-white'>
                                Las altas de colaboradores durante el periodo de ejecución de los cuestionarios de {''}
                                {currentName} <u>no son válidas</u>; si durante el periodo de ejecución de los mismos hay
                                ingresos, se deberán considerar en un lanzamiento posterior.
                            </p>
                            <p className='text-white'>
                                Las bajas durante el periodo de aplicación <u>son válidas</u>, siempre y cuando se tenga el
                                respaldo administrativo de las mismas. También se pueden realizar bajas temporales
                                por motivos de incapacidad, licencia y/o vacaciones.
                            </p>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <button
                                className='btn btn-sm btn-pink'
                                onClick={() => {
                                    setCurrentName('')
                                    $('#infoModal').hide()
                                }}
                            >De acuerdo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoModal