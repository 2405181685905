import React from 'react'

const BombavistaTC_2 = () => {
    return (
        <div className='container-fluid bg-transparent-2'>
            <div className='col-sm-12 col-md-10 col-lg-6 mx-auto'>
                <div className='card mt-3'>
                    <div className='card-body'>
                        <p className='font-weight-bold'>Bombavista $200 de descuento</p>

                        <p className='font-weight-bold'>Modalidad presencial</p>

                        <p className='text-justify'>
                            Para hacer válida la promoción, el Beneficiario deberá presentarse en la sucursal de Ópticas Bombavista con una identificación oficial, credencial digital Sigma y esta cupón al momento de la compra.
                        </p>

                        <p className='text-justify'>
                            El descuento tendrá vigencia de treinta (30) días naturales desde el momento en que el Beneficiario redima el código otorgado por Sigma,
                            acorde con en el convenio vigente celebrado entre la empresa del
                            colaborador y Sigma.
                        </p>

                        <p className='text-justify'>
                            La promoción es intransferible e improrrogable y no podrá ser cambiada, canjeable o reembolsable por dinero en efectivo, ni por alguna modalidad de redención, ni en especie, ni por algún otro
                            servicio.
                        </p>

                        <p className='text-justify'>
                            Los tiempos de las Garantías de los lentes y armazones contra defecto de fabricación o vicios en su manufactura, dependen de cada óptica, centro oftalmológico o análogo donde se haya canjeado el cupón correspondiente.
                        </p>

                        <p className='text-justify'>
                            El Beneficiario podrá consultar las Garantías directamente en la óptica, centro oftalmológico o análogo.
                        </p>

                        <p className='text-justify'>
                            Los términos y condiciones relacionados a los productos Bombavista se pueden consultar en la página <a className='text-decoration-none text-black font-weight-bold' href='https://www.bombavista.mx/' target='_blank' rel="noreferrer">www.bombavista.mx</a>
                        </p>

                        <p className='font-weight-bold'>Modalidad en línea</p>

                        <p className='text-justify'>
                            Para hacer válido el beneficio, el Beneficiario deberá ingresar a la página www.bombavista.mx y seleccionar el modelo de lentes.
                        </p>

                        <p className='text-justify'>
                            La promoción es intransferible e improrrogable y no podrá ser cambiada, canjeable o reembolsable por dinero en efectivo, ni por alguna modalidad de redención, ni en especie, ni por algún otro servicio.
                        </p>

                        <p className='text-justify'>
                            Los tiempos de las Garantías de los lentes y armazones contra defecto de fabricación o vicios en su manufactura, dependen de cada óptica, centro oftalmológico o análogo donde se haya canjeado el cupón correspondiente.
                        </p>

                        <p className='text-justify'>
                            El Beneficiario podrá consultar las Garantías directamente en la óptica, centro oftalmológico o análogo.
                        </p>

                        <p className='text-justify'>
                            Los términos y condiciones relacionados a los productos Bombavista se pueden consultar en la página <a className='text-decoration-none text-black font-weight-bold' href='https://www.bombavista.mx/' target='_blank' rel="noreferrer">www.bombavista.mx</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BombavistaTC_2