/**
 * File name: CollaboratorsTable.js
 * Description: Create a table where collaborators are selected for launch
 * guay development team
 */

/**
 * -------
 * Imports
 * -------
 */
import React, { useState, useEffect, Fragment } from 'react';
import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table';
import { getCollabWithOutLaunch, getCollabByLaunch, setUpdateCollabsToLaunch } from '../Models/Launch_model';
import { getBossEnterprise } from '../Models/Enterprise_model';
import { getEnterprisesCategory, getEnterpriseArea } from '../Models/Catalog_model';
import { getUploadedCollaborators } from '../Models/Collaborators_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { getJwt } from "../../../lib/auth";
import Swal from "sweetalert2";
import $ from 'jquery';

const CollaboratorsTable = ({ productData, setShowTable, generalPreview, setGeneralPreview, idGroup, idAdminLaunch, onlyViewFlag, setOnlyViewFlag }) => {

    /**
     * -----------------
     * General variables
     * -----------------
     */
    const [listCollaborators, setListCollaborators] = useState([]);
    const [listCollaborators_2, setListCollaborators_2] = useState([]);
    const [listCategories, setListCategories] = useState([]);
    const [listAreas, setListAreas] = useState([]);
    const [listBosses, setListBosses] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalCollaborators, setTotalCollaborators] = useState(0);
    const totalSelectedRow = selectedRows.length;
    const [preview, setPreview] = useState(false);
    const [defaultSelected, setDefaultSelected] = useState([]);

    useEffect(() => {
        try {
            getUploadedCollaborators(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        let arr = [];
                        for (let x in response.listCollab) {
                            if (response.listCollab[x].active === 1) {
                                arr.push(response.listCollab[x]);
                            }
                        }
                        setTotalCollaborators(arr.length);
                    } else {
                        toastr.error("Error al cargar el número total de colaboradores. Vuelva a intentar.", "Error");
                    }
                } else {
                    toastr.error("Error al cargar el número total de colaboradores. Vuelva a intentar.", "Error");
                }
            });
            getCollabWithOutLaunch(getJwt("enterprise"), productData.idProduct, productData.idCategory, productData.idSubcategory).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListCollaborators(response.listCollab);
                    } else {
                        switch (response.code) {
                            case 404:
                                toastr.error("Error al cargar la tabla. Vuelva a intentar.", "Error");
                                break;
                            case 1001:
                                toastr.error("No existe el centro de trabajo.", "Error");
                                break;
                            case 1002:
                                /* toastr.error("No existen colaboradores para este centro de trabajo.", "Error"); */
                                break;
                            default:
                                toastr.error("Error al cargar la tabla. Vuelva a intentar.", "Error");
                                break;
                        }
                    }
                } else {
                    toastr.error("Error al cargar la tabla. Vuelva a intentar.", "Error");
                }
            });
            getCollabByLaunch(getJwt("enterprise"), idGroup, idAdminLaunch).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListCollaborators_2(response.listCollab);
                        let arr = [];
                        for (let x in response.listCollab) {
                            arr.push(response.listCollab[x].collaboratorId);
                        }
                        setDefaultSelected(arr);
                    } else {
                        switch (response.code) {
                            case 404:
                                toastr.error("Error al cargar la tabla. Vuelva a intentar.", "Error");
                                break;
                            case 1001:
                                toastr.error("No existe el centro de trabajo.", "Error");
                                break;
                            case 1002:
                                /* toastr.error("No existen colaboradores para este centro de trabajo.", "Error"); */
                                break;
                            default:
                                toastr.error("Error al cargar la tabla. Vuelva a intentar.", "Error");
                                break;
                        }
                    }
                } else {
                    toastr.error("Error al cargar la tabla. Vuelva a intentar.", "Error");
                }
            });
            getEnterprisesCategory(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListCategories(response.categorys);
                    } else {
                        toastr.error("Error al cargar puesto. Vuelva a intentar.", "Error");
                    }
                } else {
                    toastr.error("Error al cargar puesto. Vuelva a intentar.", "Error");
                }
            });
            getEnterpriseArea(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListAreas(response.areas);
                    } else {
                        toastr.error("Error al cargar área. Vuelva a intentar.", "Error");
                    }
                } else {
                    toastr.error("Error al cargar área. Vuelva a intentar.", "Error");
                }
            });
            getBossEnterprise(getJwt("enterprise")).then((response) => {
                if (response) {
                    if (response.code === 1) {
                        setListBosses(response.listBoss);
                    } else {
                        toastr.error("Error al cargar centro de trabajo. Vuelva a intentar.", "Error");
                    }
                } else {
                    toastr.error("Error al cargar centro de trabajo. Vuelva a intentar.", "Error");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, [idAdminLaunch, idGroup, productData.idProduct, productData.idCategory, productData.idSubcategory]);

    /**
     * ---------------------------------
     * Table functions and props - Start
     * ---------------------------------
     */

    /**
     * -----------------------------
     * Set data in the table - Start
     * -----------------------------
     */

    /**
     * Function that remplace id to description in bossEntId, areaEntId, categoryEntId filds and
     * fill the BootstrapTable.
     * @returns {Object}
     */
    const setDescriptions = () => {
        try {
            let array = listCollaborators;
            let array_2 = [];
            for (let b in listBosses) {
                for (let x in array) {
                    if (listBosses[b].bossEntId === array[x].bossEntId) {
                        array[x].bossEntId = listBosses[b].description;
                    }
                }
            }
            for (let c in listAreas) {
                for (let x in array) {
                    if (listAreas[c].areaId === array[x].areaEntId) {
                        array[x].areaEntId = listAreas[c].description;
                    }
                }
            }
            for (let d in listCategories) {
                for (let x in array) {
                    if (listCategories[d].categoryEntId === array[x].categoryEntId) {
                        array[x].categoryEntId = listCategories[d].description;
                    }
                }
            }
            for (let x in array) {
                if (array[x].namesCollab === "" || array[x].lastName === "" || array[x].areaEntId === 0 || array[x].categoryEntId === 0 || array[x].branchEntId === 0) {
                    array_2.push({ ...array[x], status: 'incorrecto' });
                } else {
                    array_2.push({ ...array[x], status: 'correcto' });
                }
            }
            return array_2
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Function that remplace id to description in bossEntId, areaEntId, categoryEntId filds and 
     * fill the BootstrapTable.
     * @returns {Object}
      */
    const setDescriptions_2 = () => {
        try {
            let array = selectedRows;
            let array_2 = [];
            for (let b in listBosses) {
                for (let x in array) {
                    if (listBosses[b].bossEntId === array[x].bossEntId) {
                        array[x].bossEntId = listBosses[b].description;
                    }
                }
            }
            for (let c in listAreas) {
                for (let x in array) {
                    if (listAreas[c].areaId === array[x].areaEntId) {
                        array[x].areaEntId = listAreas[c].description;
                    }
                }
            }
            for (let d in listCategories) {
                for (let x in array) {
                    if (listCategories[d].categoryEntId === array[x].categoryEntId) {
                        array[x].categoryEntId = listCategories[d].description;
                    }
                }
            }
            for (let x in array) {
                if (array[x].namesCollab === "" || array[x].lastName === "" || array[x].areaEntId === 0 || array[x].categoryEntId === 0 || array[x].branchEntId === 0) {
                    array_2.push({ ...array[x], status: 'incorrecto' });
                } else {
                    array_2.push({ ...array[x], status: 'correcto' });
                }
            }
            return array_2
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Function that remplace id to description in bossEntId, areaEntId, categoryEntId filds and 
     * fill the BootstrapTable.
     * @returns {Object}
     */
    const setDescriptions_3 = () => {
        try {
            let array = listCollaborators_2.concat(listCollaborators);
            let array_2 = [];
            for (let b in listBosses) {
                for (let x in array) {
                    if (listBosses[b].bossEntId === array[x].bossEntId) {
                        array[x].bossEntId = listBosses[b].description;
                    }
                }
            }
            for (let c in listAreas) {
                for (let x in array) {
                    if (listAreas[c].areaId === array[x].areaEntId) {
                        array[x].areaEntId = listAreas[c].description;
                    }
                }
            }
            for (let d in listCategories) {
                for (let x in array) {
                    if (listCategories[d].categoryEntId === array[x].categoryEntId) {
                        array[x].categoryEntId = listCategories[d].description;
                    }
                }
            }
            for (let x in array) {
                if (array[x].namesCollab === "" || array[x].lastName === "" || array[x].areaEntId === 0 || array[x].categoryEntId === 0 || array[x].branchEntId === 0) {
                    array_2.push({ ...array[x], status: 'incorrecto' });
                } else {
                    array_2.push({ ...array[x], status: 'correcto' });
                }
            }
            return array_2
        } catch (error) {
            console.log(error);
        }
    }
    const setDescriptions_4 = () => {
        try {
            let array = listCollaborators_2.concat(listCollaborators);
            let arr = [];
            for (let x in array) {
                for (let y in defaultSelected) {
                    if (array[x].collaboratorId === defaultSelected[y]) {
                        arr.push(array[x]);
                    }
                }
            }
            let array_2 = [];
            for (let b in listBosses) {
                for (let x in arr) {
                    if (listBosses[b].bossEntId === arr[x].bossEntId) {
                        arr[x].bossEntId = listBosses[b].description;
                    }
                }
            }
            for (let c in listAreas) {
                for (let x in arr) {
                    if (listAreas[c].areaId === arr[x].areaEntId) {
                        arr[x].areaEntId = listAreas[c].description;
                    }
                }
            }
            for (let d in listCategories) {
                for (let x in arr) {
                    if (listCategories[d].categoryEntId === arr[x].categoryEntId) {
                        arr[x].categoryEntId = listCategories[d].description;
                    }
                }
            }
            for (let x in arr) {
                if (arr[x].namesCollab === "" || arr[x].lastName === "" || arr[x].areaEntId === 0 || arr[x].categoryEntId === 0 || arr[x].branchEntId === 0) {
                    array_2.push({ ...arr[x], status: 'incorrecto' });
                } else {
                    array_2.push({ ...arr[x], status: 'correcto' });
                }
            }
            return array_2
        } catch (error) {
            console.log(error);
        }
    }
    const setDescriptions_5 = () => {
        try {
            let array = listCollaborators_2;
            console.log(listCollaborators_2)
            let array_2 = [];
            for (let b in listBosses) {
                for (let x in array) {
                    if (listBosses[b].bossEntId === array[x].bossEntId) {
                        array[x].bossEntId = listBosses[b].description;
                    }
                }
            }
            for (let c in listAreas) {
                for (let x in array) {
                    if (listAreas[c].areaId === array[x].areaEntId) {
                        array[x].areaEntId = listAreas[c].description;
                    }
                }
            }
            for (let d in listCategories) {
                for (let x in array) {
                    if (listCategories[d].categoryEntId === array[x].categoryEntId) {
                        array[x].categoryEntId = listCategories[d].description;
                    }
                }
            }
            for (let x in array) {
                if (array[x].namesCollab === "" || array[x].lastName === "" || array[x].areaEntId === 0 || array[x].categoryEntId === 0 || array[x].branchEntId === 0) {
                    array_2.push({ ...array[x], status: 'incorrecto' });
                } else {
                    array_2.push({ ...array[x], status: 'correcto' });
                }
            }
            return array_2
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * Function that show an icon in BootstrapTable
     * @param {*} cell 
     * @returns 
     */
    const setData = (cell, row, enumObject, rowIndex) => {
        if (cell === "" || cell === 0) {
            return (
                <div>
                    <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ningún dato" />
                </div>
            )
        } else {
            return (cell)
        }
    }
    /**
     * Function that show an icon in BootstrapTable
     * @param {*} cell 
     * @returns 
     */
    const setCategoryEntId = (cell, row, enumObject, rowIndex) => {
        if (cell === "" || cell === 0) {
            return (
                <div>
                    <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ningún dato" />
                </div>
            )
        } else {
            return cell
        }
    }
    /**
     * Function that show an icon in BootstrapTable
     * @param {*} cell 
     * @returns 
     */
    const setAreaEntId = (cell, row, enumObject, rowIndex) => {
        if (cell === "" || cell === 0) {
            return (
                <div>
                    <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ningún dato" />
                </div>
            )
        } else {
            return cell
        }
    }
    /**
     * Function that show an icon in BootstrapTable
     * @param {*} cell 
     * @returns 
     */
    const setBossEntId = (cell, row, enumObject, rowIndex) => {
        if (cell === "" || cell === 0) {
            return (
                <div>
                    <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ningún dato" />
                </div>
            )
        } else {
            return cell
        }
    }
    /**
     * Function that show an icon in BootstrapTable
     * @param {*} cell 
     * @returns 
     */
    const setDataStatus = (cell, row, enumObject, rowIndex) => {
        if (cell === "incorrecto") {
            return (
                <div>
                    <i className='ft ft-x icon-medium-size text-danger pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Incompleto" />
                </div>
            )
        } else {
            return (
                <div>
                    <i className='ft ft-check icon-medium-size text-success pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Completo" />
                </div>
            )
        }
    }

    /**
     * ---------------------------
     * Set data in the table - End
     * ---------------------------
     */

    /**
     * --------------------------
     * Search field props - Start
     * --------------------------
     */

    /**
     * Individual props of search input of the table
     * @returns
     */
    const createCustomSearchField = () => {
        return (
            <SearchField
                className='my-custom-class'
                placeholder='Buscar' />
        );
    }
    /**
     * Structure of the toll bar in the table
     * @param props 
     * @returns 
     */
    const createCustomToolBar = props => {
        return (
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 searcher-table mt-1'>
                <div className="row">
                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 d-flex flex-wrap align-content-center font-medium-3">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="font-weight-bold">Selecciona colaboradores para el lanzamiento</span>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                <span className="text-base">Total de colaboradores: <span className="text-base-guay font-weight-bold">{" " + totalCollaborators}</span></span>
                            </div>
                            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex flex-wrap align-content-center">
                                <span className="text-base">Colaboradores seleccionados:
                                    <span className="text-base-guay font-weight-bold">{" "} {generalPreview === true ? (listCollaborators_2.length) : (totalSelectedRow)}</span>
                                    <i
                                        className='ft ft-eye pointer ml-1'
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Visualizar"
                                        onClick={generalPreview === true ? null : preview ? () => setPreview(false) : () => setPreview(true)} />
                                </span>
                            </div> */}
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex flex-wrap align-content-center">
                                <span className="text-base">Colaboradores seleccionados:
                                    <span className="text-base-guay font-weight-bold">{" "} {generalPreview === true ? (defaultSelected.length) : (totalSelectedRow)}</span>
                                    <i
                                        className='ft ft-eye pointer ml-1'
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Visualizar"
                                        onClick={preview ? () => setPreview(false) : () => setPreview(true)} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        {props.components.searchPanel}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * ------------------------
     * Search field props - End
     * ------------------------
     */

    /**
     * -------------------
     * Style table - Start
     * -------------------
     */

    const rowStyleFormat = () => {
        return { backgroundColor: '#fff' };
    }
    const tdStyleFormat = () => {
        return { padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word', height: '50px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1' };
    }
    const thStyleFormat = () => {
        return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '0px', paddingRight: '0px' };
    }

    /**
     * -----------------
     * Style table - End
     */

    /**
     * ----------------------------
     * Row select functions - Start
     * ----------------------------
     */

    /**
     * 
     * @param {Object} row 
     * @param {boolean} isSelected 
     * @param {Event} e 
     */
    const handleRowSelect = (row, isSelected, e) => {
        if (generalPreview) {
            if (isSelected) {
                setDefaultSelected([
                    ...defaultSelected,
                    row.collaboratorId
                ]);
            } else {
                let filtedSelectedRows = defaultSelected.filter(function (element) {
                    return element !== row.collaboratorId
                });
                setDefaultSelected(filtedSelectedRows);
            }
        } else {
            if (isSelected) {
                setSelectedRows([
                    ...selectedRows,
                    row
                ]);
            } else {
                let filtedSelectedRows = selectedRows.filter(function (element) {
                    return element.collaboratorId !== row.collaboratorId
                });
                setSelectedRows(filtedSelectedRows);
            }
        }
    }
    /**
     * 
     * @param {boolean} isSelected 
     * @param {Object} rows 
     */
    const handleSelectAll = (isSelected, rows) => {
        if (generalPreview) {
            if (isSelected) {
                let array = listCollaborators_2.concat(listCollaborators);
                let arr_2 = [];
                for (let x in array) {
                    arr_2.push(array[x].collaboratorId);
                }
                setDefaultSelected(arr_2);
                return array.map(collab => collab.collaboratorId);
            } else {
                setDefaultSelected([]);
            }
        } else {
            if (isSelected) {
                setSelectedRows([...listCollaborators]);
                return listCollaborators.map(collab => collab.collaboratorId);
            } else {
                setSelectedRows([]);
            }
        }
    }
    /**
    * Checkbox row props
    */
    const selectRow = generalPreview ? (
        {
            mode: 'checkbox',  // multi select
            columnWidth: '4%',
            onSelect: handleRowSelect,
            onSelectAll: handleSelectAll,
            selected: defaultSelected
        }
    ) : (
        {
            mode: 'checkbox',  // multi select
            columnWidth: '4%',
            onSelect: handleRowSelect,
            onSelectAll: handleSelectAll,
        }
    );
    /**
     * --------------------------
     * Row select functions - End
     * --------------------------
     */

    /**
    * Table props
    */
    const options = {
        sizePerPage: 10,  // Showing 10 for the size per page as default
        hideSizePerPage: true,
        noDataText: 'No se encontraron registros.',
        searchField: createCustomSearchField,
        toolBar: createCustomToolBar,
    };

    /**
     * --------------------------------
     * Table functions and props - End
     * --------------------------------
     */

    /**
     * -------------------------
     * General functions - Start
     * -------------------------
     */

    /**
     * ---
     */
    const sendSelectedCollaborators = () => {
        setOnlyViewFlag(false);
        if (generalPreview) {
            if (defaultSelected.length > 0) {
                Swal.fire({
                    title: '<p class="text-white mb-2">¡Atención</p>',
                    text: "¿Estás seguro deseas actualizar? ",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'Cancelar',
                    showConfirmButton: true,
                    confirmButtonText: "Si, actualizar",
                    reverseButtons: true,
                    dangerMode: true
                }).then((response) => {
                    if (response.isConfirmed) {
                        let ids = ""
                        for (let x in defaultSelected) {
                            ids = ids + defaultSelected[x] + ",";
                        }
                        console.log(ids.slice(0, -1));
                        let allIds = ids.slice(0, -1);
                        console.log(allIds)
                        setUpdateCollabsToLaunch(getJwt('enterprise'), idGroup, allIds).then(response => {
                            if (response) {
                                setShowTable(false);
                            }
                        })
                    }
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
                toastr.warning("Debes seleccionar por lo menos un colaborador.", "¡Ooops!");
            }
        } else {
            let ids = ""
            for (let x in selectedRows) {
                ids = ids + selectedRows[x].collaboratorId + ",";
            }
            sessionStorage.setItem('collabs', ids.slice(0, -1));
            setShowTable(false);
        }
    }

    /**
     * -----------------------
     * General functions - End
     * -----------------------
     */

    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-2">
            <div className="row bg-white-guay rounded-top">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                    <BootstrapTable
                        data={
                            onlyViewFlag ? (
                                setDescriptions_5()
                            ) : (
                                generalPreview === true ? (
                                    preview ? (
                                        setDescriptions_4()
                                    ) : (
                                        setDescriptions_3()
                                    )
                                ) : (
                                    preview ? (
                                        setDescriptions_2()
                                    ) : (
                                        setDescriptions()
                                    )
                                )
                            )
                        }
                        trStyle={rowStyleFormat}
                        options={options}
                        selectRow={onlyViewFlag === true ? '' : selectRow}
                        /* selectRow={selectRow} */
                        searchPlaceholder='Buscar...'
                        tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                        trClassName={onlyViewFlag === true ? ('tr-data-table') : ('tr-data-table table-checkbox')}
                        /* trClassName='tr-data-table table-checkbox' */
                        tableHeaderClass={onlyViewFlag === true ? ('') : ('table-checkbox')}
                        /* tableHeaderClass='table-checkbox' */
                        pagination={true}
                        condensed
                        search
                    >
                        <TableHeaderColumn
                            dataField="collaboratorId"
                            width="0px"
                            isKey
                            dataSort ></TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={setDataStatus}
                            tdStyle={tdStyleFormat}
                            thStyle={thStyleFormat()}
                            dataField="status"
                            dataAlign="center"
                            width="6%"
                            dataSort  >Estado</TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={setData}
                            tdStyle={tdStyleFormat}
                            thStyle={thStyleFormat()}
                            dataField="namesCollab"
                            dataAlign="center"
                            dataSort >Nombre(s)</TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={setData}
                            tdStyle={tdStyleFormat}
                            thStyle={thStyleFormat()}
                            dataField="lastName"
                            dataAlign="center"
                            dataSort >Apellidos</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={tdStyleFormat}
                            dataFormat={setData}
                            thStyle={thStyleFormat()}
                            dataField="email"
                            dataAlign="center" >Email</TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={setCategoryEntId}
                            tdStyle={tdStyleFormat}
                            thStyle={thStyleFormat()}
                            dataField="categoryEntId"
                            dataAlign="center"
                            dataSort >Cargo</TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={setAreaEntId}
                            tdStyle={tdStyleFormat}
                            thStyle={thStyleFormat()}
                            dataField="areaEntId"
                            dataAlign="center"
                            dataSort >Área</TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat={setBossEntId}
                            tdStyle={tdStyleFormat}
                            thStyle={thStyleFormat()}
                            dataField="bossEntId"
                            dataAlign="center"
                            dataSort >Jefe</TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                    {generalPreview
                        ?
                        (
                            <Fragment>
                                <button
                                    onClick={() => {
                                        setGeneralPreview(false)
                                        setOnlyViewFlag(false)
                                    }}
                                    className="btn btn-blue-3 btn-sm font-medium-2 pointer float-right">Regresar a lanzamiento</button>
                                {
                                    !onlyViewFlag ? (
                                        <button
                                            onClick={() => sendSelectedCollaborators()}
                                            className="btn btn-blue-3 btn-sm font-medium-2 pointer float-right mr-2">Actualizar</button>
                                    ) : (null)
                                }
                            </Fragment>
                        )
                        :
                        (
                            <button
                                onClick={() => sendSelectedCollaborators()}
                                className="btn btn-blue-3 btn-sm font-medium-2 pointer float-right">Regresar a lanzamiento</button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CollaboratorsTable;
