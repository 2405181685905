import React, { useState, useEffect } from 'react';
// Tools
import { redirectIfNotAuthenticated2, getJwt } from '../../../lib/auth';
// Components
import Header from '../NewHome/headerAdministrador';
import Menu from '../Menu';
import Footer from '../footerApp';
import { NextSteps } from './next_steps/NextSteps';
import ActMonController from './actionsMonitoring/ActMonController';
import Protocols from './protocols/Protocols';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { connect } from 'react-redux';
import { validateAccess, getSubsection, getSubsectionIn } from '../../helpers/validations';
import { useHistory } from "react-router-dom";
import HelperApp from '../HelperApp';

const ActionController = ({ general, access }) => {

    const { idProduct, idCategory, idSubCategory } = general;

    const [menuStep, setMenuStep] = useState(null);
    const [service, setService] = useState({
        idCategory: 0,
        idSubcategory: 0
    });

    const [servicio, setServicio] = useState('');

    const { idSubcategory } = service;

    const [isPersonalized, setIsPersonalized] = useState(false);

    const [justReadSteps, setJustReadSteps] = useState({
        step_1: false,
        step_2: false,
        step_3: false,
    })
    const {
        step_1,
        step_2,
        step_3,
    } = justReadSteps

    const [menuOptionAccess, setMenuOptionAccess] = useState({
        option_1: [],
        option_2: [],
        option_3: [],
    })
    const {
        option_1,
        option_2,
        option_3,
    } = menuOptionAccess

    const history = useHistory();

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
            setIsPersonalized(true)
            //console.log(access)
            //console.log(validateAccess(access.list, 2))
            const section = validateAccess(access.list, 2)
            //console.log(getSubsection(section[0].subSection, 17))
            const subsection = getSubsection(section[0].subSection, 17)
            const pasos = getSubsectionIn(subsection[0].subSectionIn, 38)
            const monitor = getSubsectionIn(subsection[0].subSectionIn, 39)
            const protocolo = getSubsectionIn(subsection[0].subSectionIn, 40)
            setMenuOptionAccess({
                option_1: pasos,
                option_2: monitor,
                option_3: protocolo,
            })
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            } else {
                if (subsection[0].id_action === 0) {
                    toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                    history.push("/inicio-administrador")
                } else {
                    if (sessionStorage.getItem('idc') === '1' && sessionStorage.getItem('idsc') === '2') {
                        if (protocolo[0].id_action === 0) {
                            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                            history.push("/inicio-administrador")
                        } else {
                            if (protocolo[0].id_action === 2) {
                                setJustReadSteps({
                                    ...justReadSteps,
                                    step_3: true,
                                })
                                setMenuStep(2)
                            } else {
                                setMenuStep(2)
                            }
                        }
                    } else {
                        if (pasos[0].id_action === 0) {
                            if (monitor[0].id_action === 0) {
                                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                                history.push("/inicio-administrador")
                            } else {
                                if (monitor[0].id_action === 2) {
                                    setJustReadSteps({
                                        ...justReadSteps,
                                        step_2: true,
                                    })
                                    setMenuStep(1)
                                } else {
                                    setMenuStep(1)
                                }
                            }
                        } else {
                            if (pasos[0].id_action === 2) {
                                setJustReadSteps({
                                    ...justReadSteps,
                                    step_1: true,
                                })
                            }
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [access.list, history])

    //TODO: Cambiar todas las validaciones por este formato
    useEffect(() => {
        if (idProduct === 1 && idCategory === 2 && idSubCategory === 0) { //producto de clima laboral
            setServicio('climaLaboral');
            setMenuStep(2);
        }

        if (idProduct === 1 && idCategory === 1 && idSubCategory === 1) { //producto de estado emocional
            setServicio('estadoEmocional');
            setMenuStep(0);
        }

        if (idProduct === 1 && idCategory === 1 && idSubCategory === 2) { //producto de entorno laboral
            setServicio('entornoLaboral');
            setMenuStep(0);
        }

        if (idProduct === 1 && idCategory === 4 && idSubCategory === 0) { //producto de cultura organizacional
            setServicio('culturaOrg');
            setMenuStep(2);
        }

        return () => {

        }
    }, [idProduct, idCategory, idSubCategory])

    useEffect(() => {

        setService({
            idCategory: parseInt(sessionStorage.getItem('idc')),
            idSubcategory: parseInt(sessionStorage.getItem('idsc') || 1)
        });

        if (sessionStorage.getItem('idc') === '1' && sessionStorage.getItem('idsc') === '2') {
            setMenuStep(2);
        }

        if (sessionStorage.getItem('idc') === '6' && sessionStorage.getItem('idsc') === '0') {
            setMenuStep(1);
        }

        if (sessionStorage.getItem("menuOption")) {
            setMenuStep(parseInt(sessionStorage.getItem("menuOption")));
        }
    }, []);

    const checkValidation = (step) => {
        const rol = getJwt('rol');
        if (rol === '2') {
            setMenuStep(step);
        } else {
            toastr.info("No tienes permisos para acceder");
        }
    }

    const handleAccess = (step) => {
        switch (step) {
            case 0:
                if (isPersonalized) {
                    if (option_1[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_1[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_1: true,
                            })
                            setMenuStep(0)
                        } else {
                            setMenuStep(0)
                        }
                    }
                } else {
                    setMenuStep(0)
                }
                break;
            case 1:
                if (isPersonalized) {
                    if (option_2[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_2[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_2: true,
                            })
                            setMenuStep(1)
                        } else {
                            setMenuStep(1)
                        }
                    }
                } else {
                    setMenuStep(1)
                }
                break;
            default:
                if (isPersonalized) {
                    if (option_3[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_3[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_3: true,
                            })
                            setMenuStep(2)
                        } else {
                            setMenuStep(2)
                        }
                    }
                } else {
                    setMenuStep(2)
                }
                break;
        }
    }

    if (redirectIfNotAuthenticated2()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header" id="div-Acciones">
                            <div className="col-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a></li>
                                                <li className="breadcrumb-item font-medium-3">Acciones</li>
                                                {
                                                    getJwt('rol') === '6' ? (
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Protocolos de acción</li>
                                                    ) : (
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">{menuStep === 0 ? 'Siguientes pasos' : menuStep === 1 ? 'Monitor de acciones' : menuStep === 2 ? 'Protocolos de acción' : ''}</li>
                                                    )
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            {/* Title */}
                            <div className="col-11 mx-auto mt-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-2">{/* blank space */}</div>
                                    <div className="col-sm-12 col-md-12 col-lg-10">
                                        <h3 className="pl-1 font-weight-bold text-blue-sec">Acciones</h3>
                                    </div>
                                </div>
                            </div>
                            {/* title */}
                            {/* Content */}
                            <div className="col-11 mx-auto mt-1 mb-5">
                                <div className="row">
                                    {/* Menu left */}
                                    <div className="col-sm-12 col-md-12 col-lg-2 mb-1">
                                        {
                                            idSubcategory === 1 ? (
                                                <>
                                                    {
                                                        getJwt('rol') === '6' ? (
                                                            <div className="col-12 bg-blue-navy pointer py-2 rounded-bottom">
                                                                <span className="font-medium-1 text-white font-weight-bold">Monitor de Acciones</span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className={menuStep === 0 ? "col-12 bg-blue-navy pointer py-2 rounded-top" : "col-12 bg-white-guay pointer py-2 rounded-top"}
                                                                    onClick={() => handleAccess(0)}>
                                                                    <span className={menuStep === 0 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Siguientes pasos</span>
                                                                </div>
                                                                <div
                                                                    className={menuStep === 1 ? "col-12 bg-blue-navy pointer py-2 rounded-bottom" : "col-12 bg-white-guay pointer py-2 rounded-bottom"}
                                                                    onClick={() => handleAccess(1)}>
                                                                    <span className={menuStep === 1 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Monitor de Acciones</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            ) : idSubcategory === 2 || servicio === 'climaLaboral' ? (
                                                <div
                                                    className={menuStep === 2 ? "col-12 bg-blue-navy pointer py-2 rounded-bottom" : "col-12 bg-white-guay pointer py-2 rounded-bottom"}
                                                    onClick={() => checkValidation(2)}>
                                                    <span className={menuStep === 2 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Protocolos de Acción</span>
                                                </div>
                                            ) : idSubcategory === 4 || servicio === 'culturaOrg' ? (
                                                <div
                                                    className={menuStep === 2 ? "col-12 bg-blue-navy pointer py-2 rounded-bottom" : "col-12 bg-white-guay pointer py-2 rounded-bottom"}
                                                    onClick={() => checkValidation(2)}>
                                                    <span className={menuStep === 2 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Protocolos de Acción</span>
                                                </div>
                                            ) : idSubcategory === 0 ? (
                                                <div
                                                    className={menuStep === 1 ? "col-12 bg-blue-navy pointer py-2 rounded-bottom" : "col-12 bg-white-guay pointer py-2 rounded-bottom"}
                                                    onClick={() => handleAccess(1)}>
                                                    <span className={menuStep === 1 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Monitor de Acciones</span>
                                                </div>
                                            ) : (
                                                <>
                                                    {
                                                        getJwt('rol') === '6' ? (
                                                            <div className="col-12 bg-blue-navy pointer py-2 rounded-bottom">
                                                                <span className="font-medium-1 text-white font-weight-bold">Monitor de Acciones</span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className={menuStep === 0 ? "col-12 bg-blue-navy pointer py-2 rounded-top" : "col-12 bg-white-guay pointer py-2 rounded-top"}
                                                                    onClick={() => handleAccess(0)}>
                                                                    <span className={menuStep === 0 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Siguientes pasos</span>
                                                                </div>
                                                                <div
                                                                    className={menuStep === 1 ? "col-12 bg-blue-navy pointer py-2 rounded-bottom" : "col-12 bg-white-guay pointer py-2 rounded-bottom"}
                                                                    onClick={() => handleAccess(1)}>
                                                                    <span className={menuStep === 1 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Monitor de Acciones</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    {/* menu left */}
                                    {/* Content right */}
                                    <div className="col-sm-12 col-md-12 col-lg-10">
                                        <div className="col-12 bg-white-guay py-2 rounded">
                                            {
                                                menuStep === 0
                                                    ?
                                                    (
                                                        getJwt('rol') === '6' ? <ActMonController /> : <NextSteps step_1={step_1} />
                                                    )
                                                    :
                                                    menuStep === 1 ?
                                                        (
                                                            <ActMonController
                                                                step_2={step_2}
                                                            />
                                                        )
                                                        :
                                                        menuStep === 2 ?
                                                            (
                                                                <Protocols
                                                                    step_3={step_3}
                                                                />
                                                            )
                                                            : null
                                            }
                                        </div>
                                    </div>
                                    {/* content right */}
                                </div>
                            </div>
                            {/* content */}
                        </div>
                        <div className="content-footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ general, access }) => {
    return {
        general,
        access,
    }
}

export default connect(mapStateToProps)(ActionController);
