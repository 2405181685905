import React, { useState, useEffect } from 'react';
//components
import ModalesHTML from "./ModalesHTML";
// Tools
import $ from "jquery";
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const RegisterCommunication = ({ communicationData, setCommunicationData, enterprise, files, upFiles }) => {
    // Global variables
    const [designChecked1, setDesignChecked1] = useState(false);
    const [designChecked2, setDesignChecked2] = useState(false);
    const GreenRadio = withStyles({
        root: {
            color: '#15253c',
            '&$checked': {
                color: '#9cb537',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);
    useEffect(() => {
        if (communicationData.designFlg === 1) {
            setDesignChecked1(true);
            $("#designFlg1").addClass("border-pink-guay");
        } else {
            setDesignChecked2(true);
            $("#designFlg2").addClass("border-pink-guay");
        }
        // eslint-disable-next-line
    }, []);
    
    /**
     * 
     * @param {*} e 
     */
    const handleOnChange = e => {
        if (e.target.value === "1") {
            setDesignChecked1(true);
            setDesignChecked2(false);
            $("#designFlg1").addClass("border-pink-guay");
            $("#designFlg2").removeClass("border-pink-guay");
        } else {
            setDesignChecked1(false);
            setDesignChecked2(true);
            $("#designFlg1").removeClass("border-pink-guay");
            $("#designFlg2").addClass("border-pink-guay");
        }
        setCommunicationData({
            ...communicationData,
            [e.target.name]: parseInt(e.target.value)
        });
    }
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
            <ModalesHTML designFlag={communicationData.designFlg} enterprise={enterprise} communicationData={communicationData} setCommunicationData={setCommunicationData} files={files} upFiles={upFiles} />
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2 pr-0">
                                    <img
                                        src="./../img/altaEmpresas/perfil_organizacion_iconos-06.png"
                                        alt="header-section"
                                        style={{ width: '75px', height: '75px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 pl-0 mt-1">
                                    <p className="font-weight-bold">Comunicación</p>
                                    <p className="text-base">Indícanos el diseño que se adecúe más al ADN de tu organización para el envío de la información y comunicados de sensibilización. Selecciona entre:</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                            <div className="form-check">
                                                <GreenRadio
                                                    type="radio"
                                                    inputProps={{ 'aria-label': 'C' }}
                                                    size="small"
                                                    name="designFlg"
                                                    value={1}
                                                    onClick={(e) => handleOnChange(e)}
                                                    checked={designChecked1}
                                                />
                                                <label>Diseño guay</label>
                                                {/* <label className="form-check-label pointer">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input pointer"
                                                        name="designFlg"
                                                        value={1}
                                                        onClick={(e) => handleOnChange(e)}
                                                        checked={designChecked1} />Diseño guay
                                                </label> */}
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mx-auto mt-1">
                                            <img
                                                className="img-responsive"
                                                src="./../img/Correo_Vguay.png"
                                                alt="design-guay"
                                                id="designFlg1" />
                                        </div>
                                        {
                                            communicationData.designFlg === 1
                                                ?
                                                (
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-1">
                                                        <span className="text-blue pointer"  onClick={() => {$("#HTMLcomm").show()}}><u>Ver ejemplo</u></span>
                                                    </div>
                                                )
                                                : (null)
                                        }
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <div className="form-check">
                                                <GreenRadio
                                                    inputProps={{ 'aria-label': 'C' }}
                                                    size="small"
                                                    name="designFlg"
                                                    value={2}
                                                    onClick={(e) => handleOnChange(e)}
                                                    checked={designChecked2}
                                                />
                                                <label>Diseño neutro</label>
                                                {/* <label className="form-check-label pointer">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input pointer"
                                                        name="designFlg"
                                                        value={2}
                                                        onClick={(e) => handleOnChange(e)}
                                                        checked={designChecked2} />Diseño neutro
                                                </label> */}
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mx-auto mt-1">
                                            <img
                                                className="img-responsive"
                                                src="./../img/Correo_VNeutro.png"
                                                alt="design-guay"
                                                id="designFlg2" />
                                        </div>
                                        {
                                            communicationData.designFlg === 2
                                                ?
                                                (
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-1">
                                                        <span className="text-blue pointer" onClick={() => {$("#HTMLcomm").show()}}><u>Ver ejemplo</u></span>
                                                    </div>
                                                )
                                                : (null)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterCommunication;
