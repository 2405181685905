import React from 'react';

const SurveyError = ({language='es'}) => {
    return (
        <div className="row">
            <div className="col-12 d-flex justify-content-center my-2">
                <img src="./../img/imagesProfile/iconos_guay-40.png" alt="img-guay" height="100%" />
            </div>
            <div className="col-12 d-flex justify-content-center text-center font-medium-5 text-base-guay font-weight-bold mt-5">
                <span>{ language === 'es' ? 'Gracias por esperar' : 'Thank you for waiting.'}</span>
            </div>
            <div className="col-12 d-flex justify-content-center text-center font-medium-5 text-base-guay font-weight-bold mt-1">
                <span>{language === 'es' ? 'Ocurrió un problema, por favor intenta más tarde.' : 'An issue occurred, please try again later.'}</span>
            </div>
        </div>
    )
}

export default SurveyError;
