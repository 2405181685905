import React from 'react'

const JennerTC = () => {
  return (
    <div className='container-fluid bg-transparent-2'>
        <div className='col-sm-12 col-md-10 col-lg-6 mx-auto'>
            <div className='card mt-3'>
                <div className='card-body'>
                    <p className='font-weight-bold'>Jenner Laboratorio Clínico</p>

                    <p className='font-weight-bold'>Modalidad presencial</p>

                    <p className='text-justify'>
                        Los presentes términos y condiciones aplicarán para hacer válido el precio preferencial contenido en la "carta descuento" anexa a este correo, 
                        aunado a esto, el Beneficiario deberá presentarse en la sucursal de laboratorio seleccionada y mostrar una identificación oficial, credencial
                        de póliza o plan de beneficios y la "Carta descuento" al momento de la contratación del servicio. 
                    </p>

                    <p className='text-justify'>
                        La lista de precios preferenciales será otorgada por el proveedor de laboratorios.
                    </p>

                    <p className='text-justify'>
                        El descuento no será acumulable con otras promociones, ni descuentos expresamente no autorizados por el proveedor de laboratorio seleccionado.
                    </p>

                    <p className='text-justify'>
                        El beneficio tendrá vigencia de treinta (30) días naturales desde el momento en que el Usuario solicita el cupón en la plataforma www.enlinea.sdsigma.com
                        mismo que deberá presentar al proveedor de laboratorio junto con la credencial digital Sigma e identificación oficial para hacer válido el descuento.
                    </p>

                    <p className='text-justify'>
                        La promoción es intransferible e improrrogable y no podrá ser cambiada, canjeable o reembolsable por dinero en efectivo, ni por alguna modalidad de redención,
                        ni en especie, ni por algún otro servicio.
                    </p>

                    <p className='text-justify'>
                        El Beneficiario podrá consultar preparación para estudios, precios, tiempos de entrega, etc. directamente con el proveedor de laboratorios seleccionado por el Beneficiario. 
                    </p>

                    <p className='text-justify'>
                        Los términos y condiciones relacionados a estudios de laboratorio se pueden consultar en la página <a className='text-decoration-none text-black font-weight-bold' href='https://www.jenner.com.mx/' target='_blank' rel="noreferrer">www.jenner.com.mx</a>
                    </p>

                </div>
            </div>
        </div>
    </div>
  )
}

export default JennerTC
