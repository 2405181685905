import { removeCookie } from "../../lib/session";
import { getJwt, setSession } from "../../lib/auth";
import cookie from 'js-cookie';

//LogOut Application
export const exit = async (email, password) => {
  if (process.browser) {
    setSession(getJwt("jtw"));
    removeCookie("jtw");
    removeCookie("rol");
    removeCookie("branch");
    removeCookie("enterprise");
    removeCookie("help");
    removeCookie("session");
    removeCookie("business");
    removeCookie("userName");
    removeCookie("enterpriseName");
    removeCookie("help2");
    removeCookie("enterpriseInitials");
    removeCookie("email");
    removeCookie("tkc");

    cookie.remove('survey_usrname', { domain: global.domain });
    cookie.remove('survey_usrid', { domain: global.domain });
    cookie.remove('survey_entname', { domain: global.domain });
    cookie.remove('survey_entid', { domain: global.domain });
    cookie.remove('survey_branches', { domain: global.domain });
    cookie.remove('survey_access', { domain: global.domain });
    cookie.remove('survey_totalcollab', { domain: global.domain });
    // Delete cookies from survey domain
    cookie.remove('survey_usrname', { domain: global.surveyFrontPage });
    cookie.remove('survey_usrid', { domain: global.surveyFrontPage });
    cookie.remove('survey_entname', { domain: global.surveyFrontPage });
    cookie.remove('survey_branches', { domain: global.surveyFrontPage });
    cookie.remove('survey_access', { domain: global.surveyFrontPage });
    cookie.remove('survey_totalcollab', { domain: global.surveyFrontPage });
    cookie.remove('survey_entid', { domain: global.surveyFrontPage });
    cookie.remove('auth_tkn', { domain: global.surveyFrontPage });

    sessionStorage.removeItem("countHelp");
    window.location = "/";
    return null;
  }
};

//LogOut Application with timeout
export const exitTimeout = async (email, password) => {
  if (process.browser) {
    setSession(getJwt("jtw"));
    removeCookie("jtw");
    removeCookie("rol");
    removeCookie("branch");
    removeCookie("enterprise");
    removeCookie("help");
    removeCookie("session");
    removeCookie("business");
    removeCookie("userName");
    removeCookie("enterpriseName");
    removeCookie("help2");
    removeCookie("enterpriseInitials");
    removeCookie("email");
    removeCookie("tkc");

    cookie.remove('survey_usrname', { domain: global.domain });
    cookie.remove('survey_usrid', { domain: global.domain });
    cookie.remove('survey_entname', { domain: global.domain });
    cookie.remove('survey_entid', { domain: global.domain });
    cookie.remove('survey_branches', { domain: global.domain });
    cookie.remove('survey_access', { domain: global.domain });
    cookie.remove('survey_totalcollab', { domain: global.domain });
    // Delete cookies from survey domain
    cookie.remove('survey_usrname', { domain: global.surveyFrontPage });
    cookie.remove('survey_usrid', { domain: global.surveyFrontPage });
    cookie.remove('survey_entname', { domain: global.surveyFrontPage });
    cookie.remove('survey_branches', { domain: global.surveyFrontPage });
    cookie.remove('survey_access', { domain: global.surveyFrontPage });
    cookie.remove('survey_totalcollab', { domain: global.surveyFrontPage });
    cookie.remove('survey_entid', { domain: global.surveyFrontPage });
    cookie.remove('auth_tkn', { domain: global.surveyFrontPage });

    sessionStorage.removeItem("countHelp");
    return null;
  }
};

//LogOut Application 2
export const logoutHook = async (email, password) => {
  if (process.browser) {
    setSession(getJwt("jtw"));
    removeCookie("jtw");
    removeCookie("rol");
    removeCookie("branch");
    removeCookie("enterprise");
    removeCookie("help");
    removeCookie("session");
    removeCookie("business");
    removeCookie("userName");
    removeCookie("enterpriseName");
    removeCookie("help2");
    removeCookie("enterpriseInitials");
    removeCookie("email");
    removeCookie("tkc");

    cookie.remove('survey_usrname', { domain: global.domain });
    cookie.remove('survey_usrid', { domain: global.domain });
    cookie.remove('survey_entname', { domain: global.domain });
    cookie.remove('survey_entid', { domain: global.domain });
    cookie.remove('survey_branches', { domain: global.domain });
    cookie.remove('survey_access', { domain: global.domain });
    cookie.remove('survey_totalcollab', { domain: global.domain });
    // Delete cookies from survey domain
    cookie.remove('survey_usrname', { domain: global.surveyFrontPage });
    cookie.remove('survey_usrid', { domain: global.surveyFrontPage });
    cookie.remove('survey_entname', { domain: global.surveyFrontPage });
    cookie.remove('survey_branches', { domain: global.surveyFrontPage });
    cookie.remove('survey_access', { domain: global.surveyFrontPage });
    cookie.remove('survey_totalcollab', { domain: global.surveyFrontPage });
    cookie.remove('survey_entid', { domain: global.surveyFrontPage });
    cookie.remove('auth_tkn', { domain: global.surveyFrontPage });

    sessionStorage.removeItem("session");
    sessionStorage.removeItem("enterpriseName");
    sessionStorage.removeItem("userRolId");
    sessionStorage.removeItem("enterpriseId");
    sessionStorage.removeItem("Ag");
    sessionStorage.removeItem("formOrg");
    sessionStorage.removeItem("surveyModal");
    sessionStorage.removeItem("frontSurvey");
    sessionStorage.removeItem("countHelp");
    sessionStorage.removeItem("ar");
    sessionStorage.removeItem("br");
    return true;
  }
};