import React from 'react'

const AvisoPrivacidad = () => {
    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay">
                <div className="content-wrapper bootomFooter">
                    <div className="content-header"></div>
                    <div className="content-body">
                        <div className='col-12 pr-0 pl-0'>
                            <div className="embed-responsive embed-responsive-16by9 ifram-100vh">
                                <iframe
                                    className="embed-responsive-item"
                                    src={'avisoprivacidad.html'}
                                    allowFullScreen
                                    title='privacidad'
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvisoPrivacidad