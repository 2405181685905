import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getActionProtocolsByProduct } from '../../Models/Catalog_model';
import { CompleteGuides } from './CompleteGuides';
import { FastGuides } from './FastGuides';
import Menu from './Menu';
import { SupportFormats } from './SupportFormats';
import ModalViewPdf from '../../enterprises/ModalViewPdf';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from 'jquery';

const Protocols = ({ general }) => {

    const { idProduct, idCategory, idSubCategory } = general;

    const [protocolState, setProtocolState] = useState(0);
    const [quickGuides, setQuickGuides] = useState([]);
    const [completeGuides, setCompleteGuides] = useState([]);
    const [supportDocument, setSupportDocument] = useState([]);
    const [urlFollow, setUrlFollow] = useState("");
    const [urlMatrix, setUrlMatrix] = useState("");
    const [urlTutorial, setUrlTutorial] = useState("");
    const [urlShowPdf, setUrlShowPdf] = useState("");
    const [urlOption4, setUrlOption4] = useState("");

    useEffect(() => {
        getActionProtocolsByProduct(idProduct, idCategory, idSubCategory).then(response => {
            switch (response?.code) {
                case 1:
                    setQuickGuides(response.quickGuides);
                    setCompleteGuides(response.completeGuides);
                    setSupportDocument(response.supportDocument);
                    setUrlFollow(response.urlFollow);
                    setUrlMatrix(response.urlMatrix);
                    setUrlTutorial(response.urlTutorial);
                    (idProduct === 1 && idCategory === 4 && idSubCategory === 0) && setUrlOption4(response.urlOption4)
                    break;

                default:
                    break;
            }
        });
        return () => {
        }
    }, [idProduct, idCategory, idSubCategory])

    /**
     * 
     * @param {*} url 
    */
    const previewFilePDF = (url) => {
        if (url !== '') {
            let ext = url.split('.').pop()
            ext = ext.toLowerCase()
            // console.log('ext: ', ext);
            if (ext === 'pdf') {
                setUrlShowPdf(url);
                $('#closeModalViewPdf').show()
            } else {
                window.open(url)
            }
        } else {
            toastr.info('No tiene nungún archivo aún', '¡Ooops!')
        }
    }

    const Component = (component) => {
        switch (component) {
            case 0:
                return <Menu urlFollow={urlFollow} urlMatrix={urlMatrix} urlTutorial={urlTutorial} urlOption4={urlOption4} setProtocolState={setProtocolState} previewFilePDF={previewFilePDF} />;
            case 1:
                return <FastGuides quickGuides={quickGuides} setProtocolState={setProtocolState} previewFilePDF={previewFilePDF} />;
            case 2:
                return <CompleteGuides completeGuides={completeGuides} setProtocolState={setProtocolState} previewFilePDF={previewFilePDF} />;
            case 3:
                return <SupportFormats supportDocument={supportDocument} setProtocolState={setProtocolState} previewFilePDF={previewFilePDF} />;
            default:
                return <Menu urlFollow={urlFollow} urlMatrix={urlMatrix} urlTutorial={urlTutorial} urlOption4={urlOption4} setProtocolState={setProtocolState} previewFilePDF={previewFilePDF} />;
        }
    }

    return (
        <div className="row">
            <div className="col-12 mx-auto">
                <div className="col-12 mx-auto">
                    <div className="row">
                        <div className="col-12">
                            {
                                Component(protocolState)
                            }
                        </div>
                    </div>
                </div>
                <ModalViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf} />
            </div>
        </div>
    )
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps)(Protocols);
