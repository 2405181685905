import React, { Fragment, useEffect, useState } from 'react';
// API calls
import { redirectIfNotAuthenticated } from '../../../../../lib/auth';
import { getAllOrdersByUserBusiness, getAllOrdersOfBusiness, /* getOrderSpecific */ } from '../../../Models/Shop_model';
// Components
import Header from '../../../NewHome/headerColaborador';
import Menu from '../../../Menu';
import Nav from './Nav';
import ApplicationMenu from './Menu';
import StatusTable from './StatusTable';
import StatusDetails from './StatusDetails';
import StatusNotes from './StatusNotes';
// Tools
import { getJwt } from '../../../../../lib/auth';

const StatusController = () => {
    // Global variables
    const [listOrders, setListOrders] = useState([]);
    const [listClosed, setListClosed] = useState([]);
    const [listPending, setListPending] = useState([]);
    const [listProgressing, setListProgressing] = useState([]);
    const [notes, setNotes] = useState([]);
    const [catStatus, setCatStatus] = useState([]);
    const [details, setDetails] = useState({});
    const [scoreboard, setScoreboard] = useState({
        closed: 0,
        pending: 0,
        progressing: 0
    });
    const [showDetails, setShowDetails] = useState(false);
    const [showTable, setShowTable] = useState(0);
    const [orderID, setOrderID] = useState(0);
    useEffect(() => {
        if (getJwt('business') === 'true') {
            getAllOrdersOfBusiness().then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setListOrders(response.listOrders);
                            setListClosed(response.listClosed);
                            setListPending(response.listPending);
                            setListProgressing(response.listProgressing);
                            setCatStatus(response.catStatus);
                            setScoreboard({
                                closed: response.closed,
                                pending: response.pending,
                                progressing: response.progressing
                            });
                            break;
                        default:
                            break;
                    }
                }
            });
        } else {
            getAllOrdersByUserBusiness(parseInt(getJwt('jtw'))).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setListOrders(response.listOrders);
                            setListClosed(response.listClosed);
                            setListPending(response.listPending);
                            setListProgressing(response.listProgressing);
                            setScoreboard({
                                closed: response.closed,
                                pending: response.pending,
                                progressing: response.progressing
                            });
                            break;
                        default:
                            break;
                    }
                }
            });
        }
    }, []);
    const reloadData = () => {
        getAllOrdersOfBusiness().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setListOrders(response.listOrders);
                        setListClosed(response.listClosed);
                        setListPending(response.listPending);
                        setListProgressing(response.listProgressing);
                        setCatStatus(response.catStatus);
                        setScoreboard({
                            closed: response.closed,
                            pending: response.pending,
                            progressing: response.progressing
                        });
                        break;
                    default:
                        break;
                }
            }
        });
        /* getOrderSpecific(orderID).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setDetails(response);
                        setNotes(response.orderLogList);
                        setShowDetails(true);
                        break;
                    default:
                        break;
                }
            }
        }); */
    }
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <Nav />
                        </div>
                        <div className="content-body">
                            <div className="col-12">
                                <div className="row">
                                    <ApplicationMenu
                                        scoreboard={scoreboard}
                                        setShowTable={setShowTable}
                                        showDetails={showDetails}
                                        showTable={showTable}
                                    />
                                    {
                                        showDetails === true ? (
                                            <Fragment>
                                                <StatusDetails
                                                    setShowDetails={setShowDetails}
                                                    details={details}
                                                    catStatus={catStatus}
                                                    listOrders={listOrders}
                                                    orderID={orderID}
                                                    reloadData={reloadData}
                                                />
                                                <StatusNotes
                                                    notes={notes}
                                                />
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <StatusTable
                                                    setShowDetails={setShowDetails}
                                                    setDetails={setDetails}
                                                    setNotes={setNotes}
                                                    showTable={showTable}
                                                    listClosed={listClosed}
                                                    listPending={listPending}
                                                    listProgressing={listProgressing}
                                                    setOrderID={setOrderID}
                                                />
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatusController;
