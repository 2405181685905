import React from 'react';

const LanguageInput = ({
  language,
  setLanguage
}) => {
  
  return (
    <div className="col-12 d-flex justify-content-end py-1">
        <div className="btn-group">
            <button
                type="button"
                className={`btn btn-sm px-1 rounded-group-pill-left ${language === 'es' ? 'bg-primary-1 text-white' : 'bg-secundary-2'}`}
                onClick={() => setLanguage('es')}
            >Español</button>
            <button
                type="button"
                className={`btn btn-sm px-1 rounded-group-pill-right ${language === 'en' ? 'bg-primary-1 text-white' : 'bg-secundary-2'}`}
                onClick={() => setLanguage('en')}
            >English</button>
        </div>
    </div>
  );
};

export default LanguageInput;
