import React from 'react'
// TODO: General func
import { getJwt } from '../../../../lib/auth'
// TODO: Tools
import { Doughnut } from 'react-chartjs-2'

const ProfilePanel = ({
    percentageForms,
    setShowForm,
    generalInfo,
}) => {
    const {
        genInfo,
        personalInfo,
        jobInfo,
    } = percentageForms
    const {
        fullName,
        img,
        stateName,
    } = generalInfo

    //* Doughnut props
    const opciones = {
        responsive: true,
        legend: false,
        hoverOffset: 1000,
    }
    const doughnutData_1 = {
        labels: [
            'Porcentaje contestado',
            'Porcentaje por contestar',
        ],
        datasets: [
            {
                data: [genInfo, 100 - genInfo],
                backgroundColor: [
                    '#af2f72',
                    '#fff',
                ],
            },
        ],
    }
    const doughnutData_2 = {
        labels: [
            'Porcentaje contestado',
            'Porcentaje por contestar',
        ],
        datasets: [
            {
                data: [personalInfo, 100 - personalInfo],
                backgroundColor: [
                    '#af2f72',
                    '#fff',
                ],
            },
        ],
    }
    const doughnutData_3 = {
        labels: [
            'Porcentaje contestado',
            'Porcentaje por contestar',
        ],
        datasets: [
            {
                data: [jobInfo, 100 - jobInfo],
                backgroundColor: [
                    '#af2f72',
                    '#fff',
                ],
            },
        ],
    }
    return (
        <div className='row'>
            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex justify-content-center flex-wrap align-content-center'>
                <div className='row'>
                    <div className='col-12 mb-2 d-flex justify-content-center img-wrapper'>
                        <img
                            className='img-rounded-1 rounded-circle border-green-guay border-1'
                            src={img === '' ? './../img/imagesProfile/iconos_guay-36.png' : img}
                            id='imgProfile'
                            alt='profile'
                        />
                    </div>
                    <div className='col-12 mb-2 d-flex justify-content-center'>
                        <span className='font-weight-bold font-medium-4'>{fullName}</span>
                    </div>
                    {stateName !== '' && (
                        <div className='col-12 mb-2'>
                            <div className='d-flex justify-content-center flex-wrap align-content-center'>
                                <div>
                                    <img
                                        className='mr-1'
                                        src='./../img/imagesProfile/iconos_carga_colaboradores-08.png'
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                        }}
                                        alt='guay-img'
                                    />
                                    <span className='text-capitalize'>{stateName}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='col-12 mb-2'>
                        <div className='d-flex justify-content-center flex-wrap align-content-center'>
                            <div>
                                <img
                                    className='mr-1'
                                    src='./../img/imagesProfile/iconos_carga_colaboradores-10.png'
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                    }}
                                    alt='guay-img'
                                />
                                <span>{getJwt('email')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6'>
                <div className='row'>
                    <div
                        className='col-12 mb-3'
                        style={{ zIndex: '2' }}
                    >
                        <div className='row'>
                            <div
                                className='col-sx-12 col-sm-12 col-md-6 col-lg-5 col-xxl-6'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <div className='bg-light-gray-giki d-flex justify-content-center flex-wrap align-content-center doughnut-style-1'>
                                    <p className='m-0 font-weight-bold font-medium-4'>{`${genInfo}%`}</p>
                                </div>
                                <Doughnut
                                    data={doughnutData_1}
                                    options={opciones}
                                    hidden={true}
                                />
                            </div>
                            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex flex-wrap align-content-center'>
                                <span
                                    className='pointer font-medium-4'
                                    onClick={() => setShowForm(1)}
                                ><u>Información Básica</u></span>
                            </div>
                        </div>
                    </div>
                    <div
                        className='col-12 mb-3'
                        style={{ zIndex: '2' }}
                    >
                        <div className='row'>
                            <div
                                className='col-sx-12 col-sm-12 col-md-6 col-lg-5 col-xxl-6'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <div className='bg-light-gray-giki d-flex justify-content-center flex-wrap align-content-center doughnut-style-1'>
                                    <p className='m-0 font-weight-bold font-medium-4'>{`${personalInfo}%`}</p>
                                </div>
                                <Doughnut
                                    data={doughnutData_2}
                                    options={opciones}
                                />
                            </div>
                            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex flex-wrap align-content-center'>
                                <span
                                    className='pointer font-medium-4'
                                    onClick={() => setShowForm(2)}
                                ><u>Información Personal</u></span>
                            </div>
                        </div>
                    </div>
                    <div
                        className='col-12 mb-3'
                        style={{ zIndex: '2' }}
                    >
                        <div className='row'>
                            <div
                                className='col-sx-12 col-sm-12 col-md-6 col-lg-5 col-xxl-6'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <div className='bg-light-gray-giki d-flex justify-content-center flex-wrap align-content-center doughnut-style-1'>
                                    <p className='m-0 font-weight-bold font-medium-4'>{`${jobInfo}%`}</p>
                                </div>
                                <Doughnut
                                    data={doughnutData_3}
                                    options={opciones}
                                />
                            </div>
                            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex flex-wrap align-content-center'>
                                <span
                                    className='pointer font-medium-4'
                                    onClick={() => setShowForm(3)}
                                ><u>Información Laboral</u></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mb-3 text-center'>
                        <div className='row'>
                            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-5 col-xxl-6'>
                                <img
                                    src='./../img/imagesProfile/contrasenia (1).png'
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                    }}
                                    alt='guay-img'
                                />
                            </div>
                            <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex flex-wrap align-content-center'>
                                <span
                                    className='pointer font-medium-4'
                                    onClick={() => setShowForm(4)}
                                ><u>Seguridad</u></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilePanel
