import React, { useState } from 'react';
/* import swal from "sweetalert"; */
import axios from "axios";
import $ from "jquery";
// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from 'react-google-login';
import Recaptcha from "react-google-invisible-recaptcha";
// import AppleLogin from 'react-apple-login';
import { setJwt } from "../../lib/auth";
import { endpoint } from "../../lib/api/api";
import { NavLink } from "react-router-dom";
import { getuserData } from "../app/NewHome/Model";
import * as toastr from 'toastr';
import { max_length_inp } from "../../lib/utils/generalFuntions";
import 'toastr/build/toastr.css';
import "animate.css";


const Login = () => {

  // State for Facebook & login register
  //sessionStorage.setItem('bandera', '0')
  const [regisData, updStateReg] = useState({
    name: "",
    email: "",
    phone: "",
    key: "",
    type: "1",
    usePhone: 0,
    isValidate: 0,
    user: 0,
    img: "",
    lastName: "",
  });
  //State for login 

  //Facebook Register & Login
  // const responseFacebook = (response) => {
  //   try {
  //     if (!response.status) {
  //       regisData.name = response.name;
  //       regisData.email = response.email;
  //       regisData.user = response.id;
  //       regisData.img = response.picture.data.url;
  //       regisData.type = 2;
  //       regisData.key = "";
  //       userModel();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  //Google Register & Login
  // const responseGoogle = (response) => {
  //   try {
  //     if (!response.error) {
  //       let result = response.profileObj;
  //       var gId = result.googleId;
  //       regisData.user = gId.substring(4, 20);
  //       regisData.name = result.givenName;
  //       regisData.lastName = result.familyName;
  //       regisData.email = result.email;
  //       regisData.img = result.imageUrl;
  //       regisData.type = 3;
  //       regisData.key = "";
  //       userModel();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const userModel = async () => {
  //   try {
  //     const url = global.base_url + "users/login";
  //     const response = await axios.post(url, regisData);
  //     if (!response.data.success) {
  //       let message = "";
  //       switch (response.data.code) {
  //         case 1000:
  //           message = "Error al registrar la informacion. Intente Nuevamente.";
  //           break;
  //         case 1001:
  //           message =
  //             "Ya existe una cuenta registrada con ese número o correo electrónico. Intente Nuevamente.";
  //           break;
  //         case 1002:
  //           message =
  //             "Ya existe una cuenta registrada con el correo electrónico Intente Nuevamente.";
  //           break;
  //         /* case 1002:
  //           message =
  //             "Ya existe una cuenta registrada con el correo electrónico Intente Nuevamente.";
  //           break;   */
  //         default:
  //           message = "Error del sistema. Intente Nuevamente.";
  //           break;
  //       }
  //       toastr.error(message, '¡Ooops!');
        

  //     } else {
  //       getuserData(response.data.id).then((response) => {
  //         if (response.enterprise.length > 0) {
  //           let userData = {
  //             id: response.token,
  //             enterprise: response.enterprise[0].enterpriseId,
  //             rol: response.rol,
  //             help: response.help,
  //             tkc: response.tk,
  //             email: response.correo,
  //             session: response.session,
  //             enterpriseName: response.name,
  //             enterpriseInitials: response.initials,
  //           }
  //           setJwt(userData);
  //         } else {
  //           let userData = {
  //             id: response.token,
  //             rol: response.rol,
  //             help: response.help,
  //             tkc: response.tk,
  //             email: response.correo,
  //             session: response.session,
  //             enterpriseName: response.name,
  //             enterpriseInitials: response.initials,
  //           }
  //           setJwt(userData);
  //         }
  //         switch (response.rol) {
  //           case 1:
  //             window.location = "inicio-usuario";
  //             break;
  //           default:
  //             window.location = "inicio-colaborador";
  //             break;
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // Change Value form
  const getValue = (e) => {
    try {
      let value = e.target.value;

      //max_length_inp
      if (e.target.id === "user") {
        max_length_inp(e.target.id, 100)
      }
      if (value === null || value.length === 0) {
        $("#" + e.target.name).removeClass("border-success");
        $("#" + e.target.name).addClass("border-danger");
      } else {
        $("#" + e.target.name).removeClass("border-danger");
        $("#" + e.target.name).addClass("border-success");
      }

      updStateReg({
        ...regisData,
        [e.target.name]: value,
      });
    } catch (error) {
      console.log(error);
    }
  }
  // Exucute login
  const validaUser = async (e) => {
    $('#btn-login').text("").prop('disabled', true)
      .addClass("btn-guay-processing")
      .append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite	infinite"></i> Procesando...');
    $("#form-users :input").prop("disabled", true);
    e.preventDefault();
    try {
      let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      if (!regex.test(regisData.email)) {
        regisData.phone = regisData.email;
        regisData.email = "";
      }
      if (regisData.email.length > 0 || regisData.phone.length > 0 || regisData.key.length > 0) {
        const result = await axios.post(endpoint("/users/login"), regisData);
        let response = result.data;
        console.log(response)
        if (response.success) {
          getuserData(response.id).then((response) => {
            // let enterprise = response.enterprise;
            // let enterpriseGroup = response.enterpriseGroup;
            sessionStorage.setItem('session', response.session);
            if (response.enterprise.length > 0) {
              let userData = {
                id: response.token,
                enterprise: response.enterprise[0].enterpriseId,
                // VZO 19/01/2021, branch: response.branch[0].branchEntId
                rol: response.rol,
                help: response.help,
                // VZO 24/02/2021, help2: 0
                tkc: response.tk,
                email: response.correo,
                session: response.session,
                enterpriseName: response.name,
                enterpriseInitials: response.initials,
              }
              setJwt(userData);
            } else {
              let userData = {
                id: response.token,
                rol: response.rol,
                help: response.help,
                // VZO 24/02/2021, help2: 0
                tkc: response.tk,
                email: response.correo,
                session: response.session,
                enterpriseName: response.name,
                enterpriseInitials: response.initials,
              }
              setJwt(userData);
            }
            switch (response.rol) {
              case 1:
                window.location = "inicio-usuario";
                break;
              default:
                window.location = "inicio-colaborador";
                break;
            }
          });
        } else {
          setTimeout(function () {
            $('#btn-login').text("").prop('disabled', false)
              .append('Ingresar')
              .removeClass("btn-guay-processing");
            $("#form-users :input").prop("disabled", false);
          }, 3000);
          switch (response.code) {
            case 1006:
            case 1007:
              setTimeout(function () {
                toastr.error('Los datos ingresados no son correctos. Vuelva a ingresar la información.', '¡Ooops!');
              }, 3000);
              break;
            case 1008:
              setTimeout(function () {
                toastr.warning('La cuenta aún no ha sido activada.', '¡Ooops!');
              }, 3000);
              break;
            case 1009:
              setTimeout(function () {
                toastr.warning('Solamente se puede tener una sesión activa. Cierra todas las sesiones e ingresa nuevamente.', '¡Espera!');
              }, 3000);
              break;
            default:
              setTimeout(function () {
                toastr.error('Error en la activación. Verifique la información.', '¡Ooops!');
              }, 3000);
              break;
          }
          // $("#password, #user").val("");
        }
      } else {

        toastr.warning('Debe registrar la información solicitada.', '¡Atención!');
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }


  // Hide and show text in inputs
  const seePassword = (e) => {
    e.preventDefault();
    try {
      switch (e.target.id) {
        case "icon_eye_1":
          var input = $("#password");
          if (input.attr("type") === "password") {
            input.attr("type", "text");
            $("#icon_eye_1").attr("class", "ft-eye-off");
          } else {
            input.attr("type", "password");
            $("#icon_eye_1").attr("class", "ft-eye");
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

 


  return (

    <div className="app-content content">
      <div className="content-overlay">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">

            <section className="bg-login-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-xs-12 col-md-4 align-items-center d-flex justify-content-center ">
                      <div className="row">
                        <div className="col-md-12">
                          <div style={{ height: "500px" }}>
                            <div className="card border-grey border-lighten-3 mt-2  h-40 card-header-register" style={{ borderRadius: "15px" }}>
                              <div className="">
                                <div className="border-0  bg-header-login" style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                                  <div className="font-large-1 text-center text-dark" style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#15253c" }}>
                                    <span className="font-medium-5" style={{ fontFamily: "quicksand", color: 'white' }}>Inicia sesión</span>{" "}
                                  </div>
                                </div>
                                {/* <div className="col-md-12 input-group mt-1 "  /*style={{ paddingLeft: "110px" }} />
                                  <div className="col-md-12 align-items-center d-flex justify-content-center ">
                                    <FacebookLogin
                                      appId="585782192144603"
                                      fields="name,email,picture"
                                      callback={responseFacebook}
                                      icon="fa-facebook fa-2x icon-fb-size float-left ml--3px"
                                      textButton="Entra con Facebook"
                                      cssClass="icon-facebook-registrar"
                                    />
                                  </div>
                                  <div className="col-md-12 mt-1 align-items-center d-flex justify-content-center ">
                                    <GoogleLogin
                                      clientId="78720906845-tk0et1p4cln26qpb7n4h5btreeg1b6d3.apps.googleusercontent.com"
                                      textButton="Entrar con Google"
                                      onSuccess={responseGoogle}
                                      onFailure={responseGoogle}
                                      render={(renderProps) => (
                                        <button
                                          className="icon-google-registrar"
                                          onClick={renderProps.onClick}
                                          textButton="Entrar con Gmail"
                                        >


                                          <i className="fa fa-google-plus icon-google-size" style={{
                                            marginLeft:
                                              '3px', fontSize: "1.5em", float: "left"
                                          }} />
                                          <span style={{ float: "unset", marginLeft: "-36px" }}>
                                            {"Entra con Gmail"}
                                          </span>

                                        </button>
                                      )}
                                    />
                                  </div>
                                  <div className="col-md-12 mt-1 align-items-center d-flex justify-content-center">
                                    <AppleLogin
                                      clientId="8VK7X89JY7"
                                      redirectURI={"https://smosguay.com"}
                                      responseType={"code"}
                                      responseMode={"query"}
                                      usePopup={false}
                                      designProp={
                                        {
                                          height: 30,
                                          width: 207,
                                          color: "black",
                                          border: false,
                                          type: "sign-in",
                                          border_radius: 5,
                                          scale: 1,
                                          locale: "es_MX",
                                        }
                                      }
                                    />

                                  </div>

                                </div>
                                <div className="col-md-12  align-items-center d-flex justify-content-center ">
                                  <div className="text-base" id="or">o</div>
                                </div> */}
                                <div className="col-md-12">
                                  <form id="form-users"
                                    onSubmit={validaUser}
                                    autoComplete="off">
                                    <div className="row mt-30">

                                      <div className="col-md-1"></div>
                                      <div className="col-md-9 align-items-center justify-content-center ml-2">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text bg-dark"><i className="la la-at"></i></span>
                                          </div>
                                          <input type="text"
                                            name="email"
                                            id="user"
                                            className="form-control"
                                            onChange={getValue}
                                            placeholder="Correo" />
                                        </div>
                                        <div className="input-group mt-2">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text bg-dark"><i className="la la-lock"></i></span>
                                          </div>

                                          <input type="password"
                                            name="key"
                                            id="password"
                                            onChange={getValue}
                                            className="form-control border-right-0"
                                            placeholder="Contraseña" />
                                          <div className="input-group-prepend">
                                            <span className="input-group-text icon-eye-form" onClick={seePassword}><i className="ft-eye" id="icon_eye_1"></i></span>

                                          </div>
                                        </div>



                                        <div className="col-md-12">
                                          <Recaptcha
                                            sitekey="6Lf2KjIbAAAAAK-K2Cwtz7UkGf_ENb5B0dmrHFlx"
                                            onResolved={() => console.log("Human detected.")}
                                          />
                                        </div>
                                        <div className="align-items-center d-flex justify-content-center">
                                          <button
                                            type="submit"
                                            color="blue"
                                            style={{ height: "40px", width: "150px", lineHeight: "0rem", backgroundColor: "#15253c", color: "white", borderRadius: "10px", fontFamily: "quicksand" }}
                                            className="btn btn-guay2 mt-1 btn-min-width"
                                            id="btn-login"
                                          >Ingresar</button>
                                        </div>
                                        <NavLink to="acceso/recuperar-contrasena"><p className="text-blue text-bold-300 mt-2 text-center">¿Olvidaste tu contraseña?</p></NavLink>
                                      </div>
                                    </div>
                                  </form>
                                </div>

                              
                                <div className="col-md-12 text-center ">
                                  <div className="row pb-1">
                                    <div className="col-md-6">
                                      <a href="registro" className="font-medium-1 text-bold-300 text-blue">¿No tienes cuenta guay?</a>
                                    </div>
                                    <div className="col-md-6 form-group text-center">
                                      <a href="registro"><button
                                        type="button"
                                        color="blue"
                                        style={{ height: "40px", width: "150px", lineHeight: "0rem", backgroundColor: "#15253c", color: "white", borderRadius: "10px", fontFamily: "quicksand" }}
                                        className="btn btn-guay2 btn-min-width ml-2 mb-1"
                                        text="Registrate">Regístrate</button></a>
                                    </div>


                                  </div>

                                </div>


                              </div>
                              <div>


                              </div>

                            </div>
                            <div className="col-md-12 ml-3">
                              <img src="../../img/loguin_fb_google_1_4.png" style={{ width: "80%" }} alt="guay-digital" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">

                  </div>


                </div>






              </div>

              {/* <div className="row mt-6">
        <div className=" col-md-6  align-items-center justify-content-center ">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-6 col-md-7  p-0">
                
                <div className="col-md-12 bg-blue text-center ">
                  <div className="row pb-1">
                    <div className="col-md-7">
                      <p className="text-white font-medium-3 text-bold-300 pt-2">¿No tienes cuenta guay?</p>
                    </div> 
                    <div className="col-md-3">
                     <a href="registro"><Buttons
                             type="button"
                             color="outline-light"
                             text="Registrate"/></a> 
                    </div> 
                  </div>
                   



                   
                </div>
                
            </div>
            </div>
            
            
          
        </div>
  </div>*/}
            </section>
          </div>
        </div>
      </div>
    </div>

  )
}


export default Login;
