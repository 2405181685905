import React, { Fragment, useState, useEffect } from 'react';
// Tools
import PropTypes from 'prop-types';
import { getJwt } from '../../../../lib/auth';
import { getLocation, returnBrowser, returnOS } from '../../../helpers/helpers';
import { setFinishQuizCollab, setResponseQuizCollab, setOpenResponseQuizCollab } from '../../Models/FrontSurvey_model';
import { handleScroll } from '../../../helpers/helpers';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";

const Questions = ({ survey, idCollab }) => {

    const [surveyValues, setSurveyValues] = useState([]);
    const [basckSurvey, setBasckSurvey] = useState([]);

    useEffect(() => {
      if(survey.length > 0){
        setBasckSurvey(survey);
        let coordinates = ""
        const getData = async () => {
            coordinates = await getLocation.then(response => response).catch(error => error);
            const filteredArray = survey.filter(item => item.hasOwnProperty('response'));
            const arrSurveyValues = filteredArray.map(data => {
                return {
                    idEnterprise: parseInt(getJwt('enterprise')),
                    idQuizEnterprise: parseInt(data.idQuizEnterprise),
                    idQuestionQuiz: parseInt(data.idQuestionQuiz),
                    answerQuiz: data.response.idQuestionQuiz === data.idQuestionQuiz && data.response.answerQuiz,
                    idCollaborator: idCollab,
                    coordinates: coordinates,
                    browser: returnBrowser(),
                    os: returnOS()
                }
            })
            // console.log('arrSurveyValues: ',arrSurveyValues);
            if(arrSurveyValues.length > 0 ){
                setSurveyValues(arrSurveyValues);
                const filteredNotAnsweredArray = survey.filter(item => !item.hasOwnProperty('response'));
                // console.log('filteredNotAnsweredArray: ',filteredNotAnsweredArray);
                setTimeout(() => {
                    handleScroll(`div-question-${filteredNotAnsweredArray[0].idQuestionQuiz}`);
                }, 500);

            } 
        }
        getData();
    
      }
      
    }, [survey])
    
    const counterTxt = (e,idQuestion) => {
        const span = document.getElementById(`question-${idQuestion}-counter`);
        span.textContent = `${e.target.value.length}/400`; // Cambiar el texto del span
    }

    const handleOptionsLetter = (answer, index) => {
        const letters = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm','n', 'ñ', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        return (
            <span><span className='font-weight-bold'>{`${letters[index]})`}</span> {answer}</span>
        )
    }

    const sendOpenResponse = (data) => {
        const savedAnswered = setOpenResponseQuizCollab(data).then(response => {
            switch (response?.code) {
                case 1:
                    /* toastr.success('Respuesta guardada correctamente.'); */
                    return true;

                case 99:
                    toastr.error('Ocurrió un error al guardar, verifique su conexión a internet.');
                    return false;

                case 1000:
                    toastr.error('No se pudo validar los datos ingresados, contacte a su administrador.');
                    return false;

                case 1001:
                    toastr.info('No existe su cuestionario, contacte a su administrador.');
                    return false;

                default:
                    toastr.error('Ocurrió un error inesperado. Recargue su pagina y vuelva a intentarlo.');
                    return false;
            }
        })
        return savedAnswered;
    }

    const sendResponse = (data) => {
        const savedAnswered = setResponseQuizCollab(data).then(response => {
            switch (response?.code) {
                case 1:
                    /* toastr.success('Respuesta guardada correctamente.'); */
                    return true;

                case 99:
                    toastr.error('Ocurrió un error al guardar, verifique su conexión a internet.');
                    return false;

                case 1000:
                    toastr.error('No se pudo validar los datos ingresados, contacte a su administrador.');
                    return false;

                case 1001:
                    toastr.info('No existe su cuestionario, contacte a su administrador.');
                    return false;

                default:
                    toastr.error('Ocurrió un error inesperado. Recargue su pagina y vuelva a intentarlo.');
                    return false;
            }
        })
        return savedAnswered;
    }

    const handleChangeInput = async (survey, { target }) => {
        // Se crea el objeto con los datos a mandar
        const data = {
            idEnterprise: parseInt(getJwt('enterprise')),
            idQuizEnterprise: parseInt(survey.idQuizEnterprise),
            idQuestionQuiz: parseInt(survey.idQuestionQuiz),
            answerQuiz: target.value.trim(),
            idCollaborator: idCollab,
            coordinates: await getLocation.then(response => response).catch(error => error),
            browser: returnBrowser(),
            os: returnOS()
        };

        const nextQuestion =  basckSurvey.filter(data => data.idOrder === (survey.idOrder+1)) 
        if (survey.idTypeQuestion === 1) { // Si el tipo de pregunta es abierta
            // console.log('es pregunta abierta y el data es ',data);
            const index = surveyValues.findIndex(surveyValue => surveyValue.idQuestionQuiz === survey.idQuestionQuiz);
            if (index === -1) { // Si no existe la pregunta en el array se agrega
                if(data.answerQuiz.trim() !== ""){
                    if(sendOpenResponse(data)){
                        setSurveyValues(prevSurveyValues => [...prevSurveyValues, data]);
                        if (nextQuestion.length > 0) {
                            handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                        }
                    }
                }
            } else { // si existe la pregunta en el array se elimina y se pasa e nuevo objeto
                // console.log('data.answerQuiz.trim(): ', data.answerQuiz.trim(),' data.answerQuiz.trim().length ',data.answerQuiz.trim().length, ' data.answerQuiz.length', data.answerQuiz.length);
                if(sendOpenResponse(data)){
                    // console.log('se manda la actualizacion abierta al back2');
                    if(data.answerQuiz.trim() === ""){
                        // Si se esta enviando una respuesta vacia entonces la quitamos del listado
                        setSurveyValues([...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz)]);
                    }else{
                        // Actualizamos el valor
                        setSurveyValues([...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz), data]);
                        if (nextQuestion.length > 0) {
                            handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                        }

                    }
                }
                // if(data.answerQuiz.trim() === ""){
                //     if(survey.response && survey.response.idQuestionQuiz === survey.idQuestionQuiz && survey.response.answerQuiz !== ""){
                //         console.log('se manda la actualizacion abierta al back1');
                //         // if(sendResponse(data)){
                //         setSurveyValues([...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz), data]);
                //         // }
                //     }else{
                //         // if(sendResponse(data)){
                //         console.log('se manda la actualizacion abierta al back2');
                //         setSurveyValues([...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz)]);
                //         // }
                //     }                
                // }else{
                //     console.log('trim ');
                //     // if(sendResponse(data)){
                //         console.log('se manda la actualizacion abierta al back3');
                //         setSurveyValues([...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz), data]);
                //     // }
                // }
            }
        } else if (survey.idTypeQuestion === 4) { // Si el tipo de pregunta es checkbox
            const index = surveyValues.findIndex(surveyValue => surveyValue.idQuestionQuiz === survey.idQuestionQuiz);
            if (index === -1) { // Si no existe la pregunta en el array se agrega
                if(sendResponse(data)){
                    setSurveyValues(prevSurveyValues => [...prevSurveyValues, data]);
                    if (nextQuestion.length > 0) {
                        handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                    }
                }
            } else { // si existe la pregunta en el array se elimina y se pasa eL nuevo objeto
                // convertimos en array las respuestas seleccionadas
                let arrChecked = surveyValues[index].answerQuiz.split(",");
                //verificamos el check 
                if(target.checked){ //se quitara el check
                    // Validamos si si estaba seleccionado
                    if(arrChecked.some(item => item === target.value)){
                        let newAns  = arrChecked.filter(element => element !== target.value).toString();
                        if(newAns !== ""){
                            if(sendResponse({ ...data, answerQuiz: newAns })){
                                setSurveyValues([
                                    ...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz),
                                    { ...data, answerQuiz: newAns }
                                ]);
                                if (nextQuestion.length > 0) {
                                    handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                                }
                            }
                        }
                        

                    }

                }else{ //Se agrega el check
                    if(sendResponse({ ...data, answerQuiz: `${surveyValues[index].answerQuiz},${target.value}` })){
                        setSurveyValues([
                            ...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz),
                            { ...data, answerQuiz: `${surveyValues[index].answerQuiz},${target.value}` }
                        ]);
                        if (nextQuestion.length > 0) {
                            handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                        }
                    }
                }
                
            }
        } else {
            const index = surveyValues.findIndex(surveyValue => surveyValue.idQuestionQuiz === survey.idQuestionQuiz);
            if (index === -1) { // Si no existe la pregunta en el array se agrega
                if(sendResponse(data)){
                    setSurveyValues(prevSurveyValues => [...prevSurveyValues, data]);
                    if (nextQuestion.length > 0) {
                        handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                    }
                }
            } else { // si existe la pregunta en el array se elimina y se pasa eL nuevo objeto
                if(data.answerQuiz.trim() !== ""){
                    if(sendResponse(data)){
                        setSurveyValues([
                            ...surveyValues.filter(surveyValue => surveyValue.idQuestionQuiz !== survey.idQuestionQuiz),
                            data
                        ]);
                        if (nextQuestion.length > 0) {
                            handleScroll(`div-question-${nextQuestion[0].idQuestionQuiz}`);
                        }
                    }
                }
            }
        }
    }

    const handleClickFinish = (e) => {
        e.preventDefault();
        let flagValidation = false;
        $('#submitBtn').attr('type', 'button');
        $('#submitBtn').attr('disabled', 'true');
        // Obtenemos arreglo de preguntas obligatorias
        const requiredQuestions = survey.filter(data => data.obligatory === 1);
        // console.log('requiredQuestions: ', requiredQuestions);
        // console.log('surveyValues: ', surveyValues);
        if(requiredQuestions.length > 0){ // si existen preguntas que son obligatorias
            let arrPendingQuestions = [];
            if(surveyValues.length > 0){
                requiredQuestions.map(requiredQuestion => { 
                    // Veridicamos si la pregunta obligatoria existe en el arreglo de surveyValues
                    const existQuestion = surveyValues.some(surveyValue => (surveyValue.idQuestionQuiz === requiredQuestion.idQuestionQuiz) && (surveyValue.answerQuiz !== undefined && surveyValue.answerQuiz !== ""))
                    // console.log('existQuestion');
                    if(!existQuestion){
                        arrPendingQuestions.push(requiredQuestion);
                    }
                })
                // console.log('arrPendingQuestions: ',arrPendingQuestions);
                if(arrPendingQuestions.length > 0){ //Existen preguntas obligatorias pendientes de responder
                    flagValidation = false;
                    handleScroll(`div-question-${arrPendingQuestions[0].idQuestionQuiz}`);
                    const txtPendinQuestions = arrPendingQuestions.map(item => item.idOrder).toString();
                    const txtLabel = arrPendingQuestions.length > 1 ? `Las preguntas número: ${txtPendinQuestions} son obligatorias, conteste todas las preguntas para finalizar.` : `La pregunta número: ${txtPendinQuestions} es obligatoria, conteste la pregunta para finalizar.`;
                    toastr.warning(txtLabel);
                    $('#submitBtn').attr('type', 'submit');
                    $('#submitBtn').attr('disabled', 'false');
                    
                }else{ //No existen preguntas obligatorias pendientes de contestar
                    flagValidation = true;
                }
            }else{
                // console.log('no ha contestado nada');
                flagValidation = false;
                handleScroll(`div-question-${requiredQuestions[0].idQuestionQuiz}`);
                const txtPendinQuestions = requiredQuestions.map(item => item.idOrder).toString();
                const txtLabel = requiredQuestions.length > 1 ? `Las preguntas número: ${txtPendinQuestions} son obligatorias, conteste todas las preguntas para finalizar.` : `La pregunta número: ${txtPendinQuestions} es obligatoria, conteste la pregunta para finalizar.`;
                toastr.warning(txtLabel);
                $('#submitBtn').attr('type', 'submit');
                $('#submitBtn').attr('disabled', 'false');
            }
            
        }else{ //No existen preguntas obligatorias
            // Validamos si existen datos para enviar
            if(surveyValues.length > 0){
                flagValidation = true;
            }else{
                flagValidation = false;
                sessionStorage.setItem('frontSurvey', 1);
                $('#frontSurvey').hide();
            }

        }
        // Paso las validaciones y guardara la info
        if(flagValidation){
            const data = {
                idEnterprise: parseInt(getJwt('enterprise')),
                idQuizEnterprise: parseInt(survey[0].idQuizEnterprise),
                idCollaborator: idCollab,
                answers: surveyValues
            }
            setFinishQuizCollab(data).then(response => {
                switch (response?.code) {
                    case 1:
                        toastr.success('Encuesta guardada Satisfactoriamente.');
                        sessionStorage.setItem('frontSurvey', 1);
                        $('#frontSurvey').hide();
                        break;

                    case 2:
                        toastr.info('Faltan preguntas obligatorias por responder.', '¡Ooops!');
                        break;

                    case 99:
                        toastr.error('Ocurrió un error al guardar, verifique su conexión a internet.');
                        break;

                    case 1000:
                        toastr.error('No se pudo validar los datos ingresados, contacte a su administrador.');
                        break;

                    case 1001:
                        toastr.info('No existe su cuestionario, contacte a su administrador.');
                        break;

                    default:
                        toastr.error('Ocurrió un error inesperado. Recargue su pagina y vuelva a intentarlo.');
                        break;
                }
                $('#submitBtn').attr('type', 'submit')
                $('#submitBtn').attr('disabled', 'false')
            })
        }
    }

    return (
        <div className='col-12'>
            <form onSubmit={(e) => handleClickFinish(e)}>
                {survey.map((data, index) => (
                    <div className='col-12 mb-2' key={`key-question-${index}`} id={`div-question-${data.idQuestionQuiz}`}>
                        <div className='form-group'>
                            <label className="form-label font-weight-bold">{index + 1 + ') '}{data.description}<span style={{color:"red"}}>{data.obligatory === 1 && ' *'}</span></label>
                            {
                                data.idTypeQuestion === 1 ? (
                                    <Fragment>
                                        <textarea
                                            className="form-control"
                                            rows="2"
                                            id={'answer_' + data.idOrder}
                                            name={'answer_' + data.idOrder}
                                            onChange={e => {
                                                // handleChangeInput(data, e);
                                                counterTxt(e,data.idQuestionQuiz);
                                                // if (e.target.value.trim() !== '') {
                                                //     setCounters({
                                                //         ...counters,
                                                //         [e.target.name]: e.target.value
                                                //     });
                                                //     handleChangeInput(data, e);
                                                // } else {
                                                //     e.preventDefault();
                                                // }
                                            }}
                                            onBlur={(e) => {
                                                handleChangeInput(data, e);
                                            }}
                                            style={{ resize: 'none' }}
                                            maxLength='400'
                                            // required={data.obligatory === 1 ? true : false}
                                            defaultValue={surveyValues.length > 0 ? surveyValues.filter(item => item.idQuestionQuiz === data.idQuestionQuiz).length > 0 ? surveyValues.filter(item => item.idQuestionQuiz === data.idQuestionQuiz)[0].answerQuiz : "":""}
                                        ></textarea>
                                        <span id={`question-${data.idQuestionQuiz}-counter`}></span>
                                    </Fragment>
                                ) : (
                                    data.idTypeQuestion === 2 || data.idTypeQuestion === 3 ? (
                                        <div className='row'>
                                            {
                                                data.answers.map((answer, index) => (
                                                    <div
                                                        className={data.idTypeQuestion === 2 && answer.description.length < 10 ? 'col-sm-12 col-md-3 col-lg-3' : 'col-sm-12 col-md-6 col-lg-6'}
                                                        key={'answer-' + data.idOrder + '-option-' + index}
                                                    >
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input pointer"
                                                                id={'answer-' + data.idOrder + '-option-' + index}
                                                                name={'answer-' + data.idOrder}
                                                                value={answer.idAnswerQuiz}
                                                                onChange={(e) => handleChangeInput(data, e)}
                                                                // required={data.obligatory === 1 ? true : false}
                                                                checked={surveyValues.length > 0 && surveyValues.some(item => item.idQuestionQuiz === answer.idQuestionQuiz && parseInt(item.answerQuiz) === answer.idAnswerQuiz)}
                                                            />
                                                            <label
                                                                className="form-check-label pointer"
                                                                htmlFor={'answer-' + data.idOrder + '-option-' + index}
                                                            >{handleOptionsLetter(answer.description, index)}</label>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        data.idTypeQuestion === 4 ? (
                                            <div className='row'>
                                                {
                                                    data.answers.map((answer, index) => (
                                                        <div
                                                            className='col-sm-12 col-md-6 col-lg-6'
                                                            key={'answer-' + data.idOrder + '-option-' + index}
                                                        >
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input pointer"
                                                                    id={'answer-' + data.idOrder + '-option-' + index}
                                                                    name={'answer-' + data.idOrder + '-option-' + index}
                                                                    value={answer.idAnswerQuiz}
                                                                    onChange={(e) => handleChangeInput(data, e)}
                                                                    // required={data.obligatory === 1 ? true : false}
                                                                    checked={surveyValues.length > 0 && surveyValues.filter(item => item.idQuestionQuiz === answer.idQuestionQuiz).length > 0 ? surveyValues.filter(item => item.idQuestionQuiz === answer.idQuestionQuiz)[0].answerQuiz.split(",").some(item => parseInt(item) === answer.idAnswerQuiz) : false}
                                                                />
                                                                <label
                                                                    className="form-check-label pointer"
                                                                    htmlFor={'answer-' + data.idOrder + '-option-' + index}
                                                                >{handleOptionsLetter(answer.description, index)}</label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            data.idTypeQuestion === 5 ? (
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id={'answer-' + data.idOrder}
                                                    name={'answer-' + data.idOrder}
                                                    onChange={(e) => handleChangeInput(data, e)}
                                                    // required={data.obligatory === 1 ? true : false}
                                                    value={surveyValues.length > 0 ? surveyValues.filter(item => item.idQuestionQuiz === data.idQuestionQuiz).length > 0 ? surveyValues.filter(item => item.idQuestionQuiz === data.idQuestionQuiz)[0].answerQuiz : "" : ""}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                />
                                            ) : (
                                                null
                                            )
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
                ))
                }
                <div className='d-flex justify-content-center'>
                    <button
                        type='submit'
                        className='btn btn-sm bg-pink-iam text-white pointer'
                        id='submitBtn'
                    >
                        Finalizar
                    </button>
                </div>
            </form>
        </div>
    );
};

Questions.propTypes = {
    survey: PropTypes.array.isRequired,
};

export default Questions;
