import axios from "axios";
// Model From MailGun

export const getGraphics = async (Identerprise,groupId,idbranch) => {
    try{ 
        let result = await axios({
            url: global.base_url + 'firebase/monitoryng?enterpriseId=' + Identerprise+'&groupId='+groupId+'&branch='+idbranch,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return result.data ?  result.data : 0; 

    } catch (error) {
        console.log(error);
    }
};

export const getGroup = async (Identerprise) => {
    try{ 
        let result = await axios({
            url: global.base_url + 'firebase/groupEnterprises?enterpriseId=' + Identerprise,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return result.data ?  result.data : 0; 

    } catch (error) {
        console.log(error);
    }
};

export const getBranch = async (Identerprise) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getBranches?enterpriseId=" + Identerprise,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
export const getLead = async () => {
    try {
        let result = await axios({
            url: 'https://fb96-189-217-105-246.ngrok.io/leads/data',
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
           
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const downData = async (Identerprise,groupId,idbranch) => {
    try {
        let result = await axios({
            url: global.base_url + 'firebase/getFileReport?enterpriseId='+Identerprise+'&groupId='+groupId+'&branch='+idbranch,
            method: 'GET',           
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const sendMailNotActive = async (Identerprise,groupId,idbranch) => {
    try{ 
        let result = await axios({
            url: global.base_url + 'firebase/sendMailNotActive?enterpriseId=' + Identerprise+'&groupId='+groupId+'&branch='+idbranch,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return result.data ?  result.data : 0; 

    } catch (error) {
        console.log(error);
    }
};



