import React, { useState, useEffect } from 'react'

const SeguroAccidentesSencillo = () => {
    const [urlImg, setUrlImg] = useState('')
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            // platform = "Mobile";
            setUrlImg("./../img/beneficios/BANNER_plan_seguro_sencillo_mobile.png")
        } else {
            setUrlImg("./../img/beneficios/BANNER_plan_seguro_sencillo.png")
        }
      
    }, [])
  return (
        <div className='container-fluid'>
            <div className='row bg-blue-navy'>
                <img className="img-responsive" src={urlImg} alt="img-dentista-1" />

                {/* <div className='col-12'>
                    <div className='row'>
                        <div className='col-5 col-md-3 d-flex align-items-end p-0'>
                            <div>
                                <img
                                    src='./../img/beneficios/imagen_seguro_de_accidentes.png'
                                    className='img-fluid mt-0 mt-md-2'
                                    alt='img seguro'
                                />
                            </div>
                        </div>
                        <div className='col-7 col-md-9 d-flex align-items-center'>
                            <div className='w-100'>
                                <div className='row'>
                                    <div className='col-12 px-1 px-md-4'>
                                        <h1 className='text-white fs-1-125 text-center mt-2 mt-md-0'>Seguro de Accidentes Personales</h1>
                                        <hr className='hr-white m-0 p-0 mb-md-1 pb-md-1' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 px-1 px-md-4'>
                                        <h2 className='text-white text-justify fs-1'>Protección de forma integral al titular asegurado en caso
                                            de sufrir un accidente por acción súbita, fortuita y violenta
                                            de una fuerza externa, ocasionando lesión, pérdida
                                            orgánica, invalidez o muerte.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='row my-3'>

                <div className='col-12 col-md-6 d-flex align-items-center mb-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Cobertura por muerte accidental $50,000.00 mxp</h2>
                </div>

                <div className='col-12 col-md-6 d-flex align-items-center mb-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Asistencia Médica telefónica ilimitada</h2>
                </div>

                <div className='col-12 col-md-6 d-flex align-items-center'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Orientación Médica Ilimitada en Centro de atención telefónica con médicos las 24 hrs,
                        los 365 días del año</h2>
                </div>

            </div>
            
        </div>
  )
}

export default SeguroAccidentesSencillo
