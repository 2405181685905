import React, { useState, useEffect } from 'react';
import Header from '../../NewHome/headerAdministrador';
import Footer from '../../footerApp';
import Menu from "../../Menu";
import $ from "jquery";
import { getJwt } from "../../../../lib/auth";
import { setEnterprise, getDatagroupById, relEntGroup, updateEnterprise, getEnterpriseById } from "../Model";
import { getSectors, getSubSectors, getAddressByCP, getCountries } from "../../Models/Catalog_model";
import { validateYear, validateCollab, remaining_character, validateYearVal, validateCollabVal } from "../../../../lib/utils/generalFuntions";
import { validateFile } from "../../../../lib/utils/validateFile";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import RegisterCommunication from '../RegisterCommunication';
import RegisterCommunication2 from '../RegisterCommunication2';
import RegisterComplainChannel from '../RegisterComplainChannel';
import { setCommunicationConfig, setComplaintConfig, sendConfigTest, getComplaintConfig } from '../../Models/Enterprise_model';
import ModalLoader from '../../ModalLoader';
import GenDataAddress from '../GenDataAddress';


const RegisterEnterpriseGroup = props => {

    const [stepNum, setStep] = useState(1);
    const [enterprise, upEnterprise] = useState({
        user: getJwt("jtw"),
        comercial: "",
        razon: "",
        short: "",
        rfc: "",
        countryFiscal: "",
        fiscalRegimenId: "",
        email: "",
        sector: "",
        subSector: "",
        country: "",
        anio: "",
        colaboradores: "",
        state: 0,
        town: 0,
        neibg: 0,
        cp: 0,
        street: "",
        numExt: "",
        numInt: "",
        complete: 0,
        mision: "",
        vision: "",
        objetivos: "",
        valores: "",
    });
    const [subSectors, upSubSectors] = useState([]);
    const [sectors, upSectors] = useState([]);
    const [countries, upCountries] = useState([]);
    const [files, upFiles] = useState([]);
    // eslint-disable-next-line
    const [relGroup, upRelGroup] = useState({
        action: 1,
        idGroupEnt: 0,
        idEnterprise: 0
    })
    const [enterpriseGroup, upEnterprisesGroup] = useState({});
    const [subSectionOne, setSubSectionOne] = useState(0);
    const [sectionOne, setSectionOne] = useState(true);
    const {
        comercial,
        short,
        email,
        sector,
        subSector,
        country,
        anio,
        colaboradores
    } = enterprise;
    const [communicationData, setCommunicationData] = useState({
        idUser: getJwt("jtw"),
        designFlg: 1,
        logoFlg: 2,
        presentationMsg: ""
    });
    const [channelData, setChannelData] = useState({
        idUser: getJwt("jtw"),
        complaintFlg: 1,
        presentationMsg: "",
        url: ""
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [visitedSection, setVisitedSection] = useState(0);
    const [showFiles, setShowFiles] = useState([]);
    const [idEnterprise, setIdEnterprise] = useState(0);
    const [dataAddress, setDataAddress] = useState({
        stateResidence: 0,
        townResidence: 0,
        neibgResidence: 0,
        cpResidence: 0,
        streetResidence: "",
        numExtResidence: "",
        numIntResidence: "",
        countryResidence: ""
    });
    const [showFiles_2, setShowFiles_2] = useState([]);
    useEffect(() => {
        const getObject = props.match.params;
        let object = getObject.db.split("=");
        var id = object[1];
        relGroup.idGroupEnt = id;

        getSectors().then((respSectors) => {
            upSectors([
                ...sectors,
                ...respSectors
            ]);
        });
        getCountries().then((respCountries) => {
            upCountries([
                ...countries,
                ...respCountries
            ]);
        });
        getDatagroupById(id).then((result) => {
            upEnterprisesGroup(result)
        });
        // eslint-disable-next-line
    }, []);

    const getValue = (e) => {
        $("#" + e.target.name).removeClass("border-success");
        let value = e.target.value.replace(/[ ]{2,}/, ' ');

        if (e.target.getAttribute("add-count") === 1) {
            let inpID = e.target.id;
            let maxLength = e.target.getAttribute("data-max-length");
            let eCount = e.target.getAttribute("element-name-character-count");
            remaining_character(value.length, inpID, maxLength, eCount);
        }
        switch (e.target.name) {
            case "sector":
                if (value !== "0") {
                    $("#" + e.target.name).addClass("border-success");
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#subSector").html("");
                    $("#subSector").append('<option value="0">--Seleccione--</option>');
                    getSubSectors(value).then((respSubSectors) => {
                        upSubSectors([
                            ...subSectors,
                            ...respSubSectors
                        ]);
                        upEnterprise({
                            ...enterprise,
                            sector: value,
                            complete: 1
                        });
                    });
                }
                break;
            case "cp":
                if (value.length + 0 >= 5) {
                    $("#cp").addClass("border-success");
                    $("#cp").removeClass("border-danger-guay");
                    getAddressByCP(value).then((respAddress) => {
                        if (respAddress.length > 0) {
                            $("#state, #town, #neibg").html("")
                            $("#state").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                            $("#town").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                            $("#town").addClass("border-success");
                            $("#town").removeClass("border-danger-guay");
                            $("#state").addClass("border-success");
                            $("#state").removeClass("border-danger-guay");
                            $("#neibg").addClass("border-success");
                            $("#neibg").removeClass("border-danger-guay");
                            $.each(respAddress, function (i, item) {
                                $("#neibg").append('<option value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                $("#neibg").attr("disabled", false);
                            });
                            upEnterprise({
                                ...enterprise,
                                cp: value,
                                state: respAddress[0].idState,
                                town: respAddress[0].idTown,
                                neibg: respAddress[0].idNeighborhood,
                                street: $("#street").val(),
                                complete: 3
                            });
                        } else {
                            toastr.warning("El código postal registrado no existe. Intenta nuevamente");
                        }
                    });
                }
                break;

            case "anio":
                validateYear("anio");
                upEnterprise({
                    ...enterprise,
                    [e.target.name]: value,
                    complete: setComplete(),
                });
                break;
            case "colaboradores":
                validateCollab(e.target.name);
                upEnterprise({
                    ...enterprise,
                    [e.target.name]: value

                });
                break;
            case "email":
                let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                if (regex.test(value)) {
                    $("#email").removeClass("border-danger-guay");
                    $("#" + e.target.name).addClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value
                    });
                } else {
                    $("#email").addClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value
                    });
                }
                break;
            case "rfc":
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).addClass("border-success");
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: "",
                        complete: setComplete(),
                    });
                } else if (e.target.value.length < 12 || e.target.value.length > 13) {
                    $("#" + e.target.name).addClass("border-danger-guay");
                    $("#" + e.target.name).removeClass("border-success");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value,
                        complete: setComplete(),
                    });
                } else {
                    $("#" + e.target.name).addClass("border-success");
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value,
                        complete: setComplete(),
                    });
                }
                break;
            default:
                if (value === "0" || e.target.value.length === 0) {
                    $("#" + e.target.name).removeClass("border-success");
                    $("#" + e.target.name).addClass("border-danger-guay");
                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: "",
                        complete: setComplete(),
                    });
                } else {
                    $("#" + e.target.name).removeClass("border-danger-guay");
                    $("#fiscalRegimenLabel").removeClass("text-base-guay");
                    $("#" + e.target.name).addClass("border-success");

                    upEnterprise({
                        ...enterprise,
                        [e.target.name]: value,
                        complete: setComplete(),
                    });
                }
                break;
        }
    }

    const renderFilePers = () => {
        $("#file").click();
    }

    const setComplete = () => {
        if (stepNum === 1) {
            return 1;
        }

        if (stepNum === 2) {
            return 2;
        }
        if (stepNum === 3) {
            if (enterprise.rfc.length && enterprise.cp && enterprise.neibg && enterprise.street.length && enterprise.numExt.length
                && enterprise.countryFiscal.length && enterprise.fiscalRegimenId.length && enterprise.neibg !== 0) {
                return 0;
            } else {
                return 3;
            }
        }
    }

    const onChangeHandler = (e) => {
        try {
            let file = e.target.files[0];
            validateFile(file).then((res) => {
                if (res.success === 1) {
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        $('#img-person').attr('src', e.target.result);
                    };
                    reader.readAsDataURL(file);
                    upFiles(file);

                } else {
                    toastr.error("" + res.msg + "");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = () => {
        try {

            let answered = false;
            let form_1 = 0;
            // eslint-disable-next-line
            let form_2 = 0;
            // eslint-disable-next-line
            let form_3 = 0;

            switch (stepNum) {
                case 1:
                    if (enterprise.comercial.length === 0) {
                        $("#comercial").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Nombre Comercial', '¡Espera!');
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.short.length === 0) {
                        $("#short").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Nombre Corto', '¡Espera!');
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.sector.length === 0) {
                        $("#sector").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Sector o Industria', '¡Espera!');
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.email.length === 0) {
                        $("#email").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Correo organizacional', '¡Espera!');
                    } else {
                        let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                        if (regex.test(enterprise.email)) {
                            $("#email").removeClass("border-danger-guay");
                            form_1 = form_1 + 1;
                        } else {
                            $("#email").addClass("border-danger-guay");
                            toastr.error('Formato de Correo organizacional incorrecto.', '¡Espera!');
                        }
                    }
                    if (enterprise.subSector.length === 0) {
                        $("#subSector").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Subsector', '¡Espera!');
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.country.length === 0) {
                        $("#country").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: País de Origen', '¡Espera!');
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (enterprise.anio.length === 0) {
                        $("#anio").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Año de fundación', '¡Espera!');
                    } else {

                        if (enterprise.anio.length === 4) {
                            form_1 = form_1 + 1;
                        } else {
                            $("#anio").addClass("border-danger-guay");
                            toastr.error('El campo Año de fundación debe ser un año valido (4 digitos).', '¡Espera!');
                        }
                    }
                    if (enterprise.colaboradores.length === 0) {
                        $("#colaboradores").addClass("border-danger-guay");
                        toastr.error('Falta llenar el campo: Número de Colaboradores', '¡Espera!');
                    } else {
                        if (enterprise.colaboradores > 0) {
                            form_1 = form_1 + 1;
                        } else {
                            $("#colaboradores").addClass("border-danger-guay");
                            toastr.error('El campo Número de Colaboradores debe ser por lo menos 1.', '¡Espera!');
                        }
                    }
                    if (enterprise.rfc.length > 0 && (enterprise.rfc.length > 13 || enterprise.rfc.length < 12)) {
                        $("#rfc").addClass("border-danger-guay");
                        toastr.error('El campo: RFC tiene que tener como mínimo 12 caracteres y máximo 13.', '¡Espera!');
                    } else {
                        form_1 = form_1 + 1;
                    }
                    if (!validateYearVal(enterprise.anio)) {
                        toastr.error('El campo Año de fundación debe ser un año valido. Datos Generales', '¡Espera!');
                        return false
                    }
                    if (!validateCollabVal(enterprise.colaboradores)) {
                        toastr.error('El valor para el número de colaboradores no es válido.', '¡Espera!');
                        return false
                    }
                    if (form_1 === 9) {
                        $("#anio").removeClass("border-success");
                        return answered = true;
                    }
                    break;
                case 2:
                    /*  if (enterprise.mision.length === 0) {
                          $("#mision").addClass("border-danger-guay");
                          toastr.error('Falta llenar el campo: Misión', '¡Espera!');
                      } else {
                          form_2 = form_2 + 1;
                      }
                      if (enterprise.vision.length === 0) {
                          $("#vision").addClass("border-danger-guay");
                          toastr.error('Falta llenar el campo: Visión', '¡Espera!');
                      } else {
                          form_2 = form_2 + 1;
                      }
                      if (enterprise.valores.length === 0) {
                          $("#valores").addClass("border-danger-guay");
                          toastr.error('Falta llenar el campo: Valores', '¡Espera!');
                      } else {
                          form_2 = form_2 + 1;
                      }
                      if (enterprise.objetivos.length === 0) {
                          $("#objetivos").addClass("border-danger-guay");
                          toastr.error('Falta llenar el campo: Objetivos', '¡Espera!');
                      } else {
                          form_2 = form_2 + 1;
                      }*/
                    //if (form_2 === 4) {
                    return answered = true;
                //}
                //  break;
                /*   case 3:
                       if (!validateYearVal(enterprise.anio)) {
                           toastr.error('El campo Año de fundación debe ser un año valido. Datos Generales', '¡Espera!');
                           return false
                        } 
                       if(!validateCollabVal(enterprise.colaboradores)){
                               toastr.error('El valor para el número de colaboradores no es válido. Datos Generales', '¡Espera!');
                               return false
                       }    
                       if (enterprise.rfc.length === 0) {
                           $("#rfc").addClass("border-danger-guay");
                           toastr.error('Falta llenar el campo: RFC', '¡Espera!');
                       } else {
                           if (enterprise.rfc.length <= 13) {
                               form_3 = form_3 + 1;
                           } else {
                               $("#rfc").addClass("border-danger-guay");
                               toastr.error('El campo RFC debe ser un dato valido', '¡Espera!');
                           }
                       }
                       if (enterprise.cp === 0) {
                           $("#cp").addClass("border-danger-guay");
                           toastr.error('Falta llenar el campo: Código Postal', '¡Espera!');
                       } else {
                           form_3 = form_3 + 1;
                       }
                       if (enterprise.neibg === null) {
                           $("#neibg").addClass("border-danger-guay");
                           toastr.error('Falta llenar el campo: Colonia', '¡Espera!');
                       } else {
                           form_3 = form_3 + 1;
                       }
                       if (enterprise.street.length === 0) {
                           $("#street").addClass("border-danger-guay");
                           toastr.error('Falta llenar el campo: Calle', '¡Espera!');
                       } else {
                           form_3 = form_3 + 1;
                       }
                       if (enterprise.numExt.length === 0) {
                           $("#numExt").addClass("border-danger-guay");
                           toastr.error('Falta llenar el campo: Núm. exterior', '¡Espera!');
                       } else {
                           form_3 = form_3 + 1;
                       }
                       if (enterprise.countryFiscal.length === 0) {
                           $("#countryFiscal").addClass("border-danger-guay");
                           toastr.error('Falta llenar el campo: País', '¡Espera!');
                       } else {
                           form_3 = form_3 + 1;
                       }
                       if (enterprise.fiscalRegimenId.length === 0) {
                           $("#fiscalRegimenLabel").addClass("text-base-guay");
                           toastr.error('Falta llenar el campo: Régimen fiscal', '¡Espera!');
                       } else {
                           form_3 = form_3 + 1;
                       }
                       if (form_3 === 7) {
                           return answered = true;
                       }
                       break;*/
                default:
                    if (answered === false) {
                        return answered = true;
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    // const handleOnClickNext = () => {
    //     try {
    //         let validation = validateForm();
    //         if (validation === true) {
    //             let nextStep = stepNum + 1;
    //             setStep(nextStep);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // eslint-disable-next-line
    const saveRegister = () => {
        try {
            let validation = validateForm();
            if (validation === true) {
                if (stepNum === 1) {
                    if (enterprise.comercial.length === 0) {
                        $("#comercial").addClass("border-danger-guay");
                        $("#short").removeClass("border-danger-guay");
                        $("#sector").removeClass("border-danger-guay");
                        $("#subSector").removeClass("border-danger-guay");
                    } else {
                        let formData = new FormData();
                        formData.append("file", files.file);
                        formData.append("enterprise", JSON.stringify(enterprise));
                        setEnterprise(formData).then((res) => {
                            if (res.success === 1) {
                                console.log(res)
                                //  window.location = "/inicio-empresas";
                                toastr.success("Se registró la organización correctamente", "¡Exito!");
                            } else {
                                toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                            }
                        })
                    }
                }
                if (stepNum === 2) {
                    let formData = new FormData();
                    formData.append("file", files.file);
                    formData.append("enterprise", JSON.stringify(enterprise));
                    setEnterprise(formData).then((res) => {
                        console.log(res)
                        if (res.success === 1) {
                            //window.location = "/inicio-empresas";
                            toastr.success("Se registró la organización correctamente", "¡Exito!");
                        } else {
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    })
                }
                if (stepNum === 3) {
                    let enterpriseData = {
                        ...enterprise,
                        complete: setComplete(),
                    }
                    let formData = new FormData();
                    formData.append("file", files.file);
                    formData.append("enterprise", JSON.stringify(enterpriseData));
                    setEnterprise(formData).then((res) => {
                        if (res.success === 1) {
                            console.log(res)
                            // window.location = "/inicio-empresas";
                            toastr.success("Se registró la organización correctamente", "¡Exito!");
                        } else {
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setNewEnterprise = () => {
        try {
            let validation = validateForm();
            if (validation === true) {
                $("#saveBtn").addClass("disabled").text("Procesando");
                let formData = new FormData();
                /* formData.append("file", files.file);
                formData.append("enterprise", JSON.stringify(enterprise)); */
                let data = {
                    enterpriseId: idEnterprise.toString(),
                    ...dataAddress,
                    ...enterprise
                };
                formData.append("file", files);
                formData.append("enterprise", JSON.stringify(data));
                updateEnterprise(formData).then((res) => {
                    if (res.success === 1) {
                        window.location = "/grupo-empresas/" + hashString("guay.digital") + "="
                            + enterpriseGroup.groupId + "=" + hashString(enterpriseGroup.comercialName)
                        /* console.log(res)
                        relGroup.idEnterprise = res.enterprise;
                        setRelGroup(); */
                        // window.location = "/inicio-empresas";
                        //  toastr.success("Se registró la organización correctamente", "¡Exito!");
                    } else {
                        toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        $("#saveBtn").removeClass("disabled").text("Terminar");
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    }


    /* const setRelGroup = () => {
        try {
            let formData = new FormData();
            
                formData.append("request", JSON.stringify(relGroup));
                relEntGroup(formData).then((response) => {
                    if (!response.code) {
                        toastr.error("Ocurrió un problema al agregar la organización al Grupo. Intente Nuevamente", "¡Espera!");
                    }else{
                        window.location = "/grupo-empresas/"+ hashString("guay.digital")+ "=" 
                       + enterpriseGroup.groupId +"=" + hashString(enterpriseGroup.comercialName)
                    }

                });
           

        } catch (error) {
            console.log(error);
        }



    }; */

    const setRelGroupNew = (idEnterprise) => {
        try {
            let formData = new FormData();
            let data = {
                action: 1,
                idGroupEnt: relGroup.idGroupEnt,
                idEnterprise: idEnterprise
            };
            formData.append("request", JSON.stringify(data));
            relEntGroup(formData).then((response) => {
                if (!response.code) {
                    toastr.error("Ocurrió un problema al agregar la organización al Grupo. Intente Nuevamente", "¡Espera!");
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    /* const hashString = (string) => {
        try {
            //let r = Math.random().toString(36).substring(7);
            var enc = window.btoa(string);
            return enc;
        } catch (error) {
            console.log(error);
        }
    } */

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }

    const setNewEnterpriseSubSection = (section) => {
        try {
            let validation = validateForm();
            if (validation === true) {
                if (visitedSection > subSectionOne) {
                    let formData = new FormData();
                    let data = {
                        enterpriseId: sessionStorage.getItem("newIDEnterprise"),
                        ...enterprise
                    };
                    if ((dataAddress.cpResidence !== 0 && dataAddress.stateResidence === 0) || (dataAddress.cpResidence.length > 0 && dataAddress.stateResidence === 0)) {
                        toastr.error('Ingresa un Código postal valido.', '¡Ooops!');
                    } else {
                        let countryResidence = dataAddress.countryResidence.toString();
                        let data_2 = {
                            ...data,
                            ...dataAddress,
                            countryResidence: countryResidence
                        }
                        formData.append("file", files);
                        formData.append("enterprise", JSON.stringify(data_2));
                        updateEnterprise(formData).then((res) => {
                            if (res.success === 1) {
                                setSubSectionOne(section);
                            } else {
                                toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                            }
                        });
                    }
                    /* formData.append("file", files);
                    formData.append("enterprise", JSON.stringify(data));
                    updateEnterprise(formData).then((res) => {
                        if (res.success === 1) {
                            setSubSectionOne(section);
                        } else {
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    }); */
                } else {
                    let formData = new FormData();
                    if ((dataAddress.cpResidence !== 0 && dataAddress.stateResidence === 0) || (dataAddress.cpResidence.length > 0 && dataAddress.stateResidence === 0)) {
                        toastr.error('Ingres un Código postal valido.', '¡Ooops!');
                    } else {
                        let countryResidence = dataAddress.countryResidence.toString();
                        let data_2 = {
                            ...enterprise,
                            ...dataAddress,
                            countryResidence: countryResidence
                        }
                        formData.append("file", files);
                        formData.append("enterprise", JSON.stringify(data_2));
                        setEnterprise(formData).then((res) => {
                            if (res.success === 1) {
                                setRelGroupNew(res.enterprise);
                                setSubSectionOne(section);
                                setVisitedSection(section);
                                sessionStorage.setItem('newIDEnterprise', res.enterprise);
                                setIdEnterprise(res.enterprise);
                            } else {
                                toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                            }
                        });
                    }
                    /* formData.append("file", files);
                    formData.append("enterprise", JSON.stringify(enterprise));
                    setEnterprise(formData).then((res) => {
                        if (res.success === 1) {
                            setRelGroupNew(res.enterprise);
                            setSubSectionOne(section);
                            setVisitedSection(section);
                            sessionStorage.setItem('newIDEnterprise', res.enterprise);
                            setIdEnterprise(res.enterprise);
                        } else {
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    }); */
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * 
     * @param {*} section 
     */
    const setNewCommunication = (section) => {
        try {
            const {
                idUser,
                designFlg,
                logoFlg,
                presentationMsg
            } = communicationData;
            setCommunicationConfig(sessionStorage.getItem('newIDEnterprise'), idUser, designFlg, logoFlg, presentationMsg).then((response) => {
                if (response.code === 1) {
                    setSubSectionOne(section);
                    setVisitedSection(section);
                } else {
                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * 
     * @param {*} section 
     */
    const setNewComplaintChannel = (section) => {
        try {
            const {
                idUser,
                complaintFlg,
                presentationMsg,
                url
            } = channelData;
            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, selectedFiles).then((response) => {
                if (response.code === 1) {
                    sessionStorage.removeItem('newIDEnterprise');
                    setVisitedSection(section);
                    setSectionOne(false);
                    setStep(2);
                } else {
                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeSubSection = e => {
        e.preventDefault();
        let id = e.target.id;
        if (id === "nextBtn") {
            let next = subSectionOne + 1;
            if (subSectionOne === 0) {
                setNewEnterpriseSubSection(next);
            }
            if (subSectionOne === 1) {
                setSubSectionOne(next);
            }
            if (subSectionOne === 2) {
                setNewCommunication(next);
            }
            if (subSectionOne === 3) {
                setNewComplaintChannel();
            }
        } else {
            let back = subSectionOne - 1;
            if (back === 0) {
                getEnterpriseById(sessionStorage.getItem('newIDEnterprise')).then((dataEnterprise) => {
                    setIdEnterprise(dataEnterprise.enterpriseId)
                    if (dataEnterprise.listInfoZip.length > 0) {
                        getAddressByCP(dataEnterprise.listInfoZip[0].zip).then((respAddress) => {
                            if (respAddress.length > 0) {
                                $("#stateResidence, #townResidence, #neibgResidence").html("");
                                $("#stateResidence").append('<option value="' + respAddress[0].idState + '">' + respAddress[0].nameState + '</option>');
                                $("#townResidence").append('<option value="' + respAddress[0].idTown + '">' + respAddress[0].nameTown + '</option>');
                                $("#townResidence").addClass("border-success");
                                $("#townResidence").removeClass("border-danger-guay");
                                $("#stateResidence").addClass("border-success");
                                $("#stateResidence").removeClass("border-danger-guay");
                                $("#neibgResidence").addClass("border-success");
                                $("#neibgResidence").removeClass("border-danger-guay");
                                $.each(respAddress, function (i, item) {
                                    $("#neibgResidence").append('<option key="n' + respAddress[i].idNeighborhood + '" value="' + respAddress[i].idNeighborhood + '">' + respAddress[i].nameNeighborhood + '</option>');
                                    $("#neibgResidence").attr("disabled", false);
                                });
                                //console.log(dataEnterprise.listInfoZip[0].street, dataEnterprise.listInfoZip[0].numExt, dataEnterprise.listInfoZip[0].numInt)
                                setDataAddress({
                                    stateResidence: respAddress[0].idState,
                                    townResidence: respAddress[0].idTown,
                                    neibgResidence: respAddress[0].idNeighborhood,
                                    cpResidence: respAddress[0].zip,
                                    streetResidence: dataEnterprise.listInfoZip[0].street === 'null' || dataEnterprise.listInfoZip[0].street === null ? '' : dataEnterprise.listInfoZip[0].street,
                                    numExtResidence: dataEnterprise.listInfoZip[0].numExt === 'null' || dataEnterprise.listInfoZip[0].numExt === null ? '' : dataEnterprise.listInfoZip[0].numExt,
                                    numIntResidence: dataEnterprise.listInfoZip[0].numInt === 'null' || dataEnterprise.listInfoZip[0].numInt === null ? '' : dataEnterprise.listInfoZip[0].numInt,
                                    countryResidence: dataEnterprise.country
                                });
                            } else {

                            }
                        });
                    } else {
                        setDataAddress({
                            stateResidence: 0,
                            townResidence: 0,
                            neibgResidence: 0,
                            cpResidence: 0,
                            streetResidence: "",
                            numExtResidence: "",
                            numIntResidence: "",
                            countryResidence: dataEnterprise.country
                        });
                    }
                });
            }
            setSubSectionOne(back);
        }
    }

    const testMessage = () => {
        const {
            idUser,
            designFlg,
            logoFlg,
            presentationMsg
        } = communicationData;
        setCommunicationConfig(sessionStorage.getItem('newIDEnterprise'), idUser, designFlg, logoFlg, presentationMsg).then((response) => {
            if (response.code === 1) {
                sendConfigTest(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                toastr.success('Se envió correctamente la prueba.', '¡Éxito!');
                                break;
                            case 200:
                                toastr.success('Se envió correctamente la prueba.', '¡Éxito!');
                                break;
                            case 1003:
                                toastr.warning('Excediste las pruebas. Intenta mañana.', '¡Ooops!');
                                break;
                            default:
                                toastr.error('Hubo un problema. No se envió la prueba.', '¡Ooops!');
                                break;
                        }
                    }
                });
            } else {
                toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
            }
        });
    }

    const uploadFiles = (arrayFiles, type) => {
        try {
            const {
                idUser,
                complaintFlg,
                presentationMsg,
                url
            } = channelData;
            $("#modalLoader").show();
            if (type === 1) {
                setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                    if (response.code === 1) {
                        getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                            setShowFiles_2(response.listUploadedDoc);
                            $("#modalLoader").hide();
                        });
                    } else {
                        toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        $("#modalLoader").hide();
                    }
                });
            } else {
                if (arrayFiles.length === 5) {
                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[2]).then((response) => {
                                        if (response.code === 1) {
                                            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[3]).then((response) => {
                                                if (response.code === 1) {
                                                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[4]).then((response) => {
                                                        if (response.code === 1) {
                                                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                                setShowFiles(response.configImgComplaint);
                                                                setSelectedFiles([]);
                                                            });
                                                            $("#modalLoader").hide();
                                                        } else {
                                                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                                setShowFiles(response.configImgComplaint);
                                                                setSelectedFiles([]);
                                                            });
                                                            $("#modalLoader").hide();
                                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                                        }
                                                    });
                                                } else {
                                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                        setShowFiles(response.configImgComplaint);
                                                        setSelectedFiles([]);
                                                    });
                                                    $("#modalLoader").hide();
                                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                                }
                                            });
                                        } else {
                                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                        }
                                    });
                                } else {
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 4) {
                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[2]).then((response) => {
                                        if (response.code === 1) {
                                            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[3]).then((response) => {
                                                if (response.code === 1) {
                                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                        setShowFiles(response.configImgComplaint);
                                                        setSelectedFiles([]);
                                                    });
                                                    $("#modalLoader").hide();
                                                } else {
                                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                        setShowFiles(response.configImgComplaint);
                                                        setSelectedFiles([]);
                                                    });
                                                    $("#modalLoader").hide();
                                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                                }
                                            });
                                        } else {
                                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                        }
                                    });
                                } else {
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 3) {
                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[2]).then((response) => {
                                        if (response.code === 1) {
                                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                        } else {
                                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                                setShowFiles(response.configImgComplaint);
                                                setSelectedFiles([]);
                                            });
                                            $("#modalLoader").hide();
                                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                        }
                                    });
                                } else {
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 2) {
                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[1]).then((response) => {
                                if (response.code === 1) {
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                } else {
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        setSelectedFiles([]);
                                    });
                                    $("#modalLoader").hide();
                                    toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                                }
                            });
                        } else {
                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
                if (arrayFiles.length === 1) {
                    setComplaintConfig(sessionStorage.getItem('newIDEnterprise'), idUser, complaintFlg, presentationMsg, url, arrayFiles[0]).then((response) => {
                        if (response.code === 1) {
                            getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                setShowFiles(response.configImgComplaint);
                                setSelectedFiles([]);
                            });
                            $("#modalLoader").hide();
                        } else {
                            $("#modalLoader").hide();
                            toastr.error('Hubo un problema. No se registró la organización correctamente.', '¡Ooops!');
                        }
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay">
                <Header />
                <Menu />
                <div className="content-wrapper bootomFooter">
                    <div className="content-header row">
                        <div className="col-md-6"></div>
                        <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                                        </li>
                                        <li className="breadcrumb-item font-medium-3">
                                            <a href={"/grupo-empresas/" + hashString("guay.digital") + "="
                                                + enterpriseGroup.groupId + "=" + hashString(enterpriseGroup.comercialName)}>
                                                {enterpriseGroup.comercialName}
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active font-medium-3">Alta Organización
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <h2 >
                                    <strong>{enterpriseGroup.comercialName}/Alta organización</strong></h2>
                            </div>

                        </div>

                        <div className="row mt-2">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mb-40">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-2">
                                        <div className="card">
                                            <ul className="list-group enterprise-list">
                                                <li className="list-group-item pointer" data-step="1">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            {
                                                                stepNum === 1 ?
                                                                    <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-07.png" alt="img-guay" />
                                                                    :
                                                                    <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-10.png" alt="img-guay" />
                                                            }
                                                        </div>
                                                        <div className="col-9">
                                                            <h5 className="list-group-item-heading mt-4p">Datos Generales</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item pointer" data-step="2" >
                                                    <div className="row">
                                                        <div className="col-3">
                                                            {
                                                                stepNum === 2 ?
                                                                    <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-09.png" alt="img-guay" />
                                                                    :
                                                                    <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-12.png" alt="img-guay" />
                                                            }
                                                        </div>
                                                        <div className="col-9">
                                                            <h5 className="list-group-item-heading mt-4p">Datos Específicos</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="list-group-item pointer" data-step="3" >
                                                    <div className="row">
                                                        <div className="col-3">
                                                            {
                                                                stepNum === 3 ?
                                                                    <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-08.png" alt="img-guay" />
                                                                    :
                                                                    <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-11.png" alt="img-guay" />
                                                            }
                                                        </div>
                                                        <div className="col-9">
                                                            <h5 className="list-group-item-heading mt-4p">Datos de Facturación</h5>
                                                        </div>
                                                    </div>
                                                </li> */}
                                            </ul>
                                            {/* <div className="">
                                        <div class="media-list list-group">
                                            <span className="list-group-item list-group-item-action media pointer">
                                                <div className="media-left">
                                                    {
                                                        stepNum === 1 ?
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-07.png" alt="img-guay" />
                                                            :

                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-10.png" alt="img-guay" />
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="list-group-item-heading mt-4p">Datos Generales</h5>
                                                </div>
                                            </span>
                                            <span className="list-group-item list-group-item-action media pointer">
                                                <div className="media-left">
                                                    {
                                                        stepNum === 2 ?
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-08.png" alt="img-guay" />
                                                            :
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-11.png" alt="img-guay" />
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="list-group-item-heading mt-4p">Datos Específicos</h5>
                                                </div>
                                            </span>
                                            <span className="list-group-item list-group-item-action media pointer">
                                                <div className="media-left">
                                                    {
                                                        stepNum === 3 ?
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-09.png" alt="img-guay" />
                                                            :
                                                            <img className="icon-img-50" src="./../img/altaEmpresas/iconos_shopy_guay-12.png" alt="img-guay" />
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="list-group-item-heading">Datos de Facturación</h5>
                                                </div>
                                            </span>
                                        </div>

                                    </div> */}
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                    <form autoComplete="off">
                                                        {
                                                            stepNum === 1 ?
                                                                subSectionOne === 0
                                                                    ?
                                                                    (
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                <div className="row">
                                                                                    <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2 pr-0">
                                                                                        <img
                                                                                            src="./../img/altaEmpresas/perfil_organizacion_iconos-05.png"
                                                                                            alt="header-section"
                                                                                            style={{ width: '75px', height: '75px' }} />
                                                                                    </div>
                                                                                    <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 pl-0 d-flex flex-wrap align-content-center">
                                                                                        <p className="font-weight-bold">Generales</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <h4 className="form-section"><i className="ft-user"></i> Ingrese la siguiente información</h4> */}
                                                                            <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">
                                                                                <div className="form-group mt-1">
                                                                                    <label >Nombre Comercial:</label>
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        id="comercial"
                                                                                        name="comercial"
                                                                                        maxLength="100"
                                                                                        minLength="2"
                                                                                        onChange={getValue}
                                                                                        value={comercial}
                                                                                    ></input>
                                                                                </div>
                                                                                <div className="form-group ">
                                                                                    <label >Nombre Corto:</label>
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        id="short"
                                                                                        name="short"
                                                                                        maxLength="60"
                                                                                        minLength="2"
                                                                                        onChange={getValue}
                                                                                        value={short}
                                                                                    ></input>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label className="d-flex flex-wrap align-content-center">Correo organizacional:
                                                                                        <Popup trigger={<i className="ft-info icon-guay rounded-circle mx-1" style={{ fontSize: '1.5rem' }} />} position="right">
                                                                                            <div className="p-1 text-center">
                                                                                                Este correo será el punto de contacto que tenga guay con la empresa, también ahí llegará los mensajes que se ingresen al Canal de denuncia en caso de utilizar el que guay te ofrece.
                                                                                            </div>
                                                                                        </Popup>
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="email"
                                                                                        id="email"
                                                                                        name="email"
                                                                                        maxLength="100"
                                                                                        onChange={getValue}
                                                                                        value={email}
                                                                                    ></input>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <label>Sector o Industria:</label>
                                                                                            <select
                                                                                                className="form-control form-control-sm"
                                                                                                name="sector"
                                                                                                id="sector"
                                                                                                onChange={getValue}
                                                                                                value={sector}>
                                                                                                <option value="0">--Seleccione--</option>
                                                                                                {sectors.map((optionSector, index) =>
                                                                                                    <option
                                                                                                        key={"optionSector-" + index + "--" + optionSector.sector_id}
                                                                                                        value={optionSector.sector_id}
                                                                                                    >
                                                                                                        {optionSector.description}
                                                                                                    </option>
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <label>Subsector:</label>
                                                                                            <select
                                                                                                className="form-control form-control-sm"
                                                                                                name="subSector"
                                                                                                id="subSector"
                                                                                                onChange={getValue}
                                                                                                value={subSector}
                                                                                            >
                                                                                                <option value="0">--Seleccione--</option>
                                                                                                {subSectors.map((optionSubSector, index) => (
                                                                                                    <option
                                                                                                        key={"optionSubSector-" + index + "--" + optionSubSector.sub_sector_id}
                                                                                                        value={optionSubSector.sub_sector_id}
                                                                                                    >
                                                                                                        {optionSubSector.description}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group mt-1 ">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <label>País de Origen:</label>
                                                                                            <select
                                                                                                className="form-control form-control-sm"
                                                                                                name="country"
                                                                                                id="country"
                                                                                                onChange={getValue}
                                                                                                value={country}
                                                                                            >
                                                                                                <option value="0">--Seleccione--</option>
                                                                                                {countries.map((optionCountries, index) => (
                                                                                                    <option
                                                                                                        key={"optionCountries-" + index + "--" + optionCountries.id}
                                                                                                        value={optionCountries.id}
                                                                                                    >
                                                                                                        {optionCountries.description}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <label>Año de fundación: </label>
                                                                                            <input
                                                                                                className="form-control form-control-sm"
                                                                                                type="number"
                                                                                                id="anio"
                                                                                                name="anio"
                                                                                                maxLength="4"
                                                                                                minLength="4"
                                                                                                placeholder="Año de Fundación"
                                                                                                onChange={getValue}
                                                                                                value={anio}
                                                                                            ></input>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="form-group mt-1 ">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <label>Número de Colaboradores: </label>
                                                                                            <input
                                                                                                className="form-control form-control-sm"
                                                                                                type="number"
                                                                                                id="colaboradores"
                                                                                                name="colaboradores"
                                                                                                minLength="1"
                                                                                                placeholder="Núm. Colaboradores"
                                                                                                onChange={getValue}
                                                                                                value={colaboradores}
                                                                                                max="99999"
                                                                                            ></input>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <label>RFC</label>
                                                                                            <input
                                                                                                className="form-control form-control-sm"
                                                                                                type="text"
                                                                                                id="rfc"
                                                                                                name="rfc"
                                                                                                maxLength="13"
                                                                                                minLength="12"
                                                                                                placeholder="RFC"
                                                                                                onChange={getValue}
                                                                                            ></input>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-3">
                                                                                    {/* <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                                        <div className="form-group ">
                                                                                            <label>Número de Colaboradores: </label>
                                                                                            <input
                                                                                                className="form-control form-control-sm"
                                                                                                type="number"
                                                                                                id="colaboradores"
                                                                                                name="colaboradores"
                                                                                                minLength="1"
                                                                                                placeholder="Núm. Colaboradores"
                                                                                                onChange={getValue}
                                                                                                value={colaboradores}
                                                                                                max="99999"
                                                                                            ></input>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 p-0">
                                                                                        <div className="form-group">
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                <label>Logotiopo (opcional): </label>
                                                                                            </div>
                                                                                            <input type="file" id="file" className="hidden" onChange={onChangeHandler} />
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                <img src="./../img/altaEmpresas/iconos_shopy_img-31.png" id="img-person" alt="persona" className="w-50 pointer pull-up" onClick={renderFilePers} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <GenDataAddress
                                                                                dataAddress={dataAddress}
                                                                                setDataAddress={setDataAddress}
                                                                                countries={countries}
                                                                            />
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        subSectionOne === 1
                                                                            ?
                                                                            (
                                                                                <RegisterCommunication
                                                                                    communicationData={communicationData}
                                                                                    setCommunicationData={setCommunicationData}
                                                                                    enterprise={enterprise}
                                                                                    files={files}
                                                                                    upFiles={upFiles}
                                                                                />
                                                                            )
                                                                            :
                                                                            (
                                                                                subSectionOne === 2
                                                                                    ?
                                                                                    (
                                                                                        <RegisterCommunication2
                                                                                            communicationData={communicationData}
                                                                                            setCommunicationData={setCommunicationData}
                                                                                            testMessage={testMessage} />
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        subSectionOne === 3
                                                                                            ?
                                                                                            (
                                                                                                <RegisterComplainChannel
                                                                                                    channelData={channelData}
                                                                                                    setChannelData={setChannelData}
                                                                                                    selectedFiles={selectedFiles}
                                                                                                    setSelectedFiles={setSelectedFiles}
                                                                                                    uploadFiles={uploadFiles}
                                                                                                    showFiles={showFiles}
                                                                                                    setShowFiles={setShowFiles}
                                                                                                    short={short}
                                                                                                    showFiles_2={showFiles_2}
                                                                                                    setShowFiles_2={setShowFiles_2}
                                                                                                    idEnterprise={idEnterprise}
                                                                                                />
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                null
                                                                                            )
                                                                                    )
                                                                            )
                                                                    )
                                                                : stepNum === 2 ?
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <h4 className="form-section"><i class="ft-settings"></i> Ingrese la siguiente información</h4>
                                                                        <div className="col-sx-12 col-sm-12 col-md-11 col-lg-11">
                                                                            <div className="form-group mt-1">
                                                                                <label>Misión: </label>
                                                                                <textarea id="mision"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="mision"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-mision"
                                                                                ></textarea>
                                                                                <p id="character-count-mision" className="small" align="right">0/500</p>
                                                                            </div>
                                                                            <div className="form-group mt-1">
                                                                                <label>Visión: </label>
                                                                                <textarea id="vision"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="vision"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-vision"
                                                                                ></textarea>
                                                                                <p id="character-count-vision" className="small" align="right">0/500</p>
                                                                            </div>
                                                                            <div className="form-group mt-1">
                                                                                <label>Objetivos: </label>
                                                                                <textarea id="objetivos"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="objetivos"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-objetivos"
                                                                                ></textarea>
                                                                                <p id="character-count-objetivos" className="small" align="right">0/500</p>
                                                                            </div>
                                                                            <div className="form-group mt-1">
                                                                                <label>Valores: </label>
                                                                                <textarea id="valores"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    name="valores"
                                                                                    minLength="4"
                                                                                    onChange={getValue}
                                                                                    add-count="1"
                                                                                    data-max-length="500"
                                                                                    element-name-character-count="character-count-valores"
                                                                                ></textarea>
                                                                                <p id="character-count-valores" className="small" align="right">0/500</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    : stepNum === 3 ?
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                            <h4 className="form-section"><i class="ft-file-text"></i> Ingrese la siguiente información</h4>
                                                                            <div className="row">
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            Régimen fiscal:
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId" value="1" style={{ height: '10px' }} onChange={getValue} /> Persona Fisíca
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId" value="2" style={{ height: '10px' }} onChange={getValue} /> Persona Moral
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check-inline">
                                                                                        <label className="form-check-label" id="fiscalRegimenLabel">
                                                                                            <input type="radio" className="form-check-input" name="fiscalRegimenId" id="fiscalRegimenId" value="3" style={{ height: '10px' }} onChange={getValue} /> RIF
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                                    {/* <div className="form-group mt-1 ">
                                                                                        <label>RFC</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="rfc"
                                                                                            name="rfc"
                                                                                            maxLength="13"
                                                                                            minLength="13"
                                                                                            placeholder="RFC"
                                                                                            onChange={getValue}
                                                                                        ></input>
                                                                                    </div> */}
                                                                                    <div className="form-group">
                                                                                        <label>País:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="countryFiscal"
                                                                                            id="countryFiscal"
                                                                                            onChange={getValue}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {countries.map((optionCountries) => (
                                                                                                <option
                                                                                                    key={optionCountries.id}
                                                                                                    value={optionCountries.id}
                                                                                                >
                                                                                                    {optionCountries.description}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group ">
                                                                                        <label>Código Postal: </label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="number"
                                                                                            id="cp"
                                                                                            name="cp"
                                                                                            minLength="4"
                                                                                            placeholder="Código Postal"
                                                                                            onChange={getValue}
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Colonia:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="neibg"
                                                                                            id="neibg"
                                                                                            disabled
                                                                                            onChange={getValue}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group mb-1">
                                                                                        <label>Núm. exterior:</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="numExt"
                                                                                            name="numExt"
                                                                                            maxLength="50"
                                                                                            minLength="2"
                                                                                            placeholder="Número Exterior"
                                                                                            onChange={getValue}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                                                    <div className="form-group mt-1 ">
                                                                                        <label>Razón Social (opcional)</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="razon"
                                                                                            name="razon"
                                                                                            maxLength="100"
                                                                                            minLength="3"
                                                                                            placeholder="Razón Social"
                                                                                            onChange={getValue}
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Estado:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="state"
                                                                                            id="state"
                                                                                            disabled>
                                                                                            <option value="0">--Seleccione--</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Municipio/Alcaldía:</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            name="town"
                                                                                            id="town"
                                                                                            disabled>
                                                                                            <option value="0"> ---Selecione---</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label>Calle:</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="street"
                                                                                            name="street"
                                                                                            maxLength="150"
                                                                                            minLength="2"
                                                                                            placeholder="Calle"
                                                                                            onChange={getValue}
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div className="form-group mb-1">
                                                                                        <label>Núm. Interior (opcional):</label>
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            id="numInt"
                                                                                            name="numInt"
                                                                                            maxLength="50"
                                                                                            minLength="2"
                                                                                            placeholder="Número Interior"
                                                                                            onChange={getValue}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                        }
                                                    </form>
                                                    {
                                                        sectionOne === true
                                                            ?
                                                            (
                                                                subSectionOne > 0
                                                                    ?
                                                                    (
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mx-auto mt-3">
                                                                            <button
                                                                                className="btn btn-blue float-left font-medium-1 btn-sm"
                                                                                id="backBtn"
                                                                                name="backBtn"
                                                                                onClick={handleChangeSubSection}>Atrás</button>
                                                                            <button
                                                                                className="btn btn-blue float-right font-medium-1 btn-sm"
                                                                                id="nextBtn"
                                                                                name="nextBtn"
                                                                                onClick={handleChangeSubSection}>{subSectionOne === 3 ? 'Guardar y continuar' : 'Siguiente'}</button>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mx-auto mt-3">
                                                                            <button
                                                                                className="btn btn-blue float-right font-medium-1 btn-sm"
                                                                                id="nextBtn"
                                                                                name="nextBtn"
                                                                                onClick={handleChangeSubSection}>Siguiente</button>
                                                                        </div>
                                                                    )
                                                            )
                                                            :
                                                            (
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mx-auto mt-3">
                                                                    {/* <button className="btn btn-blue float-left font-medium-1 btn-sm" onClick={() => setNewEnterprise()}>Guardar y salir</button> */}
                                                                    {/* <button className="btn btn-green float-right font-medium-1 btn-sm" id="saveBtn" onClick={stepNum === 3 ? () => setNewEnterprise() : () => handleOnClickNext()}>{stepNum === 3 ? 'Terminar' : 'Continuar'}</button> */}
                                                                    <button className="btn btn-green float-right font-medium-1 btn-sm" id="saveBtn" onClick={() => setNewEnterprise()}>Terminar</button>
                                                                </div>
                                                            )
                                                    }
                                                    {/* <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mx-auto mt-3">
                                                <button className="btn btn-blue float-left font-medium-1" onClick={() => setNewEnterprise()}>Guardar y salir</button>
                                                <button className="btn btn-green float-right font-medium-1" id="saveBtn" onClick={stepNum === 3 ? () => setNewEnterprise() : () => handleOnClickNext()}>{stepNum === 3 ? 'Terminar' : 'Continuar'}</button>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ModalLoader />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RegisterEnterpriseGroup;