
import { React } from 'react';
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from "jquery";


const FailedModalMailGun = ({
    ObjFailed,
    dateFailed
     }) => {
       
      
    /**
     * Table props - Start
     */
    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    };
   
    const trClassFormat = (row, rowIndex) => {
        if (row.nuevoValor === 1) {
            return (
                "border-none-1"
            )
        }
    }
    const dateCollabFormat = (row, rowIndex) => {
        return (
            <span
                >{dateFailed}</span>
        )
        
    }
    
    return (
        <div
            className="modal"
            id="modalFailedMailGun"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => $('#modalFailedMailGun').hide()}>&times;</button>
                    </div>
                    <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <h3 className="text-base-guay">Colaboradores</h3>
                        </div>
                       
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <BootstrapTable
                                data={ObjFailed}
                                hover
                                pagination={true}
                                trClassName={trClassFormat}
                                /* tableStyle={{ padding: '0px', fontSize: '.8rem', color: '#6b6f82' }} */
                                options={options}
                                headerStyle={{ border: 'red 0px solid' }}
                                bodyStyle={{ border: 'green 0px solid' }}
                                tableStyle={{ border: '#9cb0d1 2.5px solid', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField='rank'
                                    hidden ></TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='name'                                   
                                    /* width="20%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Colaborador</TableHeaderColumn>                                
                                <TableHeaderColumn
                                    dataField='email'
                                    /* width="25%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Correo</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='date'                                   
                                    dataFormat={dateCollabFormat}
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Fecha de envio</TableHeaderColumn>
                                
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default FailedModalMailGun;
