import React, { useState } from 'react';
// API calls
import { register } from '../Models/Users_model';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

const RegisterForm = ({ setUserID }) => {
    const history = useHistory();
    const [registerData, setRegisterData] = useState({
        name: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        type: "1",
        usePhone: 0,
        isValidate: 0,
        id: 0,
        img: '',
        origin: 1,
        key: ''
    });
    // Extract variables form registerData
    const {
        name,
        lastName,
        email,
        key
    } = registerData;
    /**
     * Get input value
     * @param {*} e 
     */
    const getValue = e => {
        $('#' + e.target.name).removeClass('border-danger');
        setRegisterData({
            ...registerData,
            [e.target.name]: e.target.value
        });
    }
    /**
     * Show conditions of use and notice of privavy documents by id
     * @param {*} e 
     */
    const handlePolitics = e => {
        e.preventDefault();
        if (e.target.id === "terms") {
            window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf");
        }
        if (e.target.id === "privacy") {
            window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf");
        }
    }
    return (
        <div className="col-sx-10 col-sm-10 col-md-4 col-lg-4 mx-auto pt-5">
            <div className="card">
                <div className="card-header p-1 bg-blue-navy">
                    <h2 className="text-center m-0 text-white">Crea tu cuenta</h2>
                </div>
                <div className="card-body font-small-3">
                    <div className="row">
                        <div className="col-12 mb-1">
                            <p className="text-center text-base">Si eres una Pyme y operas en México registrate con nosotros y te ayudaremos a cumplir con la NOM-035.</p>
                        </div>
                        <div className="col-12 mb-1">
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    // Validations
                                    if (name.length === 0) {
                                        $('#name').addClass('border-danger');
                                        return;
                                    }
                                    if (lastName.length === 0) {
                                        $('#lastName').addClass('border-danger');
                                        return;
                                    }
                                    if (email.length === 0) {
                                        $('#email').addClass('border-danger');
                                        return;
                                    }
                                    // Email validations with regex structure
                                    // eslint-disable-next-line
                                    let emailregex = /^\w+([\.-]?\w+)*@[a-zA-Z0-9_]+?\.[a-zA-Z0-9_]{2,12}.*$/;
                                    if (!emailregex.test(email)) {
                                        $('#email').addClass('border-danger');
                                        toastr.warning('Correo electrónico con formato incorrecto.', '¡Ooops!');
                                        return;
                                    }
                                    if (key.length === 0) {
                                        $('#key').addClass('border-danger');
                                        return;
                                    }
                                    // Password validations with regex structure
                                    let passwordregex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-]).{6,}$/;
                                    if (!passwordregex.test(key)) {
                                        $('#key').addClass('border-danger');
                                        toastr.warning('Contraseña con formato incorrecto.', '¡Ooops!');
                                        return;
                                    }
                                    register(registerData).then(response => {
                                        if (response) {
                                            if (response.success) {
                                                setUserID(response.id);
                                                toastr.success('Se registró correctamente.', '¡Éxito!');
                                                $('#modalToken').show();
                                            } else {
                                                switch (response.code) {
                                                    case 1001:
                                                        toastr.error('Este correo ya fue registrado.', '¡Ooops!');
                                                        break;
                                                    default:
                                                        toastr.error('Problemas técnicos, contacta al equipo de soporte. Error: ' + response.message, '¡Ooops!');
                                                        break;
                                                }
                                            }
                                        } else {
                                            toastr.error('Problemas con el servidor. Por favor intentar más tarde.', '¡Ooops!');
                                        }
                                    });
                                }}
                                autoComplete="off"
                            >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Nombre(s)"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={getValue}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Apellidos"
                                        id="lastName"
                                        name="lastName"
                                        value={lastName}
                                        onChange={getValue}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control form-control-sm"
                                        placeholder="Correo electrónico. Ej. correo@correo.com"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={getValue}
                                    />
                                </div>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        className="form-control form-control-sm"
                                        style={{
                                            borderRight: '0'
                                        }}
                                        placeholder="Contraseña"
                                        id="key"
                                        name="key"
                                        value={key}
                                        onChange={getValue}
                                    />
                                    <div
                                        className="input-group-append d-flex flex-wrap align-content-center px-1"
                                        style={{
                                            border: '1px solid #cacfe7',
                                            color: '#3b4781'
                                        }}
                                    >
                                        <i
                                            className="bi bi-eye pointer"
                                            id="showPassword"
                                            onClick={e => {
                                                e.preventDefault();
                                                if ($('#key').attr('type') === 'password') {
                                                    $('#key').attr('type', 'text');
                                                    $('#showPassword').removeClass('bi-eye');
                                                    $('#showPassword').addClass('bi-eye-slash');
                                                } else {
                                                    $('#key').attr('type', 'password');
                                                    $('#showPassword').removeClass('bi-eye-slash');
                                                    $('#showPassword').addClass('bi-eye');
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <p className="text-base text-center font-small-1 mb-1">Utiliza más de 6 carácteres, combinando letras, números y simbolos (!@#$%^&*_-.)</p>
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-blue-3"
                                    >Crear cuenta y aplicar</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 text-base text-center">
                            <p>Al crear una cuenta, aceptas las <span className="text-guay-blue pointer" id="terms" onClick={handlePolitics}>Condiciones de Uso</span> y el <span className="text-guay-blue pointer" id="privacy" onClick={handlePolitics}>Aviso de Privacidad</span> de guay</p>
                            <p>¿Ya tienes una cuenta? <span className="text-base-guay pointer" onClick={() => history.push("/")}>Ingresa aquí</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

RegisterForm.propTypes = {
    setUserID: PropTypes.func.isRequired
}

export default RegisterForm;
