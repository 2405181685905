import React, { useState, useEffect } from 'react'

const SeguroAccidentesPersonales = () => {
    const [urlImg, setUrlImg] = useState('')
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            // platform = "Mobile";
            setUrlImg("./../img/beneficios/BANNER_plan_seguro_mobile.png")
        } else {
            setUrlImg("./../img/beneficios/BANNER_plan_seguro.png")
        }
      
    }, [])
    
    return (
        <div className='container-fluid'>
            <div className='row bg-gray-giki'>
                <img className="img-responsive" src={urlImg} alt="img-dentista-1" />
                {/* <div className='col-12'>
                    <div className='row'>
                        <div className='col-5 col-md-3 d-flex align-items-end p-0'>
                            <div>
                                <img
                                    src='./../img/beneficios/imagen_seguro_de_accidentes.png'
                                    className='img-fluid mt-0 mt-md-2'
                                    alt='img seguro'
                                />
                            </div>
                        </div>
                        <div className='col-7 col-md-9 d-flex align-items-center'>
                            <div className='w-100'>
                                <div className='row'>
                                    <div className='col-12 px-1 px-md-4'>
                                        <h1 className='text-white fs-1-125 text-center mt-2 mt-md-0'>Seguro de Accidentes Personales</h1>
                                        <hr className='hr-white m-0 p-0 mb-md-1 pb-md-1' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 px-1 px-md-4'>
                                        <h2 className='text-white text-justify fs-1'>Protección de forma integral al titular asegurado en caso
                                            de sufrir un accidente por acción súbita, fortuita y violenta
                                            de una fuerza externa, ocasionando lesión, pérdida
                                            orgánica, invalidez o muerte.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='row my-3'>

                <div className='col-12 col-md-6 d-flex align-items-center mb-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Cobertura por muerte accidental $100,000.00 mxp</h2>
                </div>

                <div className='col-12 col-md-6 d-flex align-items-center mb-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Pérdidas orgánicas por accidente escala B $100,000.00 mxp</h2>
                </div>

                <div className='col-12 col-md-6 d-flex align-items-center mb-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Reembolso hasta $15,000.00 por accidente (1 vez al año)</h2>
                </div>

                <div className='col-12 col-md-6 d-flex align-items-center mb-1'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Asistencia Médica telefónica ilimitada</h2>
                </div>

                <div className='col-12 col-md-6 d-flex align-items-center'>
                    <img
                        src='./../img/beneficios/icono_aprobado_verde.png'
                        className='img-fluid mr-1'
                        alt='check'
                    />
                    <h2 className='fs-1'>Orientación Médica Ilimitada en Centro de atención telefónica con médicos las 24 hrs,
                        los 365 días del año</h2>
                </div>

            </div>
            <div className='row border-bottom'>
                <div className='col-10 col-md-4 bg-yellow-giki mx-auto mx-md-0'>
                    <h1 className='text-center fs-1-25'>Escala de indemnizaciones “B”</h1>
                </div>
            </div>

            <div className='row my-2'>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>100%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Ambas manos, ambos pies
                            o la vista de ambos ojos</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>100%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Una mano y un pie</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>100%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Una mano o un pie y la vista de un ojo</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>50%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Una mano</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>50%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Un pie</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>30%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Tres dedos, comprendiendo el pulgar o el índice, de una mano</h2>
                    </div>
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-2'>
                    <div className='card p-1 h-100 d-flex align-items-center justify-content-center'>
                        <h1 className='text-cian-giki text-center fs-1-563'>30%</h1>
                        <h2 className='font-weight-bold text-center fs-1'>Amputación parcial de un pie, comprendiendo todos los dedos</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeguroAccidentesPersonales;