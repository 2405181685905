import React, { useState, useEffect } from 'react'
import LoaderApp from '../LoaderApp';
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import Header from '../NewHome/headerAdministrador';
import Menu from "../Menu";
import TablaReenvioFichas from './TablaReenvioFichas';
import { getCardsByProduct } from '../Models/Launch_model';
import { getBranches } from '../Models/Enterprise_model';
import { validateAccess,getSubsection,getSubsectionIn } from '../../helpers/validations';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import $ from 'jquery';
import * as toastr from 'toastr';

const ReenvioFichas = ({access}) => {
    const enterpriseId = getJwt("enterprise");
    const history = useHistory();
    const [active, setActive] = useState(0)
    const [loading, setLoading] = useState(true);
    const [cardList, setCardList] = useState([])
    const [cardListTable, setCardListTable] = useState([])
    const [selectOptions, setSelectOptions] = useState([])
    const [disableActions, setDisableActions] = useState(false)

    useEffect(() => {
      try {

        if (parseInt(getJwt('rol')) > 6) {
          const section = validateAccess(access.list, 2)
          const subSection = getSubsection(section[0].subSection, 14)
          const reenvioFichas = getSubsectionIn(subSection[0].subSectionIn, 42)

          if (section[0].id_action === 0) {
            history.push("/inicio-colaborador")
          } else {
            if (reenvioFichas[0].id_action === 0) {
              toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
              history.push("/administracion-servicios")
            } else {
              if (reenvioFichas[0].id_action === 2) {
                setDisableActions(true)
              }
            }
          }
        }
        
      } catch (error) {
        console.error(error);
      }
      
    }, [enterpriseId,access.list,history])
    

    useEffect(() => {

        getCardsByProduct().then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setLoading(false);
                        setCardList(response.cardList)
                        setCardListTable(response.cardList[0]);
                        break;
                    case 1001:
                        setLoading(false);
                        toastr.info('No se encontraron fichas disponibles');
                        break;
                    default:
                        setLoading(false);
                        toastr.error('Ocurrió un error inesperado, verifique su conexión a internet y vuelva a intentarlo.');
                        break;
                }
                
            } else {
                setLoading(false);
                toastr.error('Ocurrió un error inesperado, verifique su conexión a internet y vuelva a intentarlo.');
            }
        });
    
      
    }, [history])

    useEffect(() => {
        
        getBranches(getJwt('enterprise')).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        const getBranchesList = response.branches.map(branch => ({ value: branch.branchEntId, label: branch.description }))
                        setSelectOptions(getBranchesList)
                        break;
                    default:
                        break;
                }
            }
        })
    
      
    }, [])
    
    

    const handleAdmin = () => {
        if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
          Swal.fire({
            title: '<p className="text-white mb-2">¡Aún no has terminado!</p>',
            text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Sí, salir",
            reverseButtons: true,
            dangerMode: true
          }).then((willDelete) => {
            if (willDelete.isConfirmed) {
              sessionStorage.removeItem("inLaunch");
              sessionStorage.removeItem("isEdition");
              sessionStorage.removeItem("idProduct");
              sessionStorage.removeItem("idCategory");
              sessionStorage.removeItem("idSubcategory");
              sessionStorage.removeItem("nameCategory");
              sessionStorage.removeItem("nameSubcategory");
              sessionStorage.removeItem("idTypeLaunch");
              sessionStorage.removeItem("descriptionLaunch");
              sessionStorage.removeItem("flagLaunch");
              sessionStorage.removeItem("launchDate");
              sessionStorage.removeItem("idGroup");
              sessionStorage.removeItem("numCollabSelected");
              sessionStorage.removeItem("idQuizEnterprise");
              history.push("/inicio-administrador");
            }
          });
          $(".swal2-title").addClass("bg-header-swal");
          $(".swal2-actions").addClass("w-100 justify-content-around");
          $(".swal2-confirm").removeClass("swal2-styled");
          $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
          $(".swal2-cancel").removeClass("swal2-styled");
          $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        } else {
          history.push("/inicio-administrador");
        }
    }

    const handleLanz = () => {
        history.push("/administracion-servicios");
    }

    const handleCom = () => {
        history.push("/comunicacion");
    }

    const handleChangeMenu = (data,index) => {
        setActive(index)
        setCardListTable(data)
    }


  if (redirectIfNotAuthenticated()) {
    return (
        <div className="app-content bootomFooter">
            <div className="content-overlay">
                <Header />
                <Menu />
                <div className="content-wrapper bootomFooter">
                    <div className="content-header">
                        <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                            <div className="content-header-right breadcrumb-new mt-1 d-flex justify-content-end">
                                <div className="row breadcrumbs-top d-inline-block">
                                    <div className="breadcrumb-wrapper col-12">
                                        <ol className="breadcrumb">
                                            <li
                                                className="breadcrumb-item font-medium-3 pointer"
                                                onClick={() => handleAdmin()}
                                            >Administración</li>
                                            <li className="breadcrumb-item active font-medium-3">Configura / Reenvío de Fichas
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        {
                            loading === true ? (
                                <div className="row">
                                    <LoaderApp />
                                </div>
                            ) : (
                                <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mt-2">
                                    <div className="row">
                                        <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2">
                                        </div>
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10">
                                            <nav className="navbar navbar-expand-lg bg-white">
                                                <div className="container-fluid">
                                                    {
                                                        cardList.map((data, index) =>
                                                             <li className={active === index ? 
                                                                "navbar-brand bg-white border-bottom-pink font-medium-1 text-black pointer" : 
                                                                "navbar-brand bg-white font-medium-1 text-black pointer"}
                                                                key={index}
                                                                >
                                                                <span
                                                                    className="ml-1 font-medium-2"
                                                                    onClick={() => handleChangeMenu(data,index)}
                                                                >{data.titleSub !== '' && data.titleSub !== undefined? data.titleSub : data.titleCat}
                                                                </span>
                                                            </li>
                                                        )
                                                    }
                                                </div>
                                                
                                            </nav>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-2 p-0 mt-2">
                                            <div className="card">
                                                <ul className="list-group enterprise-list">
                                                    <li className="list-group-item pointer" data-step="1">
                                                        <span
                                                        className="breadcrumb-item ml-1 font-medium-3 pointer"
                                                        onClick={() => handleLanz()}
                                                        >Lanzamientos</span>
                                                    </li>
                                                    <li className="list-group-item pointer" data-step="2" id="comunicados">
                                                        <span
                                                            className="ml-1 font-medium-2"
                                                            onClick={() => handleCom()}
                                                        >Comunicados</span>
                                                    </li>
                                                    <li className="list-group-item active pointer" data-step="3">
                                                        <span className="ml-1 font-medium-2">Reenvío de Fichas</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mt-2">
                                            <TablaReenvioFichas
                                                cardListTable={cardListTable}
                                                selectOptions={selectOptions}
                                                disableActions={disableActions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = ({access}) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(ReenvioFichas)