import React from 'react'
import $ from "jquery"

const Climate = ({
    checkboxVal,
    checkboxFunc,
    enterpriseLogo,
}) => {
    return (
        <div
            className="modal"
            id="modal-2"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content col-sx-10 mx-auto"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                    }}
                >
                    <div className="modal-body modal-div-bodered container-style-1 pb-5">
                        <div className="col-12 d-flex justify-content-end">
                            <img
                                src={enterpriseLogo}
                                className="w-25"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <img
                                src="../img/Mensaje-encuesta_1.png"
                                className="w-60"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 font-medium-1 mt-1">
                            <p className="text-justify">
                                Este cuestionario tiene como objetivo realizar el diagnóstico sobre el {''}
                                <span className='text-pink-mine font-weight-bold'>Clima Laboral</span> {''}
                                que se vive en la organización, con tu ayuda conoceremos las áreas de mejora y trabajaremos en ellas.
                            </p>
                            <div className="col-12 pl-5">La encuesta se compone de 5 secciones:
                                <p className='mt-1'>
                                    <span className='font-weight-bold'>1. Flexibilidad;</span> {''}
                                    nos referimos a como se distribuye el trabajo, la productividad y el equilibrio entre los intereses de la persona y los de la organización.
                                </p>
                                <p>
                                    <span className='font-weight-bold'>2.Reconocimiento;</span> {''}
                                    son los elementos que expresa una organización a sus colaboradores por su desempeño.
                                </p>
                                <p>
                                    <span className='font-weight-bold'>3. Bienestar Laboral;</span> {''}
                                    es la situación de equilibrio entre todos los aspectos que influyen en el colaborador sobre su entorno de trabajo.
                                </p>
                                <p>
                                    <span className='font-weight-bold'>4. Sentido de pertenencia;</span> {''}
                                    tiene que ver con sentirse identificado con los valores de la organización y con el equipo de trabajo.
                                </p>
                                <p>
                                    <span className='font-weight-bold'>5. Espíritu de equipo;</span> {''}
                                    es un sentimiento de pertenencia, de participación, de confianza y de respeto dentro de la organización y con el equipo de trabajo.
                                </p>
                                <p className='mt-1 font-weight-bold'>
                                    Importante:  considerar las condiciones de los últimos tres meses.
                                </p>
                            </div>
                            <p className="text-justify">
                                Al final preguntaremos algunos {''}
                                <span className='text-pink-mine font-weight-bold'>datos demográficos</span> {''}
                                para conocerte mejor: edad, tiempo de traslado a tu lugar de trabajo, años en la organización, etc.
                            </p>
                            <p className="text-justify">
                                Recuerda que las respuestas son opiniones basadas en {''}
                                <span className='text-pink-mine font-weight-bold'>tu experiencia de trabajo</span>, {''}
                                por lo que {''}
                                <span className='text-pink-mine font-weight-bold'>no hay respuestas correctas e incorrectas</span>. {''}
                                Tus respuestas serán tratadas de forma confidencial, no serán utilizadas para ningún propósito distinto al de ayudar a identificar las áreas de mejora dentro de la organización.
                            </p>
                            <p className="text-justify">Agradecemos tu colaboración</p>
                        </div>
                        <div className="col-12 mt-1">
                            <div className="form-group form-check">
                                <label className="form-check-label pointer font-medium-1">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value=""
                                        onChange={() => checkboxFunc()}
                                    />
                                    Al dar click aceptas nuestro {''}
                                    <span
                                        className="text-blue font-weight-bold"
                                        onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                                    >aviso de privacidad</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            {checkboxVal === true && (
                                <button
                                    className="btn btn-sm btn-blue-3 float-right font-medium-1"
                                    onClick={() => $('#modal-2').hide()}
                                >Encuesta</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Climate