import React from 'react'

const Pasos = () => {
  return (
    <div class="row">
        <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
            <p className='font-weight-bold text-blue-sec fs-1'>2. Escanea toda la documentación <span className='font-weight-normal'>y mándala al correo</span> <a href="mailto:siniestrosapvida@palig.com" className='text-blue'><u>siniestrosapvida@palig.com</u></a> a partir de este momento toda la comunicación se realizará por este medio.</p>
        </div>
        <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
            <p className='font-weight-bold text-blue-sec fs-1'>3. La aseguradora recibirá la documentación <span className='font-weight-normal'>y dará inicio al procedimiento.</span></p>
        </div>
        <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
            <p className='font-weight-bold text-blue-sec fs-1'>4. Recibirás un correo con la confirmación de que la documentación esté correcta <span className='font-weight-normal'>(de no ser así se te indicará qué documentos tendrás que volver a enviar) También Recibirás el monto a indemnizar y la solicitud de firma de finiquito.</span></p>
        </div>
        <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
            <p className='font-weight-bold text-blue-sec fs-1'>5. <span className='font-weight-normal'> Si estas de acuerdo con el pago,</span> envía el finiquito firmado para que se realice la transferencia a tu banco, <span className='font-weight-normal'>en caso de estar inconforme solicita una revisión mediante un correo adjuntando la documentación de soporte.</span></p>
        </div>
    </div>
  )
}

export default Pasos
