import React from 'react';

const Menu = () => {
    return (
        <div className="col-sx-11 col-sm-11 col-md-3 col-lg-3 mx-auto">
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-gray pointer py-2 rounded">
                <span className="text-white font-weight-bold">Solicitud</span>
            </div>
        </div>
    );
}

export default Menu;
