import React, { useState, useEffect } from "react";
import Buttons from "../../common/form/Buttons";
import { endpoint } from "../../../lib/api/api";
import $ from "jquery";
import Swal from "sweetalert2";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import axios from "axios";
import { setJwt } from "../../../lib/auth";

const ValidateSms = props => {
  const [objValidate, updValidate] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    send: 0,
    user: ""
  });
  const getValue = (e) => {
    try {
      let value = e.target.value;
      updValidate({
        ...objValidate,
        [e.target.name]: value,
      });
      if (e.target.value.length === 0) {
        $("#" + e.target.name).removeClass("border-success");
        $("#" + e.target.name).addClass("border-danger");
      } else {
        $("#" + e.target.name).removeClass("border-danger");
        $("#" + e.target.name).addClass("border-success");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setActive = (e) => {
    e.preventDefault();
    try {
      if (objValidate.code1.length === 0 || objValidate.code2.length === 0
        || objValidate.code3.length === 0 || objValidate.code4.length === 0) {
        Swal.fire({
          title: '<p class="text-white mb-2">¡Atención!</p>',
          text: "Debe registrar el código de activación.",
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          dangerMode: true
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        return;
      } else {
        let code = objValidate.code1 + objValidate.code2 + objValidate.code3 + objValidate.code4;
        let url = "/users/active-user?db=" + code + "&gdb=" + objValidate.user + "&ko=" + Math.random().toString(36).slice(2);
        axios.get(endpoint(url)).then(result => {
          let response = result.data;
          if (response.success) {
            setJwt(response);
            Swal.fire({
              title: '<p class="text-white mb-2">Correcto</p>',
              text: "La cuenta ha sido activada correctamente",
              showCloseButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              dangerMode: true
            }).then(() => {
              window.location = "../inicio-tienda";
            });
            $(".swal2-title").addClass("bg-header-swal");
            $(".swal2-actions").addClass("w-100 justify-content-around");
            $(".swal2-confirm").removeClass("swal2-styled");
            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
            $(".swal2-cancel").removeClass("swal2-styled");
            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
          } else {
            switch (response.code) {
              case 1003:
                toastr.warning("El codigo ha caducado, la cuenta ya ha sido activada. Verifique la información");
                break;
              case 1004:
                toastr.error("Error al activar la cuenta. Verifique la información");
                break;
              case 1005:
                toastr.error("Datos incorrectos. Verifique la información");
                break;
              default:
                toastr.error("Error en la activación. Verifique la información");
                break;
            }
          }

        });

      }
    } catch (error) {
      console.log(error);
    }
  }






  useEffect(() => {
    const getObject = props.match.params;
    let obj = getObject.db.split("=");
    updValidate({
      ...objValidate,
      // eslint-disable-next-line
      ['user']: obj[1]
    });
    // eslint-disable-next-line
  }, []);


  return (
    <section className="row flexbox-container bg-login-3">
      <div className="col-12 d-flex align-items-center justify-content-center">
        <div className="col-lg-6 col-md-8 col-10  p-0 text-center">
          <p className="text-green font-weight-bold font-large-1" style={{ fontFamily: "quicksand" }}>
            Gracias por confiar en nosotros!!
          </p>
          <p className="font-large-1 text-center text-dark" style={{ fontFamily: "quicksand" }}>
            <span>Estas a punto de ingresar a la experiencia </span>
            <img
              src="../../img/guay-logo.png"
              className="w-10"
              alt="guay-digital"
            />
          </p>
          <div className="col-md-12 text-center mt-2">
            <div className="col-lg-9 col-md-9 ml-7  p-0 text-center">
              <div className="card border-grey border-lighten-3 m-0">
                <div className="card border-grey border-lighten-3 m-0">
                  <div className="card-body text-center">
                    <h3 className="text-center text-dark" style={{ fontFamily: "quicksand" }} > Confirma tus datos ingresando el<br />
                      código que se envió a tu teléfono</h3>
                    <form className="form-horizontal mt-3" onSubmit={setActive}>

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-2"></div>
                          <div className="col-md-2">
                            <input
                              className="form-control text-center"
                              type="text"
                              id="code1"
                              name="code1"
                              maxLength="1"
                              minLength="1"
                              onChange={getValue}
                            ></input>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-control text-center"
                              type="text"
                              id="code2"
                              name="code2"
                              maxLength="1"
                              minLength="1"
                              onChange={getValue}
                            ></input>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-control text-center"
                              type="text"
                              id="code3"
                              name="code3"
                              maxLength="1"
                              minLength="1"
                              onChange={getValue}
                            ></input>
                          </div>
                          <div className="col-md-2">
                            <input
                              className="form-control text-center"
                              type="text"
                              id="code4"
                              name="code4"
                              maxLength="1"
                              minLength="1"
                              onChange={getValue}
                            ></input>
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          <Buttons
                            type="submit"
                            color="blue"
                            text="Continuar"
                            style={{ fontFamily: "quicksand" }}
                          />
                          <p className="text-dark" style={{ fontFamily: "quicksand" }}>¿Aún no lo tienes? <span id="send-sms" className="text-blue pointer">Enviar de nuevo</span></p>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
};

export default ValidateSms;
