import React from 'react';
// API calls
import { redirectIfNotAuthenticated } from '../../../../lib/auth';
// Components
import Header from '../../NewHome/headerColaborador';
import Menu from '../../Menu';
import Footer from '../../footerApp';
import Nav from './ApplicationStructure/Nav';
import ApplicationMenu from './ApplicationStructure/Menu';
import Form from './ApplicationStructure/Form';

const Application = () => {
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <Nav />
                        </div>
                        <div className="content-body">
                            <div className="col-12">
                                <div className="row">
                                    <ApplicationMenu />
                                    <Form />
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Application;
