import React, { Fragment, useState, useEffect } from 'react';
import $ from "jquery";
import Swal from "sweetalert2";
import { validateToken, updatePasswordFGT } from '../../../../src/components/web/register/Model'
import * as toastr from 'toastr';

const ResetPassword = (props) => {
    const [validate, updValidate] = useState({});
    // Set passwords
    const [passwords, updateStatePassword] = useState({
        id_user: "",
        password_1: "",
        password_2: ""
    });

    // Get values
    const { password_1, password_2 } = passwords;

    const [validation1, setValidation1] = useState(false);
    const [validation2, setValidation2] = useState(false);
    const [validation3, setValidation3] = useState(false);
    const [validation4, setValidation4] = useState(false);
    const [validation5, setValidation5] = useState(false);
    const [validation6, setValidation6] = useState(false);
    const [correctPassword, setCorrectPassword] = useState(false);

    useEffect(() => {
        const getObject = props.match.params;
        let obj = getObject.db.split("=");
        validateToken(obj[1], obj[2]).then((result) => {
            updValidate(result)
        });
        updateStatePassword({
            ...passwords,
            id_user: obj[2],
        });
        // eslint-disable-next-line
    }, []);

    // Update password
    const updatePassword = e => {
        e.preventDefault();
        try {
            let value = e.target.value;
            if (value === null || value.length === 0) {
                $("#" + e.target.name).removeClass("border-success");
                $("#" + e.target.name).addClass("border-danger");
            } else {
                $("#" + e.target.name).removeClass("border-danger");
                $("#" + e.target.name).addClass("border-success");
            }
            if (e.target.name === 'password_1') {
                let counter = 0;
                let regexSpecial = /[!@#$%^&*._-]/
                let regexCapital = /[A-Z]/
                let regexNumber = /[0-9]/
                let regexLength = /().{6}/
                let regexWhitespace = /\s/
                let regexLower = /[a-z]/
                if (regexSpecial.test(value)) {
                    setValidation1(true);
                    counter++;
                } else {
                    setValidation1(false);
                }
                if (regexCapital.test(value)) {
                    setValidation2(true);
                    counter++;
                } else {
                    setValidation2(false);
                }
                if (regexNumber.test(value)) {
                    setValidation3(true);
                    counter++;
                } else {
                    setValidation3(false);
                }
                if (regexLength.test(value)) {
                    setValidation4(true);
                    counter++;
                } else {
                    setValidation4(false);
                }
                if (regexWhitespace.test(value)) {
                    setValidation5(true);
                } else {
                    setValidation5(false);
                    counter++;
                }
                if (regexLower.test(value)) {
                    setValidation6(true);
                    counter++;
                } else {
                    setValidation6(false);
                }
                if (counter === 6) {
                    setCorrectPassword(true);
                } else {
                    setCorrectPassword(false);
                }
            }
            updateStatePassword({
                ...passwords,
                [e.target.name]: e.target.value
            });
        } catch (error) {
            console.log(error);
        }
    }

    // Set new password
    const setPassword = (e) => {
        e.preventDefault();
        $('#btn-n-pass').text("").prop('disabled', true)
            .addClass("btn-guay-processing-rp")
            .append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite	infinite"></i> Procesando...');
        $("#form-n-pass :input").prop("disabled", true);
        try {
            if (password_1.length !== 0 && password_2.length !== 0) {
                if (password_1 === password_2) {
                    if (correctPassword) {
                        updatePasswordFGT(passwords).then(res => {
                            if (res.success) {
                                $('#btn-n-pass').text("").prop('disabled', false)
                                    .append('Enviar')
                                    .removeClass("btn-guay-processing-rp");
                                $("#form-n-pass :input").prop("disabled", false);
                                Swal.fire({
                                    title: '<p class="text-white mb-2">¡Éxito!</p>',
                                    text: "Se restableció la contraseña",
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    showConfirmButton: true,
                                    confirmButtonText: "Ok",
                                    dangerMode: true
                                }).then((willDelete) => {
                                    window.location = "/"
                                });
                                $(".swal2-title").addClass("bg-header-swal");
                                $(".swal2-actions").addClass("w-100 justify-content-around");
                                $(".swal2-confirm").removeClass("swal2-styled");
                                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                                $(".swal2-cancel").removeClass("swal2-styled");
                                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                            }
                        });
                        return;
                    } else {
                        toastr.warning('La contraseña no tiene el formato correcto.', '¡Ooops!');
                        $('#btn-n-pass').text("").prop('disabled', false)
                            .append('Enviar')
                            .removeClass("btn-guay-processing-rp");
                        $("#form-n-pass :input").prop("disabled", false);
                    }
                } else {

                    setTimeout(function () {
                        $('#btn-n-pass').text("").prop('disabled', false)
                            .append('Enviar')
                            .removeClass("btn-guay-processing-rp");
                        $("#form-n-pass :input").prop("disabled", false);
                    }, 500);

                    setTimeout(function () {
                        Swal.fire({
                            title: '<p class="text-white mb-2">¡Atención!</p>',
                            text: "Las contraseñas no coinciden, por favor revise otra vez.",
                            showCloseButton: true,
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: "Ok",
                            dangerMode: true
                        });
                        $(".swal2-title").addClass("bg-header-swal");
                        $(".swal2-actions").addClass("w-100 justify-content-around");
                        $(".swal2-confirm").removeClass("swal2-styled");
                        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                        $(".swal2-cancel").removeClass("swal2-styled");
                        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    }, 500);
                    return;
                }
            } else {
                $('#btn-n-pass').text("").prop('disabled', false)
                    .append('Enviar')
                    .removeClass("btn-guay-processing-rp");
                $("#form-n-pass :input").prop("disabled", false);
                Swal.fire({
                    title: '<p class="text-white mb-2">¡Atención!</p>',
                    text: "Debe registrar la información solicitada.",
                    showCloseButton: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    dangerMode: true
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                return;
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Hide and show text in inputs
    const seePassword = (e) => {
        e.preventDefault();
        try {
            switch (e.target.id) {
                case "icon_eye_1":
                    var input = $("#password_1");
                    if (input.attr("type") === "password") {
                        input.attr("type", "text");
                        $("#icon_eye_1").attr("class", "ft-eye-off");
                    } else {
                        input.attr("type", "password");
                        $("#icon_eye_1").attr("class", "ft-eye");
                    }
                    break;
                case "icon_eye_2":
                    input = $("#password_2");
                    if (input.attr("type") === "password") {
                        input.attr("type", "text");
                        $("#icon_eye_2").attr("class", "ft-eye-off");
                    } else {
                        input.attr("type", "password");
                        $("#icon_eye_2").attr("class", "ft-eye");
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (

        <Fragment>
            <div className="row mt-2">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <h1 className="text-center">
                        <strong><span className="text-green">Recuperación de tu contraseña!</span></strong>
                    </h1>
                    <h2 className="text-center">
                        Estás muy cerca de ingresar a la experiencia <img src="https://canal-denuncia.guay.digital/img/logo_guay_color.png" className="w-10" alt="logo guay"></img>
                    </h2>
                </div>
                <div className="col-md-2"></div>
            </div>
            <section className="col-md-12 mt-5">
                <div className="row">
                    <div className="col-sx-12 col-md-3"></div>
                    <div className="col-sx-12 col-md-6 d-flex align-items-center justify-content-center">
                        <div className="col-sx-12 col-lg-10 col-md-10 box-shoadow-2 p-0">
                            <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                <div className="card-header border-0 pb-0">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h4>Actualización de contraseña</h4>
                                        </div>
                                    </div>
                                </div>
                                {
                                    validate['success'] ?
                                        <div className="card-content mt-2">
                                            <div className="card-body">
                                                <form className="form-horizontal" id="form-n-pass">
                                                    <div className="row">
                                                        <div className="col-md-1"></div>
                                                        <div className="col-md-10">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text bg-dark"><i className="la la-lock"></i></span>
                                                                </div>
                                                                <input type="password" className="form-control border-right-0" id="password_1" name="password_1" placeholder="Contraseña nueva" onChange={updatePassword} value={password_1} required></input>
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text icon-eye-form" onClick={seePassword}><i className="ft-eye" id="icon_eye_1"></i></span>
                                                                </div>
                                                            </div>
                                                            <p className="font-small-2 text-base text-center">
                                                                <span className="font-weight-bold">Requisitos:</span> Utiliza más de
                                                                <span className={validation4 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}> 6 carácteres, </span>
                                                                <span className={validation2 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 mayúscula, </span>
                                                                <span className={validation6 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 minúscula, </span>
                                                                <span className={validation3 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 número, </span>
                                                                <span className={validation1 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 símbolo (!@#$%^&*_-.), </span>
                                                                <span className={validation5 === false ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>sin espacios</span>
                                                            </p>
                                                            <div className="input-group mt-1">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text bg-dark"><i className="la la-lock"></i></span>
                                                                </div>
                                                                <input type="password" className="form-control border-right-0" id="password_2" name="password_2" placeholder="Contraseña nueva" onChange={updatePassword} value={password_2} required></input>
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text icon-eye-form" onClick={seePassword}><i className="ft-eye" id="icon_eye_2"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1"></div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4">
                                                            <button type="submit" id="btn-n-pass" onClick={setPassword} className="btn btn-dark btn-block mt-1">Enviar</button>
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        :
                                        <div className="alert alert-danger" role="alert">
                                            <h4 className="alert-heading">¡Ocurrió un problema con la activación de la cuenta</h4>
                                            <p>{validate.message}</p>
                                            <hr />
                                            <p className="mb-0">Si tienes alguna duda contactanos info@guay.digital</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sx-12 col-md-3"></div>
                </div>
            </section>
        </Fragment>

    );
};

export default ResetPassword;