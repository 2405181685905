/*
 File Name: SurveysModel.js
 Description: Manage Surveys API Requests
 guay dev's team
*/
import axios from "axios";
/**
 * Get all data of Estado Emocional survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessEE = async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "survey/validationAccessEE?collaboratorId=" + collaboratorId + "&idEnterprise=" + idEnterprise + "&surveyId=" + surveyId + "&idGroup=" + idGroup + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Save individual response in the Estado Emocional survey
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseEE = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idAnswer: idAnswer,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseEE",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Finish the Estado Emocional survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @param {string} sections 
 * @returns 
 */
export const updFinishSurveyEE = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    let formData = new FormData();
    formData.append("collaboratorId", collaboratorId);
    formData.append("idEnterprise", idEnterprise);
    formData.append("surveyId", surveyId);
    formData.append("idGroup", idGroup);
    formData.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/updFinishSurveyEE",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Get all data of Demografíco survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessDemographic = async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "survey/validationAccessDemographic?collaboratorId=" + collaboratorId + "&idEnterprise=" + idEnterprise + "&surveyId=" + surveyId + "&idGroup=" + idGroup + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Save individual response in the Demografíco survey
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseDemographic = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idAnswer: idAnswer,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseDemographic",
            method: 'POST',
            data: formData,
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Finish the Demografíco survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @param {string} sections 
 * @returns 
 */
export const updFinishSurveyDemographic = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    let formData = new FormData();
    formData.append("collaboratorId", collaboratorId);
    formData.append("idEnterprise", idEnterprise);
    formData.append("surveyId", surveyId);
    formData.append("idGroup", idGroup);
    formData.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/updFinishSurveyDemographic",
            method: 'POST',
            data: formData,
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const validationAccessClimate = async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "survey/validationAccessClimate?collaboratorId=" + collaboratorId + "&idEnterprise=" + idEnterprise + "&surveyId=" + surveyId + "&idGroup=" + idGroup + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Save individual answers in climate survey
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseClimate = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idAnswer: idAnswer,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseClimate",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Finish the climate survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @param {string} sections 
 * @returns 
 */
export const updFinishSurveyClimate = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    let formData = new FormData();
    formData.append("collaboratorId", collaboratorId);
    formData.append("idEnterprise", idEnterprise);
    formData.append("surveyId", surveyId);
    formData.append("idGroup", idGroup);
    formData.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/updFinishSurveyClimate",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Save the open question in climate survey
 * @param {int} idSection 
 * @param {string} comment 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseClimateOpen = async (idSection, comment, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idSection: idSection,
        comment: comment,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseClimateOpen",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Save individual response in climate demographic survey
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseDemogClimate = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idAnswer: idAnswer,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseDemogClimate",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Finish the climate demographic survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @param {string} sections 
 * @returns 
 */
export const updFinishSurveyDemogClimate = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    let formData = new FormData();
    formData.append("collaboratorId", collaboratorId);
    formData.append("idEnterprise", idEnterprise);
    formData.append("surveyId", surveyId);
    formData.append("idGroup", idGroup);
    formData.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/updFinishSurveyDemogClimate",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Get all data of Cultura Organizacional survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @returns 
 */
export const validationAccessCulture = async (collaboratorId, idEnterprise, surveyId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "survey/validationAccessCulture?collaboratorId=" + collaboratorId + "&idEnterprise=" + idEnterprise + "&surveyId=" + surveyId + "&idGroup=" + idGroup + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Save individual response in the Cultura Organizacional survey
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseCulture = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idAnswer: idAnswer,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseCulture",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Finish the Cultura Organizacional survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @param {string} sections 
 * @returns 
 */
export const updFinishSurveyCulture = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    let formData = new FormData();
    formData.append("collaboratorId", collaboratorId);
    formData.append("idEnterprise", idEnterprise);
    formData.append("surveyId", surveyId);
    formData.append("idGroup", idGroup);
    formData.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/updFinishSurveyCulture",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

// culture

/**
 * Save individual response in culture demographic survey
 * @param {int} idAnswer 
 * @param {int} idQuestion 
 * @param {int} idEnterprise 
 * @param {int} idGroup 
 * @param {int} idCollab 
 * @param {string} userDate 
 * @param {string} coordinates 
 * @param {string} browser 
 * @param {string} os 
 * @param {string} userIP 
 * @param {string} providerIP 
 * @returns 
 */
export const saveResponseDemogCulture = async (idAnswer, idQuestion, idEnterprise, idGroup, idCollab, userDate, coordinates, browser, os, userIP, providerIP) => {
    let request = {
        idAnswer: idAnswer,
        idQuestion: idQuestion,
        idEnterprise: idEnterprise,
        idGroup: idGroup,
        idCollab: idCollab,
        userDate: userDate,
        coordinates: coordinates,
        browser: browser,
        os: os,
        userIP: userIP,
        providerIP: providerIP
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/saveResponseDemogCulture",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Finish the culture demographic survey
 * @param {int} collaboratorId 
 * @param {int} idEnterprise 
 * @param {int} surveyId 
 * @param {int} idGroup 
 * @param {string} sections 
 * @returns 
 */
export const updFinishSurveyDemogCulture = async (collaboratorId, idEnterprise, surveyId, idGroup, sections) => {
    let formData = new FormData();
    formData.append("collaboratorId", collaboratorId);
    formData.append("idEnterprise", idEnterprise);
    formData.append("surveyId", surveyId);
    formData.append("idGroup", idGroup);
    formData.append("sections", sections);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
            },
            url: global.base_url + "survey/updFinishSurveyDemogCulture",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}