/*
 File Name: Catalog_model.js
 Description: Manage Generic Catalog API Requests
 guay dev's team
*/
import axios from "axios";
// TODO: Tools
import * as toastr from 'toastr'

/*
  Get Product's Catalog
*/
export const getProducts = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getProducts",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/*
  Get Products categorie's Catalog
*/
export const getSubcategories = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getCategories",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getEnterprisesCategory = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getEnterprisesCategory?enterpriseId=" + enterpriseId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getEnterpriseArea = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getEnterpriseArea?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/*
* Get sectors
*/
export const getSectors = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getSectorCat",
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/*
* Get sectors by sector ID
*/
export const getSubSectors = async (subSectorId) => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getSubSectorCat?idSector=" + subSectorId,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/*
* Get general address by zip ID
*/
export const getAddressByCP = async (cp) => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getZipInfo?idZip=" + cp,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/*
* Get countries
*/
export const getCountries = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getCountryCat",
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get all type of launches that exist
 * @returns JSON response
 */
export const getLaunches = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getTypeLaunch",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get catalog of reminders
 * @returns 
 */
export const getReminders = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getReminderLaunch",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/*
  Get International Prefix's Catalog
*/
export const getInternationalPrefix = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getCodesCountries",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get all the products in AppShop
 * @returns 
 */
export const getGuayShop = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getGuayShop",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get all the products in AppBenefits
 * @returns 
 */
export const getGuayBenefits = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getGuayBenefits",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idProduct 
 * @param {*} idCategory 
 * @param {*} idSubcategory 
 * @returns 
 */
export const getAchievementByEnterprise = async (idEnterprise, idProduct, idCategory, idSubcategory, idUser, idRol) => {
    try {
        let result = await axios({
            url: global.base_url + `outcome/getAchievementByEnterprise?idEnterprise=${idEnterprise}&idProduct=${idProduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}&idUser=${idUser}&idRol=${idRol}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Metodo para obtener las categorias de los protocolos
 * @returns JSON
 */
export const getActionProtocols = async () => {
    try {
        let result = await axios({
            url: global.base_url + "tracking/getActionProtocols",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener las categorias de los protocolos
 * @returns JSON
 */
 export const getActionProtocolsByProduct = async (idProduct, idCategory, idSubCategory) => {
    try {
        let result = await axios({
            url: global.base_url + `tracking/getActionProtocolsByProduct?idProduct=${idProduct}&idCategory=${idCategory}&idSubCategory=${idSubCategory}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @returns 
 */
export const getWebinars = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getWebinars",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getwebBrowsers = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getwebBrowsers",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getRoleAssignmentByEnterprise = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + `catalogs/getRoleAssignmentByEnterprise?idEnterprise=${idEnterprise}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getProfileCatalogs = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getProfileCatalogs`,
            headers: {
                Authorization: global.tokenAuth,
            }
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

export const getResources = async (idProdduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}Resources/getResources?idProduct=${idProdduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

/**
 * 
 * @returns 
 */
export const getPromotionCategory = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getPromotionCategory`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                default:
                    obj.data = result.data
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @returns 
 */
export const getPromotionType = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getPromotionType`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                default:
                    obj.data = result.data
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}


/**
 * 
 * @returns 
 */
export const getGenderExtCatalog = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getGenderExtCatalog`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @returns 
 */
export const getDentalPlanExtCatalog = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getDentalPlanExtCatalog`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @returns 
 */
export const getCatalogSigmaHour = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getCatalogSigmaHour`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 1000:
                    toastr.info('No se econtraron horas activas disponibles.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @returns 
 */
export const getCatalogSigmaTypeReason = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getCatalogSigmaTypeReason`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 1000:
                    toastr.info('No se econtraron razones activas disponibles.', '¡Ooops!')
                    obj.validation = false
                    obj.data = result.data
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
