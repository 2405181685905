import React from 'react';
// Tools
import $ from "jquery";

const ComplainChannelModal = ({ urlComplainChannel }) => {
    return (
        <div
            className="modal"
            id="complainChannel"
            style={
                {
                    backgroundColor: 'rgba(255,255,255,0.9)',
                }
            }
            data-backdrop="false"
        >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <button
                        className="close"
                        style={
                            {
                                position: 'absolute',
                                top: '-20px',
                                right: '-20px',
                                opacity: '1',
                                fontSize: '2rem',
                            }
                        }
                        onClick={() => {
                            $("#complainChannel").hide();
                        }}
                    >&times;</button>
                    <div
                        className="modal-body p-0"
                        style={
                            {
                                height: 'calc(100vh - 8rem)',
                            }
                        }
                    >
                        <iframe
                            src={urlComplainChannel}
                            style={
                                {
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    border: 'none',
                                }
                            }
                            title="Canal de denuncia"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComplainChannelModal;
