import React from 'react';
const AdvanceCerrado = ({ advCerrados }) => {

    const barOptions = (percentage) => {
        let width = 0;
        let backgroundColor = "#efeff6";
        if (percentage <= 30) {
            //yellow
            width = `${percentage}%`;
            backgroundColor = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            width = `${percentage}%`;
            backgroundColor = "#2188b4";
        } else if (percentage > 70) {
            //green
            width = `${percentage}%`;
            backgroundColor = "#9cb537";
        } else {
            //other
            width = "50%";
            backgroundColor = "#71758d";
        }
        return {
            width,
            backgroundColor
        }
    };

    function writeLaunchName(advance) {
        let textTitle = "";
        if (advance.type_launch === 4) {
            textTitle = "NOM035 (CONJUNTO)"
        } else {
            switch (advance.idSubCategorie) {
                case 1:
                    textTitle = `${advance.Product} (${advance.Categorie}-F1)`;
                    break;
                case 2:
                    textTitle = `${advance.Product} (${advance.Categorie}-F2)`;
                    break;
                default:
                    textTitle = `${advance.Categorie}`;
                    break;
            }
        }
        return textTitle;
    }

    function writeSubLaunchName(advance) {
        let textSub = "";
        switch (advance.type_launch) {
            case 1:
                textSub = "Normativo";
                break;
            case 2:
                textSub = "Complementario";
                break;
            case 3:
                textSub = "Estándar";
                break;
            case 4:
                textSub = "Normativo";
                break;
            default:
                textSub = "";
                break;
        }
        return textSub;
    }

    const percentageOptions = (percentage) => {
        let color = "#efeff6";
        if (percentage <= 30) {
            //yellow
            color = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            color = "#2188b4";
        } else if (percentage > 70) {
            //green
            color = "#9cb537";
        } else {
            //other
            color = "#71758d";
        }
        return {
            color
        }
    };

    if (advCerrados.length !== 0) {
        return (
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="rounded with-box-shadow d-flex pr-1">
                        <div className="vertical-text rounded-right bg-blue-navy text-white text-center font-medium-2 py-1">concluidos</div>
                        <div className="accordion w-100 h-100 bg-blue-navy rounded-right" id="accordionAvancesConcluidos">
                            {
                                advCerrados.map((advance, idx) => {
                                    return (
                                        <div className="card rounded-0 m-0 without-box-shadow" key={idx}>
                                            <div className="card-header" id={`heading-${advance.idGroup}`}>
                                                <h2 className="mb-0">
                                                    <div className="d-flex align-items-start">
                                                        <div className="col-2 font-medium-2 flex-wrap align-self-center">{writeLaunchName(advance)}</div>
                                                        <div className="col-2 font-small-2 flex-wrap align-self-center text-blue"><p className="p-0 m-0">{advance.Launch}</p><p className="p-0 m-0">{writeSubLaunchName(advance)}</p></div>
                                                        <div className="col-1 font-small-2 align-self-center">cerrado</div>
                                                        <div className="col-5 pt-1">
                                                            <div className="progress">
                                                                <div className="progress-bar" style={barOptions(advance.percent)}></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 align-self-center">
                                                            <div className="row">
                                                                <div className="col-6 font-medium-2 align-self-center text-center" style={percentageOptions(advance.percent)}>{advance.percent}%</div>
                                                                <div className="col-6 d-flex align-self-center justify-content-center">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="rounded with-box-shadow d-flex pr-1">
                        <div className="vertical-text rounded-right bg-blue-navy text-white text-center font-medium-2 py-2">concluidos</div>
                        <div className="w-100 d-flex justify-content-center align-items-center">
                            <p className="text-center py-2 font-medium-2 text-base">Sin datos</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvanceCerrado
