import React, { useRef } from 'react';

const TabLanzamientosComponent = ({ tipoLanzamiento, settipoLanzamiento }) => {
    const linkNorm = useRef(null);
    const linkComp = useRef(null);

    const handleClickLN = () => {
        linkNorm.current.className = "link-launch-selected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4";
        linkComp.current.className = "link-launch-unselected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4";
        settipoLanzamiento(1)
    }
    const handleClickLC = () => {
        linkNorm.current.className = "link-launch-unselected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4";
        linkComp.current.className = "link-launch-selected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4";
        settipoLanzamiento(2)
    }
    if (tipoLanzamiento === 1) {
        return (
            <div className="row justify-content-center mb-2">
                <div className="col-12 col-sm-8 col-md-10 d-flex justify-content-around">
                    <span ref={linkNorm} className="link-launch-selected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4 pointer" onClick={() => handleClickLN()}>Lanzamiento Normativo</span>
                    <span ref={linkComp} className="link-launch-unselected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4 pointer" onClick={() => handleClickLC()}>Lanzamiento Complementario</span>
                </div>
            </div>
        )
    } else if (tipoLanzamiento === 2) {
        return (
            <div className="row justify-content-center mb-2">
                <div className="col-12 col-sm-8 col-md-10 d-flex justify-content-around">
                    <span ref={linkNorm} className="link-launch-unselected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4 pointer" onClick={() => handleClickLN()}>Lanzamiento Normativo</span>
                    <span ref={linkComp} className="link-launch-selected font-medium-2 rounded px-1 px-sm-1 px-md-3 px-lg-4 pointer" onClick={() => handleClickLC()}>Lanzamiento Complementario</span>
                </div>
            </div>
        )
    } else {
        return (
            <div className="my-5"></div>
        )
    }
}

export default TabLanzamientosComponent
