import React from "react";
import $ from "jquery";
import Swal from 'sweetalert2';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Header from "../Header";
import Menu from "../Menu";
import Footer from "../Footer";
import axios from "axios";
import { postRegisterRol } from "./Model";


export default class RegisterUsers extends React.Component {

  constructor(props) {
    super(props);


    this.state = {

      user: {
        name: "",
        apPaterno: "",
        apMaterno: "",
        email: "",
        password: "",
        enterpriseId: 0,
        rolEnterprise: 0,
        branchEntId: 0,
      },

      listEnterprise: [],
      listRol: [],
      listSubEnterprise: [],

    }



  }

  componentDidMount() {
    let data = this.state.user;
    this.setState({ data });

    axios.get(global.base_url + `enterprise/getEnterpriseActive`)
      .then((res) => {
        this.setState({ listEnterprise: res.data });
      });

    axios.get(global.base_url + `enterprise/getRolActive`)
      .then((res) => {
        this.setState({ listRol: res.data });
      });


  }


  // Change Value form
  getValue = (e) => {
    try {
      let value = e.target.value;
      let nameState = e.target.name;
      let company = this.state.user;
      switch (e.target.name) {

        case "name":
          company[nameState] = value;
          if (value === null || value.length === 0 || /^\s+$/.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");

          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");

          }
          break;
        case "apPaterno":
          company[nameState] = value;
          if (value === null || value.length === 0 || /^\s+$/.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");

          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");

          }
          break;
        case "email":
          company[nameState] = value;
          if (value === null || value.length === 0 || /^\s+$/.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");

          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");

          }
          break;
        case "password":
          company[nameState] = value;

          if (value === null || value.length === 0 || /^\s+$/.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");

          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");

          }
          break;
        case "enterpriseId":
          if (value !== "0") {
            company[nameState] = value;
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            axios.get(global.base_url + `surveyEco/getbranchEnterprise?idEnterprise=` + value)
              .then((res) => {
                this.setState({
                  listSubEnterprise: res.data,
                  enterpriseId: value
                });
              });

          }
          break;
        default:
          company[nameState] = value;
          break;

      }
      this.setState({ company })
    } catch (error) {
      console.log(error);
    }
  }

  setUser = async (e) => {
    e.preventDefault();

    if (this.state.user.name === "") {
      toastr.warning("Debe ingresar el nombre de usuario", "Oops");
      return;
    }

    if (this.state.user.apPaterno === "") {
      toastr.warning("Debe ingresar los apellidos del usuario", "Oops");
      return;
    }
    if (this.state.user.email === "") {
      toastr.warning("Debe ingresar el correo", "Oops");
      return;
    }
    if (this.state.user.password === "") {
      toastr.warning("Debe ingresar la contraseña", "Oops");
      return;
    }
    let company = this.state.user;
    this.setState({ company });
    try {

      postRegisterRol(this.state.user).then((res) => {
        if (res.code === 1) {

          Swal.fire({
            title: '<p class="text-white mb-2">¡Oops!</p>',
            text: "El código postal registrado no existe. Intenta nuevamente",
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            dangerMode: true
          }).then(() => {
            window.location = "/empresas/registro-usuarios";
          });
          $(".swal2-title").addClass("bg-header-swal");
          $(".swal2-actions").addClass("w-100 justify-content-around");
          $(".swal2-confirm").removeClass("swal2-styled");
          $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
          $(".swal2-cancel").removeClass("swal2-styled");
          $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");

        }
        else {
          toastr.warning("Ocurrió un problema al registrar la información. Intente nuevamente", "Oops");
        }
      })

    } catch (error) {
      console.log(error)
    }


  }

  render() {
    return (
      <React.Fragment>
        <Header name="Efren" img="" />
        <Menu />
        <div className="app-content content">
          <div className="content-overlay"></div>
          <div className="content-wrapper">
            <div className="content-header row"></div>
            <div className="content-body">

              <section className="row mt-5">
                <div className="col-sx-12 col-md-1"></div>
                <div className="col-sx-12 col-md-10 d-flex align-items-center justify-content-center">
                  <div className="col-sx-12 col-lg-10 col-md-10 box-shoadow-2 p-0">
                    <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                      <div className="card-header border-0 pb-0 font-large-1 text-dark text-center">
                        Registrar Usuarios
                      </div>
                      <div className="card-content mt-2">
                        <div className="card-body">
                          <form className="form-horizontal" id="form-complaint">
                            <div className="row mt-1">

                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="la la-user"></i></span>
                                  </div>
                                  <input type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    id="name"
                                    name="name"
                                    onChange={this.getValue}></input>
                                </div>
                              </div>
                              <div className="col-md-1"></div>

                            </div>

                            <div className="row mt-1">

                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="la la-user"></i></span>
                                  </div>
                                  <input type="text"
                                    className="form-control"
                                    id="apPaterno"
                                    name="apPaterno"
                                    placeholder="Apellidos"
                                    onChange={this.getValue}></input>
                                </div>
                              </div>
                              <div className="col-md-1"></div>

                            </div>

                            <div className="row mt-1">

                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="la la-at"></i></span>
                                  </div>
                                  <input type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Correo"
                                    onChange={this.getValue}></input>
                                </div>
                              </div>
                              <div className="col-md-1"></div>

                            </div>

                            <div className="row mt-1">

                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="la la-lock"></i></span>
                                  </div>
                                  <input type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Contraseña"
                                    onChange={this.getValue}></input>
                                </div>
                              </div>
                              <div className="col-md-1"></div>

                            </div>

                            <div className="row mt-1">
                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <span className="input-group-text" id="basic-addon1"><i className="la la-industry"></i></span>
                                  <select className="form-control"
                                    name="enterpriseId"
                                    id="enterpriseId"
                                    onChange={this.getValue}>
                                    <option value="0">--Seleccione--</option>
                                    {this.state.listEnterprise.map((optionSector) => (
                                      <option
                                        key={optionSector.enterpriseId}
                                        value={optionSector.enterpriseId}
                                      >
                                        {optionSector.comercial}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="input-group-append">
                                    {/*<button type="button"
                                    className="btn btn-blue"
                                    data-toggle="dropdown">
                                    <i className="la la-caret-square-o-down"></i>
                                 </button>*/}
                                    <div className="dropdown-menu">
                                      {/* eslint-disable-next-line */}
                                      <a className="dropdown-item" href="#">Action</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                            </div>

                            <div className="row mt-1">
                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <span className="input-group-text" id="basic-addon1"><i className="la la-building"></i></span>
                                  <select className="form-control"
                                    name="branchEntId"
                                    id="branchEntId"
                                    onChange={this.getValue}>
                                    <option value="0">--Seleccione--</option>
                                    {this.state.listSubEnterprise.map((optionSub) => (
                                      <option
                                        key={optionSub.branchEntId}
                                        value={optionSub.branchEntId}
                                      >
                                        {optionSub.description}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="input-group-append">
                                    {/*<button type="button"
                                    className="btn btn-blue"
                                    data-toggle="dropdown">
                                    <i className="la la-caret-square-o-down"></i>
                                  </button>*/}
                                    <div className="dropdown-menu">
                                      {/* eslint-disable-next-line */}
                                      <a className="dropdown-item" href="#">Action</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                            </div>


                            <div className="row mt-1">
                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <div className="input-group">
                                  <span className="input-group-text" id="basic-addon1"><i className="la la-user-plus"></i></span>
                                  <select className="form-control"
                                    name="rolEnterprise"
                                    id="rolEnterprise"
                                    onChange={this.getValue}>
                                    <option value="0">--Seleccione--</option>
                                    {this.state.listRol.map((optionRol) => (
                                      <option
                                        key={optionRol.rolEntId}
                                        value={optionRol.rolEntId}
                                      >
                                        {optionRol.description}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="input-group-append">
                                    {/*<button type="button"
                                    className="btn btn-blue"
                                    data-toggle="dropdown">
                                    <i className="la la-caret-square-o-down"></i>
                                   </button>*/}
                                    <div className="dropdown-menu">
                                      {/* eslint-disable-next-line */}
                                      <a className="dropdown-item" href="#">Action</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                            </div>

                            <div className="form-group mt-2 text-center">
                              <button
                                className="btn btn-min-width btn-blue"
                                onClick={this.setUser}>GUARDAR</button>

                            </div>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sx-12 col-md-1"></div>
              </section>



            </div>





          </div>
        </div>



        <Footer />
      </React.Fragment>
    )


  }
}

