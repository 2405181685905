import React from 'react';
const openManual = (section) => {
    const host = window.location.host;
    let url = '';
    switch (host) {
        case "localhost:3000":
            url = 'http://127.0.0.1:5500/html/manual/index.html?';
            break;
        case "frontend.development.guay.digital":
            url = 'https://help.development.guay.digital/html/manual/index.html?';
            break;
        case "frontend.staging.guay.digital":
            url = 'https://help.staging.guay.digital/html/manual/index.html?';
            break;
        case "somosguay.com":
            url = 'https://help.somosguay.com/html/manual/index.html?';
            break;
        default:
            url = 'http://127.0.0.1:5500/html/manual/index.html?';
            break;
    }
    window.open(`${url}${section}`);
}

export const dataHelp = {
    administracionOrganizaciones: {
        title: <h1 className="text-base-guay font-medium-3">Organizaciones</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica para tu organización</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Prepara tu organización y las bases para utilizar tus servicios: <br />
            Agrega tu organización(es) o grupo(s): <br />
            * Registra sus datos principales, configura el tipo de comunicación y el Canal de Denuncia (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YWx0YW9yZw==')}>Saber más</span>) <br />
            * Completa los datos Específicos (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9ZGF0b19lc3BlY2lmaWNv')}>Saber más</span>) y Facturación (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9ZGF0b19mYWN0dXJh')}>Saber más</span>). <br />
            <i className="text-base-guay">Nota: El correo institucional registrado, será el punto de contacto que tenga guay con la Organización.</i>
        </p>,
        links: [{ text: 'Configura los usuarios para la organización', link: '/administracion-usuarios' },
        { text: 'Alta de colaboradores', link: '/administracion-colaboradores' }]
    },
    administracionOrganizacionesAdministracionUsuarios: {
        title: <h1 className="text-base-guay font-medium-3">Usuarios y roles</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica Usuarios y roles</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">Aquí podrás dar de alta, eliminar y editar al personal de elijas como tu equipo guay. <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YWx0YXVzdWFyaW8=')}>Saber más</span> <br />
            <br />
            Conoce los privilegios que le puedes asignar a los usuarios, da click en <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9ZGVmaW5pY2lvbg==')}>Saber más</span> <br />
            <br />
            <i className="text-base-guay">Nota: A los usuarios nuevos les llegara un correo electrónico de bienvenida, luego tendrán que activar su Cuenta.</i> </p>,
        links: [{ text: 'Alta de colaboradores', link: '/administracion-colaboradores' }]
    },
    administracionOrganizacionesColaboradores: {
        title: <h1 className="text-base-guay font-medium-3">Colaboradores</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica Colaboradores</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Aquí podrás dar de alta Colaboradores, Centros de Trabajo y Áreas <br />
            * Llena la plantilla. <br />
            <i className="text-base-guay">Nota: No se deberá alterar ni modificar la estructura de las plantillas</i><br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y29sYWJwbGFudGlsbGE=')}>Saber más</span><br />
            * Revisa los datos de los colaboradores<br />
            * Ubica, en su caso edita, cada uno de tus Centros de Trabajo (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y3RfYWdyZWdhcg==')}>Saber más</span>)<br />
            * Observa los totales de áreas y cargos de la organización (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YV9hZ3JlZ2Fy')}>Saber más</span>)<br />
            * Siempre deberás dar click en *"Continuar"* para confirmar la información<br />
            <br />
            Más adelante podrás:<br />
            * Agregar, dar de baja, homologar (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y3RfaG9tb2xvZ2Fy')}>Saber más</span>) o eliminar la información
        </p>,
        links: [{ text: 'Configura tus servicios: ingresa desde Administración', link: '/inicio-administrador' }]
    },
    inicioTienda: {
        title: <h1 className="text-base-guay font-medium-3">Tienda</h1>,
        /* subtitle: <h1 className="text-blue-sec font-medium-2">Tienda</h1>, */
        subtitle: '',
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Da click en el menú que está de lado izquierdo de los productos para ver breve descripción.<br />
            <br />
            ¿Te interesa alguno de los servicios?, Al final de la pantalla encontrarás los datos de contacto guay ó si lo prefieres puedes ingresar tu correo, teléfono y el horario para alguien de nuestro equipo te pueda contactar.<br />
            <br />
            <i className="text-base-guay">Nota: Solicitar a Soporte que se habilite el producto si es la primera vez  que se va a configurar.</i><br />
            <br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9dGllbmRh')}>Saber más</span>
        </p>,
        links: []
    },
    inicioTiendaColab: {
        title: <h1 className="text-base-guay font-medium-3">Tienda</h1>,
        /* subtitle: <h1 className="text-blue-sec font-medium-2">Tienda</h1>, */
        subtitle: '',
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Da click en el menú que está de lado izquierdo de los productos para ver breve descripción.<br />
            <br />
            ¿Te interesa alguno de los servicios?, Al final de la pantalla encontrarás los datos de contacto guay ó si lo prefieres puedes ingresar tu correo, teléfono y el horario para alguien de nuestro equipo te pueda contactar.<br />
            <br />
            <i className="text-base-guay">Nota: Solicitar a Soporte que se habilite el producto si es la primera vez  que se va a configurar.</i>
        </p>,
        links: []
    },
    administracion: {
        title: <h1 className="text-base-guay font-medium-3">Diagnóstico</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2"><b>Diagnósticos,</b> aquí verás cada servicio adquirido, para administrarlo sigue los siguientes pasos:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Configura: Programa los cuestionarios y la comunicación (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y29uZmlndXJhY2lvbg==')}>Saber más</span>)<br />
            Ejecución: Conoce la participación y resultados de las encuestas. Guarda los documentos del servicio (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9ZWplY3VjaW9u')}>Saber más</span>)<br />
            Resultados: Consulta resultados y analíticos (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9cmVzdWx0YWRvcw==')}>Saber más</span>)<br />
            Acciones: Analiza y adopta las acciones y protocolos que ayuden a mejorar tu organización (<span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YWNjaW9uZXM=')}>Saber más</span>)<br />
            Cumplimiento: <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y3VtcGxpbWllbnRv')}>Saber más</span><br />
            <br />
            En <b>Administración,</b> encontrarás accesos rápidos para la configuración de tus organizaciones, también podrás acceder al enlace del quiosco <br />
            Quiosco: <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9cXVpb3Njbw==')}>Saber más</span>
        </p>,
        links: []
    },
    administracionConfiguraLanzamientos: {
        title: <h1 className="text-base-guay font-medium-3">Configuración</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica para tu configuración:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Configura tus servicios:<br />
            Programa tu lanzamiento<br />
            * Elige el tipo de lanzamiento<br />
            * Verifica que tus colaboradores estén actualizados<br />
            * Completa los datos del formulario de acuerdo al lanzamiento que programes<br />
            * Da click en "Continuar"<br />
            <i className="text-base-guay">Nota: La fecha mínima para lanzar es de 3 días hábiles. Para cambios de fecha sólo se permitirá hasta 3 días antes de que se cumpla el plazo</i><br />
            <br />
            Comunicados<br />
            * Elige la fecha de inicio de comunicación<br />
            * Da click en "Guardar"<br />
            * Valida que tu programación sea correcta, puedes regresar a editar si lo deseas<br />
            <br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y29uZmlndXJhY2lvbg==')}>Saber más (Configuración general)</span><br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9cmVzdWxfbm9tMzU=')}>Saber más (Nom - 035)</span><br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9cmVzdWxfY2xpbWFsYWI=')}>Saber más (Clima Laboral)</span><br />
        </p>,
        links: [{ text: 'Monitorea tus servicios', link: '/gestion-documental' },
        { text: 'Difunde los documentos de cumplimiento', link: '/gestion-documental' }]
    },
    administracionMonitoreoAvances: {
        title: <h1 className="text-base-guay font-medium-3">Ejecución</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica de seguimiento de servicios:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Seguimiento a tus servicios:<br />
            Monitorea los ejercicios<br />
            Podrás monitorear de acuerdo a las secciones:<br />
            - "HOY" ejercicios activos, al dar click en el botón (+) mostrará el detalle de cada uno, donde podrás descargar un archivo con los colaboradores pendientes por contestar, "Cerrar la Evaluación" y/o "Actualizar estatus de colaboradores"<br />
            - "PRÓXIMOS" ejercicios que están por comenzar<br />
            - "CONCLUIDOS" ejercicios cerrados<br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9ZWpfbW9uaXRvcmVv')}>Saber más</span>
            <br />
            Gestión Documental<br />
            En este apartado podrás subir los documentos de cumplimiento para cada servicio<br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9ZWpfZ2VzdGlvbg==')}>Saber más</span>
            <br />
            {/* Difusión cumplimiento<br />
            En esta sección eligiras la fecha en la que se divulgarán los documentos a tus colaboradores */}
        </p>,
        links: [{ text: 'Revisa tus resultados', link: '/resultados' }]
    },
    administracionResultadosAnaliticos: {
        title: <h1 className="text-base-guay font-medium-3">Resultados</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica de tus resultados:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Monitorea los ejercicios<br />
            Cuando concluya cada ejercicio dirígete a esta sección de “Resultados”, para conocer los resultados organizacionales y los analíticos obtenidos da click en el botón "Ingresar".<br />
            <br />
            Así obtendrás Resultados, Reportes y Análisis con los indicadores más importantes y priorizados para que tomes decisiones y acciones específicas.<br />
            <br />
            <i className="text-base-guay">Nota: Los resultados varían de acuerdo a la vista que esté seleccionada</i><br />
            <br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9cmVzdWx0YWRvcw==')}>Saber más</span>
        </p>,
        links: [{ text: 'Ingresa a la sección de acciones del servicio deseado', link: '/acciones' }]
    },
    administracionAccionesSiguientesPasos: {
        title: <h1 className="text-base-guay font-medium-3">Acciones</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica de las acciones a realizar:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Siguientes pasos:<br />
            Importante: Estos pasos solo aplican para el Estado emocional de la Organización<br />
            * Selecciona uno a uno a los colaboradores y asignarles el tipo de canalización.<br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YWNuX25vbTM1')}>Saber más</span>
            <br />
            Monitor de Acciones:<br />
            En este módulo das continuidad a los colaboradores que son canalizados, también se muestra la etapa, la actividad y el estatus en el que se encuentran los colaboradores.<br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YWNjaW9uZXM=')}>Saber más</span>
            <br />
            {/* Protocolos de Acción<br />
            Para implementar tus acciones sigue los pasos que se muestran en este módulo. Importante : Los protocolos de Acción solo aplican para el Entorno Laboral */}
        </p>,
        links: [{ text: 'Revisa que no te falte nada para dar cumplimiento a tus servicios', link: '/cumplimiento' }]
    },
    administracionAccionesProtocolos: {
        title: <h1 className="text-base-guay font-medium-3">Acciones</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica de las acciones a realizar:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Protocolos de Acción<br />
            Para implementar tus acciones sigue los pasos que se muestran en este módulo. Importante : Los protocolos de Acción solo aplican para el Entorno Laboral<br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9YWNuX2NsaW1h')}>Saber más</span>
        </p>,
        links: [{ text: 'Revisa que no te falte nada para dar cumplimiento a tus servicios', link: '/cumplimiento' }]
    },
    administracionCumplimientoCumplimiento: {
        title: <h1 className="text-base-guay font-medium-3">Cumplimiento</h1>,
        subtitle: <h1 className="text-blue-sec font-medium-2">Información básica para cumplir con tus servicios:</h1>,
        paragraph: <p className="text-blue-sec text-justify font-medium-1">
            Aquí verás el tracking de los elementos que debes presentar ante una autoridad.<br />
            <br />
            Verifica que requerimiento está pendiente para recolectar o subir  la evidencia  existen dos estatus:<br />
            * Completo<br />
            * Pendiente<br />
            <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9Y3VtcGxpbWllbnRv')}>Saber más</span><br />
            <br />
            Da click en el ícono <i className="ft-download text-blue font-medium-5" /> para descargar cada documento.
        </p>,
        links: []
    },
}

export const nextSteps = {
    inicioEmpresas: {
        links: [
            { text: 'Configura los usuarios para la organización', link: '/administracion-usuarios' },
            { text: 'Alta de colaboradores', link: '/administracion-colaboradores' },
        ]
    },
    administracionColaboradores: {
        links: [
            { text: 'Configura tus servicios: ingresa desde Administración', link: '/inicio-administrador' },
        ]
    },
    acciones: {
        links: [
            { text: 'Revisa que no te falte nada para dar cumplimiento a tus servicios', link: '/cumplimiento' }
        ],
    },
    ejecucion: {
        links: [
            { text: 'Revisa tus resultados', link: '/resultados' }
        ],
    },
}

export const chatStructure = {
    inicioAdministrador: {
        chatBot: [
            { answer: -1, idStructure: 1, idContent: 1, text: [<div>Hola! ¿Cuál sección deseas conocer?<br /><b>1. Administración</b><br /><b>2. Diagnóstico</b></div>], links: [], lists: [], description: [], idUser: 0, lastAns: 0, },
            {
                answer: 1, idStructure: 1, idContent: 1,
                text: [
                    'En Administración, encontrarás accesos rápidos para la configuración de tus organizaciones, también podrás acceder al enlace del quiosco.',
                ],
                links: [
                    {
                        text: 'Para conocer más de quisco da clic',
                        manualLink: 'dmFsdWU9cXVpb3Njbw==',
                        description: 'aqui',
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
            {
                answer: 2, idStructure: 1, idContent: 3,
                text: [
                    {
                        title: 'Configuración',
                        parragrap: 'Programa los cuestionarios y la comunicación.',
                        manualLink: 'dmFsdWU9Y29uZmlndXJhY2lvbg==',
                    },
                    {
                        title: 'Ejecución',
                        parragrap: 'Conoce la participación y resultados de las encuestas. Guarda los documentos del servicio.',
                        manualLink: 'dmFsdWU9ZWplY3VjaW9u',
                    },
                    {
                        title: 'Resultados',
                        parragrap: 'Consulta resultados y analíticos.',
                        manualLink: 'dmFsdWU9cmVzdWx0YWRvcw==',
                    },
                    {
                        title: 'Acciones',
                        parragrap: 'Analiza y adopta las acciones y protocolos que ayuden a mejorar tu organización.',
                        manualLink: 'dmFsdWU9YWNjaW9uZXM=',
                    },
                    {
                        title: 'Cumplimiento',
                        parragrap: '',
                        manualLink: 'dmFsdWU9Y3VtcGxpbWllbnRv',
                    },
                ],
                links: [],
                lists: [],
                description: [],
                idUser: 0,
                lastAns: 1,
            }
        ],
        chatUser: [
            { idUser: 1, idStructure: 2, idContent: 2, text: [{ description: '1. Administración', option: 1 }, { description: '2. Diagnóstico', option: 2 }], lastUser: 1, }
        ],
    },
    inicioEmpresas: {
        chatBot: [
            { answer: 0, idStructure: 1, idContent: 1, text: [<div>Hola! Prepara tu organización y las bases para utilizar tus servicios.<br /><br />¿Cuál sección deseas conocer? <br /> <b>1.</b> Dar de alta <br /> <b>2.</b> Configuración</div>], links: [], lists: [], description: [], idUser: 0, lastAns: 0, },
            {
                answer: 1, idStructure: 1, idContent: 1,
                text: [
                    'Agrega tu organización(es) o grupo(s):',
                    'Revisa el manual guay:',
                ],
                links: [
                    {
                        text: 'Para conocer más para dar de alta Organización da clic',
                        manualLink: 'dmFsdWU9YWx0YW9yZw==',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más para dar de alta Grupo Organizaciónal da clic',
                        manualLink: 'dmFsdWU9Z3J1cG9yZw==',
                        description: 'aqui',
                    },
                ],
                lists: [],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
            {
                answer: 2, idStructure: 1, idContent: 1,
                text: [
                    'Administra tu organización(es) o grupo(s):',
                    'Revisa el manual guay:',
                ],
                links: [
                    {
                        text: 'Para conocer más de Perfil de Organización da clic',
                        manualLink: 'dmFsdWU9ZWRpdGFvcmdh',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más de Facturación da clic',
                        manualLink: 'dmFsdWU9ZGF0b19mYWN0dXJh',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más de Datos Específicos da clic',
                        manualLink: 'dmFsdWU9ZGF0b19lc3BlY2lmaWNv',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más de Usuarios y Roles da clic',
                        manualLink: 'dmFsdWU9YWx0YXVzdWFyaW8=',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más de Colaboradores da clic',
                        manualLink: 'dmFsdWU9Y29sYWJwbGFudGlsbGE=',
                        description: 'aqui',
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
        ],
        chatUser: [
            { idUser: 2, idStructure: 2, idContent: 2, text: [{ description: '1. Dar de alta', option: 1 }, { description: '2. Configuración', option: 2 }], lastUser: 1, }
        ],
    },
    administracionColaboradores: {
        chatBot: [
            { answer: 0, idStructure: 1, idContent: 1, text: [<div>Aquí podrás dar de alta Colaboradores, Centros de Trabajo y Áreas.<br /><br />¿Cuál sección deseas conocer?<br /><b>1. Colaboradores</b><br /><b>2. Centros de Trabajo</b><br /><b>3. Áreas</b></div>], links: [], lists: [], description: [], idUser: 0, lastAns: 0, },
            {
                answer: 1, idStructure: 1, idContent: 4,
                text: [
                    '* Llena la plantilla.',
                    '* Revisa los datos de los colaboradores.',
                    '* Siempre deberás dar click en *"Continuar"* para confirmar la información',
                    'Nota: No se deberá alterar ni modificar la estructura de las plantillas.',
                    'Revisa el manual guay:',
                ],
                links: [],
                lists: [
                    {
                        title: 'Colaboradores',
                        subtitles: [
                            {
                                text: 'Agregar colaboradores por plantilla',
                                manualLink: 'dmFsdWU9Y29sYWJwbGFudGlsbGE=',
                            },
                            {
                                text: 'Agregar colaboradores individuales',
                                manualLink: 'dmFsdWU9Y29sYWJpbmRpdmlkdWFs',
                            },
                            {
                                text: 'Editar',
                                manualLink: 'dmFsdWU9Y29sYWJlZGl0YXI=',
                            },
                        ]
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
            {
                answer: 2, idStructure: 1, idContent: 4,
                text: [
                    'Descripción:',
                    '* Ubica, en su caso edita, cada uno de tus Centros de Trabajo.',
                    '* Siempre deberás dar click en *"Continuar"* para confirmar la información',
                    'Revisa el manual guay:',
                ],
                links: [],
                lists: [
                    {
                        title: 'Centros de Trabajo',
                        subtitles: [
                            {
                                text: 'Agregar ',
                                manualLink: 'dmFsdWU9Y3RfYWdyZWdhcg==',
                            },
                            {
                                text: 'Editar',
                                manualLink: 'dmFsdWU9Y3RfZWRpdGFy',
                            },
                            {
                                text: 'Homologar',
                                manualLink: 'dmFsdWU9Y3RfaG9tb2xvZ2Fy',
                            },
                        ]
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
            {
                answer: 3, idStructure: 1, idContent: 4,
                text: [
                    'Descripción:',
                    '* Observa los totales de áreas y cargos de la organización.',
                    '* Siempre deberás dar click en *"Continuar"* para confirmar la información',
                    'Revisa el manual guay:',
                ],
                links: [],
                lists: [
                    {
                        title: 'Áreas',
                        subtitles: [
                            {
                                text: 'Agregar ',
                                manualLink: 'dmFsdWU9YV9hZ3JlZ2Fy',
                            },
                            {
                                text: 'Editar',
                                manualLink: 'dmFsdWU9YV9lZGl0YXI=',
                            },
                        ]
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
        ],
        chatUser: [
            {
                idUser: 2, idStructure: 2, idContent: 2,
                text: [
                    { description: '1. Colaboradores', option: 1 },
                    { description: '2. Centros de Trabajo', option: 2 },
                    { description: '3. Áreas', option: 3 },
                ],
                lastUser: 1,
            },
        ],
    },
    gestionDocumental: {
        chatBot: [
            { answer: 0, idStructure: 1, idContent: 1, text: [<div>Hola! Aquí puedes ver información básica para el seguimiento de servicios.<br /><br />¿Cuál sección deseas conocer?<br /><b>1. Monitoreo</b><br /><b>2. Gestión Documental</b></div>], links: [], lists: [], description: [], idUser: 0, lastAns: 0, },
            {
                answer: 1, idStructure: 1, idContent: 1,
                text: [
                    <div><b>Monitorea los ejercicios</b><br />
                    Podrás monitorear de acuerdo a las secciones:<br />
                    <b>"HOY"</b> ejercicios activos, al dar click en el botón (+) mostrará el detalle de cada uno, donde podrás descargar un archivo con los colaboradores pendientes por contestar "Cerrar la Evaluación" y/o "Actualizar estatus de colaboradores".<br />
                    <b>"PRÓXIMOS"</b> ejercicios que están por comenzar.<br />
                    <b>"CONCLUIDOS"</b> ejercicios cerrados.<br />
                    Revisa el manual guay:</div>,
                ],
                links: [
                    {
                        text: 'Para conocer más sobre Ejecución da clic',
                        manualLink: 'dmFsdWU9ZWplY3VjaW9u',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más sobre Monitoreo da clic',
                        manualLink: 'dmFsdWU9ZWpfbW9uaXRvcmVv',
                        description: 'aqui',
                    },
                ],
                lists: [],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
            {
                answer: 2, idStructure: 1, idContent: 1,
                text: [
                    <div><b>Gestión Documental</b><br />
                    En este apartado podrás subir los documentos de cumplimiento para cada servicio.<br />
                    Revisa el manual guay:</div>
                ],
                links: [
                    {
                        text: 'Para conocer más sobre Ejecución da clic',
                        manualLink: 'dmFsdWU9ZWplY3VjaW9u',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más de Gestión Documental da clic',
                        manualLink: 'dmFsdWU9ZWpfZ2VzdGlvbg==',
                        description: 'aqui',
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
        ],
        chatUser: [
            { idUser: 2, idStructure: 2, idContent: 2, text: [{ description: '1. Monitoreo', option: 1 }, { description: '2. Gestión Documental', option: 2 }], lastUser: 1, },
        ],
    },
    acciones: {
        chatBot: [
            { answer: 0, idStructure: 1, idContent: 1, text: [<div>Hola! Aquí puedes ver información básica de las acciones a realizar.<br /><br />¿Cuál sección deseas conocer?<br /><b>1. Siguientes pasos</b><br /><b>2. Monitor de Acciones</b></div>], links: [], lists: [], description: [], idUser: 0, lastAns: 0, },
            {
                answer: 1, idStructure: 1, idContent: 1,
                text: [
                    <div>
                        <b>Siguientes pasos</b><br />
                        <b>*</b> Selecciona uno a uno a los colaboradores y asignales el tipo de canalización.<br />
                        <b>Importante:</b> Estos pasos solo aplican para el Estado emocional de la Organización.<br />
                        Revisa el manual guay:<br />
                        <br />
                    </div>
                ],
                links: [
                    {
                        text: 'Para conocer más sobre Acciones da clic',
                        manualLink: 'dmFsdWU9YWNjaW9uZXM=',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más sobre Siguientes pasos de la NOM-035 da clic',
                        manualLink: 'dmFsdWU9YWNuX25vbTM1',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más sobre Siguientes pasos de Clima laboral da clic',
                        manualLink: 'dmFsdWU9YWNuX2NsaW1h',
                        description: 'aqui',
                    },
                ],
                lists: [],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
            {
                answer: 2, idStructure: 1, idContent: 1,
                text: [
                    <div>
                        <b>Monitor de Acciones</b><br />
                        En este módulo das continuidad a los colaboradores que son canalizados, también se muestra la etapa, la actividad y el estatus en el que se encuentran los colaboradores.<br />
                        Revisa el manual guay:<br />
                    </div>
                ],
                links: [
                    {
                        text: 'Para conocer más sobre Acciones da clic',
                        manualLink: 'dmFsdWU9YWNjaW9uZXM=',
                        description: 'aqui',
                    },
                    {
                        text: 'Para conocer más de Monitor de Acciones da clic',
                        manualLink: 'dmFsdWU9YWNuX25vbTM1',
                        description: 'aqui',
                    },
                ],
                description: [],
                idUser: 0,
                lastAns: 1,
            },
        ],
        chatUser: [
            { idUser: 2, idStructure: 2, idContent: 2, text: [{ description: '1. Siguientes pasos', option: 1 }, { description: '2. Monitor de Acciones', option: 2 }], lastUser: 1, },
        ],
    },
}