import React, { useState, useEffect } from 'react'
import { getJwt } from "../../../lib/auth"
import { getOutcomeByEnterprise } from '../Models/Outcome_model'
import Swal from 'sweetalert2';
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import $, { isEmptyObject } from 'jquery'
import { useHistory } from "react-router-dom";

const Analitics = ({ setLigaMicro }) => {

    const [listProducts, setListProducts] = useState([])
    const enterpriseId = getJwt("enterprise")
    const userId = getJwt("jtw")
    const history = useHistory();

    useEffect(() => {
        getOutcomeByEnterprise(enterpriseId, userId).then((response) => {
            switch (response?.code) {
                case 1:
                    const arrayProducts = []
                    response.listProducts.forEach(product => {
                        let arrayFichas = []
                        if (product.listCategorys !== undefined && !isEmptyObject(product.listCategorys)) { //checar si el array/objeto esta vacio
                            product.listCategorys.forEach(category => {
                                if (category.listSubCategorys !== undefined) { //checar si el array existe
                                    category.listSubCategorys.forEach(subcategory => {
                                        if (subcategory.processed === 1) {
                                            arrayFichas.push(subcategory)
                                        }
                                    })
                                } else {
                                    if (category.processed === 1) {
                                        arrayFichas.push(category)
                                    }
                                }
                            })
                        }
                        if (arrayFichas.length !== 0) { //checamos si tiene fichas
                            arrayProducts.push({
                                title: product.title,
                                description: product.description,
                                productId: product.productId,
                                fichas: arrayFichas,
                            })
                        }
                    })
                    setListProducts(arrayProducts)
                    break
                case 1001:
                    toastr.error("La organización no se encuentra activa")
                    break
                case 1002:
                    toastr.error("Actualmente sus servicios no se encuentran activos")
                    break
                case 1003:
                    toastr.info("Actualmente no se tiene información")
                    break

                default:
                    console.error(response)
                    break
            }
        })
        return () => {
        }
        // eslint-disable-next-line
    }, [enterpriseId])

    function handleClickButton(url, option, idCategory) {
        switch (option) {
            case 1:
                history.push('/acciones');
                break;
            case 2:
                sessionStorage.setItem('menuOption', 1);
                history.push('/acciones');
                break;
            default:
                if (idCategory === 6) {
                    Swal.fire({
                        title: '<p class="text-white mb-2">Espera</p>',
                        text: 'Estamos trabajando para traerte las mejores herramientas',
                        showCloseButton: true,
                        showConfirmButton: false,
                        showCancelButton: true,
                        focusCancel: true,
                        cancelButtonText: 'Regresar',
                    })
                } else {
                    setLigaMicro({
                        url,
                        dossier: (idCategory === 6 && true),
                        show: true
                    });
                }
                break;
        }
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="row my-1">
                            <div className="col-12">
                                <p className="font-medium-2 font-weight-bold text-blue-sec">Ingresa tus analíticos:</p>
                            </div>
                        </div>
                        {listProducts.length !== 0 ?
                            listProducts.map((product, idx) => {
                                return (
                                    <div key={idx}>
                                        <div className="row mb-2 mx-1">
                                            <div className="col-auto">
                                                <p className="font-medium-2 lead text-blue-sec">{product.title}</p>
                                            </div>
                                            <div className="col">
                                                <hr style={{ borderTop: "3px solid rgba(0, 0, 0, 10%)" }} />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <div className="row justify-content-around">
                                                    {product.fichas.map((ficha, idx) => {
                                                        return (
                                                            <div className="col-3 mb-2" key={idx}>
                                                                <div style={{ maxWidth: "18rem" }} className="card text-center with-box-shadow h-100 mb-0">
                                                                    <div className="card-header h-25 d-flex align-items-center justify-content-center">
                                                                        <h5 className="card-title">{ficha.title}</h5>
                                                                    </div>
                                                                    <div style={{ maxHeight: "8rem", maxWidth: "18rem" }}>
                                                                        <img src={ficha.imgApp} className="cut-img" alt={ficha.description} />
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <button className="btn btn-small btn-blue font-small-3 px-3 text-white" onClick={() => handleClickButton(ficha.url, 0, ficha.idCategory)}>Ingresar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="row my-1">
                                <div className="col mb-2">
                                    <h5 className="font-medium-4 text-center font-weight-lighter font-italic">Sin información por el momento</h5>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analitics
