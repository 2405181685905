import React, { useState, useEffect } from "react";
// Components
import Header from '../NewHome/headerAdministrador';
import Menu from "../Menu";
import Footer from '../footerApp';
import DocumentManagement from './DocumentManagement';
import DocumentManagementGuay from './DocumentManagementGuay';
import AdminAvance from "../monitor/AdminAvance";
import LoaderApp from '../LoaderApp';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import BannerAdmin from "../BannerAdmin";
// API calls
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import { getProductsDocuments, getDocumentsByProduct } from '../Models/DocumentalModel';
import MonitoreoMail from "../MailGun/MonitoreoMail";
import Monitoreo from "../MailGun/monitoreo/Monitoreo";
/* import DateDiff from "../services/DateDiff"; */
import { connect } from 'react-redux';
import { validateAccess, getSubsection, getSubsectionIn } from "../../helpers/validations";
import { useHistory } from "react-router-dom";
import HelperApp from "../HelperApp";

const DocumentManagementController = ({ access }) => {

    // Variables
    const [diffusionVia, setDiffusionVia] = useState(0);
    const [loading, setLoading] = useState(true);
    const [listDocNecProducts, setListDocNecProducts] = useState([]);
    const [service, setService] = useState(0);
    const [listInfoDocuments, setListInfoDocuments] = useState([]);
    const [idProductSelected, setIdProductSelected] = useState(0);
    const [idCategorySelected, setIdCategorySelected] = useState(0);
    const [noDocuments, setNoDocuments] = useState(false);
    const [menuStep, setMenuStep] = useState(0);

    //const [subsectionList, setSubsectionList] = useState([])

    const [justReadSteps, setJustReadSteps] = useState({
        step_1: false,
        step_2: false,
        step_3: false,
        step_4: false,
        step_5: false,
    })
    const {
        step_1,
        step_2,
        //step_3,
        step_4,
    } = justReadSteps

    const [menuOptionAccess, setMenuOptionAccess] = useState({
        option_1: [],
        option_2: [],
        option_3: [],
        option_4: [],
    })
    const {
        option_1,
        option_2,
        option_3,
        option_4,
    } = menuOptionAccess

    const history = useHistory();

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && access.access.list.length > 0) {
            //console.log(access)
            //console.log(validateAccess(access.access.list, 2))
            const section = validateAccess(access.access.list, 2)
            //console.log(getSubsection(section[0].subSection, 15))
            //setSubsectionList(getSubsection(section[0].subSection, 15))
            const subsection = getSubsection(section[0].subSection, 15)
            //console.log(getSubsectionIn(subsection[0].subSectionIn, 34))
            const usuarios = getSubsectionIn(subsection[0].subSectionIn, 34)
            const avance = getSubsectionIn(subsection[0].subSectionIn, 35)
            const correos = getSubsectionIn(subsection[0].subSectionIn, 36)
            const gestion = getSubsectionIn(subsection[0].subSectionIn, 37)
            setMenuOptionAccess({
                option_1: usuarios,
                option_2: avance,
                option_3: correos,
                option_4: gestion,
            })
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            } else {
                if (subsection[0].id_action === 0) {
                    toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                    history.push("/inicio-administrador")
                } else {
                    if (avance[0].id_action === 0) {
                        if (usuarios[0].id_action === 0) {
                            if (correos[0].id_action === 0) {
                                if (gestion[0].id_action === 0) {
                                    toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                                    history.push("/inicio-administrador")
                                } else {
                                    if (gestion[0].id_action === 2) {
                                        setJustReadSteps({
                                            ...justReadSteps,
                                            step_4: true,
                                        })
                                        setMenuStep(5)
                                    } else {
                                        setMenuStep(5)
                                    }
                                }
                            } else {
                                if (correos[0].id_action === 2) {
                                    setJustReadSteps({
                                        ...justReadSteps,
                                        step_3: true,
                                    })
                                    setMenuStep(3)
                                } else {
                                    setMenuStep(3)
                                }
                            }
                        } else {
                            if (usuarios[0].id_action === 2) {
                                setJustReadSteps({
                                    ...justReadSteps,
                                    step_1: true,
                                })
                                setMenuStep(4)
                            } else {
                                setMenuStep(4)
                            }
                        }
                    } else {
                        if (avance[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_2: true,
                            })
                            setMenuStep(0)
                        } else {
                            setMenuStep(0)
                        }
                    }
                }
            }
        }
        getProductsDocuments(getJwt('enterprise')).then((response) => {
            switch (response.code) {
                case 1:
                    try {
                        let ids = sessionStorage.getItem("idp-idc").split("-");
                        let idProduct = parseInt(ids[0]);
                        let idCategory = parseInt(ids[1]);
                        setIdCategorySelected(idCategory);
                        setIdProductSelected(idProduct);
                        setListDocNecProducts(response.listDocNecProducts);
                        getDocumentsByProduct(getJwt('enterprise'), idProduct, idCategory).then((response) => {
                            switch (response.code) {
                                case 1:
                                    for (let x in response.listInfoDocuments) {
                                        let completeDate = response.listInfoDocuments[x].lastUpdate;
                                        let date = completeDate.split(" ");
                                        let shortDate = date[0];
                                        response.listInfoDocuments[x].lastUpdate = shortDate;
                                        if (response.listInfoDocuments[x].flagSend === 2) {
                                            setDiffusionVia(1);
                                        }
                                    }
                                    setListInfoDocuments(response.listInfoDocuments);
                                    setLoading(false);
                                    break;
                                case 404:
                                    toastr.info("No se encontraron documentos", "¡Ooops!");
                                    setLoading(false);
                                    break;
                                case 1001:
                                    toastr.error("La empresa no existe o está inactiva.", "Error");
                                    setLoading(false);
                                    break;
                                case 1002:
                                    setNoDocuments(true);
                                    /* toastr.info("No requiere documentos necesarios.", "¡Ooops!"); */
                                    setLoading(false);
                                    break;
                                default:
                                    toastr.error("Recargue la pagina, por favor", "Error");
                                    setLoading(false);
                                    break;
                            }
                        });
                    } catch (error) {
                        // console.error(error);
                    }
                    break;
                case 404:
                    toastr.info("No se encontraron documentos", "¡Ooops!");
                    setLoading(false);
                    break;
                case 1001:
                    toastr.error("La empresa no existe o está inactiva.", "Error");
                    setLoading(false);
                    break;
                case 1002:
                    toastr.info("No requiere documentos necesarios.", "¡Ooops!");
                    setLoading(false);
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "Error");
                    setLoading(false);
                    break;
            }
        });
        // eslint-disable-next-line
    }, [access.access.list]);

    useEffect(() => {
        // const step = sessionStorage.getItem("step");
        // //console.log(step);
        // if (step === "1") {
        //     setMenuStep(1);
        //     sessionStorage.removeItem("step");
        // } else if (step === "2") {
        //     setMenuStep(2);
        //     sessionStorage.removeItem("step");
        // } else if (step === "3") {
        //     setMenuStep(3);
        //     sessionStorage.removeItem("step");
        // } else if (step === "4") {
        //     setMenuStep(4);
        //     sessionStorage.removeItem("step");
        // }
        // // eslint-disable-next-line
        if (sessionStorage.getItem('step') !== null) {
            let value = sessionStorage.getItem('step').split(',')
            let step = parseInt(value[0])

            setMenuStep(step)
        }
    }, []);

    const handleAccess = (step) => {
        switch (step) {
            case 4:
                if (parseInt(getJwt('rol')) > 6) {
                    if (option_1[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_1[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_1: true,
                            })
                            setMenuStep(4)
                            sessionStorage.setItem('step', `4,${window.location.pathname}`)

                        } else {
                            setMenuStep(4)
                            sessionStorage.setItem('step', `4,${window.location.pathname}`)
                        }
                    }
                } else {
                    setMenuStep(4)
                    sessionStorage.setItem('step', `4,${window.location.pathname}`)
                }
                break;
            case 0:
                if (parseInt(getJwt('rol')) > 6) {
                    if (option_2[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_2[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_2: true,
                            })
                            setMenuStep(0)
                            sessionStorage.setItem('step', `0,${window.location.pathname}`)
                        } else {
                            setMenuStep(0)
                            sessionStorage.setItem('step', `0,${window.location.pathname}`)
                        }
                    }
                } else {
                    setMenuStep(0)
                    sessionStorage.setItem('step', `0,${window.location.pathname}`)
                }
                break;
            case 3:
                if (parseInt(getJwt('rol')) > 6) {
                    if (option_3[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_3[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_3: true,
                            })
                            setMenuStep(3)
                            sessionStorage.setItem('step', `3,${window.location.pathname}`)
                        } else {
                            setMenuStep(3)
                            sessionStorage.setItem('step', `3,${window.location.pathname}`)
                        }
                    }
                } else {
                    setMenuStep(3)
                    sessionStorage.setItem('step', `3,${window.location.pathname}`)
                }
                break;
            case 10:
                if (parseInt(getJwt('rol')) > 6) {
                    if (option_2[0].id_action === 0) {
                        if (option_1[0].id_action === 0) {
                            if (option_3[0].id_action === 0) {
                                toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                            } else {
                                if (option_3[0].id_action === 2) {
                                    setJustReadSteps({
                                        ...justReadSteps,
                                        step_3: true,
                                    })
                                    setMenuStep(3)
                                    sessionStorage.setItem('step', `3,${window.location.pathname}`)
                                } else {
                                    setMenuStep(3)
                                    sessionStorage.setItem('step', `3,${window.location.pathname}`)
                                }
                            }
                        } else {
                            if (option_1[0].id_action === 2) {
                                setJustReadSteps({
                                    ...justReadSteps,
                                    step_1: true,
                                })
                                setMenuStep(4)
                                sessionStorage.setItem('step', `4,${window.location.pathname}`)
                            } else {
                                setMenuStep(4)
                                sessionStorage.setItem('step', `4,${window.location.pathname}`)
                            }
                        }
                    } else {
                        if (option_2[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_2: true,
                            })
                            setMenuStep(0)
                            sessionStorage.setItem('step', `0,${window.location.pathname}`)
                        } else {
                            setMenuStep(0)
                            sessionStorage.setItem('step', `0,${window.location.pathname}`)
                        }
                    }
                } else {
                    setMenuStep(0)
                    sessionStorage.setItem('step', `0,${window.location.pathname}`)
                }
                break;
            default:
                if (parseInt(getJwt('rol')) > 6) {
                    if (option_4[0].id_action === 0) {
                        toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    } else {
                        if (option_4[0].id_action === 2) {
                            setJustReadSteps({
                                ...justReadSteps,
                                step_4: true,
                            })
                            setMenuStep(5)
                            sessionStorage.setItem('step', `5,${window.location.pathname}`)
                        } else {
                            setMenuStep(5)
                            sessionStorage.setItem('step', `5,${window.location.pathname}`)
                        }
                    }
                } else {
                    setMenuStep(5)
                    sessionStorage.setItem('step', `5,${window.location.pathname}`)
                }
                break;
        }
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a></li>
                                                <li className="breadcrumb-item font-medium-3">Ejecución</li>
                                                {
                                                    menuStep === 0 ? (
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Monitoreo / Avances</li>
                                                    ) : (null)
                                                }
                                                {
                                                    menuStep === 5 ? (
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Gestión documental</li>
                                                    ) : (null)
                                                }
                                                {
                                                    menuStep === 3 ? (
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Monitoreo / Correos</li>
                                                    ) : (null)
                                                }
                                                {
                                                    menuStep === 4 ? (
                                                        <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Monitoreo / Usuarios registrados</li>
                                                    ) : (null)
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-2">
                                    </div>
                                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10">
                                        <h2 className="pl-3 font-weight-bold">Ejecuta tus servicios</h2>
                                    </div>
                                    <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 mt-2">
                                        <div
                                            className={menuStep === 4 || menuStep === 3 || menuStep === 0 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-gray pointer py-2 rounded-top" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-2 rounded-top"}
                                            onClick={() => handleAccess(10)}
                                        >
                                            <span className={menuStep === 4 || menuStep === 3 || menuStep === 0 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Monitoreo</span>
                                        </div>
                                        {menuStep === 4 && <>
                                            <div
                                                className={menuStep === 0 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(0)}
                                            >
                                                <span className={menuStep === 0 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Avance y participación</span>
                                            </div>
                                            <div
                                                className={menuStep === 4 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(4)}
                                            >
                                                <span className={menuStep === 4 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Usuarios registrados</span>
                                            </div>
                                            <div
                                                className={menuStep === 3 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(3)}
                                            >
                                                <span className={menuStep === 3 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Correos</span>
                                            </div>
                                        </>
                                        }
                                        {menuStep === 0 && <>
                                            <div
                                                className={menuStep === 0 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(0)}
                                            >
                                                <span className={menuStep === 0 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Avance y participación</span>
                                            </div>
                                            <div
                                                className={menuStep === 4 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(4)}
                                            >
                                                <span className={menuStep === 4 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Usuarios registrados</span>
                                            </div>
                                            <div
                                                className={menuStep === 3 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(3)}
                                            >
                                                <span className={menuStep === 3 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Correos</span>
                                            </div>
                                        </>
                                        }
                                        {menuStep === 3 && <>
                                            <div
                                                className={menuStep === 0 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(0)}
                                            >
                                                <span className={menuStep === 0 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Avance y participación</span>
                                            </div>
                                            <div
                                                className={menuStep === 4 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(4)}
                                            >
                                                <span className={menuStep === 4 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Usuarios registrados</span>
                                            </div>
                                            <div
                                                className={menuStep === 3 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-gray pointer py-1" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-1"}
                                                onClick={() => handleAccess(3)}
                                            >
                                                <span className={menuStep === 3 ? "font-small-3 text-white font-weight-bold ml-1" : "font-small-3 text-blue-sec ml-1"}>Correos</span>
                                            </div>
                                        </>
                                        }
                                        {
                                            getJwt('rol') === '6' ? '' : (
                                                <div
                                                    className={menuStep === 5 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-gray pointer py-2" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-2"}
                                                    onClick={noDocuments === false ? (() => handleAccess(1)) : (() => toastr.info("No requiere documentos necesarios.", "¡Ooops!"))}
                                                >
                                                    <span className={menuStep === 5 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Gestión Documental</span>
                                                </div>
                                            )
                                        }
                                        {getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? (null) : (<BannerAdmin />)}
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mt-2">
                                        {
                                            loading === true
                                                ?
                                                (
                                                    <LoaderApp />
                                                )
                                                :
                                                (
                                                    menuStep === 0 ?
                                                        <AdminAvance
                                                            step_2={step_2}
                                                        />
                                                        : menuStep === 3 ?
                                                            <MonitoreoMail justReadSteps={justReadSteps} />
                                                            : menuStep === 4 ?
                                                                <Monitoreo step_1={step_1} />
                                                                :
                                                                diffusionVia === 0
                                                                    ?
                                                                    (
                                                                        <DocumentManagementGuay
                                                                            setDiffusionVia={setDiffusionVia}
                                                                            listDocNecProducts={listDocNecProducts}
                                                                            setListDocNecProducts={setListDocNecProducts}
                                                                            service={service}
                                                                            setService={setService}
                                                                            listInfoDocuments={listInfoDocuments}
                                                                            setListInfoDocuments={setListInfoDocuments}
                                                                            idProductSelected={idProductSelected}
                                                                            idCategorySelected={idCategorySelected}
                                                                            step_4={step_4}
                                                                        />
                                                                    )
                                                                    :
                                                                    (
                                                                        <DocumentManagement
                                                                            setDiffusionVia={setDiffusionVia}
                                                                            listDocNecProducts={listDocNecProducts}
                                                                            setListDocNecProducts={setListDocNecProducts}
                                                                            service={service}
                                                                            setService={setService}
                                                                            listInfoDocuments={listInfoDocuments}
                                                                            setListInfoDocuments={setListInfoDocuments}
                                                                            idProductSelected={idProductSelected}
                                                                            idCategorySelected={idCategorySelected}
                                                                            step_4={step_4}
                                                                        />
                                                                    )
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (access) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(DocumentManagementController);
