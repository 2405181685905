import React, { useEffect, useState, forwardRef, useRef } from 'react'
// import { useParams } from 'react-router-dom';
import $ from 'jquery'
import * as toastr from 'toastr'
import Select from 'react-select';
// // import Header from '../NewHome/headerColaborador'
// import Menu from '../Menu'
// import HelperApp from '../HelperApp'
// import Footer from '../footerApp'
import { redirectIfNotAuthenticated, getJwt } from '../../../lib/auth'
import Datepicker, { registerLocale } from 'react-datepicker'
import { addDays } from 'date-fns';
import es from 'date-fns/locale/es';
import { handleDateTextFormat,handleDateToyyyymmdd } from '../../../lib/utils/generalFuntions'
import { getEstadosApiSigma, getCiudadesApiSigma, getSectoresApiSigma, createAppoimentBenefitSigma } from '../Models/Catalog_sigma_model'
import { getCatalogSigmaHour, getCatalogSigmaTypeReason } from '../Models/Catalog_model'

const AgendarCitaForm = ({idBenefit,idProduct,idCollab,setShowScheduleAppoiment,setShowUseBenefitHtml}) => {
    registerLocale('es', es);
    const inputRef = useRef(null);
    const [generalData, setGeneralData] = useState({
        motivo : '',
        cmdMotivo: [],
        date: addDays(new Date(), 1),
        hora: '',
        cmdHora: [],
        estado: '',
        cmdEstado:[],
        ciudad: '',
        cmdCiudad:[],
        sector: '',
        cmdSector: [],
        sintomas: '',
    })
    const [initialDate, setinitialDate] = useState('')
    const [catalogTypeReason, setCatalogTypeReason] = useState([])
    const [catalogSigmaHour, setCatalogSigmaHour] = useState([])
    const [catalogEstadosSigma, setCatalogEstadosSigma] = useState([])
    const [catalogCiudadesSigma, setCatalogCiudadesSigma] = useState([])
    const [catalogSectoresSigma, setCatalogSectoresSigma] = useState([])
    const {
        motivo,
        cmdMotivo,
        date,
        hora,
        cmdHora,
        estado,
        cmdEstado,
        ciudad,
        cmdCiudad,
        sector,
        cmdSector,
        sintomas,
    } = generalData
    useEffect(() => {
        setinitialDate(handleDateTextFormat(addDays(new Date(), 1)))  
    }, [])

    useEffect(() => {
        getCatalogSigmaHour().then(response => {
            if (response.validation) {
                const sigmaCatalogHours = response.data.sigmaCatalogHours.length > 0 ? response.data.sigmaCatalogHours.filter(data => data.active === 1) : []
                const sigmaCatalogHoursFormat = sigmaCatalogHours.map(data => (
                    {
                        label: data.description,
                        value: data.idHour
                    }
                ))
                setCatalogSigmaHour(sigmaCatalogHoursFormat)
              }
        })
        getCatalogSigmaTypeReason().then(response => {
            if (response.validation) {
                const sigmaCatalogTypeReason = response.data.sigmaCatalogTypeReason.length > 0 ? response.data.sigmaCatalogTypeReason.filter(data => data.active === 1) : []
                const sigmaCatalogTypeReasonFormat = sigmaCatalogTypeReason.map(data => (
                    {
                        label: data.description,
                        value: data.idTypeReason
                    }
                ))
                setCatalogTypeReason(sigmaCatalogTypeReasonFormat)
              }
        })
    }, [])

    useEffect(() => {
        getEstadosApiSigma().then(response => {
            if (response.validation) {
                const sigmaCatalogEstados = response.data.response.map(data => (
                    {
                        label: data.XESTADO,
                        value: data.CESTADO
                    }
                ))
                setCatalogEstadosSigma(sigmaCatalogEstados)
            }
        })   
    }, [])

    useEffect(() => {
        if(estado !== ''){
            getCiudadesApiSigma(parseInt(estado)).then(response => {
                if (response.validation) {
                    const sigmaCatalogCiudades = response.data.response.map(data => (
                        {
                            label: data.XCIUDAD,
                            value: data.CCIUDAD
                        }
                    ))
                    setCatalogCiudadesSigma(sigmaCatalogCiudades)
                }
                setGeneralData({
                    ...generalData,
                    ciudad: '',
                    cmdCiudad:[],
                })
            }) 
        }else{
            setGeneralData({
                ...generalData,
                ciudad: '',
                cmdCiudad:[],
            })
        }
          
    }, [estado])

    useEffect(() => {
        if(estado !== '' && ciudad !== ''){
            getSectoresApiSigma(parseInt(estado),parseInt(estado)).then(response => {
                if (response.validation) {
                    const sigmaCatalogSectores = response.data.response.map(data => (
                        {
                            label: data.XSECTOR,
                            value: data.CSECTOR
                        }
                    ))
                    setCatalogSectoresSigma(sigmaCatalogSectores)
                }
                setGeneralData({
                    ...generalData,
                    sector: '',
                    cmdSector:[],
                })
            }) 
        }else{
            setGeneralData({
                ...generalData,
                sector: '',
                cmdSector:[],
            })
        }
        
          
    }, [ciudad])
    

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='form input-img'>
            <input
                type='text'
                className='form__input form_input2 w-100 fs-0875em'
                placeholder=' '
                onClick={onClick}
                ref={ref}
                value={value === '' ? initialDate : handleDateTextFormat(value)}
            />
            <label className='form__label'>
                Fecha de la cita:
            </label>
            <img
                src="../img/icons/Icono_calendario.png"
                className="mx-1 icon-size-16x15 " alt="img-date"
            />
        </div>
    ))

    const handleSubmitForm = () => {
        console.log('generalData: ',generalData);
        // console.log('sintomas: ', sintomas.length, ' trim: ', sintomas.trim().length);
        let incomplete = false

        if(idBenefit === 0 || idBenefit === ''){
            toastr.warning('Hubo un problema en obtener el beneficio del cual quieres realizar la cita.', '¡Ooops!')
            incomplete = true
            return
        }

        if(idProduct === 0 || idProduct === ''){
            toastr.warning('Hubo un problema en obtener el producto del cual quieres realizar la cita.', '¡Ooops!')
            incomplete = true
            return
        }

        if(idCollab === 0 || idCollab === ''){
            toastr.warning('Hubo un problema en obtener el Id del colaborador.', '¡Ooops!')
            incomplete = true
            return
        }

        if(parseInt(motivo) === 0 || motivo === ''){
            toastr.warning('Debes seleccionar el Motivo de la cita.', '¡Ooops!')
            $(`#cmbMotivo`).addClass('border-danger')
            incomplete = true
            return
        }else{
            $(`#cmbMotivo`).removeClass('border-danger')
        }

        if(date === ''){
            toastr.warning('Debes seleccionar la fecha de la cita.', '¡Ooops!')
            $(`#date`).addClass('border-danger')
            incomplete = true
            return
        }

        if(hora === ''){
            toastr.warning('Debes seleccionar la hora de la cita.', '¡Ooops!')
            $(`#cmdHora`).addClass('border-danger')
            incomplete = true
            return
        }else{
            $(`#cmdHora`).removeClass('border-danger')
        }

        if(estado === ''){
            toastr.warning('Debes seleccionar el Estado donde realizará la cita.', '¡Ooops!')
            $(`#cmdEstado`).addClass('border-danger')
            incomplete = true
            return
        }else{
            $(`#cmdEstado`).removeClass('border-danger')
        }

        if(ciudad === ''){
            toastr.warning('Debes seleccionar el Ciudad donde realizará la cita.', '¡Ooops!')
            $(`#cmdCiudad`).addClass('border-danger')
            incomplete = true
            return
        }else{
            $(`#cmdCiudad`).removeClass('border-danger')
        }

        if(sector === ''){
            toastr.warning('Debes seleccionar el Sector donde realizará la cita.', '¡Ooops!')
            $(`#cmdSector`).addClass('border-danger')
            incomplete = true
            return
        }else{
            $(`#cmdSector`).removeClass('border-danger')
        }

        if(sintomas === ''){
            toastr.warning('Debes llenar los Sintomas.', '¡Ooops!')
            $(`#agenda-sintomas`).addClass('border-danger')
            incomplete = true
            return
        }else if(sintomas.trim().length > 150){
            toastr.warning('Los Sintomas no debe ser mayor a 150 caracteres.', '¡Ooops!')
            $(`#agenda-sintomas`).addClass('border-danger')
            incomplete = true
        }else{
            $(`#agenda-sintomas`).removeClass('border-danger')
        }

        if(!incomplete){
            $(`#modalLoaderGen`).show()
            let objAppoiment = {
                idCollab: parseInt(idCollab),
                idEnterprise: parseInt(getJwt("enterprise")),
                idUser: parseInt(getJwt("jtw")),
                idBenefit: idBenefit,
                cProduct: idProduct,
                idTypeReason: parseInt(motivo),
                fCita: handleDateToyyyymmdd(new Date(date)),
                idHour: hora,
                description: sintomas.trim(),
                cEstado: parseInt(estado),
                cCiudad: parseInt(ciudad),
                cSector: parseInt(sector),
            }

            console.log('objAppoiment: ', objAppoiment);
            createAppoimentBenefitSigma(objAppoiment).then(response => {
                $(`#modalLoaderGen`).hide()
                if(response){
                    if(response.validation){
                        toastr.success('La cita se agendó correctamente.', '¡Éxito!')
                        setShowScheduleAppoiment(false)
                        setShowUseBenefitHtml(true)
                    }
                }else{
                    toastr.error(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!')
                }
            })

        }
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className='col-12 mx-auto'>
                <div className='row'>
                    <div className='col-12 mx-auto'>
                        <div className='card rounded-10px border-0 mx-2'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 mx-auto'>
                                        <span className='pointer text-cian-giki font-medium-3 font-weight-bold'
                                        onClick={() => {
                                            setShowScheduleAppoiment(false)
                                            setShowUseBenefitHtml(true)
                                            // setShowHTML(false)
                                            // setURL('')
                                            // setBenefitName('')
                                        }}
                                        ><i className="bi bi-chevron-left font-medium-5" /> Atrás</span>
                                    </div>
                                    <div className='col-12 d-flex align-items-center justify-content-center border-bottom'>
                                        <h1 className='font-weight-normal text-blue-sec'>{idProduct === 1 ? 'Odontológico' : idProduct === 2 && 'Oftalmológico'}</h1>
                                    </div>
                                </div>

                                <div className='row bg-white mx-1 '>
                                    <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
                                        <div className='form'>
                                            <Select
                                                placeholder=""
                                                isClearable={false}
                                                isRtl={false}
                                                options={catalogTypeReason}
                                                onChange={e => {
                                                    setGeneralData({
                                                        ...generalData,
                                                        motivo: e.value,
                                                        cmdMotivo: e,
                                                    });
                                                }}
                                                id='cmbMotivo'
                                                value={cmdMotivo}
                                                closeMenuOnSelect={true}
                                                loadingMessage={() => 'Cargando opciones'}
                                                noOptionsMessage={() => 'No hay más motivos de trabajo'}
                                                className="form__input select-react-input-bottom fs-075em px-0"

                                            />
                                            <label className='form__label'>
                                                Motivo de la cita
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2 datepicker-full'>
                                        <Datepicker
                                            onChange={date => {
                                                console.log('holi: ',date);
                                                setGeneralData({
                                                    ...generalData,
                                                    date: date,
                                                })
                                            }}
                                            selected={date}
                                            customInput={<ExampleCustomInput inputRef={inputRef} />}
                                            locale="es"
                                            id='date'
                                            minDate={addDays(new Date(), 1)}
                                        />
                                    </div>
                                    <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
                                        <div className='form'>
                                            <Select
                                                placeholder=""
                                                isClearable={false}
                                                isRtl={false}
                                                options={catalogSigmaHour}
                                                onChange={e => {
                                                    console.log('e: ',e);
                                                    setGeneralData({
                                                        ...generalData,
                                                        hora: e.value,
                                                        cmdHora: e,
                                                    });
                                                }}
                                                value={cmdHora}
                                                id='cmdHora'
                                                closeMenuOnSelect={true}
                                                noOptionsMessage={() => 'No hay más horas de trabajo'}
                                                className="form__input select-react-input-bottom fs-075em px-0"

                                            />
                                            <label className='form__label'>
                                                Hora de la cita
                                            </label>
                                        </div>
                                    </div> 

                                    <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
                                        <div className='form'>
                                            <Select
                                                placeholder=""
                                                isClearable={false}
                                                isRtl={false}
                                                options={catalogEstadosSigma}
                                                onChange={e => {
                                                    setGeneralData({
                                                        ...generalData,
                                                        estado: e.value,
                                                        cmdEstado: e,
                                                    });
                                                }}
                                                value={cmdEstado}
                                                id='cmdEstado'
                                                closeMenuOnSelect={true}
                                                noOptionsMessage={() => 'No hay más estados'}
                                                className="form__input select-react-input-bottom fs-075em px-0"

                                            />
                                            <label className='form__label'>
                                                Estado
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
                                        <div className='form'>
                                            <Select
                                                placeholder=""
                                                isClearable={false}
                                                isRtl={false}
                                                options={catalogCiudadesSigma}
                                                onChange={e => {
                                                    setGeneralData({
                                                        ...generalData,
                                                        ciudad: e.value,
                                                        cmdCiudad: e,
                                                    });
                                                }}
                                                value={cmdCiudad}
                                                id='cmdCiudad'
                                                closeMenuOnSelect={true}
                                                noOptionsMessage={() => 'No hay más municipios'}
                                                className="form__input select-react-input-bottom fs-075em px-0"

                                            />
                                            <label className='form__label'>
                                                Ciudad
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
                                        <div className='form'>
                                            <Select
                                                placeholder=""
                                                isClearable={false}
                                                isRtl={false}
                                                options={catalogSectoresSigma}
                                                onChange={e => {
                                                    setGeneralData({
                                                        ...generalData,
                                                        sector: e.value,
                                                        cmdSector: e,
                                                    });
                                                }}
                                                value={cmdSector}
                                                id='cmdSector'
                                                closeMenuOnSelect={true}
                                                noOptionsMessage={() => 'No hay más sectores (colonias)'}
                                                className="form__input select-react-input-bottom fs-075em px-0"

                                            />
                                            <label className='form__label'>
                                                Sector (Colonia)
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-xl-12 col-xxl-12 mt-2'>
                                        <>
                                            <label className='text-blue-3 font-weight-bold fs-05'>
                                                Sintomas
                                            </label>
                                            <textarea className="fs-075em w-100" 
                                            id="agenda-sintomas" 
                                            name="sintomas" 
                                            maxLength="151" 
                                            rows="5" 
                                            aria-describedby="sintomas" 
                                            value={sintomas} 
                                            onChange={(e) => {
                                                setGeneralData({
                                                    ...generalData,
                                                    sintomas: e.target.value,
                                                });
                                            }} 
                                            required
                                            ></textarea>
                                            <span className='fs-075em '>{`${sintomas.trim().length}/150`}</span>
                                        </>
                                    </div>
                                    
                                </div>
                                <div className='row'>
                                    <div className="col-12 d-flex align-items-end flex-wrap justify-content-end mt-2">
                                        <button 
                                            className="btn btn-sm btn-cancel fs-1 text-center font-weight-bold rounded-pill mb-1"
                                            onClick={() => handleSubmitForm()}
                                        >Agendar</button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>

            // <div className="app-content bootomFooter">
            //     <div className="content-overlay">
            //         <Header />
            //         <Menu />
            //         <div className="content-wrapper bootomFooter">
            //             <div className="content-header">
            //                 <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
            //                     <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
            //                         <div className="row breadcrumbs-top d-inline-block">
            //                             <div className="breadcrumb-wrapper col-12">
            //                                 <ol className="breadcrumb">
            //                                     <li className="breadcrumb-item font-medium-3"><a href={getJwt('rol') === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")}>Inicio</a></li>
            //                                     <li
            //                                         className={`breadcrumb-item font-medium-3 text-blue-sec pointer`}
            //                                         onClick={() => {
            //                                             // if (benefitName !== '') {
            //                                             //     setURL('')
            //                                             //     setBenefitName('')
            //                                             //     setShowHTML(false)
            //                                             // }
            //                                         }}
            //                                     >Beneficios</li>
            //                                     <li className="breadcrumb-item font-medium-3 text-blue-sec">Ver Beneficio</li>
            //                                     <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Agendar Cita</li>
            //                                 </ol>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="content-body">
            //                 <HelperApp />
            //                 <div className='card rounded-10px border-0 mx-2'>
            //                     <div className='card-body'>
            //                         <div className='row'>
            //                             <div className='col-12 mx-auto'>
            //                                 <span className='pointer text-cian-giki font-medium-3 font-weight-bold'
            //                                 onClick={() => {
            //                                     // setShowHTML(false)
            //                                     // setURL('')
            //                                     // setBenefitName('')
            //                                 }}
            //                                 ><i className="bi bi-chevron-left font-medium-5" /> Atrás</span>
            //                             </div>
            //                             <div className='col-12 d-flex align-items-center justify-content-center border-bottom'>
            //                                 <h1 className='font-weight-normal text-blue-sec'>Oftalmología</h1>
            //                             </div>
            //                         </div>

            //                         <div className='row bg-white mx-1 '>
            //                             <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
            //                                 <div className='form'>
            //                                     <Select
            //                                         placeholder=""
            //                                         isClearable={false}
            //                                         isRtl={false}
            //                                         options={catalogTypeReason}
            //                                         onChange={e => {
            //                                             setGeneralData({
            //                                                 ...generalData,
            //                                                 motivo: e.value,
            //                                                 cmdMotivo: e,
            //                                             });
            //                                         }}
                                                    
            //                                         value={cmdMotivo}
            //                                         closeMenuOnSelect={true}
            //                                         loadingMessage={() => 'Cargando opciones'}
            //                                         noOptionsMessage={() => 'No hay más motivos de trabajo'}
            //                                         className="form__input select-react-input-bottom fs-075em px-0"

            //                                     />
            //                                     <label className='form__label'>
            //                                         Motivo de la cita
            //                                     </label>
            //                                 </div>
            //                             </div>
            //                             <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2 datepicker-full'>
            //                                 <Datepicker
            //                                     onChange={date => {
            //                                         console.log('holi: ',date);
            //                                         setGeneralData({
            //                                             ...generalData,
            //                                             date: date,
            //                                         })
            //                                     }}
            //                                     selected={date}
            //                                     customInput={<ExampleCustomInput inputRef={inputRef} />}
            //                                     locale="es"
            //                                     minDate={addDays(new Date(), 1)}
            //                                 />
            //                             </div>
            //                             <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
            //                                 <div className='form'>
            //                                     <Select
            //                                         placeholder=""
            //                                         isClearable={false}
            //                                         isRtl={false}
            //                                         options={catalogSigmaHour}
            //                                         onChange={e => {
            //                                             console.log('e: ',e);
            //                                             setGeneralData({
            //                                                 ...generalData,
            //                                                 hora: e.value,
            //                                                 cmdHora: e,
            //                                             });
            //                                         }}
            //                                         value={cmdHora}
            //                                         closeMenuOnSelect={true}
            //                                         noOptionsMessage={() => 'No hay más horas de trabajo'}
            //                                         className="form__input select-react-input-bottom fs-075em px-0"

            //                                     />
            //                                     <label className='form__label'>
            //                                         Hora de la cita
            //                                     </label>
            //                                 </div>
            //                             </div> 

            //                             <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
            //                                 <div className='form'>
            //                                     <Select
            //                                         placeholder=""
            //                                         isClearable={false}
            //                                         isRtl={false}
            //                                         options={catalogEstadosSigma}
            //                                         onChange={e => {
            //                                             setGeneralData({
            //                                                 ...generalData,
            //                                                 estado: e.value,
            //                                                 cmdEstado: e,
            //                                             });
            //                                         }}
            //                                         value={cmdEstado}
            //                                         closeMenuOnSelect={true}
            //                                         noOptionsMessage={() => 'No hay más estados'}
            //                                         className="form__input select-react-input-bottom fs-075em px-0"

            //                                     />
            //                                     <label className='form__label'>
            //                                         Estado
            //                                     </label>
            //                                 </div>
            //                             </div>
            //                             <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
            //                                 <div className='form'>
            //                                     <Select
            //                                         placeholder=""
            //                                         isClearable={false}
            //                                         isRtl={false}
            //                                         options={catalogCiudadesSigma}
            //                                         onChange={e => {
            //                                             setGeneralData({
            //                                                 ...generalData,
            //                                                 ciudad: e.value,
            //                                                 cmdCiudad: e,
            //                                             });
            //                                         }}
            //                                         value={cmdCiudad}
            //                                         closeMenuOnSelect={true}
            //                                         noOptionsMessage={() => 'No hay más municipios'}
            //                                         className="form__input select-react-input-bottom fs-075em px-0"

            //                                     />
            //                                     <label className='form__label'>
            //                                         Ciudad
            //                                     </label>
            //                                 </div>
            //                             </div>
            //                             <div className='col-lg-4 col-xl-4 col-xxl-4 px-1 mt-2'>
            //                                 <div className='form'>
            //                                     <Select
            //                                         placeholder=""
            //                                         isClearable={false}
            //                                         isRtl={false}
            //                                         options={catalogSectoresSigma}
            //                                         onChange={e => {
            //                                             setGeneralData({
            //                                                 ...generalData,
            //                                                 sector: e.value,
            //                                                 cmdSector: e,
            //                                             });
            //                                         }}
            //                                         value={cmdSector}
            //                                         closeMenuOnSelect={true}
            //                                         noOptionsMessage={() => 'No hay más sectores (colonias)'}
            //                                         className="form__input select-react-input-bottom fs-075em px-0"

            //                                     />
            //                                     <label className='form__label'>
            //                                         Sector (Colonia)
            //                                     </label>
            //                                 </div>
            //                             </div>
            //                             <div className='col-lg-12 col-xl-12 col-xxl-12 mt-2'>
            //                                 <>
            //                                     <label className='text-blue-3 font-weight-bold fs-05'>
            //                                         Sintomas
            //                                     </label>
            //                                     <textarea className="fs-075em w-100" 
            //                                     id="agenda-sintomas" 
            //                                     name="sintomas" 
            //                                     maxLength="400" 
            //                                     rows="5" 
            //                                     aria-describedby="sintomas" 
            //                                     value={sintomas} 
            //                                     onChange={(e) => {
            //                                         setGeneralData({
            //                                             ...generalData,
            //                                             sintomas: e.target.value,
            //                                         });
            //                                     }} 
            //                                     required
            //                                     ></textarea>
            //                                 </>
            //                             </div>
                                       
            //                         </div>
            //                         <div className='row'>
            //                             <div className="col-12 d-flex align-items-end flex-wrap justify-content-end mt-2">
            //                                 <button 
            //                                     className="btn btn-sm btn-cancel fs-1 text-center font-weight-bold rounded-pill mb-1"
            //                                     onClick={() => console.log('generalData: ',generalData)}
            //                                 >Agendar</button>
            //                             </div> 
            //                         </div>
            //                     </div>
            //                 </div>
                            
            //                 <Footer />
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

export default AgendarCitaForm
