import React from "react";
import $ from "jquery";
import Swal from "sweetalert2";
import Header from "../Header";
import Menu from "../Menu";
import Footer from "../Footer";
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import { getCollaborators, getEnterpriseActive, getRolActive } from '../../Cataloge/CatalogeEnterprise'
import { getEnterprise, getBranch, postNewRol } from './Model'
import { getuserData } from '../../app/users/Model'
import { getJwt } from "../../../lib/auth";


export default class AssignmentUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            user: {
                email: "",
                enterpriseId: 0,
                rolEnterprise: 0,
                branchEntId: 0,
                collaboratorId: 0
            },
            userData: [],
            listEnterprise: [],
            listRol: [],
            listSubEnterprise: [],
            dataCollab: [],
            modalName: "",
            modalEmail: "",
            newDataCollab: []

        }
    }

    componentDidMount() {
        try {
            if (getJwt("jtw")) {
                getuserData(getJwt("jtw")).then((res) => {

                    this.setState({

                        userData: res

                    });

                });
                let data = this.state.user;

                this.setState({ data });

                getEnterpriseActive().then((res) => {
                    this.setState({
                        listEnterprise: res
                    })
                })

                getRolActive().then((res) => {
                    this.setState({
                        listRol: res
                    })
                })

                $("#table").hide();

            }
            else {
                Swal.fire({
                    title: '<p class="text-white mb-2">Éxito</p>',
                    text: "Debes iniciar sesión",
                    showCloseButton: true,
                    showCancelButton: false,
                    focusCancel: true,
                    showConfirmButton: true,
                    confirmButtonText: "Login",
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    window.location = "/acceso"
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    // Change Value form
    getValue = (e) => {
        try {
            let value = e.target.value;
            let nameState = e.target.name;
            let company = this.state.user;
            let data = this.state.user;
            switch (e.target.name) {

                case "enterpriseId":
                    if (value !== "0") {
                        company[nameState] = value;
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                        getEnterprise(value).then((res) => {
                            this.setState({
                                listSubEnterprise: res,
                                enterpriseId: value
                            })
                        })
                    }
                    break;
                case "branchEntId":
                    if (value !== "0") {
                        company[nameState] = value;
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                        getBranch(this.state.user.enterpriseId, value).then((res) => {
                            this.setState({
                                branchEntId: res
                            })
                        })
                        $("#table").show()
                    }
                    break;
                case "rolEnterprise":
                    if (value !== "0") {
                        company[nameState] = value;
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                        this.setState(
                            // eslint-disable-next-line
                            this.state.dataCollab.rol_description = this.state.listRol.description
                        )
                    }
                    break;
                default:
                    company[nameState] = value;
                    break;
            }
            this.setState({ company })
            //Get to set the dataCollab array
            getCollaborators(data.enterpriseId, data.branchEntId).then((res) => {
                this.setState({ dataCollab: res })
            })
        } catch (error) {
            console.log(error);
        }
    }
    //Getting data of the modal and posting
    setData = async (e) => {
        e.preventDefault();
        let company = this.state.user;
        this.setState({ company });
        try {
            postNewRol(this.state.user).then((res) => {
                if (res.code === 1) {

                    Swal.fire({
                        title: '<p class="text-white mb-2">¡Éxito!</p>',
                        text: "Se ha registrado el usuario correctamente",
                        showCloseButton: true,
                        showCancelButton: false,
                        focusCancel: true,
                        showConfirmButton: true,
                        confirmButtonText: "Ok",
                        reverseButtons: true,
                        dangerMode: true
                    }).then((willDelete) => {
                        getCollaborators(this.state.user.enterpriseId, this.state.user.branchEntId).then((res) => {
                            this.setState({ dataCollab: res })
                        })
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
                else {
                    Swal.fire({
                        title: '<p class="text-white mb-2">¡Oops!</p>',
                        text: "Ocurrió un problema al registrar la información. Intente nuevamente",
                        showCloseButton: true,
                        showCancelButton: false,
                        focusCancel: true,
                        showConfirmButton: true,
                        confirmButtonText: "Ok",
                        reverseButtons: true,
                        dangerMode: true
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
            })
        } catch (error) {
            console.log(error)
        }


    }
    //Set the name in the modal
    setModalName = (e) => {
        try {
            let value = e;
            this.setState({
                modalName: value
            });
        } catch (error) {
            console.log(error);
        }
    }
    //Set the email in the modal
    setModalEmail = (e) => {
        try {
            let value = e;
            this.setState(
                // eslint-disable-next-line
                this.state.modalEmail = value,
                // eslint-disable-next-line
                this.state.user.email = value
            );
        } catch (error) {
            console.log(error);
        }
    }
    //Set the ID in the modal
    setModalId = (e) => {
        try {
            let value = e;
            this.setState(
                // eslint-disable-next-line
                this.state.user.collaboratorId = value,
            );
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { dataCollab } = this.state
        //Setting the columns in the table
        const columns = [
            {
                Header: 'Nombre',
                accessor: 'fullNameCollab', // String-based value accessors!
                resizable: false,
                width: 300
            }, {
                Header: 'Correo',
                accessor: 'email',
                resizable: false,
                width: 250
            },
            {
                Header: 'Rol',
                accessor: 'rol_description',
                filterable: false,
                resizable: false,
                width: 150
            },
            {
                Header: 'Asignación Rol',
                filterable: false,
                resizable: false,
                width: 80,
                Cell: (
                    <div>
                        <i className="la la-edit text-info ml-3 cursor-pointer" data-toggle="modal" data-target="#exampleModal"></i>
                        <div />
                    </div>
                )
            },]
        return (
            <React.Fragment>
                <Header name={this.state.userData.name} img={this.state.userData.img} />
                <Menu />
                <div className="app-content content">
                    <div className="content-overlay"></div>
                    <div className="content-wrapper">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            <section className="row mt-5">
                                {/*<div className="col-sx-12 col-md-1"></div>*/}
                                <div className="col-sx-12 col-md-12 d-flex align-items-center justify-content-center">
                                    <div className="col-sx-12 col-lg-12 col-md-12 box-shoadow-2 p-0">
                                        <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                            <div className="card-header border-0 pb-0 font-large-1 text-dark text-center">
                                                Asignación de usuarios
                                            </div>
                                            <div className="card-content mt-2">
                                                <div className="card-body w-100 h-100">
                                                    <form className="form-horizontal" id="form-complaint">
                                                        <div className="row mt-1">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-10">
                                                                <div className="row">
                                                                    <div className="col-md-6 mt-1">
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" id="basic-addon1"><i className="la la-industry"></i></span>
                                                                            <select className="form-control"
                                                                                name="enterpriseId"
                                                                                id="enterpriseId"
                                                                                onChange={this.getValue}>
                                                                                <option value="0">--Seleccione--</option>
                                                                                {this.state.listEnterprise.map((optionSector) => (
                                                                                    <option
                                                                                        key={optionSector.enterpriseId}
                                                                                        value={optionSector.enterpriseId}
                                                                                    >
                                                                                        {optionSector.comercial}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mt-1">
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" id="basic-addon1"><i className="la la-building"></i></span>
                                                                            <select className="form-control"
                                                                                name="branchEntId"
                                                                                id="branchEntId"
                                                                                onChange={this.getValue} >
                                                                                <option value="0">--Seleccione--</option>
                                                                                {this.state.listSubEnterprise.map((optionSub) => (
                                                                                    <option
                                                                                        key={optionSub.branchEntId}
                                                                                        value={optionSub.branchEntId}
                                                                                    >
                                                                                        {optionSub.description}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-10">

                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                        <div className="row mt-3 d-flex align-items-center justify-content-center">
                                                            {/*<div className="col-md-1"></div>*/}
                                                            <div className="col-md-10 font-small-2 collapse" id="table">
                                                                <ReactTable
                                                                    data={dataCollab}
                                                                    columns={columns}
                                                                    resolveData={data => data.map(row => row)}
                                                                    filterable
                                                                    defaultPageSize={5}
                                                                    previousText='<'
                                                                    nextText='>'
                                                                    loadingText='Cargando...'
                                                                    noDataText='Ningún campo encontrado'
                                                                    pageText='Página'
                                                                    ofText='de'
                                                                    rowsText='registros'
                                                                    getTdProps={(state, rowInfo, column, instance) => {
                                                                        return {
                                                                            onClick: () => {
                                                                                this.setModalName(rowInfo.original.fullNameCollab)
                                                                                this.setModalEmail(rowInfo.original.email)
                                                                                this.setModalId(rowInfo.original.collaboratorId)
                                                                            }
                                                                        };
                                                                    }}
                                                                />
                                                            </div>
                                                            {/*<div className="col-md-1"></div>*/}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="col-sx-12 col-md-1"></div>*/}
                            </section>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Editar usuario</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body d-flex align-items-center justify-content-center">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" value={this.state.modalName} readOnly></input>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="modalEmailCollab" id="modalEmailCollab" className="form-control" value={this.state.modalEmail} readOnly></input>
                                                </div>
                                                <div className="form-group">
                                                    <select className="form-control"
                                                        name="rolEnterprise"
                                                        id="rolEnterprise"
                                                        onChange={this.getValue}
                                                    >
                                                        <option value="0">--Seleccione--</option>
                                                        {this.state.listRol.map((optionRol) => (
                                                            <option
                                                                key={optionRol.rolEntId}
                                                                value={optionRol.rolEntId}
                                                            >
                                                                {optionRol.description}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                            <button type="button" class="btn btn-primary" id="modalUpdate" name="modalUpdate" onClick={this.setData}>Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
