import React from 'react';
// API calls
import { setClickBrandingByUser } from '../Models/SponsorModel';
// Tools
import { Carousel } from 'react-bootstrap';
import { getJwt } from '../../../lib/auth';

const AdCards = ({
    promotionList,
    pageIndex,
    setPageIndex,
    showMessage,
}) => {
    const handleSelect = (selectedIndex, e) => {
        setPageIndex(selectedIndex);
    };
    return (
        <div className="col-11 mx-auto mb-1">
            <div className="card">
                {
                    showMessage === false ? (
                        <Carousel activeIndex={pageIndex} onSelect={handleSelect}>
                            {
                                promotionList.map((data, index) => (
                                    <Carousel.Item>
                                        <div className="col-11 mx-auto">
                                            <div className="row">
                                                {
                                                    data.organizer.map((card, index) => (
                                                        <div
                                                            key={'promo-' + card.idBrandPresence}
                                                            className="col-sx-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mx-auto d-flex justify-content-center"
                                                        >
                                                            <div
                                                                className="card py-1 m-0"
                                                                style={{ boxShadow: 'none' }}>
                                                                <img
                                                                    className="card-img-top pointer"
                                                                    src={card.resourceUrl}
                                                                    alt="promo"
                                                                    onClick={() => {
                                                                        if (card.customUrl1 !== '') {
                                                                            let formData = new FormData();
                                                                            let data = {
                                                                                idBrandPresence: card.idBrandPresence,
                                                                                idUser: getJwt('jtw'),
                                                                                idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                                                                            }
                                                                            formData.append("request", JSON.stringify(data));
                                                                            setClickBrandingByUser(formData);
                                                                            let protocol = card.customUrl1.split(':');
                                                                            if (protocol[0] === 'https' || protocol[0] === 'http') {
                                                                                window.open(card.customUrl1);
                                                                            } else {
                                                                                window.open("//" + card.customUrl1);
                                                                            }
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        height: '150px',
                                                                        width: 'auto'
                                                                    }}
                                                                />
                                                                <div className="card-body text-center">
                                                                    <h4 className="card-title m-0">{card.title}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>
                    ) : (
                        <div className="col-11 mx-auto text-center p-5">
                            <span>No hay promociones</span>
                        </div>
                    )
                }
                {/* <div
                    id="carouselCard"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <div className="carousel-inner">
                        {
                            promotionList.map((data, index) => (
                                <div
                                    key={'inner-' + index}
                                    className={index === 0 ? ("carousel-item active") : ("carousel-item")}
                                >
                                    <div className="col-11 mx-auto">
                                        <div className="row">
                                            {
                                                data.organizer.map((card, index) => (
                                                    <div
                                                        key={'promo-' + card.idBrandPresence}
                                                        className="col-sx-12 col-sm-12 col-md-3 col-lg-3 mx-auto d-flex justify-content-center"
                                                    >
                                                        <div
                                                            className="card py-1 m-0"
                                                            style={{ boxShadow: 'none' }}>
                                                            <img
                                                                className="card-img-top pointer"
                                                                src={card.resourceUrl}
                                                                alt="promo"
                                                                onClick={() => window.open(card.customUrl1)}
                                                                style={{
                                                                    height: '150px',
                                                                    width: 'auto'
                                                                }}
                                                            />
                                                            <div className="card-body text-center">
                                                                <h4 className="card-title m-0">{card.title}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <span
                        className="carousel-control-prev pointer"
                        data-target="#carouselCard"
                        data-slide="prev"
                        style={{ width: '5%' }}
                    >
                        <i
                            className="bi bi-arrow-left-square text-blue-sec font-weight-bold"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Previous</span>
                    </span>
                    <span
                        className="carousel-control-next pointer text-base-guay"
                        data-target="#carouselCard"
                        data-slide="next"
                        style={{ width: '5%' }}
                    >
                        <i
                            className="bi bi-arrow-right-square text-blue-sec font-weight-bold"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Next</span>
                    </span>
                </div> */}
            </div>
        </div>
    );
}

export default AdCards;
