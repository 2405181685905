import React from 'react'
// API calls
import { saveProfile } from '../../Models/RolModel'
// Tools
import $ from 'jquery'
import * as toastr from 'toastr'

const AdminRolForm = ({ generalStrcuture, setGeneralStructure, rolData, setRolData, setSection, refreshTableData, cleanData, isGenericRol, setIsGenericRol }) => {
    const handleCleanTitle = (e, title) => {
        //console.log(title)
        let newSubSection = []
        let newSection = []
        for (let a in generalStrcuture) {
            if (generalStrcuture[a].idPlatformFunctionality === title.idPlatformFunctionality) {
                for (let b in generalStrcuture[a].subSection) {
                    let subSectionChecks = []
                    for (let ba in generalStrcuture[a].subSection[b].checks) {
                        subSectionChecks.push({ ...generalStrcuture[a].subSection[b].checks[ba], checked: false })
                    }
                    let newSubSectionIn = []
                    if (generalStrcuture[a].subSection[b].subSectionIn === undefined) {
                        newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSectionChecks, id_action: 0 })
                    } else {
                        for (let c in generalStrcuture[a].subSection[b].subSectionIn) {
                            let subSectionInChecks = []
                            for (let ca in generalStrcuture[a].subSection[b].subSectionIn[c].checks) {
                                subSectionInChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[ca], checked: false })
                            }
                            newSubSectionIn.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c], checks: subSectionInChecks, id_action: 0 })
                        }
                        newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSectionChecks, subSectionIn: newSubSectionIn, id_action: 0 })
                    }
                }
                let generalStructureChecks = []
                for (let aa in generalStrcuture[a].checks) {
                    generalStructureChecks.push({ ...generalStrcuture[a].checks[aa], checked: false })
                }
                newSection.push({ ...generalStrcuture[a], subSection: newSubSection, checks: generalStructureChecks, id_action: 0 })
            } else {
                newSection.push(generalStrcuture[a])
            }
        }
        console.log(newSection)
        setGeneralStructure(newSection)
    }
    const handleTitleChecked = (e, title, checkId, isChecked) => {
        //console.log(title, checkId, isChecked)
        let newSubSection = []
        let newSection = []
        if (isChecked) {
            handleCleanTitle(e, title)
        } else {
            for (let a in generalStrcuture) {
                if (generalStrcuture[a].idPlatformFunctionality === title.idPlatformFunctionality) {
                    for (let b in generalStrcuture[a].subSection) {
                        let subSectionChecks = []
                        if (generalStrcuture[a].subSection[b].id_action !== 4 && generalStrcuture[a].subSection[b].idPlatformFunctionality !== 14) {
                            for (let ba in generalStrcuture[a].subSection[b].checks) {
                                if (generalStrcuture[a].subSection[b].checks[ba].checkId === checkId) {
                                    subSectionChecks.push({ ...generalStrcuture[a].subSection[b].checks[ba], checked: !isChecked })
                                } else {
                                    subSectionChecks.push({ ...generalStrcuture[a].subSection[b].checks[ba], checked: false })
                                }
                            }
                            let newSubSectionIn = []
                            if (generalStrcuture[a].subSection[b].subSectionIn === undefined) {
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSectionChecks, id_action: checkId })
                            } else {
                                for (let c in generalStrcuture[a].subSection[b].subSectionIn) {
                                    let subSectionInChecks = []
                                    for (let ca in generalStrcuture[a].subSection[b].subSectionIn[c].checks) {
                                        if (generalStrcuture[a].subSection[b].subSectionIn[c].checks[ca].checkId === checkId) {
                                            subSectionInChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[ca], checked: !isChecked })
                                        } else {
                                            subSectionInChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[ca], checked: false })
                                        }
                                    }
                                    newSubSectionIn.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c], checks: subSectionInChecks, id_action: checkId })
                                }
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSectionChecks, subSectionIn: newSubSectionIn, id_action: checkId })
                            }
                        } else {
                            newSubSection.push(generalStrcuture[a].subSection[b])
                        }
                    }
                    let generalStructureChecks = []
                    for (let aa in generalStrcuture[a].checks) {
                        if (generalStrcuture[a].checks[aa].checkId === checkId) {
                            generalStructureChecks.push({ ...generalStrcuture[a].checks[aa], checked: !isChecked })
                        } else {
                            generalStructureChecks.push({ ...generalStrcuture[a].checks[aa], checked: false })
                        }
                    }
                    newSection.push({ ...generalStrcuture[a], subSection: newSubSection, checks: generalStructureChecks, id_action: checkId })
                } else {
                    newSection.push(generalStrcuture[a])
                }
            }
            //console.log(newSection)
            setGeneralStructure(newSection)
        }
    }
    const handleSectionChecked = (e, title, section, checkId, isChecked) => {
        //console.log(title, section, checkId, isChecked)
        let newSubSectionIn = []
        let newSubSection = []
        let newSection = []
        for (let a in generalStrcuture) {
            if (generalStrcuture[a].idPlatformFunctionality === title.idPlatformFunctionality) {
                for (let b in generalStrcuture[a].subSection) {
                    if (generalStrcuture[a].subSection[b].idPlatformFunctionality === section.idPlatformFunctionality) {
                        if (generalStrcuture[a].subSection[b].subSectionIn !== undefined) {
                            for (let c in generalStrcuture[a].subSection[b].subSectionIn) {
                                let newChecks = []
                                for (let d in generalStrcuture[a].subSection[b].subSectionIn[c].checks) {
                                    if (generalStrcuture[a].subSection[b].subSectionIn[c].checks[d].checkId === checkId) {
                                        newChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[d], checked: !isChecked })
                                    } else {
                                        newChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[d], checked: false })
                                    }
                                }
                                if (isChecked === true) {
                                    newSubSectionIn.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c], checks: newChecks, id_action: 0 })
                                } else {
                                    newSubSectionIn.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c], checks: newChecks, id_action: checkId })
                                }
                            }
                        }
                        let subSecChecks = []
                        for (let x in generalStrcuture[a].subSection[b].checks) {
                            if (generalStrcuture[a].subSection[b].checks[x].checkId === checkId) {
                                subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: !isChecked })
                            } else {
                                subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: false })
                            }
                        }
                        if (generalStrcuture[a].subSection[b].subSectionIn === undefined) {
                            if (isChecked === true) {
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 0 })
                            } else {
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: checkId })
                            }
                        } else {
                            if (isChecked === true) {
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 0, subSectionIn: newSubSectionIn })
                            } else {
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: checkId, subSectionIn: newSubSectionIn })
                            }
                        }
                    } else {
                        newSubSection.push(generalStrcuture[a].subSection[b])
                    }
                }
                let subSectionAct_1 = 0
                let subSectionAct_2 = 0
                let subSectionAct_3 = 0
                for (let aa in newSubSection) {
                    if (newSubSection[aa].id_action === 1) {
                        subSectionAct_1 = subSectionAct_1 + 1
                    }
                    if (newSubSection[aa].id_action === 2) {
                        subSectionAct_2 = subSectionAct_2 + 1
                    }
                    if (newSubSection[aa].id_action === 3) {
                        subSectionAct_3 = subSectionAct_3 + 1
                    }
                    if (newSubSection[aa].id_action === 4 && checkId === 1) {
                        subSectionAct_1 = subSectionAct_1 + 1
                    }
                    if (newSubSection[aa].id_action === 4 && checkId === 2) {
                        subSectionAct_2 = subSectionAct_2 + 1
                    }
                }
                let generalStrChecks = []
                if (newSubSection.length === subSectionAct_1) {
                    for (let ab in generalStrcuture[a].checks) {
                        if (generalStrcuture[a].checks[ab].checkId === 1) {
                            generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: true })
                        } else {
                            generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                        }
                    }
                    newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 1, subSection: newSubSection })
                } else {
                    if (newSubSection.length === subSectionAct_2) {
                        for (let ab in generalStrcuture[a].checks) {
                            if (generalStrcuture[a].checks[ab].checkId === 2) {
                                generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: true })
                            } else {
                                generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                            }
                        }
                        newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 2, subSection: newSubSection })
                    } else {
                        if (newSubSection.length === subSectionAct_3) {
                            for (let ab in generalStrcuture[a].checks) {
                                generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                            }
                            newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 3, subSection: newSubSection })
                        } else {
                            if (subSectionAct_1 === 0 && subSectionAct_2 === 0 && subSectionAct_3 === 0) {
                                for (let ab in generalStrcuture[a].checks) {
                                    generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                                }
                                newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 0, subSection: newSubSection })
                            } else {
                                for (let ab in generalStrcuture[a].checks) {
                                    generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                                }
                                newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 3, subSection: newSubSection })
                            }
                        }
                    }
                }
            } else {
                newSection.push(generalStrcuture[a])
            }
        }
        //console.log(newSection)
        setGeneralStructure(newSection)
    }

    const handleSubSectionsChecked = (e, title, section, subSection, checkId, isChecked) => {
        //console.log(title, section, subSection, checkId, isChecked)
        let newSubSectionIn = []
        let newSubSection = []
        let newSection = []

        let action_1 = 0
        let action_2 = 0
        for (let a in generalStrcuture) {
            if (generalStrcuture[a].idPlatformFunctionality === title.idPlatformFunctionality) {
                for (let b in generalStrcuture[a].subSection) {
                    if (generalStrcuture[a].subSection[b].idPlatformFunctionality === section.idPlatformFunctionality) {
                        if (generalStrcuture[a].subSection[b].subSectionIn !== undefined) {
                            for (let c in generalStrcuture[a].subSection[b].subSectionIn) {
                                let newSubSectionInChecks = []
                                if (generalStrcuture[a].subSection[b].subSectionIn[c].idPlatformFunctionality === subSection.idPlatformFunctionality) {
                                    for (let d in generalStrcuture[a].subSection[b].subSectionIn[c].checks) {
                                        if (generalStrcuture[a].subSection[b].subSectionIn[c].checks[d].checkId === checkId) {
                                            newSubSectionInChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[d], checked: !isChecked })
                                        } else {
                                            newSubSectionInChecks.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c].checks[d], checked: false })
                                        }
                                    }
                                    if (isChecked === false) {
                                        newSubSectionIn.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c], checks: newSubSectionInChecks, id_action: checkId })
                                    } else {
                                        newSubSectionIn.push({ ...generalStrcuture[a].subSection[b].subSectionIn[c], checks: newSubSectionInChecks, id_action: 0 })
                                    }
                                    if (checkId === 1 && isChecked === false) {
                                        action_1 = action_1 + 1
                                    }
                                    if (checkId === 2 && isChecked === false) {
                                        action_2 = action_2 + 1
                                    }
                                } else {
                                    newSubSectionIn.push(generalStrcuture[a].subSection[b].subSectionIn[c])
                                    if (generalStrcuture[a].subSection[b].subSectionIn[c].id_action === 1) {
                                        action_1 = action_1 + 1
                                    }
                                    if (generalStrcuture[a].subSection[b].subSectionIn[c].id_action === 2) {
                                        action_2 = action_2 + 1
                                    }
                                    if (generalStrcuture[a].subSection[b].subSectionIn[c].id_action === 4 && checkId === 1) {
                                        action_1 = action_1 + 1
                                    }
                                    if (generalStrcuture[a].subSection[b].subSectionIn[c].id_action === 4 && checkId === 2) {
                                        action_2 = action_2 + 1
                                    }
                                }
                            }
                        }
                        if (generalStrcuture[a].subSection[b].subSectionIn === undefined) {
                            newSubSection.push(generalStrcuture[a].subSection[b])
                        } else {
                            let subSecChecks = []
                            if (newSubSectionIn.length === action_1) {
                                for (let x in generalStrcuture[a].subSection[b].checks) {
                                    if (generalStrcuture[a].subSection[b].checks[x].checkId === 1) {
                                        subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: true })
                                    } else {
                                        subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: false })
                                    }
                                }
                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 1, subSectionIn: newSubSectionIn })
                            } else {
                                if (newSubSectionIn.length === action_2) {
                                    for (let x in generalStrcuture[a].subSection[b].checks) {
                                        if (generalStrcuture[a].subSection[b].checks[x].checkId === 2) {
                                            subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: true })
                                        } else {
                                            subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: false })
                                        }
                                    }
                                    newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 2, subSectionIn: newSubSectionIn })
                                } else {
                                    if (action_1 > 0 && action_2 > 0) {
                                        for (let x in generalStrcuture[a].subSection[b].checks) {
                                            subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: false })
                                        }
                                        newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 3, subSectionIn: newSubSectionIn })
                                    } else {
                                        if (action_1 === 0 && action_2 === 0) {
                                            for (let x in generalStrcuture[a].subSection[b].checks) {
                                                subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: false })
                                            }
                                            newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 0, subSectionIn: newSubSectionIn })
                                        } else {
                                            if ((action_1 > 0 && action_1 < newSubSectionIn.length && action_2 === 0) || (action_2 > 0 && action_2 < newSubSectionIn.length && action_1 === 0)) {
                                                for (let x in generalStrcuture[a].subSection[b].checks) {
                                                    subSecChecks.push({ ...generalStrcuture[a].subSection[b].checks[x], checked: false })
                                                }
                                                newSubSection.push({ ...generalStrcuture[a].subSection[b], checks: subSecChecks, id_action: 3, subSectionIn: newSubSectionIn })
                                            } else {
                                                newSubSection.push({ ...generalStrcuture[a].subSection[b], subSectionIn: newSubSectionIn })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        newSubSection.push(generalStrcuture[a].subSection[b])
                    }
                }
                let subSectionAct_1 = 0
                let subSectionAct_2 = 0
                let subSectionAct_3 = 0
                for (let aa in newSubSection) {
                    if (newSubSection[aa].id_action === 1) {
                        subSectionAct_1 = subSectionAct_1 + 1
                    }
                    if (newSubSection[aa].id_action === 2) {
                        subSectionAct_2 = subSectionAct_2 + 1
                    }
                    if (newSubSection[aa].id_action === 3) {
                        subSectionAct_3 = subSectionAct_3 + 1
                    }
                }
                let generalStrChecks = []
                if (newSubSection.length === subSectionAct_1) {
                    for (let ab in generalStrcuture[a].checks) {
                        if (generalStrcuture[a].checks[ab].checkId === 1) {
                            generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: true })
                        } else {
                            generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                        }
                    }
                    newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 1, subSection: newSubSection })
                } else {
                    if (newSubSection.length === subSectionAct_2) {
                        for (let ab in generalStrcuture[a].checks) {
                            if (generalStrcuture[a].checks[ab].checkId === 2) {
                                generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: true })
                            } else {
                                generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                            }
                        }
                        newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 2, subSection: newSubSection })
                    } else {
                        if (newSubSection.length === subSectionAct_3) {
                            for (let ab in generalStrcuture[a].checks) {
                                generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                            }
                            newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 3, subSection: newSubSection })
                        } else {
                            if (subSectionAct_1 === 0 && subSectionAct_2 === 0 && subSectionAct_3 === 0) {
                                for (let ab in generalStrcuture[a].checks) {
                                    generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                                }
                                newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 0, subSection: newSubSection })
                            } else {
                                for (let ab in generalStrcuture[a].checks) {
                                    generalStrChecks.push({ ...generalStrcuture[a].checks[ab], checked: false })
                                }
                                newSection.push({ ...generalStrcuture[a], checks: generalStrChecks, id_action: 3, subSection: newSubSection })
                            }
                        }
                    }
                }
            } else {
                newSection.push(generalStrcuture[a])
            }
        }
        //console.log(newSection)
        setGeneralStructure(newSection)
    }

    const handleSave = () => {
        if (rolData.description.trim() === '') {
            $('#description').addClass('border-danger')
            toastr.warning('EL campo Nombre del Rol es obligatorio.', '¡Ooops!')
            return
        }
        //console.log(generalStrcuture)
        let functionalitiesArr = []
        for (let a in generalStrcuture) {
            functionalitiesArr.push({ idPlatformFunctionality: generalStrcuture[a].idPlatformFunctionality, idAction: generalStrcuture[a].id_action })
            for (let b in generalStrcuture[a].subSection) {
                if (generalStrcuture[a].subSection[b].subSectionIn === undefined) {
                    functionalitiesArr.push({ idPlatformFunctionality: generalStrcuture[a].subSection[b].idPlatformFunctionality, idAction: generalStrcuture[a].subSection[b].id_action })
                } else {
                    functionalitiesArr.push({ idPlatformFunctionality: generalStrcuture[a].subSection[b].idPlatformFunctionality, idAction: generalStrcuture[a].subSection[b].id_action })
                    for (let c in generalStrcuture[a].subSection[b].subSectionIn) {
                        functionalitiesArr.push({ idPlatformFunctionality: generalStrcuture[a].subSection[b].subSectionIn[c].idPlatformFunctionality, idAction: generalStrcuture[a].subSection[b].subSectionIn[c].id_action })
                    }
                }
            }
        }
        //console.log(functionalitiesArr)
        let data = {
            ...rolData,
            functionalities: functionalitiesArr
        }
        //console.log(data)
        saveProfile(data).then(response => {
            switch (response.code) {
                case 1:
                case 200:
                    refreshTableData()
                    cleanData()
                    setSection(1)
                    toastr.success(rolData.idProfile === 0 ? ('El rol se creó correctamente.') : ('El rol se actualizó correctamente.'), '¡Éxito!')
                    break;
                default:
                    setSection(1)
                    cleanData()
                    toastr.error(rolData.idProfile === 0 ? ('No se pudo crear el rol. Intenta nuevamente.') : ('No se pudo actualizar el rol. Intenta nuevamente.'), '¡Ooops!')
                    break;
            }
        })
    }
    return (
        <>
            <div className='col-12 mb-3'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-6 col-xxl-6'>
                        {
                            isGenericRol ? (
                                <h5 className='pl-1'>Rol: <span className='font-weight-bold'>{rolData.description}</span></h5>
                            ) : (
                                <div className="form-group">
                                    <label htmlFor="description">Nombre del rol:</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="description"
                                        value={rolData.description}
                                        onChange={e => {
                                            $('#description').removeClass('border-danger')
                                            setRolData({
                                                ...rolData,
                                                description: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex justify-content-end flex-wrap align-content-center'>
                        <button
                            className='btn btn-sm btn-blue-3 mr-1'
                            onClick={() => {
                                cleanData()
                                setSection(1)
                                setIsGenericRol(false)
                            }}
                        >{isGenericRol ? 'Volver' : 'Cancelar'}</button>
                        {
                            !isGenericRol && (
                                <button
                                    className='btn btn-sm bg-pink-mine text-white'
                                    onClick={handleSave}
                                >Guardar</button>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                generalStrcuture.map(title => (
                    <div
                        className='col-12'
                        id={`accordion-${title.idPlatformFunctionality}`}
                        key={`accordion-${title.idPlatformFunctionality}`}
                    >
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <span className="collapsed card-link font-weight-bold">
                                    {title.description}
                                </span>
                                <a
                                    className="collapsed card-link font-weight-bold"
                                    data-toggle="collapse"
                                    href={`#card-${title.idPlatformFunctionality}`}
                                    onClick={() => {
                                        if ($(`#icon-${title.idPlatformFunctionality}`).hasClass('bi bi-plus-lg')) {
                                            $(`#icon-${title.idPlatformFunctionality}`).removeClass('bi bi-plus-lg')
                                            $(`#icon-${title.idPlatformFunctionality}`).addClass('bi bi-dash-lg')
                                        } else {
                                            $(`#icon-${title.idPlatformFunctionality}`).removeClass('bi bi-dash-lg')
                                            $(`#icon-${title.idPlatformFunctionality}`).addClass('bi bi-plus-lg')
                                        }
                                    }}
                                >
                                    <i className='bi bi-plus-lg' id={`icon-${title.idPlatformFunctionality}`} />
                                </a>
                            </div>
                            <div
                                id={`card-${title.idPlatformFunctionality}`}
                                className="collapse"
                                data-parent={`#accordion-${title.idPlatformFunctionality}`}
                            >
                                <div className="card-body border-top border-secondary">
                                    <div className='row'>
                                        <div className='col-12 mb-1'>
                                            {
                                                title.id_action !== 4 && !isGenericRol && (
                                                    <span
                                                        className='text-danger pointer float-right'
                                                        onClick={e => handleCleanTitle(e, title)}
                                                    ><i className='bi bi-brush' /> Limpiar</span>
                                                )
                                            }
                                        </div>
                                        <div className='col-12 mb-1 border-bottom pb-1'>
                                            <div className='row'>
                                                <div className='col-sm-6 col-md-4 col-lg-4 col-xxl-3'>
                                                    <span>{title.description}</span>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-4 col-xxl-3'>
                                                    {
                                                        title.checks.map(check => (
                                                            <div
                                                                className="form-check-inline pl-1"
                                                                key={`title-check-${check.checkId}`}
                                                            >
                                                                <label className="form-check-label">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        value={title.idPlatformFunctionality}
                                                                        id={`title-${title.idPlatformFunctionality}`}
                                                                        name={`title-${title.idPlatformFunctionality}`}
                                                                        checked={title.id_action === 4 ? true : check.checked}
                                                                        onChange={e => handleTitleChecked(e, title, check.checkId, check.checked)}
                                                                        disabled={isGenericRol ? true : title.id_action === 4 ? true : false}
                                                                    />{check.checkName}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        title.subSection.map(section => (
                                            <div
                                                className='row'
                                                key={`section-${section.idPlatformFunctionality}`}
                                            >
                                                {
                                                    section.subSections ? (
                                                        <div className='col-12 mb-1'>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        value={section.idPlatformFunctionality}
                                                                        id={`section-${section.idPlatformFunctionality}`}
                                                                        name={`section-${section.idPlatformFunctionality}`}
                                                                        checked={section.id_action === 4 ? true : section.checked}
                                                                        onChange={e => handleSectionChecked(e, title, section, 0, false)}
                                                                        disabled={isGenericRol ? true : section.id_action === 4 ? true : false}
                                                                    />{section.description}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='col-12 mb-1'>
                                                            <div className='row'>
                                                                <div className='col-sm-6 col-md-4 col-lg-4 col-xxl-3'>
                                                                    <span className='p-1'>{section.description}</span>
                                                                </div>
                                                                <div className='col-sm-6 col-md-4 col-lg-4 col-xxl-3'>
                                                                    {
                                                                        section.checks.map(check => (
                                                                            <div className="form-check-inline pl-1">
                                                                                <label className="form-check-label">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        value={section.idPlatformFunctionality}
                                                                                        id={`section-${section.idPlatformFunctionality}`}
                                                                                        name={`section-${section.idPlatformFunctionality}`}
                                                                                        checked={section.id_action === 4 ? true : section.idPlatformFunctionality === 14 && check.checkId === 2 && section.idAction === 2 ? true : check.checked}
                                                                                        disabled={isGenericRol ? true : section.id_action === 4 ? true : section.idPlatformFunctionality === 14 ? true : false}
                                                                                        onChange={e => handleSectionChecked(e, title, section, check.checkId, check.checked)}
                                                                                    />{check.checkName}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    section.subSectionIn && (
                                                        section.subSectionIn.map(subSection => (
                                                            <>
                                                                <div className='col-12 mb-1'>
                                                                    <div className='row'>
                                                                        <div className='col-sm-6 col-md-4 col-lg-4 col-xxl-3'>
                                                                            <span className='pl-3'>{subSection.description}</span>
                                                                        </div>
                                                                        <div className='col-sm-6 col-md-4 col-lg-4 col-xxl-3'>
                                                                            {
                                                                                subSection.checks.map(check => (
                                                                                    <div className="form-check-inline pl-1">
                                                                                        <label className="form-check-label">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                value={subSection.idPlatformFunctionality}
                                                                                                id={`subSection-${subSection.idPlatformFunctionality}`}
                                                                                                name={`subSection-${subSection.idPlatformFunctionality}`}
                                                                                                checked={section.id_action === 4 ? true : subSection.son === 14 && check.checkId === 2 && subSection.idAction === 2 ? true : check.checked}
                                                                                                onChange={e => handleSubSectionsChecked(e, title, section, subSection, check.checkId, check.checked)}
                                                                                                disabled={isGenericRol ? true : section.id_action === 4 ? true : subSection.son === 14 ? true : false}
                                                                                            />{check.checkName}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default AdminRolForm