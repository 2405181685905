
import React from "react";
import { exit } from "./LogOut";
const Header = (props) => {

    return (
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top  bg-white navbar-shadow navbar-brand-center" data-nav="brand-center">
            <div className="navbar-wrapper">
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        {/* [VZO] Skip warning in line 13 attr href */}
                        {/* eslint-disable-next-line */}
                        <li className="nav-item mobile-menu d-md-none mr-auto"><a className="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="">
                            <i className="ft-menu font-large-1"></i></a></li>
                        {/* [VZO] Skip warning in line 17 attr href */}
                        {/* eslint-disable-next-line */}
                        <li className="nav-item"><a className="navbar-brand" href=""><img alt="guay" src="./../img/guay-logo.png" /> </a></li>
                        {/* [VZO] Skip warning in line 20 attr href */}
                        {/* eslint-disable-next-line */}
                        <li className="nav-item d-md-none"><a className="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile">
                            <i className="la la-ellipsis-v"></i></a>
                        </li>
                    </ul>
                </div>
                <div className="navbar-container content">
                    <div className="collapse navbar-collapse" id="navbar-mobile">
                        <ul className="nav navbar-nav mr-auto float-left">
                            {/* [VZO] Skip warning in line 30 attr href */}
                            {/* eslint-disable-next-line */}
                            <li className="nav-item d-none d-md-block"><a className="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="#"><i className="ft-menu"></i></a></li>
                            {/* [VZO] Skip warning in line 33 attr href */}
                            {/* eslint-disable-next-line */}
                            <li className="nav-item d-none d-lg-block"><a className="nav-link nav-link-expand" href="#"><i className="ficon ft-maximize"></i></a></li>
                        </ul>
                        <ul className="nav navbar-nav float-right">
                            {/* [VZO] Skip warning in line 38 attr href */}
                            {/* eslint-disable-next-line */}
                            <li className="dropdown dropdown-notification nav-item"><a className="nav-link nav-link-label text-base-guay" href="#" data-toggle="dropdown">Ayuda <i className="ficon ft-help-circle"></i></a></li>
                            <li className="dropdown dropdown-notification nav-item">
                                {/* [VZO] Skip warning in line 42 attr href */}
                                {/* eslint-disable-next-line */}
                                <a className="nav-link nav-link-label" href="#" data-toggle="dropdown">
                                    Configuración <i className="ficon ft-settings mt-2"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <h4 className="dropdown-header m-0 text-center text-bold-600">Organización</h4>
                                    <a className="dropdown-item" href="/configuracion/empresas">
                                        <i className="la la-building text-base-guay"></i>Datos de la organización
                            </a>
                                    <a className="dropdown-item" href="/configuracion/empresas/colaboradores">
                                        <i className="ft-users text-base-guay"></i> Carga colaboradores
                            </a>
                                    <a className="dropdown-item" href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf">
                                        <i className="la la-file-text-o text-base-guay"></i> Politica de Privacidad | Condiciones
                            </a>
                                </div>
                            </li>
                            <li className="dropdown dropdown-user nav-item">
                                {/* [VZO] Skip warning in line 61 attr href */}
                                {/* eslint-disable-next-line */}
                                <a className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                    <span className="mr-1 user-name text-base text-bold-700">{props.name}</span>
                                    <span className="avatar avatar-online">
                                        {props.img == null ? <img src="./../img/user.png" alt={props.name} /> : <img src={props.img} alt={props.name} />}
                                        <i></i>
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <h4 className="dropdown-header m-0 text-center text-bold-600">¡Hola! {props.name}</h4>
                                    <a className="dropdown-item" href="administracionMarco">
                                        <i className="ft-user text-base-guay"></i> Administración Perfil
                            </a>
                                    <a className="dropdown-item" href="https://guay.digital/resources/template/Manual_GUAYClima.pdf">
                                        <i className="ft-download-cloud text-base-guay"></i> Descargar Manual
                            </a>
                                    <a className="dropdown-item" href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf">
                                        <i className="la la-file-text-o text-base-guay"></i> Politica de Privacidad | Condiciones
                            </a>
                                    {/* [VZO] Skip warning in line 81 attr href */}
                                    {/* eslint-disable-next-line */}
                                    <div className="dropdown-divider"></div><a className="dropdown-item" onClick={exit}><i className="ft-power text-base-guay"></i> Cerrar Sesión</a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
};

export default Header;