import React, { useState, useEffect, useRef } from 'react';
// API calls
import { getMarketStudiesQuiz } from '../Models/SponsorModel';
// Components
import SurveyQuestions from './SurveyQuestions';
// Tools
import { getJwt } from '../../../lib/auth';
import $ from "jquery";

const SurveyModal = () => {
    const [survey, setSurvey] = useState([]);
    const [formProps, setFormProps] = useState({
        title: '',
        logo: '',
    });
    const {
        title,
        logo,
    } = formProps;
    const [showCloseBtn, setShowCloseBtn] = useState(false);
    const [count, setCount] = useState(0);
    useEffect(() => {
        getMarketStudiesQuiz(getJwt('enterprise'), getJwt('jtw')).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setSurvey(response.quiz);
                        setFormProps({
                            title: response.title,
                            logo: response.sponsorLogo,
                        });
                        $('#surveyModal').show();
                        break;
                    default:
                        break;
                }
            }
        });
    }, []);
    // Counter functions
    function useInterval(callback, delay) {
        const savedCallback = useRef();
        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
    // Counter triggers
    useInterval(() => {
        if (count === 5) {
            setShowCloseBtn(true);
        } else {
            setCount(count + 1);
        }
    }, 1000);
    return (
        <div
            className="modal"
            id="surveyModal"
            style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-body scrollbar-1">
                        <div className='row'>
                            <div className='col-12 text-center mb-2'>
                                {
                                    showCloseBtn ? (
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={() => {
                                                $('#surveyModal').hide();
                                            }}
                                        >&times;</button>
                                    ) : (
                                        <span className='font-small-2'>Podrá cerrar la encuesta en: {count} / 5</span>
                                    )
                                }
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-3 col-lg-3 d-flex justify-content-center flex-wrap align-content-center'>
                                        {
                                            logo === '' ? (
                                                <img
                                                    src="./../img/guay-logo.png"
                                                    alt="guay logo"
                                                    className="w-50"
                                                />
                                            ) : (
                                                <img
                                                    src={logo}
                                                    alt="guay logo"
                                                    className="w-50"
                                                />
                                            )
                                        }
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center flex-wrap align-content-center'>
                                        <h3 className='text-dark font-weight-bold'>{title}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2 text-center'>
                                <span>Tu participación es muy importante para este estudio de mercado. Por favor contesta estas {survey.length} preguntas. <span className='font-weight-bold'>#somosguay</span></span>
                            </div>
                            <SurveyQuestions
                                survey={survey}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SurveyModal;
