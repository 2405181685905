import axios from "axios";

/**
 * Obtener los productos de la guaykipedia 
 * (menu superior)
 * Get Productsguakipedia catalog
 * @returns JSON response arrayList
 */
 export const getProductsguayki = async () => {
    try {
        let result = await axios({
            url: global.base_url + "repository/getProductsguayki",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Obtener los absctracts
 * Gets the resources guaykipedia - Params(idSectionguaykipedia)
 * @param {int} idSectionguaykipedia 
 * @returns JSON response arrayList
 */
export const getResourcesguayki = async (idSectionguaykipedia) => {
    try {
        let result = await axios({
            url: global.base_url + `repository/getResourcesguayki?idSectionguaykipedia=${idSectionguaykipedia}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Gets the resources of all the products on the platform by Enterprise - Params(idResourcesguaykipedia)
 * Ficha para ver mas de la ficha abstracta
 * @param {int} idFicha 
 * @returns 
 */
export const getSpecificResourceguayki = async (idSection) => {
    try {
        let result = await axios({
            url: global.base_url + `repository/getSpecificResourceguayki?idSectionguaykipedia=${idSection}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
