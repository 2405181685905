import React, { useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { getJobsAreas } from '../../Models/Enterprise_model'
import { getEnterprisesCategory } from '../../Models/Catalog_model'
import { updateCargoHomologadoAreas, updateCargosAreasModel } from '../../Models/Enterprise_model';
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import $ from "jquery";

const TablaCargosAreas = ({ enterpriseId, areaId, setviewAreaData }) => {

    const [cargos, setCargos] = useState([])
    const [cargosHomologados, setcargosHomologados] = useState([])
    const [objCargoHom, setobjCargoHom] = useState({})
    const [objCargo, setobjCargo] = useState({})
    const [oldCargo, setoldCargo] = useState()
    const [numAreas, setnumAreas] = useState()

    useEffect(() => {
        getJobsAreas(enterpriseId, areaId).then((response) => {
            switch (response.code) {
                case 1:
                    setCargos(response.listJobCategoryRel);
                    break;
                case 200:
                    setCargos(response.listJobCategoryRel);
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "Error");
                    break;
            }
        });
        getEnterprisesCategory(enterpriseId).then((response) => {
            switch (response.code) {
                case 1:
                    setcargosHomologados(response.categorys);
                    break;
                case 200:
                    setcargosHomologados(response.categorys);
                    break;
                case 404:
                    toastr.error("Recargue la pagina, por favor", "Error");
                    break;
                default:
                    toastr.error("Recargue la pagina, por favor", "Error");
                    break;
            }
        });

        return () => {

        }

    }, [enterpriseId, areaId, setviewAreaData])

    const handleClickCH = (objCargo, option) => {
        updateCargoHomologadoAreas(objCargo, option).then((response) => {
            switch (response?.code) {
                case 1:
                    toastr.success("Cargo Homologado actualizado correctamente", "Éxito")
                    $('#confirmation').hide()
                    break;
                case 200:
                    toastr.success("Cargo Homologado actualizado correctamente", "Éxito")
                    $('#confirmation').hide()
                    break;
                case 1002:
                    $('#confirmation').show()
                    break;
                default:
                    toastr.error("Error al actualizar", "Error")
                    break;
            }
        })
    }

    const handleDbClickCH = (newValue, objJob, option) => {
        const obj = {
            enterpriseId,
            areaId,
            jobId: objJob.jobCategoryRelKey.jobEntId,
            catAntId: objJob.jobCategoryRelKey.categoryEntId,
            catNewId: newValue.target.value
        }
        setoldCargo(objJob.descCategory)
        setobjCargoHom(obj)
        updateCargoHomologadoAreas(obj, option).then((response) => {
            switch (response?.code) {
                case 1:
                    toastr.success("Cargo Homologado actualizado correctamente", "Éxito")
                    break;
                case 200:
                    toastr.success("Cargo Homologado actualizado correctamente", "Éxito")
                    break;
                case 1002:
                    setnumAreas(response.numAreas)
                    $('#confirmation').show()
                    break;
                default:
                    toastr.error("Error al actualizar", "Error")
                    break;
            }
        })
    }

    const handleClickReturnBack = () => {
        setviewAreaData(false);
    }

    const handleKeyEnter = (e, objJob, option) => {
        if (e.key === "Enter") {
            if (e.target.value !== "") {
                const objCargo = {
                    "enterpriseId": enterpriseId,
                    "areaId": areaId,
                    "jobId": objJob.jobCategoryRelKey.jobEntId,
                    "categoryId": objJob.jobCategoryRelKey.categoryEntId,
                    "description": e.target.value,
                    "option": option
                }
                setobjCargo(objCargo)

                updateCargosAreas(objCargo)
            }
            setoldCargo(objJob.descJob)
        }
    }

    const handleUpdateJobs = (objJob, option) => {
        const objCargo = {
            "enterpriseId": objJob.enterpriseId,
            "areaId": objJob.areaId,
            "jobId": objJob.jobId,
            "categoryId": objJob.categoryId,
            "description": objJob.description,
            "option": option
        }
        updateCargosAreas(objCargo)
    }

    const updateCargosAreas = (objCargo) => {
        updateCargosAreasModel(objCargo).then((response) => {
            switch (response?.code) {
                case 1:
                    toastr.success("Correcto, cargo actualizado", "Éxito")
                    $('#confirmationCargo').hide()
                    break;
                case 200:
                    toastr.success("Correcto, cargo actualizado", "Éxito")
                    $('#confirmationCargo').hide()
                    break;
                case 1002:
                    setnumAreas(response.numAreas)
                    $('#confirmationCargo').show()
                    break;
                case 1003:
                    toastr.info("Este nombre ya existe", "Error")
                    break;
                default:
                    toastr.error("Error al actualizar", "Error")
                    break;
            }
        })
    }

    //------ Table styles ------//

    const selectFormat = (cell, row) => {
        return (
            <select className="form-control dropdown-toggle height-none border-0" name="cargosHomologados" onChange={(e) => handleDbClickCH(e, row, 0)}>
                {
                    cargosHomologados.map((cargos, i) => {
                        return (
                            <option key={i}
                                value={cargos.categoryEntId}
                                selected={cargos.description === cell ? true : false}>
                                {cargos.description}
                            </option>
                        )
                    })
                }
            </select >
        )
    }

    const inputFormat = (cell, row) => {
        return (
            <input className="form-control height-none py-0  border-0" defaultValue={cell} onKeyDown={(e) => handleKeyEnter(e, row, 0)} />
        )
    }

    const estatusFormat = (cell, row) => {
        if (cell === 1 || cell === 99) {
            return (
                <div>activo</div>
            )
        } else if (cell === 0) {
            return (
                <div>inactivo</div>
            )
        } else {
            return (
                <div>sin definir</div>
            )
        }
    }

    const rowStyleFormat = () => {
        return { backgroundColor: '#fff' }
    }

    const tdStyleFormat = () => {
        return { padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word', height: '50px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1' }
    }

    const thStyleFormat = () => {
        return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '0px', paddingRight: '0px' }
    }

    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        noDataText: 'No se encontraron registros.',
    }

    //------ Table styles ------//

    return (
        <div className="col-12 bg-white rounded p-2 mb-5">
            <BootstrapTable
                data={cargos}
                striped
                hover
                condensed
                pagination={true}
                tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                trClassName='tr-data-table'
                options={options}
                trStyle={rowStyleFormat}
            >
                <TableHeaderColumn isKey dataField="descCategory" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} dataFormat={selectFormat}>Cargo Homologado</TableHeaderColumn>
                <TableHeaderColumn dataField="descJob" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} dataFormat={inputFormat} >Cargo</TableHeaderColumn>
                <TableHeaderColumn dataField="active" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} dataFormat={estatusFormat} >Estatus</TableHeaderColumn>
                <TableHeaderColumn dataField="numCollab" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Número de Colaboradores</TableHeaderColumn>
            </BootstrapTable>
            <button className="btn btn-blue-3 btn-sm pointer" onClick={() => handleClickReturnBack()}>Regresar</button>
            {/*<!-- The Modal -->*/}
            <div className="modal" id="confirmation" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                        {/*<!-- Modal body -->*/}
                        <div className="modal-body scrollbar-style">
                            <div className="row d-flex justify-content-center mb-3">
                                <div className="col-8">
                                    <div className="col-12 text-center my-3">
                                        <i className="ft-alert-circle icon-big-size text-yellow" />
                                    </div>
                                    <div className="col-12 text-center mb-5">
                                        <p className="font-medium-2 font-weight-bold m-0">El cargo {oldCargo} se encuentra en {numAreas} áreas diferentes</p>
                                        <p className="font-medium-2 font-weight-bold m-0">¿Qué deseas hacer?</p>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12 col-sm-6 order-sm-2">
                                            <button className="btn btn-blue-3 btn-sm pointer float-right" onClick={() => handleClickCH(objCargoHom, 2)}>Todas las &aacute;reas</button><br />
                                            <p className="float-left">Actualizar en:</p><br />
                                            <button className="btn btn-blue-3 btn-sm pointer float-right" onClick={() => handleClickCH(objCargoHom, 1)}>Solo en esta &aacute;rea</button>
                                        </div>
                                        <div className="col-12 col-sm-6 order-sm-1 d-flex align-items-end">
                                            <button className="btn btn-blue-3 btn-sm pointer" onClick={() => $('#confirmation').hide()}>Regresar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!-- The Modal -->*/}

            {/*<!-- The Modal -->*/}
            <div className="modal" id="confirmationCargo" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }}>
                        {/*<!-- Modal body -->*/}
                        <div className="modal-body scrollbar-style">
                            <div className="row d-flex justify-content-center mb-3">
                                <div className="col-8">
                                    <div className="col-12 text-center my-3">
                                        <i className="ft-alert-circle icon-big-size text-yellow" />
                                    </div>
                                    <div className="col-12 text-center mb-5">
                                        <p className="font-medium-2 font-weight-bold m-0">El cargo {oldCargo} se encuentra en {numAreas} áreas diferentes</p>
                                        <p className="font-medium-2 font-weight-bold m-0">¿Qué deseas hacer?</p>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12 col-sm-6 order-sm-2">
                                            <button className="btn btn-blue-3 btn-sm pointer float-right button-50" onClick={() => handleUpdateJobs(objCargo, 2)}>Todas las &aacute;reas</button><br />
                                            <p className="float-left">Actualizar en:</p><br />
                                            <button className="btn btn-blue-3 btn-sm pointer float-right button-50"
                                                onClick={() => handleUpdateJobs(objCargo, 1)}>Solo  esta  &aacute;rea </button>
                                        </div>
                                        <div className="col-12 col-sm-6 order-sm-1 d-flex align-items-end">
                                            <button className="btn btn-blue-3 btn-sm pointer" onClick={() => $('#confirmationCargo').hide()}>Regresar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!-- The Modal -->*/}

        </div>
    )
}

export default TablaCargosAreas
