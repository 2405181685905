//actions
const CHANGE_PRODUCT_CATEGORY_SUBCATEGORY = 'CHANGE_PRODUCT_CATEGORY_SUBCATEGORY';
const CHANGE_ENTERPRISE = "CHANGE_ENTERPRISE";
const RESET_VALUES = "RESET_VALUES";

const initialState = {
    idProduct: -1,
    idCategory: -1,
    idSubCategory: -1,
    enterpriseInScope: {}
}

// reducer
const generalReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_PRODUCT_CATEGORY_SUBCATEGORY:
            return {
                ...state,
                ...action.payload
            }

        case CHANGE_ENTERPRISE:
            return {
                ...state,
                enterpriseInScope: { ...action.payload }
            }

        case RESET_VALUES:
            return {
                ...initialState
            }

        default:
            return state;
    }

}

export default generalReducer;

//auxiliar
const saveStorage = function (general) {
    sessionStorage.setItem('general', btoa(JSON.stringify(general)));
}

const removeStorage = function () {
    sessionStorage.removeItem('general');
}

//action creators
export const restoreGeneralAction = () => (dispatch) => {
    if (sessionStorage.getItem('general') !== null) {
        const general = JSON.parse(atob(sessionStorage.getItem('general')));
        if (general && general.idProduct !== -1) {
            dispatch({
                type: CHANGE_PRODUCT_CATEGORY_SUBCATEGORY,
                payload: {
                    idProduct: general.idProduct,
                    idCategory: general.idCategory,
                    idSubCategory: general.idSubCategory
                }
            })
        }
        if (general && general.enterpriseInScope !== {}) {
            dispatch({
                type: CHANGE_ENTERPRISE,
                payload: {
                    ...general.enterpriseInScope
                }
            })
        }
    }
}

export const changeProductCategorySubCategory = (idProduct, idCategory, idSubCategory) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PRODUCT_CATEGORY_SUBCATEGORY,
        payload: {
            idProduct: idProduct,
            idCategory: idCategory,
            idSubCategory: idSubCategory
        }
    });
    saveStorage(getState().general);
}

export const resetProductCategorySubCategory = () => (dispatch, getState) => {
    dispatch({
        type: CHANGE_PRODUCT_CATEGORY_SUBCATEGORY,
        payload: {
            idProduct: initialState.idProduct,
            idCategory: initialState.idCategory,
            idSubCategory: initialState.idSubCategory
        }
    });
    saveStorage(getState().general);
}

export const clearGeneralAction = () => (dispatch, _getState) => {
    dispatch({
        type: RESET_VALUES
    });
    removeStorage();
}

export const changeEnterpriseAction = (enterpriseInfo) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_ENTERPRISE,
        payload: {
            ...enterpriseInfo
        }
    });
    saveStorage(getState().general);
}
