import React, { useState, useEffect } from 'react'
import SeccionOlvidar from './SeccionOlvidar'

const PlanDentalPlus = () => {
    const [urlImg, setUrlImg] = useState('')
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            // platform = "Mobile";
            setUrlImg("./../img/beneficios/BANNER_plan_dental_plus_mobile.png")
        } else {
            setUrlImg("./../img/beneficios/BANNER_plan_dental_plus.png")
        }
      
    }, [])
  return (
    <div className='container-fluid bg-transparent-2'>
        <div className='row  bg-pink-iam shadow-card-2'>
            <img className="img-responsive" src={urlImg} alt="img-banner" />
            {/* <div className='col-12'>
                <div className='row'>
                    <div className='col-4 col-md-3 d-flex align-items-center justify-content-center'>
                        <div>
                            <img
                                src="./../img/beneficios/imagen_dental_plus_2.png"
                                className='img-responsive'
                                alt="im1-1"
                            />
                        </div>
                    </div>
                    <div className='col-8 col-md-9'>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-12 border-bottom-white'>
                                    <div className='d-flex align-items-center justify-content-center py-1 mt-2'>
                                        <h1 className='text-white fs-1-125'>Plan Dental Plus</h1>
                                            <img
                                                src="./../img/beneficios/icono_dental_plus.png" 
                                                alt="icono-dental"
                                                className='icon-size-34x34 mx-1'
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mt-2'>
                                    <ul className='text-white fs-1 font-weight-bold'>
                                        <li className='text-white py-1'>Urgencias dentales * <span className='text-blue-green'>ilimitadas</span><span className='font-weight-light'> con eventos ilimitados durante el año</span></li>
                                        <li className='text-white py-1'>Limpieza dental <span className='font-weight-light'>con ultrasonido </span><span className='text-blue-green'>1</span> al año <span className='font-weight-light'> a excepción de situación de urgencia</span></li>
                                        <li className='text-white py-1'>Extracciones de terceros molares y quirúrgicas <span className='font-weight-light'>están limitadas*</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        <div className='row'>
            <div className='ml-2 col-12 d-flex align-items-center mt-2 mt-md-2'>
                <h4 className='font-weight-bold text-blue-sec fs-1'>Procedimientos incluidos:</h4>
            </div>
        </div>

        <div className='row justify-content-center flex-wrap align-content-center'>
            <div className='mb-1 col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_1.png" alt="img-dentista-1" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875'>Consulta de emergencia</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Historia Clínica de urgencia</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Radiografía periapical en caso de emergencia <span className='font-weight-light'>(hasta</span> 4 <span className='font-weight-light'>radiografías por evento)</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Eliminación de caries</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Recubrimiento pulpar directo e indirecto</p>
                </div>
            </div>
            <div className='col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_2.png" alt="img-dentista-2" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875'>Cemento provisional</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Obturación temporal</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Ajustes de oclusión</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Cementado provisional de prótesis fijas</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Curetaje radicular localizado</p>
                </div>
            </div> 
            <div className='mb-1 col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_3.png" alt="img-dentista-3" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875'>Amalgama en caso de emergencia <span className='font-weight-light'>1 cara y 2 caras</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Resina en caso de emergencia dientes anteriores <span className='font-weight-light'>1 y 2 superficies</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Resina en caso de emergencia dientes posteriores <span className='font-weight-light'>1 y 2 superficies</span></p>
                </div>
            </div>
            <div className='mb-1 col-2 col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2 mx-0'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_4.png" alt="img-dentista-4" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875'>Ionómeros de vidrio en caso de emergencia</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Extracciones simples en caso de emergencia <span className='font-weight-light'>niños y adultos</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Pulpectomía en caso de emergencia</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Pulpotomía en caso de emergencia</p>
                </div>
            </div>
            <div className='mb-1 col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_5.png" alt="img-dentista-5" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875 m-0'>Tratamiento en caso de emergencia:</p>
                    <ul className='font-weight-bold'>
                        <li className='font-weight-normal text-blue-sec fs-875'>conducto uniradicular</li>
                        <li className='font-weight-normal text-blue-sec fs-875'>conducto biradicular</li>
                        <li className='font-weight-normal text-blue-sec fs-875'>conducto multiradicular</li>
                    </ul>
                </div>
            </div>
            <div className='mb-1 col-11 col-md-4 col-lg-4 d-flex align-items-end flex-wrap justify-content-center text-white my-2'>
                {/* <button className="btn btn-sm btn-save-style2 fs-1 font-weight-bold rounded-pill mb-1">Ver detalle</button> */}
            </div>  
        </div>

        <div className='row'>
            <div className='col-6 col-md-5 col-lg-5 d-flex'></div>
            <div className='col-12 col-md-7 col-lg-7 d-flex align-items-center justify-content-center mt-1 mt-md-1'>
                <p className='font-weight-light fs-75 text-blue-sec'><span className='font-weight-normal'>*Urgencia dental</span> es la situación inesperada causada por enfermedad o accidente que amerita atención odontológica paliativa urgente derivada de las siguientes manifestaciones: dolor intenso, inflamación, hemorragia, fractura dental.</p>
            </div>
        </div>
        
        <div className='row'>
            <div className='col-12 col-md-7 col-lg-7 d-flex align-items-center justify-content-center my-2 my-md-2 bg-blue-iam-2'>
                <h4 className='font-weight-bold text-white text-center fs-1-25 py-1 m-0'>En caso de emergencia cuentas 2 veces al año con:</h4>
            </div>
        </div>

        <div className='row '>
            <div className='col-6 col-md-3 col-lg-3'>
                <div className='col-12 p-1 h-100 d-flex align-items-center flex-wrap justify-content-center'>
                    <img className="img-responsive w-75" src="./../img/beneficios/Icono_urgencias.png" alt="img-urgencias" />
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 d-flex align-items-center flex-wrap justify-content-center'>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <p className='font-weight-light fs-75 text-blue-sec m-0 py-1'><span className='font-weight-normal'>*Extracciones de terceros molares erupcionados en caso de emergencia</span>  (adultos)</p>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 d-flex align-items-center flex-wrap justify-content-center'>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <p className='font-weight-light fs-75 text-blue-sec m-0 py-1'><span className='font-weight-normal'>*Extracciones de terceros molares incluidos en caso de emergencia</span>  (adultos)</p>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 d-flex align-items-center flex-wrap justify-content-center'>
                <div className='card p-1 h-50 d-flex align-items-center justify-content-center'>
                    <p className='font-weight-normal fs-75 text-blue-sec m-0 py-1'>*Extracciones quirúrgicas en caso de emergencia <span className='font-weight-light'>(adultos)</span></p>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-6 col-md-5 col-lg-5 d-flex'></div>
            <div className='col-12 col-md-7 col-lg-7 d-flex align-items-center justify-content-center'>
                <p className='font-weight-normal fs-75 text-blue-sec m-0'>*No se cubren retratamientos de procedimientos realizados por profesionales que no sean parte de la red o previos al periodo cubierto.</p>
            </div>
        </div>
        <SeccionOlvidar/> 
    </div> 
   
  )
}

export default PlanDentalPlus
