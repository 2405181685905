import React from 'react';

const CollabMenu = ({ goToByLink, rol }) => {
    return (
        <>
            <li className=" nav-item">
                <a href={rol === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")} onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/inicio-colaborador")}
                    >Pantalla principal</span>
                </a>
            </li>
            <li className=" nav-item">
                <a href="/perfil" onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/perfil")}
                    >Mi perfil</span>
                </a>
            </li>
            {/* <li className=" nav-item">
                <a href="/tienda" onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/tienda")}
                    >Tienda guay</span>
                </a>
            </li> */}
        </>
    );
}

export default CollabMenu;
