import React, { useState, Fragment } from 'react';
import Popup from 'reactjs-popup';
//import { IconGuaycitaHelper } from './components/guaycitaHelper/IconGuaycitaHelper';
//import { FloatDivHelper } from './components/guaycitaHelper/FloatDivHelper';
import { getJwt } from "../../lib/auth";
import $ from "jquery";
import ModalFooterViewPdf from './enterprises/ModalFooterViewPdf';

//import { connect } from 'react-redux';
const FooterApp = (/* { general } */) => {
    //const [displayHelp, setDisplayHelp] = useState(true);
    //const { idProduct, idCategory, idSubCategory } = general;
    const [urlShowPdf, setUrlShowPdf] = useState("")
    
    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        setUrlShowPdf(url);
        $('#closeModalFooterViewPdf').show()
    }

    return (
        <div className="col-12 bootomFooter-1 footerApp-style-1">
            <ModalFooterViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf}/>
            <div className="row">
                <div className="col-12 col-md-6 d-flex flex-wrap align-content-center guay-contact">
                    <div data-tut="tourColaborador-step-8">
                        {
                            getJwt('rol') === '2' ||
                                getJwt('rol') === '3' ||
                                getJwt('rol') === '5' ? (
                                <Fragment>
                                    <a className="d-inline p-footerApp left" href='/support' target="_blank" rel="noreferrer">Contáctanos</a>
                                    <a className="d-inline p-footerApp left" href={global.urlSupport} target="_blank" rel="noreferrer">Ayuda y soporte</a>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Popup trigger={<p className="d-inline p-footerApp left pointer">Contáctanos</p>} position="right">
                                        <div className="text-center">
                                            Teléfono: <a href="tel:5512490998">5512490998</a>
                                        </div>
                                    </Popup>
                                    <a className="d-inline p-footerApp left" href='/support' target="_blank" rel="noreferrer">Ayuda y soporte</a>
                                </Fragment>
                            )
                        }
                    </div>
                    <div className='p-0 m-0'>
                        <p
                            className="p-footerApp pointer left py-0"
                            onClick={() => previewFilePDF("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")} >Aviso de privacidad</p>
                    </div>
                    <div className='p-0 m-0'>
                        <p
                            className="p-footerApp pointer py-0"
                            onClick={() => previewFilePDF("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Terminos_%26_Condiciones_generales_Plataforma_guay.pdf")} >Términos y Condiciones</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-between bg-white" style={{ height: "55px" }}>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-wrap align-content-center">
                        <p>Copyright © 2021 guay</p>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-end flex-wrap align-content-center">
                        <div className="row">
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="d-sm-block text-yellow div-icons-redes">Síguenos</span>
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                <a href="https://www.facebook.com/guay-107436667630740" target="_blank" rel="noopener noreferrer"><img className="img-icon-footerApp" src="./../img/imagesFooter/loguin_redes_sociales_face.png" alt="img-guay" /></a>
                                <a href="https://www.linkedin.com/company/mesientoguay/" target="_blank" rel="noopener noreferrer"><img className="img-icon-footerApp" src="../../img/guay-website/resources/iconos_redes-10.png" alt="img-guay" /></a>
                                {/* <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer"><img className="img-icon-footerApp" src="./../img/imagesFooter/loguin_redes_sociales-you.png" alt="img-guay" /></a>
                                <a href="https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer"><img className="img-icon-footerApp" src="./../img/imagesFooter/loguin_redes_sociales-insta.png" alt="img-guay" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 col-md-2 d-flex justify-content-end">
                    <IconGuaycitaHelper
                        displayHelp={displayHelp}
                        setDisplayHelp={setDisplayHelp}
                    />
                </div> */}
            </div>
            {/* <FloatDivHelper
                //setDisplayHelp={setDisplayHelp}
                idProduct={idProduct}
                idCategory={idCategory}
                idSubCategory={idSubCategory}
            /> */}
        </div>
    );
}

/* const mapStateToProps = ({ general }) => {
    return {
        general
    }
}
export default connect(mapStateToProps)(FooterApp); */

export default FooterApp