import React, { useState } from "react";
import "../../Config";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
// import FacebookLogin from "react-facebook-login";
// import GoogleLogin from "react-google-login";
// import AppleLogin from 'react-apple-login';
import Recaptcha from "react-google-invisible-recaptcha";
import { setJwt } from "../../../lib/auth";
import { postRequest } from "../../../lib/api/api";
import "animate.css";

const Register = () => {
  const [regisData, updStateReg] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    type: "1",
    usePhone: 0,
    isValidate: 0,
    id: 0,
    img: "",
    lastName: "",
  });
  //States
  const {
    // name,
    // email,
    // phone,
    // key,
    usePhone,
    // type,
    isValidate,
    // user,
    // img,
    // lastName,
  } = regisData;
  const [validation1, setValidation1] = useState(false);
  const [validation2, setValidation2] = useState(false);
  const [validation3, setValidation3] = useState(false);
  const [validation4, setValidation4] = useState(false);
  const [validation5, setValidation5] = useState(false);
  const [validation6, setValidation6] = useState(false);
  //Update phone or emial field
  const changeUser = (e) => {
    try {
      let type = $(e.target).attr("data-type");
      let value = 0;
      switch (type) {
        case "1":
          $("#wrap-email").removeClass("hidden");
          $("#wrap-phone").addClass("hidden");
          $("#phone").val("");
          updStateReg({
            ...regisData,
            phone: "",
          });
          break;
        case "2":
          $("#wrap-phone").removeClass("hidden");
          $("#wrap-email").addClass("hidden");
          $("#email").val("");
          updStateReg({
            ...regisData,
            email: "",
          });
          value = 1;
          break;
        default:
          break;
      }
      updStateReg({
        ...regisData,
        usePhone: value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // //Facebook Register & Login
  // const responseFacebook = (response) => {
  //   try {
  //     if (!response.error) {
  //       //regisData.user = response.id;
  //       regisData.name = response.name;
  //       regisData.email = response.email;
  //       regisData.img = response.picture.data.url;
  //       regisData.type = 2;
  //       regisData.key = "";
  //       userModel();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // //Google Register & Login
  // const responseGoogle = (response) => {
  //   try {
  //     if (!response.error) {
  //       let result = response.profileObj;
  //       regisData.name = result.givenName;
  //       regisData.lastName = result.familyName;
  //       regisData.email = result.email;
  //       regisData.img = result.imageUrl;
  //       regisData.type = 3;
  //       regisData.key = "";
  //       userModel();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Change Value form
  const getValue = (e) => {
    try {
      let value = e.target.value;
      let validateField = 0;
      switch (e.target.name) {
        case "name":
          if (value === null || value.length === 0 || /^\s+$/.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            validateField = 0;
          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            validateField = 1;
          }
          break;
        case "lastName":
          if (value === null || value.length === 0 || /^\s+$/.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            validateField = 0;
          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            validateField = 1;
          }
          break;
        case "email":
          let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
          if (regex.test(value)) {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            validateField = 1;
          } else {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            validateField = 0;
          }
          break;
        case "phone":
          //eslint-disable-next-line
          let regphone = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g
          if (!regphone.test(value)) {
            regisData.email = "";
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            validateField = 0;
          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            validateField = 1;
          }
          break;
        case "key":
          /* let regexPass = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/ */
          /* let regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-]).{6,}$/ */
          let counter = 0;
          let regexSpecial = /[!@#$%^&*._-]/
          let regexCapital = /[A-Z]/
          let regexNumber = /[0-9]/
          let regexLength = /().{6}/
          let regexWhitespace = /\s/
          let regexLower = /[a-z]/
          if (regexSpecial.test(value)) {
            setValidation1(true);
            counter++;
          } else {
            setValidation1(false);
          }
          if (regexCapital.test(value)) {
            setValidation2(true);
            counter++;
          } else {
            setValidation2(false);
          }
          if (regexNumber.test(value)) {
            setValidation3(true);
            counter++;
          } else {
            setValidation3(false);
          }
          if (regexLength.test(value)) {
            setValidation4(true);
            counter++;
          } else {
            setValidation4(false);
          }
          if (regexWhitespace.test(value)) {
            setValidation5(true);
          } else {
            setValidation5(false);
            counter++;
          }
          if (regexLower.test(value)) {
            setValidation6(true);
            counter++;
          } else {
            setValidation6(false);
          }
          if (counter === 6) {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            validateField = 1;
          } else {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            validateField = 0;
          }
          /* if (!regexPass.test(value)) {
            $("#" + e.target.name).removeClass("border-success");
            $("#" + e.target.name).addClass("border-danger");
            validateField = 0;
          } else {
            $("#" + e.target.name).removeClass("border-danger");
            $("#" + e.target.name).addClass("border-success");
            validateField = 1;
          } */
          break;
        default:
          break;
      }
      updStateReg({
        ...regisData,
        [e.target.name]: value,
        isValidate: validateField,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const setUser = (e) => {
    $('#btn-register').text("").prop('disabled', true)
      .addClass("btn-guay-processing")
      .append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite	infinite"></i> Procesando...');
    $("#form-users :input").prop("disabled", true);
    e.preventDefault();
    try {
      if (isValidate === 1) {
        userModel();
      } else {
        setTimeout(function () {
          $('#btn-register').text("").prop('disabled', false)
            .append('Crear cuenta')
            .removeClass("btn-guay-processing");
          $("#form-users :input").prop("disabled", false);
        }, 500);

        setTimeout(function () {
          Swal.fire({
            title: '<p class="text-white mb-2">¡Atención!</p>',
            text: "Debe registrar la información solicitada.",
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Ok",
            dangerMode: true
          });
          $(".swal2-title").addClass("bg-header-swal");
          $(".swal2-actions").addClass("w-100 justify-content-around");
          $(".swal2-confirm").removeClass("swal2-styled");
          $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
          $(".swal2-cancel").removeClass("swal2-styled");
          $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        }, 500);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userModel = async () => {
    try {
      const url = global.base_url + "users/register";
      const response = await axios.post(url, regisData, {
        headers: {
          'Authorization': '' + global.tokenAuth + '',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.success) {
        let message = "";
        switch (response.data.code) {
          case 1000:
            message = "Error al registrar la informacion. Intente Nuevamente.";
            break;
          case 1001:
            message =
              "Ya existe una cuenta registrada con ese número o correo electrónico.";
            break;
          case 1002:
            message =
              "Ya existe una cuenta registrada con el correo electrónico: " +
              response.data.email +
              ".";
            break;
          default:
            message = "Error del sistema. Intente Nuevamente.";
            break;
        }
        setTimeout(function () {
          Swal.fire({
            title: '<p class="text-white mb-2">¡Oops!</p>',
            text: " " + message + " ",
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Ok",
            dangerMode: true
          }).then((response) => {
            window.location = "/"
          })
          $(".swal2-title").addClass("bg-header-swal");
          $(".swal2-actions").addClass("w-100 justify-content-around");
          $(".swal2-confirm").removeClass("swal2-styled");
          $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
          $(".swal2-cancel").removeClass("swal2-styled");
          $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        }, 2000);

      } else {
        switch (response.data.type) {
          case 1:
            if (usePhone === 0) {
              Swal.fire({
                title: '<p class="text-white mb-2">¡Éxito!</p>',
                text: "Tu cuenta se ha creado correctamente. Se ha enviado un correo electrónico para verificar tu cuenta",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Login",
                dangerMode: true
              }).then((response) => {
                setTimeout(function () {
                  window.location = "/"
                }, 2000);
              });
              $(".swal2-title").addClass("bg-header-swal");
              $(".swal2-actions").addClass("w-100 justify-content-around");
              $(".swal2-confirm").removeClass("swal2-styled");
              $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
              $(".swal2-cancel").removeClass("swal2-styled");
              $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
              // [VZO] 29/01/21 Ignore warning -> 'ref' is assigned a value but never used no-unused-vars
              //let ref = response.data.id;
              // [VZO] 29/01/21 Ignore warning -> 'token' is assigned a value but never used  no-unused-vars
              //let token = Math.random().toString(36).slice(2)
              //window.location = "validar-cuenta-sms/dblm871=" + ref + "=" + token + "";
            }
            break;
          case 2:
          case 3:
            postRequest("/users/data?user=" + regisData.user).then((result) => {
              let userData = {
                id: result.token
                , enterprise: result.enterprise
                , branch: result.branch
                , rol: result.rol
                , help: result.help
                , help2: 0
                , tkc: result.pwd
                , email: result.mail
                , session: result.session
              }
              console.log(result)
              console.log(userData)
              setJwt(userData)
            });

            //window.location = "inicio-tienda";
            break;
          default:
            setTimeout(function () {
              Swal.fire({
                title: '<p class="text-white mb-2">¡Oops!</p>',
                text: "Ocurrió un problema intenta de nuevo.",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Login",
                dangerMode: true
              });
              $(".swal2-title").addClass("bg-header-swal");
              $(".swal2-actions").addClass("w-100 justify-content-around");
              $(".swal2-confirm").removeClass("swal2-styled");
              $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
              $(".swal2-cancel").removeClass("swal2-styled");
              $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            }, 2000);
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Hide and show text in inputs
  const seePassword = (e) => {
    e.preventDefault();
    try {
      switch (e.target.id) {
        case "icon_eye_1":
          var input = $("#key");
          if (input.attr("type") === "password") {
            input.attr("type", "text");
            $("#icon_eye_1").attr("class", "ft-eye-off");
          } else {
            input.attr("type", "password");
            $("#icon_eye_1").attr("class", "ft-eye");
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="app-content content">
      <div className="content-overlay">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <section className="bg-login-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-xs-12 col-md-4 align-items-center d-flex justify-content-center ">
                      <div className="row">
                        <div className="col-md-12">
                          <div style={{ height: "500px" }}>
                            <div className="card border-grey border-lighten-3 mt-2 h-40 card-header-register" style={{ borderRadius: "15px" }}>
                              <div className="">
                                <div className="border-0 bg-header-login" style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                                  <div className="font-large-1 text-center text-dark" style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#15253c" }}>
                                    <span className="font-medium-5" style={{ fontFamily: "quicksand", color: 'white' }}>Crea tu cuenta</span>{" "}
                                  </div>
                                </div>
                                {/* <div className="col-md-12 input-group mt-1">
                                  <div className="col-md-12 align-items-center d-flex justify-content-center ">
                                    <FacebookLogin
                                      appId="585782192144603"
                                      fields="name,email,picture"
                                      callback={responseFacebook}
                                      icon="fa-facebook fa-2x icon-fb-size float-left ml--3px"
                                      textButton="Entra con Facebook"
                                      cssClass="icon-facebook-registrar"
                                    />
                                  </div>
                                  <div className="col-md-12 mt-1 align-items-center d-flex justify-content-center ">
                                    <GoogleLogin
                                      clientId="78720906845-tk0et1p4cln26qpb7n4h5btreeg1b6d3.apps.googleusercontent.com"
                                      textButton="Entrar con Google"
                                      onSuccess={responseGoogle}
                                      onFailure={responseGoogle}
                                      render={(renderProps) => (
                                        <button
                                          className="icon-google-registrar"
                                          onClick={renderProps.onClick}
                                          textButton="Entrar con Gmail"
                                        >


                                          <i className="fa fa-google-plus icon-google-size" style={{
                                            marginLeft:
                                              '3px', fontSize: "1.5em", float: "left"
                                          }} />
                                          <span style={{ float: "unset", marginLeft: "-36px" }}>
                                            {"Entra con Gmail"}
                                          </span>

                                        </button>
                                      )}
                                    />
                                  </div>
                                  <div className="col-md-12 mt-1 align-items-center d-flex justify-content-center">
                                    <AppleLogin
                                      clientId={"com.react.apple.login"}
                                      redirectURI={"https://redirectUrl.com"}
                                      responseType={"code"}
                                      responseMode={"query"}
                                      usePopup={false}
                                      designProp={
                                        {
                                          height: 30,
                                          width: 207,
                                          color: "black",
                                          border: false,
                                          type: "sign-in",
                                          border_radius: 5,
                                          scale: 1,
                                          locale: "es_MX",
                                        }
                                      }
                                    />

                                  </div>
                                </div> */}

                                {/*<div className="col-md-12 text-center ">
                          <div className="row pt-2">
                            <div className="col-md-1"></div>
                            <div className="col-xs-12 col-md-6">
                              <p className="text-white font-medium-3 text-bold-300 text-right">
                                Ingresa usando
                          </p>
                            </div>
                            <div className=" col-xs-6 col-md-2 ml-1">
                              <FacebookLogin
                                appId="585782192144603"
                                fields="name,email,picture"
                                callback={responseFacebook}
                                icon="fa-facebook fa-2x"
                                textButton=""
                                cssClass="icon-facebook"
                              />
                            </div>
                            <div className="col-xs-6 col-md-2">
                              <GoogleLogin
                                clientId="78720906845-tk0et1p4cln26qpb7n4h5btreeg1b6d3.apps.googleusercontent.com"
                                buttonText=""
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                render={(renderProps) => (
                                  <button
                                    className="icon-google"
                                    onClick={renderProps.onClick}
                                  >
                                    {" "}
                                    <FontAwesomeIcon icon={faGoogle} />
                                  </button>
                                )}
                              />
                            </div>
                          </div>
                          <div className="row mt-1 ">
                            <div className="col-md-12">
                              <p className="text-white font-medium-3 text-bold-300 text-center">
                                ¿Ya tienes una cuenta?
                            <a
                                  href="acceso"
                                  className="text-white general-link"
                                >
                                  <strong> Ingresa aquí</strong>
                                </a>
                              </p>
                            </div>
                          </div>
                                </div>*/}
                                {/* <div className="col-md-12  align-items-center d-flex justify-content-center ">
                                  <div className="text-base" id="or">o</div>
                                </div> */}
                                <div className="col-md-12">
                                  <form
                                    id="form-users"
                                    onSubmit={setUser}
                                    autoComplete="off"
                                  >
                                    <div className="row mt-2">
                                      <div className="col-md-1"></div>
                                      <div className="col-md-9 align-items-center justify-content-center ml-2 ">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                          </div>

                                          <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            onChange={getValue}
                                            style={{ height: "30px", fontFamily: "quicksand" }}
                                          />
                                        </div>
                                        <div className="input-group mt-1">
                                          <div className="input-group-prepend">
                                          </div>


                                          <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Apellidos"
                                            onChange={getValue}
                                            style={{ height: "30px", fontFamily: "quicksand" }}
                                          />
                                        </div>
                                        <div id="wrap-email">
                                          <div className="input-group mt-1">
                                            <div className="input-group-prepend">
                                            </div>

                                            <input
                                              type="email"
                                              className="form-control"
                                              id="email"
                                              name="email"
                                              placeholder="Correo"
                                              onChange={getValue}
                                              style={{ height: "30px", fontFamily: "quicksand" }}
                                            />
                                          </div>


                                        </div>
                                        <div id="wrap-phone" className="hidden">
                                          <div className="input-group mt-1">
                                            <div className="input-group-prepend">
                                            </div>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="phone"
                                              name="phone"
                                              placeholder="Teléfono"
                                              onChange={getValue}
                                              maxLength="12"
                                              minLength="0"
                                              style={{ height: "30px", fontFamily: "quicksand" }}
                                            />
                                          </div>
                                          <span
                                            className="text-black font-small-2 text-base pointer sw-phone"
                                            data-type="1"
                                            onClick={changeUser}
                                            style={{ fontFamily: "quicksand" }}
                                          >
                                            Usar correo
                                          </span>
                                        </div>
                                        <div className="input-group mt-1">
                                          <div className="input-group-prepend">
                                          </div>
                                        </div>
                                        <div className="input-group">
                                          <input
                                            id="key"
                                            name="key"
                                            type="password"
                                            className="form-control"
                                            placeholder="Contraseña"
                                            onChange={getValue}
                                            style={{ height: "30px", fontFamily: "quicksand" }}
                                          />
                                          <div className="input-group-prepend"
                                            style={{ height: "30px", fontFamily: "quicksand" }}
                                          >
                                            <span className="input-group-text icon-eye-form" onClick={seePassword}><i className="ft-eye" id="icon_eye_1"></i></span>
                                          </div>
                                        </div>
                                        <p className="font-small-2 text-base text-center" style={{ fontFamily: "quicksand" }}>
                                          <span className="font-weight-bold">Requisitos:</span> Utiliza más de
                                          <span className={validation4 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}> 6 carácteres, </span>
                                          <span className={validation2 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 mayúscula, </span>
                                          <span className={validation6 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 minúscula, </span>
                                          <span className={validation3 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 número, </span>
                                          <span className={validation1 ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>1 símbolo (!@#$%^&*_-.), </span>
                                          <span className={validation5 === false ? ('text-green font-weight-bold') : ('text-blue font-weight-bold')}>sin espacios</span>
                                        </p>
                                        <div className="col-md-12">
                                          <Recaptcha
                                            sitekey="6Lf2KjIbAAAAAK-K2Cwtz7UkGf_ENb5B0dmrHFlx"
                                            onResolved={() => console.log("Human detected.")}
                                          />
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <button
                                            type="submit"
                                            color="blue"
                                            style={{ height: "40px", width: "150px", lineHeight: "0rem", backgroundColor: "#15253c", color: "white", borderRadius: "10px", fontFamily: "quicksand" }}
                                            className="btn"
                                            id="btn-register"
                                          >Crear cuenta</button>
                                        </div>
                                        <p className="text-dark text-bold-300 text-center mb-0 mt-1" style={{ fontSize: "small", fontFamily: "quicksand" }}>
                                          Al crear una cuenta, aceptas las <a href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf" target="_blank" rel="noreferrer" style={{ color: "#2188b4" }}>Condiciones de
                                            Uso</a> y el <a href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf" target="_blank" rel="noreferrer" style={{ color: "#2188b4" }}> Aviso de Privacidad </a>de guay
                                        </p>
                                        <div className="col-md-12 mt-1">
                                          <p className="text-dark text-bold-300 text-center" style={{ fontSize: "small", fontFamily: "quicksand" }}>
                                            ¿Ya tienes una cuenta?
                                            <a
                                              href="/"
                                              className="general-link"
                                            >
                                              <strong> Ingresa aquí</strong>
                                            </a>
                                          </p>

                                        </div>


                                      </div>

                                    </div>

                                  </form>
                                </div>
                              </div>

                            </div>


                          </div>
                          <div>


                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
