import React, { useEffect, useState } from 'react';

const Micro = () => {

    const [pageName, setPageName] = useState("Micro");

    useEffect(() => {
        const container = document.getElementById("dossierContainer1");
        let updateTitle = () => {
            const currCh = window.dossier.children[window.dossier.currentChapterIndex];
            const currPage = currCh.children[currCh.currentPageIndex];
            setPageName("MicroStrategy Embedding SDK Example - " + currPage.name);
        }
        var postData = {};
        postData.username = "carlos@guay.digital";
        postData.password = "123456";
        postData.loginMode = 1;
        postData.maxSearch = 3;
        postData.workingSet = 10;
        postData.applicationType = 35;
        window.microstrategy.dossier.create({
            placeholder: container,
            url: 'https://analytics.guay.digital/MicroStrategyLibrary/app/B8F4FAA04B2CB67B44CA53831BCADB22/A3D400C1428086B99AF4CA95D1460676',
            enableCustomAuthentication: true,
            enableResponsive: true,
            containerWidth: '1000px',
            containerHeight: '400px',
            customAuthenticationType: window.microstrategy.dossier.CustomAuthenticationType.AUTH_TOKEN,
            getLoginToken: async () => {
                return await getXHRRequestPromise('https://analytics.guay.digital/MicroStrategyLibrary/api/auth/login', postData, 'POST', 'application/json', 'x-mstr-authToken').then(function (authToken) {
                    console.log('authToken', authToken)
                    return authToken;
                })
            }
        }).then((dossier) => {
            console.log('dossier', dossier)
            dossier.registerEventHandler(window.microstrategy.dossier.EventType.ON_PAGE_SWITCHED, updateTitle);
            window.dossier = dossier;
            // window.menu.updateDossier();
            updateTitle();

        });
        function getXHRRequestPromise(url, body, method, contentType, desiredHeader) {
            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open(method, url);
                xhr.withCredentials = true;
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader("Accept", "application/json");
                xhr.send(JSON.stringify(body));
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 2) {
                        console.log('xhr', xhr)
                        resolve(xhr.getResponseHeader(desiredHeader));
                    } else {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText
                        });
                    }
                };
            });
        };

        return () => { }

    }, [])

    return (
        <div className="container mt-5">
            <h1>Micro</h1>
            <hr />
            <div>Test {pageName}</div>
            <div style={{ width: '90%', height: '80%', backgroundColor: 'white', float: 'right' }}>
                <div id="dossierContainer1" style={{ width: '1000px', height: '500px' }}></div>
            </div>
        </div>
    );
}

export default Micro;
