import axios from "axios";

export const getAllPromotions = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/getAllPromotions?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @returns 
 */
export const getAllSponsorLogo = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/getAllSponsorLogo?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getSprPromotionCategory = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sprCatalogs/getSprPromotionCategory",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getSprPromotionType = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sprCatalogs/getSprPromotionType",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {number} idEnterprise 
 * @param {number} section 
 * @returns 
 */
export const getBannersByEnterprise = async (idEnterprise, section) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/getBannersByEnterprise?idEnterprise=" + idEnterprise + "&section=" + section,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} formData 
 * @returns 
 */
export const setClickBranding = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/setClickBranding",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setViewTime = async (idBrandPresence, time) => {
    try {
        const formData = new FormData();
        const data = {
            idBrandPresence,
            time
        };
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "sponsor/setViewTime",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {number} idEnterprise 
 * @param {number} idUser 
 * @returns 
 */
export const getMarketStudiesQuiz = async (idEnterprise, idUser) => {
    try {
        let result = await axios({
            url: global.base_url + "quizSponsor/getMarketStudiesQuiz?idEnterprise=" + idEnterprise + "&idUser=" + idUser,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setResponseQuizCollab = async (data) => {
    try {
        const formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "quizSponsor/setResponseQuizCollab",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setFinishQuizCollab = async (data) => {
    try {
        const formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "quizSponsor/setFinishQuizCollab",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setClickBrandingByUser = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/setClickBrandingByUser",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setViewTimeByUser = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/setViewTimeByUser",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
