import React, { useState, useEffect } from "react";
// Tools
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const DemographicSelectClimate = ({
    question,
    answers,
    sections,
    setSections,
    saveResponseDemogClimate,
    idEnterprise,
    idGroup,
    idCollab,
    todayDate,
    coordinates,
    browser,
    OS,
    allAnswersComplete }) => {

    const [answerSelected, setAnswerSelected] = useState(0);

    useEffect(() => {
        for (let x in answers) {
            if (answers[x].check === 1) {
                setAnswerSelected(answers[x].idanswer);
            }
        }
        // eslint-disable-next-line
    }, []);

    const handleChangeSelect = e => {
        e.preventDefault();
        let section = [...sections];
        let idquestion = parseInt(e.target.id);
        let idanswer = parseInt(e.target.value);
        saveResponseDemogClimate(idanswer, idquestion, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        for (let x in section) {
                            for (let y in section[x].questions) {
                                if (section[x].questions[y].idquestion === idquestion) {
                                    for (let z in section[x].questions[y].answers) {
                                        if (section[x].questions[y].answers[z].idanswer === idanswer) {
                                            section[x].questions[y].answers[z].check = 1;
                                        } else {
                                            section[x].questions[y].answers[z].check = 0;
                                        }
                                    }
                                }
                            }
                        }
                        allAnswersComplete(section);
                        setSections(section);
                        setAnswerSelected(idanswer);
                        break;
                    default:
                        toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                        break;
                }
            } else {
                toastr.error("No se guardó correctamente la respuesta. Se tienen problemas con el servidor.", "¡Ooops!");
            }
        });
        const questionsLength = section[0].questions.length; //check the length of the questions in the section
        if (idquestion + 1 <= questionsLength) { //if next question is lower or equal to questions length make the scroll
            // element which needs to be scrolled to
            const element = document.getElementById(`${idquestion + 1}`);
            // scroll to element
            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        }
    }

    return (
        <div className="form-group">
            <select
                className="form-control"
                value={answerSelected}
                id={question.idquestion}
                onChange={handleChangeSelect}>
                <option value={0}>Selecciona</option>
                {answers.map((answer) => (
                    <option
                        key={question.idquestion + "-" + answer.idanswer}
                        value={answer.idanswer}>
                        {answer.description}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default DemographicSelectClimate;
