import React from "react";
/**
 * Render Buttons
 */
const Buttons = (props) => {
    let styles =" btn btn-min-width btn-" + props.color + " " + (props.size ?  props.size : "");
  return (
     <div className="form-group mt-2 text-center"  >
         <button 
            type={props.type} 
            className={styles}
           >
          {props.text}
          
    </button>   
     </div>
    
  );
};
export default Buttons;