import React, { useState, useEffect, Fragment } from 'react';
// API calls
import { getWebinars } from '../Models/Catalog_model';
// Tools
import ReactPlayer from 'react-player/lazy';

const WebinarPublico = () => {
    const [webinars, setWebinars] = useState([]);
    useEffect(() => {
        getWebinars().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let arr = []
                        for (let x in response.listWebinars) {
                            arr.push({ ...response.listWebinars[x], showVideo: false })
                        }
                        setWebinars(arr);
                        break;
                    default:
                        break;
                }
            }
        })
    }, []);

    const handleTouch = id => {
        //console.log(id)
        let arr = []
        for (let x in webinars) {
            if (webinars[x].idWebinar === id) {
                //console.log(webinars[x].idWebinar, id)
                arr.push({ ...webinars[x], showVideo: true })
            } else {
                arr.push(webinars[x])
            }
        }
        //console.log(arr)
        setWebinars(arr);
    }

        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Webinar</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <div className="col-11 mx-auto mt-1">
                                <div className="row">
                                    {
                                        webinars.map((data, index) => (
                                            <div className="col-12 col-sm-6 col-md-4 mb-2" key={data.idWebinar}>
                                                <div className="card m-0 h-100 hover-shadow-1">
                                                    <div className="card-img-top d-flex flex-wrap align-content-center h-100">
                                                        {data.showVideo ? (
                                                            <ReactPlayer
                                                                url={data.urlVideo}
                                                                width="100%"
                                                                height="100%"
                                                                controls
                                                                playing={true}
                                                            />
                                                        ) : (
                                                            <img
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                }}
                                                                src={data.urlImg}
                                                                alt="guay-digital"
                                                                onClick={() => handleTouch(data.idWebinar)}
                                                            />
                                                        )}
                                                    </div>
                                                    {data.showVideo && (
                                                        <div className="card-body d-flex flex-wrap align-content-center py-1">
                                                            <h4 className="card-tilte font-weight-bold">{data.title}</h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    
}

export default WebinarPublico;
