import React, { useState, useEffect } from "react";
// API calls
import { getBannersByEnterprise, setClickBrandingByUser } from './Models/SponsorModel';
// Tools
import * as toastr from 'toastr';
import { getJwt } from "../../lib/auth";

const BannerAdmin = () => {
    const [bannerImg, setBannerImg] = useState('');

    useEffect(() => {
        if (getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined') {
            
        } else {
            getBannersByEnterprise(getJwt('enterprise'), 5).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setBannerImg(response.resource);
                            break;
                        case 1002:

                            break;
                        default:
                            toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                }
            });
        }
    }, []);
    return (
        <div className='col-12 p-0'>
            {
                bannerImg.length === 0 ? (
                    null
                ) : (
                    <div className="card mt-3">
                        <div className='card-body p-0'>
                            <img
                                className='img-fluid'
                                src={bannerImg[0].resourceUrl}
                                onClick={() => {
                                    if (bannerImg[0].customUrl1 !== '') {
                                        let formData = new FormData();
                                        let data = {
                                            idBrandPresence: bannerImg[0].idBrandPresence,
                                            idUser: getJwt('jtw'),
                                            idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                                        }
                                        formData.append("request", JSON.stringify(data));
                                        setClickBrandingByUser(formData);
                                        let protocol = bannerImg[0].customUrl1.split(':');
                                        if (protocol[0] === 'https' || protocol[0] === 'http') {
                                            window.open(bannerImg[0].customUrl1);
                                        } else {
                                            window.open("//" + bannerImg[0].customUrl1);
                                        }
                                    }
                                }}
                                alt='banner'
                            />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default BannerAdmin;
