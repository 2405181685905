import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
// TODO: Generic func
import { getJwt } from './lib/auth'
// TODO: Tools
import * as toastr from 'toastr';

export const VisualizerErrorPage = () => {
    const history = useHistory()
    useEffect(() => {
        if (getJwt('rol') === '6') {
            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
            history.push("/inicio-colaborador")
        }
    }, [history])
    return (
        <div>Redirigiendo...</div>
    )
}
