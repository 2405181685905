import React from 'react';

const StatiticalFilterAnswer = ({ idQuestion, listAnswers, handleSelectAnswer }) => {
    return (
        <div className="row">
            {
                listAnswers.map((answer) => (
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 padding-small-2" key={idQuestion + "-" + answer.idAnswer}>
                        {answer.check === 1
                            ?
                            (
                                <span
                                    className="pointer"
                                    onClick={(e) => handleSelectAnswer(e, idQuestion, answer.idAnswer)} >
                                    <i className="ft ft-check-circle text-base-guay align-middle icon-size-small padding-small" />{answer.description}
                                </span>
                            )
                            :
                            (
                                <span
                                    className="pointer"
                                    onClick={(e) => handleSelectAnswer(e, idQuestion, answer.idAnswer)} >
                                    <i className="ft ft-circle text-base align-middle icon-size-small padding-small" />{answer.description}
                                </span>
                            )
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default StatiticalFilterAnswer;
