import React from "react";

const Datauser = ()=>{
    return(
        <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
          <div className="col-12">
                        <div className="card box-shadow-0">
                            <div className="card-content">
                                <div className="row">

                                    <div className="col-md-12 col-12">
                                        <div className="card-body mt-1">
                                            <div className="swiper-default swiper-container ">
                                                <div className="swiper-wrapper text-center">
                                                    <a href="/resources/template/sabias_que_guay.pdf" target="_blank"> <div className="pull-up pointer"> 
                                                        <img src="./../img/youknow.jpeg" alt="Sabias que?"/>
                                                    </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="text-center mt-30">
                                                <h2>¡Estás a punto de cumplir con la <br/> NOM 035 STPS 2018!</h2>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="text-center">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="pr-2 pt-2">
                                                                <div className="btn-group mr-1">
                                                                    <button type="button" className="btn btn-secondary"><i className="la la-download"></i>
                                                                        Descargables</button>
                                                                    <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="la la-angle-down"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item" href="/resources/template/Manual_GUAYClima.pdf">Manual de uso</a>
                                                                        <a className="dropdown-item" href="/resources/template/Plantilla_Colaboradores.xlsx">Plantilla de Colaboradores</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>        
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
          </div>
        </div>
      </div>
    )
}
export default Datauser;