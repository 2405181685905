import React from 'react';

const ClimateStructureSection = ({
    section,
    handleChange,
    textarea,
    getTextarea
}) => {
    return (
        <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto mt-1">
            {
                section.map((sectionData) => (
                    <div className="row" key={sectionData.idsection}>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-3">
                            <span className="text-base font-weight-bold">{sectionData.description}</span>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 mt-1">
                            {
                                sectionData.questions.map((question) => (
                                    <div className="card shadow-card-1" id={question.idquestion} key={question.idquestion}>
                                        <div className="card-header bg-blue-guay-2 py-1">
                                            <span>{question.description}</span>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row">
                                                    {
                                                        question.answers.map((answer) => (
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 padding-small-2" key={question.idquestion + "-" + answer.idanswer}>
                                                                {answer.check === 1
                                                                    ?
                                                                    (
                                                                        <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={(e) => handleChange(e, sectionData.idsection, question.idquestion, answer.idanswer)} ><i className="ft ft-check-circle text-base-guay align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={(e) => handleChange(e, sectionData.idsection, question.idquestion, answer.idanswer)} ><i className="ft ft-circle text-base align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            <div className="form-group">
                                <label className="text-base">Agradecemos nos hagas llegar tus comentarios, nos ayudarán a mejorar en temas de <span>{sectionData.description}</span> <span className="text-base">{"(máx. 200 caracteres)"}</span></label>
                                <textarea
                                    className="form-control textarea-unresize"
                                    rows="5"
                                    maxLength="200"
                                    onChange={getTextarea}
                                    value={textarea}></textarea>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ClimateStructureSection;
