import React, { useEffect/* , useState */ } from "react";
/* import * as toastr from 'toastr'; */
import 'toastr/build/toastr.css';

const Apple_callback = props => {

    useEffect(() => {
       
        console.log(props.location.search);
        let param = props.location.search;
        let obj = param.split("=");
        let token = obj[1].split(".");
        console.log(token[0]);
        // eslint-disable-next-line
    }, []);

        return (
            <div className="app-content content">
                 <div className="content-overlay"></div>
                 <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-md-12 text-center">
                            <h2>En un momento seguiras en la experiencia guay.</h2>   
                            </div>
                            
                        </div>
                    </div>
                 </div>
            </div>
        );

};
export default Apple_callback;