import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import { VisualizerRoutes } from './VisualizerRoutes';
import { AdminRouters } from './AdminRoutes';
import { getJwt } from './lib/auth';
import Login from "./components/auth/Login";
import Welcome from "./components/web/Welcome";
import Register from "./components/web/register/Register";
import ValidataSms from "./components/web/register/Validate-sms";
import ActivateUserShop from "./components/web/register/Activate-user-shopify";
import ForgottenPassword from './components/web/register/Forgotten-password';
import ActivateEmail from './components/app/NewHome/activateEmail';
import Activate_account from './components/web/register/Activate_account';
import PlanDePrevencion from './components/app/Beneficios/beneficios-plan-oftalmologia/PlanDePrevencion';
import PlanEsencial from './components/app/Beneficios/beneficios-plan-oftalmologia/PlanEsencial';
import SeguroAccidentesPersonales from './components/app/Beneficios/seguros/SeguroAccidentesPersonales';
import PlanAvanzado from './components/app/Beneficios/plan-dental/PlanAvanzado';
import PlanDentalPlus from './components/app/Beneficios/plan-dental/PlanDentalPlus';
import PlanDentalEsencial from './components/app/Beneficios/plan-dental/PlanDentalEsencial';
import PlanPrimordial from './components/app/Beneficios/plan-dental/PlanPrimordial';
import UtilizarBeneficio from './components/app/Beneficios/UtilizarBeneficio';
import SportCityTC from './components/app/Promociones/terminosCondiciones/SportCityTC';
import CityCafeTC from './components/app/Promociones/terminosCondiciones/CityCafeTC';
import MartiTC from './components/app/Promociones/terminosCondiciones/MartiTC';
import DevlynTC from './components/app/Promociones/terminosCondiciones/DevlynTC';
import DevlynTC_2 from './components/app/Promociones/terminosCondiciones/DevlynTC_2';
import LuxTC from './components/app/Promociones/terminosCondiciones/LuxTC';
import LuxTC_2 from './components/app/Promociones/terminosCondiciones/LuxTC_2';
import BombavistaTC from './components/app/Promociones/terminosCondiciones/BombavistaTC';
import BombavistaTC_2 from './components/app/Promociones/terminosCondiciones/BombavistaTC_2';
import VisionTC from './components/app/Promociones/terminosCondiciones/VisionTC';
import DeliciasTC from './components/app/Promociones/terminosCondiciones/DeliciasTC';
import LabChontalpaTC from './components/app/Promociones/terminosCondiciones/LabChontalpaTC';
import LabPolancoTC from './components/app/Promociones/terminosCondiciones/LabPolancoTC';
import AriesTC from './components/app/Promociones/terminosCondiciones/AriesTC';
import OlarteAkleTC from './components/app/Promociones/terminosCondiciones/OlarteAkleTC';
import ChopoTC from './components/app/Promociones/terminosCondiciones/ChopoTC';
import PlanAmerican from './components/app/Beneficios/american/PlanAmerican';
import AvisoPrivacidad from './components/web/AvisoPrivacidad';
import SeguroAccidentesSencillo from './components/app/Beneficios/seguros/SeguroAccidentesSencillo';
import OlabTC from './components/app/Promociones/terminosCondiciones/OlabTC';
import AztecaTC from './components/app/Promociones/terminosCondiciones/AztecaTC';
import JennerTC from './components/app/Promociones/terminosCondiciones/JennerTC';
import SwisslabTC from './components/app/Promociones/terminosCondiciones/SwisslabTC';
import GuaykipediaCtrlPublic from './components/app/guaykipedia/GuaykipediaCtrlPublic';
import WebinarPublico from './components/app/webinar/WebinarPublico';
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Welcome} exact />
        <Route path="/acceso" component={Login} exact />
        <Route path="/acceso/recuperar-contrasena" component={ForgottenPassword} exact />
        <Route path="/activa-usuario/:db" component={Activate_account} exact />
        <Route path="/registro" component={Register} exact />
        <Route path="/validar-cuenta-sms/:db" component={ValidataSms} exact />
        <Route path="/activa-cuenta-web/:db" component={ActivateUserShop} exact />
        <Route path="/activa-email-web/:db" component={ActivateEmail} exact />
        <Route path="/beneficios-plan-oftalmologia/plan-de-prevencion" component={PlanDePrevencion} exact />
        <Route path="/beneficios-plan-oftalmologia/plan-esencial" component={PlanEsencial} exact />
        <Route path="/beneficios-seguros/seguro-accidentes-personales" component={SeguroAccidentesPersonales} exact />
        <Route path="/beneficios-seguros/seguro-accidentes-personales-sencillo" component={SeguroAccidentesSencillo} exact />
        <Route path="/beneficios-plan-dental/avanzado" component={PlanAvanzado}/>
        <Route path="/beneficios-plan-dental/plus" component={PlanDentalPlus}/>
        <Route path="/beneficios-plan-dental/esencial" component={PlanDentalEsencial}/>
        <Route path="/beneficios-plan-dental/primordial" component={PlanPrimordial} />
        <Route path="/beneficios/utilizar-beneficio" component={UtilizarBeneficio} />
        {/* <Route path="/beneficios/utilizar-beneficio/:db" component={UtilizarBeneficio} /> */}
        <Route path="/beneficios/plan-american" component={PlanAmerican} />
        <Route path="/promociones/terminos-condiciones/sportcity" component={SportCityTC} />
        <Route path="/promociones/terminos-condiciones/citycafe" component={CityCafeTC} />
        <Route path="/promociones/terminos-condiciones/marti" component={MartiTC} />
        <Route path="/promociones/terminos-condiciones/devlyn" component={DevlynTC} />
        <Route path="/promociones/terminos-condiciones/devlyn-2" component={DevlynTC_2} />
        <Route path="/promociones/terminos-condiciones/lux" component={LuxTC} />
        <Route path="/promociones/terminos-condiciones/lux-2" component={LuxTC_2} />
        <Route path="/promociones/terminos-condiciones/bombavista" component={BombavistaTC} />
        <Route path="/promociones/terminos-condiciones/bombavista-2" component={BombavistaTC_2} />
        <Route path="/promociones/terminos-condiciones/vision" component={VisionTC} />
        <Route path="/promociones/terminos-condiciones/delicias" component={DeliciasTC} />
        <Route path="/promociones/terminos-condiciones/laboratorio-polanco" component={LabPolancoTC}/>
        <Route path="/promociones/terminos-condiciones/olarte-akle" component={OlarteAkleTC} />
        <Route path="/promociones/terminos-condiciones/aries" component={AriesTC} />
        <Route path="/promociones/terminos-condiciones/olab" component={OlabTC} />
        <Route path="/promociones/terminos-condiciones/azteca" component={AztecaTC} />
        <Route path="/promociones/terminos-condiciones/jenner" component={JennerTC} />
        <Route path="/promociones/terminos-condiciones/swisslab" component={SwisslabTC} />
        <Route path="/promociones/terminos-condiciones/chontalpa" component={LabChontalpaTC} />
        <Route path="/promociones/terminos-condiciones/chopo" component={ChopoTC} />
        <Route path="/aviso-privacidad" component={AvisoPrivacidad} />
        <Route path="/guaykipedia-publico" component={GuaykipediaCtrlPublic} />
        <Route path="/webinar-publico" component={WebinarPublico} />
        {
          getJwt('rol') === '6' ? (
            <VisualizerRoutes />
          ) : (
            <AdminRouters />
          )
        }
      </Switch>
    </BrowserRouter>
  );
}

export default App;
