import React, { useState, useEffect } from 'react';
import AdvanceEnProgreso from './AdvanceEnProgreso';
import AdvanceProximo from './AdvanceProximo';
import AdvanceCerrado from './AdvanceCerrado';
import { getJwt } from "../../../lib/auth";
import { getuserData } from "../Models/Users_model";
import { getBranches, getBranchesByUserRol } from "../Models/Enterprise_model";
import { getAdByEnterpriseAll, getAdByEnterprisesBranchAll, downloadExcelMonitoringByBusinessGroup } from '../Models/Monitor_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import LoaderApp from '../LoaderApp';
import { getEnterpriseArea, getEnterprisesCategory } from '../Models/Catalog_model';

const AdminAvance = ({ step_2 }) => {

    const userId = getJwt("jtw");

    const [centrosT, setCentrosT] = useState([]);
    const enterpriseId = getJwt("enterprise");
    const [ctId, setCtId] = useState("");
    const [advEnProgreso, setAdvEnProgreso] = useState([]);
    const [advProximos, setAdvProximos] = useState([]);
    const [advCerrados, setAdvCerrados] = useState([]);
    const [advEnProgresoMachote, setAdvEnProgresoMachote] = useState([]);
    const [advProximosMachote, setAdvProximosMachote] = useState([]);
    const [advCerradosMachote, setAdvCerradosMachote] = useState([]);
    const [selectTypoLaunch, setSelectTypoLaunch] = useState("0");
    const [update, setUpdate] = useState();
    const [loading, setLoading] = useState(true);
    const [enterpriseGroupId, setEnterpriseGroupId] = useState();

    const [branchesCat, setBranchesCat] = useState([])
    const [categoriesJobCat, setCategoriesJobCat] = useState([])
    const [areasCat, setAreasCat] = useState([])

    useEffect(() => {
        getBranches(getJwt('enterprise')).then(response => setBranchesCat(response.branches))
        getEnterprisesCategory(getJwt('enterprise')).then(response => setCategoriesJobCat(response.categorys))
        getEnterpriseArea(getJwt('enterprise')).then(response => setAreasCat(response.areas))
        getuserData(userId).then((response) => {
            if (response) {
                const enterprise = response.enterprise.find(enterprise => enterprise.enterpriseId === parseInt(enterpriseId, 10));
                setEnterpriseGroupId(enterprise.enterpriseGroupId);
                if (enterprise !== undefined) {
                }
            }
        });
        if (getJwt('rol') === '6') {
            getBranchesByUserRol(enterpriseId, getJwt('jtw'), getJwt('rol')).then((response) => {
                if (response) {
                    switch (response?.code) {
                        case 1:
                            setCentrosT(response?.branches);
                            break;
                        default:
                            console.log(response)
                            break;
                    }
                }
            });
        } else {
            getBranches(enterpriseId).then((response) => {
                if (response) {
                    switch (response?.code) {
                        case 1:
                            setCentrosT(response?.branches);
                            break;
                        default:
                            console.log(response)
                            break;
                    }
                }
            });
        }
        setLoading(true);
        getAdByEnterpriseAll(enterpriseId, getJwt('jtw'), getJwt('rol')).then((response) => {
            try {
                switch (response.code) {
                    case 1:
                        const enProgreso = response.data.filter(advance => advance.estatus === 2 || advance.estatus === 4);
                        const proximos = response.data.filter(advance => advance.estatus === 1);
                        const concluidos = response.data.filter(advance => advance.estatus === 3 || advance.estatus === 5);
                        setAdvEnProgreso(enProgreso);
                        setAdvProximos(proximos);
                        setAdvCerrados(concluidos);
                        setAdvEnProgresoMachote(enProgreso);
                        setAdvProximosMachote(proximos);
                        setAdvCerradosMachote(concluidos);
                        setLoading(false);
                        break;
                    case 99:
                        toastr.error("Esta empresa no tiene lanzamientos activos", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                    case 1002:
                        toastr.info("Esta empresa no tiene lanzamientos activos.", "¡Ooops!");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                    default:
                        toastr.error("Ocurrio un error desconocido", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                }
            } catch (error) {
                console.error("Ocurrio un error en la peticion, checa los logs");
                setAdvEnProgreso([]);
                setAdvProximos([]);
                setAdvCerrados([]);
                setAdvEnProgresoMachote([]);
                setAdvProximosMachote([]);
                setAdvCerradosMachote([]);
                setLoading(false);
            }
        });
        return () => {
            setUpdate(0);
        }
        // eslint-disable-next-line
    }, [update])

    function handleChangleSelectCT(event) {
        if (event.target.value === "") {
            toastr.info("Mostrando todos los lanzamientos de esta organización");
            setCtId("");
            setSelectTypoLaunch("0");
            setLoading(false);
            getAdByEnterpriseAll(enterpriseId, getJwt('jtw'), getJwt('rol')).then((response) => {
                try {
                    switch (response.code) {
                        case 1:
                            const enProgreso = response.data.filter(advance => advance.estatus === 2 || advance.estatus === 4);
                            const proximos = response.data.filter(advance => advance.estatus === 1);
                            const concluidos = response.data.filter(advance => advance.estatus === 3 || advance.estatus === 5);
                            setAdvEnProgreso(enProgreso);
                            setAdvProximos(proximos);
                            setAdvCerrados(concluidos);
                            setAdvEnProgresoMachote(enProgreso);
                            setAdvProximosMachote(proximos);
                            setAdvCerradosMachote(concluidos);
                            setLoading(false);
                            break;
                        case 99:
                            toastr.error("Esta empresa no tiene lanzamientos activos", "Error");
                            setAdvEnProgreso([]);
                            setAdvProximos([]);
                            setAdvCerrados([]);
                            setAdvEnProgresoMachote([]);
                            setAdvProximosMachote([]);
                            setAdvCerradosMachote([]);
                            setLoading(false);
                            break;
                        default:
                            toastr.error("Ocurrio un error desconocido", "Error");
                            setAdvEnProgreso([]);
                            setAdvProximos([]);
                            setAdvCerrados([]);
                            setAdvEnProgresoMachote([]);
                            setAdvProximosMachote([]);
                            setAdvCerradosMachote([]);
                            setLoading(false);
                            break;
                    }
                } catch (error) {
                    toastr.error("Ocurrió un problema de conexión", "Revisa tu conexión a internet");
                    console.error("Ocurrio un error en la peticion, checa los logs");
                    setAdvEnProgreso([]);
                    setAdvProximos([]);
                    setAdvCerrados([]);
                    setAdvEnProgresoMachote([]);
                    setAdvProximosMachote([]);
                    setAdvCerradosMachote([]);
                    setLoading(false);
                }
            })
        } else {
            setCtId(event.target.value);
            setSelectTypoLaunch("0");
            setLoading(true);
            getAdByEnterprisesBranchAll(enterpriseId, event.target.value, getJwt('jtw'), getJwt('rol')).then((response) => {
                switch (response?.code) {
                    case 1:
                        const enProgreso = response.data.filter(advance => advance.estatus === 2 || advance.estatus === 4);
                        const proximos = response.data.filter(advance => advance.estatus === 1);
                        const concluidos = response.data.filter(advance => advance.estatus === 3 || advance.estatus === 5);
                        setAdvEnProgreso(enProgreso);
                        setAdvProximos(proximos);
                        setAdvCerrados(concluidos);
                        setAdvEnProgresoMachote(enProgreso);
                        setAdvProximosMachote(proximos);
                        setAdvCerradosMachote(concluidos);
                        setLoading(false);
                        break;
                    case 1002:
                        toastr.error("La organización actual no tiene colaboradores activos", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                    case 1003:
                        toastr.error("La organización actual no tiene lanzamientos por este centro de trabajo", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                    default:
                        toastr.error("Ocurrio un error desconocido", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                }
            })
        }
    }

    function handleChangeSelectTypeLaunch(event) {
        switch (event.target.value) {
            case "1":
                setSelectTypoLaunch("1");
                setAdvEnProgreso(advEnProgresoMachote.filter(advance => advance.type_launch === 1 || advance.type_launch === 4));
                setAdvProximos(advProximosMachote.filter(advance => advance.type_launch === 1 || advance.type_launch === 4));
                setAdvCerrados(advCerradosMachote.filter(advance => advance.type_launch === 1 || advance.type_launch === 4));
                break;
            case "2":
                setSelectTypoLaunch("2");
                setAdvEnProgreso(advEnProgresoMachote.filter(advance => advance.type_launch === 2));
                setAdvProximos(advProximosMachote.filter(advance => advance.type_launch === 2));
                setAdvCerrados(advCerradosMachote.filter(advance => advance.type_launch === 2));
                break;
            case "3":
                setSelectTypoLaunch("3");
                setAdvEnProgreso(advEnProgresoMachote.filter(advance => advance.type_launch === 3));
                setAdvProximos(advProximosMachote.filter(advance => advance.type_launch === 3));
                setAdvCerrados(advCerradosMachote.filter(advance => advance.type_launch === 3));
                break;
            default:
                setSelectTypoLaunch("0");
                setAdvEnProgreso(advEnProgresoMachote);
                setAdvProximos(advProximosMachote);
                setAdvCerrados(advCerradosMachote);
                break;
        }
    }

    function handleClickDownloadExcelByBusinessGroup(entGroupId) {
        toastr.info("Iniciando descarga de evaluación por grupo empresarial ", "Espere...");
        downloadExcelMonitoringByBusinessGroup(entGroupId).then((response) => {
            switch (response?.code) {
                case 1:
                    window.open(response.urlFile);
                    break;
                default:
                    toastr.error("No se ha podido completar la descarga, revise tu conexión a internet", "Error");
                    break;
            }
        })
    }

    const refreshData = () => {
        getAdByEnterpriseAll(enterpriseId, getJwt('jtw'), getJwt('rol')).then((response) => {
            try {
                switch (response.code) {
                    case 1:
                        const enProgreso = response.data.filter(advance => advance.estatus === 2 || advance.estatus === 4);
                        const proximos = response.data.filter(advance => advance.estatus === 1);
                        const concluidos = response.data.filter(advance => advance.estatus === 3 || advance.estatus === 5);
                        setAdvEnProgreso(enProgreso);
                        setAdvProximos(proximos);
                        setAdvCerrados(concluidos);
                        setAdvEnProgresoMachote(enProgreso);
                        setAdvProximosMachote(proximos);
                        setAdvCerradosMachote(concluidos);
                        setLoading(false);
                        break;
                    case 99:
                        toastr.error("Esta empresa no tiene lanzamientos activos", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                    case 1002:
                        toastr.info("Esta empresa no tiene lanzamientos activos.", "¡Ooops!");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                    default:
                        toastr.error("Ocurrio un error desconocido", "Error");
                        setAdvEnProgreso([]);
                        setAdvProximos([]);
                        setAdvCerrados([]);
                        setAdvEnProgresoMachote([]);
                        setAdvProximosMachote([]);
                        setAdvCerradosMachote([]);
                        setLoading(false);
                        break;
                }
            } catch (error) {
                console.error("Ocurrio un error en la peticion, checa los logs");
                setAdvEnProgreso([]);
                setAdvProximos([]);
                setAdvCerrados([]);
                setAdvEnProgresoMachote([]);
                setAdvProximosMachote([]);
                setAdvCerradosMachote([]);
                setLoading(false);
            }
        });
    }

    return (
        <div className="col-12 bg-white rounded mb-5">
            {
                loading ?
                    <LoaderApp />
                    :
                    <>
                        <div className="row d-flex justify-content-center pt-1 pb-3">
                            {
                                enterpriseGroupId && enterpriseGroupId > 0 ? (
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-7 d-flex justify-content-center ">
                                                <div className="col-12 col-sm-4">
                                                    <select className="form-control form-control-sm" value={ctId} onChange={handleChangleSelectCT}>
                                                        <option value="">Centros de trabajo</option>
                                                        {
                                                            centrosT.map((centro, idx) => {
                                                                return (
                                                                    <option key={centro.branchEntId} value={centro.branchEntId}>{centro.description}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <select className="form-control form-control-sm" value={selectTypoLaunch} onChange={handleChangeSelectTypeLaunch}>
                                                        <option value="0">Tipo evaluaci&oacute;n</option>
                                                        <option value="1">Normativo</option>
                                                        <option value="2">Complementario</option>
                                                        <option value="3">Estándar</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-5 d-flex justify-content-between">
                                                <span>Descargar avance por grupo empresarial Excel</span>
                                                <div className="col-12 col-sm-3">
                                                    <img className="icon-img-25 blurryImg"
                                                        src="img/icons/excel.png"
                                                        alt="Descargar avance por grupo empresarial Excel"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Descargar avance por grupo empresarial Excel"
                                                        onClick={() => handleClickDownloadExcelByBusinessGroup(enterpriseGroupId)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>

                                        <div className="col-12 col-sm-3">
                                            <select className="form-control form-control-sm" value={ctId} onChange={handleChangleSelectCT}>
                                                <option value="">Centros de trabajo</option>
                                                {
                                                    centrosT.map((centro, idx) => {
                                                        return (
                                                            <option key={centro.branchEntId} value={centro.branchEntId}>{centro.description}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <select className="form-control form-control-sm" value={selectTypoLaunch} onChange={handleChangeSelectTypeLaunch}>
                                                <option value="0">Tipo evaluaci&oacute;n</option>
                                                <option value="1">Normativo</option>
                                                <option value="2">Complementario</option>
                                                <option value="3">Estándar</option>
                                            </select>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <AdvanceEnProgreso
                            enterpriseId={enterpriseId}
                            advEnProgreso={advEnProgreso}
                            update={update}
                            setUpdate={setUpdate}
                            step_2={step_2}
                            branchesCat={branchesCat}
                            categoriesJobCat={categoriesJobCat}
                            areasCat={areasCat}
                            refreshData={refreshData}
                        />
                        <AdvanceProximo advProximos={advProximos} />
                        <AdvanceCerrado advCerrados={advCerrados} />
                    </>
            }
        </div>
    )
}

export default AdminAvance
