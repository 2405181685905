import React, { useState } from 'react';
import $ from "jquery";

const FilterColumns = ({ handleCheckboxChange, catalogFilters }) => {

    const [btnFilters, setBtnFilters] = useState(true);

    const handleShowDropdown = () => {
        if ($("#dropdownOptions").hasClass("show")) {
            $("#dropdownOptions").removeClass("show");
            $("#dropdown").removeClass("show");
            setBtnFilters(true);
        } else {
            $("#dropdownOptions").addClass("show");
            $("#dropdown").addClass("show");
            setBtnFilters(false);
        }
    }

    return (
        <div className="dropdown dropleft" id="dropdown">
            <span className="rounded-right bg-blue-purple font-medium-5 pointer style-plus-button" onClick={() => handleShowDropdown()} /* data-toggle="dropdown" */>
                {btnFilters === true ? <i className="ft-plus p-0 m-0"></i> : <i className="ft-minus p-0 m-0"></i>}
            </span>
            <div className="dropdown-menu dropdown-2" id="dropdownOptions">
                <div className="row w-100">
                    <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='idBranch'
                                checked={!catalogFilters.idBranch}
                                onChange={handleCheckboxChange}
                            /> Centro de trabajo
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='areaEntId'
                                checked={!catalogFilters.areaEntId}
                                onChange={handleCheckboxChange}
                            /> Área
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='bossEntId'
                                checked={!catalogFilters.bossEntId}
                                onChange={handleCheckboxChange}
                            /> Jefe
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='email'
                                checked={!catalogFilters.email}
                                onChange={handleCheckboxChange}
                            /> Correo
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='jobEntId'
                                checked={!catalogFilters.jobEntId}
                                onChange={handleCheckboxChange}
                            /> Puesto
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='categoryEntId'
                                checked={!catalogFilters.categoryEntId}
                                onChange={handleCheckboxChange}
                            /> Cargo
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='customA'
                                checked={!catalogFilters.customA}
                                onChange={handleCheckboxChange}
                            /> Teleasistencia
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='customB'
                                checked={!catalogFilters.customB}
                                onChange={handleCheckboxChange}
                            /> Personalizado 2
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='CustomC'
                                checked={!catalogFilters.CustomC}
                                onChange={handleCheckboxChange}
                            /> Personalizado 3
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='CustomD'
                                checked={!catalogFilters.CustomD}
                                onChange={handleCheckboxChange}
                            /> Personalizado 4
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='CustomE'
                                checked={!catalogFilters.CustomE}
                                onChange={handleCheckboxChange}
                            /> Personalizado 5
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='CustomF'
                                checked={!catalogFilters.CustomF}
                                onChange={handleCheckboxChange}
                            /> Personalizado 6
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='CustomG'
                                checked={!catalogFilters.CustomG}
                                onChange={handleCheckboxChange}
                            /> Personalizado 7
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='dateOfAddmision'
                                checked={!catalogFilters.dateOfAddmision}
                                onChange={handleCheckboxChange}
                            /> Admisión
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='keyPlayer'
                                checked={!catalogFilters.keyPlayer}
                                onChange={handleCheckboxChange}
                            /> Key player
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='leader'
                                checked={!catalogFilters.leader}
                                onChange={handleCheckboxChange}
                            /> Líder
                        </div>
                        <div className="text-base">
                            <input
                                type="checkbox"
                                name='afection'
                                checked={!catalogFilters.afection}
                                onChange={handleCheckboxChange}
                            /> Afectación
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterColumns;
