import React from 'react';
// Tools
import { getJwt } from '../../../../../lib/auth';

const Nav = () => {
    return (
        <div className="col-12 d-flex justify-content-end mt-2">
            <div className="breadcrumb-wrapper font-medium-3 px-1">
                {
                    getJwt('business') === 'true' ? (
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item text-blue-sec font-weight-bold pointer">
                                <span>Estatus de peticiones</span>
                            </li>
                        </ol>
                    ) : (
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/tienda">Tienda</a>
                            </li>
                            <li className="breadcrumb-item text-blue-sec font-weight-bold pointer">
                                <span>Estatus de peticiones</span>
                            </li>
                        </ol>
                    )
                }
            </div>
        </div>
    );
}

export default Nav;
