import { React } from 'react';
import { colorsArray } from '../../../helpers/helpers';
import ReactApexChart from "react-apexcharts";
import { SendMailNotActiveModal } from './SendMailNotActiveModal';
import ModalLoaderGen from '../../ModalLoaderGen';
import $ from "jquery";

const ColaboradoresGraphics = ({
    Graphics1,
    Graphics2,
    DataGraphic,
    step_1,
    organizacionId,
    groupId,
    branchId
     }) => {
    console.log('organizacion: ',organizacionId);
    console.log('groupId: ',groupId);
    console.log('branchId: ',branchId);
    /**
     * Graphic Donuts
     */
     const SeriesDonuts=Graphics1.serie?Graphics1.serie:[];
     const OptionDonus={
        labels:Graphics1.labels?Graphics1.labels:[],
       
         dataLabels:{
            
            dropShadow: {
                enabled: false,
                top: 3,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45,            
            },
            style: {               
                fontSize:'12px',
                
              },
         },
         title: {
            text:"Distribución por Centro de Trabajo",
            align:'center',
            style: {
                fontSize:  '20px',
                fontWeight:  'bold',
              },
            
          },
         colors: colorsArray,
         "fill": {
            colors: colorsArray
            
            },
            legend: {
                position: 'right',
                horizontalAlign: 'center',
                offsetX: 10,
                offsetY: 50,               
                fontSize: '15px',      
                formatter: function(seriesName) {
                    return [seriesName.substr(0,20)]
                }          

              },
        
           
       };
   
  
    
    return (
      <div className='row '>
        
        <div id="contadores" className="col-xl-4 col-lg-4 col-12 mt-2">
        <h4 className="text-center font-weight-bold " style={{fontZize:'20px'}}>Registro de Colaboradores</h4>
            <div className="card mt-2" style={{backgroundColor:'#2782a7', borderRadius: '20px', border: '0px'}}>
                <div className="card-content">
                    <div className="card-body">
                        <div className="media d-flex">
                            <div className="media-body text-center">                  
                                <h4 className="text-white">Total de Registrados <span className='font-weight-bold'>{DataGraphic.totalCollab}</span> </h4>
                                {/* <h4 className="text-white">Total de Registrados <span className='font-weight-bold'>25000</span> </h4> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="card" style={{backgroundColor:'#ADD8E6', borderRadius: '20px', border: '0px'}}>
                <div className="card-content">
                    <div className="card-body" >
                        <div className="media d-flex row">
                            <div className="media-body col-md-7 p-0">                                                                    
                                {/* <h5 className="text-blue-sec font-weight-bold">Activaciones 10000</h5> */}
                                <h5 className="text-blue-sec font-weight-bold">Activaciones {DataGraphic.totalRegister}</h5>                                
                            </div>
                              
                            <div className="media-body col-md-2 p-0">                                                               
                                    <img alt="flecha"  className='icon-img-15' src='/img/MonitoreoMailGun/siguiente.png'></img>
                            </div>   
                            <div className="media-body col-md-3 p-0">  
                                <h4 className="text-blue-sec font-weight-bold">{DataGraphic.PerRegisterWeb} % </h4>  
                                {/* <h4 className="text-blue-sec font-weight-bold">40 % </h4>                                                              */}
                            </div>                                            
                        </div>
                        { !step_1 && DataGraphic.PerRegisterWeb < 100 && organizacionId > 0 && DataGraphic.totalCollab > 0 &&  (

                            <div className="media d-flex row mt-1">
                                <div className="media-body col-md-12 p-0">                                                                    
                                    <h5 className="text-orange font-weight-bold">Pendientes {DataGraphic.totalCollab-DataGraphic.totalRegister}</h5>                                
                                </div>
                                <div className="col-xl-12 col-lg-12 col-12 text-center mt-1">
                                    <h6 className="text-blue-sec font-weight-bold" style={{fontSize:'12px'}}>¿Los colaboradores cuentan con el correo de activación?</h6>   
                                </div>
                                <div className="media-body text-center col-md-12 p-0 mt-1">                                                                    
                                    <button className='btn btn-sm bg-blue-navy text-white'
                                    onClick={() => {
                                        $("#resendMailNotActiveModal").show();
                                    }}
                                    >                                        
                                    Reenviar Invitación
                                    </button>                                  
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>        
        </div>
        <div id="donuts"  className="col-xl-8 col-lg-8 col-12 mt-2">                                        
            <ReactApexChart
                type="donut"
                options={OptionDonus}
                series={SeriesDonuts}
                height={350}
            />

        </div>
        <SendMailNotActiveModal 
            organizacionId={organizacionId} 
            groupId={groupId}
            branchId={branchId}
        />
        <ModalLoaderGen/>
        
        
      </div>
    )
}



export default ColaboradoresGraphics;
