import React, { useState, useEffect } from "react";
// Components
import DemographicSelectClimate from "./DemographicSelectClimate";
import SurveyComplete from '../../SurveyComplete';
import LoaderSurvey from "../../LoaderSurvey";
import SurveyError from '../../SurveyError';
// Tools
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// API calls
import { saveResponseDemogClimate, updFinishSurveyDemogClimate } from '../../Models/SurveysModel';
import { validationAccessDemogClimate } from "../../Models/Survey_model";

const DemographicClimate = props => {
    // Variables
    const [sections, setSections] = useState([]);
    const [idEnterprise, setIdEnterprise] = useState(0);
    const [idGroup, setIdGroup] = useState(0);
    const [idCollab, setIdCollab] = useState(0);
    const [surveyId, setSurveyId] = useState(0);
    const [todayDate, setTodayDate] = useState("");
    const [browser, setBrowser] = useState("");
    const [OS, setOS] = useState("");
    const [coordinates, setCoordinates] = useState("");
    const [surveyCompleted, setSurveyCompleted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    /**
     * useEffect for request
     */
    useEffect(() => {
        const getURLParameters = props.match.params;
        let parameters = "0-0-0-0";
        try {
            parameters = atob(getURLParameters.db);
        } catch (error) {
            console.error(error)
        }
        let parameter = parameters.split("-");
        let idCollab = parameter[0];
        let idEnterprise = parameter[1];
        let surveyId = parameter[2];
        let idGroup = parameter[3];
        setIdCollab(parseInt(idCollab));
        setIdEnterprise(parseInt(idEnterprise));
        setSurveyId(parseInt(surveyId));
        setIdGroup(parseInt(idGroup));
        validationAccessDemogClimate(parseInt(idCollab), parseInt(idEnterprise), parseInt(surveyId), parseInt(idGroup)).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let survey = response.survey;
                        let sections = [];
                        let questionAnswered = 0;
                        let totalQuestions = 0;
                        for (let x in survey) {
                            sections.push(survey[x]);
                            totalQuestions = survey[x].questions.length;
                            for (let y in survey[x].questions) {
                                for (let z in survey[x].questions[y].answers) {
                                    if (survey[x].questions[y].answers[z].check === 1) {
                                        questionAnswered = questionAnswered + 1;
                                    }
                                }
                            }
                        }
                        if (questionAnswered === totalQuestions) {
                            setSurveyCompleted(true);
                        }
                        setSections(sections);
                        setLoading(false);
                        break;
                    case 5:
                        window.location.replace(response.url);
                        break;
                    default:
                        setErrorPage(true);
                        break;
                }
            } else {
                setErrorPage(true);
            }
        });
        // eslint-disable-next-line
    }, []);
    /**
     * useEffect for user data
     */
    useEffect(() => {
        // Set format date dd/mm/yyyy hh:mm:ss
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        const fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setTodayDate(fechaActual);
        // Set browser
        let browser = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "other";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(browser);
        // Set Operative Sistem
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "other";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOS(so);
        // Set geolocation
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
    }, []);
    /**
     * 
     * @param {Event} e 
     * @param {int} idquestion 
     * @param {int} idanswer 
     */
    const handleChange = (e, idquestion, idanswer) => {
        e.preventDefault();
        let section = [...sections];
        saveResponseDemogClimate(idanswer, idquestion, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        for (let x in section) {
                            for (let y in section[x].questions) {
                                if (section[x].questions[y].idquestion === idquestion) {
                                    for (let z in section[x].questions[y].answers) {
                                        if (section[x].questions[y].answers[z].idanswer === idanswer) {
                                            section[x].questions[y].answers[z].check = 1;
                                        } else {
                                            section[x].questions[y].answers[z].check = 0;
                                        }
                                    }
                                }
                            }
                        }
                        allAnswersComplete(section);
                        setSections(section);
                        break;
                    default:
                        toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                        break;
                }
            } else {
                toastr.error("No se guardó correctamente la respuesta. Se tienen problemas con el servidor.", "¡Ooops!");
            }
        });
        const questionsLength = section[0].questions.length; //check the length of the questions in the section
        if (idquestion + 1 <= questionsLength) { //if next question is lower or equal to questions length make the scroll
            // element which needs to be scrolled to
            const element = document.getElementById(`${idquestion + 1}`);
            // scroll to element
            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        }
    }
    /**
     * 
     */
    const allAnswersComplete = (data) => {
        let section = [...data];
        let questionAnswered = 0;
        let totalQuestions = 0;
        for (let x in section) {
            totalQuestions = section[x].questions.length;
            for (let y in section[x].questions) {
                for (let z in section[x].questions[y].answers) {
                    if (section[x].questions[y].answers[z].check === 1) {
                        questionAnswered = questionAnswered + 1;
                    }
                }
            }
        }
        if (questionAnswered === totalQuestions) {
            setSurveyCompleted(true);
        }
    }
    /**
     * 
     * @param {Event} e 
     */
    const submitSurvey = e => {
        e.preventDefault();
        setLoading(true);
        updFinishSurveyDemogClimate(idCollab, idEnterprise, surveyId, idGroup, "").then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                        setShowMessage(true);
                        break;
                    case 5:
                        window.location.replace(response.url);
                        break;
                    case 1001:
                        toastr.success("No existe la empresa.", "¡Ooops!");
                        break;
                    case 1002:
                        toastr.success("No existe colaborador.", "¡Ooops!");
                        break;
                    case 1003:
                        toastr.success("Ejercicio no encontrado, intenta más tarde.", "¡Ooops!");
                        break;
                    default:
                        toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        break;
                }
            } else {
                toastr.error("Problemas con la conexión con el servidor. Intenta más tarde.", "¡Ooops!");
            }
        });
    }

    const handleClickUp = () => {
        // element which needs to be scrolled to
        const element = document.getElementById("start");
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }

    if (errorPage) {
        return (
            <div className="container-fluid">
                <SurveyError />
            </div>
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                {
                    loading === true
                        ?
                        (
                            showMessage === true
                                ?
                                (
                                    <SurveyComplete />
                                )
                                :
                                (
                                    <LoaderSurvey />
                                )
                        )
                        :
                        (
                            <div className="row">
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1" id="start">
                                    <img src="../img/logo_guay_color.png" alt="logo-guay" style={{ width: '100px' }} />
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1 font-large-1">
                                    <span className="text-base-guay font-weight-bold">Demográficos</span>
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                                    <span>Ayúdanos completando el 100% de las respuestas</span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                    {
                                        sections.map((data) => (
                                            <div className="row" key={data.idsection}>
                                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                                    <span className="text-base font-weight-bold">{data.description}</span>
                                                </div>
                                                {
                                                    data.questions.map((question) => (
                                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" key={question.idquestion} id={`${question.idquestion}`}>
                                                            <div className="card shadow-card-1">
                                                                <div className="card-header bg-blue-guay-2 py-1">
                                                                    <span>{question.description}</span>
                                                                </div>
                                                                <div className="card-body">
                                                                    {
                                                                        question.answers.length > 11
                                                                            ?
                                                                            (
                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                    <DemographicSelectClimate
                                                                                        question={question}
                                                                                        answers={question.answers}
                                                                                        sections={sections}
                                                                                        setSections={setSections}
                                                                                        saveResponseDemogClimate={saveResponseDemogClimate}
                                                                                        idEnterprise={idEnterprise}
                                                                                        idGroup={idGroup}
                                                                                        idCollab={idCollab}
                                                                                        todayDate={todayDate}
                                                                                        coordinates={coordinates}
                                                                                        browser={browser}
                                                                                        OS={OS}
                                                                                        allAnswersComplete={allAnswersComplete} />
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                question.answers.map((answer) => (
                                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 padding-small-2" key={question.idquestion + "-" + answer.idanswer}>
                                                                                        {answer.check === 1
                                                                                            ?
                                                                                            (
                                                                                                <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={(e) => handleChange(e, question.idquestion, answer.idanswer)} ><i className="ft ft-check-circle text-base-guay align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                <span className="pointer" id={question.idquestion + "-" + answer.idanswer} key={question.idquestion + "-" + answer.idanswer} onClick={(e) => handleChange(e, question.idquestion, answer.idanswer)} ><i className="ft ft-circle text-base align-middle icon-size-small padding-small" id={question.idquestion + "-" + answer.idanswer} />{answer.description}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    surveyCompleted === true
                                        ?
                                        (
                                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1">
                                                <button
                                                    className="btn btn-blue-3 btn-sm font-medium-1"
                                                    onClick={submitSurvey}>Finalizar</button>
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }
                                <button className="arrow-up" onClick={handleClickUp}><i className="ft-arrow-up icon-medium-size" /></button>
                            </div>
                        )
                }
            </div>
        )
    }
}

export default DemographicClimate;
