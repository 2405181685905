import React, { useEffect } from 'react'
// TODO: API calls
import { getUploadedCollaborators } from './Models/Collaborators_model'
import { getBranches } from './Models/Enterprise_model'
// TODO: Components
import Header from './NewHome/headerAdministrador'
import Menu from './Menu'
import HelperApp from './HelperApp'
import Footer from './footerApp'
// TODO: Generic func
import { redirectIfNotAuthenticated, getJwt } from '../../lib/auth'
// TODO: Tools
import cookie from 'js-cookie'
import { connect } from 'react-redux'
import { validateAccess, getSubsection } from '../helpers/validations'
import { useHistory } from 'react-router-dom'
import * as toastr from 'toastr'

const SurveyController = ({ access }) => {

    const history = useHistory()

    useEffect(() => {
        let insixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000)
        if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
            //console.log(access)
            //console.log(validateAccess(access.list, 3))
            const admin = validateAccess(access.list, 3)
            if (admin[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            //console.log(getSubsection(admin[0].subSection, 41))
            const admin_roles = getSubsection(admin[0].subSection, 41)
            if (admin_roles[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            } else {
                if ((admin_roles[0].id_action === 2)) {
                    cookie.set('survey_access', 2, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                } else {
                    cookie.set('survey_access', 1, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                }
            }
        } else {
            switch (getJwt('rol')) {
                case '2':
                case '3':
                case '5':
                    cookie.set('survey_access', 1, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                    break;
                case '6':
                    cookie.set('survey_access', 2, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                    break;
                default:
                    cookie.set('survey_access', 1, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                    break;
            }
        }
    }, [access, history])

    useEffect(() => {
        let insixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000)
        let totalCollaborators = 0
        getUploadedCollaborators(getJwt('enterprise')).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        const getActiveCollaborators = response.listCollab.filter(collab => collab.active === 1)
                        totalCollaborators = getActiveCollaborators.length
                        cookie.set('survey_totalcollab', totalCollaborators, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                        break;
                    default:
                        cookie.set('survey_totalcollab', totalCollaborators, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                        break;
                }
            }
        })
        let branches = ''
        getBranches(getJwt('enterprise')).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        branches = JSON.stringify(response.branches)
                        cookie.set('survey_branches', branches, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                        break;
                    default:
                        cookie.set('survey_branches', branches, { domain: global.domain, expires: insixtyMinutes, sameSite: 'None', secure: true })
                        break;
                }
            }
        })
        // * Set iframe cookies
        cookie.set('survey_usrname', getJwt('userName'), { domain: global.domain , expires: insixtyMinutes, sameSite: 'None', secure: true})
        cookie.set('survey_usrid', getJwt('jtw'), { domain: global.domain , expires: insixtyMinutes, sameSite: 'None', secure: true})
        cookie.set('survey_entname', getJwt('enterpriseName'), { domain: global.domain , expires: insixtyMinutes, sameSite: 'None', secure: true})
        cookie.set('survey_entid', getJwt('enterprise'), { domain: global.domain , expires: insixtyMinutes, sameSite: 'None', secure: true})
    }, [])

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className='col-12'>
                                <ul className="breadcrumb font-medium-3 justify-content-end p-1">
                                    <li className="breadcrumb-item"><a href='/inicio-administrador'>Administración</a></li>
                                    <li className="breadcrumb-item active">Encuestas</li>
                                </ul>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xxl-12 pr-0 pl-0'>
                                        <div className="embed-responsive embed-responsive-16by9 scroll-front-survey">
                                                <iframe
                                                    className="embed-responsive-item"
                                                    src={`${global.surveyFrontPage}`}
                                                    allowFullScreen
                                                    title='front-survey'
                                                ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ access }) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(SurveyController)
