import React from 'react'

const Leyenda = () => {
  return (
    <>
        <div className='row bg-white'>
            <div className='col-12 d-flex align-items-start justify-content-center mt-2 mt-md-2'>
                <p className='font-weight-normal text-blue-sec text-center fs-1 py-1'>Para más detalle consulta las Condiciones Generales del Seguro Colectivo Indemnizatorio de Accidentes Personales Integral</p>
                <a className='text-decoration-none text-base-guay font-weight-bold px-1' href='https://storage.googleapis.com/guay-api-production/Operacion/Benefits/Seguro-Sigma/CG-AP_Integral_LISF.pdf' target='_blank' rel="noreferrer">
                <img className="img-responsive icon-size-25x25 my-1" src="./../img/beneficios/Icono_descarga.png" alt="img-descarga" /> Descargar</a>
            </div>
        </div>
    </>
  )
}

export default Leyenda
