// TODO: Tools
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import Swal from 'sweetalert2'
import $ from 'jquery'
import * as toastr from 'toastr'

const ComEviTable = ({
    evidence,
    resourceObj,
    setResourceObj,
    setSavedFiles,
    deleteSpecificEvidence,
    handleResetTable,
    deleteEvidence,
    service,
    disableActions,
    setFiles,
    setUrlShowPdf,
    setShowImage,
}) => {
    const { SearchBar } = Search;
    const columns = [
        {
            dataField: 'title',
            text: 'Nombre',
            /* align: 'center',
            headerAlign: 'center', */
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className='overflow-hidden row-table-1'>
                        <span
                            data-toggle='tooltip'
                            data-placement='top'
                            title={cell}
                            style={{ textOverflow: 'ellipsis' }}
                        >{cell}</span>
                    </div>
                )
            },
        },
        {
            dataField: 'description',
            text: 'Descripción',
            /* align: 'center',
            headerAlign: 'center', */
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className='overflow-hidden row-table-2'>
                        <span
                            data-toggle='tooltip'
                            data-placement='top'
                            title={cell}
                            style={{ textOverflow: 'ellipsis' }}
                        >{cell}</span>
                    </div>
                )
            },
        },
        {
            dataField: 'idPerzonalizedProfile',
            text: '',
            align: 'center',
            headerAlign: 'center',
            hidden: disableActions,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    paddingRight: '10px'
                }
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className='col-12 d-flex justify-content-between px-0'>
                        <i
                            className='bi bi-pencil text-blue pointer font-medium-4'
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Editar"
                            onClick={() => {
                                setResourceObj({
                                    ...resourceObj,
                                    idComplementaryEvidence: row.idComplementaryEvidence,
                                    resourceType: row.resourceType,
                                    title: row.title,
                                    description: row.description,
                                })
                                setSavedFiles(row.resources)
                                setFiles([])
                                $('.border-danger').removeClass('border-danger')
                            }}
                        />
                        <i
                            className='bi bi-trash text-blue pointer font-medium-4'
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Eliminar"
                            onClick={() => {
                                Swal.fire({
                                    title: '<p class="text-white mb-2">¿Deseas eliminar la evidencia?</p>',
                                    text: 'Si eliminas la evidencia no podrás recuperarla.',
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    focusCancel: true,
                                    cancelButtonText: 'Cancelar',
                                    showConfirmButton: true,
                                    confirmButtonText: 'Si, eliminar',
                                    reverseButtons: true,
                                    dangerMode: true
                                }).then((willDelete) => {
                                    if (willDelete.isConfirmed) {
                                        const getService = parseInt(service) === 1 ? '1-1-1' : parseInt(service) === 2 ? '1-1-2' : '1-2-0'
                                        const getIdService = getService.split('-')
                                        let obj = {
                                            idUser: row.idUser,
                                            idComplementaryEvidence: row.idComplementaryEvidence,
                                            idProduct: parseInt(getIdService[0]),
                                            idCategory: parseInt(getIdService[1]),
                                            idSubcategory: parseInt(getIdService[2])
                                        }
                                        deleteEvidence(obj).then(response => {
                                            if (response) {
                                                if (response.code === 1) {
                                                    handleResetTable()
                                                    toastr.success('Se eliminó correctamente la evidencia.', '¡Éxito!')
                                                }
                                            }
                                        })
                                    }
                                });
                                $('.swal2-title').addClass('bg-header-swal');
                                $('.swal2-actions').addClass('w-100 justify-content-around');
                                $('.swal2-confirm').removeClass('swal2-styled');
                                $('.swal2-confirm').addClass('btn btn-sm btn-blue-alert mt-2');
                                $('.swal2-cancel').removeClass('swal2-styled');
                                $('.swal2-cancel').addClass('btn btn-sm btn-gray-alert mt-2');
                            }}
                        />
                    </div>
                )
            },
        },
    ]

    const options = {
        custom: true,
        sizePerPage: 10,
        firstPageText: 'Primera',
        prePageText: 'Atrás',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'Primera página',
        prePageTitle: 'Página anterior',
        firstPageTitle: 'Siguiente página',
        lastPageTitle: 'Última página',
        showTotal: true,
    }

    /**
     * 
     * @param {*} url 
     */
    const previewFile = (url) => {
        setShowImage(url);
        $('#modalImagesViewer').show()
    }

    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        setUrlShowPdf(url);
        $('#closeModalViewPdf').show()
    }

    /**
     * 
     * @param {*} url 
     */

    const validateTypeExt = (url) =>{
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        return ext
    }

    /**
     * 
     * @param {*} url 
     */
    const validateTypeFile = (url) => {
        let ext = validateTypeExt(url)
        if (ext === 'pdf' || ext === 'mp3' || ext === 'mp4') {
            previewFilePDF(url)
        }else if(ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'gif'){
            previewFile(url)
        }else{
            window.open(url)
        }
    }

    const expandRow = {
        className: (isExpanded, row, rowIndex) => {
            return 'bg-gray-guay'
        },
        renderer: row => (
            <div className='col-12'>
                <div className='row'>
                    {row.resources.map(data => (
                        <div className='col-sx-12 col-sm-12 col-md-6 col-lg-6'>
                            <div className='row'>
                                <div className='col-sx-12 col-sm-12 col-md-9 col-lg-9'>
                                    <p>{data.title}</p>
                                </div>
                                <div className='col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex justify-content-around'>
                                    <i  
                                        className={validateTypeExt(data.url) === 'zip' || validateTypeExt(data.url) === 'rar' ? 'bi bi-cloud-arrow-down text-blue pointer font-medium-4':'bi bi-eye text-blue pointer font-medium-4'} 
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={validateTypeExt(data.url) === 'zip' || validateTypeExt(data.url) === 'rar' ? 'Descargar':'Visualizar'}
                                        onClick={() => validateTypeFile(data.url)}
                                    />
                                    {!disableActions && (
                                        <i
                                            className='bi bi-trash text-blue pointer font-medium-4'
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Eliminar"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: '<p class="text-white mb-2">¿Deseas eliminar el archivo?</p>',
                                                    text: 'Si eliminas este archivo no podrás recuperarlo.',
                                                    showCloseButton: true,
                                                    showCancelButton: true,
                                                    focusCancel: true,
                                                    cancelButtonText: 'Cancelar',
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Si, eliminar',
                                                    reverseButtons: true,
                                                    dangerMode: true
                                                }).then((willDelete) => {
                                                    if (willDelete.isConfirmed) {
                                                        let obj = {
                                                            idUser: row.idUser,
                                                            idComplementaryEvidence: row.idComplementaryEvidence,
                                                            idComplementaryEvidenceResources: data.idComplementaryEvidenceResources,
                                                        }
                                                        deleteSpecificEvidence(obj).then(response => {
                                                            if (response) {
                                                                if (response.code === 1) {
                                                                    handleResetTable()
                                                                    toastr.success('Se eliminó correctamente la evidencia.', '¡Éxito!')
                                                                }
                                                            }
                                                        })
                                                    }
                                                });
                                                $('.swal2-title').addClass('bg-header-swal');
                                                $('.swal2-actions').addClass('w-100 justify-content-around');
                                                $('.swal2-confirm').removeClass('swal2-styled');
                                                $('.swal2-confirm').addClass('btn btn-sm btn-blue-alert mt-2');
                                                $('.swal2-cancel').removeClass('swal2-styled');
                                                $('.swal2-cancel').addClass('btn btn-sm btn-gray-alert mt-2');
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ),
        expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
            <div className='col-12'>
                {expanded ? (
                    <i
                        className='bi bi-dash text-blue pointer font-medium-4'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ocular"
                    />
                ) : (
                    <i
                        className='bi bi-plus text-blue pointer font-medium-4'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Expander"
                    />
                )}
            </div>
        ),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => (
            <div className='col-12'>
                {isAnyExpands ? (
                    <i
                        className='bi bi-dash text-blue pointer font-medium-4'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ocular todo"
                    />
                ) : (
                    <i
                        className='bi bi-plus text-blue pointer font-medium-4'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Expander todo"
                    />
                )}
            </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <ToolkitProvider
                keyField="idComplementaryEvidence"
                data={evidence}
                columns={columns}
                search
                noDataIndication="No hay evidencia"
            >
                {
                    props => (
                        <div className='row'>
                            <div className='col-6 d-flex justify-content-end'>
                                <div className='col-12 complete-searchBar mb-2 px-0'>
                                    <SearchBar
                                        {...props.searchProps}
                                        placeholder="Buscar"
                                        className='form-control form-control-sm'
                                    />
                                </div>
                            </div>
                            <div className='col-6'></div>
                            <div className='col-12'>
                                <BootstrapTable
                                    {...props.baseProps}
                                    hover
                                    condensed
                                    expandRow={expandRow}
                                    noDataIndication="No hay evidencia"
                                    classes="foo-2"
                                    {...paginationTableProps}
                                />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>
    )

    return (
        <div className="col-12">
            <PaginationProvider
                pagination={
                    paginationFactory(options)
                }
            >
                {contentTable}
            </PaginationProvider>
        </div>
    )
}

export default ComEviTable