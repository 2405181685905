import React, { useState } from 'react';
import $ from "jquery";

const FilterColumn = ({ handleCheckboxChange, catalogFilters }) => {
    const [btnFilters, setBtnFilters] = useState(true);

    const handleShowDropdown = () => {
        if ($("#dropdownOptions").hasClass("show")) {
            $("#dropdownOptions").removeClass("show");
            $("#dropdown").removeClass("show");
            setBtnFilters(true);
        } else {
            $("#dropdownOptions").addClass("show");
            $("#dropdown").addClass("show");
            setBtnFilters(false);
        }
    }

    return (
        <div
            className="dropdown dropleft"
            id="dropdown"
        >
            <span
                className="rounded-right bg-blue-purple font-medium-5 pointer style-plus-button"
                onClick={() => handleShowDropdown()}
            >
                {btnFilters === true ? (<i className="ft-plus p-0 m-0" />) : (<i className="ft-minus p-0 m-0" />)}
            </span>
            <div
                className="dropdown-menu dropdown-2"
                id="dropdownOptions"
            >
                <div className="row w-100">
                    <div className="col-11 mx-auto">
                        {catalogFilters.map(data => (
                            <div className="text-base">
                                <input
                                    type="checkbox"
                                    name={data.name}
                                    checked={!data.checked}
                                    onChange={handleCheckboxChange}
                                /> {data.description}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FilterColumn