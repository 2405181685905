import React from 'react';

const Footer = () => {
    return (
        <footer className="col-sx-12 col-sm-12 col-md-12 col-lg-12 navbar-shadow footer-2021">
            <div className="row f-10">
                <div className="col-sx-4 col-sm-4 col-md-3 col-lg-3 f-10">
                    <div className="mt-1">
                        <img className="icon-footer-2021 ml-5" alt="img-guay" src="./../img/imagesFooter/loguin_enterate.png" />
                        <span className="text-blue align-middle"><strong>ENTERATE</strong></span>
                    </div>
                    <p className="d-sm-block ml-5 text-base">Texto Texto Texto Texto</p>
                    <button className="btn ml-5" id="btn-footer-2021">Más info</button>
                </div>
                <div className="col-sx-4 col-sm-4 col-md-6 col-lg-6">
                    <center>
                        <span className="d-sm-block mt-1 text-base-guay"><strong>Contactanos</strong></span>
                        <span className="d-sm-block mt-1 text-base">55-56-78-91-011</span>
                        <a className="d-sm-block text-base" href="https://www.guay.digital/" target="_blank" rel="noopener noreferrer">www.guay.digital</a>
                    </center>
                </div>
                <div className="col-sx-4 col-sm-4 col-md-3 col-lg-3">
                    <span className="d-sm-block ml-5 mt-1 text-yellow"><strong>Siguenos</strong></span>
                    <a href="https://es-la.facebook.com/" target="_blank" rel="noopener noreferrer"><img className="icon-footer-2021 ml-5 mt-1" src="./../img/imagesFooter/loguin_redes_sociales_face.png" alt="img-guay" /></a>
                    <a href="https://twitter.com/?lang=es" target="_blank" rel="noopener noreferrer"><img className="icon-footer-2021 mt-1" src="./../img/imagesFooter/loguin_redes_sociales-tw.png" alt="img-guay" /></a>
                    <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer"><img className="icon-footer-2021 mt-1" src="./../img/imagesFooter/loguin_redes_sociales-you.png" alt="img-guay" /></a>
                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img className="icon-footer-2021 mt-1" src="./../img/imagesFooter/loguin_redes_sociales-insta.png" alt="img-guay" /></a>
                </div>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center division-footer-section f-10">
                <span className="d-sm-block mt-1">Copyright © 2020 guay</span>
            </div>
        </footer>
    );
}

export default Footer;