import React from 'react';
import Header from '../NewHome/headerAdministrador';
import Footer from '../footerApp';
import $ from "jquery";
import Swal from 'sweetalert2';
import { getJwt } from "../../../lib/auth";
import * as toastr from 'toastr';
import Menu from "../Menu";
import { max_length_inp } from "../../../lib/utils/generalFuntions";
import { getuserData } from '../Models/Users_model';
import { setContactShop } from '../Models/Shop_model';
import 'toastr/build/toastr.css';
import '../../css/shop.css';
import { getSubcategories, getProducts, getInternationalPrefix } from '../Models/Catalog_model';
//import HeaderA from '../NewHome/inicioAdministrador';
import HeaderC from '../NewHome/headerColaborador';
import CommercialOptions from './CommercialOptions';
import BannerWebinar from '../webinar/BannerWebinar';
import HelperApp from '../HelperApp';

export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contactUs:
            {
                idEnterprise: getJwt("enterprise") > 0 ? getJwt("enterprise") : 0,
                email: "",
                phone: "",
                hour: "",
                user: getJwt("jtw"),
                idCodigo: ""
            },
            //contactUs: [],
            products: [],
            categorie1: [],
            categorie2: [],
            userData: [],
            enterpriseData: [],
            internationalPrefix: [],
        }
        this.setContact = this.setContact.bind(this);
        this.setActiveProd = this.setActiveProd.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        try {

            getuserData(getJwt("jtw")).then((res) => {
                this.setState({
                    userData: res
                });
                // eslint-disable-next-line
                this.state.contactUs.email = res.correo;
                this.setState({
                    enterpriseData: res.enterprise
                });
            });
            getSubcategories().then((res) => {
                this.setState({
                    products: res
                });
            });
            getProducts().then((res) => {
                this.setState({
                    categorie1: res.products[0],
                    categorie2: res.products[1],
                });
            });

            getInternationalPrefix().then((res) => {
                this.setState({
                    internationalPrefix: res
                });
            });


        } catch (error) {
            console.log(error);
        }
    }

    getValue = (e) => {
        try {

            let value = e.target.value;
            let nameState = e.target.name;
            let contactUs = this.state.contactUs;
            // eslint-disable-next-line
            switch (e.target.name) {
                case "phone":

                    max_length_inp(e.target.id, 10);
                    contactUs[nameState] = value;
                    if (value === null || value.length === 0) {
                        $("#" + e.target.name).removeClass("border-success");
                        $("#" + e.target.name).addClass("border-danger");
                    } else {
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                    }
                    break;
                case "email":
                    contactUs[nameState] = value;
                    if (value === null || value.length === 0 /* || /^\s+$/.test(value) */) {
                        $("#" + e.target.name).removeClass("border-success");
                        $("#" + e.target.name).addClass("border-danger");
                    } else {
                        // eslint-disable-next-line
                        if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)/* /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/.test(value) */) {
                            $("#" + e.target.name).removeClass("border-danger");
                            $("#" + e.target.name).addClass("border-success");
                        } else {
                            $("#" + e.target.name).removeClass("border-success");
                            $("#" + e.target.name).addClass("border-danger");
                        }
                    }
                    break;

                case "hour":
                    contactUs[nameState] = value;
                    if (value === null || value.length === 0 || value > 24 || value === "Hr") {
                        $("#" + e.target.name).removeClass("border-success");
                        $("#" + e.target.name).addClass("border-danger");
                    } else {
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                    }
                    break;
                case "minutes":
                    if (value === null || value.length === 0 || value === "min") {
                        $("#" + e.target.name).removeClass("border-success");
                        $("#" + e.target.name).addClass("border-danger");
                    } else {
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                    }
                    break;

                case "idCodigo":

                    contactUs[nameState] = value;
                    if (value === null || value.length === 0 || value.length > 3) {
                        $("#" + e.target.name).removeClass("border-success");
                        $("#" + e.target.name).addClass("border-danger");
                    } else {
                        $("#" + e.target.name).removeClass("border-danger");
                        $("#" + e.target.name).addClass("border-success");
                    }
                    break;
                // default:
                //     contactUs[nameState] = value;
                //     break;
            }
            this.setState({ contactUs })
            if (
                // eslint-disable-next-line
                this.state.contactUs.hour != "" && this.state.contactUs.hour != "Hr" &&
                // eslint-disable-next-line
                this.state.contactUs.phone != "" && this.state.contactUs.phone.length >= 8 && this.state.contactUs.phone.length <= 10 &&
                // eslint-disable-next-line
                (this.state.contactUs.email != "" || this.state.userData.correo) &&
                // eslint-disable-next-line
                $("#minutes").val() != "min" && $("#minutes").val() != "" &&
                // eslint-disable-next-line
                $("#idCodigo").val() != "" && $("#idCodigo").val().length < 4
            ) {
                $('#btn-send-contactShop').prop('disabled', false);
            } else {
                $('#btn-send-contactShop').prop('disabled', true);
            }
        } catch (error) {
            console.log(error);
        }
        var phoneP = document.getElementById('phone');
        var idCodigo = document.getElementById('idCodigo');

        phoneP.addEventListener('input', function () {
            if (this.value.length > 10)
                this.value = this.value.slice(0, 10);
        });
        idCodigo.addEventListener('input', function () {
            if (this.value.length > 3)
                this.value = this.value.slice(0, 3);
        });
    }

    MakeItem = function (X) {
        return <option value={X}> {X}</option >
    };

    setContact(event) {
        event.preventDefault();
        try {
            let count = 0;
            $('#btn-send-contactShop').text("").prop('disabled', true)
                .addClass("btn-guay-processing-sm")
                .append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite	infinite icon-sm"></i> Procesando...');
            $("#form-send-contactShop :input").prop("disabled", true);

            if (this.state.userData.correo === "") {
                if (this.state.contactUs.email === "" || this.state.contactUs.email === undefined) {
                    this.validateMessage("#email", 'Falta llenar el campo: Correo');
                    return;
                }
            }
            // eslint-disable-next-line
            if ($("#idCodigo").val() === "" || $("#idCodigo").val() == 0 || $("#idCodigo").val() === undefined) {
                this.validateMessage("#idCodigo", 'Falta llenar el campo: Prefijo país:');
                count++;
            } else if ($("#idCodigo").val().length > 3) {
                this.validateMessage("#idCodigo", 'El campo: Prefijo país no puede ser mayor a 3 caracteres');
                count++;
            }
            if (this.state.contactUs.phone === "" || this.state.contactUs.phone === undefined) {
                this.validateMessage("#phone", 'Falta llenar el campo: Teléfono');
                count++;
            } else if (this.state.contactUs.phone.length > 10) {
                this.validateMessage("#phone", 'El campo: Teléfono no puede ser mayor a 10 caracteres');
                count++;
            }
            if (this.state.contactUs.hour === "" || this.state.contactUs.hour === undefined || this.state.contactUs.hour === "Hr") {
                this.validateMessage("#hour", 'Falta llenar el campo: Hora:');
                count++;
            } else if (this.state.contactUs.hour > 23) {
                this.validateMessage("#hour", 'El campo: "Horario de Contacto" no puede ser mayor a 23 horas');
                count++;
            }
            if ($("#minutes").val() === "" || $("#minutes").val() === undefined || $("#minutes").val() === "min") {
                this.validateMessage("#minutes", 'Falta llenar el campo: Minutos:');
                count++;
            } else if ($("#minutes").val() > 59) {
                this.validateMessage("#minutes", 'Los minutos no pueden ser mayores a 59');
                count++;
            }
            if (count > 0) {
                return;
            }
            if (this.state.contactUs.hour !== "" && $("#minutes").val() !== "") {
                let contactUs = this.state.contactUs;
                contactUs["hour"] = this.state.contactUs.hour + ":" + $("#minutes").val();
            }
            if (this.state.contactUs.phone !== "" && $("#idCodigo").val() !== "") {
                let contactUs = this.state.contactUs;
                let codigoPais = $('select[name="idCodigo"] option:selected').val();
                contactUs["phone"] = "+" + codigoPais + " " + this.state.contactUs.phone;
            }

            setContactShop(this.state.contactUs).then((res) => {

                if (res.code === 1) {
                    $('#btn-send-contactShop').text("").prop('disabled', false)
                        .append('ENVIAR')
                        .removeClass("btn-guay-processing-sm");
                    $("#form-send-contactShop :input").prop("disabled", false);
                    Swal.fire({
                        title: '<p class="text-white mb-2">¡Éxito!"</p>',
                        text: "Solicitud Registrada correctamente.",
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        dangerMode: true
                    }).then(() => {
                        let url = parseInt(getJwt("rol")) === 1 ? "./inicio-usuario" : "./inicio-colaborador"
                        window.location = url;
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
                else {
                    setTimeout(function () {
                        $('#btn-send-contactShop').text("").prop('disabled', false)
                            .append('ENVIAR')
                            .removeClass("btn-guay-processing-sm");
                        $("#form-send-contactShop :input").prop("disabled", false);
                    }, 500);
                    setTimeout(function () {
                        toastr.warning("Ocurrió un problema al registrar la información. Intente nuevamente");
                    }, 500);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    validateMessage(element, msg) {
        setTimeout(function () {
            $('#btn-send-contactShop').text("").prop('disabled', false)
                .append('ENVIAR')
                .removeClass("btn-guay-processing-sm");
            $("#form-send-contactShop :input").prop("disabled", false);
        }, 500);
        setTimeout(function () {
            $(element).addClass("border-danger");
            toastr.error(msg, '¡Espera!');
        }, 500);
        return;
    }

    setActiveProd(event) {
        try {
            let $currentTarget = $(event.currentTarget);
            let idprod = $currentTarget.attr("data-prod");
            let category = $currentTarget.attr("data-cat");
            $(".cat" + category).find("li").removeClass("active-prod");
            $("#lis" + idprod).addClass("active-prod");
            $(".catProd" + category).removeClass("active");
            $(".catProd" + category).addClass("hidden");
            $("#prod-" + idprod).removeClass("hidden");
            $("#prod-" + idprod).addClass("active");

        } catch (error) {
            console.log(error);
        }
    }
    selectHeader = () => {
        if (this.state.enterpriseData.length > 0) {
            for (let x in this.state.enterpriseData) {
                if (parseInt(getJwt("rol")) === 2 || parseInt(getJwt("rol")) === 3) {
                    if (this.state.enterpriseData[x].enterpriseId === parseInt(getJwt("enterprise"))) {
                        if (this.state.enterpriseData[x].idCollab === 0) {
                            return <Header />
                        } else {
                            return <HeaderC />
                        }
                    }
                } else {
                    return <HeaderC />
                }
            }
        } else {
            return <HeaderC />
        }
    }

    handleClick(value) {
        console.log(value);
    }

    render() {
        const { history } = this.props;
        return (
            <React.Fragment>
                <div className="app-content bootomFooter">
                    <div className="content-overlay">
                        <HeaderC />
                        <Menu />
                        <div className="content-wrapper bootomFooter">
                            <div className="content-header row">
                                <div className="col-md-6"></div>
                                <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                                    <div className="row breadcrumbs-top d-inline-block">

                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">

                                                <li className="breadcrumb-item font-medium-3"><a href={parseInt(getJwt("rol")) === 1 ? "inicio-usuario" : "inicio-colaborador"}>Inicio</a>
                                                </li>
                                                <li className="breadcrumb-item active font-medium-3">Tienda
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-body">
                                <HelperApp />
                                <div className="row">
                                    <div className="col-md-12 know-our-products">
                                        <h3><strong>Conoce nuestros producto<span onClick={() => history.push("/solicitud-comercial")}>s</span></strong></h3>
                                    </div>
                                    <CommercialOptions />
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mx-auto mt-1" >
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="card ">
                                                    {/* <div className="card heigth-list"> */}
                                                    <div className="shop-group cat1">
                                                        <ul className="list-group">
                                                            {
                                                                this.state.products.map((data) =>
                                                                    data.idProduct === 1 ?
                                                                        <li className={data.idCategory === 1 ? "list-group-item pointer pl-3 text-gray active-prod" : "list-group-item pointer pl-3 text-gray"}
                                                                            key={data.idCategory} id={"lis" + data.idCategory} data-cat={data.idProduct} data-prod={data.idCategory}
                                                                            onClick={this.setActiveProd}>{data.title}</li>
                                                                        : ""
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="card ">
                                                    <div className="shop-group cat2">
                                                        <ul className="list-group">
                                                            {
                                                                this.state.products.map((data) =>
                                                                    data.idProduct === 2 ?
                                                                        <li className={data.idCategory === 6 ? "list-group-item pointer pl-3 text-gray active-prod" : "list-group-item pointer pl-3 text-gray"}
                                                                            key={data.idCategory} id={"lis" + data.idCategory} data-cat={data.idProduct} data-prod={data.idCategory}
                                                                            onClick={this.setActiveProd}>{data.title}</li>
                                                                        : ""
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                {
                                                    getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? ('') : (<BannerWebinar setShowBanner={this.handleClick} />)
                                                }
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card ">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-3 img-producs-guay">
                                                                <img src={this.state.categorie1.img} alt={this.state.categorie1.title} />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="mt-3 ml-2">
                                                                    <p className="text-purple font-medium-1 font-weight-bold">Descripción del producto completo:</p>
                                                                    <p className="text-gray">{this.state.categorie1.description}</p>
                                                                    <p className="font-weight-bold font-medium-1 text-purple">{this.state.categorie1.services}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.products.map((dataProd) =>
                                                                dataProd.idProduct === 1 ?
                                                                    // eslint-disable-next-line
                                                                    <div className={dataProd.idCategory === 1 ? "row active catProd1" : "row hidden" + " catProd1"} id={"prod-" + dataProd.idCategory} key={dataProd.idCategory}  >
                                                                        <div className="col-md-3 img-producs-guay">
                                                                            <img src={dataProd.img} alt={dataProd.title} />
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <div className="mt-3 ml-2">
                                                                                <p className="text-black font-medium-1">Descripción {dataProd.title}:</p>
                                                                                <p className="text-gray">{dataProd.description}</p>
                                                                            </div>
                                                                            {
                                                                                dataProd.idCategory === 11 ? (
                                                                                    <button
                                                                                        className="btn btn-sm btn-pink float-right"
                                                                                        onClick={() => history.push("/solicitud-comercial")}
                                                                                    >Solicita una prueba</button>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : ""
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card ">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-3 img-producs-guay">
                                                                <img src={this.state.categorie2.img} alt={this.state.categorie1.title} />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="mt-3 ml-2">
                                                                    <p className="text-yellow font-medium-1 font-weight-bold">Descripción del producto completo:</p>
                                                                    <p className="text-gray">{this.state.categorie1.description}</p>
                                                                    <p className="font-weight-bold font-medium-1 text-yellow">{this.state.categorie1.services}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        {
                                                            this.state.products.map((dataProd) =>
                                                                dataProd.idProduct === 2 ?
                                                                    // eslint-disable-next-line
                                                                    <div className={dataProd.idCategory === 6 ? "row active catProd2" : "row hidden" + " catProd2"} id={"prod-" + dataProd.idCategory} key={dataProd.idCategory}  >
                                                                        <div className="col-md-3 img-producs-guay2">
                                                                            <img src={dataProd.img} alt={dataProd.title} />
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <div className="mt-3 ml-2">
                                                                                <p className="text-black font-medium-1">Descripción {dataProd.title}:</p>
                                                                                <p className="text-gray">{dataProd.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ""
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mx-auto mt-1">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="">
                                                            <form id="form-send-contactShop">
                                                                <div className="row">
                                                                    <div className="col-sx-8 col-sm-12 col-lg-8">
                                                                        <div className="ml-2">

                                                                            <p className="font-medium-1 text-gray">¿Te interesa alguno?</p>
                                                                            <p className="font-medium-1 text-gray">Puedes comunicarte o los teléfonos:</p>
                                                                            <p className="font-medium-1 text-gray">55-12-34-56-78 o 55-12-34-56-79</p>
                                                                            <p className="font-medium-1 text-gray">También puedes mandar correo a: ventas@guay.digital</p>
                                                                            <p className="font-medium-1 text-gray text-black">¿Quieres que te contactemos?, déjanos tus datos:</p>
                                                                        </div>

                                                                        <form onSubmit={(e) => { this.setContact(e) }}>
                                                                            <div className="row m-l2 mt-2">
                                                                                <div className="col-md-5">
                                                                                    <fieldset className="form-group ml-guay-h">
                                                                                        <label className="text-gray">Correo</label>
                                                                                        <input type="email" id="email" name="email" onChange={this.getValue} className="form-control input-style-1" placeholder="Correo"
                                                                                            value={this.state.contactUs.emailo !== "null" ? this.state.contactUs.email : ""}



                                                                                        />
                                                                                    </fieldset>
                                                                                </div>
                                                                                <div className="col-md-3 col-sm-12">
                                                                                    <fieldset className="form-group">
                                                                                        <label className="text-gray">Prefijo país</label>
                                                                                        <select
                                                                                            className="form-control form-control-sm"
                                                                                            id="idCodigo"
                                                                                            name="idCodigo"

                                                                                            onChange={this.getValue}
                                                                                        >
                                                                                            <option value="0">Seleccione</option>

                                                                                            {this.state.internationalPrefix.map((optionInertPrefix) => (
                                                                                                <option
                                                                                                    key={optionInertPrefix.idCodigop}
                                                                                                    value={optionInertPrefix.idCodigop}
                                                                                                >
                                                                                                    {"+" + optionInertPrefix.codigoPais + " (" + optionInertPrefix.descripcion + ")"}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </fieldset>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <fieldset className="form-group">
                                                                                        <label className="text-gray">Teléfono</label>
                                                                                        <input type="number" id="phone" name="phone" onChange={this.getValue} className="form-control input-style-1" placeholder="Teléfono 10 dígitos" />
                                                                                    </fieldset>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row m-l2">
                                                                                <div className="col-md-4  ml-guay-h mt-1">
                                                                                    <p className="text-gray text-muted">
                                                                                        Horario de Contacto: <br className="br-hidden" />
                                                                                        (Formato 24 Hr)
                                                                                    </p>

                                                                                </div>
                                                                                <div className="col-md-4 ml-8-n guay-hour">
                                                                                    <div className="col-md-5 ml-8-n mt-1">
                                                                                        <fieldset className="form-group">
                                                                                            <select id="hour" name="hour" onChange={this.getValue} className="form-control input-style-1" >
                                                                                                {["hr", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18"].map(this.MakeItem)}</select>
                                                                                        </fieldset>
                                                                                    </div>
                                                                                    <div className="hour-div mt-1"><p className="text-gray ">:</p></div>
                                                                                    <div className="col-md-5 ml-8-n mt-1">
                                                                                        <fieldset className="form-group">
                                                                                            <select id="minutes" onChange={this.getValue} name="minutes" className="form-control input-style-1 text-center" >
                                                                                                <option value="min">min</option>
                                                                                                <option value="00">00</option>
                                                                                                <option value="15">15</option>
                                                                                                <option value="30">30</option>
                                                                                                <option value="45">45</option>
                                                                                            </select>
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sx-4 col-lg-4  mt-2 div-btn-home">
                                                                                    <button className="btn btn-green btn-sm" id="btn-send-contactShop" type="submit" /* onClick={this.setContact} */>ENVIAR</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="col-sx-8 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center  div-woman-guay img-hidde">
                                                                        <img src="./../img/guay-shop.png" alt="guay shopping" className="img-woman-guay" />
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }


}
