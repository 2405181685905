import React, { useState } from "react";
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// API calls
import { setTrackingOfCollabByOther } from '../../Models/TrackingModel';
import { getJwt } from "../../../../lib/auth";
// Documentation
import PropTypes from 'prop-types';

const ActMonModalOther = ({
    listStageCollab,
    setListStageCollab,
    listExtractSteps,
    modalOtherGeneralData,
    handleLoadData,
    listStageCollab_2,
    step_2,
}) => {
    const [showForm, setShowForm] = useState(false);
    const {
        descStatus,
        nameCollab,
        percentage,
        idStatus,
    } = modalOtherGeneralData;
    const {
        closingDate,
        startDate,
        idAttention,
        commentsService,
        idPeriod,
        collaboratorId,
        idGroup,
        descCanalization
    } = listStageCollab;
    /**
     * Table props - Start
     */
    const options = {
        noDataText: 'No se encontraron registros',
        sortIndicator: true
    };
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const statusIcon = (cell, row, index) => {
        if (cell === 1) {
            return (
                <span>OK</span>
            )
        }
        if (cell === 2) {
            return (
                <span>OK</span>
            )
        }
        if (cell === 3) {
            return (
                <span className="text-green">Finalizado</span>
            )
        }
        if (cell === 4) {
            return (
                <span className="text-red">Fuera de proceso</span>
            )
        }
        if (cell === 5) {
            return (
                <span className="text-red">Fuera de proceso</span>
            )
        }
    }
    /**
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} index 
     * @returns 
     */
    const handleDateStepFormat = (cell, row, index) => {
        let completDate = cell.split("-");
        let month = "";
        switch (completDate[1]) {
            case "01":
                month = "Enero"
                break;
            case "02":
                month = "Febrero"
                break;
            case "03":
                month = "Marzo"
                break;
            case "04":
                month = "Abril"
                break;
            case "05":
                month = "Mayo"
                break;
            case "06":
                month = "Junio"
                break;
            case "07":
                month = "Julio"
                break;
            case "08":
                month = "Agosto"
                break;
            case "09":
                month = "Septiembre"
                break;
            case "10":
                month = "Octubre"
                break;
            case "11":
                month = "Noviembre"
                break;
            default:
                month = "Diciembre"
                break;
        }
        return (
            <span>{month + " " + completDate[2] + ", " + completDate[0]}</span>
        )
    }
    /**
     * Table props - End
     * 
     * Progress bar props - Start
     * 
     * @param {*} percentage 
     * @returns 
     */
    const barOptions = (percentage, idStatus) => {
        let width = 0;
        let backgroundColor = "#efeff6";
        switch (idStatus) {
            case 1:
                // grey
                width = `${percentage}%`;
                backgroundColor = "#6b6f82";
                break;
            case 2:
                //blue
                width = `${percentage}%`;
                backgroundColor = "#2188b4";
                break;
            case 3:
                //green
                width = `${percentage}%`;
                backgroundColor = "#9cb537";
                break;
            case 4:
                //red
                width = `${percentage}%`;
                backgroundColor = "#E74C3C";
                break;
            case 5:
                //red
                width = `${percentage}%`;
                backgroundColor = "#E74C3C";
                break;
            default:
                width = 0;
                backgroundColor = "#efeff6";
                break;
        }
        return {
            width,
            backgroundColor
        }
    };
    /**
     * Progress bar props - Start
     */
    const handleTextColor = (idStatus) => {
        let color = "#efeff6";
        switch (idStatus) {
            case 1:
                // grey
                color = "#6b6f82";
                break;
            case 2:
                //blue
                color = "#2188b4";
                break;
            case 3:
                //green
                color = "#9cb537";
                break;
            default:
                //red
                color = "#E74C3C";
                break;
        }
        return {
            color
        }
    };
    /**
     * Progress bar props - End
     */
    const handleSetForm = () => {
        // Compare if the user has changed data
        if (JSON.stringify(listStageCollab) === JSON.stringify(listStageCollab_2) === true) {
            setShowForm(!showForm)
        } else {
            let data = {
                closingDate: listStageCollab_2.closingDate === closingDate ? ("") : (closingDate),
                startDate: listStageCollab_2.startDate === startDate ? ("") : (startDate),
                idAttention: listStageCollab_2.idAttention === idAttention ? (-1) : (idAttention),
                commentsService: listStageCollab_2.commentsService === commentsService ? ("") : (commentsService),
                sessions: 0,
                address: "",
                curDate: new Date(),
                idPeriod: idPeriod,
                collaboratorId: collaboratorId,
                idGroup: idGroup,
                idEnterprise: getJwt("enterprise")
            }
            setTrackingOfCollabByOther(data.closingDate, data.startDate, data.idAttention, data.commentsService, data.sessions, data.address, data.curDate, data.idPeriod, data.collaboratorId, data.idGroup, data.idEnterprise).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            let successLoadData = handleLoadData(collaboratorId, data.idGroup);
                            if (successLoadData === 1) {
                                toastr.success('Se guardó correctamente los datos.', '¡Éxito!');
                            } else {
                                toastr.error('Se guardaron correctamente los datos pero hubo problemas en cargar los datos en la tabla. Recargue la página nuevamente.', '¡Ooops!');
                            }
                            break;
                        default:
                            toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error('Problemas con el servidor. Intente nuevamente.', '¡Ooops!');
                }
            });
        }
    }
    const handleGetValue = e => {
        let value = e.target.value;
        let paramaterName = e.target.name;
        switch (paramaterName) {
            case "idAttention":
                setListStageCollab({
                    ...listStageCollab,
                    [paramaterName]: parseInt(value)
                });
                break;
            default:
                setListStageCollab({
                    ...listStageCollab,
                    [paramaterName]: value
                });
                break;
        }
    }
    return (
        <div
            className="modal"
            id="modalActMonModalOther"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                $('#modalActMonModalOther').hide();
                                setShowForm(!showForm);
                            }}>&times;</button>
                    </div>
                    <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <h1 className="text-base-guay">Tracking - {descCanalization}</h1>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-start font-medium-1">
                                    <span className="text-base font-weight-bold">Colaborador: <span className="text-base-guay font-weight-bold">{nameCollab}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-start font-medium-1">
                                    <span className="text-base font-weight-bold">Estatus: <span className="text-base-guay font-weight-bold">{descStatus}</span></span>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-5 col-lg-5">
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-start font-medium-1">
                                            <span className="text-base">Avance:</span>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                                            <div
                                                className="progress"
                                                style={{ marginTop: '4px' }}
                                            >
                                                <div
                                                    className="progress-bar"
                                                    style={barOptions(percentage, idStatus)} />
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-start font-medium-1">
                                            <span
                                                className="font-weight-bold"
                                                style={handleTextColor(idStatus)} >{percentage}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <BootstrapTable
                                data={listExtractSteps}
                                hover
                                headerStyle={{ border: 'red 0px solid' }}
                                bodyStyle={{ border: 'green 0px solid' }}
                                tableStyle={{ border: '#9cb0d1 2.5px solid', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField='rank'
                                    hidden ></TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='descPhase'
                                    dataAlign="center"
                                    /* width="20%" */
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Fase</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='descStage'
                                    dataAlign="center"
                                    width="21%"
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Etapa</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='descActivity'
                                    dataAlign="center"
                                    width="21%"
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Actividad</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='dateStep'
                                    dataFormat={handleDateStepFormat}
                                    dataAlign="center"
                                    width="26%"
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Fecha de actualización</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='idStatus'
                                    dataFormat={statusIcon}
                                    dataAlign="center"
                                    width="15%"
                                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >Estatus</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        {
                            showForm === true
                                ?
                                (
                                    <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mt-2">
                                        <form>
                                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="text-base font-small-3">Acción:</label>
                                                            <select
                                                                className="form-control form-control-sm"
                                                                id="idAttention"
                                                                name="idAttention"
                                                                value={idAttention}
                                                                onChange={handleGetValue}
                                                                disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)} >
                                                                <option value="-1">Selecciona</option>
                                                                <option value="0">En proceso</option>
                                                                <option value="1">Rechazado</option>
                                                                <option value="2">Abandonado</option>
                                                                <option value="3">Finalizado</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className="col">
                                                        {/* <div className="row">
                                                            <div className="col-9 pr-0"> */}
                                                        <div className="form-group">
                                                            <label className="text-base font-small-3">Fecha de inicio:</label>
                                                            <input
                                                                type="date"
                                                                className="form-control form-control-sm"
                                                                id="startDate"
                                                                name="startDate"
                                                                value={startDate}
                                                                onChange={handleGetValue}
                                                                disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (listStageCollab_2.startDate === "" ? (false) : (true)) : (true)} />
                                                        </div>
                                                        {/* </div> */}
                                                        {/* <div className="col-3 d-flex flex-wrap align-content-center pr-0">
                                                                <img
                                                                    className="w-100"
                                                                    src="./../img/actions/actionsMonitoring/iconos_acciones-05.png"
                                                                    alt="img-monitoring"
                                                                />
                                                            </div> */}
                                                        {/* </div> */}
                                                    </div>
                                                    <div className="col">
                                                        {/* <div className="row"> */}
                                                        {/* <div className="col-9 pr-0"> */}
                                                        <div className="form-group">
                                                            <label className="text-base font-small-3">Fecha de finalización:</label>
                                                            <input
                                                                type="date"
                                                                className="form-control form-control-sm"
                                                                id="closingDate"
                                                                name="closingDate"
                                                                value={closingDate}
                                                                onChange={handleGetValue}
                                                                disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)} />
                                                        </div>
                                                        {/* </div> */}
                                                        {/* <div className="col-3 d-flex flex-wrap align-content-center pr-0">
                                                                <img
                                                                    className="w-100"
                                                                    src="./../img/actions/actionsMonitoring/iconos_acciones-05.png"
                                                                    alt="img-monitoring"
                                                                />
                                                            </div> */}
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-base font-small-3">Comentarios:</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control form-control-sm textarea-unresize"
                                                    rows="5"
                                                    id="commentsService"
                                                    name="commentsService"
                                                    style={{ borderRadius: '10px' }}
                                                    maxLength="200"
                                                    value={commentsService}
                                                    onChange={handleGetValue}
                                                    disabled={step_2 ? true : listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)} />
                                                <p className="text-right">{commentsService === "" ? (0) : (commentsService.length)}/200</p>
                                            </div>
                                        </form>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }
                        {
                            !step_2 && (
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center">
                                    {
                                        showForm === false ?
                                            (
                                                <button
                                                    className="btn btn-pink btn-sm w-25"
                                                    onClick={() => setShowForm(!showForm)}
                                                >Editar</button>
                                            ) :
                                            (
                                                listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ?
                                                    (
                                                        <button
                                                            className="btn btn-pink btn-sm w-25"
                                                            onClick={() => handleSetForm()}
                                                        >Actualizar</button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-pink btn-sm w-25"
                                                            onClick={() => setShowForm(!showForm)}
                                                        >Actualizar</button>
                                                    )
                                            )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

ActMonModalOther.propTypes = {
    listStageCollab: PropTypes.object.isRequired,
    setListStageCollab: PropTypes.func.isRequired,
    listExtractSteps: PropTypes.array.isRequired,
    modalOtherGeneralData: PropTypes.object.isRequired,
    handleLoadData: PropTypes.func.isRequired,
    listStageCollab_2: PropTypes.object.isRequired
}

export default ActMonModalOther;
