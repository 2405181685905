import $ from "jquery";
/**
* Function that validates file Extension
* 
* @param {Object} e Object with error generated
*/
export const validateFile = async (file, tipo = 3) => {
    try {
        var fileupload = file,
            fsize = fileupload.size,
            fname = fileupload.name,
            fextension = fname.substring(fname.lastIndexOf('.') + 1);
        let msgValidFile;

        switch (tipo) {
            case 1:
                tipo = ["pdf"];
                msgValidFile = "pdf";
                break;
            case 2:
                tipo = ["xml"];
                msgValidFile = "xml";
                break;
            case 3:
                tipo = ["jpg", "png", "jpeg"];
                msgValidFile = "jpg, png, jpeg";
                break;
            case 4:
                tipo = ["jpg", "jpeg", "png", "pdf"];
                msgValidFile = "jpg, jpeg, png, pdf";
                break;
            case 5:
                tipo = ["csv", "xls", "xlsx", "xlt"];
                msgValidFile = "csv, xls, xlsx, xlt";
                break;
            default:
                break;
        }
        var validExtensions = tipo;
        if ($.inArray(fextension, validExtensions) === -1) {
            return { 'error': 1000, "msg": "El archivo " + fname + " tiene un formato no válido. \n Los formatos validos son: " + msgValidFile + ". Intente nuevamente." };
        } else {
            if (fsize > 5242880) {/*5 MB*/
                return {
                    'error': 1001, "msg": "El archivo" + fname + "  es muy pesado, el tamaño máximo permitido para el archivo es de 5MB. Intente nuevamente"
                };
            }
            return { 'success': 1, "msg": "El archivo cumple con los requerimientos necesarios." };
        }
    } catch (exception) {
        console.log(exception);
    }
};