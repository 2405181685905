/*
 File Name: Enterprise_model.js
 Description: Manage Enterprises API Requests
 guay dev's team
*/
import axios from "axios";

/**
 * Get jobs data from specific enterprise
 * @param {enterpriseId} 
 * @returns 
 */
export const getEnterpriseJob = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getEnterpriseJob?enterpriseId=" + enterpriseId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get bosses data from specific enterprise
 * @param {enterpriseId} 
 * @returns 
 */
export const getBossEnterprise = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getBossEnterprise?enterpriseId=" + enterpriseId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for get all branches
 * @param {int} enterpriseId 
 * @returns JSON response
 */
export const getBranches = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getBranches?enterpriseId=" + enterpriseId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for deleting branch
 * @param {int} enterpriseId 
 * @param {int} branchEntId 
 * @returns JSON response
 */
export const deleteBranches = async (enterpriseId, branchEntId) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{enterpriseId: ${enterpriseId}, branchEntId: ${branchEntId}}`);
    try {
        let result = await axios({
            url: global.base_url + "enterprise/deleteBranches",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for editing name of branch
 * @param {JSON object} objBranch 
 * @param {Boolean} forceSave 
 * @returns JSON response
 */
export const editBranches = async (objBranch, forceSave) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('objBranch', objBranch);
    data.append('forceSave', forceSave);
    try {
        let result = await axios({
            url: global.base_url + "enterprise/updateBranches",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for saving new branch
 * @param {object} objBranch 
 * @returns JSON response
 */
export const addBranches = async (objBranch, force) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setBranches?forceSave=" + force + "",
            method: 'POST',
            data: objBranch,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/** AREAS METHODS */

/**
 * Get all areas from an specific enterprise with num of related jobs
 * @param enterpriseId 
 * @returns {JSON} promise
 */
export const getAreas = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getAreasWJobs?idEnterprise=" + enterpriseId,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for adding a new area
 * @param {int} enterpriseId 
 * @param {string} areaDescription 
 * @returns JSON promise
 */
export const addArea = async (enterpriseId, areaDescription) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setArea",
            method: 'POST',
            data: {
                "enterpriseId": enterpriseId,
                "description": areaDescription
            },
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for deleting an specific area from specific enterprise
 * @param {int} enterpriseId 
 * @param {int} areaId 
 * @returns JSON promise
 */
export const deleteArea = async (enterpriseId, areaId) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{"enterpriseId": ${enterpriseId},"areaEntId": ${areaId}}`);
    try {
        let result = await axios({
            url: global.base_url + "enterprise/deleteArea",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Methon for updating an specific area from specific enterprise
 * @param {int} enterpriseId 
 * @param {int} areaId 
 * @param {string} areaDescription 
 * @returns JSON response
 */
export const updateArea = async (enterpriseId, areaId, areaDescription) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/updateArea",
            method: 'POST',
            data: {
                "areaEntId": areaId,
                "enterpriseId": enterpriseId,
                "description": areaDescription
            },
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Method for get all jobs from area
 * @param {int} enterpriseId 
 * @param {int} areaId 
 * @returns JSON response
 */
export const getJobsAreas = async (enterpriseId, areaId) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/getJobWCategory?idEnterprise=${enterpriseId}&idArea=${areaId}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const updateCargoHomologadoAreas = async (objCargoHom, option) => {
    console.log("ch", objCargoHom)
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{enterpriseId: ${objCargoHom.enterpriseId}, areaId: ${objCargoHom.areaId}, jobId: ${objCargoHom.jobId}, categoryIdAnt: ${objCargoHom.catAntId}, categoryIdNew: ${objCargoHom.catNewId}, option: ${option}}`);
    try {
        let result = await axios({
            url: global.base_url + "enterprise/updCategoryEnterprise",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const updateCargosAreasModel = async (objCargoHom) => {
    console.log("cargo", objCargoHom)
    const FormData = require('form-data');
    const data = new FormData();
    data.append('request', `{enterpriseId: ${objCargoHom.enterpriseId}, areaId: ${objCargoHom.areaId}, jobId: ${objCargoHom.jobId}, categoryId: ${objCargoHom.categoryId}, description: ${objCargoHom.description}, option: ${objCargoHom.option}}`);
    try {
        let result = await axios({
            url: global.base_url + "enterprise/updJobEnterprise",
            method: 'POST',
            data,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/** AREAS METHODS */

/**
 * Update data branches
 * @param {enterpriseId: int, branchEntId: int, geolocation: string, description: string } objectBranch
 * @param { full_address: string } dataAddress
 * @param { boolean } forceSave
 * @returns { object }
 */
export const updateBranches = async (objectBranch, dataAddress, forceSave) => {
    try {
        let formData = new FormData();
        formData.append("objBranch", JSON.stringify(objectBranch));
        formData.append("objAddress", JSON.stringify(dataAddress));
        formData.append("forceSave", JSON.stringify(forceSave));
        let result = await axios({
            url: global.base_url + "enterprise/updateBranches",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * Homologate brnahces in a concatenated list of branches id
 * @param {int} enterpriseId 
 * @param {string} idsToHomologate - Concatenated list of branches id (e.g. "1,2,3,4")
 * @param {int} branchEntId 
 * @param {string} description 
 * @returns 
 */
export const homologateBranches = async (enterpriseId, idsToHomologate, branchEntId, description) => {
    try {
        let data = {
            enterpriseId: enterpriseId,
            idsToHomologate: idsToHomologate,
            branchEntId: branchEntId,
            description: description
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "enterprise/homologateBranches",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Returns active products by enterprise
 * @param {int} enterpriseId 
 * @returns 
 */
export const getActiveProductsByEnterprise = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/getProductsEnterprise?idEnterprise=${enterpriseId}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get all branches from product and enterprise
 * @param {int} enterpriseId 
 * @param {int} productId 
 * @param {int} categoryId 
 * @param {int} subCategoryId 
 * @param {int} normativeFlg 
 * @returns 
 */
export const getBranchesLaunch = async (enterpriseId, productId, categoryId, subCategoryId, normativeFlg, flgLaunch) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/getBranchEntLaunch?idEnterprise=${enterpriseId}&idProduct=${productId}&idCategory=${categoryId}&idSubcategory=${subCategoryId}&flgNormative=${normativeFlg}&flgLaunch=${flgLaunch}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getHasTypeLaunch = async (enterpriseId, productId, CategoryId, subcategoryId) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/getFlagJointLaunches?idEnterprise=${enterpriseId}&idProduct=${productId}&idCategory=${CategoryId}&idSubcategory=${subcategoryId}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const getInfoLaunch4Product = async (enterpriseId, productId, categoryId, subcategoryId, flagTypeLaunch, flagNormative) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/launch/getInfoLaunchXProduct?idEnterprise=${enterpriseId}&idProduct=${productId}&idCategory=${categoryId}&idSubcategory=${subcategoryId}&flagTypeLaunch=${flagTypeLaunch}&flagNormative=${flagNormative}`,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const getCalendarCom = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/launch/getCalendarCom",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const DisabledCalendarCom = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/launch/disabledCalendarCom",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data;
    } catch (error) {
        console.log(error);
    }
}
/**
 * Checa informacion de una empresa
 * @param {int} idEnterprise 
 * @returns 
 */
export const getActiveEnterprise = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getActiveEnterprise?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idUser 
 * @param {*} designFlg 
 * @param {*} logoFlg 
 * @param {*} presentationMsg 
 * @returns 
 */
export const setCommunicationConfig = async (idEnterprise, idUser, designFlg, logoFlg, presentationMsg) => {
    try {
        let data = {
            idEnterprise: idEnterprise,
            idUser: idUser,
            designFlg: designFlg,
            logoFlg: logoFlg,
            presentationMsg: presentationMsg,
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "enterprise/setCommunicationConfig",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idUser 
 * @param {*} complaintFlg 
 * @param {*} presentationMsg 
 * @param {*} url 
 * @returns 
 */
export const setComplaintConfig = async (idEnterprise, idUser, complaintFlg, presentationMsg, url, file) => {
    try {
        let data = {
            idEnterprise: idEnterprise,
            idUser: idUser,
            complaintFlg: complaintFlg,
            presentationMsg: presentationMsg,
            url: url
        }
        let formData = new FormData();
        /* if (complaintFlg === "2" || complaintFlg === 2) {
            if (file.length === 5) {
                formData.append("file", file[0]);
                formData.append("file", file[1]);
                formData.append("file", file[2]);
                formData.append("file", file[3]);
                formData.append("file", file[4]);
            }
            if (file.length === 4) {
                formData.append("file", file[0]);
                formData.append("file", file[1]);
                formData.append("file", file[2]);
                formData.append("file", file[3]);
            }
            if (file.length === 3) {
                formData.append("file", file[0]);
                formData.append("file", file[1]);
                formData.append("file", file[2]);
            }
            if (file.length === 2) {
                formData.append("file", file[0]);
                formData.append("file", file[1]);
            }
            if (file.length === 1) {
                formData.append("file", file[0]);
            }
        } else {
            formData.append("file", file);
        } */
        formData.append("file", file);
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "enterprise/setComplaintConfig",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @returns 
 */
export const sendConfigTest = async (idEnterprise) => {
    try {
        let data = {
            idEnterprise: idEnterprise
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "enterprise/sendConfigTest",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @returns 
 */
export const getComplaintConfig = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getComplaintConfig?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idConfigImgComplaint 
 * @returns 
 */
export const setDeleteFileComplaintConfig = async (idEnterprise, idConfigImgComplaint) => {
    try {
        let data = {
            idEnterprise: idEnterprise,
            idConfigImgComplaint: idConfigImgComplaint
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "enterprise/setDeleteFileComplaintConfig",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @returns 
 */
export const getCommunicactionConfig = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getCommunicactionConfig?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idOption 
 * @param {*} idEnterprise 
 * @returns 
 */
export const getBranchEnterprise = async (idOption, idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getBranchEnterprise?idOption=" + idOption + "&idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getBranchesByUserRol = async (enterpriseId, idUser, idRol) => {
    try {
        let result = await axios({
            url: `${global.base_url}enterprise/getBranchesByUserRol?enterpriseId=${enterpriseId}&idUser=${idUser}&idRol=${idRol}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const deleteUploadDocument = async (idUploadedDocument) => {
    try {
        let data = { idUploadedDocument };
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: global.base_url + "enterprise/deleteUploadDocument",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idEnterprise 
 * @param {*} idProduct 
 * @param {*} idCategory 
 * @param {*} idSubcategory 
 * @returns 
 */
export const getLaunchsByService = async (idEnterprise, idProduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}enterprise/launch/getLaunchsByService?idEnterprise=${idEnterprise}&idProduct=${idProduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} idUser 
 * @returns 
 */
export const getEnterprisesByUser = async (idUser) => {
    try {
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}enterprise/getEnterprisesByUser?idUser=${idUser}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
}
