import React, { useState, useEffect } from 'react';
import Header from '../../NewHome/headerAdministrador';
import Footer from '../../footerApp';
import { redirectIfNotAuthenticated, getJwt } from "../../../../lib/auth";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getDatagroupById } from "../Model";
import { getuserData, getRoleAssignment, getRoleAssignmentCatalog, setRegisterUserRol, setChangeRole, setDisableRoleAssignment } from "../../NewHome/Model";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Swal from "sweetalert2";
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Menu from "../../Menu";
import { getBranches } from '../../Models/Enterprise_model';


const UsersGroupEnterprise = props => {

    const [roles, updateRoles] = useState([]);
    const [listUsers, updateListUsers] = useState([]);
    const [idUser, setIdUser] = useState(0);
    const [companys, upCompany] = useState([]);
    // eslint-disable-next-line
    const [entGroups, upentGroups] = useState([]);
    const [data, updateData] = useState([]);
    const [enterpriseGroup, upEnterprisesGroup] = useState({});
    const [newUser, updateNewUser] = useState({
        enterpriseId: getJwt("enterprise"),
        name: '',
        lastName: '',
        email: '',
        phone: '',
        rolId: 0
    });
    const [ListBranch, setListBranch] = useState([]);

    useEffect(() => {
        try {
            const getObject = props.match.params;
            let object = getObject.db.split("=");
            var id = object[1];

            let idEnterprise = {
                idEnterprise: id,
            }
            getuserData(getJwt("jtw")).then((result) => {
                let enterpri = result.enterprise;
                updateData({
                    ...data,
                    result,
                });
                upCompany(enterpri);
            });
            let formData = new FormData();
            formData.append("request", JSON.stringify(idEnterprise));
            getRoleAssignment(formData).then((result) => {
                updateListUsers(result.listUsers);
            });
            getRoleAssignmentCatalog().then((result) => {
                updateRoles(result);
            });
            getDatagroupById(id).then((result) => {
                upEnterprisesGroup(result)
            });

            getBranches(getJwt("enterprise")).then((response) => {
                switch (response?.code) {
                    case 1:
                        setListBranch(response.branches);
                        break;

                    default:
                        console.error('error');
                        break;
                }
            });


            setEnterprises();

        } catch (error) {
            console.log(error)
        }

        // eslint-disable-next-line
    }, []);

    // Función que se ejecuta cada que el usuario escribe en un input
    const updateState = e => {
        $("#" + e.target.name).removeClass("border-danger-guay");
        if ($("#rolId").val() !== "0") {
            $("#rolId").removeClass("fake-placeholder");
        } else {
            $("#rolId").addClass("fake-placeholder");
        }
        updateNewUser({
            ...newUser,
            [e.target.name]: e.target.value
        });
    }

    // Extraer los valores
    const { name, lastName, email, phone, rolId } = newUser;



    const validateForm = () => {
        let form = 0;
        if (name.length === 0) {
            $("#name").addClass("border-danger-guay");
        } else {
            form = form + 1;
        }
        if (lastName.length === 0) {
            $("#lastName").addClass("border-danger-guay");
        } else {
            form = form + 1;
        }
        if (email.length === 0) {
            $("#email").addClass("border-danger-guay");
        } else {
            form = form + 1;
        }
        if (phone.length === 0) {
            $("#phone").addClass("border-danger-guay");
        } else {
            form = form + 1;
        }
        if (rolId === "0" || rolId === 0) {
            $("#rolId").addClass("border-danger-guay");
        } else {
            if (parseInt(rolId) === 6) {
                if (ListBranch.find(centro => centro.checked === true)) {
                    form = form + 1;
                } else {
                    $("#rolId").addClass("border-danger-guay");
                    toastr.info('Selecciona al menos 1 centro de trabajo');
                }
            } else {
                form = form + 1;
            }
        }
        if (form >= 5) {
            return true;
        } else {
            return false;
        }
    }

    const submitNewUser = e => {
        e.preventDefault();
        try {
            if (validateForm() === true) {
                let string = '';
                ListBranch.forEach(branch => {
                    if (branch.checked === true) {
                        string += branch.branchEntId + ','
                    }
                });
                let updateData = {
                    ...newUser,
                    enterpriseId: $("#enterprise").val(),
                    rolId: $("#rolId").val(),
                    branchs: string
                }
                let formData = new FormData();
                formData.append("request", JSON.stringify(updateData));
                setRegisterUserRol(formData).then((result) => {
                    if (result.code === 1) {
                        let idEnterprise = {
                            idEnterprise: sessionStorage.getItem('enterpriseId'),
                        }
                        let formData2 = new FormData();
                        formData2.append("request", JSON.stringify(idEnterprise));
                        getRoleAssignment(formData2).then((result) => {
                            //updateDataUsers(result);
                            updateListUsers(result.listUsers);
                        });
                        toastr.success('Se registró correctamente.', '¡Exito!');
                    } else {
                        toastr.error('Hubo un problema. No se registró correctamente.', '¡Ooops!');
                    }
                });
                $("#registro").hide();
                updateNewUser({
                    name: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    rolId: 0
                });
                let array = [...ListBranch];
                array = array.map(centro => {
                    return { ...centro, checked: false }
                })
                setListBranch(array);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setDataInModal = (cell, row, rowIndex) => {
        try {
            updateNewUser({
                name: row.name,
                lastName: row.lastName,
                email: row.email,
                phone: row.phone,
                rolId: row.rolId
            });
            setIdUser(cell);
            let centros = [...ListBranch];
            centros = centros.map(centro => {
                if (row.listBranch.find(idBranch => idBranch === centro.branchEntId)) {
                    return { ...centro, checked: true }
                } else {
                    return { ...centro }
                }
            });
            setListBranch(centros);

        } catch (error) {
            console.log(error);
        }
    }

    const changeRole = (e) => {
        e.preventDefault();
        try {
            let string = '';
            ListBranch.forEach(branch => {
                if (branch.checked === true) {
                    string += branch.branchEntId + ','
                }
            });
            let setRol = {
                idEnterprise: parseInt(sessionStorage.getItem('enterpriseId')),
                idUser: idUser,
                idRole: parseInt(newUser.rolId),
                name: name,
                lastName: lastName,
                email: email,
                phone: phone,
                branchs: string
            }
            let formData = new FormData();
            formData.append("request", JSON.stringify(setRol));
            setChangeRole(formData).then((result) => {
                if (result.code === 1) {
                    let idEnterprise = {
                        idEnterprise: sessionStorage.getItem('enterpriseId'),
                    }
                    let formData2 = new FormData();
                    formData2.append("request", JSON.stringify(idEnterprise));
                    getRoleAssignment(formData2).then((result) => {
                        //updateDataUsers(result);
                        updateListUsers(result.listUsers);
                    });
                    toastr.success('Se cambió el rol correctamente.', '¡Exito!');
                } else {
                    toastr.error('Hubo un problema. No se cambió el rol correctamente.', '¡Ooops!');
                }
            });
            $("#update").hide();
            $("#update-2").hide();
            updateNewUser({
                name: '',
                lastName: '',
                email: '',
                phone: '',
                rolId: 0
            });
            let array = [...ListBranch];
            array = array.map(centro => {
                return { ...centro, checked: false }
            })
            setListBranch(array);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteCollaborator = (cell, row, rowIndex) => {
        try {
            Swal.fire({
                title: '<p class="text-white mb-2">¿Deseas Continuar?</p>',
                text: "Una vez eliminado el registro, no podrá recuperarse",
                showCloseButton: true,
                showCancelButton: true,
                focusCancel: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: "SÍ, Eliminar",
                reverseButtons: true,
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    let dataDelete = {
                        idEnterprise: parseInt(sessionStorage.getItem('enterpriseId')),
                        idUser: cell,
                    }
                    let formData = new FormData();
                    formData.append("request", JSON.stringify(dataDelete));
                    setDisableRoleAssignment(formData).then((result) => {
                        if (result.code === 1) {
                            let idEnterprise = {
                                idEnterprise: sessionStorage.getItem('enterpriseId'),
                            }
                            let formData2 = new FormData();
                            formData2.append("request", JSON.stringify(idEnterprise));
                            getRoleAssignment(formData2).then((result) => {
                                //updateDataUsers(result);
                                updateListUsers(result.listUsers);
                            });
                            toastr.success('Se eliminó correctamente.', '¡Exito!');
                        } else {
                            toastr.error('Hubo un problema. No se eliminó correctamente.', '¡Ooops!');
                        }
                    });
                    updateNewUser({
                        name: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        rolId: 0
                    });
                }
            });
            $(".swal2-title").addClass("bg-header-swal");
            $(".swal2-actions").addClass("w-100 justify-content-around");
            $(".swal2-confirm").removeClass("swal2-styled");
            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
            $(".swal2-cancel").removeClass("swal2-styled");
            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        } catch (error) {
            console.log(error);
        }
    }

    const cleanData = (e) => {
        e.preventDefault();
        try {
            updateNewUser({
                name: '',
                lastName: '',
                email: '',
                phone: '',
                rolId: 0
            });
            let array = [...ListBranch];
            array = array.map(centro => {
                return { ...centro, checked: false }
            })
            setListBranch(array);
        } catch (error) {
            console.log(error);
        }
    }

    const setIcons = (cell, row, enumObject, rowIndex) => {
        let currentRolId = parseInt(sessionStorage.getItem('userRolId'))
        if (row.rolId >= currentRolId && row.rolId <= 6) {
            return (
                <div>
                    <i onClick={() =>
                        deleteCollaborator(cell, row, rowIndex)}
                        className='ft ft-trash-2 icon-medium-size text-blue pointer' ></i>
                    <i onClick={() =>
                        setDataInModal(cell, row, rowIndex)}
                        className='ft ft-edit-2 icon-medium-size text-blue pointer ml-1 mr-1'
                        data-toggle="modal"
                        data-target="#update" ></i>
                </div>
            )
        } else {
            return (
                <div>
                    <i className='ft ft-trash-2 icon-medium-size text-base pointer' ></i>
                    <i className='ft ft-edit-2 icon-medium-size text-base pointer ml-1 mr-1' ></i>
                </div>
            )
        }
    }

    const options = {
        sizePerPage: 10,  // Showing 5 for the size per page as default
        hideSizePerPage: true
    };

    const getRolesList = (rol, description) => {
        let currentRolId = parseInt(sessionStorage.getItem('userRolId'))
        if (rol !== currentRolId) {
            return (
                <option
                    key={rol}
                    value={rol}
                >
                    {description}
                </option>
            )
        } else {
            return null
        }
    }

    const setEnterprises = () => {
        let array = companys;
        let array_2 = [];
        for (let x in array) {
            if (array[x].enterpriseGroupId !== 0) {
                array_2.push({ value: array[x].enterpriseId, label: array[x].name })
            }
        }
        upentGroups(array_2)

        //return array_2;
    }

    function seletectedAll() {
        if (ListBranch.find(branch => branch.checked === undefined || branch.checked === false)) {
            return false;
        } else {
            return true;
        }
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header row">
                            <div className="col-md-6"></div>
                            <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                                <div className="row breadcrumbs-top d-inline-block">
                                    <div className="breadcrumb-wrapper col-12">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                                            </li>
                                            <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Grupo Organizacional</a>
                                            </li>
                                            <li className="breadcrumb-item active font-medium-3"><span>{enterpriseGroup.comercialName}</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-1 col-lg-1 mb-40"></div>
                                <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mt-2">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-8 d-flex">
                                                        <div className="mx-auto d-flex align-items-center">
                                                            <span className="f-20">Administración de Usuarios</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <button type="button" className="btn btn-administracionUsuarios btn-sm" >{sessionStorage.getItem('enterpriseName')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sx-11 col-sm-11 col-md-10 col-lg-2"></div>
                                            <div className="col-sx-11 col-sm-11 col-md-10 col-lg-12 mt-2">
                                                <nav>
                                                    <div className="nav nav-tabs nav-justified adminNav" id="nav-tab" role="tablist">
                                                        <a className="nav-item nav-link roles active" id="nav-asigna-tab" data-toggle="tab" href="#nav-asigna" role="tab" aria-controls="nav-asigna" aria-selected="true">Asigna roles</a>
                                                    </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent">

                                                    {/*ECO*/}
                                                    <div className="tab-pane active" id="nav-asignaRoles" role="tabpanel" aria-labelledby="nav-asigna-tab">
                                                        <div className="d-flex">
                                                            <div className="mr-auto mt-1 d-flex align-items-center">
                                                                <span className="align-middle text-base font-weight-bold font-h f-1-rem ml-3">Roles y usuarios</span>
                                                            </div>
                                                            <div className="mt-1">
                                                                <img className="icon-img-35 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_guay_inicio-05.png" alt="img-guay" data-toggle="modal" data-target="#helpModal" />
                                                                <img className="icon-img-50 ml-1 mr-1 pointer" src="./../img/imagesAdminUsuarios/iconos_guay_inicio-06.png" alt="img-guay" data-toggle="modal" data-target="#registro" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 font-small-1 pl-0 pr-0">
                                                            <BootstrapTable
                                                                data={listUsers}
                                                                striped={true}
                                                                hover={true}
                                                                pagination={true}
                                                                tableStyle={{ border: 'none' }}
                                                                options={options}
                                                                trClassName='tr-data-table'
                                                            >
                                                                <TableHeaderColumn
                                                                    dataField='userId'
                                                                    isKey
                                                                    width='100'
                                                                    thStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#6b6f82',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '1rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    tdStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#bababa',
                                                                        fontSize: '1rem',
                                                                        paddingLeft: '0px',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    dataFormat={setIcons} ></TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="name"
                                                                    thStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#6b6f82',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '1rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    tdStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#bababa',
                                                                        fontSize: '1rem',
                                                                        paddingLeft: '0px',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    dataSort={true}>Nombre</TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="lastName"
                                                                    thStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#6b6f82',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '1rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    tdStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#bababa',
                                                                        fontSize: '1rem',
                                                                        paddingLeft: '0px',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    dataSort={true}>Apellidos</TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="email"
                                                                    thStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#6b6f82',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '1rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    tdStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#bababa',
                                                                        fontSize: '1rem',
                                                                        paddingLeft: '0px',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    dataSort={true}>Email</TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="description"
                                                                    width='125'
                                                                    thStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#6b6f82',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '1rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    tdStyle={{
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderLeft: 'none',
                                                                        borderBottom: '2px solid #D7D7D7',
                                                                        backgroundColor: 'transparent',
                                                                        color: '#bababa',
                                                                        fontSize: '1rem',
                                                                        paddingLeft: '0px',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem'
                                                                    }}
                                                                    dataSort={true}>Rol</TableHeaderColumn>
                                                            </BootstrapTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sx-11 col-sm-11 col-md-10 col-lg-8 mx-auto mt-3">

                                                {/*MODAL*/}
                                                {/*<!-- The Modal -->*/}
                                                <div className="modal" id="registro" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content" style={{ borderRadius: '3.35rem', border: '5px solid gray' }}>

                                                            {/*<!-- Modal body -->*/}
                                                            <div className="modal-body pb-5">
                                                                <div className="col-lg-10 mx-auto">
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                                                <h1 className="font-weight-bold">{sessionStorage.getItem('enterpriseName')}</h1>
                                                                            </div>
                                                                            <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                                                <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                <span className="align-middle font-h ml-1 font-weight-bold" style={{ fontSize: '1.5rem' }} >Nuevo usuario</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <form>
                                                                        <div className="col-12 mt-1">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Nombre (s)</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            name="name"
                                                                                            className="form-control"
                                                                                            placeholder="Nombre"
                                                                                            id="name"
                                                                                            onChange={updateState}
                                                                                            value={name} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Apellidos</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            name="lastName"
                                                                                            className="form-control"
                                                                                            placeholder="Apellidos"
                                                                                            id="lastName"
                                                                                            onChange={updateState}
                                                                                            value={lastName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Correo electrónico</label>
                                                                                        <input
                                                                                            type="email"
                                                                                            name="email"
                                                                                            className="form-control"
                                                                                            placeholder="Correo electrónico"
                                                                                            id="email"
                                                                                            onChange={updateState}
                                                                                            value={email} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Teléfono móvil</label>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="phone"
                                                                                            className="form-control"
                                                                                            placeholder="Teléfono"
                                                                                            id="phone"
                                                                                            onChange={updateState}
                                                                                            value={phone} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Empresa</label>
                                                                                        <select
                                                                                            className="form-control fake-placeholder"
                                                                                            name="enterprise"
                                                                                            id="enterprise"
                                                                                            onChange={updateState}
                                                                                        >
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {companys.map(entData =>
                                                                                                enterpriseGroup.groupId === entData.enterpriseGroupId ?

                                                                                                    <option value={entData.enterpriseId} key={entData.enterpriseId}>{entData.name}</option>
                                                                                                    : ""
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Rol</label>
                                                                                        <select
                                                                                            className="form-control fake-placeholder"
                                                                                            name="rolId"
                                                                                            id="rolId"
                                                                                            onChange={updateState}
                                                                                            value={rolId}>
                                                                                            <option value="0">--Seleccione--</option>
                                                                                            {roles.map(rol =>
                                                                                                getRolesList(rol.rolId, rol.description)
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    parseInt(rolId) === 6 &&
                                                                                    <div className='col-12 mb-2 d-flex'>
                                                                                        <button
                                                                                            className="btn-cancelar-usuario pointer"
                                                                                            type='button'
                                                                                            onClick={() => { $('#branchesContainer').removeClass('div_branches-hidden'); $('#branchesContainer').addClass('div_branches-visible'); }}>Mostrar centros de trabajo</button>

                                                                                        <div id='branchesContainer' className='div_branches-hidden'>
                                                                                            <label className='d-flex justify-content-end pointer' onClick={() => { $('#branchesContainer').removeClass('div_branches-visible'); $('#branchesContainer').addClass('div_branches-hidden'); }}>X</label>
                                                                                            <div className='div_branches-data'>
                                                                                                <label className="text-base">Centros de trabajo asignados a este usuarios</label>
                                                                                                <div className="col form-check">
                                                                                                    <input className="form-check-input" type="checkbox"
                                                                                                        checked={seletectedAll()}
                                                                                                        onChange={({ target }) => {
                                                                                                            console.log(target.checked);
                                                                                                            let array = [...ListBranch];
                                                                                                            if (target.checked) {
                                                                                                                array = array.map(centro => {
                                                                                                                    return { ...centro, checked: true }
                                                                                                                })
                                                                                                            } else {
                                                                                                                array = array.map(centro => {
                                                                                                                    return { ...centro, checked: true }
                                                                                                                })
                                                                                                            }
                                                                                                            setListBranch(array);
                                                                                                        }} />
                                                                                                    <label className="form-check-label">
                                                                                                        Todos los centros de trabajo
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className='col row'>
                                                                                                    {ListBranch.map(branch => (
                                                                                                        <div className="col form-check" key={branch.branchEntId}>
                                                                                                            <input className="form-check-input" type="checkbox" value={branch.branchEntId} checked={branch.checked} onChange={({ target }) => {
                                                                                                                let array = [...ListBranch];
                                                                                                                array = array.map(centro => {
                                                                                                                    if (centro.branchEntId === parseInt(target.value)) {
                                                                                                                        if (centro.checked === undefined) { // si no existe la key la asignamos como true
                                                                                                                            return { ...centro, checked: true }
                                                                                                                        } else {
                                                                                                                            return { ...centro, checked: !centro.checked } // si existe la key la convertimos a su contrario
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return { ...centro }
                                                                                                                    }
                                                                                                                })
                                                                                                                setListBranch(array);
                                                                                                            }} />
                                                                                                            <label className="form-check-label">
                                                                                                                {branch.description}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 d-flex justify-content-around">
                                                                            <button className="btn-cancelar-usuario pointer" data-dismiss="modal">Cancelar</button>
                                                                            <button className="btn-agregar-usuario pointer" onClick={(e) => submitNewUser(e)}>Agregar</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<!-- The Modal 2 -->*/}
                                                <div className="modal" id="update" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content" style={{ borderRadius: '3.35rem', border: '2px solid gray' }}>

                                                            {/*<!-- Modal body -->*/}
                                                            <div className="modal-body pb-5">
                                                                <div className="col-lg-10 mx-auto">
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                                                <h1 className="font-weight-bold">{sessionStorage.getItem('enterpriseName')}</h1>
                                                                            </div>
                                                                            <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                                                <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                <span className="align-middle font-h ml-1 font-weight-bold" style={{ fontSize: '1.5rem' }} >Editar Usuario de sistema</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <form>
                                                                        <div className="col-12 mt-1">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Nombre (s)</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            name="name"
                                                                                            className="form-control"
                                                                                            placeholder="Nombre"
                                                                                            id="name"
                                                                                            value={name}
                                                                                            onChange={updateState} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Apellidos</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            name="lastName"
                                                                                            className="form-control"
                                                                                            placeholder="Apellidos"
                                                                                            id="lastName"
                                                                                            value={lastName}
                                                                                            onChange={updateState} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Correo electrónico</label>
                                                                                        <input
                                                                                            type="email"
                                                                                            name="email"
                                                                                            className="form-control"
                                                                                            placeholder="Correo electrónico"
                                                                                            id="email"
                                                                                            value={email}
                                                                                            onChange={updateState} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Teléfono móvil</label>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="phone"
                                                                                            className="form-control"
                                                                                            placeholder="Teléfono"
                                                                                            id="phone"
                                                                                            value={phone}
                                                                                            onChange={updateState} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="text-base">Rol</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="rolId"
                                                                                            id="rolId"
                                                                                            onChange={updateState}
                                                                                            value={rolId}>
                                                                                            {roles.map(rol =>
                                                                                                getRolesList(rol.rolId, rol.description)
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    parseInt(rolId) === 6 &&
                                                                                    <div className='col-12 mb-2 d-flex'>
                                                                                        <button
                                                                                            className="btn-cancelar-usuario pointer"
                                                                                            type='button'
                                                                                            onClick={() => { $('#branchesContainer1').removeClass('div_branches-hidden'); $('#branchesContainer1').addClass('div_branches-visible'); }}>Mostrar centros de trabajo</button>

                                                                                        <div id='branchesContainer1' className='div_branches-hidden'>
                                                                                            <label className='d-flex justify-content-end pointer' onClick={() => { $('#branchesContainer1').removeClass('div_branches-visible'); $('#branchesContainer1').addClass('div_branches-hidden'); }}>X</label>
                                                                                            <div className='div_branches-data'>
                                                                                                <label className="text-base">Centros de trabajo asignados a este usuarios</label>
                                                                                                <div className="col form-check">
                                                                                                    <input className="form-check-input" type="checkbox"
                                                                                                        checked={seletectedAll()}
                                                                                                        onChange={({ target }) => {
                                                                                                            console.log(target.checked);
                                                                                                            let array = [...ListBranch];
                                                                                                            if (target.checked) {
                                                                                                                array = array.map(centro => {
                                                                                                                    return { ...centro, checked: true }
                                                                                                                })
                                                                                                            } else {
                                                                                                                array = array.map(centro => {
                                                                                                                    return { ...centro, checked: true }
                                                                                                                })
                                                                                                            }
                                                                                                            setListBranch(array);
                                                                                                        }} />
                                                                                                    <label className="form-check-label">
                                                                                                        Todos los centros de trabajo
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className='col row'>
                                                                                                    {ListBranch.map(branch => (
                                                                                                        <div className="col form-check" key={branch.branchEntId}>
                                                                                                            <input className="form-check-input" type="checkbox" value={branch.branchEntId} checked={branch.checked} onChange={({ target }) => {
                                                                                                                let array = [...ListBranch];
                                                                                                                array = array.map(centro => {
                                                                                                                    if (centro.branchEntId === parseInt(target.value)) {
                                                                                                                        if (centro.checked === undefined) { // si no existe la key la asignamos como true
                                                                                                                            return { ...centro, checked: true }
                                                                                                                        } else {
                                                                                                                            return { ...centro, checked: !centro.checked } // si existe la key la convertimos a su contrario
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return { ...centro }
                                                                                                                    }
                                                                                                                })
                                                                                                                setListBranch(array);
                                                                                                            }} />
                                                                                                            <label className="form-check-label">
                                                                                                                {branch.description}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 d-flex justify-content-around">
                                                                            <button className="btn-cancelar-usuario pointer" onClick={(e) => cleanData(e)} data-dismiss="modal">Atrás</button>
                                                                            <button className="btn-agregar-usuario pointer" data-dismiss="modal" data-toggle="modal" data-target="#update-2" >Actualizar</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<!-- The Modal 2 -->*/}
                                                <div className="modal" id="update-2" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                                        <div className="modal-content" style={{ borderRadius: '3.35rem', border: '2px solid gray' }}>

                                                            {/*<!-- Modal body -->*/}
                                                            <div className="modal-body pb-5">
                                                                <div className="col-lg-10 mx-auto">
                                                                    <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                                        <h1 className="font-weight-bold">{sessionStorage.getItem('enterpriseName')}</h1>
                                                                    </div>
                                                                    <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                                        <span className="font-medium-1 font-weight-bold text-base">El usuario se actualizará de la siguiente manera:</span>
                                                                    </div>
                                                                    <div className="col-lg-12 mt-1">
                                                                        <div className="row font-medium-1 text-base">
                                                                            <div className="col-lg-10 mt-1 mx-auto">
                                                                                <div className="row">
                                                                                    <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 p-0 d-flex justify-content-end flex-wrap align-content-center">
                                                                                        <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                    </div>
                                                                                    <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                                        <span>{name + " " + lastName}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-10 mt-1 mx-auto">
                                                                                <div className="row">
                                                                                    <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 p-0 d-flex justify-content-end flex-wrap align-content-center">
                                                                                        <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                    </div>
                                                                                    <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                                        <span>{email}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-10 mt-1 mx-auto">
                                                                                <div className="row">
                                                                                    <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 p-0 d-flex justify-content-end flex-wrap align-content-center">
                                                                                        <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                    </div>
                                                                                    <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                                        <span>{phone}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-10 mt-1 mx-auto">
                                                                                <div className="row">
                                                                                    <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 p-0 d-flex justify-content-end flex-wrap align-content-center">
                                                                                        <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                    </div>
                                                                                    <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                                        <span>{roles.map(rol => parseInt(rolId) === rol.rolId ? rol.description : null)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                parseInt(rolId) === 6 && (
                                                                                    <div className="col-lg-10 mt-1 mx-auto">
                                                                                        <div className="row">
                                                                                            <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 p-0 d-flex justify-content-end flex-wrap align-content-center">
                                                                                                <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                                                            </div>
                                                                                            <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                                                <button
                                                                                                    className="btn-cancelar-usuario pointer"
                                                                                                    type='button'
                                                                                                    onClick={() => { $('#branchesContainer2').removeClass('div_branches-hidden'); $('#branchesContainer2').addClass('div_branches-visible'); }}>Mostrar centros de trabajo</button>
                                                                                                <div id='branchesContainer2' className='div_branches-hidden'>
                                                                                                    <label className='d-flex justify-content-end pointer' onClick={() => { $('#branchesContainer2').removeClass('div_branches-visible'); $('#branchesContainer2').addClass('div_branches-hidden'); }}>X</label>
                                                                                                    <div className='div_branches-data'>
                                                                                                        <label className="text-base">Centros de trabajo asignados a este usuarios</label>
                                                                                                        <ul>
                                                                                                            {
                                                                                                                ListBranch.map(branch => {
                                                                                                                    if (branch.checked) {
                                                                                                                        return (<li>{branch.description}</li>)
                                                                                                                    } else {
                                                                                                                        return null
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 d-flex justify-content-around mt-2">
                                                                        <button className="btn-cancelar-usuario pointer" data-dismiss="modal" data-toggle="modal" data-target="#update" >Atrás</button>
                                                                        <button className="btn-agregar-usuario pointer" onClick={(e) => changeRole(e)} >Confirmar</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<!-- The Modal 3 -->*/}
                                                <div className="modal" id="helpModal" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                                                    <div className="modal-dialog modal-dialog-centered modal-xl">
                                                        <div className="modal-content" style={{ border: 'none', background: 'transparent' }}>
                                                            <div className="modal-header" style={{ border: 'none' }}>
                                                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                            </div>
                                                            {/*<!-- Modal body -->*/}
                                                            <div className="modal-body" style={{ height: '600px' }}>
                                                                <div className="row mx-auto">
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-sx-12 col-sm-12 col-md-3 col-lg-2"></div>
                                                                            <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3">
                                                                                <img className="img-responsive" style={{ position: 'absolute' }} src="./../img/imagesAdminUsuarios/iconos_shopy_guay-39.png" alt="guay-digital" />
                                                                                <p className="font-small-3 text-center div-style-4" >Conoce los roles que puedes administrar en el sistema</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '170px' }}>
                                                                        <div className="row d-flex justify-content-center">
                                                                            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2">
                                                                                <img className="img-responsive" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-41.png" alt="guay-digital" />
                                                                            </div>
                                                                            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2">
                                                                                <img className="img-responsive" style={{ marginTop: '20%' }} src="./../img/imagesAdminUsuarios/iconos_shopy_guay-42.png" alt="guay-digital" />
                                                                                <p className="font-small-3 text-center div-style-5" >Administrador</p>
                                                                            </div>
                                                                            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2">
                                                                                <img className="img-responsive" style={{ marginTop: '20%' }} src="./../img/imagesAdminUsuarios/iconos_shopy_guay-42.png" alt="guay-digital" />
                                                                                <p className="font-small-3 text-center div-style-5" >Configurador</p>
                                                                            </div>
                                                                            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2">
                                                                                <img className="img-responsive" style={{ marginTop: '20%' }} src="./../img/imagesAdminUsuarios/iconos_shopy_guay-42.png" alt="guay-digital" />
                                                                                <p className="font-small-3 text-center div-style-5" >Colaborador</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersGroupEnterprise;
