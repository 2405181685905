import React from 'react';
import $ from "jquery";

const ImagesViewer = ({ showImage }) => {
    return (
        <div
            className="modal"
            id="modalImagesViewer"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            onClick={() => $('#modalImagesViewer').hide()}>&times;</button>
                    </div>
                    {/*<!-- Modal body -->*/}
                    <div className="modal-body modal-div-style-1 container-style-1 pl-0 pr-0">
                        <div className="col-lg-12">
                            <img
                                className="img-responsive"
                                src={showImage}
                                alt="preview-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImagesViewer;
