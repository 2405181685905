import React, { useState, useEffect } from "react";
import ConfirmCollab from "./ConfirmCollab";
import UploadCollab from "./UploadCollab";
import AdminAreas from "./AdminAreas";
import RegisterBranches from "./RegisterBranches";
import Header from '../../NewHome/headerAdministrador';
import Menu from "../../Menu";
import Footer from '../../footerApp';
import { redirectIfNotAuthenticated } from "../../../../lib/auth";
import { connect } from 'react-redux';
import { getJwt } from "../../../../lib/auth";
import { validateAccess, getSubsection } from "../../../helpers/validations";
import { useHistory } from "react-router-dom";
import HelperApp from "../../HelperApp";
import WelcomeEmailCtrl from "./welcomeEmail/WelcomeEmailCtrl";

const Admin_collab = ({ access }) => {
  const [steps, actualizarSteps] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(1);
  const [titleSection, setTitleSection] = useState('');

  const [justRead, setJustRead] = useState(false)

  const history = useHistory();

  useEffect(() => {
    if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
      //console.log(access)
      let access_1 = false
      let access_2 = false
      for (let x in access.list) {
        if (access.list[x].idPlatformFunctionality === 21) {
          access_1 = true
        }
        if (access.list[x].idPlatformFunctionality === 28) {
          access_2 = true
        }
      }
      let goBackTo = {
        admin: false,
        enterprises: false,
      }
      if (access_1) {
        //console.log(validateAccess(access.list, 3))
        const section = validateAccess(access.list, 3)
        //console.log(getSubsection(section[0].subSection, 21))
        const subsection = getSubsection(section[0].subSection, 21)
        if (subsection[0].id_action === 2) {
          setJustRead(true)
        }
        if (section[0].id_action === 0) {
          goBackTo = {
            ...goBackTo,
            admin: true,
          }
        }
      } else {
        if (access_2) {
          //console.log(validateAccess(access.list, 4))
          const section = validateAccess(access.list, 4)
          //console.log(getSubsection(section[0].subSection, 28))
          const subsection = getSubsection(section[0].subSection, 28)
          if (subsection[0].id_action === 2) {
            setJustRead(true)
          }
          if (section[0].id_action === 0) {
            goBackTo = {
              ...goBackTo,
              enterprises: true,
            }
          }
        } else {
          if (goBackTo.admin) {
            history.push("/inicio-administrador")
          }
          if (goBackTo.enterprises) {
            history.push("/inicio-empresas")
          }
          if (!goBackTo.admin && !goBackTo.enterprises) {
            history.push("/inicio-colaborador")
          }
        }
      }
    }
  }, [access, history])

  if (redirectIfNotAuthenticated()) {
    return (
      <div className="app-content bootomFooter">
        <div className="content-overlay">
          <Header />
          <Menu />
          <div className="content-wrapper bootomFooter">
            <div className="content-header row">
              <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                <div className="content-header-right breadcrumb-new mt-1 d-flex justify-content-end">
                  <div className="row breadcrumbs-top d-inline-block">
                    <div className="breadcrumb-wrapper col-12">
                      {
                        sessionStorage.getItem('fromOrg')
                          ?
                          (
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                              </li>
                              <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Grupos Organizacionales</a>
                              </li>
                              <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Organizaciones</a>
                              </li>
                              <li className="breadcrumb-item active font-medium-3">{steps === 0 ? "Carga Colaboradores" : steps === 1 ? "Colaboradores" : steps === 2 ? "Centros de trabajo" : steps === 3 ? "Áreas y cargos" : steps === 4 ? "Configuración Correo" :null}
                              </li>
                            </ol>
                          )
                          :
                          (
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                              </li>
                              <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Organizaciones</a>
                              </li>
                              <li className="breadcrumb-item active font-medium-3">{steps === 0 ? "Carga Colaboradores" : steps === 1 ? "Colaboradores" : steps === 2 ? "Centros de trabajo" : steps === 3 ? "Áreas y cargos" : steps === 4 ? "Configuración Correo" :null}
                              </li>
                            </ol>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-body">
              <HelperApp />
              <div className="col-sx-12 col-sm-12 col-md-12 col-lg-11 mx-auto mt-2">
                <div className="row">
                  <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2">
                  </div>
                  <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10">
                    <h2 className="pl-3">{titleSection}</h2>
                  </div>
                  <div className="col-sx-12 col-sm-12 col-md-11 col-lg-2 mx-auto pl-0 mt-2">
                    <div className="row">
                      {steps === 0 || steps === 1
                        ?
                        (
                          <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-blue-guay pointer py-2 rounded-top">
                            <img src="./../img/collabImages/iconos_carga_colaboradores-02.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Colaboradores</span>
                          </div>
                        )
                        :
                        (
                          <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2 rounded-top">
                            <img src="./../img/collabImages/iconos_carga_colaboradores_1.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Colaboradores</span>
                          </div>
                        )
                      }
                      {
                        completedSteps >= 2
                          ?
                          (steps === 2
                            ?
                            (
                              <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-blue-guay pointer py-2">
                                <img src="./../img/collabImages/iconos_carga_colaboradores-04.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Centros de Trabajo</span>
                              </div>
                            )
                            :
                            (
                              <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2">
                                <img src="./../img/collabImages/iconos_carga_colaboradores-03.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Centros de Trabajo</span>
                              </div>
                            )
                          )
                          :
                          (
                            <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2" style={{ opacity: '0.7' }}>
                              <img src="./../img/collabImages/iconos_carga_colaboradores-03.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Centros de Trabajo</span>
                            </div>
                          )
                      }
                      {
                        completedSteps >= 3
                          ?
                          (steps === 3
                            ?
                            (
                              <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-blue-guay pointer py-2 rounded-bottom">
                                <img src="./../img/collabImages/iconos_carga_colaboradores-06.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Áreas</span>
                              </div>
                            )
                            :
                            (
                              <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2 rounded-bottom">
                                <img src="./../img/collabImages/iconos_carga_colaboradores-05.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Áreas</span>
                              </div>
                            )
                          )
                          :
                          (
                            <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2" style={{ opacity: '0.7' }}>
                              <img src="./../img/collabImages/iconos_carga_colaboradores-05.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Áreas</span>
                            </div>
                          )
                      }
                      {
                        completedSteps === 4
                          ?
                          (steps === 4
                            ?
                            (
                              <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-blue-guay pointer py-2 rounded-bottom">
                                <img src="./../img/welcomeEmail/4_blanco.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Correo inicial</span>
                              </div>
                            )
                            :
                            (
                              <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2 rounded-bottom">
                                <img src="./../img/welcomeEmail/4_azul.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Correo inicial</span>
                              </div>
                            )
                          )
                          :
                          (
                            <div className="col-sx-12 col-sm-12 col-md-4 col-lg-12 mx-auto bg-white-guay pointer py-2" style={{ opacity: '0.7' }}>
                              <img src="./../img/welcomeEmail/4_azul.png" className="img-btn-nav mr-1" alt="guay-img" /><span className="font-medium-1">Correo inicial</span>
                            </div>
                          )
                      }
                    </div>
                  </div>
                  <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mt-2">
                    {steps === 0 ?
                      <UploadCollab
                        actualizarSteps={actualizarSteps}
                        setTitleSection={setTitleSection}
                        justRead={justRead}
                      /> : steps === 1 ?
                        <ConfirmCollab
                          actualizarSteps={actualizarSteps}
                          setTitleSection={setTitleSection}
                          setCompletedSteps={setCompletedSteps}
                          justRead={justRead}
                        /> : steps === 3 ?
                          <AdminAreas
                            setTitleSection={setTitleSection}
                            actualizarSteps={actualizarSteps}
                            setCompletedSteps={setCompletedSteps}
                          /> : steps === 2 ?
                            <RegisterBranches
                              setTitleSection={setTitleSection}
                              actualizarSteps={actualizarSteps}
                              setCompletedSteps={setCompletedSteps}
                            /> : steps === 4 && (
                              <WelcomeEmailCtrl
                                setTitleSection={setTitleSection}
                                actualizarSteps={actualizarSteps}
                                setCompletedSteps={setCompletedSteps}
                              />
                            )
                    }
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ access }) => {
  return {
    access
  }
}

export default connect(mapStateToProps)(Admin_collab);
