import React from 'react';
import Iframe from 'react-iframe';

export const IframeOut = () => {
    return (
        <div className="container mt-5">
            <h1>Docs Directa</h1>
            <hr />
            <Iframe url="https://analytics.guay.digital/MicroStrategy/servlet/mstrWeb?&hiddensections=path&evt=2048001&src=mstrWeb.2048001&documentID=68F9C6E44A5B83F2EB45B89560BB9334&currentViewMedia=1&visMode=0&Server=35.231.31.201&Port=0&Project=Norma+035&uid=carlos@guay.digital&pwd=123456"
                width="100%"
                height="500px"
                id="myId"
                className="w-100"
                display="initial"
                position="relative" overflow="hidden" />
        </div>
    );
}
