import React, { useEffect } from 'react';
import $ from 'jquery';
import * as toastr from 'toastr'
import { cancelAppointmentByIdAgenda, getAppointmentByIdAgenda } from '../Models/Catalog_sigma_model';
import { useState } from 'react';

const UtilizarBeneficio = ({
    idBenefit,
    idCollab,
    setIdBenefit,
    setIdProduct,
    setURL,
    setBenefitName,
    setShowHTML,
    setShowScheduleAppoiment,
    setShowUseBenefitHtml,
    appointmentsList,
    catalogTypeReason,
    setRefreshUseBenefitHtml,
}) => {

    const [appointmentData, setAppointmentData] = useState({
        idAppointment:'',
        motivo : '',
        date: '',
        hora: '',
        sintomas: '',
        estadoCita:'',
        doctor:'',
        clinica:'',
        direccion: '',
    })

    const { idAppointment, motivo, date, hora, sintomas, estadoCita, doctor, clinica, direccion} = appointmentData

    const handleCloseModal = idModal => {

        $(`#${idModal}`).hide()
        setAppointmentData({
            idAppointment:'',
            motivo : '',
            date: '',
            hora: '',
            sintomas: '',
            estadoCita:'',
            doctor:'',
            clinica:'',
            direccion: '',
        })

    }

    const handleCancelAppointmentByIdAgenda = (idAppointment, statusAppointment) => {
        if(idAppointment !== '' && parseInt(idAppointment) > 0){
            $(`#modalLoaderGen`).show()
            $(`#modal_cancelar_cita`).hide()
            if(statusAppointment === 'P' || statusAppointment === 'A' || statusAppointment === 'O'){
                // Method to cancel an appointment with estatus (pendiente P, aceptado A, coordinado O)
                cancelAppointmentByIdAgenda(parseInt(idAppointment)).then(response => {
                    if (response.validation) {
                        $(`#modalLoaderGen`).hide()
                        setRefreshUseBenefitHtml(true)
                        toastr.success('La cita se canceló correctamente', '¡Éxíto!')
                    }
                })
            }else{
                $(`#modalLoaderGen`).hide()
                toastr.info(`Esta cita no puede cancelarse dedbido a su estatus: ${handleGetEstadoCita(estadoCita)} `, '¡Ooops')
            }
        }else{
            toastr.warning('Hubo un problema en obtener la cita', '¡Ooops!')
        }
    }
    const handleGetEstadoCitaColor = (idEstadoCita) => {
        console.log('idEstadoCita: ',idEstadoCita);
        let colorCita =''
        switch (idEstadoCita) {
            case 'P':
                colorCita = 'text-yellow'
                break;
            case 'N':
                colorCita = 'text-base-guay'
                break;
            case 'O':
                colorCita = 'text-blue-iam-2'
                break;
            case 'A':
                colorCita = 'text-green'
                break;
            case 'C':
                colorCita = 'text-red2'
                break;
            case 'W':
                colorCita = 'text-white-blue'
                break;
            case 'Z':
                colorCita = 'text-blue-sec'
                break; 
            default:
                break;
        }
        return colorCita
    }
    
    const handleGetEstadoCita = (idEstadoCita) => {
        console.log('idEstadoCita: ',idEstadoCita);
        let estadoCita =''
        switch (idEstadoCita) {
            case 'P':
                estadoCita = 'Pendientes'
                break;
            case 'N':
                estadoCita = 'Anulado'
                break;
            case 'O':
                estadoCita = 'Coordinado'
                break;
            case 'A':
                estadoCita = 'Aceptado'
                break;
            case 'C':
                estadoCita = 'Cancelado'
                break;
            case 'W':
                estadoCita = 'Asistió'
                break;
            case 'Z':
                estadoCita = 'No Asistió'
                break; 
            default:
                break;
        }
        return estadoCita
    }

    const handleGetFecha = txt => {
        return `${txt.slice(6,8)}/${txt.slice(4,6)}/${txt.slice(0,4)}`
        // return `${txt.charAt(6)}${txt.charAt(7)}/${txt.charAt(4)}${txt.charAt(5)}/${txt.charAt(0)}${txt.charAt(1)}${txt.charAt(2)}${txt.charAt(3)}`
    }

    const handleGetHora = txt => {
        return `${txt.slice(8,10)}:${txt.slice(10,12)}`
        // return `${txt.charAt(8)}${txt.charAt(9)}:${txt.charAt(10)}${txt.charAt(11)}`
    }

    const handleGetAppointmentByIdAgenda = (idModal) => {
        if(appointmentsList.length > 0){
            $(`#modalLoaderGen`).show()
            const idAppointment = appointmentsList[0].idAppointment
            getAppointmentByIdAgenda(idAppointment).then(response => {
                if (response.validation) {
                    const srtMotivo =catalogTypeReason.filter(data => data.idTypeReason === parseInt(response.data.IMOTIVO))
                    setAppointmentData({
                        idAppointment:idAppointment,
                        motivo :  srtMotivo[0].description,
                        date: response.data.FCITA !== '' ? handleGetFecha(response.data.FCITA) : handleGetFecha(response.data.FCITAP),
                        hora: response.data.FCITA !== '' ? handleGetHora(response.data.FCITA) : handleGetHora(response.data.FCITAP),
                        sintomas: response.data.XDESCRIPCION,
                        estadoCita:response.data.IESTADO,
                        doctor:response.data.XDOCTOR && response.data.XDOCTOR !== '' ? response.data.XDOCTOR : '',
                        clinica:response.data.XCLINICA && response.data.XCLINICA !== '' ? response.data.XCLINICA : '',
                        direccion:response.data.XDIRECCION && response.data.XDIRECCION !== '' ? response.data.XDIRECCION : '',
                    })
                    $(`#modalLoaderGen`).hide()
                    $(`#${idModal}`).show()

                }
            })   
        }else{
            toastr.info('No ha generado ninguna cita', '¡Ooops!')
        }
    }
    
    return (
        <div className='container-fluid'>
            <div className='row bg-white m-1 rounded'>
                <div className='col-12 mx-auto'>
                    <span className='pointer text-cian-giki font-medium-3 font-weight-bold'
                    onClick={() => {
                        setShowScheduleAppoiment(false)
                        setShowUseBenefitHtml(false)
                        setShowHTML(false)
                        setIdBenefit(0)
                        setIdProduct(0)
                        setURL('')
                        setBenefitName('')
                    }}
                    ><i className="bi bi-chevron-left font-medium-5" /> Atrás</span>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-center'>
                    <div className='row'>
                        <div className='col-12 m-1'>
                            <h1 className='text-center text-blue-sec fs-1-25'>¿Qu&eacute; te gustar&iacute;a hacer?</h1>
                        </div>
                        <div className='col-12 m-1 d-flex justify-content-center'>
                            <button
                                className='btn btn-blue fs-875'
                                onClick={() => {
                                    setShowScheduleAppoiment(true)
                                    setShowUseBenefitHtml(false)
                                }}
                            >
                                <img
                                    src='./../img/beneficios/Icono_agendar_cita.png'
                                    className='img-fluid w-18 mr-1'
                                    alt='Icono_agendar_cita'
                                />
                                Agendar cita
                            </button>
                        </div>
                        <div className='col-12 m-1 d-flex justify-content-center'>
                            <button
                                className='btn btn-blue fs-875'
                                onClick={() => handleGetAppointmentByIdAgenda('modal_consultar_cita')}
                            >
                                <img
                                    src='./../img/beneficios/Icono_consultar_cita.png'
                                    className='img-fluid w-18 mr-1'
                                    alt='Icono_consultar_cita'
                                />
                                Consultar cita
                            </button>
                        </div>
                        <div className='col-12 m-1 d-flex justify-content-center'>
                            <button
                                className='btn btn-blue fs-875'
                                onClick={() => handleGetAppointmentByIdAgenda('modal_cancelar_cita')}
                            >
                                <img
                                    src='./../img/beneficios/Icono_cancelar_cita.png'
                                    className='img-fluid w-18 mr-1'
                                    alt='Icono_consultar_cita'
                                />
                                Cancelar cita
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-center justify-content-end justify-content-md-center'>
                    <img
                        src='./../img/beneficios/Imagen_guaycita_que_te_gustaria_hacer.png'
                        className='img-fluid w-40 '
                        alt='Imagen_dental_mobile_inverted'
                    />
                </div>
            </div>
            {/*<!-- The Modal -->*/}
            {/*<!-- The Cancel Modal -->*/}
            <div
                className="modal"
                id="modal_cancelar_cita"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                data-backdrop="false">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{
                            borderRadius: '1.5rem',
                            border: '1px solid gray',
                            overflow: 'auto'
                        }}>
                        
                        {/*<!-- Modal body -->*/}
                        {/* <div className="modal-body scrollbar-style" style={{backgroundImage:`url("../../img/inicio/general_back.jpg")`}}> */}
                        <div className="modal-body scrollbar-style" >
                            <div className="col-lg-12 mb-1">
                                <div className="row">
                                    <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mt-1 text-center">
                                        <div className="btn-close-3 d-flex justify-content-center flex-wrap align-content-center">
                                            <button
                                            type="button"
                                            className="close text-white"
                                            onClick={() => handleCloseModal('modal_cancelar_cita')}>&times;</button>
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center">
                                        <h1 className='text-blue-sec fs-1-563'>¿Estas seguro de cancelar la cita?</h1>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        <div className='card rounded-10px mx-2' style={{border: '1px solid #babfc7'}}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Fecha:</span> {date}</p>
                                                        <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Horario:</span> {hora}</p>
                                                        <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Motivo:</span> {motivo}</p>
                                                        {clinica !== '' && <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Clínica:</span> {clinica}</p>}
                                                        {doctor !== '' && <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Doctor:</span> {doctor}</p>}
                                                        {direccion !== '' && <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Dirección:</span> {direccion}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center mb-1">
                                        <h1 className={`${handleGetEstadoCitaColor(estadoCita)} fs-1-563`}>{handleGetEstadoCita(estadoCita)}</h1>
                                    </div>
                                    <div className="col-12 d-flex align-items-center flex-wrap justify-content-center mt-1">
                                        <button 
                                            className="btn btn-sm btn-blue-2 fs-1 w-40 mr-1 text-center font-weight-bold rounded-pill"
                                            onClick={() => handleCloseModal('modal_cancelar_cita')}
                                        >No</button>
                                        <button className="btn btn-sm btn-cancel fs-1 w-40 ml-1 font-weight-bold rounded-pill"
                                            onClick={() => handleCancelAppointmentByIdAgenda(idAppointment,estadoCita)}
                                        >Si</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- The Cancel Modal -->*/}
            {/*<!-- The Data Modal -->*/}
            <div
                className="modal"
                id="modal_consultar_cita"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                data-backdrop="false">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{
                            borderRadius: '1.5rem',
                            border: '1px solid gray',
                            overflow: 'auto'
                        }}>
                        
                        {/*<!-- Modal body -->*/}
                        {/* <div className="modal-body scrollbar-style" style={{backgroundImage:`url("../../img/inicio/general_back.jpg")`}}> */}
                        <div className="modal-body scrollbar-style" >
                            <div className="col-lg-12 mb-1">
                                <div className="row">
                                    <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mt-1 text-center">
                                        <div className="btn-close-3 d-flex justify-content-center flex-wrap align-content-center">
                                            <button
                                            type="button"
                                            className="close text-white"
                                            onClick={() => handleCloseModal('modal_consultar_cita')}>&times;</button>
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center">
                                        <h1 className='text-blue-sec fs-1-563'>Datos de Cita</h1>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        <div className='card rounded-10px mx-2' style={{border: '1px solid #babfc7'}}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Fecha:</span> {date}</p>
                                                        <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Horario:</span> {hora}</p>
                                                        <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Motivo:</span> {motivo}</p>
                                                        {clinica !== '' && <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Clínica:</span> {clinica}</p>}
                                                        {doctor !== '' && <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Doctor:</span> {doctor}</p>}
                                                        {direccion !== '' && <p className='font-weight-normal fs-1'><span className='font-weight-bold'>Dirección:</span> {direccion}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 text-center mb-1">
                                        <h1 className={`${handleGetEstadoCitaColor(estadoCita)} fs-1-563`}>{handleGetEstadoCita(estadoCita)}</h1>
                                    </div>
                                    <div className="col-12 d-flex align-items-center flex-wrap justify-content-center">
                                        <button className="btn btn-sm btn-cancel fs-1 w-75 font-weight-bold rounded-pill" onClick={() => handleCloseModal('modal_consultar_cita')}
                                        >Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- The Data Modal -->*/}

        </div>
    );
};

export default UtilizarBeneficio;