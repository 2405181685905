import React, { useState, useEffect } from 'react'
// API calls
import { getwebBrowsers } from '../app/Models/Catalog_model';
// Components
import ModalLogin from '../auth/ModalLogin';
// Tools
import '../css/guay-web/style.css';
import '../css/guay-web/responsive.css';
import { Modal } from 'react-bootstrap';
import Recaptcha from "react-google-invisible-recaptcha";
import * as toastr from 'toastr';
import Swal from "sweetalert2";
import $ from 'jquery';

var ReactRotatingText = require('react-rotating-text');

const Welcome = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        getwebBrowsers().then(response => {
            switch (response?.code) {
                case 1:
                    checkNavigator(response.listWebBrowsers);
                    break;
                default:
                    toastr.error("Ocurrió un problema al validar la versión de su navegador. Intente nuevamente más tarde.");
                    break;
            }
        });
        const checkNavigator = function (listWebBrowsers) {
            if (window.navigator.userAgentData) {
                let navigator = "";
                let version = "";
                window.navigator.userAgentData.brands.every(nav => {
                    if (nav.brand.match(/Edge|Chrome|Chromium/)) {
                        navigator = window.navigator.userAgentData.brands.at(-1).brand;
                        version = window.navigator.userAgentData.brands.at(-1).version;
                        return false;
                    }
                    return true;
                });
                if (navigator !== "") {
                    const match = listWebBrowsers.find(browser => browser.name === navigator);
                    if (match) {
                        const versionInt = parseInt(version);
                        const matchVersionInt = parseInt(match.version);
                        if (versionInt < matchVersionInt) {
                            Swal.fire({
                                title: '<p class="text-white mb-2">Actualiza o descarga Chrome</p>',
                                text: "Algunas características no podrían funcionar correctamente, te recomendamos actualizar a Google Chrome",
                                showCloseButton: true,
                                showCancelButton: true,
                                focusCancel: true,
                                cancelButtonText: 'Continuar',
                                showConfirmButton: true,
                                confirmButtonText: "Si, descargar",
                                reverseButtons: true,
                                dangerMode: true
                            }).then((willDelete) => {
                                if (willDelete.isConfirmed) {
                                    window.open("https://www.google.com.mx/chrome/");
                                }
                            });
                            $(".swal2-title").addClass("bg-header-swal");
                            $(".swal2-actions").addClass("w-100 justify-content-around");
                            $(".swal2-confirm").removeClass("swal2-styled");
                            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                            $(".swal2-cancel").removeClass("swal2-styled");
                            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                        }
                    }
                } else {
                    Swal.fire({
                        title: '<p class="text-white mb-2">Actualiza o descarga Chrome</p>',
                        text: "Algunas características no podrían funcionar correctamente, te recomendamos actualizar a Google Chrome",
                        showCloseButton: true,
                        showCancelButton: true,
                        focusCancel: true,
                        cancelButtonText: 'Continuar',
                        showConfirmButton: true,
                        confirmButtonText: "Si, descargar",
                        reverseButtons: true,
                        dangerMode: true
                    }).then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            window.open("https://www.google.com.mx/chrome/");
                        }
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
            } else {
                const navs = window.navigator.userAgent.split(" ");
                const M = navs.at(-1).match(/(chrome|safari|firefox|Edg|(?=\/))\/?\s*(\d+)/i) || [];
                const navigator = M[1];
                const version = M[2];
                if (navigator.match(/Firefox|Safari/)) {
                    const match = listWebBrowsers.find(browser => browser.name === navigator);
                    if (match) {
                        const versionInt = parseInt(version);
                        const matchVersionInt = parseInt(match.version);
                        if (versionInt < matchVersionInt) {
                            Swal.fire({
                                title: '<p class="text-white mb-2">Actualiza o descarga Chrome</p>',
                                text: "Algunas características no podrían funcionar correctamente, te recomendamos actualizar a Google Chrome",
                                showCloseButton: true,
                                showCancelButton: true,
                                focusCancel: true,
                                cancelButtonText: 'Continuar',
                                showConfirmButton: true,
                                confirmButtonText: "Si, descargar",
                                reverseButtons: true,
                                dangerMode: true
                            }).then((willDelete) => {
                                if (willDelete.isConfirmed) {
                                    window.open("https://www.google.com.mx/chrome/");
                                }
                            });
                            $(".swal2-title").addClass("bg-header-swal");
                            $(".swal2-actions").addClass("w-100 justify-content-around");
                            $(".swal2-confirm").removeClass("swal2-styled");
                            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                            $(".swal2-cancel").removeClass("swal2-styled");
                            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                        }
                    }
                } else {
                    Swal.fire({
                        title: '<p class="text-white mb-2">Actualiza o descarga Chrome</p>',
                        text: "Algunas características no podrían funcionar correctamente, te recomendamos actualizar a Google Chrome",
                        showCloseButton: true,
                        showCancelButton: true,
                        focusCancel: true,
                        cancelButtonText: 'Continuar',
                        showConfirmButton: true,
                        confirmButtonText: "Si, descargar",
                        reverseButtons: true,
                        dangerMode: true
                    }).then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            window.open("https://www.google.com.mx/chrome/");
                        }
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                }
            }
        }

    }, [])
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="modal-login"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body
                    className='custom-dialog'
                >
                    <ModalLogin />
                </Modal.Body>
            </Modal>
            <header className='col-12 bg-white with-box-shadow-2 mb-3'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center py-1'>
                        <img
                            src="../../img/logo_guay.png"
                            style={{ height: '43px' }}
                            alt="Guay logo"
                        />
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center py-1'>
                        <div className="box-btn">
                            <button
                                type="button"
                                className="thm-btn header__cta-btn"
                                onClick={handleShow}
                            ><span>Acceso</span></button>
                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div className="col-sm-12 col-md-10 col-lg-10 col-xl-8 mx-auto mb-5">
                    <div className='row'>
                        <span className="banner-one__shape-1"></span>
                        <span className="banner-one__shape-2"></span>
                        <span className="banner-one__shape-3"></span>
                        <span className="banner-one__shape-4 shape-hidde"></span>
                        <div className='col-sm-12 col-md-8 col-lg-6 col-xl-6 d-flex flex-wrap align-content-center'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <p className='font-large-1'>Construye el <span className='text-cian-giki font-weight-bold'>ambiente laboral</span> ideal para tu organización</p>
                                </div>
                                <div className='col-12 mb-3' style={{ height: '100px' }}>
                                    <h1>
                                        Servicios de: <span className="txt-rotate">
                                            <ReactRotatingText
                                                items={[" Endomarketing.", " Documentos normativos.", " Planes de acción.", " Asistencia emocional.", " Telemedicina."]}
                                                speed={1}
                                                period={2000}
                                            />
                                        </span>
                                    </h1></div>
                                <div className='col-12 mb-3'>
                                    <p className='font-medium-5 text-gray-giki'>Una plataforma con un potente <b>programa de bienestar</b> para cuidar la salud de personas y organizaciones.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-6 col-xl-6 d-flex justify-content-center'>
                            <img
                                src="../../img/img_fecha_landing.png"
                                className='img-landign-1'
                                style={{ opacity: '0' }}
                                alt="guay"
                            />
                            <img
                                src="../../img/img_fecha_landing.png"
                                className='animation-img-levitate img-landign-1'
                                alt="guay"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-blue-navy'>
                <div className='col-11 mx-auto mb-5'>
                    <div className='row'>
                        <div className='col-12 py-1 text-center'>
                            <h2 style={{ color: "#fff" }}>Conoce nuestros <b>servicios de Salud Laboral guay</b></h2>
                        </div>
                        <div className='col-12 py-1'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 text-center'>
                                    <h3 className='font-medium-3'><span style={{ color: "#fff" }}><b>ESTADO EMOCIONAL<br /><span style={{ color: "#92BC1C" }}><b>(Fase 1 NOM035)</b></span></b></span></h3>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 text-center'>
                                    <h3 className='font-medium-3'><span style={{ color: "#fff" }}><b>ENTORNO LABORAL<br /><span style={{ color: "#92BC1C" }}><b>(Fase 2 NOM035)</b></span></b></span></h3>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 text-center'>
                                    <h3 className='font-medium-3'><span style={{ color: "#fff" }}><b>CLIMA<br /><span style={{ color: "#92BC1C" }}><b>LABORAL</b></span></b></span></h3>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 text-center'>
                                    <h3 className='font-medium-3'><span style={{ color: "#fff" }}><b>CULTURA<br /><span style={{ color: "#92BC1C" }}><b>ORGANIZACIONAL</b></span></b></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='col-sm-12 col-md-10 col-lg-10 col-xl-9 mx-auto mx-auto my-5'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-7 col-xl-7 mb-3'>
                            <img
                                src="../../img/guay-website/mocs/cta-moc-1-3.png"
                                className='img-fluid'
                                style={{ opacity: '0' }}
                                alt="guay"
                            />
                            <img
                                src="../../img/guay-website/mocs/cta-moc-1-3.png"
                                className='img-fluid animation-img-levitate'
                                alt="Awesome-img"
                                style={{ left: '0' }}
                            />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 mx-auto d-flex flex-wrap align-content-center'>
                            <p className='font-medium-5 text-justify text-gray-giki' style={{ lineHeight: '1.5' }}>Una plataforma que te ayuda para que con unos cuantos clicks puedas <span style={{ color: "#92BC1C" }}><b>Sensibilizar</b></span>, <span style={{ color: "#92BC1C" }}><b>Identificar</b></span>, <span style={{ color: "#92BC1C" }}><b>Solucionar</b></span> y <span style={{ color: "#92BC1C" }}><b>Prevenir</b></span> riesgos de salud y bienestar en tu organización.</p>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className='col-11 mx-auto mb-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
                                    <img
                                        src="../../img/logo_guay.png"
                                        width="119"
                                        alt="logo"
                                        className="footer-widget__logo"
                                    />
                                    <p className="footer-widget__contact">
                                        <a
                                            className="p-footerApp pl-0 left"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf"
                                        >Políticas de Privacidad</a>
                                        <a
                                            className="pl-1"
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Terminos_%26_Condiciones_generales_Plataforma_guay.pdf"
                                        >Términos y Condiciones</a>
                                    </p>
                                    <p className="footer-widget__contact">Contactános: <a href="tel:5512490998"> 5512490998</a> - <a href="mailto:info@guay.digital">info@guay.digital</a></p>
                                    <p className="footer-widget__contact">Av. Montes Urales 755 Piso 5, 5007 Lomas de Chapultepec, Miguel Hidalgo, CDMX, CP 11000</p>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center flex-wrap align-content-center'>
                                    <img
                                        className='mx-1'
                                        style={{ width: '50px', height: '50px' }}
                                        src='../../img/guay-website/resources/iconos_redes-06.png'
                                        onClick={() => window.open("https://www.facebook.com/guay-107436667630740")}
                                        alt='redes-sociales'
                                    />
                                    <img
                                        className='mx-1'
                                        style={{ width: '50px', height: '50px' }}
                                        src='../../img/guay-website/resources/iconos_redes-10.png'
                                        onClick={() => window.open("https://www.linkedin.com/company/mesientoguay/")}
                                        alt='redes-sociales'
                                    />
                                    <img
                                        className='mx-1'
                                        style={{ width: '50px', height: '50px' }}
                                        src='../../img/guay-website/resources/iconos_redes-09.png'
                                        onClick={() => window.open("https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=")}
                                        alt='redes-sociales'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 bg-white' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <p className='text-center mb-0'>&copy; copyright 2021 Derechos reservados de Me Siento guay S A P I de CV</p>
                </div>
            </footer>
            <Recaptcha
                sitekey="6Lf2KjIbAAAAAK-K2Cwtz7UkGf_ENb5B0dmrHFlx"
                onResolved={() => console.log("Human detected.")}
            />
        </>
    )
}

export default Welcome