import React, { useEffect } from 'react';
import Iframe from 'react-iframe';
import { cred } from '../../../auth/authMicro';

export const EmbeddedMicro = ({ ligaMicro }) => {

    const { url, dossier } = ligaMicro;

    useEffect(() => {

        if (dossier) {
            const container = document.getElementById("dossierContainer1");
            var postData = {};
            postData.username = cred.user;
            postData.password = cred.pwd;
            postData.loginMode = 1;
            postData.maxSearch = 3;
            postData.workingSet = 10;
            postData.applicationType = 35;
            window.microstrategy.dossier.create({
                placeholder: container,
                url: url,
                enableCustomAuthentication: true,
                enableResponsive: true,
                containerWidth: '1000px',
                containerHeight: '400px',
                customAuthenticationType: window.microstrategy.dossier.CustomAuthenticationType.AUTH_TOKEN,
                getLoginToken: async () => {
                    return await getXHRRequestPromise('https://analytics.guay.digital/MicroStrategyLibrary/api/auth/login', postData, 'POST', 'application/json', 'x-mstr-authToken').then(function (authToken) {
                        return authToken;
                    })
                }
            }).then((dossier) => {
                window.dossier = dossier;

            });
            function getXHRRequestPromise(url, body, method, contentType, desiredHeader) {
                return new Promise(function (resolve, reject) {
                    var xhr = new XMLHttpRequest();
                    xhr.open(method, url);
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('Content-Type', contentType);
                    xhr.setRequestHeader("Accept", "application/json");
                    xhr.send(JSON.stringify(body));
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === 2) {
                            resolve(xhr.getResponseHeader(desiredHeader));
                        } else {
                            reject({
                                status: this.status,
                                statusText: xhr.statusText
                            });
                        }
                    };
                });
            };

        }

    }, [dossier, url])

    if (dossier) {
        return (
            <div
                id="dossierContainer1"
                style={{ width: '100%', height: '100%' }}
            ></div>
        )
    } else {
        return (
            <Iframe url={url}
                width="100%"
                height="100%"
                className="embed-responsive-item"
                position="absolute"
                display="initial"
                overflow="hidden"
                loading="lazy"
                allow="fullscreen"
                allowFullScreen="true"
            />

        )
    }
}
