import React, { useState, useEffect } from 'react';
// API calls
import { getSpecificTrackingByOther, setTrackingOfCollabByOther } from '../../Models/TrackingModel';
import { getJwt } from "../../../../lib/auth";
// Tools
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";

const ActMonCollabForm = () => {

    const [listStageCollab, setListStageCollab] = useState({});
    const [listStageCollab_2, setListStageCollab_2] = useState({});
    const {
        address,
        closingDate,
        collaboratorId,
        commentsService,
        descCanalization,
        institution,
        idAttention,
        idGroup,
        idPeriod,
        numberOfSessions,
        startDate
    } = listStageCollab;

    useEffect(() => {
        setTimeout(() => {
            if (sessionStorage.getItem('Ag')) {
                let asistenciaguayData = sessionStorage.getItem('Ag').split('-');
                getSpecificTrackingByOther(asistenciaguayData[0], asistenciaguayData[1], asistenciaguayData[2], asistenciaguayData[3]).then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                setListStageCollab(response.listStageCollab[0]);
                                setListStageCollab_2(response.listStageCollab[0]);
                                break;
                            case 1002:
                                break;
                            default:
                                toastr.error('Problemas con el servidor. Recargue la página nuevamente.', '¡Ooops!');
                                break;
                        }
                    } else {
                        toastr.error('Problemas con el servidor. Recargue la página nuevamente.', '¡Ooops!');
                    }
                });
            }
        }, 500)
    }, []);

    const handleForm = e => {
        let value = e.target.value;
        let paramaterName = e.target.name;
        switch (paramaterName) {
            case "idAttention":
                setListStageCollab({
                    ...listStageCollab,
                    [paramaterName]: parseInt(value)
                });
                break;
            default:
                setListStageCollab({
                    ...listStageCollab,
                    [paramaterName]: value
                });
                break;
        }
    }

    const handleSetForm = e => {
        e.preventDefault();
        if (listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1) {
            let data = {
                closingDate: idAttention === 0 || idAttention === -1 ? (listStageCollab_2.closingDate === closingDate ? ("") : (closingDate)) : (closingDate === "" ? (new Date()) : (closingDate)),
                startDate: listStageCollab_2.startDate === startDate ? ("") : (startDate),
                idAttention: listStageCollab_2.idAttention === idAttention ? (-1) : (idAttention),
                commentsService: listStageCollab_2.commentsService === commentsService ? ("") : (commentsService),
                sessions: listStageCollab_2.numberOfSessions === numberOfSessions ? (0) : (numberOfSessions),
                address: listStageCollab_2.address === address ? ("") : (address),
                curDate: new Date(),
                idPeriod: idPeriod,
                collaboratorId: collaboratorId,
                idGroup: idGroup,
                idEnterprise: getJwt("enterprise")
            }
            setTrackingOfCollabByOther(data.closingDate, data.startDate, data.idAttention, data.commentsService, data.sessions, data.address, data.curDate, data.idPeriod, data.collaboratorId, data.idGroup, data.idEnterprise).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            getSpecificTrackingByOther(getJwt("enterprise"), collaboratorId, idPeriod, idGroup).then((response) => {
                                if (response) {
                                    switch (response.code) {
                                        case 1:
                                            setListStageCollab(response.listStageCollab[0]);
                                            setListStageCollab_2(response.listStageCollab[0]);
                                            toastr.success('Se guardó correctamente los datos.', '¡Éxito!');
                                            break;
                                        case 1001:
                                            toastr.info('La empresa no está activa. Active la empresa e intente nuevamente.', '¡Ooops!');
                                            break;
                                        case 1002:
                                            toastr.info('No hay información del colaborador.', '¡Ooops!');
                                            break;
                                        default:
                                            toastr.error('Problemas con el servidor. Recargue la página nuevamente.', '¡Ooops!');
                                            break;
                                    }
                                } else {
                                    toastr.error('Problemas con el servidor. Recargue la página nuevamente.', '¡Ooops!');
                                }
                            });
                            break;
                        default:
                            toastr.error('Problemas con el servidor. Recargue la página nuevamente.', '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error('Problemas con el servidor. Recargue la página nuevamente.', '¡Ooops!');
                }
            });
        } else {
            toastr.info('El proceso ha finalizado.', '¡Ooops!')
        }
    }

    return (
        <div
            className="modal"
            id="modalFormActMon"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body modal-div-style-1 container-style-1 px-1">
                        <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                            <form onSubmit={handleSetForm}>
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-9 col-lg-9">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-base font-weight-bold m-0">Tipo de atención:</p>
                                                <p className="text-base-guay font-weight-bold">{descCanalization}</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="text-base font-weight-bold m-0">Tu organización recomendó:</p>
                                                <p className="text-blue font-weight-bold">{institution}</p>
                                            </div>
                                        </div>
                                        <span className="text-base">Intitución</span>
                                        <div className="input-group input-group-sm my-1">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ backgroundColor: 'transparent', borderLeft: '1px solid #cacfe7', borderTop: '1px solid #cacfe7', borderBottom: '1px solid #cacfe7', borderRight: 'none' }}><i className="ft-map-pin text-green" /></span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm border-left-0"
                                                id="address"
                                                name="address"
                                                value={address}
                                                onChange={handleForm}
                                                disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                                <label className="text-base">Intitución</label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="address"
                                                    name="address"
                                                    value={address}
                                                    onChange={handleForm}
                                                    disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)}
                                                />
                                            </div> */}
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="text-base">Estatus</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="idAttention"
                                                        name="idAttention"
                                                        value={idAttention}
                                                        onChange={handleForm}
                                                        disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)}
                                                    >
                                                        <option value="-1">Selecciona</option>
                                                        <option value="0">En proceso</option>
                                                        <option value="1">Rechazado</option>
                                                        <option value="2">Abandonado</option>
                                                        <option value="3">Finalizado</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col">
                                                {/* <div className="row">
                                                        <div className="col-9 pr-0"> */}
                                                <div className="form-group">
                                                    <label className="text-base">Fecha inicio</label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        id="startDate"
                                                        name="startDate"
                                                        value={startDate}
                                                        onChange={handleForm}
                                                        disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (listStageCollab_2.startDate === "" ? (false) : (true)) : (true)}
                                                    />
                                                </div>
                                                {/* </div>
                                                        <div className="col-3 d-flex flex-wrap align-content-center pr-0">
                                                            <img
                                                                className="w-100"
                                                                src="./../img/actions/actionsMonitoring/iconos_acciones-05.png"
                                                                alt="img-monitoring"
                                                            />
                                                        </div>
                                                    </div> */}
                                            </div>
                                            <div className="col">
                                                {/* <div className="row">
                                                        <div className="col-9 pr-0"> */}
                                                <div className="form-group">
                                                    <label className="text-base">Fecha fin</label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        id="closingDate"
                                                        name="closingDate"
                                                        value={closingDate}
                                                        onChange={handleForm}
                                                        disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)}
                                                    />
                                                </div>
                                                {/* </div>
                                                        <div className="col-3 d-flex flex-wrap align-content-center pr-0">
                                                            <img
                                                                className="w-100"
                                                                src="./../img/actions/actionsMonitoring/iconos_acciones-05.png"
                                                                alt="img-monitoring"
                                                            />
                                                        </div>
                                                    </div> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="text-base">Número de sesiones:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm w-25"
                                                        id="numberOfSessions"
                                                        name="numberOfSessions"
                                                        value={numberOfSessions}
                                                        onChange={handleForm}
                                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                        disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-base">Comentarios:</label>
                                            <textarea
                                                type="text"
                                                className="form-control form-control-sm textarea-unresize"
                                                rows="5"
                                                id="commentsService"
                                                name="commentsService"
                                                maxLength="200"
                                                style={{ borderRadius: '10px' }}
                                                value={commentsService}
                                                onChange={handleForm}
                                                disabled={listStageCollab_2.idAttention === 0 || listStageCollab_2.idAttention === -1 ? (false) : (true)}
                                            />
                                            {/* <p className="text-right">{commentsService === "" ? (0) : (commentsService.length)}/100</p> */}
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-center">
                                        <img
                                            className="w-100"
                                            src="./../img/actions/actionsMonitoring/iconos_acciones-11.png"
                                            alt="img-monitoring"
                                        />
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                        <button
                                            type="button"
                                            className="btn btn-blue-3 btn-sm w-25"
                                            onClick={() => $('#modalFormActMon').hide()}
                                        >Regresar</button>
                                        <button
                                            type="submit"
                                            className="btn btn-pink btn-sm float-right w-25"
                                        >Actualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActMonCollabForm;
