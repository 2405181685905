import React, { useEffect, useState } from 'react';
// API calls
import { getSectors, getSubSectors, getAddressByCP, getCountries } from "../Models/Catalog_model";
import { setRegisterShop } from '../Models/Shop_model';
// Tools
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";
import { validateFields } from '../Shop/Commercial/ApplicationStructure/Helper';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PropTypes from 'prop-types';

const ApplicationForm = ({ userID }) => {
    const history = useHistory();
    const [sectors, setSectors] = useState([]);
    const [subSectors, setSubSectors] = useState([]);
    const [countries, setCountries] = useState([]);
    /* const [fullName, setFullName] = useState({
        name: '',
        lastName: ''
    }); */
    const fullName = {
        name: '',
        lastName: ''
    };
    const [formData, setFormData] = useState({
        userId: userID.toString(),
        origin: 1,
        zipCode: '',
        enterpriseName: '',
        enterpriseShortName: '',
        applicaDate: new Date(),
        clientName: "",
        email: "",
        idCountries: "",
        numCollabs: "",
        phone: "",
        sectorId: "",
        stateId: 0,
        subSectorId: "",
        townId: 0,
    });
    const { zipCode, phone, numCollabs, subSectorId, sectorId, idCountries, email, enterpriseName, enterpriseShortName } = formData;
    useEffect(() => {
        getSectors().then(response => {
            setSectors(response);
        });
        getCountries().then(response => {
            let getCountries = [];
            for (let x in response) {
                getCountries.push(response[x]);
            }
            setCountries(getCountries);
        });
    }, []);
    /**
     * 
     * @param {any} e 
     */
    const handleOnChange = e => {
        $("#" + e.target.name).removeClass("border-danger-guay");
        switch (e.target.name) {
            case "phone":
                if (e.target.value.length < 11) {
                    setFormData({
                        ...formData,
                        [e.target.name]: e.target.value
                    });
                }
                break;
            case "numCollabs":
                if (e.target.value.length < 8) {
                    setFormData({
                        ...formData,
                        [e.target.name]: e.target.value
                    });
                }
                break;
            case "zipCode":
                if (e.target.value.length === 5) {
                    getAddressByCP(parseInt(e.target.value)).then(response => {
                        if (response.length > 0) {
                            $("#stateId, #townId").html("");
                            $("#stateId").append('<option value="' + response[0].idState + '">' + response[0].nameState + '</option>');
                            $("#townId").append('<option value="' + response[0].idTown + '">' + response[0].nameTown + '</option>');
                            $("#stateId, #townId").attr("disabled", false);
                            setFormData({
                                ...formData,
                                zipCode: response[0].zip.toString(),
                                stateId: response[0].idState,
                                townId: response[0].idTown
                            });
                        } else {
                            toastr.warning("El código postal no existe. Intenta nuevamente", '¡Ooops!');
                        }
                    });
                } else {
                    if (e.target.value.length < 6) {
                        $("#stateId, #townId").html("");
                        $("#stateId, #townId").append("<option>Seleccione</option>");
                        $("#stateId, #townId").attr("disabled", true);
                        setFormData({
                            ...formData,
                            zipCode: e.target.value,
                            stateId: 0,
                            townId: 0
                        });
                    }
                }
                break;
            case "sectorId":
                setFormData({
                    ...formData,
                    subSectorId: "",
                    [e.target.name]: e.target.value
                });
                getSubSectors(e.target.value).then(response => {
                    setSubSectors(response);
                });
                break;
            default:
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value
                });
                break;
        }
    }
    /**
     * Func that send application
     * @param {*} e 
     */
    const handleOnSubmit = e => {
        e.preventDefault();
        if (validateFields(formData, fullName) === 1) {
            let data = {
                ...formData,
                sectorId: parseInt(sectorId),
                subSectorId: parseInt(subSectorId),
                numCollabs: parseInt(numCollabs),
                idCountries: parseInt(idCountries),
                zipCode: parseInt(zipCode),
                clientName: fullName.name + " " + fullName.lastName,
            }
            setRegisterShop(data).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                        case 200:
                            Swal.fire({
                                title: '<p class="text-white mb-2">Registro éxitoso</p>',
                                text: "Te haremos llegar un correo con los detalles de tu solicitud. Mientras tanto dale seguimiento ingresando al portal en la sección tienda.",
                                showCloseButton: true,
                                showConfirmButton: true,
                                confirmButtonText: "Aceptar",
                                reverseButtons: true,
                                dangerMode: true
                            }).then((willDelete) => {
                                history.push("/");
                            });
                            $(".swal2-title").addClass("bg-header-swal");
                            $(".swal2-actions").addClass("w-100 justify-content-around");
                            $(".swal2-confirm").removeClass("swal2-styled");
                            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                            break;
                        default:
                            toastr.error("Problemas en el envío. Solicitar ayuda técnica.", '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error("Problemas al comunicarse con el servidor. Intentar de nuevo más tarde.", '¡Ooops!');
                }
            })
        }
    }
    return (
        <div className="col-sx-10 col-sm-10 col-md-8 col-lg-8 mx-auto pt-2">
            <h2 className="text-center">Datos Organización</h2>
            <div className="card">
                <div className="card-body">
                    <form
                        onSubmit={handleOnSubmit}
                        autocomplete="off"
                    >
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Nombre Organización</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Nombre Organización"
                                        id="enterpriseName"
                                        name="enterpriseName"
                                        maxLength="150"
                                        value={enterpriseName}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Nombre Corto</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Nombre Corto"
                                        id="enterpriseShortName"
                                        name="enterpriseShortName"
                                        maxLength="150"
                                        value={enterpriseShortName}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>
                                        Correo <Popup trigger={<i className="ft-help-circle icon-guay rounded-circle" />} position="right">
                                            <div className="p-1 text-center font-small-3">
                                                Este correo será el punto de contacto que tenga guay con la organización, también ahí llegarán los mensajes que se ingresen al Canal de Denuncia en el caso de utilizar el que guay te ofrece.
                                            </div>
                                        </Popup>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control form-control-sm"
                                        placeholder="Correo"
                                        id="email"
                                        name="email"
                                        maxLength="150"
                                        value={email}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Teléfono</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        placeholder="Teléfono (10 digitos)"
                                        id="phone"
                                        name="phone"
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                        onChange={handleOnChange}
                                        value={phone}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Código Postal</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        placeholder="Código Postal"
                                        id="zipCode"
                                        name="zipCode"
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                        onChange={handleOnChange}
                                        value={zipCode}
                                        autocomplete="ÑÖcompletes"
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>País</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="idCountries"
                                        name="idCountries"
                                        onChange={handleOnChange}
                                        value={idCountries}
                                    >
                                        <option value="">Seleccionar</option>
                                        {
                                            countries.map(data => (
                                                <option
                                                    key={data.id}
                                                    value={data.id}
                                                >{data.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Estado</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="stateId"
                                        name="stateId"
                                        disabled
                                    >
                                        <option value="">Seleccionar</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Ciudad</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="townId"
                                        name="townId"
                                        disabled
                                    >
                                        <option value="">Seleccionar</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Sector</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="sectorId"
                                        name="sectorId"
                                        onChange={handleOnChange}
                                        value={sectorId}
                                    >
                                        <option value="">Seleccionar</option>
                                        {
                                            sectors.map(data => (
                                                <option
                                                    key={data.sector_id}
                                                    value={data.sector_id}
                                                >{data.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>Subsector</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="subSectorId"
                                        name="subSectorId"
                                        onChange={handleOnChange}
                                        value={subSectorId}
                                    >
                                        <option value="">Seleccionar</option>
                                        {
                                            subSectors.map(data => (
                                                <option
                                                    key={data.sub_sector_id}
                                                    value={data.sub_sector_id}
                                                >{data.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Número de colaboradores</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        placeholder="Número de colaboradores"
                                        id="numCollabs"
                                        name="numCollabs"
                                        onChange={handleOnChange}
                                        value={numCollabs}
                                    />
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-sm btn-pink float-right"
                        >
                            Solicitar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

ApplicationForm.propTypes = {
    userID: PropTypes.number.isRequired
}

export default ApplicationForm;
