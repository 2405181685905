import React, { useState } from 'react';
// Components
import ModalToken from './ModalToken';
import RegisterForm from './RegisterForm';
import ApplicationForm from './ApplicationForm';

const RegisterFreemium = () => {
    const [showComponent, setShowComponent] = useState(1);
    const [userID, setUserID] = useState(0);
    return (
        <div className="app-content content">
            <div className="content-overlay">
                <div className="content-wrapper">
                    <div className="content-header"></div>
                    <div className="content-body">
                        {
                            showComponent === 1 ? (
                                <RegisterForm
                                    setUserID={setUserID}
                                />
                            ) : (
                                <ApplicationForm
                                    userID={userID}
                                />
                            )
                        }
                        <ModalToken
                            userID={userID}
                            setShowComponent={setShowComponent}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterFreemium;
