import React from 'react';
import { useState, useEffect } from 'react';
import {getGroup,getGraphics,getBranch,downData} from './ModelMonitor';
import ColaboradoresGraphics from './ColaboradoresGraphics';
import ColaboradoresBar from './ColaboradoresBar';
import Dispositivos from './Dispositivos';
import Areas from './AreasCargos';
import toastr from 'toastr';
import { getJwt } from '../../../../lib/auth';
import { LoaderView } from '../../../../lib/utils/Loader';
import $ from "jquery";
import { getuserData } from '../../NewHome/Model';
import { ThreeCircles } from 'react-loader-spinner'

const Monitoreo = ({ step_1 }) =>{   
    const [Graphics1,setGraphics1]=useState([]); 
    const [Graphics2,setGraphics2]=useState([]);
    const [Graphics3,setGraphics3]=useState([]);   
    const [Graphics4,setGraphics4]=useState([]);
    const [DataTable,setDataTable]=useState([]);  
    const [ListArea,setListArea]=useState([]);
    const [ListCargos,setListCargos]=useState([]);   
    const [DataGraphic,setDataGraphic]=useState([]);       
    const [Filtro,getFiltro]=useState([]);
    const [Enterprises,getEnterprise]=useState([]);
    const [Centros,getCentros]=useState([]);
    const [Monitor,setMonitor]=useState({
        enterpriseId: 0,
        group:0,
        branch:0      
    });
    const [showTable,setShowTable]=useState(false);
    const [optionGroup, setoptionGroup] = useState(0);
    const [downloadData, setDownloadData] = useState(false)
    const [showAllOption, setShowAllOption] = useState(false)
    const [organizacionId, setOrganizacionId] = useState(0)

    useEffect(()=>{    
        setShowTable(true);
        getGroup(getJwt('enterprise')).then((response)=>{                 
            if(response){   
                setShowTable(true);
                setMonitor({
                    group:response.groupId,
                    branch:0,
                    enterpriseId: getJwt('enterprise'),
                    });
                    $("#organizacion").val(getJwt('enterprise'));
                    getGraphics(getJwt('enterprise'),response.groupId,0).then((response)=>{
                        if(response.totalCollab>0){
                            
                            setDataGraphic(response);
                            setGraphics1(response.graph1[0]);
                            setGraphics2(response.graph2[0]);
                            setGraphics3(response.graph3[0]);
                            setGraphics4(response.graph4[0]);    
                            setDataTable(response.table); 
                            setListArea(response.areas);
                            setListCargos(response.cargos);
                            
                        }else{
                            toastr.info('Hubo un problema. No se encontraron datos.', '¡Ooops!');
                        }
                        setShowTable(false);  
                    }); 
                    getFiltro(response);   
                const enterprises = response.enterprises
                getuserData(getJwt('jtw')).then(response => {
                    const getEnts = enterprises.map(data => data.enterpriseId)
                    const getGeneralEnts = response.enterprise.filter(data => getEnts.indexOf(data.enterpriseId) !== -1)
                    const getEnterprises = getGeneralEnts.filter(data => data.rolEnterpriseId === 2 || data.rolEnterpriseId === 3 || data.rolEnterpriseId >= 5)
                    const getFormateEnts = getEnterprises.map(data => {
                        return { enterprise: data.name, enterpriseId: data.enterpriseId }
                    })
                    //console.log(getFormateEnts)
                    const totalEnts = enterprises.length
                    const totalEnt = getFormateEnts.length
                    if (totalEnts === totalEnt) {
                        setShowAllOption(true)
                    }
                    getEnterprise(getFormateEnts)
                })
                    getBranch(getJwt('enterprise')).then((response)=>{                 
                        if(response.branches){                     
                            getCentros(response.branches); 
                                           
                        }else{
                            toastr.info('Hubo un problema. No se encontraron centros de trabajo.', '¡Ooops!');
                            getCentros("");
                        }
                    });
                    response.groupId===0 ? setoptionGroup(0) : setoptionGroup(1)
                    setOrganizacionId(getJwt('enterprise'))
                    $("#organizacion").val(getJwt('enterprise'));                
                  

            }else{
                toastr.error('Hubo un problema. No se encontraron datos.', '¡Ooops!');
                setShowTable(false);
            }
        });    

        

               
    },[]);   
    
    const ChangeDatas = e => {  
        setShowTable(true);
             
        let nameSelect=e.target.name;
        
        if(nameSelect==="organizacion"){
            setOrganizacionId(e.target.value)
            if(e.target.value > 0){
                getBranch(e.target.value).then((response)=>{                 
                    if(response.branches){                     
                        getCentros(response.branches); 
                                       
                    }else{
                        toastr.info('Hubo un problema. No se encontraron centros de trabajo.', '¡Ooops!');
                        getCentros("");
                    }
                });
            }else{
                getCentros("");
            }            

            Monitor.enterpriseId=e.target.value;
            if(Monitor.enterpriseId===0){
                Monitor.enterpriseId=getJwt('enterprise');
            }else{
                $("option[value=0]").text('Todos');
            }
            
            //$('option[value=0]').prop('selected', true);
            Monitor.branch=0;

        }else{
            Monitor.branch=e.target.value;
            
        }
        getGraphics(Monitor.enterpriseId,Monitor.group,Monitor.branch).then((response)=>{
            
            if(response.totalCollab>0){

                setDataGraphic(response);
                setGraphics1(response.graph1[0]);
                setGraphics2(response.graph2[0]);
                setGraphics3(response.graph3[0]);
                setGraphics4(response.graph4[0]);    
                setDataTable(response.table); 
                setListArea(response.areas); 
                setListCargos(response.cargos);
                setShowTable(false);
            }else{
                setShowTable(false);
               toastr.info('No se encontraron datos.', '¡Ooops!');
                const grafica0={
                    totalCollab: 0,
                    totalRegister: 0,
                    PerRegisterWeb: 0,
                    totalRegisterCel: 0,
                    PercentRegister: 0,
                    totalRegisterWeb: 0,     
                };
                const area=[{area:"",Total:0}];
                const grap4=[{serie:"",label:0}];
                setDataGraphic(grafica0);
                setGraphics1(grap4);
                setGraphics2(grap4);
                setGraphics3(grap4);
                setGraphics4(grap4);    
                setDataTable("");
                setListArea(area);
            }
        });       
       
        
    }   
    const downloaddata = () => {
        setDownloadData(true)
        downData(Monitor.enterpriseId,Monitor.group,Monitor.branch).then((response) => {
            console.log(response)
            switch (response.code) {
                case 1:
                    window.open(response.urlFile);
                    setDownloadData(false)
                    break;               
                default:
                    toastr.error("Lo sentimos no se pudo generar el reporte", "¡Ooops!");
                    setDownloadData(false)
                    break;
            }
        });
      };
     
         
    return(
        <div className="col-12 bg-white rounded mb-5 ">
            <section className="mb-1 ml-1 mt-1">
                <div className="card-header">
                    <h4 className="card-title text-center">Usuarios registrados en la plataforma</h4>                                
                </div>
                <div className="card-content collapse show">
                    <div className="card-body">
                        <div className='row justify-content-left'>
                            <div className="col-md-1 d-flex flex-wrap align-content-center " >                         
                                <label className="text-gray text-right ml-3"> Grupo:</label>
                                                    
                            </div>
                            <div className="col-md-2 d-flex flex-wrap align-content-center">
                                <label className="text-right"> {Filtro.group} </label>
                            </div>   
                            <div className="col-md-2 d-flex flex-wrap align-content-center" >                         
                                <label className="text-gray text-right"> Organizacion</label>
                                                    
                            </div>
                            <div className="col-md-2 d-flex flex-wrap align-content-center">
                                <select                                                
                                    className="form-control form-control-sm"
                                    id="organizacion"
                                    name="organizacion"
                                    onChange={ChangeDatas}
                                    value={organizacionId}
                                    >
                                    {
                                        optionGroup === 1 && showAllOption ? (
                                            <option value="0" key="0">Todos</option>
                                        ) : ('')
                                    }
                                    
                                    {   
                                        Enterprises.length>0?
                                        Enterprises.map(data=>(
                                            <option key={parseInt(data.enterpriseId)} value={parseInt(data.enterpriseId)}>{data.enterprise} </option>
                                        )) : ""
                                    }
                                                            
                                </select>  
                            </div>   
                            <div className="col-md-2  d-flex flex-wrap align-content-center " >                         
                                <label className="text-gray text-right"> Centros de trabajo</label>
                                                    
                            </div>
                            <div className="col-md-2 d-flex flex-wrap align-content-center">
                                <select                                                
                                    className="form-control form-control-sm"
                                    id="branch"
                                    name="branch"
                                    onChange={ChangeDatas}>
                                    
                                    {
                                        optionGroup === 1 ? (
                                            <option value="0" key="0">Seleccione</option>
                                        ) : (<option value="0" key="0">Todos</option>)
                                    }
                                    {                                                                                                   
                                        Centros.length>0?
                                        Centros.map(data=>(
                                            <option key={parseInt(data.branchEntId)} value={parseInt(data.branchEntId)}>{data.description} </option>
                                        )) : ""
                                    }                      
                                </select>  
                            </div>                                                                
                            
            
                        </div>
                        <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-12">
                                            {showTable ?
                                                <LoaderView />
                                                : ""}
                                        </div>
                       </div>
                    <div> 
                    {showTable===false && ( 
                        <div>                         
                        <div className="row ">  
                            <div className='col-xl-12 col-lg-12 col-12 mt-2 text-right'>
                                            {
                                                downloadData ? (
                                                    <div className='row d-flex justify-content-end'>
                                                        <div className='pr-1'>
                                                            <span>Descargando los datos</span>
                                                        </div>
                                                        <div className='pr-1'>
                                                            <ThreeCircles
                                                                height="20"
                                                                width="20"
                                                                visible={true}
                                                                ariaLabel="three-circles-rotating"
                                                                outerCircleColor="#2188b4"
                                                                innerCircleColor="#9cb537"
                                                                middleCircleColor="#af2f72"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                        <span
                                                            className="fake-select pointer"
                                                            onClick={() => downloaddata()}
                                                        >Descarga los datos
                                                            <img
                                                                alt="flecha"
                                                                src='/img/MonitoreoMailGun/descarga.png'
                                                                style={{ width: '2%' }}
                                                                className='ml-1'
                                                            />
                                                        </span>
                                                    )
                                            }
                            </div>                                
                            <div className="col-xl-12 col-lg-12 col-12 mt-2">
                                <div className="card" style={{backgroundColor:'#e8edf7', borderRadius: '20px', border: '0px'}}>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <ColaboradoresGraphics
                                                Graphics1={Graphics1}                                                
                                                DataGraphic={DataGraphic}
                                                step_1={step_1}
                                                organizacionId={organizacionId}
                                                groupId={Filtro.groupId}
                                                branchId={parseInt(Monitor.branch)}
                                            >
                                            </ColaboradoresGraphics>
                                            <ColaboradoresBar
                                               
                                                Graphics2={Graphics2}
                                            >
                                            </ColaboradoresBar>
                                           
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                        <div className="row mt-2">                  
                            <div className="col-xl-12 col-lg-12 col-12 ">
                                <h4 className="text-center ml-2">Usuarios registrados en la plataforma</h4> 
                                <Dispositivos                                   
                                    Graphics3={Graphics3}
                                    DataGraphic={DataGraphic}
                                    DataTable={DataTable}
                                >

                                </Dispositivos>

                            </div>
                            
                        </div>
                            
                    
                    <div className='row justify-content-center'>
                        
                        <div className="col-xl-12 col-lg-12 col-12 ">
                            <h4 className="text-center ml-2">Usuarios registrados por área y cargo</h4> 
                                <Areas                                   
                                    Graphics4={Graphics4}
                                    DataGraphic={DataGraphic}
                                    ListArea={ListArea}
                                    ListCargos={ListCargos}
                                >

                                </Areas>

                        </div>
                        
                    </div>
                    </div>

                    )}

                    </div>
                   
                   
                    </div> 
                </div>
            </section>
            
                        
                                              
                       
        </div>
        
    );
        
}
export default Monitoreo;
