import React, { useEffect } from "react";
import $ from 'jquery';
import { getJwt } from "../../lib/auth";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { logoutHook } from "./LogOut";
import { CollabAdminMenu } from "./CollabAdminMenu";
import CollabMenu from "./CollabMenu";

export const MenuHover = () => {
    const history = useHistory();
  const validateUrl = () => {
    try {
      let url = window.location.pathname;
      let res = url.split("/");
      let value = res[1];
      switch (value) {
        case "eco-rs":
          $("." + value).addClass("open active");
          break;
        case "monitoreo":
          $("." + value).addClass("open active");
          break;
        default:
          $("." + value).addClass("active");
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    validateUrl();
  }, []);

  const goToByLink = (link) => {
    if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
      Swal.fire({
        title: '<p class="text-white mb-2">¡Aún no has terminado!</p>',
        text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
        showCloseButton: true,
        showCancelButton: true,
        focusCancel: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: "Si, salir",
        reverseButtons: true,
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          sessionStorage.removeItem("inLaunch");
          sessionStorage.removeItem("isEdition");
          sessionStorage.removeItem("idProduct");
          sessionStorage.removeItem("idCategory");
          sessionStorage.removeItem("idSubcategory");
          sessionStorage.removeItem("nameCategory");
          sessionStorage.removeItem("nameSubcategory");
          sessionStorage.removeItem("idTypeLaunch");
          sessionStorage.removeItem("descriptionLaunch");
          sessionStorage.removeItem("flagLaunch");
          sessionStorage.removeItem("launchDate");
          sessionStorage.removeItem("idGroup");
          sessionStorage.removeItem("numCollabSelected");
          history.push(link);
        }
      });
      $(".swal2-title").addClass("bg-header-swal");
      $(".swal2-actions").addClass("w-100 justify-content-around");
      $(".swal2-confirm").removeClass("swal2-styled");
      $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
      $(".swal2-cancel").removeClass("swal2-styled");
      $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    } else {
      switch (link) {
        case '/inicio-administrador':
          history.push('/inicio-administrador');
          break;
        case '/inicio-colaborador':
          if (getJwt('rol') === '1') {
            history.push("/inicio-usuario");
          } else {
            history.push("/inicio-colaborador");
          }
          break;
        case '/perfil':
          history.push('/perfil');
          break;
        case '/inicio-empresas':
          history.push('/inicio-empresas');
          break;
        case '/tienda':
          history.push('/tienda');
          break;
        default:
          break;
      }
    }
  }

  $("#logout").on("click", function () {
    logoutHook().then(result => {
      if (result === true) {
        history.push("/");
      }
    });
  });

  const returnComponentMenu = () => {

    const rol = getJwt("rol");

    switch (rol) {
      case '1':
        return <CollabMenu goToByLink={goToByLink} rol={rol} />;
      case '2':
        return <CollabAdminMenu rol={2} goToByLink={goToByLink} />;
      case '3':
        return <CollabAdminMenu rol={3} goToByLink={goToByLink} />;
      case '4':
        return <CollabMenu goToByLink={goToByLink} rol={rol} />;
      case '5':
        return <CollabAdminMenu rol={2} goToByLink={goToByLink} />;
      case '6':
        return <CollabAdminMenu rol={2} goToByLink={goToByLink} />;
      default:
        break;
    }
  }
  return (
    <div className="dropdown-hover-content" >
        <ul className="navigation list-style-none">
          {
            returnComponentMenu()
          }
          {
            getJwt('rol') === '2' ||
              getJwt('rol') === '3' ||
              getJwt('rol') === '5' ? (
              <li className=" nav-item">
                <a 
                  onClick={(e) => {
                  e.preventDefault()
                  window.open(global.urlSupport)
                  }}
                  href={global.urlSupport} target="_blank" rel="noreferrer">
                  <span
                    className="menu-title"
                    data-i18n="nav.dash.main"
                    id="support"
                    onClick={() => window.open(global.urlSupport)}
                  >Ayuda y soporte</span>
                </a>
              </li>
            ) : (null)
          }
          <li className=" nav-item">
            <a href="/" onClick={(e) => e.preventDefault()}>
              <span
                className="menu-title"
                data-i18n="nav.dash.main"
                id="logout"
              >Cerrar sesión</span>
            </a>
          </li>
        </ul>
    </div>
  )
}
