import React, { useState, useEffect } from 'react';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import Swal from "sweetalert2";
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
// API calls
import { setDeleteFileComplaintConfig, getComplaintConfig, deleteUploadDocument } from '../Models/Enterprise_model';
import { getJwt } from "../../../lib/auth";
import { getReportChannel } from '../NewHome/Model';
// Components
import ImagesViewer from '../ImagesViewer';
import ModalViewPdf from './ModalViewPdf';

const RegisterComplainChannel = ({ channelData, setChannelData, selectedFiles, setSelectedFiles, uploadFiles, showFiles, setShowFiles, short, showFiles_2, setShowFiles_2, idEnterprise }) => {
    // Global variables
    const [showChannel, setShowChannel] = useState(true);
    const [complaintChecked1, setComplaintChecked1] = useState(false);
    const [complaintChecked2, setComplaintChecked2] = useState(false);
    const [showImage, setShowImage] = useState("");
    const [urlShowPdf, setUrlShowPdf] = useState("")
    const GreenRadio = withStyles({
        root: {
            color: '#15253c',
            '&$checked': {
                color: '#9cb537',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);
    useEffect(() => {
        if (channelData.complaintFlg === 1) {
            setComplaintChecked1(true);
        } else {
            setComplaintChecked2(true);
            setShowChannel(false);
        }
        // eslint-disable-next-line
    }, []);
    /**
     * 
     * @param {*} e 
     */
    const handleComplainFlag = e => {
        if (e.target.value === "1") {
            let data = {
                ...channelData,
                idUser: getJwt("jtw"),
                complaintFlg: 1,
                presentationMsg: "",
                url: "",
                isUpdate: 0
            };
            setComplaintChecked1(true);
            setComplaintChecked2(false);
            setShowChannel(true);
            setChannelData(data);
        } else {
            setComplaintChecked1(false);
            setComplaintChecked2(true);
            setShowChannel(false);
            setChannelData({
                ...channelData,
                [e.target.name]: e.target.value
            });
        }
    }
    /**
     * 
     * @param {*} e 
     */
    const handleOnChange = e => {
        e.preventDefault();
        setChannelData({
            ...channelData,
            [e.target.name]: e.target.value
        });
    }
    /**
     * 
     */
    const renderFileEvidence = () => {
        $("#fileEvidence").click();
    }
    const renderFileEvidencePDF = () => {
        $("#fileEvidencePDF").click();
    }
    /**
     * 
     * @param {*} e 
     */
    const uploadEvidence = e => {
        if (showFiles.length > 0) {
            if (showFiles.length + e.target.files.length > 5) {
                toastr.error('Ya tienes archivos cargados. Solo se permite subir 5 archivos. Intenta nuevamente.', '¡Ooops!');
            } else {
                if (e.target.files.length <= 5) {
                    let arraySizes = [];
                    let correctSize = true;
                    if (e.target.files.length === 5) {
                        arraySizes.push(e.target.files[4].size, e.target.files[3].size, e.target.files[2].size, e.target.files[1].size, e.target.files[0].size);
                    }
                    if (e.target.files.length === 4) {
                        arraySizes.push(e.target.files[3].size, e.target.files[2].size, e.target.files[1].size, e.target.files[0].size);
                    }
                    if (e.target.files.length === 3) {
                        arraySizes.push(e.target.files[2].size, e.target.files[1].size, e.target.files[0].size);
                    }
                    if (e.target.files.length === 2) {
                        arraySizes.push(e.target.files[1].size, e.target.files[0].size);
                    }
                    if (e.target.files.length === 1) {
                        arraySizes.push(e.target.files[0].size);
                    }
                    for (let x in arraySizes) {
                        if (arraySizes[x] > 5000000) {
                            correctSize = false;
                        }
                    }
                    if (correctSize === true) {
                        let fileTypes = [
                            "image/png",
                            "image/jpg",
                            "image/jpeg",
                            "image/tiff"
                        ];
                        let arrayType = [];
                        let correctType = 0;
                        if (e.target.files.length === 5) {
                            arrayType.push(e.target.files[4].type, e.target.files[3].type, e.target.files[2].type, e.target.files[1].type, e.target.files[0].type);
                        }
                        if (e.target.files.length === 4) {
                            arrayType.push(e.target.files[3].type, e.target.files[2].type, e.target.files[1].type, e.target.files[0].type);
                        }
                        if (e.target.files.length === 3) {
                            arrayType.push(e.target.files[2].type, e.target.files[1].type, e.target.files[0].type);
                        }
                        if (e.target.files.length === 2) {
                            arrayType.push(e.target.files[1].type, e.target.files[0].type);
                        }
                        if (e.target.files.length === 1) {
                            arrayType.push(e.target.files[0].type);
                        }
                        for (let x in arrayType) {
                            for (let y in fileTypes) {
                                if (arrayType[x] === fileTypes[y]) {
                                    correctType = correctType + 1;
                                }
                            }
                        }
                        if (correctType === e.target.files.length) {
                            setSelectedFiles(e.target.files);
                            uploadFiles(e.target.files, 0);
                        } else {
                            toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!');
                        }
                    } else {
                        toastr.error('Un archivo supera los 5MB. Intenta nuevamente.', '¡Ooops!');
                    }
                } else {
                    toastr.error('Solo se permite subir 5 archivos. Intenta nuevamente.', '¡Ooops!');
                }
            }
        } else {
            if (e.target.files.length <= 5) {
                let arraySizes = [];
                let correctSize = true;
                if (e.target.files.length === 5) {
                    arraySizes.push(e.target.files[4].size, e.target.files[3].size, e.target.files[2].size, e.target.files[1].size, e.target.files[0].size);
                }
                if (e.target.files.length === 4) {
                    arraySizes.push(e.target.files[3].size, e.target.files[2].size, e.target.files[1].size, e.target.files[0].size);
                }
                if (e.target.files.length === 3) {
                    arraySizes.push(e.target.files[2].size, e.target.files[1].size, e.target.files[0].size);
                }
                if (e.target.files.length === 2) {
                    arraySizes.push(e.target.files[1].size, e.target.files[0].size);
                }
                if (e.target.files.length === 1) {
                    arraySizes.push(e.target.files[0].size);
                }
                for (let x in arraySizes) {
                    if (arraySizes[x] > 5000000) {
                        correctSize = false;
                    }
                }
                if (correctSize === true) {
                    let fileTypes = [
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                        "image/tiff"
                    ];
                    let arrayType = [];
                    let correctType = 0;
                    if (e.target.files.length === 5) {
                        arrayType.push(e.target.files[4].type, e.target.files[3].type, e.target.files[2].type, e.target.files[1].type, e.target.files[0].type);
                    }
                    if (e.target.files.length === 4) {
                        arrayType.push(e.target.files[3].type, e.target.files[2].type, e.target.files[1].type, e.target.files[0].type);
                    }
                    if (e.target.files.length === 3) {
                        arrayType.push(e.target.files[2].type, e.target.files[1].type, e.target.files[0].type);
                    }
                    if (e.target.files.length === 2) {
                        arrayType.push(e.target.files[1].type, e.target.files[0].type);
                    }
                    if (e.target.files.length === 1) {
                        arrayType.push(e.target.files[0].type);
                    }
                    for (let x in arrayType) {
                        for (let y in fileTypes) {
                            if (arrayType[x] === fileTypes[y]) {
                                correctType = correctType + 1;
                            }
                        }
                    }
                    if (correctType === e.target.files.length) {
                        setSelectedFiles(e.target.files);
                        uploadFiles(e.target.files, 0);
                    } else {
                        toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!');
                    }
                } else {
                    toastr.error('Un archivo supera los 5MB. Intenta nuevamente.', '¡Ooops!');
                }
            } else {
                toastr.error('Solo se permite subir 5 archivos. Intenta nuevamente.', '¡Ooops!');
            }
        }
    }

    /**
     * 
     * @param {*} id 
     * @param {*} idFile 
     */
    const deleteFile = (id, idFile) => {
        Swal.fire({
            title: '<p class="text-white mb-2">Eliminar imagen</p>',
            text: "¿Deseas eliminar la imagen seleccionada?",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Si, eliminar",
            reverseButtons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                setDeleteFileComplaintConfig(id, idFile).then((response) => {
                    if (response) {
                        if (sessionStorage.getItem('newIDEnterprise')) {
                            switch (response.code) {
                                case 1:
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                case 200:
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                default:
                                    toastr.error('No se eliminó correctamente.', '¡Ooops!');
                                    break;
                            }
                        } else {
                            switch (response.code) {
                                case 1:
                                    getComplaintConfig(channelData.idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                case 200:
                                    getComplaintConfig(channelData.idEnterprise).then((response) => {
                                        setShowFiles(response.configImgComplaint);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                default:
                                    toastr.error('No se eliminó correctamente.', '¡Ooops!');
                                    break;
                            }
                        }
                    }
                });
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }
    const deleteDocumenPDF = id => {
        Swal.fire({
            title: '<p class="text-white mb-2">Eliminar imagen</p>',
            text: "¿Deseas eliminar el documento seleccionada?",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Si, eliminar",
            reverseButtons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                deleteUploadDocument(id).then((response) => {
                    if (response) {
                        if (sessionStorage.getItem('newIDEnterprise')) {
                            switch (response.code) {
                                case 1:
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles_2(response.listUploadedDoc);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                case 200:
                                    getComplaintConfig(sessionStorage.getItem('newIDEnterprise')).then((response) => {
                                        setShowFiles_2(response.listUploadedDoc);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                default:
                                    toastr.error('No se eliminó correctamente.', '¡Ooops!');
                                    break;
                            }
                        } else {
                            switch (response.code) {
                                case 1:
                                    getComplaintConfig(channelData.idEnterprise).then((response) => {
                                        setShowFiles_2(response.listUploadedDoc);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                case 200:
                                    getComplaintConfig(channelData.idEnterprise).then((response) => {
                                        setShowFiles_2(response.listUploadedDoc);
                                        toastr.success('Se eliminó correctamente el archivo.', '¡Éxito!');
                                    });
                                    break;
                                default:
                                    toastr.error('No se eliminó correctamente.', '¡Ooops!');
                                    break;
                            }
                        }
                    }
                });
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }
    /**
     * 
     * @param {*} url 
     */
    const previewFile = (url) => {
        setShowImage(url);
        $('#modalImagesViewer').show()
    }
    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        // console.log('ext: ', ext);
        if (ext === 'pdf') {
            setUrlShowPdf(url);
            $('#closeModalViewPdf').show()
        }else{
            window.open(url);
        }
    }
    /**
     * 
     * @param {*} url 
     */
    const showChannelguay = () => {
        /* const hostname = ((window || {}).location || {}).hostname;
        let channel = "";
        let base64 = btoa(short);
        switch (hostname) {
            case "somosguay.com":
                channel = "https://canal-denuncia.guay.digital/" + base64;
                break;
            case "frontend.development.guay.digital":
                channel = "https://complaint.development.guay.digital/" + base64;
                break;
            case "frontend.staging.guay.digital":
                channel = "https://complaint.staging.guay.digital/" + base64;
                break;
            case "localhost:3000":
                channel = "https://complaint.development.guay.digital/" + base64;
                break;
            default:
                channel = "https://complaint.development.guay.digital/" + base64;
                break;
        } */
        Swal.fire({
            title: '<p class="text-white mb-2">Canal de denuncia</p>',
            text: "Se abrirá el canal de denuncia guay en otra ventana.",
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: "Si, abrir",
            reverseButtons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                getReportChannel(idEnterprise).then(response => {
                    window.open(response.urlChannel);
                })
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }
    const uploadEvidencePDF = e => {
        let ext = e.target.files[0].name.split('.').pop();
        let doc = e.target.files;
        if (showFiles_2.length === 0) {
            if (ext === 'pdf') {
                uploadFiles(e.target.files, 1);
            } else {
                toastr.warning("Debe ser un archivo .pdf.", "¡Ooops!");
            }
        } else {
            if (ext === 'pdf') {
                Swal.fire({
                    title: '<p class="text-white mb-2">Canal de denuncia</p>',
                    text: "¿Deseas sustituir el documento actual?",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'Cancelar',
                    showConfirmButton: true,
                    confirmButtonText: "Si, sustituir",
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        uploadFiles(doc, 1);
                    }
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
                toastr.warning("Debe ser un archivo .pdf.", "¡Ooops!");
            }
        }
    }
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2 pr-0">
                                    <img
                                        src="./../img/altaEmpresas/perfil_organizacion_iconos-04.png"
                                        alt="header-section"
                                        style={{ width: '75px', height: '75px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 pl-0 mt-1">
                                    <p className="font-weight-bold">Canal de denuncia</p>
                                    <p className="text-base">Indícanos cuál será tu canal de denuncia:</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-check">
                                        <GreenRadio
                                            name="complaintFlg"
                                            id="complaintFlg"
                                            value={1}
                                            inputProps={{ 'aria-label': 'C' }}
                                            size="small"
                                            onChange={(e) => handleComplainFlag(e)}
                                            checked={complaintChecked1}
                                        />
                                        <label className="text-base">Canal de denuncia guay</label>
                                        {/* <label className="form-check-label text-base pointer">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="complaintFlg"
                                                id="complaintFlg"
                                                value={1}
                                                onClick={(e) => handleComplainFlag(e)}
                                                checked={complaintChecked1} />Canal de denuncia guay
                                        </label> */}
                                        {/* <span className="text-blue ml-1 pointer"><u>Ver comunicado</u></span> */}
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <div className="form-check">
                                        <GreenRadio
                                            name="complaintFlg"
                                            id="complaintFlg"
                                            value={2}
                                            inputProps={{ 'aria-label': 'C' }}
                                            size="small"
                                            onChange={(e) => handleComplainFlag(e)}
                                            checked={complaintChecked2}
                                        />
                                        <label className="text-base">Canal de denuncia propio</label>
                                        {/* <label className="form-check-label text-base pointer">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="complaintFlg"
                                                id="complaintFlg"
                                                onClick={(e) => handleComplainFlag(e)}
                                                value={2}
                                                checked={complaintChecked2} />Canal de denuncia propio
                                        </label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showChannel === true
                            ?
                            (
                                <div className="row mt-1">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 ml-2">
                                        <span className="text-base">Accede desde tu <span>página principal</span> o da click <span className="text-blue pointer" onClick={() => showChannelguay()}><u>aquí</u></span></span>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="row mt-1">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                        <textarea
                                            className="form-control textarea-unresize"
                                            rows="3"
                                            id="presentationMsg"
                                            name="presentationMsg"
                                            placeholder="Descripción"
                                            onChange={handleOnChange}
                                            value={channelData.presentationMsg}></textarea>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="row">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                        <span
                                                            className="btn btn-blue-3 btn-sm"
                                                            onClick={() => renderFileEvidence()}>
                                                            <input
                                                                type="file"
                                                                id="fileEvidence"
                                                                className="hidden"
                                                                onChange={uploadEvidence}
                                                                multiple />
                                                            <i className='ft-plus' /> Imágenes
                                                        </span>
                                                    </div>
                                                    {
                                                        showFiles.length > 0
                                                            ?
                                                            (
                                                                showFiles.map((data) => (
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                                        <span
                                                                            className="d-inline"
                                                                            id={data.idEnterprise + "-" + data.idConfigImgComplaint}
                                                                            key={data.idEnterprise + "-" + data.idConfigImgComplaint}>
                                                                            Archivo <i
                                                                                className='ft-trash-2 text-red pointer'
                                                                                onClick={() => deleteFile(data.idEnterprise, data.idConfigImgComplaint)} /> {/* <i
                                                                                className='ft-camera text-blue pointer'
                                                                                onClick={() => previewFile(data.url)} /> */}
                                                                        </span>
                                                                        <div className="d-inline">
                                                                            <img
                                                                                className="pointer"
                                                                                src="../img/icons/iconos_registro_documental-20.png"
                                                                                alt="img-guay"
                                                                                style={{ height: '1.1rem', marginLeft: '5px', position: 'absolute' }}
                                                                                onClick={() => previewFile(data.url)} />
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )
                                                            :
                                                            (null)
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="row">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                        <span
                                                            className="btn btn-blue-3 btn-sm"
                                                            onClick={() => renderFileEvidencePDF()}>
                                                            <input
                                                                type="file"
                                                                id="fileEvidencePDF"
                                                                className="hidden"
                                                                onChange={uploadEvidencePDF}
                                                            />
                                                            <i className='ft-plus' /> Documento
                                                        </span>
                                                    </div>
                                                    {
                                                        showFiles_2.map((data) => (
                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                                                <span
                                                                    className="d-inline"
                                                                    id={`pdfdoc-${data.idUploadedDocument}`}
                                                                    key={`pdfdoc-${data.idUploadedDocument}`}
                                                                >Archivo <i className='ft-trash-2 text-red pointer' onClick={() => deleteDocumenPDF(data.idUploadedDocument)} />
                                                                </span>
                                                                {/* <div className="d-inline">
                                                                    <span
                                                                        className='pointer'
                                                                        onClick={() => window.open(data.urlFile)}
                                                                    > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                        </svg></span>
                                                                </div> */}
                                                                <div className="d-inline">
                                                                    <span className="pointer">&nbsp;
                                                                        <i className="bi bi-eye pointer"
                                                                        onClick={() => previewFilePDF(data.urlFile)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-1 d-flex flex-wrap align-content-center">
                                                        <label className="m-0">URL</label>
                                                    </div>
                                                    <div className="col-md-11">
                                                        <div className="form-group m-0">
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="url"
                                                                name="url"
                                                                onChange={handleOnChange}
                                                                value={channelData.url} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <ImagesViewer
                        showImage={showImage} />
                     <ModalViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf}/>
                </div>
            </div>
        </div>
    )
}

export default RegisterComplainChannel
