import React, { useState } from "react";
// Components
import Header from '../NewHome/headerAdministrador';
import Menu from "../Menu";
import Footer from '../footerApp';
import Cumplimiento from "./Cumplimiento";
import BannerAdmin from "../BannerAdmin";
import HelperApp from "../HelperApp";
// API Calls
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";

const ResultController = () => {
    const [menuOption, setMenuOption] = useState(0)
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a></li>
                                                <li className="breadcrumb-item font-medium-3">Cumplimiento</li>
                                                <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold pointer">{menuOption === 0 ? 'Cumplimiento' : 'Evidencia Complementaria'}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mt-2">
                                <div className="row">
                                    <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2"></div>
                                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10">
                                        <h3 className="pl-1 font-weight-bold">{menuOption === 0 ? 'Cumplimiento' : 'Evidencia Complementaria'}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mt-1">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-2 mb-1">
                                        {/* <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-navy pointer py-2 rounded">
                                                <span className="font-medium-1 text-white font-weight-bold">Cumplimiento</span>
                                            </div> */}
                                        <div
                                            className={`col-sx-12 col-sm-12 col-md-12 col-lg-12 ${menuOption === 0 ? 'bg-blue-gray' : 'bg-white-guay'} bg-blue-gray pointer py-2 rounded-top`}
                                            onClick={() => setMenuOption(0)}
                                        >
                                            <span className={`font-medium-1 ${menuOption === 0 ? 'text-white font-weight-bold' : 'text-blue-sec'}`}>Cumplimiento</span>
                                        </div>
                                        {getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? (null) : (<BannerAdmin />)}
                                    </div>
                                    {menuOption === 0 && (
                                        <Cumplimiento />
                                    )}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResultController
