import React, { Fragment, useState, useEffect } from 'react';
// API calls
import { getAllOrdersByUserBusiness } from '../Models/Shop_model';
// Tools
import { useHistory } from "react-router-dom";
import { getJwt } from '../../../lib/auth';

const CommercialOptions = () => {
    // Global variables
    const [scoreboard, setScoreboard] = useState({
        closed: 0,
        pending: 0,
        progressing: 0
    });
    const {
        closed,
        pending,
        progressing
    } = scoreboard;
    const history = useHistory();
    useEffect(() => {
        getAllOrdersByUserBusiness(parseInt(getJwt('jtw'))).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setScoreboard({
                            closed: response.closed,
                            pending: response.pending,
                            progressing: response.progressing
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }, []);
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mx-auto mt-1 d-flex justify-content-end">
            {/* {
                closed === 0 && pending === 0 && progressing === 0 ? (
                    <Fragment>
                        <button
                            className="btn btn-sm btn-pink"
                            onClick={() => history.push("/solicitud-comercial")}
                        >Solicita una prueba</button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <button
                            className="btn btn-sm btn-pink mr-1"
                            onClick={() => history.push("/estatus-solicitud")}
                        >Estatus de solicitudes</button>
                        <button
                            className="btn btn-sm btn-pink"
                            onClick={() => history.push("/solicitud-comercial")}
                        >Solicita una prueba</button>
                    </Fragment>
                )
            } */}
              {
                closed === 0 && pending === 0 && progressing === 0 ? (
                    ''
                ) : (
                    <Fragment>
                        <button
                            className="btn btn-sm btn-pink mr-1"
                            onClick={() => history.push("/estatus-solicitud")}
                        >Estatus de solicitudes</button>
                        <button
                            className="btn btn-sm btn-pink"
                            onClick={() => history.push("/solicitud-comercial")}
                        >Solicita una prueba</button>
                    </Fragment>
                )
            }
        </div>
    );
}

export default CommercialOptions;
