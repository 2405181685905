import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ModalAlerts from './ModalAlerts';
import { getDatabyLaunchMonitorynAll, setPauseSurveyFH, setActivateSurveyFH, downloadPDFMonitoryngAll, downloadExcelMonitoringAll } from '../Models/Monitor_model';
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { ModalCollabs } from './ModalCollabs';
import { getJwt } from '../../../lib/auth';
import LoaderApp from '../LoaderApp';
import ModalForward from './ModalForward';
import ModalLoaderGen from '../ModalLoaderGen';
import AssingCollabsLauncCtrl from '../assingCollabsLaunch/AssingCollabsLauncCtrl';
import InfoModal from '../assingCollabsLaunch/InfoModal';
import ModalViewPdf from '../enterprises/ModalViewPdf';

const AdvanceEnProgreso = ({ enterpriseId, advEnProgreso, update, setUpdate, step_2,
    branchesCat,
    categoriesJobCat,
    areasCat,
    refreshData,
}) => {
    const [tableCollabData, setTableCollabData] = useState([]);
    const [tableClima, setTableClima] = useState([]);
    const [tableCulture, setTableCulture] = useState([])
    const [graphicCollabData, setGraphicCollabData] = useState({});
    const [advance, setAdvance] = useState({});
    const [dataForward, setDataForward] = useState({});
    const [collabData, setCollabData] = useState({
        completo: 0,
        enProgreso: 0,
        sinContestar: 0
    });
    const [loading, setLoading] = useState(false);

    const opciones = {
        responsive: true,
        legend: false,
    };
    const [buttonSelected, setButtonSelected] = useState(0);
    const [buttonState, setButtonState] = useState(true);
    const [modalData, setModalData] = useState({
        modalShow: false,
        groupId: 0
    });

    const { modalShow, groupId } = modalData;
    const [urlShowPdf, setUrlShowPdf] = useState("")


    const [currentIdGroup, setCurrentIdGroup] = useState(0);
    const [currentName, setCurrentName] = useState('');
    useEffect(() => {
        advEnProgreso.forEach(element => {
            const collapseIdx = document.getElementById(`collapse-${element.idGroup}`);
            collapseIdx.className = "collapse";
            const buttonIdx = document.getElementById(`button-${element.idGroup}`);
            buttonIdx.src = "img/icons/iconos_registro_documental-07.png";
        });
        return () => {
            setButtonSelected(0);
            setButtonState(true);
        }
    }, [update, advEnProgreso])

    useEffect(() => {
        if (!modalShow) {
            if (groupId !== 0) {
                getDataByLaunchFunction(enterpriseId, groupId);
                setUpdate(2);
            }
        }
        // eslint-disable-next-line
    }, [modalShow, enterpriseId, groupId])

    const barOptions = (percentage) => {
        let width = 0;
        let backgroundColor = "#efeff6";
        if (percentage <= 30) {
            //yellow
            width = `${percentage}%`;
            backgroundColor = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            width = `${percentage}%`;
            backgroundColor = "#2188b4";
        } else if (percentage > 70) {
            //green
            width = `${percentage}%`;
            backgroundColor = "#9cb537";
        } else {
            //other
            width = "50%";
            backgroundColor = "#71758d";
        }
        return {
            width,
            backgroundColor
        }
    };

    const percentageOptions = (percentage) => {
        let color = "#efeff6";
        if (percentage <= 30) {
            //yellow
            color = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            color = "#2188b4";
        } else if (percentage > 70) {
            //green
            color = "#9cb537";
        } else {
            //other
            color = "#71758d";
        }
        return {
            color
        }
    };

    const options = {
        noDataText: 'No se encontraron registros',
        sortIndicator: true,
        page: 1,  // which page you want to show as default
        sizePerPage: 5,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 5,  // the pagination bar size.
        prePage: '<', // Previous page button text
        nextPage: '>', // Next page button text
        firstPage: '<<', // First page button text
        lastPage: '>>', // Last page button text
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        hideSizePerPage: true, // You can hide the dropdown for sizePerPage
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
    };

    function formatIcon(value, dataObject, enumObject, index) {
        if (value === 0) {
            return <div className="text-center"><i className="la la-thumbs-o-down la-2x blue-radioactivo fa-4x"></i></div>;
        } else {
            return <div className="text-center"><i className="la la-thumbs-o-down la-2x blue-radioactivo fa-4x"></i></div>;
        }
    }

    const handleCollapse = (enterpriseId, idGroup, idProduct, idCategorie, idSubCategorie) => {
        if (idGroup !== buttonSelected) {
            advEnProgreso.forEach(element => {
                if (element.idGroup === idGroup) {
                    setButtonSelected(element.idGroup);
                    const buttonIdx = document.getElementById(`button-${element.idGroup}`);
                    buttonIdx.src = "img/icons/iconos_registro_documental-10.png";
                    setButtonState(true);
                } else {
                    const buttonIdx = document.getElementById(`button-${element.idGroup}`);
                    buttonIdx.src = "img/icons/iconos_registro_documental-07.png";
                }
            })
        } else {
            setButtonState(!buttonState);
            if (buttonState) {
                const buttonIdx = document.getElementById(`button-${idGroup}`);
                buttonIdx.src = "img/icons/iconos_registro_documental-07.png";
            } else {
                const buttonIdx = document.getElementById(`button-${idGroup}`);
                buttonIdx.src = "img/icons/iconos_registro_documental-10.png";
            }
        }

        getDataByLaunchFunction(enterpriseId, idGroup, idProduct, idCategorie, idSubCategorie);
    }

    const getDataByLaunchFunction = function (enterpriseId, idGroup, idProduct, idCategorie, idSubCategorie) {
        setLoading(true);
        getDatabyLaunchMonitorynAll(enterpriseId, idGroup, getJwt('jtw'), getJwt('rol')).then((response) => {
            switch (response?.code) {
                case 1:
                    const arrayAux = [];
                    //se crea un id unico porque el componente de la tabla tira error ya que un colaborador puede tener varios lanzamientos
                    response.data.forEach(element => {
                        arrayAux.push({ ...element, idGroupIdCollab: `${idGroup}-${element.idCollab}` });
                    });
                    if ((idProduct === 1 && idCategorie === 2 && idSubCategorie === 0) || (idProduct === 1 && idCategorie === 4 && idSubCategorie === 0)) {
                        let auxArr = [];
                        let flag = 0;
                        let idFlag = 0;
                        for (let x in response.data) {
                            if (auxArr.length === 0) {
                                auxArr.push({ id: response.data[x].idBranch, total: 1, name: response.data[x].Branch });
                            } else {
                                for (let y in auxArr) {
                                    if (response.data[x].idBranch === auxArr[y].id) {
                                        auxArr[y].total = auxArr[y].total + 1;
                                        flag = 0;
                                        break;
                                    } else {
                                        flag = 1;
                                        idFlag = x;
                                    }

                                }
                                if (flag === 1) {
                                    auxArr.push({ id: response.data[idFlag].idBranch, total: 1, name: response.data[idFlag].Branch });
                                }
                            }
                        }
                        idCategorie === 4 ? setTableCulture(auxArr) : setTableClima(auxArr);
                    }
                    setTableCollabData(arrayAux);
                    setGraphicCollabData({
                        labels: ["En progreso", "Completo", "Sin contestar"],
                        datasets: [
                            {
                                label: '# de colaboradores',
                                data: [response.totalProgres, response.totalComplete, response.totalInactive],
                                backgroundColor: [
                                    '#dfa444',
                                    '#9cb537',
                                    '#15253c'
                                ],
                                borderColor: [
                                    '#FF9E00',
                                    '#BCDA44',
                                    '#00B3FF'
                                ],
                                borderWidth: 1,
                            },
                        ],
                    });
                    setCollabData({
                        completo: response.totalComplete,
                        enProgreso: response.totalProgres,
                        sinContestar: response.totalInactive
                    });
                    setLoading(false);
                    break;
                case 1002:
                    setTableCollabData([]);
                    setGraphicCollabData({
                        labels: ["En progreso", "Completo", "Sin contestar"],
                        datasets: [
                            {
                                label: '# de colaboradores',
                                data: [0, 1, 0],
                                backgroundColor: [
                                    '#dfa444',
                                    '#9cb537',
                                    '#15253c'
                                ],
                                borderColor: [
                                    '#FF9E00',
                                    '#BCDA44',
                                    '#00B3FF'
                                ],
                                borderWidth: 1,
                            },
                        ],
                    });
                    setCollabData({
                        completo: 0,
                        enProgreso: 0,
                        sinContestar: 0
                    });
                    setLoading(false);
                    break;
                case 99:
                    setLoading(false);
                    toastr.info("Cambiaron los datos de tus colaboradores. Favor de validarlos.", "¡Ooops!");
                    break;
                default:
                    setLoading(false);
                    toastr.error("Ocurrió un problema de conexión", "Revisa tu conexión a internet");
                    console.error("Ocurrio un error en la peticion, checa los logs");
                    break;
            }
        });
    }

    function handleClickCloseLaunch(advance) {
        switch (advance.idCategorie) {
            //NOM
            case 1:
                if (advance.type_launch === 4) { //conjunto
                    if (advance.percent === "100") {
                        setAdvance(advance);
                        $("#closeEvalCaseAsk").show();
                    } else {
                        $("#closeEvalCaseError").show();
                    }
                } else if (advance.type_launch === 1) { //normativo
                    if (advance.idSubCategorie === 1) {// nom035 F1
                        if (advance.percent === "100") {
                            setAdvance(advance);
                            $("#closeEvalCaseAsk").show();
                        } else {
                            $("#closeEvalCaseError").show();
                        }
                    } else { //nom035 F2
                        if (parseInt(advance.percent, 10) >= 80) {
                            setAdvance(advance);
                            $("#closeEvalCaseAsk").show();
                        } else {
                            $("#closeEvalCaseError2").show();
                        }
                    }
                } else { //complementario
                    setAdvance(advance);
                    $("#closeEvalCaseAsk").show();
                }
                break;
            //CLIMA
            case 2:
                setAdvance(advance);
                $("#closeExeCaseAsk").show();
                break;
            //FH
            case 6:
                setAdvance(advance);
                $("#closeExeCaseAsk").show();
                break;
            //RS
            case 7:
                setAdvance(advance);
                $("#closeExeCaseAsk").show();
                break;
            case 4:
                setAdvance(advance);
                $("#closeExeCaseAsk").show();
                break;
            default:
                setAdvance({});
                toastr.error("Producto no establecido", "Error interno");
                break;
        }
    }

    function handleClickPauseActiveLaunch(advance) {
        if (advance.estatus === 4) {
            setActivateSurveyFH(enterpriseId, advance.idGroup).then((response) => {
                switch (response?.code) {
                    case 1:
                        setUpdate(2);
                        toastr.success("Si deseas pausarlo habilita el botón de pausado y a la brevedad se pausarán las evaluaciones.", "¡Tu ejercicio ha sido reanudado con éxito!");
                        break;
                    case 99:
                        toastr.error("¡Ocurrió un error al pausar tu evaluación del ejercicio!", "¡Error!");
                        break;
                    case 1000:
                        toastr.error("No se encontro la empresa", "¡Error!");
                        break;
                    default:
                        toastr.error("Error interno", "¡Error!");
                        break;
                }
            });
        } else {
            setPauseSurveyFH(enterpriseId, advance.idGroup).then((response) => {
                switch (response?.code) {
                    case 1:
                        setUpdate(2);
                        toastr.success("Si deseas reanudarlo deshabilita el botón de pausado y a la brevedad reactivara las evaluaciones de acuerdo al plan y flujo de restauración de evaluaciones pausadas.", "¡Tu ejercicio ha sido pausado con éxito!");
                        break;
                    case 99:
                        toastr.error("¡Ocurrió un error al pausar tu evaluación del ejercicio!", "¡Error!");
                        break;
                    case 1000:
                        toastr.error("No se encontro la empresa", "¡Error!");
                        break;
                    default:
                        toastr.error("Error interno", "¡Error!");
                        break;
                }
            });
        }
    }

    function writeLaunchName(advance) {
        let textTitle = "";
        if (advance.type_launch === 4) {
            textTitle = "NOM035 (CONJUNTO)"
        } else {
            switch (advance.idSubCategorie) {
                case 1:
                    textTitle = `${advance.Product} (${advance.Categorie}-F1)`;
                    break;
                case 2:
                    textTitle = `${advance.Product} (${advance.Categorie}-F2)`;
                    break;
                default:
                    textTitle = `${advance.Categorie}`;
                    break;
            }
        }
        return textTitle;
    }

    function writeSubLaunchName(advance) {
        let textSub = "";
        switch (advance.type_launch) {
            case 1:
                textSub = "Normativo";
                break;
            case 2:
                textSub = "Complementario";
                break;
            case 3:
                textSub = "Estándar";
                break;
            case 4:
                textSub = "Normativo";
                break;
            default:
                textSub = "";
                break;
        }
        return textSub;
    }

    const writeProductName = advance => {
        let textTitle = ''
        switch (advance.idSubCategorie) {
            case 1:
                textTitle = `NOM035`;
                break;
            case 2:
                textTitle = `NOM035`;
                break;
            default:
                textTitle = `${advance.Categorie}`;
                break;
        }
        return textTitle;
    }

    /**
    * 
    * @param {*} url 
    */
    const previewFilePDF = (url) => {
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        // console.log('ext: ', ext);
        if (ext === 'pdf') {
            setUrlShowPdf(url);
            $('#closeModalViewPdf').show()
        } else {
            window.open(url);
        }
    }

    function handleClickDownloadPDF(groupId) {
        // toastr.info("Iniciando descarga de evaluación PDF", "Espere...");
        $("#modalLoaderGen").show();
        downloadPDFMonitoryngAll(enterpriseId, groupId, getJwt('jtw'), getJwt('rol')).then((response) => {
            switch (response?.code) {
                case 1:
                    // window.open(response.urlDoc);
                    $("#modalLoaderGen").hide();
                    if (response.urlDoc && response.urlDoc !== '') {
                        previewFilePDF(response.urlDoc)
                    } else {
                        toastr.info("No se encontro el archivo", "¡Ooops!");
                    }
                    break;
                default:
                    $("#modalLoaderGen").hide();
                    toastr.error("No se ha podido completar la descarga, revise tu conexión a internet", "Error");
                    break;
            }
        })
    }

    function handleClickDownloadExcel(groupId) {
        toastr.info("Iniciando descarga de evaluación Excel", "Espere...");
        downloadExcelMonitoringAll(enterpriseId, groupId).then((response) => {
            switch (response?.code) {
                case 1:
                    window.open(response.urlFile);
                    break;
                default:
                    toastr.error("No se ha podido completar la descarga, revise tu conexión a internet", "Error");
                    break;
            }
        })
    }

    const handleClickOpenModalCollabs = (advance) => {
        setModalData(prevState => ({
            modalShow: !prevState.modalShow,
            groupId: advance.idGroup
        }));
    }

    const handleClickSendReminder = (enterpriseId, idGroup, idProduct, idCategorie, idSubCategorie) => {
        setDataForward({ userId: getJwt('jtw'), enterpriseId: enterpriseId, idGroup: idGroup, idProduct: idProduct, idCategorie: idCategorie, idSubCategorie: idSubCategorie });
        $("#closeModalForward").show();

    }

    if (advEnProgreso.length !== 0) {
        return (
            <div className="row">
                <ModalAlerts enterpriseId={enterpriseId} advance={advance} setUpdate={setUpdate} />
                <ModalForward dataForward={dataForward} />
                <ModalLoaderGen />
                <AssingCollabsLauncCtrl
                    step_2={step_2}
                    currentIdGroup={currentIdGroup}
                    branchesCat={branchesCat}
                    categoriesJobCat={categoriesJobCat}
                    areasCat={areasCat}
                    refreshData={refreshData}
                    setCurrentIdGroup={setCurrentIdGroup}
                />
                <InfoModal currentName = {currentName} setCurrentName={setCurrentName}/>
                <ModalViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf} />
                {modalShow && <ModalCollabs setModalData={setModalData} enterpriseId={enterpriseId} groupId={groupId} />}
                <div className="col-12 mb-2">
                    <div className="rounded with-box-shadow d-flex pr-1">
                        <div className="vertical-text rounded-right bg-blue-navy text-white text-center font-medium-2 py-1">hoy</div>
                        <div className="accordion w-100 h-100 bg-blue-navy rounded-right" id="accordionAvancesEnProgreso">
                            {
                                advEnProgreso.map((advance, idx) => {
                                    return (
                                        <div className="card rounded-0 m-0 without-box-shadow" key={idx}>
                                            <div className="card-header" id={`heading-${advance.idGroup}`}>
                                                <h2 className="mb-0">
                                                    <div className="d-flex align-items-start">
                                                        <div className="col-2 font-medium-2 flex-wrap align-self-center">{writeLaunchName(advance)}</div>
                                                        <div className="col-2 font-small-2 flex-wrap align-self-center text-blue"><p className="p-0 m-0">{advance.Launch}</p><p className="p-0 m-0">{writeSubLaunchName(advance)}</p></div>
                                                        <div className="col-1 font-small-2 align-self-center text-center">en progreso</div>
                                                        <div className="col-5 pt-1">
                                                            <div className="progress">
                                                                <div className="progress-bar" style={barOptions(advance.percent)}></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 align-self-center">
                                                            <div className="row">
                                                                <div className="col-6 font-medium-2 align-self-center text-center" style={percentageOptions(advance.percent)}>{advance.percent}%</div>
                                                                <div className="col-6 d-flex align-self-center justify-content-center">
                                                                    <img className="icon-img-18 pointer blurryImg ico" alt={`boton desplegable ${advance.idGroup}`} src="img/icons/iconos_registro_documental-07.png" data-toggle="collapse" data-target={`#collapse-${advance.idGroup}`} aria-controls={`collapse-${advance.idGroup}`} onClick={() => handleCollapse(enterpriseId, advance.idGroup, advance.idProduct, advance.idCategorie, advance.idSubCategorie)} id={`button-${advance.idGroup}`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-1 font-small-3 align-self-center mx-0 px-0 bg-success" style={percentageOptions(advance.percent)}>{advance.percent}%</div>
                                                        <div className="col-1 d-flex align-self-center justify-content-center mx-0 px-0 bg-danger">
                                                            <img className="icon-img-25 pointer blurryImg ico" alt={`boton desplegable ${advance.idGroup}`} src="img/icons/iconos_registro_documental-07.png" data-toggle="collapse" data-target={`#collapse-${advance.idGroup}`} aria-controls={`collapse-${advance.idGroup}`} onClick={() => handleCollapse(enterpriseId, advance.idGroup)} id={`button-${advance.idGroup}`} />
                                                        </div> */}
                                                    </div>
                                                </h2>
                                            </div>
                                            <div id={`collapse-${advance.idGroup}`} className="collapse" aria-labelledby={`heading-${advance.idGroup}`} data-parent="#accordionAvancesEnProgreso">
                                                <div className="card-body">
                                                    {
                                                        loading ?
                                                            <LoaderApp />
                                                            :
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-3 d-flex justify-content-between px-3">
                                                                    </div>
                                                                    <div className="col-9 d-flex justify-content-between px-3">
                                                                        {advance.idCategorie === 6 ?
                                                                            <span>{`CICLO ${tableCollabData[0]?.idPeriod} ${tableCollabData[0]?.Section}`}</span>
                                                                            : <span>&nbsp;</span>}
                                                                        {
                                                                            (advance.idProduct === 1 && advance.idCategorie === 2 && advance.idSubCategorie === 0 ) || (advance.idProduct === 1 && advance.idCategorie === 4 && advance.idSubCategorie === 0) ? (
                                                                                null
                                                                            ) : (
                                                                                <>
                                                                                    {/* <div className="col-10 d-flex justify-content-between px-3"></div>
                                                                                <div className="col-2 d-flex justify-content-end pr-0">
                                                                                <div className="col d-flex justify-content-end pl-3 pr-0">
                                                                                <
                                                                                    img className="icon-img-25 blurryImg"
                                                                                    src="img/icons/pdf.png"
                                                                                    alt="descargar evaluación"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title="Descargar evaluación PDF"
                                                                                    onClick={() => handleClickDownloadPDF(advance.idGroup)}
                                                                                />
                                                                                </div>
                                                                                <div className="col d-flex justify-content-end p-0">
                                                                                <
                                                                                    img className="icon-img-25 blurryImg"
                                                                                    src="img/icons/excel.png"
                                                                                    alt="descargar evaluación Excel"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title="Descargar evaluación Excel"
                                                                                    onClick={() => handleClickDownloadExcel(advance.idGroup)}
                                                                                /> <span>Descargar evaluación Excel</span>
                                                                                </div>
                                                                                </div> */}
                                                                                    <div className='col-12 mb-1'>
                                                                                        <div className='row d-flex justify-content-end'>
                                                                                            <div
                                                                                                className='pointer mr-1'
                                                                                                onClick={() => handleClickDownloadPDF(advance.idGroup)}
                                                                                            ><img
                                                                                                    className="icon-img-25 blurryImg"
                                                                                                    src="img/icons/pdf.png"
                                                                                                    alt="descargar evaluación"
                                                                                                    data-toggle="tooltip"
                                                                                                    data-placement="top"
                                                                                                    title="Descargar evaluación PDF"
                                                                                                /> <span>Descargar avance PDF</span>
                                                                                            </div>
                                                                                            <div
                                                                                                className=' pointer mr-1'
                                                                                                onClick={() => handleClickDownloadExcel(advance.idGroup)}
                                                                                            ><img
                                                                                                    className="icon-img-25 blurryImg"
                                                                                                    src="img/icons/excel.png"
                                                                                                    alt="descargar evaluación Excel"
                                                                                                    data-toggle="tooltip"
                                                                                                    data-placement="top"
                                                                                                    title="Descargar evaluación Excel"
                                                                                                /> <span>Descargar avance Excel</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-3 px-0">
                                                                        <div className="col-12 d-flex justify-content-center mb-1 py-1">
                                                                            <Doughnut data={graphicCollabData} options={opciones} />
                                                                        </div>
                                                                        <div className="col-12 d-flex flex-column">
                                                                            <p className="font-small-3 text-center text-base font-weight-bold">{advance.idCategorie === 6 ? "Avance general (total ciclos)" : "Estado de participación"}</p>
                                                                        </div>
                                                                        <div className="col-12 d-flex flex-column">
                                                                            {advance.idCategorie === 6 ? <>
                                                                                <p className="font-small-2 text-base text-center">Participación concluida <span className="text-green font-weight-bold">{collabData.completo}</span></p>
                                                                                <p className="font-small-2 text-base text-center">Participación pendiente <span className="text-yellow font-weight-bold">{collabData.sinContestar}</span></p>
                                                                            </> : null}
                                                                            {
                                                                                getJwt('rol') === '6' ? '' : advance.idCategorie === 6 || advance.idCategorie === 7 ? null : !step_2 && <button className="btn btn-sm btn-save-blue font-small-3 align-self-center mt-2 mb-1" onClick={() => handleClickCloseLaunch(advance)}>Cerrar Evaluaci&oacute;n</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9">
                                                                        <div className="col-12">
                                                                            {
                                                                                (advance.idProduct === 1 && advance.idCategorie === 2 && advance.idSubCategorie === 0) 
                                                                                || (advance.idProduct === 1 && advance.idCategorie === 4 && advance.idSubCategorie === 0) ? (
                                                                                    <BootstrapTable data={advance.idCategorie === 2 ? tableClima : tableCulture} striped hover condensed pagination
                                                                                        trClassName='tr-data-table px-0'
                                                                                        tableStyle={{ borderRight: "none", borderLeft: "none", borderRadius: 0 }}
                                                                                        options={options}
                                                                                        bodyStyle={{ wordBreak: 'break-all' }}
                                                                                    >
                                                                                        <TableHeaderColumn isKey dataField="id" hidden>Id</TableHeaderColumn>
                                                                                        <TableHeaderColumn dataField='name' dataSort dataAlign="center" tdStyle={{ fontSize: '12px', whiteSpace: "break-spaces" }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Centro de Trabajo</TableHeaderColumn>
                                                                                        <TableHeaderColumn dataField='total' dataSort dataAlign="center" tdStyle={{ fontSize: '12px', whiteSpace: "normal" }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Pendientes por participar</TableHeaderColumn>
                                                                                    </BootstrapTable>
                                                                                ) : (
                                                                                    <BootstrapTable data={tableCollabData} striped hover condensed pagination
                                                                                        trClassName='tr-data-table px-0'
                                                                                        tableStyle={{ borderRight: "none", borderLeft: "none", borderRadius: 0 }}
                                                                                        options={options}
                                                                                        bodyStyle={{ wordBreak: 'break-all' }}
                                                                                    >
                                                                                        <TableHeaderColumn isKey dataField="idGroupIdCollab" hidden>Id</TableHeaderColumn>
                                                                                        {advance.idCategorie === 6 ? <TableHeaderColumn dataField='cycle' dataSort dataAlign="center" width="15%" tdStyle={{ fontSize: '12px' }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Evaluación</TableHeaderColumn> : null}
                                                                                        <TableHeaderColumn dataField='Branch' dataSort dataAlign="center" width="25%" tdStyle={{ fontSize: '12px', whiteSpace: "break-spaces" }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Centro de Trabajo</TableHeaderColumn>
                                                                                        <TableHeaderColumn dataField='Name' dataSort dataAlign="center" width="20%" tdStyle={{ fontSize: '12px', whiteSpace: "normal" }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Colaborador</TableHeaderColumn>
                                                                                        <TableHeaderColumn dataField='email' dataSort dataAlign="center" width="20%" tdStyle={{ fontSize: '12px', whiteSpace: "break-spaces" }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Correo</TableHeaderColumn>
                                                                                        <TableHeaderColumn dataField='status' dataFormat={formatIcon} dataSort dataAlign="center" width="20%" tdStyle={{ fontSize: '12px' }} thStyle={{ fontSize: "15px", textAlign: "center", padding: "25px 0px 25px 0px" }}>Participaci&oacute;n</TableHeaderColumn>
                                                                                    </BootstrapTable>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-12 d-flex justify-content-start mb-2">
                                                                            <div className="col d-flex justify-content-start align-items-center">
                                                                                <ins
                                                                                    className="text-blue pointer"
                                                                                    onClick={() => {
                                                                                        setCurrentName(writeProductName(advance))
                                                                                        setCurrentIdGroup(advance.idGroup)
                                                                                        $('#modalAssingCollabs').show()
                                                                                        $('#infoModal').show()
                                                                                    }}
                                                                                >
                                                                                    Asignar colaboradores al lanzamiento
                                                                                </ins>
                                                                            </div>
                                                                            {/* <a href="./administracion-colaboradores"><ins className="text-blue">Actualizar estatus de colaboradores</ins></a> */}
                                                                            {
                                                                                getJwt('rol') === '6' ? '' : (
                                                                                    (advance.idProduct === 1 && advance.idCategorie === 2 && advance.idSubCategorie === 0) || 
                                                                                    (advance.idProduct === 1 && advance.idCategorie === 4 && advance.idSubCategorie === 0 ) ? (
                                                                                        null
                                                                                    ) : (
                                                                                        !step_2 && (
                                                                                            <div className="col d-flex justify-content-start align-items-center">
                                                                                                <ins
                                                                                                    className="text-blue pointer"
                                                                                                    onClick={() => {
                                                                                                        handleClickOpenModalCollabs(advance)
                                                                                                    }}
                                                                                                >
                                                                                                    Actualizar estatus de colaboradores
                                                                                                </ins>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                )
                                                                            }
                                                                            {
                                                                                !step_2 ? (
                                                                                    <div className="col d-flex justify-content-start align-items-center">
                                                                                        <ins
                                                                                            className="text-blue pointer"
                                                                                            onClick={() => handleClickSendReminder(enterpriseId, advance.idGroup, advance.idProduct, advance.idCategorie, advance.idSubCategorie)}
                                                                                        >Enviar recordatorio a colaboradores pendientes
                                                                                        </ins>
                                                                                    </div>
                                                                                ) : null
                                                                            }

                                                                        </div>
                                                                        <div className="col-12 d-flex mb-2">
                                                                            {advance.idCategorie === 6 && !step_2 ?
                                                                                <>
                                                                                    <div className="col-5 d-flex justify-content-start align-items-center">
                                                                                        <span className="font-small-3 text-base mr-1">Pausar ejercicio</span>
                                                                                        <label className="switch switch-small">
                                                                                            <input type="checkbox" checked={advance.estatus === 4 ? true : false} onChange={() => handleClickPauseActiveLaunch(advance)} />
                                                                                            <span className="slider slider-small round"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-5 d-flex justify-content-start align-items-center">
                                                                                        <span className="font-small-3 text-base mr-1">Cerrar ejercicio</span>
                                                                                        <label className="switch switch-small">
                                                                                            <input type="checkbox" checked={advance.estatus === 3 ? true : false} onChange={() => handleClickCloseLaunch(advance)} />
                                                                                            <span className="slider slider-small round"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            {advance.idCategorie === 7 && !step_2 ?
                                                                                <div className="col-7 d-flex align-items-center">
                                                                                    <span className="font-small-3 text-base mr-1">Cerrar ejercicio incluyendo PSS</span>
                                                                                    <label className="switch switch-small">
                                                                                        <input type="checkbox" />
                                                                                        <span className="slider slider-small round"></span>
                                                                                    </label>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        {advance.idCategorie === 7 && !step_2 ?
                                                                            <div className="col-12 d-flex justify-content-end">
                                                                                <ins className="text-blue">Seguimiento cumplimiento medidas</ins>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    } else {
        return (
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="rounded with-box-shadow d-flex pr-1">
                        <div className="vertical-text rounded-right bg-blue-navy text-white text-center font-medium-2 py-1">hoy</div>
                        <div className="w-100 d-flex justify-content-center align-items-center">
                            <p className="text-center py-2 font-medium-2 text-base">Sin datos</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvanceEnProgreso
