import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../Config";
import {activateAccounts} from "./Model";

const Activate_account = props => {

  /* const [validate, updValidate]  = useState({});  */
  const [message, setMessage]  = useState(""); 
  const [flag, setFlag]  = useState(false); 
  const [loading, setLoading]  = useState(true); 

  useEffect(() => {
    const getObject = props.match.params;
    let obj = getObject.db.split("=");
    activateAccounts(obj[2], obj[1]).then((result) => {
      if (result){
        if (result.success) {
          /* updValidate(result); */
          setFlag(true);
          setLoading(false);
        } else {
          switch (result.code) {
            case 1003:
              // Ya fue usado el token
              setMessage("El token ha expirado.");
              setLoading(false);
              break;
            case 1004:
              // Ocurrio un error
              setMessage("Ocurrió un error. Por favor consulta con el administrador.");
              setLoading(false);
              break;
            default:
              setMessage("Ocurrió un error. Por favor consulta con el administrador.");
              setLoading(false);
              break;
          }
        }
      }
    }, []);
    
    // eslint-disable-next-line
  }, []);
  return (
    <div className="app-content content">
      <div className="content-overlay">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <section className="bg-login-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-xs-12 col-md-4 align-items-center d-flex justify-content-center ">
                      <div className="row">
                        <div className="col-md-12">
                       
                                    <div className="content-header row mt-5 ">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <h1 className="text-center">
                                                <strong><span className="text-green">Activación de cuenta</span></strong>
                                            </h1>
                                            <h2 className="text-center">
                                                Estás muy cerca de ingresar a la experiencia <img src="https://canal-denuncia.guay.digital/img/logo_guay_color.png" style={{width: '90px'}} /* className="w-10" */ alt="logo guay"></img>
                                            </h2>
                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>

                        </div>
                      </div><br/>
                     
                    </div>
                  </div>
                  <div className="col-md-4">

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-xs-12 col-md-4 align-items-center d-flex justify-content-center ">
                      <div className="row">
                        <div className="col-md-12 mt-3">
                       {
                         loading === true ?
                         (null)
                          :  flag === true ?
                            <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading">Activación completada</h4>
                            <p>Se ha verificado correctamente tu cuenta. Ahora eres parte de la experiencia guay.</p>
                            <hr/>
                            <NavLink to="/"><p className="mb-0">Ingresa aquí</p></NavLink>
                          </div>
                        : 
                          <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">¡Ocurrió un problema con la activación de la cuenta</h4>
                            <p>{message}</p>
                            <hr/>
                            <p className="mb-0">Si tienes alguna duda contactanos info@guay.digital</p>
                          </div>     
                       }
                        

                        </div>
                      </div><br/>
                     
                    </div>
                  </div>
                  <div className="col-md-4">

                  </div>
                </div>
              </div>
              
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activate_account;
