import React from 'react'
import $ from "jquery";
import * as toastr from 'toastr';
import { sendCardsByEnterpriseLaunch } from '../Models/Launch_model';
import { getMonitoring } from './Model';
export const ResendModal = ({data,branch,idEnterprise,idUser,idGroup,setShowTable,setMailGunData,setDataGeneral,setIdGroup,monitoring}) => {

    function cambios() {
        setShowTable(true);
        getMonitoring(monitoring).then((result) => {
            if(result.success){
                setMailGunData(result.data);   
                setDataGeneral({
                    deliveredTotal:result.deliveredTotal,
                    sendItems:result.sendItems,
                    totalItems:result.totalItems,
                    acceptedTotal:result.acceptedTotal,
                    failedTotal:result.failedTotal,
                    openedtotal:result.openedtotal,
                    progress:result.progress,
                    totalOpens:result.totalOpens,
                    progressOpens:result.ProgressOpens,
                }) 
                setIdGroup(result.idGroup)
                toastr.success("Se actualizó correctamente la información.", "¡Éxito!");             
                
            }else{                
                setMailGunData([]);
                setDataGeneral({
                    deliveredTotal:0,
                    sendItems:0,
                    totalItems:0,
                    acceptedTotal:0,
                    failedTotal:0,
                    openedtotal:0,
                    progress:0,
                    totalOpens:0,
                    progressOpens:0,
                    
                });
                setIdGroup(0)
                toastr.error("Por el momento no hay información para el lanzamiento seleccionado.", "Espera!");  
                        
            }
            setShowTable(false);
        });
    }

    function handleClicYesCloseAll(flagAll) {
        $('#resendModal').hide();
        $('#modalLoaderGen').show();
        sendCardsByEnterpriseLaunch(parseInt(idUser),parseInt(idEnterprise),data.idCard,idGroup,parseInt(flagAll),branch ).then(response => {
            switch (response?.code) {
                case 1:
                    $('#modalLoaderGen').hide();
                    toastr.success("¡El comunicado se reenvío con éxito!", "¡Éxito!");
                    cambios();
                    break

                case 99:
                    $('#modalLoaderGen').hide();
                    toastr.error('Ocurrió un error, verifique su conexión a internet.')
                    break

                case 1001:
                    $('#modalLoaderGen').hide();
                    toastr.error('La organización no se encuentra o no está activa.')
                    break

                case 1002:
                    $('#modalLoaderGen').hide();
                  toastr.error('El usuario no se encuentra o no está activo.')
                  break

                default:
                    $('#modalLoaderGen').hide();
                    toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                    break
            }
        })
        
    }

  return (
    <div
    className="modal"
    id="resendModal"
    style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
    data-backdrop="false">
        <div className="modal-dialog modal-dialog-centered modal-md">
            <div
                className="modal-content"
                style={{ backgroundColor: 'transparent',borderRadius: '2rem',border: "none" }}

                >
                        <button
                        className="close"
                        style={
                            {
                                position: 'absolute',
                                top: '-20px',
                                right: '-20px',
                                opacity: '1',
                                fontSize: '2rem',
                            }
                        }
                        onClick={() => {
                            $("#resendModal").hide();
                        }}
                    >&times;</button>
                
                <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                    <div className="row mb-1 d-flex justify-content-center">
                        <div className="col-8">
                            <h1 className="font-medium-1 text-center">¿Deseas reenviar el comunicado?</h1>
                        </div>
                    </div>
                    <div className="row mb-2 d-flex justify-content-center">
                        <div className="col">
                            <h1 className="font-medium-2 text-center font-weight-bold">{data.subject}</h1>
                        </div>
                    </div>
                    <div className="row mb-1 d-flex justify-content-center">
                        <div className="col">
                            <p className="font-medium-1 text-center">Se reenviará a los siguientes colaboradores</p>
                        </div>
                    </div>
                    <div className="row mb-2 d-flex justify-content-center">
                        <div className="col-10 d-flex justify-content-between">
                            <button className="btn btn-small btn-save-style2" onClick={() => handleClicYesCloseAll(1)}>Todos</button>
                            <button className="btn btn-small btn-save-style2" onClick={() => handleClicYesCloseAll(0)}>Fallidos</button>
                        </div>
                    </div>
                    <div className="row mb-1 d-flex justify-content-center">
                        <div className="col" style={{textAlign: 'center'}}>
                            <button className="btn btn-small btn-cancel-style2" onClick={() => $("#resendModal").hide()}>No, regresar</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div> 
  )
}
