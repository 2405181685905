import React, {
    useState,
    useEffect,
} from 'react'

const ProductCard = ({
    products,
    activeCards,
    history,
    arrCirclesImg,
    totalSliderInner,
}) => {

    const [cards, setCards] = useState([])
    useEffect(() => {
        let newCards = []
        // console.log(products)
        // console.log(activeCards)
        for (let x in products) {
            if (products[x].idCategory === 4 && activeCards.benefits) {
                newCards.push(products[x])
            }
            if (products[x].idCategory === 5 && activeCards.promotions) {
                newCards.push(products[x])
            }
        }
        let newCards2 = []
        let counter = totalSliderInner > 0 ? totalSliderInner : 0
        for (let x in newCards ) {
            if(counter === (arrCirclesImg.length)){
                newCards2.push({...newCards[x], imgCircle: arrCirclesImg[0].imgCircle, imgCircleStyle: arrCirclesImg[0].imgStyle, imgCircleId: counter })
                counter = 1
            }else{
                newCards2.push({...newCards[x], imgCircle: arrCirclesImg[counter].imgCircle, imgCircleStyle: arrCirclesImg[counter].imgStyle, imgCircleId: counter })
                counter++
            }
        }
        setCards(newCards2);
        // setCards(newCards)
    }, [products, activeCards,arrCirclesImg,totalSliderInner])

    const handleOnClickCard = product => {
        switch (product.idCategory) {
            case 4:
                history.push("/beneficios")
                break;
            case 5:
                history.push("/promociones")
                break;
            default:
                break;
        }
    }

    return (
        <>
            {cards.map((product, index) => (
                <div 
                    key={`F-1-${product.idCategory}`}
                    className="col-sm-12 col-md-6 col-lg-3 col-xxl-3 mb-1"
                >
                    <div 
                        className="card h-100 div-shadow-style-1 pointer"
                        onClick={() => handleOnClickCard(product)}
                    >
                        <div className="card-body py-0 d-flex flex-wrap align-content-center">
                            <img
                                src={product.imgCircle}
                                alt={product.nameCategory}
                                style={product.imgCircleStyle}
                            />
                            <div className="row h-100">
                                <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center justify-content-center p-0">
                                    <img className="img-card-responsive" src={product.imgCategory} alt={product.nameCategory} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-8 col-lg-8 pt-1 d-flex flex-wrap align-content-center text-justify">
                                    <p className='fs-1-563 font-weight-bold text-blue-sec '>{product.nameCategory}</p>
                                    <p className='fs-875 text-blue-sec '>{product.descCategory}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ProductCard