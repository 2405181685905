import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import generalReducer, { restoreGeneralAction } from './generalDuck';
import launchReducer, { restoreLaunchAction } from "./launchDuck";
import accesReducer, { restoreAccessAction } from './accessDunk'

let rootReducer = combineReducers({
    general: generalReducer,
    launch: launchReducer,
    access: accesReducer,
});

//TODO: Cambiar por productivo

//dev
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//prod
// const composeEnhancers = compose;

const generateStore = () => {
    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );
    //restablecer datos en refresh
    restoreGeneralAction()(store.dispatch);
    restoreLaunchAction()(store.dispatch);
    restoreAccessAction()(store.dispatch, store.getState);
    return store;
}

export default generateStore;