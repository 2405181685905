import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { dataHelp, chatStructure, nextSteps } from '../../../../data/dataHelp';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { getJwt } from "../../../../lib/auth";

import Chat from './Chat';

export const FloatDivHelper = ({
    setShowBtn,
    idProduct,
    idCategory,
    idSubCategory,
}) => {

    const { pathname } = useLocation();

    const [helpInfo, setHelpInfo] = useState({
        title: '',
        subtitle: '',
        paragraph: '',
        links: []

    });
    const [currentChatUsr, setCurrentChatUsr] = useState(0);
    const [chat, setChat] = useState([]);
    const [chatBot, setChatBot] = useState([]);
    const [chatUser, setChatUser] = useState([]);
    const [showChat, setShowChat] = useState(false);
    const [nextStep, setNextStep] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [lastData, setLastData] = useState({});
    useEffect(() => {
        let rol = parseInt(getJwt('rol'));
        const openManual = (section) => {
            const host = window.location.host;
            let url = '';
            switch (host) {
                case "localhost:3000":
                    url = 'http://127.0.0.1:5500/html/manual/index.html?';
                    break;
                case "frontend.development.guay.digital":
                    url = 'https://help.development.guay.digital/html/manual/index.html?';
                    break;
                case "frontend.staging.guay.digital":
                    url = 'https://help.staging.guay.digital/html/manual/index.html?';
                    break;
                case "somosguay.com":
                    url = 'https://help.somosguay.com/html/manual/index.html?';
                    break;
                default:
                    url = 'http://127.0.0.1:5500/html/manual/index.html?';
                    break;
            }
            window.open(`${url}${section}`);
        }
        let arr = [];
        switch (pathname) {
            case '/inicio-empresas':
                /* setHelpInfo({
                    ...dataHelp.administracionOrganizaciones
                }); */
                arr.push(chatStructure.inicioEmpresas.chatBot[0])
                arr.push(chatStructure.inicioEmpresas.chatUser[0])
                setChat(arr);
                setChatBot(chatStructure.inicioEmpresas.chatBot);
                setChatUser(chatStructure.inicioEmpresas.chatUser);
                setShowChat(true);
                setNextStep(nextSteps.inicioEmpresas.links);
                break;

            case '/inicio-empresas/registro-empresa':
                setHelpInfo({
                    ...dataHelp.administracionOrganizaciones
                });
                break;

            case '/administracion-usuarios':
                setHelpInfo({
                    ...dataHelp.administracionOrganizacionesAdministracionUsuarios
                });
                break;

            case '/administracion-colaboradores':
                /* setHelpInfo({
                    ...dataHelp.administracionOrganizacionesColaboradores
                }); */
                setNextStep(nextSteps.administracionColaboradores.links);
                arr.push(chatStructure.administracionColaboradores.chatBot[0])
                arr.push(chatStructure.administracionColaboradores.chatUser[0])
                setChat(arr);
                setChatBot(chatStructure.administracionColaboradores.chatBot);
                setChatUser(chatStructure.administracionColaboradores.chatUser);
                setShowChat(true);
                break;

            case '/tienda':
                if (rol === 2 || rol === 3 || rol === 5) {
                    setHelpInfo({
                        ...dataHelp.inicioTienda
                    });
                } else {
                    setHelpInfo({
                        ...dataHelp.inicioTiendaColab
                    });
                }
                break;

            case '/inicio-administrador':
                /* setHelpInfo({
                    ...dataHelp.administracion
                }); */
                /* setChat(
                    <DiagnosisHelp
                        setDisplayHelp={setDisplayHelp}
                        openManual={openManual}
                    />
                ) */
                arr.push(chatStructure.inicioAdministrador.chatBot[0])
                arr.push(chatStructure.inicioAdministrador.chatUser[0])
                setChat(arr);
                setChatBot(chatStructure.inicioAdministrador.chatBot);
                setChatUser(chatStructure.inicioAdministrador.chatUser);
                setShowChat(true);
                break;

            case '/administracion-servicios':
                setHelpInfo({
                    ...dataHelp.administracionConfiguraLanzamientos
                });
                break;

            case '/comunicacion':
                setHelpInfo({
                    ...dataHelp.administracionConfiguraLanzamientos
                });
                break;

            case '/gestion-documental':
                /* setHelpInfo({
                    ...dataHelp.administracionMonitoreoAvances
                }); */
                setNextStep(nextSteps.ejecucion.links);
                arr.push(chatStructure.gestionDocumental.chatBot[0])
                arr.push(chatStructure.gestionDocumental.chatUser[0])
                setChat(arr);
                setChatBot(chatStructure.gestionDocumental.chatBot);
                setChatUser(chatStructure.gestionDocumental.chatUser);
                setShowChat(true);
                break;

            case '/resultados':
                setHelpInfo({
                    ...dataHelp.administracionResultadosAnaliticos
                });
                break;

            case '/acciones':
                if (idProduct === 1 && idCategory === 2 && idSubCategory === 0) { //producto de clima laboral
                    setHelpInfo({
                        ...dataHelp.administracionAccionesProtocolos
                    });
                } else {
                    /* setHelpInfo({
                        ...dataHelp.administracionAccionesSiguientesPasos
                    }); */
                    setNextStep(nextSteps.acciones.links);
                    arr.push(chatStructure.acciones.chatBot[0])
                    arr.push(chatStructure.acciones.chatUser[0])
                    setChat(arr);
                    setChatBot(chatStructure.acciones.chatBot);
                    setChatUser(chatStructure.acciones.chatUser);
                    setShowChat(true);
                }
                break;

            case '/cumplimiento':
                setHelpInfo({
                    ...dataHelp.administracionCumplimientoCumplimiento
                });
                break;

            case '/perfil':
                if (rol === 2 || rol === 3 || rol === 5) {
                    setHelpInfo({
                        title: <h1 className='text-pink-mine'>Perfil</h1>,
                        subtitle: '',
                        paragraph: <p className='text-center'>Si tienes duda del funcionamiento ingresa al manual digital de perfil <br /> <span className='pointer text-manual-guay blink' onClick={() => openManual('dmFsdWU9cGVyZmls')}>Saber más</span></p>,
                        links: []
                    })
                } else {
                    setHelpInfo({
                        title: <h1>Hola {getJwt('userName')}</h1>,
                        subtitle: '',
                        paragraph: 'Yo me encargaré de brindarte ayuda a través de tu proceso',
                        links: []
                    })
                }
                break;

            default:
                if (rol === 2 || rol === 3 || rol === 5) {
                    setHelpInfo({
                        title: <h1>Hola {getJwt('userName')}</h1>,
                        subtitle: '',
                        paragraph: <p className='text-center'>Cualquier duda que tengas del funcionamiento de la plataforma puedes ingresar a este manual digital:<br />
                            <br />
                            <span
                                className='pointer text-manual-guay blink'
                                onClick={() => {
                                    window.open(global.urlSupport)
                                }}
                            ><u>Manual guay</u></span>
                            <br />
                            <span
                                className='pointer text-manual-guay blink'
                                onClick={() => {$('#tourModal').show()}}
                            ><u>Tutorial plataforma</u></span>
                        </p>,
                        links: [],
                    })
                } else {
                    setHelpInfo({
                        title: <h1>Hola {getJwt('userName')}</h1>,
                        subtitle: '',
                        paragraph: 'Yo me encargaré de brindarte ayuda a través de tu proceso',
                        links: []
                    })
                }
                break;
        }

        return () => {

        }
        // eslint-disable-next-line
    }, [pathname])

    /* return (
        <div
            style={{ position: 'absolute', bottom: '10vh', right: '5vw', backgroundColor: '#fff', borderRadius: '25px', zIndex: '99', width: '25vw' }}
            className="with-box-shadow animate__animated hidden"
            id="containerHelp"
        >
            <div className="container-fluid">
                <button
                    type="button"
                    className="close pointer"
                    style={{ position: 'absolute', top: '0', right: '-20px' }}
                    onClick={() => {
                        $('#containerHelp').removeClass('animate__backInRight');
                        $('#containerHelp').addClass('animate__backOutRight');
                        setDisplayHelp(true);
                    }}
                >&times;</button>
                <div className="row font-medium-1 my-1 px-1">
                    <div className="col-12 text-center">
                        {helpInfo.title}
                    </div>
                    <div className="col-12 text-center">
                        {helpInfo.subtitle}
                    </div>
                    <div className="col-12 bg-gray-guay rounded p-1">
                        {helpInfo.paragraph}
                    </div>
                    <div className="col-12">
                        {helpInfo.links.length !== 0 && <><span>Siguientes pasos:</span><br /></>}
                        {helpInfo.links.map((link, idx) => (
                            <Fragment key={idx}><Link to={link.link}><u>{link.text}</u></Link><br /></Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    ) */

    const validationOnChange = function ({ target }) {
        if (target.value.length <= 1) {
            setInputValue(target.value)
        }
    }

    const validationOnKeyDown = function (event) {
        const options = lastData.text.map(option => option.option);
        const regex = new RegExp(`[${options.toString()}\b\r\n]`);
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
        if (event.keyCode === 13 && inputValue.length === 1) {
            submitToChat();
        }
    }

    const validationOnPaste = function (event) {
        event.preventDefault();
    }

    const submitToChat = function () {
        if (inputValue === "0") {
            $('#containerHelp').removeClass('animate__backInRight');
            $('#containerHelp').addClass('animate__backOutRight');
            setShowBtn(true)
            setInputValue("");
        } else {
            let newArr = [];
            let arrAdded = [];
            for (let x in chat) {
                newArr.push(chat[x]);
            }
            for (let x in chatBot) {
                if (chatBot[x].answer === parseInt(inputValue)) {
                    newArr.push(chatBot[x]);
                    arrAdded.push(chatBot[x]);
                }
            }
            if (arrAdded[0].idUser !== 0) {
                for (let x in chatUser) {
                    if (chatUser[x].idUser === arrAdded[0].idUser) {
                        newArr.push(chatUser[x]);
                    }
                }
            }
            if (arrAdded[0].lastAns === 1 && lastData.lastUser === 1) {
                newArr.push(lastData);
            } else {
                setCurrentChatUsr(currentChatUsr + 1);
            }
            setChat(newArr);
            setInputValue("");
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: '25vh',
                right: '50px',
                backgroundColor: '#fff',
                borderRadius: '25px',
                zIndex: '99',
                width: '25vw',
                maxHeight: '75vh'
            }}
            className="with-box-shadow animate__animated hidden"
            id="containerHelp"
        >
            <div className="container-fluid">
                <button
                    type="button"
                    className="close pointer font-large-2"
                    style={{
                        position: 'absolute',
                        top: '-30px',
                        right: '-20px'
                    }}
                    onClick={() => {
                        setShowBtn(true)
                        $('#containerHelp').removeClass('animate__backInRight');
                        $('#containerHelp').addClass('animate__backOutRight');
                    }}
                >&times;</button>
                <div className='row'>
                    {
                        showChat ? (
                            <Fragment>
                                <div className='col-12 p-0 m-0'>
                                    <img width="100%" src="./../img/chat_portal.png" alt="chat_portal" />
                                </div>
                                <Chat
                                    currentChatUsr={currentChatUsr}
                                    setCurrentChatUsr={setCurrentChatUsr}
                                    chat={chat}
                                    setChat={setChat}
                                    chatBot={chatBot}
                                    chatUser={chatUser}
                                    setShowBtn={setShowBtn}
                                    setLastData={setLastData}
                                />
                                <div className="col-12 d-flex justify-content-center align-items-center p-1">
                                    <input type="text" className="input-chat p-1" value={inputValue}
                                        onChange={validationOnChange}
                                        onKeyDown={validationOnKeyDown}
                                        onPaste={validationOnPaste}
                                    />
                                    <i className='ft-chevron-right pointer font-large-1 mb-1' onClick={submitToChat}></i>
                                </div>
                                <div className="col-12 p-1">
                                    {nextStep.length !== 0 && <><span>Siguientes pasos:</span><br /></>}
                                    {
                                        nextStep.map((link, idx) => (
                                            <Fragment key={idx}><Link to={link.link} className="font-medium-1"><u>{link.text}</u></Link><br /></Fragment>
                                        ))
                                    }
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className='col-12 d-flex justify-content-center align-items-end p-0 mb-1'>
                                    <div className='TitleSubtitleChatStyle text-center'>
                                        {helpInfo.title}
                                        {helpInfo.subtitle}
                                    </div>
                                    <img width="100%" src="./../img/chat_portal.png" alt="chat_portal" />
                                </div>
                                <div className='col-12 p-0 m-0 div-help'>
                                    <div className="row font-medium-1 my-1 px-1">
                                        <div className="col-12 bg-gray-guay rounded p-1">
                                            {helpInfo.paragraph}
                                        </div>
                                        <div className="col-12">
                                            {helpInfo.links.length !== 0 && <><span>Siguientes pasos:</span><br /></>}
                                            {helpInfo.links.map((link, idx) => (
                                                <Fragment key={idx}><Link to={link.link} className="font-medium-1"><u>{link.text}</u></Link><br /></Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }

                </div>
            </div>
        </div>
    )
}
