/*
 File Name: Monitor_model.js
 Description: Manage Monitor's API Request
 guay dev's team
*/

import axios from "axios";

/**
 * 
 * @param {int || string} enterpriseId 
 * @param {int || string} idUser 
 * @param {int || string} idRol 
 * @returns Array evidencia del avance de las encuestas
 */
export const getAdByEnterpriseAll = async (enterpriseId, idUser, idRol) => {
    const data = new FormData();
    data.append("request", `{enterprise: ${enterpriseId}, idUser: ${idUser}, idRol: ${idRol}}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "enterprise/launch/getAdByEnterpriseAll",
            method: 'POST',
            data,
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * 
 * @param {int || string} enterpriseId 
 * @param {int || string} idGroup 
 * @param {int || string} idUser 
 * @param {int || string} idRol 
 * @returns object regresa el total de colaboradores con encuestas concluidas, en progreso, inconclusas, y regresa array de colaboradores que no han contestado
 */
export const getDatabyLaunchMonitorynAll = async (enterpriseId, idGroup, idUser, idRol) => {
    const data = new FormData();
    data.append("request", `{enterprise: ${enterpriseId}, group: ${idGroup}, idUser: ${idUser}, idRol: ${idRol}}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "enterprise/launch/getDatabyLaunchMonitorynAll",
            method: 'POST',
            data,
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para cerrar el lanzamiento
 * @param {int || string} enterpriseId 
 * @param {int || string} idAdminLaunch 
 * @param {int || string} idGroup 
 * @returns Response code
 */
export const setEndOfLaunch = async (enterpriseId, idAdminLaunch, idGroup) => {
    const data = new FormData();
    data.append("request", `{enterpriseId: ${enterpriseId}, idAdminLaunch: ${idAdminLaunch}, idGroup: ${idGroup}}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "enterprise/launch/setEndOfLaunch",
            method: 'POST',
            data,
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para pausar el ejericio de FH
 * @param {int || string} enterpriseId 
 * @param {int || string} idGroup 
 * @returns object response code
 */
export const setPauseSurveyFH = async (enterpriseId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/launch/setPauseSurveyFH?idEnterprise=${enterpriseId}&idGroup=${idGroup}`,
            method: 'POST',
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para activar el ejericio de FH
 * @param {int || string} enterpriseId 
 * @param {int || string} idGroup 
 * @returns object response code
 */
export const setActivateSurveyFH = async (enterpriseId, idGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/launch/setActivateSurveyFH?idEnterprise=${enterpriseId}&idGroup=${idGroup}`,
            method: 'POST',
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener los lanzamientos por centro de trabajo
 * @param {int} enterpriseId 
 * @param {int} branchId 
 * @param {int || string} idUser 
 * @param {int || string} idRol 
 * @returns response object
 */
export const getAdByEnterprisesBranchAll = async (enterpriseId, branchId, idUser, idRol) => {
    const data = new FormData();
    data.append("request", `{enterprise: ${enterpriseId}, branch: ${branchId}, idUser: ${idUser}, idRol: ${idRol}}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "enterprise/launch/getAdByEnterprisesBranchAll",
            method: 'POST',
            data,
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener enlace de descarga de pdf
 * @param {int} enterpriseId 
 * @param {int} groupId 
 * @param {int || string} idUser 
 * @param {int || string} idRol 
 * @returns response object
 */
export const downloadPDFMonitoryngAll = async (enterpriseId, groupId, idUser, idRol) => {
    const data = new FormData();
    data.append("request", `{enterprise: ${enterpriseId}, group: ${groupId}, idUser: ${idUser}, idRol: ${idRol}}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "enterprise/launch/downloadPDFMonitoryngAll",
            method: 'POST',
            data,
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener los colaboradores de un ejercicio
 * @param {INT} enterpriseId 
 * @param {INT} groupId 
 * @returns 
 */
export const getCollabsByLaunch = async (enterpriseId, groupId) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/launch/getCollabsByLaunch?idEnterprise=${enterpriseId}&idGroup=${groupId}`,
            method: 'GET',
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para activar / inactivar / eliminar colaboradores de un ejercicio en monitoreo
 * @param {INT} enterpriseId 
 * @param {INT} groupId 
 * @param {ARRAY} collabList 
 * @param {INT} status 
 * @returns 
 */
export const setUpdateCollabsByLaunch = async (enterpriseId, groupId, collabList, status) => {
    const data = new FormData();
    data.append("request", `{idEnterprise: ${enterpriseId}, idGroup: ${groupId}, collabsList: ${collabList}, status: ${status}}`);
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "enterprise/launch/setUpdateCollabsByLaunch",
            method: 'POST',
            data,
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const downloadExcelMonitoringAll = async (enterpriseId, groupId) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/launch/downloadExcelMonitoringAll?idEnterprise=${enterpriseId}&idGroup=${groupId}`,
            method: 'GET',
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const downloadExcelMonitoringByBusinessGroup = async (idEnterpriseGroup) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/launch/downloadExcelMonitoringByBusinessGroup?idEnterpriseGroup=${idEnterpriseGroup}`,
            method: 'GET',
            redirect: 'follow',
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};