import React, { useState } from 'react';
// API calls
import { tokenFree } from '../Models/Users_model';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import PropTypes from 'prop-types';

const ModalToken = ({ userID, setShowComponent }) => {
    const [token, setToken] = useState('');
    return (
        <div
            className="modal"
            id="modalToken"
            style={{ backgroundColor: 'rgba(23,32,42,0.5)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div
                    className="modal-content"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body">
                        <div className="card">
                            <div className="card-body">
                                <p className="text-center">Ingresa el código envíado al correo electrónico ingresado en el formulario.</p>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="token"
                                        name="token"
                                        value={token}
                                        onChange={e => {
                                            setToken(e.target.value);
                                        }}
                                    />
                                </div>
                                <button
                                    className="btn btn-sm btn-blue-3 float-right"
                                    onClick={() => {
                                        if (token.length > 0) {
                                            tokenFree(token, userID).then(response => {
                                                if (response) {
                                                    switch (response.code) {
                                                        case 1:
                                                        case 200:
                                                            $('#modalToken').hide();
                                                            setShowComponent(2);
                                                            break;
                                                        case 1001:
                                                            toastr.error('El token ya fue registrado o es incorrecto.', '¡Ooops!');
                                                            break;
                                                        default:
                                                            toastr.error('Problemas técnicos, contacta al equipo de soporte. Error: ' + response.message, '¡Ooops!');
                                                            break;
                                                    }
                                                } else {
                                                    toastr.error('Problemas con el servidor. Por favor intentar más tarde.', '¡Ooops!');
                                                }
                                            });
                                        }
                                    }}>Enviar token</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ModalToken.propTypes = {
    userID: PropTypes.number.isRequired, 
    setShowComponent: PropTypes.func.isRequired
}

export default ModalToken;
