import React, { Fragment, useEffect, useState } from 'react';
import Header from '../NewHome/headerColaborador';
import Footer from '../footerApp';
import Menu from "../Menu";
import { redirectIfNotAuthenticated2, getJwt } from "../../../lib/auth";
import { getProductsguayki, getResourcesguayki, getSpecificResourceguayki } from '../Models/Repository_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import parse from "html-react-parser";
import 'animate.css';
/* import BannerModal from '../BannerModal'; */
/* import $ from "jquery"; */
import BannerGuaykipedia from '../BannerGuaykipedia';
import GuaykipediaMenu from './GuaykipediaMenu';
import SurveyModal from '../SurveyModal/SurveyModal';
import HelperApp from '../HelperApp';

const GuaykipediaController = () => {

    const [guaypediaState, setGuaypediaState] = useState({
        products: [],
        activeProduct: {
            title: "Producto",
            idCategory: 0
        },
        activeSection: {
            title: "Sección",
            idSectionguaykipedia: 0
        },
        listResources: [],
        resourceSpecific: "",
        menu: [],
    });
    /* const [count, setCount] = useState(0); */

    useEffect(() => {
        getProductsguayki().then(response => {
            switch (response?.code) {
                case 1:
                    setGuaypediaState(state => ({
                        ...state,
                        products: response.products,
                        activeProduct: {
                            title: response.products[0].title,
                            idCategory: response.products[0].idCategory
                        },
                        activeSection: {
                            title: response.products[0].subCategories[0].title,
                            idSectionguaykipedia: response.products[0].subCategories[0].idSectionguaykipedia
                        },
                        menu: response.products,
                    }));
                    getResourcesguayki(response.products[0].subCategories[0].idSectionguaykipedia).then(response => {
                        switch (response?.code) {
                            case 1:
                                setGuaypediaState(state => ({
                                    ...state,
                                    listResources: response.listResources,
                                }));
                                break;
                            case 99:
                                toastr.error("Ocurrió un error al obtener toda la información de los recursos (abstracts)", "Error 99");
                                break;

                            case 1001:
                                toastr.error("No existen recursos disponibles (abstracts)", "Error 1001");
                                break;

                            default:
                                toastr.error("Ocurrió un error con el servidor.");
                                break;
                        }
                    })
                    break;

                case 99:
                    toastr.error("Ocurrió un error al obtener toda la información de los recursos (menú)", "Error 99");
                    break;

                case 1001:
                    toastr.error("No existen recursos disponibles (menú)", "Error 1001");
                    break;

                default:
                    toastr.error("Ocurrió un error con el servidor.");
                    break;
            }
        })
        return () => {
        }
    }, [])

    const { products, activeProduct, activeSection, listResources, resourceSpecific,
        menu } = guaypediaState;

    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.section && domNode.attribs.custom) {
                return <button className={domNode.attribs.classname} onClick={() => handleClickVerMas(domNode.attribs.section, `idResource-${domNode.attribs.custom}`)} >Conoce más</button>;
            }
        }
    };

    const handleClickGuaykipedia = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const handleClickProduct = () => {
        getProductsguayki().then(response => {
            switch (response?.code) {
                case 1:
                    setGuaypediaState(state => ({
                        ...state,
                        products: response.products,
                        activeProduct: {
                            title: response.products[0].title,
                            idCategory: response.products[0].idCategory
                        },
                        activeSection: {
                            title: response.products[0].subCategories[0].title,
                            idSectionguaykipedia: response.products[0].subCategories[0].idSectionguaykipedia
                        },
                        resourceSpecific: ""
                    }));
                    getResourcesguayki(response.products[0].subCategories[0].idSectionguaykipedia).then(response => {
                        switch (response?.code) {
                            case 1:
                                setGuaypediaState(state => ({
                                    ...state,
                                    listResources: response.listResources,
                                }));
                                break;
                            case 99:
                                toastr.error("Ocurrió un error al obtener toda la información de los recursos (abstracts)", "Error 99");
                                break;

                            case 1001:
                                toastr.error("No existen recursos disponibles (abstracts)", "Error 1001");
                                break;

                            default:
                                toastr.error("Ocurrió un error con el servidor.");
                                break;
                        }
                    })
                    break;

                case 99:
                    toastr.error("Ocurrió un error al obtener toda la información de los recursos (menú)", "Error 99");
                    break;

                case 1001:
                    toastr.error("No existen recursos disponibles (menú)", "Error 1001");
                    break;

                default:
                    toastr.error("Ocurrió un error con el servidor.");
                    break;
            }
        })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const handleClickSection = (idCategory, idSectionguaykipedia) => {
        getResourcesguayki(idSectionguaykipedia).then(response => {
            switch (response?.code) {
                case 1:
                    setGuaypediaState(state => ({
                        ...state,
                        activeProduct: {
                            title: products.find(product => product.idCategory === idCategory).title,
                            idCategory: products.find(product => product.idCategory === idCategory).idCategory
                        },
                        listResources: response.listResources,
                        activeSection: {
                            title: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === idSectionguaykipedia).title,
                            idSectionguaykipedia: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === idSectionguaykipedia).idSectionguaykipedia
                        },
                        resourceSpecific: "",
                    }));
                    handleClickGuaykipedia();
                    break;

                case 99:
                    toastr.error("Error al obtener los recursos (abstracts) disponibles", "Error 99")
                    break;

                case 1001:
                    toastr.error("No existen recursos (abstracts) disponibles", "Error 1001")
                    break;

                default:
                    toastr.error("Ocurrió un error desconocido.");
                    break;
            }
        })
    }

    const handleClickVerMas = (id, idResource, idCategory) => {
        /* $('#modalBanner').show();
        setCount(0); */
        getSpecificResourceguayki(id).then(response => {
            switch (response?.code) {
                case 1:
                    if (idCategory) {
                        setGuaypediaState(state => ({
                            ...state,
                            resourceSpecific: response.resource,
                            activeSection: {
                                title: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === parseInt(id)).title,
                                idSectionguaykipedia: products.find(product => product.idCategory === idCategory).subCategories.find(category => category.idSectionguaykipedia === parseInt(id)).idSectionguaykipedia
                            },
                        }));
                    } else {
                        setGuaypediaState(state => ({
                            ...state,
                            resourceSpecific: response.resource,
                        }));
                    }
                    setTimeout(() => {
                        window.scrollTo({
                            top: document.getElementById(idResource).offsetTop,
                            behavior: "smooth"
                        });
                    }, 100);
                    break;

                case 99:
                    toastr.error("Error al obtener los recursos (especifico) disponibles", "Error 99")
                    break;

                case 1001:
                    toastr.error("No existen recursos (especifico) disponibles", "Error 1001")
                    break;

                default:
                    break;
            }
        })
    }
console.log(sessionStorage.getItem('surveyModal'))
    if (redirectIfNotAuthenticated2()) {
        return (
            <div className="app-content new-guay-plataform bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header col-12 bg-light-gray-giki sticky">
                            <div className='col-12 mx-auto'>
                                <div className='row'>
                                    <div className="col-md-6 d-flex justify-content-start align-items-center">
                                        <img
                                            src='https://storage.googleapis.com/guay-api-production/Operacion/Guaykipedia/Logo_guaykipedia.png'
                                            alt='logo guaykipedia'
                                            className='img-fluid img-height-50'
                                        />
                                    </div>
                                    <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                                        <div className="row breadcrumbs-top d-inline-block">
                                            <div className="breadcrumb-wrapper col-12">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item font-medium-3"><a href={getJwt("rol") === "2" || getJwt("rol") === "3" || getJwt("rol") === "4" ? ("/inicio-colaborador") : ("/inicio-usuario")}>Inicio</a>
                                                    </li>
                                                    <li className="breadcrumb-item font-medium-3 pointer" onClick={handleClickGuaykipedia}>guaykipedia
                                                    </li>
                                                    <li className="breadcrumb-item font-medium-3 pointer"
                                                        onClick={handleClickProduct}
                                                    >
                                                        {activeProduct?.title}
                                                    </li>
                                                    <li className="breadcrumb-item active font-medium-3 pointer"
                                                        onClick={() => {
                                                            handleClickSection(activeProduct.idCategory, activeSection.idSectionguaykipedia)
                                                        }}
                                                    >
                                                        {activeSection?.title}
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <BannerGuaykipedia
                                guaypediaState={guaypediaState}
                            />
                            {
                                sessionStorage.getItem('surveyModal') === null ? (
                                    <SurveyModal />
                                ) : (
                                    sessionStorage.getItem('surveyModal') !== '1' ? (
                                        <SurveyModal />
                                    ) : (null)
                                )
                            }
                            <div className='col-12 bg-white py-1'>
                                <div className='col-10 mx-auto'>
                                    <GuaykipediaMenu
                                        menu={menu}
                                        activeProduct={activeProduct}
                                        handleClickSection={handleClickSection}
                                        handleClickVerMas={handleClickVerMas}
                                    />
                                </div>
                            </div>
                            <div className='col-12 bg-white'>
                                <div className='col-11 mx-auto'>
                                    <div className='row'>
                                        {resourceSpecific !== "" ?
                                            parse(resourceSpecific)
                                            :
                                            listResources.map(resource => {
                                                if (resource.firstTemplate !== undefined) {
                                                    return <Fragment key={resource.idResourcesguaykipedia}>{parse(resource.firstTemplate, options)}</Fragment>
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <span className="scroll-to-target scroll-to-top pointer" onClick={handleClickGuaykipedia}><i className="la la-angle-up"></i></span>
                            {/* <BannerModal
                                count={count}
                                setCount={setCount}
                            /> */}
                            <Footer />
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default GuaykipediaController;
