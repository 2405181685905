import React from 'react'
// TODO: Generic func
import { convertDateToWords } from '../../../../helpers/helpers'
// TODO: Tools
import $ from 'jquery'

const InfoModal = ({
    collaboratorsTotal,
    branchesTotal,
    areasTotal,
    datePickerValue,
    finishUploadProcess,
    convertDateToYYYYMMDD,
}) => {
    return (
        <div
            className='modal'
            id='infoModal'
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop='false'
        >
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-body'>
                        <div className='col-12 mb-1 d-flex justify-content-center'>
                            <img
                                src='./../img/collabImages/iconos_carga_colaboradores-12.png'
                                style={{
                                    height: '100px',
                                    width: '100px',
                                }}
                                alt='icono'
                            />
                        </div>
                        <div className='col-12 mb-1'>
                            {convertDateToYYYYMMDD(new Date()) === convertDateToYYYYMMDD(datePickerValue) ? (
                                <p className='text-center f-subtitle-sm'>Se enviará el correo de bienvenida <span className='font-weight-bold'> al presionar Enviar</span></p>
                            ) : (
                                <p className='text-center f-subtitle-sm'>Se enviará el correo de bienvenida el <span className='font-weight-bold'>{convertDateToWords(datePickerValue)} a las 15:00 hrs</span></p>
                            )}
                            <p className='text-center f-subtitle-sm'>A los siguientes colaboradores:</p>
                            <p className='text-center f-normal font-weight-bold m-0'>{collaboratorsTotal} colaboradores</p>
                            <p className='text-center f-normal font-weight-bold m-0'>{branchesTotal} centros de trabajo</p>
                            <p className='text-center f-normal font-weight-bold m-0'>{areasTotal} áreas</p>
                        </div>
                        <div className='col-12'>
                            <button
                                className='btn btn-sm f-normal bg-secundary-1 text-white'
                                onClick={() => {
                                    $('#infoModal').hide()
                                }}
                            >Regresar</button>
                            <button
                                className='btn btn-sm f-normal bg-primary-1 text-white float-right'
                                onClick={() => {
                                    if (convertDateToYYYYMMDD(new Date()) === convertDateToYYYYMMDD(datePickerValue)) {
                                        finishUploadProcess(1)
                                    } else {
                                        finishUploadProcess(2)
                                    }
                                }}
                            >Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoModal