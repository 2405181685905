import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export const SupportFormats = ({ supportDocument, setProtocolState, previewFilePDF }) => {

    const urlFormatter = (cell, row) => {
        return <a onClick={() => previewFilePDF(cell)} rel="noreferrer"><i className='ft-download text-base-guay' /></a>;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="d-flex align-items-center">
                    <i className="btn btn-guay ft-arrow-left mr-5 font-medium-2 py-0" onClick={() => setProtocolState(0)} />
                    <br />
                    <h1 className="font-medium-4">Formatos de Apoyo</h1>
                    <hr />
                </div>
            </div>
            <div className="col-12 mt-2">
                <BootstrapTable data={supportDocument} height='240' scrollTop={'Top'} search={true} searchPlaceholder={"Documento..."}>
                    <TableHeaderColumn dataField='idSupportDocument' isKey hidden>idSupportDocument</TableHeaderColumn>
                    <TableHeaderColumn dataField='url' dataFormat={urlFormatter} width="7%"><i className='ft-download' /></TableHeaderColumn>
                    <TableHeaderColumn dataField='title'>Documento</TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    );
}
