import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// TODO: API Calls
import {
    getProtocolFCHTK,
    updFinishFCHTK,
} from '../../Models/ClinicalFilterHTK_model';
// TODO: Local Components
import LoaderApp from '../../LoaderApp';
import SurveyComplete from './SurveyComplete';
// TODO: Dependencies
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const Diagnosis = () => {
    //getters & setters
    // Boolean
    const [loading, setLoading] = useState(true);
    const [shareAllowed, setShareAllowed] = useState(false);
    const [showDiagnosis, setShowDiagnosis] = useState(false);
    const [inProcess, setInProcess] = useState(false);

    // Object
    const [diagnosisData, setDiagnosisData] = useState({});
    const [generalData, setGeneralData] = useState({});

    // Obtener parametros de la liga
    const { identity } = useParams();
    useEffect(() => {
        let parser = "0-0-0-0";
        // Desencriptar Base64
        try {
            parser = atob(identity);
        } catch (error) {
            console.error(error);
        }
        parser = parser.split("-");
        // Asignar valores
        const idCollaborator = parser[0];
        const idEnterprise = parser[1];
        const idCollabSurveyControlFCHTK = parser[2];
        let objData = {
            idEnterprise: parseInt(idEnterprise),
            idCollabSurveyControlFCHTK: parseInt(idCollabSurveyControlFCHTK),
            idCollaborator: parseInt(idCollaborator),
        }
        if (Object.keys(objData).length > 0) {
            // Obtenemos la informacion general
            setGeneralData(objData);
            // Obtener resumen de diagnostico
            getProtocolFCHTK(objData).then(response => {
                if (response) {
                    if (response.validation) {
                        if (response.data.code === 2) {
                            // Se quita el loader
                            setLoading(false);
                            return;
                        }
                        // Guarda los datos del resumen del diagnóstico
                        console.log('response: ', response);
                        const arrDescription = response.data.description ? response.data.description.split("|") : [];
                        setDiagnosisData({
                            protocol: response.data.protocol,
                            icon: response.data.icon ? response.data.icon : '',
                            description: arrDescription,
                            title: response.data.title,
                            status: response.data.status,
                            critic: response.data.critic
                        });
                        // Se muestra el diagnóstico
                        setShowDiagnosis(true);
                        // Se quita el loader
                        setLoading(false);
                    }
                }
            })
        }
    }, [identity]);

    // Función para manejar el cambio de valor de compartir resultados
    const handleChangeSareAllowed = (event) => {
        console.log('event.target.checked: ',event.target.checked);
        setShareAllowed(event.target.checked);
        event.target.checked === false && setShareAllowed(false);
    };

    const handleFinish = () => {
        // Validación para permitir guardar otra respuesat mientras está en proceso de guardado
        if (inProcess) {
            return;
        }
        const requestObj = {
            ...generalData,
            anonymous: shareAllowed ? 1 : 0,
        };
        updFinishFCHTK(requestObj).then(response => {
            if (response) {
                if (response.validation) {
                    // Redirecciona a Preguntas Extra
                    setShowDiagnosis(false);
                } else {
                    // Actualiza la bandera de proceso para permitir otra petición
                    setInProcess(false);
                }
            } else {
                // Actualiza la bandera de proceso para permitir otra petición
                setInProcess(false);
            }
        });
    }

    return (
        loading ? (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-5">
                <div className="row">
                    <LoaderApp />
                    <div className="col-12">
                        <p className="fs-1-2em text-center">Espera unos segundos, estamos obteniendo el Resumen de tu Diagnóstico. Favor de no cerrar la ventana.</p>
                    </div>
                </div>
            </div>
        ) : showDiagnosis ? (
            <div className="col-12 bg-white-smoke">
                <div className="row">
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto my-1'>
                        <div className='card my-0'>
                            <div className='card-body px-0'>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center'>
                                    <img
                                        className='img-fluid'
                                        src='../img/filtroClinico/6-ResumenDiagnostico.png'
                                        alt='ResumenDiagnostico'
                                    />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center">
                                    <p className="fs-1-3em">De acuerdo con tus respuestas presentas un cuadro de:</p>
                                </div>
                                {
                                    diagnosisData.critic === 1 || diagnosisData.status === 3 ? (
                                        <>
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center border-top-primary-cian border-bottom-primary-cian py-1">
                                                <p className="fs-1-6em font-weight-bold m-0 text-uppercase">{diagnosisData.title}</p>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center py-1'>
                                                <img
                                                    src="../img/filtroClinico/resumen/CirculoDepresionLeve.png"
                                                    className="img-fluid"
                                                    alt='Depresion'
                                                />
                                            </div>
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                                <p className="fs-1-3em text-center">Mientras tanto te compartimos el siguiente <span className='font-weight-bold'>plan diseñado para tu apoyo</span>.</p>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center border-bottom-top-pink py-1">
                                                <p className="fs-1-6em font-weight-bold m-0 text-uppercase">{diagnosisData.title}</p>
                                            </div>
                                            {diagnosisData.description.length > 0 && (
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 bg-pink-guay py-1'>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"><p className="fs-1-2em font-weight-bold">Puedes experimentar:</p></div>
                                                    {diagnosisData.icon !== '' &&
                                                        <div className="col-2 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-center flex-wrap align-items-center">
                                                            <img
                                                            src={diagnosisData.icon}
                                                            className="img-fluid"
                                                            alt='icono'
                                                            />
                                                        </div>
                                                    }
                                                    <div className={diagnosisData.icon !== '' ? "col-10 col-md-10 col-lg-10 col-xl-10 d-flex justify-content-start" : "col-12 d-flex justify-content-center"}>
                                                        <ul className='font-weight-bold fs-1-2em px-0'>
                                                            {diagnosisData.description.map((data,i) => {
                                                                return (
                                                                    <li key={i}><span className='font-weight-normal'>{data}</span></li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                            <div className='col-10 col-lg-11 col-xl-11 mt-1 d-flex justify-content-center flex-wrap align-items-center'>
                                                <img
                                                    className='img-fluid'
                                                    src='../img/filtroClinico/resumen/CirculoEmpiezaCambio.png'
                                                    alt='CirculoEmpiezaCambio'
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                
                                <div className="col-12 fs-1em d-flex justify-content-center">
                                    <button className="btn btn-white-pink bg-white rounded-1 text-blue-sec font-weight-bold fs-1em" onClick={() => diagnosisData.protocol !== '' && window.open(diagnosisData.protocol, '_blank')}>
                                        <img
                                        src="../img/filtroClinico/IconoProtocoloIntervencion.png"
                                        className="icon-img-35"
                                        alt='protocolo'
                                        />
                                        Plan de acción
                                    </button>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 fs-1-3em d-flex justify-content-center">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <FormControlLabel value={true}
                                                control={
                                                    <Checkbox icon={<i className="ft ft-circle text-blue-sec align-middle" />}
                                                        checkedIcon={<i className="ft ft-check-circle text-blue-sec align-middle" />}
                                                        checked={shareAllowed}
                                                        onChange={handleChangeSareAllowed}
                                                        size="small" />
                                                }
                                                label={<span className='fs-1-2em'>Deseo compartir mi resultado a la persona encargada de proveer apoyo en mi organización</span>} />
                                        </div>
                                    
                                </div>
                                <div className='col d-flex justify-content-center'>
                                    <button
                                        className='btn-sm bg-primary-1 text-white font-weight-bold rounded-pill-1 px-3 fs-1-3em'
                                        onClick={() => {
                                            setInProcess(true);
                                            handleFinish();
                                        }}
                                    >Finalizar</button>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        ): (
            <SurveyComplete />
        )
    )
}

export default Diagnosis
