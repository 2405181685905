import axios from "axios";
/*
* Model for HTK Module
*/


/*
 Validate User HTK
*/ 
export const getDataUsrHTK = async (data) => {
    try {
      let formData = new FormData();
      formData.append("request", JSON.stringify(data));
      let result  = await axios({
          url: global.base_url + "healthTracking/validationAccesHTK",
          method: 'POST',
          data: formData,
        headers: {
          'Content-Type': 'multipart/form-data;',
          Authorization: global.tokenAuth
        }
      });
        if (result.data) {
            return result.data;
          }   
    } catch (error) {
      console.log(error);
    }
}
export const setDataUsrHTK = async (data) => {
    try {
      let result  = await axios({
          url: global.base_url + "healthTracking/saveresponsesHTK",
          method: 'POST',
          data: data,
        headers: {
          'Content-Type': 'multipart/form-data;',
          Authorization: global.tokenAuth
        }
      });
        if (result.data) {
            return result.data;
          }   
    } catch (error) {
      console.log(error);
    }
}