import React, { useState, useEffect } from "react";
import DropZone from "./DropZone";
import { getTotalNumberCollaborators } from '../../Models/Collaborators_model';
import LoaderApp from "../../LoaderApp";
import { getJwt } from "../../../../lib/auth";
import { useHistory } from "react-router-dom";
import { checkEnterpriseBenefit } from "../../Models/BenefitsModel";


const UploadCollab = ({ actualizarSteps, closeModal, confirmView, chartView, openModal, setTitleSection, justRead }) => {

  const [numCollabs, setnumCollabs] = useState(0);
  const [loading, setLoading] = useState(true);
  /* const [disabled, setdisabled] = useState(false); */
  const history = useHistory();
  const enterpriseId = getJwt("enterprise");
  const [relationBenefitEnterprise, setRelationBenefitEnterprise] = useState(false)

  useEffect(() => {
    checkEnterpriseBenefit(parseInt(getJwt("enterprise"))).then(response => {
      if (response.validation) {
        // console.log('response: ',response);
        setRelationBenefitEnterprise(response.data.benefit)
      }
    })
  }, [])
  

  useEffect(() => {
    try {
      setTitleSection("Administración de Colaboradores");
      getTotalNumberCollaborators(getJwt("enterprise")).then((response) => {
        if (response) {
          switch (response.code) {
            case 1:
              setnumCollabs(response.numCollab);
              setLoading(false);
              if (response.numCollab > 0) {
                /* setdisabled(false); */
                if (!confirmView) {
                  actualizarSteps(1);
                }
              } else {
                /* setdisabled(true); */
              }
              break;
            case 2:
              setnumCollabs(response.numCollab);
              setLoading(false);
              if (response.numCollab > 0) {
                /* setdisabled(false); */
                if (!confirmView) {
                  actualizarSteps(1);
                }
              } else {
                /* setdisabled(true); */
              }
              break;
            case 99:
              if (justRead) {
                history.push("/inicio-colaborador")
              }
              setLoading(false);
              break;
            case 1000:
              if (justRead) {
                history.push("/inicio-colaborador")
              }
              setLoading(false);
              break;
            default:
              if (justRead) {
                history.push("/inicio-colaborador")
              }
              setLoading(false);
              break;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [/* actualizarSteps, confirmView, loading */]);
  /**
   * Muestra alerta si el numero de colaboradores es mayor a 0
   * Activa el boton de agregar colaboradores si hay colaboradores
  */
  if (loading === true) {
    return (
      <LoaderApp />
    )
  } else {
    return (
      <div className="col-12 bg-white rounded p-2 mb-5">
        <div className="col-12" >
          <p className="my-1 text-center font-medium-2">Actualmente existen: <span className="text-base-guay">{numCollabs} Colaboradores</span> registrados en guay, si deseas agregar m&aacute;s, cuentas con las siguientes opciones:</p>
        </div>
        <div className="col-12 text-center my-4">
          <div className="col-12">
            <span className="font-medium-2">1.- Descarga la plantilla de colaboradores, llena el archivo correctamente y c&aacute;rgalo aqu&iacute;.</span>
          </div>
          <div className="col-12">
            {/* <a className="btn btn-blue-2 mt-3 font-medium-2 px-2" href='https://storage.googleapis.com/guay-api-production/Operacion/Plantilla/Plantilla_Plataforma_guay_v1.xlsx' download> */}
            <a className="btn btn-blue-2 mt-3 font-medium-2 px-2" href={relationBenefitEnterprise ? 'https://storage.googleapis.com/guay-api-production/Operacion/Plantilla/Plantilla_Plataforma_guay_2023_B.xlsx': 'https://storage.googleapis.com/guay-api-production/Operacion/Plantilla/Plantilla_Plataforma_guay_2023_A.xlsx'} download>
              <i className="ft-download icon-medium-size"></i> Descargar plantilla
          </a>
          </div>
        </div>
        <div className="row d-flex justify-content-center text-center">
          <div className="col-6">
            <DropZone actualizarSteps={actualizarSteps} setLoading={setLoading} enterpriseId={enterpriseId} closeModal={closeModal} confirmView={confirmView} chartView={chartView} />
          </div>
        </div>
        {/* <div className="col-12 text-center my-3">
          <div className="col-12">
            <span className="font-medium-2">2.- Hazlo de forma manual, uno por uno dentro de nuestra plataforma.</span>
          </div>
          <div className="col-12">
            {disabled === true
              ?
              (
                <button className="btn bg-white-guay mt-3 font-medium-2" disabled={disabled}>
                  <i className="ft-plus-circle icon-big-size text-blue"></i><br />
            Agregar colaborador
                </button>
              )
              :
              (
                <button className="btn bg-white-guay mt-3 font-medium-2" disabled={disabled} onClick={() => openModal()}>
                  <i className="ft-plus-circle icon-big-size text-blue"></i><br />
            Agregar colaborador
                </button>
              )
            }
          </div>
        </div> */}
      </div>
    );
  }
};

export default UploadCollab;
