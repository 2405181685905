import React from "react";

const Footer = () => {
  return (
    <footer className="footer footer-static footer-dark navbar-border navbar-shadow">
    <div className="float-left width-70-per"> 
        <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
            <span className="float-md-left d-block d-md-inline-block">Copyright © 2020. Derechos reservados de guay</span>
        </p>
    </div> 
    <div className="float-md-right d-none d-lg-block">
        <div className="sprite s0x11"></div>
    </div>
</footer>
  );
};

export default Footer;
