import $ from "jquery";

/**
 * Controla el lengiaje del texto para la introducción de la encuesta ATS
 * @param {text} language es (Español), en (English)
 * @param {functtion} funct1 checkboxFunc
 * @param {boolean} value1 checkboxVal
 * @returns 
 */
export const textATS = (language, funct1, value1) => {
    let objText = {};
    if (language === 'es') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        A continuación encontrarás el cuestionario donde se identifican los {''}
                        <span className='text-pink-mine font-weight-bold'>Acontecimientos Traumáticos Severos (ATS) durante tu jornada o con motivo del trabajo</span> {''}
                        que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero ocurridos {''}
                        <span className='text-pink-mine font-weight-bold'>fuera de tu jornada laboral o en tu trabajo anterior</span>.
                    </p>
                    <div className="col-12 pl-5">Ambos cuestionarios de Acontecimientos Traumáticos Severos contienen 4 secciones:
                        <p className='mt-1'>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                        <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                    </div>
                    <p className="text-justify">
                        Es importante que estés concentrado y {''}
                        <span className='text-pink-mine font-weight-bold'>seas sincero</span> {''}
                        al momento de responder, recuerda que el objetivo de esto es mejorar tu bienestar emocional.
                    </p>
                    <p className="text-justify">
                        Este cuestionario da {''}
                        <span className='text-pink-mine font-weight-bold'>cumplimiento a la NOM-035</span> {''}
                        de la Secretaría del Trabajo por lo que es de {''}
                        <span className='text-pink-mine font-weight-bold'>carácter obligatorio</span>
                        , la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente para {''}
                        <span className='text-pink-mine font-weight-bold'>fines de mejora</span> {''}
                        en tu estado emocional.
                    </p>
                    <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                    <p className="text-justify">Agradecemos tu colaboración</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            />
                            Al dar click aceptas nuestro {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                            >aviso de privacidad</span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Encuesta</button>
                    )}
                </div>
        };
    }
    if (language === 'en') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        You will begin with the {''}
                        <span className='text-pink-mine font-weight-bold'>Severe Traumatic Events (STE)</span> {''}
                        survey, which is divided into two sections: in the first, {''}
                        <span className='text-pink-mine font-weight-bold'>the traumatic events (STE) related to your current job</span> {''}
                        are identified. Then, you will be asked about STEs that occurred {''}
                        <span className='text-pink-mine font-weight-bold'>outside of your working hours or in a previous job</span>.
                    </p>
                    <div className="col-12 pl-5">These two sections of questions are grouped as follows:
                        <p className='mt-1'>1. The type of event <span className="font-weight-bold">(regardless of how long ago it occurred)</span></p>
                        <p>2. Persistent memories of the event <span className="font-weight-bold">(in the last month)</span></p>
                        <p>3. Similar or associated circumstances to the event <span className="font-weight-bold">(in the last month)</span></p>
                        <p>4. The impact <span className="font-weight-bold">(in the last month)</span></p>
                    </div>
                    <p className="text-justify">
                        It is important that you stay focused and {''}
                        <span className='text-pink-mine font-weight-bold'>be honest</span> {''}
                        when answering. Remember that the goal of this is to improve your emotional well-being.
                    </p>
                    <p className="text-justify">
                        This questionnaire {''}
                        <span className='text-pink-mine font-weight-bold'>complies with NOM-035</span> {''}
                        of the Ministry of Labor, {''}
                        <span className='text-pink-mine font-weight-bold'>and it is mandatory.</span> {''}
                        The information collected will be treated confidentially, and the results will be used exclusively to {''}
                        <span className='text-pink-mine font-weight-bold'>improve</span> {''}
                        your emotional well-being.
                    </p>
                    <p className="text-justify">It is necessary to answer all the questions to obtain reliable results.</p>
                    <p className="text-justify">We appreciate your cooperation.</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> By clicking, you accept our {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso_Privacidad_GUAY_Ingles.pdf")}>
                                privacy notice.
                            </span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Survey</button>
                    )}
                </div>
        };
    }
    return objText;
};
/**
 * Controla el lengiaje del texto para la introducción de la encuesta FRP
 * @param {text} language es (Español), en (English)
 * @param {functtion} funct1 checkboxFunc
 * @param {boolean} value1 checkboxVal
 * @returns 
 */
export const textFRP = (language, funct1, value1) => {
    let objText = {};
    if (language === 'es') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        A continuación encontrarás el diagnóstico de los {''}
                        <span className='text-pink-mine font-weight-bold'>Factores de Riesgo Psicosocial y el entorno laboral</span> {''}
                        que existe en tu organización.
                    </p>
                    <div className="col-12 pl-5">Este cuestionario evalúa 5 aspectos generales:
                        <p className='mt-1'>1. El ambiente de trabajo</p>
                        <p>2. Los factores propios de la actividad</p>
                        <p>3. La organización del tiempo de trabajo</p>
                        <p>4. El liderazgo y relaciones en el trabajo</p>
                        <p>5. El entorno organizacional</p>
                        <p className='mt-1 font-weight-bold'>Importante:  considerar las condiciones de los últimos dos meses.</p>
                    </div>
                    <p className="text-justify">
                        Es importante que estés concentrado y {''}
                        <span className='text-pink-mine font-weight-bold'>seas sincero</span> {''}
                        al momento de responder, recuerda que al ser tú opinión {''}
                        <span className='text-pink-mine font-weight-bold'>no hay respuestas correctas o incorrectas</span>.
                    </p>
                    <p className="text-justify">
                        Este cuestionario da {''}
                        <span className='text-pink-mine font-weight-bold'>cumplimiento a la NOM-035</span> {''}
                        de la Secretaría del Trabajo por lo que es de {''}
                        <span className='text-pink-mine font-weight-bold'>carácter obligatorio</span>, {''}
                        la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente para {''}
                        <span className='text-pink-mine font-weight-bold'>fines de mejora</span> {''}
                        en las condiciones del ambiente de trabajo.
                    </p>
                    <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                    <p className="text-justify">Agradecemos tu colaboración</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            />
                            Al dar click aceptas nuestro {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                            >aviso de privacidad</span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Encuesta</button>
                    )}
                </div>
        };
    }
    if (language === 'en') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        In the first part, you will find the diagnosis of {''}
                        <span className='text-pink-mine font-weight-bold'>Psychosocial Risk Factors and the work environment</span> {''}
                        in your organization.
                    </p>
                    <div className="col-12 pl-5">This questionnaire evaluates 5 general aspects:
                        <p className='mt-1'>1. The work environment</p>
                        <p>2. Factors related to the activity</p>
                        <p>3. The organization of work time</p>
                        <p>4. Leadership and relationships at work</p>
                        <p>5. The organizational environment</p>
                        <p className='mt-1 font-weight-bold'>Important: consider the conditions from the last two months.</p>
                    </div>
                    <p className="text-justify">
                        It is important that you stay focused and {''}
                        <span className='text-pink-mine font-weight-bold'>be honest</span> {''}
                        when answering. Remember that since it´s your opinion, {''}
                        <span className='text-pink-mine font-weight-bold'>there are no right or wrong answers</span>.
                    </p>
                    <p className="text-justify">
                        This questionnaire {''}
                        <span className='text-pink-mine font-weight-bold'>complies with NOM-035</span> {''}
                        of the Ministry of Labor, so {''}
                        <span className='text-pink-mine font-weight-bold'>it is mandatory</span>. {''}
                        The information collected will be treated confidentially and the results will be used exclusively to {''}
                        <span className='text-pink-mine font-weight-bold'>improve</span> {''}
                        working conditions.
                    </p>
                    <p className="text-justify">It is necessary to answer all the questions to obtain reliable results.</p>
                    <p className="text-justify">We appreciate your cooperation.</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> By clicking, you accept our {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso_Privacidad_GUAY_Ingles.pdf")}>
                                privacy notice.
                            </span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Survey</button>
                    )}
                </div>
        };
    }
    return objText;
};
/**
 * Controla el lengiaje del texto para la introducción de la encuesta ATS + DEMO
 * @param {text} language es (Español), en (English)
 * @param {functtion} funct1 checkboxFunc
 * @param {boolean} value1 checkboxVal
 * @returns 
 */
export const textATSDEMO = (language, funct1, value1) => {
    let objText = {};
    if (language === 'es') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        Empezarás con la encuesta de {''}
                        <span className='text-pink-mine font-weight-bold'>Acontecimientos Traumáticos Severos (ATS)</span> {''}
                        la cual se divide en dos bloques: en el primero se identifican los {''}
                        <span className='text-pink-mine font-weight-bold'>acontecimientos (ATS) ocurridos durante y con motivo del trabajo</span>
                        que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero que sucedieron {''}
                        <span className='text-pink-mine font-weight-bold'>fuera de tu jornada laboral o en tu trabajo anterior</span>.
                    </p>
                    <div className="col-12 pl-5">Estos dos bloques de preguntas se agrupan de la siguiente manera:
                        <p className='mt-1'>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                        <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                    </div>
                    <p className="text-justify">
                        En la segunda encuesta, preguntaremos algunos {''}
                        <span className='text-pink-mine font-weight-bold'>datos demográficos</span> {''}
                        para conocerte mejor: edad, tiempo de traslado a tu lugar de trabajo, años en la organización, etc.
                    </p>
                    <p className="text-justify">
                        Es importante que estés concentrado y {''}
                        <span className='text-pink-mine font-weight-bold'>seas sincero</span> {''}
                        al momento de responder, recuerda que el objetivo de esto es mejorar tu bienestar emocional.
                    </p>
                    <p className="text-justify">
                        Este cuestionario da {''}
                        <span className='text-pink-mine font-weight-bold'>cumplimiento a la NOM-035</span> {''}
                        de la Secretaría del Trabajo por lo que es de {''}
                        <span className='text-pink-mine font-weight-bold'>carácter obligatorio</span>
                        , la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente para {''}
                        <span className='text-pink-mine font-weight-bold'>fines de mejora</span> {''}
                        en tu estado emocional.
                    </p>
                    <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                    <p className="text-justify">Agradecemos tu colaboración</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            />
                            Al dar click aceptas nuestro {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                            >aviso de privacidad</span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Encuesta</button>
                    )}
                </div>
        };
    }
    if (language === 'en') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        You will begin with the {''}
                        <span className='text-pink-mine font-weight-bold'>Severe Traumatic Events (STE)</span> {''}
                        survey, which is divided into two sections: in the first, {''}
                        <span className='text-pink-mine font-weight-bold'>the traumatic events (STE) related to your current job</span> {''}
                        are identified. Then, you will be asked about STEs that occurred {''}
                        <span className='text-pink-mine font-weight-bold'>outside of your working hours or in a previous job</span>.
                    </p>
                    <div className="col-12 pl-5">These two sections of questions are grouped as follows:
                        <p className='mt-1'>1. The type of event <span className="font-weight-bold">(regardless of how long ago it occurred)</span></p>
                        <p>2. Persistent memories of the event <span className="font-weight-bold">(in the last month)</span></p>
                        <p>3. Similar or associated circumstances to the event <span className="font-weight-bold">(in the last month)</span></p>
                        <p>4. The impact <span className="font-weight-bold">(in the last month)</span></p>
                    </div>
                    <p className="text-justify">
                        In the second survey, we will ask for some {''}
                        <span className='text-pink-mine font-weight-bold'>demographic data</span> {''}
                        to get to know you better: age, commute time to work, years in the organization, etc.
                    </p>
                    <p className="text-justify">
                        It is important that you stay focused and {''}
                        <span className='text-pink-mine font-weight-bold'>be honest</span> {''}
                        when answering. Remember that the goal of this is to improve your emotional well-being.
                    </p>
                    <p className="text-justify">
                        This questionnaire {''}
                        <span className='text-pink-mine font-weight-bold'>complies with NOM-035</span> {''}
                        of the Ministry of Labor, {''}
                        <span className='text-pink-mine font-weight-bold'>and it is mandatory.</span> {''}
                        The information collected will be treated confidentially, and the results will be used exclusively to {''}
                        <span className='text-pink-mine font-weight-bold'>improve</span> {''}
                        your emotional well-being.
                    </p>
                    <p className="text-justify">It is necessary to answer all the questions to obtain reliable results.</p>
                    <p className="text-justify">We appreciate your cooperation.</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> By clicking, you accept our {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso_Privacidad_GUAY_Ingles.pdf")}>
                                privacy notice.
                            </span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Survey</button>
                    )}
                </div>
        };
    }
    return objText;
};
/**
 * Controla el lengiaje del texto para la introducción de la encuesta FRP + DEMO
 * @param {text} language es (Español), en (English)
 * @param {functtion} funct1 checkboxFunc
 * @param {boolean} value1 checkboxVal
 * @returns 
 */
export const textFRPDEMO = (language, funct1, value1) => {
    let objText = {};
    if (language === 'es') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        En la primera parte encontrarás el diagnóstico de los {''}
                        <span className='text-pink-mine font-weight-bold'>Factores de Riesgo Psicosocial y el entorno laboral</span> {''}
                        que existe en tu organización.
                    </p>
                    <div className="col-12 pl-5">Este cuestionario evalúa 5 aspectos generales:
                        <p className='mt-1'>1. El ambiente de trabajo</p>
                        <p>2. Los factores propios de la actividad</p>
                        <p>3. La organización del tiempo de trabajo</p>
                        <p>4. El liderazgo y relaciones en el trabajo</p>
                        <p>5. El entorno organizacional</p>
                        <p className='mt-1 font-weight-bold'>Importante:  considerar las condiciones de los últimos dos meses.</p>
                    </div>
                    <p className="text-justify">
                        En la segunda parte, preguntaremos algunos {''}
                        <span className='text-pink-mine font-weight-bold'>datos demográficos</span> {''}
                        para conocerte mejor: edad, tiempo de traslado a tu lugar de trabajo, años en la organización, etc.
                    </p>
                    <p className="text-justify">
                        Es importante que estés concentrado y {''}
                        <span className='text-pink-mine font-weight-bold'>seas sincero</span> {''}
                        al momento de responder, recuerda que al ser tú opinión {''}
                        <span className='text-pink-mine font-weight-bold'>no hay respuestas correctas o incorrectas</span>.
                    </p>
                    <p className="text-justify">
                        Este cuestionario da {''}
                        <span className='text-pink-mine font-weight-bold'>cumplimiento a la NOM-035</span> {''}
                        de la Secretaría del Trabajo por lo que es de {''}
                        <span className='text-pink-mine font-weight-bold'>carácter obligatorio</span>, {''}
                        la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente para {''}
                        <span className='text-pink-mine font-weight-bold'>fines de mejora</span> {''}
                        en las condiciones del ambiente de trabajo.
                    </p>
                    <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                    <p className="text-justify">Agradecemos tu colaboración</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            />
                            Al dar click aceptas nuestro {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                            >aviso de privacidad</span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Encuesta</button>
                    )}
                </div>
        };
    }
    if (language === 'en') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        In the first part, you will find the diagnosis of {''}
                        <span className='text-pink-mine font-weight-bold'>Psychosocial Risk Factors and the work environment</span> {''}
                        in your organization.
                    </p>
                    <div className="col-12 pl-5">This questionnaire evaluates 5 general aspects:
                        <p className='mt-1'>1. The work environment</p>
                        <p>2. Factors related to the activity</p>
                        <p>3. The organization of work time</p>
                        <p>4. Leadership and relationships at work</p>
                        <p>5. The organizational environment</p>
                        <p className='mt-1 font-weight-bold'>Important: consider the conditions from the last two months.</p>
                    </div>
                    <p className="text-justify">
                        In the second part, we will ask for some {''}
                        <span className='text-pink-mine font-weight-bold'>demographic data</span> {''}
                        to get to know you better: age,commute time to work, years in the organization, etc.
                    </p>
                    <p className="text-justify">
                        It is important that you stay focused and {''}
                        <span className='text-pink-mine font-weight-bold'>be honest</span> {''}
                        when answering. Remember that since it´s your opinion, {''}
                        <span className='text-pink-mine font-weight-bold'>there are no right or wrong answers</span>.
                    </p>
                    <p className="text-justify">
                        This questionnaire {''}
                        <span className='text-pink-mine font-weight-bold'>complies with NOM-035</span> {''}
                        of the Ministry of Labor, so {''}
                        <span className='text-pink-mine font-weight-bold'>it is mandatory</span>. {''}
                        The information collected will be treated confidentially and the results will be used exclusively to {''}
                        <span className='text-pink-mine font-weight-bold'>improve</span> {''}
                        working conditions.
                    </p>
                    <p className="text-justify">It is necessary to answer all the questions to obtain reliable results.</p>
                    <p className="text-justify">We appreciate your cooperation.</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> By clicking, you accept our {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso_Privacidad_GUAY_Ingles.pdf")}>
                                privacy notice.
                            </span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Survey</button>
                    )}
                </div>
        };
    }
    return objText;
};
/**
 * Controla el lengiaje del texto para la introducción de la encuesta ATS + FRP + DEMO
 * @param {text} language es (Español), en (English)
 * @param {functtion} funct1 checkboxFunc
 * @param {boolean} value1 checkboxVal
 * @returns 
 */
export const textATSFRPDEMO = (language, funct1, value1) => {
    let objText = {};
    if (language === 'es') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">En la primera parte encontrarás el cuestionario donde se identifican los <span className="text-pink-mine font-weight-bold">Acontecimientos Traumáticos Severos (ATS) durante tu jornada o con motivo del trabajo</span> que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero ocurridos <span className="text-pink-mine font-weight-bold">fuera de tu jornada laboral o en tu trabajo anterior.</span> </p>
                    <div className="col-12 pl-5">Ambos cuestionarios de Acontecimientos Traumáticos Severos contienen 4 secciones:
                        <p className='mt-1'>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                        <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                    </div>
                    <p className="text-justify">En la segunda parte hacemos el <span className="text-pink-mine font-weight-bold">diagnóstico de los Factores de Riesgos Psicosocial</span> en tu centro de trabajo <span className="font-weight-bold">(considera las condiciones de los últimos dos meses).</span></p>
                    <p className="text-justify">Por último, preguntaremos algunos <span className="text-pink-mine font-weight-bold">datos demográficos</span> para conocerte mejor: edad, tiempo de traslado a tú lugar de trabajo, años en la organización, etc.</p>
                    <p className="text-justify">Es importante que estés concentrado y <span className="text-pink-mine font-weight-bold">seas sincero</span> al momento de responder, recuerda que <span className="text-pink-mine font-weight-bold">no hay respuestas correctas o incorrectas.</span></p>
                    <p className="text-justify">Estos cuestionarios dan <span className="text-pink-mine font-weight-bold">cumplimiento a la NOM-035</span> de la Secretaría del Trabajo, son de <span className="text-pink-mine font-weight-bold">carácter obligatorio,</span> la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente con <span className="text-pink-mine font-weight-bold">fines de mejora</span> en las condiciones del ambiente de trabajo.</p>
                    <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                    <p className="text-justify">Agradecemos tu colaboración</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> Al dar click aceptas nuestro <span className="text-blue font-weight-bold" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>aviso de privacidad</span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Encuesta</button>
                    )}
                </div>
        };
    }
    if (language === 'en') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        In the first part, you will find the questionnaire that identifies {''}
                        <span className="text-pink-mine font-weight-bold">Severe Traumatic Events (STE) during your workday or related to the work</span> {''}
                        you are currently performing. Afterward, we will ask you about STEs that occurred {''}
                        <span className="text-pink-mine font-weight-bold">outside of your working hours or in your previous job.</span>
                    </p>
                    <div className="col-12 pl-5">Both Severe Traumatic Events questionnaires contain 4 sections:
                        <p className='mt-1'>1. The type of event <span className="font-weight-bold">(it doesn’t matter how long ago you experienced it)</span></p>
                        <p>2. Persistent memories of the event <span className="font-weight-bold">(during the last month)</span></p>
                        <p>3. Similar or associated circumstances to the event <span className="font-weight-bold">(during the last month)</span></p>
                        <p>4. The impacts <span className="font-weight-bold">(during the last month)</span></p>
                    </div>
                    <p className="text-justify">
                        In the second part, we will {''}
                        <span className="text-pink-mine font-weight-bold">diagnose the Psychosocial Risk Factors</span> {''}
                        in your workplace {''}
                        <span className="font-weight-bold">(considering conditions from the last two months)</span>.
                    </p>
                    <p className="text-justify">
                        Lastly, we will ask for some {''}
                        <span className="text-pink-mine font-weight-bold">demographic information</span> {''}
                        to get to know you better: age, commute time to your workplace, years with the organization, etc.
                    </p>
                    <p className="text-justify">
                        It is important that you remain focused and {''}
                        <span className="text-pink-mine font-weight-bold">answer honestly</span>, {''}
                        remembering that {''}
                        <span className="text-pink-mine font-weight-bold">there are no right or wrong answers</span>.
                    </p>
                    <p className="text-justify">
                        These questionnaires {''}
                        <span className="text-pink-mine font-weight-bold">comply with NOM-035</span> {''}
                        of the Ministry of Labor. They are {''}
                        <span className="text-pink-mine font-weight-bold">mandatory</span>, {''}
                        the information obtained will be treated confidentially, and the results will be used exclusively {''}
                        <span className="text-pink-mine font-weight-bold">to improve</span> {''}
                        working conditions.
                    </p>
                    <p className="text-justify">It is necessary to answer all the questions to ensure the system provides reliable results.</p>
                    <p className="text-justify">We appreciate your cooperation.</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> By clicking, you accept our {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso_Privacidad_GUAY_Ingles.pdf")}>
                                privacy notice.
                            </span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Survey</button>
                    )}
                </div>
        };
    }
    return objText;
};
/**
 * Controla el lengiaje del texto para la introducción de la encuesta ATS + FRP
 * @param {text} language es (Español), en (English)
 * @param {functtion} funct1 checkboxFunc
 * @param {boolean} value1 checkboxVal
 * @returns 
 */
export const textATSFRP = (language, funct1, value1) => {
    let objText = {};
    if (language === 'es') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">En la primera parte encontrarás el cuestionario donde se identifican los <span className="text-pink-mine font-weight-bold">Acontecimientos Traumáticos Severos (ATS) durante tu jornada o con motivo del trabajo</span> que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero ocurridos <span className="text-pink-mine font-weight-bold">fuera de tu jornada laboral o en tu trabajo anterior.</span> </p>
                    <div className="col-12 pl-5">Ambos cuestionarios de Acontecimientos Traumáticos Severos contienen 4 secciones:
                        <p className='mt-1'>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                        <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                        <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                    </div>
                    <p className="text-justify">En la segunda parte hacemos el <span className="text-pink-mine font-weight-bold">diagnóstico de los Factores de Riesgos Psicosocial</span> en tu centro de trabajo <span className="font-weight-bold">(considera las condiciones de los últimos dos meses).</span></p>
                    <p className="text-justify">Es importante que estés concentrado y <span className="text-pink-mine font-weight-bold">seas sincero</span> al momento de responder, recuerda que <span className="text-pink-mine font-weight-bold">no hay respuestas correctas o incorrectas.</span></p>
                    <p className="text-justify">Estos cuestionarios dan <span className="text-pink-mine font-weight-bold">cumplimiento a la NOM-035</span> de la Secretaría del Trabajo, son de <span className="text-pink-mine font-weight-bold">carácter obligatorio,</span> la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente con <span className="text-pink-mine font-weight-bold">fines de mejora</span> en las condiciones del ambiente de trabajo.</p>
                    <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                    <p className="text-justify">Agradecemos tu colaboración</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> Al dar click aceptas nuestro <span className="text-blue font-weight-bold" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>aviso de privacidad</span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Encuesta</button>
                    )}
                </div>
        };
    }
    if (language === 'en') {
        objText = {
            section1:
                <div className="col-12 font-medium-1 mt-1">
                    <p className="text-justify">
                        In the first part, you will find the questionnaire that identifies {''}
                        <span className="text-pink-mine font-weight-bold">Severe Traumatic Events (STE) during your workday or related to the work</span> {''}
                        you are currently performing. Afterward, we will ask you about STEs that occurred {''}
                        <span className="text-pink-mine font-weight-bold">outside of your working hours or in your previous job.</span>
                    </p>
                    <div className="col-12 pl-5">Both Severe Traumatic Events questionnaires contain 4 sections:
                        <p className='mt-1'>1. The type of event <span className="font-weight-bold">(it doesn’t matter how long ago you experienced it)</span></p>
                        <p>2. Persistent memories of the event <span className="font-weight-bold">(during the last month)</span></p>
                        <p>3. Similar or associated circumstances to the event <span className="font-weight-bold">(during the last month)</span></p>
                        <p>4. The impacts <span className="font-weight-bold">(during the last month)</span></p>
                    </div>
                    <p className="text-justify">
                        In the second part, we will {''}
                        <span className="text-pink-mine font-weight-bold">diagnose the Psychosocial Risk Factors</span> {''}
                        in your workplace {''}
                        <span className="font-weight-bold">(considering conditions from the last two months)</span>.
                    </p>
                    <p className="text-justify">
                        It is important that you remain focused and {''}
                        <span className="text-pink-mine font-weight-bold">answer honestly</span>, {''}
                        remembering that {''}
                        <span className="text-pink-mine font-weight-bold">there are no right or wrong answers</span>.
                    </p>
                    <p className="text-justify">
                        These questionnaires {''}
                        <span className="text-pink-mine font-weight-bold">comply with NOM-035</span> {''}
                        of the Ministry of Labor. They are {''}
                        <span className="text-pink-mine font-weight-bold">mandatory</span>, {''}
                        the information obtained will be treated confidentially, and the results will be used exclusively {''}
                        <span className="text-pink-mine font-weight-bold">to improve</span> {''}
                        working conditions.
                    </p>
                    <p className="text-justify">It is necessary to answer all the questions to ensure the system provides reliable results.</p>
                    <p className="text-justify">We appreciate your cooperation.</p>
                </div>,
            section2:
                <div className="col-12 mt-1">
                    <div className="form-group form-check">
                        <label className="form-check-label pointer font-medium-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={() => funct1()}
                            /> By clicking, you accept our {''}
                            <span
                                className="text-blue font-weight-bold"
                                onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso_Privacidad_GUAY_Ingles.pdf")}>
                                privacy notice.
                            </span>
                        </label>
                    </div>
                </div>,
            section3:
                <div className="col-12 mt-1">
                    {value1 === true && (
                        <button
                            className="btn btn-sm btn-blue-3 float-right font-medium-1"
                            onClick={() => $('#modal-1').hide()}
                        >Survey</button>
                    )}
                </div>
        };
    }
    return objText;
};