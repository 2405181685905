import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validationAccessClinic, saveResponseClinic, saveResponseOpenClinic, updFinishSurveyClinic } from '../Models/Survey_model';
import LoaderSurvey from '../LoaderSurvey';
import SurveyComplete from '../SurveyComplete';
import SurveyError from '../SurveyError';
import StatisticalFilterModal from './StatisticalFilter/StatisticalFilterModal';
import StatisticalFilterNoConcent from "./StatisticalFilter/StatisticalFilterNoConcent";
import PregnantMessage from './PregnantMessage';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Checkbox } from '@material-ui/core';
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import NoConsentMessage from './NoConsentMessage';

const FiltroClinico = () => {

    //obtener identificador
    const { identity } = useParams();
    let parser = "0-0-0-0";

    //parsear identificador
    try {
        parser = atob(identity);
    } catch (error) {
        console.error(error);
    }
    parser = parser.split("-");

    //asignar datos parseados
    const collaboratorId = parser[0];
    const idEnterprise = parser[1];
    const idGroup = parser[2];
    const idPeriod = parser[3];

    //getters & setters
    const [loading, setLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [subAnswers, setSubAnswers] = useState([]);
    const [answerAnswered, setAnswerAnswered] = useState([]);
    const [subanswerAnswered, setSubanswerAnswered] = useState([]);
    // eslint-disable-next-line
    const [openQuestions, setOpenQuestions] = useState([]);
    const [idQuestionSelected, setidQuestionSelected] = useState([]);
    // const [update, setUpdate] = useState(0);
    const [userDate, setUserDate] = useState("");
    const [coordinates, setCoordinates] = useState("");
    const [browser, setBrowser] = useState("");
    const [os, setOs] = useState("");
    const [gender, setGender] = useState(0);
    const [pregnantMess, setPregnantMess] = useState(false);
    const [isPregnant, setIsPregnant] = useState(0);
    const [noConsent, setNoConsent] = useState(false);
    // eslint-disable-next-line
    const [showSurvey, setShowSurvey] = useState(false);

    useEffect(() => {
        validationAccessClinic(collaboratorId, idEnterprise, idGroup, idPeriod).then((response) => {
            switch (response?.code) {
                case 1:
                    setGender(response.gender)
                    setQuestions(response.listCatQuestions);
                    setAnswers(response.listCatAnswers);
                    setSubAnswers(response.listSubanswer);
                    setAnswerAnswered(response.listAnswerClinic);
                    let arraySubanswers = [];
                    response.listAnswerClinic.forEach(element => {
                        if (element.idSubanswer !== "") {
                            let arregloSubanswers = element.idSubanswer.split(",");
                            arregloSubanswers.forEach(elementString => {
                                if (elementString !== "") {
                                    arraySubanswers.push({ idQuestion: element.idAnswerClinic.idQuestion, idAnswer: element.idAnswer, idSubanswer: parseInt(elementString, 10) });
                                }
                            });
                        }
                    });
                    setSubanswerAnswered(arraySubanswers);
                    const arrayOpenQ = [];
                    const arrayOpenQId = [];
                    response.listAnswerClinic.forEach(element => {
                        if ("answerText" in element) {
                            arrayOpenQ.push({ idQuestion: element.idAnswerClinic.idQuestion, answerText: element.answerText });
                            arrayOpenQId.push({ idQuestion: element.idAnswerClinic.idQuestion });
                        }
                    });
                    setOpenQuestions(arrayOpenQ);
                    setidQuestionSelected(arrayOpenQId);
                    setLoading(false);
                    if (response.consent === 0) {
                        $('#wellcomeMessage').show();
                    }
                    break;
                case 3:
                    //encuesta contestada
                    setLoading(false);
                    setShowMessage(true);
                    setErrorPage(false);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                case 4:
                    //no se puede continuar debido a que no se dio consentimiento
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(false);
                    setNoConsent(true);
                    setPregnantMess(false);
                    break;
                case 5:
                    //no se puede continuar debido a que usted se encuentra embarazada
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(false);
                    setNoConsent(false);
                    setPregnantMess(true);
                    break;
                case 1001:
                    //no se puede continuar debido a que la empresa no existe o no esta activa
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                case 1002:
                    //no se puede continuar debido a que el colaborador no existe o no esta activo
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                case 1003:
                    //no se encontro colaborador para la encuesta
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                default:
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    console.error("Error desconocido");
                    break;
            }
        })
        return () => {
        }
    }, [collaboratorId, idEnterprise, idGroup, idPeriod])

    const data = () => {
        // fecha, horas, minutos y segundos
        let fechaActual = new Date();
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setUserDate(fechaActual);
        // navegador
        const brow = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(brow);
        // sistema operativo
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOs(so);
        // coordenadas geograficas
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
    }

    function HandleChangeRadio(answerData) {
        data();
        saveResponseClinic(answerData.idAnswerClinic.idAnswer, "", answerData.idAnswerClinic.idQuestion, idEnterprise, idGroup, idPeriod, parseInt(collaboratorId, 10), JSON.stringify(userDate), JSON.stringify(coordinates), JSON.stringify(browser), JSON.stringify(os)).then((response) => {
            switch (response?.code) {
                case 1:
                    //guardado de respuesta correcto 
                    break;
                default:
                    toastr.error("No se pudo guardar la respuesta, recargue la página y vuelva a intentarlo");
                    setErrorPage(true);
                    console.error("Error sin documentar");
                    break;
            }
        });
        if (answerData.flagText === 0) {
            let arrayOpenQId = idQuestionSelected;
            arrayOpenQId = arrayOpenQId.filter(element => element.idQuestion !== answerData.idAnswerClinic.idQuestion); //filtramos el array de preguntas abiertas
            setidQuestionSelected(arrayOpenQId); //subtituimos el array de preguntas abiertas
        } else {
            const arrayOpenQId = idQuestionSelected;
            arrayOpenQId.push({ idQuestion: answerData.idAnswerClinic.idQuestion });
            setidQuestionSelected(arrayOpenQId); //agregar el id de la pregunta al array de preguntas abiertas
        }
        //eliminar alguna respuesta con el mismo id de pregunta
        let arrayanswerAnswered = answerAnswered.filter(element => element.idAnswerClinic.idQuestion !== answerData.idAnswerClinic.idQuestion);
        //agregar nuevo elemento al arreglo let
        arrayanswerAnswered.push({
            idAnswer: answerData.idAnswerClinic.idAnswer,
            idSubanswer: "",
            active: answerData.active,
            idAnswerClinic: {
                idcollab: parseInt(collaboratorId, 10),
                serialversionuid: answerData.idAnswerClinic.serialversionuid,
                idGroup: idGroup,
                idQuestion: answerData.idAnswerClinic.idQuestion,
                idEnterprise: idEnterprise,
                idPeriod: idPeriod,
            },
            status: answerData.status,
        });
        //substituir el antiguo arreglo de respuestas por el actualizado
        setAnswerAnswered(arrayanswerAnswered);
        //eliminar las subrespuestas de esa pregunta
        let arraysubanswerAnswered = subanswerAnswered.filter(element => element.idQuestion !== answerData.idAnswerClinic.idQuestion);
        //substituir el antiguo arreglo de subrespuestas por el actualizado
        setSubanswerAnswered(arraysubanswerAnswered);
        // setUpdate(1);

        try {
            // element which needs to be scrolled to
            const element = document.getElementById(`Q-${answerData.idAnswerClinic.idQuestion + 1}`);
            // scroll to element
            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        } catch (error) {

        }

    }

    function HandleChangeCheckBox(answerData, idQuestion) {
        // copiar las subrespuestas a arreglo let
        let arraysubanswerAnswered = subanswerAnswered;
        //checar si se volvio a seleccionar la misma 
        if (subanswerAnswered.some(subanswerAns => subanswerAns.idSubanswer === answerData.idCatSubanswerClinic.idSubanswer)) {//se encontro una coincidencia
            //filtrar el array para eliminar la coincidencia
            arraysubanswerAnswered = arraysubanswerAnswered.filter(element => element.idSubanswer !== answerData.idCatSubanswerClinic.idSubanswer);
            //substituir el antiguo arreglo de subrespuestas por el actualizado
            setSubanswerAnswered(arraysubanswerAnswered);
            let arrayOpenQId = idQuestionSelected;
            arrayOpenQId = arrayOpenQId.filter(element => element.idQuestion !== idQuestion); //filtramos el array de preguntas abiertas
            setidQuestionSelected(arrayOpenQId); //subtituimos el array de preguntas abiertas
        } else {//no se encuentra coincidencia
            //agregar nueva subrespuesta al arreglo let
            arraysubanswerAnswered.push({ idQuestion: idQuestion, idAnswer: answerData.idCatSubanswerClinic.idAnswer, idSubanswer: answerData.idCatSubanswerClinic.idSubanswer });
            //substituir el antiguo arreglo de subrespuestas por el actualizado
            setSubanswerAnswered(arraysubanswerAnswered);
            if (answerData.idType === 4) { //checar si es una respuesta abierta
                const arrayOpenQId = idQuestionSelected;
                arrayOpenQId.push({ idQuestion });
                setidQuestionSelected(arrayOpenQId); //agregar el id de la pregunta al array de preguntas abiertas
            }
        }

        //crear nuevo arreglo de las subrespuesta de la misma respuesta
        let nuevoarreglo = arraysubanswerAnswered.filter((element) => element.idAnswer === answerData.idCatSubanswerClinic.idAnswer);
        //concatenar respuesta en string
        let subrepsuestasString = "";
        nuevoarreglo.forEach((element) => {
            subrepsuestasString = subrepsuestasString + element.idSubanswer.toString() + ",";
        });

        //eliminar alguna respuesta con el mismo id de pregunta
        let arrayanswerAnswered = answerAnswered.filter(element => element.idAnswerClinic.idQuestion !== idQuestion);
        //agregar nuevo elemento al arreglo let
        arrayanswerAnswered.push({
            idAnswer: answerData.idCatSubanswerClinic.idAnswer,
            idSubanswer: subrepsuestasString,
            active: answerData.active,
            idAnswerClinic: {
                idcollab: parseInt(collaboratorId, 10),
                serialversionuid: answerData.idCatSubanswerClinic.serialversionuid,
                idGroup: idGroup,
                idQuestion: idQuestion,
                idEnterprise: idEnterprise,
                idPeriod: idPeriod,
            },
            status: answerData.status,
        });
        //substituir el antiguo arreglo de respuestas por el actualizado
        setAnswerAnswered(arrayanswerAnswered);
        data();
        saveResponseClinic(answerData.idCatSubanswerClinic.idAnswer, subrepsuestasString, idQuestion, idEnterprise, idGroup, idPeriod, parseInt(collaboratorId, 10), JSON.stringify(userDate), JSON.stringify(coordinates), JSON.stringify(browser), JSON.stringify(os)).then((response) => {
            switch (response?.code) {
                case 1:
                    //guardado de respuesta correcto 
                    break;
                default:
                    toastr.error("No se pudo guardar la respuesta, recargue la página y vuelva a intentarlo");
                    setErrorPage(true);
                    console.error("Error sin documentar");
                    break;
            }
        });

        // setUpdate(1);
    }

    function HandleChangeTextArea(idQuestion, e) {
        if (!idQuestionSelected.some(question => question.idQuestion === idQuestion)) {
            const arrayOpenQId = idQuestionSelected;
            arrayOpenQId.push({ idQuestion: idQuestion });
            setidQuestionSelected(arrayOpenQId);
        }
    }

    function CheckOpenAnswerQuestion() {
        if ($("#TAQ-1").length === 1) {
            if ($("#TAQ-1").val() === undefined || $("#TAQ-1").val() === "") {
                toastr.error("La respuesta abierta de la pregunta 1 se encuentra vacía");
                return true;
            }
        }
        if ($("#TAQ-2").length === 1) {
            if ($("#TAQ-2").val() === undefined || $("#TAQ-2").val() === "") {
                toastr.error("La respuesta abierta de la pregunta 2 se encuentra vacía");
                return true;
            }
        }
        if ($("#TAQ-3").length === 1) {
            if ($("#TAQ-3").val() === undefined || $("#TAQ-3").val() === "") {
                toastr.error("La respuesta abierta de la pregunta 3 se encuentra vacía");
                return true;
            }
        }
        if ($("#TAQ-9").length === 1) {
            if ($("#TAQ-9").val() === undefined || $("#TAQ-9").val() === "") {
                toastr.error("La respuesta abierta de la pregunta 9 se encuentra vacía");
                return true;
            }
        }
        if ($("#TAQ-10").length === 1) {
            if ($("#TAQ-10").val() === undefined || $("#TAQ-10").val() === "") {
                toastr.error("La respuesta abierta de la pregunta 10 se encuentra vacía");
                return true;
            }
        }
        if ($("#TAQ-11").length === 1) {
            if ($("#TAQ-11").val() === undefined || $("#TAQ-11").val() === "") {
                toastr.error("La respuesta abierta de la pregunta 11 se encuentra vacía");
                return true;
            }
        }

        return false;

    }

    function HandleClickEndSurvey() {

        let errors = 0;

        if (answerAnswered.length < (questions.length - 1)) {
            toastr.error("Aún hay preguntas sin contestar");
            errors = errors + 1;
        } else {
            const array = [];
            answers.forEach((element) => {
                if (answerAnswered.some(answer => answer.idAnswer === element.idAnswerClinic.idAnswer)) {
                    array.push(element);
                }
            });
            array.forEach((element) => {
                if (element.flagSubanswer === 1) {
                    if (!subanswerAnswered.some(answer => answer.idAnswer === element.idAnswerClinic.idAnswer)) {
                        toastr.error("Hay subrespuestas sin contestar");
                        errors = errors + 1;
                    }
                }
            })
        }

        // checar si alguna respuesta abierta se encuentra vacia
        if (!CheckOpenAnswerQuestion()) {
            if (errors === 0) {
                const arrayOpenAnswerQuestion = [];
                idQuestionSelected.forEach((element) => { //buscamos por cada textarea su valor y agregamos al array
                    if ($("#TAQ-" + element.idQuestion).val() === "" || $("#TAQ-" + element.idQuestion).val() === undefined) { //validamos que no este vacio
                        toastr.error("Una respuesta abierta se encuentra vacía");
                    } else {
                        arrayOpenAnswerQuestion.push({ idQuestion: element.idQuestion, answerText: $("#TAQ-" + element.idQuestion).val() });
                    }
                });
                setOpenQuestions(arrayOpenAnswerQuestion);
                saveResponseOpenClinic(collaboratorId, idEnterprise, idGroup, idPeriod, JSON.stringify(arrayOpenAnswerQuestion)).then((response) => {
                    switch (response?.code) {
                        case 1:
                            //guardado correcto
                            break;
                        default:
                            toastr.error("No se pudo guardar la respuesta, recargue la página y vuelva a intentarlo");
                            setErrorPage(true);
                            console.error("Error no documentado")
                            break;
                    }
                });
                updFinishSurveyClinic(collaboratorId, idEnterprise, idGroup, idPeriod).then((response) => {
                    switch (response?.code) {
                        case 1:
                            setShowMessage(true);
                            break;
                        default:
                            setErrorPage(true);
                            console.error("Error no documentado")
                            break;
                    }
                });
            }
        }
    }

    function handleClickUp() {
        // element which needs to be scrolled to
        const element = document.getElementById("start");
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }

    if (loading) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-5">
                <LoaderSurvey />
            </div>
        )
    } else if (showMessage) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <SurveyComplete />
            </div>
        )
    } else if (errorPage) {
        return (
            <div className="container-fluid">
                <SurveyError />
            </div>
        )
    } else if (pregnantMess) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <PregnantMessage />
            </div>
        )
    } else if (noConsent) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <NoConsentMessage />
            </div>
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <StatisticalFilterModal
                    gender={gender}
                    idCollab={collaboratorId}
                    idEnterprise={idEnterprise}
                    idGroup={idGroup}
                    setPregnantMess={setPregnantMess}
                    setShowSurvey={setShowSurvey}
                    isPregnant={isPregnant}
                    setPregnant={setIsPregnant}
                    idPeriod={idPeriod} />
                <StatisticalFilterNoConcent
                    gender={gender}
                    idCollab={collaboratorId}
                    idEnterprise={idEnterprise}
                    idGroup={idGroup}
                    setNoConsent={setNoConsent}
                    setShowSurvey={setShowSurvey}
                    isPregnant={isPregnant}
                    idPeriod={idPeriod} />
                <div className="row">
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1" id="start">
                        <img src="../img/logo_guay_color.png" alt="logo-guay" style={{ width: '100px' }} />
                    </div>
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1 font-large-1">
                        <span className="text-base-guay font-weight-bold">Cuestionario Filtro Clínico sobre Salud</span>
                    </div>
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                        <span>Ayúdanos completando el 100% de las respuestas</span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                        <div className="row">
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                <span className="text-base font-weight-bold">Filtro Clínico sobre Salud</span>
                            </div>
                            {questions.map((question, indiceQuestion) => {
                                if (question.idType === 1 || question.idType === 2 || question.idType === 3) {
                                    return (
                                        <div key={indiceQuestion} className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1">
                                            <div className="card shadow-card-1">
                                                <div className="card-header bg-blue-guay-2 py-1" id={`Q-${question.idQuestion}`}>
                                                    <span>{question.description}</span>
                                                </div>
                                                <div className="card-body">
                                                    <RadioGroup>
                                                        {answers.map((answer, indiceAnswer) => {
                                                            if (question.idQuestion === answer.idAnswerClinic.idQuestion) {
                                                                return (
                                                                    <div key={indiceAnswer}>
                                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                            <FormControlLabel value={answer.idAnswerClinic.idAnswer} onChange={() => HandleChangeRadio(answer)}
                                                                                control={
                                                                                    <Radio icon={<i className="ft ft-circle text-base align-middle" />}
                                                                                        checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />}
                                                                                        checked={answerAnswered.some(answerAns => answerAns.idAnswer === answer.idAnswerClinic.idAnswer)}
                                                                                        size="small" />
                                                                                }
                                                                                label={answer.description} />
                                                                            <FormGroup>
                                                                                {subAnswers.map((subAnswer, indiceAnswer) => {
                                                                                    if (answerAnswered.some(answerAns => answerAns.idAnswer === answer.idAnswerClinic.idAnswer) && answer.idAnswerClinic.idAnswer === subAnswer.idCatSubanswerClinic.idAnswer) {
                                                                                        return (
                                                                                            <div key={indiceAnswer}>
                                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                icon={<i className="ft ft-square text-base align-middle" />}
                                                                                                                checkedIcon={<i className="ft ft-check-square text-base-guay align-middle" />}
                                                                                                                checked={subanswerAnswered.some(answerAns => answerAns.idSubanswer === subAnswer.idCatSubanswerClinic.idSubanswer)}
                                                                                                                onChange={() => HandleChangeCheckBox(subAnswer, question.idQuestion)}
                                                                                                            />
                                                                                                        }
                                                                                                        label={subAnswer.description} />
                                                                                                </div>
                                                                                                {subanswerAnswered.some(answerAns => answerAns.idSubanswer === subAnswer.idCatSubanswerClinic.idSubanswer) && subAnswer.idType === 4 ? <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                                                    <textarea id={`TAQ-${question.idQuestion}`} className="form-control" name="textarea" maxLength="100" placeholder="Escriba aquí..." defaultValue={answerAnswered.find(element => element.idAnswer === answer.idAnswerClinic.idAnswer)?.answerText} onChange={(e) => HandleChangeTextArea(question.idQuestion, e)}></textarea>
                                                                                                </div>
                                                                                                    : null}
                                                                                            </div>
                                                                                        )
                                                                                    } else {
                                                                                        return (null)
                                                                                    }
                                                                                })}
                                                                            </FormGroup>
                                                                        </div>
                                                                        {answerAnswered.some(answerAns => answerAns.idAnswer === answer.idAnswerClinic.idAnswer) && answer.flagText === 1 ? <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                            <textarea id={`TAQ-${question.idQuestion}`} className="form-control" name="textarea" maxLength="100" placeholder="Escriba aquí..." defaultValue={answerAnswered.find(element => element.idAnswer === answer.idAnswerClinic.idAnswer)?.answerText} onChange={(e) => HandleChangeTextArea(question.idQuestion, e)}></textarea>
                                                                        </div>
                                                                            : null}
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (null)
                                                            }
                                                        })}
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else if (question.idType === 4) {
                                    return (
                                        <div key={indiceQuestion} className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1">
                                            <div className="card shadow-card-1">
                                                <div className="card-header bg-blue-guay-2 py-1">
                                                    <span>{question.description}</span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                        <textarea id={`TAQ-${question.idQuestion}`} className="form-control" name="textarea" rows="3" maxLength="200" placeholder="Escriba aquí..." defaultValue={answerAnswered.find(element => element.idAnswerClinic.idQuestion === question.idQuestion)?.answerText} onChange={(e) => HandleChangeTextArea(question.idQuestion, e)}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3 d-flex justify-content-center">
                            <button onClick={HandleClickEndSurvey} className="btn btn-small btn-blue-3">Finalizar</button>
                        </div>
                    </div>
                    <button className="arrow-up" onClick={handleClickUp}><i className="ft-arrow-up icon-medium-size" /></button>
                </div>
            </div>
        )

    }
}

export default FiltroClinico
