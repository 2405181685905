import React, { useState, useEffect, useRef, Fragment } from 'react';
// API calls
import { getBannersByEnterprise, setClickBrandingByUser, setViewTimeByUser } from '../Models/SponsorModel';
// Tools
import * as toastr from 'toastr';
import { getJwt } from '../../../lib/auth';
import $ from "jquery";

const BannerWebinar = ({ setShowBanner }) => {
    const [bannerImg, setBannerImg] = useState('');
    const [showCloseBtn, setShowCloseBtn] = useState(false);
    const [noBanner, setNobanner] = useState(true);
    useEffect(() => {
        if (getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined') {
            setShowBanner(false)
            setCount(31)
        } else {
            getBannersByEnterprise(getJwt('enterprise'), 2).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setBannerImg(response.resource);
                            break;
                        case 1002:
                            setNobanner(false);
                            setShowBanner(false)
                            setCount(31)
                            break;
                        default:
                            toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                            setShowBanner(false)
                            setCount(31)
                            break;
                    }
                } else {
                    toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                    setShowBanner(false)
                    setCount(31)
                }
            });
        }
    }, [setShowBanner]);
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
    const [count, setCount] = useState(0);
    const [hideBanner, setHideBanner] = useState(false);

    useInterval(() => {
        if (count === 10) {
            setShowCloseBtn(true);
        }
        if (count === 30) {
            if (hideBanner === true) {
                setCount(0);
                setHideBanner(false);
                $('#img-banner-promo').removeClass('hidden');
                $('#close-banner-promo').removeClass('hidden');
            } else {
                if (noBanner) {
                    setCount(0);
                    let formData = new FormData();
                    let data = {
                        idBrandPresence: bannerImg[0].idBrandPresence,
                        idUser: getJwt('jtw'),
                        idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                        time: 30,
                    }
                    formData.append("request", JSON.stringify(data));
                    setViewTimeByUser(formData);
                    getBannersByEnterprise(getJwt('enterprise'), 2).then(response => {
                        if (response) {
                            switch (response.code) {
                                case 1:
                                    setBannerImg(response.resource);
                                    setShowCloseBtn(false);
                                    break;
                                case 1002:
                                    setNobanner(false)
                                    break;
                                default:
                                    toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                                    break;
                            }
                        } else {
                            toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                        }
                    });
                }
            }
        } else {
            if (count > 30) {

            } else {
                setCount(count + 1);
            }
        }
    }, 1000);
    
    return (
        <div
            className='d-flex justify-content-center p-0'
        >
            {
                bannerImg.length === 0 ? (
                    null
                ) : (
                    <Fragment>
                        {
                            showCloseBtn === true ? (
                                <>
                                    <button
                                        type="button"
                                        className="close"
                                        style={{ position: 'absolute', right: '0' }}
                                        onClick={() => {
                                            let formData = new FormData();
                                            let data = {
                                                idBrandPresence: bannerImg[0].idBrandPresence,
                                                idUser: getJwt('jtw'),
                                                idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                                                time: count,
                                            }
                                            formData.append("request", JSON.stringify(data));
                                            setViewTimeByUser(formData);
                                            $('#img-banner-promo').addClass('hidden');
                                            $('#close-banner-promo').addClass('hidden');
                                            setHideBanner(true);
                                            getBannersByEnterprise(getJwt('enterprise'), 2).then(response => {
                                                if (response) {
                                                    switch (response.code) {
                                                        case 1:
                                                            setBannerImg(response.resource);
                                                            setShowCloseBtn(false);
                                                            break;
                                                        case 1002:

                                                            break;
                                                        default:
                                                            toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                                                            break;
                                                    }
                                                } else {
                                                    toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                                                }
                                            });
                                        }}
                                        id='close-banner-promo'
                                    >&times;</button>
                                </>
                            ) : (
                                null
                            )
                        }
                        <img
                            style={{ width: '100%', maxHeight: '250px' }}
                            src={bannerImg[0].resourceUrl}
                            onClick={() => {
                                if (bannerImg[0].customUrl1 !== '') {
                                    let formData = new FormData();
                                    let data = {
                                        idBrandPresence: bannerImg[0].idBrandPresence,
                                        idUser: getJwt('jtw'),
                                        idEnterprise: getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? 0 : getJwt('enterprise'),
                                    }
                                    formData.append("request", JSON.stringify(data));
                                    setClickBrandingByUser(formData);
                                    let protocol = bannerImg[0].customUrl1.split(':');
                                    if (protocol[0] === 'https' || protocol[0] === 'http') {
                                        window.open(bannerImg[0].customUrl1);
                                    } else {
                                        window.open("//" + bannerImg[0].customUrl1);
                                    }
                                }
                            }}
                            alt='banner'
                            id='img-banner-promo'
                        />
                    </Fragment>
                )
            }
        </div>
    );
};

export default BannerWebinar;
