import axios from "axios";
// Model From User Data
/*
* Get General user data by user ID
*/
export const getuserData = async (userId) => {
  try {
    let result = await axios({
      headers: {
        Authorization: global.tokenAuth
      },
      url: global.base_url + "users/data?user=" + userId,
      method: 'POST'
    });
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
  }
};