import React/* , { useState, useEffect } */ from 'react';
/* // Components
import ImageViewer from './ImageViewer';
// Tools
import $ from "jquery";
import swal from "sweetalert";
// API calls
import { getGuayBenefits } from '../Models/Catalog_model'; */

const AppBenefits = () => {

    /* const [products, setProducts] = useState([]);
    const [imagePreview, setImagePreview] = useState("");

    useEffect(() => {
        getGuayBenefits().then((response) => {
            if (response) {
                setProducts(response);
            }
        });
    }, []);

    const handlePreview = url => {
        let file = url;
        let fileTypes = [
            ".png",
            ".jpeg",
            ".jpg"
        ];
        let exist = false;
        for (let x in fileTypes) {
            let isType = file.indexOf(fileTypes[x]);
            if (isType !== -1) {
                exist = true
            }
        }
        if (exist === true) {
            setImagePreview(url);
            $("#modalViewer").show();
        } else {
            swal({
                title: "No se puede previsualizar",
                text: "No se puede previsualizar este tipo de documento. ¿Deseas descargarlo?",
                icon: "warning",
                buttons: ["Cancelar", "Sí, descargar"],
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete) {
                    window.open(file);
                }
            });
        }
    } */

    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
            <div
                    className="col-sx-12 col-sm-12 col-md-8 col-lg-3 mx-auto d-flex justify-content-center flex-wrap align-content-center"
                    style={{ height: "100vh" }}>
                    <img className="img-responsive" src="./../img/APP_guay-13.png" alt="guay-benefits" />
                </div>
                {/* {
                    products.map((product) => (
                        <div
                            className="col-sx-12 col-sm-12 col-md-4 col-lg-4 mt-2"
                            key={product.idGuayShop}>
                            <div className="card">
                                <img className="card-img-top img-responsive" src={product.url_preview} alt="img-product" />
                                <div className="card-body">
                                    <h4 className="card-title">{product.title}</h4>
                                    <p className="card-text">{product.summary}</p>
                                    <button
                                        className="btn btn-blue-3 btn-sm"
                                        onClick={() => handlePreview(product.url)}>¡Aprovéchalo!</button>
                                </div>
                            </div>
                        </div>
                    ))
                } */}
            </div>
            {/* <ImageViewer image={imagePreview} /> */}
        </div>
    )
}

export default AppBenefits;
