import axios from 'axios'

export const getComplementaryEvidenceByEnterprise = async (
    idEnterprise,
    idProduct,
    idCategory,
    idSubcategory,
) => {
    try {
        let result = await axios({
            url: `${global.base_url}complaint/getComplementaryEvidenceByEnterprise?idEnterprise=${idEnterprise}&idProduct=${idProduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        if (result.data) {
            return result.data
        }
    } catch (error) {
        console.log(error)
    }
}
/**
 * Get evidence list
 * @param {number} idProdduct 
 * @param {number} idCategory 
 * @param {number} idSubcategory 
 * @returns 
 */
export const getResources = async (idProdduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}complaint/getComplementaryEvidenceByEnterprise?idProduct=${idProdduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * Set new evidence record
 * @param {object} obj 
 * @param {Array} file 
 * @returns 
 */
export const saveComplementaryEvidence = async (obj, file) => {
    try {
        const formData = new FormData()
        //formData.append('resourceType', obj.resourceType)
        formData.append('title', obj.title)
        formData.append('description', obj.description)
        formData.append('idUser', obj.idUser)
        formData.append('idEnterprise', obj.idEnterprise)
        file.forEach(element => {
            formData.append('file', element)
        })
        formData.append('idComplementaryEvidence', obj.idComplementaryEvidence)
        formData.append('idProduct', obj.idProduct)
        formData.append('idCategory', obj.idCategory)
        formData.append('idSubcategory', obj.idSubcategory)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}complaint/saveComplementaryEvidence`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * Edit general information of complementary evidence
 * @param {object} obj 
 * @returns 
 */
export const editComplementaryEvidence = async (obj) => {
    try {
        const formData = new FormData()
        formData.append('idComplementaryEvidence', obj.idComplementaryEvidence)
        formData.append('idUser', obj.idUser)
        formData.append('active', 1)
        formData.append('title', obj.title)
        formData.append('description', obj.description)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}complaint/saveComplementaryEvidence`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * Delete an specific evidence file
 * @param {object} obj 
 * @returns 
 */
export const deleteSpecificEvidence = async (obj) => {
    try {
        const formData = new FormData()
        formData.append('idUser', obj.idUser)
        formData.append('idComplementaryEvidence', obj.idComplementaryEvidence)
        formData.append('idComplementaryEvidenceResources', obj.idComplementaryEvidenceResources)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}complaint/editComplementaryEvidence`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * Add new evidence file
 * @param {object} obj 
 * @param {Array} file 
 * @returns 
 */
export const addEvidenceFile = async (obj, file) => {
    try {
        const formData = new FormData()
        formData.append('idUser', obj.idUser)
        formData.append('idComplementaryEvidence', obj.idComplementaryEvidence)
        formData.append('idComplementaryEvidenceResources', obj.idComplementaryEvidenceResources)
        file.forEach(element => {
            formData.append('file', element)
        })
        //formData.append('resourceType', obj.resourceType)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}complaint/editComplementaryEvidence`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * Delete evidence
 * @param {*} obj 
 * @returns 
 */
export const deleteEvidence = async (obj) => {
    try {
        const formData = new FormData()
        formData.append('idComplementaryEvidence', obj.idComplementaryEvidence)
        formData.append('idUser', obj.idUser)
        formData.append('active', 0)
        formData.append('idProduct', obj.idProduct)
        formData.append('idCategory', obj.idCategory)
        formData.append('idSubcategory', obj.idSubcategory)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url}complaint/saveComplementaryEvidence`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
