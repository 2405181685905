import axios from "axios";

/* Get all profile data from a specific user by user id*/
export const getProfilebyUser = async (userId) => {
    try {
        let result = await axios({
            url: global.base_url + "profile/getProfilebyUser?idUser=" + userId + "",
            method: "GET",
            dataType: "json",
            ContentType: "application/json",
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/* Set all profile data from a specific user by user id*/
export const updProfilebyUser = async (data) => {
    try {
        let result = await axios({
            url: global.base_url + "profile/updProfilebyUser",
            method: "POST",
            data: data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
        /* const url = global.base_url + "profile/updProfilebyUser";
        const response = await axios.post(url, data);
        if (response.data) {
            return response.data;
        } */
    } catch (error) {
        console.log(error);
    }
};

/* Get all profile data from a specific user by user id*/
export const getProfileNetworkbyUser = async (userId) => {
    try {
        let result = await axios({
            url:
                global.base_url +
                "profile/getProfileNetworkbyUser?idUser=" +
                userId +
                "",
            method: "GET",
            dataType: "json",
            ContentType: "application/json",
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/* Set all profile data from a specific user by user id*/
export const updProfileNetworkbyUser = async (data) => {
    try {
        let result = await axios({
            url: global.base_url + "profile/updProfileNetworkbyUser",
            method: "POST",
            data: data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
        /* const url = global.base_url + "profile/updProfileNetworkbyUser";
        const response = await axios.post(url, data);
        if (response.data) {
            return response.data;
        } */
    } catch (error) {
        console.log(error);
    }
};

/* Set a new password */
export const updNewPasswordUser = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "users/updNewPasswordUser",
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data;",
                Authorization: global.tokenAuth
            },
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

/* Update email using an object
@parameters
{
    idUser: "",
    email: ""
} */
export const updateEmail = async (data) => {
    try {
        let result = await axios({
            url: global.base_url + "profile/updateEmail",
            method: "POST",
            data: data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
        /* const url = global.base_url + "profile/updateEmail";
        const response = await axios.post(url, data);
        if (response.data) {
            return response.data;
        } */
    } catch (error) {
        console.log(error);
    }
};

/* Update email using an object
@parameters
{
    idProfile: ""
} 
*/
export const confirmEmail = async (data) => {
    try {
        let result = await axios({
            url: global.base_url + "profile/confirmEmail",
            method: "POST",
            data: data,
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
        /* const url = global.base_url + "profile/confirmEmail";
        const response = await axios.post(url, data);
        if (response.data) {
            return response.data;
        } */
    } catch (error) {
        console.log(error);
    }
};