import React from 'react'
// TODO: Tools
import { useHistory } from 'react-router-dom'

const Message = ({ setShowMessage }) => {
    const history = useHistory()
    const handleOnClick = () => {
        history.push('/inicio-colaborador')
    }
    return (
        <div
            className='col-sx-11 col-sm-11 col-md-8 col-lg-6 col-xxl-4'
            style={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                zIndex: '1000',
            }}
        >
            <div className='container-fluid'>
                <img
                    className='img-fluid'
                    src='./../img/imagesProfile/Mensaje_guay.png'
                    alt='message'
                    onClick={() => setShowMessage(false)}
                />
                <div
                    className='col-7 d-flex justify-content-center flex-wrap align-content-center h-100'
                    style={{
                        position: 'absolute',
                        top: '0',
                    }}
                >
                    <p className='text-center text-base'>En esta sección encontraras tu perfil</p>
                    <p className='text-center text-base'>Para regresar a la pantalla principal da click <u className='text-base-guay pointer' onClick={handleOnClick}>aqui</u> o presiona el logo</p>
                    <img
                        className='img-logo-nav pointer'
                        src='./../img/guay-logo.png'
                        alt='logo'
                        onClick={handleOnClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default Message
