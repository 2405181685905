import { React ,useState} from 'react';
import { colorsArray } from '../../../helpers/helpers';
import ReactApexChart from "react-apexcharts";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
const Dispositivos = ({
    Graphics3,
    DataGraphic,
    DataTable
     }) => {
    const [views,setViews]=useState(0);        
    /**
     * Graphic Bar
     */
     const serie3B =Graphics3.serie?Graphics3.serie:[] ;
     const options3B={
       chart: {
           type: 'bar',
           height: 350
         },

         plotOptions: {
           bar: {
             horizontal: false,
             columnWidth: '85%',
             endingShape: 'flat'
           },
         },
         dataLabels: {
           enabled: false
         },
         stroke: {
           show: true,
           width: 2,
           colors: ['transparent']
         },
         tooltip: {
          y: {
            formatter: function (val) {
              return val 
            }
          }
        },
         xaxis: {
           categories: Graphics3.serie?Graphics3.categories:[],
           labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            trim: true,
            
          }   
         },
         yaxis: {
           title: {
             text: 'Colaboradores'
           }
         },
         colors: colorsArray,
         fill: {
            colors: colorsArray,
           opacity: 1
         },
         
    };
      
    const rowStyleFormat = () => {
      return { backgroundColor: '#fff' }
    }

    const tdStyleFormat = () => {
        return { padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word', height: '50px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1' }
    }

    const thStyleFormat = () => {
        return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '0px', paddingRight: '0px' }
    }
   
    const opciones = {
      sizePerPage: 5,
      hideSizePerPage: true,
      noDataText: 'No se encontraron registros.',
  } 
  const changeView = () => {
      if(views===0){
        setViews(1);
      }else{
        setViews(0);
      }
    };

    
    return (
      <div className='row'>        
        <div id="tableros" className="col-xl-5 col-lg-5 col-12">        
            <div className='row'>
                <div className="col-xl-5 col-lg-5 col-12 ml-1">
                   <div className='ml-1'>
                        <div className="content_movil">
                            <img src="/img/MonitoreoMailGun/movil.png" style={{maxWidth: '55%',height: 'auto'}} alt="movil"/>
                            <label className="title-imgmovil" style={{fontSize:'2rem',color:'#9a5587'}}>{DataGraphic.totalRegisterCel}</label>
                        </div>
                   </div>
                </div>
                
                <div className="col-xl-5 col-lg-5 col-12 mt-2">
                    <div className="content_web">
                        <img src="/img/MonitoreoMailGun/web.png" style={{width: '75%'}} alt="web" />
                        <h1 className="title-imgweb" style={{color:'#d69356'}}>{DataGraphic.totalRegisterWeb}</h1>
                    </div>                    
                </div>
            </div>
            <div className='row mt-1'>
                <div className="col-xl-6 col-lg-6 col-12">                   
                    <label className="text-gray text-center"> Registros en celular </label>
                    <label className="text-gray text-center ml-1" style={{fontSize:'1rem',color:'#9a5587'}}>  {DataGraphic.PerRegisterCel} %</label>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-12 ">                    
                    <label className="text-gray text-center"> Registros en web  </label>
                    <label className="text-gray text-center ml-1" style={{fontSize:'1rem',color:'#d69356'}}>{DataGraphic.PerRegisterWeb} %</label>
                </div>
                <div className='col-xl-12 col-lg-12 col-12 text-center'>
                  <span
                      className=" fake-select pointer"
                      onClick={() => changeView() }>{views===0?"Cambiar vista a tabla":"Cambiar vista a grafica"}
                       <img alt="flecha" src='/img/MonitoreoMailGun/cambio.png' style={{width: '4%'}} className='ml-1'></img> 
                  </span>
              </div> 
            </div>           
             
        </div>
        
        <div id="grafica"  className="col-xl-7 col-lg-7 col-12 ">

            {views===0?
            <ReactApexChart
            id="grfica1"
            type="bar"
            options={options3B}
            series={serie3B}
            height={450}
          />:
          
          <section className="card mr-1 ml-1 bootomFooter">                            
                <div className="card-content collapse show">
                <div className="card-body">
                <BootstrapTable
                    data={DataTable}
                    striped
                    hover
                    condensed
                    pagination={true}
                    tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                    trClassName='tr-data-table'
                    options={opciones}
                    trStyle={rowStyleFormat}
                >
                    <TableHeaderColumn dataField="enterprise" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} > Organizacion</TableHeaderColumn>
                    <TableHeaderColumn dataField="totalCel" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Celular</TableHeaderColumn>
                    <TableHeaderColumn dataField="perCel" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()}  >% Celular</TableHeaderColumn>
                    <TableHeaderColumn isKey dataField="totalWeb" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Web</TableHeaderColumn>
                    <TableHeaderColumn dataField="perWeb" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >% Web</TableHeaderColumn>
                    <TableHeaderColumn dataField="totalCollab" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()}  >Total Colaboradores</TableHeaderColumn> 
                    
                </BootstrapTable>
                </div>
            </div>
        </section>}

            

        </div>

        
            
            
      </div>
    )
}



export default Dispositivos;
