import React, { useState, useEffect, Fragment } from "react";
// Components
import StatisticalFilterStructure from "./StatisticalFilterStructure";
import StatisticalFilterModal from "./StatisticalFilterModal";
import StatisticalFilterNoConcent from "./StatisticalFilterNoConcent";
import SurveyComplete from "../../SurveyComplete";
import SurveyError from "../../SurveyError";
import NoConsentMessage from "../NoConsentMessage";
import PregnantMessage from "../PregnantMessage";
// API calls
import { validationAccessStatistics, saveResponseStatistics, updFinishSurveyStatistics } from '../../Models/AssistanceModel';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const StatisticalFilterController = props => {
    // Global variables
    const [idEnterprise, setIdEnterprise] = useState(0);
    const [idGroup, setIdGroup] = useState(0);
    const [idCollab, setIdCollab] = useState(0);
    const idPeriod = 0;
    const [todayDate, setTodayDate] = useState("");
    const [browser, setBrowser] = useState("");
    const [OS, setOS] = useState("");
    const [coordinates, setCoordinates] = useState("");
    const [listQuestions, setListQuestions] = useState([]);
    const [openQuestion, setOpenQuestion] = useState("");
    const [gender, setGender] = useState(0);
    const [completeSurvey, setCompleteSurvey] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [submitSurveyButton, setsubmitSurveyButton] = useState(false);
    const [noConsent, setNoConsent] = useState(false);
    const [pregnantMess, setPregnantMess] = useState(false);
    const [isPregnant, setPregnant] = useState(0);
    useEffect(() => {
        const getURLParameters = props.match.params;
        let parameters = "0-0-0-0";
        try {
            parameters = atob(getURLParameters.db);
        } catch (error) {
            console.error(error)
        }
        let parameter = parameters.split("-");
        let idCollab = parameter[0];
        let idEnterprise = parameter[1];
        let idGroup = parameter[2];
        setIdCollab(parseInt(idCollab));
        setIdEnterprise(parseInt(idEnterprise));
        setIdGroup(parseInt(idGroup));
        validationAccessStatistics(idCollab, idEnterprise, idGroup).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let listQuestions = [];
                        let listAnswers = [];
                        // Set all the answers by question
                        for (let x in response.listCatQuestions) {
                            for (let y in response.listCatAnswers) {
                                if (response.listCatQuestions[x].idType === response.listCatAnswers[y].idType) {
                                    listAnswers.push({ ...response.listCatAnswers[y], check: 0 });
                                }
                            }
                            listQuestions.push({ ...response.listCatQuestions[x], listAnswers });
                            listAnswers = [];
                        }
                        // Check all the answers where idQuestion is between 1 - 6
                        for (let x in listQuestions) {
                            for (let y in response.listAnswerEvent) {
                                if (response.listAnswerEvent[y].idAnswerstatisticalEvent.idQuestion === listQuestions[x].idQuestion) {
                                    if ((response.listAnswerEvent[y].answer1 !== undefined || response.listAnswerEvent[y].answer1 !== "undefined") && response.listAnswerEvent[y].answer1 === 1) {
                                        for (let z in listQuestions[x].listAnswers) {
                                            if (listQuestions[x].listAnswers[z].idAnswer === 1) {
                                                listQuestions[x].listAnswers[z].check = 1;
                                            }
                                        }
                                    }
                                    if ((response.listAnswerEvent[y].answer2 !== undefined || response.listAnswerEvent[y].answer2 !== "undefined") && response.listAnswerEvent[y].answer2 === 1) {
                                        for (let z in listQuestions[x].listAnswers) {
                                            if (listQuestions[x].listAnswers[z].idAnswer === 2) {
                                                listQuestions[x].listAnswers[z].check = 1;
                                            }
                                        }
                                    }
                                    if ((response.listAnswerEvent[y].answer3 !== undefined || response.listAnswerEvent[y].answer3 !== "undefined") && response.listAnswerEvent[y].answer3 === 1) {
                                        for (let z in listQuestions[x].listAnswers) {
                                            if (listQuestions[x].listAnswers[z].idAnswer === 3) {
                                                listQuestions[x].listAnswers[z].check = 1;
                                            }
                                        }
                                    }
                                    if ((response.listAnswerEvent[y].answer4 !== undefined || response.listAnswerEvent[y].answer4 !== "undefined") && response.listAnswerEvent[y].answer4 === 1) {
                                        for (let z in listQuestions[x].listAnswers) {
                                            if (listQuestions[x].listAnswers[z].idAnswer === 4) {
                                                listQuestions[x].listAnswers[z].check = 1;
                                            }
                                        }
                                    }
                                    if ((response.listAnswerEvent[y].answer5 !== undefined || response.listAnswerEvent[y].answer5 !== "undefined") && response.listAnswerEvent[y].answer5 === 1) {
                                        for (let z in listQuestions[x].listAnswers) {
                                            if (listQuestions[x].listAnswers[z].idAnswer === 5) {
                                                listQuestions[x].listAnswers[z].check = 1;
                                            }
                                        }
                                    }
                                    if ((response.listAnswerEvent[y].answer6 !== undefined || response.listAnswerEvent[y].answer6 !== "undefined") && response.listAnswerEvent[y].answer6 === 1) {
                                        for (let z in listQuestions[x].listAnswers) {
                                            if (listQuestions[x].listAnswers[z].idAnswer === 6) {
                                                listQuestions[x].listAnswers[z].check = 1;
                                            }
                                        }
                                    }
                                    if (response.listAnswerEvent[y].answer7 !== undefined || response.listAnswerEvent[y].answer7 !== "undefined") {
                                        setOpenQuestion(response.listAnswerEvent[y].answer7);
                                    }
                                }
                            }
                            // Check all the answers where idQuestion is higher than 6
                            for (let y in response.listAnswerAffect) {
                                if (response.listAnswerAffect[y].idAnswerStatisticalAffectation.idQuestion === listQuestions[x].idQuestion) {
                                    for (let z in listQuestions[x].listAnswers) {
                                        if (listQuestions[x].listAnswers[z].idAnswer === response.listAnswerAffect[y].idAnswer) {
                                            listQuestions[x].listAnswers[z].check = 1;
                                        }
                                    }
                                }
                            }
                        }
                        setGender(response.gender);
                        setListQuestions(listQuestions);
                        setShowSurvey(true);
                        if (response.consent === 0) {
                            $('#wellcomeMessage').show();
                        }
                        /* hanldeCompleteSurvey(listQuestions, 0); */
                        break;
                    case 2:
                        window.location.replace(response.url);
                        break;
                    case 3:
                        setCompleteSurvey(true);
                        break;
                    case 4:
                        setNoConsent(true);
                        break;
                    case 5:
                        setPregnantMess(true);
                        break;
                    default:
                        setErrorMessage(true);
                        break;
                }
            }
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        // Set format date dd/mm/yyyy hh:mm:ss
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        const fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setTodayDate(fechaActual);
        // Set browser
        let browser = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "other";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(browser);
        // Set Operative Sistem
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "other";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOS(so);
        // Set geolocation
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
    }, []);
    /**
     * 
     * @param {event} e 
     * @param {int} idQuestion 
     * @param {int} idAnswer 
     */
    const handleSelectAnswer = (e, idQuestion, idAnswer) => {
        e.preventDefault();
        let saveR = [...listQuestions];
        if (idQuestion > 6) {
            for (let x in saveR) {
                if (saveR[x].idQuestion === idQuestion) {
                    for (let y in saveR[x].listAnswers) {
                        if (saveR[x].listAnswers[y].idAnswer === idAnswer) {
                            saveResponseStatistics(idAnswer.toString(), idQuestion, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
                                if (response) {
                                    switch (response.code) {
                                        case 1:
                                            saveR[x].listAnswers[y].check = 1;
                                            setListQuestions(saveR);
                                            handleCompleteSurvey(saveR, 1);
                                            let elementID = idQuestion;
                                            let nextIndex = parseInt(x) + 1;
                                            if (saveR.length > nextIndex) {
                                                elementID = saveR[nextIndex].idQuestion;
                                                // element which needs to be scrolled to
                                                const element = document.getElementById(`${elementID}`);
                                                // scroll to element
                                                element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                            }
                                            break;
                                        default:
                                            toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                                            break;
                                    }
                                } else {
                                    toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                                }
                            });
                        } else {
                            saveR[x].listAnswers[y].check = 0;
                        }
                    }
                }
            }
        } else {
            for (let x in saveR) {
                if (saveR[x].idQuestion === idQuestion) {
                    let answers = [];
                    for (let y in saveR[x].listAnswers) {
                        if (saveR[x].listAnswers[y].idAnswer === idAnswer && saveR[x].listAnswers[y].check === 0) {
                            saveR[x].listAnswers[y].check = 1;
                            answers.push({ idAnswer: saveR[x].listAnswers[y].idAnswer, check: 1 });
                        } else {
                            answers.push({ idAnswer: saveR[x].listAnswers[y].idAnswer, check: saveR[x].listAnswers[y].check });
                            if (saveR[x].listAnswers[y].idAnswer === idAnswer) {
                                saveR[x].listAnswers[y].check = 0;
                                answers.push({ idAnswer: saveR[x].listAnswers[y].idAnswer, check: 0 });
                            }
                        }
                    }
                    let idsAnswers = "";
                    for (let x in answers) {
                        idsAnswers = idsAnswers + answers[x].check + "|";
                    }
                    saveResponseStatistics(idsAnswers.slice(0, -1), idQuestion, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
                        if (response) {
                            switch (response.code) {
                                case 1:
                                    setListQuestions(saveR);
                                    handleCompleteSurvey(saveR, 1);
                                    break;
                                default:
                                    toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                                    break;
                            }
                        }
                    });
                }
            }
        }
    }
    /**
     * 
     * @param {array} data 
     * @param {int} flag 
     */
    const handleCompleteSurvey = (data, flag) => {
        let totalAnswer = 0;
        let totalAnswered = 0;
        let totalMultipleAnswered = 0;
        let answers = [];
        let answers_2 = [];
        let isSix = 0;
        for (let x in data) {
            if (data[x].idQuestion === 6) {
                isSix = 1;
            }
            if (data[x].idQuestion > 6) {
                totalAnswer = totalAnswer + 1;
                for (let y in data[x].listAnswers) {
                    if (data[x].listAnswers[y].check === 1) {
                        totalAnswered = totalAnswered + 1;
                    }
                }
            } else {
                for (let y in data[x].listAnswers) {
                    if (data[x].listAnswers.length === data[x].listAnswers[y].idAnswer) {
                        answers.push({ idAnswer: data[x].listAnswers[y].idAnswer, check: data[x].listAnswers[y].check });
                        answers_2.push(answers);
                        answers = [];
                    } else {
                        answers.push({ idAnswer: data[x].listAnswers[y].idAnswer, check: data[x].listAnswers[y].check });
                    }
                }
            }
        }
        let idsAnswers = "";
        for (let x in answers_2) {
            for (let y in answers_2[x]) {
                idsAnswers = idsAnswers + answers_2[x][y].check + "|";
            }
            answers.push({ answersIds: idsAnswers });
            idsAnswers = "";
        }
        for (let x in answers) {
            if (answers[x].answersIds.indexOf("1") !== -1) {
                totalMultipleAnswered = totalMultipleAnswered + 1;
            }
        }
        if (flag === 0) {
            if (isSix === 1) {
                let text = openQuestion === undefined || openQuestion === "undefined" || openQuestion === "" ? (0) : 1;
                if (totalAnswered === totalAnswer && totalMultipleAnswered === answers.length) {
                    if (text !== "") {
                        setsubmitSurveyButton(true);
                        let elementID = "end";
                        // element which needs to be scrolled to
                        const element = document.getElementById(`${elementID}`);
                        // scroll to element
                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                    }
                }
            } else {
                if (totalAnswered === totalAnswer && totalMultipleAnswered === answers.length) {
                    setsubmitSurveyButton(true);
                    let elementID = "end";
                    // element which needs to be scrolled to
                    const element = document.getElementById(`${elementID}`);
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }
            }
        } else {
            if (isSix === 1) {
                let text = openQuestion === undefined || openQuestion === "undefined" || openQuestion === "" ? (0) : 1;
                if (totalAnswered === totalAnswer && totalMultipleAnswered === answers.length) {
                    if (text === 1) {
                        setsubmitSurveyButton(true);
                    }
                }
            } else {
                if (totalAnswered === totalAnswer && totalMultipleAnswered === answers.length) {
                    setsubmitSurveyButton(true);
                }
            }
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        if (submitSurveyButton === true) {
            let saveR = [...listQuestions];
            for (let x in saveR) {
                if (saveR[x].idQuestion === 6) {
                    let answers = [];
                    for (let y in saveR[x].listAnswers) {
                        answers.push({ idAnswer: saveR[x].listAnswers[y].idAnswer, check: saveR[x].listAnswers[y].check });
                    }
                    let idsAnswers = "";
                    for (let x in answers) {
                        idsAnswers = idsAnswers + answers[x].check + "|";
                    }
                    saveResponseStatistics(idsAnswers + openQuestion, 6, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
                        if (response) {
                            switch (response.code) {
                                case 1:
                                    setListQuestions(saveR);
                                    handleCompleteSurvey(saveR, 1);
                                    break;
                                default:
                                    toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                                    break;
                            }
                        }
                    });
                }
            }
            updFinishSurveyStatistics(idCollab, idEnterprise, idGroup).then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setShowSurvey(false);
                            setCompleteSurvey(true);
                            break;
                        case 2:
                            window.location.replace(response.url);
                            break;
                        default:
                            toastr.error("No se guardó correctamente la encuesta. Intentelo nuevamente.", "¡Ooops!");
                            break;
                    }
                }
            });
        } else {
            toastr.error("Aún quedan respuestas por contestar.", "¡Ooops!");
        }
    }
    return (
        <Fragment>
            {
                completeSurvey === true
                    ?
                    (<SurveyComplete />)
                    :
                    (null)
            }
            {
                showSurvey === true
                    ?
                    (
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1" id="start">
                                    <img src="../img/logo_guay_color.png" alt="logo-guay" style={{ width: '100px' }} />
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1 font-large-1">
                                    <span className="text-base-guay font-weight-bold">Filtro estadístico</span>
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                                    <span>Ayúdanos completando el 100% de las respuestas</span>
                                </div>
                            </div>
                            <StatisticalFilterStructure
                                listQuestions={listQuestions}
                                setListQuestions={setListQuestions}
                                openQuestion={openQuestion}
                                handleSelectAnswer={handleSelectAnswer}
                                setOpenQuestion={setOpenQuestion}
                                handleCompleteSurvey={handleCompleteSurvey} />
                            <div className="row">
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center py-2" id="end">
                                    <button className="btn btn-blue-3 btn-sm" onClick={handleSubmit} >Finalizar</button>
                                </div>
                            </div>
                            {/* {submitSurveyButton === true
                                ?
                                (
                                    <div className="row">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center py-2" id="end">
                                            <button className="btn btn-blue-3 btn-sm" onClick={handleSubmit} >Finalizar</button>
                                        </div>
                                    </div>
                                )
                                :
                                (null)
                            } */}
                            <StatisticalFilterModal
                                gender={gender}
                                idCollab={idCollab}
                                idEnterprise={idEnterprise}
                                idGroup={idGroup}
                                setPregnantMess={setPregnantMess}
                                setShowSurvey={setShowSurvey}
                                isPregnant={isPregnant}
                                setPregnant={setPregnant}
                                idPeriod={idPeriod} />
                            <StatisticalFilterNoConcent
                                gender={gender}
                                idCollab={idCollab}
                                idEnterprise={idEnterprise}
                                idGroup={idGroup}
                                setNoConsent={setNoConsent}
                                setShowSurvey={setShowSurvey}
                                isPregnant={isPregnant}
                                idPeriod={idPeriod} />
                        </div>
                    )
                    :
                    (null)
            }
            {
                errorMessage === true
                    ?
                    (<SurveyError />)
                    :
                    (null)
            }
            {
                noConsent === true
                    ?
                    (<NoConsentMessage />)
                    :
                    (null)
            }
            {
                pregnantMess === true
                    ?
                    (<PregnantMessage />)
                    :
                    (null)
            }
        </Fragment>
    )
}

export default StatisticalFilterController;