/*
 File Name: Users_model.js
 Description: Manage Sessions users API Requests
 guay dev's team
*/
import axios from "axios";

export const getuserData = async (userId) => {
    try {
        let result = await axios({
            url: global.base_url + "users/data?user=" + userId,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getProductsUser = async (idUser) => {
    try {
        let result = await axios({
            url: global.base_url + "users/getProductsUser?idUser=" + idUser + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getProductsFree = async (idEnterprise, idUser) => {
    try {
        let result = await axios({
            url: global.base_url + "users/getProductsFree?idEnterprise=" + idEnterprise + "&idUser=" + idUser + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {object} data 
 * @returns 
 */
export const register = async (data) => {
    try {
        let result = await axios({
            url: global.base_url + "users/register",
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            },
            data: data
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const tokenFree = async (token, idUser) => {
    try {
        let result = await axios({
            url: global.base_url + "users/validate-tokenFree?db=" + token + "&gc=" + idUser + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
