import React, { useState, useEffect } from 'react';
// API calls
import { getOrderSpecific } from '../../../Models/Shop_model';
import { getSectors } from "../../../Models/Catalog_model";
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { getJwt } from '../../../../../lib/auth';

const StatusTable = ({
    setShowDetails,
    setDetails,
    setNotes,
    showTable,
    listClosed,
    listPending,
    listProgressing,
    setOrderID
}) => {
    // Globla variables
    const [sectors, setSectors] = useState([]);
    useEffect(() => {
        getSectors().then(response => {
            setSectors(response);
        });
    }, []);
    const history = useHistory();
    // Table Props
    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    };
    /**
     * Style rows
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const trClassFormat = (row, rowIndex) => {
        if (row.nuevoValor === 1) {
            return (
                "border-none-1"
            )
        }
    }
    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setIcon = (cell, row, rowIndex) => {
        return (
            <div>
                <i
                    onClick={() => {
                        setOrderID(row.idOrder);
                        getOrderSpecific(row.idOrder).then(response => {
                            if (response) {
                                switch (response.code) {
                                    case 1:
                                        setDetails(response);
                                        setNotes(response.orderLogList);
                                        setShowDetails(true);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });
                    }}
                    className='ft-eye text-base-guay pointer font-medium-1'
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Ver detalles"
                />
            </div>
        )
    }
    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setSector = (cell, row, rowIndex) => {
        for (let x in sectors) {
            if (sectors[x].sector_id === cell) {
                return (
                    <span>{sectors[x].description}</span>
                )
            }
        }
    }
    return (
        <div className="col-sx-11 col-sm-11 col-md-9 col-lg-9 mx-auto">
            <div className="card">
                <div className="card-body">
                    {
                        getJwt('business') !== 'true' ? (
                            <div className="col-12 mb-1">
                                <span
                                    className="pointer link-pink-guay"
                                    onClick={() => history.push("/tienda")}
                                ><i className="ft-arrow-left" /> Regresar</span>
                            </div>
                        ) : (
                            null
                        )
                    }
                    <div className="col-12">
                        {
                            getJwt('business') === 'true' ? (
                                <BootstrapTable
                                    data={showTable === 0 ? (listPending) : (showTable === 1 ? (listProgressing) : (listClosed))}
                                    hover
                                    pagination={true}
                                    trClassName={trClassFormat}
                                    options={options}
                                    headerStyle={{ border: 'red 0px solid' }}
                                    bodyStyle={{ border: 'green 0px solid' }}
                                    tableStyle={{ border: '#9cb0d1 2.5px solid', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                                >
                                    <TableHeaderColumn
                                        dataField="idOrder"
                                        isKey
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                        dataFormat={setIcon}
                                        width="5%"
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="order"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                        thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >No. Solicitud</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="descStatus"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >Estatus</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="enterpriseName"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >Empresa</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="phone"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >Teléfono</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="numCollabs"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                        thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >Colaboradores</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sectorId"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                        dataFormat={setSector}
                                    >Sector</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="register_date"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >Fecha</TableHeaderColumn>
                                </BootstrapTable>
                            ) : (
                                <BootstrapTable
                                    data={showTable === 0 ? (listPending) : (showTable === 1 ? (listProgressing) : (listClosed))}
                                    hover
                                    pagination={true}
                                    trClassName={trClassFormat}
                                    options={options}
                                    headerStyle={{ border: 'red 0px solid' }}
                                    bodyStyle={{ border: 'green 0px solid' }}
                                    tableStyle={{ border: '#9cb0d1 2.5px solid', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                                >
                                    <TableHeaderColumn
                                        dataField="idOrder"
                                        isKey
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                        dataFormat={setIcon}
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="order"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >No. Solicitud</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="register_date"
                                        dataAlign="center"
                                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                    >Fecha de solicitud</TableHeaderColumn>
                                </BootstrapTable>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

StatusTable.propTypes = {
    setShowDetails: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    setNotes: PropTypes.func.isRequired,
    showTable: PropTypes.number.isRequired,
    listClosed: PropTypes.array.isRequired,
    listPending: PropTypes.array.isRequired,
    listProgressing: PropTypes.array.isRequired,
    setOrderID: PropTypes.func.isRequired
}

export default StatusTable;
