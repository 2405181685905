import React, { useState, useEffect, Fragment } from 'react';
// API calls
import { getWebinars } from '../Models/Catalog_model';
import { setWebinarView } from '../Models/WebinarModel';
// Components
import Header from '../NewHome/headerColaborador';
import Menu from "../Menu";
import HelperApp from '../HelperApp';
import Footer from '../footerApp';
import BannerWebinar from './BannerWebinar';
// Tools
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import ReactPlayer from 'react-player/lazy';

const Webinar = () => {
    const [webinars, setWebinars] = useState([]);
    const [playedWebinar, setPlayedWebinar] = useState([]);
    const [webinarInfo, setWebinarInfo] = useState([]);
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        getWebinars().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let arr = []
                        for (let x in response.listWebinars) {
                            arr.push({ ...response.listWebinars[x], showVideo: false })
                        }
                        setWebinars(arr);
                        break;
                    default:
                        break;
                }
            }
        })
    }, []);

    const handleTouch = id => {
        //console.log(id)
        let arr = []
        for (let x in webinars) {
            if (webinars[x].idWebinar === id) {
                //console.log(webinars[x].idWebinar, id)
                arr.push({ ...webinars[x], showVideo: true })
            } else {
                arr.push(webinars[x])
            }
        }
        //console.log(arr)
        setWebinars(arr);
    }

    const handleOnStart = (idWebinar) => {
        //console.log("Comineza video");
        const objRequest = {
            idWebinarView: 0,
            idUser: parseInt(getJwt("jtw")),
            idEnterprise: parseInt(getJwt("enterprise")),
            idWebinar: idWebinar,
            time: 0,
        };
        setWebinarView(objRequest).then(response => {
            if (response) {
                if (response.validation) {
                    let newWebinarInfo = webinarInfo;
                    // Verificar si existe el webinar en el state
                    const getWebinar = newWebinarInfo.find(webinar => webinar.idWebinarView === response.data.idWebinarView);
                    if (getWebinar === undefined) {
                        objRequest.idWebinarView = response.data.idWebinarView;
                        // Agregar al nuevo array
                        newWebinarInfo.push(objRequest);
                        // Actualizar el state
                        setWebinarInfo(newWebinarInfo);
                    }
                }
            }
        });
    }

    const handleOnDuration = (duration, idWebinar) => {
        //console.log(duration)
        //console.log(idWebinar)
        const backupPlayedWebinar = playedWebinar;
        const getWebinar = backupPlayedWebinar.find(webinar => webinar.idWebinar === idWebinar);
        if (getWebinar === undefined) {
            backupPlayedWebinar.push({
                idWebinar,
                duration: Math.round(duration),
            });
            setPlayedWebinar(backupPlayedWebinar);
        }
    }

    const handleOnProgress = (progress, idWebinar) => {
        //console.log(progress.playedSeconds)
        //console.log(idWebinar)
        const backupPlayedWebinar = playedWebinar;
        const getPlatedWebinar = backupPlayedWebinar.filter(webinar => webinar.idWebinar === idWebinar);
        if (getPlatedWebinar.length > 0) {
            const backupWebinarInfo = webinarInfo;
            const getPlatedWebinarInfo = getPlatedWebinar[0];
            const progress25 = Math.round(getPlatedWebinarInfo.duration / 4);
            const progress50 = Math.round(getPlatedWebinarInfo.duration / 4) * 2;
            const progress75 = Math.round(getPlatedWebinarInfo.duration / 4) * 3;
            const progress100 = getPlatedWebinarInfo.duration - 5;
            const roundedProgress = Math.round(progress.playedSeconds);
            const getWebinarInfo = backupWebinarInfo.find(webinar => webinar.idWebinar === idWebinar);
            const objRequest = getWebinarInfo;

            let requestAllowed = false;

            if (progress25 === roundedProgress) {
                console.log("25% del video")
                objRequest.time = 25;
                console.log(objRequest)
                requestAllowed = true;
            }
            if (progress50 === roundedProgress) {
                console.log("50% del video")
                objRequest.time = 50;
                console.log(objRequest)
                requestAllowed = true;
            }
            if (progress75 === roundedProgress) {
                console.log("75% del video")
                objRequest.time = 75;
                console.log(objRequest)
                requestAllowed = true;
            }
            if (progress100 === roundedProgress) {
                console.log("100% del video")
                objRequest.time = 100;
                console.log(objRequest)
                requestAllowed = true;
            }

            if (requestAllowed) {
                setWebinarView(objRequest)
            }
        }
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href={getJwt('rol') === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")}>Inicio</a></li>
                                                <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Webinar</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className="col-11 mx-auto mt-1">
                                <div className="row">
                                    {
                                        webinars.map((data, index) => (
                                            index === 2 && showBanner ? (
                                                <Fragment>
                                                    <div className="col-12 col-sm-6 col-md-4 mb-2 d-flex justify-content-center flex-wrap align-content-center p-0" key={'punlicidad-1'}>
                                                        {
                                                            getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? ('') : (<BannerWebinar setShowBanner={setShowBanner} />)
                                                        }
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 mb-2" key={data.idWebinar}>
                                                        <div className="card m-0 h-100 hover-shadow-1">
                                                            <div className="card-img-top d-flex flex-wrap align-content-center h-100">
                                                                {data.showVideo ? (
                                                                    <ReactPlayer
                                                                        url={data.urlVideo}
                                                                        width="100%"
                                                                        height="100%"
                                                                        controls
                                                                        playing={true}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                        }}
                                                                        src={data.urlImg}
                                                                        alt="guay-digital"
                                                                        onClick={() => handleTouch(data.idWebinar)}
                                                                    />
                                                                )}
                                                            </div>
                                                            {data.showVideo && (
                                                                <div className="card-body d-flex flex-wrap align-content-center py-1">
                                                                    <h4 className="card-tilte font-weight-bold">{data.title}</h4>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ) : (
                                                <div className="col-12 col-sm-6 col-md-4 mb-2" key={data.idWebinar}>
                                                    <div className="card m-0 h-100 hover-shadow-1">
                                                        <div className="card-img-top d-flex flex-wrap align-content-center h-100">
                                                            {data.showVideo ? (
                                                                <ReactPlayer
                                                                    url={data.urlVideo}
                                                                    width="100%"
                                                                    height="100%"
                                                                    controls
                                                                    playing={true}
                                                                    onStart={() => handleOnStart(data.idWebinar)}
                                                                    onDuration={(e) => handleOnDuration(e, data.idWebinar)}
                                                                    onProgress={(e) => handleOnProgress(e, data.idWebinar)}
                                                                    onPause={(e) => {
                                                                        console.log(e)
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                    }}
                                                                    src={data.urlImg}
                                                                    alt="guay-digital"
                                                                    onClick={() => handleTouch(data.idWebinar)}
                                                                />
                                                            )}
                                                        </div>
                                                        {data.showVideo && (
                                                            <div className="card-body d-flex flex-wrap align-content-center py-1">
                                                                <h4 className="card-tilte font-weight-bold">{data.title}</h4>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Webinar;
