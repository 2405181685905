import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {redirectIfNotAuthenticated} from "../../../lib/auth";

const MainPage = () => {
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content content bg-login-4">
                <div className="content-overlay">
                    <div className="content-wrapper">
                        <div className="content-header row"></div>
                        <div className="content-body">
                            <div className="row">
                                <Header empresa={false} />
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mx-auto mt-150 mb-40">
                                    <div id="demo" className="carousel slide" data-ride="carousel">

                                        <ul className="carousel-indicators">
                                            <li data-target="#demo" data-slide-to="0" className="active guay carousel-indicators-10"></li>
                                            <li data-target="#demo" data-slide-to="1" className="guay carousel-indicators-10"></li>
                                            <li data-target="#demo" data-slide-to="2" className="guay carousel-indicators-10"></li>
                                        </ul>

                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <a href="https://guay.digital/resources/template/sabias_que_guay.pdf" target="_blank" rel="noopener noreferrer"><img className="img-responsive" src="./../img/inicio/sabias_shopy.png" alt="Guay-digital" /></a>
                                            </div>
                                            <div className="carousel-item">
                                            <a href="https://guay.digital/resources/template/sabias_que_guay.pdf" target="_blank" rel="noopener noreferrer"><img className="img-responsive" src="./../img/inicio/sabias_shopy.png" alt="Guay-digital" /></a>
                                            </div>
                                            <div className="carousel-item">
                                            <a href="https://guay.digital/resources/template/sabias_que_guay.pdf" target="_blank" rel="noopener noreferrer"><img className="img-responsive" src="./../img/inicio/sabias_shopy.png" alt="Guay-digital" /></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainPage;