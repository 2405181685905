import React from 'react';
import $ from "jquery";

const DocumentModal = ({ evidenceList,setUrlShowPdf }) => {

    /**
     * 
     * @param {*} url 
     */
    const validateTypeExt = (url) =>{
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        return ext
    }
    
    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        let ext = validateTypeExt(url)
        if (ext === 'pdf') {
            setUrlShowPdf(url);
            $('#closeModalViewPdf').show()
        }else{
            window.open(url)
        }
    }

    return (
        <div
            className="modal"
            id="evidenceList"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-12 mb-2'>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => {
                                        $('#evidenceList').hide();
                                    }}
                                >&times;</button>
                            </div>
                            <div className='col-12 mb-2'>
                                <h2 className='text-base-guay text-center'>Descarga tus evidencias anteriores</h2>
                            </div>
                            {
                                evidenceList.map(data => (
                                    <div className='col-12 mb-2' key={data.idEvidenceDocument}>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-6 col-lg-6 text-center'>
                                                <span>{data.dateLaunch}</span>
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-6 text-center'>
                                                <i
                                                    className={validateTypeExt(data.urlFile) === 'zip' || validateTypeExt(data.urlFile) === 'rar' ? 'text-base-guay ft ft-download pointer': 'text-base-guay bi bi-eye'}
                                                    onClick={() => {
                                                        previewFilePDF(data.urlFile);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentModal