import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { useState, useEffect } from 'react';
import { getLaunchCat, getMonitoring,getBranchCat,downReport } from './Model';
import toastr from 'toastr';
import { getJwt } from "../../../lib/auth";
import { LoaderView } from '../../../lib/utils/Loader';

import FailedModalMailGun from './FailedModalMailGun';
import { ResendModal } from './ResendModal';
import ModalLoaderGen from '../ModalLoaderGen';
import $ from "jquery";
import ModalViewPdf from '../enterprises/ModalViewPdf';
import { ThreeCircles } from 'react-loader-spinner'

const MonitoreoMail = ({justReadSteps}) =>{
    const [LaunchCat,setLaunchCat]=useState([]);
    const [MailGunData, setMailGunData] = useState([]);
    const [DataGeneral, setDataGeneral] =useState({
        deliveredTotal:0,
        sendItems:0,
        totalItems:0,
        acceptedTotal:0,
        failedTotal:0,
        openedtotal:0,
        progress:0,
        totalOpens:0,
        progressOpens:0,
    });

    const [Monitoring]=useState({
        enterpriseId: getJwt('enterprise'),
        launch:0,
        branch:0      
    });
    const [ObjFailed,setObjFailed]=useState([]);
    const [dateFailed,setDateFailed]=useState();   
    const [Branch,setBranch]=useState([]);
    const [showTable,setShowTable]=useState(false);
    const [ObjFailedResend,setObjFailedResend]=useState([]);
    const [idGroup, setIdGroup] = useState([0]);
    const [urlShowPdf, setUrlShowPdf] = useState("")
    const [downloadData, setDownloadData] = useState(false);
    const [disableBranches, setDisableBranches] = useState(false);
    const [disableLaunch, setDisableLaunch] = useState(false);

    useEffect(()=>{    
        setShowTable(true);           
        getLaunchCat(getJwt('enterprise')).then((resp)=>{
            if(resp.data){
                setLaunchCat(resp.data);
                     
            }else{
                toastr.error('Hubo un problema. No se encontraron ejercicios.', '¡Ooops!');
            }
            
        });   
        setShowTable(false);           
       
    },[]);   
    const rowStyleFormat = () => {
        return { backgroundColor: '#fff' }
    }

    const tdStyleFormat = () => {
        return { padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word', height: '50px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1' }
    }

    const thStyleFormat = () => {
        return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '0px', paddingRight: '0px' }
    }
    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        noDataText: 'No se encontraron registros.',
    } 
    const ChangeDatas = e => {  
        setBranch(0);
        Monitoring.branch=0;
        Monitoring.launch=e.target.value;
            getBranchCat(parseInt(e.target.value)).then((resp)=>{
            if(resp.data){
                setBranch(resp.data);              
                $("#branch option[value=0]").text("Todos");                
                cambios();
                     
            }else{
                toastr.error('Hubo un problema. No se encontraron ejercicios.', '¡Ooops!');
                $("#branch option[value=0]").text("Seleccione");
            }
        });
        
    }
    const ChangeBranch = e => {  
        Monitoring.branch=e.target.value;            
        cambios();
    }
    

    function cambios() {
        setShowTable(true);
        setDisableBranches(true);
        setDisableLaunch(true);
        getMonitoring(Monitoring).then((result) => {
            if(result.success){
                setMailGunData(result.data);   
                setDataGeneral({
                    deliveredTotal:result.deliveredTotal,
                    sendItems:result.sendItems,
                    totalItems:result.totalItems,
                    acceptedTotal:result.acceptedTotal,
                    failedTotal:result.failedTotal,
                    openedtotal:result.openedtotal,
                    progress:result.progress,
                    totalOpens:result.totalOpens,
                    progressOpens:result.ProgressOpens,
                }) 
                setIdGroup(result.idGroup)
                toastr.success("Se actualizó correctamente la información.", "¡Éxito!");             
                
            }else{                
                setMailGunData([]);
                setDataGeneral({
                    deliveredTotal:0,
                    sendItems:0,
                    totalItems:0,
                    acceptedTotal:0,
                    failedTotal:0,
                    openedtotal:0,
                    progress:0,
                    totalOpens:0,
                    progressOpens:0,
                    
                });
                setIdGroup(0)
                toastr.error("Por el momento no hay información para el lanzamiento seleccionado.", "Espera!");  
                        
            }
            setShowTable(false);
            setDisableBranches(false);
            setDisableLaunch(false);
        });
    }
    const barOptions = (percentage) => {
        let width = 0;
        let backgroundColor = "#efeff6";
        if (percentage <= 30) {
            //yellow
            width = `${percentage}%`;
            backgroundColor = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            width = `${percentage}%`;
            backgroundColor = "#2188b4";
        } else if (percentage > 70) {
            //green
            width = `${percentage}%`;
            backgroundColor = "#9cb537";
        } else {
            //other
            width = "50%";
            backgroundColor = "#71758d";
        }
        return {
            width,
            backgroundColor
        }
    };

    const textOptions = (percentage) => {        
        let color = "#000";
        if (percentage <= 30) {
            //yellow
            color = "#dfa444";
        } else if (percentage > 30 && percentage <= 70) {
            //blue
            color = "#2188b4";
        } else if (percentage > 70) {
            //green
            color = "#9cb537";
        } else {
            //other
            color = "#71758d";
        }
        return {
            color
        }
    };
    
    const nameCollabFormat = (cell, row, index) => {
         if(row.objectFalled){
             var link= "text-base-blue pointer";
             var fallido= 1;
         }
        return (
           
            <span
                className={link}
                data-toggle="tooltip"
                title={cell}
                onClick={() => FailedModal(row,fallido)} >{row.falled?row.falled:0}</span>
            
        )
    }

    const noForwards = (cell, row, index) => {
       return (
           <span
               className="text-base-blue"
               data-toggle="tooltip"
               title={cell}
            >{cell}</span>
           
       )
   }

    const resendIcon = (cell, row, index) => {
       return (
          
           <span
               className="text-base-blue pointer"
               data-toggle="tooltip"
               title={cell}
               onClick={() => confirmResend(row)} >
                <i className="bi bi-arrow-counterclockwise font-weight-bold align-middle icon-medium-size" style={{color: "rgb(0, 111, 141)"}}></i>
            </span>
           
       )
   }

   const confirmResend = (data) => {
    setObjFailedResend(data)
    $('#resendModal').show();
   }

    const FailedModal = (data,failed) => {
        if(failed===1){
            $('#modalFailedMailGun').show();
            setObjFailed(data.objectFalled);
            setDateFailed(data.date);
        }
        
    }
    const styles = {
        border: '5px dashed rgb(133,163,47)',
        height:'100%',
        position: 'relative',      
    };

    const porcentFormat = (cell, row, index) => {
        return (
            <span               
                title={cell}
                 >{row.ProgresOpen} %</span>
            
        )
    }

    /**
     * 
     * @param {*} url 
     */
     const previewFilePDF = (url) => {
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        // console.log('ext: ', ext);
        if (ext === 'pdf') {
            setUrlShowPdf(url);
            $('#closeModalViewPdf').show()
        }else{
            window.open(url);
        }
    }

    const downloadReport = () => {
        $('#downloadPDF').text('Cargando reporte');
        // $("#modalLoaderGen").show();
        setDownloadData(true)
        downReport(Monitoring).then((response) => {
            // console.log(response)
            switch (response.code) {
                case 1:
                    // window.open(response.urlDoc);
                    if(response.urlDoc && response.urlDoc !== ''){
                        previewFilePDF(response.urlDoc)
                    }else{
                        toastr.info("No se encontro el archivo", "¡Ooops!");
                    }
                    $('#downloadPDF').text('');
                    $('#downloadPDF').text('Descargar Reporte PDF');
                    // $("#modalLoaderGen").hide();
                    setDownloadData(false)
                    break;               
                default:
                    toastr.error("Lo sentimos no se pudo generar el reporte", "¡Ooops!");
                    $('#downloadPDF').text('Descargar Reporte PDF');
                    // $("#modalLoaderGen").hide();
                    setDownloadData(false)
                    break;
            }
        });
    }
    return(
        <div className="col-12 bg-white rounded mb-5 ">
                <section className="mr-1 ml-1 mt-1">
                            <div className="card-header">
                                <h4 className="card-title text-center">Monitoreo de correos</h4>                                
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className='row '>
                                        <div className="col-md-1  d-flex flex-wrap " >                         
                                            <label className="text-gray  align-content-center"> Ejercicios:</label>
                                                                
                                        </div>
                                        <div className="col-md-2">
                                        <select
                                                className="form-control form-control-sm"
                                                id="launch"
                                                name="launch"
                                                disabled={disableLaunch}
                                                onChange={ChangeDatas}>
                                                <option value="0" key="0">Seleccione</option>
                                                {
                                                   LaunchCat.length>0?
                                                   LaunchCat.map(data=>(
                                                       <option key={parseInt(data.id)} value={parseInt(data.id)}>{data.description} </option>
                                                   )) : ""
                                                }
                                                                                
                                            </select>  
                                        </div>   
                                        <div className="col-md-2  d-flex flex-wrap align-content-center " >                         
                                            <label className="text-gray text-right"> Centros de trabajo:</label>
                                                                
                                        </div>
                                        <div className="col-md-2">
                                        <select                                                
                                                className="form-control form-control-sm"
                                                id="branch"
                                                name="branch"
                                                disabled={disableBranches}
                                                onChange={ChangeBranch}>
                                                <option value="0" key="0">Seleccione</option>
                                                {                                                    
                                                   Branch.length>0?
                                                   Branch.map(data=>(
                                                       <option key={parseInt(data.idBranch)} value={parseInt(data.idBranch)}>{data.branch} </option>
                                                   )) : ""
                                                }
                                                                           
                                            </select>  
                                        </div>   
                                        {
                                            MailGunData.length>0 ?  
                                            <div className='col-md-3 d-flex justify-content-center'>
                                                <div className='row'>
                                                {!downloadData && <i className='bi bi-eye text-blue font-weight-bold align-middle' />}
                                                <span
                                                    id='downloadPDF'
                                                    className="text-base fake-select pointer"
                                                    onClick={() => downloadReport() }>
                                                      Descargar Reporte PDF
                                                </span>
                                                
                                                {
                                                    downloadData && (
                                                        <div className='pl-1 '>
                                                            <ThreeCircles
                                                                height="20"
                                                                width="20"
                                                                visible={true}
                                                                ariaLabel="three-circles-rotating"
                                                                outerCircleColor="#2188b4"
                                                                innerCircleColor="#9cb537"
                                                                middleCircleColor="#af2f72"
                                                            />
                                                        </div>
                                                    )
                                                }
                                                </div>
                                            </div> 
                                            
                                              
                                            :""                                         
                                        }
                                         {
                                            MailGunData.length>0 ?  
                                            <div className='col-md-2'>
                                                <button type="button" onClick={cambios} className="btn btn-icon btn-outline-info" style={{marginTop: "-11%"}}><i className="ft-refresh-ccw"></i></button>
                                            </div> 
                                            
                                              
                                            :""                                         
                                        }
                                                              
                                        
                        
                                    </div>
                                    
                                    
                                    <div className="row mt-5">
                                        <div className="col-xl-12 col-lg-12 col-12">
                                            {showTable ?
                                                <LoaderView />
                                                : ""}
                                        </div>
                                    </div>
                                   
                                    <div>
                                        {showTable===false && (
                                            <div>
                                                <div className="row">                                    
                                                    <div className="col-xl-12 col-lg-12 col-12 ">
                                                        <h2 className="text-center">Resumen general</h2>
                                                    </div>                                            
                                                </div>
                                                <div className='row mt-1 mb-2'>
                                                    <div className="col-xl-4 col-lg-4 col-12">
                                                        <h4 className='text-center'> Número de comunicados: {DataGeneral.sendItems} de {DataGeneral.totalItems}</h4>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-12">
                                                        <div className="progress">
                                                            <div className="progress-bar" style={barOptions(DataGeneral.progress)}></div>                                                        
                                                        </div>                                                    
                                                    </div>
                                                    <div className="col-xl-2 col-lg-2 col-12">
                                                        <h4 style={textOptions(DataGeneral.progress)}> {DataGeneral.progress} % Completado </h4>
                                                    </div>
                                                </div>

                                                <div className="row mt-1 mb-2">                                    
                                                    <div className="col-xl-5 col-lg-5 col-12 mt-12">
                                                        <div className="row">
                                                            <div className="col-xl-2 col-lg-2"></div>
                                                            <div className="col-xl-6 col-lg-6 col-6">
                                                                <div className="card bg-success">
                                                                    <div className="card-content">
                                                                        <div className="card-body">
                                                                            <div className="media d-flex">
                                                                                <div className="media-body text-center">                                                                    
                                                                                    <h3 className="text-white">Correos enviados </h3>
                                                                                    <label className="text-white" style={{fontSize:'2rem'}}><i className="bi bi-box-arrow-up-right"></i> {DataGeneral.deliveredTotal}</label>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-4" style={{position: 'absolute',left: '65%', zIndex: '1', top:'8%'}}>
                                                                <div className="badge block " style={{backgroundColor:'#9bb537a8'}}>
                                                                    <h3 className="text-white" > Recibidos: {DataGeneral.acceptedTotal}</h3>
                                                                </div>
                                                                <div className="badge block  mt-1" style={{backgroundColor:'#9bb537a8'}}>
                                                                    <h3 className="text-white" > Fallidos: {DataGeneral.failedTotal} </h3>
                                                                </div>
                                                            </div>        
                                                        </div>                                           
                                                    </div>
                                                    <div className="col-xl-7 col-lg-7 col-12" >
                                                        <div className="row mr-1" style={styles} >
                                                            <div className="col-xl-2 col-lg-2 col-2 ml-2" style={{position: 'absolute', zIndex: '2'}}>
                                                                <div className="text-center mt-3" >
                                                                    <img alt="flecha" src='/img/MonitoreoMailGun/flechas.png' ></img>
                                                                </div>                                                    
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-6 " style={{position: 'absolute',left: '20%', zIndex: '2'}}>
                                                                <div className="card mt-1" style={{backgroundColor:'#006F8D'}}>
                                                                    <div className="card-content">
                                                                        <div className="card-body">
                                                                            <div className="media d-flex">
                                                                                <div className="media-body text-center">                                                                    
                                                                                    <h3 className="text-white">Correos abiertos </h3>
                                                                                    <label className="text-white" style={{fontSize:'2rem'}}><i className="bi bi-box-arrow-up-right"></i> {DataGeneral.totalOpens}</label>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-6" style={{position: 'absolute',left: '55%', zIndex: '1'}}>
                                                                <div className="card mt-1" style={{backgroundColor:'#3D91A8'}}>
                                                                    <div className="card-content">
                                                                        <div className="card-body">
                                                                            <div className="media d-flex">
                                                                                <div className="media-body text-center">                                                                    
                                                                                    <h3 className="text-white">Correspondientes al </h3>
                                                                                    <label className="text-white" style={{fontSize:'2rem'}}><i className="bi bi-box-arrow-up-right"></i> {DataGeneral.progressOpens} %</label>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                
                                                            </div>
                                                        </div>                                                                                        
                                                    </div>
                                                </div>

                                            <section className="card mr-1 ml-1 bootomFooter">                            
                                                <div className="card-content collapse show">
                                                    <div className="card-body">
                                                    <BootstrapTable
                                                        data={MailGunData}
                                                        striped
                                                        hover
                                                        condensed
                                                        pagination={true}
                                                        tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                                                        trClassName='tr-data-table'
                                                        options={options}
                                                        trStyle={rowStyleFormat}
                                                    >
                                                        <TableHeaderColumn dataField="collabtotal" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} > #Colaboradores</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="totalRegister" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >#Entregados</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="subject" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()}  >Asunto</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="lider" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()}  >Tipo</TableHeaderColumn>
                                                        <TableHeaderColumn isKey dataField="date" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Fecha Envio</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="status" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Estatus</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="date" dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()}  >Recibido</TableHeaderColumn>                                    
                                                        <TableHeaderColumn dataField="opens"  dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >#Leidos</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="porcent" dataFormat={porcentFormat} dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >%Leidos</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="moredetail"  dataFormat={nameCollabFormat} dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Fallidos</TableHeaderColumn>
                                                        {
                                                            !justReadSteps.step_3 ?
                                                            (
                                                                <TableHeaderColumn dataField="resend"  dataFormat={resendIcon} dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >Reenviar</TableHeaderColumn>
                                                            ): null
                                                        }
                                                        <TableHeaderColumn dataField="forwards"  dataFormat={noForwards} dataAlign="center" tdStyle={tdStyleFormat()} thStyle={thStyleFormat()} >#EnvÍos</TableHeaderColumn>
                                                    </BootstrapTable>
                                                    </div>
                                                </div>
                                            </section>
                                                <FailedModalMailGun
                                                    ObjFailed={ObjFailed}
                                                    dateFailed={dateFailed}
                                                />
                                                <ResendModal
                                                    data={ObjFailedResend}
                                                    branch={Monitoring.branch}
                                                    idEnterprise={getJwt('enterprise')}
                                                    idUser={getJwt('jtw')}
                                                    idGroup={idGroup}
                                                    setShowTable={setShowTable}
                                                    setMailGunData={setMailGunData}
                                                    setDataGeneral={setDataGeneral}
                                                    setIdGroup={setIdGroup}
                                                    monitoring={Monitoring}
                                                />
                                                <ModalLoaderGen />
                                                <ModalViewPdf urlShowPdf={urlShowPdf} setUrlShowPdf={setUrlShowPdf}/>
                                            </div>
                                        ) }                            
                                        
                                        
                                    </div>            
                               
                                </div>
                            </div>
                        </section>
                                              
                        
        </div>
        
    );
        
}
export default MonitoreoMail;
