import React from 'react'

const MartiTC = () => {
    return (
        <div className='container-fluid bg-transparent-2'>
            <div className='col-sm-12 col-md-10 col-lg-6 mx-auto'>
                <div className='card mt-3'>
                    <div className='card-body'>
                        <p className='font-weight-bold'>Martí</p>

                        <p className='text-justify'>
                            Los presentes Términos y Condiciones aplicarán para hacer válida la promoción contenida en el presente descuento. Aunado a esto, el Beneficiario deberá presentar una identificación oficial y el cupón descuento al momento de pagar en Tiendas Martí.
                        </p>

                        <p className='text-justify'>
                            La promoción de los cupones de descuento de Tiendas Martí y es intransferible e improrrogable y no podrá ser cambiada, canjeable o reembolsable por dinero en efectivo, ni por alguna modalidad de redención, ni en especie, ni por algún otro servicio.
                        </p>

                        <p className='text-justify'>
                            El descuento no será acumulable con otras promociones, ni descuentos expresamente no autorizados por el proveedor.
                        </p>

                        <p className='text-justify'>
                            Cualquier duda relacionada con ubicaciones, productos, términos y condiciones favor de ingresar a <a className='text-decoration-none text-black font-weight-bold' href='https://www.marti.mx/' target='_blank' rel="noreferrer">www.marti.mx</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MartiTC