import React, { useState, useEffect } from 'react';
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import Header from '../NewHome/headerAdministrador';
import Menu from "../Menu";
import Footer from '../footerApp';
import AdminLaunches from './AdminLaunches';
/* import DateDiff from './DateDiff'; */
import { getActiveProductsByEnterprise } from '../Models/Enterprise_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import $ from 'jquery';
import BannerAdmin from '../BannerAdmin';
import { validateAccess, getSubsection, getSubsectionIn } from '../../helpers/validations';
import { connect } from 'react-redux';
import HelperApp from '../HelperApp';

const AdminServices = ({ access }) => {

    const [steps, setSteps] = useState(0);
    const history = useHistory();

    let prodData;
    const [prodData_2, setprodData_2] = useState({});

    let hulk = sessionStorage.getItem("hulk");
    if (hulk !== null) {
        hulk = hulk.slice(22, -22);
        prodData = JSON.parse(atob(hulk));
        /* sessionStorage.removeItem("hulk"); */
    }

    const [disableActions, setDisableActions] = useState(false)

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 5) {
            //console.log(access)
            //console.log(validateAccess(access.access.list, 2))
            const section = validateAccess(access.access.list, 2)
            //console.log(getSubsection(section[0].subSection, 14))
            const subSection = getSubsection(section[0].subSection, 14)
            //console.log(getSubsectionIn(subSection[0].subSectionIn, 32))
            const lanzamiento = getSubsectionIn(subSection[0].subSectionIn, 32)
            if (section[0].id_action === 0) {
                history.push("/inicio-colaborador")
            } else {
                if (lanzamiento[0].id_action === 0) {
                    toastr.info('No puedes ingrear a esta sección.', '¡Ooops!')
                    history.push("/comunicacion")
                } else {
                    if (lanzamiento[0].id_action === 2) {
                        setDisableActions(true)
                    }
                }
            }
        }
        getActiveProductsByEnterprise(getJwt("enterprise")).then((response) => {
            if (response) {
                if (response.code === 1) {
                    let acquiredProducts = [];
                    for (let x in response.listProducts) {
                        if (response.listProducts[x].acquired === 1) {
                            acquiredProducts.push(response.listProducts[x]);
                        }
                    }
                    setprodData_2(acquiredProducts[0]);
                }
            } else {
                toastr.error("Problemas al cargar los productos. Intente nuevamente.");
            }
        });
    }, [access.access.list, history])

    useEffect(() => {
        const step = sessionStorage.getItem("step");
        if (step) {
            setSteps(2);
            sessionStorage.removeItem("step");
        }
        return () => {
            sessionStorage.removeItem("step");
        }
    }, []);

    const handleClickLanzamiento = () => {
        setSteps(0);
    }

    const handleAdmin = () => {
        if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
            Swal.fire({
                title: '<p class="text-white mb-2">¡Aún no has terminado!</p>',
                text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
                showCloseButton: true,
                showCancelButton: true,
                focusCancel: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: "Si, salir",
                reverseButtons: true,
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    sessionStorage.removeItem("inLaunch");
                    sessionStorage.removeItem("isEdition");
                    sessionStorage.removeItem("idProduct");
                    sessionStorage.removeItem("idCategory");
                    sessionStorage.removeItem("idSubcategory");
                    sessionStorage.removeItem("nameCategory");
                    sessionStorage.removeItem("nameSubcategory");
                    sessionStorage.removeItem("idTypeLaunch");
                    sessionStorage.removeItem("descriptionLaunch");
                    sessionStorage.removeItem("flagLaunch");
                    sessionStorage.removeItem("launchDate");
                    sessionStorage.removeItem("idGroup");
                    sessionStorage.removeItem("numCollabSelected");
                    history.push("/inicio-administrador");
                }
            });
            $(".swal2-title").addClass("bg-header-swal");
            $(".swal2-actions").addClass("w-100 justify-content-around");
            $(".swal2-confirm").removeClass("swal2-styled");
            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
            $(".swal2-cancel").removeClass("swal2-styled");
            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        } else {
            history.push("/inicio-administrador");
        }
    }

    const handleCom = (id) => {
        if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
            Swal.fire({
                title: '<p class="text-white mb-2">¡Aún no has terminado!</p>',
                text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
                showCloseButton: true,
                showCancelButton: true,
                focusCancel: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: "Si, salir",
                reverseButtons: true,
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    sessionStorage.removeItem("inLaunch");
                    sessionStorage.removeItem("isEdition");
                    sessionStorage.removeItem("idProduct");
                    sessionStorage.removeItem("idCategory");
                    sessionStorage.removeItem("idSubcategory");
                    sessionStorage.removeItem("nameCategory");
                    sessionStorage.removeItem("nameSubcategory");
                    sessionStorage.removeItem("idTypeLaunch");
                    sessionStorage.removeItem("descriptionLaunch");
                    sessionStorage.removeItem("flagLaunch");
                    sessionStorage.removeItem("launchDate");
                    sessionStorage.removeItem("idGroup");
                    sessionStorage.removeItem("numCollabSelected");
                    if (id === 1) {
                        history.push("/comunicacion");
                    } else {
                        history.push("/asignar-colaboradores-lanzamiento");
                    }
                }
            });
            $(".swal2-title").addClass("bg-header-swal");
            $(".swal2-actions").addClass("w-100 justify-content-around");
            $(".swal2-confirm").removeClass("swal2-styled");
            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
            $(".swal2-cancel").removeClass("swal2-styled");
            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        } else {
            if (id === 1) {
                history.push("/comunicacion");
            } else {
                history.push("/asignar-colaboradores-lanzamiento");
            }
        }
    }

    // const handleTokenForwarding = () => {
    //     if (sessionStorage.getItem("inLaunch") && sessionStorage.getItem("inLaunch") === "1") {
    //         Swal.fire({
    //             title: '<p class="text-white mb-2">¡Aún no has terminado!</p>',
    //             text: "Aún no has terminado de configurar tu lanzamiento ¿estás seguro de querer salir?",
    //             showCloseButton: true,
    //             showCancelButton: true,
    //             focusCancel: true,
    //             cancelButtonText: 'Cancelar',
    //             showConfirmButton: true,
    //             confirmButtonText: "Si, salir",
    //             reverseButtons: true,
    //             dangerMode: true
    //         }).then((willDelete) => {
    //             if (willDelete.isConfirmed) {
    //                 sessionStorage.removeItem("inLaunch");
    //                 sessionStorage.removeItem("isEdition");
    //                 sessionStorage.removeItem("idProduct");
    //                 sessionStorage.removeItem("idCategory");
    //                 sessionStorage.removeItem("idSubcategory");
    //                 sessionStorage.removeItem("nameCategory");
    //                 sessionStorage.removeItem("nameSubcategory");
    //                 sessionStorage.removeItem("idTypeLaunch");
    //                 sessionStorage.removeItem("descriptionLaunch");
    //                 sessionStorage.removeItem("flagLaunch");
    //                 sessionStorage.removeItem("launchDate");
    //                 sessionStorage.removeItem("idGroup");
    //                 sessionStorage.removeItem("numCollabSelected");
    //                 history.push("/reenvio-fichas");
    //             }
    //         });
    //         $(".swal2-title").addClass("bg-header-swal");
    //         $(".swal2-actions").addClass("w-100 justify-content-around");
    //         $(".swal2-confirm").removeClass("swal2-styled");
    //         $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
    //         $(".swal2-cancel").removeClass("swal2-styled");
    //         $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    //     } else {
    //         history.push("/reenvio-fichas");
    //     }
    // }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-1 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li
                                                    className="breadcrumb-item font-medium-3 pointer"
                                                    onClick={() => handleAdmin()}
                                                >Administración</li>
                                                <li className="breadcrumb-item active font-medium-3">{steps === 0 ? "Configura / Lanzamientos" : steps === 1 ? "Configura / Comunicados" : steps === 2 ? "Configura / Difusión Cumplimiento" : null}
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto mt-2">
                                <div className="row">
                                    <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2">
                                    </div>
                                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10">
                                        <h2><strong>Configura tus servicios</strong></h2>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-2 p-0 mt-2">
                                        <div className="card">
                                            <ul className="list-group enterprise-list">
                                                <li className="list-group-item active pointer" data-step="1" onClick={handleClickLanzamiento}>
                                                    <span className="ml-1 font-medium-2">Lanzamientos</span>
                                                </li>

                                                <li className="list-group-item pointer" data-step="2" id="comunicados">
                                                    <span
                                                        className="ml-1 font-medium-2"
                                                        onClick={() => handleCom(1)}
                                                    >Comunicados</span>
                                                </li>
                                                {/* <li className="list-group-item pointer" data-step="3" id="reenvioFichas">
                                                    <span
                                                        className="ml-1 font-medium-2"
                                                        onClick={() => handleTokenForwarding()}
                                                    >Reenvío de Fichas</span>
                                                </li> */}

                                            </ul>
                                        </div>
                                        {getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? (null) : (<BannerAdmin />)}
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-10 mt-2">
                                        <AdminLaunches
                                            prodData={prodData ? (prodData) : (prodData_2)}
                                            disableActions={disableActions}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (access) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(AdminServices)
