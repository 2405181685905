import React, { useState, useEffect } from 'react'
import SeccionOlvidar from './SeccionOlvidar'

const PlanPrimordial = () => {
    const [urlImg, setUrlImg] = useState('')
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            // platform = "Mobile";
            setUrlImg("./../img/beneficios/BANNER_plan_dental_primordial_mobile.png")
        } else {
            setUrlImg("./../img/beneficios/BANNER_plan_dental_primordial.png")
        }
      
    }, [])
    
  return (
    <div className='container-fluid bg-transparent-2'>
        <div className='row shadow-card-2'>
            <img className="img-responsive" src={urlImg} alt="img-banner" />
            
            {/* <div className='col-12'>
                <div className='row'>
                    <div className='col-4 col-md-3 d-flex align-items-center justify-content-center'>
                        <div>
                            <img
                                src="./../img/beneficios/imagen_dental_plus_2.png"
                                className='img-responsive'
                                alt="im1-1"
                            />
                        </div>
                    </div>
                    <div className='col-8 col-md-9'>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-12 border-bottom-white'>
                                    <div className='d-flex align-items-center justify-content-center py-1 mt-2'>
                                        <h1 className='text-white fs-1-125'>Plan Primordial</h1>
                                            <img
                                                src="./../img/beneficios/icono_dental_primordial.png" 
                                                alt="icono-dental"
                                                className='icon-size-34x34 mx-1'
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mt-2'>
                                    <p className='text-white fs-1 font-weight-normal'>Cuentas con</p>
                                    <ul className='text-white fs-1 font-weight-bold'>
                                        <li className='text-white py-1'><span className='text-yellow-mine'>1</span> <span className='font-weight-light'>evento al año  de</span> urgencia dental*</li>
                                        <li className='text-white py-1'><span className='text-yellow-mine'>1</span> Limpieza dental <span className='font-weight-light'>con ultrasonido</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        <div className='row'>
            <div className='ml-2 col-12 d-flex align-items-center mt-2 mt-md-2'>
                <h4 className='font-weight-bold text-blue-sec fs-1'>Procedimientos incluidos:</h4>
            </div>
        </div>

        
        <div className='row justify-content-center flex-wrap align-content-center'>
            <div className='mb-1 col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_1.png" alt="img-dentista-1" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875'>Consulta de emergencia</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Historia Clínica de urgencia</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Radiografía periapical en caso de emergencia <span className='font-weight-light'>(hasta</span> 4 <span className='font-weight-light'>radiografías por evento)</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Eliminación de caries</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Extracción sencilla de diente o raíz</p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Recementado de prótesis fijas</p>
                </div>
            </div>
            <div className='col-2 col-md-1 col-lg-1 d-flex align-items-end flex-wrap my-2'>
                <img className="img-responsive" src="./../img/beneficios/Icono_dentista_2.png" alt="img-dentista-2" />
            </div>
            <div className='mb-1 col-9 col-md-3 col-lg-3 d-flex align-items-center flex-wrap my-2'>
                <div className='card p-1 h-100 d-flex align-items-start justify-content-center'>
                    <p className='font-weight-normal text-blue-sec fs-875'>Recubrimiento pulpar directo e indirecto <span className='font-weight-light'>(no incluye restauración final)</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Eliminación de cámara pulpar en dientes primarios <span className='font-weight-light'>(pulpotomía y pulpectomía, ninguna incluye restauración final)</span></p>
                    <p className='font-weight-normal text-blue-sec fs-875'>Corona provisional directa en acrílico autopolimerizable en dientes anteriores</p>
                </div>
            </div> 
            <div className='mb-1 col-11 col-md-4 col-lg-4 d-flex align-items-end flex-wrap justify-content-center text-white my-2'>
                {/* <button className="btn btn-sm btn-save-style2 fs-1 font-weight-bold rounded-pill mb-1">Ver detalle</button> */}
            </div>  
        </div>
        <div className='row'>
            <div className='col-6 col-md-5 col-lg-5 d-flex'></div>
            <div className='col-12 col-md-7 col-lg-7 d-flex align-items-center justify-content-center mt-1 mt-md-1'>
                <p className='font-weight-light fs-75 text-blue-sec'><span className='font-weight-normal'>*Urgencia dental</span> situación inesperada causada por enfermedad o accidente que amerita atención odontológica paliativa urgente derivada de las siguientes manifestaciones: dolor intenso, inflamación, hemorragia, fractura dental.</p>
            </div>
            <div className='col-6 col-md-5 col-lg-5 d-flex'></div>
            <div className='col-12 col-md-7 col-lg-7 d-flex align-items-center justify-content-center mt-1 mt-md-1'>
                <p className='font-weight-light fs-75 text-blue-sec'>* No se cubren retratamientos de procedimientos realizados por profesionales que no sean parte de la red o previos al periodo cubierto.</p>
            </div>
        </div>
        <SeccionOlvidar/>
    </div> 
  )
}

export default PlanPrimordial
