import React, { useState, useEffect } from 'react'
import { setSupportUser } from './Models/AssistanceModel'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'

const SupportPage = () => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        return () => {
            setName("");
            setPhone("");
            setEmail("");
            setMessage("");
            setUpdate(0);
        }
    }, [update])


    const handleSubmit = (e) => {
        e.preventDefault();
        let error = 0;
        if (name === "") {
            error = error + 1;
            toastr.info("El nombre es requerido");
        }
        if (message === "") {
            error = error + 1;
            toastr.info("El mensaje es requerido");
        }
        if (phone === "" && email === "") {
            error = error + 1;
            toastr.info("El teléfono o el correo es requerido");
        }
        if (error === 0) {
            const data = {
                contact: name,
                email: email,
                phone: phone,
                message: message,
            }
            setSupportUser(data).then((response) => {
                switch (response?.code) {
                    case 1:
                        toastr.success("Se envió su información, espere por una pronta respuesta.");
                        setUpdate(1);
                        break;
                    case 99:
                        toastr.error("Error con su envió, vuelva a intentarlo.");
                        break;
                    case 200:
                        toastr.success("Se envió su información, espere por una pronta respuesta.");
                        setUpdate(1);
                        break;
                    case 1001:
                        toastr.error("Datos vacíos");
                        break;
                    default:
                        toastr.error("Ocurrió un error");
                        break;
                }
            })
        }
    }

    return (
        <div>
            <header className="site-header site-header__header-one with-box-shadow">
                <nav className="navbar navbar-light bg-white">
                    <a href="https://somosguay.com"><img src="./img/logo_guay_color.png" height="50" className="d-inline-block align-top blurryImg" alt="logo_guay" /></a>
                </nav>
            </header>
            <nav className="navbar navbar-light bg-white">
                <a href="https://somosguay.com"><img src="./img/logo_guay_color.png" height="50" className="d-inline-block align-top blurryImg" alt="logo_guay" /></a>
            </nav>
            <div className="jumbotron bg-transparent p-1 m-1">
                <h1 className="display-4 text-center">Mejora la <span className="text-blue">salud</span>  de tu Organización <br /> y de tus Colaboradores.</h1>
                <p className="text-center mt-3 font-medium-2">Una plataforma que automatiza todas las actividades necesarias para lograr una mejor y constante <b>salud laboral</b></p>
            </div>
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-md-2">
                    <div className="col mb-2">
                        <div className="card h-100">
                            <h5 className="card-header mb-0 pb-0">ENVIANOS UN MENSAJE</h5>
                            <div className="card-body">
                                <form method="post" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="inputName">Nombre</label>
                                                <input type="text" className="form-control form-control-sm" id="inputName" name="name" minLength="5" maxLength="150" value={name} onChange={(e) => setName(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputPhone">Teléfono móvil</label>
                                                <input type="text" className="form-control form-control-sm" id="inputPhone" name="phone" minLength="10" maxLength="10" aria-describedby="phoneHelp" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                <small id="phoneHelp" className="form-text text-muted">No compartiremos estos datos con nadie más.</small>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputEmail">Correo electrónico</label>
                                                <input type="email" className="form-control form-control-sm" id="inputEmail" name="email" minLength="3" maxLength="150" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="textAreaMessage">Mensaje</label>
                                                <textarea className="form-control form-control-sm" id="textAreaMessage" name="message" maxLength="400" rows="5" aria-describedby="messageHelp" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                                                <small id="messageHelp" className="form-text text-muted">Comparte un poco mas de tus dudas, porque medio te gustaría que te contactáramos...</small>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-sm bg-blue-iam text-white">Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-2">
                        <div className="card h-100">
                            <h5 className="card-header mb-0 pb-0">CONTÁCTANOS</h5>
                            <div className="card-body">
                                <h5 className="card-title">Somos <a href="https://somosguay.com"><b className="text-green">g</b><b className="text-base-guay">u</b><b className="text-blue">a</b><b className="text-yellow">y</b></a></h5>
                                <p className="card-text py-2">No dudes en comunicarte con nosotros, estamos a tus órdenes para resolver dudas, solicitar y dar seguimiento a tus beneficios.</p>
                                <p className="card-text font-weight-bold text-base pb-2">Comunícate al: (55) 12490998</p>
                                <p className="card-text font-weight-bold text-base pb-2">Correo Electrónico: info@guay.digital</p>
                                <p className="card-text text-base pb-2">Dirección: Av. F.C. de Cuernavaca 214, Polanco, Miguel Hidalgo, CDMX</p>
                                <a className="py-2" href="https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf" target="_blank" rel="noreferrer">Aviso de privacidad</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="site-footer__bottom">
                <div className="container-fluid">
                    <div className="inner-container text-center">
                        <p className="site-footer__copy">&copy; copyright 2021 Derechos reservados de Me Siento guay S A P I de
                            CV

                        </p>

                    </div>
                </div>
            </footer>
        </div>
    )
}

export default SupportPage
