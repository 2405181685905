import React, { useState, useEffect } from 'react';
import $ from "jquery";
import Swal from "sweetalert2";
import Header from '../../NewHome/headerAdministrador';
import Menu from "../../Menu";
import Footer from '../../footerApp';
import { updateLogoEnte, relEntGroup, getDatagroupById } from "../Model";
import { redirectIfNotAuthenticated, getJwt, setJwt } from "../../../../lib/auth";
import { getuserData, setStatusEnterprise } from "../../NewHome/Model";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import LoaderApp from '../../LoaderApp';
import { connect } from 'react-redux';
import { validateAccess, getSubsection } from '../../../helpers/validations';
import { useHistory } from "react-router-dom";

const Enterprises = props => {

    const [data, updateData] = useState([]);
    const [companys, upCompany] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    //const [total, setTotal] = useState(0);
    //  const [enterprises, upEnterprises] = useState([]);   
    const [files, upFiles] = useStateWithCallbackLazy(0);
    const [enterprise] = useState({
        enterpriseId: ""
    });
    const [relGroup] = useState({
        action: 0,
        idGroupEnt: 0,
        idEnterprise: 0
    })
    const [enterpriseGroup, upEnterprisesGroup] = useState({});

    const history = useHistory();

    const [justRead, setJustRead] = useState(false)

    const [justReadSteps, setJustReadSteps] = useState({
        step_1: 0,
        step_2: 0,
        step_3: 0,
        step_4: 0,
        step_5: 0,
    })
    const {
        step_1,
        //step_2,
        step_3,
        step_4,
        step_5,
    } = justReadSteps
    const [groupId, setGroupId] = useState(0)

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && props.access.list.length > 0) {
            setJustRead(true)
            //console.log(props.access)
            //console.log(validateAccess(props.access.list, 4))
            const section = validateAccess(props.access.list, 4)
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            //console.log(getSubsection(section[0].subSection, 24))
            const group_profile = getSubsection(section[0].subSection, 24)
            //console.log(getSubsection(section[0].subSection, 25))
            const group_orgs = getSubsection(section[0].subSection, 25)
            //console.log(getSubsection(section[0].subSection, 26))
            const org_profile = getSubsection(section[0].subSection, 26)
            //console.log(getSubsection(section[0].subSection, 27))
            const org_roles = getSubsection(section[0].subSection, 27)
            //console.log(getSubsection(section[0].subSection, 28))
            const org_collab = getSubsection(section[0].subSection, 28)
            setJustReadSteps({
                step_1: group_profile[0].id_action,
                step_2: group_orgs[0].id_action,
                step_3: org_profile[0].id_action,
                step_4: org_roles[0].id_action,
                step_5: org_collab[0].id_action,
            })
        }
        if (getJwt('rol') === '3') {
            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
            history.push("/inicio-colaborador")
        }
    }, [props.access, history])

    /*Render Modal with Dual List*/
    const showDualList = () => {
        try {
            $("#modal-1").show();
        } catch (error) {
            console.log(error);
        }
    }
    /*Close Modal*/
    const closeModal = () => {
        try {
            $('#modal-1').hide();
        } catch (error) {
            console.log(error);
        }
    }

    const reloadEnterprises = () => {
        try {
            if (selected.length > 0) {
                let flag = 0
                selected.forEach(element => {
                    let obj = {
                        ...relGroup,
                        action: 1,
                        idEnterprise: element,
                    }
                    let formData = new FormData()
                    formData.append('request', JSON.stringify(obj))
                    relEntGroup(formData).then((response) => {
                        if (!response.code) {
                            toastr.error('Ocurrió un problema al agregar la organización al Grupo. Intente Nuevamente', '¡Espera!')
                        } else {
                            flag++
                            if (selected.length === flag) {
                                getuserData(getJwt("jtw")).then((result) => {
                                    updateData(result)
                                    let enterprises = []
                                    for (let x in result.enterprise) {
                                        if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === groupId) {
                                            enterprises.push(result.enterprise[x])
                                        }
                                        if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === 0) {
                                            enterprises.push(result.enterprise[x])
                                        }
                                    }
                                    upCompany(enterprises)
                                    toastr.success('Empresas agregadas correctamente.', '¡Éxito!')
                                })
                                closeModal()
                            }
                        }
                    })
                })
            } else {
                closeModal()
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onChange = (selected, selection) => {
        try {
            setSelected(selected)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        try {
            var getObject = props.match.params;
            let object = getObject.db.split("=");
            var id = object[1];
            relGroup.idGroupEnt = id;
            setGroupId(parseInt(id))
            getuserData(getJwt("jtw")).then((result) => {
                updateData({
                    //...data,
                    result,
                });
                let enterprises = []
                for (let x in result.enterprise) {
                    if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === parseInt(id)) {
                        enterprises.push(result.enterprise[x])
                    }
                    if((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === parseInt(0)){
                        enterprises.push(result.enterprise[x])
                    }
                }
                upCompany(enterprises);
                setShowLoader(false);
            });

            getDatagroupById(id).then((result) => {
                upEnterprisesGroup(result)
            });

        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, []);

    const setEnterprises = () => {
        let array = companys;
        let array_2 = [];
        for (let x in array) {
            if (array[x].enterpriseGroupId === 0) {
                array_2.push({ value: array[x].enterpriseId, label: array[x].name })
            }
        }

        return array_2;
    }

    const deleteEntGroup = (idEmpresa) => {
        try {

            Swal.fire({
                title: '<p class="text-white mb-2">¿Deseas eliminar esta organización del Grupo Organizacional?</p>',
                text: "NO se perderá la información, podrás consultarlo en el listado de organizaciones",
                showCloseButton: true,
                showCancelButton: true,
                focusCancel: true,
                cancelButtonText: 'NO',
                showConfirmButton: true,
                confirmButtonText: "SÍ",
                reverseButtons: true,
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    relGroup.idEnterprise = idEmpresa;
                    relGroup.action = 0;
                    let formData = new FormData();
                    formData.append("request", JSON.stringify(relGroup));
                    relEntGroup(formData).then((res) => {
                        if (res.code === 1) {
                            toastr.success("Se eliminó correctamente la organización.", "¡Exito!");
                            getuserData(getJwt("jtw")).then((result) => {
                                updateData({
                                    ...data,
                                    result,
                                });
                                let enterprises = []
                                for (let x in result.enterprise) {
                                    if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === groupId) {
                                        enterprises.push(result.enterprise[x])
                                    }
                                    if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === 0) {
                                        enterprises.push(result.enterprise[x])
                                    }
                                }
                                upCompany(enterprises);
                            });
                            setSelected([]);
                        } else {
                            toString.error("Ocurrió un problema al eliminar la organización del Grupo organizacional. Intente nuevamente", "¡Espera!");
                        }
                    });
                }
            });
            $(".swal2-title").addClass("bg-header-swal");
            $(".swal2-actions").addClass("w-100 justify-content-around");
            $(".swal2-confirm").removeClass("swal2-styled");
            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
            $(".swal2-cancel").removeClass("swal2-styled");
            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");

        } catch (error) {

        }
    }



    const activeInactiveEnterprise = (idEnterprise, action) => {
        try {
            if (action === 0) {
                Swal.fire({
                    title: '<p class="text-white mb-2">¿Deseas eliminar esta organización del Grupo Organizacional?</p>',
                    text: "NO se perderá la información",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'NO',
                    showConfirmButton: true,
                    confirmButtonText: "SÍ",
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        let setActive = {
                            idEnterprise: idEnterprise,
                            idAction: 0
                        }
                        let formData = new FormData();
                        formData.append("request", JSON.stringify(setActive));
                        setStatusEnterprise(formData).then((res) => {
                            if (res.code === 1) {
                                getuserData(getJwt("jtw")).then((result) => {
                                    updateData({
                                        ...data,
                                        result,
                                    });
                                    let enterprises = []
                                    for (let x in result.enterprise) {
                                        if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === groupId) {
                                            enterprises.push(result.enterprise[x])
                                        }
                                        if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === 0) {
                                            enterprises.push(result.enterprise[x])
                                        }
                                    }
                                    upCompany(enterprises);
                                });
                                toastr.success("Se eliminó correctamente la organización.", "¡Exito!");
                            }
                        });
                    }
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
                let setActive = {
                    idEnterprise: idEnterprise,
                    idAction: action
                }
                let formData = new FormData();
                formData.append("request", JSON.stringify(setActive));
                setStatusEnterprise(formData).then((res) => {
                    if (res.code === 1) {
                        getuserData(getJwt("jtw")).then((result) => {
                            updateData({
                                ...data,
                                result,
                            });
                            let enterprises = []
                            for (let x in result.enterprise) {
                                if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === groupId) {
                                    enterprises.push(result.enterprise[x])
                                }
                                if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === 0) {
                                    enterprises.push(result.enterprise[x])
                                }
                            }
                            upCompany(enterprises);
                        });
                        toastr.success("Se activo correctamente la organización.", "¡Exito!");
                    }
                });
            }

        } catch (error) {
            console.log(error);
        }
    }

    const onChangeHandler = (e) => {
        try {
            let $currentTarget = $(e.currentTarget);
            var empresa = $currentTarget.attr("data-id");
            enterprise.enterpriseId = empresa;
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#img-person[data-id="' + empresa + '"]').attr('src', e.target.result);
            };

            let file = e.target.files[0];
            reader.readAsDataURL(e.target.files[0]);
            let formData = new FormData();
            upFiles(
                files, () => {
                    formData.append("file", file)

                });
            formData.append("enterprise", JSON.stringify(enterprise));
            updateLogoEnte(formData).then((res) => {
                if (res.success) {
                    toastr.success("Se actualizó correctamente el logo de la organización.", "¡Exito!");
                    getuserData(getJwt("jtw")).then((result) => {
                        let enterprises = []
                        for (let x in result.enterprise) {
                            if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === groupId) {
                                enterprises.push(result.enterprise[x])
                            }
                            if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === 0) {
                                enterprises.push(result.enterprise[x])
                            }
                        }
                        upCompany(enterprises);

                    });
                } else {
                    toastr.error("Ocurrió un problema con la actualización. Intente nuevamente.", "¡Error!");
                }
            });

        } catch (error) {
            console.log(error);
        }
    }
    const renderFilePers = () => {
        $("#file").click();
    }
    /* const hashString = (string) => {
        try {
            //let r = Math.random().toString(36).substring(7);
            var enc = window.btoa(string);
            return enc;
        } catch (error) {
            console.log(error);
        }
    } */
    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }
    const goCollaborators = (enterpriseId, rol) => {
        try {
            for (let x in companys) {
                if (companys[x].enterpriseId === enterpriseId) {
                    let userData = {
                        id: data.result.token,
                        enterprise: enterpriseId,
                        rol: rol,
                        help: data.result.help,
                        // VZO 24/02/2021, help2: 0
                        tkc: data.result.tk,
                        email: data.result.correo,
                        session: data.result.session,
                        enterpriseName: companys[x].name,
                        enterpriseInitials: companys[x].initials,
                        userName: data.name
                    }
                    setJwt(userData);
                    sessionStorage.setItem('fromOrg', 1);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    if (redirectIfNotAuthenticated()) {

        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay"></div>
                <Header />
                <Menu />
                <div className="content-wrapper bootomFooter">
                    <div className="content-header row">
                        <div className="col-md-6"></div>
                        <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item font-medium-3"><a href="/inicio-administrador">Administración</a>
                                        </li>
                                        <li className="breadcrumb-item font-medium-3"><a href="/inicio-empresas">Organizaciones</a>
                                        </li>
                                        <li className="breadcrumb-item active font-medium-3">{enterpriseGroup.comercialName}
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        {
                            showLoader === true
                                ?
                                (
                                    <LoaderApp />
                                )
                                :
                                (
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-1 col-lg-1 mb-40"></div>
                                        <div className="col-sx-12 col-sm-12 col-md-10 col-lg-9 mb-40 ml-4 text-center">


                                            <div className="row mt-2">
                                                {
                                                    justRead ? (
                                                        step_1 === 1 && (
                                                            <div className="col-md-6 mt-1p text-center mb-4">
                                                                <span className="align-middle text-base font-medium-2">Agregar organización al Grupo Organizacional</span>
                                                                <img onClick={showDualList} className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" />
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="col-md-6 mt-1p text-center mb-4">
                                                            <span className="align-middle text-base font-medium-2">Agregar organización al Grupo Organizacional</span>
                                                            <img onClick={showDualList} className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    justRead ? (
                                                        step_1 === 1 && (
                                                            <div className="col-md-6 mt-1p text-center mb-4">
                                                                <span className="align-middle text-base font-medium-2">Registrar Organización</span>
                                                                <a href={"/registra-organizacion/" + hashString("guay.digital") + "="
                                                                    + enterpriseGroup.groupId + "=" + hashString(enterpriseGroup.comercialName)}>
                                                                    <img onClick="" className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" />
                                                                </a>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="col-md-6 mt-1p text-center mb-4">
                                                            <span className="align-middle text-base font-medium-2">Registrar Organización</span>
                                                            <a href={"/registra-organizacion/" + hashString("guay.digital") + "="
                                                                + enterpriseGroup.groupId + "=" + hashString(enterpriseGroup.comercialName)}>
                                                                <img onClick="" className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" />
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="row">
                                                {
                                                    companys.map((data) =>

                                                        <div className={data.enterpriseGroupId !== parseInt(relGroup.idGroupEnt) ? " col-sx-12 col-sm-12 col-md-6 col-lg-3 mt-2 hidden"
                                                            : " col-sx-12 col-sm-12 col-md-6 col-lg-3 mt-2"}

                                                            key={data.enterpriseId}>

                                                            <div className="row text-center">
                                                                <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11" >
                                                                    <div className="card" >
                                                                        <div className="card-header">
                                                                            {data.active > 0 &&
                                                                                justRead ? (
                                                                                step_1 === 1 && (
                                                                                    <div className="heading-elements">
                                                                                        <ul className="list-inline mb-0">
                                                                                            <li>
                                                                                                <i
                                                                                                    className="ft-trash-2 text-danger font-medium-2 pointer"
                                                                                                    onClick={() => activeInactiveEnterprise(data.enterpriseId, 0)}
                                                                                                ></i>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                <div className="heading-elements">
                                                                                    <ul className="list-inline mb-0">
                                                                                        <li>
                                                                                            <i
                                                                                                className="ft-trash-2 text-danger font-medium-2 pointer"
                                                                                                onClick={() => activeInactiveEnterprise(data.enterpriseId, 0)}
                                                                                            ></i>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="card-body">
                                                                            {data.logo ?
                                                                                <div className="wrap-img-enterprise">
                                                                                    {
                                                                                        justRead ? (
                                                                                            step_3 === 1 ? (
                                                                                                <a href={"/perfil-empresa/" + hashString("guaydigital") +
                                                                                                    + data.enterpriseId + "=" + hashString(data.name)}>
                                                                                                    <img className={data.active > 0 ? " img-responsive pointer" : "img-responsive cursor-not-allowed"} src={data.logo} alt="guay-digital" />
                                                                                                </a>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    <img className={data.active > 0 ? " img-responsive pointer" : "img-responsive cursor-not-allowed"} src={data.logo} alt="guay-digital" />
                                                                                                </span>
                                                                                            )
                                                                                        ) : (
                                                                                            <a href={"/perfil-empresa/" + hashString("guaydigital") +
                                                                                                + data.enterpriseId + "=" + hashString(data.name)}>
                                                                                                <img className={data.active > 0 ? " img-responsive pointer" : "img-responsive cursor-not-allowed"} src={data.logo} alt="guay-digital" />
                                                                                            </a>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <div className="wrap-img-enterprise">
                                                                                    {
                                                                                        justRead ? (
                                                                                            step_3 === 1 ? (
                                                                                                <div className="form-group">
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        id="file"
                                                                                                        className="hidden"
                                                                                                        onChange={onChangeHandler}
                                                                                                        data-id={data.enterpriseId}
                                                                                                    />
                                                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                        <img
                                                                                                            src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                            id="img-person"
                                                                                                            data-id={data.enterpriseId}
                                                                                                            alt="persona"
                                                                                                            className="w-50 pointer"
                                                                                                            onClick={renderFilePers}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="form-group">
                                                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                        <img
                                                                                                            src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                            id="img-person"
                                                                                                            data-id={data.enterpriseId}
                                                                                                            alt="persona"
                                                                                                            className="w-50 pointer"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        ) : (
                                                                                            <div className="form-group">
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id="file"
                                                                                                    className="hidden"
                                                                                                    onChange={onChangeHandler}
                                                                                                    data-id={data.enterpriseId}
                                                                                                />
                                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                    <img
                                                                                                        src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                        id="img-person"
                                                                                                        data-id={data.enterpriseId}
                                                                                                        alt="persona"
                                                                                                        className="w-50 pointer"
                                                                                                        onClick={renderFilePers}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="mt-2 ">
                                                                                <p className="text-uppercase text-img-wrap">{data.name}</p>
                                                                            </div>

                                                                            {
                                                                                data.active === 1 ?
                                                                                    <div className="btn-group mr-1   mt-1">
                                                                                        <button type="button" className="btn btn-blue dropdown-toggle font-medium-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Configuración</button>
                                                                                        <div className="dropdown-menu">
                                                                                            {
                                                                                                justRead ? (
                                                                                                    step_3 === 1 && (
                                                                                                        <span className="dropdown-item pointer" onClick={() => deleteEntGroup(data.enterpriseId)}>Eliminar del Grupo</span>
                                                                                                    )
                                                                                                ) : (
                                                                                                    <span className="dropdown-item pointer" onClick={() => deleteEntGroup(data.enterpriseId)}>Eliminar del Grupo</span>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                justRead ? (
                                                                                                    (step_3 === 1 || step_3 === 2) && (
                                                                                                        <a className="dropdown-item" href={"/perfil-empresa/" + hashString("guaydigital") +
                                                                                                            + data.enterpriseId + "=" + hashString(data.name)}>Perfil de Organización</a>
                                                                                                    )
                                                                                                ) : (
                                                                                                    <a className="dropdown-item" href={"/perfil-empresa/" + hashString("guaydigital") +
                                                                                                        + data.enterpriseId + "=" + hashString(data.name)}>Perfil de Organización</a>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                justRead ? (
                                                                                                    (step_4 === 1 || step_4 === 2) && (
                                                                                                        data.incomplete === 0 ? (
                                                                                                            <a
                                                                                                                className="dropdown-item"
                                                                                                                href="/administracion-usuarios"
                                                                                                                onClick={() => goCollaborators(data.enterpriseId, data.rolEnterpriseId)}
                                                                                                            >Usuarios y Roles</a>
                                                                                                        ) : (
                                                                                                            <a
                                                                                                                className="dropdown-item"
                                                                                                                href="#!"
                                                                                                                onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                            >Usuarios y Roles</a>
                                                                                                        )
                                                                                                    )
                                                                                                ) : (
                                                                                                    data.incomplete === 0 ? (
                                                                                                        <a
                                                                                                            className="dropdown-item"
                                                                                                            href="/administracion-usuarios"
                                                                                                            onClick={() => goCollaborators(data.enterpriseId, data.rolEnterpriseId)}
                                                                                                        >Usuarios y Roles</a>
                                                                                                    ) : (
                                                                                                        <a
                                                                                                            className="dropdown-item"
                                                                                                            href="#!"
                                                                                                            onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                        >Usuarios y Roles</a>
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                justRead ? (
                                                                                                    (step_5 === 1 || step_5 === 2) && (
                                                                                                        data.incomplete === 0 ? (
                                                                                                            <a
                                                                                                                className="dropdown-item"
                                                                                                                href="/administracion-colaboradores"
                                                                                                                onClick={() => goCollaborators(data.enterpriseId, data.rolEnterpriseId)}
                                                                                                            >Colaboradores</a>
                                                                                                        ) : (
                                                                                                            <a
                                                                                                                className="dropdown-item"
                                                                                                                href="#!"
                                                                                                                onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                            >Colaboradores</a>
                                                                                                        )
                                                                                                    )
                                                                                                ) : (
                                                                                                    data.incomplete === 0 ? (
                                                                                                        <a
                                                                                                            className="dropdown-item"
                                                                                                            href="/administracion-colaboradores"
                                                                                                            onClick={() => goCollaborators(data.enterpriseId, data.rolEnterpriseId)}
                                                                                                        >Colaboradores</a>
                                                                                                    ) : (
                                                                                                        <a
                                                                                                            className="dropdown-item"
                                                                                                            href="#!"
                                                                                                            onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                        >Colaboradores</a>
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="btn-group mr-1 mt-9p ">
                                                                                        {
                                                                                            justRead ? (
                                                                                                step_1 === 1 ? (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-secundary font-small-3"
                                                                                                        onClick={() => activeInactiveEnterprise(data.enterpriseId, 1)}
                                                                                                    >Reactivar Organización</button>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-secundary font-small-3"
                                                                                                        disabled
                                                                                                    >Reactivar Organización</button>
                                                                                                )
                                                                                            ) : (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secundary font-small-3"
                                                                                                    onClick={() => activeInactiveEnterprise(data.enterpriseId, 1)}
                                                                                                >Reactivar Organización</button>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                        <Footer />
                    </div>
                </div>

                {/*MODAL*/}
                {/*<!-- Message modal -->*/}
                <div className="modal" id="modal-1" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto" style={{ backgroundColor: 'transparent', border: 'none' }}>
                            <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                                <button type="button" className="close" id="close" onClick={closeModal}>&times;</button>
                            </div>
                            {/*<!-- Modal body -->*/}
                            <div className="modal-body modal-div-style-1 container-style-1">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center text-center font-medium-2">
                                    <p>
                                        Puedes agregar organizaciones activas a tu Grupo Organizacional
                                    </p>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                    <div className="row font-medium-2 font-weight-bold mb-1">
                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6 text-center d-flex justify-content-center">
                                            <span>Organizaciones Activas</span>
                                        </div>
                                        <div className="col-sx-6 col-sm-6 col-md-6 col-lg-6 text-center d-flex justify-content-center">
                                            <span className="">Grupo Organizacional</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">

                                    <DualListBox
                                        options={setEnterprises()}
                                        selected={selected}
                                        onChange={onChange}

                                        icons={{
                                            moveLeft: <span className="la la-chevron-left" />,
                                            moveAllLeft: [
                                                <span key={0} className="la la-chevron-left" />,
                                                <span key={1} className="la la-chevron-left" />,
                                            ],
                                            moveRight: <span className="la la-chevron-right" />,
                                            moveAllRight: [
                                                <span key={0} className="la la-chevron-right" />,
                                                <span key={1} className="la la-chevron-right" />,
                                            ],
                                            moveDown: <span className="la la-chevron-down" />,
                                            moveUp: <span className="la la-chevron-up" />,
                                        }}
                                    />
                                </div>

                                <button className="btn btn-green btn-sm mb-1 btn-align-center w-25" style={{ bottom: '0' }}
                                    onClick={reloadEnterprises}
                                    id="close-1" >Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({ access }) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(Enterprises);
