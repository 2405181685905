import React/* , { useState, useEffect } */ from 'react';
/* // Components
import ImageViewer from './ImageViewer';
// Tools
import $ from "jquery";
import swal from "sweetalert";
// API calls
import { getGuayShop } from '../Models/Catalog_model'; */

const AppShop = () => {

    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
                <div
                    className="col-sx-12 col-sm-12 col-md-8 col-lg-3 mx-auto d-flex justify-content-center flex-wrap align-content-center"
                    style={{ height: "100vh" }}>
                    <img className="img-responsive" src="./../img/APP_guay-14.png" alt="img-shop" />
                </div>
                {/* {
                    products.map((product) => (
                        <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 mt-2">
                            <div className="card">
                                <img className="card-img-top img-responsive" src={product.url_preview} alt="img-product" />
                                <div className="card-body">
                                    <h4 className="card-title">{product.title}</h4>
                                    <p className="card-text">{product.summary}</p>
                                    <button
                                        className="btn btn-blue-3 btn-sm"
                                        onClick={() => handlePreview(product.url)}>¡Lo quiero!</button>
                                </div>
                            </div>
                        </div>
                    ))
                } */}
            </div>
            {/* <ImageViewer image={imagePreview} /> */}
        </div>
    )
}

export default AppShop;
