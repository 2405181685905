import React, { useState, useEffect } from 'react';
import Header from '../NewHome/headerAdministrador';
import Footer from '../footerApp';
import { redirectIfNotAuthenticated, getJwt, setJwt } from "../../../lib/auth";
import { updateStatusGroup, updateLogoEnte, updateLogoGroupEnte } from "./Model";
import { getuserData, setStatusEnterprise } from "../NewHome/Model";
import $ from "jquery";
import Swal from "sweetalert2";
import Menu from "../Menu";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import LoaderApp from '../LoaderApp';
import { getActiveEnterprise } from '../Models/Enterprise_model';
import { connect } from 'react-redux';
import { validateAccess, getSubsection } from '../../helpers/validations';
import { useHistory } from "react-router-dom";
import { changeEnterpriseAction } from '../../../redux/generalDuck';
import HelperApp from '../HelperApp';

const EmpresasPanel = ({ general, access, changeEnterpriseAction }) => {

    const [help, setHelp] = useState(0);
    const [data, updateData] = useState([]);
    const [enterprises, upEnterprises] = useState([]);
    const [enterpriseGroup, upEnterprisesGroup] = useState([]);

    // eslint-disable-next-line
    const [files, upFiles] = useStateWithCallbackLazy(0);
    const [enterprise] = useState({
        enterpriseId: ""
    });
    const [group] = useState({
        groupId: ""
    });
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const [justRead, setJustRead] = useState(false)

    const [justReadSteps, setJustReadSteps] = useState({
        step_1: 0,
        step_2: 0,
        step_3: 0,
        step_4: 0,
        step_5: 0,
    })
    const {
        step_1,
        step_2,
        step_3,
        step_4,
        step_5,
    } = justReadSteps

    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
            setJustRead(true)
            //console.log(access)
            //console.log(validateAccess(access.list, 4))
            const section = validateAccess(access.list, 4)
            if (section[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            //console.log(getSubsection(section[0].subSection, 24))
            const group_profile = getSubsection(section[0].subSection, 24)
            //console.log(getSubsection(section[0].subSection, 25))
            const group_orgs = getSubsection(section[0].subSection, 25)
            //console.log(getSubsection(section[0].subSection, 26))
            const org_profile = getSubsection(section[0].subSection, 26)
            //console.log(getSubsection(section[0].subSection, 27))
            const org_roles = getSubsection(section[0].subSection, 27)
            //console.log(getSubsection(section[0].subSection, 28))
            const org_collab = getSubsection(section[0].subSection, 28)
            setJustReadSteps({
                step_1: group_profile[0].id_action,
                step_2: group_orgs[0].id_action,
                step_3: org_profile[0].id_action,
                step_4: org_roles[0].id_action,
                step_5: org_collab[0].id_action,
            })
        }
        if (getJwt('rol') === '3') {
            toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
            history.push("/inicio-colaborador")
        }
    }, [access, history])

    useEffect(() => {
        try {
            getuserData(getJwt("jtw")).then((result) => {
                let enterprises = [];
                updateData(result);
                let enterpriseByGroup = []
                for (let x in result.enterprise) {
                    if (result.enterprise[x].enterpriseId === parseInt(getJwt('enterprise'))) {
                        if (sessionStorage.getItem('showMessage')) {
                            let message = "Faltan datos de la empresa " + result.enterprise[x].name + " por llenar. Ingresa a Perfil de organización y llena Datos generales."
                            sessionStorage.removeItem('showMessage');
                            toastr.warning(message, "¡Ooops!");
                        }
                    }
                    if ((result.enterprise[x].rolEnterpriseId === 2 || result.enterprise[x].rolEnterpriseId > 6) && result.enterprise[x].enterpriseGroupId === 0) {
                        enterprises.push(result.enterprise[x]);
                    }
                    if (result.enterprise[x].enterpriseGroupId > 0) {
                        enterpriseByGroup.push(result.enterprise[x])
                    }
                }
                upEnterprises(enterprises);
                let orderByGroup = []
                for (let x in result.enterpriseGroup) {
                    let group = []
                    for (let y in enterpriseByGroup) {
                        if (result.enterpriseGroup[x].enterpriseGroupId === enterpriseByGroup[y].enterpriseGroupId) {
                            group.push(enterpriseByGroup[y])
                        }
                    }
                    orderByGroup.push({ ...result.enterpriseGroup[x], organizaciones: group })
                }
                console.log(orderByGroup)
                console.log(access.list.length)
                let getGroup = []
                for (let x in orderByGroup) {
                    if (orderByGroup[x].organizaciones.length > 0) {
                        let flag = 0
                        for (let y in orderByGroup[x].organizaciones) {
                            if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
                                if ((orderByGroup[x].organizaciones[y].rolEnterpriseId === 2 || orderByGroup[x].organizaciones[y].rolEnterpriseId > 6) && flag === 0 && step_2 > 0) {
                                    flag = 1
                                    getGroup.push(orderByGroup[x])
                                } else {
                                    if ((orderByGroup[x].organizaciones[y].rolEnterpriseId === 2 || orderByGroup[x].organizaciones[y].rolEnterpriseId > 6) && flag === 0) {
                                        flag = 1
                                        getGroup.push(orderByGroup[x])
                                    }
                                }
                            } else {
                                if (orderByGroup[x].organizaciones[y].rolEnterpriseId === 2 && flag === 0) {
                                    flag = 1
                                    getGroup.push(orderByGroup[x])
                                }
                            }
                        }
                    } else {
                        getGroup.push(orderByGroup[x])
                    }
                    /* for (let y in enterpriseByGroup) {
                        if (parseInt(getJwt('rol')) > 6 && access.access.list.length > 0) {
                            if (result.enterpriseGroup[x].enterpriseGroupId === enterpriseByGroup[y].enterpriseGroupId && (enterpriseByGroup[y].rolEnterpriseId === 2 || enterpriseByGroup[y].rolEnterpriseId > 6)) {
                                orderByGroup.push(result.enterpriseGroup[x])
                            }
                        } else {
                            if (result.enterpriseGroup[x].enterpriseGroupId === enterpriseByGroup[y].enterpriseGroupId && enterpriseByGroup[y].rolEnterpriseId === 2) {
                                orderByGroup.push(result.enterpriseGroup[x])
                            }
                        }
                    } */
                }
                console.log(getGroup)
                upEnterprisesGroup(getGroup);
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, [access]);

    const activeInactiveEnterprise = (idEnterprise, action) => {
        try {
            if (action === 0) {
                Swal.fire({
                    title: '<p class="text-white mb-2">¿Deseas eliminar la empresa?</p>',
                    text: "Solo podrás eliminar empresas que no tengan información de colaboradores.",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'NO',
                    showConfirmButton: true,
                    confirmButtonText: "SÍ",
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        let setActive = {
                            idEnterprise: idEnterprise,
                            idAction: action
                        }
                        let formData = new FormData();
                        formData.append("request", JSON.stringify(setActive));
                        setStatusEnterprise(formData).then((res) => {
                            switch (res.code) {
                                case 1:
                                    getuserData(getJwt("jtw")).then((result) => {
                                        upEnterprises(result.enterprise);
                                        upEnterprisesGroup(result.enterpriseGroup);
                                    });
                                    toastr.success("Se eliminó correctamente la organización.", "¡Exito!");
                                    break;
                                case 1002:
                                    toastr.info("La organización cuenta con colaboradores activos, no es posible eliminar.", "¡Ooops!");
                                    break;
                                default:
                                    toastr.success("No se eliminó correctamente la organización.", "¡Ooops!");
                                    break;
                            }
                        });
                    }
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
                let setActive = {
                    idEnterprise: idEnterprise,
                    idAction: action
                }
                let formData = new FormData();
                formData.append("request", JSON.stringify(setActive));
                setStatusEnterprise(formData).then((res) => {
                    if (res.code === 1) {
                        getuserData(getJwt("jtw")).then((result) => {
                            upEnterprises(result.enterprise);
                            upEnterprisesGroup(result.enterpriseGroup);
                        });
                        toastr.success("Se activo correctamente la empresa.", "¡Exito!");
                    }
                });
            }

        } catch (error) {
            console.log(error);
        }
    }

    const activeInactiveGroup = (idgroup, action) => {
        try {
            if (action === 0) {
                Swal.fire({
                    title: '<p class="text-white mb-2">¿Deseas eliminar el Grupo Empresarial?</p>',
                    text: "Solo podrás eliminar grupos empresariales que no tengan empresas asociadas o empresas inactivas.",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusCancel: true,
                    cancelButtonText: 'NO',
                    showConfirmButton: true,
                    confirmButtonText: "SÍ",
                    reverseButtons: true,
                    dangerMode: true
                }).then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        let setActive = {
                            idGroup: idgroup,
                            idAction: action
                        }
                        let formData = new FormData();
                        formData.append("request", JSON.stringify(setActive));
                        updateStatusGroup(formData).then((res) => {
                            if (!res.code) {
                                getuserData(getJwt("jtw")).then((result) => {
                                    upEnterprises(result.enterprise);
                                    upEnterprisesGroup(result.enterpriseGroup);
                                });
                                toastr.success("Se eliminó el Grupo Organizacional.", "¡Exito!");
                            } else {
                                let message = "";
                                switch (res.code) {
                                    case 7003:
                                        message = "El Grupo Empresarial tiene empresas activas relacionadas.";
                                        break;
                                    default:
                                        message = "Ocurrió un problema con la actualización. Intente nuevamente."
                                        break;
                                }
                                toastr.error(message, "¡Error!");
                            }
                        });
                    }
                });
                $(".swal2-title").addClass("bg-header-swal");
                $(".swal2-actions").addClass("w-100 justify-content-around");
                $(".swal2-confirm").removeClass("swal2-styled");
                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                $(".swal2-cancel").removeClass("swal2-styled");
                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
            } else {
                let setActive = {
                    idGroup: idgroup,
                    idAction: action
                }
                let formData = new FormData();
                formData.append("request", JSON.stringify(setActive));
                setStatusEnterprise(formData).then((res) => {
                    if (!res.code) {
                        toastr.success("Se eliminó el grupo Empresarial", "!Éxito!");
                        getuserData(getJwt("jtw")).then((result) => {
                            upEnterprises(result.enterprise);
                            upEnterprisesGroup(result.enterpriseGroup);
                        });
                        toastr.success("Se activo correctamente el Grupo Organizacional.", "¡Exito!");
                    } else {
                        let message = "";
                        switch (res.code) {
                            case 7003:
                                message = "El Grupo Empresarial tiene empresas activas relacionadas.";
                                break;
                            default:
                                message = "Ocurrió un problema con la actualización. Intente nuevamente."
                                break;
                        }
                        toastr.error(message, "¡Error!");
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* const hashString = (string) => {
        try {
            //let r = Math.random().toString(36).substring(7);
            var enc = window.btoa(string);
            return enc;
        } catch (error) {
            console.log(error);
        }
    } */

    const hashString = string => {
        try {
            let enc = window.btoa(string)
            const removeSlash = enc.replace('/', '')
            return removeSlash
        } catch (error) {
            console.log(error)
        }
    }

    const onChangeHandler = (e) => {
        try {
            let $currentTarget = $(e.currentTarget);
            var empresa = $currentTarget.attr("data-id");
            enterprise.enterpriseId = empresa;
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#img-person[data-id="' + empresa + '"]').attr('src', e.target.result);
            };

            let file = e.target.files[0];
            reader.readAsDataURL(e.target.files[0]);
            let formData = new FormData();
            upFiles(
                file, () => {
                    formData.append("file", file)

                });
            formData.append("enterprise", JSON.stringify(enterprise));
            updateLogoEnte(formData).then((res) => {
                if (res.success) {
                    toastr.success("Se actualizó correctamente el logo de la empresa.", "¡Exito!");
                    getuserData(getJwt("jtw")).then((result) => {
                        upEnterprises(result.enterprise);
                        upEnterprisesGroup(result.enterpriseGroup);
                    });
                } else {
                    toastr.error("Ocurrió un problema con la actualización. Intente nuevamente.", "¡Error!");
                }
            });

        } catch (error) {
            console.log(error);
        }
    }

    const onChangeHandlerGroup = (e) => {
        try {
            let $currentTarget = $(e.currentTarget);
            var idGroup = $currentTarget.attr("data-group");
            group.groupId = idGroup;
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#img-person[data-group="' + idGroup + '"]').attr('src', e.target.result);
            };

            let file = e.target.files[0];
            reader.readAsDataURL(e.target.files[0]);
            let formData = new FormData();
            upFiles(
                file, () => {
                    formData.append("file", file)

                });
            formData.append("group", JSON.stringify(group));
            updateLogoGroupEnte(formData).then((res) => {
                if (res.success) {
                    toastr.success("Se actualizó correctamente el logo del Grupo Organizacional.", "¡Exito!");
                    getuserData(getJwt("jtw")).then((result) => {
                        upEnterprises(result.enterprise);
                        upEnterprisesGroup(result.enterpriseGroup);
                    });
                } else {
                    toastr.error("Ocurrió un problema con la actualización. Intente nuevamente.", "¡Error!");
                }
            });

        } catch (error) {
            console.log(error);
        }
    }

    const renderFilePers = () => {
        $("#file").click();
    }

    const goCollaborators = (enterpriseId, rol) => {
        try {
            for (let x in enterprises) {
                if (enterprises[x].enterpriseId === enterpriseId) {
                    let userData = {
                        id: data.token,
                        enterprise: enterpriseId,
                        rol: rol,
                        help: data.help,
                        // VZO 24/02/2021, help2: 0
                        tkc: data.tk,
                        email: data.correo,
                        session: data.session,
                        enterpriseName: enterprises[x].name,
                        enterpriseInitials: enterprises[x].initials,
                        userName: data.name
                    }
                    setJwt(userData);
                    if (sessionStorage.getItem('fromOrg') !== 'undefined') {
                        sessionStorage.removeItem('fromOrg');
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function goEnterpriseLink() {
        getActiveEnterprise(getJwt("enterprise")).then((response) => {
            switch (response?.code) {
                case 1: //empresa activa
                    window.location = "/inicio-administrador";
                    break; //empresa inactiva
                case 1001:
                    toastr.info("Esta empresa no se encuentra activa");
                    break;
                default://error no documentado
                    break;
            }
        });
    }

    const goLinkCase = (pagina, enterpriseId, enterprise) => {
        switch (pagina) {
            case 1:
                //perfil de la organizacion
                window.location = "/perfil-empresa/" + hashString("guaydigital") + enterpriseId + "=" + hashString(enterprise.name);
                break;
            case 2:
                //usuarios y roles
                window.location = "/administracion-usuarios";
                goCollaborators(enterprise.enterpriseId, enterprise.rolEnterpriseId)
                break;
            case 3:
                //Colaboradores
                window.location = "/administracion-colaboradores";
                goCollaborators(enterprise.enterpriseId, enterprise.rolEnterpriseId)
                break;
            default:
                window.location.reload();
                break;
        }
    }

    const changeEnterprise = (enterpriseId, rol, enterprise, pagina) => {
        getActiveEnterprise(enterpriseId).then((response) => {
            switch (response?.code) {
                case 1: //empresa activa
                    for (let x in enterprises) {
                        if (enterprises[x].enterpriseId === enterpriseId) {
                            let userData = {
                                id: data.token,
                                enterprise: enterpriseId,
                                rol: rol,
                                help: data.help,
                                tkc: data.tk,
                                email: data.correo,
                                session: data.session,
                                enterpriseName: enterprises[x].name,
                                enterpriseInitials: enterprises[x].initials,
                                business: data.business,
                                userName: data.name
                            }
                            setJwt(userData);
                            changeEnterpriseAction(enterprise)
                            switch (rol) {
                                case 1:
                                    window.location = "inicio-usuario";
                                    break;
                                case 4:
                                    window.location = "inicio-colaborador";
                                    break;
                                default:
                                    // window.location.reload();
                                    goLinkCase(pagina, enterpriseId, enterprise);
                                    break;
                            }
                        }
                    }
                    break; //empresa inactiva
                case 1001:
                    toastr.info("No se puede acceder debido a que la empresa no se encuentra activa.");
                    break;
                default://error no documentado
                    toastr.info("Ocurrió un error desconocido, verifique que esta conectado a internet, sino contacte a su administrador.");
                    break;
            }
        });
    }

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header row">
                            <div className="col-md-6"></div>
                            <div className="content-header-right col-md-6 col-12 mb-2 breadcrumb-new text-center mt-1">
                                <div className="row breadcrumbs-top d-inline-block">

                                    <div className="breadcrumb-wrapper col-12">
                                        <ol className="breadcrumb">
                                            {enterprises.length > 0
                                                ?
                                                (
                                                    <li className="breadcrumb-item font-medium-3"><span className="pointer" onClick={goEnterpriseLink}>Administración</span></li>
                                                )
                                                :
                                                (
                                                    <li className="breadcrumb-item font-medium-3 pointer fake-link-guay" onClick={() => toastr.warning("Primero debes seleccionar una empresa de la lista.", "¡Ooops!")}>Administración</li>
                                                )
                                            }
                                            <li className="breadcrumb-item active font-medium-3">Organizaciones
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            {loading === true ?
                                <LoaderApp />
                                :
                                <div className="row">

                                    <div className="col-sx-12 col-sm-12 col-md-1 col-lg-2 mb-40"></div>
                                    <div className="col-sx-12 col-sm-12 col-md-10 col-lg-9 mb-40">
                                        <div className="row mb-2">
                                            <div className="col-md-2">
                                                <h2 className="mt-1"><strong>Dar de alta</strong></h2>
                                            </div>
                                            {
                                                justRead ? (
                                                    step_3 === 1 && (
                                                        <div className="col-md-3 mt-1p">
                                                            <span className="align-middle text-base font-medium-2">Organización</span>
                                                            {/* <img className="icon-img-35 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_guay_inicio-05.png" alt="img-guay" onClick={() => setHelp(1)} /> */}
                                                            <a href="/inicio-empresas/registro-empresa"><img className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-13.png" alt="img-guay" /></a>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="col-md-3 mt-1p">
                                                        <span className="align-middle text-base font-medium-2">Organización</span>
                                                        {/* <img className="icon-img-35 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_guay_inicio-05.png" alt="img-guay" onClick={() => setHelp(1)} /> */}
                                                        <a href="/inicio-empresas/registro-empresa"><img className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-13.png" alt="img-guay" /></a>
                                                    </div>
                                                )
                                            }
                                            {
                                                justRead ? (
                                                    step_1 === 1 && (
                                                        <div className="col-md-4 mt-1p">
                                                            <span className="align-middle text-base font-medium-2">Grupo Organizacional</span>
                                                            {/* <img className="icon-img-35 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_guay_inicio-05.png" alt="img-guay" onClick={() => setHelp(2)} /> */}
                                                            <a href="/inicio-empresas/registra-grupo">
                                                                <img className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" />
                                                            </a>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="col-md-4 mt-1p">
                                                        <span className="align-middle text-base font-medium-2">Grupo Organizacional</span>
                                                        {/* <img className="icon-img-35 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_guay_inicio-05.png" alt="img-guay" onClick={() => setHelp(2)} /> */}
                                                        <a href="/inicio-empresas/registra-grupo">
                                                            <img className="icon-img-50 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                            {
                                                help === 1
                                                    ?
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mb-40 contenedor-400">
                                                        <div className="row">
                                                            <button type="button" class="close" style={{ position: 'absolute', right: '0' }} onClick={() => setHelp(0)}>&times;</button>
                                                            <div className="col-sx-12 col-sm-12 col-md-12 d-flex align-items-center justify-content-center">

                                                                <div className="col-sx-12 col-sm-12 col-md-12">
                                                                    <div className="globo iii right abajo-izquierda text-center">
                                                                        <p>
                                                                            Cuando tu das de alta una empresa la podrás
                                                                            click en el botón de <img className="" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-13.png" alt="img-guay" style={{ height: '15px', width: '15px' }} />
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <img className="dummy-guay-2" src="./../img/imagesEmpresasPanel/Elementos_GUAY-06.png" alt="img-guay" />

                                                        </div>
                                                    </div>
                                                    : help === 2
                                                        ?
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-8 mb-40 contenedor-400">
                                                            <div className="row">
                                                                <button type="button" class="close" style={{ position: 'absolute', right: '0' }} onClick={() => setHelp(0)}>&times;</button>
                                                                <div className="col-sm-12 d-flex align-items-center justify-content-center">

                                                                    <div className="col-sm-12">
                                                                        <div className="globo iii left abajo-derecha text-center">
                                                                            <p>
                                                                                Cuando tu das de alta una empresa la podrás
                                                                                hacer subdivisiones por medio de Centros de
                                                                                Trabajo y/o Areas ...
                                                                            </p>
                                                                            <p>
                                                                                Para ogregar una nueva empresa da
                                                                                click en el botón de <img className="" src="./../img/imagesEmpresasPanel/iconos_shopy_guay-14.png" alt="img-guay" style={{ height: '15px', width: '15px' }} />
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <img className="dummy-guay-3" src="./../img/imagesEmpresasPanel/Elementos_GUAY-05.png" alt="img-guay" />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                            }
                                        </div>
                                        {
                                            enterpriseGroup.length > 0 ?
                                                <div>
                                                    <span className="align-middle text-base font-medium-2">Grupo Organizacional</span><hr />
                                                </div>
                                                : ""
                                        }

                                        <div className="row">
                                            {
                                                enterpriseGroup.map((data_ent) =>
                                                    <div className="col-sx-12 col-sm-12 col-md-6 col-lg-3 mt-2" key={data_ent.enterpriseGroupId}>
                                                        <div className="row text-center">
                                                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11" >
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        {/* <h4 className="card-title" id="heading-buttons2"></h4> */}
                                                                        {data_ent.status > 0 &&
                                                                            justRead ? (
                                                                            step_1 === 1 && (
                                                                                <div className="heading-elements">
                                                                                    <ul className="list-inline mb-0">
                                                                                        <li>
                                                                                            <i className="ft-trash-2 text-danger font-medium-2 pointer" onClick={() => activeInactiveGroup(data_ent.enterpriseGroupId, 0)}></i>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            )
                                                                        ) : (
                                                                            <div className="heading-elements">
                                                                                <ul className="list-inline mb-0">
                                                                                    <li>
                                                                                        <i className="ft-trash-2 text-danger font-medium-2 pointer" onClick={() => activeInactiveGroup(data_ent.enterpriseGroupId, 0)}></i>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            justRead ? (
                                                                                step_1 === 0 || step_1 === 2 ? (
                                                                                    data_ent.logo ?
                                                                                        <div className="wrap-img-enterprise">
                                                                                            <img
                                                                                                className="img-responsive pointer"
                                                                                                src={data_ent.logo}
                                                                                                alt="guay-digital"
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="wrap-img-enterprise">
                                                                                            <div className="form-group">
                                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                    <img
                                                                                                        src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                        id="img-person"
                                                                                                        data-group={data_ent.enterpriseGroupId}
                                                                                                        alt="Grupo Organizacional"
                                                                                                        className="w-75 pointer"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                ) : (
                                                                                    data_ent.logo ?
                                                                                        <div className="wrap-img-enterprise">
                                                                                            <a href={"/grupo-empresarial/" + hashString(data.enterpriseGroupId) + "="
                                                                                                + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}>
                                                                                                <img className="img-responsive pointer" src={data_ent.logo} alt="guay-digital" />
                                                                                            </a>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="wrap-img-enterprise">
                                                                                            <div className="form-group">
                                                                                                <input type="file" id="file" className="hidden" onChange={onChangeHandlerGroup} data-group={data_ent.enterpriseGroupId} />
                                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                    <img src="./../img/altaEmpresas/iconos_shopy_img-31.png" id="img-person" data-group={data_ent.enterpriseGroupId} alt="Grupo Organizacional" className="w-75 pointer" onClick={renderFilePers} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                )
                                                                            ) : (
                                                                                data_ent.logo ?
                                                                                    <div className="wrap-img-enterprise">
                                                                                        <a href={"/grupo-empresarial/" + hashString(data.enterpriseGroupId) + "="
                                                                                            + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}>
                                                                                            <img className="img-responsive pointer" src={data_ent.logo} alt="guay-digital" />
                                                                                        </a>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="wrap-img-enterprise">
                                                                                        <div className="form-group">
                                                                                            <input type="file" id="file" className="hidden" onChange={onChangeHandlerGroup} data-group={data_ent.enterpriseGroupId} />
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                <img src="./../img/altaEmpresas/iconos_shopy_img-31.png" id="img-person" data-group={data_ent.enterpriseGroupId} alt="Grupo Organizacional" className="w-75 pointer" onClick={renderFilePers} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            )
                                                                        }
                                                                        <div className="mt-5">
                                                                            <p className="text-uppercase text-img-wrap">{data_ent.comercialName}</p>
                                                                        </div>
                                                                        {
                                                                            justRead ? (
                                                                                data_ent.active > 0 ?
                                                                                    <div className="btn-group mr-1 mb-1  mt-1">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-blue dropdown-toggle font-medium-1"
                                                                                            data-toggle="dropdown"
                                                                                            aria-haspopup="true"
                                                                                            aria-expanded="false"
                                                                                        >Configuración</button>
                                                                                        <div className="dropdown-menu">
                                                                                            {
                                                                                                (step_1 === 1 || step_1 === 2) && (
                                                                                                    <a
                                                                                                        className="dropdown-item"
                                                                                                        href={"/grupo-empresarial/" + hashString("guay.digital") + "=" + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}
                                                                                                    >Perfil de Grupo</a>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                (step_2 === 1 || step_2 === 2) && (
                                                                                                    <a
                                                                                                        className="dropdown-item"
                                                                                                        href={"/grupo-empresas/" + hashString("guay.digital") + "="
                                                                                                            + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}
                                                                                                    >Organizaciones</a>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="btn-group mr-1 mb-1  mt-1 ">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-secundary font-small-3"
                                                                                            disabled={step_1 === 1 ? false : true}
                                                                                        >Reactivar Organización</button>
                                                                                    </div>
                                                                            ) : (
                                                                                data_ent.active > 0 ?
                                                                                    <div className="btn-group mr-1 mb-1  mt-1">
                                                                                        <button type="button" className="btn btn-blue dropdown-toggle font-medium-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Configuración</button>
                                                                                        <div className="dropdown-menu">
                                                                                            <a className="dropdown-item" href={"/grupo-empresarial/" + hashString("guay.digital") + "="
                                                                                                + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}>Perfil de Grupo</a>
                                                                                            {/* <a className="dropdown-item" href={"/usuarios-grupo-org/" + hashString("guay.digital") + "="
                                                                                            + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}>Usuarios y Roles</a> */}
                                                                                            <a className="dropdown-item" href={"/grupo-empresas/" + hashString("guay.digital") + "="
                                                                                                + data_ent.enterpriseGroupId + "=" + hashString(data_ent.comercialName)}>Organizaciones</a>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="btn-group mr-1 mb-1  mt-1 ">
                                                                                        <button type="button" className="btn btn-secundary font-small-3">
                                                                                            Reactivar Organización</button>
                                                                                    </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }

                                        </div>

                                        {
                                            enterprises.length > 0 ?
                                                <div>
                                                    <span className="align-middle text-base font-medium-2">Organizaciones</span><hr />
                                                </div>
                                                : ""
                                        }
                                        <div className="row">

                                            {
                                                enterprises.map((data) =>

                                                    <div className={data.enterpriseGroupId !== 0 ? " col-sx-12 col-sm-12 col-md-6 col-lg-3 mt-2 hidden"
                                                        : " col-sx-12 col-sm-12 col-md-6 col-lg-3 mt-2"}


                                                        key={data.enterpriseId}>

                                                        <div className="row text-center">
                                                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11" >
                                                                <div className="card" >
                                                                    <div className="card-header">
                                                                        {data.active > 0 &&
                                                                            justRead ? (
                                                                            step_1 === 1 && (
                                                                                <div className="heading-elements">
                                                                                    <ul className="list-inline mb-0">
                                                                                        <li>
                                                                                            <i
                                                                                                className="ft-trash-2 text-danger font-medium-2 pointer"
                                                                                                onClick={() => activeInactiveEnterprise(data.enterpriseId, 0)}
                                                                                            ></i>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            )
                                                                        ) : (
                                                                            <div className="heading-elements">
                                                                                <ul className="list-inline mb-0">
                                                                                    <li>
                                                                                        <i
                                                                                            className="ft-trash-2 text-danger font-medium-2 pointer"
                                                                                            onClick={() => activeInactiveEnterprise(data.enterpriseId, 0)}
                                                                                        ></i>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {data.logo ?
                                                                            <div className="wrap-img-enterprise">
                                                                                {
                                                                                    justRead ? (
                                                                                        step_3 === 1 ? (
                                                                                            <a href={"/perfil-empresa/" + hashString("guaydigital") +
                                                                                                + data.enterpriseId + "=" + hashString(data.name)}>
                                                                                                <img className={data.active > 0 ? " img-responsive pointer" : "img-responsive cursor-not-allowed"} src={data.logo} alt="guay-digital" />
                                                                                            </a>
                                                                                        ) : (
                                                                                            <span>
                                                                                                <img className={data.active > 0 ? " img-responsive pointer" : "img-responsive cursor-not-allowed"} src={data.logo} alt="guay-digital" />
                                                                                            </span>
                                                                                        )
                                                                                    ) : (
                                                                                        <a href={"/perfil-empresa/" + hashString("guaydigital") +
                                                                                            + data.enterpriseId + "=" + hashString(data.name)}>
                                                                                            <img className={data.active > 0 ? " img-responsive pointer" : "img-responsive cursor-not-allowed"} src={data.logo} alt="guay-digital" />
                                                                                        </a>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="wrap-img-enterprise">
                                                                                {
                                                                                    justRead ? (
                                                                                        step_3 === 1 ? (
                                                                                            <div className="form-group">
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id="file"
                                                                                                    className="hidden"
                                                                                                    onChange={onChangeHandler}
                                                                                                    data-id={data.enterpriseId}
                                                                                                />
                                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                    <img
                                                                                                        src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                        id="img-person"
                                                                                                        data-id={data.enterpriseId}
                                                                                                        alt="persona"
                                                                                                        className="w-50 pointer"
                                                                                                        onClick={renderFilePers}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="form-group">
                                                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                    <img
                                                                                                        src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                        id="img-person"
                                                                                                        data-id={data.enterpriseId}
                                                                                                        alt="persona"
                                                                                                        className="w-50 pointer"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                type="file"
                                                                                                id="file"
                                                                                                className="hidden"
                                                                                                onChange={onChangeHandler}
                                                                                                data-id={data.enterpriseId}
                                                                                            />
                                                                                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                                                                <img
                                                                                                    src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                                                    id="img-person"
                                                                                                    data-id={data.enterpriseId}
                                                                                                    alt="persona"
                                                                                                    className="w-50 pointer"
                                                                                                    onClick={renderFilePers}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        }
                                                                        <div className="mt-2 ">
                                                                            <p className="text-uppercase text-img-wrap">{data.name}</p>
                                                                        </div>

                                                                        {
                                                                            data.active === 1 ?
                                                                                <div className="btn-group mr-1   mt-1">
                                                                                    <button type="button" className="btn btn-blue dropdown-toggle font-medium-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Configuración</button>
                                                                                    <div className="dropdown-menu">
                                                                                        {
                                                                                            justRead ? (
                                                                                                (step_3 === 1 || step_3 === 2) && (
                                                                                                    <button type="button" className="dropdown-item" onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data, 1)}>Perfil de Organización</button>
                                                                                                )
                                                                                            ) : (
                                                                                                <button type="button" className="dropdown-item" onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data, 1)}>Perfil de Organización</button>
                                                                                            )
                                                                                        }
                                                                                        {/* <a className="dropdown-item"href={"/administracion-usuarios/"+ hashString("guaydigital")+ 
                                                                            + data.enterpriseId +"=" + hashString(data.name)}>Usuarios y Roles</a> */}
                                                                                        {
                                                                                            justRead ? (
                                                                                                (step_4 === 1 || step_4 === 2) && (
                                                                                                    data.incomplete === 0 ? (
                                                                                                        <button type="button" className="dropdown-item" onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data, 2)}>Usuarios y Roles</button>
                                                                                                    ) : (
                                                                                                        <a
                                                                                                            className="dropdown-item"
                                                                                                            href="#!"
                                                                                                            onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                        >Usuarios y Roles</a>
                                                                                                    )
                                                                                                )
                                                                                            ) : (
                                                                                                data.incomplete === 0 ? (
                                                                                                    <button type="button" className="dropdown-item" onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data, 2)}>Usuarios y Roles</button>
                                                                                                ) : (
                                                                                                    <a
                                                                                                        className="dropdown-item"
                                                                                                        href="#!"
                                                                                                        onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                    >Usuarios y Roles</a>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            justRead ? (
                                                                                                (step_5 === 1 || step_5 === 2) && (
                                                                                                    data.incomplete === 0 ? (
                                                                                                        <button type="button" className="dropdown-item" onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data, 3)}>Colaboradores</button>
                                                                                                    ) : (
                                                                                                        <a
                                                                                                            className="dropdown-item"
                                                                                                            href="#!"
                                                                                                            onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                        >Colaboradores</a>
                                                                                                    )
                                                                                                )
                                                                                            ) : (
                                                                                                data.incomplete === 0 ? (
                                                                                                    <button type="button" className="dropdown-item" onClick={() => changeEnterprise(data.enterpriseId, data.rolEnterpriseId, data, 3)}>Colaboradores</button>
                                                                                                ) : (
                                                                                                    <a
                                                                                                        className="dropdown-item"
                                                                                                        href="#!"
                                                                                                        onClick={() => toastr.warning("Primero debes entrar a Perfil de Organización y llenar Datos generales.", "¡Ooops!")}
                                                                                                    >Colaboradores</a>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                        {/* <a className="dropdown-item" href={"/administracion-colaboradores/"+ hashString("guaydigital")+ 
                                                                            + data.enterpriseId +"=" + hashString(data.name)}>Colaboradores</a> */}
                                                                                    </div >
                                                                                </div >
                                                                                :
                                                                                <div className="btn-group mr-1 mt-9p ">
                                                                                    {
                                                                                        justRead ? (
                                                                                            step_1 === 1 ? (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secundary font-small-3"
                                                                                                    onClick={() => activeInactiveEnterprise(data.enterpriseId, 1)}
                                                                                                >Reactivar Organización</button>
                                                                                            ) : (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secundary font-small-3"
                                                                                                    disabled
                                                                                                >Reactivar Organización</button>
                                                                                            )
                                                                                        ) : (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-secundary font-small-3"
                                                                                                onClick={() => activeInactiveEnterprise(data.enterpriseId, 1)}
                                                                                            >Reactivar Organización</button>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </div >
                                                                </div >
                                                            </div >
                                                        </div >

                                                    </div >

                                                )
                                            }
                                        </div >
                                        {
                                            enterprises.length === 0 && enterpriseGroup.length === 0 ?
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <div className="card">
                                                            <div className="mt-3 mb-3">
                                                                <h3>¡Hola!</h3><br />
                                                                <h3>Aún no tienes ninguna empresa o grupo <br />empresarial dado de alta, te invitamos a<br /> iniciar tu configuración.</h3>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div> : ""
                                        }



                                    </div >

                                    <Footer />
                                </div >
                            }
                        </div >
                    </div >
                </div >
            </div >
        );
    }
}

const mapStateToProps = ({ general, access }) => {
    return {
        general,
        access,
    }
}
export default connect(mapStateToProps, { changeEnterpriseAction })(EmpresasPanel);