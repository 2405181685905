import React, { useState, useEffect } from 'react';
//import { getEnterpriseById } from "./Model";
import { getJwt } from "../../../lib/auth";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { getRoleAssignment, /* getRoleAssignmentCatalog, */ setRegisterUserRol, setChangeRole, setDisableRoleAssignment } from "../NewHome/Model";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Swal from "sweetalert2";
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import LoaderApp from '../LoaderApp';
import { getBranches } from '../Models/Enterprise_model';
import { getRoleAssignmentByEnterprise } from '../Models/Catalog_model'
import { MultiSelect } from 'react-multi-select-component'

const AdministracionUsuarios = ({ justRead, step_1 }) => {

    const [roles, updateRoles] = useState([]);
    const [listUsers, updateListUsers] = useState([]);
    const [idUser, setIdUser] = useState(0);
    /* const [company, updCompany] = useState({
        enterpriseId: ""
    }); */
    // const [enterprise] = useState({});
    const [newUser, updateNewUser] = useState({
        enterpriseId: getJwt("enterprise"),
        name: "",
        lastName: "",
        email: "",
        phone: "",
        rolId: 0
    });
    const userRol = parseInt(getJwt("rol"));
    const [loading, setLoading] = useState(true);
    /* const [ListBranch, setListBranch] = useState([]); */

    const [selectOptions, setSelectOptions] = useState([])
    const [selected, setSelected] = useState([])

    useEffect(() => {
        let idEnterprise = {
            idEnterprise: getJwt("enterprise")
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(idEnterprise));
        getRoleAssignment(formData).then((result) => {
            setLoading(false);
            updateListUsers(result.listUsers);
        });
        /* getRoleAssignmentCatalog().then((result) => {
            setLoading(false);
            updateRoles(result);
        }); */
        getRoleAssignmentByEnterprise(getJwt('enterprise')).then((response) => {
            setLoading(false);

            let getAllRoles

            if (parseInt(getJwt("rol")) > 6) {
                getAllRoles = response.filter(product => product.idPerzonalizedProfile > 6)
            } else {
                const getUnlikedRoles = response.filter(product => product.idPerzonalizedProfile === 1 || product.idPerzonalizedProfile === 4 || product.idPerzonalizedProfile === 5)
                //console.log(getUnlikedRoles)
                const getUnlikedRolesId = getUnlikedRoles.map(product => product.idPerzonalizedProfile)
                //console.log(getUnlikedRolesId)
                const getAllRolesId = response.map(product => product.idPerzonalizedProfile)
                //console.log(getAllRolesId)
                const getLikedRoles = getAllRolesId.filter(product => getUnlikedRolesId.indexOf(product) === -1)
                //console.log(getLikedRoles)
                getAllRoles = response.filter(product => getLikedRoles.indexOf(product.idPerzonalizedProfile) !== -1)
            }

            //console.log(getAllRoles)
            updateRoles(getAllRoles);
        });
        getBranches(getJwt("enterprise")).then((response) => {
            switch (response?.code) {
                case 1:
                    //setListBranch(response.branches);
                    const getBranchesList = response.branches.map(branch => ({ value: branch.branchEntId, label: branch.description }))
                    setSelectOptions(getBranchesList)
                    break;

                default:
                    console.error('error');
                    break;
            }
        });

    }, []);

    // Función que se ejecuta cada que el usuario escribe en un input
    const updateState = e => {
        let value = e.target.value;
        $("#" + e.target.name).removeClass("border-danger-guay");
        if ($("#rolId").val() !== "0") {
            $("#rolId").removeClass("fake-placeholder");
        } else {
            $("#rolId").addClass("fake-placeholder");
        }
        switch (e.target.name) {
            case "phone":
                $("#phone2").removeClass("border-danger-guay");
                if (value.length < 11) {
                    updateNewUser({
                        ...newUser,
                        [e.target.name]: value
                    });
                }
                break;
            case "email":
                $("#email2").removeClass("border-danger-guay");
                updateNewUser({
                    ...newUser,
                    [e.target.name]: value
                });
                break;
            default:
                updateNewUser({
                    ...newUser,
                    [e.target.name]: e.target.value
                });
                break;
        }
    }

    // Extraer los valores
    const { name, lastName, email, phone, rolId } = newUser;

    const validateForm = () => {
        let form = 0;
        if (name.length === 0) {
            $("#name").addClass("border-danger-guay");
            $("#name2").addClass("border-danger-guay");
        } else {
            form = form + 1;
            $("#name").removeClass("border-danger-guay");
            $("#name2").removeClass("border-danger-guay");
        }
        if (lastName.length === 0) {
            $("#lastName").addClass("border-danger-guay");
            $("#lastName2").addClass("border-danger-guay");
        } else {
            form = form + 1;
            $("#lastName").removeClass("border-danger-guay");
            $("#lastName2").removeClass("border-danger-guay");
        }
        if (email.length === 0) {
            if (phone.length === 0) {
                $("#email").addClass("border-danger-guay");
                $("#phone").addClass("border-danger-guay");
                $("#email2").addClass("border-danger-guay");
                $("#phone2").addClass("border-danger-guay");
            } else {
                form = form + 1;
                $("#email").removeClass("border-danger-guay");
                $("#phone").removeClass("border-danger-guay");
                $("#email2").removeClass("border-danger-guay");
                $("#phone2").removeClass("border-danger-guay");
            }
        } else {
            form = form + 1;
        }
        if (phone.length === 0) {
            if (email.length === 0) {
                $("#email").addClass("border-danger-guay");
                $("#phone").addClass("border-danger-guay");
                $("#email2").addClass("border-danger-guay");
                $("#phone2").addClass("border-danger-guay");
            } else {
                form = form + 1;
                $("#email").removeClass("border-danger-guay");
                $("#phone").removeClass("border-danger-guay");
                $("#email2").removeClass("border-danger-guay");
                $("#phone2").removeClass("border-danger-guay");
            }
        } else {
            form = form + 1;
        }
        if (rolId === "0" || rolId === 0) {
            $("#rolId").addClass("border-danger-guay");
        } else {
            if (parseInt(rolId) >= 6) {
                if (selected.length > 0) {
                    form = form + 1;
                    $("#rolId").removeClass("border-danger-guay");
                } else {
                    $("#rolId").addClass("border-danger-guay");
                    toastr.info('Selecciona al menos 1 centro de trabajo');
                }
                /* if (ListBranch.find(centro => centro.checked === true)) {
                    form = form + 1;
                } else {
                    if (ListBranch.length > 0) {
                        $("#rolId").addClass("border-danger-guay");
                        toastr.info('Selecciona al menos 1 centro de trabajo');
                    } else {
                        form = form + 1;
                    }
                } */
            } else {
                form = form + 1;
                $("#rolId").removeClass("border-danger-guay");
            }
        }
        if (form >= 5) {
            return true;
        } else {
            return false;
        }
    }

    const submitNewUser = e => {
        e.preventDefault();
        try {
            if (validateForm() === true) {
                /* let string = '';
                ListBranch.forEach(branch => {
                    if (branch.checked === true) {
                        string += branch.branchEntId + ','
                    }
                }); */
                let branchesList = ''
                selected.forEach(branch => {
                    branchesList += branch.value + ','
                })
                let updateData = {
                    ...newUser,
                    enterpriseId: getJwt("enterprise"),
                    rolId: parseInt(newUser.rolId),
                    branchs: branchesList === '' ? '0' : branchesList
                }
                let formData = new FormData();
                formData.append("request", JSON.stringify(updateData));
                setLoading(true);
                setRegisterUserRol(formData).then((result) => {
                    switch (result?.code) {
                        case 1:
                            let idEnterprise = {
                                idEnterprise: getJwt("enterprise"),
                            }
                            let formData2 = new FormData();
                            formData2.append("request", JSON.stringify(idEnterprise));
                            getRoleAssignment(formData2).then((result) => {
                                //updateDataUsers(result);
                                updateListUsers(result.listUsers);
                            });
                            toastr.success('Se registró correctamente.', '¡Exito!');
                            setLoading(false);
                            break;

                        case 1001:
                            toastr.info('Selecciona al menos 1 centro de trabajo para el rol de visualizador');
                            setLoading(false);
                            break;

                        default:
                            toastr.error('Hubo un problema. No se registró correctamente.', '¡Ooops!');
                            setLoading(false);
                            break;
                    }
                });
                $("#registro").hide();
                updateNewUser({
                    name: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    rolId: 0
                });
                setSelected([])
                /* let array = [...ListBranch];
                array = array.map(centro => {
                    return { ...centro, checked: false }
                })
                setListBranch(array); */
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setDataInModal = (cell, row, rowIndex) => {
        try {
            updateNewUser({
                name: row.name,
                lastName: row.lastName,
                email: row.email,
                phone: row.phone,
                rolId: row.rolId
            });
            /* let centros = [...ListBranch];
            centros = centros.map(centro => {
                if (row.listBranch.find(idBranch => idBranch === centro.branchEntId)) {
                    return { ...centro, checked: true }
                } else {
                    return { ...centro }
                }
            });
            setListBranch(centros); */
            const getBranchesList = selectOptions.filter(branch => row.listBranch.indexOf(branch.value) !== -1)
            setSelected(getBranchesList)
            setIdUser(cell);
        } catch (error) {
            console.log(error);
        }
    }

    const changeRole = (e) => {
        e.preventDefault();
        try {
            /* let string = '';
            ListBranch.forEach(branch => {
                if (branch.checked === true) {
                    string += branch.branchEntId + ','
                }
            }); */
            let branchesList = ''
            selected.forEach(branch => {
                branchesList += branch.value + ','
            })
            let setRol = {
                idEnterprise: getJwt("enterprise"),
                idUser: idUser,
                idRole: parseInt(newUser.rolId),
                name: name,
                lastName: lastName,
                email: email === "" ? " " : email,
                phone: phone === "" ? " " : phone,
                branchs: branchesList === '' ? '0' : branchesList
            }
            let formData = new FormData();
            formData.append("request", JSON.stringify(setRol));
            setLoading(true);
            setChangeRole(formData).then((result) => {
                switch (result?.code) {
                    case 1:
                        let idEnterprise = {
                            idEnterprise: getJwt("enterprise"),
                        }
                        let formData2 = new FormData();
                        formData2.append("request", JSON.stringify(idEnterprise));
                        getRoleAssignment(formData2).then((result) => {
                            //updateDataUsers(result);
                            updateListUsers(result.listUsers);
                        });
                        toastr.success('Se actualizaron los cambios correctamente.', '¡Exito!');
                        setLoading(false);
                        break;

                    case 1001:
                        toastr.error('Selecciona al menos 1 centro de trabajo para el rol de visualizador');
                        setLoading(false);
                        break;

                    default:
                        toastr.error('Hubo un problema. No se cambió el rol correctamente.', '¡Ooops!');
                        setLoading(false);
                        break;
                }
            });
            $("#update").hide();
            $("#update-2").hide();
            updateNewUser({
                name: '',
                lastName: '',
                email: '',
                phone: '',
                rolId: 0
            });
            setSelected([])
            /* let array = [...ListBranch];
            array = array.map(centro => {
                return { ...centro, checked: false }
            })
            setListBranch(array); */
        } catch (error) {
            console.log(error);
        }
    }

    const showConfirmationMessage = e => {
        e.preventDefault();
        if (validateForm() === true) {
            $('#update').hide();
            $('#update-2').show();
        }
    }

    const deleteCollaborator = (cell, row, rowIndex) => {
        try {
            Swal.fire({
                title: '<p class="text-white mb-2">¿Deseas Continuar?</p>',
                text: "Una vez eliminado el registro, no podrá recuperarse",
                showCloseButton: true,
                showCancelButton: true,
                focusCancel: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: "Si, Eliminar",
                reverseButtons: true,
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    let dataDelete = {
                        idEnterprise: getJwt("enterprise"),
                        idUser: cell,
                    }
                    let formData = new FormData();
                    formData.append("request", JSON.stringify(dataDelete));
                    setLoading(true);
                    setDisableRoleAssignment(formData).then((result) => {
                        if (result.code === 1) {
                            let idEnterprise = {
                                idEnterprise: getJwt("enterprise"),
                            }
                            let formData2 = new FormData();
                            formData2.append("request", JSON.stringify(idEnterprise));
                            getRoleAssignment(formData2).then((result) => {
                                //updateDataUsers(result);
                                updateListUsers(result.listUsers);
                            });
                            toastr.success('Se eliminó correctamente.', '¡Exito!');
                            setLoading(false);
                        } else {
                            toastr.error('Hubo un problema. No se eliminó correctamente.', '¡Ooops!');
                            setLoading(false);
                        }
                    });
                    updateNewUser({
                        name: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        rolId: 0
                    });
                }
            });
            $(".swal2-title").addClass("bg-header-swal");
            $(".swal2-actions").addClass("w-100 justify-content-around");
            $(".swal2-confirm").removeClass("swal2-styled");
            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
            $(".swal2-cancel").removeClass("swal2-styled");
            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
        } catch (error) {
            console.log(error);
        }
    }

    const cleanData = (e) => {
        e.preventDefault();
        try {
            updateNewUser({
                name: '',
                lastName: '',
                email: '',
                phone: '',
                rolId: 0
            });
            setSelected([])
            /* let array = [...ListBranch];
            array = array.map(centro => {
                return { ...centro, checked: false }
            })
            setListBranch(array); */
        } catch (error) {
            console.log(error);
        }
    }

    const setIcons = (cell, row, enumObject, rowIndex) => {
        if (userRol === 2) {
            if (row.userId !== parseInt(getJwt("jtw"))) {
                return (
                    <div>
                        <i onClick={() =>
                            deleteCollaborator(cell, row, rowIndex)}
                            className='ft ft-trash-2 icon-medium-size text-blue pointer' ></i>
                        <i onClick={() =>
                            setDataInModal(cell, row, rowIndex)}
                            className='ft ft-edit-2 icon-medium-size text-blue pointer ml-1 mr-1'
                            data-toggle="modal"
                            data-target="#update" ></i>
                    </div>
                )
            } else {
                return (
                    <div>
                        <i className='ft ft-trash-2 icon-medium-size text-base pointer' ></i>
                        <i className='ft ft-edit-2 icon-medium-size text-base pointer ml-1 mr-1' ></i>
                    </div>
                )
            }
        }
        if (userRol === 3) {
            if (row.userId !== parseInt(getJwt("jtw")) && row.rolId !== 2) {
                return (
                    <div>
                        <i onClick={() =>
                            deleteCollaborator(cell, row, rowIndex)}
                            className='ft ft-trash-2 icon-medium-size text-blue pointer' ></i>
                        <i onClick={() =>
                            setDataInModal(cell, row, rowIndex)}
                            className='ft ft-edit-2 icon-medium-size text-blue pointer ml-1 mr-1'
                            data-toggle="modal"
                            data-target="#update" ></i>
                    </div>
                )
            } else {
                return (
                    <div>
                        <i className='ft ft-trash-2 icon-medium-size text-base pointer' ></i>
                        <i className='ft ft-edit-2 icon-medium-size text-base pointer ml-1 mr-1' ></i>
                    </div>
                )
            }
        }
        if (userRol > 6) {
            if (row.userId !== parseInt(getJwt("jtw")) && row.rolId > 6 && step_1 === 1) {
                return (
                    <div>
                        <i onClick={() =>
                            deleteCollaborator(cell, row, rowIndex)}
                            className='ft ft-trash-2 icon-medium-size text-blue pointer' ></i>
                        <i onClick={() =>
                            setDataInModal(cell, row, rowIndex)}
                            className='ft ft-edit-2 icon-medium-size text-blue pointer ml-1 mr-1'
                            data-toggle="modal"
                            data-target="#update" ></i>
                    </div>
                )
            } else {
                return (
                    <div>
                        <i className='ft ft-trash-2 icon-medium-size text-base pointer' ></i>
                        <i className='ft ft-edit-2 icon-medium-size text-base pointer ml-1 mr-1' ></i>
                    </div>
                )
            }
        }
    }

    const options = {
        sizePerPage: 10,  // Showing 5 for the size per page as default
        hideSizePerPage: true
    };

    const getRolesList = (rol, description) => {
        if (userRol === 3) {
            if (rol !== 2 && rol !== 4) {
                return (
                    <option
                        key={rol}
                        value={rol}
                    >
                        {description}
                    </option>
                )
            }
        } else {
            if (rol !== 4) {
                return (
                    <option
                        key={rol}
                        value={rol}
                    >
                        {description}
                    </option>
                )
            }
        }
    }

    /* function seletectedAll() {
        if (ListBranch.find(branch => branch.checked === undefined || branch.checked === false)) {
            return false;
        } else {
            return true;
        }
    } */
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row"></div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                        <span className="font-medium-5">Administración de Usuarios</span>
                    </div>
                    <div className="col-sx-11 col-sm-11 col-md-10 col-lg-2"></div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                        <div className="row">
                            <div></div>
                            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 d-flex mx-auto px-0">
                                <div className="mr-auto mt-1 d-flex align-items-center">
                                    <span className="align-middle text-base font-weight-bold font-h f-1-rem">Roles y usuarios</span>
                                </div>
                                <div className="mt-1">
                                    <img className="icon-img-35 ml-1 pointer" src="./../img/imagesEmpresasPanel/iconos_guay_inicio-05.png" alt="img-guay" data-toggle="modal" data-target="#helpModal" />
                                    {
                                        justRead ? (
                                            step_1 === 1 && (
                                                <img
                                                    className="icon-img-35 ml-1 pointer"
                                                    src="./../img/imagesAdminUsuarios/iconos_guay_inicio-06.png"
                                                    alt="img-guay"
                                                    data-toggle="modal"
                                                    data-target="#registro"
                                                />
                                            )
                                        ) : (
                                            <img
                                                className="icon-img-35 ml-1 pointer"
                                                src="./../img/imagesAdminUsuarios/iconos_guay_inicio-06.png"
                                                alt="img-guay"
                                                data-toggle="modal"
                                                data-target="#registro"
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto mt-1 font-small-1 pl-0 pr-0">
                                {loading === true
                                    ?
                                    (
                                        <LoaderApp />
                                    )
                                    :
                                    (
                                        <BootstrapTable
                                            data={listUsers}
                                            striped={true}
                                            hover={true}
                                            pagination={true}
                                            tableStyle={{ border: 'none' }}
                                            options={options}
                                            trClassName='tr-data-table'
                                        >
                                            <TableHeaderColumn
                                                dataField='userId'
                                                isKey
                                                width='100'
                                                thStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#6b6f82',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                tdStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#bababa',
                                                    fontSize: '1rem',
                                                    paddingLeft: '0px',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                dataFormat={setIcons} ></TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="name"
                                                thStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#6b6f82',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                tdStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#bababa',
                                                    fontSize: '1rem',
                                                    paddingLeft: '0px',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                dataSort={true}>Nombre</TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="lastName"
                                                thStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#6b6f82',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                tdStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#bababa',
                                                    fontSize: '1rem',
                                                    paddingLeft: '0px',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                dataSort={true}>Apellidos</TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="email"
                                                thStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#6b6f82',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                tdStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#bababa',
                                                    fontSize: '1rem',
                                                    paddingLeft: '0px',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                dataSort={true}>Email</TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="description"
                                                width='125'
                                                thStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#6b6f82',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                tdStyle={{
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: '2px solid #D7D7D7',
                                                    backgroundColor: 'transparent',
                                                    color: '#bababa',
                                                    fontSize: '1rem',
                                                    paddingLeft: '0px',
                                                    paddingTop: '0.5rem',
                                                    paddingBottom: '0.5rem'
                                                }}
                                                dataSort={true}>Rol</TableHeaderColumn>
                                        </BootstrapTable>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-sx-11 col-sm-11 col-md-10 col-lg-8 mx-auto mt-3">

                        {/*MODAL*/}
                        {/*<!-- The Modal -->*/}
                        <div className="modal" id="registro" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content overflow_visible" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }} /* style={{ borderRadius: '3.35rem', border: '1px solid gray' }} */>

                                    {/*<!-- Modal body -->*/}
                                    <div className="modal-body overflow_visible pb-5 scrollbar-style">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                        <h1 className="font-weight-bold">{sessionStorage.getItem('enterpriseName')}</h1>
                                                    </div>
                                                    <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                        <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                        <span className="align-middle font-h ml-1 font-weight-bold" style={{ fontSize: '1.5rem' }} >Nuevo usuario</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={submitNewUser}>
                                                <div className="col-12 mt-1">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="text-base">Nombre (s)</label>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control"
                                                                    placeholder="Nombre"
                                                                    id="name"
                                                                    maxLength="100"
                                                                    onChange={updateState}
                                                                    value={name} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="text-base">Apellidos</label>
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    maxLength="100"
                                                                    className="form-control"
                                                                    placeholder="Apellidos"
                                                                    id="lastName"
                                                                    onChange={updateState}
                                                                    value={lastName} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="text-base">Correo electrónico</label>
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                    placeholder="Correo electrónico"
                                                                    id="email"
                                                                    maxLength="100"
                                                                    onChange={updateState}
                                                                    value={email} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="text-base">Teléfono móvil</label>
                                                                <input
                                                                    type="number"
                                                                    name="phone"
                                                                    className="form-control"
                                                                    placeholder="Teléfono"
                                                                    id="phone"
                                                                    onChange={updateState}
                                                                    value={phone}
                                                                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="text-base">Rol</label>
                                                                <select
                                                                    className="form-control fake-placeholder"
                                                                    name="rolId"
                                                                    id="rolId"
                                                                    onChange={updateState}
                                                                    value={rolId}>
                                                                    <option value="0">--Seleccione--</option>
                                                                    {roles.map(rol =>
                                                                        rol.rolId !== 1 && rol.rolId !== 5 ?
                                                                            getRolesList(rol.idPerzonalizedProfile, rol.description)
                                                                            : null
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            parseInt(rolId) >= 6 && (
                                                                <div className="col-12 mb-3">
                                                                    <MultiSelect
                                                                        className="multi-select"
                                                                        options={selectOptions}
                                                                        value={selected}
                                                                        onChange={e => {
                                                                            setSelected(e)
                                                                        }}
                                                                        overrideStrings={{
                                                                            selectSomeItems: "Selecciona",
                                                                            allItemsAreSelected: "Todos los centros de trabajo",
                                                                            selectAll: "Todos los centros de trabajo",
                                                                            search: "Buscar centro de trabajo",
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {/* {
                                                            parseInt(rolId) >= 6 &&
                                                            <div className='col-12 mb-2 d-flex'>
                                                                <button
                                                                    className="btn-cancelar-usuario pointer"
                                                                    type='button'
                                                                    onClick={() => { $('#branchesContainer').removeClass('div_branches-hidden'); $('#branchesContainer').addClass('div_branches-visible'); }}>Mostrar centros de trabajo</button>

                                                                <div id='branchesContainer' className='div_branches-hidden'>
                                                                    <label className='d-flex justify-content-end pointer' onClick={() => { $('#branchesContainer').removeClass('div_branches-visible'); $('#branchesContainer').addClass('div_branches-hidden'); }}>X</label>
                                                                    <div className='div_branches-data'>
                                                                        <label className="text-base">Centros de trabajo asignados a este usuarios</label>
                                                                        <div className="col form-check">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                checked={seletectedAll()}
                                                                                onChange={({ target }) => {
                                                                                    console.log(target.checked);
                                                                                    let array = [...ListBranch];
                                                                                    if (target.checked) {
                                                                                        array = array.map(centro => {
                                                                                            return { ...centro, checked: true }
                                                                                        })
                                                                                    } else {
                                                                                        array = array.map(centro => {
                                                                                            return { ...centro, checked: true }
                                                                                        })
                                                                                    }
                                                                                    setListBranch(array);
                                                                                }} />
                                                                            <label className="form-check-label">
                                                                                Todos los centros de trabajo
                                                                            </label>
                                                                        </div>
                                                                        <div className='col row'>
                                                                            {ListBranch.map(branch => (
                                                                                <div className="col form-check" key={branch.branchEntId}>
                                                                                    <input className="form-check-input" type="checkbox" value={branch.branchEntId} checked={branch.checked} onChange={({ target }) => {
                                                                                        let array = [...ListBranch];
                                                                                        array = array.map(centro => {
                                                                                            if (centro.branchEntId === parseInt(target.value)) {
                                                                                                if (centro.checked === undefined) { // si no existe la key la asignamos como true
                                                                                                    return { ...centro, checked: true }
                                                                                                } else {
                                                                                                    return { ...centro, checked: !centro.checked } // si existe la key la convertimos a su contrario
                                                                                                }
                                                                                            } else {
                                                                                                return { ...centro }
                                                                                            }
                                                                                        })
                                                                                        setListBranch(array);
                                                                                    }} />
                                                                                    <label className="form-check-label">
                                                                                        {branch.description}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-around">
                                                    <button
                                                        className="btn-cancelar-usuario pointer"
                                                        onClick={(e) => { $('#registro').hide(); cleanData(e); }}>Cancelar</button>
                                                    <button type="submit" className="btn-agregar-usuario pointer">Agregar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*<!-- The Modal 2 -->*/}
                        <div className="modal" id="update" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content overflow_visible" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }} /* style={{ borderRadius: '3.35rem', border: '2px solid gray' }} */>

                                    {/*<!-- Modal body -->*/}
                                    <div className="modal-body overflow_visible pb-5 scrollbar-style">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                        <h1 className="font-weight-bold">{sessionStorage.getItem('enterpriseName')}</h1>
                                                    </div>
                                                    <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                                        <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" />
                                                        <span className="align-middle font-h ml-1 font-weight-bold" style={{ fontSize: '1.5rem' }} >Editar Usuario de sistema</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={showConfirmationMessage}>
                                                <div className="col-12 mt-1">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="text-base">Nombre (s)</label>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control"
                                                                    placeholder="Nombre"
                                                                    id="name2"
                                                                    maxLength="100"
                                                                    value={name}
                                                                    onChange={updateState} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="text-base">Apellidos</label>
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    className="form-control"
                                                                    placeholder="Apellidos"
                                                                    id="lastName2"
                                                                    maxLength="100"
                                                                    value={lastName}
                                                                    onChange={updateState} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="text-base">Correo electrónico</label>
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                    placeholder="Correo electrónico"
                                                                    id="email2"
                                                                    maxLength="100"
                                                                    value={email}
                                                                    readOnly={true}
                                                                    onChange={updateState} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="text-base">Teléfono móvil</label>
                                                                <input
                                                                    type="number"
                                                                    name="phone"
                                                                    className="form-control"
                                                                    placeholder="Teléfono"
                                                                    id="phone2"
                                                                    value={phone}
                                                                    onChange={updateState} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="text-base">Rol</label>
                                                                <select
                                                                    className="form-control"
                                                                    name="rolId"
                                                                    id="rolId"
                                                                    onChange={updateState}
                                                                    value={rolId}>
                                                                    {roles.map(rol =>
                                                                        rol.rolId !== 1 && rol.rolId !== 5 ?
                                                                            getRolesList(rol.idPerzonalizedProfile, rol.description)
                                                                            : null
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            parseInt(rolId) >= 6 && (
                                                                <div className="col-12 mb-3">
                                                                    <MultiSelect
                                                                        className="multi-select"
                                                                        options={selectOptions}
                                                                        value={selected}
                                                                        onChange={e => {
                                                                            setSelected(e)
                                                                        }}
                                                                        overrideStrings={{
                                                                            selectSomeItems: "Selecciona",
                                                                            allItemsAreSelected: "Todos los centros de trabajo",
                                                                            selectAll: "Todos los centros de trabajo",
                                                                            search: "Buscar centro de trabajo",
                                                                            placeholder: 'Buscar'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {/* {
                                                            parseInt(rolId) >= 6 &&
                                                            <div className='col-12 mb-2 d-flex'>
                                                                <button
                                                                    className="btn-cancelar-usuario pointer"
                                                                    type='button'
                                                                    onClick={() => { $('#branchesContainer1').removeClass('div_branches-hidden'); $('#branchesContainer1').addClass('div_branches-visible'); }}>Mostrar centros de trabajo</button>

                                                                <div id='branchesContainer1' className='div_branches-hidden'>
                                                                    <label className='d-flex justify-content-end pointer' onClick={() => { $('#branchesContainer1').removeClass('div_branches-visible'); $('#branchesContainer1').addClass('div_branches-hidden'); }}>X</label>
                                                                    <div className='div_branches-data'>
                                                                        <label className="text-base">Centros de trabajo asignados a este usuarios</label>
                                                                        <div className="col form-check">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                checked={seletectedAll()}
                                                                                onChange={({ target }) => {
                                                                                    console.log(target.checked);
                                                                                    let array = [...ListBranch];
                                                                                    if (target.checked) {
                                                                                        array = array.map(centro => {
                                                                                            return { ...centro, checked: true }
                                                                                        })
                                                                                    } else {
                                                                                        array = array.map(centro => {
                                                                                            return { ...centro, checked: true }
                                                                                        })
                                                                                    }
                                                                                    setListBranch(array);
                                                                                }} />
                                                                            <label className="form-check-label">
                                                                                Todos los centros de trabajo
                                                                            </label>
                                                                        </div>
                                                                        <div className='row col'>
                                                                            {ListBranch.map(branch => (
                                                                                <div className="col form-check" key={branch.branchEntId}>
                                                                                    <input className="form-check-input" type="checkbox" value={branch.branchEntId} checked={branch.checked} onChange={({ target }) => {
                                                                                        let array = [...ListBranch];
                                                                                        array = array.map(centro => {
                                                                                            if (centro.branchEntId === parseInt(target.value)) {
                                                                                                if (centro.checked === undefined) { // si no existe la key la asignamos como true
                                                                                                    return { ...centro, checked: true }
                                                                                                } else {
                                                                                                    return { ...centro, checked: !centro.checked } // si existe la key la convertimos a su contrario
                                                                                                }
                                                                                            } else {
                                                                                                return { ...centro }
                                                                                            }
                                                                                        })
                                                                                        setListBranch(array);
                                                                                    }} />
                                                                                    <label className="form-check-label">
                                                                                        {branch.description}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-around">
                                                    <button
                                                        className="btn-cancelar-usuario pointer"
                                                        onClick={(e) => { $('#update').hide(); cleanData(e); }}>Atrás</button>
                                                    <button
                                                        type="submit"
                                                        className="btn-agregar-usuario pointer" >Actualizar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*<!-- The Modal 2 -->*/}
                        <div className="modal" id="update-2" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content" style={{ borderRadius: '1.5rem', border: '1px solid gray', overflow: 'auto' }} /* style={{ borderRadius: '3.35rem', border: '2px solid gray' }} */>

                                    {/*<!-- Modal body -->*/}
                                    <div className="modal-body pb-5 scrollbar-style">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                <h1 className="font-weight-bold">{sessionStorage.getItem('enterpriseName')}</h1>
                                            </div>
                                            <div className="col-lg-12 mt-1 d-flex justify-content-center">
                                                <span className="font-medium-1 font-weight-bold text-base">El usuario se actualizará de la siguiente manera:</span>
                                            </div>
                                            <div className="col-lg-12 mt-1">
                                                <div className="row font-medium-1 text-base">
                                                    <div className="col-lg-10 mt-1 mx-auto">
                                                        <div className="row">
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                            </div>
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                                <img className="icon-img-25" src="./../img/imagesAdminUsuarios/Usuario-17.png" alt="img-guay" />
                                                            </div>
                                                            <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap justify-content-start align-content-center">
                                                                <span>{name + " " + lastName}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-10 mt-1 mx-auto">
                                                        <div className="row">
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                            </div>
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                                <img className="icon-img-30x20" src="./../img/imagesAdminUsuarios/Usuario-19.png" alt="img-guay" />
                                                            </div>
                                                            <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                <span>{email}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-10 mt-1 mx-auto">
                                                        <div className="row">
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                            </div>
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                                <img className="icon-img-25" src="./../img/imagesAdminUsuarios/Usuario-21.png" alt="img-guay" />
                                                            </div>
                                                            <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                <span>{phone}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-10 mt-1 mx-auto">
                                                        <div className="row">
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                            </div>
                                                            <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                                <img className="icon-img-25" src="./../img/imagesAdminUsuarios/Usuario-23.png" alt="img-guay" />
                                                            </div>
                                                            <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                <span>{roles.map(rol => parseInt(rolId) === rol.idPerzonalizedProfile ? rol.description : null)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        parseInt(rolId) >= 6 && (
                                                            <div className="col-lg-10 mt-1 mx-auto">
                                                                <div className="row">
                                                                    <div className="col-sx-4 col-sm-4 col-md-4 col-lg-4 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                                        {/* <img className="icon-img-50" src="./../img/imagesAdminUsuarios/iconos_shopy_guay-40.png" alt="img-guay" /> */}
                                                                    </div>
                                                                    <div className="col-sx-8 col-sm-8 col-md-8 col-lg-8 p-0 d-flex flex-wrap align-content-center">
                                                                        <button
                                                                            className="btn-cancelar-usuario pointer"
                                                                            type='button'
                                                                            onClick={() => { $('#branchesContainer2').removeClass('div_branches-hidden'); $('#branchesContainer2').addClass('div_branches-visible'); }}>Mostrar centros de trabajo</button>
                                                                        <div id='branchesContainer2' className='div_branches-hidden'>
                                                                            <label className='d-flex justify-content-end pointer' onClick={() => { $('#branchesContainer2').removeClass('div_branches-visible'); $('#branchesContainer2').addClass('div_branches-hidden'); }}>X</label>
                                                                            <div className='div_branches-data scrollbar-1'>
                                                                                <label className="text-base">Centros de trabajo asignados a este usuarios</label>
                                                                                <ul>
                                                                                    {selected.map(data => (
                                                                                        <li key={data.value}>{data.label}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-12 d-flex justify-content-around mt-2">
                                                <button
                                                    className="btn-cancelar-usuario pointer"
                                                    onClick={() => { $('#update').show(); $('#update-2').hide(); }} >Atrás</button>
                                                <button className="btn-agregar-usuario pointer" onClick={(e) => changeRole(e)} >Confirmar</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*<!-- The Modal 3 -->*/}
                        <div className="modal" id="helpModal" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content" style={{ border: 'none', background: 'transparent' }}>
                                    <div className="modal-header" style={{ border: 'none' }}>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    {/*<!-- Modal body -->*/}
                                    <div className="modal-body" style={{ height: '600px' }}>
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row">
                                                <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-7 mx-auto">
                                                            <div className="card">
                                                                <img className="img-responsive" src="./../img/imagesAdminUsuarios/iconos_guay-59.png" alt="guay-digital" />
                                                                <div className="card-img-overlay d-flex justify-content-center flex-wrap align-content-center">
                                                                    <p className="font-small-2 text-center text-white">Además de las acciones y beneficios de la comunidad
                                                                        guay, ahora puedes agregar permisos al personal que
                                                                        elijas como tu equipo en guay. Conoce los roles que le
                                                                        puedes asignar a los usuarios que registres en el sistema</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div className="col-sx-12 col-sm-12 col-md-6 col-lg-3 mx-auto">
                                                                <img className="img-responsive" src="./../img/imagesAdminUsuarios/iconos_guay-55.png" alt="guay-digital" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="card">
                                                                <img className="img-responsive" src="./../img/imagesAdminUsuarios/iconos_guay-61.png" alt="guay-digital" />
                                                                <div className="card-img-overlay d-flex justify-content-center flex-wrap align-content-center">
                                                                    <p className="font-small-3 text-center text-white font-weight-bold">Administrador</p>
                                                                    <p className="font-small-2 text-center text-white">El usuario gozará de todos los privilegios, es decir, editar
                                                                        los datos de la organización o grupo, gestionar (altas,
                                                                        bajas y cambios) a usuarios y colaboradores incluyendo
                                                                        Centros de Trabajo y Áreas. Además realizará la
                                                                        configuración y seguimiento completo de los servicios
                                                                        contratados.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="card">
                                                                <img className="img-responsive" src="./../img/imagesAdminUsuarios/iconos_guay-61.png" alt="guay-digital" />
                                                                <div className="card-img-overlay d-flex justify-content-center flex-wrap align-content-center">
                                                                    <p className="font-small-3 text-center text-white font-weight-bold">Configurador</p>
                                                                    <p className="font-small-2 text-center text-white">Este usuario tendrá la facultad de administrar colaboradores
                                                                        junto con los Centros de Trabajo y áreas, también la
                                                                        configuración y seguimiento de los servicios contratados en
                                                                        la organización.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-6 mx-auto">
                                                            <div className="card" style={{ width: '300px' }}>
                                                                <img className="img-responsive" src="./../img/imagesAdminUsuarios/iconos_guay-61.png" alt="guay-digital" />
                                                                <div className="card-img-overlay d-flex justify-content-center flex-wrap align-content-center">
                                                                    <p className="font-small-3 text-center text-white font-weight-bold">Colaborador</p>
                                                                    <p className="font-small-2 text-center text-white">Será el encargado de nutrir de información a guay
                                                                        mediante los cuestionarios que correspondan a cada
                                                                        servicio y contará con diversos beneficios tales como la
                                                                        asistencia guay. Para facilitar y tener mayor control de tus
                                                                        colaboradores dentro de la organización encontrarás la
                                                                        sección específica para darlos de alta y darle seguimiento.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdministracionUsuarios;
