import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from "jquery";

const HistoryCollab = ({ listDeleteCollaborators, options_2, rowStyleFormat, selectRow_2, setDataStatus_2, tdStyleFormat, thStyleFormat, setData, setCategoryEntId, setBranchEntId, setAreaEntId, setTeleasistencia, selectedRows_2, handleCollaboratorStatus, setShowHistory, justRead }) => {
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay rounded-top mb-5">
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex justify-content-center flex-wrap align-content-center">
                    <span className="font-medium-2">Colaboradores eliminados</span>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex justify-content-end flex-wrap align-content-center">
                    {selectedRows_2.length > 0
                        ?
                        (
                            <button
                                className="btn btn-pink btn-sm pointer"
                                onClick={() => $('#history_2').show()} >Reactivar</button>
                        )
                        :
                        (null)
                    }
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                    <BootstrapTable
                        data={listDeleteCollaborators}
                        condensed
                        search
                        pagination={true}
                        tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                        trClassName='tr-data-table table-checkbox'
                        options={options_2}
                        tableHeaderClass='table-checkbox'
                        searchPlaceholder='Buscar...'
                        trStyle={rowStyleFormat()}
                        selectRow={justRead ? {} : selectRow_2}
                    >
                        <TableHeaderColumn
                            dataField="collaboratorId"
                            dataAlign="center"
                            width="6%"
                            dataFormat={setDataStatus_2}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            isKey
                            dataSort>Estado</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="namesCollab"
                            dataAlign="center"
                            dataFormat={setData}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            dataSort >{"Nombre(s)"}</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="lastName"
                            dataAlign="center"
                            dataFormat={setData}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            dataSort >Apellidos</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataAlign="center"
                            dataFormat={setData}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()} >Email</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="categoryEntId"
                            dataAlign="center"
                            dataFormat={setCategoryEntId}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            dataSort >Cargo</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="areaEntId"
                            dataAlign="center"
                            dataFormat={setAreaEntId}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            dataSort >Área</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="branchEntId"
                            dataAlign="center"
                            dataFormat={setBranchEntId}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            dataSort >Centro de trabajo</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="customA"
                            dataAlign="center"
                            dataFormat={setTeleasistencia}
                            tdStyle={tdStyleFormat()}
                            thStyle={thStyleFormat()}
                            dataSort >Teleasistencia</TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                    <button
                        className="btn btn-pink btn-sm pointer float-left"
                        onClick={() => setShowHistory(0)} >Regresar</button>
                </div>
            </div>
            {/*<!-- The Modal -->*/}
            <div
                className="modal"
                id="history_2"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                data-backdrop="false">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{
                            borderRadius: '1.5rem',
                            border: '1px solid gray',
                            overflow: 'auto'
                        }}>
                        {/*<!-- Modal body -->*/}
                        <div className="modal-body scrollbar-style">
                            <div className="col-lg-12 mb-1">
                                <div className="row">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 text-center">
                                        <span className="font-medium-2">Se reactivarán los {selectedRows_2.length} colaboradores seleccionados</span>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                        <button
                                            className="btn btn-pink btn-sm pointer float-left"
                                            onClick={() => $('#history_2').hide()} >Regresar</button>
                                        <button
                                            className="btn btn-pink btn-sm pointer float-right"
                                            onClick={() => handleCollaboratorStatus(3)} >Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- The Modal -->*/}
        </div>
    )
}

export default HistoryCollab;
