import React from 'react';

const NoConsentMessage = () => {
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <div className="row font-medium-5 text-base-guay font-weight-bold">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                    <i
                        className="ft ft-check-circle"
                        style={{ fontSize: '150px', color: 'grey' }} />
                </div>
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center text-center mt-5">
                    <span>Agradecemos el tiempo dedicado hasta el momento, en breve recibirás un correo con el cierre del proceso.</span>
                </div>
            </div>
        </div>
    )
}

export default NoConsentMessage;
