import React from "react";
import $ from "jquery";
/*
Render Mood Form
*/
const Mood = props =>{
    const updateResponse1 = (event)=>{
        try {
            let $currentTarget = $(event.currentTarget);
            let dayData = $currentTarget.attr("data-day");
            let question= $currentTarget.attr("data-question");
            let res= $currentTarget.attr("data-res");
            $(".icon-res[data-question='"+question+"'][data-day='"+dayData+"']").addClass("text-disabled");
            $(".icon-res[data-question='"+question+"'][data-day='"+dayData+"']").removeClass("result");
            $(".icon-res[data-res='"+res+"'][data-question='"+question+"'][data-day='"+dayData+"']").removeClass("text-disabled");
            $(".icon-res[data-res='"+res+"'][data-question='"+question+"'][data-day='"+dayData+"']").addClass("result");
            
        } catch (error) {
            console.log(error);
        }
    }

    return(

        <div>
            <ul className="nav nav-tabs nav-justified" id="mood">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-day="1" id="active-tab" data-toggle="tab" href="#m-dia-1" aria-controls="active" aria-expanded="true">Día 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link disabled" data-day="2" id="link-tab" data-toggle="tab" href="#m-dia-2" aria-controls="link" aria-expanded="false">Día 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link disabled" data-day="3" id="link-tab" data-toggle="tab" href="#m-dia-3" aria-controls="link" aria-expanded="false">Día 3</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link disabled" data-day="4" id="link-tab" data-toggle="tab" href="#m-dia-4" aria-controls="link" aria-expanded="false">Día 4</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link disabled" data-day="5" id="link-tab" data-toggle="tab" href="#m-dia-5" aria-controls="link" aria-expanded="false">Día 5</a>
                                    </li>
             </ul>
             <div className="tab-content px-1 pt-1">
             <div role="tabpanel" className="tab-pane active dia1" id="m-dia-1" aria-labelledby="active-tab">
             <p>Conteste en forma completa todos los rubros por día bajo la siguiente escala:</p> 
                                <div className="row mt-2 text-center">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2 "> {props.options[0].description }</div>
                                    <div className="col-md-2 "> {props.options[1].description }</div>
                                    <div className="col-md-2 "> {props.options[2].description}</div>
                                    <div className="col-md-2 "> {props.options[3].description}</div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-2">
                                    <div className="col-md-4 text-left">
                                    {props.questions[4].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[4].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[4].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[4].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[4].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                    
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[5].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[5].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[5].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[5].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[5].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[6].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[6].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[6].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[6].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[6].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[7].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[7].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[7].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[7].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[7].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[8].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[8].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[8].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[8].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[8].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[9].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[9].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[9].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[9].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[9].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[10].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[10].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[10].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[10].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[10].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[11].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[11].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[11].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[11].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[11].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[12].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[12].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[12].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[12].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[12].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[13].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[13].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[13].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[13].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="1" data-question={props.questions[13].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>



        </div>
             <div className="tab-pane" id="m-dia-2" role="tabpanel" aria-labelledby="link-tab">
                <p>Conteste en forma completa todos los rubros por día bajo la siguiente escala:</p> 
                                <div className="row mt-2 text-center">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2 "> {props.options[0].description }</div>
                                    <div className="col-md-2 "> {props.options[1].description }</div>
                                    <div className="col-md-2 "> {props.options[2].description}</div>
                                    <div className="col-md-2 "> {props.options[3].description}</div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-2">
                                    <div className="col-md-4 text-left">
                                    {props.questions[4].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[4].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[4].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[4].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[4].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                    
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[5].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[5].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[5].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[5].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[5].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[6].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[6].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[6].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[6].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[6].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[7].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[7].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[7].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[7].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[7].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[8].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[8].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[8].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[8].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[8].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[9].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[9].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[9].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[9].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[9].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[10].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[10].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[10].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[10].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[10].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[11].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[11].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[11].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[11].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[11].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[12].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[12].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[12].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[12].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[12].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[13].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[13].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[13].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[13].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="2" data-question={props.questions[13].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>

                        </div>
             <div className="tab-pane" id="m-dia-3">
             <p>Conteste en forma completa todos los rubros por día bajo la siguiente escala:</p> 
                                <div className="row mt-2 text-center">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2 "> {props.options[0].description }</div>
                                    <div className="col-md-2 "> {props.options[1].description }</div>
                                    <div className="col-md-2 "> {props.options[2].description}</div>
                                    <div className="col-md-2 "> {props.options[3].description}</div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-2">
                                    <div className="col-md-4 text-left">
                                    {props.questions[4].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[4].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[4].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[4].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[4].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                    
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[5].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[5].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[5].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[5].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[5].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[6].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[6].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[6].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[6].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[6].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[7].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[7].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[7].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[7].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[7].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[8].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[8].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[8].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[8].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[8].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[9].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[9].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[9].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[9].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[9].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[10].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[10].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[10].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[10].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[10].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[11].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[11].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[11].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[11].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[11].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[12].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[12].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[12].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[12].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[12].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[13].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[13].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[13].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[13].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="3" data-question={props.questions[13].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
             </div>   
             <div className="tab-pane" id="m-dia-4">
            <p>Conteste en forma completa todos los rubros por día bajo la siguiente escala:</p> 
                                <div className="row mt-2 text-center">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2 "> {props.options[0].description }</div>
                                    <div className="col-md-2 "> {props.options[1].description }</div>
                                    <div className="col-md-2 "> {props.options[2].description}</div>
                                    <div className="col-md-2 "> {props.options[3].description}</div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-2">
                                    <div className="col-md-4 text-left">
                                    {props.questions[4].description}
                                </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[4].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[4].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[4].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[4].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                    
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[5].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[5].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[5].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[5].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[5].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[6].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[6].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[6].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[6].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[6].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[7].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[7].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[7].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[7].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[7].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[8].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4"  data-question={props.questions[8].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[8].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[8].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[8].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[9].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[9].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[9].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[9].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[9].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[10].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[10].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[10].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[10].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[10].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[11].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[11].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[11].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[11].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[11].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[12].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[12].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[12].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[12].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[12].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[13].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[13].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[13].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[13].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="4" data-question={props.questions[13].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>  
            </div>
             <div className="tab-pane" id="m-dia-5">
             <div className="row bs-callout-primary callout-border-left p-1 mt-2">
                                    <div className="col-md-4 text-left">
                                    {props.questions[4].description}
                                </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[4].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[4].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[4].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[4].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                    
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[5].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[5].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[5].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[5].idQuestion} data-res={props.options[2].idResponse} className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[5].idQuestion} data-res={props.options[3].idResponse} className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[6].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[6].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[6].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[6].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[6].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[7].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[7].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[7].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[7].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[7].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[8].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5"  data-question={props.questions[8].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[8].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[8].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[8].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[9].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[9].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[9].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[9].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[9].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[10].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[10].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[10].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[10].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[10].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[11].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[11].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[11].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[11].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[11].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[12].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[12].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[12].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[12].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[12].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>
                                <div className="row bs-callout-primary callout-border-left p-1 mt-1">
                                    <div className="col-md-4 text-left">
                                    {props.questions[13].description}
                                    </div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[13].idQuestion} data-res={props.options[0].idResponse} className="icon-res text-success font-large-2 las la-grin-stars pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[13].idQuestion} data-res={props.options[1].idResponse} className="icon-res text-yellow font-large-2 las la-smile-wink pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[13].idQuestion} data-res={props.options[2].idResponse}className="icon-res text-warning font-large-2 las la-meh pull-up cursor-pointer"></i></div>
                                    <div className="col-md-2 text-center"><i onClick={updateResponse1} data-day="5" data-question={props.questions[13].idQuestion} data-res={props.options[3].idResponse}className="icon-res text-danger font-large-2 las la-frown pull-up cursor-pointer"></i></div>
                                </div>       
            </div>   
        </div>
        </div>



    )
}
export default Mood;