import React from 'react';

export const CollabAdminMenu = ({ rol, goToByLink }) => {
    return (
        <>
            <li className=" nav-item">
                <a href="/inicio-colaborador" onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/inicio-colaborador")}
                    >Pantalla principal</span>
                </a>
            </li>
            {
                rol === '2' ? (
                    <li className=" nav-item">
                        <a href="/inicio-empresas" onClick={(e) => e.preventDefault()}>
                            <span
                                className="menu-title"
                                data-i18n="nav.dash.main"
                                onClick={() => goToByLink("/inicio-empresas")}
                            >Organizaciones / Grupos</span>
                        </a>
                    </li>
                ) : (null)
            }
            <li className=" nav-item">
                <a href="/perfil" onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/perfil")}
                    >Mi perfil</span>
                </a>
            </li>
            <li className=" nav-item">
                <a href="/inicio-administrador" onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/inicio-administrador")}
                    >Administración</span>
                </a>
            </li>
            {/* <li className=" nav-item">
                <a href="/tienda" onClick={(e) => e.preventDefault()}>
                    <span
                        className="menu-title"
                        data-i18n="nav.dash.main"
                        onClick={() => goToByLink("/tienda")}
                    >Tienda guay</span>
                </a>
            </li> */}
        </>
    )
}
