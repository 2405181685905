import axios from "axios";

//Getting collaborators with two parameters: enterprise ID and branch ID
export const getCollaborators = async (enterpriseId, branchId) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "surveyEco/getCollabEntBranch?idEnterprise=" + enterpriseId + "&idBranch=" + branchId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
export const getEnterpriseActive = async () => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/getEnterpriseActive`,
            method: 'GET'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getRolActive = async () => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + `enterprise/getRolActive`,
            method: 'GET'
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};