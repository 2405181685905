// TODO: Tools
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table'
import $ from "jquery";

const Table = ({
    listFichas,
    listPosters,
    listInfografias,
    showTable,
    setUrlShowPdf,
    setShowImage,
}) => {
    // Table Props
    const options = {
        noDataText: 'No se encontraron recursos',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    }

    /**
     * 
     * @param {*} url 
     */
    const previewFile = (url) => {
        setShowImage(url);
        $('#modalImagesViewer').show()
    }

    /**
     * 
     * @param {*} url 
     */
    const previewFilePDF = (url) => {
        setUrlShowPdf(url);
        $('#closeModalViewPdf').show()
    }

    /**
     * 
     * @param {*} url 
     */
    const validateTypeExt = (url) =>{
        let ext = url.split('.').pop()
        ext = ext.toLowerCase()
        return ext
    }

    /**
     * 
     * @param {*} url 
    */
    const validateTypeFile = (url) => {
        let ext = validateTypeExt(url)
        // console.log('ext: ', ext);
        if (ext === 'pdf') {
            previewFilePDF(url)
        }else if(ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'gif'){
            previewFile(url)
        }else if(ext === 'mp3' || ext === 'mp4'){
            previewFilePDF(url)
        }else{
            window.open(url)
        }
    }

    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setIcons = (cell, row, rowIndex) => {
        return (
            <div className='col-12 d-flex justify-content-center'>
                <i
                    className={validateTypeExt(row.url) === 'zip' || validateTypeExt(row.url) === 'rar' ? 'bi bi-download text-blue pointer font-medium-4':'bi bi-eye text-blue pointer font-medium-4'} 
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Descargar'
                    onClick={() => validateTypeFile(row.url)}
                />
            </div>
        )
    }

    const trClassFormat = (row, rowIndex) => {
        // row is the current row data
        return rowIndex % 2 === 0 ? "bg-white-light-giki" : "bg-white";  // return class name.
    }

    const tdStyleFormat = () => {
        return {
            padding: '10px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            height: '10px',
            border: 'none',
            verticalAlign: 'middle',
        }
    }

    const thStyleFormat = () => {
        return {
            backgroundColor: '#FFF',
            border: 'none',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    }
    return (
        <div className='col-10 mx-auto py-3'>
            <BootstrapTable
                data={showTable === 0 ? (listFichas) : (showTable === 1 ? (listPosters) : (listInfografias))}
                pagination={true}
                options={options}
                bordered={false}
                trClassName={trClassFormat}
                search
                searchPlaceholder='Buscar'
            >
                <TableHeaderColumn
                    dataField='idResource'
                    isKey
                    dataAlign='center'
                    tdStyle={tdStyleFormat()}
                    thStyle={thStyleFormat()}
                    hidden
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField='orderNumber'
                    dataAlign='center'
                    tdStyle={tdStyleFormat()}
                    thStyle={thStyleFormat()}
                    width='10%'
                >Orden de envío</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='title'
                    dataAlign='center'
                    tdStyle={tdStyleFormat()}
                    thStyle={thStyleFormat()}
                    dataSort={true}
                >Nombre</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='description'
                    dataAlign='center'
                    tdStyle={tdStyleFormat()}
                    thStyle={thStyleFormat()}
                    width='50%'
                    dataSort={true}
                >Descripción</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='idResource'
                    dataAlign='center'
                    tdStyle={tdStyleFormat()}
                    thStyle={thStyleFormat()}
                    dataFormat={setIcons}
                    width='10%'
                ></TableHeaderColumn>
            </BootstrapTable>
        </div>
    )
}

export default Table
