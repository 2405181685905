import React from 'react'
import $ from 'jquery';
import Contacto from './Contacto';
import Leyenda from './Leyenda';
import Pasos from './Pasos';

const PlanAmerican = () => {
    const handleButtonSelected = id => {
        switch (id) {
            case 'btnAccidentes':
                if($('#btnAccidentes').hasClass('btn-selected')){
                    // cambiar estilo de btn
                    $('#btnAccidentes').removeClass('btn-blue-4')
                    $('#btnAccidentes').removeClass('btn-selected')
                    $('#btnAccidentes').addClass('btn-blue-2')
                    // cambiar estilo de icon btn
                    $('#iconbtnAccidentes').addClass('la-angle-right')
                    $('#iconbtnAccidentes').addClass('text-white')
                    $('#iconbtnAccidentes').removeClass('la-angle-down')
                    $('#iconbtnAccidentes').removeClass('text-blue')

                    
                }else{
                    // El btn no estaba seleccionado y se marca como seleccionado
                    //  estilo a btn
                    $('#btnAccidentes').addClass('btn-blue-4')
                    $('#btnAccidentes').addClass('btn-selected')
                    // cambiar estilo de icon btn
                    $('#iconbtnAccidentes').removeClass('la-angle-right')
                    $('#iconbtnAccidentes').removeClass('text-white')
                    $('#iconbtnAccidentes').addClass('la-angle-down')
                    $('#iconbtnAccidentes').addClass('text-blue')
                    // se restaruran las clases de los otros botones 
                    if($('#btnOrganos').hasClass('btn-selected')){
                        $('#btnOrganos').click()

                    }else if($('#btnAccidental').hasClass('btn-selected')){
                        $('#btnAccidental').click()
                    }
                }
                
                break;
            case 'btnOrganos':
                if($('#btnOrganos').hasClass('btn-selected')){
                    $('#btnOrganos').removeClass('btn-blue-4')
                    $('#btnOrganos').removeClass('btn-selected')
                    $('#btnOrganos').addClass('btn-blue-2')
                    // cambiar estilo de icon btn
                    $('#iconbtnOrganos').addClass('la-angle-right')
                    $('#iconbtnOrganos').addClass('text-white')
                    $('#iconbtnOrganos').removeClass('la-angle-down')
                    $('#iconbtnOrganos').removeClass('text-blue')
                    
                }else{
                    $('#btnOrganos').addClass('btn-blue-4')
                    $('#btnOrganos').addClass('btn-selected')
                    // cambiar estilo de icon btn
                    $('#iconbtnOrganos').removeClass('la-angle-right')
                    $('#iconbtnOrganos').removeClass('text-white')
                    $('#iconbtnOrganos').addClass('la-angle-down')
                    $('#iconbtnOrganos').addClass('text-blue')
                    // se restaruran las clases de los otros botones 
                    if($('#btnAccidentes').hasClass('btn-selected')){
                        $('#btnAccidentes').click()

                    }else if($('#btnAccidental').hasClass('btn-selected')){
                        $('#btnAccidental').click()
                    }
                }
            
            break;
            case 'btnAccidental':
                if($('#btnAccidental').hasClass('btn-selected')){
                    $('#btnAccidental').removeClass('btn-blue-4')
                    $('#btnAccidental').removeClass('btn-selected')
                    $('#btnAccidental').addClass('btn-blue-2')
                    // cambiar estilo de icon btn
                    $('#iconbtnAccidental').addClass('la-angle-right')
                    $('#iconbtnAccidental').addClass('text-white')
                    $('#iconbtnAccidental').removeClass('la-angle-down')
                    $('#iconbtnAccidental').removeClass('text-blue')
                    
                }else{
                    $('#btnAccidental').addClass('btn-blue-4')
                    $('#btnAccidental').addClass('btn-selected')
                     // cambiar estilo de icon btn
                     $('#iconbtnAccidental').removeClass('la-angle-right')
                     $('#iconbtnAccidental').removeClass('text-white')
                     $('#iconbtnAccidental').addClass('la-angle-down')
                     $('#iconbtnAccidental').addClass('text-blue')
                     // se restaruran las clases de los otros botones 
                    if($('#btnAccidentes').hasClass('btn-selected')){
                        $('#btnAccidentes').click()

                    }else if($('#btnOrganos').hasClass('btn-selected')){
                        $('#btnOrganos').click()
                    }
                }
                
            break;
        
            default:
                break;
        }
    }
  return (
    <div className='container-fluid bg-transparent-2'>
        <div className='row bg-white'>
            <div className='col-12 d-flex align-items-center justify-content-center'>
                <img
                    src="./../img/beneficios/Icono_logo_panamerican.png"
                    className='img-responsive w-40 py-1'
                    alt="img-logo"
                />
            </div>
        </div>
        <div className='row bg-white'>
            <div className='col-12 d-flex align-items-center justify-content-center border-bottom mt-2 mt-md-2'>
                <h1 className='font-weight-bold text-blue-sec fs-563'>¡No Olvides!</h1>
            </div>
        </div>
        <div className='row justify-content-center flex-wrap align-content-center bg-white'>
            <div className='col-12 col-md-3 col-lg-3 d-flex align-items-center my-3'>
                <img className="img-responsive w-12 mx-1" src="./../img/beneficios/Icono_imprime.png" alt="img-imprime" />
                <p className='font-weight-normal text-blue-sec fs-1 m-0'>1. <span className='font-weight-bold'>Imprime</span> tu póliza</p>
            </div>
            <div className='col-12 col-md-3 col-lg-3 d-flex align-items-center my-3'>
                <img className="img-responsive w-12 mx-1" src="./../img/beneficios/Icono_llena.png" alt="img-llena" />
                <p className='font-weight-normal text-blue-sec fs-1 m-0'>2. <span className='font-weight-bold'>Llena</span> el formulario con letra de molde</p>
            </div>
            <div className='col-12 col-md-3 col-lg-3 d-flex align-items-center my-3'>
                <img className="img-responsive w-12 mx-1" src="./../img/beneficios/Icono_indica.png" alt="img-indica" />
                <p className='font-weight-normal text-blue-sec fs-1 m-0'>3. <span className='font-weight-bold'>Indica</span> tus beneficiarios y <span className='font-weight-normal'>fírmala</span></p>
            </div>
            <div className='col-12 col-md-3 col-lg-3 d-flex align-items-center my-3'>
                <img className="img-responsive w-12 mx-1" src="./../img/beneficios/Icono_mantenla.png" alt="img-mantenla" />
                <p className='font-weight-normal text-blue-sec fs-1 m-0'>4. <span className='font-weight-bold'>Mantenla</span> siempre a <span className='font-weight-normal'>la mano</span></p>
            </div>
        </div>
        <div className='row bg-white'>
            <div className='mb-1 col-11 col-md-12 col-lg-12 d-flex align-items-end flex-wrap justify-content-center text-white my-2'>
                <button onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Benefits/Seguro-Sigma/Poliza%20AP30001680%20.pdf")} className="btn btn-sm btn-cancel fs-1 font-weight-bold rounded-pill mb-1">Póliza</button>
            </div>
        </div>
        <div className='row justify-content-center flex-wrap align-content-center bg-yellow-grow'>
            <div className='mb-1 col-2 col-md-1 col-lg-1 d-flex align-items-center justify-content-end flex-wrap'>
                <img className="img-responsive w-40 pt-1 m-0" src="./../img/beneficios/Icono_recuerda.png" alt="img-recuerda" />
            </div>
            <div className='mb-1 col-9 col-md-6 col-lg-6 d-flex align-items-center justify-content-start flex-wrap'>
                <h4 className='font-weight-bold text-white fs-1 pt-1'><u>Recuerda</u> <span className='font-weight-normal'>que este es el</span> documento principal  <span className='font-weight-normal'>para hacer</span> válido el seguro</h4>
            </div>
        </div>
        <Leyenda/>
        <div className='row bg-white'>
            <div className='mb-1 col-11 col-md-12 col-lg-12 d-flex align-items-end flex-wrap justify-content-center text-white mt-1'>
                <h1 className='font-weight-bold text-blue-sec fs-563'>¿Qué hacer en caso de siniestro?</h1>
            </div>
        </div>
        <div className='row justify-content-center flex-wrap align-content-center bg-white'>
            <div className='mb-1 col-12 col-md-4 col-lg-4 d-flex align-items-end flex-wrap justify-content-center text-white my-2'>
                <button 
                    className="btn btn-sm btn-blue-2 w-100 fs-1 font-weight-bold rounded-pill mb-1" id="btnAccidentes" onClick={() => handleButtonSelected('btnAccidentes')}
                    data-toggle="collapse" data-target="#collapseAccidentes" aria-expanded="false" aria-controls="collapseAccidentes"
                >
                    <span>Reembolso de Gastos Médicos por accidente</span> <i id="iconbtnAccidentes" className="la la-angle-right text-white"></i>
                </button>
            </div>
            <div className='mb-1 col-12 col-md-4 col-lg-4 d-flex align-items-end flex-wrap justify-content-center text-white my-2'>
                <button 
                    className="btn btn-sm btn-blue-2 w-88 fs-1 font-weight-bold rounded-pill mb-1" id="btnOrganos"  onClick={() => handleButtonSelected('btnOrganos')}
                    data-toggle="collapse" data-target="#collapseOrganos" aria-expanded="false" aria-controls="collapseOrganos"
                >
                    <span>Pérdida de Órganos</span> <i id="iconbtnOrganos" className="la la-angle-right text-white"></i>
                </button>
            </div>
            <div className='mb-1 col-12 col-md-4 col-lg-4 d-flex align-items-end flex-wrap justify-content-center text-white my-2'>
                <button 
                    className="btn btn-sm btn-blue-2 w-88 fs-1 font-weight-bold rounded-pill mb-1" id="btnAccidental"  onClick={() => handleButtonSelected('btnAccidental')}
                    data-toggle="collapse" data-target="#collapseAccidental" aria-expanded="false" aria-controls="collapseAccidental"
                >
                    <span>Muerte accidental</span> <i id="iconbtnAccidental" className="la la-angle-right text-white"></i>
                </button>
            </div>
        </div> 
        {/* Accidentes */}
        <div className='row justify-content-center flex-wrap align-content-center bg-white'>
            <div class="col-12 collapse" id="collapseAccidentes">
                <div class="row">
                    <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-bold text-blue-sec fs-1'>1. Recopila y completa la documentación:</p>
                    </div>
                    <div className='pl-3 col-12 col-md-12 col-lg-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>a. Formato de informe médico</p>
                        <a className='text-decoration-none text-base-guay font-weight-bold px-1' href='https://storage.googleapis.com/guay-api-production/Operacion/Benefits/Seguro-Sigma/Informe_Mdico_Gastos_Mdicos_PC_04-05-2022_Editable.pdf' target='_blank' rel="noreferrer">
                        <img className="img-responsive icon-size-25x25 m-0" src="./../img/beneficios/Icono_descarga.png" alt="img-descarga" /> Descargar</a>
                    </div>
                </div>
                <div class="row">
                    <div className='pl-3 col-12 col-md-12 col-lg-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>b. Formulario de reclamación&nbsp;&nbsp;</p>
                        <a className='text-decoration-none text-base-guay font-weight-bold px-1' href='https://storage.googleapis.com/guay-api-production/Operacion/Benefits/Seguro-Sigma/Formulario_de_Reclamo_04-05-2022_Editable.pdf' target='_blank' rel="noreferrer">
                        <img className="img-responsive icon-size-25x25 m-0" src="./../img/beneficios/Icono_descarga.png" alt="img-descarga" /> Descargar</a>
                    </div>
                </div>
                <div class="row">
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>c. Identificación oficial</p>
                    </div>
                    <div className='pl-3 col-11 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>d. Tú póliza - documento que comprueba que perteneces al grupo asegurado.</p>
                    </div>
                    <div className='pl-3 col-11 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>e. Constancia o informe del Médico que acredite las lesiones y tratamientos como consecuencia del Accidente cubierto, así como las fechas en que ocurrió el Accidente y la fecha de la primera atención al paciente.</p>
                    </div>
                    <div className='pl-3 col-11 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>f. Estudios realizados que demuestren la(s) lesiones sufridas por el Asegurado.</p>
                    </div>
                    <div className='pl-3 col-11 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>g. Facturas y recibos fiscales de los servicios médicos recibidos, dichas facturas deberán estar a nombre del Asegurado, además deben contener el desglose correspondiente de gastos.</p>
                    </div>
                    <div className='pl-3 col-11 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>h. Comprobante de domicilio del asegurado con una antiguedad no mayor a 3 (tres) meses.</p>
                    </div>
                    <div className='pl-3 col-11 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>i. Estado de cuenta del beneficiario (no mayor a 3 meses) con fin de validar los datos de la cuenta a depositar.</p>
                    </div>
                </div>
                <div className='row bg-white'>
                    <div className='col-12 d-flex align-items-center bg-pink-iam justify-content-center border-bottom mt-2 mt-md-2'>
                        <h1 className='font-weight-bold text-white text-center fs-1-25 py-1'>Tienes 10 días para realizar tu reclamación</h1>
                    </div>
                </div>
                <Pasos/>
                <div className='row bg-white'>
                    <div className='col-12 d-flex align-items-center justify-content-center mt-2 mt-md-2'>
                        <p className='font-weight-bold text-base-guay text-center fs-1 py-1'><i>Este proceso esta sujeto a cambios sin previo aviso, para mas información consulta las Condiciones Generales del Seguro Colectivo Indemnizatorio de Accidentes Personales Integral a partir de la pág. 47</i></p>
                    </div>
                </div>
                <Contacto/>
            </div>
        </div>
        {/* Pérdida de Órdanos */}
        <div className='row justify-content-center flex-wrap align-content-center bg-white'>
            <div class="col-12 collapse" id="collapseOrganos">
                <div class="row">
                    <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-bold text-blue-sec fs-1'>1. Recopila y completa la documentación:</p>
                    </div>
                    <div className='pl-3 col-11 col-md-12 col-lg-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>a. Carta reclamación - Un documento de redacción libre que lleve Fecha y la intensión de cobrar la indemnización (te damos un ejemplo)</p>
                        <a className='text-decoration-none text-base-guay font-weight-bold px-1' href='https://storage.googleapis.com/guay-api-production/Operacion/Benefits/Seguro-Sigma/Modelo%20de%20carta%20para%20reclamar%20p%C3%A9rdidas%20org%C3%A1nicas.docx' target='_blank' rel="noreferrer">
                        <img className="img-responsive icon-size-25x25 m-0" src="./../img/beneficios/Icono_descarga.png" alt="img-descarga" /> Descargar</a>
                    </div>
                </div> 
                <div class="row">
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>b. Últimos 3 recibos de nómina a la fecha del siniestro y contrato de trabajo</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>c. Indentificación oficial del Asegurado y en caso de ser extranjero, original (para cotejo) y copia de documento que acredite su legal estancia en el país</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>d. Comprobante de domicilio del asegurado con una antigüedad no mayor a 3 (tres) meses</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>e. Registro Federal de contribuyentes y/o Clave Única de Población en caso de ser aplicable</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>f. Copia certificada de las actuaciones ante el Ministerio Público en caso de ser aplicable</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>g. Certificado Individual</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>h. Estudios realizados que demuestren la(s) lesiones sufridas por el Asegurado</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>i. Constancia del médico que acredite la Pérdida Orgánica</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>j. Estado de cuenta del beneficiario (no mayor a 3 meses) con fin de validar los datos de la cuenta a depositar</p>
                    </div>
                </div>
                <div className='row bg-white'>
                    <div className='col-12 d-flex align-items-center bg-pink-iam justify-content-center border-bottom mt-2 mt-md-2'>
                        <h1 className='font-weight-bold text-white fs-1 py-1'>La compañía pagará al Asegurado el porcentaje establecido en Tabla en Pérdidas de Miembros señalada en la Carátula de Póliza y/o en el Certificado Individual sobre la Suma Asegurada Contratada para esta Cobertura, si durante la vigencia del Certificado Individual ocurre un Accidente y a consecuencia de las lesiones del asegurado pierde algún miembro en el evento o dentro de los 90 días posteriores al mismo</h1>
                    </div>
                </div>
                <Pasos/>
                <div className='row bg-white'>
                    <div className='col-12 d-flex align-items-center justify-content-center mt-2 mt-md-2'>
                        <p className='font-weight-bold text-base-guay text-center fs-1 py-1'><i>Este proceso esta sujeto a cambios sin previo aviso, para mas información consulta las Condiciones Generales del Seguro Colectivo Indemnizatorio de Accidentes Personales Integral a partir de la pág. 43</i></p>
                    </div>
                </div>
                <Contacto/> 
            </div>
        </div>
        {/* Muerte Accidental */}
        <div className='row justify-content-center flex-wrap align-content-center bg-white'>
            <div class="col-12 collapse" id="collapseAccidental">
                <div class="row">
                    <div className='pl-2 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-bold text-blue-sec fs-1'>1. Recopila y completa la documentación:</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>a. Carta reclamación - Un documento de redacción libre que lleve Fecha y causa del fallecimiento y la intensión de cobrar la indemnización.</p>
                        <a className='text-decoration-none text-base-guay font-weight-bold px-1' href='https://storage.googleapis.com/guay-api-production/Operacion/Benefits/Seguro-Sigma/Modelo%20de%20carta%20para%20reclamar%20muerte%20accidental.docx' target='_blank' rel="noreferrer">
                        <img className="img-responsive icon-size-25x25 m-0" src="./../img/beneficios/Icono_descarga.png" alt="img-descarga" /> Descargar</a>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>b. Tu póliza - Consentimiento original firmado por el asegurado con designacion de beneficiarios (Certificado en el que se designan a los beneficiarios firmado por el titular).</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>c. Indentificación oficial del asegurado. En caso de ser extranjero, el documento que acredite su legal estancia en el país.</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>d. Acta de defunción del Asegurado y/o certificado de defunción del Asegurado.</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>e. Actuaciones ante el Ministerio Público completas, en caso de que la muerte del Asegurado haya sucedido de forma violenta (averiguaciones previas, identificación del cadáver, necropsia o dispensa, resultado de los estudios químico, toxicológicos y de alcoholemia, fotografías); parte de tránsito en caso de Accidente y conclusiones.</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>f. Acta de nacimiento del Asegurado.</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>g. Comprobante de domicilio del asegurado con una antigüedad no mayor a 3 (tres) meses.</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>h. Registro Federal de Contribuyentes y/o la Clave Única de Registro de Población del Asegurado.</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>i. Comprobante de domicilio particular del Beneficiario y documento de acuerdo al tipo de relación:</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <ul className='text-blue-sec fs-875'>
                            <li>Cónyuge del Asegurado - acta de matrimonio.</li>
                            <li>Concubino o concubina del asegurado - una declaración firmada bajo protesta de decir la verdad del Beneficiario que señale que el Beneficiario y el Asegurado no tenían impedimiento para contraer matrimonio y vivieron en común por al menos un periodo de 2 (dos) años o tienen un hijo en común.</li>
                            <li>Hijo/hija del Asegurado, acta de nacimiento, así como una declaración bajo protesta de decir la verdad que no existe otra persona con mejor derecho para reclamar el seguro.</li>
                            <li>Sucesión legal del Asegurado, testamento o resultado del juicio sucesorio, así como una declaración bajo protesta de decir la verdad que no existe otra persona con mejor derecho para reclamar el seguro.</li>
                        </ul>
                    </div> 
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>j. Certificado individual. (mismo del punto b)</p>
                    </div>
                    <div className='pl-3 col-12 d-flex align-items-center mt-1 mt-md-1'>
                        <p className='font-weight-normal text-blue-sec fs-875 m-0'>k. Estado de cuenta del beneficiario (no mayor a 3 meses).</p>
                    </div>
                </div>
                <div className='row bg-white'>
                    <div className='col-12 d-flex align-items-center bg-pink-iam justify-content-center border-bottom mt-2 mt-md-2'>
                        <h1 className='font-weight-bold text-white fs-1 py-1'>Tienes 90 días para realizar tu reclamación</h1>
                    </div>
                </div>
                <Pasos/>
                <div className='row bg-white'>
                    <div className='col-12 d-flex align-items-center justify-content-center mt-2 mt-md-2'>
                        <p className='font-weight-bold text-base-guay text-center fs-1 py-1'><i>Este proceso esta sujeto a cambios sin previo aviso, para mas información consulta las Condiciones Generales del Seguro Colectivo Indemnizatorio de Accidentes Personales Integral a partir de la pág. 35</i></p>
                    </div>
                </div>
                <Contacto/>
            </div>
        </div>
    </div>
  )
}

export default PlanAmerican
