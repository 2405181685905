import React from 'react';
// Tools
import PropTypes from 'prop-types';

const Menu = ({ scoreboard, setShowTable, showDetails, showTable }) => {
    const style_1 = "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-gray pointer py-2 rounded mb-2 text-white font-weight-bold";
    const style_2 = "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-2 rounded mb-2 text-blue-sec";
    const {
        closed,
        pending,
        progressing
    } = scoreboard;
    return (
        <div className="col-sx-11 col-sm-11 col-md-3 col-lg-3 mx-auto">
            <div
                className={showTable === 0 ? (style_1) : (style_2)}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(0)
                    }
                }}
            >
                <div className="col-12 mb-1">
                    <div className="row">
                        <div className="col-6">
                            <span className="font-large-1">{pending}</span>
                        </div>
                        <div className="col-6"></div>
                    </div>
                </div>
                <div className="col-12">
                    <span>Pendiente</span>
                </div>
            </div>
            <div
                className={showTable === 1 ? (style_1) : (style_2)}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(1)
                    }
                }}
            >
                <div className="col-12 mb-1">
                    <div className="row">
                        <div className="col-6">
                            <span className="font-large-1">{progressing}</span>
                        </div>
                        <div className="col-6"></div>
                    </div>
                </div>
                <div className="col-12">
                    <span>Procesado</span>
                </div>
            </div>
            <div
                className={showTable === 2 ? (style_1) : (style_2)}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(2)
                    }
                }}
            >
                <div className="col-12 mb-1">
                    <div className="row">
                        <div className="col-6">
                            <span className="font-large-1">{closed}</span>
                        </div>
                        <div className="col-6"></div>
                    </div>
                </div>
                <div className="col-12">
                    <span>Cerradas</span>
                </div>
            </div>
        </div>
    );
}

Menu.propTypes = {
    scoreboard: PropTypes.object.isRequired,
    setShowTable: PropTypes.func.isRequired,
    showDetails: PropTypes.bool.isRequired,
    showTable: PropTypes.number.isRequired
}

export default Menu;
