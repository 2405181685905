import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
// TODO: Components
import Header from '../../NewHome/headerAdministrador'
import Menu from '../../Menu'
import AdminUsuarios from '../adminUsuarios'
import AdminRol from './AdminRol'
import Footer from '../../footerApp'
import HelperApp from '../../HelperApp'
// TODO: Generic funct
import { redirectIfNotAuthenticated } from '../../../../lib/auth'
import { validateAccess, getSubsection } from '../../../helpers/validations'
import { getJwt } from '../../../../lib/auth'
// TODO: Tools
import * as toastr from 'toastr';

const AdminUsrController = ({ access }) => {
    const history = useHistory()
    const [menuStep, setMenuStep] = useState(0)
    const [justRead, setJustRead] = useState(false)
    const [justReadSteps, setJustReadSteps] = useState({
        step_1: 0,
        step_2: 0,
    })
    const {
        step_1,
        //step_2,
    } = justReadSteps
    useEffect(() => {
        if (parseInt(getJwt('rol')) > 6 && access.list.length > 0) {
            //console.log(access)
            //console.log(validateAccess(access.list, 3))
            //console.log(validateAccess(access.list, 4))
            const admin = validateAccess(access.list, 3)
            const enterprises = validateAccess(access.list, 4)
            if (admin[0].id_action === 0 && enterprises[0].id_action === 0) {
                toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                history.push("/inicio-colaborador")
            }
            //console.log(getSubsection(admin[0].subSection, 21))
            const admin_roles = getSubsection(admin[0].subSection, 21)
            //console.log(getSubsection(enterprises[0].subSection, 27))
            const enterprises_roles = getSubsection(enterprises[0].subSection, 27)
            let obj = {}
            if (admin_roles[0].id_action === 0) {
                if (enterprises_roles[0].id_action === 0) {
                    toastr.info('No tienes permiso para acceder a esta sección', '¡Ooops!')
                    history.push("/inicio-colaborador")
                } else {
                    if (enterprises_roles[0].id_action === 2) {
                        obj = {
                            ...obj,
                            step_1: 2,
                        }
                        setJustRead(true)
                    } else {
                        obj = {
                            ...obj,
                            step_1: 1,
                        }
                    }
                }
            } else {
                if ((admin_roles[0].id_action === 2)) {
                    obj = {
                        ...obj,
                        step_1: 2,
                    }
                    setJustRead(true)
                } else {
                    obj = {
                        ...obj,
                        step_1: 1,
                    }
                }
            }
            setJustReadSteps(obj)
        }
    }, [access, history])
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className='col-12'>
                                <ul className="breadcrumb font-medium-3 justify-content-end p-1">
                                    <li className="breadcrumb-item"><a href="/inicio-administrador">Administración</a></li>
                                    <li className="breadcrumb-item"><a href="/inicio-empresas">Organizaciones</a></li>
                                    <li className="breadcrumb-item active">{menuStep === 0 ? 'Administración de usuarios' : 'Administración de Roles'}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-3 col-lg-3 col-xxl-2'>
                                        <div
                                            className={menuStep === 0 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-gray pointer py-2" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-2"}
                                            onClick={() => menuStep !== 0 && setMenuStep(0)}
                                        >
                                            <span className={menuStep === 0 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Administración de Usuarios</span>
                                        </div>
                                        <div
                                            className={menuStep === 1 ? "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-blue-gray pointer py-2" : "col-sx-12 col-sm-12 col-md-12 col-lg-12 bg-white-guay pointer py-2"}
                                            onClick={() => menuStep !== 1 && setMenuStep(1)}
                                        >
                                            <span className={menuStep === 1 ? "font-medium-1 text-white font-weight-bold" : "font-medium-1 text-blue-sec"}>Administración de Roles</span>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-9 col-lg-9 col-xxl-10'>
                                        <div className='row'>
                                            {
                                                menuStep === 0 ? (
                                                    <AdminUsuarios
                                                        justRead={justRead}
                                                        step_1={step_1}
                                                    />
                                                ) : (
                                                    <AdminRol
                                                        justRead={justRead}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ access }) => {
    return {
        access
    }
}

export default connect(mapStateToProps)(AdminUsrController)
