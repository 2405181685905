// TODO: Dependencias
import * as toastr from 'toastr';
import axios from "axios";

/**
 * Guarda información de un webinar por empresa y usuario.
 * @param {object} data 
 * @returns 
 */
export const setWebinarView = async (data) => {
    try {
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: `${global.base_url}webinar/setWebinarView`,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData,
        });
        let obj = {
            data: {},
            validation: true,
        };
        if (result) {
            if (result.data) {
                switch (result.data.code) {
                    case 1:
                        obj.data = result.data;
                        break;
                    case 200:
                        obj.data = result.data;
                        break;
                    case 404:
                        toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                        obj.validation = false;
                        break;
                    case 1001:
                        // Validación silenciosa
                        console.log(`Falló el guardado de tiempo. Code: ${result.data.code}`);
                        obj.validation = true;
                        break;
                    default:
                        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!');
                        obj.validation = false;
                        break;
                }
            } else {
                toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!');
                obj.validation = false;
            }
            return obj;
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
            obj.validation = false;
        }
        return obj;
    } catch (error) {
        console.log(error);
        toastr.info(`Error desconocido: Favor de contactar al equipo de soporte.`, '¡Ooops!');
    }
};
