import React from 'react'

const Contacto = () => {
  return (
    <>
        <div className='row bg-white'>
            <div className='col-12 d-flex align-items-center justify-content-center border-bottom mt-2 mt-md-2'>
                <h1 className='font-weight-bold text-blue-sec fs-563'>Contacto</h1>
            </div>
        </div>
        <div className='row bg-white'>
            <div className='col-12 d-flex align-items-center justify-content-start mt-2 mt-md-2'>
                <p className='font-weight-bold text-blue-sec fs-1'>Aclaración de dudas:</p>
            </div>
        </div>
        <div className='row justify-content-start flex-wrap align-content-center bg-white'>
            <div className='col-2 col-md-1 col-lg-1 d-flex align-items-center justify-content-center flex-wrap'>
                <img className="img-responsive w-50" src="./../img/beneficios/icono_telefono.png" alt="img-telefono" />
            </div>
            <div className='col-10 col-md-2 col-lg-2 d-flex align-items-center flex-wrap my-2'>
                <div className='py-1 h-100 d-flex align-items-center justify-content-center'>
                    <a href="tel:55-5047-2500" className='font-weight-light text-blue-sec fs-1 m-0' id='phoneNumber'>(52 55) 50472500</a>
                </div>
            </div>
            <div className='col-2 col-md-1 col-lg-1 d-flex align-items-center justify-content-center flex-wrap'>
                <img className="img-responsive w-50" src="./../img/beneficios/icono_correo_activo.png" alt="img-telefono" />
            </div>
            <div className='col-10 col-md-2 col-lg-2 d-flex align-items-center flex-wrap my-2'>
                <div className='py-1 h-100 d-flex align-items-center justify-content-center'>
                    <a href="mailto:seguros@guay.digital" className='font-weight-light text-blue-sec fs-1 m-0'>seguros@guay.digital</a>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contacto
