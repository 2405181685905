import $ from "jquery";
/**
* Function that validates file Extension
* 
* @param {Object} e Object with error generated
*/
export const remaining_character = async (valLength, inpID, maxLength, eCount) => {
    try {
        let character_remaining = maxLength - valLength;
        let elementCount = '#' + eCount;
        $(elementCount).text(valLength + " / " + character_remaining);
        let inpName = document.getElementById(inpID);
        inpName.addEventListener('input', function () {
            if (this.value.length > maxLength)
                this.value = this.value.slice(0, maxLength);
        });
        if (valLength <= maxLength) {
            $(elementCount).css('color', '#8BBC3F');
        } else {
            $(elementCount).css('color', '#FF0000');
            return {
                'error': 1001, "msg": "La cadena de texto es mayor a " + maxLength + "."
            }
        }
    } catch (exception) {
        console.log(exception);
    }
};

/**
* Function that validates size input file
* 
* @param {Object} e Object with error generated
*/

export const max_length_inp = async (inpID, maxLength) => {
    try {
        let inpName = document.getElementById(inpID);
        inpName.addEventListener('input', function () {
            if (this.value.length > maxLength)
                this.value = this.value.slice(0, maxLength);
        });
    } catch (exception) {
        console.log(exception);
    }
};

/**
* Function that validates size input file
* 
* @param {Object} e Object with error generated
*/
export const validateYear = (inpID) => {
    try {
        console.log(inpID);
        let value = $("#" + inpID).val()

        let currentYear = new Date().getFullYear();
        if (parseInt(value) > currentYear || parseInt(value) < 1900) {
            $("#" + inpID).addClass("border-danger-guay");
            return false;

        } else {
            $("#" + inpID).removeClass("border-danger-guay");
            $("#" + inpID).addClass("border-success");
            return true;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
* Function that validates value
* 
* @param {Object} e Object with error generated
*/
export const validateYearVal = (value) => {
    try {

        console.log(value);
        let currentYear = new Date().getFullYear();
        if (parseInt(value) > currentYear || parseInt(value) < 1900) {
            return false;

        } else {
            return true;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
* Function that validates value
* 
* @param {Object} e Object with error generated
*/
export const validateCollabVal = (value) => {
    try {
        if (parseInt(value) > 999999999 || parseInt(value) < 1) {
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
* Function that validates size input file
* 
* @param {Object} e Object with error generated
*/
export const validateCollab = (inpID) => {
    try {
        let value = $("#" + inpID).val()
        if (parseInt(value) > 999999999 || parseInt(value) < 1) {
            $("#" + inpID).addClass("border-danger-guay");
            return false;

        } else {
            $("#" + inpID).removeClass("border-danger-guay");
            $("#" + inpID).addClass("border-success");
            return true;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Format text day de month de year. Eg. 1 de enero de 2023
 * @param {string} date 
 * @returns 
 */
export const handleDateTextFormat = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth()
    const year = today.getFullYear()
    const textMonths = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    const getTextMonth = textMonths[month]
    return `${day} de ${getTextMonth} de ${year}`
}

export const handleDateToddmmyyyy = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth()
    const year = today.getFullYear()
    return `${day < 10 ? `0${day}`: day}/${month < 10 ? `0${month}`: month}/${year}`
}

export const handleDateToyyyymmdd = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth()+1
    const year = today.getFullYear()
    console.log('month: ', month);
    return `${year}-${month < 10 ? `0${month}`: month}-${day < 10 ? `0${day}`: day}`
}

