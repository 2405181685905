import React, { useState, useEffect } from 'react';
// API calls
import { getPromotionsByEnterpriseAndCollab } from '../Models/BenefitsModel';
import {
    getPromotionCategory,
    getPromotionType,
} from '../Models/Catalog_model';
import { getCredentialSigma } from '../Models/BenefitsModel';
// Components
import Header from '../NewHome/headerColaborador';
import Menu from "../Menu";
import PromotionsCardInfo from './PromotionsCardInfo';
import PromotionsCard from './PromotionsCard';
import PromorionsHTML from './PromorionsHTML';
import HelperApp from '../HelperApp'
import Footer from '../footerApp'
// Tools
import {
    redirectIfNotAuthenticated,
    getJwt,
} from "../../../lib/auth";
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import { handleScroll } from '../../helpers/helpers';

const PromotionsIndex = ({
    general,
}) => {
    const history = useHistory()
    const [promotions, setPromotions] = useState([]);
    const [contactData, setContactData] = useState({
        description: '',
        email: '',
        whatsapp: '',
        phone: '',
        website: '',
    });
    const {
        description,
        email,
        whatsapp,
        phone,
        website,
    } = contactData;
    const [icons, setIcons] = useState({});
    const [showHTML, setShowHTML] = useState(false);
    const [url, setURL] = useState('');
    const [benefitName, setBenefitName] = useState('');
    const [categories, setCategories] = useState([])
    const [types, setTypes] = useState([])
    const [filterData, setFilterData] = useState(promotions)
    const [cardData, setCardData] = useState({
        name: '',
        idCURP: '',
        policy: '',
        validity: '',
    })
    const [showCard, setShowCard] = useState(false);
    useEffect(() => {
        getPromotionsByEnterpriseAndCollab(getJwt("enterprise"), general.enterpriseInScope.idCollab).then(response => {
            if (response.validation) {
                if (response.data.code !== 1001) {
                    setPromotions(response.data.promotions.filter(benefit => benefit.active === 1))
                    setFilterData(response.data.promotions.filter(benefit => benefit.active === 1))
                    let flag = false
                    for (let x in response.data.promotions) {
                        if (response.data.promotions[x].flgGral === 0) {
                            flag = true
                        }

                    }
                    setShowCard(flag)
                    setIcons(response.data.iconsContact);
                } else {
                    toastr.info('No tienes promociones disponibles.', '¡Ooops!')
                    history.push("/inicio-colaborador")
                }
            }
        })
        getPromotionCategory().then(response => {
            if (response.validation) {
                let arr = []
                for (let x in response.data) {
                    arr.push({ ...response.data[x], checked: false })
                }
                setCategories(arr)
            }
        })
        getPromotionType().then(response => {
            if (response.validation) {
                let arr = []
                for (let x in response.data) {
                    arr.push({ ...response.data[x], checked: false })
                }
                setTypes(arr)
            }
        })
        getCredentialSigma(
            general.enterpriseInScope.enterpriseId,
            general.enterpriseInScope.userId,
            general.enterpriseInScope.idCollab
        ).then(response => {
            if (response.validation) {
                setCardData({
                    name: response.data.sigma[0].name,
                    idCURP: response.data.sigma[0].idCURP,
                    policy: response.data.sigma[0].policy,
                    validity: response.data.sigma[0].validity,
                })
            }
        })
        // eslint-disable-next-line
    }, []);
    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href={getJwt('rol') === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")}>Inicio</a></li>
                                                <li
                                                    className={`breadcrumb-item font-medium-3 text-blue-sec ${benefitName === '' ? 'font-weight-bold' : 'pointer'}`}
                                                    onClick={() => {
                                                        if (benefitName !== '') {
                                                            setURL('')
                                                            setBenefitName('')
                                                            setShowHTML(false)
                                                            handleScroll('section-top')
                                                        }
                                                    }}
                                                >Promociones</li>
                                                {benefitName !== '' && (
                                                    <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">{benefitName}</li>
                                                )}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body" id='section-top'>
                            <HelperApp />
                            
                            <PromotionsCardInfo
                                cardData={cardData}
                            />
                            {!showHTML ? (
                                <PromotionsCard
                                    showCard={showCard}
                                    promotions={promotions}
                                    filterData={filterData}
                                    categories={categories}
                                    types={types}
                                    setCategories={setCategories}
                                    setTypes={setTypes}
                                    setFilterData={setFilterData}
                                    setContactData={setContactData}
                                    setShowHTML={setShowHTML}
                                    setURL={setURL}
                                    setBenefitName={setBenefitName}
                                    handleScroll={handleScroll}
                                />
                            ) : (
                                <PromorionsHTML
                                    url={url}
                                    setURL={setURL}
                                    setShowHTML={setShowHTML}
                                    setBenefitName={setBenefitName}
                                    handleScroll={handleScroll}
                                />
                            )}
                            <div
                                className="modal"
                                id="contactModal"
                                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
                                data-backdrop="false"
                            >
                                <div className="modal-dialog modal-sm modal-dialog-centered">
                                    <div
                                        className="modal-content"
                                        style={{ borderRadius: '30px' }}
                                    >
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: 'silver',
                                                width: '25px',
                                                height: '25px',
                                                borderRadius: '50px',
                                                top: '-25px',
                                                right: '-25px',
                                            }}
                                        >&times;</button>
                                        <div className="modal-body">
                                            <h1 className='text-center font-weight-bold'>Contáctanos</h1>
                                            <p className='font-medium-1'>{description}</p>
                                            {email !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.mail} style={{ width: '25px', height: '20px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(`mailto:${email}`)}>{email}</span></p>}
                                            {phone !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.phone} style={{ width: '25px', height: '25px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(`callto:${phone}`)}>{phone}</span></p>}
                                            {whatsapp !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.whatsapp} style={{ width: '25px', height: '25px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`)}>{whatsapp}</span></p>}
                                            {website !== '' && <p className='font-medium-1 pl-2'><img className='mr-1' src={icons.site} style={{ width: '25px', height: '25px' }} alt='icons' /> <span className='text-dark pointer' onClick={() => window.open(website)}>{website}</span></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps)(PromotionsIndex)