import React from 'react';
// Tools
import $ from "jquery";

const RegisterCommunication2 = ({ communicationData, setCommunicationData, testMessage }) => {
    /**
     * 
     * @param {*} e 
     */
    const onChangeText = e => {
        setCommunicationData({
            ...communicationData,
            [e.target.name]: e.target.value
        });
    }
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2 pr-0">
                                    <img
                                        src="./../img/altaEmpresas/perfil_organizacion_iconos-06.png"
                                        alt="header-section"
                                        style={{ width: '75px', height: '75px' }} />
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 pl-0 mt-1">
                                    <p className="font-weight-bold">Comunicación</p>
                                    <p className="text-base text-justify">A continuación te damos a conocer el primer correo que se enviará a tus colaboradores, en él se da una breve explicación de quienes somos y cómo colaboramos juntos, al final podrás agregar información de tu organización si así lo deseas:</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="card card-img-525">
                                <img
                                    className="card-img-top card-img-500"
                                    src="./../img/altaEmpresas/perfil_organizacion_iconos_1_1.png"
                                    alt="background-guay" />
                                <div className="card-img-overlay d-flex flex-wrap align-content-center">
                                    <div className="row d-flex flex-wrap align-content-center font-small-3">
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto">
                                            <p>¡Hola colaborador!</p>
                                            <p>¿Conoces <img className="img-logo-nav pointer" src="./../img/guay-logo.png" alt="logo" style={{height: '1.1rem'}} /> ? Somos una plataforma que ayuda a las organizaciones y sus colaboradores a transformar el entorno laboral: más saludable, mejor habilitado, crecimiento continuo y mejores competencias. </p>
                                            <p>¡Lo hemos creado pensando en ti!</p>
                                            <p>Con guay ahora obtendrás diversos beneficios que puedes aprovechar dentro y fuera de tu entorno laboral para mejorar tu calidad de vida.</p>
                                            <p>Algunos de los beneficios que encontrarás:</p>
                                            <ul>
                                                <li>Información y herramientas para mejorar la salud y bienestar en el trabajo</li>
                                                <li>Evaluaciones y diagnósticos</li>
                                            </ul>
                                            <p>Disfruta de la vida utilizando este extraordinario medio que ponemos a tu alcance para tu beneficio.</p>
                                            <p>Todo esto y más lo encuentras en <img className="img-logo-nav pointer" src="./../img/guay-logo.png" alt="logo" style={{height: '1.1rem'}} />, ingresa a nuestra plataforma, descúbrela y sorpréndete.</p>
                                        </div>
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto text-center">
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control textarea-unresize border-0 p-0"
                                                    rows="3"
                                                    placeholder="Si deseas, puedes escribir un texto adicional aquí."
                                                    id="presentationMsg"
                                                    name="presentationMsg"
                                                    maxLength="500"
                                                    value={communicationData.presentationMsg}
                                                    onChange={onChangeText}
                                                    onClick={() => {
                                                        $('#presentationMsg').removeClass("border-0");
                                                        $('#presentationMsg').addClass("border");
                                                        $('#presentationMsg').removeClass("p-0");
                                                    }}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                            <span
                                className="text-blue pointer"
                                onClick={() => testMessage()}><u>Enviar prueba</u></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterCommunication2;
