import React from 'react';
import { ThreeCircles } from 'react-loader-spinner'

const LoaderApp = () => {
    return (
        <div className="col-12">
            <div className="row d-flex align-items-center justify-content-center">
                <ThreeCircles
                    height="100"
                    width="100"
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor="#2188b4"
                    innerCircleColor="#9cb537"
                    middleCircleColor="#af2f72"
                />
            </div>
        </div>
    )
}

export default LoaderApp;
