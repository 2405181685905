import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import 'toastr/build/toastr.css';

const Menu = ({ general, urlFollow, urlMatrix, urlTutorial, urlOption4, setProtocolState, previewFilePDF }) => {

    const { idProduct, idCategory, idSubCategory } = general;
    let pasos = [];
    let archivos = [];

    if (idProduct === 1 && idCategory === 2 && idSubCategory === 0) {
        pasos = [
            {
                img: './img/icons/Iconos_acciones_protocolo-07.png',
                alt: '1',
                text: 'Accede y revisa los resultados de tu percepción del clima'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-08.png',
                alt: '2',
                text: 'Analiza el nivel de percepción para determinar donde poner el foco'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-09.png',
                alt: '3',
                text: 'Prioriza y define tus acciones'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-10.png',
                alt: '4',
                text: 'Supervisa y da seguimiento a tus acciones'
            },
        ];

        archivos = [
            {
                img: './img/icons/Iconos_acciones_protocolo-05.png',
                alt: 'Guías de acciones',
                buttonText: 'Guías de acciones',
                protocol: 2
            },
            // {
            //     img: './img/icons/Iconos_acciones_protocolo-06.png',
            //     alt: 'formatos de apoyo',
            //     buttonText: 'Formatos de Apoyo',
            //     protocol: 3
            // },
        ];
    } else if (idProduct === 1 && idCategory === 4 && idSubCategory === 0) {
        pasos = [
            {
                img: './img/icons/Iconos_acciones_protocolo-07.png',
                alt: '1',
                text: 'Accede y revisa los resultados del diagnóstico de la cultura'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-08.png',
                alt: '2',
                text: 'Analiza los 4 pilares de la cultura y los subíndices para determinar las áreas de mejora'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-09.png',
                alt: '3',
                text: 'Prioriza las acciones para los pilares que presenten áreas de mejora'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-10.png',
                alt: '4',
                text: 'Supervisa y da seguimiento a tus acciones'
            },
        ];

        archivos = [
            {
                img: './img/icons/Iconos_acciones_protocolo-05.png',
                alt: 'guías completas',
                buttonText: 'Guías Completas',
                protocol: 2
            },
            {
                img: './img/icons/Iconos_acciones_protocolo-06.png',
                alt: 'formatos de apoyo',
                buttonText: 'Formatos de Apoyo',
                protocol: 3
            },
        ];
    } else {

        pasos = [
            {
                img: './img/icons/Iconos_acciones_protocolo-07.png',
                alt: '1',
                text: 'Accede y revisa los resultados del ejercicio.'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-08.png',
                alt: '2',
                text: 'Analiza los niveles de riesgo y de la organización donde se presentan.'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-09.png',
                alt: '3',
                text: 'Prioriza y define tu acciones.'
            }, {
                img: './img/icons/Iconos_acciones_protocolo-10.png',
                alt: '4',
                text: 'Supervisa y da seguimiento a tus acciones.'
            },
        ];

        archivos = [
            /* {
                img: './img/icons/Iconos_acciones_protocolo-04.png',
                alt: 'guías rápidas',
                buttonText: 'Guías Rápidas',
                protocol: 1
            }, */
            {
                img: './img/icons/Iconos_acciones_protocolo-05.png',
                alt: 'Guías de Acciones',
                buttonText: 'Guías de Acciones',
                protocol: 2
            },
            {
                img: './img/icons/Iconos_acciones_protocolo-06.png',
                alt: 'formatos de apoyo',
                buttonText: 'Formatos de Apoyo',
                protocol: 3
            },
        ];
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {
                        (idProduct === 1 && idCategory === 2 && idSubCategory === 0) ?
                            <Fragment>
                                <h1 className="font-medium-4">Acciones de intervención Clima Laboral.</h1>
                                <h1 className="font-medium-2">Para una mejor experiencia en la implementación de tus acciones
                                    considera los siguientes pasos:</h1>
                            </Fragment>
                            :
                            (idProduct === 1 && idCategory === 4 && idSubCategory === 0) ?
                                <Fragment>
                                    <h1 className="font-medium-4 text-blue">Acciones de mejora para los pilares culturales</h1>
                                    <h1 className="font-medium-2">Para impulsar la palanca del cambio cultural considera los siguientes pasos:</h1>
                                </Fragment>
                                :
                                <Fragment>
                                    <h1 className="font-medium-4">Protocolos de Acción</h1>
                                    <h1 className="font-medium-2">Para implementar tus acciones considera los siguientes pasos:</h1>
                                </Fragment>
                    }
                </div>
                <div className="col-12 mt-3">
                    <div className="row d-flex justify-content-center">
                        {
                            pasos.map(paso => (
                                <div className="col-12 col-sm-6 col-md-3 text-center mb-1 mb-md-0" key={paso.img}>
                                    <img alt={paso.alt} src={paso.img} width="60px" />
                                    <p className="font-medium-2 text-blue-sec mt-1">{paso.text}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-lg-9 mb-1 mb-md-0">
                            <div className="row bg-gray-light-2 rounded py-1">
                                <h1 className="font-medium-2 ml-1 mt-1 mb-3">{
                                    (idProduct === 1 && idCategory === 4 && idSubCategory === 0) ?
                                        'Descarga la guía de acciones de acuerdo al pilar cultural que quieras mejorar' :
                                        'Descarga tus archivos de apoyo:'
                                }
                                </h1>
                                {
                                    (idProduct === 1 && idCategory === 4 && idSubCategory === 0) ?
                                        <div className="col-12 mb-4">
                                            <div className="row">
                                                <div className="col-12 col-md-6 px-5 text-center mb-1">
                                                    <img alt="manual" src="./img/icons/Iconos-cultura_participacion.png" height="70px" />
                                                    {/* <a className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" href={urlTutorial} rel="noreferrer" target="_blank" download>Participación</a> */}
                                                    <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlTutorial)}>Participación</button>
                                                </div>
                                                <div className="col-12 col-md-6 px-5 text-center mb-1">
                                                    <img alt="matriz" src="./img/icons/Iconos-cultura_consistencia.png" height="70px" />
                                                    {/* <a className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" href={urlMatrix} rel="noreferrer" target="_blank" download>Consistencia</a> */}
                                                    <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlMatrix)}>Consistencia</button>
                                                </div>
                                                <div className="col-12 col-md-6 px-5 text-center mb-1">
                                                    <img alt="plan" src="./img/icons/Iconos-cultura_adaptabilidad.png" height="70px" />
                                                    {/* <a className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" href={urlFollow} rel="noreferrer" target="_blank" download>Adaptabilidad</a> */}
                                                    <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlFollow)}>Adaptabilidad</button>
                                                </div>
                                                <div className="col-12 col-md-6 px-5 text-center mb-1">
                                                    <img alt="plan" src="./img/icons/Iconos-cultura_mision.png" height="70px" />
                                                    <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlOption4)}>Misión</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="col-12 mb-4 mt-3"  >
                                                <div className="row">
                                                    <div className="col-12 col-md-4 text-center mb-1 mb-md-0">
                                                        <img alt="manual" src="./img/icons/Iconos_acciones_protocolo-01.png" height="70px" />
                                                        <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlTutorial)}>Manual de Implementación</button>
                                                    </div>
                                                    <div className="col-12 col-md-4 text-center mb-1 mb-md-0">
                                                        <img alt="plan" src="./img/icons/Iconos_acciones_protocolo-03.png" height="70px" />
                                                        <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlFollow)}>Plan de Seguimiento</button>
                                                    </div>
                                                    {
                                                        archivos.map(archivo => (
                                                            <div className="col-12 col-md-4 text-center mb-1 mb-md-0">
                                                                <img alt={archivo.alt} src={archivo.img} height="70px" key={archivo.img} />
                                                                <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => setProtocolState(archivo.protocol)}>{archivo.buttonText}</button>
                                                            </div>
                                                        ))
                                                    }
                                                    {/* <div className="col-12 col-md-4 text-center mb-1 mb-md-0">
                                                        <img alt="matriz" src="./img/icons/Iconos_acciones_protocolo-02.png" height="70px" />
                                                        <button className="btn btn-guay font-medium-1 mt-1 rounded-1 w-100" onClick={() => previewFilePDF(urlMatrix)}>Matriz de Apoyo</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 text-center">
                            {
                                (idProduct === 1 && idCategory === 2 && idSubCategory === 0) ?
                                    <img alt="guaycita protocolos de acción" src="./img/guaycit@s/guaycito_acciones_protocolo.png" height="350px" />
                                    :
                                    (idProduct === 1 && idCategory === 4 && idSubCategory === 0) ?
                                        <img alt="guaycito protocolos de acción" src="./img/guaycit@s/Iconos-cultura_guaycito.png" height="400px" />
                                        :
                                        <Fragment>
                                            <img alt="guaycita protocolos de acción" src="./img/guaycit@s/guaycita_acciones_protocolo.png" height="400px" />
                                            <p className="font-medium-1 text-blue-sec mt-1">*El detalle de cada paso lo encuentras en el <b>Manual de Implementación.</b></p>
                                        </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ general }) => {
    return { general }
}

export default connect(mapStateToProps)(Menu);
