import React from 'react'

const BenefitHML = ({
    url,
    setURL,
    setShowHTML,
    setBenefitName,
    handleScroll,
}) => {
    return (
        <>
            <div className='col-12 mx-auto bg-white'>
                <span className='pointer text-cian-giki font-medium-3 font-weight-bold'
                    onClick={() => {
                        setShowHTML(false)
                        setURL('')
                        setBenefitName('')
                        handleScroll('section-top')
                    }}
                ><i className="bi bi-chevron-left font-medium-5" /> Atrás</span>
            </div>
            <div className='col-12 pr-0 pl-0'>
                <div className="embed-responsive embed-responsive-16by9 scroll-benefits">
                    <iframe
                        className="embed-responsive-item"
                        src={url}
                        allowFullScreen
                        title='Beneficios'
                    ></iframe>
                </div>
            </div>
        </>
    )
}

export default BenefitHML