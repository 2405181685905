import React, {
    useState,
} from 'react'
// TODO: API calls
import {
    getComplementaryEvidenceByEnterprise,
    deleteSpecificEvidence,
    deleteEvidence,
} from '../../Models/ComplaintModel'
// TODO: Components
import Form from './ComEviForm'
import Table from './ComEviTable'
// TODO: Generic Func
import { getJwt } from '../../../../lib/auth'

const ComEviCtrl = ({
    service,
    evidence,
    setEvidence,
    disableActions,
    setUrlShowPdf,
    setShowImage,
}) => {
    const [savedFiles, setSavedFiles] = useState([])
    const [resourceObj, setResourceObj] = useState({
        idComplementaryEvidence: '',
        idUser: parseInt(getJwt('jtw')),
        idEnterprise: parseInt(getJwt('enterprise')),
        //resourceType: '',
        title: '',
        description: '',
    })
    const [files, setFiles] = useState([])
    const [sizeFileTotal, setSizeFileTotal] = useState(0)

    const handleResetTable = () => {
        const getService = parseInt(service) === 1 ? '1-1-1' : parseInt(service) === 2 ? '1-1-2' : '1-2-0'
        const getIdService = getService.split('-')
        getComplementaryEvidenceByEnterprise(parseInt(getJwt('enterprise')), parseInt(getIdService[0]), parseInt(getIdService[1]), parseInt(getIdService[2])).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        /* let arr = []
                    response.listComplementaryEvidence.forEach(data => {
                        if (data.resourceType === 1) {
                            arr.push({ ...data, resourceName: 'Archivo' })
                        }
                        if (data.resourceType === 2) {
                            arr.push({ ...data, resourceName: 'Audio' })
                        }
                        if (data.resourceType === 3) {
                            arr.push({ ...data, resourceName: 'Video' })
                        }
                    })
                    setEvidence(arr) */
                        setEvidence(response.listComplementaryEvidence)
                        break;
                    case 1002:
                        setEvidence([])
                        break;
                    default:
                        break;
                }
            }
        })
    }
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='col-12 mb-2'>
                    <h3 className='font-weight-bold'>Evidencia Complementaria</h3>
                </div>
                <Table
                    evidence={evidence}
                    resourceObj={resourceObj}
                    setResourceObj={setResourceObj}
                    setSavedFiles={setSavedFiles}
                    deleteSpecificEvidence={deleteSpecificEvidence}
                    handleResetTable={handleResetTable}
                    deleteEvidence={deleteEvidence}
                    service={service}
                    disableActions={disableActions}
                    setFiles={setFiles}
                    setUrlShowPdf={setUrlShowPdf}
                    setShowImage={setShowImage}
                />
                {!disableActions && (
                    <Form
                        resourceObj={resourceObj}
                        setResourceObj={setResourceObj}
                        handleResetTable={handleResetTable}
                        savedFiles={savedFiles}
                        setSavedFiles={setSavedFiles}
                        deleteSpecificEvidence={deleteSpecificEvidence}
                        service={service}
                        files={files}
                        setFiles={setFiles}
                        sizeFileTotal={sizeFileTotal}
                        setSizeFileTotal={setSizeFileTotal}
                    />
                )}
            </div>
        </div>
    )
}

export default ComEviCtrl