import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import $ from "jquery";
import BannerPromo from '../BannerPromo';
import { getJwt } from "../../../lib/auth";
import { getBannersByEnterprise } from '../Models/SponsorModel';
import * as toastr from 'toastr';

const MainpageCarousel = ({handleServicesActions}) => {

    const [index, setIndex] = useState(0);
    const [bannerImg, setBannerImg] = useState('');

    const carruselImg = [
        {name:"sabias-que_2024__colaboradores-1.gif", url:""},
        {name:"sabias-que_2024_colaboradores-2.png", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/04_Equilibrio%20vida%20y%20trabajo_01_guay.pdf"},
        {name:"sabias-que_2024_colaboradores-3.gif", url:""},
        {name:"sabias-que_2024_colaboradores-11.gif", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/Enfermedades_infograf%C3%ADa%203.pdf"},
        {name:"sabias-que_2024_colaboradores-8.gif", url:""},
        {name:"sabias-que_2024_colaboradores-4.gif", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/05_%20estres%20laboral_03_guay.pdf"},
        {name:"sabias-que_2024_colaboradores-6.gif", url:""},
        {name:"sabias-que_2024_colaboradores-5.png", url:""},
        {name:"sabias-que_2024_colaboradores-9.gif", url:""},
        {name:"sabias-que_2024_colaboradores-7.png", url:""},
        {name:"sabias-que_2024_colaboradores-10.png", url:"https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/Recomendaciones_infografia%201.pdf"},
    ];

    useEffect(() => {
        if (getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined') { }
        else {
            getBannersByEnterprise(getJwt('enterprise'), 2).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setBannerImg(response.resource);
                            break;
                        case 1002:

                            break;
                        default:
                            toastr.error('Problemas técnicos. Favor de contactarse con el equipo de soporte. Code error: ' + response.code, '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error('Problemas con el servidor. Favor de intentar nuevamente más tarde.', '¡Ooops!');
                }
            });
        }
    }, []);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const animationIn = () => {
        $('img.img-selection').show(500);
        $('h1.h1-selection').slideDown(800);
        $('p.p-selection').slideDown(1000);
        $('button.btn-selection').slideDown(1200);
    }

    const animationOut = () => {
        $('img.img-selection').hide(1000);
        $('h1.h1-selection').hide(1000);
        $('p.p-selection').hide(1000);
        $('button.btn-selection').hide(1000);
    }

    const handleClick = (url) => {
        if(url !== ""){
            window.open(url, '_blank');
        }
    };

    return (
        <div className='col-12 mt-1'>
            {/* {getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? (null) : (<BannerPromo
                bannerImg={bannerImg}
                setBannerImg={setBannerImg}
            />)} */}
            {
                window.screen.width > 576 && (
                    bannerImg === '' ? '' : <BannerPromo
                        bannerImg={bannerImg}
                        setBannerImg={setBannerImg}
                    />
                )
            }
            <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                onSlide={animationOut}
                onSlid={animationIn}
                className="carousle-style-2"
                data-tut="tourColaborador-step-6" >
                    {
                        carruselImg.map((element) => ( 
                            <Carousel.Item interval={10000}>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2 px-0">
                                    <div className="row">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center" >
                                            <img
                                                src={`https://storage.googleapis.com/guay-api-production/Operacion/Home/SabiasQue/${element.name}`}
                                                // className='w-100'    
                                                className={element.url !== "" ? 'img-fluid pointer' : 'img-fluid'}
                                                onClick={() => handleClick(element.url)}
                                                alt={element.name} />
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))
                    }
                {/* <Carousel.Item interval={10000}>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2 px-0">
                        <div className="row">
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end" >
                                <img
                                    src="https://storage.googleapis.com/guay-api-production/Operacion/Home/SabiasQue/sabias-que_2024__colaboradores_1.gif"
                                    className='w-100'
                                    alt="sabias-que" />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 slider-style-2" style={{position:'relative'}} >
                        <div className="row">
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                <img
                                    src="https://storage.googleapis.com/guay-api-production/Operacion/Home/SabiasQue/sabias-que_2024_colaboradores_2.png"
                                    className='w-100'
                                    alt="sabias-que" />
                            </div>
                            <div className="col-sx-2 col-sm-2 col-md-12 col-lg-12 d-flex justify-content-end" style={{position:'absolute',bottom:'10%', right: '5%'}}>
                                <button 
                                    className="btn btn-sm rounded-pill-1 fs-1 bg-primary-1 text-white font-weight-bold px-1"
                                    onClick={() => handleClick("https://storage.googleapis.com/guay-api-production/Operacion/Home/DocumentosSabiasQue/04_Equilibrio%20vida%20y%20trabajo_01_guay.pdf")}
                                    // https://drive.google.com/file/d/1yYF2FvtOrg_XMlCYpRe462oZM6t5uWxJ/view
                                >conoce más</button>
                            </div>
                        </div>
                    </div>
                </Carousel.Item> */}
                
            </Carousel>
        </div>
    )
}

export default MainpageCarousel;
