import axios from "axios";

/**
 * 
 * @param {number} idEnterprise 
 * @param {number} idUser 
 * @returns 
 */
export const getMarketStudiesQuiz = async (idEnterprise, idBranch, idCollaborator) => {
    try {
        let result = await axios({
            url: `${global.surveyFront}quizEnterprise/getMarketStudiesQuiz?idEnterprise=${idEnterprise}&idBranch=${idBranch}&idCollaborator=${idCollaborator}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setResponseQuizCollab = async (data) => {
    try {
        const formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: `${global.surveyFront}quizEnterprise/setResponseQuizCollab`,
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setOpenResponseQuizCollab = async (data) => {
    try {
        const formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: `${global.surveyFront}quizEnterprise/setOpenResponseQuizCollab`,
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setFinishQuizCollab = async (data) => {
    try {
        const formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            url: `${global.surveyFront}quizEnterprise/setFinishQuizCollab`,
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getListMarketStudyByIdEnterprise = async (idEnterprise) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.surveyFront}quizEnterprise/getListMarketStudyByIdEnterprise?idEnterprise=${idEnterprise}`,
            headers: {
                Authorization: global.tokenAuth,
            }
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
