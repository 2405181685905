/*
 File Name: Documental.js
 Description: Manage Collaborators API Requests
 guay dev's team
*/
import axios from "axios";

/**
 * 
 * @param {int} idEnterprise 
 * @returns 
 */
export const getProductsDocuments = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "documental/getProductsDocuments?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {int} idEnterprise 
 * @param {int} idProduct 
 * @param {int} idCategory 
 * @returns 
 */
export const getDocumentsByProduct = async (idEnterprise, idProduct, idCategory) => {
    try {
        let result = await axios({
            url: global.base_url + "documental/getDocumentsByProduct?idEnterprise=" + idEnterprise + "&idProduct=" + idProduct + "&idCategory=" + idCategory + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {int} idEnterprise 
 * @param {int} idDocumentMng 
 * @param {int} flagEvidencia 
 * @returns 
 */
export const dwlDocumentMgmt = async (idEnterprise, idDocumentMng, flagEvidencia) => {
    try {
        let result = await axios({
            url: global.base_url + "documental/dwlDocumentMgmt?idEnterprise=" + idEnterprise + "&idDocumentMng=" + idDocumentMng + "&flagEvidencia=" + flagEvidencia + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {int} enterpriseId 
 * @param {int} idProduct 
 * @param {int} idCategory 
 * @param {int} idTypeDocument 
 * @param {int} flagSend 
 * @param {boolean} isEvidence 
 * @param {int} idDocumentMng 
 * @param {int} idUser 
 * @param {Object} file 
 * @returns 
 */
export const uploadDocumentalFiles = async (enterpriseId, idProduct, idCategory, idTypeDocument, flagSend, isEvidence, idDocumentMng, idUser, file) => {
    let request = {
        enterpriseId: enterpriseId,
        idProduct: idProduct,
        idCategory: idCategory,
        idTypeDocument: idTypeDocument,
        flagSend: flagSend,
        isEvidence: isEvidence,
        idDocumentMng: idDocumentMng,
        idUser: idUser
    }
    let formData = new FormData();
    if (file.length ===  3){
        formData.append("file", file[0]);
        formData.append("file", file[1]);
        formData.append("file", file[2]);
    }
    if (file.length === 2){
        formData.append("file", file[0]);
        formData.append("file", file[1]);
    }
    if (file.length === 1){
        formData.append("file", file[0]);
    }
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "documental/uploadDocumentalFiles",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {int} enterpriseId 
 * @param {int} idUser 
 * @param {Object} listDocumentaryManagement 
 * @returns 
 */
export const setLaunchOfDocumentManagement = async (enterpriseId, idUser, listDocumentaryManagement) => {
    let request = {
        enterpriseId: enterpriseId,
        idUser: idUser,
        listDocumentaryManagement: listDocumentaryManagement
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "documental/setLaunchOfDocumentManagement",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {int} enterpriseId 
 * @param {int} idUser 
 * @param {int} flagSend 
 * @param {Object} listDocumentaryManagement 
 * @returns 
 */
export const setFlagSend = async (enterpriseId, idUser, flagSend, listDocumentaryManagement) => {
    let request = {
        enterpriseId: enterpriseId,
        idUser: idUser,
        flagSend: flagSend,
        listDocumentaryManagement: listDocumentaryManagement
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "documental/setFlagSend",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}