import React from 'react'
import $ from "jquery";
import { sendRemeinderByLaunch } from '../Models/Launch_model';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const ModalForward = ({dataForward}) => {
    function handleClicYesForward() {
        console.log('dataForward: ',dataForward);
        $('#closeModalForward').hide()
        $('#modalLoaderGen').show()
        sendRemeinderByLaunch(parseInt(dataForward.userId),parseInt(dataForward.enterpriseId), dataForward.idGroup,dataForward.idProduct, dataForward.idCategorie, dataForward.idSubCategorie).then(response => {
            switch (response?.code) {
                case 1:
                    $('#modalLoaderGen').hide()
                    toastr.success("Se ha enviado el recordatorio correctamente", "Éxito");
                    break;
                case 1001:
                    $('#modalLoaderGen').hide()
                    toastr.info("Su organización se encuentra desactivada", "Error");
                    break;
                case 1002:
                    $('#modalLoaderGen').hide()
                    toastr.error("El usuario no existe o no se encuentra activa.", "Error");
                    break;

                default:
                    $('#modalLoaderGen').hide()
                    toastr.error("Ocurrió un error inesperado, verifique su conexión a internet.", "Error");
                    break;
            }
        });
        // $('#modalLoaderGen').hide()
    }
    
  return (
    <div
        className="modal"
        id="closeModalForward"
        style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
        data-backdrop="false">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div
                className="modal-content"
                style={{ backgroundColor: 'transparent', borderRadius: '2rem', overflow: "auto", border: "none" }}>
                <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                    <button
                        type="button"
                        className="close"
                        onClick={() => $('#closeModalForward').hide()}>&times;</button>
                </div>
                <div className="modal-body modal-div-style-1 container-style-1 scrollbar-style">
                    <div className="row mb-5">
                        <div className="col-12 d-flex justify-content-center">
                            <img width="75px" src="./img/icons/iconos_registro_documental-12.png" alt="success" />
                        </div>
                    </div>
                    <div className="row mb-5 d-flex justify-content-center">
                        <div className="col-8">
                            <h1 className="font-medium-2 text-base text-center">Enviar Recordatorio</h1>
                        </div>
                    </div>
                    <div className="row mb-5 d-flex justify-content-center">
                        <div className="col-10">
                            <p className="font-medium-2 text-base text-center">Se enviará un recordatorio extraordinario para contestar el ejercicio a los colaboradores pendientes ¿Deseas enviarlo?</p>
                        </div>
                    </div>
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-10 d-flex justify-content-between">
                            <button className="btn btn-small btn-cancel-style2" onClick={() => $("#closeModalForward").hide()}>No</button>
                            <button className="btn btn-small btn-save-style2" onClick={() => handleClicYesForward()}>Si, enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
export default ModalForward
