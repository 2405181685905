import { getEnterpriseProfileFunctionalities } from "../components/app/Models/RolModel";
import { getJwt } from "../lib/auth";

//actions
const SET_ACCESS_LIST = 'SET_ACCESS_LIST';
const RESET_VALUES = "RESET_VALUES";

const initialState = {
    list: []
}

// reducer
const generalReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ACCESS_LIST:
            return {
                ...state,
                ...action.payload
            }

        case RESET_VALUES:
            return {
                ...initialState
            }

        default:
            return state;
    }

}

export default generalReducer;

//auxiliar
const saveStorage = function (list) {
    let data = btoa(list)
    sessionStorage.setItem('access', btoa(JSON.stringify(data)));
}

const removeStorage = function () {
    sessionStorage.removeItem('access');
}

//action creators
export const restoreAccessAction = () => (dispatch, getState) => {
    if (getJwt('enterprise') === undefined || getJwt('rol') === undefined) {

    } else {
        if (getState().access.list.length === 0 && getJwt('enterprise') !== 'undefined') {
            getEnterpriseProfileFunctionalities(getJwt('enterprise'), getJwt('rol')).then(response => {
                if (response.code === 1) {
                    dispatch({
                        type: SET_ACCESS_LIST,
                        payload: {
                            list: response.functions
                        }
                    })
                }
            })
        }
    }
}

export const saveAccessList = (list) => (dispatch, getState) => {
    dispatch({
        type: SET_ACCESS_LIST,
        payload: {
            list: list
        }
    });
    saveStorage(getState().list);
}

export const resetAccessList = () => (dispatch, getState) => {
    dispatch({
        type: SET_ACCESS_LIST,
        payload: {
            list: []
        }
    });
    saveStorage(getState().list);
}

export const clearGeneralAction = () => (dispatch, _getState) => {
    dispatch({
        type: RESET_VALUES
    });
    removeStorage();
}
