import React from 'react'
import $ from "jquery"
import HTMLGuayDesign from './HTMLDesignCommunication/HTMLGuayDesign.js'
import HTMLNeutroDesign from './HTMLDesignCommunication/HTMLNeutroDesign.js'

const ModalesHTML = ({ designFlag, enterprise, communicationData, setCommunicationData, files, upFiles }) => {

    function handleClickClose() {
        $("#container1").scrollTop(0)
        $("#HTMLcomm").hide()
    }

    return (
        <div
            className="modal"
            id="HTMLcomm"
            style={{ backgroundColor: "rgba(255,255,255,0.9)", zIndex: 998 }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content"
                    style={{ backgroundColor: 'transparent', borderRadius: '2rem', overflow: "auto", border: "none" }}>
                    <div className="modal-body modal-div-style-1 scrollbar-style" id="container1">

                        {designFlag === 1 ? <HTMLGuayDesign enterprise={enterprise} communicationData={communicationData} setCommunicationData={setCommunicationData} files={files} upFiles={upFiles} /> : designFlag === 2 ? <HTMLNeutroDesign enterprise={enterprise} communicationData={communicationData} setCommunicationData={setCommunicationData} files={files} upFiles={upFiles} /> : null}

                        <div className="row d-flex justify-content-center">
                            <div className="col-10 d-flex justify-content-center">
                                <button type="button" className="btn btn-small btn-cancel-style2" onClick={handleClickClose}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalesHTML

