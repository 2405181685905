import React from 'react';
import UIDropdown from 'react-multilevel-dropdown';

const GuaykipediaMenu = ({ menu, activeProduct, handleClickSection, handleClickVerMas }) => {
    console.log(menu);
    return (
        <div className='row w-100'>
            {
                menu.map(menuItem => {
                    return (
                        <UIDropdown key={menuItem.idCategory} title={menuItem.title}
                            wrapperClassName="col"
                            buttonClassName={menuItem.idCategory === activeProduct.idCategory ? 
                                "w-100 bg-white border-bottom-guay font-medium-1" : 
                                "w-100 bg-white font-medium-1"}
                            position="right"
                        >
                            {
                                menuItem.subCategories.map(subMenu => (
                                    <UIDropdown.Item
                                        key={subMenu.idSectionguaykipedia}
                                        onClick={() => handleClickSection(subMenu.idCategory, subMenu.idSectionguaykipedia)}
                                        className='hoverGuaykipedia'
                                    >
                                        {subMenu.title}
                                        <UIDropdown.Submenu position='right' className='width-fit-content'>
                                            {
                                                subMenu.resources.map(subSubMenu => (
                                                    <UIDropdown.Item
                                                        key={subSubMenu.idResourcesguaykipedia}
                                                        onClick={() => handleClickVerMas(subMenu.idSectionguaykipedia, subSubMenu.idResource, subMenu.idCategory)}
                                                        className='hoverGuaykipedia'
                                                    >
                                                        {subSubMenu.title}
                                                    </UIDropdown.Item>
                                                ))
                                            }
                                        </UIDropdown.Submenu>
                                    </UIDropdown.Item>
                                ))
                            }
                        </UIDropdown>
                    )
                })
            }
        </div>
    );
};

export default GuaykipediaMenu;
