import React, { useState } from 'react'
// TODO: API calls
import { setCollabsToSpecificLaunch } from '../Models/Launch_model'
// TODO: Tools
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table'
import * as toastr from 'toastr'

const CollabsTable = ({
    collaborators,
    launchsSelected,
    handleShowTable,
    disableActions,
}) => {
    
    const [selectedCollabs, setSelectedCollabs] = useState([])
    const handleAssignCollaborators = () => {
        let collabIdsArr = []
        selectedCollabs.forEach(e => collabIdsArr.push(e.collaboratorId))
        //console.log(collabIdsArr)
        setCollabsToSpecificLaunch(collabIdsArr, launchsSelected).then(response => {
            if (response.code === 1 || response.code === 200) {
                toastr.success('Se asginó correctamente los colaboradores al lanzamiento.', '¡Éxito!');
                handleShowTable(launchsSelected)
            } else {
                toastr.error('No se asginó correctamente los colaboradores al lanzamiento.', '¡Ooops!');
            }
        })
    }
    const handleRowSelect = (row, isSelected, e) => {
        //console.log(row)
        //console.log(isSelected)
        //console.log(e)
        let collabArr = []
        if (isSelected) {
            collabArr = selectedCollabs
            collabArr.push(row)
            setSelectedCollabs(collabArr)
        } else {
            selectedCollabs.forEach(e => {
                if (e.collaboratorId !== row.collaboratorId) {
                    collabArr.push(e)
                }
            })
            setSelectedCollabs(collabArr)
        }
    }
    const handleSelectAll = (isSelected, rows) => {
        //console.log(isSelected)
        //console.log(rows)
        if (isSelected) {
            setSelectedCollabs(rows)
        } else {
            setSelectedCollabs([])
        }
    }
    // Table Props
    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    }
    const selectRow = {
        mode: 'checkbox',  // multi select
        clickToSelect: true,
        onSelect: handleRowSelect,
        onSelectAll: handleSelectAll,
    }
    return (
        <div className='col-12'>
            <BootstrapTable
                data={collaborators}
                hover
                striped
                pagination={true}
                options={options}
                tableStyle={{
                    border: 'none',
                    padding: '0px',
                    fontSize: '1rem',
                    color: '#798a94',
                }}
                trClassName={`border-0 ${!disableActions && 'table-checkbox-2'}`}
                tableHeaderClass={`${!disableActions && 'table-checkbox-2'}`}
                trStyle={{ border: '.5px' }}
                search
                searchPlaceholder='Buscar'
                selectRow={!disableActions && selectRow}
            >
                <TableHeaderColumn
                    dataField='collaboratorId'
                    isKey
                    hidden
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField='namesCollab'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                    width='15%'
                >{'Nombre(s)'}</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='lastName'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                    width='15%'
                >Apellidos</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='email'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                    width='20%'
                >Email</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='categoryEntId'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Cargo</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='areaEntId'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Área</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='branchEntId'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Centro de trabajo</TableHeaderColumn>
            </BootstrapTable>
            <div className='col-12 mt-3'>
                {!disableActions && (
                    <button
                        className='btn bg-pink-iam text-white float-right'
                        onClick={handleAssignCollaborators}
                    >Asignar a lanzamiento</button>
                )}
            </div>
        </div>
    )
}

export default CollabsTable
