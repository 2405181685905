import React, { useState, useEffect } from 'react';
import { getJwt } from '../../../lib/auth';
import LoaderApp from '../LoaderApp';
import CollaboratorsTable from './CollaboratorsTable';
import LanzamientoNormativo from './LanzamientoNormativo';
import LanzamientoComplementario from './LanzamientoComplementario';
import LaunchFH from './LaunchFH';
import LaunchWea from './LaunchWea';
import SelectProductComponent from './SelectProductComponent';
import TabLanzamientosComponent from './TabLanzamientosComponent';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import LaunchSR from './LaunchSR';
import { Redirect } from 'react-router';
import { getListMarketStudyByIdEnterprise } from '../Models/FrontSurvey_model';

import LaunchCultura from './LaunchCultura';

const AdminLaunches = ({ prodData, disableActions }) => {

    //get data from cookies
    const enterpriseId = getJwt("enterprise");
    const userId = getJwt("jtw");

    //setters & getters
    const [productData, setproductData] = useState(prodData); //datos del producto
    const [loading, setloading] = useState(true); //estado del componente de carga
    const [tipoLanzamiento, settipoLanzamiento] = useState(null); //tipo de lanzamiento normativo / complementario /Factor Humano
    const [showTable, setShowTable] = useState(false); //estado para mostrar la tabla de colaboradores al modificar
    const [generalPreview, setGeneralPreview] = useState(false); //estado para mostrar la tabla de colaboradores al visualizar
    const [idGroup, setidGroup] = useState(0); //definicion del idGroup para obtener los colaboradores
    const [idAdminLaunch, setidAdminLaunch] = useState(0); //definicion del idAdminLaunch para obtener los colaboradores
    const [onlyViewFlag, setOnlyViewFlag] = useState(false);
    const [customSurveys, setCustomSurveys] = useState([])
    useEffect(() => {
        sessionStorage.removeItem("descriptionLaunch");
        sessionStorage.removeItem("selectValue");
        //sessionStorage.removeItem("inLaunch");
        sessionStorage.setItem("isEdition", 0);
        if (prodData === {}) {
            toastr.info("El producto que seleccionaste no se encuentra disponible", "Oye");
        } else {
            if (productData) {
                switch (productData.idCategory) {
                    case 1:
                        if (sessionStorage.getItem('settipoLanzamiento') === '2') {
                            settipoLanzamiento(2); //NOM 035 complementario
                        } else {
                            settipoLanzamiento(1); //NOM 035
                        }
                        break;
                    case 2:
                        settipoLanzamiento(0); //CLIMA
                        break;
                    case 6:
                        settipoLanzamiento(6); //Factor Humano
                        break;
                    case 7:
                        settipoLanzamiento(7); //Regreso Seguro
                        break;
                    case 4:
                        settipoLanzamiento(4); //Cultura Organizacional
                        break;
                    default:
                        settipoLanzamiento(1);
                        break;
                }
            }
            setloading(false);
        }
        return () => {
        }
    }, [productData, prodData])
    useEffect(() => {
        getListMarketStudyByIdEnterprise(getJwt('enterprise')).then(response => {
            const getSurveys = response.listQuizEnterprise.map(survey => ({ value: survey.idQuizEnterprise, label: survey.title }))
            setCustomSurveys(getSurveys)
        })
    }, [])

    if (loading) {
        return (
            <LoaderApp />
        )
    } else if (showTable || generalPreview) {
        return (
            <div className="col-12 bg-white rounded p-2 mb-5">
                <CollaboratorsTable productData={productData} setShowTable={setShowTable} generalPreview={generalPreview} setGeneralPreview={setGeneralPreview} idGroup={idGroup} idAdminLaunch={idAdminLaunch} onlyViewFlag={onlyViewFlag} setOnlyViewFlag={setOnlyViewFlag} />
            </div>
        );
    } else {
        return (
            <div className="col-12 bg-white rounded px-0 py-2 mb-5">
                <SelectProductComponent enterpriseId={enterpriseId} productData={productData} setproductData={setproductData} />
                <TabLanzamientosComponent tipoLanzamiento={tipoLanzamiento} settipoLanzamiento={settipoLanzamiento} />
                {
                    tipoLanzamiento === 1 ?
                        <LanzamientoNormativo
                            setloading={setloading}
                            enterpriseId={enterpriseId}
                            userId={userId}
                            productData={productData}
                            tipoLanzamiento={tipoLanzamiento}
                            disableActions={disableActions}
                            customSurveys={customSurveys}
                        />
                        :
                        tipoLanzamiento === 2 ?
                            <LanzamientoComplementario
                                setloading={setloading}
                                enterpriseId={enterpriseId}
                                userId={userId}
                                productData={productData}
                                tipoLanzamiento={tipoLanzamiento}
                                setidGroup={setidGroup}
                                idAdminLaunch={idAdminLaunch}
                                setidAdminLaunch={setidAdminLaunch}
                                setShowTable={setShowTable}
                                setGeneralPreview={setGeneralPreview}
                                setOnlyViewFlag={setOnlyViewFlag}
                                disableActions={disableActions}
                                customSurveys={customSurveys}
                            />
                            :
                            tipoLanzamiento === 6 ?
                                <LaunchFH
                                    setloading={setloading}
                                    productData={productData}
                                    tipoLanzamiento={tipoLanzamiento}
                                    idGroup={idGroup}
                                    setidGroup={setidGroup}
                                    idAdminLaunch={idAdminLaunch}
                                    setidAdminLaunch={setidAdminLaunch}
                                    setShowTable={setShowTable}
                                    setGeneralPreview={setGeneralPreview}
                                    setOnlyViewFlag={setOnlyViewFlag}
                                    disableActions={disableActions}
                                />
                                :
                                tipoLanzamiento === 0 ?
                                    <LaunchWea
                                        setloading={setloading}
                                        productData={productData}
                                        tipoLanzamiento={tipoLanzamiento}
                                        idGroup={idGroup}
                                        setidGroup={setidGroup}
                                        idAdminLaunch={idAdminLaunch}
                                        setidAdminLaunch={setidAdminLaunch}
                                        setShowTable={setShowTable}
                                        setGeneralPreview={setGeneralPreview}
                                        setOnlyViewFlag={setOnlyViewFlag}
                                        disableActions={disableActions}
                                        customSurveys={customSurveys}
                                    />
                                    :
                                    tipoLanzamiento === 7 ?
                                        <LaunchSR
                                            setloading={setloading}
                                            productData={productData}
                                            tipoLanzamiento={tipoLanzamiento}
                                            idGroup={idGroup}
                                            setidGroup={setidGroup}
                                            idAdminLaunch={idAdminLaunch}
                                            setidAdminLaunch={setidAdminLaunch}
                                            setShowTable={setShowTable}
                                            setGeneralPreview={setGeneralPreview}
                                            setOnlyViewFlag={setOnlyViewFlag}
                                            disableActions={disableActions}
                                        />
                                        :
                                        tipoLanzamiento === 4 ? (
                                            <LaunchCultura
                                                setloading={setloading}
                                                productData={productData}
                                                tipoLanzamiento={tipoLanzamiento}
                                                idGroup={idGroup}
                                                setidGroup={setidGroup}
                                                idAdminLaunch={idAdminLaunch}
                                                setidAdminLaunch={setidAdminLaunch}
                                                setShowTable={setShowTable}
                                                setGeneralPreview={setGeneralPreview}
                                                setOnlyViewFlag={setOnlyViewFlag}
                                                disableActions={disableActions}
                                                customSurveys={customSurveys}
                                            />
                                        ) : (
                                            <Redirect to="/inicio-administrador" />
                                        )
                }
            </div>
        );
    }
}

export default AdminLaunches
