import React, { useState, useEffect } from "react";
// Components
import ClimateStructureSection from './ClimateStructureSection';
//import IntroSurvey2 from './IntroSurvey';
import Climate from "../IntroText/Climate";
import LoaderSurvey from "../../LoaderSurvey";
import SurveyComplete from '../../SurveyComplete';
import SurveyError from '../../SurveyError';
// Tools
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
// API calls
import { validationAccessClimate, saveResponseClimate, updFinishSurveyClimate, saveResponseClimateOpen } from '../../Models/SurveysModel'
import { getEnterpriseById } from "../../enterprises/Model";

const ClimateController = props => {

    const [sections, setSections] = useState([]);
    const [todayDate, setTodayDate] = useState("");
    const [browser, setBrowser] = useState("");
    const [OS, setOS] = useState("");
    const [coordinates, setCoordinates] = useState("");
    const [loading, setLoading] = useState(true);
    const [showSection, setShowSection] = useState(0);
    const [textarea, setTextarea] = useState("");
    const [nextButton, setNextButton] = useState(false);
    const [submitSurveyButton, setsubmitSurveyButton] = useState(false);
    const [idEnterprise, setIdEnterprise] = useState(0);
    const [idGroup, setIdGroup] = useState(0);
    const [idCollab, setIdCollab] = useState(0);
    const [surveyId, setSurveyId] = useState(0);
    const [showMessage, setShowMessage] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [btnState, setBtnState] = useState(false);
    const [enterpriseData, setEnterpriseData] = useState({})

    useEffect(() => {
        const getURLParameters = props.match.params;
        let parameters = "0-0-0-0";
        try {
            parameters = atob(getURLParameters.db);
        } catch (error) {
            console.error(error)
        }
        let parameter = parameters.split("-");
        let idCollab = parameter[0];
        let idEnterprise = parameter[1];
        let surveyId = parameter[2];
        let idGroup = parameter[3];
        setIdCollab(parseInt(idCollab));
        setIdEnterprise(parseInt(idEnterprise));
        setSurveyId(parseInt(surveyId));
        setIdGroup(parseInt(idGroup));
        getEnterpriseById(idEnterprise).then(response => {
            setEnterpriseData(response)
        })
        validationAccessClimate(idCollab, idEnterprise, surveyId, idGroup).then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        if (response.privacity === 0) {
                            $("#modal-2").show();
                        }
                        let lastQuestionAnswered = 0;
                        let section = 0;
                        let answers = 0;
                        for (let x in response.survey) {
                            for (let y in response.survey[x].questions) {
                                for (let z in response.survey[x].questions[y].answers) {
                                    if (response.survey[x].questions[y].answers[z].check === 1) {
                                        answers = answers + 1;
                                        lastQuestionAnswered = response.survey[x].questions[y].idquestion;
                                        if (answers === response.survey[x].questions.length) {
                                            section = section + 1;
                                            answers = 0;
                                            lastQuestionAnswered = 0;
                                        }
                                    }
                                }
                            }
                        }
                        if (response.survey.length === section) {
                            let section_2 = section - 1;
                            setShowSection(section_2);
                            setSections(response.survey);
                            setsubmitSurveyButton(true);
                        } else {
                            setShowSection(section);
                            setSections(response.survey);
                        }
                        setLoading(false);
                        if (lastQuestionAnswered > 0) {
                            let elementID = lastQuestionAnswered;
                            // element which needs to be scrolled to
                            const element = document.getElementById(`${elementID}`);
                            // scroll to element
                            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                        }
                        break;
                    case 2:
                        window.location.replace(response.url);
                        break;
                    case 3:
                        setShowMessage(true);
                        break;
                    case 4:
                        window.location.replace(response.url);
                        break;
                    case 5:
                        window.location.replace(response.url);
                        break;
                    default:
                        setErrorPage(true);
                        break;
                }
            } else {
                setErrorPage(true);
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Set format date dd/mm/yyyy hh:mm:ss
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        const fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setTodayDate(fechaActual);
        // Set browser
        let browser = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "other";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(browser);
        // Set Operative Sistem
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "other";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOS(so);
        // Set geolocation
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
    }, []);

    const handleChange = (e, idsection, idquestion, idanswer) => {
        e.preventDefault();
        let section = [...sections];
        let totalAnswerChecked = 0;
        let sectionNumber = idsection - 1;
        saveResponseClimate(idanswer, idquestion, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
            if (response) {
                switch (response.code) {
                    case 1:
                        for (let x in section) {
                            if (section[x].idsection === idsection) {
                                for (let y in section[x].questions) {
                                    if (section[x].questions[y].idquestion === idquestion) {
                                        for (let z in section[x].questions[y].answers) {
                                            if (section[x].questions[y].answers[z].idanswer === idanswer) {
                                                section[x].questions[y].answers[z].check = 1;
                                            } else {
                                                section[x].questions[y].answers[z].check = 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (let x in section) {
                            if (section[x].idsection === idsection) {
                                for (let y in section[x].questions) {
                                    for (let z in section[x].questions[y].answers) {
                                        if (section[x].questions[y].answers[z].check === 1) {
                                            totalAnswerChecked = totalAnswerChecked + 1;
                                        }
                                    }
                                }
                            }
                        }
                        if (totalAnswerChecked === section[sectionNumber].questions.length) {
                            if (section.length === idsection) {
                                setsubmitSurveyButton(true);
                                let elementID = 1002;
                                // element which needs to be scrolled to
                                const element = document.getElementById(`${elementID}`);
                                // scroll to element
                                element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                            } else {
                                setNextButton(true);
                                let elementID = 1002;
                                // element which needs to be scrolled to
                                const element = document.getElementById(`${elementID}`);
                                // scroll to element
                                element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                            }
                        } else {
                            let elementID = idquestion;
                            elementID = elementID + 1;
                            // element which needs to be scrolled to
                            const element = document.getElementById(`${elementID}`);
                            // scroll to element
                            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                        }
                        setSections(section);
                        break;
                    default:
                        toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                        break;
                }
            } else {
                toastr.error("No se guardó correctamente la respuesta. Se tienen problemas con el servidor.", "¡Ooops!");
            }
        });
    }

    const getTextarea = e => {
        e.preventDefault();
        let value = e.target.value;
        setTextarea(value);
    }

    const scrollToStart = () => {
        let elementID = 1001;
        // element which needs to be scrolled to
        const element = document.getElementById(`${elementID}`);
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }

    const nextSectionTrigger = e => {
        e.preventDefault();
        if (textarea === "") {
            setShowSection(showSection + 1);
            setNextButton(false);
            setTextarea("");
            scrollToStart();
        } else {
            let idSection = showSection + 1;
            saveResponseClimateOpen(idSection, textarea, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setShowSection(showSection + 1);
                            setNextButton(false);
                            setTextarea("");
                            let elementID = 1001;
                            // element which needs to be scrolled to
                            const element = document.getElementById(`${elementID}`);
                            // scroll to element
                            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                            break;
                        default:
                            toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                            break;
                    }
                }
            });
        }
    }

    const submitSurvey = e => {
        e.preventDefault();
        setLoading(true);
        if (textarea === "") {
            updFinishSurveyClimate(idCollab, idEnterprise, surveyId, idGroup, "").then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                            setShowMessage(true);
                            break;
                        case 2:
                            toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                            window.location.replace(response.url);
                            break;
                        case 5:
                            toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                            window.location.replace(response.url);
                            break;
                        case 1001:
                            toastr.success("No existe la empresa.", "¡Ooops!");
                            setLoading(false);
                            break;
                        case 1002:
                            toastr.success("No existe colaborador.", "¡Ooops!");
                            setLoading(false);
                            break;
                        case 1003:
                            toastr.success("Ejercicio no encontrado, intenta más tarde.", "¡Ooops!");
                            setLoading(false);
                            break;
                        default:
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                            setLoading(false);
                            break;
                    }
                } else {
                    toastr.error("Problemas con la conexión con el servidor. Intenta más tarde.", "¡Ooops!");
                }
            });
        } else {
            let idSection = showSection + 1;
            saveResponseClimateOpen(idSection, textarea, idEnterprise, idGroup, idCollab, todayDate, coordinates, browser, OS, "", "").then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            setTextarea("");
                            break;
                        default:
                            toastr.error("No se guardó correctamente la respuesta. Intentelo nuevamente.", "¡Ooops!");
                            break;
                    }
                }
            });
            updFinishSurveyClimate(idCollab, idEnterprise, surveyId, idGroup, "").then((response) => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                            setShowMessage(true);
                            break;
                        case 2:
                            toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                            window.location.replace(response.url);
                            break;
                        case 5:
                            toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                            window.location.replace(response.url);
                            break;
                        case 1001:
                            toastr.success("No existe la empresa.", "¡Ooops!");
                            setLoading(false);
                            break;
                        case 1002:
                            toastr.success("No existe colaborador.", "¡Ooops!");
                            setLoading(false);
                            break;
                        case 1003:
                            toastr.success("Ejercicio no encontrado, intenta más tarde.", "¡Ooops!");
                            setLoading(false);
                            break;
                        default:
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                            setLoading(false);
                            break;
                    }
                } else {
                    toastr.error("Problemas con la conexión con el servidor. Intenta más tarde.", "¡Ooops!");
                }
            });
        }
    }

    const showMessageBtn = () => {
        setBtnState(!btnState)
    }

    if (errorPage) {
        return (
            <div className="container-fluid">
                <SurveyError />
            </div>
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                {/* <IntroSurvey2 /> */}
                <Climate
                    checkboxVal={btnState}
                    checkboxFunc={showMessageBtn}
                    enterpriseLogo={enterpriseData.logo}
                />
                {
                    loading === true
                        ?
                        (
                            showMessage === true
                                ?
                                (
                                    <SurveyComplete />
                                )
                                :
                                (
                                    <LoaderSurvey />
                                )
                        )
                        :
                        (
                            <div className="row">
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1" id="start">
                                    <img src="../img/logo_guay_color.png" alt="logo-guay" style={{ width: '100px' }} />
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1 font-large-1">
                                    <span className="text-base-guay font-weight-bold">Clima Laboral</span>
                                </div>
                                <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3" id={1001}>
                                    <span>Ayúdanos completando el 100% de las respuestas</span>
                                </div>
                                <ClimateStructureSection
                                    section={[sections[showSection]]}
                                    handleChange={handleChange}
                                    textarea={textarea}
                                    getTextarea={getTextarea} />
                                <div
                                    className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mx-auto d-flex justify-content-center"
                                    id={1002}>
                                    {
                                        nextButton === true
                                            ?
                                            (
                                                <button
                                                    className="btn btn-blue-3 btn-sm"
                                                    onClick={nextSectionTrigger}>Siguiente</button>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }
                                    {
                                        submitSurveyButton === true
                                            ?
                                            (
                                                <button
                                                    className="btn btn-blue-3 btn-sm"
                                                    onClick={submitSurvey}>Finalizar</button>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }
                                </div>
                                <button className="arrow-up pointer" onClick={() => scrollToStart()}><i className="ft-arrow-up icon-medium-size" /></button>
                            </div>
                        )
                }
            </div>
        )
    }
}

export default ClimateController;
