import React, { useState } from "react";
import "../../Config";
import $ from "jquery";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import { recoveryPass } from "./Model";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

const ForgottenPassword = () => {

    const [objValidate, updStateReg] = useState({});
    const {
        isValidate,
    } = objValidate;

    const setUser = (e) => {
        e.preventDefault();
        $('#btn-send-email').text("").prop('disabled', true)
            .addClass("btn-guay-processing")
            .append('<i class="la la-spinner animate__animated animate__rotateIn animate__infinite	infinite"></i> Procesando...');
        $("#form-complaint :input").prop("disabled", true);
        try {
            if (isValidate === 1) {

                let formData = new FormData();
                formData.append("request", JSON.stringify(objValidate));
                recoveryPass(formData).then((result) => {
                    if (result.success) {
                        Swal.fire({
                            title: '<p class="text-white mb-2">¡Éxito!</p>',
                            text: "Se ha enviado un correo electrónico para reestablecer la contraseña",
                            showCloseButton: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            dangerMode: true
                        }).then(() => {
                            window.location = "/";
                        });
                        $(".swal2-title").addClass("bg-header-swal");
                        $(".swal2-actions").addClass("w-100 justify-content-around");
                        $(".swal2-confirm").removeClass("swal2-styled");
                        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                        $(".swal2-cancel").removeClass("swal2-styled");
                        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    } else {
                        let msgError;
                        switch (result.code) {
                            case 99:
                                msgError = 'El correo ingresado no se encuentra registrado';
                                toastr.error(msgError, '¡Espera!');
                                break;
                            case 101:
                                Swal.fire({
                                    title: '<p class="text-white mb-2">¡Éxito!</p>',
                                    text: "Te hemos enviado un correo para la activación de tu cuenta.",
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    dangerMode: true
                                }).then(() => {
                                    window.location = "/";
                                });
                                $(".swal2-title").addClass("bg-header-swal");
                                $(".swal2-actions").addClass("w-100 justify-content-around");
                                $(".swal2-confirm").removeClass("swal2-styled");
                                $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                                $(".swal2-cancel").removeClass("swal2-styled");
                                $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                                break;
                            default:
                                msgError = 'Error interno en el servidor';
                                toastr.error(msgError, '¡Espera!');
                                break;
                        }
                        setTimeout(function () {
                            $('#btn-send-email').text("").prop('disabled', false)
                                .append('Enviar')
                                .removeClass("btn-guay-processing");
                            $("#form-complaint :input").prop("disabled", false);
                        }, 500);
                    }

                });
            } else {
                toastr.warning("Debe registrar la información solicitada.");
                $('#btn-send-email').text("").prop('disabled', false)
                    .append('Enviar')
                    .removeClass("btn-guay-processing");
                $("#form-complaint :input").prop("disabled", false);
                return;
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Change Value form
    const getValue = (e) => {
        try {
            let value = e.target.value;
            let validateField = 0;
            if (value === null || value.length === 0) {
                $("#" + e.target.name).removeClass("border-success");
                $("#" + e.target.name).addClass("border-danger");
                validateField = 0;
            } else {
                $("#" + e.target.name).removeClass("border-danger");
                $("#" + e.target.name).addClass("border-success");
                validateField = 1;
            }
            updStateReg({
                [e.target.name]: value,
                isValidate: validateField,
            })
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="app-content content">
            <div className="content-overlay">
                <div className="content-wrapper">
                    <div className="content-body ">
                        <section className="bg-login-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content-header row mt-5 ">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <h1 className="text-center">
                                                <strong><span className="text-green">¡Olvidaste tu contraseña!</span></strong>
                                            </h1>
                                            <h2 className="text-center">
                                                Estás muy cerca de ingresar a la experiencia <img src="https://canal-denuncia.guay.digital/img/logo_guay_color.png" className="w-10" alt="logo guay"></img>
                                            </h2>
                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>
                                </div>
                                <section className="col-md-12 mt-5 ">
                                    <div className="row">
                                        <div className="col-sx-12 col-md-3"></div>
                                        <div className="col-sx-12 col-md-6 d-flex align-items-center justify-content-center">
                                            <div className="col-sx-12 col-lg-10 col-md-10 box-shoadow-2 p-0">
                                                <div className="card border-grey border-lighten-3 px-2 py-2 m-0" style={{ borderRadius: "15px" }}>
                                                    <div className="card-header border-0 pb-0">
                                                        <div className="row">
                                                            <div className="col-md-12 text-center">
                                                                <h4>Recuperación de contraseña</h4>
                                                                <h5>Ingresa tu correo electrónico para recuperar tu contraseña</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-content mt-2">
                                                        <div className="card-body">
                                                            <form className="form-horizontal" id="form-complaint">
                                                                <div className="row">
                                                                    <div className="col-md-1"></div>
                                                                    <div className="col-md-10">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                {/*<span className="input-group-text bg-dark"><i className="la la-envelope-o"></i></span>*/}
                                                                            </div>
                                                                            <input type="email" className="form-control" id="email" required name="email" placeholder="Correo" onChange={getValue}></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1"></div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-md-5 align-items-center d-flex justify-content-center">
                                                                        <NavLink to="/"><button type="button" className="btn btn-outline-dark btn-block mt-1" style={{ height: "40px", width: "150px", lineHeight: "0rem", borderRadius: "10px", fontFamily: "quicksand" }}>Regresar</button></NavLink>
                                                                    </div>
                                                                    <div className="col-md-2"></div>
                                                                    <div className="col-md-5 align-items-center d-flex justify-content-center">
                                                                        <button type="submit" color="blue" id="btn-send-email" onClick={setUser} className="btn btn-guay2 btn-block mt-1" style={{ height: "40px", width: "150px", lineHeight: "0rem", backgroundColor: "#15253c", color: "white", borderRadius: "10px", fontFamily: "quicksand" }}>Enviar</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sx-12 col-md-3"></div>
                                    </div>

                                </section>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )


}
export default ForgottenPassword;