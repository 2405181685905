import axios from "axios";
// Model From User Data
/*
* Get General user data by user ID
*/
export const getuserData = async (userId) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
              },
            url: global.base_url + "users/data?user=" + userId,
            method: 'POST'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};



/*
* Get address by enterprise ID
*/
export const getAddressById = async (enterpriseId) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
              },
            url: global.base_url + "enterprise/getEnterpriseAddress?idEnterprise=" + enterpriseId,
            method: 'POST'
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/*
* Set status (active) using formData
* formData content:
* + JSON: 
*  - enterprise ID
*  - active 0/1
*/
export const setStatusEnterprise = async (formData) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth,
                'Content-Type': 'multipart/form-data;'
              },
            url: global.base_url + "enterprise/setStatusEnterprise",
            method: 'POST',
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getRoleAssignment = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getRoleAssignment",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const getRoleAssignmentCatalog = async () => {
    try {
        let result = await axios({
            url: global.base_url + "catalogs/getRoleAssignment",
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setRegisterUserRol = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "users/setRegisterUserRol",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setChangeRole = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setChangeRole",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setDisableRoleAssignment = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setDisableRoleAssignment",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/* Get all tutorials by user id and tutorial id */
export const getTutorials = async (userId, tutorialId) => {
    try {
        let result = await axios({
            url: global.base_url + "tutorial/getViewTutorial?idUser=" + userId + "&idTutorial=" + tutorialId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/* Set a specific tutorial by user id and tutorial id */
export const setViewedTutorial = async (userId, tutorialId) => {
    try {
        let result = await axios({
            url: global.base_url + "tutorial/setViewTutorial?idUser=" + userId + "&idTutorial=" + tutorialId + "",
            method: 'POST',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/* Get url of report channel by enterprise id */
export const getReportChannel = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getReportChannel?idEnterprise=" + enterpriseId + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/* Get all tutorials by user id and tutorial id */
export const getsurveyLinksUser = async (idUser, idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "collaborator/getsurveyLinksUser?idUser=" + idUser + "&idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/* Upload collaborators by document file (xlsx) */
export const uploadListCollaborator = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "collaborator/uploadListCollaborator",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};