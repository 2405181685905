/*
 File Name: GuaykipediaModel.js
 Description: Manage guaykipedia API Requests
 guay dev's team
*/
import axios from "axios";
/**
 * Get all the content in guaykipedia
 * @returns 
 */
export const getAllResourcesInfo = async () => {
    try {
        let result = await axios({
            url: global.base_url + "repository/getAllResourcesInfo",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {int} idEnterprise 
 * @returns 
 */
export const getAllResourcesEnterprise = async (idEnterprise) => {
    try {
        let result = await axios({
            url: global.base_url + "repository/getAllResourcesEnterprise?idEnterprise=" + idEnterprise + "",
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};