import React, { useState, useEffect } from 'react';
import { getCollabsByLaunch, setUpdateCollabsByLaunch } from '../Models/Monitor_model';
import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

export const ModalCollabs = ({ setModalData, enterpriseId, groupId }) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [collabsList, setCollabsList] = useState([]);

  useEffect(() => {
    getCollabsByLaunch(enterpriseId, groupId).then((response) => {
      switch (response?.code) {
        case 1:
          setCollabsList(response.listCollabs);
          break;
        case 1001:
          toastr.error('Organización inactiva o eliminada', 'Error');
          break;
        case 1002:
          toastr.error('Error en el lanzamiento, recargue la pagina y vuelva a intentarlo');
          break;

        default:
          toastr.error('No se encontro el lanzamiento');
          // console.error(response);
          break;
      }
    })
    return () => {

    }
  }, [enterpriseId, groupId])

  const handleClickChangeCollabStatus = function (selection) {
    // if (selection === 0) { //eliminar
    //   setUpdateCollabsByLaunch(enterpriseId, groupId, selectedRows, selection);
    // } else if (selection === 1) { //reactivar

    // } else if (selection === 2) { //baja temporal

    // }
    setUpdateCollabsByLaunch(enterpriseId, groupId, JSON.stringify(selectedRows.toString()), selection).then((response) => {
      switch (response?.code) {
        case 1:
          setModalData(prevState => ({
            ...prevState,
            modalShow: !prevState.modalShow
          }));
          toastr.success('Estado del colaborador ha cambiado correctamente');
          break;
        case 1001:
          toastr.error('La empresa no existe o esta desactivada', 'Error');
          break;
        case 1002:
          toastr.error('El lanzamiento no existe', 'Error');
          break;

        default:
          toastr.error('Recargue la pagina y vuelva a intentarlo', 'Ocurrió un error desconocido', 'Error');
          break;
      }
    });
  }

  const createCustomSearchField = () => {
    return (
      <SearchField
        className='my-custom-class'
        placeholder='Buscar' />
    );
  }

  // const deleteCollaborator = (cell, row, rowIndex) => {
  //   /* setdeleteCollabData({
  //     name: row.namesCollab,
  //     lastName: row.lastName,
  //     enterpriseId: row.enterpriseId,
  //     collaboratorId: row.collaboratorId,
  //     job: row.categoryEntId,
  //     area: row.areaEntId
  //   });
  //   $("#confirmDeleteCollab").show(); */
  //   setSelectedRows([
  //     row.collaboratorId
  //   ]);
  // }

  const options = {
    sizePerPage: 10,  // Showing 10 for the size per page as default
    hideSizePerPage: true,
    noDataText: 'No se encontraron registros.',
    searchField: createCustomSearchField,
  };

  const rowStyleFormat = () => {
    return { backgroundColor: '#fff' };
  }
  const tdStyleFormat = () => {
    return { padding: '10px', whiteSpace: 'normal', wordWrap: 'break-word', height: '50px', borderRight: 'none', borderLeft: 'none', borderTop: '1px solid #CCD1D1', borderBottom: '1px solid #CCD1D1' };
  }
  const thStyleFormat = () => {
    return { backgroundColor: '#D7DBDD', border: 'none', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '0px', paddingRight: '0px' };
  }

  const handleRowSelect = (row, isSelected) => {
    if (isSelected) {
      setSelectedRows([
        ...selectedRows,
        row.collaboratorId
      ]);
    } else {
      let filtedSelectedRows = selectedRows.filter(collabId => collabId !== row.collaboratorId);
      setSelectedRows(filtedSelectedRows);
    }
  }
  const handleSelectAll = (isSelected, rows) => {
    if (isSelected) {
      const idsArray = collabsList.map(row => row.collaboratorId);
      setSelectedRows(idsArray);
      return idsArray;
    } else {
      setSelectedRows([]);
    }
  }
  /**
   * Checkbox row props
   */
  const selectRow = {
    mode: 'checkbox',  // multi select
    columnWidth: '4%',
    selected: selectedRows,
    clickToSelect: true,
    onSelect: handleRowSelect,
    onSelectAll: handleSelectAll
  };

  // Table formats //
  const setData = (cell) => {
    if (cell === "" || cell === 0) {
      return (
        <div>
          <i className='ft ft-alert-triangle icon-medium-size text-warning pointer'
            data-toggle="tooltip"
            data-placement="top"
            title="Ningún dato" />
        </div>
      )
    } else {
      return (cell)
    }
  }

  const setDataStatus = (cell) => {
    switch (cell) {
      case 0:
        return (
          <div>
            <i className='ft ft-user-x icon-medium-size text-danger pointer'
              data-toggle="tooltip"
              data-placement="top"
              title="Incompleto" />
          </div>
        );
      case 1:
        return (
          <div>
            <i className='ft ft-user-check icon-medium-size text-success pointer'
              data-toggle="tooltip"
              data-placement="top"
              title="Completo" />
          </div>
        );
      case 2:
        return (
          <div>
            <i className='ft ft-user-minus icon-medium-size text-yellow pointer'
              data-toggle="tooltip"
              data-placement="top"
              title="Inactivo" />
          </div>
        );

      default:
        return (
          <div>
            <i className='ft ft-user-x icon-medium-size text-danger pointer'
              data-toggle="tooltip"
              data-placement="top"
              title="Incompleto" />
          </div>
        );
    }
  }
  // Table formats //


  return (
    <div
      className="modal"
      id="collabModalguay"
      style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'Block' }}
      data-backdrop="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div
          className="modal-content col-12"
          style={{ backgroundColor: 'transparent', border: 'none' }}
        >
          <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
            <button
              type="button"
              className="close"
              onClick={() => {
                setModalData(prevState => ({
                  ...prevState,
                  modalShow: !prevState.modalShow
                }));
              }}>
              &times;
            </button>
          </div>

          <div className="modal-body modal-div-style-1 container-style-1" style={{ backgroundColor: '#f4f4f6' }}>
            <div className="row">
              <div className="col-12 mb-1">
                <BootstrapTable
                  data={collabsList}
                  condensed
                  search
                  pagination={true}
                  tableStyle={{ border: 'none', padding: '0px', fontSize: '12px' }}
                  trClassName='tr-data-table table-checkbox-3'
                  options={options}
                  tableHeaderClass='table-checkbox-3'
                  searchPlaceholder='Buscar...'
                  trStyle={rowStyleFormat}
                  selectRow={selectRow}
                >
                  <TableHeaderColumn dataField="collaboratorId" isKey dataAlign="center" /*dataFormat={setIcon1}*/ /*tdStyle={tdStyleFormat}*/ width="4%" thStyle={thStyleFormat()} hidden ></TableHeaderColumn>
                  <TableHeaderColumn dataField="active" dataAlign="center" dataFormat={setDataStatus} tdStyle={tdStyleFormat} dataSort width="6%" thStyle={thStyleFormat()}>Estado</TableHeaderColumn>
                  <TableHeaderColumn dataField="namesCollab" dataAlign="center" dataFormat={setData} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} dataSort >{"Nombre(s)"}</TableHeaderColumn>
                  <TableHeaderColumn dataField="lastName" dataAlign="center" dataFormat={setData} tdStyle={tdStyleFormat} thStyle={thStyleFormat()} dataSort >Apellidos</TableHeaderColumn>
                  <TableHeaderColumn dataField="email" dataAlign="center" tdStyle={tdStyleFormat} dataFormat={setData} thStyle={thStyleFormat()} >Email</TableHeaderColumn>
                </BootstrapTable>
              </div>
              <div className="col-12 d-flex justify-content-around mt-2">
                <button
                  className="btn btn-sm btn-blue-3 font-medium-3 px-4"
                  onClick={() => {
                    handleClickChangeCollabStatus(1)
                  }}
                >
                  Reactivar en el ejercicio
                </button>
                <button
                  className="btn btn-sm btn-blue-3 font-medium-3 px-4"
                  onClick={() => {
                    handleClickChangeCollabStatus(2)
                  }}
                >
                  Baja temporal del ejercicio
                </button>
                <button
                  className="btn btn-sm btn-blue-3 font-medium-3 px-4"
                  onClick={() => {
                    handleClickChangeCollabStatus(0)
                  }}
                >
                  Eliminar del ejercicio
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
