import axios from "axios";

export const getEnterprise = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + `surveyEco/getbranchEnterprise?idEnterprise=` + enterpriseId,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};
export const getBranch = async (enterpriseId, value) => {
    try {
        let result = await axios({
            url: global.base_url + `surveyEco/getbranchEnterprise?idEnterprise=` + enterpriseId + "&idBranch=" + value + "",
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};
export const postNewRol = async (data) => {
    try {
        let formData = new FormData();
        formData.append("user", JSON.stringify(data));
        let response = await axios({

            url: global.base_url + "users/assignUserRol/",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return response.data ?  response.data : 0;   
    } catch (error) {

        console.log(error);
    }
}

export const postRegisterRol = async (data) => {
    try {
        let formData = new FormData();
        formData.append("user", JSON.stringify(data));
        let result = await axios({

            url: global.base_url + "users/registerUserRol/",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {

        console.log(error);
    }
};

/*
* Set enterprise using formData
* formData content:
* + image (optional)
* + JSON with enterprise data
*/
export const setEnterprise = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setEnterprise",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};

/*
* Set enterprise group using formData
* formData content:
* + image (optional)
* + JSON with enterprise group data
*/

export const setEnterpriseGroup = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/set-group",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};

/*
* Active inactive Enterprise Group
* formData content:
* + image (optional)
* + JSON with enterprise group data
*/

export const updateStatusGroup = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setStatusEnterpriseGroup",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};

/*
* Update enterpise's logo
* formData content:
* + image (required)
* + JSON with enterprise group data
*/
export const updateLogoEnte = async (formData)=>{
    try {
        var result = await axios({
            url: global.base_url + "enterprise/updateLogo",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    } 
}

/*
* Update enterpise's group logo
* formData content:
* + image (required)
* + JSON with enterprise group logo
*/
export const updateLogoGroupEnte = async (formData)=>{
    try {
        var result = await axios({
            url: global.base_url + "enterprise/update-logo-group",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    } 
}

/*
* Get Enterprise Group Data
* + JSON with enterprise group data
*/
export const getDatagroupById = async (groupId) => {
    try {
        let result = await axios({
            url: global.base_url + `enterprise/getGroupById?groupId=` + groupId,
            method: 'GET',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
}

/*
* Update enterprise data using formData
* formData content:
* + image (optional)
* + JSON with enterprise data
*/
export const updateEnterprise = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/updateEnterprise",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};

/*
* Get enterprise by enterprise ID
*/
export const getEnterpriseById = async (enterpriseId) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/getEnterprise?idEnterprise=" + enterpriseId,
            method: 'POST',
            headers: {
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};

/*
* Update enterprise's group data using formData
* formData content:
* + image (optional)
* + JSON with enterprise data
*/
export const updateEnterpriseGroup = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/updateEnterpriseGroup",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};


/*
* Update enterprise's group data using formData
* formData content:
* + image (optional)
* + JSON with enterprise data
*/
export const relEntGroup = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "enterprise/setRelGroupEnterprises",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return result.data ?  result.data : 0;   
    } catch (error) {
        console.log(error);
    }
};