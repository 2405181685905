// [VZO] 29/01/21 Ignore warning -> 'React' is defined but never used  no-unused-vars
// eslint-disable-next-line
import React, { Component } from 'react';
import { setJwt } from "../../lib/auth";
import { getuserData } from '../app/users/Model';

export default class Redirect extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            result: ""
        }
    }

    componentDidMount() {
        try {
            let getObject = this.props.match.params;
            let obj = getObject.db.split("=");
            let userID = obj[0];
            let module = obj[1];
            getuserData(userID).then((response) => {
                let userData = {
                    id: response.token
                    , enterprise: response.enterprise
                    , branch: response.branch
                    , rol: response.rol
                    , help: response.help
                    , help2: 0
                    , tkc: response.tk
                    , email: response.mail
                    , session: response.session,
                    userName: response.name
                }
                setJwt(userData);
            }).then((res) => {
                // [VZO] 29/01/21 Ignore warning -> Expected a default case default-case
                // eslint-disable-next-line
                switch (module) {
                    case "1":
                        window.location = "../eco-rs/registro-documental";
                        break;
                    case "2":
                        window.location = "../eco-rs/evidencia";
                        break;
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            ""
        )
    }
}