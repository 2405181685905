import fetch from "isomorphic-fetch";
import axios from "axios";
export function endpoint(path) {
  let host;
  const hostname = ((window || {}).location || {}).hostname;
  switch (hostname) {
    case "somosguay.com":
      host = "https://api.guay.digital/v2";
      break;
    case "frontend.development.guay.digital":
      host = "https://api.development.guay.digital/v2";
      break;
    case "frontend.staging.guay.digital":
      host = "https://api.staging.guay.digital/v2";
      break;
    case "localhost:3000":
      host = "https://api.development.guay.digital/v2";
      break;
    default:
      host = "https://api.development.guay.digital/v2";
      break;
  }
  return host + path;
}

/*POST REQUEST*/
export const postRequest = async (url, data = null) => {
  try {
    let response = await axios.post(endpoint(url), data, {
      headers: {
        'Authorization': '' + global.tokenAuth + '',
        'Content-Type': 'application/json'
      }
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

/*GET REQUEST*/
export const getRequest = async (url) => {
  axios.get(endpoint(url)).then((response) => {
    let result = response.data;
    return result;
  });
};

export function multipartRequest(url, file) {
  let formData = new FormData();
  formData.append("image[file]", file, file.name);
  return fetch(endpoint(url), {
    method: "POST",
    credentials: "include",
    body: formData,
  });
}
