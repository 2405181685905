import React from 'react';
import { Switch, Route } from "react-router-dom";

import Panel from "./components/app/Panel";
import ResetPassword from './components/web/register/ResetPassword';
import ControlSurvey from './components/app/htk/ControlSurvey';
import Redirect from './components/app/Redirect';
import MainPage from './components/app/NewHome/MainPage';
import InicioColaborador from './components/app/NewHome/inicioColaborador';
import InicioAdministrador from './components/app/enterprises/InicioAdministrador_2';
import InicioUsuarioGenerico from './components/app/NewHome/inicioUsuarioGenerico';
import Home from './components/app/Shop/Home';
/* import MyProfile from './components/app/NewHome/myProfile'; */
import ActionController from './components/app/actions/ActionController';
import ErrorLoadingPage from './components/app/ErrorLoadingPage';
import DocumentManagementController from './components/app/documentManagement/DocumentManagementController';
import ATSController from './components/app/surveys/ATSController';
import EntornoLaboral from './components/app/surveys/EntornoLaboral';
import FiltroClinico from './components/app/surveys/FiltroClinico';
import DemographicController from './components/app/surveys/Demographic/DemographicController';
import DemographicClimate from './components/app/surveys/Demographic/DemographicClimate';
import GuaykipediaController from './components/app/guaykipedia/GuaykipediaController';
import ClimateController from './components/app/surveys/Climate/ClimateController';
import AppShop from './components/app/Shop/AppShop';
import AppBenefits from './components/app/Shop/AppBenefits';
import SupportPage from './components/app/SupportPage';
import KioskController from './components/app/kiosk/KioskController';
import Apple_callback from './components/auth/Apple_callback';
import StatisticalFilterController from './components/app/surveys/StatisticalFilter/StatisticalFilterController';
import ResultController from './components/app/ResultComponents/ResultController';
import ActMonCollabForm from './components/app/actions/actionsMonitoring/ActMonCollabForm';
import AnaliticsController from './components/app/ResultComponents/AnaliticsController';
import Application from './components/app/Shop/Commercial/Application';
import Micro from './components/app/administration/micro/Micro';
import { Docs } from './components/app/administration/micro/Docs';
import { IframeOut } from './components/app/administration/micro/IframeOut';
import RegisterFreemium from './components/app/registerFreemium/RegisterFreemium';
import Advertising from './components/app/advertising/Advertising';
import Webinar from './components/app/webinar/Webinar';
import FreeLanding from './components/app/FreeLanding';
import Benefits from './components/app/benefits/Benefits';
import StatusController from './components/app/Shop/Commercial/StatusStructure/StatusController';
import { VisualizerErrorPage } from './VisualizerErrorPage';
import ProfileController from './components/app/NewHome/profile/ProfileController';
import AdminServices from './components/app/services/AdminServices';
import Calendar from './components/app/enterprises/comunication/Calendar';

export const VisualizerRoutes = () => {
    return (
        <Switch>
            <Route path="/inicio" component={Panel} exact />
            <Route path="/inicio-usuario" component={InicioUsuarioGenerico} exact />
            <Route path="/inicio-tienda" component={MainPage} exact />
            <Route path="/inicio-colaborador" component={InicioColaborador} exact />
            <Route path="/inicio-administrador" component={InicioAdministrador} exact />
            {/* <Route path="/perfil" component={MyProfile} exact /> */}
            <Route path="/perfil" component={ProfileController} exact />
            <Route path="/acceso/restablecer-contrasena/:db" component={ResetPassword} exact />
            <Route path="/htk/:db" component={ControlSurvey} exact />
            <Route path="/redirect/:db" component={Redirect} exact />
            <Route path="/tienda" component={Home} exact />
            <Route path="/estadoemocional/:db" component={ATSController} exact />
            <Route path="/demograficoClima/:db" component={DemographicClimate} exact />
            <Route path="/demografico/:db" component={DemographicController} exact />
            <Route path="/entornolaboral/:identity" component={EntornoLaboral} exact />
            <Route path="/filtroClinico/:identity" component={FiltroClinico} exact />
            <Route path="/guaykipedia" component={GuaykipediaController} exact />
            <Route path="/app-shop" component={AppShop} exact />
            <Route path="/app-benefits" component={AppBenefits} exact />
            <Route path="/support" component={SupportPage} exact />
            <Route path="/climalaboral/:db" component={ClimateController} exact />
            <Route path="/centralSurvey/:db" component={KioskController} exact />
            <Route path="/filtroEstadistico/:db" component={StatisticalFilterController} exact />
            <Route path="/error-loading-page" component={ErrorLoadingPage} exact />
            <Route path="/apple/callback" component={Apple_callback} exact />
            <Route path="/resultados" component={AnaliticsController} exact />
            <Route path="/cumplimiento" component={ResultController} exact />
            <Route path="/acciones" component={ActionController} exact />
            <Route path="/colaborador-tracking" component={ActMonCollabForm} exact />
            <Route path="/solicitud-comercial" component={Application} exact />
            <Route path="/gestion-documental" component={DocumentManagementController} exact />
            <Route path="/webinar" component={Webinar} exact />
            <Route path="/microstrategy-dossier" component={Micro} exact />
            <Route path="/microstrategy-prompts" component={Docs} exact />
            <Route path="/microstrategy-docs" component={IframeOut} exact />
            <Route path="/registro-freemium" component={RegisterFreemium} exact />
            <Route path="/promociones" component={Advertising} exact />
            <Route path="/free-landing" component={FreeLanding} exact />
            <Route path="/beneficios" component={Benefits} exact />
            <Route path="/estatus-solicitud" component={StatusController} exact />
            <Route path="/administracion-servicios" component={AdminServices} exact />
            <Route path="/comunicacion" component={Calendar} exact />
            <Route path="*" component={VisualizerErrorPage} exact />
        </Switch>
    )
}