import React, { useState } from 'react'
import { validateFile } from "../../../../lib/utils/validateFile"
import { updateLogoEnte } from '../Model'
import $, { isEmptyObject } from "jquery"
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

const HTMLNeutroDesign = ({ enterprise, communicationData, setCommunicationData, files, upFiles }) => {

    const [selectValue, setSelectValue] = useState(communicationData.logoFlg === 1 ? "upload" : communicationData.logoFlg === 2 ? "hide" : "")

    if (!isEmptyObject(files)) {
        let file = files
        let reader2 = new FileReader()
        reader2.onload = function (e) {
            $('#img-person').attr('src', e.target.result)
        }
        reader2.readAsDataURL(file)
    }

    const onChangeHandler = (e) => {
        try {
            let file = e.target.files[0]
            validateFile(file).then((res) => {
                if (res.success === 1) {
                    let reader = new FileReader()
                    reader.onload = function (e) {
                        $('#img-person').attr('src', e.target.result)
                    }
                    reader.readAsDataURL(file)
                    upFiles(file)
                    let formData = new FormData()
                    formData.append("file", file)
                    if (sessionStorage.getItem('newIDEnterprise')) {
                        let data = {
                            enterpriseId: sessionStorage.getItem('newIDEnterprise'),
                            ...enterprise
                        }
                        formData.append("enterprise", JSON.stringify(data))
                    } else {
                        let data = {
                            enterpriseId: communicationData.idEnterprise,
                            ...enterprise
                        }
                        formData.append("enterprise", JSON.stringify(data))
                    }
                    updateLogoEnte(formData).then((res) => {
                        if (res.success) {
                            setCommunicationData({
                                ...communicationData,
                                logoFlg: 1
                            })
                            toastr.success("Se actualizó correctamente el logo de la organización.", "¡Exito!")
                        } else {
                            if (res.code === 1001) {
                                toastr.info("El nombre de la imagen no debe tener caracteres especiales.", "Ooops!")
                                setSelectValue("hide")
                                upFiles({})
                                // Limpiar el valor del input si es necesario
    							document.getElementById('file').value = '';
                                return
                            }
                            toastr.error("Ocurrió un problema con la actualización. Intente nuevamente.", "¡Error!")
                        }
                    });
                } else {
                    toastr.error("" + res.msg + "");
                }
            });


        } catch (error) {
            console.error(error);
        }
    }

    function handleChangeSelect(e) {
        if (e.target.value === "upload") {
            setSelectValue("upload")
            if (!isEmptyObject(files)) {
                setCommunicationData({
                    ...communicationData,
                    logoFlg: 1
                })
                let file = files
                let reader2 = new FileReader()
                reader2.onload = function (e) {
                    $('#img-person').attr('src', e.target.result)
                }
                reader2.readAsDataURL(file)
                upFiles(file)
            } else if (enterprise.logo !== undefined && enterprise.logo !== "" && enterprise.logo !== "null") {
                setCommunicationData({
                    ...communicationData,
                    logoFlg: 1
                })
            } else {
                $("#file").click();
            }
        } else if (e.target.value === "hide") {
            setSelectValue("hide")
            setCommunicationData({
                ...communicationData,
                logoFlg: 2
            });
        } else {
            toastr.info("Puede subir su logotipo, que se mostrará en el cuerpo del correo que se enviará")
        }
    }

    return (
        <center>
            <div className="container-fluid">

                

                <div className="row p-1">
                <div className="col-6">
                        <img alt=""
                            //src="https://mcusercontent.com/bc36ae27c4c4c23c73119a7d7/images/6eda776b-b993-4aba-8d32-4bcc04ebe230.png"
                            src="./../img/saludLaboral.png"
                            style={{ maxWidth: "300px", paddingBottom: "0" }}
                            className="mcnImage" />
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <div>
                            <input type="file" id="file" className="hidden" onChange={onChangeHandler} />
                            <img src={enterprise.logo} id="img-person" alt="persona" className="pointer pull-up edition-cursor" onClick={() => $("#file").click()}
                                style={{ maxWidth: "300px", maxHeight: "60px", paddingBottom: "0", height: "auto", width: "auto" }}
                                hidden={communicationData.logoFlg === 1 ? false : communicationData.logoFlg === 2 ? true : false} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '25px' }}>
                            <Popup trigger={<img className="icon-img-18x15 blurryImg help-cursor mr-1" src="../img/icons/iconos_registro_documental-20.png" alt="subir_logo" />} position="left">
                                <div className="p-1 text-center">
                                    Peso: 4 Mb (Max) <br />
                                    Tamaño: Ej. 1000px x 400px <br />
                                    Formato: .png .jpg .jpeg
                                </div>
                            </Popup>
                            <select value={selectValue} onChange={handleChangeSelect}>
                                <option value="upload">Con logotipo</option>
                                <option value="hide">Sin logotipo</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row p-1">
                    <div className="col-12">
                        <img align="center" alt=""
                            //src="https://mcusercontent.com/bc36ae27c4c4c23c73119a7d7/images/78144cdb-0f97-4123-a71a-24599a00a909.png"
                            src="./../img/Estructura_Neutro_1.png"
                            width="564"
                            style={{ maxWidth: "600px", paddingBottom: "0", display: "inline !important", verticalAlign: "bottom" }}
                            className="mcnImage" />
                    </div>
                </div>

                <div className="row p-1">
                    <div className="col-12">
                        <img align="center" alt=""
                            //src="https://mcusercontent.com/bc36ae27c4c4c23c73119a7d7/images/8eeb21ac-fcba-401e-90f0-85f6c6a768fc.png"
                            src="./../img/Estructura_Neutro_2.png"
                            width="564"
                            style={{ maxWidth: "600px", paddingBottom: "0", display: "inline !important", verticalAlign: "bottom" }}
                            className="mcnImage" />
                    </div>
                </div>

                <div className="row p-1">
                    <div className="col-12">
                        <img align="center" alt=""
                            //src="https://mcusercontent.com/bc36ae27c4c4c23c73119a7d7/images/e8272f38-0673-4dbe-9583-a6ad2c6769b9.png"
                            src="./../img/Estructura_Neutro_3.png"
                            width="564"
                            style={{ maxWidth: "600px", paddingBottom: "0", display: "inline !important", verticalAlign: "bottom" }}
                            className="mcnImage" />
                    </div>

                </div>
            </div>
        </center>
    )
}

export default HTMLNeutroDesign
